/* cSpell:disable */

type ContactNameIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const ContactNameIcon = ({ tableSize, stroked }: ContactNameIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7468" id="Grupo_7468" transform="translate(-0.318 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0.318 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <g id="check" transform="translate(2.318 1.837)">
              <path
                d="M0,0H16V16H0Z"
                data-name="Trazado 3061"
                fill="rgba(0,0,0,0)"
                id="Trazado_3061"
              />
              <path
                d="M5.086,3A2.086,2.086,0,0,0,3,5.086V7.171A2.086,2.086,0,0,0,5.086,9.257H7.171A2.086,2.086,0,0,0,9.257,7.171V5.086A2.086,2.086,0,0,0,7.171,3Zm0,8.343A2.086,2.086,0,0,0,3,13.429v2.086A2.086,2.086,0,0,0,5.086,17.6H7.171a2.086,2.086,0,0,0,2.086-2.086V13.429a2.086,2.086,0,0,0-2.086-2.086Zm6.257-6.257A2.086,2.086,0,0,1,13.429,3h2.086A2.086,2.086,0,0,1,17.6,5.086V7.171a2.086,2.086,0,0,1-2.086,2.086H13.429a2.086,2.086,0,0,1-2.086-2.086Zm0,8.343a2.086,2.086,0,0,1,2.086-2.086h2.086A2.086,2.086,0,0,1,17.6,13.429v2.086A2.086,2.086,0,0,1,15.514,17.6H13.429a2.086,2.086,0,0,1-2.086-2.086Z"
                data-name="Trazado 3252"
                fill="#004dbc"
                id="Trazado_3252"
                transform="translate(-2.3 -2.3)"
              />
            </g>
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7397" id="Grupo_7397" transform="translate(0 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M13.287,8.4h3.246m-3.246,2.6h3.246m-3.246,2.6h3.246M4.2,17.485H17.182a1.948,1.948,0,0,0,1.948-1.948V6.448A1.948,1.948,0,0,0,17.182,4.5H4.2A1.948,1.948,0,0,0,2.25,6.448v9.089A1.948,1.948,0,0,0,4.2,17.485ZM9.391,8.72A1.623,1.623,0,1,1,7.768,7.1,1.623,1.623,0,0,1,9.391,8.72Zm1.12,5.485a5.85,5.85,0,0,1-5.486,0,2.922,2.922,0,0,1,5.486,0Z"
              data-name="Trazado 4475"
              fill="none"
              id="Trazado_4475"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              transform="translate(-0.69 -1.155)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
