import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Option, toast } from '@/lib/v2/components';

import { IGroup, ISegmentTableRow, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { GROUP_PATH } from '@/src/modules/ContactsModule/constants';

type Segment = {
  segment: ISegmentTableRow;
};
type SegmentList = Segment[];

type Group = {
  group: IGroup;
};
type GroupList = Group[];

type Tag = {
  tag: ITag;
};
type TagsList = Tag[];

type CurrentListType = SegmentList | GroupList | TagsList;

interface useDistributeContactsProps<T> {
  currentList: T;
  selectedRows: number[];
}
/** cspell:disable */
const typeByTab = {
  groups: 'usuario',
  tags: 'interes',
  segments: 'perfil',
};
type KeyTypeByTab = keyof typeof typeByTab;
/** cspell:enable */

const quantities: Option[] = [
  { name: '2', id: 2, value: 2, disabled: false },
  { name: '3', id: 3, value: 3, disabled: false },
  { name: '4', id: 4, value: 4, disabled: false },
  { name: '5', id: 5, value: 5, disabled: false },
];

export const useDistributeContacts = <T extends CurrentListType>({
  currentList,
  selectedRows,
}: useDistributeContactsProps<T>) => {
  const service = useService();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const view = pathname.split('/').pop() as KeyTypeByTab;
  const { t } = useTranslation();

  const [distributeIsLoading, setDistributeIsLoading] = useState(false);
  const [openDistributeModal, setOpenDistributeModal] = useState(false);

  const getListSelected = useCallback((currentList: T, selectedRows: number[]) => {
    const arrFixed: Array<(typeof currentList)[number]> = currentList;

    return arrFixed.filter((_: Segment | Group | Tag, index: number) => {
      return selectedRows.includes(index);
    }) as T;
  }, []);

  const segmentsIds: number[] = useMemo(() => {
    const listSelected = getListSelected(currentList, selectedRows);

    return listSelected.map((item) => {
      if ('segment' in item) {
        return item.segment.id;
      } else if ('group' in item) {
        return item.group.id;
      } else {
        return item.tag.id;
      }
    });
  }, [currentList, getListSelected, selectedRows]);

  const getTotalContacts = useCallback(
    (currentList: T, selectedRows: number[]) => {
      if (!currentList) return 0;

      const listSelected = getListSelected(currentList, selectedRows);

      const segmentsContactsSelected = listSelected.map((item) => {
        if ('segment' in item) {
          return item.segment.contacts;
        } else if ('group' in item) {
          return item.group.contacts;
        } else {
          return item.tag.contacts;
        }
      });

      const sumOfContactsSelected = segmentsContactsSelected.reduce((total, num) => {
        if (typeof num === 'number' && typeof total === 'number') {
          return total + num;
        }
      }, 0);

      return sumOfContactsSelected;
    },
    [getListSelected]
  );

  const distributeIsDisabled = useMemo(() => {
    const totalContacts = getTotalContacts(currentList, selectedRows) ?? 0;

    return totalContacts <= 1 ? true : false;
  }, [currentList, getTotalContacts, selectedRows]);

  const distributeContacts = useCallback(
    async (name: string, quantity: number) => {
      setDistributeIsLoading(true);
      const response = await service.segmentContacts(segmentsIds, quantity, name, typeByTab[view]);
      let OkBody;
      let unknownError;
      const repeatedNameError = t(
        'MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.ExistingBody.Groups'
      );
      if (view === 'segments') {
        OkBody = t(`MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.OkBody.segments`);
        unknownError = t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.KoBody.segments');
      } else if (view === 'groups') {
        OkBody = t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.OkBody.Groups');
        unknownError = t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.KoBody.Groups');
      } else {
        OkBody = t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.OkBody.Tags');
        unknownError = t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.KoBody.Tags');
      }

      setDistributeIsLoading(false);
      if (response.statusOk) {
        return { statusOK: response.statusOk, message: OkBody };
      } else if (response.errorType.repeatedName) {
        return { statusOK: false, message: repeatedNameError };
      } else {
        return { statusOK: false, message: unknownError };
      }
    },
    [segmentsIds, service, t, view]
  );

  const handleDistributeSubmit = useCallback(
    async (name: string, quantity: number) => {
      const distributeResponse = await distributeContacts(name, quantity);
      const { statusOK, message } = distributeResponse;
      toast({
        title: statusOK
          ? t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.NOTIFICATION.AlertTitle'),
        body: message,
        variant: statusOK ? 'success' : 'error',
      });
      if (statusOK) {
        navigate(`${GROUP_PATH}?filterSearch=${name}`);
      }
      setOpenDistributeModal(false);
    },
    [distributeContacts, navigate, t]
  );

  const quantityOptions = useMemo(() => {
    const totalContacts = getTotalContacts(currentList, selectedRows) ?? 0;
    return quantities.map((quantity) => {
      if (Number(quantity.value) > totalContacts) return { ...quantity, disabled: true };
      return quantity;
    });
  }, [currentList, getTotalContacts, selectedRows]);

  return {
    distributeIsLoading,
    handleDistributeSubmit,
    distributeIsDisabled,
    openDistributeModal,
    setOpenDistributeModal,
    quantityOptions,
    segmentsIds,
  };
};
