import { atomWithCache } from 'jotai-cache';

import { ICustomFieldsResponse } from '@/src/infrastructure/interfaces/ICustomFields';
import { CustomFieldsServiceSingleton } from '@/src/infrastructure/Protocol/CustomFields/service/CustomFieldsServiceSingleton';

const apiManagerService = CustomFieldsServiceSingleton.getInstance();

export const atomCustomFieldsService = atomWithCache<Promise<ICustomFieldsResponse>>(() => {
  const data = apiManagerService.getCustomFields();
  return data;
});
atomCustomFieldsService.debugLabel = 'atomCustomFieldsService';
