export const EmailRemarketingIcon = () => {
  return (
    <svg fill="none" height="30" viewBox="0 0 33 30" width="33" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5776_507)">
        <path
          d="M16.0177 8.61323L25.0001 0.119083C24.715 0.0443393 24.4159 0 24.1067 0H3.41326C3.10274 0 2.80493 0.0430725 2.51986 0.119083L11.5023 8.6145C12.7584 9.80279 14.7615 9.80279 16.0177 8.6145V8.61323Z"
          fill="#2556B8"
        />
        <path
          d="M21.5512 12.3606C22.6393 11.2749 23.9718 10.7226 25.5142 10.72C25.8426 10.72 26.1697 10.72 26.498 10.72H27.2807H27.5187V3.30902C27.5187 2.38929 27.1305 1.55698 26.5056 0.957764L16.0164 10.8784C14.7603 12.0667 12.7571 12.0667 11.501 10.8784L1.01304 0.957764C0.38816 1.55698 0 2.38929 0 3.30902V17.4989C0 19.3269 1.52846 20.8079 3.41327 20.8079H13.6963C13.9165 20.1972 14.2754 19.6158 14.7832 19.1103L17.2331 16.6678C18.6737 15.2325 20.1131 13.7972 21.5525 12.3618L21.5512 12.3606Z"
          fill="#2556B8"
        />
        <path
          d="M30.8416 12.021C30.5094 12.021 30.1785 12.021 29.8464 12.021C29.3157 12.021 28.7862 12.021 28.2555 12.021C27.7337 12.021 27.212 12.021 26.6914 12.021C26.3644 12.021 26.0373 12.021 25.7102 12.021C24.5228 12.0235 23.5111 12.4403 22.6711 13.279C20.416 15.5301 18.1583 17.7788 15.9019 20.0287C14.6343 21.293 14.6305 23.1882 15.898 24.4563C17.4354 25.993 18.9753 27.5258 20.5191 29.0562C21.1528 29.6845 21.947 30 22.7411 30C23.5352 30 24.3218 29.6871 24.953 29.0612C27.2285 26.8025 29.4964 24.5361 31.7668 22.2723C32.5915 21.4501 32.9962 20.4531 32.9987 19.2952C33.0025 17.5862 33.0013 15.8773 32.9987 14.167C32.9975 12.9002 32.1168 12.0248 30.8428 12.0223L30.8416 12.021ZM27.8686 18.6656C27.8686 18.6656 27.8636 18.6656 27.8597 18.6656C27.016 18.6605 26.3338 17.984 26.3236 17.1428C26.3135 16.2991 27.0058 15.6061 27.8597 15.6049H27.8636C28.7201 15.6049 29.4009 16.2814 29.4009 17.134C29.4009 17.9827 28.715 18.6668 27.8674 18.6668L27.8686 18.6656Z"
          fill="#2556B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5776_507">
          <rect fill="white" height="30" width="33" />
        </clipPath>
      </defs>
    </svg>
  );
};
