import { Collapse, Text } from '@/lib/v2/components';

import { Option } from './Select';
import SelectOption from './SelectOption';

export interface SelectGroupProps {
  groupName: string;
  optionsGroup: Option[];
  isOpened: boolean;
  onOpen: (index: number, groupName?: string) => void;
  index: number;
}

const SelectGroup = ({ groupName, optionsGroup, isOpened, onOpen, index }: SelectGroupProps) => {
  return (
    <div className="w-full py-1">
      <div
        className="flex w-full cursor-pointer flex-col px-2"
        onClick={() => onOpen(index, groupName)}
      >
        <Text fontWeight="bold" variant="text">
          {groupName}
        </Text>
        <span className="h-[2px] w-full bg-gray-200"></span>
      </div>
      <Collapse isOpened={isOpened}>
        {optionsGroup.map((option) => (
          <SelectOption key={option.id} option={option} />
        ))}
      </Collapse>
    </div>
  );
};

export default SelectGroup;
