//cspell:disable

export const preImportContactsStatusStub = [
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 0,
      Estado: null,
      Error: null,
      contactosDetectados: 0,
      contactosImportados: {
        cantidad: 0,
        detalle: {},
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {},
      },
    },
    IdProcess: 342110405,
    Estado: 'CARGANDO_SQL',
    status: 'CARGANDO_SQL',
    ProgressRange: {
      Min: 1,
      Max: 20,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 0,
      Estado: null,
      Error: null,
      contactosDetectados: 0,
      contactosImportados: {
        cantidad: 0,
        detalle: {},
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {},
      },
    },
    IdProcess: 342110405,
    Estado: 'CORRIGIENDO',
    status: 'CORRIGIENDO',
    ProgressRange: {
      Min: 20,
      Max: 45,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 0,
      Estado: null,
      Error: null,
      contactosDetectados: 0,
      contactosImportados: {
        cantidad: 0,
        detalle: {},
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {},
      },
    },
    IdProcess: 342110405,
    Estado: 'MARCANDO_BASURAS',
    ProgressRange: {
      Min: 55,
      Max: 70,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 0,
      Estado: null,
      Error: null,
      contactosDetectados: 0,
      contactosImportados: {
        cantidad: 0,
        detalle: {},
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {},
      },
    },
    IdProcess: 342110405,
    Estado: 'CONTANDO_REGISTROS',
    ProgressRange: {
      Min: 90,
      Max: 99,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'PREIMPORTACION_DATOS_DISPONIBLES',
    ProgressRange: {
      Min: 100,
      Max: 100,
    },
  },
];

export const analyzeResultStub = {
  ProcessIdentifier: 342110405,
  Estado: null,
  Error: null,
  contactosDetectados: 186800,
  contactosImportados: {
    cantidad: 186800,
    detalle: {
      nuevos: 0,
      actualizados: 0,
      nuevos_corregidos: 0,
      actualizados_corregidos: 0,
      reactivados: 2,
      reactivados_corregidos: 0,
      sin_modificar: 186798,
    },
  },
  cantidadDescartados: {
    cantidad: 0,
    detalle: {
      repetidos: 0,
      insuficientes: 0,
      invalidos: 0,
      basuras: 0,
    },
  },
};

export const importContactsStatusStub = [
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'CARGANDO_DATOS_AUXILIARES',
    ProgressRange: {
      Min: 25,
      Max: 55,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'MODIFICANDO_FECHA_MODIFICACION',
    ProgressRange: {
      Min: 55,
      Max: 56,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'DETECTANDO_INSEGUROS',
    ProgressRange: {
      Min: 65,
      Max: 85,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'AGREGANDO_EN_GRUPOS',
    ProgressRange: {
      Min: 86,
      Max: 98,
    },
  },
  {
    IdEmpresa: 32940,
    Configuracion: [
      {
        numero_columna: 0,
        id_campo_personalizado: 1130644,
        formato: null,
        id_campo_especial: null,
      },
    ],
    Stats: {
      ProcessIdentifier: 342110405,
      Estado: null,
      Error: null,
      contactosDetectados: 186800,
      contactosImportados: {
        cantidad: 186800,
        detalle: {
          nuevos: 0,
          actualizados: 0,
          nuevos_corregidos: 0,
          actualizados_corregidos: 0,
          reactivados: 2,
          reactivados_corregidos: 0,
          sin_modificar: 186798,
        },
      },
      cantidadDescartados: {
        cantidad: 0,
        detalle: {
          repetidos: 0,
          insuficientes: 0,
          invalidos: 0,
          basuras: 0,
        },
      },
    },
    IdProcess: 342110405,
    Estado: 'IMPORTACION_FINALIZADA',
    ProgressRange: {
      Min: 100,
      Max: 100,
    },
  },
];
