export const PhoneIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7469" id="Group_7469" transform="translate(-0.084)">
        <g data-name="Group 4402" id="Group_4402" transform="translate(4.86 2.538)">
          <g data-name="Group 4402" id="Group_4402-2">
            <g data-name="Icon feather-smartphone" id="Icon_feather-smartphone">
              <path
                d="M8.992,3h7.462a1.492,1.492,0,0,1,1.492,1.492v11.94a1.492,1.492,0,0,1-1.492,1.492H8.992A1.492,1.492,0,0,1,7.5,16.432V4.492A1.492,1.492,0,0,1,8.992,3Z"
                data-name="Traced 3359"
                fill="none"
                id="Traced_3359"
                stroke="#004dbc"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                transform="translate(-7.5 -3)"
              />
              <path
                d="M18,27h0"
                data-name="Traced 3360"
                fill="none"
                id="Traced_3360"
                stroke="#004dbc"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                transform="translate(-12.776 -15.06)"
              />
            </g>
          </g>
        </g>
        <rect
          data-name="Rectangle 4052"
          fill="none"
          height="20"
          id="Rectangle_4052"
          transform="translate(0.084)"
          width="20"
        />
      </g>
    </svg>
  );
};
