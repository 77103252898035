import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { SimpleBadge, Table, TextSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { ITableContactProfileTagsProps } from '@/src/ContactsModule/interfaces/Interfaces';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ContactsProfileTagsTable({
  activityList,
  activityTagsTotalCount,
  changeTableOrder,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
}: ITableContactProfileTagsProps) {
  const { t } = useTranslation();
  const { dateFormatter } = useDate();

  const [tableOrderByList, setTableOrderByList] = useState<
    Array<{ id: string; isAsc: boolean | undefined }>
  >([
    {
      id: 'Tag',
      isAsc: undefined,
    },
    {
      id: 'TagDate',
      isAsc: undefined,
    },
  ]);

  const idColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.id')}</span>
      </Flex>
    ),
    accessor: 'id',
    id: 'id',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };
  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.date')}</span>
        <SortTableArrow
          elementName={'TagDate'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'tagDate',
    id: 'date',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
        </span>
      </Flex>
    ),
  };
  const tagColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.tag')}</span>
        <SortTableArrow
          elementName={'Tag'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'tag',
    id: 'tag',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <SimpleBadge text={value} />
      </Flex>
    ),
  };
  const sourceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.source')}</span>
      </Flex>
    ),
    accessor: 'source',
    id: 'source',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };
  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.status')}</span>
      </Flex>
    ),
    accessor: 'status',
    id: 'status',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          {Number(value) === 0
            ? t('PROFILE_CHANNEL_TABLE.valid')
            : t('PROFILE_CHANNEL_TABLE.noValid')}
        </span>
      </Flex>
    ),
  };
  const associationColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_CHANNEL_TABLE.association')}</span>
      </Flex>
    ),
    accessor: 'association',
    id: 'association',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [
    idColumn,
    dateColumn,
    tagColumn,
    sourceColumn,
    statusColumn,
    associationColumn,
  ].filter((x) => x) as Column<object>[];

  return (
    <div className="size-full pt-6 text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={activityList}
        emptyScreen={{
          totalCount: activityTagsTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.activity')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityTagsAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityTagsDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyActivityTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
