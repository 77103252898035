import { IContactActivityTags } from '@/src/ContactsModule/interfaces/Interfaces';
/* cspell:disable */

export const ActivityTagsMock: IContactActivityTags[] = [
  {
    id: 5742,
    tagDate: new Date(2018, 11, 24, 10, 33),
    tag: 'emBlue',
    source: 'Website',
    status: 0,
    association: 'Adhesion',
    emailId: 8,
  },
];
