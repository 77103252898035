/**
 * Truncates a given string to a specified number of characters,
 * appending ellipsis (...) at the end if the original length exceeds it.
 *
 * @param {string} string - The original string.
 * @param {number} quantity - The maximum allowed character count.
 * @returns {string} The truncated string or the original string unchanged.
 */
export const truncateString = (string: string, quantity: number): string => {
  if (string.length > quantity) {
    return string.slice(0, quantity - 1) + '\u2026';
  } else {
    return string;
  }
};
