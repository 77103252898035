import { useTranslation } from 'react-i18next';

import { Button, Text } from '@/lib/v2/components';

interface SuspendContactModalProps {
  onClick: () => void;
  isLoading?: boolean;
}

export const SuspendContactModal = ({ onClick, isLoading }: SuspendContactModalProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-4" id="suspended-modal-container">
      <Text fontWeight="bold">{t('CONTACT_SUSPEND_MODAL.title')}</Text>
      <Text>{t('CONTACT_SUSPEND_MODAL.description')}</Text>
      <Button destructive id="suspended-button" isLoading={isLoading} onClick={onClick}>
        {t('suspend')}
      </Button>
    </div>
  );
};
