import {
  Context,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react';

import { RestrictedPair } from '@/src/modules/ContactsModule/hooks/useFiltersValidator';
import { useGetListFiltersOptions } from '@/src/modules/ContactsModule/hooks/useGetListFiltersOptions';
import { useSegmentFilters } from '@/src/modules/ContactsModule/hooks/useSegmentFilters';
import { useGetContext } from '@/src/utils/ContextUtils';

import { FiltersType, IOptionFilter } from '@/modules/ContactsModule/types/Segments';

interface StateContext {
  filtersOptionsIsLoading: boolean;
  isLoadingEditData: boolean;
  isLoadingData: boolean;
  isEditPath: boolean;
  numberOfContacts: number;
  filterOptions: IOptionFilter[];
  filters: FiltersType;
  segmentName: string;
  currentFiltersWithRestriction: RestrictedPair[];
}

interface MutationContext {
  setFilterOptions: Dispatch<SetStateAction<IOptionFilter[]>>;
  setFilters: Dispatch<SetStateAction<FiltersType>>;
  setSegmentName: Dispatch<SetStateAction<string>>;
  clearFilters: () => void;
  createSegmentFilters: () => Promise<void>;
  editSegmentFilters: () => Promise<void>;
  filtersIsValid: () => boolean;
  getQuantityTemporaryFilters: () => Promise<void>;
  setAmountContactsFilter: Dispatch<SetStateAction<number>>;
}

interface CreateSegmentProviderProps {
  children: ReactNode;
}

const StateCreateSegmentContext = createContext<StateContext | undefined>(undefined);
const MutationCreateSegmentContext = createContext<MutationContext | undefined>(undefined);

const CreateSegmentProvider = ({ children }: CreateSegmentProviderProps) => {
  const { isLoading, filterOptions, setFilterOptions } = useGetListFiltersOptions();
  const {
    createSegmentFilters,
    numberOfContacts,
    isLoadingData,
    filtersIsValid,
    segmentName,
    setSegmentName,
    isEditPath,
    isLoadingEditData,
    filters,
    setFilters,
    getQuantityTemporaryFilters,
    editSegmentFilters,
    setAmountContactsFilter,
    clearFilters,
    currentFiltersWithRestriction,
  } = useSegmentFilters(isLoading);

  const memoizedMutations: MutationContext = useMemo<MutationContext>(
    () => ({
      setFilterOptions,
      setFilters,
      clearFilters,
      setSegmentName,
      createSegmentFilters,
      filtersIsValid,
      getQuantityTemporaryFilters,
      editSegmentFilters,
      setAmountContactsFilter,
    }),
    [
      createSegmentFilters,
      filtersIsValid,
      getQuantityTemporaryFilters,
      setFilterOptions,
      setFilters,
      clearFilters,
      setSegmentName,
      editSegmentFilters,
      setAmountContactsFilter,
    ]
  );

  const state: StateContext = {
    filtersOptionsIsLoading: isLoading,
    isLoadingEditData,
    isEditPath,
    numberOfContacts,
    isLoadingData,
    filterOptions,
    filters,
    segmentName,
    currentFiltersWithRestriction,
  };

  return (
    <MutationCreateSegmentContext.Provider value={memoizedMutations}>
      <StateCreateSegmentContext.Provider value={state}>
        {children}
      </StateCreateSegmentContext.Provider>
    </MutationCreateSegmentContext.Provider>
  );
};

export const useStateCreateSegmentContext = (): StateContext =>
  useGetContext<StateContext>(StateCreateSegmentContext, 'CreateSegmentProvider');

export const useMutationCreateSegmentContext = (): MutationContext =>
  useGetContext<MutationContext>(MutationCreateSegmentContext, 'CreateSegmentProvider');

export default CreateSegmentProvider;
