import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Modal } from '@/lib/v2/components/Modal';
import { useItemsSmartFinder, useShowSmartFinder } from '@/lib/v2/hooks/SmartFinder';

import { ROLES } from '@/src/application/constants';
import { useUserData } from '@/src/infrastructure/services/useUserData';

import AdminTools from './components/AdminTools';
import SwitchAccount from './components/AdminTools/SwitchAccount';
import ItemsFound from './components/ItemsFound';
import Loading from './components/Lottie/loading.json';
import Navigation from './components/Navigation/Navigation';
import QuickActions from './components/QuickActions';

const SmartFinder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userData] = useUserData();
  const isAdmin = ROLES.SUPER_ADMIN_ADMIN === userData.role;
  const { showSmartFinder, closeSmartFinder, smartFinderView, goToStep1 } = useShowSmartFinder();
  const inputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState<boolean | number>(false);
  const {
    itemsNavigation,
    itemsAdminTools,
    itemsQuickAction,
    itemsGeneralQuickAction,
    searchItem,
    itemsFound,
    textSearch,
    haveResults,
    searchMoreEntity,
    loading,
  } = useItemsSmartFinder({
    navigate,
    onClose: closeSmartFinder,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
  };

  useEffect(() => {
    if (loading && rootRef.current) rootRef.current.scrollTop = 0;
  }, [loading]);

  useEffect(() => {
    if (typeof focus !== 'number' || !rootRef.current) return;

    rootRef.current
      ?.querySelectorAll('button')
      ?.item(focus)
      ?.focus(focus === 0 ? { preventScroll: true } : undefined);

    if (focus === 0) {
      setTimeout(() => {
        if (rootRef.current) rootRef.current.scrollTop = 0;
      }, 100);
    }
  }, [focus]);

  useHotkeys(
    'ArrowUp',
    () => {
      const total = (rootRef.current?.querySelectorAll('button').length ?? 1) - 1;
      if (typeof focus === 'number') setFocus(focus > 0 ? focus - 1 : total);
      else if (rootRef.current) setFocus(0);
    },
    {
      enableOnFormTags: ['input'],
    },
    [focus]
  );

  useHotkeys(
    'ArrowDown',
    () => {
      const total = (rootRef.current?.querySelectorAll('button').length ?? 1) - 1;
      if (typeof focus === 'number' && focus < total) setFocus(focus + 1);
      else setFocus(0);
    },
    {
      enableOnFormTags: ['input'],
    },
    [focus]
  );

  useEffect(() => {
    if (!showSmartFinder) setFocus(false);
  }, [showSmartFinder]);

  return (
    <Modal
      externalClose
      noPadding
      open={showSmartFinder}
      showCloseButton={false}
      onClose={closeSmartFinder}
    >
      <div
        ref={rootRef}
        className={
          'visible-scrollbar relative max-h-96 w-[768px] max-w-full overflow-hidden rounded-lg bg-gray-200 shadow-lg' +
          (!loading ? ' overflow-y-auto' : '')
        }
      >
        <div className="flex flex-col">
          <div className="sticky top-0 border-b border-gray-400 bg-gray-200 px-6 py-4">
            <div className="flex items-center space-x-3">
              <input
                ref={inputRef}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                className="w-full bg-transparent text-gray-700 outline-none placeholder:text-gray-500"
                placeholder="Go to..."
                type="text"
                onChange={(evt) => void searchItem(evt.target.value)}
                onKeyDown={(evt) => {
                  if (evt.key === 'Escape') {
                    closeSmartFinder();
                  }
                }}
              />
            </div>
          </div>
          {smartFinderView !== 'principal' && (
            <button className="m-5 my-2 flex items-center gap-2 text-gray-700" onClick={goToStep1}>
              <IconSvg
                fillColor="gray-dark"
                svgComponent={<ArrowBackIcon height="18px" width="8px" />}
              />{' '}
              {t('SMART_FINDER.back')}
            </button>
          )}

          {smartFinderView === 'principal' && (
            <>
              <QuickActions data={itemsGeneralQuickAction} />
              {haveResults && (
                <ItemsFound
                  data={itemsFound}
                  textSearch={textSearch}
                  onClose={closeSmartFinder}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSearchMore={searchMoreEntity}
                />
              )}
              <Navigation data={itemsNavigation} />
              {isAdmin && <AdminTools data={itemsAdminTools} />}
            </>
          )}

          {smartFinderView === 'create' && <QuickActions data={itemsQuickAction} />}

          {smartFinderView === 'switchAccount' && <SwitchAccount />}
        </div>

        {loading && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-gray-800">
            <Lottie options={defaultOptions} width={150} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SmartFinder;
