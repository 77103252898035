import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
  name?: string;
}

//Exclusive component for react-table
export const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  (
    { indeterminate, ...rest }: Partial<TableToggleAllRowsSelectedProps>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const defaultRef = useRef(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (typeof resolvedRef === 'object' && resolvedRef.current) {
        resolvedRef.current.indeterminate = Boolean(indeterminate);
        resolvedRef.current.checked = Boolean(indeterminate);
        setIsIndeterminate(resolvedRef.current.indeterminate);
      }
    }, [resolvedRef, indeterminate]);

    // TODO: Add real functionality
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      rest.onChange ? rest.onChange({ ...e, target: e.currentTarget }) : undefined;
    };

    return (
      <input ref={resolvedRef} className="h-4 w-4" type="checkbox" onChange={onChange} {...rest} />
    );
  }
);
