export const PreviewIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_4314_10969)">
        <path
          d="M9.99984 13.5C10.9721 13.5 11.7984 13.1597 12.479 12.4792C13.1596 11.7986 13.4998 10.9722 13.4998 10C13.4998 9.02778 13.1596 8.20139 12.479 7.52083C11.7984 6.84028 10.9721 6.5 9.99984 6.5C9.02762 6.5 8.20123 6.84028 7.52067 7.52083C6.84012 8.20139 6.49984 9.02778 6.49984 10C6.49984 10.9722 6.84012 11.7986 7.52067 12.4792C8.20123 13.1597 9.02762 13.5 9.99984 13.5ZM9.99984 12C9.44428 12 8.97206 11.8056 8.58317 11.4167C8.19428 11.0278 7.99984 10.5556 7.99984 10C7.99984 9.44444 8.19428 8.97222 8.58317 8.58333C8.97206 8.19444 9.44428 8 9.99984 8C10.5554 8 11.0276 8.19444 11.4165 8.58333C11.8054 8.97222 11.9998 9.44444 11.9998 10C11.9998 10.5556 11.8054 11.0278 11.4165 11.4167C11.0276 11.8056 10.5554 12 9.99984 12ZM9.99984 16C8.19428 16 6.53456 15.5174 5.02067 14.5521C3.50678 13.5868 2.30539 12.3125 1.4165 10.7292C1.34706 10.6181 1.29845 10.5016 1.27067 10.3798C1.24289 10.258 1.229 10.133 1.229 10.0048C1.229 9.8766 1.24289 9.75 1.27067 9.625C1.29845 9.5 1.34706 9.38194 1.4165 9.27083C2.30539 7.6875 3.50678 6.41319 5.02067 5.44792C6.53456 4.48264 8.19428 4 9.99984 4C11.8054 4 13.4651 4.48264 14.979 5.44792C16.4929 6.41319 17.6943 7.6875 18.5832 9.27083C18.6526 9.38194 18.7012 9.4984 18.729 9.62021C18.7568 9.74201 18.7707 9.86701 18.7707 9.99521C18.7707 10.1234 18.7568 10.25 18.729 10.375C18.7012 10.5 18.6526 10.6181 18.5832 10.7292C17.6943 12.3125 16.4929 13.5868 14.979 14.5521C13.4651 15.5174 11.8054 16 9.99984 16ZM9.99984 14.5C11.5554 14.5 12.9929 14.0972 14.3123 13.2917C15.6318 12.4861 16.6457 11.3889 17.354 10C16.6457 8.61111 15.6318 7.51389 14.3123 6.70833C12.9929 5.90278 11.5554 5.5 9.99984 5.5C8.44428 5.5 7.00678 5.90278 5.68734 6.70833C4.36789 7.51389 3.354 8.61111 2.64567 10C3.354 11.3889 4.36789 12.4861 5.68734 13.2917C7.00678 14.0972 8.44428 14.5 9.99984 14.5Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
