import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { RfmIcon } from '@/lib/v2/icons/general';
import { CardActionProps } from '@/lib/v2/organisms/CardAction/CardAction';

import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import {
  ECommerceSegmentsEnum,
  ECommerceSegmentsV1Name,
  EmblueSegmentInfo,
  ISegmentFilterID,
  ModalSegmentsCardsProps,
  SegmentTypeEnum,
} from '@/modules/ContactsModule/types/Segments';

const getEcommerceGroupIdArray = (
  v1Name: ECommerceSegmentsV1Name,
  eCommerceGroups: IGroup[]
): string[] => {
  const id = eCommerceGroups
    .find((g) => g.groupName.toUpperCase() === v1Name.toUpperCase())
    ?.id.toString();
  return id ? [id] : [];
};

const getCustomEcommerceGroups = (eCommerceGroups: IGroup[]): IGroup[] => {
  // cspell:disable
  const defaultEcommerceSegments: ECommerceSegmentsV1Name[] = [
    'Clientes',
    'Compraron al menos una vez',
    'Compraron en el último mes',
    'Compraron en los últimos 10 días',
    'Nunca Compraron',
    'RFM HIGH',
    'RFM LOW',
    'RFM MED',
  ];
  // cspell:enable
  return eCommerceGroups.filter(
    (g) => !defaultEcommerceSegments.includes(g.groupName as ECommerceSegmentsV1Name)
  );
};

const getEcommerceFilter = (ecommerceGroupIds: string[]): ISegmentFilterID[] => {
  return [
    {
      dataType: 'ID',
      condition: 'equal',
      operator: 'or',
      field: {
        source: 'relation',
        name: 'ecommerceGroups',
      },
      values: ecommerceGroupIds,
    },
  ];
};

const getCustomEcommerceCard = (
  eCommerceGroup: IGroup,
  exists: boolean
): ModalSegmentsCardsProps => {
  return {
    segmentNumber: undefined,
    created: exists,
    filters: getEcommerceFilter([eCommerceGroup.id.toString()]),
  };
};

type EcommerceSegmentsCardsProps = {
  createSegment: (
    name: string,
    segment: ModalSegmentsCardsProps,
    segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce
  ) => Promise<void>;
  emblueSegmentsInUse?: EmblueSegmentInfo[];
};

export const useEcommerceSegmentsCards = ({
  createSegment,
  emblueSegmentsInUse,
}: EcommerceSegmentsCardsProps) => {
  const { t } = useTranslation();

  const [eCommerceGroups] = useEmblue(ServiceMethods.getECommerceGroups);

  const eCommerceFilters: ModalSegmentsCardsProps[] = useMemo(
    () =>
      !eCommerceGroups?.length
        ? []
        : [
            {} as never, // This allows access to each index by its value in enum ECommerceSegmentsEnum
            {
              segmentNumber: ECommerceSegmentsEnum.rfmHigh,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.rfmHigh
              ),
              filters: getEcommerceFilter(getEcommerceGroupIdArray('RFM HIGH', eCommerceGroups)),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.rfmMedium,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.rfmMedium
              ),
              filters: getEcommerceFilter(getEcommerceGroupIdArray('RFM MED', eCommerceGroups)),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.rfmLow,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.rfmLow
              ),
              filters: getEcommerceFilter(getEcommerceGroupIdArray('RFM LOW', eCommerceGroups)),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.clients,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.clients
              ),
              // cspell:disable-next
              filters: getEcommerceFilter(getEcommerceGroupIdArray('Clientes', eCommerceGroups)),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.purchasedAtLeast1Time,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.purchasedAtLeast1Time
              ),
              filters: getEcommerceFilter(
                // cspell:disable-next
                getEcommerceGroupIdArray('Compraron al menos una vez', eCommerceGroups)
              ),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.purchasedOnlyAMonthAgo,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.purchasedOnlyAMonthAgo
              ),
              filters: getEcommerceFilter(
                // cspell:disable-next
                getEcommerceGroupIdArray('Compraron en el último mes', eCommerceGroups)
              ),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.tenDaysSinceLastPurchase,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.tenDaysSinceLastPurchase
              ),
              filters: getEcommerceFilter(
                // cspell:disable-next
                getEcommerceGroupIdArray('Compraron en los últimos 10 días', eCommerceGroups)
              ),
            },
            {
              segmentNumber: ECommerceSegmentsEnum.neverPurchased,
              created: !!emblueSegmentsInUse?.some(
                (s) =>
                  s.segmentType === SegmentTypeEnum.eCommerce &&
                  s.segmentNumber === ECommerceSegmentsEnum.neverPurchased
              ),
              filters: getEcommerceFilter(
                // cspell:disable-next
                getEcommerceGroupIdArray('Nunca Compraron', eCommerceGroups)
              ),
            },
          ],
    [emblueSegmentsInUse, eCommerceGroups]
  );

  const eCommerceCards: CardActionProps[] = useMemo(
    () =>
      !eCommerceFilters.length
        ? []
        : ([
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmHigh.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmHigh.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.rfmHigh].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmHigh.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.rfmHigh],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.rfmHigh].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmHigh.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmMedium.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmMedium.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.rfmMedium].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmMedium.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.rfmMedium],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.rfmMedium].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmMedium.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmLow.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmLow.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.rfmLow].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmLow.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.rfmLow],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.rfmLow].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.RfmLow.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.clients.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.clients.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.clients].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.clients.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.clients],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.clients].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.clients.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedAtLeast1Time.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedAtLeast1Time.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.purchasedAtLeast1Time].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedAtLeast1Time.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.purchasedAtLeast1Time],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.purchasedAtLeast1Time].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedAtLeast1Time.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedOnlyAMonthAgo.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedOnlyAMonthAgo.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.purchasedOnlyAMonthAgo].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedOnlyAMonthAgo.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.purchasedOnlyAMonthAgo],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.purchasedOnlyAMonthAgo].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.purchasedOnlyAMonthAgo.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.tenDaysSinceLastPurchase.title'),
              description: t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.tenDaysSinceLastPurchase.description'
              ),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.tenDaysSinceLastPurchase].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.tenDaysSinceLastPurchase.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.tenDaysSinceLastPurchase],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.tenDaysSinceLastPurchase].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.tenDaysSinceLastPurchase.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
            {
              title: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.neverPurchased.title'),
              description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.neverPurchased.description'),
              buttonText: eCommerceFilters[ECommerceSegmentsEnum.neverPurchased].created
                ? t('EMBLUE_SEGMENTS.created')
                : t('EMBLUE_SEGMENTS.create'),
              onClickAction: () => {
                void createSegment(
                  t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.neverPurchased.title'),
                  eCommerceFilters[ECommerceSegmentsEnum.neverPurchased],
                  SegmentTypeEnum.eCommerce
                );
              },
              icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
              badgeText: t('SEGMENTS_CARDS.ecommerce'),
              badgeColor: 'sleepy',
              disabled: eCommerceFilters[ECommerceSegmentsEnum.neverPurchased].created,
              btnActionId: `${t(
                'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.neverPurchased.title'
              )}--recipe_id`.replaceAll(' ', '_'),
            },
          ] as CardActionProps[]),
    [t, eCommerceFilters, createSegment]
  );

  const getCustomEcommerceCards = useCallback(
    (eCommerceGroups: IGroup[]) => {
      const customGroups = getCustomEcommerceGroups(eCommerceGroups);
      const cards: CardActionProps[] = customGroups.map((g) => {
        const tagAssociated = g.groupName.split(':')[1];
        const name = `${t(
          'EMBLUE_SEGMENTS.CARDS.ECOMMERCE.customSegments.title'
        )}:${tagAssociated}`;
        const exists = !!emblueSegmentsInUse?.some(
          (s) =>
            s.segmentType === SegmentTypeEnum.eCommerce && s.name.split(':')[1] === tagAssociated
        );
        return {
          title: name,
          description: t('EMBLUE_SEGMENTS.CARDS.ECOMMERCE.customSegments.description'),
          buttonText: exists ? t('EMBLUE_SEGMENTS.created') : t('EMBLUE_SEGMENTS.create'),
          onClickAction: () => {
            void createSegment(name, getCustomEcommerceCard(g, exists), SegmentTypeEnum.eCommerce);
          },
          icon: <IconSvg height="53px" svgComponent={<RfmIcon />} width="53px" />,
          badgeText: t('SEGMENTS_CARDS.ecommerce'),
          badgeColor: 'sleepy',
          disabled: exists,
          btnActionId: `${name}--recipe_id`.replaceAll(' ', '_'),
        };
      });
      return cards ? cards : [];
    },
    [createSegment, emblueSegmentsInUse, t]
  );

  const allCards: CardActionProps[] = useMemo(() => {
    if (!eCommerceGroups?.length) {
      return [];
    }
    const customEcommerceCards = getCustomEcommerceCards(eCommerceGroups);
    return eCommerceCards.concat(customEcommerceCards);
  }, [eCommerceCards, eCommerceGroups, getCustomEcommerceCards]);

  return allCards;
};
