import { format } from '@formkit/tempo';
import { t } from 'i18next';
import { useSetAtom } from 'jotai';
import { groupBy } from 'lodash';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomActionsList,
  atomCalendarLoader,
  atomCalendarLoaderSidebar,
  atomListCalendar,
  atomListCalendarSidebar,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomLoadingProcess,
  atomMoveSuccess,
  atomTotalsProcess,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  ICalendarActionsPayload,
  ICalendarCampaignList,
  ICampaignActionsListPayload,
  IDuplicateCampaignActions,
  IDuplicateCampaignActionsResponse,
  IEditActionParams,
  IEditActionPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EChannelsID,
  IMoveCampaignPayload,
  ISaveV1Message,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

const TRANSLATE_MODAL_DELETE = 'CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.TOAST_MESSAGES';
const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.TOAST_MESSAGES';
const TRANSLATE_MODAL_EDIT = 'CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.TOAST_MESSAGES';
const TRANSLATE_MODAL_MOVE = 'CAMPAIGN_ACTIONS_MAIN.MOVE_ACTIONS.TOAST_MESSAGES';

export const useCampaignActions = () => {
  const navigate = useNavigate();

  const campaignService = useCampaignsService();
  const serviceV1 = useService();

  const setActionsList = useSetAtom(atomActionsList);
  const setTotalsCampaignList = useSetAtom(atomTotalsProcess);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);
  const setMoveSuccess = useSetAtom(atomMoveSuccess);
  const setListCalendarSidebar = useSetAtom(atomListCalendarSidebar);
  const setListCalendar = useSetAtom(atomListCalendar);
  const setCalendarLoader = useSetAtom(atomCalendarLoader);
  const setCalendarLoaderSidebar = useSetAtom(atomCalendarLoaderSidebar);

  const duplicateActionV2 = useFeatureFlag('duplicateAction');

  const [loading, setLoading] = useState<boolean>(false);

  const getList = useCallback(
    async (payload: ICampaignActionsListPayload) => {
      setLoadingProcess(true);

      const response = await campaignService.getActionsByCampaign(payload);

      setActionsList(response?.actions || []);
      setTotalsCampaignList({
        total: response?.total || 0,
        partial: response?.partial || 0,
      });
      setLoadingProcess(false);
    },
    [campaignService, setActionsList, setLoadingProcess, setTotalsCampaignList]
  );

  const editAction = useCallback(
    async ({
      name,
      campaignElementId: actionId,
      originId,
      htmlOriginId,
      showToast = true,
    }: IEditActionParams) => {
      if (!actionId) return;

      setLoading(true);

      const campaignPayload: IEditActionPayload = {
        campaignElementId: Number(actionId),
        name: name.replace(/\s+/g, ' ').trim(),
        ...(originId && { originId }),
        ...(htmlOriginId && { htmlOriginId }),
      };

      const { statusOK } = await campaignService.editAction(campaignPayload);
      setLoading(false);

      if (showToast) {
        toast({
          title: statusOK
            ? t(`${TRANSLATE_MODAL_EDIT}.SUCCESS.title`)
            : t(`${TRANSLATE_MODAL_EDIT}.ERROR.title`),
          body: statusOK
            ? t(`${TRANSLATE_MODAL_EDIT}.SUCCESS.message`)
            : t(`${TRANSLATE_MODAL_EDIT}.ERROR.message`),
          variant: statusOK ? 'success' : 'error',
        });
      }
      return statusOK;
    },
    [campaignService]
  );

  const moveAction = useCallback(
    async (payload: IMoveCampaignPayload) => {
      /** cspell:disable */
      const isSingle: boolean = payload?.elementosId?.length === 1;
      /** cspell:enable */
      setLoading(true);

      const { statusOK } = await serviceV1.moveV1Action(payload);
      setLoading(false);

      if (statusOK) {
        setMoveSuccess(true);
        navigate(`/v2/campaigns/${payload.campaniaId}`);
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_MOVE}.ERROR.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_MOVE}.ERROR.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_MOVE}.ERROR.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_MOVE}.ERROR.MULTIPLE.message`),
        variant: 'error',
      });
    },
    [navigate, serviceV1, setMoveSuccess]
  );

  const deleteAction = useCallback(
    async (actionsId: number[]) => {
      const isSingle: boolean = actionsId?.length === 1;
      setLoading(true);

      const { statusOK } = await serviceV1.deleteV1Actions(actionsId);

      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1]
  );

  const duplicateAction = useCallback(
    async ({ actionName, campaignActionId, actionType }: IDuplicateCampaignActions) => {
      if (!campaignActionId) return;
      setLoading(true);
      const campaignPayload: IDuplicateCampaignActions = {
        campaignActionId: Number(campaignActionId),
        actionName,
        actionType,
      };
      let { statusOK, actionIdDuplicate } = {} as IDuplicateCampaignActionsResponse;

      const actionTypes = [
        EChannelsID.EMAIL,
        EChannelsID.EMAIL_API,
        EChannelsID.EMAIL_TRIGGER,
        EChannelsID.SMS,
        EChannelsID.SMS_API,
        EChannelsID.EMAIL_ATTACHMENT,
      ];

      const isEmailAction = actionTypes.includes(actionType);

      if (duplicateActionV2 && isEmailAction) {
        ({ statusOK, actionIdDuplicate } = await campaignService.duplicateActions(campaignPayload));
      } else {
        ({ statusOK, actionIdDuplicate } = await serviceV1.duplicateV1Actions(campaignPayload));
      }

      setLoading(false);
      toast({
        title: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.title`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.title`),
        body: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.message`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.message`),
        variant: statusOK ? 'success' : 'error',
      });
      return actionIdDuplicate;
    },
    [serviceV1]
  );

  const saveAction = useCallback(
    async ({ actionName, campaignActionId, actionType }: IDuplicateCampaignActions) => {
      if (!campaignActionId) return;
      setLoading(true);
      const campaignPayload: IDuplicateCampaignActions = {
        campaignActionId: Number(campaignActionId),
        actionName,
        actionType,
      };
      const { statusOK, actionIdDuplicate } = await serviceV1.duplicateV1Actions(campaignPayload);
      setLoading(false);
      toast({
        title: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.title`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.title`),
        body: statusOK
          ? t(`${TRANSLATE_MODAL_DUPLICATE}.SUCCESS.message`)
          : t(`${TRANSLATE_MODAL_DUPLICATE}.ERROR.message`),
        variant: statusOK ? 'success' : 'error',
      });
      return actionIdDuplicate;
    },
    [serviceV1]
  );

  const saveV1Message = useCallback(
    async (payload: ISaveV1Message) => {
      const { statusOK } = await serviceV1.saveV1Message(payload);
      return statusOK;
    },
    [serviceV1]
  );

  const getListCalendarSidebar = useCallback(
    async (date: Date) => {
      setCalendarLoaderSidebar(true);

      const dateFormat = format(date, 'YYYY-MM-DD');

      const payload: ICalendarActionsPayload = {
        startDate: dateFormat,
        endDate: dateFormat,
        actionStatus: [2, 23, 18, 3, 4, 8, 9, 17, 7, 6, 5, 12],
      };
      const calendarActions = await campaignService.getCalendarActions(payload);
      const calendarGroupCampaigns = groupBy(
        calendarActions,
        ({ campaignId }) => campaignId
      ) as ICalendarCampaignList;

      setListCalendarSidebar(calendarGroupCampaigns);

      setCalendarLoaderSidebar(false);
      return calendarActions;
    },
    [campaignService, setListCalendarSidebar, setCalendarLoaderSidebar]
  );

  const getListCalendar = useCallback(
    async (payload: ICalendarActionsPayload) => {
      setCalendarLoader(true);

      const calendarActions = await campaignService.getCalendarActions(payload);

      setListCalendar(calendarActions);

      setCalendarLoader(false);
    },
    [campaignService, setCalendarLoader, setListCalendar]
  );

  return {
    getList,
    deleteAction,
    duplicateAction,
    editAction,
    moveAction,
    saveAction,
    saveV1Message,
    getListCalendarSidebar,
    getListCalendar,
    loading,
  };
};
