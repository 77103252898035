import _ from 'lodash';
import { memo, useMemo } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { If } from '@/lib/v2/components/If';
import { Option, Select } from '@/lib/v2/components/Select';

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  maxDate?: Date | null;
  minDate?: Date | null;
  showMonthYearPicker?: boolean;
}

const CustomHeader = ({
  date: dateCurrent,
  changeMonth,
  changeYear,
  maxDate,
  minDate,
  showMonthYearPicker,
}: CustomHeaderProps) => {
  const { t } = useTranslation();

  const months: Option[] = useMemo(
    () => [
      { id: 0, name: t('MONTHS.january') },
      { id: 1, name: t('MONTHS.february') },
      { id: 2, name: t('MONTHS.march') },
      { id: 3, name: t('MONTHS.april') },
      { id: 4, name: t('MONTHS.may') },
      { id: 5, name: t('MONTHS.june') },
      { id: 6, name: t('MONTHS.july') },
      { id: 7, name: t('MONTHS.august') },
      { id: 8, name: t('MONTHS.september') },
      { id: 9, name: t('MONTHS.october') },
      { id: 10, name: t('MONTHS.november') },
      { id: 11, name: t('MONTHS.december') },
    ],
    [t]
  );

  const optionsYears = useMemo(() => {
    return rangeYear(maxDate, minDate);
  }, [maxDate, minDate]);

  const selectedYear = () => {
    return optionsYears.find((year) => year.name === dateCurrent.getFullYear());
  };

  return (
    <div className="flex flex-row gap-1" data-testid="header-date-input-component">
      <If condition={!showMonthYearPicker}>
        <div className="basis-3/5">
          <Select
            classNameMenuContainer="!max-h-44"
            name="month"
            options={months}
            value={months[dateCurrent.getMonth()]}
            onChange={(value: Option | Option[]) => {
              changeMonth(Number((value as Option).id));
            }}
          />
        </div>
      </If>
      <div className={showMonthYearPicker ? 'basis-full' : 'basis-2/5'}>
        <Select
          classNameMenuContainer="!max-h-44"
          name="year"
          options={optionsYears}
          value={selectedYear()}
          onChange={(value: Option | Option[]) => {
            changeYear(Number((value as Option).name));
          }}
        />
      </div>
    </div>
  );
};

const rangeYear = (maxDate?: Date | null, minDate?: Date | null) => {
  const max = maxDate ? maxDate.getFullYear() : new Date().getFullYear();
  const min = minDate ? minDate.getFullYear() : max - 60;
  const years = _.range(min, max + 1, 1);
  const yearsOptions = years.reverse().map((year, index): Option => ({ id: index, name: year }));
  return yearsOptions;
};

export default memo(CustomHeader);
