import {
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTablePagination } from '@/lib/components/Table/useTablePagination';

import { useSearchParamsState } from '@/src/application/hooks/useSearchParamsState';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useGetWidgetsCount } from '@/src/infrastructure/services/useGetWidgetsCount';
import { useNpsContactList } from '@/src/infrastructure/services/useNpsContactList';
import {
  HAPPINESS_FILTERS_INITIAL,
  HAPPINESS_STATUS_INITIAL,
} from '@/src/modules/ContactsModule/screens/HappinessMain/constants';
import {
  NpsContacts,
  NpsHappinessFilters,
  NpsRank,
  NpsStats,
  NpsTotalStats,
} from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { useGetContext } from '@/src/utils/ContextUtils';

interface StateContext {
  happinessFilter: NpsHappinessFilters[];
  isHappinessFilterApplied: boolean;
  npsCount?: number;
  npsRank?: NpsRank;
  npsStats?: NpsStats;
  npsGeneralValue?: number;
  npsTotalStats?: NpsTotalStats;
  npsTotalContactsCount?: number;
  npsContacts?: NpsContacts;
  searchQuery: string;
  searchQueryHandler: string;
  totalContactsBySearch: number;
  pageSize: number;
  isAllResultsSelected: boolean;
  selectedContacts: number[];
  showSelectAllResultsButton: boolean;
  totalPages: number;
  isLoadingContacts: boolean;
  gotoPageIndex: number;
  tableOrderByList: Array<{ id: string; isAsc: boolean | undefined }>;
  lastRefresh: number;
}

interface MutationContext {
  setHappinessFilter: Dispatch<SetStateAction<NpsHappinessFilters[]>>;
  setShowSelectAllResultsButton: Dispatch<SetStateAction<boolean>>;
  setOnSearch: () => void | null;
  handleClearSearchQuery: () => void;
  handleOnChangeSearchQuery: (event: ChangeEvent<HTMLInputElement>) => void;
  resetOnSearch: () => void;
  setIsAllResultsSelected: Dispatch<SetStateAction<boolean>>;
  changeTableOrderBy: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => void;
  setSelectedContacts: Dispatch<SetStateAction<number[]>>;
  setTableOrderByList: Dispatch<SetStateAction<Array<{ id: string; isAsc: boolean | undefined }>>>;
  resetContactList: () => void;
  setLastRefresh: Dispatch<SetStateAction<number>>;
}

interface HappinessProviderProps {
  children: React.ReactElement;
}

const HappinessStateContext = createContext<StateContext | undefined>(undefined);
const HappinessMutationContext = createContext<MutationContext | undefined>(undefined);

const HappinessProvider = ({ children }: HappinessProviderProps) => {
  const [npsStatus] = useState<string>(HAPPINESS_STATUS_INITIAL);
  const [npsCount] = useGetWidgetsCount(npsStatus);
  const [npsRank] = useEmblue(ServiceMethods.getNpsRank, { status: npsStatus });
  const [npsStats] = useEmblue(ServiceMethods.getNpsStats, { status: npsStatus });
  const [npsGeneralValue] = useEmblue(ServiceMethods.getNpsGeneralValue, {
    status: npsStatus,
  });
  const [npsTotalStats] = useEmblue(ServiceMethods.getNpsTotalStats, {
    status: npsStatus,
  });
  const [npsTotalContactsCount] = useEmblue(ServiceMethods.getNpsTotalContactsCount, {
    status: npsStatus,
  });

  /* Happiness Filter */
  const [happinessFilter, setHappinessFilter] =
    useState<NpsHappinessFilters[]>(HAPPINESS_FILTERS_INITIAL);
  const isHappinessFilterApplied = happinessFilter.length !== HAPPINESS_FILTERS_INITIAL.length;
  /* End Happiness Filter */

  /* Search */
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>('filterSearch', '');
  const [totalContactsBySearch, setTotalContactsBySearch] = useState<number>(0);
  const [searchQueryHandler, setSearchQueryHandler] = useState<string>('');

  const handleOnChangeSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchQueryHandler(event.target.value),
    []
  );
  const setOnSearch = useCallback(() => setSearchQuery(searchQueryHandler), [searchQueryHandler]);
  const handleClearSearchQuery = useCallback(() => {
    searchQuery ? setSearchQuery('') : setSearchQueryHandler('');
  }, [searchQuery]);

  useEffect(() => {
    resetPagination();
  }, [searchQuery, happinessFilter]);

  useEffect(() => {
    setSearchQueryHandler(searchQuery);
  }, [searchQuery]);
  /* End search */

  const [lastRefresh, setLastRefresh] = useState<number>(0);

  const { pageSize, pageIndex, totalPages, gotoPageIndex, changeTablePage, resetPagination } =
    useTablePagination(
      totalContactsBySearch !== 0 ? totalContactsBySearch : npsTotalContactsCount?.totalContacts
    );

  /* Table order */
  const [, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('desc');

  const initialStateOrderByList = useMemo(() => [{ id: 'contactName', isAsc: false }], []);
  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const changeTableOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);
  /* End table order */

  /* Reset table */
  const resetContactList = useCallback(() => {
    resetPagination();
    setOrderColumn(undefined);
    setOrderDirection('desc');
    setTableOrderByList(initialStateOrderByList);
    setSearchQuery('');
  }, [initialStateOrderByList, resetPagination, setSearchQuery]);

  const resetOnSearch = useCallback(() => {
    resetContactList();
    setHappinessFilter(HAPPINESS_FILTERS_INITIAL);
  }, [resetContactList]);
  /* End Search*/

  const [npsContacts, , isLoadingContacts] = useNpsContactList({
    npsStatus: npsStatus,
    happiness: happinessFilter,
    search: searchQuery,
    pageNumber: pageIndex,
    orderByName: orderDirection,
    rowsPerPage: pageSize,
  });

  /* Select contacts */
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [isAllResultsSelected, setIsAllResultsSelected] = useState<boolean>(false);
  const [showSelectAllResultsButton, setShowSelectAllResultsButton] = useState<boolean>(false);

  useEffect(() => {
    if (npsContacts) {
      setTotalContactsBySearch(npsContacts.count);
    }
  }, [npsContacts]);
  /* End select contacts */

  const memoizedMutations: MutationContext = useMemo<MutationContext>(
    () => ({
      setHappinessFilter,
      setShowSelectAllResultsButton,
      resetOnSearch,
      handleClearSearchQuery,
      handleOnChangeSearchQuery,
      setOnSearch,
      setIsAllResultsSelected,
      changeTableOrderBy,
      changeTablePage,
      setSelectedContacts,
      setTableOrderByList,
      resetContactList,
      setLastRefresh,
    }),
    [
      resetOnSearch,
      handleClearSearchQuery,
      handleOnChangeSearchQuery,
      setOnSearch,
      changeTableOrderBy,
      changeTablePage,
      resetContactList,
      setLastRefresh,
    ]
  );

  const state: StateContext = useMemo<StateContext>(
    () => ({
      happinessFilter,
      isHappinessFilterApplied,
      npsCount: npsCount?.count,
      npsRank,
      npsStats,
      npsGeneralValue,
      npsTotalStats,
      npsTotalContactsCount: npsTotalContactsCount?.totalContacts,
      searchQuery,
      showSelectAllResultsButton,
      npsContacts,
      selectedContacts,
      pageSize,
      totalContactsBySearch,
      searchQueryHandler,
      isAllResultsSelected,
      totalPages,
      isLoadingContacts,
      gotoPageIndex,
      tableOrderByList,
      lastRefresh,
    }),
    [
      happinessFilter,
      isHappinessFilterApplied,
      npsCount?.count,
      npsRank,
      npsStats,
      npsGeneralValue,
      npsTotalStats,
      npsTotalContactsCount?.totalContacts,
      searchQuery,
      showSelectAllResultsButton,
      npsContacts,
      selectedContacts,
      pageSize,
      totalContactsBySearch,
      searchQueryHandler,
      isAllResultsSelected,
      totalPages,
      isLoadingContacts,
      gotoPageIndex,
      tableOrderByList,
      lastRefresh,
    ]
  );

  return (
    <HappinessMutationContext.Provider value={memoizedMutations}>
      <HappinessStateContext.Provider value={state}>{children}</HappinessStateContext.Provider>
    </HappinessMutationContext.Provider>
  );
};

export const useHappinessMutationContext = (): MutationContext =>
  useGetContext<MutationContext>(HappinessMutationContext, 'HappinessMutationContext');
export const useHappinessStateContext = (): StateContext =>
  useGetContext<StateContext>(HappinessStateContext, 'HappinessStateContext');

export default HappinessProvider;
