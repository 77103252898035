import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/index';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { NpsDetractorIcon } from '@/src/ContactsModule/Icons/NpsDetractorIcon';
import { NpsNoRegIcon } from '@/src/ContactsModule/Icons/NpsNoRegIcon';
import { NpsPassiveIcon } from '@/src/ContactsModule/Icons/NpsPassiveIcon';
import { NpsPromoterIcon } from '@/src/ContactsModule/Icons/NpsPromoterIcon';

export type NpsBadgeProps = {
  npsStatus: string | undefined;
  npsCampaign?: string;
};

export const NpsBadge = ({ npsStatus, npsCampaign }: NpsBadgeProps) => {
  const { t } = useTranslation();

  const currentStatus = useMemo(() => {
    return {
      promoter: {
        title: t('NPS_BADGE.promoter'),
        color: 'bg-emblue-success-light text-emblue-success',
        icon: <IconSvg svgComponent={<NpsPromoterIcon />} />,
      },
      passive: {
        title: t('NPS_BADGE.passive'),
        color: 'bg-emblue-passive-hover text-emblue-passive',
        icon: <IconSvg svgComponent={<NpsPassiveIcon />} />,
      },
      detractor: {
        title: t('NPS_BADGE.detractor'),
        color: 'bg-emblue-alert-hover text-emblue-alert',
        icon: <IconSvg svgComponent={<NpsDetractorIcon />} />,
      },
      noRegistry: {
        title: t('NPS_BADGE.noRegistry'),
        color: 'bg-emblue-secondary text-emblue-subheadings',
        icon: <IconSvg svgComponent={<NpsNoRegIcon />} />,
      },
    };
  }, [t]);

  return npsStatus ? (
    <div
      className={`inline-flex items-center rounded px-4 py-1 text-base font-medium
                ${currentStatus[npsStatus as keyof typeof currentStatus].color}
                `}
      title={npsCampaign && npsCampaign}
    >
      <Flex withGap>
        {currentStatus[npsStatus as keyof typeof currentStatus].icon}
        {currentStatus[npsStatus as keyof typeof currentStatus].title}
      </Flex>
    </div>
  ) : (
    <TextSkeleton size="sm" />
  );
};
