import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import {
  ChangeEvent,
  Fragment,
  isValidElement,
  KeyboardEvent,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '@/lib/v2/components/Button';
import { SizeButton } from '@/lib/v2/components/Button/Button';
import { Checkbox } from '@/lib/v2/components/Checkbox';
import { IconSvg, IconSvgProps } from '@/lib/v2/components/IconSvg';
import { Input } from '@/lib/v2/components/Input';

import DropdownItem, { DropdownItemProps } from './DropdownItem';

export interface DropdownProps {
  id?: string | number;
  dropdownItems: (DropdownItemProps & { checked?: boolean })[];
  label: string;
  disabled?: boolean;
  menuOnTheLeft?: boolean;
  color?: 'standard' | 'primary';
  icon?: JSX.Element;
  fullWidth?: boolean;
  tooltip?: string;
  withSearch?: boolean;
  searchPlaceholder?: string;
  checkBox?: boolean;
  buttonText?: string;
  buttonOnClick?: (checkedItems: DropdownItemProps[]) => void;
  size?: SizeButton;
  iconRight?: ReactElement<IconSvgProps>;
  isScrollable?: boolean;
  className?: string;
}

const Dropdown = ({
  id,
  dropdownItems,
  label,
  disabled = false,
  menuOnTheLeft = false,
  color = 'standard',
  icon,
  fullWidth = false,
  tooltip,
  withSearch,
  searchPlaceholder,
  checkBox = false,
  buttonText,
  buttonOnClick,
  size,
  iconRight,
  isScrollable = false,
  className,
}: DropdownProps) => {
  if (iconRight && (!isValidElement(iconRight) || iconRight.type !== IconSvg)) {
    throw new Error('The "icon" prop must be an IconSvg component v2');
  }

  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounce, setSearchValueDebounce] = useState('');
  const [filteredItems, setFilteredItems] =
    useState<(DropdownItemProps & { checked?: boolean })[]>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const inputSearchRef = useRef<HTMLLinkElement>();

  const classesMenuItems = classNames(
    'absolute z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none p-4',
    {
      'right-0': menuOnTheLeft,
      'py-0': !checkBox && !withSearch,
    }
  );

  const itemsMemo = useMemo(() => {
    return dropdownItems;
  }, [dropdownItems]);

  const handleOnChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const handleOnChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = [...prevCheckedItems];
      if (checked) {
        newCheckedItems.push(id);
        return newCheckedItems;
      } else {
        return newCheckedItems.filter((checkedItem) => checkedItem !== id);
      }
    });
  }, []);

  const handleOnClickButton = useCallback(() => {
    const checkedDropdownItems = itemsMemo.filter((item) => {
      return checkedItems.some((checkedItem) => checkedItem === item.id.toString());
    });

    buttonOnClick?.(checkedDropdownItems);
  }, [buttonOnClick, checkedItems, itemsMemo]);

  const filterItems = useCallback(() => {
    return itemsMemo.filter((item) => {
      return item.label.toLowerCase().includes(searchValueDebounce.toLowerCase());
    });
  }, [itemsMemo, searchValueDebounce]);

  const autoFocusSearch = useCallback(() => {
    inputSearchRef.current?.focus();
  }, []);

  const handleOnLeave = useCallback(() => {
    withSearch && setSearchValue('');
  }, [withSearch]);

  const handleKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValueDebounce(searchValue);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    const filteredItemsResult = filterItems();
    setFilteredItems(filteredItemsResult);
  }, [filterItems, searchValueDebounce]);

  useEffect(() => {
    const itemsChecked: string[] = [];
    dropdownItems.forEach((item) => {
      if (item.checked) itemsChecked.push(item.id.toString() ?? '');
    });
    setCheckedItems(itemsChecked);
  }, [dropdownItems]);

  const isSearch = searchValueDebounce.length > 0;

  const itemsContainerClass = classNames(
    isScrollable ? 'visible-scrollbar overflow-y-scroll overflow-x-hidden max-h-[150px]' : '',
    checkBox ? 'mb-2' : ''
  );

  const iconRightComponent = iconRight || (
    <IconSvg svgComponent={<ChevronDownIcon aria-hidden="true" />} />
  );

  return (
    <Menu
      as="div"
      className={classNames('relative inline-block text-left', { 'w-full': fullWidth })}
      data-testid="dropdown-component"
      id={String(id)}
    >
      <div>
        <Menu.Button as="div" title={tooltip}>
          <Button
            className={className}
            disabled={disabled}
            iconLeft={icon}
            iconRight={iconRightComponent}
            id={`${id}-dropdown-button`}
            outline={color === 'standard'}
            primary={color === 'primary'}
            secondary={color === 'standard'}
            size={size}
            standard={color === 'standard'}
          >
            <>
              {label}
              {checkBox && checkedItems.length !== 0 && <strong>({checkedItems.length})</strong>}
            </>
          </Button>
        </Menu.Button>
      </div>

      <Transition
        afterEnter={autoFocusSearch}
        afterLeave={handleOnLeave}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items static className={classesMenuItems}>
          {withSearch && (
            <Input
              ref={inputSearchRef}
              placeHolder={searchPlaceholder}
              value={searchValue}
              onChange={handleOnChangeSearch}
              onKeyDown={handleKeyEvent}
            />
          )}
          <div className={itemsContainerClass}>
            {(isSearch ? filteredItems : itemsMemo)?.map((dropdownItem) => {
              const { id, label, value } = dropdownItem;

              if (checkBox) {
                const isChecked = checkedItems.some(
                  (checkedItem) => checkedItem === dropdownItem.id.toString()
                );

                return (
                  <div key={id} className="my-2 first:!mt-0">
                    <Checkbox
                      checked={isChecked}
                      id={id.toString()}
                      label={label}
                      value={value}
                      onChange={handleOnChangeCheckbox}
                    />
                  </div>
                );
              }
              return <DropdownItem key={id} {...dropdownItem} />;
            })}
          </div>
          {checkBox && buttonText && (
            <Menu.Item>
              {({ close }) => (
                <div className="flex w-full justify-end">
                  <Button
                    id={`${String(id)}-button`}
                    onClick={() => (handleOnClickButton(), close())}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default memo(Dropdown);
