export const SleepContactIcon = () => {
  return (
    <svg
      data-name="Grupo 6485"
      height="30.494"
      id="Grupo_6485"
      viewBox="0 0 36.75 35.494"
      width="31.75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="9.575"
        cy="9.574"
        data-name="Elipse 1698"
        fill="#9c59df"
        id="Elipse_1698"
        rx="9.575"
        ry="9.574"
        transform="translate(5.632 0)"
      />
      <path
        d="M794.217,527.327h-6.488a.8.8,0,0,1-.724-.472.774.774,0,0,1,.157-.85l4.63-4.63h-4.5a.787.787,0,1,1,0-1.575h6.425a.8.8,0,0,1,.724.472.774.774,0,0,1-.157.85l-4.63,4.63h4.567a.787.787,0,1,1,0,1.575Z"
        data-name="Trazado 3805"
        fill="#9c59df"
        id="Trazado_3805"
        transform="translate(-758.254 -500.399)"
      />
      <path
        d="M778.475,557.969H774a.8.8,0,0,1-.724-.472.773.773,0,0,1,.158-.85l2.771-2.771h-2.52a.787.787,0,1,1,0-1.575h4.441a.8.8,0,0,1,.724.472.774.774,0,0,1-.157.85l-2.772,2.771h2.583a.78.78,0,0,1,.788.787A.807.807,0,0,1,778.475,557.969Z"
        data-name="Trazado 3806"
        fill="#9c59df"
        id="Trazado_3806"
        transform="translate(-748.938 -522.664)"
      />
      <path
        d="M718.387,532.534a3.922,3.922,0,0,1,3.181-6.236h.788a3.91,3.91,0,0,1,1.134-3.086h0a3.879,3.879,0,0,1-1.543-2.551,13.854,13.854,0,0,0-1.8-1.449,1.287,1.287,0,0,0-1.417,0,11.743,11.743,0,0,1-13.512,0,1.231,1.231,0,0,0-1.417,0c-5.323,3.559-6.835,10.456-6.835,10.456s-1.386,5.669,4.221,5.669h16.881A3.945,3.945,0,0,1,718.387,532.534Z"
        data-name="Trazado 3807"
        fill="#9c59df"
        id="Trazado_3807"
        transform="translate(-696.818 -499.842)"
      />
      <path
        d="M778.764,553.523l-2.772,2.771h2.583a.787.787,0,0,1,0,1.575H774.1a.8.8,0,0,1-.724-.472.774.774,0,0,1,.157-.85l2.772-2.771h-2.52a.787.787,0,0,1,0-1.575h2.8a3.94,3.94,0,0,0,1.89.5h.441A.724.724,0,0,1,778.764,553.523Z"
        data-name="Trazado 3808"
        fill="#9c59df"
        id="Trazado_3808"
        transform="translate(-749.006 -522.595)"
      />
      <path
        d="M794.217,527.259h-4.85a3.943,3.943,0,0,0-1.89-.5h-.441a.774.774,0,0,1,.158-.85l4.63-4.63h-4.535a.787.787,0,1,1,0-1.575h6.425a.8.8,0,0,1,.724.472.774.774,0,0,1-.157.85l-4.63,4.63h4.567a.78.78,0,0,1,.787.787A.807.807,0,0,1,794.217,527.259Z"
        data-name="Trazado 3809"
        fill="#9c59df"
        id="Trazado_3809"
        transform="translate(-758.254 -500.331)"
      />
      <g data-name="Grupo 6437" id="Grupo_6437" transform="translate(28.246 19.401)">
        <path
          d="M794.217,527.327h-6.488a.8.8,0,0,1-.724-.472.774.774,0,0,1,.157-.85l4.63-4.63h-4.5a.787.787,0,1,1,0-1.575h6.425a.8.8,0,0,1,.724.472.774.774,0,0,1-.157.85l-4.63,4.63h4.567a.787.787,0,1,1,0,1.575Z"
          data-name="Trazado 3810"
          fill="#9c59df"
          id="Trazado_3810"
          transform="translate(-786.5 -519.8)"
        />
      </g>
      <g data-name="Grupo 6438" id="Grupo_6438" transform="translate(23.962 29.636)">
        <path
          d="M778.475,557.969H774a.8.8,0,0,1-.724-.472.773.773,0,0,1,.158-.85l2.771-2.771h-2.52a.787.787,0,1,1,0-1.575h4.441a.8.8,0,0,1,.724.472.774.774,0,0,1-.157.85l-2.772,2.771h2.583a.78.78,0,0,1,.788.787A.807.807,0,0,1,778.475,557.969Z"
          data-name="Trazado 3811"
          fill="#9c59df"
          id="Trazado_3811"
          transform="translate(-772.9 -552.3)"
        />
      </g>
    </svg>
  );
};
