export const WarningIcon = () => {
  return (
    <svg
      height="27.634"
      viewBox="0 0 27.634 27.634"
      width="27.634"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon_feather-alert-circle" transform="translate(1.5 1.5)">
        <g>
          <path
            d="M27.634,15.317A12.317,12.317,0,1,1,15.317,3,12.317,12.317,0,0,1,27.634,15.317Z"
            fill="none"
            stroke="#ff4261"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            transform="translate(-3 -3)"
          />
          <path
            d="M18,13.5v5.433"
            fill="none"
            stroke="#ff4261"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            transform="translate(-5.683 -6.616)"
          />
          <path
            d="M18,25.5h0"
            fill="none"
            stroke="#ff4261"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            transform="translate(-5.683 -7.75)"
          />
        </g>
      </g>
    </svg>
  );
};
