export const GroupPatternIcon = () => {
  return (
    <svg className="h-full w-full" viewBox="0 0 308.635 218.642" xmlns="http://www.w3.org/2000/svg">
      <g id="Group_7380" transform="translate(-4177.586 -1528.481)">
        <path
          d="M963.742,63.671a63.671,63.671,0,1,0-127.343,0v.706h.177c-.177,4.762,1.058,36.686,54.326,89.422a12.825,12.825,0,0,0,18.164.176l.178-.176c53.265-52.56,54.5-84.483,54.321-89.422h.177v-.706Z"
          fill="#004dbc"
          id="_Trazado_2"
          transform="translate(3341.187 1528.481)"
        />
        <path
          d="M12800.106,11033.785v41.424l52.643-.391"
          fill="none"
          id="Path_4613"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-8511.168 -9389.652)"
        />
        <path
          d="M13178.769,11215.369l23.18-34.416h33.3"
          fill="none"
          id="Path_4614"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-8838.626 -9533.178)"
        />
        <path
          d="M13204.773,11365.746l27.047,18.309"
          fill="none"
          id="Path_4615"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-8865.231 -9679.607)"
        />
        <path
          d="M13431.031,11633.52h20.865s24.807-17.121,33.678-23.035"
          fill="none"
          id="Path_4616"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-9053.472 -9924.666)"
        />
        <path
          d="M13631.187,11214.605l20.738,34.539h16.846"
          fill="none"
          id="Path_4617"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-9233.922 -9568.609)"
        />
        <path
          d="M13653.916,11179.721h27.4l30.926-22.791"
          fill="none"
          id="Path_4618"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-9246.512 -9531.834)"
        />
        <path
          d="M14053.98,11208.168v50.279h-22.543"
          fill="none"
          id="Path_4619"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-9578.896 -9578.965)"
        />
        <path
          d="M13743.021,11648.916l22.619,17.182v39.1h-60.076"
          fill="none"
          id="Path_4620"
          stroke="#111827"
          strokeWidth="3"
          transform="translate(-9291.416 -9963.803)"
        />
        <g id="Group_7372" transform="translate(4316.392 1655.464)">
          <g id="Group_7361" transform="translate(0 0)">
            <g id="Group_7359" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#18c678"
                id="Ellipse_1742"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#18c678"
                id="Path_3933"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="Group_7373" transform="translate(4360.673 1681.427)">
          <g id="Group_7361-2" transform="translate(0 0)">
            <g id="Group_7359-2" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#11cffa"
                id="Ellipse_1742-2"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360-2" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#11cffa"
                id="Path_3933-2"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="Group_7374" transform="translate(4382.206 1619.104)">
          <g id="Group_7361-3" transform="translate(0 0)">
            <g id="Group_7359-3" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#18c678"
                id="Ellipse_1742-3"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360-3" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#18c678"
                id="Path_3933-3"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="Group_7375" transform="translate(4455.078 1598.174)">
          <g id="Group_7361-4" transform="translate(0 0)">
            <g id="Group_7359-4" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#11cffa"
                id="Ellipse_1742-4"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360-4" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#11cffa"
                id="Path_3933-4"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="Group_7376" transform="translate(4424.714 1653.911)">
          <g id="Group_7361-5" transform="translate(0 0)">
            <g id="Group_7359-5" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#18c678"
                id="Ellipse_1742-5"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360-5" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#18c678"
                id="Path_3933-5"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="Group_7377" transform="translate(4394.231 1710.763)">
          <g id="Group_7361-6" transform="translate(0 0)">
            <g id="Group_7359-6" transform="translate(5.699 0)">
              <circle
                cx="9.873"
                cy="9.873"
                fill="#18c678"
                id="Ellipse_1742-6"
                r="9.873"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360-6" transform="translate(0 19.564)">
              <path
                d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                fill="#18c678"
                id="Path_3933-6"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
        <g id="csv-svgrepo-com" transform="translate(4243.322 1528.671)">
          <path
            d="M170.645,160.789l-7.322-82.876H119.1v75.669Z"
            fill="#fff"
            id="Path_4101"
            transform="translate(-119.096 -77.913)"
          />
          <path
            d="M300.125,153.582V103.136L282.109,95.93,274.9,77.913H255.711l.115,82.876Z"
            fill="#e8e6e6"
            id="Path_4102"
            transform="translate(-211.484 -77.913)"
          />
          <path
            d="M314.991,103.136V77.913l25.223,25.223Z"
            fill="#fff"
            id="Path_4103"
            transform="translate(-251.573 -77.913)"
          />
          <rect
            fill="#b3da73"
            height="39.636"
            id="Rectangle_4223"
            transform="translate(0 75.669)"
            width="88.642"
          />
          <g id="Group_7371" transform="translate(18.998 86.719)">
            <path
              d="M193.851,348.823l-2.524,2.989a5.867,5.867,0,0,0-4.312-2.23,5.016,5.016,0,0,0,0,10.02,6.429,6.429,0,0,0,4.312-2.009l2.548,2.695a10.356,10.356,0,0,1-7.08,3.136,8.71,8.71,0,0,1-9.016-8.82c0-4.949,3.944-8.673,9.163-8.673A9.96,9.96,0,0,1,193.851,348.823Z"
              fill="#fff"
              id="Path_4104"
              transform="translate(-177.779 -345.883)"
            />
            <path
              d="M246.166,347.693l-1.642,3.405a12.778,12.778,0,0,0-5.488-1.788c-1.127,0-1.886.417-1.886,1.25,0,2.964,9.065,1.274,9.065,7.472,0,3.43-3.038,5.243-6.86,5.243a12.79,12.79,0,0,1-7.84-2.744l1.69-3.356a10.692,10.692,0,0,0,6.2,2.573c1.372,0,2.229-.514,2.229-1.495,0-3.038-9.065-1.225-9.065-7.3,0-3.161,2.719-5.17,6.836-5.17A12.873,12.873,0,0,1,246.166,347.693Z"
              fill="#fff"
              id="Path_4105"
              transform="translate(-214.119 -345.782)"
            />
            <path
              d="M287.451,363.537l-6.664-17.15h4.655L289.828,359l4.386-12.618H298.7l-6.737,17.15Z"
              fill="#fff"
              id="Path_4106"
              transform="translate(-247.44 -346.191)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
