import { useTranslation } from 'react-i18next';

import { InputSearch } from '@/lib/v2/components';

import { useSearchCampaignsInput } from './useSearchCampaignsInput.hooks';

const SearchCampaignsInput = () => {
  const { t } = useTranslation();
  const {
    events: { handleOnChangeSearch, handleOnClickSearch, handleOnClearSearch },
    state: { keywordSearchCampaigns },
  } = useSearchCampaignsInput();

  return (
    <InputSearch
      standard
      autoComplete="off"
      id="search-campaigns-and-actions"
      placeHolder={t('CAMPAIGNS_MAIN.SEARCH.placeholder')}
      value={keywordSearchCampaigns}
      onAction={handleOnClickSearch}
      onChange={handleOnChangeSearch}
      onClear={handleOnClearSearch}
    />
  );
};

export default SearchCampaignsInput;
