import { addMonth, format, parse } from '@formkit/tempo';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';

import { atomCurrentDateCalendar } from '@/modules/CampaignsModule/atoms/actionsAtom';

export const useCalendar = () => {
  const setCurrentDateCalendar = useSetAtom(atomCurrentDateCalendar);

  const currentDate = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const [currentMonth, setCurrentMonth] = useState(format(currentDate, 'MMM YYYY'));
  const currentMonthDate = parse(currentMonth, 'MMM YYYY');

  const nextMonth = useCallback(() => {
    const nextMonth = addMonth(currentMonthDate, 1);
    setCurrentMonth(format(nextMonth, 'MMM YYYY'));
  }, [currentMonthDate, setCurrentMonth]);

  const previousMonth = useCallback(() => {
    const nextMonth = addMonth(currentMonthDate, -1);
    setCurrentMonth(format(nextMonth, 'MMM YYYY'));
  }, [currentMonthDate, setCurrentMonth]);

  const handleToday = useCallback(() => {
    setCurrentMonth(format(currentDate, 'MMM YYYY'));
    setCurrentDateCalendar(currentDate);
  }, [currentDate, setCurrentDateCalendar]);

  return {
    events: {
      nextMonth,
      previousMonth,
      handleToday,
    },
    state: {
      currentMonth,
      currentDate,
    },
  };
};
