import { useMemo, useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { ISegmentsEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { SegmentTypeEnum } from '@/src/modules/ContactsModule/types/Segments';

import { useSegmentList } from './useSegmentList';

type SegmentTotalsCardKeys =
  | 'segmentsCustomTotal'
  | 'segmentsEcommerceTotal'
  | 'segmentsRecipeTotal';
interface SegmentTotalCard {
  total: number;
  isLoading: boolean;
}
export type SegmentTotalCards = Record<SegmentTotalsCardKeys, SegmentTotalCard>;
interface useSegmentMainProps
  extends Omit<ISegmentsEmblueService, 'orderColumn' | 'orderDirection'> {
  lastRefresh: number;
}

export const useSegmentMain = ({
  identifier = 'SEGMENTS_VIEW',
  pageSize,
  page,
  search,
  segmentTypes,
  lastRefresh,
}: useSegmentMainProps) => {
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('asc');

  const [segmentList, , isLoadingSegments] = useSegmentList({
    identifier,
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection,
    lastRefresh,
    segmentTypes,
  });

  const [segmentsCountOfSearch] = useEmblue(ServiceMethods.getSegmentsCountOfSearch, {
    search,
    lastRefresh,
    segmentTypes,
  });

  const [segmentsCountTotal, , isLoadingSegmentCountTotal] = useEmblue(
    ServiceMethods.getSegmentsCountOfSearch,
    {
      lastRefresh,
      segmentTypes,
    }
  );

  // TO DO: begin return 3 counts (custom, recipe, ecommerce)
  const [segmentsCustomTotal, , isLoadingSegmentsCustomTotal] = useEmblue(
    ServiceMethods.getSegmentsCountOfSearch,
    {
      lastRefresh,
      segmentTypes: [SegmentTypeEnum.custom],
    }
  );

  const [segmentsRecipeTotal, , isLoadingSegmentsRecipeTotal] = useEmblue(
    ServiceMethods.getSegmentsCountOfSearch,
    {
      lastRefresh,
      segmentTypes: [SegmentTypeEnum.emblueRecipe],
    }
  );

  const [segmentsEcommerceTotal, , isLoadingSegmentsEcommerceTotal] = useEmblue(
    ServiceMethods.getSegmentsCountOfSearch,
    {
      lastRefresh,
      segmentTypes: [SegmentTypeEnum.eCommerce],
    }
  );

  const emblueSegmentTotalsCard: SegmentTotalCards = useMemo(() => {
    return {
      segmentsCustomTotal: {
        total: segmentsCustomTotal?.count ?? 0,
        isLoading: isLoadingSegmentsCustomTotal,
      },
      segmentsEcommerceTotal: {
        total: segmentsEcommerceTotal?.count ?? 0,
        isLoading: isLoadingSegmentsRecipeTotal,
      },
      segmentsRecipeTotal: {
        total: segmentsRecipeTotal?.count ?? 0,
        isLoading: isLoadingSegmentsEcommerceTotal,
      },
    };
  }, [
    isLoadingSegmentsCustomTotal,
    isLoadingSegmentsEcommerceTotal,
    isLoadingSegmentsRecipeTotal,
    segmentsCustomTotal?.count,
    segmentsEcommerceTotal?.count,
    segmentsRecipeTotal?.count,
  ]);
  // TO DO: end return 3 counts (custom, recipe, ecommerce)

  return {
    segmentList,
    isLoadingSegments,
    segmentsCountOfSearch,
    segmentsCountTotal,
    isLoadingSegmentCountTotal,
    emblueSegmentTotalsCard,
    setOrderColumn,
    setOrderDirection,
  };
};
