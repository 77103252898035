import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const SummarySection = ({ title, children }: SectionProps) => {
  return (
    <section className="mb-12">
      <Text
        className="sticky top-0 mr-3 border-b border-solid border-emblue-borders bg-emblue-white pb-2"
        color="primary"
        fontWeight="medium"
        variant="sub-headline"
      >
        {title}
      </Text>
      <div className="mt-6">{children}</div>
    </section>
  );
};

export default memo(SummarySection);
