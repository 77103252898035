export const SuspendContactIcon = () => {
  return (
    <svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25,13.25a11,11,0,1,1,11,11A11,11,0,0,1,2.25,13.25ZM9.865,9.019a.846.846,0,0,0-.846.846v6.769a.846.846,0,0,0,.846.846h.846a.846.846,0,0,0,.846-.846V9.865a.846.846,0,0,0-.846-.846Zm5.923,0a.846.846,0,0,0-.846.846v6.769a.846.846,0,0,0,.846.846h.846a.846.846,0,0,0,.846-.846V9.865a.846.846,0,0,0-.846-.846Z"
        data-name="Trazado 3536"
        fill="#004dbc"
        fillRule="evenodd"
        id="Trazado_3536"
        transform="translate(-2.25 -2.25)"
      />
    </svg>
  );
};
