import data from '@emoji-mart/data';
import i18n_en from '@emoji-mart/data/i18n/en.json';
import i18n_es from '@emoji-mart/data/i18n/es.json';
import i18n_pt from '@emoji-mart/data/i18n/pt.json';
import Picker from '@emoji-mart/react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useMemo } from 'react';

import { Button } from '@/lib/v2/components';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { HappyFacePlusIcon } from '@/lib/v2/icons/outline';

import './EmojiPicker.tailwind.css';

export type EmojiPickerLanguage = 'es' | 'pt' | 'en';
export interface I18n {
  search: string;
  search_no_results_1: string;
  search_no_results_2: string;
  pick: string;
  add_custom: string;
  categories: {
    activity: string;
    custom: string;
    flags: string;
    foods: string;
    frequent: string;
    nature: string;
    objects: string;
    people: string;
    places: string;
    search: string;
    symbols: string;
  };
  skins: {
    choose: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
  };
}

export interface IEmojiPickerEvent {
  id: string;
  native: string;
  name: string;
}

export interface EmojiPickerProps {
  id: string;
  onSelectEmoji: (emoji: IEmojiPickerEvent) => void;
  lang: EmojiPickerLanguage;
  disabled?: boolean;
  menuOnTheLeft?: boolean;
}

const languageTranslations: { [key in EmojiPickerLanguage]: I18n } = {
  es: i18n_es,
  pt: i18n_pt,
  en: i18n_en,
};

const EmojiPicker = ({
  id,
  disabled = false,
  lang,
  menuOnTheLeft = false,
  onSelectEmoji,
}: EmojiPickerProps) => {
  const classesContainer = classNames('emoji-picker--container');
  const classesMenuItems = classNames(
    'absolute z-20 mb-4 mt-2 w-auto origin-top-right rounded-md bg-white outline-none',
    { 'right-0': menuOnTheLeft }
  );

  const i18n = useMemo(() => languageTranslations[lang], [lang]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left"
        data-testid="emoji-picker-component"
      >
        <Menu.Button as="div">
          <Button
            outline
            secondary
            standard
            disabled={disabled}
            iconRight={<IconSvg svgComponent={<HappyFacePlusIcon />} />}
            id={`${id}__dropdown-button`}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items static className={classesMenuItems}>
            <div className={classesContainer}>
              <div className="pb-5">
                <Picker
                  data={data}
                  emojiSize={24}
                  height={150}
                  i18n={i18n}
                  id={`${id}__emoji-picker`}
                  previewPosition="none"
                  set="native"
                  theme="light"
                  onEmojiSelect={onSelectEmoji}
                />
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default EmojiPicker;
