import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Option, Text } from '@/lib/v2/components';

import FormDistribute, { FormInput } from './FormDistribute';

interface DistributeModalProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  onSubmit: (name: string, quantity: number) => void;
  isLoading: boolean;
  quantityOptions: Option[];
}

const DistributeModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  quantityOptions,
}: DistributeModalProps) => {
  const { t } = useTranslation();

  const handleSubmitData = useCallback(
    (formData: FormInput) => {
      const {
        names,
        fieldQuantity: { value },
      } = formData;
      onSubmit(names, Number(value));
    },
    [onSubmit]
  );

  return (
    <Modal withAuto open={isOpen} onClose={onClose}>
      <div className="flex w-[400px] flex-col divide-y">
        <Text color="light-black" variant="headline">
          {t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.title')}
        </Text>
        <FormDistribute
          isLoading={isLoading}
          quantityOptions={quantityOptions}
          onClose={onClose}
          onSubmit={handleSubmitData}
        />
      </div>
    </Modal>
  );
};

export default memo(DistributeModal);
