export const EmptyTagIcon = () => {
  return (
    <svg
      height="5"
      viewBox="0 0 24.2 5"
      width="24.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="b">
          <rect height="5" width="24.2" />
        </clipPath>
      </defs>
      <g clipPath="url(#b)" id="a">
        <rect fill="transparent" height="5" width="24.2" />
        <g transform="translate(-334.489 -4567.896)">
          <ellipse
            cx="2.372"
            cy="2.4"
            fill="#2a62ed"
            rx="2.372"
            ry="2.4"
            transform="translate(334.489 4567.896)"
          />
          <ellipse
            cx="2.372"
            cy="2.4"
            fill="#2a62ed"
            rx="2.372"
            ry="2.4"
            transform="translate(344.117 4567.896)"
          />
          <ellipse
            cx="2.372"
            cy="2.4"
            fill="#2a62ed"
            rx="2.372"
            ry="2.4"
            transform="translate(353.745 4567.896)"
          />
        </g>
      </g>
    </svg>
  );
};
