export const EmptyCampaignsIcon = () => {
  return (
    <svg
      fill="none"
      height="131"
      viewBox="0 0 162 131"
      width="162"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.9071 113.81C35.7565 112.796 30.0564 109.962 25.5511 105.678C9.17928 94.2932 4.2701 90.235 0.997314 87.7953V121.934C0.997314 124.091 1.85934 126.16 3.39376 127.685C4.92817 129.21 7.00929 130.067 9.17928 130.067H74.6114C76.7814 130.067 78.8625 129.21 80.3969 127.685C81.9313 126.16 82.7934 124.091 82.7934 121.934V87.7953C79.5206 90.235 74.6114 94.301 58.2553 105.678C53.7521 109.96 48.055 112.794 41.9071 113.81ZM74.6114 65.0333H9.17928C7.00929 65.0333 4.92817 65.8901 3.39376 67.4151C1.85934 68.9402 0.997314 71.0086 0.997314 73.1654L0.997314 77.2314C7.54289 82.1107 6.72469 82.1107 30.4445 99.1803C32.8991 100.807 37.8083 105.686 41.8914 105.686C45.9745 105.686 50.8916 100.807 53.3383 99.9935C77.0581 82.8848 76.2399 82.8848 82.817 78.0368V73.1654C82.817 72.0955 82.6046 71.0361 82.1919 70.0479C81.7792 69.0598 81.1744 68.1623 80.4122 67.4068C79.6499 66.6514 78.7452 66.0529 77.7497 65.6456C76.7543 65.2383 75.6879 65.0302 74.6114 65.0333ZM140.036 40.6605H58.2475C56.0775 40.6605 53.9964 41.5172 52.4619 43.0423C50.9275 44.5674 50.0655 46.6358 50.0655 48.7926V56.9247H74.6114C78.675 56.9285 82.5921 58.4335 85.6017 61.1473C88.6113 63.8611 90.4985 67.5899 90.8967 71.6093L90.9675 71.5546V105.694H140.044C142.214 105.694 144.295 104.837 145.829 103.312C147.363 101.787 148.225 99.7184 148.225 97.5617V48.7769C148.221 46.6215 147.356 44.5559 145.821 43.034C144.285 41.5121 142.204 40.6584 140.036 40.6605ZM131.854 73.1732H115.505V56.9012H131.862L131.854 73.1732ZM41.9071 48.7769C41.9113 44.4687 43.6334 40.3378 46.6963 37.2892C49.7591 34.2407 53.9129 32.5231 58.2475 32.5127H115.498V8.13209C115.498 5.97668 114.637 3.90944 113.104 2.3846C111.571 0.859759 109.492 0.00207251 107.324 0L25.5353 0C23.3653 0 21.2842 0.856772 19.7498 2.38183C18.2154 3.9069 17.3534 5.97533 17.3534 8.13209V56.9012H41.9071V48.7769Z"
        fill="#6694D7"
      />
      <path
        d="M137.263 127.481C148.449 127.481 157.517 118.413 157.517 107.227C157.517 96.0408 148.449 86.9727 137.263 86.9727C126.076 86.9727 117.008 96.0408 117.008 107.227C117.008 118.413 126.076 127.481 137.263 127.481Z"
        fill="white"
      />
      <path
        d="M137.659 84.0015C133.042 84.0015 128.529 85.3705 124.69 87.9355C120.852 90.5006 117.86 94.1463 116.093 98.4118C114.326 102.677 113.864 107.371 114.764 111.899C115.665 116.427 117.888 120.587 121.153 123.851C124.418 127.116 128.577 129.339 133.105 130.24C137.633 131.141 142.327 130.678 146.593 128.911C150.858 127.145 154.504 124.153 157.069 120.314C159.634 116.475 161.003 111.962 161.003 107.345C161.003 104.279 160.4 101.244 159.227 98.4112C158.054 95.5788 156.335 93.0052 154.167 90.8375C151.999 88.6698 149.425 86.9503 146.593 85.7774C143.761 84.6044 140.725 84.001 137.659 84.0015ZM147.816 109.139H139.455V117.502C139.446 117.972 139.252 118.419 138.917 118.748C138.581 119.077 138.13 119.261 137.66 119.261C137.19 119.261 136.739 119.077 136.403 118.748C136.067 118.419 135.874 117.972 135.864 117.502V109.141H127.503C127.033 109.131 126.586 108.938 126.257 108.602C125.928 108.266 125.744 107.815 125.744 107.345C125.744 106.875 125.928 106.424 126.257 106.088C126.586 105.753 127.033 105.559 127.503 105.55H135.863V97.1886C135.873 96.7188 136.066 96.2714 136.402 95.9426C136.738 95.6137 137.189 95.4295 137.659 95.4295C138.129 95.4295 138.58 95.6137 138.916 95.9426C139.251 96.2714 139.445 96.7188 139.455 97.1886V105.549H147.816C148.286 105.559 148.734 105.752 149.062 106.088C149.391 106.423 149.575 106.875 149.575 107.345C149.575 107.814 149.391 108.266 149.062 108.601C148.734 108.937 148.286 109.13 147.816 109.14V109.139Z"
        fill="#FC7E00"
      />
    </svg>
  );
};
