export const SendersIcon = ({ color = '#D9D9D9' }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_247_5472)">
        <path
          d="M13 20.375V18.775C13 18.5417 13.0667 18.3333 13.2 18.15C13.3333 17.9667 13.5167 17.85 13.75 17.8L17 17L13.75 16.2C13.5167 16.15 13.3333 16.0333 13.2 15.85C13.0667 15.6667 13 15.4583 13 15.225V13.625C13 13.2417 13.1583 12.9542 13.475 12.7625C13.7917 12.5708 14.1167 12.5583 14.45 12.725L21.2 16.1C21.5667 16.2833 21.75 16.5833 21.75 17C21.75 17.4167 21.5667 17.7167 21.2 17.9L14.45 21.275C14.1167 21.4417 13.7917 21.4292 13.475 21.2375C13.1583 21.0458 13 20.7583 13 20.375ZM13 22V18L17 17L13 16V12L23 17L13 22ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15.2C3.8 15.2 4.5 14.9458 5.1 14.4375C5.7 13.9292 6 13.2833 6 12.5C6 11.7167 5.7 11.0708 5.1 10.5625C4.5 10.0542 3.8 9.8 3 9.8V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H9C9 3.3 9.24167 2.70833 9.725 2.225C10.2083 1.74167 10.8 1.5 11.5 1.5C12.2 1.5 12.7917 1.74167 13.275 2.225C13.7583 2.70833 14 3.3 14 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V13.25L13.9 10.2C13.2333 9.86667 12.5833 9.89167 11.95 10.275C11.3167 10.6583 11 11.225 11 11.975V18.05C10.3333 18.1833 9.8 18.4958 9.4 18.9875C9 19.4792 8.8 20.15 8.8 21H5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
