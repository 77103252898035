export enum importStatus {
  endConcatenation = 'FIN_CONCATENADO',
  preImportDataAvailable = 'PREIMPORTACION_DATOS_DISPONIBLES',
  preImportError = 'ERROR_PREIMPORTACION',
  loadingSql = 'CARGANDO_SQL',
  fixing = 'CORRIGIENDO',
  importFinish = 'IMPORTACION_FINALIZADA',
  importError = 'ERROR_IMPORTACION',
}

export enum loadingStatus {
  ready = 'ready',
  error = 'error',
  loading = 'loading',
  initial = 'initial',
}

export type TLoadingStatus = 'ready' | 'error' | 'loading';
