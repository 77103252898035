import { useMemo } from 'react';
import Masonry from 'react-layout-masonry';

export const SkeletonMyCatalogTemplates = ({ cards = 8, columns = 6 }) => {
  const data: boolean[] = useMemo(() => new Array<boolean>(cards).fill(false), [cards]);

  const mapper = () => (
    <div className="w-full animate-pulse bg-gray-100 p-4">
      <div className={'mb-2.5  h-[200px] w-full rounded-md bg-gray-300'}></div>
      <div className="h-4 w-full rounded-full bg-gray-300 "></div>
    </div>
  );

  return (
    <Masonry className="w-full" columns={columns}>
      {data.map(mapper)}
    </Masonry>
  );
};
