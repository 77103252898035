export const BackIcon = () => {
  return (
    <svg className="mr-2 h-4 w-4" viewBox="0 0 18.85 16.412">
      <path
        d="M26.859,21.069a6.459,6.459,0,0,1-6.451,6.455H8.795a.786.786,0,0,1,0-1.571H20.409a4.881,4.881,0,1,0,0-9.762H10.988L13.559,18.3a.786.786,0,1,1-1,1.214L8.3,16.011c-.009-.008-.016-.017-.026-.026a.748.748,0,0,1-.058-.059l-.006-.006c-.005-.006-.013-.011-.018-.017v0c-.008-.01-.014-.021-.022-.031a.691.691,0,0,1-.043-.064c0-.005-.007-.01-.01-.015s-.01-.013-.013-.02-.008-.021-.013-.031-.02-.046-.028-.07l0-.007c-.008-.022-.015-.045-.021-.068a.682.682,0,0,1-.013-.071c0-.015-.007-.028-.008-.043s0-.028,0-.042,0-.022,0-.034,0-.023,0-.035,0-.027,0-.041a.3.3,0,0,1,.008-.043c0-.024.008-.048.013-.071a.679.679,0,0,1,.021-.068l0-.007c.009-.024.018-.047.028-.07s.007-.021.013-.031.009-.013.013-.02.007-.01.01-.015a.691.691,0,0,1,.042-.064c.007-.01.013-.022.022-.031v0c.008-.01.018-.016.026-.026a.7.7,0,0,1,.057-.057c.009-.008.016-.018.026-.026l0,0h0l4.264-3.5a.786.786,0,1,1,1,1.214l-2.571,2.114H20.41a6.458,6.458,0,0,1,6.449,6.45Z"
        fill="#004dbc"
        id="back"
        transform="translate(-8.009 -11.112)"
      />
    </svg>
  );
};
