import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Carousel, IconSvg, Text } from '@/lib/v2/components';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { ASSETS_LOGIN } from '@/src/modules/AuthModule/constants';
import { EmblueAcademy } from '@/src/modules/AuthModule/images/icons/EmblueAcademy';
import { EmblueLogo } from '@/src/modules/AuthModule/images/icons/EmblueLogo';

const LoginLayout = () => {
  const { t } = useTranslation();
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const { URL_FILES_APP } = configData.endpoints[env] as { URL_FILES_APP: string };
  /** cspell:disable */
  const language = localStorage.getItem('idioma') ?? window.navigator.language;
  /** cspell:enable */

  const getCurrentImages = useCallback(
    (lang: string = 'en') => {
      let code = lang.substring(0, 2).toLowerCase();
      const available = ['es', 'en', 'pt'];

      if (!available.includes(code)) code = 'en';

      const dir = URL_FILES_APP + ASSETS_LOGIN + code + '/';
      return new Array(6).fill(null).map(function (_, index) {
        const name = `slide_${index + 1}.webp`;
        return dir + name;
      });
    },
    [URL_FILES_APP]
  );

  const currentImages = useMemo(() => getCurrentImages(language), [getCurrentImages, language]);

  const images = useMemo(
    () => [
      {
        id: 1,
        url: currentImages[0],
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.ONE.title'),
        description: t('AUTH.SLIDES.ONE.description'),
        className: 'mb-8',
      },
      {
        id: 2,
        url: currentImages[1],
        redirectUrl: 'https://www.youtube.com/watch?v=TndVvIrywFw',
        title: t('AUTH.SLIDES.TWO.title'),
        description: t('AUTH.SLIDES.TWO.description'),
        className: '',
      },
      {
        id: 3,
        url: currentImages[2],
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.THREE.title'),
        description: t('AUTH.SLIDES.THREE.description'),
        className: '',
      },
      {
        id: 4,
        url: currentImages[3],
        redirectUrl: undefined,
        title: t('AUTH.SLIDES.FOUR.title'),
        description: t('AUTH.SLIDES.FOUR.description'),
        className: '',
      },
      {
        id: 4,
        url: currentImages[4],
        redirectUrl: 'https://academy.embluemail.com/',
        title: t('AUTH.SLIDES.FIVE.title'),
        description: t('AUTH.SLIDES.FIVE.description'),
        className: '',
      },
    ],
    [currentImages, t]
  );

  return (
    <div className="relative w-full bg-emblue-secondary-hover md:h-screen">
      <IconSvg className="ml-6 !h-16 !w-20" svgComponent={<EmblueLogo />} />
      <div className="h-[calc(100%-64px)] min-h-[717px] bg-emblue-secondary-hover px-3 pb-20 sm:px-6 md:px-10 xl:px-32">
        <div className="flex size-full flex-col rounded-lg border shadow-md md:flex-row">
          <div className="h-full rounded-l-lg bg-white px-4 py-10 sm:px-8 md:w-1/2 lg:px-16">
            <Outlet />
          </div>
          <div className="flex h-full flex-col items-center justify-center rounded-r-lg bg-emblue-primary px-8 py-10 md:w-1/2 lg:px-10">
            <Carousel autoplay autoplaySpeed={4000}>
              {images.map((slide) => {
                return (
                  <div key={slide.id}>
                    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
                      <Text
                        as="h1"
                        className="!text-[16px] lg:!text-[24px] 2xl:!text-[30px]"
                        color="tertiary"
                        variant="title-2"
                      >
                        {slide.title}
                      </Text>
                      <div className="flex flex-col">
                        <Text
                          alignment="center"
                          className={classNames(
                            '!text-[12px] lg:!text-[14px] w-[200px] xl:w-[300px] 2xl:text-[16px]',
                            slide.className
                          )}
                          color="white"
                          fontWeight="normal"
                        >
                          {slide.description}
                        </Text>
                        {slide.redirectUrl && (
                          <Text
                            link
                            alignment="center"
                            className="!text-[12px] lg:!text-[14px] 2xl:!text-[16px]"
                            color="white"
                            href={slide.redirectUrl}
                            target="_blank"
                            textDecoration="underline"
                          >
                            {t('AUTH.LOGIN.viewMore')}
                          </Text>
                        )}
                      </div>
                      <a
                        key={slide.id}
                        className="h-auto w-full"
                        href={slide.redirectUrl}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <div key={slide.id} className="h-auto w-full">
                          <img alt="carousel item" className="h-auto w-full" src={slide.url} />
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 flex w-full flex-row items-center justify-center divide-x-2 divide-emblue-primary bg-[#D9D9D9] py-3">
        <div className="px-4">
          <Text
            link
            color="primary"
            href="https://help.embluemail.com/"
            target="_blank"
            variant="text"
          >
            {t('AUTH.LOGIN.help')}
          </Text>
        </div>
        <div className="px-4">
          <Text
            link
            color="primary"
            href="https://help.embluemail.com/primeros-pasos/canales-de-contacto/"
            target="_blank"
            variant="text"
          >
            {t('AUTH.LOGIN.support')}
          </Text>
        </div>
        <div className="px-4">
          <Text link href="https://academy.embluemail.com/" target="_blank">
            <IconSvg height="25px" svgComponent={<EmblueAcademy />} width="159px" />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
