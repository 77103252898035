import classNames from 'classnames';
import { FC, memo, ReactNode } from 'react';

export interface CardFooterProps {
  /** receives any element or string as a child  */
  children: ReactNode;
  /** add padding to section container */
  noPadding?: boolean;
  /** color footer */
  color?: 'transparent' | 'white' | 'gray';
  /** add border top divide */
  withDivide?: boolean;
}

const CardFooter: FC<CardFooterProps> = ({
  children,
  noPadding = false,
  color = 'gray',
  withDivide = false,
}): JSX.Element => {
  const classes = classNames('eb-card-footer', {
    'px-4 py-1': !noPadding,
    [`eb-card--footer-${color}`]: true,
    'border-t': withDivide,
  });

  return <div className={classes}>{children}</div>;
};

export default memo(CardFooter);
