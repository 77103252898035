import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import SignUpSuccessImg from '@/src/modules/AuthModule/images/success-img.png';

const SignUpFinished = () => {
  const { t } = useTranslation();

  return (
    <div className="m-auto mt-10 flex w-full flex-col items-center justify-center gap-4">
      <Text color="tertiary" variant="title-2">
        {t('SignUpFinished.title')}
      </Text>
      <Text alignment="center" color="primary" variant="title-1">
        {t('SignUpFinished.description')}
      </Text>
      <img alt="sign up finished" className="w-2/3" src={SignUpSuccessImg} />
      <Text alignment="center">
        <Text as="span" fontWeight="medium">
          {t('SignUpFinished.sentEmail')}
        </Text>{' '}
        {t('SignUpFinished.checkMailbox')}
      </Text>
    </div>
  );
};

export default memo(SignUpFinished);
