/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IBackOfficeAccountsService, IEmblueApiMethodNameMap } from './IBackOfficeAccountsService';

import { IContractsBillingsPayloadForCSVDownload } from '@/modules/BackofficeModule/interfaces/contracts';
import {
  ChargeSurplusErrors,
  ChargeSurplusResponse,
  ContractsBackofficeBillings,
  IBackOfficeAccountsParamStart,
  IBackOfficeContract,
  IBackOfficeGetAccounts,
  IBackOfficeGetAccountsResponse,
  IExecutive,
  IFilters,
  IPlan,
  SMSPrices,
  Surplus,
  SurplusResult,
} from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';
import { DataResponse } from '@/modules/ContactsModule/types/Segments';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class BackOfficeAccountsService implements IBackOfficeAccountsService {
  private api: APIService;

  private constructor(payload: IBackOfficeAccountsParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error BackOfficeAccountsService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of BackOfficeAccountsService
   */
  static create(payload: IBackOfficeAccountsParamStart): IBackOfficeAccountsService {
    return new BackOfficeAccountsService(payload);
  }

  /**
   * @desc Get all accounts
   */
  async getAccounts(filters: IFilters): Promise<IBackOfficeGetAccountsResponse | null> {
    try {
      const { data } = await this.api.get(`api/v2.1/backoffice/accounts/accounts`, filters);
      const { data: accounts } = data as IBackOfficeGetAccounts;

      return accounts;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getAccounts');
      return null;
    }
  }

  /**
   * @desc Get a contract
   */
  async getContract(id: number): Promise<IBackOfficeContract | null> {
    try {
      const { data } = await this.api.get(`api/v2.1/backoffice/contracts/detail/${id}`);
      return data as IBackOfficeContract;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getContract');
      return null;
    }
  }

  /**
   * @desc Get all executives
   */
  async getExecutives(): Promise<IExecutive[] | null> {
    try {
      const { data } = await this.api.get(`api/v2.1/backoffice/contracts/executives`);

      return data as IExecutive[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getContract');
      return null;
    }
  }

  /**
   * @desc Get all change status
   */
  async changeStatus(contractId: number, statusId: number): Promise<boolean> {
    try {
      const { data } = (await this.api.put(
        `api/v2.1/backoffice/contracts/change_status/${contractId}`,
        {
          statusId,
        }
      )) as { data: { success: boolean } };

      return data.success ? true : false;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
      return false;
    }
  }

  /**
   * @desc Get all plans
   */
  async getPlans(): Promise<IPlan[]> {
    try {
      const { data } = await this.api.get(`api/v2.1/backoffice/contracts/plans`);

      return data as IPlan[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
      return [];
    }
  }

  /**
   * @desc Get all contracts billings
   */
  async getContractsBillings(): Promise<ContractsBackofficeBillings[]> {
    try {
      const { data } = await this.api.get(`api/v2.1/backoffice/contracts/billings`);
      return data as ContractsBackofficeBillings[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
      return [];
    }
  }

  /**
   * @desc Download a csv with the billings. It has a maximum of rows to be downloaded.
   * The maximum is defined in the backend.
   */
  async downloadContractsBillingsCSV(
    payload: IContractsBillingsPayloadForCSVDownload
  ): Promise<void> {
    try {
      const params = { ...payload, responseType: 'blob' };
      const { data } = await this.api.get(
        `api/v2.1/backoffice/contracts/billings/download_csv`,
        params
      );

      const blob = new Blob([data as BlobPart], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      const date = new Date();
      const dateForFilename =
        date.toISOString().replace(/:/g, '-').split('T')[0] +
        '_' +
        date.toISOString().split('T')[1].split('.')[0];

      link.download = dateForFilename + '_contracts_billings.csv';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
    }
  }

  /**
   * @desc Get all sms prices
   */
  async getSmsPrices(): Promise<SMSPrices | null> {
    try {
      const { data } = await this.api.get(`api/v2.1/my_plan/sms_prices`);

      return data as SMSPrices;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getSmsPrices');
      return null;
    }
  }

  /**
   * @desc Create a contract
   */
  async createContract(payload: Omit<IBackOfficeContract, 'id'>): Promise<DataResponse<string>> {
    try {
      const { data } = await this.api.post(`api/v2.1/backoffice/contracts/detail`, payload);

      return { success: data ? true : false, data: 'Contract created' };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createContract');
      if (error instanceof AxiosError) {
        return { success: false, data: error.response?.data?.message };
      }
      return { success: false, data: 'Error, contract not created' };
    }
  }
  /**
   * @desc Update a contract
   */
  async updateContract(payload: IBackOfficeContract): Promise<DataResponse<string>> {
    try {
      const { data } = await this.api.put(`api/v2.1/backoffice/contracts/detail`, payload);

      return { success: data ? true : false, data: 'Contract updated' };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'updateContract');
      if (error instanceof AxiosError) {
        return { success: false, data: error.response?.data?.message };
      }
      return { success: false, data: 'Error, contract not updated' };
    }
  }

  /**
   * @desc Get surplus
   */
  async getSurplus(id: number, monthsAgo: number): Promise<Surplus | null> {
    try {
      const { data } = await this.api.get<Surplus>(
        `api/v2.1/backoffice/contracts/surplus?contractId=${id}&monthsAgo=${monthsAgo}`
      );

      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getSurplus');
      return null;
    }
  }

  /**
   * @desc update prices sms
   * @param countryCode
   * @param price
   */
  async updatePriceSms(countryCode: string, price: number): Promise<boolean> {
    try {
      const { data } = await this.api.patch<{ success: boolean }>(
        `api/v2.1/backoffice/contracts/update_plan_prices`,
        {
          product: 'sms',
          newPrice: price,
          countryCode,
        }
      );

      return data.success;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'updatePriceSms');
      return false;
    }
  }

  /**
   * @desc Charge surplus
   */
  async chargeSurplus(id: number, monthsAgo: number): Promise<ChargeSurplusResponse> {
    try {
      const { data } = await this.api.post<{ surplus: Surplus; warnings?: string[] }>(
        `api/v2.1/backoffice/contracts/surplus/charge`,
        {
          contractId: id,
          monthsAgo,
        }
      );

      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'chargeSurplus');
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 404: {
            return { errorType: error.response?.data?.message as ChargeSurplusErrors };
          }
          case 400: {
            return { errorType: error.response?.data?.message[0] as ChargeSurplusErrors };
          }
        }
      }
      return { errorType: 'UNKNOWN' };
    }
  }

  async getSurplusListBulk(): Promise<Surplus[] | null> {
    try {
      const { data } = await this.api.get<Surplus[]>(
        'api/v2.1/backoffice/contracts/surplus/list_bulk'
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getSurplus');
      return null;
    }
  }
  async chargeSurplusBulk(): Promise<SurplusResult[] | null> {
    try {
      const { data } = await this.api.post<SurplusResult[]>(
        'api/v2.1/backoffice/contracts/surplus/charge_bulk'
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'chargeSurplusBulk');
      return null;
    }
  }
}
export const BackOfficeAccountsServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  BackOfficeAccountsService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof BackOfficeAccountsService);
