export const CloudComputingIcon = () => (
  <svg
    height="78.783"
    id="cloud-computing"
    viewBox="0 0 100.353 78.783"
    width="100.353"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
      >
        <stop offset="0" stopColor="#3c8cff" />
        <stop offset="1" stopColor="#044c8a" />
      </linearGradient>
    </defs>
    <g data-name="Group 2" id="Group_2" transform="translate(0)">
      <g data-name="Group 1" id="Group_1">
        <path
          d="M83.612,81.135A34.493,34.493,0,0,0,15.756,87.03a18.814,18.814,0,0,0,3.136,37.377H34.57v-6.271H18.891a12.543,12.543,0,1,1,0-25.085,3.136,3.136,0,0,0,3.136-3.136,28.221,28.221,0,0,1,55.909-5.487A3.136,3.136,0,0,0,80.6,86.936a15.678,15.678,0,0,1-2.132,31.2H65.927v6.271H78.469a21.95,21.95,0,0,0,5.143-43.272Z"
          data-name="Path 6"
          fill="url(#linear-gradient)"
          id="Path_6"
          transform="translate(0 -55.032)"
        />
        <path
          d="M193.578,249.923l-12.543,12.543,4.421,4.421,7.212-7.181v30.071h6.271V259.706l7.181,7.181,4.421-4.421L198,249.923A3.135,3.135,0,0,0,193.578,249.923Z"
          data-name="Path 7"
          fill="url(#linear-gradient)"
          id="Path_7"
          transform="translate(-145.556 -210.995)"
        />
      </g>
    </g>
  </svg>
);
