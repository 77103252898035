export const AUTH_PATHS = {
  LOGIN_PATH: '/Account/Login',
  RECOVER_PATH: 'recover-password',
  CHANGE_PATH: 'change-password',
  MFA: 'mfa',
  SIGN_UP_PATH: 'sign-up',
};

export const LOGIN_LS = 'login';

export const SOURCE_INBOX = 'inbox';

export const INVALID_ADMINISTRATION_USER_RESPONSE = 'INVALID ADMINISTRATION USER';

export const INVALID_USER_RESPONSE = 'INVALID USER';
export const INVALID_PASSWORD_RESPONSE = 'INVALID PASSWORD';
export const BLOCKED_USER_RESPONSE = 'BLOCKED USER';
export const DISABLED_USER_RESPONSE = 'DISABLED USER';
/** cspell:disable */
export const LOGIN_IN_MAINTENANCE = 'MAINTAINANCE FAILED LOGIN';
export const RECOVER_PASSWORD_OK = 'PEDIDO_CAMBIO_OK';
export const RECOVER_PASSWORD_VERY_QUICK = 'PEDIDO_CAMBIO_MUY_RAPIDO';
export const NON_EXISTING_USER = 'USUARIO_NO_EXISTENTE';
export const BLOCKED_USER = 'USUARIO_BLOQUEADO';
export const DISABLED_USER = 'USUARIO_DESHABILITADO';
export const PASSWORD_CHANGED = 'PASSWORD_CAMBIADA_CORRECTAMENTE';
export const INVALID_TOKEN = 'TOKEN_USUARIO_INCORRECTO';

export const TERMS_URL = 'https://www.embluemail.com/terminos-y-condiciones.html';

export const ASSETS_LOGIN = '/app/images/login/';
