import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { Modal } from '@/lib/v2/components';

import UploadFilePage from '@/src/modules/CampaignsModule/components/HtmlUpload/pages/UploadFilePage';

import { isUploadFilePageModalOpenAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';

export const ModalUpload = () => {
  const [isUploadFileOpen, setIsUploadFileOpen] = useAtom(isUploadFilePageModalOpenAtom);

  const handleOnClose = useCallback(() => {
    setIsUploadFileOpen(false);
  }, [setIsUploadFileOpen]);

  return (
    <Modal id="modal-upload-file" open={isUploadFileOpen} onClose={handleOnClose}>
      <UploadFilePage />
    </Modal>
  );
};
