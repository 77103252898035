import _ from 'lodash';
import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateInput, Input, MultiValueInput, Select } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { Option } from '@/lib/v2/components/Select/Select';

import {
  useMutationCreateSegmentContext,
  useStateCreateSegmentContext,
} from '@/modules/ContactsModule/contexts/CreateSegmentContext';
import {
  FieldType,
  ISegmentFilter,
  ISegmentFilterActivity,
  ISegmentFilterDate,
} from '@/modules/ContactsModule/types/Segments';

interface DynamicFieldComponentProps {
  fieldType: FieldType;
  index: number;
  options?: Option[];
  isBeetWeen: boolean;
  isGreaterThan: boolean;
  isSmallerThan: boolean;
  lastDays: boolean;
  disabled: boolean;
}

const DynamicFieldComponent = ({
  fieldType,
  index,
  options,
  isBeetWeen,
  lastDays,
  isGreaterThan,
  isSmallerThan,
  disabled,
}: DynamicFieldComponentProps) => {
  const { setFilters } = useMutationCreateSegmentContext();
  const { filters } = useStateCreateSegmentContext();

  const { t } = useTranslation();

  const value = useMemo(() => {
    const filter = filters[index] as ISegmentFilter & { values: string[] };
    const values = filter.values;
    if (fieldType === FieldType.SELECT_MULTIPLE) {
      return options?.filter((option) =>
        values?.some((val: string) => option.value?.toString() === val.toString())
      );
    }
    if (fieldType === FieldType.SELECT) {
      if (filters[index].dataType === 'ACTIVITY') {
        const filterSelect = filters[index] as ISegmentFilterActivity;
        const valueSelect = filterSelect.lastDays;
        return options?.find((option) => Number(option.value) === Number(valueSelect));
      } else {
        const filterSelect = filters[index] as ISegmentFilter & { values: string[] };
        const valueSelect = filterSelect.values[0];
        return options?.find((option) => option.value?.toString() === valueSelect?.toString());
      }
    }
    if (fieldType === FieldType.DATE) {
      if (isBeetWeen)
        return [values[0] ? new Date(values[0]) : null, values[1] ? new Date(values[1]) : null];
      if (lastDays) return values[0];
      return values.length === 0 ? undefined : values;
    }
    return values;
  }, [isBeetWeen, fieldType, filters, index, lastDays, options]);

  const handleOnChangeMultiSelectValue = useCallback(
    (val: Option | Option[] | string[]) => {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        if (fieldType === FieldType.TEXT_MULTIPLE) {
          const filter = newFilters[index] as ISegmentFilter & { values: string[] };
          if (isGreaterThan || isSmallerThan) {
            filter.values = (val as string[]).slice(0, 1);
          } else {
            filter.values = val as string[];
          }

          newFilters[index] = filter;
        }
        if (fieldType === FieldType.SELECT_MULTIPLE) {
          const filter = newFilters[index] as ISegmentFilter & { values: string[] };
          filter.values = (val as Option[]).map((v) => v.value?.toString()) as string[];
          newFilters[index] = filter;
        }
        if (fieldType === FieldType.SELECT) {
          if (newFilters[index].dataType === 'ACTIVITY') {
            const filter = newFilters[index] as ISegmentFilterActivity;
            filter.lastDays = Number((val as Option).value);
            newFilters[index] = filter;
          } else {
            const filter = newFilters[index] as ISegmentFilter & { values: string[] };
            filter.values = [(val as Option).value?.toString() as string];
            newFilters[index] = filter;
          }
        }
        return newFilters;
      });
    },
    [setFilters, fieldType, index, isGreaterThan, isSmallerThan]
  );

  const handleOnChange: eventOnChangeDateInput = (e) => {
    if (Array.isArray(e)) {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        const filter = newFilters[index] as ISegmentFilter & { values: string[] };
        const startDate = e[0] ? e[0].toISOString() : '';
        const endDate = e[1] ? e[1].toISOString() : '';
        filter.values[0] = startDate;
        filter.values[1] = endDate;
        newFilters[index] = filter;
        return newFilters;
      });
    }
    if (e instanceof Date) {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        const filter = newFilters[index] as ISegmentFilter & { values: string[] };
        if (isGreaterThan || isSmallerThan) {
          filter.values = [e.toISOString()];
        } else {
          filter.values.push(e.toISOString());
        }
        newFilters[index] = filter;
        return newFilters;
      });
    }
  };

  const handleOnChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => {
      const newFilters = _.cloneDeep(prevFilters);
      const filter = newFilters[index] as ISegmentFilterDate;
      filter.values = [e.target.value];
      newFilters[index] = filter;
      return newFilters;
    });
  };

  const handleOnDeleteDate = (indexDate: number) => {
    const newDates = [...(value as string[])];
    newDates.splice(indexDate, 1);
    setFilters((prevFilters) => {
      const newFilters = _.cloneDeep(prevFilters);
      const filter = newFilters[index] as ISegmentFilterDate;
      filter.values = newDates;
      return newFilters;
    });
  };

  switch (fieldType) {
    case FieldType.SELECT_MULTIPLE:
      return (
        <Select
          multiSelect
          withSearch
          disabled={disabled}
          id="value-multi-select"
          label={t('SEGMENT_FILTERS.Valor')}
          options={options || []}
          orderBy="asc"
          searchPlaceholder={t('SEGMENT_FILTERS.searchValuesPlaceholder')}
          value={value as Option[]}
          onChange={handleOnChangeMultiSelectValue}
        />
      );
    case FieldType.TEXT_MULTIPLE:
      return (
        <MultiValueInput
          disabled={disabled}
          id="value-input"
          isBeetWeen={isBeetWeen}
          label={t('SEGMENT_FILTERS.Valor')}
          type={filters[index].dataType === 'NUMERIC' ? 'number' : 'text'}
          value={value as string[]}
          onChange={handleOnChangeMultiSelectValue}
        />
      );
    case FieldType.SELECT:
      return (
        <Select
          disabled={disabled}
          id="value-select"
          label={t('SEGMENT_FILTERS.Valor')}
          options={options || []}
          orderBy="asc"
          value={value as Option}
          onChange={handleOnChangeMultiSelectValue}
        />
      );

    case FieldType.DATE:
      if (lastDays) {
        return (
          <Input
            disabled={disabled}
            id="value-input-date"
            label={t('SEGMENT_FILTERS.Valor')}
            type="number"
            value={value as string}
            onChange={handleOnChangeInput}
          />
        );
      }
      if (isBeetWeen) {
        const [startDate, endDate] = value as [Date | null, Date | null];
        return (
          <DateInput
            selectsRange
            disabled={disabled}
            endDate={endDate}
            id="value-date-input"
            label={t('SEGMENT_FILTERS.Valor')}
            maxDate={new Date()}
            placeholder="dd/MM/yyyy - dd/MM/yyyy"
            startDate={startDate}
            onChange={handleOnChange}
          />
        );
      } else {
        const datesFormat = value
          ? (value as string[]).map((date) => date.split('T')[0])
          : undefined;

        let date;

        if (datesFormat) {
          const [year, month, day] = datesFormat[0].split('-').map(Number);
          date = new Date(year, month - 1, day);
        }

        return (
          <DateInput
            {...((isGreaterThan || isSmallerThan) && { date })}
            {...(!(isGreaterThan || isSmallerThan) && { dates: datesFormat })}
            disabled={disabled}
            id="value-date-input"
            label={t('SEGMENT_FILTERS.Valor')}
            maxDate={new Date()}
            multiDates={isGreaterThan || isSmallerThan ? false : true}
            onChange={handleOnChange}
            onDelete={handleOnDeleteDate}
          />
        );
      }
    default:
      return null;
  }
};

export default memo(DynamicFieldComponent);
