import { Option } from '@/lib/v2/components/Select/Select';

export enum SelectorEnum {
  date = 'date',
  sex = 'sex',
  country = 'country',
}

export const dateOptions: Option[] = [
  {
    id: 'dd/mm/yyyy',
    name: 'dd/mm/yyyy',
  },
  {
    id: 'yyyy/mm/dd',
    name: 'yyyy/mm/dd',
  },
  {
    id: 'dd-mm-yyyy',
    name: 'dd-mm-yyyy',
  },
  {
    id: 'yyyy-mm-dd',
    name: 'yyyy-mm-dd',
  },
];

/* cspell:disable */
export const sexOptions: Option[] = [
  {
    id: 'M - F',
    name: 'M - F',
    value: 1,
  },
  {
    id: 'Masculino - Femenino',
    name: 'Masculino - Femenino',
    value: 2,
  },
  {
    id: 'H - M',
    name: 'H - M',
    value: 3,
  },
  {
    id: 'Hombre - Mujer',
    name: 'Hombre - Mujer',
    value: 4,
  },
];
/* cspell:enable */

export const countryOptions: Option[] = [
  {
    id: '2d-a',
    name: 'ISO 3166 (2 digits-alpha)',
  },
  {
    id: '3d-a',
    name: 'ISO 3166 (3 digits-alpha)',
  },
  {
    id: '3d-n',
    name: 'ISO 3166 (3 digits-num)',
  },
];
