/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useVersion } from './useVersion';

export type GenericHook<R> = (...args: any[]) => R;
export type GenericHookWithSameParameters<T, R> = (...args: ArgTypes<T>) => R;
type ArgTypes<T> = T extends (...a: infer A) => any ? A : [];

export const useWithCompat = <P extends (...a: any[]) => unknown, R>(
  fn1: GenericHook<R>,
  fn2: GenericHookWithSameParameters<P, R>,
  ...args: ArgTypes<P>
) => {
  let { version } = useVersion();
  let v2apiResult = fn1(...args);
  let v1CompatResult = fn2(...args);

  if (version === 'v2') {
    return v2apiResult;
  } else {
    return v1CompatResult;
  }
};
