import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { atomPlansInformation } from '@/src/modules/MyPlanModule/atoms/ManagePlan';

export const useDisabledPlanButton = () => {
  const plans = useAtomValue(atomPlansInformation);

  const hasNewPlans = useMemo(() => plans?.find((plan) => plan.actualPlan)?.actualPlan, [plans]);

  const isDisabledButton = useCallback(
    (
      planName: PlanType,
      frequency?: 'monthly' | 'annually' | 'quarterly' | 'semiannual' | 'annual',
      actualPlan?: boolean
    ) => {
      return (
        hasNewPlans && planName === 'Advance' && frequency === 'annually' && actualPlan === false
      );
    },
    [hasNewPlans]
  );

  return { isDisabledButton, hasNewPlans };
};
