import { MouseEvent, useCallback, useState } from 'react';

export const useRenameModal = () => {
  const [editNameModalIsOpen, setEditNameModalIsOpen] = useState(false);
  const [position, setPosition] = useState([0, 0]);

  const handleOpenEditNameModal = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const { right, top } = event.currentTarget.getBoundingClientRect();
    setPosition([right, top]);
    setEditNameModalIsOpen(true);
  }, []);

  return {
    position,
    editNameModalIsOpen,
    setEditNameModalIsOpen,
    handleOpenEditNameModal,
  };
};
