export const ContactIcon = () => (
  <svg
    className="h-14 w-14 sm:h-14 sm:w-14 md:h-14 md:w-14 lg:h-16 lg:w-16"
    viewBox="0 0 62.109 59.185"
  >
    <g data-name="Group 3069" id="Group_3069" transform="translate(0 0)">
      <path
        className="fill-blue-600"
        d="M14.112,11.661a11.639,11.639,0,1,1,11.64,11.661,11.65,11.65,0,0,1-11.64-11.661"
        transform="translate(5.302 0)"
      />
      <path
        className="fill-blue-600"
        d="M31.055,19.509A32.361,32.361,0,0,0,.42,41.446,7.915,7.915,0,0,0,7.953,51.855h46.2a7.915,7.915,0,0,0,7.534-10.409A32.361,32.361,0,0,0,31.055,19.509"
        transform="translate(0 7.33)"
      />
    </g>
  </svg>
);

export const ContactImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueBlue/25'}>
    <ContactIcon />
  </div>
);
