export const UpdateIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16.029 16" width="16.029" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7939" id="Group_7939" transform="translate(-157)">
        <rect
          data-name="Rectangle 4347"
          fill="none"
          height="16"
          id="Rectangle_4347"
          transform="translate(157)"
          width="16"
        />
        <g id="update-svgrepo-com" transform="translate(158.032 1.623)">
          <path
            d="M15.536,14.125a6.377,6.377,0,1,1,1.205-3.348m0,0,1.2-1.2m-1.2,1.2-1.2-1.2"
            data-name="Path 4693"
            fill="none"
            id="Path_4693"
            stroke="#364365"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-4 -4)"
          />
          <path
            d="M12,8v3.188l2.391,2.391"
            data-name="Path 4694"
            fill="none"
            id="Path_4694"
            stroke="#364365"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-5.623 -4.812)"
          />
        </g>
      </g>
    </svg>
  );
};
