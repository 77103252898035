import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomContactsDownload } from '@/src/atoms/Contacts';
import { IDownloadContacts } from '@/src/infrastructure/interfaces/IResponses';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { DOWNLOAD_LIMIT_RANGE } from '@/modules/ContactsModule/constants';

interface IProps<T> {
  onDownload: (selected: T) => Promise<void>;
  filename: string;
}

export const useDownloadContacts = <T>(props: IProps<T>) => {
  const { onDownload } = props;
  const { t } = useTranslation();
  const service = useService();
  const [lastSettings, setLastSettings] = useState<IDownloadContacts | undefined>();
  const [show, setShow] = useState(false);
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);
  const [wordingsDownload, setWordingsDownload] = useState({
    title: t('MODAL_CONTACTS_DOWNLOAD.title'),
    reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder'),
    resumeSelected: t('MODAL_CONTACTS_DOWNLOAD.resumeSelected'),
    search: {
      label: t('MODAL_CONTACTS_DOWNLOAD.search_label'),
      placeholder: t('MODAL_CONTACTS_DOWNLOAD.search_placeholder'),
    },
    actions: {
      accept: t('MODAL_CONTACTS_DOWNLOAD.export'),
    },
  });

  const getSettings = useCallback(async () => {
    return await service.getDownloadContacts();
  }, [service]);

  const updateCountDownload = useCallback(async () => {
    return await service.updateDownloadContacts();
  }, [service]);

  const openDownload = useCallback(async () => {
    const settings = await getSettings();

    if (!settings) return;

    if (!settings.canDownload) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.limitExceeded'),
      }));
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        disabled: true,
      }));
    }

    const total = settings.totalDownloadsLastDay;
    const limit = settings.downloadLimit;

    setLastSettings(settings);
    setShow(true);

    if (total >= limit - DOWNLOAD_LIMIT_RANGE) {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: '',
        reminderLimit: t('MODAL_CONTACTS_DOWNLOAD.reminderLimit', {
          limit: limit - total,
        }),
      }));
    } else {
      setWordingsDownload((prevWordingsDownload) => ({
        ...prevWordingsDownload,
        reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder', {
          downloadPerDay: settings.downloadLimit,
        }),
      }));
    }
  }, [getSettings, setContactsDownload, t]);

  const closeDownload = useCallback(() => {
    setWordingsDownload((prevWordingsDownload) => ({
      ...prevWordingsDownload,
      reminder: t('MODAL_CONTACTS_DOWNLOAD.reminder', {
        downloadPerDay: lastSettings?.downloadLimit,
      }),
      reminderLimit: null,
    }));
    setContactsDownload((prevContactDownload) => ({
      ...prevContactDownload,
      disabled: false,
    }));
    setShow(false);
  }, [lastSettings?.downloadLimit, setContactsDownload, t]);

  const handleDownload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (selected: any): Promise<void> => {
      const settings = await getSettings();
      if (!settings || !settings.canDownload) return;
      await onDownload(selected);
      setContactsDownload((prevContactsDownload) => ({
        ...prevContactsDownload,
        show: true,
        file: {
          ...prevContactsDownload.file,
          name: props.filename,
        },
        status: 'loading',
        error: false,
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exporting'),
          action: '',
        },
      }));

      await updateCountDownload();
      closeDownload();
    },
    [
      closeDownload,
      getSettings,
      onDownload,
      props.filename,
      setContactsDownload,
      t,
      updateCountDownload,
    ]
  );

  return {
    showDownload: show,
    settingsDownload: lastSettings,
    contactsDownload,
    wordingsDownload,
    openDownload,
    closeDownload,
    handleDownload,
  };
};
