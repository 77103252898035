import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface ValidatePasswordItemProps {
  isChecked?: boolean;
  text: string;
}

const ValidatePasswordItem = ({ isChecked = false, text }: ValidatePasswordItemProps) => {
  const classesIcon = classNames('h-4 w-4', {
    'text-emblue-success': isChecked,
    'text-emblue-disabled': !isChecked,
  });
  return (
    <div className="flex flex-row items-center gap-1">
      <CheckCircleIcon className={classesIcon} />
      <Text variant="text-sm">{text}</Text>
    </div>
  );
};

export default memo(ValidatePasswordItem);
