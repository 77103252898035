import { MouseEventHandler, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FloatModal } from '@/lib/components/Modal/Modal';
import { Button, Select, Spinner, Text, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Modal } from '@/lib/v2/components/Modal';
import { Option } from '@/lib/v2/components/Select/Select';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

type FormInput = {
  fieldCategory: Option | null;
};
interface IEditCategoryModalProps {
  lastRefreshIncrement?: Function;
  categories: ITag[];
  tags: ITag[];
  onClose: (value?: boolean) => void;
  show: boolean;
}
const ButtonsSection = ({
  onAction,
  onClose,
}: {
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  return (
    <div className="pt-4">
      <Flex withGap alignment="end" gapSize="medium">
        <Button outline onClick={onClose}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_CATEGORY_EDIT_MODAL.close')}
          </div>
        </Button>
        <Button typeButton="submit" onClick={onAction}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_CATEGORY_EDIT_MODAL.confirm')}
          </div>
        </Button>
      </Flex>
    </div>
  );
};
export const EditCategoryModal = ({
  lastRefreshIncrement,
  categories,
  tags,
  onClose,
  show,
}: IEditCategoryModalProps) => {
  const [showSpinnerByAction, setShowSpinnerByAction] = useState<boolean>(false);
  const { t } = useTranslation();
  const service = useService();
  const RULES = useMemo(() => {
    return {
      fieldCategory: {
        required: t('MANAGE_CATEGORY_EDIT_MODAL.emptyField'),
      },
      onAction: async (value: FormInput) => {
        const toCategory = value?.fieldCategory?.id ?? null;
        if (toCategory === null) return false;

        setShowSpinnerByAction(true);

        const payload = {
          toCategory: Number(value?.fieldCategory?.id),
          tags: tags.map((item) => item.id),
        };

        const responseCategory = await service?.updateTagCategory(payload);

        const OkBody = t('MANAGE_CATEGORY_EDIT_MODAL.NOTIFICATION.OkBody');
        const KoBody = t('MANAGE_CATEGORY_EDIT_MODAL.NOTIFICATION.KoBody');
        const unknownError = t('MANAGE_CATEGORY_EDIT_MODAL.NOTIFICATION.KoBody');

        responseCategory.statusOk && lastRefreshIncrement && lastRefreshIncrement();
        setShowSpinnerByAction(false);

        let body = responseCategory.statusOk ? OkBody : unknownError;

        if (responseCategory.errorType.contentError) body = KoBody;

        toast({
          title: responseCategory.statusOk
            ? t('MANAGE_CATEGORY_EDIT_MODAL.NOTIFICATION.OkTitle')
            : t('MANAGE_CATEGORY_EDIT_MODAL.NOTIFICATION.AlertTitle'),
          body: body,
          variant: responseCategory.statusOk ? 'success' : 'error',
        });

        onClose();
      },
    };
  }, [categories, lastRefreshIncrement, service, t]);

  const options =
    categories?.map((item) => {
      return {
        id: item.id,
        name: item.id === 0 ? t('TAGS_TABLE.noCategory') : item.name,
      } as Option;
    }) ?? [];

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      fieldCategory: undefined,
    },
  });

  const { fieldCategory } = watch();
  const onAction = handleSubmit((values: FormInput) => {
    RULES.onAction(values).catch(console.error);
  });

  const handleClose = () => {
    onClose();
  };

  return showSpinnerByAction ? (
    <FloatModal>
      <Spinner withoutOverlay />
    </FloatModal>
  ) : (
    <Modal id="modal-tag-category-edit" open={show} onClose={onClose}>
      <div className="flex w-full flex-col gap-2 divide-y">
        <Text color="light-black" variant="headline">
          {t('MANAGE_CATEGORY_EDIT_MODAL.title')}
        </Text>
        <div className="mb-2 flex w-full flex-col pt-4">
          <Select
            control={control}
            label={t('MANAGE_CATEGORY_EDIT_MODAL.fieldCategory')}
            name="fieldCategory"
            options={options}
            rules={RULES.fieldCategory}
            value={fieldCategory ? fieldCategory : ({ id: -1, name: '' } as Option)}
          />
        </div>
        <div className="flex w-full flex-row items-center justify-end gap-3">
          <ButtonsSection onAction={onAction} onClose={handleClose} />
        </div>
      </div>
    </Modal>
  );
};
