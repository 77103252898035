import { t } from 'i18next';
import { useCallback } from 'react';

import { toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useNpsMutationContext } from '@/src/modules/NPSModule/contexts/NpsContext';

export type RemoveWidgetFunction = (
  widgetId: number[],
  deleteWidget: boolean
) => Promise<{ widgetId: number[]; responseStatus?: string }>;

interface RemoveWidgetResult {
  deleteWidget: RemoveWidgetFunction;
  restoreWidgetFromTrash: RemoveWidgetFunction;
}

type RemoveWidgetType = (accountIdEmblue: string, onsiteToken: string) => RemoveWidgetResult;

export const useRemoveWidget: RemoveWidgetType = (accountIdEmblue, onsiteToken) => {
  const service = useService();
  onsiteToken && service?.setOnsiteToken(onsiteToken);

  const { setIsRecycleBin } = useNpsMutationContext();

  const deleteWidget = useCallback(
    async (widgetsIdsNps: number[], deleteWidget: boolean) => {
      const isSingular = widgetsIdsNps.length === 1;
      const result = await service?.deleteWidget({
        widgetsIdsNps,
        accountIdEmblue,
        deleteWidget,
      });

      if (deleteWidget) {
        let title, body;
        if (result?.status === 'OK') {
          title = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_DELETE.OkTitle')
            : t('NPS_SCREEN.NOTIFICATION_DELETE.OkTitlePlural');
          body = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_DELETE.OkBody')
            : t('NPS_SCREEN.NOTIFICATION_DELETE.OkBodyPlural');
        } else {
          title = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_DELETE.AlertTitle')
            : t('NPS_SCREEN.NOTIFICATION_DELETE.AlertTitlePlural');
          body = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_DELETE.AlertBody')
            : t('NPS_SCREEN.NOTIFICATION_DELETE.AlertBodyPlural');
        }

        toast({
          title: title,
          body: body,
          variant: result?.status === 'OK' ? 'success' : 'error',
        });
      } else {
        let title, body;
        if (result?.status === 'OK') {
          title = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_RECYCLE.OkTitle')
            : t('NPS_SCREEN.NOTIFICATION_RECYCLE.OkTitlePlural');
          body = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_RECYCLE.OkBody')
            : t('NPS_SCREEN.NOTIFICATION_RECYCLE.OkBodyPlural');
        } else {
          title = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_RECYCLE.AlertTitle')
            : t('NPS_SCREEN.NOTIFICATION_RECYCLE.AlertTitlePlural');
          body = isSingular
            ? t('NPS_SCREEN.NOTIFICATION_RECYCLE.AlertBody')
            : t('NPS_SCREEN.NOTIFICATION_RECYCLE.AlertBodyPlural');
        }

        toast({
          title: title,
          body: body,
          variant: result?.status === 'OK' ? 'success' : 'error',
        });
      }

      return {
        widgetId: widgetsIdsNps,
        responseStatus: result?.status,
      };
    },
    [accountIdEmblue, service, setIsRecycleBin]
  );

  const restoreWidgetFromTrash = useCallback(
    async (widgetId: number[]) => {
      const result = await service?.restoreWidget({
        widgetId: widgetId[0],
        accountIdEmblue,
      });
      toast({
        title:
          result?.status === 'OK'
            ? t('NPS_SCREEN.NOTIFICATION_RESTORE.OkTitle')
            : t('NPS_SCREEN.NOTIFICATION_RESTORE.AlertTitle'),
        body:
          result?.status === 'OK'
            ? t('NPS_SCREEN.NOTIFICATION_RESTORE.OkBody')
            : t('NPS_SCREEN.NOTIFICATION_RESTORE.AlertBody'),
        variant: result?.status === 'OK' ? 'success' : 'error',
      });
      result?.status === 'OK' && setIsRecycleBin(false);
      return {
        widgetId,
        responseStatus: result?.status,
      };
    },
    [accountIdEmblue, service, setIsRecycleBin]
  );

  return { deleteWidget, restoreWidgetFromTrash };
};
