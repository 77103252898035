export const RulesTagAssociatedIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M28.7085 19.2478L19.2468 28.7095C18.9468 29.0082 18.6093 29.232 18.2342 29.3811C17.8591 29.5305 17.481 29.6051 17.0998 29.6051C16.7187 29.6051 16.3406 29.5305 15.9655 29.3811C15.5904 29.232 15.2528 29.0082 14.9528 28.7095L3.27384 17.0305C2.9974 16.7543 2.78195 16.4327 2.62751 16.0658C2.47284 15.6992 2.39551 15.3135 2.39551 14.9088V5.42182C2.39551 4.58982 2.69173 3.8776 3.28417 3.28515C3.87662 2.69271 4.58884 2.39648 5.42084 2.39648H14.9078C15.3096 2.39648 15.6988 2.47671 16.0755 2.63715C16.4522 2.79737 16.7786 3.01837 17.0548 3.30015L28.7085 14.9712C29.0123 15.2749 29.2346 15.6148 29.3755 15.9908C29.5164 16.3666 29.5868 16.7448 29.5868 17.1255C29.5868 17.5059 29.5164 17.8802 29.3755 18.2482C29.2346 18.6162 29.0123 18.9494 28.7085 19.2478ZM8.74517 10.7311C9.29606 10.7311 9.76462 10.5384 10.1508 10.1528C10.5371 9.76726 10.7302 9.29904 10.7302 8.74815C10.7302 8.19726 10.5374 7.72871 10.1518 7.34248C9.76629 6.95626 9.29806 6.76315 8.74717 6.76315C8.19629 6.76315 7.72773 6.95593 7.34151 7.34148C6.95529 7.72704 6.76217 8.19526 6.76217 8.74615C6.76217 9.29704 6.95495 9.7656 7.34051 10.1518C7.72606 10.538 8.19429 10.7311 8.74517 10.7311Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
