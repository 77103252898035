import { memo, ReactNode } from 'react';

import { Flex } from '@/lib/v2/components';

interface InboxMainLayoutProps {
  children: ReactNode;
}

const InboxMainLayout = ({ children }: InboxMainLayoutProps) => {
  return (
    <Flex column>
      <div
        className="size-full min-h-[calc(100vh_-_64px)] bg-emblueLightGray"
        id="campaigns-module"
      >
        {children}
      </div>
    </Flex>
  );
};

export default memo(InboxMainLayout);
