import { memo, Suspense } from 'react';

import ModalAssignment from '@/lib/v2/organisms/ModalAssignment';

import { useGroupsByContacts } from '@/src/ContactsModule/hooks/useGroupsByContacts';
import { IGroup, IUpdateItemsToPropsV2 } from '@/src/ContactsModule/interfaces/Interfaces';

interface ContactsGroupsAssignmentModalProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  selectedContacts: number[];
  groups: IGroup[];
  onAssignment: ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => Promise<void>;
  selectAllResults: boolean;
  onLastRefresh?: () => void;
  allContactsCount: number;
  isLoading?: boolean;
}

const ContactsGroupsAssignmentModal = ({
  isOpen,
  onClose,
  selectedContacts,
  groups,
  onAssignment,
  selectAllResults,
  onLastRefresh,
  allContactsCount,
  isLoading,
}: ContactsGroupsAssignmentModalProps) => {
  const { wordings, onApply, handleOnCreate } = useGroupsByContacts({
    selectedContacts,
    selectAllResults,
    onAssignment,
    onLastRefresh,
    allContactsCount,
  });

  const items = groups.map((group) => ({
    id: group.id,
    name: group.groupName,
  }));

  return (
    <Suspense>
      <ModalAssignment
        isLoading={isLoading}
        isOpen={isOpen}
        items={items}
        onClose={onClose}
        onCreate={handleOnCreate}
        wordings={wordings}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onApply={(e, selected, unselected) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onApply({ selectedContacts, selectedItems: selected, unselectedItems: unselected })
        }
      />
    </Suspense>
  );
};

export default memo(ContactsGroupsAssignmentModal);
