import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, CardSection, Flex, Text } from '@/lib/v2/components';
import { CampaignsIcon } from '@/lib/v2/icons/solid';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import IframeTransport from '@/src/compat/iframeTransport';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';

const ActionReport = () => {
  const { campaignId, actionId: id } = useParams();
  const [showSpinnerByAction, setShowSpinnerByAction] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useMessage(EventListenerType.Loaded, (_) => {
    showSpinnerByAction && setIsLoaded(true);
  });

  useEffect(() => {
    const type = 'manual';
    isLoaded &&
      showSpinnerByAction &&
      SendActionsPostMessage('Campaign', 'ViewReportCampaign', { id, type });
  }, [showSpinnerByAction, isLoaded, id]);

  useMessage(EventListenerType.LoadedReport, (_, payload) => {
    if (isLoaded && showSpinnerByAction) {
      // cspell:disable
      setInfoCampaignV1({
        campaign: {
          id: parseInt(campaignId || '0'),
          name: payload?.campaniaNombre ?? '',
        },
        action: {
          id: parseInt(id || '0'),
          name: payload?.nombre ?? '',
        },
      });
      // cspell:enable
      setShowSpinnerByAction(false);
    }
  });

  useEffect(() => {
    SendActionsPostMessage('Campaign', 'VerifyLoaded');
  }, []);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeader
            backButton
            sticky
            icon={<CampaignsIcon />}
            isLoading={showSpinnerByAction}
            titleNode={
              <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
                <Flex withGap>
                  {t('REPORTS')}
                  {' / '}
                  <Text color="primary">{infoCampaignV1?.action?.name}</Text>
                </Flex>
              </Text>
            }
            onBack={() => navigate && navigate(-1)}
          />
          <Flex column withGap gapSize="medium">
            <IframeTransport loading={showSpinnerByAction} />
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  );
};

export default ActionReport;
