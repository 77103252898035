import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Card,
  CardSection,
  Container,
  Flex,
  IconSvg,
  Text,
} from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import {
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomPagerAction,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomDetailCampaign,
  atomHistorySearchPagerCampaign,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomCreateActionsModalIsOpen,
  atomCreateRules,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
  atomToggleEditCreateCampaignModal,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CreateActionsModal } from '@/src/modules/CampaignsModule/components/CampaignActions/CreateActionsModal';
import { HeaderBannerActions } from '@/src/modules/CampaignsModule/components/CampaignActions/HeaderBannerActions';
import { TableContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer';
import { CreateEditDuplicateCampaigns } from '@/src/modules/CampaignsModule/components/CampaignsModal';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import {
  CAMPAIGNS_PATH,
  defaultPager,
  WHATSAPP_PREFIX,
} from '@/src/modules/CampaignsModule/constants';
import { useActionsData } from '@/src/modules/CampaignsModule/hooks/useActionsData';
import { useCampaignModal } from '@/src/modules/CampaignsModule/hooks/useCampaignModal';
import { useFiltersActions } from '@/src/modules/CampaignsModule/hooks/useFiltersActions';
import { useResetAtomOnUnmount } from '@/src/modules/CampaignsModule/hooks/useResetAtomOnUnmount';
import { ERulesActionType } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ModalType, ScreenType } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { ELanguageMap, formattedNumber } from '@/src/modules/CampaignsModule/utils/formattedNumber';

const CampaignActionsMain = () => {
  const [detailCampaign] = useAtom(atomDetailCampaign);
  const { t, i18n } = useTranslation();

  const [actionCreationsModalIsOpen, setActionCreationsModalIsOpen] = useAtom(
    atomCreateActionsModalIsOpen
  );

  const [isMount, setIsMount] = useState(false);
  const filters = useAtomValue(atomFiltersAction);
  const pager = useAtomValue(atomPagerAction);
  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);
  const setCreateRules = useSetAtom(atomCreateRules);

  const { handleOnCloseModal, toggleEditCreateCampaignModal } = useCampaignModal();
  const { resetAtomActionsList } = useResetAtomOnUnmount();
  const { resetFilters } = useFiltersActions();
  const navigate = useNavigate();

  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setScreenTypeProcess = useSetAtom(atomScreenTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);
  const historySearchPagerCampaign = useAtomValue(atomHistorySearchPagerCampaign);

  const { actionsList, loadingProcess, totalsActionsList, filtersCount, isCampaignAutomatic } =
    useActionsData();

  useEffect(() => {
    setIsMount(true);
  }, [isMount]);

  useEffect(() => {
    return () => {
      resetAtomActionsList();
    };
  }, []);

  const handleCreateCampaignAction = () => {
    setCreateRules(ERulesActionType.ALL);
    setActionCreationsModalIsOpen(true);

    setHistorySearchPagerAction({
      filters: { ...filters },
      pager: {
        page: pager?.page || defaultPager.page,
        limit: pager?.limit || defaultPager.limit,
      },
    });
  };

  const handleCloseActionCreationsModal = useCallback(() => {
    setActionCreationsModalIsOpen(false);
  }, [setActionCreationsModalIsOpen]);

  const handleClickEditCampaign = useCallback(() => {
    setModalTypeProcess(ModalType.EDIT);
    setScreenTypeProcess(ScreenType.DETAIL);
    setToggleEditCategoryModal(true);
  }, [setModalTypeProcess, setToggleEditCategoryModal, setScreenTypeProcess]);

  const handleBack = useCallback(() => {
    navigate(CAMPAIGNS_PATH, {
      replace: true,
      state: { from: 'actions', data: { ...historySearchPagerCampaign } },
    });
    resetFilters();
  }, [historySearchPagerCampaign, navigate, resetFilters]);

  const campaignActionsMainPaths = useMemo(() => {
    const paths = [
      {
        id: 'campaigns-path',
        name: t('BREADCRUMB.campaigns'),
        to: {
          path: CAMPAIGNS_PATH,
          params: {
            replace: true,
            state: { from: 'actions', data: { ...historySearchPagerCampaign } },
          },
        },
      },
      {
        id: 'campaigns-name-path',
        name: detailCampaign?.name || '',
      },
    ];

    paths.forEach((path) => {
      if (path.name.toLowerCase().startsWith(WHATSAPP_PREFIX)) {
        path.name = path.name.slice(9);
        path.name = path.name.charAt(0).toUpperCase() + path.name.slice(1);
      }
    });

    return paths;
  }, [detailCampaign?.name, historySearchPagerCampaign, t]);

  return (
    <>
      <Breadcrumb
        isEditable
        customPaths={campaignActionsMainPaths}
        handleOnBackClick={handleBack}
        onEditableClick={handleClickEditCampaign}
      />
      <Container>
        <CreateActionsModal
          isOpen={actionCreationsModalIsOpen}
          onClose={handleCloseActionCreationsModal}
        />
        <Card className="mb-4">
          <HeaderBannerActions />
        </Card>
        <Card>
          <CardSection noPadding>
            <Flex column>
              <SectionHeader
                actionsButtons={
                  !isCampaignAutomatic && (
                    <Button
                      iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                      id="create-campaign-actions-button"
                      size="small"
                      onClick={handleCreateCampaignAction}
                    >
                      {t('CAMPAIGN_ACTIONS_MAIN.button')}
                    </Button>
                  )
                }
                subtitle={
                  <SectionHeaderSubtitle
                    countFilters={filtersCount}
                    countRecordsPartial={totalsActionsList.partial}
                    dataList={actionsList}
                    i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                    loading={loadingProcess}
                  />
                }
                title={t('CAMPAIGN_ACTIONS_MAIN.title')}
                titleDetail={
                  actionsList?.length && !loadingProcess && isMount ? (
                    <Text as="span" color="primary" fontWeight="medium" id="total-count-actions">
                      {`${
                        totalsActionsList.total &&
                        ` (${
                          formattedNumber(
                            totalsActionsList.total,
                            i18n.language as keyof typeof ELanguageMap
                          ) || 0
                        })`
                      }`}
                    </Text>
                  ) : (
                    ' (0)'
                  )
                }
              />
              <Flex column withGap gapSize="medium">
                <TableContainer />
              </Flex>
            </Flex>
          </CardSection>
        </Card>
      </Container>
      {toggleEditCreateCampaignModal && (
        <CreateEditDuplicateCampaigns onClose={handleOnCloseModal} />
      )}
    </>
  );
};

export default CampaignActionsMain;
