export const QuestionIcon = () => {
  return (
    <svg
      data-name="components_3839_15"
      height="14.5"
      id="components_3839_15"
      viewBox="0 0 19.5 19.5"
      width="14.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25,12A9.75,9.75,0,1,1,12,21.75,9.75,9.75,0,0,1,2.25,12ZM13.628,8.083a2.564,2.564,0,0,0-3.255,0,.75.75,0,0,1-.988-1.129,4.061,4.061,0,0,1,5.23,0,3.15,3.15,0,0,1,0,4.842,3.75,3.75,0,0,1-.837.552c-.676.328-1.028.774-1.028,1.152v.75a.75.75,0,0,1-1.5,0V13.5A2.971,2.971,0,0,1,13.125,11a2.28,2.28,0,0,0,.5-.331A1.652,1.652,0,0,0,13.628,8.083ZM12,18a.75.75,0,1,0-.75-.75A.75.75,0,0,0,12,18Z"
        data-name="Trazado 4613"
        fill="#004dbc"
        fillRule="evenodd"
        id="Trazado_4613"
        transform="translate(-2.25 -2.25)"
      />
    </svg>
  );
};
