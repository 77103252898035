import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';

import { atomContentDialogSaveRows } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

interface ILoadModal {
  name: string;
  synced: boolean;
  isEmblueRow: boolean;
}

const useContentDialog = () => {
  const setContentDialogSaveRows = useSetAtom(atomContentDialogSaveRows);

  const loadModal = useCallback((): Promise<ILoadModal> => {
    return new Promise((resolve) => {
      const payload = {
        open: true,
        onSuccess: (name: string, isEmblueRow: boolean) => {
          const data = {
            name,
            isEmblueRow,
            synced: false,
          };
          resolve(data);
        },
        onFail: () => {
          setContentDialogSaveRows(RESET);
          resolve({
            name: '',
            isEmblueRow: false,
            synced: false,
          });
        },
      };

      setContentDialogSaveRows(payload);
    });
  }, [setContentDialogSaveRows]);

  return {
    loadModal,
  };
};

export default useContentDialog;
