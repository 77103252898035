import '@/lib/components/Card/Card.tailwind.css';

export type CircleProps = {
  children?: JSX.Element | string | any;
  size?: number | string;
};

// TODO: Parametrize this element to be more flexible
export const Circle = ({ children, size }: CircleProps) => (
  <div
    className={`
      flex size-32 flex-col justify-center rounded-full bg-white/25 p-4 `}
    style={size ? { width: size, height: size } : {}}
  >
    {children}
  </div>
);
