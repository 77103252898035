import { useCallback } from 'react';

import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';
import { ISmsInfoPayload } from '@/modules/CampaignsModule/interfaces/CampaignActions';
interface ISmsFormEventsProps {
  actionId: number;
}

export const useSmsFormEvents = ({ actionId }: ISmsFormEventsProps) => {
  const { saveInfoSms } = useActionSmsData(Number(actionId));

  const setInfoOnEvent = useCallback(
    (payload: ISmsInfoPayload) => void saveInfoSms(payload),
    [saveInfoSms]
  );

  return { setInfoOnEvent };
};
