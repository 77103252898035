import { IconProps } from './iconPropTypes';

export const ActivityPushNotificationIcon = ({ color }: IconProps) => {
  return (
    <svg height="14" viewBox="0 0 20.125 14" width="20.125" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 4496" id="Group_4496" transform="translate(-673.901 -697.54)">
        <rect
          data-name="Rectangle 2763"
          fill={`${color}`}
          height="14"
          id="Rectangle_2763"
          rx="3"
          transform="translate(673.901 697.54)"
          width="20.125"
        />
        <path
          d="M6.041,4.525l-.772-.7a4.994,4.994,0,0,0-2.224,3.85h1.08A4.067,4.067,0,0,1,6.041,4.525Zm6.688,3.149h1.08a5.024,5.024,0,0,0-2.224-3.85l-.767.7A4.09,4.09,0,0,1,12.729,7.674Zm-1.063.245a3.031,3.031,0,0,0-2.429-3.1V4.486a.813.813,0,0,0-1.619,0v.334a3.022,3.022,0,0,0-2.429,3.1v2.452l-1.08.981v.49h8.637v-.49l-1.08-.981Zm-3.239,5.4a1.037,1.037,0,0,0,.216-.02,1.078,1.078,0,0,0,.777-.579.907.907,0,0,0,.081-.383H7.342A1.042,1.042,0,0,0,8.427,13.315Z"
          data-name="Icon material-notifications-active"
          fill="#fff"
          id="Icon_material-notifications-active"
          transform="translate(675.799 696.008)"
        />
      </g>
    </svg>
  );
};
