export const NpsPassiveIcon = () => {
  return (
    <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.3,11.775A1.125,1.125,0,1,0,10.425,12.9,1.125,1.125,0,0,0,9.3,11.775Zm5.4,0A1.125,1.125,0,1,0,15.825,12.9,1.125,1.125,0,0,0,14.7,11.775ZM12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm0,16.2A7.21,7.21,0,0,1,4.8,12a7.3,7.3,0,0,1,.045-.774A9.056,9.056,0,0,0,9.534,6.393,8.977,8.977,0,0,0,16.878,10.2,8.784,8.784,0,0,0,18.9,9.966,7.189,7.189,0,0,1,12,19.2Z"
        data-name="Icon material-face"
        fill="#ffb114"
        id="Icon_material-face"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
