import cn from 'classnames';

import { DownArrowIcon } from '@/lib/icon';
import { EmptyTagIcon } from '@/lib/icon/EmptyTagIcon';

export type SimpleBadgeProps = {
  text?: string;
  dotted?: boolean;
  plain?: boolean;
  background?: boolean;
  shortened?: number;
  tooltip?: string;
  arrow?: boolean;
};

//TODO: Color per parameter (tag color). (Beta-505)

export const SimpleBadge = ({
  text,
  dotted,
  background,
  plain,
  shortened,
  tooltip,
  arrow,
}: SimpleBadgeProps) => {
  return (
    <span
      className={cn(
        'inline-flex h-5 items-center justify-center rounded-full border border-[#005ADC] px-2.5 py-[1px] text-base text-[#005ADC]',
        {
          'w-12': dotted,
          'border-0': plain,
          'bg-[#DBEAFE]': background,
        }
      )}
      title={(shortened && text && text.length > shortened && tooltip) || ''}
    >
      {dotted ? (
        <EmptyTagIcon />
      ) : shortened ? (
        text && text.length > shortened ? (
          text.slice(0, shortened) + '...'
        ) : (
          text
        )
      ) : (
        text && text
      )}
      {arrow ? <DownArrowIcon /> : null}
    </span>
  );
};
