import { useSearchParams } from 'react-router-dom';

type InputValue = string | string[] | number[];

const transformValueToString = (value: InputValue): string => {
  if (Array.isArray(value)) {
    return value.join(',');
  }

  return value.toString();
};

const transformStringToValue = <T extends InputValue>(value: string, defaultValue: T): T => {
  let parsedValue;

  try {
    parsedValue = JSON.parse(`[${value}]`);
  } catch {
    parsedValue = value.split(',').map((str) => str.trim());
  }

  parsedValue = parsedValue.map((val: string) =>
    typeof defaultValue !== 'number' ? val.toString() : parseFloat(val)
  ) as T;

  // If the expected type is not an array, return only the first element
  if (!(defaultValue instanceof Array)) {
    return parsedValue[0] as T;
  }

  return (Array.isArray(parsedValue) ? parsedValue : [parsedValue]) as T;
};

export function useSearchParamsState<T extends InputValue>(
  searchParamName: string,
  defaultValue: T
): readonly [searchParamsState: T, setSearchParamsState: (newState: T) => void | null] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState: T = acquiredSearchParam
    ? transformStringToValue<T>(acquiredSearchParam, defaultValue)
    : defaultValue;

  const setSearchParamsState = (newState: T) => {
    const stringNewState = transformValueToString(newState);

    if (stringNewState === '') {
      searchParams.delete(searchParamName);
    } else {
      searchParams.set(searchParamName, stringNewState);
    }

    const next = Array.from(searchParams).reduce((o, [key, value]) => ({ ...o, [key]: value }), {});

    setSearchParams(next);
  };

  return [searchParamsState, setSearchParamsState];
}
