export const contractsListDataMock = {
  result: [],
  pager: {
    countPartial: 2,
    countTotal: 2,
    currentPage: 0,
  },
};
export const logsListDataMock = {
  result: [],
  pager: {
    countPartial: 2,
    countTotal: 2,
    currentPage: 0,
  },
};
