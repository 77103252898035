import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, memo, ReactNode, RefObject, useRef } from 'react';

import { IconSvg } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline';

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose?: (value: boolean) => void;
  externalClose?: boolean;
  noPadding?: boolean;
  id?: string;
  /** the width adapts to the width of your container automatically */
  withAuto?: boolean;
  mountTransitionEnd?: (refContainerModal?: RefObject<HTMLDivElement>) => void;
  unMountTransitionEnd?: (refContainerModal?: RefObject<HTMLDivElement>) => void;
  showCloseButton?: boolean;
}

const Modal = ({
  children,
  open,
  onClose,
  externalClose = false,
  noPadding = false,
  id,
  withAuto = false,
  mountTransitionEnd,
  unMountTransitionEnd,
  showCloseButton = true,
}: ModalProps) => {
  const refModal = useRef<HTMLDivElement>(null);

  const classesContainer = classNames(
    'transform rounded-lg bg-white align-middle shadow-xl transition-all',
    { 'p-8': !noPadding },
    { 'w-full max-w-full md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl': !withAuto },
    { 'w-auto': withAuto }
  );

  const classesClose = classNames(
    'absolute z-10 rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emblue-primary focus:ring-offset-2 p-1',
    {
      'right-0 top-0': !noPadding,
      'right-6 top-6': noPadding,
    }
  );

  const handleAfterEnter = () => {
    mountTransitionEnd?.(refModal);
  };

  const handleAfterLeave = () => {
    unMountTransitionEnd?.(refModal);
  };

  return (
    <Transition
      appear
      afterEnter={handleAfterEnter}
      afterLeave={handleAfterLeave}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={open}
    >
      <div className="relative z-[999999]" data-testid="modal-component" id={id}>
        <div className="fixed inset-0 bg-black/25" />

        <div
          aria-hidden
          className="fixed inset-0 z-[999999] overflow-hidden"
          onClick={() => externalClose && onClose?.(false)}
        >
          <div className={'flex min-h-full items-center justify-center p-4'}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                ref={refModal}
                aria-hidden
                className={classesContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="relative flex w-full flex-col">
                  {showCloseButton && (
                    <button className={classesClose} type="button" onClick={() => onClose?.(false)}>
                      <span className="sr-only">Close</span>
                      <IconSvg svgComponent={<CloseIcon />} />
                    </button>
                  )}

                  <div className="relative w-full">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default memo(Modal);
