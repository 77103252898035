export const FileIcon = () => {
  return (
    <svg
      height="35.473"
      viewBox="0 0 26.605 35.473"
      width="26.605"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.519,9.422V0H1.663A1.659,1.659,0,0,0,0,1.663V33.81a1.659,1.659,0,0,0,1.663,1.663H24.942A1.659,1.659,0,0,0,26.6,33.81V11.085H17.182A1.668,1.668,0,0,1,15.519,9.422Zm4.434,16.351a.834.834,0,0,1-.831.831H7.483a.834.834,0,0,1-.831-.831v-.554a.834.834,0,0,1,.831-.831h11.64a.834.834,0,0,1,.831.831Zm0-4.434a.834.834,0,0,1-.831.831H7.483a.834.834,0,0,1-.831-.831v-.554a.834.834,0,0,1,.831-.831h11.64a.834.834,0,0,1,.831.831Zm0-4.988v.554a.834.834,0,0,1-.831.831H7.483a.834.834,0,0,1-.831-.831v-.554a.834.834,0,0,1,.831-.831h11.64A.834.834,0,0,1,19.954,16.351ZM26.6,8.446v.423H17.736V0h.423a1.662,1.662,0,0,1,1.178.485l6.783,6.79A1.657,1.657,0,0,1,26.6,8.446Z"
        data-name="Icon awesome-file-alt"
        fill="#008bd0"
        id="Icon_awesome-file-alt"
      />
    </svg>
  );
};
