// cspell:disable

export const ContactsWithBouncesLastDaysIcon = () => {
  return (
    <svg
      height="40.631"
      viewBox="0 0 53.685 40.631"
      width="53.685"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          filterUnits="userSpaceOnUse"
          height="28.549"
          id="Path_145"
          width="50.457"
          x="0"
          y="0"
        >
          {/* <feOffset dy="2" input="SourceAlpha" /> */}
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodOpacity="0.161" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 8458" id="Group_8458" transform="translate(-637.913 -332.467)">
        <g data-name="Group 8179" id="Group_8179" transform="translate(643.913 336.467)">
          <g data-name="Group 265" id="Group_265">
            <path
              d="M85.38,222.514H48.886a.989.989,0,0,1-.986-.986V194.386a.989.989,0,0,1,.986-.986H85.38a.989.989,0,0,1,.986.986v27.142A.989.989,0,0,1,85.38,222.514Z"
              data-name="Path 140"
              fill="#e7eefd"
              id="Path_140"
              transform="translate(-47.9 -193.4)"
            />
            <path
              d="M322.878,196.3l-.008.008a1.092,1.092,0,0,1,.365.822v2.6l-14.756,10.8a9.679,9.679,0,0,1-2.679,1.594l17.028,12.858a.989.989,0,0,0,.4-.789V197.048A.949.949,0,0,0,322.878,196.3Z"
              data-name="Path 141"
              fill="#cfdbfc"
              id="Path_141"
              transform="translate(-284.769 -196.059)"
            />
            <g filter="url(#Path_145)" transform="matrix(1, 0, 0, 1, -6, -4)">
              <path
                d="M71.747,208a6.445,6.445,0,0,1-9.236,0L47.9,197.091V194.47a1.033,1.033,0,0,1,.986-1.07H85.371a1.033,1.033,0,0,1,.986,1.07v2.621Z"
                data-name="Path 145"
                fill="#fff"
                id="Path_145-2"
                transform="translate(-41.9 -189.4)"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 8457" id="Group_8457" transform="translate(-363.75 -21.25)">
          <ellipse
            cx="8.5"
            cy="9"
            data-name="Ellipse 1929"
            fill="#fff"
            id="Ellipse_1929"
            rx="8.5"
            ry="9"
            transform="translate(1033.75 373.75)"
          />
          <path
            d="M15.6,3A12.6,12.6,0,1,0,28.2,15.6,12.586,12.586,0,0,0,15.6,3Zm6.3,17.12L20.12,21.9,15.6,17.374,11.075,21.9,9.3,20.12,13.821,15.6,9.3,11.075,11.075,9.3,15.6,13.821,20.12,9.3,21.9,11.075,17.374,15.6Z"
            data-name="Icon material-cancel"
            fill="#fc7e00"
            id="Icon_material-cancel"
            transform="translate(1027.152 366.152)"
          />
        </g>
      </g>
    </svg>
  );
};
