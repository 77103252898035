import _ from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Container, Text } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';

import Filter from './Filter';

import {
  useMutationCreateSegmentContext,
  useStateCreateSegmentContext,
} from '@/modules/ContactsModule/contexts/CreateSegmentContext';
import { ContactField } from '@/modules/ContactsModule/types/Segments';

interface SegmentFiltersContainerProps {
  isLoadingEditData: boolean;
}

const SegmentFiltersContainer = ({ isLoadingEditData }: SegmentFiltersContainerProps) => {
  const { filtersOptionsIsLoading, filterOptions, filters, currentFiltersWithRestriction } =
    useStateCreateSegmentContext();
  const { setFilters } = useMutationCreateSegmentContext();
  const { t } = useTranslation();

  const AndElement = () => (
    <div className="my-1 ml-0 flex 2xl:-ml-10">
      <span className="ml-1 inline-flex items-center rounded-full bg-emblue-primary-hover px-2 text-base font-normal text-white">
        {t('SEGMENT_FILTERS.AND')}
      </span>
    </div>
  );

  const addNewFilter = () => {
    setFilters([
      ...filters,
      {
        key: _.uniqueId(),
        dataType: undefined,
        condition: undefined,
        field: undefined,
      },
    ]);
  };

  const deleteFilter = (indexFilter: number) => {
    const newFilters = _.cloneDeep(filters);
    newFilters.splice(indexFilter, 1);
    setFilters(newFilters);
  };

  const daysOptions: Option[] = useMemo(() => {
    return Array.from({ length: 90 }, (v, i) => ({ id: i + 1, name: i + 1, value: i + 1 }));
  }, []);

  return (
    <Container>
      <div className="flex flex-col">
        <div className="py-3">
          <Text>{t('SEGMENT_FILTERS.important')}</Text>
        </div>
        {filters.length > 0 &&
          filters.map((filter, index) => {
            const restrictedFilter = currentFiltersWithRestriction.find(
              (restriction) =>
                restriction.filterOne === filter.field?.name ||
                restriction.filterTwo === filter.field?.name
            );
            return (
              <Fragment key={filter.key}>
                <Filter
                  daysOptions={daysOptions}
                  filterOptions={filterOptions}
                  filterValue={filter}
                  index={index}
                  isLoading={filtersOptionsIsLoading || isLoadingEditData}
                  operator={
                    filter.dataType === 'ID' ||
                    (filter.dataType === 'STRING' &&
                      (filter.field as ContactField).metadata?.optionType === 'combo')
                      ? filter?.operator
                      : undefined
                  }
                  restrictedFilter={restrictedFilter}
                  onDelete={deleteFilter}
                />
                {filters.length - 1 !== index && <AndElement />}
              </Fragment>
            );
          })}
        <div className="my-3 text-left">
          <Button
            outline
            secondary
            disabled={filtersOptionsIsLoading}
            id={'add-filter-btn'}
            onClick={addNewFilter}
          >
            {t('SEGMENT_FILTERS.addFilter')}
          </Button>
        </div>
      </div>
      {/* <div className="flex flex-col text-left">
        <pre>{JSON.stringify(filters, null, '\t')}</pre>
      </div> */}
    </Container>
  );
};

export default memo(SegmentFiltersContainer);
