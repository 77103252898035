import { timeout } from '@/src/compat/util';
import { ICustomFieldsResponse } from '@/src/infrastructure/interfaces/ICustomFields';

import { customFieldsListMock } from './CustomFieldsService.mocks';
import { ICustomFieldsService } from './ICustomFieldsService';

export class StubCustomFieldsService implements ICustomFieldsService {
  static create(): ICustomFieldsService {
    return new StubCustomFieldsService();
  }

  async getCustomFields(): Promise<ICustomFieldsResponse> {
    try {
      await timeout(2000);
      return customFieldsListMock;
    } catch (error) {
      return {} as ICustomFieldsResponse;
    }
  }
}
