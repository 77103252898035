export const EditTagCategoryIcon = () => {
  return (
    <svg height="18.25" viewBox="0 0 18.25 18.25" width="18.25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.607,3A2.607,2.607,0,0,0,3,5.607V8.214a2.607,2.607,0,0,0,2.607,2.607H8.214a2.607,2.607,0,0,0,2.607-2.607V5.607A2.607,2.607,0,0,0,8.214,3Zm0,10.429A2.607,2.607,0,0,0,3,16.036v2.607A2.607,2.607,0,0,0,5.607,21.25H8.214a2.607,2.607,0,0,0,2.607-2.607V16.036a2.607,2.607,0,0,0-2.607-2.607Zm7.821-7.821A2.607,2.607,0,0,1,16.036,3h2.607A2.607,2.607,0,0,1,21.25,5.607V8.214a2.607,2.607,0,0,1-2.607,2.607H16.036a2.607,2.607,0,0,1-2.607-2.607Zm0,10.429a2.607,2.607,0,0,1,2.607-2.607h2.607a2.607,2.607,0,0,1,2.607,2.607v2.607a2.607,2.607,0,0,1-2.607,2.607H16.036a2.607,2.607,0,0,1-2.607-2.607Z"
        data-name="Trazado 3252"
        fill="#004dbc"
        id="Trazado_3252"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
