export const WidgetIcon = () => (
  <svg
    className="h-14 w-14 sm:h-14 sm:w-14 md:h-14 md:w-14 lg:h-16 lg:w-16"
    viewBox="0 0 80.91 62.003"
  >
    <g transform="translate(0 0)">
      <path
        className="fill-[#9c59df]"
        d="M23.474,3.473a11.857,11.857,0,1,1,0,16.768,11.858,11.858,0,0,1,0-16.768"
        transform="translate(8.494 0.001)"
      />
      <path
        className="fill-[#9c59df]"
        d="M58.752,24.548H38.728a2.2,2.2,0,0,0,.03-.292v-8.21a2.205,2.205,0,0,0-4.411,0v8.21a2.2,2.2,0,0,0,.03.292H14.355c-2.98,0-5.407,2.163-5.407,4.82v8.507a2.205,2.205,0,0,0,4.411,0v-8.5a1.131,1.131,0,0,1,1-.417h44.4a1.149,1.149,0,0,1,.994.409v8.27a2.205,2.205,0,0,0,4.411,0v-8.27c0-2.657-2.425-4.82-5.405-4.82"
        transform="translate(3.8 5.878)"
      />
      <path
        className="fill-white stroke-white stroke-1"
        d="M27.432,49.689H4.164A4.164,4.164,0,0,1,0,45.525V33.16A4.164,4.164,0,0,1,4.164,29H27.432A4.164,4.164,0,0,1,31.6,33.16V45.525a4.164,4.164,0,0,1-4.164,4.164"
        transform="translate(0 12.314)"
      />
      <path
        className="fill-white stroke-white stroke-1"
        d="M62.046,49.689H38.778a4.164,4.164,0,0,1-4.164-4.164V33.16A4.164,4.164,0,0,1,38.778,29H62.046a4.164,4.164,0,0,1,4.164,4.164V45.525a4.164,4.164,0,0,1-4.164,4.164"
        transform="translate(14.7 12.314)"
      />
    </g>
  </svg>
);

export const WidgetImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueViolet/25'}>
    <WidgetIcon />
  </div>
);
