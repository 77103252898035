export const CallIcon = () => {
  return (
    <svg
      height="54.996"
      viewBox="0 0 54.998 54.996"
      width="54.998"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
        >
          <stop offset="0" stopColor="#5df5ff" />
          <stop offset="0.547" stopColor="#55d0ff" />
          <stop offset="1" stopColor="#4eaeff" />
        </linearGradient>
      </defs>
      <path
        d="M19.94,18.556q-.751.676-1.486,1.385A36.214,36.214,0,0,1,15.025.722c0-.014,0-.031,0-.045.619.291,1.248.571,1.878.837a3.023,3.023,0,0,1,1.643,2.8A32.389,32.389,0,0,0,20.567,15.5,2.826,2.826,0,0,1,19.94,18.556ZM53.9,38.5a3.018,3.018,0,0,0-2.8-1.64,32.39,32.39,0,0,1-11.187-2.018,2.831,2.831,0,0,0-3.062.627c-.448.5-.91,1-1.38,1.486a.529.529,0,0,1,.07.031,36.591,36.591,0,0,0,19.192,3.392Q54.3,39.454,53.9,38.5Zm1.229,2.748a.348.348,0,0,0-.381-.311,36.982,36.982,0,0,1-19.46-3.454.345.345,0,0,0-.465.162q-.932,1.952-1.87,3.91a1.013,1.013,0,0,1-1.371.473,42.754,42.754,0,0,1-18.2-18.2,1.011,1.011,0,0,1,.473-1.369l3.9-1.875a.349.349,0,0,0,.165-.465A36.955,36.955,0,0,1,14.468.663a.348.348,0,0,0-.311-.381A12.659,12.659,0,0,0,3.351,4.489a12.4,12.4,0,0,0-2.846,11A52.146,52.146,0,0,0,39.924,54.9,12.586,12.586,0,0,0,55.127,41.252ZM32.809,8.5a2.057,2.057,0,1,0,0,4.114,10,10,0,0,1,9.983,9.989,2.057,2.057,0,0,0,4.114,0A14.109,14.109,0,0,0,32.809,8.5Zm0,6.146a2.057,2.057,0,1,0,0,4.114,3.845,3.845,0,0,1,3.837,3.843,2.057,2.057,0,0,0,4.114,0A7.957,7.957,0,0,0,32.809,14.649ZM30.7,21.3a2.409,2.409,0,1,0,3.415,0A2.414,2.414,0,0,0,30.7,21.3Zm2.107-18.94h0a2.057,2.057,0,1,0,0,4.114,16.144,16.144,0,0,1,16.13,16.135,2.057,2.057,0,0,0,4.114,0A20.26,20.26,0,0,0,32.812,2.357Z"
        data-name="call (2)"
        fill="#55d0ff"
        id="call_2_"
        transform="translate(-0.201 -0.212)"
      />
    </svg>
  );
};
