export const ListIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <rect fill="none" height="16" width="16" />
      <path
        d="M7.123,6.663h9.226M7.123,10.7h9.226M7.123,14.736h9.226M3.663,6.663h.005v.006H3.663Zm.288,0a.288.288,0,1,1-.288-.288A.288.288,0,0,1,3.952,6.663ZM3.663,10.7h.005v.006H3.663Zm.288,0a.288.288,0,1,1-.288-.288A.288.288,0,0,1,3.952,10.7Zm-.288,4.036h.005v.006H3.663Zm.288,0a.288.288,0,1,1-.288-.288A.288.288,0,0,1,3.952,14.736Z"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(-1.338 -2.708)"
      />
    </svg>
  );
};
