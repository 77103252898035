/* cspell:disable */
import { ITableWidget } from '@/modules/NPSModule/interfaces';

export const widgetListMock: ITableWidget[] = [
  {
    widget: {
      id: 917202,
      name: 'ViajesWidgetNPS123',
      views: 15,
      actions: 0,
      ctr: 0.0,
      creationDays: 701,
      status: 'Inactive',
    },
  },
  {
    widget: {
      name: 'WidgetNPSV1',
      views: 19,
      actions: 1,
      ctr: 5.3,
      creationDays: 138,
      status: 'Inactive',
      id: 12312,
    },
  },
  {
    widget: {
      name: 'NPS widget DMC Deplpr',
      views: 6,
      actions: 4,
      ctr: 66.7,
      creationDays: 82,
      status: 'Inactive',
      id: 12312,
    },
  },
  {
    widget: {
      name: 'PRUEBA NPS',
      views: 0,
      actions: 0,
      ctr: 0.0,
      creationDays: 75,
      status: 'Inactive',
      id: 12312,
    },
  },
  {
    widget: {
      name: 'NPS widget Test CP',
      views: 19,
      actions: 8,
      ctr: 42.1,
      creationDays: 66,
      status: 'Active',
      id: 12312,
    },
  },
  {
    widget: {
      name: 'Widget NPS Migration',
      views: 10,
      actions: 10,
      ctr: 100.0,
      creationDays: 38,
      status: 'Inactive',
      id: 12312,
    },
  },
  {
    widget: {
      name: 'Final Migration Widget NPS',
      views: 11,
      actions: 8,
      ctr: 72.7,
      creationDays: 30,
      status: 'Inactive',
      id: 12312,
    },
  },
];

export const responseScriptCodeMock =
  "(function(d,t,u,s,c,f){f=function(m){m=new Date();return m.getFullYear()+''+(m.getMonth()+1)+''+m.getDate()+'T'+m.getHours()+''+m.getMinutes()+''+m.getSeconds()};u='https://beta-widgets-static.embluemail.com/accounts/355EC542BE06D61/scripts/sw_35.js?ts='+f();s=d.createElement(t);s.async=1;s.src=u;c=d.getElementsByTagName(t)[0];c.parentNode.insertBefore(s,c);})(document,'script');";
