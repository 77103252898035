import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { NpsCount } from '@/modules/ContactsModule/screens/HappinessMain/types';

export const useGetWidgetsCount = (
  status: string,
  lastRefresh?: number
): [NpsCount | undefined, Error | undefined, boolean] => {
  const [count, error, isLoading] = useEmblue(ServiceMethods.getNpsCount, {
    status,
    lastRefresh,
  });
  return [count, error, isLoading];
};
