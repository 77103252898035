export const EmBlueIcon = () => {
  return (
    <svg height="20" viewBox="0 0 31.68 39.221" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M868.08,15.84a15.84,15.84,0,0,0-31.68,0v.176h.044c-.044,1.185.263,9.127,13.514,22.246a3.192,3.192,0,0,0,4.519.044l.044-.044c13.251-13.076,13.558-21.018,13.514-22.246h.044V15.84Z"
        data-name="Path 3402"
        fill="#004dbc"
        id="Path_3402"
        transform="translate(-836.4)"
      />
    </svg>
  );
};
