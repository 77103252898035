import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconSvg, toast } from '@/lib/v2/components';
import { CustomSegmentIcon } from '@/lib/v2/icons/general';
import { CardActionProps } from '@/lib/v2/organisms/CardAction/CardAction';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { SEGMENT_PATH } from '@/src/modules/ContactsModule/constants';

import { useEcommerceSegmentsCards } from './useEcommerceSegmentsCards';
import { useEmblueRecipesCards } from './useEmblueRecipesCards';

import { ModalSegmentsCardsProps, SegmentTypeEnum } from '@/modules/ContactsModule/types/Segments';

type SegmentsCardsProps = {
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setCreateCustomSegment?: Dispatch<SetStateAction<boolean>>;
  lastRefreshIncrement?: () => void;
};

export const useSegmentsCards = ({
  setShowModal,
  setCreateCustomSegment,
  lastRefreshIncrement,
  setIsLoading,
}: SegmentsCardsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [emblueSegmentsInUse] = useEmblue(ServiceMethods.getEmblueSegmentsInUse, {
    lastRefresh: undefined,
  });

  const service = useService();
  const createSegment = useCallback(
    async (
      name: string,
      segment: ModalSegmentsCardsProps,
      segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce
    ) => {
      setShowModal && setShowModal(false);
      setIsLoading && setIsLoading(true);
      const result = await service?.createV2Segment({
        name: name,
        v2SegmentFilters: segment.filters,
        asEmblueSegment: { segmentType: segmentType, segmentNumber: segment.segmentNumber },
      });
      setIsLoading && setIsLoading(false);
      const title = result?.success
        ? t('EMBLUE_SEGMENTS.Notification.OK.title')
        : t('EMBLUE_SEGMENTS.Notification.ERROR.title');
      // eslint-disable-next-line no-nested-ternary
      const body = result?.success
        ? t('EMBLUE_SEGMENTS.Notification.OK.body')
        : result?.data.errorByName
        ? t('EMBLUE_SEGMENTS.Notification.ERROR.bodyRepeatedName')
        : t('EMBLUE_SEGMENTS.Notification.ERROR.body');
      toast({
        show: true,
        variant: result?.success ? 'success' : 'error',
        title: title,
        body: body,
      });
      result?.success && navigate(`${SEGMENT_PATH}/${result.data.segmentId}`);
      lastRefreshIncrement && lastRefreshIncrement();
    },
    [setShowModal, setIsLoading, service, t, navigate, lastRefreshIncrement]
  );

  const customSegmentCard: CardActionProps[] = useMemo(
    () =>
      [
        {
          title: t('EMBLUE_SEGMENTS.CARDS.CUSTOM.Custom.title'),
          description: t('EMBLUE_SEGMENTS.CARDS.CUSTOM.Custom.description'),
          buttonText: t('EMBLUE_SEGMENTS.create'),
          onClickAction: () => {
            setShowModal && setShowModal(false);
            setCreateCustomSegment && setCreateCustomSegment(true);
          },
          icon: <IconSvg height="53px" svgComponent={<CustomSegmentIcon />} width="53px" />,
          badgeText: t('SEGMENTS_CARDS.customized'),
          badgeColor: 'success',
        },
      ] as CardActionProps[],
    [t, setCreateCustomSegment, setShowModal]
  );

  const emblueRecipesCards: CardActionProps[] = useEmblueRecipesCards({
    createSegment,
    emblueSegmentsInUse,
  });

  const eCommerceCards: CardActionProps[] = useEcommerceSegmentsCards({
    createSegment,
    emblueSegmentsInUse,
  });

  const allCards: CardActionProps[] = useMemo(() => {
    const emblueSegments = sortDisabledToEnd(emblueRecipesCards.concat(eCommerceCards));
    return customSegmentCard.concat(emblueSegments);
  }, [emblueRecipesCards, eCommerceCards, customSegmentCard]);

  return allCards;
};

const sortDisabledToEnd = (arr: CardActionProps[]): CardActionProps[] => {
  return arr.sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1;
    }
    if (!a.disabled && b.disabled) {
      return -1;
    }
    return 0;
  });
};
