export const DateIcon = () => {
  return (
    <svg
      height="14.344"
      viewBox="0 0 14.344 14.344"
      width="14.344"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.429,5.743V3m5.486,2.743V3M5.743,8.486H12.6M4.372,15.344h9.6a1.372,1.372,0,0,0,1.372-1.372V5.743a1.372,1.372,0,0,0-1.372-1.372h-9.6A1.372,1.372,0,0,0,3,5.743v8.229A1.372,1.372,0,0,0,4.372,15.344Z"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};
