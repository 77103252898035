import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const editorRefAtom = atom<any | null>(null);
export const htmlEditorAtom = atomWithReset<string>('');

export const isLoadingSaveAtom = atom<boolean>(false);
export const isLoadingUploadAtom = atom<boolean>(false);
export const isLoadingCFAtom = atom<boolean>(true);
export const isLoadingHtmlAtom = atom<boolean>(true);

export const isUploadFilePageModalOpenAtom = atom<boolean>(false);
