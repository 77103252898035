import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IUpdateItemsToPropsV2 } from '@/src/ContactsModule/interfaces/Interfaces';

import { useGroup } from '@/modules/ContactsModule/hooks/useGroup';

interface IGroupsByContactsProps {
  selectedContacts: number[];
  selectAllResults: boolean;
  onAssignment: ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => Promise<void>;
  onLastRefresh?: () => void;
  allContactsCount: number;
}

export const useGroupsByContacts = ({
  selectedContacts = [],
  selectAllResults,
  onAssignment,
  onLastRefresh,
  allContactsCount,
}: IGroupsByContactsProps) => {
  const { t } = useTranslation();

  const { createGroup } = useGroup();

  const onApply = async ({ selectedItems, unselectedItems }: IUpdateItemsToPropsV2) => {
    await onAssignment({ selectedContacts, selectedItems, unselectedItems });
  };

  const handleOnCreate = useCallback(
    async (value: string) => {
      const groupCreated = await createGroup(value);
      if (groupCreated) onLastRefresh?.();
    },
    [createGroup, onLastRefresh]
  );

  const wordings = useMemo(() => {
    const contactCount = selectAllResults ? allContactsCount : selectedContacts.length;

    return {
      title: t('CONTACTS_ACTIONS_DROPDOWN.AddToGroup'),
      search: {
        placeholder: t('CONTACT_GROUP_MODAL.inputDescription'),
      },
      actions: {
        apply: t('CONTACT_GROUP_MODAL.apply'),
        cancel: t('CONTACT_GROUP_MODAL.cancel'),
      },
      selectedCount: contactCount,
      description: {
        selected: t('CONTACT_GROUP_MODAL.contactsToAssign', { count: contactCount }),
        unselected: t('CONTACT_GROUP_MODAL.contactsToUnassign', { count: contactCount }),
      },
      labelInputCreate: t('ITEM_SEARCH_MODAL.Group'),
    };
  }, [t, selectAllResults, selectedContacts.length, allContactsCount]);

  return {
    wordings,
    onApply,
    handleOnCreate,
  };
};
