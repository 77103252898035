import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { atomProcessingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomCallbackStep } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';

const ImmediateDeliveryForm = () => {
  const navigate = useNavigate();
  const { campaignId, actionId } = useParams();

  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const { setWhatsAppDelivery } = useActionWhatsAppData(Number(actionId));

  const handleSubmitData = useCallback(async () => {
    setProcessingAction(true);
    await setWhatsAppDelivery();
    navigate(`${CAMPAIGNS_PATH}/${Number(campaignId)}/whatsapp/${Number(actionId)}/summary`);
    setProcessingAction(false);
  }, [actionId, campaignId, navigate, setProcessingAction, setWhatsAppDelivery]);

  useEffect(() => {
    setCallbackStep(() => handleSubmitData);
  }, [handleSubmitData, setCallbackStep]);

  return null;
};

export default ImmediateDeliveryForm;
