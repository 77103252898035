import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card } from '@/lib/components';
import { Circle } from '@/lib/components/Util/Circle';
import { Button, CardSection, Flex, IconSvg, Spinner, Text } from '@/lib/v2/components';
import { SearchHealthIcon } from '@/lib/v2/icons/outline/SearchHealthIcon';

import configData from '@/src/config.json';
import { useSenderScore } from '@/src/infrastructure/services/useSenderScore';
import ScoreOverview from '@/src/presentation/components/Main/components/ScoreOverview/ScoreOverview';
import { SenderScoring } from '@/src/presentation/util/enum/senderScoringEnum';

const ScoreOverviewCard = () => {
  const { t } = useTranslation();
  const [scoring] = useSenderScore();
  const [open, setOpen] = useState(false);

  const senderScoreUrl = configData.HOME.SENDER_SCORING_GUIDE_URL;

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const getColorClass = useCallback((score?: number): string => {
    if (score === undefined) return '';

    if (score >= SenderScoring.maxScore) return 'card blue';
    if (score <= SenderScoring.minScore) return 'card red';
    return 'card yellow';
  }, []);

  const getLabel = useCallback(
    (score?: number): string => {
      if (score === undefined) return '';

      if (score >= SenderScoring.maxScore) return t('SENDER_SCORING.high');
      if (score <= SenderScoring.minScore) return t('SENDER_SCORING.low');
      return t('SENDER_SCORING.medium');
    },
    [t]
  );

  const colorClass = useMemo(() => getColorClass(scoring), [scoring, getColorClass]);
  const label = useMemo(() => getLabel(scoring), [scoring, getLabel]);

  return (
    <>
      <Card noPadded>
        {scoring === undefined || isNaN(scoring) ? (
          <div className="item-center  flex flex-col p-8">
            <Spinner withoutOverlay />
          </div>
        ) : (
          <>
            <CardSection
              className={classNames(
                colorClass,
                'text-white flex flex-col items-center justify-center rounded-t-lg'
              )}
              paddingX="small"
              paddingY="small"
            >
              <Text
                alignment="center"
                as="h3"
                className="p-3 leading-tight"
                color="white"
                fontWeight="bold"
                variant="sub-headline"
              >
                {t('SENDER_SCORING.title')}
              </Text>
              <Circle size="115px">
                <Text
                  alignment="center"
                  as="h3"
                  className="p-3 leading-tight"
                  color="white"
                  fontWeight="normal"
                  variant="title-3"
                >
                  {scoring.toString()}
                </Text>
              </Circle>
            </CardSection>
            <Flex withGap alignment="spaceBetween" className="my-4 px-4 " gapSize="medium">
              <Text
                alignment="left"
                as="h3"
                className="truncate leading-tight"
                color="black"
                fontWeight="medium"
                variant="sub-headline"
              >
                {label}
                <Link
                  className="relative w-full cursor-pointer overflow-hidden  "
                  rel="noopener noreferrer"
                  target="_blank"
                  to={senderScoreUrl}
                >
                  <Text
                    alignment="left"
                    as="h3"
                    className="truncate pt-1 leading-tight "
                    color="primary"
                    fontWeight="medium"
                    variant="text-sm"
                  >
                    {t('SENDER_SCORING.textLink')}
                  </Text>
                </Link>
              </Text>
              <Button
                standard
                className="cursor-pointer border-none bg-none p-0"
                id="show-score-graph"
                onClick={handleOpen}
              >
                <IconSvg height="24px" svgComponent={<SearchHealthIcon />} width="24px" />
              </Button>
            </Flex>
          </>
        )}
      </Card>

      {open && (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center">
          <div className="fixed inset-0 bg-black/25" onClick={handleClose}></div>
          <div className="z-[1000000] rounded-lg bg-white p-8 shadow-lg">
            <ScoreOverview onClose={handleClose} />
          </div>
        </div>
      )}
    </>
  );
};

export default ScoreOverviewCard;
