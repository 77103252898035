import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Input, TimeInput } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomDeliveryInformation,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import useSchedulingForm, {
  SchedulingFormState,
} from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useSchedulingForm';
import { SubjectButtons } from '@/modules/CampaignsModule/components/SubjectButtons';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  IDeliveryActionResponse,
  ISetDeliveryActionPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const ADD_MINUTES = 10;
const ONE_DAY_MS = 24 * 60 * 60 * 1000;

const SchedulingDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);

  const [userData] = useUserData();
  const { control, RULES, handleSubmit, setValue, getValues, resetField, clearErrors } =
    useSchedulingForm();
  const { saveDeliveryAction, setActionState } = useActionEmailData(Number(actionId));

  const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryTime, setScheduledDeliveryTime] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryMinDateTime, setScheduledDeliveryMinDateTime] = useState<
    Date | undefined
  >(undefined);
  const [reinforcementDateState, setReinforcementDateState] = useState<Date | undefined>(undefined);
  const [reinforcementTimeState, setReinforcementTimeState] = useState<Date | undefined>(undefined);
  const [reinforcementMinDateTime, setReinforcementMinDateTime] = useState<Date | undefined>(
    undefined
  );
  const [reinforcementMinDate, setReinforcementMinDate] = useState<Date | undefined>(undefined);
  const [reinforcementCheckbox, setReinforcementCheckbox] = useState<boolean>(
    ((deliveryInformation?.reinforcement?.isReinforcementShipment &&
      deliveryInformation?.shippingDate) as boolean) || false
  );

  const formatGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );

  const currentDateRoot = useMemo(
    () => (formatGMT ? DateConverter.toGMTDate(new Date().toISOString(), formatGMT) : undefined),
    [formatGMT]
  );

  useEffect(() => {
    const {
      shippingDate,
      reinforcement: { subject, startDate: reinforcementStartDate },
    } = deliveryInformation as IDeliveryActionResponse;

    if (!formatGMT) return;

    const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);

    if (shippingDate && !scheduledDeliveryDate) {
      const newShippingDate = DateConverter.toGMTDate(shippingDate, formatGMT);

      setValue('scheduledDeliveryDate', newShippingDate);
      setScheduledDeliveryDate(newShippingDate);

      setValue('scheduledDeliveryTime', newShippingDate);
      setScheduledDeliveryTime(newShippingDate);

      if (newShippingDate.getDate() === currentDate.getDate()) {
        if (newShippingDate.getHours() > currentDate.getHours()) {
          setScheduledDeliveryMinDateTime(currentDate);
        } else {
          setScheduledDeliveryMinDateTime(newShippingDate);
        }
      } else {
        currentDate.setHours(0, 0, 0, 0);
        setScheduledDeliveryMinDateTime(currentDate);
      }
    }

    if (reinforcementCheckbox && !!shippingDate) {
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const newReinforcementDate = DateConverter.toGMTDate(reinforcementStartDate, formatGMT);
      setValue('reinforcementCheckbox', true);
      setValue('reinforcementSubject', subject);
      setValue('reinforcementDate', reinforcementStartDate ? newReinforcementDate : null);
      setValue('reinforcementTime', reinforcementStartDate ? newReinforcementDate : null);

      setReinforcementDateState(reinforcementStartDate ? newReinforcementDate : undefined);
      setReinforcementTimeState(reinforcementStartDate ? newReinforcementDate : undefined);

      if (newReinforcementDate.getDate() === currentDate.getDate()) {
        if (newReinforcementDate.getHours() > currentDate.getHours()) {
          setReinforcementMinDate(currentDate);
          setReinforcementMinDateTime(currentDate);
        } else {
          setReinforcementMinDate(currentDate);
          setReinforcementMinDateTime(newReinforcementDate);
        }
      } else {
        const currentDate = DateConverter.toGMTDate(shippingDate, formatGMT);
        currentDate.setHours(
          currentDate.getHours(),
          Math.floor(currentDate.getMinutes() / 10) * 10
        );
        currentDate.setDate(currentDate.getDate() + 1);

        setReinforcementMinDate(currentDate);
        setReinforcementMinDateTime(currentDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatGMT]);

  const onChangeScheduledDeliveryDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const inputDate = event as Date;

      if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth()
      ) {
        inputDate.setHours(currentDate.getHours(), Math.floor(currentDate.getMinutes() / 10) * 10);
        inputDate.setMinutes(inputDate.getMinutes() + ADD_MINUTES);
      } else {
        inputDate && inputDate.setHours(0, 0, 0, 0);
      }

      const currentDateMin = new Date(inputDate);
      currentDateMin.setDate(currentDateMin.getDate() + 1);

      setScheduledDeliveryDate(inputDate);
      setScheduledDeliveryTime(undefined);
      setScheduledDeliveryMinDateTime(inputDate);
      setReinforcementMinDate(currentDateMin);
      resetField('scheduledDeliveryTime');
    },
    [resetField, formatGMT]
  );

  const onChangeScheduledDeliveryTime: eventOnChangeTimeInput = useCallback(
    (event) => {
      if (!formatGMT) return;

      const inputTime = event as Date;

      setScheduledDeliveryTime(inputTime);
      resetField('reinforcementDate');
      resetField('reinforcementTime');
      setReinforcementDateState(undefined);
      setReinforcementTimeState(undefined);

      const inputDate = event as Date;
      setReinforcementMinDateTime(inputDate);
    },
    [resetField, formatGMT]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const inputDate = event as Date;
      const reinforcementMinDateTime = DateConverter.toGMTDate(inputDate.toISOString(), formatGMT);

      setReinforcementDateState(inputDate);

      const currentDeliveryDate = new Date(scheduledDeliveryDate as Date);
      const differenceInMs = inputDate.getTime() - currentDeliveryDate.getTime();

      if (differenceInMs <= ONE_DAY_MS) {
        setReinforcementMinDateTime(scheduledDeliveryTime);
      } else {
        reinforcementMinDateTime.setHours(0, 0, 0, 0);
        setReinforcementMinDateTime(reinforcementMinDateTime);
      }

      setReinforcementTimeState(undefined);
      resetField('reinforcementTime');
    },
    [formatGMT, resetField, scheduledDeliveryTime, scheduledDeliveryDate]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setReinforcementTimeState(event as Date);
  }, []);

  const handleChangeReinforcement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setReinforcementCheckbox(checked);
      if (!checked) clearErrors();
    },
    [clearErrors]
  );

  const getGMTDate = (date: Date, time: Date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');

    const hour = String(time?.getHours()).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    const minutes = String(time?.getMinutes()).padStart(2, '0');

    return {
      date: `${year}-${month}-${day}`,
      hour: `${hour}:${minutes}`,
    };
  };

  const handleSubmitData = useCallback(
    async (formData: SchedulingFormState) => {
      setGlobalLoadingAction(true);

      const {
        scheduledDeliveryDate,
        scheduledDeliveryTime,
        reinforcementSubject,
        reinforcementCheckbox,
        reinforcementDate,
        reinforcementTime,
      } = formData;

      if (!scheduledDeliveryDate || !scheduledDeliveryTime) return;

      const delivery = getGMTDate(scheduledDeliveryDate, scheduledDeliveryTime);
      const deliveryReinforcement =
        reinforcementDate && reinforcementTime
          ? getGMTDate(reinforcementDate, reinforcementTime)
          : null;

      const payload: ISetDeliveryActionPayload = {
        actionType: EChannelsID.EMAIL,
        actionId: Number(actionId),
        isImmediateShipment: false,
        delivery: delivery,
        reinforcement: {
          subject: reinforcementCheckbox ? reinforcementSubject : null,
          isReinforcementShipment: reinforcementCheckbox ?? false,
          date: deliveryReinforcement ? deliveryReinforcement?.date : null,
          hour: deliveryReinforcement ? deliveryReinforcement.hour : null,
        },
      };

      await saveDeliveryAction(payload);
      await setActionState({
        actionId: Number(actionId),
        statusId: EActionStatusAvailable.CONFIRMABLE_DRAFT,
      });

      setGlobalLoadingAction(false);
      navigate(`/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, navigate, saveDeliveryAction, setActionState, setGlobalLoadingAction]
  );

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setGlobalLoadingAction(false);
  }, []);

  const handleChangeSubjectButton = ({ item }: ISubjectChangeButtonsProps) => {
    const currentSubject = getValues('reinforcementSubject');
    setValue('reinforcementSubject', `${currentSubject} ${item}`);
  };

  return (
    <form
      id="scheduling-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="mb-5 flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={scheduledDeliveryDate}
            disabled={disabled}
            id="scheduledDeliveryDate"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            minDate={currentDateRoot}
            name="scheduledDeliveryDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.scheduledDeliveryDate}
            onChange={onChangeScheduledDeliveryDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={scheduledDeliveryTime}
            disabled={disabled}
            id="scheduledDeliveryTime"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={scheduledDeliveryMinDateTime || currentDateRoot}
            name="scheduledDeliveryTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.scheduledDeliveryTime}
            onChange={onChangeScheduledDeliveryTime}
          />
        </div>
      </div>
      <div className="mb-5 flex items-center gap-3" id="scheduling-checkbox-container">
        <Checkbox
          checked={reinforcementCheckbox}
          color="#D3DDEA"
          control={control}
          disabled={disabled}
          id="reinforcementCheckbox"
          label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.reinforcement')}
          name="reinforcementCheckbox"
          size={16}
          onChange={handleChangeReinforcement}
        />
      </div>
      <div className="mb-4 flex w-3/4 gap-2" id="scheduling-subject-container">
        <Input
          control={control}
          disabled={!reinforcementCheckbox || disabled}
          id="reinforcementSubject"
          isRequired={reinforcementCheckbox}
          name="reinforcementSubject"
          placeHolder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.subjectPlaceholder`)}
          rules={RULES.reinforcementSubject}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={!reinforcementCheckbox || disabled}
            id="email-scheduling-buttons"
            searchPlaceholder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            control={control}
            date={reinforcementDateState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementDate"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            minDate={reinforcementMinDate}
            name="reinforcementDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.reinforcementDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            control={control}
            date={reinforcementTimeState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementTime"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={reinforcementMinDateTime}
            name="reinforcementTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.reinforcementTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default SchedulingDeliveryForm;
