import { useLocation, useParams } from 'react-router-dom';

export const useURLParams = () => {
  const { tagId, groupId } = useParams();

  // Tags Filter
  const selectedTags = tagId && [parseInt(tagId)];

  // Groups Filter
  const selectedGroups = groupId && [parseInt(groupId)];

  // Status Filter
  const location = useLocation();
  const selectedStatus = location.pathname.includes('discarded') && ['discarded'];

  return { selectedTags, selectedGroups, selectedStatus };
};
