import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { useFiltersCampaign } from '@/modules/CampaignsModule/hooks/useFiltersCampaign';

export const useResetButton = (resetSearch: () => void, resetDateRange: () => void) => {
  const filtersCampaign = useAtomValue(atomFiltersCampaign);

  const { resetFilters } = useFiltersCampaign();

  const id = 'clear-filters-campaigns';

  const disabled = Object.keys(filtersCampaign ?? {}).length === 0;

  const handleClickReset = useCallback(() => {
    resetSearch();
    resetDateRange();
    resetFilters();
  }, [resetDateRange, resetFilters, resetSearch]);

  return {
    id,
    disabled,
    handleClickReset,
  };
};
