export const EmojiIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_889_1457)">
        <path
          d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15ZM11.625 8.25C12.2475 8.25 12.75 7.7475 12.75 7.125C12.75 6.5025 12.2475 6 11.625 6C11.0025 6 10.5 6.5025 10.5 7.125C10.5 7.7475 11.0025 8.25 11.625 8.25ZM6.375 8.25C6.9975 8.25 7.5 7.7475 7.5 7.125C7.5 6.5025 6.9975 6 6.375 6C5.7525 6 5.25 6.5025 5.25 7.125C5.25 7.7475 5.7525 8.25 6.375 8.25ZM9 13.125C10.7475 13.125 12.2325 12.03 12.8325 10.5H5.1675C5.7675 12.03 7.2525 13.125 9 13.125Z"
          fill="#9DA3A7"
        />
      </g>
      <defs>
        <clipPath id="clip0_889_1457">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
