import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import {
  atomFiltersAction,
  atomStatusFilterCount,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useFiltersActions } from '@/modules/CampaignsModule/hooks/useFiltersActions';

export const useResetButton = (resetSearch: () => void, resetDateRange: () => void) => {
  const { resetFilters } = useFiltersActions();
  const setStatusFilterCount = useSetAtom(atomStatusFilterCount);
  const filtersActions = useAtomValue(atomFiltersAction);

  const id = 'clear-filters-campaigns-action';
  const disabled = Object.keys(filtersActions ?? {}).length === 0;

  const handleClickReset = useCallback(() => {
    setStatusFilterCount(0);
    resetSearch();
    resetDateRange();
    resetFilters();
  }, [resetDateRange, resetFilters, resetSearch, setStatusFilterCount]);

  return {
    id,
    disabled,
    handleClickReset,
  };
};
