import { useCallback } from 'react';

import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { IEmailInfoPayload } from '@/modules/CampaignsModule/interfaces/CampaignActions';
interface IEmailFormHandleEventsProps {
  actionId: number;
}

export const useEmailFormEvents = ({ actionId }: IEmailFormHandleEventsProps) => {
  const { saveInfoAction } = useActionEmailData(Number(actionId));

  const setInfoByFieldOnChange = useCallback(
    (payload: Partial<IEmailInfoPayload>) => {
      if (Object.values(payload).length === 0) return;
      void saveInfoAction(payload);
    },
    [saveInfoAction]
  );

  return { setInfoByFieldOnChange };
};
