export const DataStorageIcon = () => {
  return (
    <svg
      height="16.517"
      viewBox="0 0 13.214 16.517"
      width="13.214"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.1,13.875a.826.826,0,1,0,.826.826A.826.826,0,0,0,7.1,13.875ZM10.407,1.9C7.1,1.9,3.8,3.031,3.8,5.2v9.91c0,2.172,3.3,3.3,6.607,3.3s6.607-1.131,6.607-3.3V5.2C17.014,3.031,13.71,1.9,10.407,1.9Zm4.955,13.214c0,.586-1.883,1.652-4.955,1.652S5.452,15.7,5.452,15.114v-2.7a10.869,10.869,0,0,0,4.955,1.049,10.869,10.869,0,0,0,4.955-1.049Zm0-4.955c0,.586-1.883,1.652-4.955,1.652s-4.955-1.065-4.955-1.652v-2.7a10.868,10.868,0,0,0,4.955,1.049,10.868,10.868,0,0,0,4.955-1.049Zm-4.955-3.3C7.335,6.855,5.452,5.79,5.452,5.2s1.883-1.652,4.955-1.652S15.362,4.617,15.362,5.2,13.479,6.855,10.407,6.855ZM7.1,8.92a.826.826,0,1,0,.826.826A.826.826,0,0,0,7.1,8.92Z"
        fill="#004dbc"
        id="data-storage"
        transform="translate(-3.8 -1.9)"
      />
    </svg>
  );
};
