import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components/Button';
import { InputSearch } from '@/lib/v2/components/InputSearch';
import { Text } from '@/lib/v2/components/Text';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const SwitchAccount = () => {
  const service = useService();
  const { t } = useTranslation();
  const [accountName, setAccountName] = useState<string>();
  const [accountId, setAccountId] = useState<number>(0);

  const handleSubmit = useCallback(
    async (value: string) => {
      const newValue = +value;
      if (!newValue || isNaN(newValue)) {
        return;
      }

      const accountName = await service.getAccountNameById(+newValue);
      setAccountName(accountName);
      setAccountId(+newValue);
    },
    [service]
  );

  const switchAccount = useCallback(async () => {
    const success = await service.switchAccount(accountId);
    if (success) {
      window.location.reload();
    }
  }, [service, accountId]);

  return (
    <div className="flex flex-col space-y-2 px-6 py-4">
      <label className="text-sm font-medium text-gray-700" htmlFor="accountIdInput">
        {t('SMART_FINDER.ADMIN_TOOLS.accountId')}
      </label>
      <InputSearch
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        id="accountIdInput"
        placeholder={t('SMART_FINDER.ADMIN_TOOLS.accountId')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises, react/jsx-sort-props
        onAction={handleSubmit}
      />
      {accountName?.length ? (
        <div className="flex-col ">
          <div className="mb-2">
            <Text
              renderHtml={t('SMART_FINDER.ADMIN_TOOLS.accountName').replaceAll('{0}', accountName)}
              variant="sub-headline"
            />
          </div>
          <Button onClick={switchAccount}>{t('confirm')}</Button>
        </div>
      ) : null}
    </div>
  );
};

export default SwitchAccount;
