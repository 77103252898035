export const HelpIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5144 10.1689H17.4365V11.9268H18.489C18.8022 11.9268 19.0392 11.8535 19.2029 11.7068C19.3666 11.56 19.4484 11.3484 19.4484 11.0719C19.4484 10.7954 19.3666 10.5809 19.2001 10.4201C19.0336 10.2564 18.805 10.1746 18.5144 10.1689Z"
        fill="#A7B1CC"
      />
      <path
        d="M20.5908 3.59998H3.40957C2.18778 3.59998 1.2002 4.59038 1.2002 5.80935V17.6576C1.2002 18.8794 2.1906 19.867 3.40957 19.867H20.5908C21.8126 19.867 22.8002 18.8766 22.8002 17.6576V5.80935C22.8002 4.58756 21.8098 3.59998 20.5908 3.59998ZM5.76286 4.84716C6.08735 4.84716 6.35259 5.11239 6.35259 5.43689C6.35259 5.76138 6.08735 6.02663 5.76286 6.02663C5.43837 6.02663 5.17312 5.76138 5.17312 5.43689C5.17312 5.11239 5.43837 4.84716 5.76286 4.84716ZM3.26285 5.43689C3.26285 5.10957 3.52808 4.84716 3.85258 4.84716C4.17707 4.84716 4.44231 5.11239 4.44231 5.43689C4.44231 5.76138 4.17707 6.02663 3.85258 6.02663C3.52808 6.02663 3.26285 5.76138 3.26285 5.43689ZM7.67596 14.503H6.80406V12.2654H4.54953V14.503H3.67199V9.46061H4.54953V11.5599H6.80406V9.46061H7.67596V14.503ZM7.67032 6.02663C7.34582 6.02663 7.08059 5.76138 7.08059 5.43689C7.08059 5.11239 7.34582 4.84716 7.67032 4.84716C7.99481 4.84716 8.26005 5.11239 8.26005 5.43689C8.26005 5.76138 7.99481 6.02663 7.67032 6.02663ZM12.0072 14.503H8.71152V9.46061H11.9847V10.166H9.58906V11.5571H11.6602V12.2484H9.58906V13.8004H12.0101V14.5001L12.0072 14.503ZM15.8871 14.503H12.7211V9.46061H13.5987V13.8032H15.8871V14.503ZM19.8402 12.2202C19.5157 12.4967 19.0615 12.6322 18.4774 12.6322H17.4362V14.503H16.5614V9.46061H18.4915C19.053 9.46061 19.5016 9.60734 19.8346 9.90079C20.1647 10.1942 20.3312 10.5836 20.3312 11.0662C20.3312 11.5487 20.1704 11.9465 19.8459 12.2202"
        fill="#A7B1CC"
      />
    </svg>
  );
};
