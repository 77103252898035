const TemplatesIcon = () => {
  return (
    <svg
      fill="none"
      height="131"
      viewBox="0 0 160 131"
      width="160"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.9071 113.81C34.7565 112.796 29.0564 109.962 24.5511 105.678C8.17928 94.2932 3.2701 90.235 -0.00268555 87.7953V121.934C-0.00268555 124.091 0.85934 126.16 2.39376 127.685C3.92817 129.21 6.00929 130.067 8.17928 130.067H73.6114C75.7814 130.067 77.8625 129.21 79.3969 127.685C80.9313 126.16 81.7934 124.091 81.7934 121.934V87.7953C78.5206 90.235 73.6114 94.301 57.2553 105.678C52.7521 109.96 47.055 112.794 40.9071 113.81ZM73.6114 65.0333H8.17928C6.00929 65.0333 3.92817 65.8901 2.39376 67.4151C0.85934 68.9402 -0.00268555 71.0086 -0.00268555 73.1654L-0.00268555 77.2314C6.54289 82.1107 5.72469 82.1107 29.4445 99.1803C31.8991 100.807 36.8083 105.686 40.8914 105.686C44.9745 105.686 49.8916 100.807 52.3383 99.9935C76.0581 82.8848 75.2399 82.8848 81.817 78.0368V73.1654C81.817 72.0955 81.6046 71.0361 81.1919 70.0479C80.7792 69.0598 80.1744 68.1623 79.4122 67.4068C78.6499 66.6514 77.7452 66.0529 76.7497 65.6456C75.7543 65.2383 74.6879 65.0302 73.6114 65.0333ZM139.036 40.6605H57.2475C55.0775 40.6605 52.9964 41.5172 51.4619 43.0423C49.9275 44.5674 49.0655 46.6358 49.0655 48.7926V56.9247H73.6114C77.675 56.9285 81.5921 58.4335 84.6017 61.1473C87.6113 63.8611 89.4985 67.5899 89.8967 71.6093L89.9675 71.5546V105.694H139.044C141.214 105.694 143.295 104.837 144.829 103.312C146.363 101.787 147.225 99.7184 147.225 97.5617V48.7769C147.221 46.6215 146.356 44.5559 144.821 43.034C143.285 41.5121 141.204 40.6584 139.036 40.6605ZM130.854 73.1732H114.505V56.9012H130.862L130.854 73.1732ZM40.9071 48.7769C40.9113 44.4687 42.6334 40.3378 45.6963 37.2892C48.7591 34.2407 52.9129 32.5231 57.2475 32.5127H114.498V8.13209C114.498 5.97668 113.637 3.90944 112.104 2.3846C110.571 0.859759 108.492 0.00207251 106.324 0L24.5353 0C22.3653 0 20.2842 0.856772 18.7498 2.38183C17.2154 3.9069 16.3534 5.97533 16.3534 8.13209V56.9012H40.9071V48.7769Z"
        fill="#6694D7"
      />
      <path
        d="M136.262 127.481C147.449 127.481 156.517 118.413 156.517 107.227C156.517 96.0407 147.449 86.9725 136.262 86.9725C125.076 86.9725 116.008 96.0407 116.008 107.227C116.008 118.413 125.076 127.481 136.262 127.481Z"
        fill="white"
      />
      <path
        d="M136.659 84.0013C132.042 84.0013 127.529 85.3704 123.69 87.9354C119.852 90.5004 116.86 94.1462 115.093 98.4116C113.326 102.677 112.864 107.371 113.764 111.899C114.665 116.427 116.888 120.586 120.153 123.851C123.418 127.116 127.577 129.339 132.105 130.24C136.633 131.14 141.327 130.678 145.592 128.911C149.858 127.144 153.504 124.153 156.069 120.314C158.634 116.475 160.003 111.962 160.003 107.345C160.003 104.279 159.4 101.243 158.227 98.411C157.054 95.5787 155.334 93.0051 153.167 90.8374C150.999 88.6696 148.425 86.9502 145.593 85.7772C142.761 84.6043 139.725 84.0008 136.659 84.0013ZM146.816 109.138H138.455V117.502C138.446 117.972 138.252 118.419 137.916 118.748C137.581 119.077 137.13 119.261 136.66 119.261C136.19 119.261 135.738 119.077 135.403 118.748C135.067 118.419 134.874 117.972 134.864 117.502V109.141H126.503C126.033 109.131 125.586 108.938 125.257 108.602C124.928 108.266 124.744 107.815 124.744 107.345C124.744 106.875 124.928 106.424 125.257 106.088C125.586 105.753 126.033 105.559 126.503 105.55H134.863V97.1885C134.873 96.7187 135.066 96.2713 135.402 95.9425C135.738 95.6136 136.189 95.4294 136.659 95.4294C137.129 95.4294 137.58 95.6136 137.916 95.9425C138.251 96.2713 138.445 96.7187 138.454 97.1885V105.549H146.816C147.286 105.558 147.733 105.752 148.062 106.088C148.391 106.423 148.575 106.874 148.575 107.344C148.575 107.814 148.391 108.266 148.062 108.601C147.733 108.937 147.286 109.13 146.816 109.14V109.138Z"
        fill="#FC7E00"
      />
    </svg>
  );
};

export default TemplatesIcon;
