import { useMemo } from 'react';

export const AccordionSkeleton = ({ cards = 4 }) => {
  const data: boolean[] = useMemo(() => new Array<boolean>(cards).fill(false), [cards]);

  const mapper = () => (
    <div className="flex w-full animate-pulse items-center gap-1 py-1">
      <div className={'size-4 rounded-md bg-gray-300'}></div>
      <div className="h-4 w-40 max-w-full rounded-md bg-gray-300 "></div>
    </div>
  );

  return <div className="flex flex-col">{data.map(mapper)}</div>;
};
