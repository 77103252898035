import classNames from 'classnames';
import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface HeaderLoginProps {
  title?: string;
  subTitle?: string;
  className?: string;
}

const HeaderLogin = ({ title, subTitle, className }: HeaderLoginProps) => {
  const classes = classNames('flex flex-col items-center gap-1 w-full', className);
  /** cspell:disable */
  return (
    <div className={classes}>
      {/* <div className="mb-4 !h-20 !w-20">
        <IconSvg className="!h-20 !w-20" svgComponent={<LoginIcon />} />
      </div> */}
      <Text as="h1" fontWeight="medium" variant="headline">
        {title}
      </Text>
      <Text className="text-emblue-disabled" variant="text">
        {subTitle}
      </Text>
    </div>
  );
};

export default memo(HeaderLogin);
