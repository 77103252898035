import { useNavigate } from 'react-router-dom';

import {
  IModuleItem,
  IModuleItems,
  INavigationProps,
} from '@/lib/v2/components/SmartFinder/SmartFinder.interface';

const SubItems = ({ data }: IModuleItem) => {
  const navigate = useNavigate();

  return (
    <>
      {data.map((item) => (
        <button
          key={item.id}
          className="ml-6 flex items-center space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
          onClick={() => item.handleOnClick && item.handleOnClick({ navigate })}
        >
          <span>{item.title}</span>
        </button>
      ))}
    </>
  );
};

const ModuleItems = ({ data }: IModuleItems) => {
  const navigate = useNavigate();

  return (
    <>
      {data.map((item) => (
        <div key={item.id}>
          <button
            className="flex items-center space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
            onClick={() => item.handleOnClick && item.handleOnClick({ navigate })}
          >
            <div className="text-gray-200">{item.icon}</div>
            <span>{item.title}</span>
          </button>
          <SubItems data={item.children} />
        </div>
      ))}
    </>
  );
};

const Navigation = ({ data }: INavigationProps) => {
  const results = data.filter((item) => item.findMatch);

  if (results.length === 0) return null;

  return (
    <div>
      <div className="sticky top-[57px] border-t border-gray-400 bg-gray-300 px-6 py-2 font-semibold text-gray-700 outline-0">
        Navigation
      </div>
      <div className="flex flex-col space-y-2 px-6 py-4">
        <ModuleItems data={results} />
      </div>
    </div>
  );
};

export default Navigation;
