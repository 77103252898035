export const StatusIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-0.318 0.164)">
        <rect fill="none" height="16" transform="translate(0.318 -0.164)" width="16" />
        <g data-name="Grupo 7594" id="Grupo_7594" transform="translate(2.731 2.327)">
          <path
            d="M14.237,8.618A5.618,5.618,0,1,1,8.618,3,5.618,5.618,0,0,1,14.237,8.618Z"
            fill="none"
            stroke="#004dbc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-3 -3)"
          />
          <path
            d="M13.6,10.485a.234.234,0,0,1,0,.41l-3.5,1.943a.234.234,0,0,1-.348-.2V8.746a.234.234,0,0,1,.348-.2Z"
            fill="none"
            stroke="#004dbc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-5.536 -5.071)"
          />
        </g>
      </g>
    </svg>
  );
};
