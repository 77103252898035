import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';

import { atomActionsList, atomActionType } from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomCampaignList,
  atomDetailCampaign,
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

export const useResetAtomOnUnmount = () => {
  const setTotalsProcess = useSetAtom(atomTotalsProcess);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);
  const setCampaignList = useSetAtom(atomCampaignList);
  const setActionType = useSetAtom(atomActionType);

  const setActionsList = useSetAtom(atomActionsList);
  const setDetailCampaign = useSetAtom(atomDetailCampaign);

  const resetAtomActionsList = () => {
    setActionsList(RESET);
    setDetailCampaign(RESET);
    setActionType(RESET);
    setTotalsProcess(RESET);
  };

  const resetAtomProcess = () => {
    setCampaignList(RESET);
    setTotalsProcess(RESET);
    setLoadingProcess(RESET);
  };

  return { resetAtomProcess, resetAtomActionsList };
};
