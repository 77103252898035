import { ItemsListProps } from '@/lib/components/DropDown/DropDown';

export const useFilterElement = (
  list: ItemsListProps[],
  valuesSelect: string[],
  searchItem: string
) => {
  const foundItemCoincidences = (item: ItemsListProps) => {
    if (searchItem === '') {
      return true;
    } else {
      const regex = new RegExp(searchItem, 'i');
      return regex.test(item.title);
    }
  };

  const elements = list
    ?.filter((element: ItemsListProps) => foundItemCoincidences(element))
    .map((item) => {
      const state = valuesSelect?.includes(item.id) || false;
      return {
        id: item.id,
        state,
        title: item.title,
        count: item.count,
        icon: item.icon,
        onAction: item.onAction,
      };
    });

  return [elements];
};
