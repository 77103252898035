/* cspell:disable */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import configData from '@/src/config.json';
import { useHtmlEditorService } from '@/src/infrastructure/Protocol/HtmlEditor/useHtmlEditorService';
import brokenImage from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/brokenImage.svg';
import { cleanerHTML } from '@/src/modules/CampaignsModule/components/HtmlUpload/util/htmlModifiers';

import { useHtml } from './useHtml';

import { NpsWidgets } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

export const useWidgetsNps = () => {
  const isInsertWidgetActive = useFeatureFlag('insertWidgetNpsInHTML');
  const { t } = useTranslation(['editorHtml']);

  const [widgets, setWidgets] = useState<NpsWidgets[]>([]);
  const serviceHtmlEditor = useHtmlEditorService();
  const { sendElementID: actionId } = useHtml();

  const landingPageUrl = configData.LANDING_URL;

  const getWidgets = useCallback(async () => {
    try {
      const widgetList = await serviceHtmlEditor.getWidgetsList();

      setWidgets(widgetList);
    } catch (error) {
      console.error(error);
    }
  }, [serviceHtmlEditor]);

  useEffect(() => {
    void getWidgets();
  }, [getWidgets]);

  const getWidgetToken = useCallback(
    async (widgetId: number) => {
      try {
        const token = await serviceHtmlEditor.generateWidgetToken(widgetId);
        return token || '';
      } catch (error) {
        console.error(error);
      }
    },
    [serviceHtmlEditor]
  );

  const widgetsNameList = widgets && widgets.map((obj: { name: string }) => obj.name);

  const getWidgetModalInfo = async (widgetName: string) => {
    const selectedWidget =
      widgets && widgets.find((obj: { name: string }) => obj.name === widgetName);

    const widgetToken = await getWidgetToken((selectedWidget && selectedWidget.id) ?? 0);

    const defaultOnErrorImage = (element: any) => {
      const traditionalSelector = document.querySelectorAll('.traditionalWidget');
      traditionalSelector.forEach((element: any) => {
        element.style.pointerEvents = 'none';
        element.style.cursor = 'not-allowed';
      });

      const parent = element.parentNode;
      parent.style.boxShadow = '0px 0px 10px 0px rgba(0,0,0,0.2)';
      parent.style.padding = '0px 20px 30px 20px';
      parent.style.borderRadius = '5px';
      parent.style.maxWidth = '200px';
      parent.style.marginTop = '30px';

      const paragraphElement = parent.previousElementSibling;
      paragraphElement.textContent = `${t('NPS.selectVisualizationError')}`;

      const newElement = document.createElement('div');
      newElement.textContent = `${t('NPS.onErrorImageMessage')}`;
      parent.appendChild(newElement);
      newElement.style.whiteSpace = 'nowrap';

      element.src = brokenImage;
      element.style.width = '75px';
      element.style.marginTop = '40px';
    };

    const error = `
        <div id="widgetSelectionModal" class="fixed bottom-0 left-0 right-0 top-0 flex items-start justify-end">
          <div class="pointer-events-auto mt-7 mr-5 w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-3 ring-black/5" data-testid="notification-component" id="5">
            <div class="h-1 w-full bg-gray-200"><div class="bg-emblue-primary h-1" style="width: 81%;"></div></div>
            <div class="p-4">
              <div class="flex items-start">
                <div class="shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-yellow-500"><path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5 text-left">
                  <p class="text-sm font-medium text-gray-900">${t('NPS.errorTitle')}</p>
                  <p class="mt-1 text-sm text-gray-500">${t('NPS.errorDescription')}</p>
                </div>
                <div class="ml-4 flex shrink-0">
                  <button class="closeButton inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" type="button">
                    <span class="sr-only">Close</span><svg data-name="Icon ionic-md-close" height="12" id="Icon_ionic-md-close" viewBox="0 0 20.953 20.953" width="12"><path d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z" data-name="Icon ionic-md-close" fill="#919296" id="Icon_ionic-md-close-2" transform="translate(-7.523 -7.523)"></path></svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        `;

    const traditionalWidget = `
    <a type="widgetNps" data-href="${landingPageUrl}/?token=${widgetToken}&actionId=${actionId}&uid=\${0#email}" href="${landingPageUrl}/?token=${widgetToken}&actionId=${actionId}&uid=\${0#email}" target="_blank" >
        <img id="${selectedWidget?.name}" type="widgetNps" src="${
      selectedWidget && selectedWidget?.imageUrl
    }" alt="${
      selectedWidget && selectedWidget.title
    }" style="display:block;width:500px;max-width:800px;cursor:pointer;margin-left: auto; margin-right: auto;">
        </a>
        `;

    const smileyWidget = `
        <table contenteditable="false" id="${
          selectedWidget?.name
        }" type="widgetNps" align="center" role="img" style="width: 350px; height: 200px;">
          <tbody type="widgetNps">
            <tr type="widgetNps">
              <td type="widgetNps" style="font-size: 1.25rem; font-weight: bold; border: none" colspan="3"><p type="widgetNps">${
                selectedWidget && cleanerHTML(selectedWidget?.title)
              }</p></td>
            </tr>
            <tr type="widgetNps">
              <td type="widgetNps" colspan="3" style="margin-top: 0.5rem; color: #4b5563!important; border: none"><p type="widgetNps">${
                selectedWidget && cleanerHTML(selectedWidget?.description)
              }</p></td>
            </tr>
            <tr type="widgetNps" align="center">
              <td type="widgetNps" style="border: none">
                <a type="widgetNps" data-href="${landingPageUrl}/?token=${widgetToken}&type=detractor&actionId=${actionId}&uid=\${0#email}" href="${landingPageUrl}/?token=${widgetToken}&type=detractor&actionId=${actionId}&uid=\${0#email}" target="_blank" style="pointer-events: none;">
                  <img type="widgetNps" src="https://files.embluemail.com/nps/shared/detractor.png" alt="sad face" style="pointer-events: none; cursor: pointer">
                </a>
              </td>
              <td type="widgetNps" style="border: none">
                <a type="widgetNps" data-href="${landingPageUrl}/?token=${widgetToken}&type=passive&actionId=${actionId}&uid=\${0#email}" href="${landingPageUrl}/?token=${widgetToken}&type=passive&actionId=${actionId}&uid=\${0#email}" target="_blank" style="pointer-events: none;">
                  <img type="widgetNps" src="https://files.embluemail.com/nps/shared/passive.png" alt="neutral face" style="pointer-events: none; cursor: pointer">
                </a>
              </td>
              <td type="widgetNps" style="border: none">
                <a type="widgetNps" data-href="${landingPageUrl}/?token=${widgetToken}&type=promoter&actionId=${actionId}&uid=\${0#email}" href="${landingPageUrl}/?token=${widgetToken}&type=promoter&actionId=${actionId}&uid=\${0#email}" target="_blank" style="pointer-events: none;">
                  <img type="widgetNps" src="https://files.embluemail.com/nps/shared/promoter.png" alt="happy face" style="pointer-events: none; cursor: pointer">
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        `;

    const contentSelector = `
        <div id="widgetSelectionModal" class="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-70">
            <div class="flex justify-center items-center max-w-6xl h-full">
                <div class="bg-white p-8 rounded-lg w-full">
                    <div class="flex justify-end">
                        <button width="26px" height="26px" class="closeButton text-gray-500 font-bold rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                <g id="Grupo_8492" data-name="Grupo 8492" transform="translate(0 0)">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M28.4,9.6l-2.1-2.1-8.345,8.345L9.6,7.5,7.5,9.6l8.345,8.345L7.5,26.294l2.1,2.1,8.345-8.345L26.294,28.4l2.1-2.1-8.345-8.345Z" transform="translate(-5.299 -5.174)" fill="#96a6ce"/>
                                    <rect id="Rectángulo_4222" data-name="Rectángulo 4222" width="26" height="26" transform="translate(0 0)" fill="none"/>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div class="mt-4 ml-2 eb-text eb-text--bold eb-text--headline text-left text-darkBlue">${t(
                      'NPS.selectVisualization'
                    )}</div>
                    <!-- Selectores -->
                    <div class="flex justify-center mt-4 gap-4">
                        <div id="traditional" class="traditionalWidget gap-2 p-8 w-full max-w-2xl flex flex-col items-center justify-start bg-white border-solid border-2 border-gray-200 rounded-lg cursor-pointer transition-all duration-200 hover:border-emblue-primary-hover">
                            <p class="text-midnightBlue eb-text eb-text--text">${t(
                              'NPS.widgetTraditionalUse'
                            )}</p>
                            <div class="m-4 w-96 flex flex-col justify-center align-middle items-center gap-4 text-lg text-bluePrimary font-bold">
                              <img title="onErrorImage" class="w-full h-full" src="${
                                selectedWidget && selectedWidget.imageUrl
                              }" alt="Traditional Widget">
                            </div>
                        </div>
                        <div id="smiley" class="smileyWidget gap-2 p-8 w-full max-w-2xl flex flex-col items-center  bg-white border-solid border-2 border-gray-200 rounded-lg cursor-pointer transition-all duration-200 hover:border-emblue-primary-hover">
                            <p class="text-midnightBlue eb-text eb-text--text">${t(
                              'NPS.widgetSmileyUse'
                            )}</p>
                            <div class="bg-white shadow-lg p-3 m-4 w-96 flex">
                                ${smileyWidget}
                            </div>
                            <p class="self-start text-midnightBlue eb-text eb-text--text-sm">* ${t(
                              'NPS.widgetSmileyText'
                            )}</p>
                            <div class="w-4/5 flex justify-around">
                                <p class="text-center text-midnightBlue b-text eb-text--text-sm">${t(
                                  'NPS.detractor'
                                )} = 3</p>
                                <div class="text-gray-200">|</div>
                                <p class="text-center text-midnightBlue b-text eb-text--text-sm">${t(
                                  'NPS.passive'
                                )} = 7</p>
                                <div class=" text-gray-200">|</div>
                                <p class="text-center text-midnightBlue b-text eb-text--text-sm">${t(
                                  'NPS.promoter'
                                )} = 9</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;

    return {
      widget: selectedWidget,
      traditionalWidget,
      smileyWidget,
      contentSelector,
      error,
      widgetToken,
      defaultOnErrorImage,
    };
  };
  return { widgetsNameList, getWidgetModalInfo, isInsertWidgetActive };
};
