import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';

import { LoadingNotification } from '@/lib/components/LoadingNotification/LoadingNotification';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';

import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

import { atomShowProgressNotification } from '@/modules/ContactsModule/atoms/Steps';

export const StateCard = () => {
  const [showProgressNotification, setShowProgressNotification] = useAtom(
    atomShowProgressNotification
  );
  const { percentage, status, show, description } = showProgressNotification;

  const navigate = useNavigate();

  const handler = () => {
    navigate(`v2/${RouteEnum.ContactsImport}`);
    localStorage.setItem('@import/outside', 'false');

    if (showProgressNotification.status === loadingStatus.ready) setShowProgressNotification(RESET);
  };

  if (!show) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-[999] w-fit p-6">
      <button onClick={handler}>
        <LoadingNotification
          description={description}
          id={'import-loading-notification'}
          percentage={percentage}
          status={status}
        />
      </button>
    </div>
  );
};
