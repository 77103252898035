import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { useFiltersCampaign } from '@/modules/CampaignsModule/hooks/useFiltersCampaign';
import { EFilterKeysCampaign } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputDate = () => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersCampaign();
  const filtersCampaign = useAtomValue(atomFiltersCampaign);

  const id = 'date-campaigns';
  const placeholder = t('CAMPAIGNS_MAIN.FILTERS.update');

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersCampaign?.startDate ?? null,
    filtersCampaign?.endDate ?? null,
  ]);

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        event[0] && event[1] && updateFilter(EFilterKeysCampaign.Date, [event[0], event[1]]);
      }
    },
    [updateFilter]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    id,
    dateRange,
    placeholder,
    handleOnClickDate,
    resetDateRange,
  };
};
