import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Modal, Option, Select, Text, toast } from '@/lib/v2/components';

import { BillingFormState as BillingFormStateBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomBillingInformation } from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';

import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { BillingInfo } from '@/modules/MyPlanModule/types';

interface ModalInformationProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  canEdit?: boolean;
}

export type BillingFormState = {
  businessName: string;
  fantasyName: string;
  rut: string;
  address: string;
  country?: Option;
  billingContact: string;
  billingEmail: string;
};

const ModalInformation = ({ isOpen, onClose, canEdit }: ModalInformationProps) => {
  const { t } = useTranslation();
  const service = useService();
  const [billingInformation, setBillingInformation] = useAtom(atomBillingInformation);
  const { getCountryOption, countriesOptions } = useCountriesOptions();
  const [updateInfoIsLoading, setUpdateInfoIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
    trigger,
  } = useForm<BillingFormState>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (billingInformation && isOpen) {
      setValue('businessName', billingInformation.businessName ?? '');
      setValue('fantasyName', billingInformation.fantasyName ?? '');
      setValue('rut', billingInformation.rut ?? '');
      setValue('address', billingInformation.address?.toString() ?? '');
      setValue('country', getCountryOption(billingInformation.country ?? ''));
      setValue('billingContact', billingInformation.billingContact ?? '');
      setValue('billingEmail', billingInformation.billingEmail ?? '');
      void trigger();
    }
  }, [billingInformation, getCountryOption, isOpen, trigger, setValue]);

  const RULES: Record<keyof BillingFormState, object> = {
    businessName: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    fantasyName: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    rut: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
      maxLength: {
        value: 15,
        message: t('MY_PLAN.BILLING.MODAL_INFORMATION.maxlengthRut'),
      },
    },
    address: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    country: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    billingContact: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    billingEmail: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
      pattern: {
        value: /^[\w.%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        message: t('AUTH.LOGIN.invalidEmail'),
      },
    },
  };

  const getBillingInformation = useCallback(async () => {
    const response = await service.getBillingInformation();
    setBillingInformation(JSON.parse(JSON.stringify(response)) as BillingInfo);
  }, [service, setBillingInformation]);

  const handleUpdateData = handleSubmit(async (data) => {
    setUpdateInfoIsLoading(true);
    const billingInfoBody: BillingFormStateBody = { ...data, country: Number(data.country?.value) };
    const response = await service.updateBillingInfo(billingInfoBody);
    if (response.success) {
      await getBillingInformation();
      toast({
        id: 'success',
        title: t('MY_PLAN.BILLING.MODAL_INFORMATION.success'),
      });
    } else {
      toast({
        id: 'error',
        variant: 'error',
        title: t('MY_PLAN.BILLING.MODAL_INFORMATION.error'),
      });
    }
    setUpdateInfoIsLoading(false);
    onClose(false);
  });

  return (
    <Modal withAuto open={isOpen} onClose={onClose}>
      <div className="relative w-full sm:w-5/6 md:w-[650px]">
        <div className="w-full border-b pb-6">
          <Text fontWeight="medium" variant="sub-headline">
            {t('MY_PLAN.BILLING.MODAL_INFORMATION.title')}
          </Text>
        </div>
        <form className="mt-8 flex w-full flex-col gap-4" onSubmit={handleUpdateData}>
          <div className="flex gap-4">
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="businessName"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.businessName')}
              name="businessName"
              rules={RULES.businessName}
            />
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="fantasyName"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.fantasyName')}
              name="fantasyName"
              rules={RULES.fantasyName}
            />
          </div>
          <div className="flex gap-4">
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="rut"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.rut')}
              name="rut"
              rules={RULES.rut}
            />
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="address"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.address')}
              name="address"
              rules={RULES.address}
            />
          </div>
          <div className="flex gap-4">
            <Select
              isRequired
              withSearch
              classNameMenuContainer="h-[200px]"
              control={control}
              disabled={!canEdit}
              id="country"
              label={t('MANAGE_PLAN.BILLING_FORM.inputFive')}
              name="country"
              options={countriesOptions}
              rules={RULES.country}
            />
            <Input
              disabled
              defaultValue={billingInformation?.contractId.toString()}
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.contractId')}
            />
          </div>
          <div className="flex gap-4">
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="billingContact"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminContact')}
              name="billingContact"
              rules={RULES.billingContact}
            />
            <Input
              isRequired
              control={control}
              disabled={!canEdit}
              id="billingEmail"
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminEmail')}
              name="billingEmail"
              rules={RULES.billingEmail}
            />
          </div>
          <div className="flex gap-4">
            <Input
              disabled
              defaultValue={billingInformation?.executive ?? ''}
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.accountExecutive')}
            />
            <Input
              disabled
              defaultValue={billingInformation?.executiveEmail ?? ''}
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.executiveEmail')}
            />
          </div>
          <div className="flex w-1/2 pr-2">
            <Input
              disabled
              defaultValue={billingInformation?.appAdminEmail}
              label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminPlatform')}
            />
          </div>
          <div className="mt-4 flex w-full justify-end gap-2">
            <Button outline size="small" onClick={() => onClose(false)}>
              {t('MY_PLAN.BILLING.MODAL_INFORMATION.cancel')}
            </Button>
            <Button
              disabled={!isValid || !canEdit}
              isLoading={updateInfoIsLoading}
              size="small"
              type="submit"
            >
              {t('MY_PLAN.BILLING.MODAL_INFORMATION.save')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default memo(ModalInformation);
