import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { useUserData } from '@/src/infrastructure/services/useUserData';

export const useCanChangePlan = (planName?: string) => {
  const [userData] = useUserData();
  if (!planName || !userData) return { canChangePlan: undefined };
  const embluemailAllowedPlans: PlanType[] = ['Free Trial', 'Free', 'Starter', 'Advance'];
  const clientAllowedPlans: PlanType[] = ['Free', 'Starter', 'Advance'];

  let canChangePlan: boolean;

  if (userData.email.includes('@embluemail.com')) {
    canChangePlan = embluemailAllowedPlans.some((plan) => plan === planName);
  } else {
    canChangePlan = clientAllowedPlans.some((plan) => plan === planName);
  }

  return { canChangePlan };
};
