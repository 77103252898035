import classNames from 'classnames';
import { CountryCode, E164Number } from 'libphonenumber-js/types';
import { forwardRef, memo } from 'react';
import PhoneInput, {
  DefaultInputComponentProps,
  isValidPhoneNumber,
  Props,
} from 'react-phone-number-input';

import { withController } from '@/lib/v2/HOCs/WithController';

import 'react-phone-number-input/style.css';
import './SelectPhoneNumber.tailwind.css';

interface SelectPhoneNumberProps extends Omit<Props<DefaultInputComponentProps>, 'onChange'> {
  id?: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  value?: E164Number;
  onChange?: (phoneNumber?: string, phoneNumberIsValid?: boolean) => void;
  defaultCountry?: CountryCode;
  error?: boolean;
  message?: string;
}
//TO DO:errors and validation with withController HOC react-hook-form
const SelectPhoneNumber = forwardRef<any, SelectPhoneNumberProps>(
  (
    {
      value,
      onChange,
      defaultCountry = 'AR',
      label,
      name,
      id,
      isRequired = false,
      error,
      message,
    }: SelectPhoneNumberProps,
    ref
  ) => {
    const handleOnChange = (value: E164Number) => {
      onChange?.(value, value ? isValidPhoneNumber(value) : false);
    };

    const classesPhoneInput = classNames('select-phone-number', {
      'input-error': error,
    });
    const classesMessage = classNames(error ? 'message-error' : 'eb-input--message-info');

    return (
      <div {...(id && { id })} className="flex w-full flex-col">
        {label && (
          <label className="label" htmlFor={name}>
            {label} {isRequired && <span className="text-red-500"> * </span>}
          </label>
        )}
        <PhoneInput
          ref={ref}
          {...(id && { id: `${id}-input` })}
          {...(name && { name })}
          international
          className={classesPhoneInput}
          defaultCountry={defaultCountry}
          value={value}
          onChange={handleOnChange}
        />
        <p className={classesMessage} id={`${name}-message`}>
          {message}
        </p>
      </div>
    );
  }
);

export default memo(withController(SelectPhoneNumber));
