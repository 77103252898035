import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/lib/components/Button/Button';
import { Card } from '@/lib/components/Card/Card';
import { SupportCard } from '@/lib/components/SupportCard';
import { Heading } from '@/lib/components/Text/Heading';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { useVersion } from '@/src/application/hooks/useVersion';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import { LastActionsReport } from '@/src/presentation/components/LastActionsReport';
import {
  CreateCampaignCard,
  CreateContactCard,
  CreateSmsCard,
  CreateWidgetCard,
} from '@/src/presentation/components/Main/Cards';
import EmblueAcademyCard from '@/src/presentation/components/Main/components/EmblueAcademyCard';
import ScoreOverviewCard from '@/src/presentation/components/Main/components/ScoreOverviewCard';
import { goToAllReportsHandler, ReportsTable } from '@/src/presentation/components/ReportsTable';
import { courseAcademyUrls } from '@/src/presentation/constants/urlConstants';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';
import { IReport } from '@/src/presentation/types/interfaces/IReport.interface';
import { ReportData } from '@/src/presentation/types/types';
import { DashboardIcon, ReportIconTable } from '@/src/presentation/util/icon';

export type MainComponentProps = {
  userData: UserData;
  senderScore?: number;
  reports?: IReport[];
  freeTrialBlock?: IFreeTrialDemo;
};

export const MainComponent = ({ userData, reports }: MainComponentProps) => {
  const { t } = useTranslation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const showFinishedActionsDashboard = useFeatureFlag('showFinishedActionsDashboard');

  const showCampaignV1 = useAtomValue(atomShowCampaignsV1);

  return (
    <div className="bg-emblueLightGray">
      <SupportCard />
      <div className="mx-8 min-h-screen">
        <section className={`flex flex-row justify-between py-6 ${showCampaignV1 ? '' : 'hidden'}`}>
          <Flex noGrow withGap itemAlignment="center">
            <div className="h-[37px] w-9 rounded-[5px] bg-emblue-blue p-[7px]">
              <DashboardIcon />
            </div>
            <p className="title text-5xl font-medium">{t('home')}</p>
          </Flex>
          {/*freeTrialBlock?.showCounter && !freeTrialBlock?.isBlocked && (
              <DemoTrialCounter freeTrialBlock={freeTrialBlock} />
            )*/}
        </section>

        <div className={`flex w-full grid-cols-4 gap-6 pb-8  ${showCampaignV1 ? '' : 'hidden'}`}>
          <CreateContactCard userData={userData} />
          <CreateCampaignCard userData={userData} />
          <CreateSmsCard userData={userData} />
          <CreateWidgetCard userData={userData} />
        </div>
        <div className={`grid w-full grid-cols-4 gap-6 pb-8 ${!showCampaignV1 ? 'pt-8' : ''}`}>
          <article className="col-span-3 rounded-lg bg-white">
            {showFinishedActionsDashboard ? (
              <LastActionsReport userData={userData} />
            ) : (
              <Card>
                <Card.Header extend>
                  <Heading>{t('REPORT_TABLE.title')}</Heading>
                  <Button
                    Icon={<ReportIconTable />}
                    onAction={() => goToAllReportsHandler(userData, version, navigate)}
                  >
                    {t('REPORT_TABLE.goToReports')}
                  </Button>
                </Card.Header>
                <Card.Body padded>
                  <div className="min-h-[400px]">
                    <ReportsTable {...{ reports: reports, userData }} />
                  </div>
                </Card.Body>
              </Card>
            )}
          </article>
          <Flex column withGap gapSize="medium">
            <ScoreOverviewCard />
            <EmblueAcademyCard
              courseNameUrl={courseAcademyUrls.platformAcademy}
              thumbnailsUrl={courseAcademyUrls.youtubeThumbnail}
            />
          </Flex>
        </div>
      </div>
    </div>
  );
};

const MainContainer = ({
  userData,
  reports,
  counter,
}: {
  userData?: UserData;
  reports?: ReportData[];
  counter?: IFreeTrialDemo;
}) => {
  return (
    <MainComponent
      freeTrialBlock={counter}
      reports={reports}
      userData={userData || ({} as UserData)}
    />
  );
};

export default MainContainer;
