/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IDashboardService } from './IDashboardService';

import {
  IDashboardParamStart,
  ISenderActivity,
  ISenderScore,
} from '@/modules/DashboardsModule/interfaces/Dashboard';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class DashboardService implements IDashboardService {
  private apiV1: APIService;

  private constructor(payload: IDashboardParamStart) {
    this.apiV1 = new APIService(payload.url_ema_app_v1, payload.jwt);
  }

  /**
   * Handles errors from API requests.

   * @param {AxiosError<Error>} error - The Axios error.
   * @param {string} method - The method name where the error occurred.
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error DashboardService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of DashboardService
   * @param payload - The payload for the DashboardService.
   * @returns An instance of the DashboardService.
   */
  static create(payload: IDashboardParamStart): IDashboardService {
    return new DashboardService(payload);
  }

  /**
   * @desc Retrieves sender score overview data.
   * @returns A promise resolving to an array of ISenderScore objects, or null if an error occurs.
   */
  async getDataScoreOverview(): Promise<ISenderScore[] | null> {
    try {
      const result = await this.apiV1.get(`api/dashboard/GetSenderScoreInTime?days=90`);
      return result.data as ISenderScore[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getDataScoreOverview');
      //cspell: disable
      return null;
    }
  }

  /**
   * @desc Retrieves the sender activity from the dashboard.
   * @returns A promise resolving to an array of ISenderActivity objects, or null if an error occurs.
   */
  async getSenderActivity(): Promise<ISenderActivity[] | null> {
    try {
      const { data } = await this.apiV1.get(`api/dashboard/GetSenderActivity`);
      return data as ISenderActivity[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getSenderActivity');
      return null;
    }
  }
}

export const DashboardServiceMethods: Record<string, string> = Object.getOwnPropertyNames(
  DashboardService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof DashboardService);
