import { SearchCampaignsItems } from '@/src/modules/CampaignsModule/components/SearchCampaigns/components/SearchCampaignsItems';

import { SearchLoader } from './components/SearchLoader';

import { IListSearchCampaigns } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface SearchCampaignsListProps {
  list: IListSearchCampaigns[] | undefined;
  loading?: boolean;
}

const SearchCampaignsList = ({ list, loading }: SearchCampaignsListProps) => {
  return <>{loading ? <SearchLoader /> : <>{list && <SearchCampaignsItems list={list} />}</>}</>;
};

export default SearchCampaignsList;
