const SummarySkeleton = () => {
  return (
    <div className="w-full">
      <section className="mb-12 animate-pulse">
        <div className="mb-2.5 h-5 w-full rounded-full bg-gray-300 pb-2"></div>
        <div className="mt-6">
          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
        </div>
        <div className="mt-6">
          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
        </div>
      </section>
      <section className="mb-12 animate-pulse">
        <div className="mb-2.5 h-5 w-full rounded-full bg-gray-300 pb-2"></div>
        <div className="mt-6">
          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
        </div>
      </section>
      <section className="mb-12 animate-pulse">
        <div className="mb-2.5 h-5 w-full rounded-full bg-gray-300 pb-2"></div>
        <div className="mt-6">
          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
        </div>
        <div className="mt-6">
          <div className="w- mb-3 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="h-3 w-300 rounded-full bg-gray-300 "></div>
        </div>
      </section>
    </div>
  );
};

export default SummarySkeleton;
