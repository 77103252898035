export const ReloadIcon = () => {
  return (
    <>
      <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Group 7410" id="Group_7410" transform="translate(-1672 -287)">
          <rect
            data-name="Rectangle 4195"
            fill="none"
            height="20"
            id="Rectangle_4195"
            transform="translate(1672 287)"
            width="20"
          />
          <path
            d="M19.675,8.353a8.013,8.013,0,1,0,2.083,7.661H19.675A6.006,6.006,0,1,1,14.017,8a5.924,5.924,0,0,1,4.226,1.783L15.018,13.01h7.01V6Z"
            data-name="Icon material-refresh"
            fill="#364365"
            id="Icon_material-refresh"
            transform="translate(1667.979 282.988)"
          />
        </g>
      </svg>
    </>
  );
};
