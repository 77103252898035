import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components';

import FilterChannel from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer/components/FilterBar/components/FilterChannel';
import FilterStatus from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer/components/FilterBar/components/FilterStatus';

import { useFiltersDropdown } from './useFiltersDropdown';

import { atomActionType } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { ECampaignsTypeOptions } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IFiltersProps {
  onClose?: () => void;
}
const FiltersDropdown = ({ onClose }: IFiltersProps) => {
  const { t } = useTranslation();

  const actionType = useAtomValue(atomActionType);
  const detailCampaign = useAtomValue(atomDetailCampaign);

  const {
    filtersApplied,
    isNotDraftOrFinished,
    onApplyFilters,
    setSelectedChannel,
    setSelectedStatus,
  } = useFiltersDropdown(onClose);

  const containerClasses = classNames(
    'flex flex-col w-300 overflow-y-auto overflow-y-scroll overflow-x-hidden ',
    {
      hidden: filtersApplied,
    }
  );

  return (
    <div className={containerClasses} id="filters-actions">
      {isNotDraftOrFinished && <FilterStatus currentItems={setSelectedStatus} />}

      {(actionType || detailCampaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL) && (
        <FilterChannel currentItems={setSelectedChannel} />
      )}
      <div className="flex justify-end">
        <Button id="button-apply-filters-actions" onClick={onApplyFilters}>
          {t('TABLE_FILTER_BAR.apply')}
        </Button>
      </div>
    </div>
  );
};

export default memo(FiltersDropdown);
