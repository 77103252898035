import { MouseEventHandler } from 'react';

import { Button, Flex } from '@/lib/v2/components';

type IButtonActionsProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  alignment?: 'center' | 'spaceEvenly' | 'spaceBetween' | 'start' | 'end';
  disabledSubmitButton?: boolean;
  hideCancelButton?: boolean;
  textCancelButton?: string;
  textSubmitButton?: string;
  isLoading?: boolean;
};

const ButtonActions = ({
  onClick,
  onClose,
  alignment = 'end',
  disabledSubmitButton,
  hideCancelButton,
  textCancelButton,
  textSubmitButton,
  isLoading,
}: IButtonActionsProps) => {
  return (
    <footer className="pt-7">
      <Flex withGap alignment={alignment} gapSize="small">
        {!hideCancelButton && (
          <Button outline id="cancel" size="small" onClick={onClose}>
            {textCancelButton}
          </Button>
        )}
        <Button
          disabled={disabledSubmitButton}
          id="submit"
          isLoading={isLoading}
          size="small"
          typeButton="submit"
          onClick={onClick}
        >
          {textSubmitButton}
        </Button>
      </Flex>
    </footer>
  );
};

export default ButtonActions;
