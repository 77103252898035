/* cSpell:disable */

type StatusIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const StatusIcon = ({ tableSize, stroked = true }: StatusIconProps) => {
  return (
    <>
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 6145" id="Grupo_6145" transform="translate(0.135)">
            <rect
              data-name="Rectángulo 3178"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3178"
              transform="translate(-0.135)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M9.977,2a7.977,7.977,0,1,0,7.977,7.977A7.98,7.98,0,0,0,9.977,2Zm0,14.358a6.381,6.381,0,1,1,6.381-6.381A6.39,6.39,0,0,1,9.977,16.358Zm3.1-9.341-4.69,4.69-1.5-1.5a.8.8,0,1,0-1.125,1.125L7.823,13.4a.794.794,0,0,0,1.125,0L14.2,8.142a.794.794,0,0,0,0-1.125A.8.8,0,0,0,13.072,7.017Z"
              data-name="Trazado 3216"
              fill="#004dbc"
              id="Trazado_3216"
              transform="translate(-0.674 0.024)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
