// cspell:disable
export const LastTag1DayIcon = () => {
  return (
    <svg
      height="46.165"
      viewBox="0 0 51.75 46.165"
      width="51.75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        data-name="calendar-svgrepo-com (2)"
        id="calendar-svgrepo-com_2_"
        transform="translate(-0.75 -7.135)"
      >
        <g id="_x30_19_Select_Product" transform="translate(0.75 -51.801)">
          <g data-name="Grupo 7726" id="Grupo_7726" transform="translate(0 58.936)">
            <g data-name="Grupo 7725" id="Grupo_7725" transform="translate(0.388 0.388)">
              <g data-name="Grupo 7689" id="Grupo_7689">
                <g data-name="Grupo 7678" id="Grupo_7678" transform="translate(2.18)">
                  <path
                    d="M71,65.766V95.609H26.473V65.766A2.83,2.83,0,0,1,29.3,62.935H68.172A2.83,2.83,0,0,1,71,65.766Z"
                    data-name="Trazado 4563"
                    fill="#d3d9d9"
                    id="Trazado_4563"
                    transform="translate(-26.473 -62.935)"
                  />
                </g>
                <g data-name="Grupo 7679" id="Grupo_7679" transform="translate(41.06 0.001)">
                  <path
                    d="M432.956,65.781v29.84h-2.82V65.781a2.83,2.83,0,0,0-2.828-2.832h2.82A2.83,2.83,0,0,1,432.956,65.781Z"
                    data-name="Trazado 4564"
                    fill="#a8b2b4"
                    id="Trazado_4564"
                    transform="translate(-427.308 -62.949)"
                  />
                </g>
                <g data-name="Grupo 7680" id="Grupo_7680" transform="translate(2.18 0.001)">
                  <path
                    d="M32.117,62.949a2.83,2.83,0,0,0-2.828,2.832v29.84H26.473V65.781A2.827,2.827,0,0,1,29.3,62.949Z"
                    data-name="Trazado 4565"
                    fill="#ededed"
                    id="Trazado_4565"
                    transform="translate(-26.473 -62.949)"
                  />
                </g>
                <g data-name="Grupo 7681" id="Grupo_7681" transform="translate(4.999 2.047)">
                  <path
                    d="M55.538,111.846V84.823a.784.784,0,0,1,.782-.785H93.645a.784.784,0,0,1,.781.785v27.023Z"
                    data-name="Trazado 4566"
                    fill="#d4f0fc"
                    id="Trazado_4566"
                    transform="translate(-55.538 -84.038)"
                  />
                </g>
                <g data-name="Grupo 7682" id="Grupo_7682" transform="translate(4.612 1.659)">
                  <path
                    d="M90.816,108.621H51.928a.388.388,0,0,1-.388-.388V81.21a1.172,1.172,0,0,1,1.169-1.173H90.035A1.172,1.172,0,0,1,91.2,81.21v27.023A.388.388,0,0,1,90.816,108.621Zm-38.5-.776H90.428V81.21a.4.4,0,0,0-.393-.4H52.709a.4.4,0,0,0-.393.4Z"
                    data-name="Trazado 4567"
                    fill="#333"
                    id="Trazado_4567"
                    transform="translate(-51.54 -80.037)"
                  />
                </g>
                <g data-name="Grupo 7688" id="Grupo_7688" transform="translate(0 32.677)">
                  <g data-name="Grupo 7683" id="Grupo_7683" transform="translate(0 0)">
                    <path
                      d="M52.887,399.829v1.89A2.887,2.887,0,0,1,50,404.6H6.887A2.887,2.887,0,0,1,4,401.719v-1.89Z"
                      data-name="Trazado 4568"
                      fill="#a8b2b4"
                      id="Trazado_4568"
                      transform="translate(-4.001 -399.829)"
                    />
                  </g>
                  <g data-name="Grupo 7684" id="Grupo_7684" transform="translate(0 0)">
                    <path
                      d="M9.71,404.6H6.882A2.886,2.886,0,0,1,4,401.717v-1.889H6.824v1.889A2.886,2.886,0,0,0,9.71,404.6Z"
                      data-name="Trazado 4569"
                      fill="#d3d9d9"
                      id="Trazado_4569"
                      transform="translate(-3.996 -399.828)"
                    />
                  </g>
                  <g data-name="Grupo 7685" id="Grupo_7685" transform="translate(43.173)">
                    <path
                      d="M454.8,399.828v1.889a2.886,2.886,0,0,1-2.886,2.886h-2.828a2.886,2.886,0,0,0,2.886-2.886v-1.889Z"
                      data-name="Trazado 4570"
                      fill="#7c8b8e"
                      id="Trazado_4570"
                      transform="translate(-449.091 -399.828)"
                    />
                  </g>
                  <g data-name="Grupo 7686" id="Grupo_7686" transform="translate(17.425 0)">
                    <path
                      d="M197.68,399.829v1.363a.856.856,0,0,1-.856.856H184.5a.856.856,0,0,1-.856-.856v-1.363Z"
                      data-name="Trazado 4571"
                      fill="#fff"
                      id="Trazado_4571"
                      transform="translate(-183.644 -399.829)"
                    />
                  </g>
                  <g data-name="Grupo 7687" id="Grupo_7687" transform="translate(28.315)">
                    <path
                      d="M299.059,399.828v1.364a.857.857,0,0,1-.858.854H295.91a.854.854,0,0,0,.854-.854v-1.364Z"
                      data-name="Trazado 4572"
                      fill="#ededed"
                      id="Trazado_4572"
                      transform="translate(-295.91 -399.828)"
                    />
                  </g>
                </g>
              </g>
              <g data-name="Grupo 7694" id="Grupo_7694" transform="translate(17.566 4.696)">
                <g data-name="Grupo 7690" id="Grupo_7690" transform="translate(0.002 0.002)">
                  <rect
                    data-name="Rectángulo 4266"
                    fill="#fedd8e"
                    height="13.753"
                    id="Rectángulo_4266"
                    width="13.753"
                  />
                </g>
                <g data-name="Grupo 7691" id="Grupo_7691">
                  <path
                    d="M198.848,111.349V125.1H185.093v-2.951h4.695a6.107,6.107,0,0,0,6.11-6.11v-4.695h2.951Z"
                    data-name="Trazado 4573"
                    fill="#fdd367"
                    id="Trazado_4573"
                    transform="translate(-185.093 -111.349)"
                  />
                </g>
                <g data-name="Grupo 7692" id="Grupo_7692" transform="translate(5.208 0.002)">
                  <rect
                    data-name="Rectángulo 4267"
                    fill="#fc8a8a"
                    height="3.864"
                    id="Rectángulo_4267"
                    width="3.341"
                  />
                </g>
                <g data-name="Grupo 7693" id="Grupo_7693" transform="translate(5.208 0.001)">
                  <path
                    d="M242.126,111.361v3.866h-3.344v-1.272h.8a1.27,1.27,0,0,0,1.272-1.272v-1.322Z"
                    data-name="Trazado 4574"
                    fill="#f96364"
                    id="Trazado_4574"
                    transform="translate(-238.782 -111.361)"
                  />
                </g>
              </g>
              <g data-name="Grupo 7699" id="Grupo_7699" transform="translate(15.878 22.332)">
                <g data-name="Grupo 7695" id="Grupo_7695" transform="translate(0.388 0.388)">
                  <path
                    d="M188.053,299.153a1.985,1.985,0,0,1-1.986,1.986H173.685a1.986,1.986,0,1,1,0-3.971h12.382A1.985,1.985,0,0,1,188.053,299.153Z"
                    data-name="Trazado 4575"
                    fill="#7bde9e"
                    id="Trazado_4575"
                    transform="translate(-171.699 -297.167)"
                  />
                </g>
                <g data-name="Grupo 7696" id="Grupo_7696" transform="translate(0.387 0.389)">
                  <path
                    d="M176.189,301.151h-2.515a1.986,1.986,0,1,1,0-3.971h2.515a1.986,1.986,0,0,0,0,3.971Z"
                    data-name="Trazado 4576"
                    fill="#a7eabf"
                    id="Trazado_4576"
                    transform="translate(-171.688 -297.18)"
                  />
                </g>
                <g data-name="Grupo 7697" id="Grupo_7697" transform="translate(12.241 0.389)">
                  <path
                    d="M298.4,299.166a1.985,1.985,0,0,1-1.986,1.986H293.9a1.986,1.986,0,0,0,0-3.972h2.515A1.985,1.985,0,0,1,298.4,299.166Z"
                    data-name="Trazado 4577"
                    fill="#51d27f"
                    id="Trazado_4577"
                    transform="translate(-293.898 -297.18)"
                  />
                </g>
                <g data-name="Grupo 7698" id="Grupo_7698">
                  <path
                    d="M182.453,297.916H170.071a2.374,2.374,0,1,1,0-4.748h12.382a2.374,2.374,0,0,1,0,4.748Zm-12.382-3.972a1.6,1.6,0,0,0,0,3.2h12.382a1.6,1.6,0,1,0,0-3.2Z"
                    data-name="Trazado 4578"
                    fill="#333"
                    id="Trazado_4578"
                    transform="translate(-167.697 -293.168)"
                  />
                </g>
              </g>
              <g data-name="Grupo 7708" id="Grupo_7708" transform="translate(17.18 4.31)">
                <g data-name="Grupo 7700" id="Grupo_7700">
                  <path
                    d="M307.974,215.762l-2.233,1.163,2.849,2.736L307.4,220.9l-2.849-2.736-1.072,2.278-1.934-6.358Z"
                    data-name="Trazado 4579"
                    fill="#fff"
                    id="Trazado_4579"
                    transform="translate(-289.865 -203.734)"
                  />
                  <path
                    d="M200.109,123.022l-2.459-2.362,1.753-.913a.388.388,0,0,0-.081-.72l-3.679-.96v-10.3a.388.388,0,0,0-.388-.388H181.5a.388.388,0,0,0-.388.388v13.753a.388.388,0,0,0,.388.388h12.159l.7,2.293a.388.388,0,0,0,.343.274.383.383,0,0,0,.379-.222l.841-1.789,2.46,2.362a.388.388,0,0,0,.269.108h.008a.39.39,0,0,0,.272-.119l1.19-1.239A.388.388,0,0,0,200.109,123.022ZM189.661,108.15v3.088H187.1V108.15Zm-7.771,12.977V108.15h4.43v3.476a.388.388,0,0,0,.388.388h3.341a.388.388,0,0,0,.388-.388V108.15h4.43v9.714l-1.974-.515a.388.388,0,0,0-.469.488l1,3.289Zm16.749,2.866-2.57-2.468a.389.389,0,0,0-.269-.108.4.4,0,0,0-.071.006.389.389,0,0,0-.281.216l-.642,1.366-1.439-4.73,4.783,1.249-1.339.7a.388.388,0,0,0-.089.624l2.569,2.467Z"
                    data-name="Trazado 4580"
                    fill="#333"
                    id="Trazado_4580"
                    transform="translate(-181.114 -107.374)"
                  />
                </g>
                <g data-name="Grupo 7707" id="Grupo_7707" transform="translate(7.851 6.498)">
                  <g data-name="Grupo 7702" id="Grupo_7702" transform="translate(1.126 1.241)">
                    <g data-name="Grupo 7701" id="Grupo_7701">
                      <path
                        d="M275.158,189.01a.387.387,0,0,1-.269-.108l-1.111-1.067a.388.388,0,1,1,.537-.56l1.111,1.067a.388.388,0,0,1-.269.668Z"
                        data-name="Trazado 4581"
                        fill="#333"
                        id="Trazado_4581"
                        transform="translate(-273.659 -187.166)"
                      />
                    </g>
                  </g>
                  <g data-name="Grupo 7704" id="Grupo_7704" transform="translate(3.959)">
                    <g data-name="Grupo 7703" id="Grupo_7703">
                      <path
                        d="M303.289,176.687a.388.388,0,0,1-.388-.38l-.031-1.541a.388.388,0,0,1,.38-.4.4.4,0,0,1,.4.38l.031,1.541a.388.388,0,0,1-.38.4Z"
                        data-name="Trazado 4582"
                        fill="#333"
                        id="Trazado_4582"
                        transform="translate(-302.869 -174.37)"
                      />
                    </g>
                  </g>
                  <g data-name="Grupo 7706" id="Grupo_7706" transform="translate(0 4.092)">
                    <g data-name="Grupo 7705" id="Grupo_7705">
                      <path
                        d="M262.44,217.365a.388.388,0,0,1-.008-.776l1.54-.031a.388.388,0,1,1,.016.776l-1.54.031Z"
                        data-name="Trazado 4583"
                        fill="#333"
                        id="Trazado_4583"
                        transform="translate(-262.052 -216.558)"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Grupo 7724" id="Grupo_7724" transform="translate(7.013 12.253)">
                <g data-name="Grupo 7713" id="Grupo_7713" transform="translate(0.387 0.387)">
                  <g data-name="Grupo 7710" id="Grupo_7710" transform="translate(30.151 0)">
                    <g data-name="Grupo 7709" id="Grupo_7709">
                      <path
                        d="M395.281,196.918l-3.387,2.842a.47.47,0,0,1-.771-.362V193.72a.47.47,0,0,1,.771-.362l3.387,2.842A.469.469,0,0,1,395.281,196.918Z"
                        data-name="Trazado 4584"
                        fill="#fc8a8a"
                        id="Trazado_4584"
                        transform="translate(-391.123 -193.248)"
                      />
                    </g>
                  </g>
                  <g data-name="Grupo 7712" id="Grupo_7712">
                    <g data-name="Grupo 7711" id="Grupo_7711">
                      <path
                        d="M80.448,196.2l3.387-2.842a.47.47,0,0,1,.771.362V199.4a.47.47,0,0,1-.771.362l-3.387-2.842A.469.469,0,0,1,80.448,196.2Z"
                        data-name="Trazado 4585"
                        fill="#fc8a8a"
                        id="Trazado_4585"
                        transform="translate(-80.281 -193.248)"
                      />
                    </g>
                  </g>
                </g>
                <g data-name="Grupo 7718" id="Grupo_7718" transform="translate(2.954 0.386)">
                  <g data-name="Grupo 7715" id="Grupo_7715" transform="translate(27.583 2.157)">
                    <g data-name="Grupo 7714" id="Grupo_7714">
                      <path
                        d="M395.281,216.992l-3.384,2.843a.472.472,0,0,1-.773-.363v-1.035l3.048-2.557a.459.459,0,0,0,.162-.4l.947.8A.468.468,0,0,1,395.281,216.992Z"
                        data-name="Trazado 4586"
                        fill="#f96364"
                        id="Trazado_4586"
                        transform="translate(-391.124 -215.478)"
                      />
                    </g>
                  </g>
                  <g data-name="Grupo 7717" id="Grupo_7717">
                    <g data-name="Grupo 7716" id="Grupo_7716">
                      <path
                        d="M108.508,193.713v5.679a.472.472,0,0,1-.773.363l-.985-.827a.463.463,0,0,0,.3-.44v-4.563l.684-.576A.472.472,0,0,1,108.508,193.713Z"
                        data-name="Trazado 4587"
                        fill="#f96364"
                        id="Trazado_4587"
                        transform="translate(-106.75 -193.24)"
                      />
                    </g>
                  </g>
                </g>
                <g data-name="Grupo 7723" id="Grupo_7723">
                  <g data-name="Grupo 7720" id="Grupo_7720" transform="translate(30.151)">
                    <g data-name="Grupo 7719" id="Grupo_7719">
                      <path
                        d="M388,196.654a.861.861,0,0,1-.364-.081.85.85,0,0,1-.495-.778v-5.677a.857.857,0,0,1,1.405-.658l3.388,2.842a.855.855,0,0,1,0,1.31l-3.387,2.842A.849.849,0,0,1,388,196.654Zm0-6.622a.088.088,0,0,0-.037.009.08.08,0,0,0-.049.077V195.8a.079.079,0,0,0,.049.077.076.076,0,0,0,.088-.011l3.387-2.841h0a.081.081,0,0,0,.029-.063.08.08,0,0,0-.029-.062l-3.388-2.843A.079.079,0,0,0,388,190.032Z"
                        data-name="Trazado 4588"
                        fill="#333"
                        id="Trazado_4588"
                        transform="translate(-387.141 -189.259)"
                      />
                    </g>
                  </g>
                  <g data-name="Grupo 7722" id="Grupo_7722" transform="translate(0 0)">
                    <g data-name="Grupo 7721" id="Grupo_7721">
                      <path
                        d="M80.535,196.654a.848.848,0,0,1-.546-.2L76.6,193.612a.855.855,0,0,1,0-1.31l3.387-2.841a.857.857,0,0,1,1.406.658V195.8a.861.861,0,0,1-.859.859Zm0-6.622a.08.08,0,0,0-.051.02L77.1,192.894h0a.083.083,0,0,0,0,.125l3.388,2.842a.077.077,0,0,0,.087.011.08.08,0,0,0,.049-.077v-5.677a.08.08,0,0,0-.049-.077A.085.085,0,0,0,80.536,190.032ZM76.849,192.6h0Z"
                        data-name="Trazado 4589"
                        fill="#333"
                        id="Trazado_4589"
                        transform="translate(-76.296 -189.259)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              d="M49.274,91.613H47.482V62.155a3.221,3.221,0,0,0-3.216-3.219H5.4A3.222,3.222,0,0,0,2.18,62.155V91.613H.388A.388.388,0,0,0,0,92v1.89a3.277,3.277,0,0,0,3.274,3.274H46.388a3.278,3.278,0,0,0,3.274-3.274V92A.388.388,0,0,0,49.274,91.613ZM18.2,92.389h13.26v.975a.468.468,0,0,1-.468.468H18.669a.468.468,0,0,1-.468-.468v-.975ZM2.956,62.155A2.444,2.444,0,0,1,5.4,59.712H44.267a2.444,2.444,0,0,1,2.44,2.443V91.61H2.956V62.155Zm45.93,31.736a2.5,2.5,0,0,1-2.5,2.5H3.274a2.5,2.5,0,0,1-2.5-2.5v-1.5H17.425v.975a1.245,1.245,0,0,0,1.244,1.244H30.994a1.245,1.245,0,0,0,1.243-1.244v-.975H48.886Z"
              data-name="Trazado 4590"
              fill="#333"
              id="Trazado_4590"
              transform="translate(0 -58.936)"
            />
          </g>
        </g>
        <g data-name="Grupo 7351" id="Grupo_7351" transform="translate(32.664 33.083)">
          <circle
            cx="10"
            cy="10"
            data-name="Elipse 1743"
            fill="#76c9b0"
            id="Elipse_1743"
            r="10"
            transform="translate(-0.164 0.217)"
          />
          <path
            d="M51.933,45.514H47.338V40.919a.919.919,0,1,0-1.838,0v5.514a.919.919,0,0,0,.919.919h5.514a.919.919,0,1,0,0-1.838Z"
            data-name="Trazado 4399"
            fill="#fff"
            id="Trazado_4399"
            transform="translate(-36.31 -36.324)"
          />
        </g>
      </g>
    </svg>
  );
};
