import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { TableSkeleton } from '@/lib/components';
import { TableBar } from '@/lib/components/TableBar';
import { Flex } from '@/lib/v2/components';

import { ActionBarButtons } from './components/ActionBarButtons';
import { FilterBar } from './components/FilterBar';

import {
  atomCampaignsRecurrentList,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { CampaignRecurrentTable } from '@/modules/CampaignsModule/components/CampaignsRecurrent/CampaignsRecurrentTable';

export function TableContainer() {
  const recurrentMessageList = useAtomValue(atomCampaignsRecurrentList);
  const totalRecurrentMessageList = useAtomValue(atomTotalsProcess);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);

  const wrapperActionsTable = classNames({
    'mt-[-52px]': recurrentMessageList && recurrentMessageList?.length > 0,
  });

  return (
    <Flex column>
      {recurrentMessageList ? (
        <>
          {totalRecurrentMessageList?.total > 0 && (
            <TableBar>{totalSelectedRows > 0 ? <ActionBarButtons /> : <FilterBar />}</TableBar>
          )}
          <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
            <CampaignRecurrentTable />
          </Flex>
        </>
      ) : (
        <div className="size-full py-10">
          <TableSkeleton columns={7} />
        </div>
      )}
    </Flex>
  );
}
