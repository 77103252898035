import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, PhonePreviewWhatsApp } from '@/lib/v2/components';

import { WhatsAppForm } from './components/WhatsAppForm';
import { extractBodyFromJson } from './utils/extractBodyFromJson';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomMessageWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import {
  EActionTypesPath,
  EStepHeaderAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const WhatsAppAction = () => {
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const [messageWhatsApp, setMessageWhatsApp] = useAtom(atomMessageWhatsApp);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const setActionTypePath = useSetAtom(atomActionTypePath);

  const { getSummaryInfoWhatsApp, getContractCountry } = useActionWhatsAppData(Number(actionId));

  useLayoutEffect(() => {
    const fetchData = async () => await getSummaryInfoWhatsApp();
    void fetchData();
  }, [getSummaryInfoWhatsApp]);

  useEffect(() => {
    if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
  }, [currentStep, setCurrentStep]);

  //TODO: check if this is needed, because it has been inherited from the sms action
  useEffect(() => {
    return () => {
      setMessageWhatsApp({
        original: '',
        shortened: '',
        track: false,
      });
    };
  }, [setMessageWhatsApp]);

  useEffect(() => void getContractCountry(), [getContractCountry]);
  useEffect(() => setActionTypePath(EActionTypesPath.SMS), [setActionTypePath]);

  const processMessage = useMemo(() => (message: string) => extractBodyFromJson(message), []);

  return (
    <Flex itemAlignment="start">
      <div className="my-12 w-1/2 bg-white px-10">
        {<WhatsAppForm loading={globalLoadingAction} />}
      </div>
      <div className="grid min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-[#F4F5FA] py-4 ">
        <PhonePreviewWhatsApp
          message={processMessage(
            messageWhatsApp.track ? messageWhatsApp.shortened : messageWhatsApp.original
          )}
        />
      </div>
    </Flex>
  );
};

export default WhatsAppAction;
