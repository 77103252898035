import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormColumnSkeleton } from '@/lib/components/Skeletons';
import { Card, CardSection } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Option } from '@/lib/v2/components/Select/Select';

import { IContactCustom } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';

import { ContactsProfileDetailGroups } from '@/modules/ContactsModule/screens/ContactProfile/components/ContactsProfileDetailsPanel/ContactsProfileDetail/ContactsProfileDetailGroups';
import { ContactsProfileDetailInformation } from '@/modules/ContactsModule/screens/ContactProfile/components/ContactsProfileDetailsPanel/ContactsProfileDetail/ContactsProfileDetailInformation';
import { ContactsProfileDetailTags } from '@/modules/ContactsModule/screens/ContactProfile/components/ContactsProfileDetailsPanel/ContactsProfileDetail/ContactsProfileDetailTags';

const ContactsProfileDetailsPanel = ({
  emailId,
  countries,
  contactDetailInfo,
  onUpdate,
}: {
  emailId: number;
  countries?: Option[];
  contactDetailInfo?: IContactCustom;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const renderSkeleton = () => (
    <Card>
      <CardSection>
        <Flex alignment="center">
          <FormColumnSkeleton />
        </Flex>
      </CardSection>
    </Card>
  );

  return (
    <Flex column alignment="start" className="md:gap-4 lg:gap-4 xl:gap-4 2xl:gap-6">
      {contactDetailInfo ? (
        <ContactsProfileDetailInformation
          contactDetailInfo={contactDetailInfo}
          countries={countries}
          permanentEmblue={true}
          title={t('CONTACTS_PROFILE.contactInfo')}
          onUpdate={onUpdate}
        />
      ) : (
        renderSkeleton()
      )}
      {contactDetailInfo ? (
        <ContactsProfileDetailInformation
          sortFields
          contactDetailInfo={contactDetailInfo}
          permanentEmblue={false}
          title={t('CONTACTS_PROFILE.customFields')}
          onUpdate={onUpdate}
        />
      ) : (
        renderSkeleton()
      )}
      {emailId ? (
        <ContactsProfileDetailGroups emailId={Number(emailId)} permanentEmblue={false} />
      ) : (
        renderSkeleton()
      )}
      {emailId ? (
        <ContactsProfileDetailTags emailId={Number(emailId)} permanentEmblue={false} />
      ) : (
        renderSkeleton()
      )}
    </Flex>
  );
};

export default memo(ContactsProfileDetailsPanel);
