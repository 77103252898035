import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import BeefreeModal from '@/src/modules/CampaignsModule/components/BeefreeModal';
import EditorActions from '@/src/modules/CampaignsModule/components/EditorActions';
import { useCustomFIelds } from '@/src/modules/CampaignsModule/hooks/useCustomFields';
import { useEditor, useServicesEditor } from '@/src/modules/CampaignsModule/hooks/useEditorContent';

const signature = [
  {
    type: 'Signature',
    label: '[Subscribe]',
    link: '$FE{subscribe}',
  },
  {
    type: 'Signature',
    label: '[Share on Facebook]',
    link: '$FE{facebook}',
  },
  {
    type: 'Signature',
    label: '[Share on Twitter]',
    link: '$FE{twitter}',
  },
  {
    type: 'Signature',
    label: '[See in my browser]',
    link: '$FE{online}',
  },
  {
    type: 'Signature',
    label: '[Update Profile]',
    link: '$FE{update}',
  },
  {
    type: 'Signature',
    label: '[Legal]',
    link: '$FE{legal}',
  },
  {
    type: 'Signature',
    label: '[Sender data]',
    link: '$FE{data}',
  },
  {
    type: 'Signature',
    label: '[Unsubscribe]',
    link: '$FE{unsubscription}',
  },
];
const EditorContent = () => {
  const [userData] = useUserData();
  const companyId = +userData.companyId;
  const listCustomField = useCustomFIelds();
  const { actionId } = useParams();

  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, { identifier: 'TAGS_DROP_DOWN' });

  const { onSaveMail, onSend, onSaveTemplate, onSaveRow, onRemoveRow } = useServicesEditor({
    accountId: companyId,
    actionId: parseInt(actionId || '0'),
    listCustomField: listCustomField ?? [],
    tags: tags?.list || null,
    signature,
  });
  const { beefree, disabled, saved } = useEditor({
    onSaveRow,
    onSaveMail,
    onSend,
    onSaveTemplate,
    onRemoveRow,
    companyId,
    listCustomField: listCustomField ?? [],
    tags: tags?.list || null,
    signature: signature,
  });

  return (
    <div className="absolute left-0 top-0 flex h-[calc(100vh-64px)] w-full flex-col">
      <EditorActions beefree={beefree} disabled={disabled} saved={saved} />
      <div className="h-[100vh] w-full bg-[#32373a]" id="bee-plugin-container" />
      <BeefreeModal />
    </div>
  );
};

export default memo(EditorContent);
