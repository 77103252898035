export const ExclamationIcon = () => {
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1 8C1 4.13385 4.13385 1 8 1C11.8662 1 15 4.13385 15 8C15 11.8662 11.8662 15 8 15C4.13385 15 1 11.8662 1 8ZM7.25046 6.96472C8.07323 6.55333 9.0001 7.29713 8.77682 8.18954L8.2678 10.2256L8.29795 10.2113C8.42446 10.1557 8.56752 10.1508 8.69751 10.1978C8.82749 10.2447 8.93446 10.3398 8.99626 10.4634C9.05807 10.587 9.06998 10.7297 9.02953 10.8618C8.98909 10.994 8.89938 11.1055 8.77897 11.1733L8.75026 11.1891C7.92677 11.6005 6.9999 10.8567 7.22318 9.96431L7.73292 7.92821L7.70277 7.94256C7.63926 7.97786 7.56926 7.99994 7.49699 8.00747C7.42472 8.015 7.35167 8.00782 7.28225 7.98637C7.21283 7.96492 7.14846 7.92965 7.09304 7.88266C7.03761 7.83568 6.99226 7.77797 6.95973 7.713C6.92719 7.64803 6.90814 7.57715 6.90373 7.50462C6.89932 7.43209 6.90963 7.35942 6.93404 7.29099C6.95845 7.22255 6.99646 7.15976 7.04579 7.1064C7.09511 7.05305 7.15472 7.01022 7.22103 6.98051L7.25046 6.96472ZM8 5.84615C8.14281 5.84615 8.27977 5.78942 8.38075 5.68844C8.48173 5.58746 8.53846 5.4505 8.53846 5.30769C8.53846 5.16488 8.48173 5.02792 8.38075 4.92694C8.27977 4.82596 8.14281 4.76923 8 4.76923C7.85719 4.76923 7.72023 4.82596 7.61925 4.92694C7.51827 5.02792 7.46154 5.16488 7.46154 5.30769C7.46154 5.4505 7.51827 5.58746 7.61925 5.68844C7.72023 5.78942 7.85719 5.84615 8 5.84615Z"
        fill="#0056B8"
        fillRule="evenodd"
      />
    </svg>
  );
};
