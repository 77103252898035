import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputBar } from '@/lib/components/SearchInputBar';
import { AddCirclePlusIcon } from '@/lib/icon';
import { Checkbox, IconSvg, Text } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import { LoadingIcon } from '@/lib/v2/icons/animated';

import { useSortedItems } from '@/src/application/hooks/useSortedItems';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

export interface Item {
  name: string;
  indeterminate: boolean;
}

export type ItemListType = Array<string | Item>;

interface ItemSearchProps {
  SearchPlaceHolder?: string;
  itemList: ItemListType;
  title?: string;
  subTitle?: string;
  selectedItems: ItemListType;
  setSelectedItems?: Dispatch<SetStateAction<ItemListType>>;
  setUnselectedItems?: Dispatch<SetStateAction<ItemListType>>;
  maxItems?: number;
  onCreate?: (value: string) => void;
  isLoading?: boolean;
}

export const ItemSearch = ({
  SearchPlaceHolder = '',
  itemList,
  title = '',
  subTitle = '',
  selectedItems,
  setSelectedItems,
  setUnselectedItems,
  maxItems,
  onCreate,
  isLoading,
}: ItemSearchProps) => {
  const { t } = useTranslation();
  const [searchItem, setSearchItem] = useState('');
  const [showMaxItemsMessage, setShowMaxItemsMessage] = useState<boolean>(false);
  const { resetFilter } = useResetSearch({ setSearchValue: setSearchItem as () => void });

  const createNewItem = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchItem(value);
  };

  const foundItemCoincidences = (item: string | Item) => {
    if (searchItem === '') {
      return true;
    } else {
      const regex = new RegExp(searchItem, 'i');
      if (typeof item === 'string') {
        return regex.test(item);
      } else {
        return regex.test(item.name);
      }
    }
  };

  const foundItemInVector = (items: ItemListType, searchItemVal: string) => {
    let finder: string | Item | undefined;

    if (typeof items[0] === 'string') {
      finder = (items as string[]).find((item) => {
        return item.toLowerCase() === searchItemVal.toLowerCase();
      });
    } else {
      finder = (items as Item[]).find((item) => {
        return item.name.toLowerCase() === searchItemVal.toLowerCase();
      });
    }

    return finder !== undefined ? true : false;
  };

  useEffect(() => {
    if (maxItems) {
      disableInputs(selectedItems.length >= maxItems);
      setShowMaxItemsMessage(selectedItems.length >= maxItems);
    }
  }, [selectedItems]);

  const checkboxHandle = (e: ChangeEvent<HTMLInputElement>, items: ItemListType): void => {
    const checkedValue = e.target.value;
    let itemsAux: ItemListType = [...selectedItems];

    if (typeof items[0] === 'string') {
      if (e.target.checked) {
        if (!(itemsAux as string[]).includes(checkedValue)) {
          (itemsAux as Item[]).push({ name: checkedValue, indeterminate: false });
        }
      } else {
        itemsAux = (itemsAux as Item[]).filter((item) => item.name !== checkedValue);
      }
    } else if (e.target.checked) {
      if (!(itemsAux as Item[]).find((item) => item.name === checkedValue)) {
        (itemsAux as Item[]).push({ name: checkedValue, indeterminate: false });
      }
    } else {
      itemsAux = (itemsAux as Item[]).filter((item) => item.name !== checkedValue);
    }

    setSelectedItems && setSelectedItems(itemsAux);
    const unselectedItemsAux = items.filter((item) => !itemsAux.includes(item));
    setUnselectedItems && setUnselectedItems(unselectedItemsAux);
  };

  const disableInputs = (value: boolean) => {
    const checkbox: any = document.getElementsByClassName('_modal_checkbox_');
    if (checkbox) {
      for (let i = 0; i < checkbox.length; i++) {
        if (!checkbox[i].checked) {
          checkbox[i].disabled = value;
          checkbox[i].parentElement.style.opacity = value ? 0.4 : 1;
        }
      }
    }
  };

  const sortedItems: ItemListType = useSortedItems(selectedItems, itemList);

  const handleCreate = useCallback(() => {
    onCreate?.(searchItem);
  }, [onCreate, searchItem]);

  const filteredItems = sortedItems.filter((element: string | Item) => {
    const itemName = typeof element === 'string' ? element : element.name;
    return foundItemCoincidences(element) && !itemName.startsWith('system-whatsapp');
  });

  return (
    <div className="w-full">
      <section className="sticky top-1 z-10 mb-5 max-w-lg bg-white outline outline-[10px] outline-white">
        <SearchInputBar
          cleanBox={resetFilter}
          placeHolder={SearchPlaceHolder}
          onChange={createNewItem}
        />
      </section>
      <section>
        {showMaxItemsMessage && (
          <span className="text-14">
            {t('ITEM_SEARCH_MODAL.MaxSelection')} {maxItems},{' '}
            {t('ITEM_SEARCH_MODAL.WorkingOnIncreasingNumber')}{' '}
          </span>
        )}
      </section>
      <section>
        {searchItem?.length > 0 && !foundItemInVector(itemList, searchItem) && (
          <section>
            {subTitle !== '' && (
              <Text
                as="h3"
                className="uppercase"
                color="primary"
                fontWeight="normal"
                variant="text"
              >
                {subTitle}
              </Text>
            )}
            <div className="mb-4 flex flex-row items-center justify-between">
              <Text className="!text-12 xl:!text-14 2xl:!text-16">{searchItem}</Text>
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <IconSvg
                  className="!h-6 !w-6"
                  id="create-item-button"
                  svgComponent={<AddCirclePlusIcon />}
                  onClick={handleCreate}
                />
              )}
            </div>
          </section>
        )}
        <section className={'max-h-60 min-h-min min-w-200 overflow-y-auto pb-1'}>
          {title !== '' && (
            <Text as="h3" className="uppercase" color="primary" fontWeight="normal" variant="text">
              {title}
            </Text>
          )}
          <div className="mt-2 divide-y ">
            <Divide>
              <Flex column itemAlignment="stretch">
                <>
                  {filteredItems.map((item) => {
                    const itemName = typeof item === 'string' ? item : item.name;
                    const itemIndeterminate = typeof item === 'string' ? false : item.indeterminate;

                    return (
                      <div key={itemName} className="h-[26px] lg:h-[30px] xl:h-[38px] 2xl:h-[48px]">
                        <Flex alignment="start" itemAlignment="center">
                          <Checkbox
                            checked={
                              selectedItems.find((item) =>
                                typeof item === 'string'
                                  ? item === itemName
                                  : item.name === itemName
                              ) !== undefined
                            }
                            className="_modal_checkbox_"
                            id="checked-checkbox"
                            indeterminate={itemIndeterminate}
                            value={itemName}
                            onChange={(e) => checkboxHandle(e, [itemName])}
                          />
                          <span className="text-[10px] lg:text-12 xl:text-14 2xl:text-16 ">
                            {itemName}
                          </span>
                        </Flex>
                      </div>
                    );
                  })}
                </>
              </Flex>
            </Divide>
          </div>
        </section>
      </section>
    </div>
  );
};
