export const InactiveWidgetIcon = () => {
  return (
    <svg
      height="26.946"
      viewBox="0 0 22.629 26.946"
      width="22.629"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <g transform="translate(4.333)">
            <path
              d="M200.777,66.544a2.44,2.44,0,0,0-2.449-1.6H196.9a5.635,5.635,0,0,0,.509-2.284,4.621,4.621,0,0,0-.071-.806,1.725,1.725,0,0,0-1.849-1.6,1.437,1.437,0,0,0-.169.02c-.425.051-.57.452-.924,1.431a13.722,13.722,0,0,1-1.275,2.814,4.724,4.724,0,0,1-1.822,1.491V65.94h-4.7v6.987h4.7v-.385a5.813,5.813,0,0,1,1.258.017l.843.489.631.391a12.556,12.556,0,0,0,4.43.192,2.2,2.2,0,0,0,1.846-1.474C200.426,71.753,201.442,68.16,200.777,66.544ZM199.424,71.9a1.292,1.292,0,0,1-1.09.813,13.215,13.215,0,0,1-3.86-.088l-.557-.351-1.063-.594a5.587,5.587,0,0,0-1.555-.057v-4.6a5.8,5.8,0,0,0,2.6-1.987,14.553,14.553,0,0,0,1.37-3.006s.27-.675.337-.83c.469,0,.725.26.823.82a3.746,3.746,0,0,1,.054.644,5.037,5.037,0,0,1-.722,2.5l-.469.715h3.033A1.526,1.526,0,0,1,199.92,66.9C200.382,68.011,199.714,70.889,199.424,71.9Z"
              fill="#fc644d"
              transform="translate(-186.6 -60.257)"
            />
          </g>
          <g data-name="Grupo 8488" id="Grupo_8488" transform="translate(0 16.943)">
            <path
              d="M189.3,541.455a3.954,3.954,0,0,1,0-7.908h12.627a3.954,3.954,0,0,1,0,7.908H189.3m0,1.047h12.627a5,5,0,0,0,5-5h0a5,5,0,0,0-5-5H189.3a5,5,0,0,0-5,5h0a5,5,0,0,0,5,5Z"
              fill="#fc644d"
              transform="translate(-184.3 -532.5)"
            />
            <g data-name="Grupo 8487" id="Grupo_8487" transform="translate(2.472 2.116)">
              <ellipse cx="2.886" cy="2.886" fill="#fc644d" rx="2.886" ry="2.886" />
            </g>
            <path
              d="M468.08,592.258l-1.047,1.189a.523.523,0,1,0,.786.691l.958-1.084.958,1.084a.523.523,0,0,0,.786-.691l-1.047-1.189,1.047-1.189a.527.527,0,0,0-.047-.738.519.519,0,0,0-.346-.131.512.512,0,0,0-.393.178l-.958,1.084-.958-1.084a.53.53,0,0,0-.393-.178.524.524,0,0,0-.393.869Z"
              fill="#fc644d"
              transform="translate(-452.101 -587.178)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
