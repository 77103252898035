import { ComponentType, FC } from 'react';

const withDeprecated = <OriginalProps extends object>(
  ChildComponent: ComponentType<OriginalProps>,
  message: string = ''
): FC<OriginalProps> => {
  const DeprecateComponent = (props: OriginalProps) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(`${ChildComponent.name} is deprecated. `, message);
      return (
        <div className="relative border-2 border-red-500">
          <p className="absolute z-[9999] bg-red-500 px-1 text-[8px] font-medium text-black">
            Deprecated
          </p>
          <ChildComponent {...props} />
        </div>
      );
    }

    return <ChildComponent {...props} />;
  };

  return DeprecateComponent;
};

export default withDeprecated;
