import classNames from 'classnames';
import { ChangeEventHandler, forwardRef, HTMLAttributes, LegacyRef, memo } from 'react';

import { withController } from '@/lib/v2/HOCs/WithController';

interface RadioButtonProps extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  variant?: 'default' | 'button';
  hasError?: boolean;
}

const RadioButton = forwardRef(
  (
    {
      id,
      label,
      checked,
      onChange,
      defaultChecked,
      value,
      name,
      disabled,
      variant = 'default',
      hasError = false,
      ...restOfProps
    }: RadioButtonProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const classesRadioGroupButton = classNames(
      'label !flex !h-[38px] w-full !items-center rounded-md border border-emblue-borders pr-1 !leading-[1.2] text-[#111827] peer-checked:!border-emblue-primary peer-checked:text-emblue-primary pl-8',
      { '!border-emblue-alert': hasError }
    );

    return (
      <>
        {variant === 'default' ? (
          <div className="flex h-5 items-center" data-testid="radiobutton-component">
            <input
              ref={ref}
              checked={checked}
              className="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              defaultChecked={defaultChecked}
              disabled={disabled}
              id={id}
              name={name}
              type="radio"
              value={value}
              onChange={onChange}
              {...restOfProps}
            />
            <label className="label ml-1 !h-full" htmlFor={id}>
              {label}
            </label>
          </div>
        ) : (
          <div className="relative size-full" data-testid="radiobutton-component">
            <input
              ref={ref}
              checked={checked}
              className="peer absolute left-[10px] top-3 size-4 border-gray-300"
              defaultChecked={defaultChecked}
              disabled={disabled}
              id={id}
              name={name}
              type="radio"
              value={value}
              onChange={onChange}
              {...restOfProps}
            />
            <label className={classesRadioGroupButton} htmlFor={id}>
              <span>{label}</span>
            </label>
          </div>
        )}
      </>
    );
  }
);

export default memo(withController(RadioButton));
