import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATE_RULES_PRESENCE_OPTIONS } from '@/modules/RulesModule/constants';

export const useConditionOptions = () => {
  const { t } = useTranslation('rules');

  const presenceOptions = useMemo(
    () => [
      { id: 0, name: `${t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.notPresent`)}`, value: 0 },
      { id: 1, name: `${t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.present`)}`, value: 1 },
    ],
    [t]
  );

  return {
    presenceOptions,
  };
};
