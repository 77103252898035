import withDeprecated from '@/src/application/HOCs/WithDeprecated';

import './Text.tailwind.css';

export type HeadingProps = {
  children?: JSX.Element | string;
};

export const _SubHeading = ({ children }: HeadingProps) => (
  <div className="subheading text-[14px] text-gray-600">{children}</div>
);

/**
 * @deprecated use new Text component v2 -> import { Text } from "@/lib/v2/components"
 */
export const SubHeading = withDeprecated(
  _SubHeading,
  'use new Text component v2 -> import { Text } from "@/lib/v2/components";'
);
