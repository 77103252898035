import { CustomsFieldsProps } from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';

/* cspell:disable */
export const listCustomField = [
  {
    itIsFixedEmblue: true,
    id: '1',
    name: 'NOMBRE',
  },
  {
    itIsFixedEmblue: true,
    id: '2',
    name: 'APELLIDO',
  },
  {
    itIsFixedEmblue: true,
    id: '3',
    name: 'PuESTO',
  },
  {
    itIsFixedEmblue: true,
    id: '4',
    name: 'AREA',
  },
  {
    itIsFixedEmblue: true,
    id: '5',
    name: 'EMAIL',
  },
  {
    itIsFixedEmblue: true,
    id: '6',
    name: 'SEXO',
    dataType: 'sex',
  },
  {
    itIsFixedEmblue: true,
    id: '7',
    name: 'CUMPLEANIOS',
    dataType: 'date',
  },
  {
    itIsFixedEmblue: true,
    name: 'No Import',
    id: '-1',
  },
] as Array<CustomsFieldsProps>;
