export const ContactClickIcon = () => {
  return (
    <svg
      height="29"
      viewBox="0 0 35 29"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            data-name="Rectangle 3574"
            fill="#9c59df"
            height="29"
            id="Rectangle_3574"
            stroke="#707070"
            strokeWidth="1"
            width="35"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Mask Group 10" id="Mask_Group_10">
        <g id="click2" transform="translate(5.856 -0.194)">
          <path
            d="M25.041,21.847,8.494,7.011v22.2l4.6-4.93,2.56,5.777,5.182-2.3-2.586-5.833Zm0,0"
            data-name="Path 3328"
            fill="#9c59df"
            id="Path_3328"
            transform="translate(-1.242 -1.025)"
          />
          <path
            d="M4.783.824,6.338.137l1.767,4L6.55,4.823Zm0,0"
            data-name="Path 3329"
            fill="#9c59df"
            id="Path_3329"
            transform="translate(-0.699 -0.02)"
          />
          <path
            d="M10.644,4.079,12.217,0,13.8.612,12.23,4.691Zm0,0"
            data-name="Path 3330"
            fill="#9c59df"
            id="Path_3330"
            transform="translate(-1.556)"
          />
          <path
            d="M13.937,6.556l3.995-1.776.691,1.554L14.628,8.109Zm0,0"
            data-name="Path 3331"
            fill="#9c59df"
            id="Path_3331"
            transform="translate(-2.038 -0.699)"
          />
          <path
            d="M0,6.785.615,5.2,4.691,6.782,4.076,8.367Zm0,0"
            data-name="Path 3332"
            fill="#9c59df"
            id="Path_3332"
            transform="translate(0 -0.76)"
          />
          <path
            d="M.128,12.65l4-1.758.684,1.557-4,1.758Zm0,0"
            data-name="Path 3333"
            fill="#9c59df"
            id="Path_3333"
            transform="translate(-0.019 -1.592)"
          />
        </g>
      </g>
    </svg>
  );
};
