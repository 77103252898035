export const AutoResponseIcon = () => {
  return (
    <svg fill="none" height="34" viewBox="0 0 34 34" width="34" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_751_61)">
        <path
          d="M9.9645 0V3.99047H10.4061C17.5152 3.99047 24.6095 3.99047 31.7186 3.99047C33.2199 3.99047 34 4.78562 34 6.28757C34 9.43872 34 12.6046 34 15.7557C34 17.2135 33.1905 18.0087 31.7333 18.0087C30.0554 18.0087 28.3922 18.0087 26.7143 18.0087H26.3169V19.9376C25.3307 18.9511 24.3446 17.9792 23.4026 17.0221C24.3593 16.0797 25.3307 15.0931 26.3169 14.1213V15.9913H31.9835V5.99307H9.9645V9.95409H0V0H9.9645ZM2.00173 7.95149H7.93333V2.01732H2.00173V7.95149Z"
          fill="#004DBC"
        />
        <path
          d="M19.6935 31.9678V30.0241H19.2667C13.6294 30.0241 8.00693 30.0241 2.3697 30.0241C0.868398 30.0241 0.0735931 29.229 0.0735931 27.7418C0.0735931 24.5906 0.0735931 21.4247 0.0735931 18.2736C0.0735931 16.8158 0.868398 16.0059 2.32554 16.0059C5.41645 16.0059 8.50736 16.0059 11.5835 16.0059H12.0104V12.0449H21.9602V21.9696H12.0398V18.038H2.07532V28.0215H19.6641V26.1662C20.6355 27.138 21.6216 28.1246 22.5784 29.067C21.6658 29.98 20.6797 30.9665 19.6788 31.9678H19.6935ZM14.0268 19.9817H19.9584V14.0475H14.0268V19.9817Z"
          fill="#004DBC"
        />
        <path
          d="M24.0502 34.0001V24.0754H33.9706V34.0001H24.0502ZM26.0519 26.0633V31.9975H31.9835V26.0633H26.0519Z"
          fill="#004DBC"
        />
      </g>
      <defs>
        <clipPath id="clip0_751_61">
          <rect fill="white" height="34" width="34" />
        </clipPath>
      </defs>
    </svg>
  );
};
