import { IconSvg } from '@/lib/v2/components';
import { ImageCampaignIcon } from '@/lib/v2/icons/general/ImageCampaignIcon';
import { InsightCard } from '@/lib/v2/organisms/InsightCard';

const CampaignCards = ({ description, title }: { description: string; title: string }) => (
  <div className="w-1/2">
    <InsightCard
      fullText
      noShadow
      description={description}
      icon={<IconSvg fullWidth svgComponent={<ImageCampaignIcon />} />}
      title={title}
    />
  </div>
);

export default CampaignCards;
