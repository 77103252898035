import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputBar, TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IContactActivityTags } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ContactsProfileTagsTable } from './ContactsProfileTagsTable';

export const ContactsProfileActivityTagsTable = ({
  emailId,
  onSearch,
}: {
  emailId: number;
  onSearch?: (search: string) => void;
}) => {
  const { t } = useTranslation();
  const [search, setSearchValue] = useState<string>('');
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [totalTagsContacts, setTotalTagsContacts] = useState<number>();
  const {
    pageSize,
    pageIndex,
    totalPages,
    isLoading,
    gotoPageIndex,
    setIsLoading,
    changeTablePage,
    resetPagination,
  } = useTablePagination(totalTagsContacts);
  const [activityTagsListValue] = useEmblue(ServiceMethods.getContactActivityTags, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    search,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const [activityTagsTotalCount] = useEmblue(ServiceMethods.getContactActivityTags, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });
  const [activityTagsList, setActivityTagsList] = useState<{
    list: IContactActivityTags[];
    total: number;
  }>();
  const cleanBoxRef = useRef<CleanBoxRef>(null);

  useEffect(() => {
    if (activityTagsListValue) {
      setActivityTagsList(activityTagsListValue);
    }
  }, [activityTagsListValue]);

  useEffect(() => {
    if (activityTagsList) {
      setTotalTagsContacts(activityTagsListValue?.total);
    }
  }, [activityTagsList, search]);

  useEffect(() => {
    activityTagsList && activityTagsList.list;
    resetPagination();
    if (search && totalTagsContacts) {
      setTotalTagsContacts(totalPages);
    }
  }, [search]);

  useEffect(() => {
    activityTagsList && setIsLoading(false);
  }, [activityTagsList]);

  const resetActivityTags = () => {
    cleanBoxRef?.current?.cleanBox();
    resetFilter();
  };

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
      setIsLoading(true);
    },
    [orderDirection]
  );

  return (
    <>
      {activityTagsTotalCount && activityTagsTotalCount.total > 0 && (
        <div className="mb-[-53px] w-[250px] py-2">
          <SearchInputBar
            ref={cleanBoxRef}
            withButton
            cleanBox={resetFilter}
            placeHolder={t('PROFILE_TAGS_TABLE.searchInputBar')}
            onAction={(ev) => setSearchValue && setSearchValue(ev)}
          />
        </div>
      )}
      <Flex column>
        {!activityTagsList && (
          <span className="h-full w-full py-10">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {activityTagsList && (
        <ContactsProfileTagsTable
          activityCount={activityTagsList ? activityTagsList.total : 0}
          activityList={activityTagsList.list}
          activityTagsTotalCount={activityTagsTotalCount ? activityTagsTotalCount.total : 0}
          callToAction={[resetActivityTags]}
          changeTableOrder={changeOrderBy}
          changeTablePage={changeTablePage}
          gotoPageIndex={gotoPageIndex}
          isLoading={isLoading}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
