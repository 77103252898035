import { useEffect, useMemo, useState } from 'react';

import { useEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';

import { EmblueService } from './EmblueService';
import { IEmblueService } from './IEmblueService';
import { StubEmblueService } from './StubEmblueService';

export const useService = () => {
  const { env } = useEnv();

  const service = useMemo(() => {
    if (env !== 'storybook') {
      // TODO: Add more environments, or improve how we read this json file.
      const {
        URL_EMA_APP,
        URL_EMA_APP_V2,
        JWT_DEV_TOKEN,
        URL_API_NODE,
        URL_WIDGET_API,
        URL_INBOX_API,
      } = configData.endpoints[env];
      return EmblueService.init({
        emaAppUrl: URL_EMA_APP,
        emaAppV2Url: URL_EMA_APP_V2,
        apiNodeApp: URL_API_NODE,
        widgetsUrl: URL_WIDGET_API,
        inboxApiUrl: URL_INBOX_API,
        jwtToken:
          JWT_DEV_TOKEN !== ''
            ? JWT_DEV_TOKEN
            : (window as Window & typeof globalThis & { __JWTToken: string }).__JWTToken,
      });
    } else {
      return StubEmblueService.init();
    }
  }, [env]);

  return service;
};

export const useEmblue = <T extends keyof IEmblueService>(
  method: T,
  ...p: Parameters<IEmblueService[T]>
): [Awaited<ReturnType<IEmblueService[T]>> | undefined, Error | undefined, boolean] => {
  const service = useService();
  const [value, setValue] = useState<Awaited<ReturnType<IEmblueService[T]>>>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  const stringParams = JSON.stringify(p);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!service) return;

      const fn = service[method];

      try {
        setIsLoading(true);

        const result = await (fn as any).call(service, ...JSON.parse(stringParams));
        if (isMounted) {
          setValue(result);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.error('Error', e);
        // eslint-disable-next-line no-console
        console.log({
          method,
          parameters: p,
          value,
          error: e,
          existMethod: typeof fn === 'function',
        });
        setError(e as Error);
      }
    };

    void fetchData();

    return () => {
      isMounted = false;
    };
  }, [method, service, stringParams]);

  return [value, error, isLoading];
};
