import { useMemo } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { Field, FieldsResponse } from '@/modules/ContactsModule/types/Segments';

export interface ICustomField {
  id: string;
  name: string;
  number: number;
  emblueField: boolean;
}

export const useCustomFIelds = () => {
  const [listCustomField] = useEmblue(ServiceMethods.getFieldsFilters) ?? {};

  const customFields = useMemo(() => {
    if (!listCustomField || typeof listCustomField?.data === 'string') return [];

    const { data } = listCustomField as { data: FieldsResponse };
    const result: ICustomField[] = [];

    const mapperDefaultFields = (item: Field): ICustomField => ({
      id: item.id.toString(),
      name: item?.value,
      number: item?.metadata?.internalNumber || 0,
      emblueField: item?.metadata?.defaultField ? item?.metadata?.defaultField : false,
    });

    const mapperCustomFields = (item: Field): ICustomField => ({
      id: item.id.toString(),
      name: item?.metadata.internalColumn,
      number: item?.metadata?.internalNumber || 0,
      emblueField: item?.metadata?.defaultField ? item?.metadata?.defaultField : false,
    });

    const defaultField = data?.defaultFields?.map(mapperDefaultFields);
    const customFields = data?.customFields?.map(mapperCustomFields);
    return result
      .concat(defaultField)
      .concat(customFields)
      .filter((item) => item.number < 200);
  }, [listCustomField]);

  return customFields;
};
