import { DotsVerticalIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { If } from '@/lib/v2/components/If';
import { Text } from '@/lib/v2/components/Text';
import { LoadingIcon } from '@/lib/v2/icons/animated';
import { ClipboardIcon, EmojiIcon } from '@/lib/v2/icons/outline';
import {
  ArrowIcon,
  CameraIcon,
  MicrophoneIcon,
  PhoneSimpleIcon,
  UserIcon,
} from '@/lib/v2/icons/solid';

import { WhatsAppBackground } from './WhatsAppBackground';

import './PhonePreviewWhatsApp.tailwind.css';

export interface IPhonePreviewWhatsAppProps {
  message: string;
  shortPhone?: boolean;
  loading?: boolean;
}

const PhonePreviewWhatsApp = ({
  message = '',
  shortPhone = false,
  loading = false,
}: IPhonePreviewWhatsAppProps): JSX.Element => {
  const { t } = useTranslation('inbox');
  const classesContainer = classNames(
    'relative h-full max-h-[600px] w-[310px] overflow-hidden border-[#054D47] bg-[#054D47]',
    {
      'rounded-t-2xl border-x-[6px] border-b-0 border-t-[6px]': shortPhone,
      'rounded-2xl border-[6px]': !shortPhone,
    }
  );
  const classesMessage = classNames(
    'relative z-10 min-h-[118px] break-words rounded-xl bg-emblue-green-mint p-4 text-left leading-tight text-[#364365]',
    { 'flex justify-center items-center': loading }
  );
  return (
    <div className="flex h-full items-center" data-testid="phone-preview-component">
      <div className={classesContainer}>
        <div className="relative z-20 flex flex-col items-center gap-2 bg-emblue-green-dark pb-3 pt-[50px]">
          <div className="absolute inset-x-0 top-0 mx-auto h-[38px] w-[210px] rounded-b-lg bg-[#054D47]" />
          <div className="flex w-full justify-between px-4">
            <div className="flex items-center gap-2">
              <IconSvg height="16px" svgComponent={<ArrowIcon />} width="16px" />
              <IconSvg
                rounded
                bgColor="gray-light"
                fillColor="gray-dark"
                height="32px"
                svgComponent={<UserIcon />}
                width="32px"
                withPadding="small"
              />
              <div className="h-[11px] w-[92px] rounded-md bg-[#D9D9D9]" />
            </div>
            <div className="flex items-center gap-4">
              <IconSvg height="20px" svgComponent={<PhoneSimpleIcon />} width="20px" />
              <IconSvg
                height="20px"
                strokeColor="white"
                svgComponent={<DotsVerticalIcon />}
                width="20px"
              />
            </div>
          </div>
        </div>
        <div className="relative h-[calc(100%-97px)]">
          <div className="absolute inset-0">
            <WhatsAppBackground />
          </div>
          <div className="relative z-10 h-[calc(100%-60px)] overflow-y-auto pl-5 pr-2 pt-5">
            <p className={classesMessage}>
              <If condition={loading}>
                <LoadingIcon />
              </If>
              <If condition={!loading}>{message}</If>
            </p>
          </div>
          <div className="absolute inset-x-0 bottom-0 z-20 p-2">
            <div className="flex items-center justify-between">
              <div className="flex h-9 w-[245px] justify-between rounded-full bg-white px-3 py-2">
                <div className="flex items-center gap-1">
                  <IconSvg
                    fillColor="gray"
                    height="18px"
                    svgComponent={<EmojiIcon />}
                    width="18px"
                  />
                  <Text color="gray" variant="text-sm">
                    {t('INBOX.PHONE_PREVIEW.PLACEHOLDER')}
                  </Text>
                </div>
                <div className="flex items-center gap-2">
                  <IconSvg
                    fillColor="gray"
                    height="18px"
                    svgComponent={<ClipboardIcon />}
                    width="18px"
                  />
                  <IconSvg
                    fillColor="gray"
                    height="18px"
                    svgComponent={<CameraIcon />}
                    width="18px"
                  />
                </div>
              </div>

              <IconSvg
                rounded
                bgColor="greenTeal"
                height="32px"
                svgComponent={<MicrophoneIcon />}
                width="32px"
                withPadding="small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PhonePreviewWhatsApp);
