import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '@/lib/v2/components';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';

import {
  CSV_TEMPLATE_MODEL_CONTACTS,
  VIDEO_IMPORT_CONTACTS,
} from '@/modules/ContactsModule/constants';
import { useModalHelp } from '@/modules/ContactsModule/hooks/useModalHelp';

const ModalHelp = () => {
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const { closeModalHelp, showHelp } = useModalHelp();
  const { t } = useTranslation();
  const { URL_FILES_APP } = configData.endpoints[env] as { URL_FILES_APP: string };

  return (
    <Modal externalClose showCloseButton open={showHelp} onClose={closeModalHelp}>
      <div className="flex flex-col gap-2 divide-y">
        <header>
          <Text as="h3" fontWeight="medium" variant="sub-headline">
            {t('IMPORT_MAIN.HELP.MODAL.title')}
          </Text>
        </header>

        <div className="flex justify-center py-4">
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            className="aspect-video w-11/12 rounded-md"
            referrerPolicy="strict-origin-when-cross-origin"
            src={VIDEO_IMPORT_CONTACTS}
            title="YouTube video player"
          />
        </div>

        <div className="flex flex-col pt-4">
          <Text>{t('IMPORT_MAIN.HELP.MODAL.downloadTemplate')}</Text>
          <Text
            link
            color="primary"
            fontWeight="medium"
            href={URL_FILES_APP + CSV_TEMPLATE_MODEL_CONTACTS}
          >
            {t('IMPORT_MAIN.HELP.MODAL.downloadCsv')}
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalHelp);
