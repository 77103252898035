import { ISegmentsEmblueServiceResponse } from '@/src/infrastructure/Protocol/ServiceInterfaces';

/* spell-checker: disable */
export const segmentMock: ISegmentsEmblueServiceResponse = {
  list: [
    {
      segment: {
        id: 1867281,
        name: 'TestPerfiles-Dani',
        contacts: 4,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 1307119,
        name: 'testinfo34566',
        contacts: 0,
        lastTotalContactsUpdate: undefined,
      },
    },
    {
      segment: {
        id: 1306980,
        name: 'testInfo1234',
        contacts: 107,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 1307209,
        name: 'TEST18848',
        contacts: 1,
        lastTotalContactsUpdate: undefined,
      },
    },
    {
      segment: {
        id: 2308785,
        name: 'test-perfilconItems',
        contacts: 10,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 917202,
        name: 'test trigger 80856',
        contacts: 3,
        lastTotalContactsUpdate: undefined,
      },
    },
    {
      segment: {
        id: 1907902,
        name: 'test perfiles',
        contacts: 2,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 1306973,
        name: 'test 12345',
        contacts: 29,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 526235,
        name: 'tag adidas',
        contacts: 1,
        lastTotalContactsUpdate: '2023-12-15T08:51:40.980Z',
      },
    },
    {
      segment: {
        id: 901395,
        name: 'Sergio',
        contacts: 1,
        lastTotalContactsUpdate: undefined,
      },
    },
  ],
};
