export const RfmIcon = () => {
  return (
    <svg height="56" viewBox="0 0 56.022 56" width="56.022" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 8056" id="Group_8056" transform="translate(-704 -347)">
        <rect
          data-name="Rectangle 4410"
          fill="none"
          height="56"
          id="Rectangle_4410"
          transform="translate(704 347)"
          width="56"
        />
        <g id="ecommerce-online-shopping-svgrepo-com" transform="translate(704.474 326.222)">
          <path
            d="M75.584,56.878V95.126H25.6V56.878A3.176,3.176,0,0,1,28.778,53.7H72.417A3.187,3.187,0,0,1,75.584,56.878Z"
            data-name="Path 5080"
            fill="#fff"
            id="Path_5080"
            transform="translate(-22.823 -26.568)"
          />
          <path
            d="M434.346,56.978V95.226h-3.167V56.978A3.176,3.176,0,0,0,428,53.8h3.167A3.176,3.176,0,0,1,434.346,56.978Z"
            data-name="Path 5081"
            fill="#ededed"
            id="Path_5081"
            transform="translate(-381.574 -26.657)"
          />
          <path
            d="M54.7,110.87V75.779a.875.875,0,0,1,.879-.879h41.9a.875.875,0,0,1,.879.879v35.08H54.7Z"
            data-name="Path 5082"
            fill="#d3f0fd"
            id="Path_5082"
            transform="translate(-48.767 -45.468)"
          />
          <path
            d="M295.91,195.2v1.2a.5.5,0,0,1-.5.5H291.8a.5.5,0,0,1-.5-.5h0v-1.2a.5.5,0,0,1,.5-.5h3.623A.5.5,0,0,1,295.91,195.2Z"
            data-name="Path 5083"
            fill="#fc8a8a"
            id="Path_5083"
            transform="translate(-259.702 -152.273)"
          />
          <path
            d="M321.5,195.2v1.2a.5.5,0,0,1-.5.5h-1a.5.5,0,0,0,.5-.5v-1.2a.5.5,0,0,0-.5-.5h1A.5.5,0,0,1,321.5,195.2Z"
            data-name="Path 5084"
            fill="#f96364"
            id="Path_5084"
            transform="translate(-285.289 -152.273)"
          />
          <path
            d="M292.8,196.991h-1a.5.5,0,0,1-.5-.488h0v-1.2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0-.5.5h0v1.2a.51.51,0,0,0,.5.488Z"
            data-name="Path 5085"
            fill="#fcb0b0"
            id="Path_5085"
            transform="translate(-259.702 -152.362)"
          />
          <path
            d="M125.88,349.59a1.79,1.79,0,1,1-1.79-1.79A1.792,1.792,0,0,1,125.88,349.59Z"
            data-name="Path 5086"
            fill="#a8b1b3"
            id="Path_5086"
            transform="translate(-109.034 -288.766)"
          />
          <path
            d="M213.58,349.59a1.79,1.79,0,1,1-1.79-1.79h0A1.806,1.806,0,0,1,213.58,349.59Z"
            data-name="Path 5087"
            fill="#a8b1b3"
            id="Path_5087"
            transform="translate(-187.221 -288.766)"
          />
          <path
            d="M126.771,350.392a1.786,1.786,0,0,1-3.471.6,1.792,1.792,0,0,0,2.278-2.289A1.823,1.823,0,0,1,126.771,350.392Z"
            data-name="Path 5088"
            fill="#7c8b8e"
            id="Path_5088"
            transform="translate(-109.925 -289.568)"
          />
          <path
            d="M214.471,350.392a1.786,1.786,0,0,1-3.471.6,1.786,1.786,0,0,0,2.278-1.1,1.735,1.735,0,0,0,0-1.193A1.823,1.823,0,0,1,214.471,350.392Z"
            data-name="Path 5089"
            fill="#7c8b8e"
            id="Path_5089"
            transform="translate(-188.112 -289.568)"
          />
          <path
            d="M122.04,226.2,118.7,236.939H107.917a1.022,1.022,0,0,1-.976-.727l-2.7-8.678a1.043,1.043,0,0,1,.683-1.291.936.936,0,0,1,.3-.043Z"
            data-name="Path 5090"
            fill="#7bde9e"
            id="Path_5090"
            transform="translate(-92.893 -180.356)"
          />
          <path
            d="M225.907,226.2l-3.341,10.739H220.7l3.352-10.739Z"
            data-name="Path 5091"
            fill="#51d27f"
            id="Path_5091"
            transform="translate(-196.76 -180.356)"
          />
          <path
            d="M109.831,236.939h-1.855a1.022,1.022,0,0,1-.976-.727l-2.7-8.678a1.025,1.025,0,0,1,.683-1.291.979.979,0,0,1,.3-.043h1.855a1.037,1.037,0,0,0-1.031,1.031.98.98,0,0,0,.043.3l2.7,8.678A1.012,1.012,0,0,0,109.831,236.939Z"
            data-name="Path 5092"
            fill="#a7eabf"
            id="Path_5092"
            transform="translate(-92.942 -180.356)"
          />
          <path
            d="M124.962,190.7h-3.623a.933.933,0,0,0-.933.933v.174h-.466a1.569,1.569,0,0,0-1.5,1.106l-.38,1.215h-16.5a1.465,1.465,0,0,0-1.464,1.464,1.45,1.45,0,0,0,.065.434l2.7,8.678a1.468,1.468,0,0,0,1.4,1.031h10.186l-.466,1.5a.152.152,0,0,1-.13.1H104.3a2.224,2.224,0,1,0,2.224,2.224h0a2.22,2.22,0,0,0-.477-1.356h6.02a2.264,2.264,0,0,0-.477,1.356,2.224,2.224,0,1,0,3.2-1.985c.011-.022.022-.054.033-.076l4.458-14.308a.7.7,0,0,1,.673-.5h.466v.174a.933.933,0,0,0,.933.933h3.612a.933.933,0,0,0,.933-.933v-1.2A.959.959,0,0,0,124.962,190.7ZM103.7,204.433l-2.7-8.678a.6.6,0,0,1,.391-.748.477.477,0,0,1,.174-.022H117.8l-3.07,9.871H104.276A.6.6,0,0,1,103.7,204.433Zm.6,6.454a1.356,1.356,0,1,1,1.356-1.356h0A1.356,1.356,0,0,1,104.3,210.887Zm9.513,0a1.356,1.356,0,1,1,1.356-1.356h0a1.356,1.356,0,0,1-1.356,1.356Zm11.216-18.05a.07.07,0,0,1-.065.065H121.35a.063.063,0,0,1-.065-.065v-1.2a.07.07,0,0,1,.065-.065h3.623a.07.07,0,0,1,.065.065Z"
            data-name="Path 5093"
            fill="#333"
            id="Path_5093"
            transform="translate(-89.242 -148.707)"
          />
          <path
            d="M182.834,251.347a.435.435,0,0,1-.434-.434v-5.879a.434.434,0,1,1,.868,0v5.879A.435.435,0,0,1,182.834,251.347Z"
            data-name="Path 5094"
            fill="#333"
            id="Path_5094"
            transform="translate(-162.615 -196.76)"
          />
          <path
            d="M213.976,251.153a.212.212,0,0,1-.076-.011.431.431,0,0,1-.358-.5h0l.976-5.879a.437.437,0,1,1,.868.108v.033l-.976,5.879A.45.45,0,0,1,213.976,251.153Z"
            data-name="Path 5095"
            fill="#333"
            id="Path_5095"
            transform="translate(-190.373 -196.566)"
          />
          <path
            d="M143.856,251.4a.422.422,0,0,1-.423-.369l-.965-5.879a.438.438,0,0,1,.38-.488.424.424,0,0,1,.477.347l.965,5.879a.431.431,0,0,1-.358.5h0A.212.212,0,0,1,143.856,251.4Z"
            data-name="Path 5096"
            fill="#333"
            id="Path_5096"
            transform="translate(-127.01 -196.813)"
          />
          <path
            d="M330.426,245.468h-8.992a.434.434,0,0,1,0-.868h8.992a.434.434,0,0,1,0,.868Z"
            data-name="Path 5097"
            fill="#333"
            id="Path_5097"
            transform="translate(-286.18 -196.76)"
          />
          <path
            d="M315.181,278.568H304.334a.434.434,0,0,1,0-.868h10.847a.434.434,0,0,1,0,.868Z"
            data-name="Path 5098"
            fill="#333"
            id="Path_5098"
            transform="translate(-270.935 -226.27)"
          />
          <path
            d="M299.936,311.668h-12.7a.434.434,0,1,1,0-.868h12.7a.434.434,0,0,1,0,.868Z"
            data-name="Path 5099"
            fill="#333"
            id="Path_5099"
            transform="translate(-255.69 -255.779)"
          />
          <path
            d="M284.6,344.768H270.034a.434.434,0,1,1,0-.868h14.557a.435.435,0,0,1,.434.434A.426.426,0,0,1,284.6,344.768Z"
            data-name="Path 5100"
            fill="#333"
            id="Path_5100"
            transform="translate(-240.356 -285.289)"
          />
          <path
            d="M57.887,435.611v2.126a3.245,3.245,0,0,1-3.243,3.243H6.243A3.262,3.262,0,0,1,3,437.726V435.6H57.887Z"
            data-name="Path 5101"
            fill="#d3d9d9"
            id="Path_5101"
            transform="translate(-2.675 -367.042)"
          />
          <path
            d="M9.411,440.969H6.243A3.245,3.245,0,0,1,3,437.726V435.6H6.178v2.126A3.236,3.236,0,0,0,9.411,440.969Z"
            data-name="Path 5102"
            fill="#ededed"
            id="Path_5102"
            transform="translate(-2.675 -367.042)"
          />
          <path
            d="M456.222,435.7v2.126a3.245,3.245,0,0,1-3.243,3.243H449.8a3.245,3.245,0,0,0,3.243-3.243V435.7Z"
            data-name="Path 5103"
            fill="#a8b2b4"
            id="Path_5103"
            transform="translate(-401.009 -367.131)"
          />
          <path
            d="M199.061,435.7v1.529a.968.968,0,0,1-.965.965h-13.83a.968.968,0,0,1-.965-.965V435.7Z"
            data-name="Path 5104"
            fill="#fff"
            id="Path_5104"
            transform="translate(-163.417 -367.131)"
          />
          <path
            d="M299.636,435.7v1.529a.958.958,0,0,1-.965.955H296.1a.956.956,0,0,0,.955-.955V435.7Z"
            data-name="Path 5105"
            fill="#ededed"
            id="Path_5105"
            transform="translate(-263.981 -367.131)"
          />
          <path
            d="M76.1,118h7.81v1.638a3.905,3.905,0,1,1-7.81,0h0Z"
            data-name="Path 5106"
            fill="#fedd8e"
            id="Path_5106"
            transform="translate(-67.845 -83.893)"
          />
          <path
            d="M371.71,118v1.638a3.905,3.905,0,1,1-7.81.043V118Z"
            data-name="Path 5107"
            fill="#fedd8e"
            id="Path_5107"
            transform="translate(-324.427 -83.893)"
          />
          <path
            d="M220,118h7.81v1.638a3.905,3.905,0,0,1-7.81,0Z"
            data-name="Path 5108"
            fill="#fedd8e"
            id="Path_5108"
            transform="translate(-196.136 -83.893)"
          />
          <path
            d="M4.1,118h7.81v1.638a3.905,3.905,0,0,1-7.81,0h0Z"
            data-name="Path 5109"
            fill="#fc8a8a"
            id="Path_5109"
            transform="translate(-3.655 -83.893)"
          />
          <path
            d="M292,118h7.81v1.638a3.905,3.905,0,0,1-7.81,0Z"
            data-name="Path 5110"
            fill="#fc8a8a"
            id="Path_5110"
            transform="translate(-260.326 -83.893)"
          />
          <path
            d="M148,118h7.81v1.638a3.905,3.905,0,0,1-7.81,0h0Z"
            data-name="Path 5111"
            fill="#fc8a8a"
            id="Path_5111"
            transform="translate(-131.946 -83.893)"
          />
          <path
            d="M443.71,118v1.638a3.905,3.905,0,1,1-7.81,0h0V118Z"
            data-name="Path 5112"
            fill="#fc8a8a"
            id="Path_5112"
            transform="translate(-388.617 -83.893)"
          />
          <path
            d="M14.383,26.9,11.91,36.782H4.1l2.853-8.1A2.668,2.668,0,0,1,9.469,26.9Z"
            data-name="Path 5113"
            fill="#fc8a8a"
            id="Path_5113"
            transform="translate(-3.655 -2.675)"
          />
          <path
            d="M85.4,26.9l-1.5,9.882H76.1L78.573,26.9Z"
            data-name="Path 5114"
            fill="#fedd8e"
            id="Path_5114"
            transform="translate(-67.845 -2.675)"
          />
          <path
            d="M156.309,26.9l-.5,9.882H148l1.5-9.882Z"
            data-name="Path 5115"
            fill="#fc8a8a"
            id="Path_5115"
            transform="translate(-131.946 -2.675)"
          />
          <path
            d="M227.311,26.9l.5,9.882H220l.5-9.882Z"
            data-name="Path 5116"
            fill="#fedd8e"
            id="Path_5116"
            transform="translate(-196.136 -2.675)"
          />
          <path
            d="M294.212,26.9l1.5,9.882H287.9l-.5-9.882Z"
            data-name="Path 5117"
            fill="#fc8a8a"
            id="Path_5117"
            transform="translate(-256.225 -2.675)"
          />
          <path
            d="M357.023,26.9l2.473,9.882h-7.8L350.2,26.9Z"
            data-name="Path 5118"
            fill="#fedd8e"
            id="Path_5118"
            transform="translate(-312.213 -2.675)"
          />
          <path
            d="M420.541,28.679l2.853,8.1h-7.81L413.1,26.9h4.925A2.679,2.679,0,0,1,420.541,28.679Z"
            data-name="Path 5119"
            fill="#fc8a8a"
            id="Path_5119"
            transform="translate(-368.29 -2.675)"
          />
          <path
            d="M80.949,123.424a4,4,0,0,1-.944.108,3.9,3.9,0,0,1-3.905-3.905V118h1.887v1.638A3.9,3.9,0,0,0,80.949,123.424Z"
            data-name="Path 5120"
            fill="#fee9b4"
            id="Path_5120"
            transform="translate(-67.845 -83.893)"
          />
          <path
            d="M368.849,123.424a4,4,0,0,1-.944.108A3.9,3.9,0,0,1,364,119.627V118h1.887v1.638A3.9,3.9,0,0,0,368.849,123.424Z"
            data-name="Path 5121"
            fill="#fee9b4"
            id="Path_5121"
            transform="translate(-324.516 -83.893)"
          />
          <path
            d="M224.849,123.424a4,4,0,0,1-.944.108A3.9,3.9,0,0,1,220,119.627V118h1.887v1.638A3.909,3.909,0,0,0,224.849,123.424Z"
            data-name="Path 5122"
            fill="#fee9b4"
            id="Path_5122"
            transform="translate(-196.136 -83.893)"
          />
          <path
            d="M8.949,123.424a4,4,0,0,1-.944.108A3.9,3.9,0,0,1,4.1,119.627V118H5.987v1.638A3.9,3.9,0,0,0,8.949,123.424Z"
            data-name="Path 5123"
            fill="#fcb0b0"
            id="Path_5123"
            transform="translate(-3.655 -83.893)"
          />
          <path
            d="M296.849,123.424a4,4,0,0,1-.944.108,3.9,3.9,0,0,1-3.9-3.905V118h1.887v1.638A3.909,3.909,0,0,0,296.849,123.424Z"
            data-name="Path 5124"
            fill="#fcb0b0"
            id="Path_5124"
            transform="translate(-260.326 -83.893)"
          />
          <path
            d="M152.849,123.424a4,4,0,0,1-.944.108A3.9,3.9,0,0,1,148,119.627V118h1.887v1.638A3.909,3.909,0,0,0,152.849,123.424Z"
            data-name="Path 5125"
            fill="#fcb0b0"
            id="Path_5125"
            transform="translate(-131.946 -83.893)"
          />
          <path
            d="M440.849,123.424a4,4,0,0,1-.944.108,3.9,3.9,0,0,1-3.9-3.905V118h1.887v1.638A3.9,3.9,0,0,0,440.849,123.424Z"
            data-name="Path 5126"
            fill="#fcb0b0"
            id="Path_5126"
            transform="translate(-388.706 -83.893)"
          />
          <path
            d="M9.459,26.9,5.987,36.782H4.1l2.853-8.1A2.655,2.655,0,0,1,9.459,26.9Z"
            data-name="Path 5127"
            fill="#fcb0b0"
            id="Path_5127"
            transform="translate(-3.655 -2.675)"
          />
          <path
            d="M80.461,26.9l-2.473,9.882H76.1L78.573,26.9Z"
            data-name="Path 5128"
            fill="#fee9b4"
            id="Path_5128"
            transform="translate(-67.845 -2.675)"
          />
          <path
            d="M151.374,26.9l-1.486,9.882H148l1.5-9.882Z"
            data-name="Path 5129"
            fill="#fcb0b0"
            id="Path_5129"
            transform="translate(-131.946 -2.675)"
          />
          <path
            d="M222.386,26.9l-.5,9.882H220l.5-9.882Z"
            data-name="Path 5130"
            fill="#fee9b4"
            id="Path_5130"
            transform="translate(-196.136 -2.675)"
          />
          <path
            d="M289.786,36.782H287.9l-.5-9.882h1.887Z"
            data-name="Path 5131"
            fill="#fcb0b0"
            id="Path_5131"
            transform="translate(-256.225 -2.675)"
          />
          <path
            d="M353.673,36.782h-1.887L350.3,26.9h1.877Z"
            data-name="Path 5132"
            fill="#fee9b4"
            id="Path_5132"
            transform="translate(-312.302 -2.675)"
          />
          <path
            d="M417.461,36.782h-1.887L413.1,26.9h1.887Z"
            data-name="Path 5133"
            fill="#fcb0b0"
            id="Path_5133"
            transform="translate(-368.29 -2.675)"
          />
          <path
            d="M108.149,118v1.638a3.9,3.9,0,0,1-3.905,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5134"
            fill="#fdd367"
            id="Path_5134"
            transform="translate(-92.095 -83.893)"
          />
          <path
            d="M396.049,118v1.638a3.9,3.9,0,0,1-3.905,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5135"
            fill="#fdd367"
            id="Path_5135"
            transform="translate(-348.766 -83.893)"
          />
          <path
            d="M252.149,118v1.638a3.9,3.9,0,0,1-3.9,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5136"
            fill="#fdd367"
            id="Path_5136"
            transform="translate(-220.475 -83.893)"
          />
          <path
            d="M36.249,118v1.638a3.9,3.9,0,0,1-3.905,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5137"
            fill="#f96364"
            id="Path_5137"
            transform="translate(-27.994 -83.893)"
          />
          <path
            d="M324.149,118v1.638a3.9,3.9,0,0,1-3.905,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5138"
            fill="#f96364"
            id="Path_5138"
            transform="translate(-284.665 -83.893)"
          />
          <path
            d="M180.149,118v1.638a3.9,3.9,0,0,1-3.9,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.811-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5139"
            fill="#f96364"
            id="Path_5139"
            transform="translate(-156.285 -83.893)"
          />
          <path
            d="M468.049,118v1.638a3.9,3.9,0,0,1-3.9,3.905,4,4,0,0,1-.944-.108,3.941,3.941,0,0,0,1.812-1.03,3.874,3.874,0,0,0,1.15-2.755V118Z"
            data-name="Path 5140"
            fill="#f96364"
            id="Path_5140"
            transform="translate(-412.955 -83.893)"
          />
          <path
            d="M63.061,26.9l-2.473,9.882H58.7L61.173,26.9Z"
            data-name="Path 5141"
            fill="#f96364"
            id="Path_5141"
            transform="translate(-52.333 -2.675)"
          />
          <path
            d="M133.984,26.9l-1.5,9.882H130.6l1.5-9.882Z"
            data-name="Path 5142"
            fill="#fdd367"
            id="Path_5142"
            transform="translate(-116.433 -2.675)"
          />
          <path
            d="M204.986,26.9l-.5,9.882H202.6l.5-9.882Z"
            data-name="Path 5143"
            fill="#f96364"
            id="Path_5143"
            transform="translate(-180.623 -2.675)"
          />
          <path
            d="M272.386,36.782H270.5L270,26.9h1.887Z"
            data-name="Path 5144"
            fill="#fdd367"
            id="Path_5144"
            transform="translate(-240.712 -2.675)"
          />
          <path
            d="M336.273,36.782h-1.887L332.9,26.9h1.877Z"
            data-name="Path 5145"
            fill="#f96364"
            id="Path_5145"
            transform="translate(-296.789 -2.675)"
          />
          <path
            d="M400.061,36.782h-1.887L395.7,26.9h1.887Z"
            data-name="Path 5146"
            fill="#fdd367"
            id="Path_5146"
            transform="translate(-352.777 -2.675)"
          />
          <path
            d="M463.859,36.782h-1.887L458.5,26.9h0a2.679,2.679,0,0,1,2.517,1.779Z"
            data-name="Path 5147"
            fill="#f96364"
            id="Path_5147"
            transform="translate(-408.765 -2.675)"
          />
          <path
            d="M55.213,68.244H53.086V39.52a4.319,4.319,0,0,0,1.085-.781,4.138,4.138,0,0,0,1.237-2.994V34.107A.321.321,0,0,0,55.386,34l-2.842-8.1a3,3,0,0,0-2.82-2H5.792a3,3,0,0,0-2.82,2L.13,34a.477.477,0,0,1-.022.108v1.638A4.222,4.222,0,0,0,2.441,39.52V68.244H.325A.32.32,0,0,0,0,68.569V70.7a3.579,3.579,0,0,0,3.569,3.569H51.98A3.579,3.579,0,0,0,55.549,70.7V68.569A.336.336,0,0,0,55.213,68.244Zm-.445-32.5a3.58,3.58,0,1,1-7.159,0V34.433h7.159ZM3.1,68.233V39.78a4.236,4.236,0,0,0,2.517-.011V65.391a.32.32,0,0,0,.325.325H49.6a.32.32,0,0,0,.325-.325V39.78a4.236,4.236,0,0,0,2.517.011V68.244Zm32.216.662v1.2a.641.641,0,0,1-.64.64H20.848a.641.641,0,0,1-.64-.64v-1.2ZM12.149,39.976a4.263,4.263,0,0,0,3.905-2.6,4.235,4.235,0,0,0,7.81,0,4.235,4.235,0,0,0,7.81,0,4.235,4.235,0,0,0,7.81,0,4.235,4.235,0,0,0,7.81,0,4.28,4.28,0,0,0,1.985,2.137V65.076H6.259V39.509a4.464,4.464,0,0,0,1.074-.781,4.3,4.3,0,0,0,.922-1.356,4.22,4.22,0,0,0,3.894,2.6ZM8.58,35.745V34.433h7.159v1.313a3.58,3.58,0,1,1-7.159,0Zm7.8,0V34.433h7.159v1.313a3.58,3.58,0,1,1-7.159,0Zm15.62,0V34.433h7.159v1.313a3.58,3.58,0,1,1-7.159,0Zm7.81,0V34.433h7.159v1.313a3.58,3.58,0,1,1-7.159,0Zm9.925-11.194a2.339,2.339,0,0,1,2.2,1.562l2.7,7.669H47.544l-2.321-9.231Zm-5.185,0,2.321,9.231H39.755l-1.388-9.231Zm-6.834,0L39.1,33.782H31.989l-.466-9.231Zm-13.049,0H30.86l.466,9.231H24.2Zm-.477,9.882h7.159v1.313a3.58,3.58,0,1,1-7.159,0Zm-6.367-9.882h6.194l-.466,9.231H16.434Zm-6.834,0h6.183l-1.4,9.231h-7.1ZM3.6,26.113a2.339,2.339,0,0,1,2.2-1.562h4.512L7.994,33.782H.9ZM.77,35.745V34.433H7.929v1.313a3.58,3.58,0,1,1-7.159,0ZM54.887,70.684A2.919,2.919,0,0,1,51.969,73.6H3.569A2.912,2.912,0,0,1,.651,70.684v-1.8H19.568v1.2a1.3,1.3,0,0,0,1.291,1.291H34.7a1.3,1.3,0,0,0,1.291-1.291v-1.2h18.9Z"
            data-name="Path 5148"
            fill="#333"
            id="Path_5148"
          />
        </g>
      </g>
    </svg>
  );
};
