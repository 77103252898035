import { ITableContact } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export interface ContactList {
  list: ITableContact[];
}

export const useContactList = ({
  page,
  pageSize,
  search,
  orderColumn,
  orderDirection,
  tagsFilter,
  statusFilter,
  happinessFilter,
  groupsFilter,
  segmentsFilter,
  lastRefresh,
  discardedFilter,
}: {
  page: number;
  pageSize: number;
  search?: string;
  orderColumn?: string;
  orderDirection?: string;
  tagsFilter?: number[];
  discardedFilter?: string[];
  statusFilter?: string[];
  happinessFilter?: string[];
  groupsFilter?: number[];
  segmentsFilter?: number;
  lastRefresh: number;
}): [ContactList | undefined, Error | undefined, boolean] => {
  const [contactListValue, error, isLoadingGetContacts] = useEmblue(ServiceMethods.getContacts, {
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection: orderDirection,
    includeTags: true,
    tagsFilter,
    statusFilter,
    happinessFilter,
    groupsFilter,
    segmentsFilter,
    lastRefresh,
    discardedFilter,
  });

  return [contactListValue, error, isLoadingGetContacts];
};
