import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@/lib/v2/components/SecondaryNavigationMenu/SecondaryNavigationMenu';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

export const useNavigationBar = (): Record<string, Link[]> => {
  const { t } = useTranslation();
  const showRecurrentMessage = useFeatureFlag('showRecurrentMessage');

  const campaignsNavigationLinks = useMemo<Link[]>(
    () => [
      {
        key: 'campaigns',
        name: t('CAMPAIGNS_MAIN.NAVBAR.campaign'),
        to: '/v2/campaigns',
      },
      {
        key: 'all-actions',
        name: t('CAMPAIGNS_MAIN.NAVBAR.allActions'),
        to: '/v2/campaigns/actions-all',
      },
      {
        key: 'actions-draft',
        name: t('CAMPAIGNS_MAIN.NAVBAR.actionsDraft'),
        to: '/v2/campaigns/actions-draft',
      },
      {
        key: 'actions-finished',
        name: t('CAMPAIGNS_MAIN.NAVBAR.actionsFinished'),
        to: '/v2/campaigns/actions-finished',
      },
      {
        key: 'recurrent',
        name: t('CAMPAIGNS_MAIN.NAVBAR.recurrent'),
        to: '/v2/campaigns/recurrent',
        isHidden: !showRecurrentMessage ? true : false,
      },
    ],
    [t, showRecurrentMessage]
  );

  const contactsNavigationLinks = useMemo<Link[]>(
    () => [
      {
        key: 'contacts-all',
        name: t('CONTACTS_MENU_BAR.all'),
        to: '/v2/contacts',
      },
      {
        key: 'contacts-group',
        name: t('CONTACTS_MENU_BAR.groups'),
        to: '/v2/contacts/groups',
      },
      {
        key: 'contacts-segment',
        name: t('CONTACTS_MENU_BAR.segments'),
        to: '/v2/contacts/segments',
      },
      {
        key: 'contacts-tags',
        name: t('CONTACTS_MENU_BAR.tags'),
        to: '/v2/contacts/tags',
      },
      {
        key: 'contacts-items',
        name: t('CONTACTS_MENU_BAR.items'),
        to: '/v2/contacts/items',
      },
      {
        key: 'contacts-discarded',
        name: t('CONTACTS_MENU_BAR.discarded'),
        to: '/v2/contacts/discarded',
      },
      {
        key: 'contacts-import',
        name: t('CONTACTS_MENU_BAR.import'),
        to: '/v2/contacts/import',
      },
      {
        key: 'contacts-happiness',
        name: t('CONTACTS_MENU_BAR.happiness'),
        to: '/v2/contacts/happiness',
      },
    ],
    [t]
  );

  const automationNavigationLinks = useMemo<Link[]>(
    () => [
      {
        key: 'automation-flows',
        name: t('AUTOMATION_MAIN_MODULE.title'),
        to: '/v2/automation',
        id: 'menu-automation-flows',
      },
      {
        key: 'automation-events',
        name: t('AUTOMATION_MAIN_MODULE.events.title'),
        to: '/v2/automation/events',
        id: 'menu-automation-events',
      },
    ],
    [t]
  );

  const sendersNavigationLinks = useMemo<Link[]>(
    () => [
      {
        key: 'my-senders',
        name: t('SENDERS_MAIN.mySenders'),
        to: '/v2/configuration/senders',
        enable: true,
      },
      {
        key: 'validators-domain',
        name: t('SENDERS_MAIN.validatorsDomain'),
        to: '/v2/configuration/senders/domains/validator',
        enable: true,
      },
    ],
    [t]
  );

  return {
    campaignsNavigationLinks,
    contactsNavigationLinks,
    automationNavigationLinks,
    sendersNavigationLinks,
  };
};
