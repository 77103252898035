import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { useDashboardService } from '@/src/infrastructure/Protocol/Dashboard/useDashboardService';

import { ISenderActivity, ISenderScore } from '@/modules/DashboardsModule/interfaces/Dashboard';

const useScoreOverview = () => {
  const serviceDashboard = useDashboardService();
  const [dataScoring, setDataScoring] = useState<ISenderScore[]>([]);
  const [dataActivity, setDataActivity] = useState<ISenderActivity[]>([]);
  const [loadingScoring, setLoadingScoring] = useState(true);

  useEffect(() => {
    const fetchSenderScore = async () => {
      const scoringResponse = (await serviceDashboard.getDataScoreOverview()) as ISenderScore[];
      //cspell:disable-next-line
      scoringResponse.sort((a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime());
      setDataScoring(scoringResponse);
      setLoadingScoring(false);
    };

    const fetchSenderActivity = async () => {
      const activityResponse = (await serviceDashboard.getSenderActivity()) as ISenderActivity[];
      setDataActivity(activityResponse);
    };

    void fetchSenderScore();
    void fetchSenderActivity();
  }, [serviceDashboard]);

  const dataScoringGraph = useMemo(() => {
    return dataScoring.map((item) => ({
      //Cspell:disable-next-line
      date: format(new Date(item.fecha), 'dd-MM-yyyy'),
      value: parseFloat(item.score.toFixed(2)),
    }));
  }, [dataScoring]);

  const dataSenderActivity = useMemo(() => {
    return dataActivity.map((item) => ({
      //Cspell:disable
      days: item.dias,
      sends: item.enviados,
      actions: item.acciones,
      opens: item.opens,
      clicks: item.clicks,
      //Cspell:enable
    }));
  }, [dataActivity]);

  return {
    dataScoring: dataScoringGraph,
    dataSenderActivity,
    loadingScoring,
  };
};

export default useScoreOverview;
