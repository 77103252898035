export const CalculatorIcon = () => {
  return (
    <svg
      height="26.451"
      viewBox="0 0 24.89 28.451"
      width="22.89"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.32,0A.3.3,0,0,0,0,.32v27.81a.319.319,0,0,0,.32.32H24.57a.319.319,0,0,0,.32-.32V.32A.3.3,0,0,0,24.57,0H.32Zm3.24,3.561h17.8v7.122H3.561Zm0,10.683H7.122V17.8H3.561Zm7.122,0h3.561V17.8H10.683Zm7.122,0h3.561V24.926H17.8ZM3.561,21.365H7.122v3.561H3.561Zm7.122,0h3.561v3.561H10.683Z"
        data-name="Icon open-calculator"
        fill="#18c678"
        id="Icon_open-calculator"
        transform="translate(0 0)"
      />
    </svg>
  );
};
