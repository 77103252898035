/* eslint-disable regexp/prefer-d */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import {
  REGEX_CUSTOM_FIELDS,
  TRANSLATE_WHATSAPP_ACTION_FORM_ERROR,
} from '@/modules/CampaignsModule/constants';

export interface IWhatsAppFormInfo {
  country: Option;
  message: string;
  recipients: OptionExternal[];
  phone: Option;
  shortUrl: boolean;
}

export const useWhatsAppForm = () => {
  const { t } = useTranslation('inbox');
  const { control, handleSubmit, setValue, getValues } = useForm<IWhatsAppFormInfo>();

  const RULES = {
    country: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM_ERROR}.dropdown`),
    },
    message: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM_ERROR}.input`),
      validate: (value: string) => {
        // Replace all placeholders with a generic string
        const replacedInput = value.replace(REGEX_CUSTOM_FIELDS, 'PLACEHOLDER');
        // Regex to validate the input
        //const validationRegex = /^[A-ZÑa-zñ0-9!?#$%(){}Ç*+,\-./:;=@ ]+$/;
        const validationRegex = /^[A-ZÑa-zñ0-9!?#$%(){}Ç*+,\-./:;=@ ¡]+$/;
        return (
          validationRegex.test(replacedInput) ||
          t(`${TRANSLATE_WHATSAPP_ACTION_FORM_ERROR}.pattern`)
        );
      },
    },
    recipients: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM_ERROR}.dropdown`),
    },
    phone: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM_ERROR}.dropdown`),
    },
  };

  return {
    control,
    handleSubmit,
    setValue,
    getValues,
    RULES,
  };
};
