import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputBar, TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IContactActivityGroups } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ContactsProfileGroupsTable } from './ContactsProfileGroupsTable';

export const ContactsProfileActivityGroupsTable = ({ emailId }: { emailId: number }) => {
  const { t } = useTranslation();
  const [search, setSearchValue] = useState<string>('');
  const [totalGroupsContacts, setTotalGroupsContacts] = useState<number>();
  const {
    pageSize,
    pageIndex,
    totalPages,
    isLoading,
    gotoPageIndex,
    setIsLoading,
    changeTablePage,
    resetPagination,
  } = useTablePagination(totalGroupsContacts);
  const [orderDirection, setOrderDirection] = useState<string>('asc');
  const [orderColumn, setOrderColumn] = useState<string>();
  const [activityGroupsListValue] = useEmblue(ServiceMethods.getContactActivityGroups, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    search,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const [activityGroupsTotalCount] = useEmblue(ServiceMethods.getContactActivityGroups, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });
  const [activityGroupsList, setActivityGroupsList] = useState<{
    list: IContactActivityGroups[];
    total: number;
  }>();
  const cleanBoxRef = useRef<CleanBoxRef>(null);

  useEffect(() => {
    if (activityGroupsListValue) {
      setActivityGroupsList(activityGroupsListValue);
    }
  }, [activityGroupsListValue]);

  useEffect(() => {
    if (activityGroupsList) {
      setTotalGroupsContacts(activityGroupsListValue?.total);
    }
  }, [activityGroupsList, search]);

  useEffect(() => {
    activityGroupsList && activityGroupsList.list;
    resetPagination();
    if (search && totalGroupsContacts) {
      setTotalGroupsContacts(totalPages);
    }
  }, [search]);

  useEffect(() => {
    activityGroupsList && setIsLoading(false);
  }, [activityGroupsList]);

  const resetActivityItems = () => {
    cleanBoxRef?.current?.cleanBox();
    resetFilter();
  };

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
      setIsLoading(true);
    },
    [orderDirection]
  );

  return (
    <>
      {activityGroupsTotalCount && activityGroupsTotalCount.total > 0 && (
        <div className="mb-[-53px] w-[250px] py-2">
          <SearchInputBar
            ref={cleanBoxRef}
            withButton
            cleanBox={resetFilter}
            placeHolder={t('PROFILE_GROUPS_TABLE.searchInputBar')}
            onAction={(ev) => setSearchValue && setSearchValue(ev)}
          />
        </div>
      )}
      <Flex column>
        {!activityGroupsList && (
          <span className="h-full w-full py-10">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {activityGroupsList && (
        <ContactsProfileGroupsTable
          activityList={activityGroupsList.list}
          activityTotalCount={activityGroupsTotalCount ? activityGroupsTotalCount.total : 0}
          callToAction={[resetActivityItems]}
          changeTableOrder={changeOrderBy}
          changeTablePage={changeTablePage}
          gotoPageIndex={gotoPageIndex}
          isLoading={isLoading}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
