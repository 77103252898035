import { useAtom, useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Text } from '@/lib/v2/components';
import { RemoveIcon } from '@/lib/v2/icons/solid';

import { atomToggleDeleteActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { atomSelectedActionsRowsWithID } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import DeleteRecurrentActions from '@/modules/CampaignsModule/components/CampaignRecurrentModal/DeleteRecurrentActions/DeleteRecurrentActions';
import { IActionIcon } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ActionBarButtons = () => {
  const { t } = useTranslation();

  const [toggleDeleteActionsModal, setToggleDeleteActionsModal] = useAtom(
    atomToggleDeleteActionsModal
  );

  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);

  const ICONS_LIST_ACTIONS: IActionIcon[] = [
    {
      icon: <RemoveIcon />,
      onClick: () => {
        setToggleDeleteActionsModal(true);
      },
      tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
      hide: false,
      id: 'delete-campaign-action',
    },
  ];

  return (
    <Flex alignment="start" className="bg-emblue-primary">
      <Flex alignment="start" className="px-4">
        <div className="min-w-[120px]">
          <Text color="white" variant="text-sm">
            {selectedActionsRowsWithID.length > 1
              ? `${selectedActionsRowsWithID.length} ${t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.selected')}`
              : `${selectedActionsRowsWithID.length} ${t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.select')}`}
          </Text>
        </div>
        <Flex withGap alignment="start" gapSize="small">
          {ICONS_LIST_ACTIONS.map((element) => {
            const { icon, hide, tooltip, onClick: handleOnClick, id } = element;
            return (
              <>
                {!hide && (
                  <div key={id}>
                    <Button
                      fullWidth
                      outline
                      standard
                      iconLeft={icon}
                      id={id}
                      tooltip={tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )}
              </>
            );
          })}
        </Flex>
      </Flex>
      {toggleDeleteActionsModal && <DeleteRecurrentActions />}
    </Flex>
  );
};

export default memo(ActionBarButtons);
