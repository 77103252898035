import { memo } from 'react';

import { Container } from '@/lib/v2/components';

import { FormCreateEvent } from './components/FormCreateEvent';
import { HeaderCreateEvent, StructureDataEvent } from './components';
interface CreateEventMainProps {
  isEditView?: boolean;
}
const CreateEventMain = ({ isEditView = false }: CreateEventMainProps) => {
  return (
    <div className="size-full">
      <HeaderCreateEvent isEditView={isEditView} />
      <Container
        fullHeight
        noPaddingY
        background="white"
        className="min-h-[calc(100vh_-_72px_-_64px)]"
        fluid="screen"
      >
        <div className="flex w-full grow flex-col divide-x-2 sm:flex-row">
          <FormCreateEvent isEditView={isEditView} />
          <StructureDataEvent />
        </div>
      </Container>
    </div>
  );
};

export default memo(CreateEventMain);
