import { useCallback, useState } from 'react';

import { Button, Modal, Text } from '@/lib/v2/components';

import { IEmailOfCatalog } from '@/src/modules/CampaignsModule/interfaces/Beefree';

interface IModalDelete {
  open: boolean;
  data: IEmailOfCatalog | null;
  onCancel: () => Promise<void>;
  onAccept: (id: number) => Promise<void>;
  wordings: {
    title: string;
    message: string;
    actions: {
      cancel: string;
      accept: string;
    };
  };
}

export const ModalDelete = (props: IModalDelete) => {
  const { open, wordings, onCancel, onAccept, data } = props;
  const [loading, setLoading] = useState(false);

  const handleCancel = useCallback(() => {
    void onCancel();
  }, [onCancel]);

  const handleAccept = useCallback(async () => {
    setLoading(true);
    const id = data?.catalogId ?? data?.id;
    if (typeof id !== 'number') return;

    await onAccept(id);
    setLoading(false);
  }, [data?.catalogId, data?.id, onAccept]);

  return (
    <Modal noPadding withAuto open={open} onClose={handleCancel}>
      <div className="flex w-96 flex-col p-4 py-6">
        <Text variant="headline">{wordings.title}</Text>
        <Text className="py-2" variant="text">
          {wordings.message}
        </Text>

        <div className="mt-4 flex justify-center gap-4">
          <Button outline size="small" onClick={handleCancel}>
            {wordings.actions.cancel}
          </Button>
          <Button isLoading={loading} size="small" onClick={handleAccept}>
            {wordings.actions.accept}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
