import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InputSearch } from '@/lib/v2/components';
import { CleanInputSearchRef } from '@/lib/v2/components/InputSearch/InputSearch';
import { Flex } from '@/lib/v2/components/Layout/Stack';
// import { useSearchParams } from 'react-router-dom';

export interface IOnsiteFilter {
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnClear?: () => void;
  onReset?: () => void;
}

export const OnsiteFilters = ({ onSearch, handleOnClear }: IOnsiteFilter) => {
  const { t } = useTranslation();

  const inputSearchRef = useRef<CleanInputSearchRef>(null);

  return (
    <>
      <Flex alignment="start" itemAlignment="center">
        <Flex withGap alignment="start">
          {
            <div className="flex w-[300px] flex-col">
              <InputSearch
                ref={inputSearchRef}
                placeHolder={t('ONSITE_FILTERS.searchInputBar')}
                onChange={onSearch}
                onClear={handleOnClear}
              />
            </div>
          }
        </Flex>
      </Flex>
    </>
  );
};
