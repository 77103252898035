export const ContactsIcon = () => (
  <svg
    height="16.992"
    viewBox="0 0 17.773 16.992"
    width="17.773"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(11.703 13.612)">
      <path
        d="M-1225.1,99.2a4.548,4.548,0,0,0,.282.657h0C-1224.912,99.669-1225.006,99.388-1225.1,99.2Z"
        fill="#004dbc"
        transform="translate(1225.1 -99.2)"
      />
    </g>
    <g transform="translate(8.793 10.608)">
      <path d="M-1228.2,96Z" fill="#004dbc" transform="translate(1228.2 -96)" />
    </g>
    <g transform="translate(9.074 10.513)">
      <path
        d="M-1227.9,95.9a.345.345,0,0,1,.282.094.345.345,0,0,0-.282-.094Z"
        fill="#004dbc"
        transform="translate(1227.9 -95.9)"
      />
    </g>
    <g transform="translate(9.732 7.628)">
      <path
        d="M-1219.221,97.12a7.543,7.543,0,0,0-2.722-4.224.6.6,0,0,0-.563,0,4.6,4.6,0,0,1-3.943.657,5.323,5.323,0,0,1-.751,1.22c.094,0,.094.094.188.094a8.091,8.091,0,0,1,3,4.412h3.1C-1218.658,99.467-1219.221,97.12-1219.221,97.12Z"
        fill="#004dbc"
        transform="translate(1227.2 -92.825)"
      />
    </g>
    <path
      d="M-1225.333,84.7a3.909,3.909,0,0,0-3.567,2.347,4.679,4.679,0,0,1,2.722,4.224,3.194,3.194,0,0,1-.094.939,2.894,2.894,0,0,0,.939.094,3.807,3.807,0,0,0,3.849-3.849A3.786,3.786,0,0,0-1225.333,84.7Z"
      fill="#004dbc"
      transform="translate(1237.036 -84.7)"
    />
    <g transform="translate(2.222 2.817)">
      <circle cx="3.849" cy="3.849" fill="#004dbc" r="3.849" />
    </g>
    <g transform="translate(0 10.444)">
      <path
        d="M-1225.488,100.12a7.543,7.543,0,0,0-2.722-4.224.6.6,0,0,0-.563,0,4.808,4.808,0,0,1-5.445,0,.6.6,0,0,0-.563,0,7.265,7.265,0,0,0-2.722,4.224s-.563,2.253,1.69,2.253h8.636C-1224.925,102.373-1225.488,100.12-1225.488,100.12Z"
        fill="#004dbc"
        transform="translate(1237.567 -95.825)"
      />
    </g>
  </svg>
);
