import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import { ICampaignListPayload } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { getParamsCampaigns, getParamsPager } from '@/src/modules/CampaignsModule/utils';

import { useCampaignRecurrent } from './useCampaignRecurrent';

import {
  atomCampaignsRecurrentList,
  atomFiltersRecurrent,
  atomHistorySearchPagerRecurrent,
  atomLoadingProcess,
  atomOrderBy,
  atomPagerCampaignsRecurrent,
  atomRefetchAllData,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

export const useRecurrentData = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { getList } = useCampaignRecurrent();

  const orderBy = useAtomValue(atomOrderBy);
  const [refetchAllData, setRefetchAllData] = useAtom(atomRefetchAllData);
  const [recurrentMessageList, setRecurrentMessageList] = useAtom(atomCampaignsRecurrentList);
  const [filters, setFilters] = useAtom(atomFiltersRecurrent);
  const [pager, setPager] = useAtom(atomPagerCampaignsRecurrent);

  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsCampaignRecurrentList = useAtomValue(atomTotalsProcess);

  const filtersCount = useMemo(() => Object.keys(filters ?? {}).length, [filters]);

  const getListCampaigns = useCallback(
    (payload: ICampaignListPayload) => {
      void getList(payload);
    },
    [getList]
  );

  useEffect(() => {
    if (refetchAllData) {
      getListCampaigns({
        page: pager?.page,
        limit: pager?.limit,
        ...filters,
        ...orderBy,
      });
      setRefetchAllData(RESET);
    }
  }, [getListCampaigns, refetchAllData, setRefetchAllData]);

  const historySearchPagerCampaign = useAtomValue(atomHistorySearchPagerRecurrent);

  const initializeFilters = useCallback(() => {
    const filtersURL = getParamsCampaigns();
    setFilters(filtersURL);
  }, [setFilters]);

  const initializePager = useCallback(() => {
    const pagerURL = getParamsPager();
    setPager(pagerURL);
  }, [setPager]);

  useEffect(() => {
    if (location.state?.from === 'actions') {
      setPager(historySearchPagerCampaign?.pager ?? defaultPager);
      setFilters(historySearchPagerCampaign?.filters ?? {});
      navigate('.', { state: null });
      return;
    }

    if (!filters || !pager) {
      initializeFilters();
      initializePager();
    }

    return () => {
      setFilters(undefined);
      setPager(undefined);
      setRecurrentMessageList(RESET);
    };
  }, [
    location?.state?.from,
    setRecurrentMessageList,
    setFilters,
    setPager,
    initializeFilters,
    initializePager,
  ]);

  const getParamsSearchAll = useCallback((): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentPage = pager?.page ?? defaultPager.page;
    const currentLimit = pager?.limit ?? defaultPager.limit;

    if (pager?.page) {
      currentPage !== defaultPager.page || queryParams.has('page')
        ? queryParams.set('page', currentPage.toString())
        : queryParams.delete('page');
    }

    if (pager?.limit) {
      currentLimit !== defaultPager.limit || queryParams.has('limit')
        ? queryParams.set('limit', currentLimit.toString())
        : queryParams.delete('limit');
    }

    const currentFilterKeys = new Set(queryParams.keys());

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (key === 'startDate' || key === 'endDate') {
          const timeStampCast = new Date(value as Date).getTime() / 1000;
          queryParams.set(key, timeStampCast.toString());
        } else if (Array.isArray(value)) {
          queryParams.set(key, value.join(','));
        } else if (value != null) {
          queryParams.set(key, String(value));
        }
        currentFilterKeys.delete(key);
      });
    }

    if (filters !== undefined && filters !== null) {
      currentFilterKeys.forEach((key) => {
        if (key !== 'page' && key !== 'limit') {
          queryParams.delete(key);
        }
      });
    }

    return `${location.pathname}?${queryParams.toString()}`;
  }, [filters, location.pathname, pager?.limit, pager?.page]);

  useEffect(() => {
    const URL = getParamsSearchAll();
    navigate(URL, { replace: true });
  }, [pager, filters, getParamsSearchAll, navigate]);

  useEffect(() => {
    if (!filters && !pager) return;

    const payload = {
      page: pager?.page,
      limit: pager?.limit,
      ...filters,
      ...orderBy,
    };
    getListCampaigns(payload);
  }, [filters, pager?.page, pager?.limit, orderBy]);

  return {
    recurrentMessageList,
    loadingProcess,
    totalsCampaignRecurrentList,
    filtersCount,
  };
};
