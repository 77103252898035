export const GroupAddIconSolid = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20">
      <g data-name="Group 7416" id="Group_7416" transform="translate(-0.318 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.318 -0.163)"
          width="20"
        />
        <g data-name="Group 7429" id="Group_7429" transform="translate(-3.901 -4.164)">
          <g data-name="Group 6770" id="Group_6770" transform="translate(5.219 10.689)">
            <path
              d="M6.62,4.5V8.741m2.12-2.12H4.5"
              data-name="Path 3413"
              fill="none"
              id="Path_3413"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              transform="translate(-4.5 -4.5)"
            />
            <line
              data-name="Line 75"
              fill="none"
              id="Line_75"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeWidth="1.5"
              transform="translate(0.089 6.926)"
              x2="4.215"
            />
          </g>
          <g data-name="Group 6771" id="Group_6771" transform="translate(10.937 8.001)">
            <g data-name="Group 6567" id="Group_6567" transform="translate(8.155 9.484)">
              <path
                d="M-1225.1,99.2a3.16,3.16,0,0,0,.2.458h0C-1224.969,99.527-1225.035,99.331-1225.1,99.2Z"
                data-name="Path 3928"
                fill="#004dbc"
                id="Path_3928"
                transform="translate(1225.1 -99.2)"
              />
            </g>
            <g data-name="Group 6568" id="Group_6568" transform="translate(6.128 7.393)">
              <path
                d="M-1228.2,96Z"
                data-name="Path 3929"
                fill="#004dbc"
                id="Path_3929"
                transform="translate(1228.2 -96)"
              />
            </g>
            <g data-name="Group 6569" id="Group_6569" transform="translate(6.324 7.327)">
              <path
                d="M-1227.9,95.9a.24.24,0,0,1,.2.065.24.24,0,0,0-.2-.065Z"
                data-name="Path 3930"
                fill="#004dbc"
                id="Path_3930"
                transform="translate(1227.9 -95.9)"
              />
            </g>
            <g data-name="Group 6570" id="Group_6570" transform="translate(6.782 5.316)">
              <path
                d="M-1221.639,95.818a5.256,5.256,0,0,0-1.9-2.944.417.417,0,0,0-.392,0,3.2,3.2,0,0,1-2.748.458,3.7,3.7,0,0,1-.523.85c.066,0,.066.065.131.065a5.641,5.641,0,0,1,2.093,3.075h2.159C-1221.247,97.453-1221.639,95.818-1221.639,95.818Z"
                data-name="Path 3931"
                fill="#004dbc"
                id="Path_3931"
                transform="translate(1227.2 -92.825)"
              />
            </g>
            <path
              d="M-1226.414,84.7a2.724,2.724,0,0,0-2.486,1.635,3.261,3.261,0,0,1,1.9,2.944,2.225,2.225,0,0,1-.065.654,2.018,2.018,0,0,0,.654.065,2.653,2.653,0,0,0,2.682-2.682A2.638,2.638,0,0,0-1226.414,84.7Z"
              data-name="Path 3932"
              fill="#004dbc"
              id="Path_3932"
              transform="translate(1234.57 -84.7)"
            />
            <g data-name="Group 6573" id="Group_6573" transform="translate(1.548 1.963)">
              <circle
                cx="2.682"
                cy="2.682"
                data-name="Ellipse 1742"
                fill="#004dbc"
                id="Ellipse_1742"
                r="2.682"
                transform="translate(0 0)"
              />
            </g>
            <g data-name="Group 6574" id="Group_6574" transform="translate(0 7.277)">
              <path
                d="M-1229.149,98.818a5.258,5.258,0,0,0-1.9-2.944.417.417,0,0,0-.393,0,3.35,3.35,0,0,1-3.794,0,.417.417,0,0,0-.392,0,5.063,5.063,0,0,0-1.9,2.944s-.393,1.57,1.177,1.57h6.018C-1228.757,100.388-1229.149,98.818-1229.149,98.818Z"
                data-name="Path 3933"
                fill="#004dbc"
                id="Path_3933"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
