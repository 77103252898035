export const GlossaryIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.46019 6.42828C7.37579 6.78042 7.29139 7.13258 7.20117 7.51383H7.82397C7.72211 7.1093 7.63189 6.76588 7.54458 6.41956C7.51839 6.41956 7.4922 6.42537 7.4631 6.42828"
        fill="#A7B1CC"
      />
      <path
        d="M7.50067 13.7739C8.7521 13.7739 10.0035 13.7827 11.252 13.7739C12.6839 13.7623 13.7869 12.6884 13.7985 11.2565C13.8189 8.74203 13.8218 6.22754 13.7985 3.71305C13.784 2.27536 12.6693 1.20729 11.2433 1.20438C8.76665 1.19856 6.2929 1.19856 3.81624 1.20438C2.30289 1.20729 1.21153 2.30156 1.20571 3.82073C1.19989 6.25955 1.19697 8.69547 1.20571 11.1343C1.21153 12.7088 2.28833 13.7739 3.86571 13.7797C5.07639 13.7856 6.28999 13.7797 7.50358 13.7797L7.50067 13.7739ZM6.73817 9.32991C6.70034 9.48125 6.65086 9.62675 6.61594 9.77809C6.54318 10.0982 6.33946 10.2525 6.02224 10.1681C5.71957 10.0866 5.64681 9.84504 5.72248 9.55401C5.9553 8.62854 6.18231 7.70305 6.41513 6.77758C6.53154 6.31484 6.64504 5.8521 6.77018 5.39228C6.87786 4.99648 7.14852 4.7753 7.50358 4.7753C7.86155 4.7753 8.13802 4.98776 8.23988 5.38938C8.59494 6.77468 8.93836 8.16289 9.28468 9.5511C9.35744 9.8363 9.2905 10.0837 8.98783 10.1681C8.67061 10.2554 8.4698 10.0953 8.39122 9.781C8.28354 9.35901 8.17295 8.93411 8.06527 8.50629C7.99833 8.48592 7.97505 8.47137 7.94885 8.47137C7.29501 8.4151 6.89145 8.70129 6.73817 9.32991Z"
        fill="#A7B1CC"
      />
      <path
        d="M22.8003 12.86C22.8003 11.2739 21.7177 10.2 20.1199 10.1971C18.6764 10.1971 17.23 10.1971 15.7865 10.1971H14.7039C14.8232 11.5329 14.666 12.732 13.7085 13.6953C12.7482 14.6644 11.5375 14.7837 10.2249 14.6906V15.1271C10.2249 16.7918 10.222 18.4536 10.2249 20.1183C10.2307 21.7364 11.3046 22.7987 12.9286 22.8016C15.315 22.8016 17.6986 22.8016 20.085 22.8016C21.7322 22.8016 22.7974 21.7393 22.8003 20.095C22.8003 17.6824 22.8003 15.2726 22.8003 12.86ZM17.8266 19.2132C16.9623 19.2277 16.1008 19.2306 15.2365 19.2161C14.7533 19.2073 14.5758 18.8436 14.8319 18.4099C15.4809 17.3128 16.1416 16.2214 16.7964 15.1271C16.8604 15.0165 16.9215 14.9059 17.035 14.7051C16.8284 14.7051 16.6858 14.7051 16.5432 14.7051C16.0921 14.7051 15.641 14.708 15.1899 14.7022C14.9018 14.6964 14.7126 14.5392 14.7097 14.254C14.7097 13.9746 14.8901 13.7884 15.1753 13.7855C16.063 13.7738 16.9535 13.7651 17.8441 13.7855C18.2952 13.7971 18.4465 14.1696 18.1962 14.5974C17.5792 15.6393 16.9506 16.6754 16.3307 17.7144C16.2347 17.8774 16.1415 18.0432 15.9931 18.3023C16.6654 18.3023 17.23 18.2761 17.7917 18.3168C17.9692 18.3284 18.2079 18.4769 18.2893 18.6311C18.4349 18.9105 18.1933 19.2103 17.8324 19.2161"
        fill="#A7B1CC"
      />
      <path
        d="M18.1839 8.58804C18.1869 8.92563 18.1898 9.26903 18.1898 9.61245H19.1647C19.1647 9.26612 19.1618 8.9198 19.1589 8.5793C19.1414 6.87969 17.8347 5.59334 16.1176 5.58752C15.5065 5.58752 14.8924 5.58752 14.2812 5.58752V6.55955C14.8924 6.55955 15.5036 6.55955 16.1147 6.55955C17.2847 6.56246 18.1752 7.43265 18.1869 8.58512"
        fill="#A7B1CC"
      />
      <path
        d="M6.56382 16.0007C6.56382 15.4681 6.56382 14.9326 6.56382 14.4H5.58887C5.58887 14.9355 5.58887 15.471 5.58887 16.0065C5.59469 17.84 6.88686 19.1293 8.73198 19.138C9.00555 19.138 9.27912 19.138 9.55269 19.138V18.163C9.27912 18.163 9.00846 18.163 8.73489 18.163C7.41944 18.1572 6.56382 17.3103 6.56091 16.0007"
        fill="#A7B1CC"
      />
    </svg>
  );
};
