import { memo } from 'react';

import { Button, Modal, Text } from '@/lib/v2/components';

interface ReactivateDiscardedUsersModalProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onClick: () => void;
  isLoading?: boolean;
  title: string;
  description: string;
  textButton: string;
}

const ReactivateDiscardedUsersModal = ({
  isOpen,
  onClose,
  onClick,
  isLoading,
  title,
  description,
  textButton,
}: ReactivateDiscardedUsersModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="relative flex flex-col gap-4">
        <Text fontWeight="bold">{title}</Text>
        <Text>{description}</Text>
        <Button destructive id="reactivate-button" isLoading={isLoading} onClick={onClick}>
          {textButton}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ReactivateDiscardedUsersModal);
