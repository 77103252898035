/* eslint-disable @typescript-eslint/no-unused-vars */
import { timeout } from '@/src/compat/util';

import { IRulesService } from './IRulesService';
import { ContentHtmlSuccessPageMock } from './Rules.mocks';

import {
  IRulesV1Response,
  PayloadSaveCloseDateRule,
  PayloadSaveRules,
} from '@/modules/RulesModule/interfaces';

export class StubRulesService implements IRulesService {
  static create(): IRulesService {
    return new StubRulesService();
  }

  /**
   * @param payload: IRulesV1Response
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Save rules
   */
  async saveRule(payload: PayloadSaveRules): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: actionId: number
   * @returns {Promise<IRulesV1Response>}
   * @description Get rule detail
   */
  async getRuleById(actionId: number): Promise<IRulesV1Response> {
    try {
      await timeout(2000);
      return {} as IRulesV1Response;
    } catch (error) {
      return {} as IRulesV1Response;
    }
  }

  /**
   * @param payload: active: boolean; actionId: number
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Set touch rules
   */
  async setTouchRules(payload: {
    active: boolean;
    actionId: number;
  }): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: actionId: number
   * @returns {Promise<{ statusOK: boolean; data?: { active: boolean } }>}
   * @description Activate rule
   */
  async activateRule(actionId: number): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: actionId: number
   * @returns {Promise<{ statusOK: boolean; data?: { active: boolean } }>}
   * @description Inactivate rule
   */
  async inactivateRule(actionId: number): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param payload: PayloadSaveCloseDateRule
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Save the closing date of the rules
   */
  async saveCloseDate(payload: PayloadSaveCloseDateRule): Promise<{ statusOK: boolean }> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @param {boolean} isSuccessRedirectExternalUrl - Indicates if the redirect page is a success.
   * @returns {Promise<string>}
   * @description Retrieves the HTML of the redirect page, indicating whether it is a success or error.
   */
  async getRedirectRulePageHtml(isSuccessRedirectExternalUrl: boolean): Promise<string> {
    try {
      await timeout(2000);
      return ContentHtmlSuccessPageMock;
    } catch (error) {
      return '';
    }
  }
}
