import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from '@/lib/v2/components';

import { ApiManagerMenuBar } from '@/src/modules/ApiManagerModule/components/ApiManagerMenuBar';

const ApiManagerLayout = () => {
  return (
    <>
      <ApiManagerMenuBar />
      <Container fullHeight fluid="screen">
        <Outlet />
      </Container>
    </>
  );
};

export default memo(ApiManagerLayout);
