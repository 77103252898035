import { FC } from 'react';

import { Card, Flex } from '@/lib/v2/components';

import { ChartSkeleton } from './ChartSkeleton';
import { PieChartWidget } from './PieChartWidget';
import { IPieChartCard } from './types';

export const PieSemiChartCard: FC<IPieChartCard> = ({ chartData, label }) => {
  return (
    <Card className="relative m-auto flex h-[236px] justify-center">
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
        {chartData ? (
          <Flex withGap alignment="center" gapSize="large">
            <PieChartWidget
              halfPie
              chartData={chartData}
              label={label}
              maxValue={100}
              minValue={-100}
            />
          </Flex>
        ) : (
          <ChartSkeleton halfPie />
        )}
      </div>
    </Card>
  );
};
