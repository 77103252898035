/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useHtmlEditorService } from '@/src/infrastructure/Protocol/HtmlEditor/useHtmlEditorService';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { prepareHtml } from '@/src/modules/CampaignsModule/components/HtmlUpload/util/htmlModifiers';
import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { htmlEditorAtom, isLoadingHtmlAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';

export const useHtml = () => {
  const serviceHtmlEditor = useHtmlEditorService();

  const { actionId } = useParams();
  const [searchParams] = useSearchParams();
  const { action } = useActionData(actionId ? parseInt(actionId) : 0);
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);

  const setIsLoading = useSetAtom(isLoadingHtmlAtom);
  const [html, setHtml] = useAtom(htmlEditorAtom);

  const [userData] = useUserData();
  const companyId = +userData.companyId;

  const sendElementID = actionInfoEmail?.shippingElementId;
  const accountId = companyId;

  const fileName = action?.name || '';
  const emptyEditor = searchParams.get('emptyEditor') || '';

  const getElementHtml = async () => {
    setIsLoading(true);
    const response = await serviceHtmlEditor.getElementHtml(Number(actionId));
    if (response) {
      const { result } = response;
      const { preparedHtml } = prepareHtml(result);
      setHtml(preparedHtml);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getElementHtml();
  }, []);

  return {
    cleanedFileString: html,
    fileName,
    sendElementID,
    emptyEditor,
    accountId,
    actionId: Number(actionId),
  };
};
