export const VideoSubscriptionIcon = () => {
  return (
    <svg height="61.8" viewBox="0 0 58.527 61.8" width="58.527" xmlns="http://www.w3.org/2000/svg">
      <g id="subscribe-button" transform="translate(-6.43 -5)">
        <path
          d="M52.86,5H11.111A4.692,4.692,0,0,0,6.43,9.681V50.24a4.681,4.681,0,0,0,4.681,4.681H46.474l.767,9.156A2.93,2.93,0,0,0,50.148,66.8h9.087a3.033,3.033,0,0,0,3.01-2.541l1.545-8.08a3.674,3.674,0,0,0-2.6-4.166l-4.7-1.4v-.641h4.578a3.9,3.9,0,0,0,3.891-3.9v-7.3a3.9,3.9,0,0,0-3.891-3.891H57.507V9.7A4.692,4.692,0,0,0,52.86,5ZM11.111,7.289H52.86a2.4,2.4,0,0,1,2.4,2.392V34.859H8.719V9.681a2.4,2.4,0,0,1,2.392-2.392ZM8.719,50.24V37.17H30.326a3.777,3.777,0,0,0-.366,1.6v5.345H24.4a3.628,3.628,0,0,0-6.924,0H16.238a1.144,1.144,0,1,0,0,2.289h1.236a3.628,3.628,0,0,0,6.924,0h5.631a3.857,3.857,0,0,0,3.822,3.571H49.232a4.658,4.658,0,0,0-1.362.973,5.47,5.47,0,0,0-1.019,1.682H11.111A2.392,2.392,0,0,1,8.719,50.24Zm13.619-4.978a1.408,1.408,0,1,1-1.408-1.35A1.408,1.408,0,0,1,22.338,45.262Zm38.2,8.95a1.4,1.4,0,0,1,1.007,1.545L59.99,63.882a.767.767,0,0,1-.755.641H50.148a.641.641,0,0,1-.629-.572l-.767-9.224a3.147,3.147,0,0,1,.8-2.209,2.048,2.048,0,0,1,.549-.4v2.014a1.144,1.144,0,0,0,2.289,0v-11.7h0v-.87a.9.9,0,1,1,1.808,0v7.267h0v2.644a1.144,1.144,0,0,0,.813,1.144Zm2.129-15.427v7.3a1.614,1.614,0,0,1-1.6,1.614H56.488V43.568h1.968a1.144,1.144,0,1,0,0-2.289H56.431a3.147,3.147,0,0,0-6.283,0H36.415a1.144,1.144,0,1,0,0,2.289H50.1v4.12H33.851a1.614,1.614,0,0,1-1.591-1.614v-7.3a1.6,1.6,0,0,1,1.6-1.6h27.2a1.6,1.6,0,0,1,1.6,1.6Z"
          data-name="Path 3408"
          fill="#465ce7"
          id="Path_3408"
          transform="translate(0)"
        />
        <path
          d="M30.117,31.153A10.907,10.907,0,1,0,19.21,20.247,10.907,10.907,0,0,0,30.117,31.153Zm0-19.524A8.618,8.618,0,1,1,21.5,20.247a8.618,8.618,0,0,1,8.618-8.618Z"
          data-name="Path 3409"
          fill="#465ce7"
          id="Path_3409"
          transform="translate(1.846 0.627)"
        />
        <path
          d="M27.191,24.42a1.957,1.957,0,0,0,1.408-.286L32.913,21.5a2.014,2.014,0,0,0,.641-.641,1.991,1.991,0,0,0-.641-2.747L28.6,15.436a1.865,1.865,0,0,0-1.007-.286,1.9,1.9,0,0,0-1.911,1.9v5.516a1.968,1.968,0,0,0,.286,1.007,1.911,1.911,0,0,0,1.225.847ZM28,17.736l3.319,2.071-3.353,2.037Z"
          data-name="Path 3410"
          fill="#465ce7"
          id="Path_3410"
          transform="translate(2.781 1.466)"
        />
      </g>
    </svg>
  );
};
