import { Route, Routes } from 'react-router-dom';

import NpsLayout from '@/src/modules/NPSModule/layouts/NpsLayout/NpsLayout';
import { NPSMain } from '@/src/modules/NPSModule/screens/NPSMain';

export const NpsModuleRouter = () => (
  <Routes>
    <Route element={<NpsLayout />} path="/">
      <Route index element={<NPSMain />} />
    </Route>
  </Routes>
);
