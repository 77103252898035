import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';

interface CreateSegmentButtonProps {
  createSegment: () => void;
}

const CreateSegmentButton = ({ createSegment }: CreateSegmentButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      iconLeft={
        <IconSvg height="15px" strokeColor="white" svgComponent={<CreateIcon />} width="15px" />
      }
      id="create-segment-button"
      onClick={createSegment}
    >
      {t('CONTACTS_ACTIONS_DROPDOWN.Segment')}
    </Button>
  );
};

export default memo(CreateSegmentButton);
