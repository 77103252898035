import { Listbox, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { t } from 'i18next';
import {
  ChangeEvent,
  forwardRef,
  Fragment,
  KeyboardEvent,
  memo,
  Ref,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconSvg, Input } from '@/lib/v2/components';
import { withController } from '@/lib/v2/HOCs/WithController';
import { LoadingIcon } from '@/lib/v2/icons/animated';

import { OptionExternal, SelectExternalSearchProps } from './Interfaces';
import SelectedOptions from './SelectedOptions';
import SelectOption from './SelectOption';

import './SelectExternalSearch.tailwind.css';

const SelectExternalSearch = forwardRef(
  (
    {
      label,
      multiSelect,
      value,
      onChange,
      onChangeSearch,
      onLastItem,
      onClose,
      options,
      defaultValue,
      placeholder = t('SELECT.selectItem'),
      name,
      error,
      message,
      isLoading,
      isSearching,
      searchPlaceholder,
      id,
      readOnly,
      disabled,
      isRequired,
      emptyResults = t('IMPORT_MAIN.empty_results'),
      classNameMenuContainer,
    }: SelectExternalSearchProps,
    ref: Ref<HTMLElement>
  ) => {
    const [search, setSearch] = useState('');
    const inputSearchRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    const classes = classNames('eb-select');
    const classesSelect = classNames('eb-select--select', 'min-w-full', 'overflow-x-hidden', {
      'eb-select--error': error,
      readOnly,
      disabled: disabled && !readOnly,
    });
    const classesMessage = classNames(error ? 'message-error' : 'eb-select--message-info');
    const classesMenuOptions = classNames(
      'sm:text-sm absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none',
      {
        classNameMenuContainer,
        '!overflow-y-hidden': isSearching,
      },
      classNameMenuContainer
    );

    const deleteOption = useCallback(
      (index: number) => () => {
        if (Array.isArray(value)) {
          const newOptions = [...value];
          newOptions.splice(index, 1);
          onChange?.(newOptions);
        }
      },
      [onChange, value]
    );

    const autoFocusSearch = useCallback(() => {
      inputSearchRef.current?.focus();
    }, []);

    const handleKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.stopPropagation();
      }
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearch(value);
      onChangeSearch?.(value);
    };

    const handleScroll = useCallback(() => {
      if (listRef?.current) {
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;
        const roundedScrollHeight = Math.round(scrollHeight);
        const roundedScrollTop = Math.round(scrollTop);
        const roundedClientHeight = Math.round(clientHeight);

        if (roundedScrollHeight - roundedScrollTop <= roundedClientHeight) {
          onLastItem?.(options[options.length - 1]);
        }
      }
    }, [onLastItem, options]);

    const handleClose = useCallback(() => {
      setSearch('');
      onClose?.();
    }, [onClose]);

    const isSelectAnItem = useMemo(
      () => value && !Array.isArray(value) && value.name === placeholder,
      [value, placeholder]
    );

    if (isLoading) {
      return (
        <div className="flex w-full animate-pulse flex-col" data-testid="select-component">
          {label && <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>}
          <div className="my-1 h-[38px] w-full rounded bg-gray-200"></div>
        </div>
      );
    }

    return (
      <Listbox
        by="id"
        data-testid="select-component"
        multiple={multiSelect}
        name={name}
        value={value ?? []}
        onChange={onChange}
        {...(defaultValue && { defaultValue })}
        ref={ref}
        disabled={disabled || readOnly}
      >
        {({ open }) => (
          <div className={classes}>
            {label && (
              <Listbox.Label className="label">
                {label} {isRequired && <span className="text-red-500"> * </span>}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={classesSelect}
                id={`${String(id)}-button`}
                onClick={(e) => e.stopPropagation()}
              >
                {placeholder &&
                  ((Array.isArray(value) && value.length === 0) ||
                    value === undefined ||
                    (!Array.isArray(value) &&
                      typeof value === 'object' &&
                      String(value.name) === '')) && (
                    <span className="block text-[#A7B1CC]">{placeholder}</span>
                  )}
                {value && (
                  <span className={`block ${isSelectAnItem ? 'text-[#A7B1CC]' : ''}`}>
                    {Array.isArray(value) ? (
                      <SelectedOptions deleteOption={deleteOption} value={value} />
                    ) : (
                      value?.name
                    )}
                  </span>
                )}
                {defaultValue && !value && (
                  <span className="block">
                    <>{({ value: valueOption }: { value: OptionExternal }) => valueOption?.name}</>
                  </span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={`size-5 text-gray-400${open ? 'rotate-180' : ''} transition-all`}
                  />
                </span>
              </Listbox.Button>

              <Transition
                afterEnter={autoFocusSearch}
                afterLeave={handleClose}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options
                  ref={listRef}
                  static
                  aria-disabled={isSearching}
                  className={classesMenuOptions}
                  onScroll={handleScroll}
                >
                  <div className="relative w-full px-2">
                    <Input
                      ref={inputSearchRef}
                      iconLeft={<IconSvg strokeColor="gray" svgComponent={<SearchIcon />} />}
                      iconRight={
                        isSearching ? <IconSvg svgComponent={<LoadingIcon />} /> : undefined
                      }
                      id={`${String(id)}-search`}
                      placeHolder={searchPlaceholder}
                      value={search}
                      onChange={handleChangeSearch}
                      onKeyDown={handleKeyEvent}
                    />
                  </div>
                  <div className="relative">
                    {isSearching && (
                      <div className="absolute inset-x-0 top-0 z-10 m-auto flex size-full items-center justify-center bg-white bg-opacity-50">
                        <div className="absolute bottom-3">
                          <LoadingIcon />
                        </div>
                      </div>
                    )}
                    {options.length > 0 ? (
                      options.map((option) => <SelectOption key={option.id} option={option} />)
                    ) : (
                      <span className="block py-2 text-center text-[#A7B1CC]">{emptyResults}</span>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </div>
            <p className={classesMessage} id={name && `${name}-message`}>
              {message}
            </p>
          </div>
        )}
      </Listbox>
    );
  }
);

export default memo(withController(SelectExternalSearch));
