export const AutomationIcon = () => {
  return (
    <svg className="w-full" viewBox="0 0 18.802 19.018">
      <path
        d="M18,14.824V12.5A3.5,3.5,0,0,0,14.5,9h-2A1.5,1.5,0,0,1,11,7.5V5.176a2.4,2.4,0,1,0-2,0V7.5A1.5,1.5,0,0,1,7.5,9h-2A3.5,3.5,0,0,0,2,12.5v2.324A2.4,2.4,0,0,0,3,19.4a2.4,2.4,0,1,0,1-4.576V12.5A1.5,1.5,0,0,1,5.5,11h2A3.464,3.464,0,0,0,9,10.65v4.174A2.4,2.4,0,0,0,10,19.4a2.4,2.4,0,1,0,1-4.576V10.65a3.464,3.464,0,0,0,1.5.35h2A1.5,1.5,0,0,1,16,12.5v2.324a2.4,2.4,0,1,0,2,0ZM10,1.615A1.384,1.384,0,1,1,8.616,3,1.384,1.384,0,0,1,10,1.615ZM3,18.385A1.385,1.385,0,1,1,4.385,17,1.385,1.385,0,0,1,3,18.385Zm7,0A1.385,1.385,0,1,1,11.385,17,1.385,1.385,0,0,1,10,18.385Zm7,0A1.385,1.385,0,1,1,18.385,17,1.385,1.385,0,0,1,17,18.385Z"
        fill="#1a8ae4"
        id="automatize"
        transform="translate(-0.598 -0.6)"
      />
    </svg>
  );
};
