import { atomWithBroadcast } from '@/lib/v2/hooks/atomWithBroadcast';

export const defaultContactDownload = {
  show: false,
  error: false,
  disabled: false,
  file: {
    url: '',
    token: '',
    name: '',
  },
  wordings: {
    message: '',
    action: '',
  },
  status: 'loading',
};

export const atomContactsDownload = atomWithBroadcast('contactsDownload', defaultContactDownload);
