import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'react-phone-number-input';

import { Button, Input, Option, Select, Text, toast } from '@/lib/v2/components';

import { formValidations } from '@/src/constants/FormValidations';
import { geolocationES } from '@/src/ContactsModule/utils/Geolocation';
import { StepTwoBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomDealResponseIds,
  atomFormStepOne,
  atomFormStepTwo,
  atomSignUpCurrentStep,
  atomStepCompleted,
} from '@/src/modules/AuthModule/atoms/SignUp';
import { useSignUpStepTwoOptions } from '@/src/modules/AuthModule/hooks/useSignUpStepTwoOptions';
import { useValidateEmailOrOrganization } from '@/src/modules/AuthModule/hooks/useValidateEmailOrOrganization';

export interface FormStepTwo {
  company: string;
  industry: Option;
  numberOfContacts: Option;
  role: string;
  youUseEmblue: Option;
}

const SignUpFormTwo = () => {
  const { t } = useTranslation();
  const setCurrentStep = useSetAtom(atomSignUpCurrentStep);
  const setStepCompleted = useSetAtom(atomStepCompleted);
  const [formData, setFormData] = useAtom(atomFormStepTwo);
  const formDataOne = useAtomValue(atomFormStepOne);
  const [dealResponseIds, setDealResponseIds] = useAtom(atomDealResponseIds);
  const [isLoading, setIsLoading] = useState(false);

  const { checkIfEmailExist } = useValidateEmailOrOrganization('organization');

  const { industryOptions, numberOfContactsOptions, youUseEmblueOptions } =
    useSignUpStepTwoOptions();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
    setError,
    trigger,
  } = useForm<FormStepTwo>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    delayError: 500,
  });

  const { formRegExp, inputLengths } = formValidations;
  const rules: Record<keyof FormStepTwo, UseControllerProps['rules']> = {
    company: {
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
    industry: {
      required: t('FORMS_RULES_MESSAGES.required'),
    },
    numberOfContacts: {
      required: t('FORMS_RULES_MESSAGES.required'),
    },
    role: {
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
    youUseEmblue: {
      required: t('FORMS_RULES_MESSAGES.required'),
    },
  };

  const service = useService();

  const handleNextClick = async (data: FormStepTwo) => {
    setIsLoading(true);
    const orgExists = await checkIfEmailExist(data.company);
    if (orgExists) {
      setError('company', { type: 'validate', message: t('SignUpFormTwo.orgExists') });
    } else {
      const country = parsePhoneNumber(formDataOne?.phone ?? '')?.country ?? 'AR';
      const countryName = geolocationES[country as keyof typeof geolocationES];

      const body: StepTwoBody = {
        company: data.company,
        country: countryName,
        industry: data.industry.id as number,
        numberOfContacts: data.numberOfContacts.id as number,
        role: data.role,
        youUseEmblue: data.youUseEmblue.id as number,
        personId: dealResponseIds?.personId ?? 0,
        dealId: dealResponseIds?.dealId ?? 0,
        email: formDataOne?.email ?? '',
      };

      const response = await service.setDeal(2, body);
      if (response?.dealId && response?.personId && response?.orgId) {
        setDealResponseIds(response);
        setFormData(data);
        setStepCompleted(3);
        setCurrentStep(3);
      } else {
        toast({
          title: t('SignUpFormOne.error'),
          body: t('SignUpFormOne.errorPipeDrive'),
          variant: 'error',
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (formData) {
      setValue('company', formData.company);
      setValue('industry', formData.industry);
      setValue('numberOfContacts', formData.numberOfContacts);
      setValue('role', formData.role);
      setValue('youUseEmblue', formData.youUseEmblue);
      void trigger();
    }
  }, [formData, setValue, trigger]);

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleNextClick)}>
      <Text className="mb-4" color="primary" fontWeight="medium" variant="headline">
        {t('SignUpFormTwo.title')}
      </Text>
      <Input
        isRequired
        control={control}
        id="company"
        label={t('SignUpFormTwo.company')}
        name="company"
        placeHolder={t('SignUpFormTwo.company')}
        rules={rules.company}
      />
      <Select
        isRequired
        control={control}
        id="industry"
        label={t('SignUpFormTwo.industry')}
        name="industry"
        options={industryOptions}
        rules={rules.industry}
      />
      <Select
        isRequired
        control={control}
        id="numberOfContacts"
        label={t('SignUpFormTwo.numberOfContacts')}
        name="numberOfContacts"
        options={numberOfContactsOptions}
        rules={rules.numberOfContacts}
      />
      <Input
        isRequired
        control={control}
        id="role"
        label={t('SignUpFormTwo.role')}
        name="role"
        placeHolder={t('SignUpFormTwo.role')}
        rules={rules.role}
      />
      <Select
        isRequired
        control={control}
        id="youUseEmblue"
        label={t('SignUpFormTwo.youUseEmblue')}
        name="youUseEmblue"
        options={youUseEmblueOptions}
        rules={rules.youUseEmblue}
      />
      <Button fullWidth className="mt-6" disabled={!isValid} isLoading={isLoading} type="submit">
        {t('SignUpFormTwo.next')}
      </Button>
    </form>
  );
};

export default memo(SignUpFormTwo);
