export const MailTableIcon = () => {
  return (
    <svg className="mr-2 flex h-8 w-8 self-center" viewBox="0 0 32 18.489">
      <path
        d="M34.156,24H12.111a2.853,2.853,0,0,0-2.844,2.844v1.067a.711.711,0,1,0,1.422,0V26.844a1.785,1.785,0,0,1,.071-.427l7.964,6.827L10.76,40.071a1.785,1.785,0,0,1-.071-.427V38.578a.711.711,0,1,0-1.422,0v1.067a2.853,2.853,0,0,0,2.844,2.844H34.156A2.853,2.853,0,0,0,37,39.645v-12.8A2.853,2.853,0,0,0,34.156,24ZM11.827,25.458a.693.693,0,0,1,.284-.036H34.156a.693.693,0,0,1,.284.036L23.6,34.738a.747.747,0,0,1-.923,0ZM34.156,41.067H12.111a.693.693,0,0,1-.284-.036l8-6.862,1.915,1.671a2.069,2.069,0,0,0,2.77,0l1.92-1.671,8,6.862A.693.693,0,0,1,34.156,41.067Zm1.422-1.422a1.786,1.786,0,0,1-.071.427l-7.964-6.827,7.964-6.827a1.785,1.785,0,0,1,.071.427ZM7.133,31.467a.714.714,0,0,1,.711-.711h4.267a.711.711,0,0,1,0,1.422H7.844a.714.714,0,0,1-.711-.711Zm4.978,4.267h-6.4a.711.711,0,1,1,0-1.422h6.4a.711.711,0,1,1,0,1.422Z"
        fill="#18c678"
        transform="translate(-5 -24)"
      />
    </svg>
  );
};
