// cspell:disable
export interface CharSymbol {
  symbol: string;
  code: string;
}

const Symbols: CharSymbol[] = [
  { symbol: '¢', code: '&cent;' },
  { symbol: '€', code: '&euro;' },
  { symbol: '£', code: '&pound;' },
  { symbol: '¥', code: '&yen;' },
  { symbol: '¤', code: '&curren;' },
  { symbol: '₠', code: '&#8360;' },
  { symbol: '₡', code: '&#8353;' },
  { symbol: '₢', code: '&#8354;' },
  { symbol: '₣', code: '&#8355;' },
  { symbol: '₤', code: '&#8356;' },
  { symbol: '₥', code: '&#8357;' },
  { symbol: '₦', code: '&#8358;' },
  { symbol: '₧', code: '&#8359;' },
  { symbol: '₨', code: '&#8360;' },
  { symbol: '₩', code: '&#8361;' },
  { symbol: '₪', code: '&#8362;' },
  { symbol: '₫', code: '&#8363;' },
  { symbol: '₭', code: '&#8365;' },
  { symbol: '₮', code: '&#8366;' },
  { symbol: '₯', code: '&#8367;' },
  { symbol: '₰', code: '&#8368;' },
  { symbol: '°', code: '&#176;' },
  { symbol: '₱', code: '&#8369;' },
  { symbol: '₲', code: '&#8370;' },
  { symbol: 'â', code: '&#226;' },
  { symbol: '₳', code: '&#8371;' },
  { symbol: '₴', code: '&#8372;' },
  { symbol: '₵', code: '&#8373;' },
  { symbol: '₶', code: '&#8374;' },
  { symbol: '₷', code: '&#8375;' },
  { symbol: '₸', code: '&#8376;' },
  { symbol: '₹', code: '&#8377;' },
  { symbol: '₺', code: '&#8378;' },
  { symbol: '₻', code: '&#8379;' },
  { symbol: '₼', code: '&#8380;' },
  { symbol: '₽', code: '&#8381;' },
  { symbol: '円', code: '&#20870;' },
  { symbol: '元', code: '&#20803;' },
  { symbol: '©', code: '&copy;' },
  { symbol: '®', code: '&reg;' },
  { symbol: '™', code: '&trade;' },
  { symbol: '‰', code: '&permil;' },
  { symbol: 'µ', code: '&micro;' },
  { symbol: '·', code: '&middot;' },
  { symbol: '•', code: '&bull;' },
  { symbol: '…', code: '&hellip;' },
  { symbol: '′', code: '&prime;' },
  { symbol: '″', code: '&Prime;' },
  { symbol: '§', code: '&sect;' },
  { symbol: '¶', code: '&para;' },
  { symbol: 'ß', code: '&szlig;' },
  { symbol: 'ƒ', code: '&fnof;' },
  { symbol: '∫', code: '&int;' },
  { symbol: '∑', code: '&sum;' },
  { symbol: '∞', code: '&infin;' },
  { symbol: '√', code: '&radic;' },
  { symbol: '∼', code: '&sim;' },
  { symbol: '≅', code: '&cong;' },
  { symbol: '≈', code: '&asymp;' },
  { symbol: '≠', code: '&ne;' },
  { symbol: '≡', code: '&equiv;' },
  { symbol: '∈', code: '&isin;' },
  { symbol: '∉', code: '&notin;' },
  { symbol: '∋', code: '&ni;' },
  { symbol: '∏', code: '&prod;' },
  { symbol: '∧', code: '&and;' },
  { symbol: '∨', code: '&or;' },
  { symbol: '¬', code: '&not;' },
  { symbol: '∩', code: '&cap;' },
  { symbol: '∪', code: '&cup;' },
  { symbol: '∂', code: '&part;' },
  { symbol: '∀', code: '&forall;' },
  { symbol: '∃', code: '&exist;' },
  { symbol: '∅', code: '&empty;' },
  { symbol: '∇', code: '&nabla;' },
  { symbol: '∗', code: '&lowast;' },
  { symbol: '∝', code: '&prop;' },
  { symbol: '∠', code: '&ang;' },
  { symbol: 'À', code: '&Agrave;' },
  { symbol: 'Â', code: '&Acirc;' },
  { symbol: 'Ã', code: '&Atilde;' },
  { symbol: 'Ä', code: '&Auml;' },
  { symbol: 'Å', code: '&Aring;' },
  { symbol: 'Æ', code: '&AElig;' },
  { symbol: 'Ç', code: '&Ccedil;' },
  { symbol: 'È', code: '&Egrave;' },
  { symbol: 'Ê', code: '&Ecirc;' },
  { symbol: 'Ë', code: '&Euml;' },
  { symbol: 'Ì', code: '&Igrave;' },
  { symbol: 'Î', code: '&Icirc;' },
  { symbol: 'Ï', code: '&Iuml;' },
  { symbol: 'Ð', code: '&ETH;' },
  { symbol: 'Ò', code: '&Ograve;' },
  { symbol: 'Ô', code: '&Ocirc;' },
  { symbol: 'Õ', code: '&Otilde;' },
  { symbol: 'Ö', code: '&Ouml;' },
  { symbol: 'Ø', code: '&Oslash;' },
  { symbol: 'Œ', code: '&OElig;' },
  { symbol: 'Š', code: '&Scaron;' },
  { symbol: 'Ù', code: '&Ugrave;' },
  { symbol: 'Û', code: '&Ucirc;' },
  { symbol: 'Ü', code: '&Uuml;' },
  { symbol: 'Ý', code: '&Yacute;' },
  { symbol: 'Ÿ', code: '&Yuml;' },
  { symbol: 'Þ', code: '&THORN;' },
  { symbol: 'à', code: '&agrave;' },
  { symbol: 'â', code: '&acirc;' },
  { symbol: 'ã', code: '&atilde;' },
  { symbol: 'ä', code: '&auml;' },
  { symbol: 'å', code: '&aring;' },
  { symbol: 'æ', code: '&aelig;' },
  { symbol: 'ç', code: '&ccedil;' },
  { symbol: 'è', code: '&egrave;' },
  { symbol: 'ê', code: '&ecirc;' },
  { symbol: 'ë', code: '&euml;' },
  { symbol: 'ì', code: '&igrave;' },
  { symbol: 'î', code: '&icirc;' },
  { symbol: 'ï', code: '&iuml;' },
  { symbol: 'ð', code: '&eth;' },
  { symbol: 'ò', code: '&ograve;' },
  { symbol: 'ô', code: '&ocirc;' },
  { symbol: 'õ', code: '&otilde;' },
  { symbol: 'ö', code: '&ouml;' },
  { symbol: 'ø', code: '&oslash;' },
  { symbol: 'œ', code: '&oelig;' },
  { symbol: 'š', code: '&scaron;' },
  { symbol: 'Â', code: '&#194;' },
  { symbol: 'ù', code: '&ugrave;' },
  { symbol: 'û', code: '&ucirc;' },
  { symbol: 'ü', code: '&uuml;' },
  { symbol: 'ý', code: '&yacute;' },
  { symbol: 'þ', code: '&thorn;' },
  { symbol: 'ÿ', code: '&yuml;' },
  { symbol: 'Α', code: '&Alpha;' },
  { symbol: 'Β', code: '&Beta;' },
  { symbol: 'Γ', code: '&Gamma;' },
  { symbol: 'Δ', code: '&Delta;' },
  { symbol: 'Ε', code: '&Epsilon;' },
  { symbol: 'Ζ', code: '&Zeta;' },
  { symbol: 'Η', code: '&Eta;' },
  { symbol: 'Θ', code: '&Theta;' },
  { symbol: 'Ι', code: '&Iota;' },
  { symbol: 'Κ', code: '&Kappa;' },
  { symbol: 'Λ', code: '&Lambda;' },
  { symbol: 'Μ', code: '&Mu;' },
  { symbol: 'Ν', code: '&Nu;' },
  { symbol: 'Ξ', code: '&Xi;' },
  { symbol: 'Ο', code: '&Omicron;' },
  { symbol: 'Ρ', code: '&Rho;' },
  { symbol: 'Σ', code: '&Sigma;' },
  { symbol: 'Τ', code: '&Tau;' },
  { symbol: 'Υ', code: '&Upsilon;' },
  { symbol: 'Φ', code: '&Phi;' },
  { symbol: 'Χ', code: '&Chi;' },
  { symbol: 'Ψ', code: '&Psi;' },
  { symbol: 'Ω', code: '&Omega;' },
  { symbol: 'α', code: '&alpha;' },
  { symbol: 'β', code: '&beta;' },
  { symbol: 'γ', code: '&gamma;' },
  { symbol: 'δ', code: '&delta;' },
  { symbol: 'ε', code: '&epsilon;' },
  { symbol: 'ζ', code: '&zeta;' },
  { symbol: 'η', code: '&eta;' },
  { symbol: 'θ', code: '&theta;' },
  { symbol: 'ι', code: '&iota;' },
  { symbol: 'κ', code: '&kappa;' },
  { symbol: 'λ', code: '&lambda;' },
  { symbol: 'μ', code: '&mu;' },
  { symbol: 'ν', code: '&nu;' },
  { symbol: 'ξ', code: '&xi;' },
  { symbol: 'ο', code: '&omicron;' },
  { symbol: 'π', code: '&pi;' },
  { symbol: 'ρ', code: '&rho;' },
  { symbol: 'σ', code: '&sigma;' },
  { symbol: 'τ', code: '&tau;' },
  { symbol: 'υ', code: '&upsilon;' },
  { symbol: 'φ', code: '&phi;' },
  { symbol: 'χ', code: '&chi;' },
  { symbol: 'ψ', code: '&psi;' },
  { symbol: 'ω', code: '&omega;' },
  { symbol: 'ℵ', code: '&alefsym;' },
  { symbol: 'ϖ', code: '&piv;' },
  { symbol: 'ℜ', code: '&real;' },
  { symbol: 'ϒ', code: '&upsih;' },
  { symbol: '℘', code: '&wp;' },
  { symbol: 'ℑ', code: '&image;' },
  { symbol: '←', code: '&larr;' },
  { symbol: '↑', code: '&uarr;' },
  { symbol: '→', code: '&rarr;' },
  { symbol: '↓', code: '&darr;' },
  { symbol: '↔', code: '&harr;' },
  { symbol: '↵', code: '&crarr;' },
  { symbol: '⇐', code: '&lArr;' },
  { symbol: '⇑', code: '&uArr;' },
  { symbol: '⇒', code: '&rArr;' },
  { symbol: '⇓', code: '&dArr;' },
  { symbol: '⇔', code: '&hArr;' },
  { symbol: '∴', code: '&there4;' },
  { symbol: '⊂', code: '&sub;' },
  { symbol: '⊃', code: '&sup;' },
  { symbol: '⊄', code: '&nsub;' },
  { symbol: '⊆', code: '&sube;' },
  { symbol: '⊇', code: '&supe;' },
  { symbol: '⊕', code: '&oplus;' },
  { symbol: '⊗', code: '&otimes;' },
  { symbol: '⊥', code: '&perp;' },
  { symbol: '⋅', code: '&sdot;' },
  { symbol: '⌈', code: '&lceil;' },
  { symbol: '⌉', code: '&rceil;' },
  { symbol: '⌊', code: '&lfloor;' },
  { symbol: '⌋', code: '&rfloor;' },
  { symbol: '〈', code: '&lang;' },
  { symbol: '〉', code: '&rang;' },
  { symbol: '◊', code: '&loz;' },
  { symbol: '□', code: '&square;' },
  { symbol: '▪', code: '&bull;' },
  { symbol: '■', code: '&#9632;' },
  { symbol: '▢', code: '&#9633;' },
  { symbol: '▣', code: '&#9635;' },
  { symbol: '▤', code: '&#9636;' },
  { symbol: '▥', code: '&#9637;' },
  { symbol: '▦', code: '&#9638;' },
  { symbol: '▧', code: '&#9639;' },
  { symbol: '▨', code: '&#9640;' },
  { symbol: '▩', code: '&#9641;' },
  { symbol: '▪', code: '&bull;' },
  { symbol: '▫', code: '&#9643;' },
  { symbol: '▮', code: '&#9644;' },
  { symbol: '▯', code: '&#9645;' },
  { symbol: '▰', code: '&#9648;' },
  { symbol: '▱', code: '&#9649;' },
  { symbol: '⬛', code: '&#11035;' },
  { symbol: '⬜', code: '&#11036;' },
  { symbol: '◧', code: '&#9687;' },
  { symbol: '◨', code: '&#9688;' },
  { symbol: '◩', code: '&#9689;' },
  { symbol: '◪', code: '&#9690;' },
  { symbol: '◫', code: '&#9691;' },
];

export default Symbols;
