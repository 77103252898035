import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { IconSvgProps } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import {
  ActionTypeName,
  EActionStatusAvailable,
  IOrderByTable,
  IPager,
  TCampaignID,
} from './Campaigns';

import { TriggerTypes } from '@/modules/RulesModule/interfaces';
import { SenderStatus } from '@/modules/SendersModule/interfaces/Senders';

export interface IChildProps {
  children: React.ReactElement;
}

export enum EChannelsID {
  MASTER = 7,
  EMAIL = 1,
  SMS = 8,
  EMAIL_TRIGGER = 3,
  EMAIL_ATTACHMENT = 9,
  EMAIL_API = 4,
  SMS_API = 5,
}

export enum EActionTypes {
  DRAFT = 'draft',
  FINISHED = 'finished',
  ALL = 'all',
}

export enum EDateFilterType {
  CREATION_DATE = 'creationDate',
  EXE_DATE = 'exeDate',
  MODIFICATION_DATE = 'modificationDate',
}

export interface ChannelInfo {
  icon: ReactElement<IconSvgProps>;
  route: string;
}

export interface IActionsFooterItem {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element;
}

export interface ICampaignActions {
  actionId: number;
  name: string;
  campaignId: number;
  campaignName: string;
  channel: EChannelsID;
  status: number;
  creationDate: string;
  exeDate: string | null; // This field could be null. It should be included in the interface
  modificationDate: string;
  audience: number | null;
  openingRate: number | null;
  clickRate: number | null;
  report?: boolean;
  splitTest: boolean;
  htmlOriginId: number;
  originId: number;
  touchRules?: boolean;
}

export interface ITableCampaignActions {
  action: ICampaignActions;
}

export interface ICampaignsActionsResponse {
  statusOK?: boolean;
  actions?: ITableCampaignActions[];
  total?: number;
  partial?: number;
}

export interface ICampaignActionsListPayload {
  campaignId?: number | undefined;
  type?: EActionTypes;
  page?: number;
  limit?: number;
  search?: string;
  state?: number[];
  channel?: number[];
  startDate?: Date;
  endDate?: Date;
  sortField?: string;
  sortOrder?: string;
  dateFilterField?: EDateFilterType;
}

export interface ITotalActions {
  total: number;
  partial: number;
}

export interface IFilterByTableActions {
  [key: string]: string | number | Date | number[] | undefined;
  search?: string;
  status?: number[];
  channel?: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface IMutationContext {
  changeTablePage: ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => void;
  resetPagination: () => void;
  setFilterBy: Dispatch<SetStateAction<IFilterByTableActions>>;
  setClearFilterBy: Dispatch<SetStateAction<boolean>>;
  setOrderBy: (orderBy: IOrderByTable) => void;
}
export interface IStateContext {
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  gotoPageIndex: number;
  filterBy: IFilterByTableActions;
  clearFilterBy: boolean;
  orderBy: IOrderByTable;
}

export interface IDuplicateCampaignActions {
  campaignActionId: number;
  actionName: string;
  actionType: number;
}

export interface IDuplicateCampaignActionsResponse {
  actionIdDuplicate: number;
  statusOK: boolean;
}

export interface IDuplicateCampaignsResponse {
  campaignId: number;
  statusOK: boolean;
}

export type TActionID = number;

export interface IEditActionPayload {
  campaignElementId: number;
  name: string;
  originId?: number;
  htmlOriginId?: number;
}

export interface IEditActionParams extends IEditActionPayload {
  showToast?: boolean;
}
export interface IEditActionResponse {
  statusOK: boolean;
}

export interface IFormMoveAction {
  campaigns: string;
}

export interface IHistorySearchAction {
  filters: IFilterByTableActions;
  pager: IPager;
}

export enum EResourceType {
  CAMPAIGN = 'campaign',
  ACTION = 'action',
  STRATEGY = 'strategy',
}

export interface ICheckNameResource {
  resource: EResourceType;
  name: string;
  campaignId?: TCampaignID;
}

export interface ITouchRulesResponse {
  days: number;
  emails: number;
}

interface ISender {
  id: number;
  nameFrom: string;
  emailFrom: string;
  emailReply: string;
}

interface IEmailInfo {
  subject: string;
  firstLine: string;
}

export interface IContent {
  editorVersion: string;
  footer: string;
  header: string;
  message: string;
  mobile: {
    body: string;
    footer: string;
    header: string;
  };
  originId: number;
  errors: string[];
}

export interface IAddresseesByType {
  id: number;
  name: string;
  type: number;
}

interface ITouchRules {
  isTouchRules: boolean;
  days: number;
  emails: number;
}

export interface IEmailInfoResponse {
  actionId: number;
  shippingElementId: number;
  touchRules: ITouchRules;
  sender: ISender;
  emailInfo: IEmailInfo;
  content: IContent;
  groupsAndLists: IAddresseesByType[];
}

export enum EGroupAndListsType {
  USER = 2,
  PROFILE = 3,
  INTEREST = 5,
  INTEGRATION = 7,
}

export enum EAddressTypeByString {
  USER = 'USER',
  PROFILE = 'PROFILE',
  INTEREST = 'INTEREST',
  INTEGRATION = 'INTEGRATION',
}

// cspell:disable
//TODO: Remove this when migrate service CantidadDestinatarios to V1
export enum EGroupAndListsTypeV1Map {
  Usuario = 2,
  Perfil = 3,
  Interes = 5,
  Integracion = 7,
}
export enum EGroupAndListsTypeV1MapString {
  USUARIO = 'user',
  PERFIL = 'profile',
  INTERES = 'interest',
  INTEGRACION = 'integration',
}
export enum EGroupAndListsTypeV1MapAlt {
  user = 2,
  profile = 3,
  interest = 5,
  integration = 7,
}

export enum EGroupAndListsTypeV1MapSms {
  user = 'usuario',
  profile = 'perfil',
  interest = 'interes',
  integration = 'perfil',
}
export enum EGroupAndListsTypeV1MapAltSms {
  usuario = 'user',
  perfil = 'profile',
  interes = 'interest',
  integracion = 'integration',
}
// cspell:enable

export interface ElementAddressType {
  name: string;
  type: number;
}

export interface IListAddresseesByType {
  [EAddressTypeByString.USER]: ElementAddressType[];
  [EAddressTypeByString.PROFILE]: ElementAddressType[];
  [EAddressTypeByString.INTEREST]: ElementAddressType[];
  [EAddressTypeByString.INTEGRATION]: ElementAddressType[];
}

type IReinforcement = {
  isReinforcementShipment: boolean;
  startDate?: string;
  subject?: string;
};

export interface IAddresseesInfoResponse {
  isImmediateShipping: boolean;
  shippingDate: string;
  reinforcement: IReinforcement;
}

//TODO: remove this interface
export interface IDeliveryActionResponse {
  isImmediateShipping: boolean;
  shippingDate: string;
  reinforcement: {
    isReinforcementShipment: boolean;
    startDate: string;
    subject: string;
  };
}

export interface IDeliveryActionPayload {
  actionId: number;
}

export interface ISetDeliveryActionPayload {
  actionType: number;
  actionId: number;
  isImmediateShipment: boolean;
  delivery?: {
    date: string | null;
    hour: string | null;
  };
  reinforcement: {
    subject: string | null;
    isReinforcementShipment: boolean;
    date: string | null;
    hour: string | null;
  };
}

export interface ISetDeliveryActionResponse {
  message: string;
  campaignElementId: number;
  shippingElementId: number;
}

export interface IAddresseesFilterType {
  user: boolean;
  profile: boolean;
  integration: boolean;
  interest: boolean;
}

export interface IAddresseesPayload {
  offset: number;
  groupQuantity: number;
  search?: string;
  searchById?: number;
  sortField?: string;
  sortOrder?: string;
  filterType: IAddresseesFilterType;
}

export interface IAddresseesListResponse {
  result: IAddresseesList[];
}

export interface IAddresseesList {
  groupId: number;
  name: string;
  contactCount: string;
  rank: number;
  type: string;
}

type IEmailInfoGroup = {
  id: number;
  type: number;
};

export interface IEmailInfoPayload {
  senderId: number;
  subject: string;
  firstLine: string;
  touchRules: boolean;
  groups: IEmailInfoGroup[];
}

export interface IUpdateStatusAction {
  actionId: number;
  statusId: EActionStatusAvailable;
}

export enum EStepHeaderAction {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last',
}

export type IActionHeaderCurrentStep = EStepHeaderAction;

export type IEmailFormInfo = {
  sender: {
    id: string | number;
    name: string;
    value: string;
    iconStatus?: SenderStatus;
  };
  subject: string;
  firstLine: string;
  touchRules: boolean;
  recipients: OptionExternal[];
};

export interface DeliveryOption {
  id: string;
  icon: ReactElement<IconSvgProps>;
  title: string;
  description: string;
  children: React.ReactNode;
}

export type IFormProps<T extends FieldValues> = {
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  control: Control<T>;
};

export enum EActionTypesPath {
  EMAIL = 'email',
  EMAIL_SPLIT = 'email-split',
  EMAIL_REMARKETING = 'email-remarketing',
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
}

export interface ICalendarActionsPayload {
  startDate: string;
  endDate: string;
  actionStatus: number[];
}

export interface ICalendarCampaignActions {
  id: number;
  name: string;
  type: number;
  status: number;
  sendDate: string;
  campaignId: number;
  campaignName: string;
}

export interface ICalendarCampaignList {
  [key: string]: ICalendarCampaignActions[];
}

interface IContentEmailPreview {
  footer: string;
  header: string;
  message: string;
  editorVersion: string;
}
export interface IEmailPreview {
  subject: string;
  content: IContentEmailPreview;
  sender: ISender;
}

// cspell:disable
export interface ISmsInfoPayload {
  elementoId: number;
  campoId: number;
  mensaje: string;
  track: boolean;
}
// cspell:enable

export enum DELIVERY_OPTIONS {
  immediate = 'immediate-delivery',
  scheduled = 'scheduled-delivery',
}

// cspell:disable
export interface IScheduledSMSDeliveryV1 {
  elementoId: number;
  minuto: number;
  hora: number;
  milis: number;
  force_cache?: number;
}
// cspell:enable

// cspell:disable
export interface IRecipientsSMSByType {
  id: number | string;
  nombre: string;
  tipo: number | string;
}
// cspell:enable

// cspell:disable
export interface IListRecipientsSMSByType {
  ['INTERES']: IRecipientsSMSByType[];
  ['PERFIL']: IRecipientsSMSByType[];
  ['USUARIO']: IRecipientsSMSByType[];
  ['INTEGRACION']: IRecipientsSMSByType[];
}
// cspell:enable

export enum ERulesActionType {
  ALL = 'all',
  RULES = 'rules',
}

export type ConfirmEmailErrors =
  | 'BLOCKED_SENDING_HOURS'
  | 'SENDING_LIMIT_EXCEEDED'
  | 'DAILY_LIMIT_EXCEEDED'
  | 'BLOCKED_USER_PERMISSION_TO_CONFIRM_EMAIL';

export interface IReportParams {
  clave: string;
  valor: string;
}

export interface ActionNameWithOptionalRule {
  name: ActionTypeName;
  ruleType?: TriggerTypes;
}

export enum HTML_ORIGIN_ID_MAP {
  UPLOAD_FILE = 1,
  UPLOAD_URL = 2,
  HISTORY = 3,
  TEMPLATE = 4,
  FROM_SCRATCH = 5,
  CONTENT_MANAGER_MANUAL_SUPPORT = 6,
  CONTENT_MANAGER_REMARKETING = 7,
  DRAG_DROP_EDITOR = 8,
}
