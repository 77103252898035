/* eslint-disable @typescript-eslint/no-explicit-any */
export const urlWithPublicPath = (url: string) => {
  return url.replace('./', '');
};

export const groupObjectByKey = <T>(arr: T[], key: keyof T): Record<string | number, T[]> => {
  return arr.reduce((acc, curr) => {
    const keyValue = curr[key];
    if (typeof keyValue === 'string' || typeof keyValue === 'number') {
      delete curr[key];
      (acc[keyValue] = acc[keyValue] || []).push(curr);
    }
    return acc;
  }, {} as Record<string | number, T[]>);
};
