export const CheckConfirmCircleIcon = ({ blue, green }: { blue?: boolean; green?: boolean }) => {
  return (
    <svg className="h-full w-full" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 4076" id="Group_4076" transform="translate(5620 4218)">
        <g data-name="Group 4048" id="Group_4048" transform="translate(-5620 -4218)">
          <g data-name="Group 4018" id="Group_4018" transform="translate(0 0)">
            <ellipse
              cx="10"
              cy="10"
              data-name="Ellipse 1559"
              fill={`
                            ${blue || green ? (blue ? '#004dbc' : green && '#4ade80') : '#004dbc'}
                        `}
              id="Ellipse_1559"
              rx="10"
              ry="10"
            />
          </g>
        </g>
        <path
          d="M4.087,13.374.176,9.463a.6.6,0,0,1,0-.851l.851-.851a.6.6,0,0,1,.851,0L4.512,10.4l5.642-5.642a.6.6,0,0,1,.851,0l.851.851a.6.6,0,0,1,0,.851L4.937,13.374A.6.6,0,0,1,4.087,13.374Z"
          data-name="Icon awesome-check"
          fill="#fff"
          id="Icon_awesome-check"
          transform="translate(-5616.016 -4217.063)"
        />
      </g>
    </svg>
  );
};
