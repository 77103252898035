import { ExclamationCircleIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import {
  ChangeEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  LegacyRef,
  memo,
  MouseEventHandler,
  useMemo,
  useState,
} from 'react';

import { withController } from '@/lib/v2/HOCs/WithController';

import './Input.tailwind.css';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** controlled input value */
  value?: string;
  /** default value input not controlled */
  defaultValue?: string;
  /** indicate the type of input (text, number, email, etc) */
  type?: HTMLInputTypeAttribute;
  /** name label of input */
  label?: string;
  /** name of input */
  name?: string;
  /** show input placeholder message */
  placeHolder?: string;
  /** to indicate that there is a validation error */
  error?: boolean;
  /** validation information or error message  */
  message?: string;
  /** with left icon */
  iconLeft?: JSX.Element;
  /** with right icon */
  iconRight?: JSX.Element;
  /** HTML id attribute */
  id?: string;
  /** callback function onClick of input */
  onClick?: MouseEventHandler<HTMLInputElement>;
  /** callback function onChange of input */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  seeMore?: boolean;
  /** Show mark when is required field */
  isRequired?: boolean;
  /** Show skeleton */
  isLoading?: boolean;
  /** Show message value */
  showMessage?: boolean;
  /** Styles for standard variation  */
  standard?: boolean;
  /** keeps the value as a number, and does not allow e,-,+ and .   */
  valueOnlyNumbers?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      label,
      onClick,
      onChange,
      type = 'text',
      name,
      id,
      error,
      message,
      placeHolder,
      iconLeft,
      iconRight,
      seeMore,
      disabled,
      readOnly,
      isRequired,
      isLoading,
      showMessage = true,
      standard = false,
      valueOnlyNumbers,
      onKeyDown,
      ...restOfProps
    }: InputProps,
    ref?: LegacyRef<HTMLInputElement>
  ): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);

    const classes = classNames('eb-input');
    const classesInput = classNames('eb-input--input', {
      readOnly,
      'eb-input--standard': standard,
      'eb-input--error': error,
      '!pl-9': iconLeft,
      '!pr-9': iconRight,
      disabled: disabled && !readOnly,
      seeMore,
    });
    const classesMessage = classNames(error ? 'message-error' : 'eb-input--message-info');

    const handleToggleShowPassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const getPasswordIcon = useMemo(() => {
      return (
        <button type="button" onClick={handleToggleShowPassword}>
          {' '}
          {showPassword ? (
            <EyeOffIcon className="size-5 text-emblue-disabled" />
          ) : (
            <EyeIcon className="size-5 text-emblue-disabled" />
          )}
        </button>
      );
    }, [showPassword]);

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (valueOnlyNumbers) {
        if (event.key === '-' || event.key === 'e' || event.key === '+' || event.key === '.') {
          event.preventDefault();
        }
      }
      if (onKeyDown) onKeyDown(event);
    };

    if (isLoading) {
      return (
        <div className="flex w-full animate-pulse flex-col" data-testid="select-component">
          {label && <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>}
          <div className="my-1 h-[38px] w-full rounded bg-gray-200"></div>
        </div>
      );
    }

    return (
      <div className={classes} data-testid="input-component">
        {label && (
          <label className="label" htmlFor={name}>
            {label} {isRequired && <span className="text-red-500"> * </span>}
          </label>
        )}
        <div className="relative rounded-md">
          {iconLeft && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">{iconLeft}</div>
          )}
          <input
            name={name}
            type={type === 'password' && showPassword ? 'text' : type}
            value={value}
            {...(id && { id })}
            ref={ref}
            className={classesInput}
            disabled={disabled}
            placeholder={placeHolder}
            readOnly={readOnly}
            onChange={onChange}
            onClick={onClick}
            onKeyDown={handleOnKeyDown}
            {...restOfProps}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {error && !iconRight && (
              <ExclamationCircleIcon aria-hidden="true" className="size-5 text-red-500" />
            )}
            {type === 'password' ? getPasswordIcon : iconRight}
          </div>
        </div>
        {showMessage && (
          <p className={classesMessage} id={`${name}-message`}>
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default memo(withController(Input));
