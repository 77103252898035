import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, PhonePreview } from '@/lib/v2/components';

import { SmsForm } from './components/SmsForm';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomMessageSMS } from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';
import {
  EActionTypesPath,
  EStepHeaderAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const SmsAction = () => {
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const [messageSMS, setMessageSMS] = useAtom(atomMessageSMS);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const setActionTypePath = useSetAtom(atomActionTypePath);

  const { getSummaryInfoSms, getContractCountry } = useActionSmsData(Number(actionId));

  useLayoutEffect(() => {
    const fetchData = async () => await getSummaryInfoSms();
    void fetchData();
  }, [getSummaryInfoSms]);

  useEffect(() => {
    if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
  }, [currentStep, setCurrentStep]);

  useEffect(() => {
    return () => {
      setMessageSMS({
        original: '',
        shortened: '',
        track: false,
      });
    };
  }, [setMessageSMS]);

  useEffect(() => void getContractCountry(), [getContractCountry]);
  useEffect(() => setActionTypePath(EActionTypesPath.SMS), [setActionTypePath]);

  return (
    <Flex itemAlignment="start">
      <div className="my-12 w-1/2 bg-white px-10">{<SmsForm loading={globalLoadingAction} />}</div>
      <div className="grid min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-[#F4F5FA] py-4 ">
        <PhonePreview message={messageSMS.track ? messageSMS.shortened : messageSMS.original} />
      </div>
    </Flex>
  );
};

export default SmsAction;
