import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/lib/v2/components';
import { ModalSingleInput } from '@/lib/v2/organisms';

import { useDuplicateCampaignSingle } from './hooks/useDuplicateCampaignSingle';

import {
  atomDetailCampaign,
  atomLoadingDetail,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';

const TRANSLATE_MODAL = 'CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS';
const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGNS_MAIN.DUPLICATE_CAMPAIGNS';

const DuplicateCampaign = () => {
  const { t } = useTranslation();
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const toggleDuplicateCampaignModal = useAtomValue(atomToggleDuplicateCampaignModal);

  const { VALIDATION_RULES, handleCloseModal, handleSubmitData, handleValidation, loading } =
    useDuplicateCampaignSingle();

  return loadingDetail ? (
    <Spinner />
  ) : (
    <ModalSingleInput
      alert={{
        description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
        type: 'info',
      }}
      id="duplicate-campaign-modal"
      isLoading={loading}
      isOpen={toggleDuplicateCampaignModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(detailCampaign.name)} ${t(`${TRANSLATE_MODAL}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
      onValidateInputFetch={handleValidation}
    />
  );
};
export default DuplicateCampaign;
