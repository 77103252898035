import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';
import { GroupsIcon, SegmentsIcon, TagsIcon } from '@/lib/v2/icons/solid';
import { EcommerceIcon } from '@/lib/v2/icons/solid/EcommerceIcon';

import { useDebounce } from '@/src/application/hooks/useDebounce';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomActionInfoEmail } from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomRecipientOptions } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { useEmailFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailFormEvents';
import {
  EGroupAndListsTypeV1MapAlt,
  IAddresseesList,
  IAddresseesPayload,
  IEmailFormInfo,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

interface IRecipientsProps {
  setValue?: UseFormSetValue<IEmailFormInfo>;
}

const ICONS_BY_TYPE: { [key: string]: JSX.Element } = {
  interest: <TagsIcon />,
  user: <GroupsIcon />,
  integration: <EcommerceIcon />,
  profile: <SegmentsIcon />,
};

const PAYLOAD_RECIPIENTS: IAddresseesPayload = {
  offset: 0,
  groupQuantity: 20,
  filterType: {
    user: true,
    profile: true,
    integration: true,
    interest: true,
  },
};

export const useRecipients = ({ setValue }: IRecipientsProps = {}) => {
  const [loading, setLoading] = useState(false);
  const { actionId } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const [recipientsOptions, setRecipientOptions] = useAtom(atomRecipientOptions);

  const [recipientListPayload, setRecipientListPayload] =
    useState<IAddresseesPayload>(PAYLOAD_RECIPIENTS);
  const [recipientsSearch, setRecipientsSearch] = useState<string>('');
  const [hasMoreRecipient, setHasMoreRecipient] = useState<boolean>(false);

  const campaignService = useCampaignsService();
  const { setInfoByFieldOnChange } = useEmailFormEvents({ actionId: Number(actionId) });
  const recipientsSearchDebounced = useDebounce(recipientsSearch, 400);

  const { groupsAndLists } = actionInfoEmail ?? {};

  useEffect(() => {
    if (recipientsSearchDebounced.length === 0) return;

    const payloadOffset = {
      ...recipientListPayload,
      search: recipientsSearchDebounced,
      offset: 0,
    };

    void getRecipients(payloadOffset);

    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientsSearchDebounced]);

  useEffect(() => {
    if (groupsAndLists?.length === 0) {
      setValue?.('recipients', []);
      return;
    }

    const recipients = groupsAndLists?.map((address) => {
      const addressType = EGroupAndListsTypeV1MapAlt[address.type].toLowerCase();

      return {
        id: Number(address.id),
        name: String(address.name).trim() || '-',
        value: EGroupAndListsTypeV1MapAlt[address.type].toLowerCase(),
        metadata: {
          icon: ICONS_BY_TYPE[addressType],
        },
      };
    });

    setValue?.('recipients', recipients || []);
  }, [groupsAndLists, setValue]);

  const getRecipientsOptions = useCallback(
    ({ addresseesList }: { addresseesList: IAddresseesList[] }): OptionExternal[] => {
      const addresseesOptions = addresseesList.map((address) => {
        return {
          id: Number(address.groupId),
          name: String(address.name).trim() || '-',
          value: address.type,
          metadata: {
            icon: ICONS_BY_TYPE[address.type],
            alt: `(${address.contactCount})`,
          },
        };
      });
      return addresseesOptions;
    },
    []
  );

  const getRecipients = useCallback(
    async (payload: IAddresseesPayload) => {
      if (!payload) return;

      setLoading(true);

      const newOffset = payload.offset || 0;
      const response = await campaignService.getAddressees(payload);
      const addresseesOptionsDropdown = getRecipientsOptions({ addresseesList: response });

      setRecipientOptions((prevOptions) =>
        newOffset === 0 ? addresseesOptionsDropdown : [...prevOptions, ...addresseesOptionsDropdown]
      );

      setHasMoreRecipient(response.length === payload.groupQuantity);

      setLoading(false);
    },
    [campaignService, getRecipientsOptions, setHasMoreRecipient, setRecipientOptions]
  );

  const handleChangeRecipients = useCallback(
    (value: OptionExternal | OptionExternal[]) => {
      const selectedItems = Array.isArray(value) ? value : [value];
      const groups = selectedItems.map((item) => ({
        id: Number(item.id),
        type: EGroupAndListsTypeV1MapAlt[item.value as keyof typeof EGroupAndListsTypeV1MapAlt],
      }));

      setInfoByFieldOnChange({ groups });
    },
    [setInfoByFieldOnChange]
  );

  const handleLoadLastRecipients = useCallback(
    async (option: OptionExternal) => {
      if (!hasMoreRecipient) return;

      if (option.id === recipientsOptions[recipientsOptions.length - 1].id) {
        const payloadOffset = {
          ...recipientListPayload,
          offset: recipientsOptions.length,
        };

        await getRecipients(payloadOffset);
        setRecipientListPayload(payloadOffset);
      }
    },
    [
      getRecipients,
      hasMoreRecipient,
      recipientListPayload,
      recipientsOptions,
      setRecipientListPayload,
    ]
  );

  const handleCloseRecipients = useCallback(() => {
    if (recipientsSearch.length === 0) return;

    const payloadOffset = { ...recipientListPayload, search: '', offset: 0 };

    void getRecipients(payloadOffset);
    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
  }, [
    getRecipients,
    recipientListPayload,
    recipientsSearch.length,
    setHasMoreRecipient,
    setRecipientListPayload,
  ]);

  return {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: {
      setRecipientsSearch,
    },
    state: {
      loading,
      recipientsOptions,
      recipientListPayload,
      recipientsSearchDebounced,
    },
  };
};
