export const AddUserIcon = () => {
  return (
    <svg
      height="14.071"
      id="add-user"
      viewBox="0 0 12.195 14.071"
      width="12.195"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        data-name="Path 3304"
        fill="none"
        id="Path_3304"
        strokeLinejoin="round"
        transform="translate(-4 -14.894)"
      >
        <path
          d="M13.615,28.965H5.407A1.411,1.411,0,0,1,4,27.558a6.094,6.094,0,0,1,3.1-5.3,4.672,4.672,0,0,0,3,1.083,4.625,4.625,0,0,0,2.237-.572,1.31,1.31,0,0,0-.127.572v.7h-.7a1.407,1.407,0,0,0,0,2.814h.7v.7A1.411,1.411,0,0,0,13.615,28.965Z"
          stroke="none"
        />
        <path
          d="M 9.413333892822266 27.46499061584473 C 8.908293724060059 26.94179153442383 8.59726619720459 26.2302417755127 8.59726619720459 25.44735145568848 C 8.59726619720459 25.18094062805176 8.633320808410645 24.92267036437988 8.700749397277832 24.6773853302002 C 8.145580291748047 24.54867935180664 7.609950542449951 24.34369850158691 7.110361099243164 24.068359375 C 6.124815940856934 24.91125106811523 5.528711318969727 26.14552879333496 5.501006603240967 27.46499061584473 L 9.413333892822266 27.46499061584473 M 13.61491584777832 28.96499061584473 L 5.407055854797363 28.96499061584473 C 4.630926132202148 28.96268081665039 4.002315998077393 28.33407211303711 3.999995946884155 27.55794143676758 C 4.001185894012451 25.36004066467285 5.185825824737549 23.33307075500488 7.100225925445557 22.25333213806152 C 7.941375732421875 22.95457077026367 9.002059936523438 23.3383846282959 10.09725570678711 23.33676147460938 C 10.87956619262695 23.33834266662598 11.64912605285645 23.14141082763672 12.33448600769043 22.76455116271973 C 12.24960613250732 22.94328117370605 12.20630645751953 23.13891220092773 12.20785617828369 23.33676147460938 L 12.20785617828369 24.04029083251953 L 11.50431632995605 24.04029083251953 C 10.72722625732422 24.04029083251953 10.09726619720459 24.67025184631348 10.09726619720459 25.44735145568848 C 10.09726619720459 26.22444152832031 10.72722625732422 26.85441207885742 11.50431632995605 26.85441207885742 L 12.20785617828369 26.85441207885742 L 12.20785617828369 27.55794143676758 C 12.21016597747803 28.33407211303711 12.83878612518311 28.96268081665039 13.61491584777832 28.96499061584473 Z"
          fill="#364365"
          stroke="none"
        />
      </g>
      <g
        data-name="Ellipse 1565"
        fill="none"
        id="Ellipse_1565"
        stroke="#364365"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(2.345 0)"
      >
        <ellipse cx="3.752" cy="3.752" rx="3.752" ry="3.752" stroke="none" />
        <ellipse cx="3.752" cy="3.752" fill="none" rx="3.002" ry="3.002" />
      </g>
      <path
        d="M28.69,26.111H27.049V24.469a.469.469,0,0,0-.938,0v1.642H24.469a.469.469,0,0,0,0,.938h1.642V28.69a.469.469,0,1,0,.938,0V27.049H28.69a.469.469,0,1,0,0-.938Z"
        data-name="Path 3305"
        fill="#364365"
        id="Path_3305"
        transform="translate(-16.965 -16.027)"
      />
    </svg>
  );
};
