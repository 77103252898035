/* cSpell:disable */

type TagIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const TagIcon = ({ tableSize, stroked }: TagIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7406" id="Grupo_7406" transform="translate(0.166 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(-0.166 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M0,8.077V1.538A1.538,1.538,0,0,1,1.538,0H8.077A1.538,1.538,0,0,1,9.164.45l6.788,6.788a1.538,1.538,0,0,1,0,2.175L9.413,15.952a1.538,1.538,0,0,1-2.175,0L.45,9.164A1.538,1.538,0,0,1,0,8.077ZM3.588,2.05A1.538,1.538,0,1,0,5.126,3.588,1.538,1.538,0,0,0,3.588,2.05Z"
              data-name="Icon awesome-tag"
              fill="#004dbc"
              id="Icon_awesome-tag"
              transform="translate(1.717 2.112)"
            />
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7407" id="Grupo_7407" transform="translate(0.33)">
            <rect
              data-name="Rectángulo 3240"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3240"
              transform="translate(-0.33)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M6.333,6.333h.008M6.333,3H10.5a1.659,1.659,0,0,1,1.178.488l5.833,5.833a1.667,1.667,0,0,1,0,2.357l-5.833,5.833a1.667,1.667,0,0,1-2.357,0L3.488,11.679A1.662,1.662,0,0,1,3,10.5V6.333A3.333,3.333,0,0,1,6.333,3Z"
              data-name="Trazado 3206"
              fill="none"
              id="Trazado_3206"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-0.33)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
