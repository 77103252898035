export const ApiConfigIcon = ({ color = '#D9D9D9' }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_83_509)">
        <path
          d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V6.5C3 6.26667 3.0375 6.04583 3.1125 5.8375C3.1875 5.62917 3.3 5.43333 3.45 5.25L4.85 3.55C5 3.36667 5.17083 3.22917 5.3625 3.1375C5.55417 3.04583 5.76667 3 6 3H18C18.2333 3 18.4458 3.04583 18.6375 3.1375C18.8292 3.22917 19 3.36667 19.15 3.55L20.55 5.25C20.7 5.43333 20.8125 5.62917 20.8875 5.8375C20.9625 6.04583 21 6.26667 21 6.5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5.4 6H18.6L17.75 5H6.25L5.4 6ZM15.2 13.5L13.825 14.875C13.625 15.075 13.525 15.3167 13.525 15.6C13.525 15.8833 13.625 16.125 13.825 16.325C14.025 16.525 14.2667 16.625 14.55 16.625C14.8333 16.625 15.075 16.525 15.275 16.325L17.4 14.2C17.6 14 17.7 13.7667 17.7 13.5C17.7 13.2333 17.6 13 17.4 12.8L15.275 10.675C15.075 10.475 14.8333 10.375 14.55 10.375C14.2667 10.375 14.025 10.475 13.825 10.675C13.625 10.875 13.525 11.1167 13.525 11.4C13.525 11.6833 13.625 11.925 13.825 12.125L15.2 13.5ZM8.85 13.5L10.225 12.125C10.425 11.925 10.525 11.6833 10.525 11.4C10.525 11.1167 10.425 10.875 10.225 10.675C10.025 10.475 9.78333 10.375 9.5 10.375C9.21667 10.375 8.975 10.475 8.775 10.675L6.65 12.8C6.45 13 6.35 13.2333 6.35 13.5C6.35 13.7667 6.45 14 6.65 14.2L8.775 16.325C8.975 16.525 9.21667 16.625 9.5 16.625C9.78333 16.625 10.025 16.525 10.225 16.325C10.425 16.125 10.525 15.8833 10.525 15.6C10.525 15.3167 10.425 15.075 10.225 14.875L8.85 13.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
