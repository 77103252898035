export const NpsIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_143_259)">
        <path
          d="M19.6894 8.85718C19.4383 8.15891 18.9713 7.56213 18.3583 7.15633C17.7453 6.75053 17.0193 6.55757 16.2893 6.60639H14.3104C14.7658 5.59331 15.0068 4.49439 15.018 3.3807C15.0189 2.99872 14.9864 2.61742 14.9208 2.24129C14.8966 1.90191 14.8027 1.57146 14.6451 1.2712C14.4876 0.970934 14.2699 0.707519 14.0061 0.497907C13.7423 0.288295 13.4382 0.137133 13.1135 0.0541499C12.7887 -0.0288333 12.4504 -0.0417997 12.1204 0.016089C11.5303 0.0855503 11.3277 0.654648 10.8383 2.03534C10.3957 3.42183 9.80235 4.75388 9.06932 6.00682C8.41059 6.9142 7.53792 7.63874 6.53154 8.11382V8.00414H0V17.8652H6.53394V17.3217C7.11637 17.2663 7.70295 17.2741 8.28376 17.3449L9.45311 18.0346L10.3274 18.5879C11.1765 19.0522 14.8849 19.0972 16.4812 18.8572C17.0584 18.7936 17.6059 18.5643 18.0595 18.1961C18.5132 17.828 18.8543 17.3361 19.0429 16.7782C19.2048 16.2115 20.6129 11.1372 19.6894 8.85718ZM17.81 16.4126C17.6877 16.7281 17.4811 17.0027 17.2134 17.2053C16.9458 17.4079 16.6278 17.5305 16.2953 17.5593C14.5116 17.7851 12.705 17.744 10.9331 17.4375L10.1583 16.9439L8.68913 16.1055C7.97666 15.9775 7.25021 15.95 6.53034 16.0239V9.52864C7.97401 8.96554 9.22793 7.99263 10.1427 6.72581C10.9329 5.38961 11.5711 3.96647 12.0449 2.4838C12.0449 2.4838 12.4214 1.53083 12.5126 1.31026C13.1626 1.31026 13.5224 1.67585 13.6556 2.46552C13.7062 2.7662 13.7311 3.07077 13.7299 3.37582C13.7055 4.62264 13.3598 5.84116 12.7273 6.90982L12.076 7.91884H16.2869C16.7621 7.87186 17.239 7.98892 17.6407 8.25114C18.0424 8.51336 18.3454 8.90544 18.5008 9.36412C19.1413 10.9288 18.2142 14.9893 17.81 16.4126Z"
          fill="#11CFFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_143_259">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
