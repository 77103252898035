export const CloudComputingUploadIcon = () => {
  return (
    <svg
      height="78.783"
      viewBox="0 0 127.055 87.835"
      width="100.353"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
        >
          <stop offset="0" stopColor="#3c8cff" />
          <stop offset="1" stopColor="#044c8a" />
        </linearGradient>
      </defs>
      <g data-name="Group 4022" id="Group_4022" transform="translate(0)">
        <g id="cloud-computing" transform="translate(0 0)">
          <g data-name="Group 2" id="Group_2">
            <g data-name="Group 1" id="Group_1">
              <path
                d="M105.86,88.081a43.67,43.67,0,0,0-85.912,7.463,23.82,23.82,0,0,0,3.97,47.323h19.85v-7.94H23.918a15.88,15.88,0,1,1,0-31.76,3.97,3.97,0,0,0,3.97-3.97,35.73,35.73,0,0,1,70.786-6.948,3.97,3.97,0,0,0,3.375,3.176,19.85,19.85,0,0,1-2.7,39.5H83.469v7.94h15.88a27.79,27.79,0,0,0,6.511-54.786Z"
                data-name="Path 6"
                fill="url(#linear-gradient)"
                id="Path_6"
                transform="translate(0 -55.032)"
              />
              <path
                d="M13.89,34.475.6,21.184a2.045,2.045,0,0,1,0-2.892L3.491,15.4a2.045,2.045,0,0,1,2.892,0l8.954,8.954L34.514,5.176a2.045,2.045,0,0,1,2.892,0L40.3,8.068a2.045,2.045,0,0,1,0,2.892L16.782,34.476A2.045,2.045,0,0,1,13.89,34.475Z"
                data-name="Icon awesome-check"
                fill="#18c678"
                id="Icon_awesome-check"
                transform="translate(46.83 52.761)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
