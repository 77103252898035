export const PromotionIcon = () => {
  return (
    <svg
      height="52.601"
      viewBox="0 0 50.844 52.601"
      width="50.844"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        data-name="promotion-svgrepo-com (1)"
        id="promotion-svgrepo-com_1_"
        transform="translate(-8.551)"
      >
        <path
          d="M90.727,350.6H86.335a.824.824,0,0,0-.824.824v4.393a.824.824,0,0,0,.824.824h4.393a.824.824,0,0,0,.824-.824v-4.393A.824.824,0,0,0,90.727,350.6Z"
          data-name="Path 3389"
          fill="#9baaab"
          id="Path_3389"
          transform="translate(-69.053 -314.578)"
        />
        <path
          d="M128.611,350.6h-.879v6.04h.879a.824.824,0,0,0,.824-.824V351.42A.824.824,0,0,0,128.611,350.6Z"
          data-name="Path 3390"
          fill="#687f82"
          id="Path_3390"
          transform="translate(-106.938 -314.578)"
        />
        <path
          d="M8.972,299.4a.825.825,0,0,0-.41.847L11.2,316.934a.824.824,0,0,0,.813.7h5.765a.824.824,0,0,0,.824-.824V299.29H9.4A.86.86,0,0,0,8.972,299.4Z"
          data-name="Path 3391"
          fill="#cdd4d5"
          id="Path_3391"
          transform="translate(-0.001 -268.542)"
        />
        <path
          d="M19.038,196.676H9.375a.824.824,0,0,0-.824.824v10.542a.824.824,0,0,0,.824.824H19.862V197.5A.824.824,0,0,0,19.038,196.676Z"
          data-name="Path 3392"
          fill="#4acfd9"
          id="Path_3392"
          transform="translate(0 -176.47)"
        />
        <path
          d="M8.551,247.449v6.15a.824.824,0,0,0,.824.824H19.862v-6.973Z"
          data-name="Path 3393"
          fill="#0295aa"
          id="Path_3393"
          transform="translate(0 -222.027)"
        />
        <path
          d="M134.223,25.754a.824.824,0,0,0-.841.031l-27.234,17.57-.016,11.916L133.367,73.72a.824.824,0,0,0,1.286-.682V26.477A.824.824,0,0,0,134.223,25.754Z"
          data-name="Path 3394"
          fill="#ffd1a9"
          id="Path_3394"
          transform="translate(-87.557 -23.018)"
        />
        <path
          d="M106.142,247.449l-.009,6.831,27.234,18.449a.824.824,0,0,0,1.286-.682v-24.6Z"
          data-name="Path 3395"
          fill="#fd6a33"
          id="Path_3395"
          transform="translate(-87.557 -222.027)"
        />
        <path
          d="M333.674,0h-7.907a.823.823,0,0,0-.824.824V51.778a.824.824,0,0,0,.824.824h7.907a.824.824,0,0,0,.824-.824V.824A.824.824,0,0,0,333.674,0Z"
          data-name="Path 3396"
          fill="#4acfd9"
          id="Path_3396"
          transform="translate(-283.888)"
        />
        <path
          d="M324.944,247.449V273.8a.823.823,0,0,0,.824.824h7.907a.823.823,0,0,0,.824-.824V247.449Z"
          data-name="Path 3397"
          fill="#0295aa"
          id="Path_3397"
          transform="translate(-283.888 -222.027)"
        />
        <g data-name="Group 4493" id="Group_4493" transform="translate(46.327 3.514)">
          <path
            d="M377.074,46.393a.824.824,0,0,1-.824-.824V35.028a.824.824,0,0,1,1.647,0V45.57A.823.823,0,0,1,377.074,46.393Z"
            data-name="Path 3398"
            fill="#fff"
            id="Path_3398"
            transform="translate(-376.25 -34.204)"
          />
          <path
            d="M377.074,177.063a.824.824,0,0,1-.824-.824v-4.392a.824.824,0,0,1,1.647,0v4.393A.823.823,0,0,1,377.074,177.063Z"
            data-name="Path 3399"
            fill="#fff"
            id="Path_3399"
            transform="translate(-376.25 -156.967)"
          />
        </g>
        <g data-name="Group 4494" id="Group_4494" transform="translate(52.233 15.374)">
          <path
            d="M457.548,241.079h-3.514a.824.824,0,1,1,0-1.647h3.514a.824.824,0,1,1,0,1.647Z"
            data-name="Path 3400"
            fill="#f0353d"
            id="Path_3400"
            transform="translate(-451.209 -230.208)"
          />
          <path
            d="M434.558,153.049a.824.824,0,0,1-.413-1.537l3.043-1.757a.824.824,0,1,1,.824,1.426l-3.043,1.757A.82.82,0,0,1,434.558,153.049Z"
            data-name="Path 3401"
            fill="#f0353d"
            id="Path_3401"
            transform="translate(-433.733 -149.645)"
          />
          <path
            d="M437.6,315.521a.82.82,0,0,1-.411-.11l-3.043-1.757a.824.824,0,1,1,.824-1.426l3.043,1.757a.824.824,0,0,1-.413,1.537Z"
            data-name="Path 3402"
            fill="#f0353d"
            id="Path_3402"
            transform="translate(-433.733 -295.424)"
          />
        </g>
      </g>
    </svg>
  );
};

export const PromotionImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueLightBlue/25'}>
    <PromotionIcon />
  </div>
);
