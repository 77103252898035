export const ScheduledDeliveryIcon = () => {
  return (
    <svg fill="none" height="37" viewBox="0 0 38 37" width="38" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8686 36.6894C28.9696 36.6894 37.1581 28.5009 37.1581 18.3999C37.1581 8.29895 28.9696 0.110474 18.8686 0.110474C8.76758 0.110474 0.579102 8.29895 0.579102 18.3999C0.579102 28.5009 8.76758 36.6894 18.8686 36.6894Z"
        fill="#DCE2F7"
      />
      <path
        d="M32.2267 24.3369C32.2267 27.0632 29.9951 29.2948 27.2688 29.2948H11.1425C8.41615 29.2948 6.18457 27.0632 6.18457 24.3369V13.2159C6.18457 10.4896 8.41615 11.3317 11.1425 11.3317H27.2688C29.9951 11.3317 32.2267 10.4896 32.2267 13.2159V24.3369Z"
        fill="white"
      />
      <path
        d="M37.221 26.5684C37.2052 30.8842 33.6789 34.4 29.3999 34.3632C25.0526 34.3263 21.5894 30.8316 21.6104 26.5053C21.6315 22.2 25.1526 18.7316 29.4736 18.7527C33.7526 18.779 37.2368 22.2895 37.221 26.5632V26.5684Z"
        fill="#18C678"
      />
      <path
        d="M30.2792 24.4474C30.2792 24.1369 30.2792 23.8316 30.2792 23.5211C30.2792 23.3947 30.2792 23.2684 30.2792 23.1421C30.274 22.6 29.9266 22.2158 29.4266 22.2105C28.9371 22.2 28.5476 22.5895 28.5476 23.1211C28.5371 24.2474 28.5476 25.3684 28.5476 26.4947C28.5476 26.8947 28.7266 27.1842 29.0898 27.3632C29.9687 27.7947 30.8424 28.2369 31.7161 28.6737C32.2213 28.9263 32.7266 28.7895 32.9582 28.3421C33.195 27.8842 33.0108 27.3895 32.495 27.1263C31.8266 26.7895 31.1529 26.4474 30.4792 26.1158C30.3371 26.0474 30.2792 25.9684 30.2845 25.8053C30.295 25.3526 30.2845 24.9 30.2845 24.4474H30.2792Z"
        fill="white"
      />
      <path
        d="M7.9322 13.3316C15.469 13.3264 22.969 13.3264 30.4848 13.3264C30.4848 13.3264 30.4848 13.3264 30.4848 13.3316H32.2269C32.2269 12.4685 32.2269 11.6053 32.2217 10.7422C32.2059 9.23165 31.1164 8.13691 29.6111 8.1106C28.848 8.09481 28.0848 8.1106 27.3217 8.1106C27.2269 8.1106 27.1269 8.10007 27.0111 8.09481C27.0111 7.49481 27.0217 6.92639 27.0111 6.35796C27.0006 5.83691 26.569 5.46323 26.0532 5.51586C25.6111 5.55796 25.2901 5.92639 25.2848 6.42112C25.2743 6.97375 25.2848 7.52639 25.2848 8.08954H20.0848C20.0848 7.54218 20.0848 7.01586 20.0848 6.48954C20.0848 5.90533 19.7375 5.51586 19.2217 5.5106C18.7059 5.5106 18.348 5.90007 18.348 6.47902C18.348 7.0106 18.348 7.54744 18.348 8.08428C18.2901 8.09481 18.2585 8.1106 18.2217 8.1106C16.5954 8.1106 14.9743 8.1106 13.348 8.11586C13.1638 8.11586 13.1427 8.02639 13.1427 7.87902C13.1427 7.3527 13.1532 6.82639 13.1322 6.30007C13.1164 5.89481 12.8006 5.57375 12.4217 5.51586C12.0269 5.45796 11.6427 5.65797 11.4954 6.02639C11.4375 6.17375 11.4269 6.34218 11.4217 6.50533C11.4111 7.02639 11.4217 7.5527 11.4217 8.1106C11.2901 8.1106 11.1954 8.1106 11.0954 8.1106C10.2954 8.1106 9.49535 8.09481 8.70062 8.12112C7.32167 8.16849 6.24798 9.24744 6.21641 10.6211C6.19535 11.5264 6.20062 12.4264 6.20588 13.3316H7.94798H7.9322Z"
        fill="#004BC2"
      />
    </svg>
  );
};
