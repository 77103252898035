export const EcommerceIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.1403 7.35014C8.06769 7.27753 7.97097 7.23759 7.86793 7.23759C7.76489 7.23759 7.66817 7.27757 7.5956 7.35014L6.35802 8.58772L5.41803 7.64774L6.65562 6.41016C6.80572 6.25997 6.80572 6.01561 6.65558 5.86542L6.22705 5.43689C6.15444 5.36429 6.05772 5.32434 5.95468 5.32434C5.85164 5.32434 5.75488 5.36432 5.68235 5.43693L4.44481 6.67447L3.7416 5.97127C3.669 5.89866 3.57227 5.85872 3.46924 5.85872C3.3662 5.85872 3.26948 5.8987 3.19691 5.97127L2.57887 6.58919C2.42868 6.73938 2.42868 6.98375 2.57887 7.13397L2.72532 7.28042C2.66384 7.32225 2.60579 7.36998 2.55213 7.42363L0.754099 9.22167C0.529301 9.44643 0.405539 9.7463 0.405539 10.066C0.405539 10.3858 0.52934 10.6856 0.754099 10.9103L1.44525 11.6015L0.197459 12.8493C-0.0658196 13.1126 -0.0658196 13.5394 0.197459 13.8026C0.329079 13.9343 0.501606 14 0.674094 14C0.846583 14 1.01911 13.9343 1.15073 13.8026L2.39856 12.5548L3.09541 13.2517C3.32013 13.4764 3.62 13.6002 3.93975 13.6002C4.25949 13.6002 4.55937 13.4764 4.78409 13.2517L6.58212 11.4536C6.63582 11.4 6.68354 11.3419 6.72537 11.2804L6.87182 11.4269C6.94443 11.4995 7.04115 11.5394 7.14415 11.5394C7.24719 11.5394 7.34391 11.4995 7.41656 11.4269L8.03441 10.8089C8.10709 10.7363 8.14708 10.6396 8.14708 10.5365C8.14708 10.4334 8.10709 10.3367 8.03445 10.2642L7.33128 9.56098L8.56886 8.3234C8.71901 8.17322 8.71901 7.92881 8.56882 7.77866L8.1403 7.35014Z"
        fill="#0056B8"
      />
      <path
        d="M13.8026 0.197491C13.5394 -0.0657489 13.1126 -0.0657489 12.8493 0.197491L11.5903 1.45653L10.8991 0.76534C10.6744 0.540581 10.3745 0.416819 10.0548 0.416819C9.73503 0.416819 9.43516 0.540581 9.21044 0.76534L7.41241 2.56337C7.35875 2.61703 7.31102 2.67508 7.26915 2.7366L7.1227 2.59015C7.0501 2.51754 6.95337 2.47755 6.85034 2.47755C6.7473 2.47755 6.65054 2.51754 6.57797 2.59015L5.96004 3.20807C5.88739 3.28068 5.84741 3.3774 5.84741 3.48048C5.84741 3.58352 5.88739 3.68028 5.96004 3.75285L10.2529 8.04573C10.3255 8.11833 10.4222 8.15832 10.5253 8.15832C10.6283 8.15832 10.725 8.11833 10.7976 8.04573L11.4156 7.42776C11.5658 7.27758 11.5658 7.03321 11.4156 6.88303L11.2692 6.73662C11.3306 6.69478 11.3887 6.64706 11.4424 6.59336L13.2404 4.79533C13.4652 4.57061 13.589 4.2707 13.589 3.95099C13.589 3.63128 13.4652 3.33137 13.2404 3.10665L12.5436 2.40984L13.8026 1.1508C14.0659 0.887523 14.0659 0.460731 13.8026 0.197491Z"
        fill="#0056B8"
      />
    </svg>
  );
};
