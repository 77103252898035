import { FileIcon } from '@/lib/v2/icons/solid';

import {
  ContactsIcon,
  FileWritten,
  PatternIcon,
  WrongEmailsIcon,
} from '@/src/presentation/util/icon';

export type TypeTranslation = (text: string) => string;

export const getDataCardCleaning = (t: TypeTranslation) => [
  {
    icon: <FileIcon />,
    description: t('IMPORT_MAIN.readingFiles'),
  },
  {
    icon: <PatternIcon />,
    description: t('IMPORT_MAIN.invalidPatterns'),
  },
  {
    icon: <FileWritten />,
    description: t('IMPORT_MAIN.syntaxCorrections'),
  },
  {
    icon: <WrongEmailsIcon />,
    description: t('IMPORT_MAIN.wrongEmails'),
  },
  {
    icon: <ContactsIcon />,
    description: t('IMPORT_MAIN.newDuplicateContacts'),
  },
];

export const getDataCardGroups = (t: TypeTranslation) => [
  {
    icon: <PatternIcon />,
    description: t('IMPORT_MAIN.cleaningData'),
  },
];
