export const WrongEmailsIcon = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#CFF6FE]">
      <svg
        data-name="Group 7363"
        height="55%"
        id="Group_7363"
        viewBox="0 0 70.502 63.999"
        width="55%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g data-name="Group 7353" id="Group_7353" transform="translate(0 9.757)">
          <path
            d="M64.4,13H11.845A8.745,8.745,0,0,0,3,21.715V58.267a8.889,8.889,0,0,0,2.471,6.114h0l.13.13a8.735,8.735,0,0,0,6.374,2.732H64.657A8.854,8.854,0,0,0,73.5,58.4V21.715A9.145,9.145,0,0,0,64.4,13Z"
            data-name="Path 24"
            fill="#11cffa"
            id="Path_24"
            transform="translate(-3 -13)"
          />
          <path
            d="M57.251,55.07v.39C53.089,52.078,44.5,45.054,38,39.721a29.4,29.4,0,0,0,2.862-2.862L57.121,21.9V55.07Z"
            data-name="Path 25"
            fill="#cce8f6"
            id="Path_25"
            transform="translate(7.527 -10.323)"
          />
          <path
            d="M25.02,17.13H62.872a3.6,3.6,0,0,1,1.691.39L46.092,34.3l-.13.13C42.45,38.2,37.9,41.715,36.6,41.715c-1.431,0-6.114-3.642-9.886-7.544h0L8.5,17.52A3.689,3.689,0,0,1,10.191,17H25.02Z"
            data-name="Path 26"
            fill="#cce8f6"
            id="Path_26"
            transform="translate(-1.346 -11.797)"
          />
          <path
            d="M7.2,55.46V21.9L23.33,36.6a35.9,35.9,0,0,0,3.122,2.992C19.948,45.054,11.362,52.078,7.2,55.46Z"
            data-name="Path 27"
            fill="#cce8f6"
            id="Path_27"
            transform="translate(-1.737 -10.323)"
          />
          <path
            d="M24.418,53.909H10.63c1.951-1.561,4.813-3.9,8.195-6.634,4.423-3.642,8.975-7.414,10.926-8.975,2.211,1.561,4.423,2.6,6.244,2.6s4.032-1.041,6.114-2.6c1.951,1.691,6.374,5.333,10.8,8.975q5.268,4.293,8.195,6.634H24.418Z"
            data-name="Path 28"
            fill="#cce8f6"
            id="Path_28"
            transform="translate(-0.744 -5.39)"
          />
        </g>
        <ellipse
          cx="12.357"
          cy="12.357"
          data-name="Ellipse 1759"
          fill="#11cffa"
          id="Ellipse_1759"
          rx="12.357"
          ry="12.357"
          transform="translate(22.766)"
        />
        <path
          d="M-28.692-23.4l3.772-3.772a1.5,1.5,0,0,0,0-2.211,1.5,1.5,0,0,0-2.211,0L-30.9-25.615l-3.772-3.772a1.5,1.5,0,0,0-2.211,0,1.5,1.5,0,0,0,0,2.211l3.772,3.772-3.772,3.772a1.5,1.5,0,0,0,0,2.211,1.649,1.649,0,0,0,1.171.52,1.589,1.589,0,0,0,1.171-.52l3.772-3.772L-27-17.42a1.649,1.649,0,0,0,1.171.52,1.589,1.589,0,0,0,1.171-.52,1.5,1.5,0,0,0,0-2.211Z"
          data-name="Path 4095"
          fill="#fff"
          id="Path_4095"
          transform="translate(66.027 35.748)"
        />
      </svg>
    </div>
  );
};
