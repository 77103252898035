export const ClickRateIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.8488 14.12L9 10.9576M9 10.9576L7.4344 12.3464L7.7896 6.4352L11.052 11.3768L9 10.9576ZM7.9496 2V3.4048M11.5912 3.5088L10.6 4.5008M13.1008 7.1496H11.6952M5.3008 9.8L4.3088 10.7904M4.2048 7.1496H2.8M5.3008 4.5008L4.3088 3.5088"
        stroke="#004DBC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
