// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapItemInterfaceEsToEn = (
  Item: any,
  interfaceInEnglish: any,
  translations: Record<string, string>
) => {
  // Loop through the interface attributes in Spanish
  for (const attribute in Item) {
    if (Item[attribute] === undefined) return;
    // If the attribute is an object, call the function recursively
    // to translate nested attributes
    if (typeof Item[attribute] === 'object') {
      interfaceInEnglish[translations[attribute]] = {};
      mapItemInterfaceEsToEn(
        Item[attribute],
        interfaceInEnglish[translations[attribute]],
        translations
      );
    } else {
      // If the attribute is found in the translation dictionary,
      // assign its value to the english interface
      if (translations[attribute]) {
        interfaceInEnglish[translations[attribute]] = Item[attribute];
      }
    }
  }

  // Returns the interface in English
  return interfaceInEnglish;
};
