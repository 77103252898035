export const RatioTableIcon = () => {
  return (
    <svg className="mr-2 flex h-4 w-4 self-center" viewBox="0 0 17.387 16.68">
      <path
        d="M3,17.267V9.329A1.778,1.778,0,0,1,3.791,7.85L10.013,3.7a1.778,1.778,0,0,1,1.973,0L18.209,7.85A1.778,1.778,0,0,1,19,9.329v7.938m-16,0a1.778,1.778,0,0,0,1.778,1.778H17.222A1.778,1.778,0,0,0,19,17.267m-16,0,6-4m10,4-6-4m-10-4,6,4m10-4-6,4m0,0-1.013.676a1.778,1.778,0,0,1-1.973,0L9,13.267"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-2.307 -2.865)"
      />
    </svg>
  );
};
