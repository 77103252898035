import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { isValidDate } from '@/src/application/util';

import { useUserData } from './useUserData';

export const useDate = () => {
  const [{ timeOffset }] = useUserData();
  const [isLoadingDate, setIsLoadingDate] = useState(true);
  const accountTimeOffset = timeOffset && timeOffset * 60;

  useEffect(() => {
    if (timeOffset && accountTimeOffset) {
      setIsLoadingDate(false);
    }
  }, [accountTimeOffset, timeOffset]);

  const dateFormatter = (date: Date | string) => {
    try {
      if (timeOffset && accountTimeOffset) {
        date = typeof date === 'string' ? new Date(date) : date;
        const offsetDate = new Date(date.getTime() + accountTimeOffset * 60 * 1000);

        if (isValidDate(offsetDate)) {
          return offsetDate.toLocaleDateString(`${t('TIMEZONE.locale')}`, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          });
        }
        return offsetDate;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { dateFormatter, isLoadingDate };
};
