export type KeyMap = Record<string, string>;

export const mapKeysToEnglish = <T>(obj: Record<string, any>, keyMap: KeyMap): T => {
  if (typeof obj !== 'object' || obj === null) {
    return obj as T;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => mapKeysToEnglish(item, keyMap)) as T;
  }

  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = keyMap[key] || key;
      newObj[newKey] =
        typeof obj[key] === 'object' || Array.isArray(obj[key])
          ? mapKeysToEnglish(obj[key], keyMap)
          : obj[key];
    }
  }

  return newObj as T;
};
