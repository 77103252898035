import { IPluginRow } from '@mailupinc/bee-plugin/dist/types/bee';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';

import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import { atomContentDialogSaveRows } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

export const key = 'mockBackend';

interface IColumn {
  weight?: number;
  modules: {
    locked?: boolean;
    type: string;
    alt?: string;
    src?: string;
    text?: string;
    size?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    align?: string;
    descriptor?: {
      divider: {
        [key: string]: string;
      };
      style: {
        [key: string]: string;
      };
      image: {
        [key: string]: string;
      };
      computedStyle: {
        [key: string]: string;
      };
    };
  }[];
  style: {
    [key: string]: string;
  };
}
export interface IRowBeefree {
  columns?: IColumn[];
  locked?: boolean;
  container?: {
    style?: {
      [key: string]: string;
    };
  };
  content?: {
    style?: {
      [key: string]: string;
    };
  };
  type: string;
}
export interface IRowDB {
  id: number;
  name: string;
  json: string;
  createdBy?: string;
  designer?: string;
}
export interface ITemplate {
  metadata?: {
    name: string;
  };
}
export interface IPage {
  template: ITemplate;
  rows: IPluginRow[];
}

const useRows = () => {
  const service = useBeefreeService();
  const [, setConfig] = useAtom(atomContentDialogSaveRows);

  const closeSaveRow = () => {
    setConfig(RESET);
  };

  const getRows = async (type: string) => {
    if (type === 'byDefault') return await service.getDefaultRows();
    else return await service.getRowsByUser();
  };

  return {
    getRows,
    closeSaveRow,
  };
};

export default useRows;
