export const ContactMessagesIcon = () => {
  return (
    <svg
      height="32.48"
      viewBox="0 0 36.541 32.48"
      width="36.541"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.15,28.42C8.526,28.42,6.9,27,6.09,26.39,2.03,23.548.812,22.533,0,21.924V30.45a2.03,2.03,0,0,0,2.03,2.03H18.27a2.03,2.03,0,0,0,2.03-2.03V21.924c-.812.609-2.03,1.624-6.09,4.466C13.4,27,11.774,28.42,10.15,28.42Zm8.12-12.18H2.03A2.03,2.03,0,0,0,0,18.27v1.015C1.624,20.5,1.421,20.5,7.308,24.766c.609.406,1.827,1.624,2.842,1.624s2.233-1.218,2.842-1.421c5.887-4.263,5.684-4.263,7.308-5.481V18.27A2.03,2.03,0,0,0,18.27,16.24Zm16.24-6.09H14.21a2.03,2.03,0,0,0-2.03,2.03v2.03h6.09a4.066,4.066,0,0,1,4.042,3.668l.018-.014V26.39H34.51a2.03,2.03,0,0,0,2.03-2.03V12.18A2.03,2.03,0,0,0,34.51,10.15Zm-2.03,8.12H28.42V14.21h4.06ZM10.15,12.18a4.065,4.065,0,0,1,4.06-4.06H28.42V2.03A2.03,2.03,0,0,0,26.39,0H6.09A2.03,2.03,0,0,0,4.06,2.03V14.21h6.09Z"
        data-name="Icon awesome-mail-bulk"
        fill="#004dbc"
        id="Icon_awesome-mail-bulk"
      />
    </svg>
  );
};
