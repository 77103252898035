import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

import {
  atomActionEmailAddresseesByType,
  atomActionInfoEmail,
  atomEmailAddresseesInfo,
  atomTotalAddressees,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { SummaryAction } from '@/modules/CampaignsModule/components/SummaryAction';
import { SummaryDataSection } from '@/modules/CampaignsModule/components/SummaryAction/SummaryAction';
import {
  EAddressTypeByString,
  ElementAddressType,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { formattedNumber } from '@/modules/CampaignsModule/utils';
import { formatDateTimeLocal } from '@/modules/CampaignsModule/utils/formatDate';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

const TRANSLATE_EMAIL_ACTION = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION';

const EmailSummary = () => {
  const { t, i18n } = useTranslation();
  const [userData] = useUserData();
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const emailAddresseesInfo = useAtomValue(atomEmailAddresseesInfo);
  const actionEmailAddresseesByType = useAtomValue(atomActionEmailAddresseesByType);
  const totalAddressees = useAtomValue(atomTotalAddressees);

  const { sender, emailInfo, touchRules } = actionInfoEmail ?? {};
  const { shippingDate, reinforcement, isImmediateShipping } = emailAddresseesInfo ?? {};

  const formatGMT = userData.timeOffset ? `GMT${userData.timeOffset}` : undefined;

  const dateMessage = useMemo(() => {
    if (!formatGMT) return;

    if (isImmediateShipping) return t(`${TRANSLATE_EMAIL_ACTION}.reinforcementImmediate`);
    if (!shippingDate) return;

    const messageReinforcement = t(`${TRANSLATE_EMAIL_ACTION}.reinforcementScheduled`);

    const date = DateConverter.toGMTDate(shippingDate, formatGMT);
    const dateLocal = formatDateTimeLocal(date);
    const messageDate =
      shippingDate && `${dateLocal.date.toString()}, ${dateLocal.time.toString()} (${formatGMT})`;

    return `${messageReinforcement}. ${messageDate || ''}`;
  }, [formatGMT, isImmediateShipping, shippingDate, t]);

  const getContentElement = useCallback((title: string, content: unknown) => {
    return {
      title,
      content: content,
    };
  }, []);

  const summaryData = useMemo(() => {
    if (!formatGMT) return [];

    const date = DateConverter.toGMTDate(
      reinforcement?.startDate || new Date().toString(),
      formatGMT
    );
    const dateLocal = formatDateTimeLocal(date);
    const messageDate =
      reinforcement?.startDate &&
      `${dateLocal.date.toString()}, ${dateLocal.time.toString()} (${formatGMT})`;

    return [
      {
        title: t(`${TRANSLATE_EMAIL_ACTION}.shipping`),
        elements: [
          getContentElement(t(`${TRANSLATE_EMAIL_ACTION}.shippingDate`), dateMessage),
          getContentElement(
            t(`${TRANSLATE_EMAIL_ACTION}.reinforcementShipment`),
            reinforcement?.isReinforcementShipment ? messageDate : '-'
          ),
        ],
      },
      {
        title: t(`${TRANSLATE_EMAIL_ACTION}.sender`),
        elements: [
          {
            title: `${t(`${TRANSLATE_EMAIL_ACTION}.from`)}: `,
            content: `${sender?.nameFrom} <${sender?.emailFrom || ''}>`,
            singleLine: true,
          },
          {
            title: `${t(`${TRANSLATE_EMAIL_ACTION}.reply`)}: `,
            content: `<${sender?.emailFrom || ''}>`,
            singleLine: true,
          },
        ],
      },
      {
        title: t(`${TRANSLATE_EMAIL_ACTION}.subject`),
        elements: [
          getContentElement(t(`${TRANSLATE_EMAIL_ACTION}.subject`), emailInfo?.subject),
          getContentElement(t(`${TRANSLATE_EMAIL_ACTION}.firstLine`), emailInfo?.firstLine),
        ],
      },
      {
        title: `${t(`${TRANSLATE_EMAIL_ACTION}.recipients`)} (${
          formattedNumber(totalAddressees, i18n.language as keyof typeof ELanguageMap) || 0
        })`,
        elements: [
          {
            title: t(`${TRANSLATE_EMAIL_ACTION}.addressees.segments`),
            content: actionEmailAddresseesByType?.[EAddressTypeByString.PROFILE]?.map(
              (item: ElementAddressType) => item.name || '-'
            ),
          },
          {
            title: t(`${TRANSLATE_EMAIL_ACTION}.addressees.groups`),
            content:
              actionEmailAddresseesByType?.[EAddressTypeByString.USER]?.map(
                (item: ElementAddressType) => item.name
              ) || '-',
          },
          {
            title: t(`${TRANSLATE_EMAIL_ACTION}.addressees.tags`),
            content:
              actionEmailAddresseesByType?.[EAddressTypeByString.INTEREST]?.map(
                (item: ElementAddressType) => item.name
              ) || '-',
          },
          {
            title: t(`${TRANSLATE_EMAIL_ACTION}.addressees.integrations`),
            content:
              actionEmailAddresseesByType?.[EAddressTypeByString.INTEGRATION]?.map(
                (item: ElementAddressType) => item.name
              ) || '-',
          },
          {
            title: t(`${TRANSLATE_EMAIL_ACTION}.labelTouchRules`),
            content: touchRules?.isTouchRules
              ? t(`${TRANSLATE_EMAIL_ACTION}.applyCriteriaTouchRules`, {
                  emails: touchRules.emails,
                  days: touchRules.days,
                  messages_text:
                    touchRules.emails > 1
                      ? t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.messages')
                      : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.message'),
                  days_text:
                    touchRules.days > 1
                      ? t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.days')
                      : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.day'),
                })
              : '-',
          },
        ],
      },
    ];
  }, [
    reinforcement?.startDate,
    reinforcement?.isReinforcementShipment,
    userData,
    t,
    getContentElement,
    dateMessage,
    sender?.nameFrom,
    sender?.emailFrom,
    emailInfo?.subject,
    emailInfo?.firstLine,
    totalAddressees,
    i18n.language,
    actionEmailAddresseesByType,
    touchRules?.isTouchRules,
    touchRules?.emails,
    touchRules?.days,
  ]);

  return (
    <div className="visible-scrollbar max-h-[calc(100vh_-_240px)] overflow-y-auto ">
      <SummaryAction summaryData={summaryData as SummaryDataSection[]} />
    </div>
  );
};

export default EmailSummary;
