export const MailIcon = () => (
  <svg
    className="h-14 w-14 sm:h-14 sm:w-14 md:h-14 md:w-14 lg:h-16 lg:w-16"
    viewBox="0 0 65.086 49.439"
  >
    <g transform="translate(0 0)">
      <path
        className="fill-[#11cffa]"
        d="M62.692,1.629,37.885,25.2a7.794,7.794,0,0,1-10.68,0L2.4,1.629A7.737,7.737,0,0,0,0,7.216V40.93a7.97,7.97,0,0,0,8.074,7.862H57.013a7.969,7.969,0,0,0,8.073-7.862V7.216a7.742,7.742,0,0,0-2.394-5.587"
        transform="translate(0 0.647)"
      />
      <path
        className="fill-white"
        d="M37.2,25.846,62.011,2.276A8.154,8.154,0,0,0,56.333,0H7.392A8.151,8.151,0,0,0,1.715,2.276l24.808,23.57a7.794,7.794,0,0,0,10.68,0"
        transform="translate(0.68 0.001)"
      />
    </g>
  </svg>
);

export const MailImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueLightBlue/25'}>
    <MailIcon />
  </div>
);
