import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, DropDown, SearchInputBar, TableSkeleton, TextSkeleton } from '@/lib/components';
import { ItemsListProps } from '@/lib/components/DropDown/DropDown';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { ActivityCleanFilterIcon, DataStorageIcon } from '@/lib/icon';
import { Card, CardSection, Container, Modal, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { ItemPreview } from '@/src/ContactsModule/components/ItemPreview';
import { ItemsTable } from '@/src/ContactsModule/components/ItemsTable';
import {
  useMutationItemsContext,
  useStateItemsContext,
} from '@/src/ContactsModule/contexts/ItemsContext';
import { ItemIcon } from '@/src/ContactsModule/Icons/ItemIcon';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useItemList } from '@/src/infrastructure/services/useItemList';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { SectionHeader } from '@/modules/ContactsModule/components';

export const ItemsMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isChecked] = useState(false);
  const [totalItems, setTotalItems] = useState<number>();
  const { pageSize, pageIndex, totalPages, gotoPageIndex, changeTablePage, resetPagination } =
    useTablePagination(totalItems);
  const [search, setSearchValue] = useState<string>();

  const [lastDays, setLastDays] = useState<number>(10);
  const [dataSource, setDataSource] = useState<number[]>([]);

  const [orderColumn, setOrderColumn] = useState<string>('LastActivity');
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [itemList, , isLoadingItems] = useItemList({
    lastDays,
    page: pageIndex,
    pageSize,
    search,
    orderColumn,
    orderDirection,
    dataSource,
  });
  const [total] = useItemList({
    lastDays,
    page: pageIndex,
    pageSize,
    orderColumn,
    orderDirection,
    dataSource,
  });
  const [itemsCountOfSearch] = useEmblue(ServiceMethods.getItemsCountOfSearch, {
    search,
    lastDays,
    dataSource,
  });
  const countTotalItems = useRef(0);
  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });
  const cleanBoxRef = useRef<CleanBoxRef>(null);

  const { closeModal } = useMutationItemsContext();
  const { openModal, itemProps } = useStateItemsContext();

  useEffect(() => {
    resetPagination();
  }, [search, lastDays, dataSource, resetPagination]);

  useEffect(() => {
    if (!totalItems && itemsCountOfSearch && itemsCountOfSearch.count) {
      setTotalItems(itemsCountOfSearch.count);
    }
    if (
      !search &&
      !dataSource.length &&
      itemsCountOfSearch &&
      totalItems &&
      countTotalItems.current > 0 &&
      countTotalItems.current !== totalItems
    ) {
      setTotalItems(itemsCountOfSearch.count);
    }
    if (
      totalItems &&
      itemsCountOfSearch &&
      itemsCountOfSearch.count &&
      itemsCountOfSearch.count !== totalItems
    ) {
      setTotalItems(itemsCountOfSearch.count);
    }
    if (
      !search &&
      itemsCountOfSearch &&
      itemsCountOfSearch.count &&
      countTotalItems.current === 0
    ) {
      countTotalItems.current = itemsCountOfSearch.count;
    }
  }, [lastDays, itemsCountOfSearch, totalItems, search, dataSource.length, total]);

  const changeOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);

  const applyLastDaysFilter = (lastDaysValue: number) => {
    if (lastDays !== lastDaysValue) {
      setLastDays(lastDaysValue);
    }
  };

  const applyDataSourceFilter = (dataSourceList: string[]) => {
    setDataSource(dataSourceList.map((i) => Number(i)));
  };

  const lastDaysOptions = Array.from({ length: 10 }, (_, i) => i + 1);
  const lastDaysOptionsList = lastDaysOptions.map((n) => {
    return {
      id: String(n),
      title:
        n === 1 ? t('ITEMS_MAIN.lastDay') : `${t('ITEMS_MAIN.last')} ${n} ${t('ITEMS_MAIN.days')}`,
      onAction: () => applyLastDaysFilter(n),
    };
  });

  const dataSourceOptions: ItemsListProps[] = [
    {
      id: '1',
      title: t('ITEMS_MAIN.actClick'),
      state: false,
    },
    {
      id: '2',
      title: t('ITEMS_MAIN.webTracking'),
      state: false,
    },
    {
      id: '3',
      title: t('ITEMS_MAIN.remarketing'),
      state: false,
    },
  ];

  const goToConfiguration = () => {
    navigate('/v2/configuration#');
  };

  const resetItems = () => {
    resetFilter();
    resetItemList();
  };

  const resetItemList = () => {
    setSearchValue('');
    setLastDays(10);
    setDataSource([]);
    setOrderColumn('LastActivity');
    setOrderDirection('desc');
    cleanBoxRef?.current?.cleanBox();
  };

  const title = useMemo(() => {
    return (
      <Flex column itemAlignment="start">
        <Text as="span" fontWeight="medium" variant="title-1">
          {' '}
          {t('ITEMS_MAIN.items')}{' '}
        </Text>
        {!itemList || isLoadingItems ? (
          <TextSkeleton />
        ) : !search &&
          !dataSource.length &&
          totalItems &&
          itemsCountOfSearch &&
          itemsCountOfSearch.count ? (
          <span>
            {' '}
            {t('ITEMS_MAIN.youHave')}{' '}
            <span className="font-medium text-[#004DBC]">
              {' '}
              {quantifier(itemsCountOfSearch.count)}{' '}
            </span>{' '}
            {itemList.list.length === 1
              ? t('ITEMS_MAIN.searchMatch')
              : t('ITEMS_MAIN.pluralSearchMatch')}
          </span>
        ) : !search &&
          dataSource.length &&
          totalItems &&
          itemList &&
          itemsCountOfSearch &&
          itemsCountOfSearch.count ? (
          <span>
            {' '}
            {t('ITEMS_MAIN.youHave')}{' '}
            <span className="font-medium text-[#004DBC]">
              {' '}
              {quantifier(itemsCountOfSearch && itemsCountOfSearch.count)}{' '}
            </span>{' '}
            {itemList.list.length === 1
              ? t('ITEMS_MAIN.searchMatch')
              : t('ITEMS_MAIN.pluralSearchMatch')}
          </span>
        ) : (search || dataSource.length > 0) &&
          totalItems &&
          itemList &&
          itemsCountOfSearch &&
          itemsCountOfSearch.count ? (
          <span>
            {' '}
            {t('ITEMS_MAIN.youHave')}{' '}
            <span className="font-medium text-[#004DBC]">
              {' '}
              {quantifier(itemsCountOfSearch && itemsCountOfSearch.count)}{' '}
            </span>{' '}
            {itemList.list.length === 1
              ? t('ITEMS_MAIN.searchMatch')
              : t('ITEMS_MAIN.pluralSearchMatch')}
          </span>
        ) : (
          t('noResultsFound')
        )}
      </Flex>
    );
  }, [dataSource.length, isLoadingItems, itemList, itemsCountOfSearch, search, t, totalItems]);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader icon={<ItemIcon />} title={title} />
            <div className="size-full">
              <Flex column withGap gapSize="medium">
                <Container background="white">
                  <Flex alignment="start">
                    {!isChecked && (
                      <Flex
                        withGap
                        alignment="start"
                        className="mb-[-80px] mt-3 xl:max-w-[900px] 2xl:max-w-fit"
                      >
                        {countTotalItems && countTotalItems.current > 0 && (
                          <>
                            <div className="w-[340px]">
                              <SearchInputBar
                                ref={cleanBoxRef}
                                withButton
                                cleanBox={resetFilter}
                                placeHolder={t('ITEMS_FILTERS.searchInputBar')}
                                onAction={setSearchValue}
                              />
                            </div>
                            <Flex noGrow withGap>
                              <div className="min-w-[150px]">
                                <DropDown
                                  overflowWidth
                                  itemList={lastDaysOptionsList}
                                  titleHead={
                                    lastDays === 1
                                      ? t('ITEMS_MAIN.lastDay')
                                      : `${t('ITEMS_MAIN.last')} ${lastDays} ${t(
                                          'ITEMS_MAIN.days'
                                        )}`
                                  }
                                />
                              </div>
                              <div className="min-w-[150px]">
                                <DropDown
                                  checkBox
                                  overflowWidth
                                  btnOnAction={applyDataSourceFilter}
                                  btnText={t('ITEMS_FILTERS.apply')}
                                  iconHead={<DataStorageIcon />}
                                  itemList={dataSourceOptions}
                                  subtitle={t('ITEMS_FILTERS.selectSource')}
                                  titleHead={t('ITEMS_FILTERS.source')}
                                  tooltip={t('ITEMS_FILTERS.source')}
                                  totalSelectedChk={dataSource && dataSource?.length}
                                  valuesSelected={dataSource && dataSource.map((i) => String(i))}
                                />
                              </div>
                              <div className="w-auto">
                                <Button
                                  fullWidth
                                  standard
                                  Icon={<ActivityCleanFilterIcon color="#004DBC" />}
                                  tooltip={t('CONTACTS_FILTERS.cleanAllFilters')}
                                  onAction={resetItemList}
                                ></Button>
                              </div>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Container>
                {!itemList && (
                  <div className="size-full py-10">
                    <TableSkeleton />
                  </div>
                )}
                {itemList && (
                  <ItemsTable
                    callToAction={[resetItems, goToConfiguration]}
                    changeTableOrder={changeOrderBy}
                    changeTablePage={changeTablePage}
                    gotoPageIndex={gotoPageIndex}
                    isLoading={isLoadingItems}
                    itemList={itemList.list}
                    totalItemCount={
                      countTotalItems && countTotalItems.current ? countTotalItems.current : 0
                    }
                    totalPages={totalPages}
                  />
                )}
              </Flex>
            </div>
          </Flex>
        </CardSection>
      </Card>
      <Modal externalClose open={openModal} onClose={closeModal}>
        {itemProps && (
          <ItemPreview image={itemProps.image} name={itemProps.name} url={itemProps.url} />
        )}
      </Modal>
    </>
  );
};
