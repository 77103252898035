const ImportMainSkeleton = () => {
  return (
    <div className="w-full animate-pulse space-y-4 divide-y divide-gray-200" role="status">
      <div className="flex items-center justify-between pt-4">
        <div className="h-[100vh] max-h-[600px] min-h-[400px] w-full rounded-md bg-gray-200"></div>
      </div>
    </div>
  );
};

export default ImportMainSkeleton;
