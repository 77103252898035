import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { isLoadingCFAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';
import { ICustomField, useCustomFIelds } from '@/modules/CampaignsModule/hooks/useCustomFields';

const useCustomFieldsEditor = () => {
  const [customFieldsNameList, setCustomFieldsNameList] = useState<ICustomField[]>([]);
  const setIsLoading = useSetAtom(isLoadingCFAtom);
  const customFields = useCustomFIelds();

  useEffect(() => {
    if (!isLoadingCFAtom || !customFields) return;

    setIsLoading(false);
    setCustomFieldsNameList(customFields);
  }, [customFields, setIsLoading]);

  return { customFieldsNameList };
};

export default useCustomFieldsEditor;
