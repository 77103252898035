import { useCallback, useEffect, useState } from 'react';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useGetScriptCode = (accountIdEmblue: string, onsiteToken: string): string => {
  const [scriptCode, setScriptCode] = useState<string>('');

  const service = useService();
  onsiteToken && service?.setOnsiteToken(onsiteToken);

  const getScriptCode = useCallback(async () => {
    const response = await service?.getNpsScriptInstallationCode({ accountIdEmblue });
    setScriptCode(response ?? '');
  }, [accountIdEmblue, service]);

  useEffect(() => {
    onsiteToken && void getScriptCode();
  }, [getScriptCode, onsiteToken]);

  return scriptCode;
};
