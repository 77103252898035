export const EyeIcon = () => {
  return (
    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_238_1389)">
        <path
          d="M22.3015 9.0266C20.8151 6.60585 17.4341 2.54443 11.5001 2.54443C5.56611 2.54443 2.18511 6.60585 0.698735 9.0266C0.239241 9.76979 -0.00415039 10.6263 -0.00415039 11.5001C-0.00415039 12.3738 0.239241 13.2303 0.698735 13.9735C2.18511 16.3943 5.56611 20.4557 11.5001 20.4557C17.4341 20.4557 20.8151 16.3943 22.3015 13.9735C22.761 13.2303 23.0044 12.3738 23.0044 11.5001C23.0044 10.6263 22.761 9.76979 22.3015 9.0266ZM20.6675 12.9701C19.391 15.0459 16.5017 18.539 11.5001 18.539C6.49857 18.539 3.60919 15.0459 2.33269 12.9701C2.0597 12.5284 1.9151 12.0194 1.9151 11.5001C1.9151 10.9808 2.0597 10.4717 2.33269 10.03C3.60919 7.95423 6.49857 4.4611 11.5001 4.4611C16.5017 4.4611 19.391 7.95039 20.6675 10.03C20.9405 10.4717 21.0851 10.9808 21.0851 11.5001C21.0851 12.0194 20.9405 12.5284 20.6675 12.9701Z"
          fill="#364365"
        />
        <path
          d="M11.4999 6.70825C10.5522 6.70825 9.6258 6.98928 8.83781 7.51579C8.04983 8.04231 7.43567 8.79067 7.073 9.66623C6.71033 10.5418 6.61544 11.5052 6.80032 12.4347C6.98521 13.3642 7.44157 14.218 8.1117 14.8881C8.78183 15.5583 9.63562 16.0146 10.5651 16.1995C11.4946 16.3844 12.458 16.2895 13.3336 15.9268C14.2092 15.5642 14.9575 14.95 15.484 14.162C16.0106 13.374 16.2916 12.4476 16.2916 11.4999C16.2901 10.2296 15.7847 9.01166 14.8865 8.11338C13.9882 7.2151 12.7703 6.70977 11.4999 6.70825ZM11.4999 14.3749C10.9313 14.3749 10.3754 14.2063 9.90266 13.8904C9.42986 13.5745 9.06137 13.1255 8.84377 12.6001C8.62616 12.0748 8.56923 11.4967 8.68016 10.939C8.7911 10.3813 9.06491 9.86906 9.46699 9.46699C9.86906 9.06491 10.3813 8.79109 10.939 8.68016C11.4967 8.56923 12.0748 8.62616 12.6001 8.84377C13.1255 9.06137 13.5745 9.42986 13.8904 9.90265C14.2063 10.3754 14.3749 10.9313 14.3749 11.4999C14.3749 12.2624 14.072 12.9937 13.5329 13.5329C12.9937 14.072 12.2624 14.3749 11.4999 14.3749Z"
          fill="#364365"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_1389">
          <rect fill="white" height="23" width="23" />
        </clipPath>
      </defs>
    </svg>
  );
};
