import { useTranslation } from 'react-i18next';

import { NpsRank } from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { customRounding } from '@/src/utils/CustomRounding';

import { ProcessStats } from './components/ProcessStats';

import { useHappinessStateContext } from '@/modules/ContactsModule/contexts/HappinessContext';

export const HappinessProcesses = () => {
  const { t } = useTranslation();
  const { npsRank, npsCount } = useHappinessStateContext();
  const { highestNps, lowestNps, singleNps } = npsRank as NpsRank;

  if (!npsCount) {
    return (
      <div className="grid grid-cols-2">
        <div className="grid grid-cols-auto-1fr items-center gap-2 border-r-2 bg-[#F9FAFB] p-4">
          <ProcessStats
            name={t('HAPPINESS_DASHBOARD.withoutProcess')}
            processDescription={t('HAPPINESS_DASHBOARD.mostRecommendedProcess')}
            score={0}
            variant={'withoutProcess'}
          />
        </div>
        <div className="grid grid-cols-auto-1fr items-center gap-2 bg-[#F9FAFB] p-4">
          <ProcessStats
            name={t('HAPPINESS_DASHBOARD.withoutProcess')}
            processDescription={t('HAPPINESS_DASHBOARD.lowScoreProcess')}
            score={0}
            variant={'withoutProcess'}
          />
        </div>
      </div>
    );
  }

  if (npsCount === 1 && singleNps) {
    return (
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-auto-1fr items-center gap-2 bg-[#F9FAFB] p-4">
          <ProcessStats
            name={singleNps.widgetName}
            processDescription={t('HAPPINESS_DASHBOARD.mostRecommendedProcess')}
            score={customRounding(singleNps.nps)}
            variant={'highestNps'}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2">
      <div className="grid grid-cols-auto-1fr items-center gap-2 border-r-2 bg-[#F9FAFB] p-4">
        <ProcessStats
          name={highestNps ? highestNps?.widgetName : t('HAPPINESS_DASHBOARD.withoutProcess')}
          processDescription={t('HAPPINESS_DASHBOARD.mostRecommendedProcess')}
          score={highestNps ? customRounding(highestNps.nps) : 0}
          variant={highestNps ? 'highestNps' : 'withoutProcess'}
        />
      </div>
      <div className="grid grid-cols-auto-1fr items-center gap-2 bg-[#F9FAFB] p-4">
        <ProcessStats
          name={lowestNps ? lowestNps?.widgetName : t('HAPPINESS_DASHBOARD.withoutProcess')}
          processDescription={t('HAPPINESS_DASHBOARD.lowScoreProcess')}
          score={lowestNps ? customRounding(lowestNps.nps) : 0}
          variant={lowestNps ? 'lowestNps' : 'withoutProcess'}
        />
      </div>
    </div>
  );
};
