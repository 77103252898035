/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAtom } from 'jotai';
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeleteIconSolid, SuspendContactIcon } from '@/lib/icon';
import { Button, IconSvg, If, toast, Tooltip } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { ReactivateIcon, WhatsAppIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { atomSendWhatsAppModalIsOpen } from '@/modules/ContactsModule/atoms/WhatsApp';
import { ActionConfirmModal } from '@/modules/ContactsModule/components';
import { SendWhatsAppModal } from '@/modules/ContactsModule/components/SendWhatsAppModal';

interface ContactsProfileButtonsProps {
  emailId: number;
  isDiscardedUser: boolean;
  setLastRefresh: Dispatch<SetStateAction<number>>;
  havePhoneNumber: boolean;
}

const ContactsProfileButtons = ({
  emailId,
  isDiscardedUser,
  setLastRefresh,
  havePhoneNumber,
}: ContactsProfileButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const outboundWhatsApp = useFeatureFlag('outboundWhatsApp');

  const service = useService();
  const [isLoading, setIsLoading] = useState(false);
  const [suspendModalIsOpen, setSuspendModalIsOpen] = useState(false);
  const [reactivateModalIsOpen, setReactivateModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [sendWhatsAppModalIsOpen, setSendWhatsAppModalIsOpen] = useAtom(
    atomSendWhatsAppModalIsOpen
  );

  const handleCloseSendWhatsAppModal = useCallback(() => {
    setSendWhatsAppModalIsOpen(false);
  }, [setSendWhatsAppModalIsOpen]);

  const deleteContact = async () => {
    setIsLoading(true);
    const result = await service?.deleteSelectedContacts({ contactsIds: [emailId] });
    if (result) {
      navigate && navigate('/v2/contacts');
    }
    toast({
      variant: result ? 'success' : 'error',
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
    });
    setIsLoading(false);
  };

  const showNotification = useCallback(
    (result: boolean) => {
      toast({
        title: result
          ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
          : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
        body: result
          ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
          : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
        variant: result ? 'success' : 'error',
      });
    },
    [t]
  );

  const reactivateUser = useCallback(async () => {
    setIsLoading(true);
    const result = await service?.reactivateDiscardedUsersSelected([emailId]);

    if (result?.success) {
      setLastRefresh((prevState) => prevState + 1);
      showNotification(true);
      setReactivateModalIsOpen(false);
    } else {
      showNotification(false);
    }
    setIsLoading(false);
  }, [emailId, service, setLastRefresh, showNotification]);

  const suspendContact = async () => {
    setIsLoading(true);
    const result = await service?.suspendSelectedContacts({ contactsIds: [emailId] });
    if (result) setSuspendModalIsOpen(false);
    toast({
      variant: result ? 'success' : 'error',
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
    });
    setLastRefresh((prevState) => prevState + 1);
    setIsLoading(false);
  };

  return (
    <>
      <Flex withGap alignment="end">
        <If condition={outboundWhatsApp}>
          <Tooltip
            isFullWidth
            content={
              !havePhoneNumber
                ? t('CONTACT_BY_WHATSAPP.tooltipDisabled')
                : t('CONTACT_BY_WHATSAPP.tooltip')
            }
          >
            <Button
              outline
              disabled={!havePhoneNumber}
              iconLeft={
                <IconSvg
                  fillColor="primary"
                  height="20px"
                  svgComponent={<WhatsAppIcon />}
                  width="20px"
                  withPadding="small"
                />
              }
              onClick={() => setSendWhatsAppModalIsOpen(true)}
            >
              {t('CONTACT_BY_WHATSAPP.textButton')}
            </Button>
          </Tooltip>
        </If>
        <Button outline iconLeft={<DeleteIconSolid />} onClick={() => setDeleteModalIsOpen(true)}>
          {t('delete')}
        </Button>
        {!isDiscardedUser && (
          <Button
            outline
            iconLeft={<SuspendContactIcon />}
            onClick={() => setSuspendModalIsOpen(true)}
          >
            {t('suspend')}
          </Button>
        )}
        {isDiscardedUser && (
          <Button
            outline
            iconLeft={<IconSvg height="30px" svgComponent={<ReactivateIcon />} width="30px" />}
            onClick={() => setReactivateModalIsOpen(true)}
          >
            {t('REACTIVATE_DISCARDED_USER.reactivate')}
          </Button>
        )}
      </Flex>
      <ActionConfirmModal
        description={t('CONTACT_DELETE_MODAL.description')}
        isLoading={isLoading}
        isOpen={deleteModalIsOpen}
        textButton={t('delete')}
        title={t('CONTACT_DELETE_MODAL.title')}
        onClick={deleteContact}
        onClose={setDeleteModalIsOpen}
      />
      <ActionConfirmModal
        description={t('CONTACT_SUSPEND_MODAL.description')}
        isLoading={isLoading}
        isOpen={suspendModalIsOpen}
        textButton={t('suspend')}
        title={t('CONTACT_SUSPEND_MODAL.title')}
        onClick={suspendContact}
        onClose={setSuspendModalIsOpen}
      />
      <ActionConfirmModal
        description={t('REACTIVATE_DISCARDED_USER.description')}
        isLoading={isLoading}
        isOpen={reactivateModalIsOpen}
        textButton={t('REACTIVATE_DISCARDED_USER.reactivate')}
        title={t('REACTIVATE_DISCARDED_USER.title')}
        onClick={reactivateUser}
        onClose={setReactivateModalIsOpen}
      />
      <SendWhatsAppModal isOpen={sendWhatsAppModalIsOpen} onClose={handleCloseSendWhatsAppModal} />
    </>
  );
};

export default memo(ContactsProfileButtons);
