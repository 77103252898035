import { useEffect, useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { NpsContacts } from '@/modules/ContactsModule/screens/HappinessMain/types';

export const useNpsContactList = ({
  npsStatus,
  happiness,
  search,
  pageNumber,
  orderByName,
  rowsPerPage,
}: {
  npsStatus: string;
  happiness: string[];
  search: string;
  pageNumber: number;
  orderByName: string;
  rowsPerPage: number;
}): [NpsContacts | undefined, Error | undefined, boolean] => {
  const [npsContacts, error, isLoadingContacts] = useEmblue(ServiceMethods.getNpsContactList, {
    npsStatus,
    happiness,
    search,
    pageNumber,
    orderByName,
    rowsPerPage,
  });

  return [npsContacts, error, isLoadingContacts];
};
