export const ArrowBackIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 28 32" width="28" xmlns="http://www.w3.org/2000/svg">
      <rect height="31" rx="3.5" stroke="#D3DDEA" width="27" x="0.5" y="0.5" />
      <path
        d="M11.7156 16.9975L17.6706 11.7064C17.8814 11.5185 17.9995 11.2639 17.999 10.9986C17.9985 10.7333 17.8795 10.4791 17.668 10.2918C17.4566 10.1045 17.1701 9.99957 16.8716 10C16.5731 10.0004 16.287 10.1062 16.0763 10.2941L9.32728 16.2897C9.12344 16.4717 9.00632 16.7165 9.00025 16.9732C8.99418 17.2299 9.09963 17.4789 9.29469 17.6683L16.0737 23.7056C16.1783 23.7987 16.3025 23.8727 16.4393 23.9232C16.576 23.9738 16.7227 23.9998 16.8708 24C17.0189 24.0002 17.1656 23.9744 17.3025 23.9242C17.4394 23.8739 17.5638 23.8003 17.6687 23.7073C17.7735 23.6143 17.8568 23.5039 17.9136 23.3824C17.9705 23.2608 17.9998 23.1305 18 22.9989C18.0002 22.8673 17.9712 22.7369 17.9147 22.6152C17.8582 22.4936 17.7752 22.383 17.6706 22.2898L11.7156 16.9975Z"
        fill="#364365"
      />
    </svg>
  );
};
