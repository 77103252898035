export const customRounding = (number: number, numDecimals = 0): number => {
  if (numDecimals < 0) {
    throw new Error('numDecimals must be greater than or equal to 0');
  }

  const factor = Math.pow(10, numDecimals);
  const roundedNumber = Math.round(number * factor) / factor;

  if (numDecimals === 0) {
    return Math.round(roundedNumber);
  } else {
    return roundedNumber;
  }
};
