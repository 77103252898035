import { atom } from 'jotai';

import { DealResponse } from '@/src/infrastructure/interfaces/IResponses';
import { FormStepOne } from '@/src/modules/AuthModule/components/SignUpFormOne/SignUpFormOne';
import { FormStepTwo } from '@/src/modules/AuthModule/components/SignUpFormTwo/SignUpFormTwo';

export const atomSignUpCurrentStep = atom<number>(1);
atomSignUpCurrentStep.debugLabel = 'atomSignUpCurrentStep';

export const atomFormStepOne = atom<FormStepOne | undefined>(undefined);
atomFormStepOne.debugLabel = 'atomFormStepOne';

export const atomFormStepTwo = atom<FormStepTwo | undefined>(undefined);
atomFormStepTwo.debugLabel = 'atomFormStepTwo';

export const atomStepCompleted = atom<number>(1);
atomStepCompleted.debugLabel = 'atomStepCompleted';

export const atomDealResponseIds = atom<DealResponse | undefined>(undefined);
atomDealResponseIds.debugLabel = 'atomDealResponseIds';
