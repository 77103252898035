export const NeutralFace = () => {
  return (
    <svg height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1,15.675A1.625,1.625,0,1,0,13.725,17.3,1.626,1.626,0,0,0,12.1,15.675Zm7.8,0A1.625,1.625,0,1,0,21.525,17.3,1.626,1.626,0,0,0,19.9,15.675ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Zm0,23.4A10.414,10.414,0,0,1,5.6,16a10.54,10.54,0,0,1,.065-1.118A13.08,13.08,0,0,0,12.438,7.9a12.966,12.966,0,0,0,10.608,5.5,12.688,12.688,0,0,0,2.925-.338A10.384,10.384,0,0,1,16,26.4Z"
        fill="#ffb114"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
