export const ThumbIcon = () => {
  return (
    <svg
      height="18.822"
      viewBox="0 0 20.129 18.822"
      width="20.129"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M19.816,25.109a3.4,3.4,0,0,0-3.422-2.23H14.4a7.883,7.883,0,0,0,.712-3.2,6.383,6.383,0,0,0-.1-1.128,2.416,2.416,0,0,0-2.818-2.2c-.594.068-.8.632-1.291,2a19.431,19.431,0,0,1-1.78,3.934,6.662,6.662,0,0,1-2.554,2.087v-.113H0v9.768H6.576v-.538a8.3,8.3,0,0,1,1.761.023l1.177.683.879.549c.854.46,4.591.5,6.194.267a3.076,3.076,0,0,0,2.579-2.062C19.328,32.389,20.746,27.364,19.816,25.109Zm-1.892,7.485A1.8,1.8,0,0,1,16.4,33.73,18.517,18.517,0,0,1,11,33.6l-.78-.489-1.478-.83A7.913,7.913,0,0,0,6.576,32.2V25.77a8.117,8.117,0,0,0,3.635-2.78,20.373,20.373,0,0,0,1.914-4.2s.379-.944.471-1.163c.655,0,1.016.364,1.151,1.144a5.228,5.228,0,0,1,.074.9,7.064,7.064,0,0,1-1.009,3.5l-.656,1,4.238,0A2.138,2.138,0,0,1,18.622,25.6C19.265,27.159,18.332,31.179,17.924,32.594Z"
          fill="#004dbc"
          transform="translate(0 -16.331)"
        />
      </g>
    </svg>
  );
};
