import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconSvg, Text } from '@/lib/v2/components';
import {
  ISmartFinderFound,
  ISmartFinderItem,
} from '@/lib/v2/components/SmartFinder/SmartFinder.interface';
import { IDataSet } from '@/lib/v2/hooks/SmartFinder.worker';
import { SearchIcon } from '@/lib/v2/icons/outline';

import { EChannelsID } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';

const EntityLinkMore = ({
  title,
  type,
  onSearchMore,
}: {
  title: string;
  type: keyof IDataSet;
  onSearchMore: (type: keyof IDataSet) => Promise<void>;
}) => {
  return (
    <button
      className="mb-2 flex items-center space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
      onClick={() => void onSearchMore(type)}
    >
      <IconSvg svgComponent={<SearchIcon color="#374151" />} />
      <Text color="dark" variant="text">
        {title}
      </Text>
    </button>
  );
};

const ModuleItems = ({
  data,
  module,
  handleOnClick,
}: {
  data: ISmartFinderItem[];
  module: string;
  handleOnClick: (item: ISmartFinderItem) => void;
}) => {
  return (
    <>
      {data.map((item) => (
        <button
          key={item.id}
          className="flex items-center justify-between space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
          onClick={() => handleOnClick && handleOnClick(item)}
        >
          <Text color="dark" variant="text">
            {item.name}
          </Text>
          <Text color="primary" variant="text-sm">
            {module}
          </Text>
        </button>
      ))}
    </>
  );
};

const ItemsFound = ({
  data,
  textSearch,
  onSearchMore,
  onClose,
}: {
  data: ISmartFinderFound;
  textSearch: string;
  onSearchMore: (type: keyof IDataSet) => Promise<void>;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const haveData: boolean = Object.values(data).find(
    (item) => Array.isArray(item) && item?.length > 0
  )
    ? true
    : false;
  const navigate = useNavigate();

  const channelInfoByID = useMemo(() => {
    return {
      [EChannelsID.MASTER]: 'master',
      [EChannelsID.EMAIL]: 'email',
      [EChannelsID.EMAIL_TRIGGER]: 'email-trigger',
      [EChannelsID.EMAIL_ATTACHMENT]: 'email-attachment',
      [EChannelsID.EMAIL_API]: 'mail-api',
      [EChannelsID.SMS]: 'sms',
      [EChannelsID.SMS_API]: 'sms-api',
    };
  }, []);

  const handleActions = useCallback(
    (item: ISmartFinderItem) => {
      if (!item.subType) return;
      navigate(
        `/v2/campaigns/${item.parentId as number}/${channelInfoByID[item.subType as EChannelsID]}/${
          item.id
        }`
      );
      onClose();
    },
    [channelInfoByID, navigate, onClose]
  );

  const handleCampaigns = useCallback(
    (item: ISmartFinderItem) => {
      navigate(`/v2/campaigns/${item.id}/`);
      onClose();
    },
    [navigate, onClose]
  );

  const handleGroups = useCallback(
    (item: ISmartFinderItem) => {
      navigate(`/v2/contacts/groups/${item.id}`);
      onClose();
    },
    [navigate, onClose]
  );

  const handleTags = useCallback(
    (item: ISmartFinderItem) => {
      navigate(`/v2/contacts/tags/${item.id}`);
      onClose();
    },
    [navigate, onClose]
  );

  const handleSegments = useCallback(
    (item: ISmartFinderItem) => {
      navigate(`/v2/contacts/segments/${item.id}`);
      onClose();
    },
    [navigate, onClose]
  );

  return (
    <div className="flex w-full flex-col">
      <div className="sticky top-[57px] border-t border-gray-400 bg-gray-300 px-6 py-2 font-semibold text-gray-700">
        {t('SMART_FINDER.itemsFound')}
      </div>

      {haveData && (
        <div className="flex flex-col space-y-2 px-6 py-4">
          <ModuleItems
            data={data?.actions ?? []}
            handleOnClick={handleActions}
            module={t('SMART_FINDER.actions')}
          />
          <ModuleItems
            data={data?.campaigns ?? []}
            handleOnClick={handleCampaigns}
            module={t('TOP_BAR.campaigns')}
          />
          <ModuleItems
            data={data?.groups ?? []}
            handleOnClick={handleGroups}
            module={t('CONTACTS_MENU_BAR.groups')}
          />
          <ModuleItems
            data={data?.tags ?? []}
            handleOnClick={handleTags}
            module={t('CONTACTS_MENU_BAR.tags')}
          />
          <ModuleItems
            data={data?.segments ?? []}
            handleOnClick={handleSegments}
            module={t('CONTACTS_MENU_BAR.segments')}
          />
        </div>
      )}

      {!haveData && (
        <div className="flex flex-col p-3">
          <Text
            alignment="center"
            className="my-6"
            color="dark"
            fontWeight="normal"
            renderHtml={t('SMART_FINDER.emptyResults').replaceAll('{0}', textSearch)}
            variant="sub-headline"
          />
          <EntityLinkMore
            title={t('SMART_FINDER.actions')}
            type="actions"
            onSearchMore={onSearchMore}
          />
          <EntityLinkMore
            title={t('SMART_FINDER.campaigns')}
            type="campaigns"
            onSearchMore={onSearchMore}
          />
          <EntityLinkMore
            title={t('SMART_FINDER.groups')}
            type="groups"
            onSearchMore={onSearchMore}
          />
          <EntityLinkMore title={t('SMART_FINDER.tags')} type="tags" onSearchMore={onSearchMore} />
          <EntityLinkMore
            title={t('SMART_FINDER.segments')}
            type="segments"
            onSearchMore={onSearchMore}
          />
        </div>
      )}

      <button
        className="m-3 flex items-center space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
        onClick={() => window.open('https://help.embluemail.com/?s=' + textSearch, '_new')}
      >
        <Text color="dark" variant="text">
          {t('SMART_FINDER.searchInHelp')}
        </Text>
        <Text color="dark" fontWeight="medium">
          {'"' + textSearch + '"'}
        </Text>
      </button>
    </div>
  );
};

export default ItemsFound;
