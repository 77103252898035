import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { Flex, Text } from '@/lib/v2/components';

import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

interface SegmentTitleProps {
  segmentsSwitcher: string;
  isLoadingSegmentCountTotal: boolean;
  segmentsCountTotal: number;
  segmentListLength: number;
  isLoadingSegments: boolean;
  segmentsCountOfSearch: number;
  search: string;
}

const SegmentTitle = ({
  segmentsSwitcher,
  segmentListLength,
  isLoadingSegments,
  segmentsCountTotal,
  isLoadingSegmentCountTotal,
  segmentsCountOfSearch,
  search,
}: SegmentTitleProps) => {
  const { t } = useTranslation();
  let segmentTitle;
  if (segmentsSwitcher === SegmentsSwitcherEnum.Customized)
    segmentTitle = t('SEGMENTS_MAIN.segments');
  else if (segmentsSwitcher === SegmentsSwitcherEnum.EmBlueRecipes)
    segmentTitle = t('SEGMENTS_MAIN.segmentsEmBlue');
  else segmentTitle = t('SEGMENTS_MAIN.segmentsECommerce');

  const countText = !isLoadingSegmentCountTotal ? (
    <Text color="primary" fontWeight="medium" variant="title-1">
      ({quantifier(segmentsCountTotal)})
    </Text>
  ) : null;

  const searchText = (() => {
    if (isLoadingSegments) {
      return <TextSkeleton />;
    }

    if (!search && segmentsCountOfSearch) {
      return <Text>{t('SEGMENTS_MAIN.noFilters')}</Text>;
    }

    if (search && segmentsCountOfSearch && !isLoadingSegments && segmentsCountOfSearch) {
      return (
        <Text as="span">
          {t('SEGMENTS_MAIN.youHave')}
          <Text as="span" color="primary" fontWeight="medium">
            {` ${quantifier(segmentsCountOfSearch) ?? 0} `}
          </Text>
          {segmentListLength === 1
            ? t('SEGMENTS_MAIN.contactMatch')
            : t('SEGMENTS_MAIN.contactsMatch')}
        </Text>
      );
    }
    return t('noResultsFound');
  })();

  return (
    <Flex column itemAlignment="start">
      <div className="flex flex-row gap-2">
        <Text fontWeight="medium" variant="title-1">
          {segmentTitle}
        </Text>
        {countText}
      </div>
      {searchText}
    </Flex>
  );
};

export default memo(SegmentTitle);
