import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { memo } from 'react';

export interface RadioSwitchOption extends Object {
  value: string | number;
  label: string;
}

interface RadioSwitchProps {
  value: RadioSwitchOption;
  onChange?: (value: RadioSwitchOption) => void;
  options: [RadioSwitchOption, RadioSwitchOption];
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
}

const RadioSwitch = ({ value, onChange, options, size = 'sm', disabled }: RadioSwitchProps) => {
  const handleChange = (value: string | number) => {
    const optionSelected = options.find((option) => option.value === value);
    onChange?.(optionSelected ?? options[0]);
  };
  const sizes = {
    sm: 'px-2.5 py-1',
    md: 'px-2 py-2 min-w-[100px]',
    lg: 'p-3 min-w-[160px]',
  };
  return (
    <RadioGroup
      className={classNames(
        'text-xs grid grid-cols-2 gap-x-1 rounded-full p-1 text-center font-medium leading-5 ring-1 ring-inset ring-gray-200',
        { 'opacity-60': disabled }
      )}
      disabled={disabled}
      id="frequency"
      value={value.value}
      onChange={handleChange}
    >
      <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
      {options.map((option) => (
        <RadioGroup.Option
          key={option.value}
          className={({ checked }) => {
            return classNames(
              checked ? 'bg-emblue-primary text-white' : 'text-gray-500',
              'cursor-pointer rounded-full ',
              sizes[size]
            );
          }}
          disabled={disabled}
          value={option.value}
        >
          <span>{option.label}</span>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default memo(RadioSwitch);
