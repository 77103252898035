import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { TRANSLATE_API_MANAGER_NAV } from '@/modules/ApiManagerModule/constants';

export type ApiManagerMenuItemsProps = {
  name: string;
  to: string;
  current: boolean;
};

const MenuBarItems = () => {
  const { t } = useTranslation(['apiManager']);

  const links: ApiManagerMenuItemsProps[] = useMemo(
    () => [
      {
        name: t(`${TRANSLATE_API_MANAGER_NAV}.topBar`),
        to: '/v2/api-manager',
        current: location.pathname.includes('api-manager') ? true : false,
      },
    ],
    [t]
  );

  const linkClasses = classNames(
    'border-[#004DBC] text-[#004DBC] border-b-[5px] px-3 hover:text-gray-700 inline-flex text-sm items-center'
  );

  return (
    <>
      {links.map((item) => (
        <NavLink key={item.name} className={linkClasses} to={item.to}>
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

export default MenuBarItems;
