import { format, parse } from '@formkit/tempo';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components';

import { TRANSLATE_CALENDAR_HEADER } from '@/modules/CampaignsModule/constants';
interface CalendarHeaderProps {
  currentMonth: string;
  onToday?: () => void;
  onNextMonth?: () => void;
  onPrevMonth?: () => void;
}

const CalendarHeader = ({
  currentMonth,
  onToday,
  onNextMonth,
  onPrevMonth,
}: CalendarHeaderProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const currentMonthDate = useMemo(() => parse(currentMonth, 'MMM YYYY'), [currentMonth]);

  return (
    <header className="mb-3 flex items-center justify-between">
      <div className="flex gap-4">
        <Button gray outline standard id="calendar-header__today" onClick={() => onToday?.()}>
          {t(`${TRANSLATE_CALENDAR_HEADER}.today`)}
        </Button>
        <div className="eb-button eb-button--medium eb-button--primary eb-button--outline--primary eb-button--outline--gray eb-button--standard relative flex items-center gap-2 capitalize">
          <button
            className="flex items-center justify-center"
            id="calendar-header__PrevMonth"
            onClick={() => onPrevMonth?.()}
          >
            <ChevronLeftIcon aria-hidden="true" className="size-5" />
          </button>
          {format(currentMonthDate, 'MMM YYYY', i18n.language)}
          <button
            className="flex items-center justify-center"
            id="calendar-header__NextMonth"
            type="button"
            onClick={() => onNextMonth?.()}
          >
            <ChevronRightIcon aria-hidden="true" className="size-5" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default CalendarHeader;
