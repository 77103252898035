import { useMemo } from 'react';

export const useTruncateString = (value: string, removeLength: number) => {
  const valueTruncated = useMemo(() => {
    if (value.length > removeLength) {
      return `${value.substring(0, value.length - removeLength)}...`;
    } else {
      return value;
    }
  }, [removeLength, value]);

  return valueTruncated;
};
