import { IconProps } from './iconPropTypes';

export const TagProfileTableIcon = ({ color }: IconProps) => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 3598" id="Grupo_3598" transform="translate(-472.5 -405.723)">
        <rect
          data-name="Rectángulo 3240"
          fill="none"
          height="20"
          id="Rectángulo_3240"
          transform="translate(472.5 405.723)"
          width="20"
        />
        <path
          d="M6.556,6.556h.009M6.556,3H11a1.769,1.769,0,0,1,1.257.521l6.222,6.222a1.778,1.778,0,0,1,0,2.514l-6.222,6.222a1.778,1.778,0,0,1-2.514,0L3.521,12.257A1.772,1.772,0,0,1,3,11V6.556A3.556,3.556,0,0,1,6.556,3Z"
          data-name="Trazado 3206"
          fill="none"
          id="Trazado_3206"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(471.5 405.223)"
        />
      </g>
    </svg>
  );
};
