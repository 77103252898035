import { Details } from '@/lib/v2/components/DomainValidator/DomainValidator';

export interface ITotalsSender {
  total: number;
  partial: number;
}

export interface IPager {
  page: number;
  limit: number;
}

export type ITablePager = {
  pageIndex: number;
  pageSize: number;
  search?: string;
};

export interface ISendersFilters {
  search: string;
  limit: number;
  page: number;
}

export enum SenderStatus {
  Verify = 'check',
  NoCheck = 'noCheck',
  Warning = 'warning',
}

export enum SenderState {
  NotVerify = 'NotVerified',
  Verify = 'Verified',
  Incorrect = 'MisConfigured',
  NonExistent = 'NonExistent',
}

export type TFieldMessage = {
  errorCode: keyof ErrorMessages;
  errorArgs: string[];
};

export interface IDomainValidationData {
  rootDomain: {
    errors: TFieldMessage[];
    warnings: TFieldMessage[];
    status: string;
    record: {
      statusCode: number;
      loadTimeMs: number;
      endUrl: string;
      contentType: string;
    };
  };
  spf: {
    errors: TFieldMessage[];
    warnings: TFieldMessage[];
    status: string;
    record: {
      version: string;
      include: {
        qualifier: string;
        values: string[];
      };
      all: {
        qualifier: string;
        values: string[];
      };
    };
    raw: string;
    hops: {
      name: string;
      record: {
        version: string;
        ip4?: {
          qualifier: string;
          values: string[];
        };
        ip6?: {
          qualifier: string;
          values: string[];
        };
        all: {
          qualifier: string;
          values: string[];
        };
      };
      path: string;
      raw: string;
    }[][];
  };
  dmarc: {
    errors: TFieldMessage[];
    warnings: TFieldMessage[];
    status: string;
    record: {
      v: string;
      p: string;
      rua: string;
    };
    raw: string;
  };
  dkim: {
    errors: TFieldMessage[];
    warnings: TFieldMessage[];
    status: string;
    record: any;
    raw: any;
  };
  reputation: string;
}

export interface IDomainFieldAdapted {
  type: Details['status'];
  message: string;
}

interface ErrorMessageDetails {
  message: string;
  detail: string;
}

interface ErrorMessageLanguages {
  en: ErrorMessageDetails;
  es: ErrorMessageDetails;
  pt: ErrorMessageDetails;
}

export interface ErrorMessages {
  exception: ErrorMessageLanguages;
  record_not_found: ErrorMessageLanguages;
  spf_missing_bounce_cname: ErrorMessageLanguages;
  record_unknown_key: ErrorMessageLanguages;
  dkim_record_incorrect_version: ErrorMessageLanguages;
  spf_all_key_must_be_last: ErrorMessageLanguages;
  spf_too_many_hops: ErrorMessageLanguages;
  dns_record_incorrect_type: ErrorMessageLanguages;
}

export type ErrorLangs = 'en' | 'es' | 'pt';
