import { Button, IconSvg } from '@/lib/v2/components';
import ProgressNotification from '@/lib/v2/components/ProgressNotification';
import { useNotificationDownload } from '@/lib/v2/hooks/useNotificationDownload';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';

export const NotifyDownloadDiscarded = () => {
  const { show, wordings } = useNotificationDownload();

  const icon = (
    <Button
      className="my-2 rounded-full"
      iconLeft={<IconSvg fillColor={'white'} svgComponent={<DownloadIcon />} />}
    />
  );

  if (!show) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-[999] w-fit p-6">
      <ProgressNotification icon={icon} indeterminate={true} wordings={wordings} />
    </div>
  );
};
