import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import {
  atomPlansInformation,
  atomSelectedPlanData,
} from '@/src/modules/MyPlanModule/atoms/ManagePlan';
import { PlansIds } from '@/src/modules/MyPlanModule/types';

import { usePlanAdditionalInfo } from './usePlanAdditionalInfo';

export const usePlan = () => {
  const plansInformation = useAtomValue(atomPlansInformation);
  const selectedPlanData = useAtomValue(atomSelectedPlanData);
  const { planAdditionalInfo } = usePlanAdditionalInfo();

  const plan = useMemo(() => {
    return (
      plansInformation &&
      plansInformation?.find((planInfo) => planInfo.id === selectedPlanData?.planId)
    );
  }, [plansInformation, selectedPlanData?.planId]);

  const getPlanNameById = useCallback(
    (id: PlansIds) => {
      const name = plansInformation?.find((planInfo) => planInfo.id === id)?.name;
      if (!name) return undefined;
      return planAdditionalInfo[name].name;
    },
    [planAdditionalInfo, plansInformation]
  );

  const getPlanInformationById = useCallback(
    (planId: PlansIds) => {
      return plansInformation?.find((planInfo) => planInfo.id === planId);
    },
    [plansInformation]
  );

  return { plan, getPlanInformationById, getPlanNameById };
};
