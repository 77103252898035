import { ITableCampaignActions } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

interface ICampaignIDByActionSelected {
  rowAction: number[];
  actionsList: ITableCampaignActions[] | undefined;
}
export const getCampaignIDByActionSelected = ({
  rowAction,
  actionsList,
}: ICampaignIDByActionSelected) => {
  const campaignIDByActionSelected = rowAction.map((index) => {
    const action = actionsList?.[index]?.action;
    return action?.campaignId;
  });
  return campaignIDByActionSelected?.[0];
};
