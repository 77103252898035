export const EmptyRecycleBinIcon = () => {
  return (
    <svg height="147.9" viewBox="0 0 125.7 147.9" width="125.7" xmlns="http://www.w3.org/2000/svg">
      <g id="recycle" transform="translate(-62.7 -2.4)">
        <path
          d="M162.1,150.2H89a16.986,16.986,0,0,1-17-17L63.2,8.6a5.7,5.7,0,0,1,5.7-5.7H182.2a5.7,5.7,0,0,1,5.7,5.7l-8.8,124.6A17.05,17.05,0,0,1,162.1,150.2Z"
          data-name="Trazado 5363"
          fill="#004dbc"
          id="Trazado_5363"
          opacity="0.6"
        />
        <path
          d="M148.7,79.5l-2.2-3.4a3.744,3.744,0,0,0-6.2,4.2l2.2,3.4a3.78,3.78,0,0,1-3.1,5.9h-2.1a3.77,3.77,0,0,0-6.1-4.2L126,90.6a3.839,3.839,0,0,0-.2,5.5l5.3,5.3a3.739,3.739,0,0,0,6-4.3h2.1A11.312,11.312,0,0,0,148.7,79.5Z"
          data-name="Trazado 5364"
          fill="#fff"
          id="Trazado_5364"
          stroke="#727272"
          strokeWidth="1"
        />
        <g data-name="Grupo 8868" id="Grupo_8868">
          <path
            d="M142.2,59l-2,7.3a3.715,3.715,0,0,1-4.8,2.6L128.2,67a3.8,3.8,0,0,1-2.7-4.6,3.842,3.842,0,0,1,3.3-2.8l-1.1-1.8a3.768,3.768,0,0,0-3.5-1.9A3.912,3.912,0,0,0,121,58l-1.8,3.6a3.8,3.8,0,1,1-6.8-3.4l1.8-3.6a11.29,11.29,0,0,1,10-6.3,11.13,11.13,0,0,1,10,5.6l1.1,1.8a3.8,3.8,0,0,1,4.1-1.5A4.091,4.091,0,0,1,142.2,59Z"
            data-name="Trazado 5365"
            fill="#fff"
            id="Trazado_5365"
            stroke="#727272"
            strokeWidth="1"
          />
          <path
            d="M113.6,89l-4,.3a3.79,3.79,0,0,1-3.5-5.7l1-1.8a3.511,3.511,0,0,0,4,1.4,3.8,3.8,0,0,0,2.7-4.6l-1.9-7.1a3.873,3.873,0,0,0-4.7-2.9l-7.3,2a3.8,3.8,0,0,0-2.7,4.6,3.842,3.842,0,0,0,3.3,2.8l-1.1,1.8a11.367,11.367,0,0,0,10.6,17l4-.3a3.756,3.756,0,0,0-.4-7.5Z"
            data-name="Trazado 5366"
            fill="#fff"
            id="Trazado_5366"
            stroke="#727272"
            strokeWidth="1"
          />
        </g>
        <path
          d="M71.8,131.1l.2,2.2a16.986,16.986,0,0,0,17,17h73.2a16.986,16.986,0,0,0,17-17l.2-2.2Z"
          data-name="Trazado 5367"
          fill="#ddd"
          id="Trazado_5367"
        />
        <path
          d="M187.1,19.9l.8-11.3a5.7,5.7,0,0,0-5.7-5.7H68.9a5.7,5.7,0,0,0-5.7,5.7L64,19.9Z"
          data-name="Trazado 5368"
          fill="#fff"
          id="Trazado_5368"
          stroke="#727272"
          strokeWidth="1"
        />
        <path
          d="M182.2,2.9h-30c-12,8-30.3,14.1-51.9,16.9h86.8l.8-11.3A5.677,5.677,0,0,0,182.2,2.9Z"
          data-name="Trazado 5369"
          fill="#ddd"
          id="Trazado_5369"
          stroke="#727272"
          strokeWidth="1"
        />
        <line
          data-name="Line 531"
          fill="none"
          id="Line_531"
          stroke="#727272"
          strokeWidth="1"
          transform="translate(64 19.9)"
          x2="123.5"
        />
        <line
          data-name="Line 532"
          fill="none"
          id="Line_532"
          stroke="#727272"
          strokeWidth="1"
          transform="translate(71.8 131.1)"
          x2="107"
        />
      </g>
    </svg>
  );
};
