import { useCallback, useEffect, useState } from 'react';

import { Option } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const usePreviewContacts = () => {
  const [previewContacts, setPreviewContacts] = useState<Option[] | undefined>(undefined);
  const [previewGroups, setPreviewGroups] = useState<Option[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const service = useService();

  const getPreviewContacts = useCallback(async () => {
    const response = await service.getContactsForEmailTest();

    const previewContactsOptions: Option[] = response.map((item) => ({
      id: item.id,
      name: item.email,
      value: item.email,
    }));
    setPreviewContacts(previewContactsOptions);
  }, [service, setPreviewContacts]);

  const getPreviewGroups = useCallback(async () => {
    const response = await service.getGroupsForEmailTest();

    const previewGroupsOptions: Option[] = response.map((item) => ({
      id: item.id,
      name: item.name,
      value: item.name,
    }));
    setPreviewGroups(previewGroupsOptions);
  }, [service, setPreviewGroups]);

  useEffect(() => {
    const fetchGroupsContacts = async () => {
      setLoading(true);

      const promises = [getPreviewContacts(), getPreviewGroups()];
      await Promise.allSettled(promises);

      setLoading(false);
    };

    void fetchGroupsContacts();
  }, [getPreviewContacts, getPreviewGroups]);

  return {
    previewContacts,
    previewGroups,
    loading,
  };
};
