import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@/lib/components';
import { PencilSuspensiveIcon } from '@/lib/icon/PencilSuspensiveIcon';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IModal } from '@/src/ContactsModule/interfaces/Interfaces';

export const ContactsTextareaModal = ({ children, apply, cancel, toggleRef }: IModal) => {
  const { t } = useTranslation();

  return (
    <Modal>
      <div ref={toggleRef} className="h-full p-8">
        <Modal.Title onAction={cancel}>
          <Flex withGap>
            <PencilSuspensiveIcon />
            <div>{t('CONTACTS_PROFILE.EditExtendedField')}</div>
          </Flex>
        </Modal.Title>
        <div className="h-[86%] sm:py-[2%] lg:py-[6%]">
          <Modal.Body>
            <div className="w-[30vw] p-[1%]">{children}</div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Flex withGap alignment="start">
            <Button onAction={apply}>
              <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
                {t('CONTACTS_PROFILE.Apply')}
              </div>
            </Button>
            <Button cancel onAction={cancel}>
              <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
                {t('CONTACTS_PROFILE.cancel')}
              </div>
            </Button>
          </Flex>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
