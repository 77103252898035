import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { Option } from '@/lib/v2/components/Select/Select';

/**
 * @desc Atom of progress for first step of import contacts
 */
export const atomSelectedGroups = atomWithReset<Option[]>([]);
atomSelectedGroups.debugLabel = 'atomSelectedGroups';

/**
 * @desc Atom of fields mapped
 */
export const atomModalMappedFields = atom<boolean>(false);
atomModalMappedFields.debugLabel = 'atomModalMappedFields';

/**
 * @desc Atom of create group
 */
export const atomStep4CreateGroup = atomWithReset<boolean>(false);
atomStep4CreateGroup.debugLabel = 'atomStep4CreateGroup';

/**
 * @desc Atom of tags
 */
export const atomStep4Tags = atom<Option[]>([]);
atomStep4Tags.debugLabel = 'atomStep4Tags';

/**
 * @desc Atom of groups
 */
export const atomStep4Groups = atom<Option[]>([]);
atomStep4Groups.debugLabel = 'atomStep4Groups';
