import { MenuBarItems } from './components/MenuBarItems';

const ApiManagerMenuBar = () => {
  return (
    <div className="relative z-10 flex h-[56px] w-full items-center justify-between bg-white px-[2%] shadow-md lg:gap-4 xl:gap-12 2xl:gap-16">
      <div className="flex h-full">
        <nav className="flex lg:gap-2 xl:gap-10 2xl:gap-16">
          <MenuBarItems />
        </nav>
      </div>
    </div>
  );
};

export default ApiManagerMenuBar;
