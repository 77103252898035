import { ReactElement } from 'react';

import { InputSearchProps } from '@/lib/v2/components/InputSearch/InputSearch';

import DropdownFilter, { IDropdownFilterProps } from './components/DropdownFilter';
import DropdownSimple, { IDropdownSimpleProps } from './components/DropdownSimple';
import InputDateRange, { IInputDateRangeProps } from './components/InputDateRange';
import InputSearchFilter from './components/InputSearchFilter';
import ResetButton, { IResetButtonProps } from './components/ResetButton';

type FiltersType =
  | IDropdownFilterProps
  | IDropdownSimpleProps
  | IInputDateRangeProps
  | InputSearchProps
  | IResetButtonProps;

interface ITableFilterBarProps {
  children?: ReactElement<FiltersType>[];
}

const TableFilterBar = ({ children }: ITableFilterBarProps) => {
  return <div className="z-10 flex max-w-fit items-center gap-2 px-4">{children}</div>;
};

TableFilterBar.DropdownFilter = DropdownFilter;
TableFilterBar.DropdownSimple = DropdownSimple;
TableFilterBar.InputDateRange = InputDateRange;
TableFilterBar.InputSearch = InputSearchFilter;
TableFilterBar.ResetButton = ResetButton;

export default TableFilterBar;
