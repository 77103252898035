export const SuccessIcon = () => {
  return (
    <svg
      height="27.634"
      viewBox="0 0 27.634 27.634"
      width="27.634"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon_feather-alert-circle" transform="translate(1.5 1.5)">
        <g>
          <path
            d="M27.634,15.317A12.317,12.317,0,1,1,15.317,3,12.317,12.317,0,0,1,27.634,15.317Z"
            fill="none"
            stroke="#18c678"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            transform="translate(-3 -3)"
          />
          <path
            d="M23.917,13.421l-1.108-1.139a.238.238,0,0,0-.176-.076h0a.229.229,0,0,0-.176.076l-7.679,7.736-2.795-2.795a.243.243,0,0,0-.352,0l-1.12,1.12a.251.251,0,0,0,0,.359l3.525,3.525a1.115,1.115,0,0,0,.736.359,1.168,1.168,0,0,0,.73-.346h.006l8.415-8.459A.269.269,0,0,0,23.917,13.421Z"
            fill="#18c678"
            transform="translate(-4.891 -5.076)"
          />
        </g>
      </g>
    </svg>
  );
};
