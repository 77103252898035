export type arrowProps = {
  color?: 'white' | 'black';
};

export const DownArrowIcon = ({ color }: arrowProps) => {
  return (
    <svg
      aria-hidden="true"
      className="-mr-1 ml-2 h-5 w-5"
      fill={`${color ? (color === 'white' ? 'white' : 'currentColor') : 'currentColor'}`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        fillRule="evenodd"
      />
    </svg>
  );
};
