import { t } from 'i18next';

import { toast } from '@/lib/v2/components';

export const showNotification = (result: boolean) => {
  toast({
    title: result
      ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
      : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
    body: result
      ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
      : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
    variant: result ? 'success' : 'error',
  });
};
