import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FloatModal, ItemSearch, Modal } from '@/lib/components';
import { Item, ItemListType } from '@/lib/components/ItemSearch/ItemSearch';
import { Button, Spinner } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { useTag } from '@/modules/ContactsModule/hooks/useTag';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';

const ButtonsSection = ({
  tags,
  setShowTagsModal,
  setSelectedTags,
  selectedTagsNames,
  setSelectedTagsNames,
  isUpdateCheckboxModal,
  setUnselectedTags,
}: {
  tags: ITag[];
  setShowTagsModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedTags?: Dispatch<SetStateAction<number[]>>;
  selectedTagsNames: ItemListType;
  setSelectedTagsNames: Dispatch<SetStateAction<ItemListType>>;
  isUpdateCheckboxModal: boolean;
  setUnselectedTags?: Dispatch<SetStateAction<number[]>>;
}) => {
  const { t } = useTranslation();
  const [disableButtons, setDisableButtons] = useState(false);

  const checkDisableButtons = (selectedGroups: ItemListType) => {
    for (const group of selectedGroups) {
      if (typeof group !== 'string' && group.indeterminate === true) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    !isUpdateCheckboxModal && setDisableButtons(checkDisableButtons(selectedTagsNames));
  }, [selectedTagsNames]);

  const cleanInputs = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkbox: any = document.getElementsByClassName('_modal_checkbox_'); // inputs in <ItemSearch/>
    if (checkbox) {
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    }
  };

  return (
    <Flex withGap alignment="end" gapSize="medium">
      <Button
        outline
        disabled={disableButtons}
        onClick={() => {
          cleanInputs();
          setSelectedTagsNames([]);
        }}
      >
        <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
          {t('CONTACT_TAG_MODAL.clearTag')}
        </div>
      </Button>
      <Button
        disabled={disableButtons}
        onClick={() => {
          const selectedTagsIds = tags
            .filter((g) => {
              if (typeof selectedTagsNames[0] === 'string') {
                return (selectedTagsNames as string[]).includes(g.name);
              } else {
                return (selectedTagsNames as Item[]).some((tag) => tag.name === g.name);
              }
            })
            .map((g) => Number(g.id));
          const unselectedTagsIds = tags
            .filter((g) => {
              if (typeof selectedTagsNames[0] === 'string') {
                return !(selectedTagsNames as string[]).includes(g.name);
              } else {
                return !(selectedTagsNames as Item[]).some((tag) => tag.name === g.name);
              }
            })
            .map((g) => Number(g.id));
          setSelectedTags && setSelectedTags(selectedTagsIds);
          setUnselectedTags && setUnselectedTags(unselectedTagsIds);
          //closeModal();
          setShowTagsModal && setShowTagsModal(false);
        }}
      >
        <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">{t('Save')}</div>
      </Button>
    </Flex>
  );
};
interface IContactsTagModalProps {
  contactTags?: ITag[];
  tags: ITag[];
  selectedContacts?: number[];
  setSelectedTags?: Dispatch<SetStateAction<number[]>>;
  setUnselectedTags?: Dispatch<SetStateAction<number[]>>;
  setShowTagsModal?: Dispatch<SetStateAction<boolean>>;
  maxTagsToAdd?: number;
  isUpdateCheckboxModal?: boolean;
  setListSelectedTagsNames?: Dispatch<SetStateAction<ItemListType>>;
  setListUnselectedGroupsNames?: Dispatch<SetStateAction<ItemListType>>;
  onLastRefresh?: () => void;
  showModal?: boolean;
}
export const ContactsTagModal = ({
  contactTags,
  tags,
  showModal = true,
  selectedContacts,
  setShowTagsModal,
  setSelectedTags,
  setUnselectedTags,
  setListSelectedTagsNames,
  setListUnselectedGroupsNames,
  isUpdateCheckboxModal = false,
  onLastRefresh,
  maxTagsToAdd,
}: IContactsTagModalProps) => {
  const { t } = useTranslation();
  const [tagsListValue, , isLoadingTagList] = useEmblue(ServiceMethods.getGroupsByContacts, {
    contactsIds: [],
    allResults: false,
    filters: {} as ContactTableSearchFilters,
  });
  const [selectedTagsNames, setSelectedTagsNames] = useState<ItemListType>([]);
  const [unselectedTagsNames, setUnselectedTagsNames] = useState<ItemListType>([]);
  const [isLoadingTag, setIsLoadingTag] = useState(false);

  const { createTag } = useTag();

  let tagsNamesList: ItemListType = [];
  if (contactTags) {
    tagsNamesList = contactTags.map((e: ITag) => ({
      name: e.name,
      indeterminate: false,
    }));
  } else if (selectedContacts && tagsListValue) {
    tagsNamesList = [];
  }

  useEffect(() => {
    if (setListSelectedTagsNames && setListUnselectedGroupsNames) {
      setListSelectedTagsNames(selectedTagsNames);
      setListUnselectedGroupsNames(unselectedTagsNames);
    }
  }, [selectedTagsNames, unselectedTagsNames]);

  useEffect(() => {
    if (tagsNamesList) {
      setSelectedTagsNames(tagsNamesList);
      setUnselectedTagsNames(tagsNamesList);
    }
  }, [tagsNamesList.length]);

  const handleOnCreate = useCallback(
    async (value: string) => {
      setIsLoadingTag(true);
      const tagCreated = await createTag(value);
      setIsLoadingTag(false);
      if (tagCreated) onLastRefresh?.();
    },
    [createTag, onLastRefresh]
  );

  return (
    <FloatModal>
      <Modal show={showModal}>
        <div className="h-full p-8">
          <Flex column withGap gapSize="medium" itemAlignment="stretch">
            <Modal.Title onAction={() => setShowTagsModal && setShowTagsModal(false)}>
              <Flex withGap>
                <p>{t('CONTACT_TAG_MODAL.title')}</p>
                <p>({selectedTagsNames.length})</p>
              </Flex>
            </Modal.Title>
            <div className="visible-scrollbar max-h-[50vh] overflow-y-auto">
              <Modal.Body withBorder>
                <div className="w-[30vw] p-[5%]">
                  {isLoadingTagList ? (
                    <Spinner withoutOverlay />
                  ) : (
                    <ItemSearch
                      SearchPlaceHolder={t('CONTACT_TAG_MODAL.inputDescription')}
                      isLoading={isLoadingTag}
                      itemList={tags.map((tag) => tag.name)}
                      maxItems={maxTagsToAdd}
                      selectedItems={selectedTagsNames}
                      setSelectedItems={setSelectedTagsNames}
                      setUnselectedItems={setUnselectedTagsNames}
                      subTitle={t('CONTACT_TAG_MODAL.createTag')}
                      title={t('CONTACT_TAG_MODAL.chooseTag')}
                      onCreate={handleOnCreate}
                    />
                  )}
                </div>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <ButtonsSection
                isUpdateCheckboxModal={isUpdateCheckboxModal}
                selectedTagsNames={selectedTagsNames}
                setSelectedTags={setSelectedTags}
                setSelectedTagsNames={setSelectedTagsNames}
                setShowTagsModal={setShowTagsModal}
                setUnselectedTags={setUnselectedTags}
                tags={tags}
              />
            </Modal.Footer>
          </Flex>
        </div>
      </Modal>
    </FloatModal>
  );
};
