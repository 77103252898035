// cspell:disable

export const LosingEngagementIcon = () => {
  return (
    <svg height="52" viewBox="0 0 54.47 52" width="54.47" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 8173" id="Group_8173" transform="translate(-1002.75 -346.749)">
        <g data-name="Group 8180" id="Group_8180">
          <g data-name="Group 7953" id="Group_7953" transform="translate(1003 347)">
            <rect
              data-name="Rectangle 4375"
              fill="none"
              height="52"
              id="Rectangle_4375"
              transform="translate(-0.25 -0.251)"
              width="52"
            />
          </g>
          <g data-name="Group 8461" id="Group_8461">
            <g data-name="Group 7952" id="Group_7952" transform="translate(1006.084 349.07)">
              <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(29.314 31.342)">
                <path
                  d="M386.11,331.53a11.571,11.571,0,0,0,.714,1.667h0C386.585,332.721,386.348,332.007,386.11,331.53Z"
                  data-name="Trazado 3928"
                  fill="#2552a0"
                  id="Trazado_3928"
                  transform="translate(-386.11 -331.53)"
                />
              </g>
              <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(22.645 23.474)">
                <path
                  d="M315.57,248.307a.876.876,0,0,1,.714.238A.873.873,0,0,0,315.57,248.307Z"
                  data-name="Trazado 3930"
                  fill="#2552a0"
                  id="Trazado_3930"
                  transform="translate(-315.57 -248.301)"
                />
              </g>
              <g data-name="Grupo 6573" id="Grupo_6573" transform="translate(6.611 0)">
                <circle
                  cx="11.454"
                  cy="11.454"
                  data-name="Elipse 1742"
                  fill="#f6a658"
                  id="Elipse_1742"
                  r="11.454"
                />
              </g>
              <g data-name="Grupo 6574" id="Grupo_6574" transform="translate(0 22.708)">
                <path
                  d="M111.989,252.962a22.449,22.449,0,0,0-8.1-12.562,1.782,1.782,0,0,0-1.675,0,14.308,14.308,0,0,1-16.2,0,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h21.516C109.471,259.667,111.989,252.962,111.989,252.962Z"
                  data-name="Trazado 3933"
                  fill="#788f9a"
                  id="Trazado_3933"
                  transform="translate(-76.043 -240.19)"
                />
              </g>
              <path
                d="M157.424,0a11.454,11.454,0,0,0,0,22.909Z"
                data-name="Path 4758"
                fill="#f5b673"
                id="Path_4758"
                transform="translate(-139.359 0)"
              />
              <path
                d="M94.108,242.915a14.291,14.291,0,0,1-8.1-2.515,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h12.85Z"
                data-name="Path 4759"
                fill="#8fa3ad"
                id="Path_4759"
                transform="translate(-76.042 -217.482)"
              />
            </g>
            <g id="broken-link" transform="translate(1027.264 359.271)">
              <path
                d="M15.7,22.786a3.19,3.19,0,0,1-.908,1.929l-1.8,1.8a3.217,3.217,0,1,1-4.55-4.55l1.8-1.8a3.183,3.183,0,0,1,1.93-.908l2.213-2.213A5.695,5.695,0,0,0,8.48,18.4l-1.8,1.8a5.712,5.712,0,1,0,8.078,8.079l1.8-1.8a5.713,5.713,0,0,0,1.353-5.9ZM28.286,6.673a5.712,5.712,0,0,0-8.079,0l-1.8,1.8a5.713,5.713,0,0,0-1.353,5.9l2.21-2.21a3.19,3.19,0,0,1,.908-1.929l1.8-1.8a3.217,3.217,0,1,1,4.55,4.549l-1.8,1.8a3.183,3.183,0,0,1-1.93.908l-2.213,2.213a5.695,5.695,0,0,0,5.906-1.356l1.8-1.8A5.72,5.72,0,0,0,28.286,6.673Z"
                data-name="Path 5242"
                fill="#fc7e00"
                id="Path_5242"
              />
              <rect
                data-name="Rectangle 4427"
                fill="#fc7e00"
                height="6.762"
                id="Rectangle_4427"
                transform="translate(23.073 21.994) rotate(-60.929)"
                width="1.314"
              />
              <rect
                data-name="Rectangle 4428"
                fill="#fc7e00"
                height="6.762"
                id="Rectangle_4428"
                transform="translate(20.847 23.71) rotate(-29.05)"
                width="1.314"
              />
              <rect
                data-name="Rectangle 4429"
                fill="#fc7e00"
                height="6.762"
                id="Rectangle_4429"
                transform="translate(5.34 10.828) rotate(-60.948)"
                width="1.314"
              />
              <rect
                data-name="Rectangle 4430"
                fill="#fc7e00"
                height="6.762"
                id="Rectangle_4430"
                transform="translate(9.682 5.976) rotate(-29.05)"
                width="1.314"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
