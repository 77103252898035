export const StatisticsIcon = () => {
  return (
    <svg
      height="17.146"
      viewBox="0 0 17.146 17.146"
      width="17.146"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.146,20.146H3V18.24H20.146ZM7.763,9.668H3.953v6.668h3.81Zm5.715-3.81H9.668V16.335h3.81ZM19.193,3h-3.81V16.335h3.81Z"
        fill="#004dbc"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
