import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Text } from '@/lib/v2/components';

import useLinks, { ILink } from '@/src/modules/BackofficeModule/hooks/useLinks';

const SideLayouts = () => {
  const { links } = useLinks();

  const linksMapper = useCallback((link: ILink) => {
    return (
      <div key={link.name} id={`${link.name}-link`}>
        <Link to={link.to || '#'}>
          <Text
            className={(link.current ? 'bg-alert-info' : '') + ' mb-2 flex flex-col p-3'}
            fontWeight="bold"
          >
            {link.name}
          </Text>
        </Link>
        {link.childrenIsActive && (
          <div className={'m-auto flex w-4/5 flex-col'}>
            {Array.isArray(link?.children) &&
              link.children.map((item) => {
                const handleRedirect = item.onClick;
                return (
                  <Link key={item.name} to={item.to || '#'} onClick={handleRedirect}>
                    <Text className="flex flex-col py-1">{item.name}</Text>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
    );
  }, []);

  return (
    <div className="sticky top-0 flex h-[100vh] flex-col bg-white py-4 shadow-sm">
      <div className="flex w-64 flex-col">{links.map(linksMapper)}</div>
    </div>
  );
};

export default memo(SideLayouts);
