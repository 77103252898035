import { atom } from 'jotai';

import { Billing, BillingInfo, ContractDetail } from '@/src/modules/MyPlanModule/types';

export const atomContractDetail = atom<ContractDetail | undefined>(undefined);
atomContractDetail.debugLabel = 'atomContractDetail';

export const atomBillingInformation = atom<BillingInfo | undefined>(undefined);
atomBillingInformation.debugLabel = 'atomBillingInformation';

export const atomLastBill = atom<Billing | undefined>(undefined);
