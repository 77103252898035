import { atom } from 'jotai';

import { PlanOffer } from '@/src/infrastructure/interfaces/IResponses';
import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';
import { BillingDataPayment, SelectedPlanData } from '@/src/modules/MyPlanModule/types';

export const atomCurrentStep = atom<number>(1);
atomCurrentStep.debugLabel = 'atomCurrentStep';

export const atomPlansInformation = atom<PlanOffer[] | undefined>(undefined);
atomPlansInformation.debugLabel = 'atomPlansInformation';

export const atomSelectedPlanData = atom<SelectedPlanData>({
  planId: 'plan-free',
  planName: 'Free',
  selectedRanges: {
    rangeEmail: 0,
    rangeSms: 0,
    rangeOnsite: 0,
  },
});
atomSelectedPlanData.debugLabel = 'atomSelectedPlanData';

export const atomFrequency = atom<Frequency | undefined>(undefined);
atomFrequency.debugLabel = 'atomFrequency';

export const atomBillingFormLocal = atom<BillingDataPayment | undefined>(undefined);
atomBillingFormLocal.debugLabel = 'atomBillingFormLocal';

export const atomDowngradeIsPending = atom<boolean | undefined>(undefined);
atomDowngradeIsPending.debugLabel = 'atomDowngradeIsPending';
