export const SellIcon = () => {
  return (
    <svg height="20" id="check" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0H20V20H0Z" data-name="Path 3061" fill="none" id="Path_3061" />
      <g id="Icon" transform="translate(-367 -185)">
        <rect
          data-name="Rectangle 3518"
          fill="none"
          height="20"
          id="Rectangle_3518"
          transform="translate(367 185)"
          width="20"
        />
        <path
          d="M6.556,6.556h.009M6.556,3H11a1.769,1.769,0,0,1,1.257.521l6.222,6.222a1.778,1.778,0,0,1,0,2.514l-6.222,6.222a1.778,1.778,0,0,1-2.514,0L3.521,12.257A1.772,1.772,0,0,1,3,11V6.556A3.556,3.556,0,0,1,6.556,3Z"
          data-name="Path 3206"
          fill="none"
          id="Path_3206"
          stroke="#005adc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(366 184)"
        />
      </g>
    </svg>
  );
};
