import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface EmptyScreenTableProps {
  id?: string | number;
  image?: JSX.Element;
  title?: string;
  description?: string | JSX.Element;
  actionText?: string | JSX.Element;
  onClickAction?: () => void;
}

const EmptyScreenTable = ({
  id,
  image,
  title,
  description,
  actionText,
  onClickAction,
}: EmptyScreenTableProps) => {
  const ActionTextElement = () =>
    (typeof actionText === 'string' ? (
      <Text
        link
        as="a"
        className="ml-1"
        color="primary"
        fontWeight="medium"
        id={`${String(id)}-link`}
        variant="text"
        onClick={onClickAction}
      >
        {actionText}
      </Text>
    ) : (
      actionText
    )) ?? null;

  const DescriptionElement = () =>
    typeof description === 'string' ? (
      <Text className="text-center text-emblue-text" fontWeight="normal" variant="text">
        {description}
        <ActionTextElement />
      </Text>
    ) : (
      <>
        {description}
        <ActionTextElement />
      </>
    );

  return (
    <div className="mt-10 size-full p-12">
      <div className="flex flex-col items-center justify-center gap-2 py-10">
        {image ? image : null}
        <Text className=" text-emblue-tiles" fontWeight="normal" variant="title-1">
          {title}
        </Text>
        <div className="max-w-[682px]">
          <DescriptionElement />
        </div>
      </div>
    </div>
  );
};

export default memo(EmptyScreenTable);
