import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomHeaderCurrentStep,
  haveMessageAtom,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  EGroupAndListsTypeV1MapAlt,
  EStepHeaderAction,
  IEmailFormInfo,
  IEmailInfoPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IEmailFormHandleEventsProps {
  actionId: number;
}

export const useEmailFormSubmit = ({ actionId }: Partial<IEmailFormHandleEventsProps>) => {
  const navigate = useNavigate();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setHaveMessage = useSetAtom(haveMessageAtom);
  const setCurrentStep = useSetAtom(atomHeaderCurrentStep);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const { saveInfoAction } = useActionEmailData(Number(actionId));

  const handleSubmitData = useCallback(
    async (data: IEmailFormInfo) => {
      if (!actionInfoEmail?.content?.message) {
        setHaveMessage(true);
        return;
      }

      setProcessingAction(true);

      const { sender, subject, firstLine, recipients, touchRules } = data;
      const groups = recipients.map((item) => ({
        id: Number(item.id),
        type: EGroupAndListsTypeV1MapAlt[item.value as keyof typeof EGroupAndListsTypeV1MapAlt],
      }));

      const payload: IEmailInfoPayload = {
        senderId: Number(sender.id),
        subject: subject,
        firstLine: firstLine,
        touchRules: touchRules,
        groups: groups || [],
      };

      await saveInfoAction(payload);
      navigate('scheduling');
      setCurrentStep(EStepHeaderAction.MIDDLE);
      setProcessingAction(false);
    },
    [actionInfoEmail, navigate, saveInfoAction, setCurrentStep, setHaveMessage, setProcessingAction]
  );

  return {
    handleSubmitData,
  };
};
