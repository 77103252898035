export enum mixPanelEvents {
  GoToReportsButton = 'GoToReportsButton',
  SingleReportButton = 'SingleReportButton',
  StepperComplete = 'StepperComplete',
  CreateCampaignButton = 'CreateCampaignButton',
  CreateContactButton = 'CreateContactButton',
  CreateSmsButton = 'CreateSmsButton',
  CreateWidgetButton = 'CreateWidgetButton',
  IntegrationButton = 'IntegrationButton',
  SkipStep = 'SkipStep',
  ClickSender = 'ClickSender',
  ClickContacts = 'ClickContacts',
  ClickEmail = 'ClickEmail',
}
