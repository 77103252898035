import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { memo, useEffect } from 'react';

import { atomFiltersCatalog } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { CatalogEmails } from '@/src/modules/CampaignsModule/components/CatalogEmails';
import { CatalogFilters } from '@/src/modules/CampaignsModule/components/CatalogFilters';
import PreviewTemplate from '@/src/modules/CampaignsModule/components/PreviewTemplate';

export const CatalogTemplates = memo(() => {
  const [, setFilters] = useAtom(atomFiltersCatalog);

  useEffect(() => {
    return () => {
      setFilters(RESET);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row">
      <CatalogFilters />
      <CatalogEmails />
      <PreviewTemplate />
    </div>
  );
});
