export type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export interface IContactCustom {
  contactCustomFields: IContactCustomFields[];
}

export interface IContactCustomFields {
  id: number;
  number: number;
  name: string;
  value?: string | null;
  fieldType: number;
  dataType: number;
  optionType: number;
  defaultValues?: string | null;
  emblueField: boolean;
  readOnly: boolean;
}

export interface IContactFormCustomField {
  id: string;
  value: string;
}
export interface IContactFormField {
  field: string;
  value: string;
  fieldType: string;
}

export interface IContactFormResponse {
  statusOk: boolean;
  statusError: boolean;
}

export enum FieldType {
  NORMAL = 1,
  EXTENDED = 2,
}

export enum DataType {
  DATE = 1,
  NUMERIC = 2,
  ALPHANUMERIC = 3,
  EMAIL = 4,
  GENDER = 5,
  COUNTRY = 6,
}

export enum OptionType {
  NONE = 1,
  RADIO = 2,
  COMBO = 3,
}

export type TranslationECommerceKeys =
  | 'newCustomer'
  | 'newLead'
  | 'brandDefender'
  | 'loyalCustomer'
  | 'preInactiveCustomer'
  | 'needsAttention'
  | 'inactiveCustomer'
  | 'customerInRisk'
  | 'cantLoseIt';

export type TranslationsECommerce = Record<TranslationECommerceKeys, string>;

export type CustomFieldValue = { id: number } | Array<{ name: string }>;
