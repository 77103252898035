import classNames from 'classnames';
import { memo } from 'react';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { If } from '@/lib/v2/components/If';
import { LoadingIcon } from '@/lib/v2/icons/animated';
import { UserIcon } from '@/lib/v2/icons/solid';

import './PhonePreview.tailwind.css';

export interface IPhonePreviewProps {
  message: string;
  shortPhone?: boolean;
  loading?: boolean;
}

const PhonePreview = ({
  message = '',
  shortPhone = false,
  loading = false,
}: IPhonePreviewProps): JSX.Element => {
  const classesContainer = classNames(
    'h-full max-h-[600px] w-[310px] overflow-hidden border-[#737E96]',
    {
      'rounded-t-2xl border-x-[6px] border-b-0 border-t-[6px] pb-24': shortPhone,
      'rounded-2xl border-[6px]': !shortPhone,
    }
  );
  const classesMessage = classNames(
    'min-h-[118px] break-words rounded-xl bg-[#F4F5FA] p-4 text-left leading-tight text-[#364365]',
    { 'flex justify-center items-center': loading }
  );
  return (
    <div className="flex h-full items-center" data-testid="phone-preview-component">
      <div className={classesContainer}>
        <div className="relative flex flex-col items-center gap-2 bg-[#F4F5FA] pb-3 pt-[50px]">
          <div className="absolute inset-x-0 top-0 mx-auto h-[38px] w-[210px] rounded-b-lg bg-[#737E96]" />
          <IconSvg
            rounded
            bgColor="gray-light"
            fillColor="gray-dark"
            height="38px"
            svgComponent={<UserIcon />}
            width="38px"
            withPadding="small"
          />
          <div className="h-[11px] w-[152px] rounded-md bg-[#D9D9D9]" />
        </div>
        <div className="h-full bg-white px-5 pt-8">
          <p className={classesMessage}>
            <If condition={loading}>
              <LoadingIcon />
            </If>
            <If condition={!loading}>{message}</If>
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(PhonePreview);
