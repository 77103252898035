import { Route, Routes } from 'react-router-dom';

import InboxLayout from '@/src/modules/InboxModule/layouts/InboxLayout';
import InboxMainLayout from '@/src/modules/InboxModule/layouts/InboxMainLayout';
import InboxMain from '@/src/modules/InboxModule/screens/InboxMain/InboxMain';

Routes;
export const InboxModuleRouter = () => {
  return (
    <InboxMainLayout>
      <Routes>
        <Route element={<InboxLayout />} path="/">
          <Route index element={<InboxMain />} />
        </Route>
      </Routes>
    </InboxMainLayout>
  );
};
