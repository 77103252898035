import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = debounce(() => setDebouncedValue(value), delay);
    handler();
    return () => handler.cancel();
  }, [value, delay]);

  return debouncedValue;
}
