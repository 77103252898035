export const ContactsIcon = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#CFF6FE]">
      <svg height="55%" viewBox="0 0 65.604 69.187" width="55%" xmlns="http://www.w3.org/2000/svg">
        <g id="Group_7364" transform="translate(-0.001)">
          <g id="Group_7361" transform="translate(0 0)">
            <g id="Group_7359" transform="translate(10.601 0)">
              <circle
                cx="18.365"
                cy="18.365"
                fill="#11cffa"
                id="Ellipse_1742"
                r="18.365"
                transform="translate(0)"
              />
            </g>
            <g id="Group_7360" transform="translate(0 36.392)">
              <path
                d="M-1179.935,116.317s-2.687-12.99-12.989-20.156a2.856,2.856,0,0,0-2.687,0,22.941,22.941,0,0,1-25.979,0,2.856,2.856,0,0,0-2.688,0c-10.3,6.719-12.99,20.156-12.99,20.156s-2.687,10.75,8.063,10.75H-1188C-1177.247,127.067-1179.935,116.317-1179.935,116.317Z"
                fill="#11cffa"
                id="Path_3933"
                transform="translate(1237.567 -95.825)"
              />
            </g>
          </g>
          <path
            d="M12527.146,8651.967c9,9.664,7.752,22.408-1.1,30.438"
            fill="none"
            id="Path_4097"
            stroke="#11cffa"
            strokeLinecap="round"
            strokeWidth="3"
            transform="translate(-12480.165 -8648.386)"
          />
          <path
            d="M58.516.134c.885-.012,2.123.5,3.658,1.944,4.05,3.8,12.728,18.6,10.579,24.217-1.1,2.884-2.661,4.092-4.608,4.67"
            fill="none"
            id="Path_4098"
            stroke="#11cffa"
            strokeLinecap="round"
            strokeWidth="3"
            transform="translate(-8.998 36.357)"
          />
        </g>
      </svg>
    </div>
  );
};
