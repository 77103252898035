import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '@/lib/v2/organisms';

import {
  atomRefetchAllData,
  atomTotalSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDeleteActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { atomSelectedActionsRowsWithID } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { useRecurrentActions } from '@/modules/CampaignsModule/hooks/useRecurrentActions';

const DeleteRecurrentActions = () => {
  const { t } = useTranslation();
  const [toggleDeleteActionsModal, setToggleDeleteActionsModal] = useAtom(
    atomToggleDeleteActionsModal
  );

  const { deleteAction, loading } = useRecurrentActions();
  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);

  const actionId = useAtomValue(atomSelectedActionsRowsWithID);
  const handleConfirm = useCallback(async () => {
    await deleteAction(actionId);
    setToggleDeleteActionsModal(false);
    setRefetchAllData(true);
  }, [deleteAction, actionId, setToggleDeleteActionsModal, setRefetchAllData]);

  return (
    <ModalConfirmation
      isLoading={loading}
      isOpen={toggleDeleteActionsModal}
      textCancelButton={t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.cancelButton')}
      textConfirmButton={t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.successButton')}
      title={
        totalSelectedRows > 1
          ? t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.MULTIPLE.title')
          : t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.SINGLE.title')
      }
      onClose={setToggleDeleteActionsModal}
      onConfirmButton={handleConfirm}
    />
  );
};

export default DeleteRecurrentActions;
