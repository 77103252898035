import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConditionalOptionsByDataType,
  ConditionsOption,
} from '@/modules/ContactsModule/types/Segments';

// TO DO: translations
export const useGetListConditionalOptions = (dataType?: keyof ConditionalOptionsByDataType) => {
  const { t } = useTranslation();

  const conditionalOptions: ConditionsOption[] = useMemo(() => {
    const equalsConditions: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.equal'),
        value: 'equal',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.distinct'),
        value: 'distinct',
      },
    ];

    const containsConditions: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.contains'),
        value: 'contains',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.not-contains'),
        value: 'not-contains',
      },
      {
        id: 3,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.exactly'),
        value: 'exactly',
      },
    ];

    const dateConditions: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.eq'),
        value: 'eq',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.neq'),
        value: 'neq',
      },
      {
        id: 3,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.gte'),
        value: 'gte',
      },
      {
        id: 4,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.lte'),
        value: 'lte',
      },
      {
        id: 5,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.bt'),
        value: 'bt',
      },
      {
        id: 6,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.last-days'),
        value: 'last-days',
      },
    ];

    const numConditions: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.equal'),
        value: 'eq',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.distinct'),
        value: 'neq',
      },
      {
        id: 3,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.gte'),
        value: 'gte',
      },
      {
        id: 4,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.lte'),
        value: 'lte',
      },
      {
        id: 5,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.bt'),
        value: 'bt',
      },
    ];

    const activityConditions: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.withRegistered'),
        value: 'with',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.notRegistered'),
        value: 'without',
      },
    ];

    const equalsConditionsStringCustomU: ConditionsOption[] = [
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.equal'),
        value: 'contains',
      },
      {
        id: 2,
        name: t('SEGMENTS_CONDITIONS.CONDITIONS.distinct'),
        value: 'not-contains',
      },
    ];

    const conditionalOptionsByDataType: ConditionalOptionsByDataType = {
      ID: equalsConditions,
      EMAIL: containsConditions,
      STRING: containsConditions,
      GENDER: equalsConditions,
      DATE: dateConditions,
      NUMERIC: numConditions,
      ACTIVITY: activityConditions,
      COUNTRY: equalsConditions,
      COMBO: equalsConditionsStringCustomU,
      RADIO: equalsConditionsStringCustomU,
    };

    return dataType ? conditionalOptionsByDataType[dataType] : [];
  }, [dataType, t]);

  return conditionalOptions;
};
