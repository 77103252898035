import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';

export const useSignUpStepTwoOptions = () => {
  const { t } = useTranslation();

  const industryOptions = useMemo<Option[]>(() => {
    return [
      {
        id: 139,
        name: t('useSignUpStepTwoOptions.food'),
      },
      {
        id: 493,
        name: t('useSignUpStepTwoOptions.agencies'),
      },
      {
        id: 140,
        name: t('useSignUpStepTwoOptions.automotive'),
      },
      {
        id: 141,
        name: t('useSignUpStepTwoOptions.banking'),
      },
      {
        id: 142,
        name: t('useSignUpStepTwoOptions.beauty'),
      },
      {
        id: 145,
        name: t('useSignUpStepTwoOptions.entertainment'),
      },
      {
        id: 144,
        name: t('useSignUpStepTwoOptions.education'),
      },
      {
        id: 148,
        name: t('useSignUpStepTwoOptions.realEstate'),
      },
      {
        id: 147,
        name: t('useSignUpStepTwoOptions.computers'),
      },
      {
        id: 149,
        name: t('useSignUpStepTwoOptions.media'),
      },
      {
        id: 155,
        name: t('useSignUpStepTwoOptions.public'),
      },
      {
        id: 152,
        name: t('useSignUpStepTwoOptions.retail'),
      },
      {
        id: 539,
        name: t('useSignUpStepTwoOptions.sale'),
      },
      {
        id: 157,
        name: t('useSignUpStepTwoOptions.telecommunications'),
      },
      {
        id: 154,
        name: t('useSignUpStepTwoOptions.health'),
      },
      {
        id: 407,
        name: t('useSignUpStepTwoOptions.services'),
      },
      {
        id: 404,
        name: t('useSignUpStepTwoOptions.others'),
      },
      {
        id: 565,
        name: t('useSignUpStepTwoOptions.industry'),
      },
    ];
  }, [t]);

  const numberOfContactsOptions = useMemo<Option[]>(() => {
    return [
      {
        id: 550,
        name: t('useSignUpStepTwoOptions.ceroToThousand'),
      },
      {
        id: 551,
        name: t('useSignUpStepTwoOptions.thousandToTenThousand'),
      },
      {
        id: 552,
        name: t('useSignUpStepTwoOptions.fiftyThousand'),
      },
      {
        id: 553,
        name: t('useSignUpStepTwoOptions.moreFiftyThousand'),
      },
    ];
  }, [t]);

  const youUseEmblueOptions = useMemo<Option[]>(() => {
    return [
      {
        id: 575,
        name: t('useSignUpStepTwoOptions.emailMarketing'),
      },
      {
        id: 576,
        name: t('useSignUpStepTwoOptions.campaigns'),
      },
      {
        id: 577,
        name: t('useSignUpStepTwoOptions.channels'),
      },
    ];
  }, [t]);

  return { industryOptions, numberOfContactsOptions, youUseEmblueOptions };
};
