import { memo } from 'react';

export const Spinner = memo(({ withoutOverlay = false }: { withoutOverlay?: boolean }) =>
  withoutOverlay ? (
    <div className="cv-spinner">
      <span className="spinner"></span>
    </div>
  ) : (
    <div id="overlay">
      <div className="cv-spinner">
        <span className="spinner"></span>
      </div>
    </div>
  )
);
