import { useEffect, useState } from 'react';

import { Item, ItemListType } from '@/lib/components/ItemSearch/ItemSearch';

import { sortItemsByProperty } from '@/src/ContactsModule/utils/sortItemsByProperty';

export const useSortedItems = (
  selectedItems: ItemListType,
  itemList: ItemListType
): (Item | string)[] => {
  const [sortedItems, setSortedItems] = useState<(Item | string)[]>([]);

  useEffect(() => {
    let sorted: (Item | string)[] = [];

    if (typeof selectedItems[0] === 'string') {
      const selectedSorted = ((selectedItems as string[]) || []).map((name) => ({
        name,
        indeterminate: false,
      }));
      const unselectedSorted = (itemList as string[]).filter(
        (item) => !selectedItems.includes(item)
      );
      sorted = [...selectedSorted, ...unselectedSorted];
    } else {
      const selectedSorted = sortItemsByProperty((selectedItems as Item[]) || [], 'name');
      const unselectedSorted = sortItemsByProperty(
        (itemList as Item[]).filter(
          (item) =>
            !(selectedItems as Item[]).some((selectedItem) => selectedItem.name === item.name)
        ),
        'name'
      );
      sorted = [...selectedSorted, ...unselectedSorted];
    }

    // Remove duplicates
    const uniqueSortedItems = Array.from(
      new Set(sorted.map((item) => (typeof item === 'string' ? item : item.name)))
    )
      .map((name) => {
        return sorted.find((item) => (typeof item === 'string' ? item : item.name) === name);
      })
      .filter((item): item is Item | string => item !== undefined); // filter out undefined items

    setSortedItems(uniqueSortedItems);
  }, [selectedItems, itemList]);

  return sortedItems;
};
