export const CartIcon = () => {
  return (
    /* spell-checker: disable */
    <svg height="32" viewBox="0 0 36 36" width="32" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 7903" id="Grupo_7903" transform="translate(-0.171 0.296)">
        <rect
          data-name="Rectángulo 3180"
          fill="none"
          height="32"
          id="Rectángulo_3180"
          transform="translate(0.171 -0.296)"
          width="32"
        />
        <g id="shopping-cart-solid" transform="translate(3.138 3.038)">
          <path
            d="M7.363,13.19a2.993,2.993,0,1,0,2.993,2.993A3,3,0,0,0,7.363,13.19Z"
            data-name="Trazado 4646"
            fill="#18c678"
            id="Trazado_4646"
            transform="translate(3.369 10.169)"
          />
          <path
            d="M15,13.19h0A2.993,2.993,0,1,0,18,16.183,3,3,0,0,0,15,13.19Z"
            data-name="Trazado 4647"
            fill="#18c678"
            id="Trazado_4647"
            transform="translate(9.259 10.169)"
          />
          <path
            d="M8.483,6.145,7.261,0H0V3.542H4.356L7.367,18.577a4.225,4.225,0,0,0,4.126,3.4H23.571A4.2,4.2,0,0,0,27.7,18.559L30.053,6.145H8.483Z"
            data-name="Trazado 4648"
            fill="#18c678"
            id="Trazado_4648"
          />
        </g>
      </g>
    </svg>
  );
};
