export type ImageProps = {
  children?: JSX.Element | string;
  alt?: string;
  src: JSX.Element | string;
  size?: number | string;
};

export const RoundedImage = ({ src, size }: ImageProps) => {
  if (typeof src === 'string') {
    return (
      <img
        // TODO: all image HTML elements must have an alt value for accessibility (https://accessibility.psu.edu/images/imageshtml/)
        alt=""
        className="mx-auto h-32 w-32 overflow-hidden rounded-full"
        src={src}
        style={size ? { width: size, height: size } : {}}
      />
    );
  } else {
    return (
      <div
        className="mx-auto h-32 w-32 overflow-hidden rounded-full"
        style={size ? { width: size, height: size } : {}}
      >
        {src}
      </div>
    );
  }
};
