import { useEffect, useState } from 'react';

import useMessage from '@/src/compat/useMessage';

import { EventListenerType } from './enums/EventListenerEnum';
import { UserData } from './useUserDataInterface';
import { AppVersion, useVersion } from './useVersion';

export const useLanguage = ({ userData }: { userData: UserData; version: AppVersion }) => {
  const { version } = useVersion();
  const [language, setLanguage] = useState('en');

  /*
    Language for Onsite
        To perform the V2 translation inside the Onsite interface, the language is requested through a DOM variable,
        being in a different interface the Translation has no communication to make the change.
    */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onsiteEnabled = (window as any).__onsiteEnabled || false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onsiteLanguage = (window as any).__onsiteLanguage || 'en';

  useMessage(EventListenerType.Language, (_, event) => {
    setLanguage(event.data.value);
  });
  useEffect(() => {
    if (version === 'v2') {
      if (onsiteEnabled) {
        setLanguage(onsiteLanguage);
      } else {
        setLanguage(userData.language || 'en');
      }
    }
  }, [setLanguage, userData.language, version, onsiteEnabled, onsiteLanguage]);

  return { language };
};
