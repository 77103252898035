export const ActiveWidgetIcon = () => {
  return (
    <svg
      height="27.428"
      viewBox="0 0 23.825 27.428"
      width="23.825"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 0)">
        <g transform="translate(0)">
          <g transform="translate(5.355)">
            <path
              d="M200.621,66.474A2.413,2.413,0,0,0,198.2,64.9H196.79a5.573,5.573,0,0,0,.5-2.259,4.571,4.571,0,0,0-.07-.8,1.706,1.706,0,0,0-1.828-1.578,1.423,1.423,0,0,0-.167.02c-.42.05-.564.447-.914,1.415a13.568,13.568,0,0,1-1.261,2.783,4.672,4.672,0,0,1-1.8,1.475v-.077H186.6v6.91h4.651v-.38a5.75,5.75,0,0,1,1.245.017l.834.484.624.387a12.417,12.417,0,0,0,4.381.19,2.179,2.179,0,0,0,1.825-1.458C200.274,71.626,201.278,68.072,200.621,66.474Zm-1.338,5.3a1.278,1.278,0,0,1-1.078.8,13.068,13.068,0,0,1-3.817-.087l-.551-.347-1.051-.587a5.526,5.526,0,0,0-1.538-.057V66.945a5.739,5.739,0,0,0,2.573-1.965,14.394,14.394,0,0,0,1.355-2.973s.267-.667.334-.821c.464,0,.717.257.814.811a3.705,3.705,0,0,1,.053.637,4.982,4.982,0,0,1-.714,2.476l-.464.707h3a1.509,1.509,0,0,1,1.575,1.014C200.23,67.926,199.57,70.772,199.283,71.769Z"
              fill="#18c678"
              transform="translate(-186.6 -60.257)"
            />
          </g>
          <g data-name="Grupo 8485" id="Grupo_8485" transform="translate(0 16.897)">
            <path
              d="M189.166,541.928a4.163,4.163,0,1,1,0-8.326h13.293a4.163,4.163,0,1,1,0,8.326H189.166m0,1.1h13.293a5.269,5.269,0,0,0,5.266-5.266h0a5.269,5.269,0,0,0-5.266-5.266H189.166a5.269,5.269,0,0,0-5.266,5.266h0a5.269,5.269,0,0,0,5.266,5.266Z"
              fill="#18c678"
              transform="translate(-183.9 -532.5)"
            />
            <g data-name="Grupo 8484" id="Grupo_8484" transform="translate(15.383 2.228)">
              <ellipse cx="3.038" cy="3.038" fill="#18c678" rx="3.038" ry="3.038" />
            </g>
            <path
              d="M263.295,598.071a.563.563,0,0,1-.4-.165l-1.533-1.588a.55.55,0,1,1,.788-.766l1.119,1.158,1.924-2.167a.551.551,0,0,1,.822.733l-2.316,2.614a.556.556,0,0,1-.4.187C263.3,598.071,263.3,598.071,263.295,598.071Z"
              fill="#18c678"
              transform="translate(-256.949 -590.947)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
