import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { CreateCheckoutBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { ERRORS_PRORATION } from '@/src/infrastructure/Protocol/EmblueService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useProrationStripe = (body: CreateCheckoutBody, calculateProrations?: boolean) => {
  const service = useService();
  const [proration, setProration] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const getProration = async () => {
      if (!service || !calculateProrations) {
        setProration(undefined);
        return;
      }
      setIsLoading(true);
      const proration = await service.getProration(body);
      if (proration.success) {
        setProration(+proration.data);
        setIsLoading(false);
      } else {
        setProration(undefined);
        setIsLoading(false);
        if (proration.data === ERRORS_PRORATION.TRIAL) {
          setIsTrial(true);
          toast({
            variant: 'error',
            title: 'Error',
            body: t('useProrationStripe.bodyErrorTrial'),
          });
        }
      }
    };

    if (!isLoading) void getProration();
  }, []);

  return { prorationAmount: proration, isLoading, isTrial };
};
