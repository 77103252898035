export const PlusIcon = () => {
  return (
    <svg
      className="h-6 w-6"
      fill="none"
      stroke="#FFF"
      strokeWidth="1.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4.5v15m7.5-7.5h-15" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
