export const VimeoIcon = () => {
  return (
    <svg
      data-name="Layer 2"
      height="61.8"
      id="Layer_2"
      viewBox="0 0 61.801 61.8"
      width="61.801"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 1" id="Layer_1">
        <rect
          data-name="Rectangle 3825"
          fill="#23b6ea"
          height="61.8"
          id="Rectangle_3825"
          rx="6.48"
          width="61.8"
        />
        <path
          d="M38.766,14.423c-.278,5.951-11.153,21.064-17.446,21.064q-2.783,0-4.688-5.116C14.191,21.38,13.1,15.857,11,15.857a9.8,9.8,0,0,0-2.483,1.5l-1.5-1.927C11,11.94,14.213,8.643,16.353,8.451q3.682-.45,4.538,5.031c.728,4.624,1.8,12.095,3.639,12.095,1.434,0,4.945-5.8,5.116-7.9.321-3-2.141-3.168-4.5-2.141Q27.506,7.873,34.142,8q4.9.15,4.624,6.422Z"
          data-name="Path 3448"
          fill="#fff"
          id="Path_3448"
          transform="translate(8.007 9.124)"
        />
      </g>
    </svg>
  );
};
