export const FileWithLine = () => {
  return (
    <svg height="32" viewBox="0 0 24 32" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4,6A4,4,0,0,1,8,2h9.172A4,4,0,0,1,20,3.171L26.828,10A4,4,0,0,1,28,12.828V30a4,4,0,0,1-4,4H8a4,4,0,0,1-4-4ZM8,18a2,2,0,0,1,2-2H22a2,2,0,1,1,0,4H10A2,2,0,0,1,8,18Zm2,6a2,2,0,1,0,0,4H22a2,2,0,0,0,0-4Z"
        fill="#005adc"
        fillRule="evenodd"
        id="Path_3330"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};
