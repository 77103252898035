export const CodeIcon = () => {
  return (
    <svg
      height="21.01"
      id="check"
      viewBox="0 0 21.01 21.01"
      width="21.01"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,0H21.01V21.01H0Z" fill="none" />
      <path
        d="M22.563,10.109a.807.807,0,0,0-.82-.6A2.152,2.152,0,0,1,19.508,8.4a2.264,2.264,0,0,1,.149-2.459.7.7,0,0,0-.075-.969A10.179,10.179,0,0,0,15.41,2.584a.76.76,0,0,0-.894.447,2.293,2.293,0,0,1-4.172,0,.694.694,0,0,0-.894-.447A9.562,9.562,0,0,0,5.278,5.043a.7.7,0,0,0-.075.969A2.272,2.272,0,0,1,5.353,8.47,2.406,2.406,0,0,1,3.118,9.587a.738.738,0,0,0-.82.6A8.371,8.371,0,0,0,2,12.567a9.255,9.255,0,0,0,.3,2.459.686.686,0,0,0,.82.522,2.152,2.152,0,0,1,2.235,1.118A2.264,2.264,0,0,1,5.2,19.123a.7.7,0,0,0,.075.969,10.609,10.609,0,0,0,4.247,2.459.76.76,0,0,0,.894-.447,2.293,2.293,0,0,1,4.172,0,.716.716,0,0,0,.671.447h.224a10.609,10.609,0,0,0,4.247-2.459.7.7,0,0,0,.075-.969,2.272,2.272,0,0,1-.149-2.459,2.406,2.406,0,0,1,2.235-1.118.738.738,0,0,0,.82-.6,9.255,9.255,0,0,0,.3-2.459A8.967,8.967,0,0,0,22.563,10.109ZM9.227,14.281a.72.72,0,0,1,0,1.043.72.72,0,0,1-1.043,0L5.949,13.089a.72.72,0,0,1,0-1.043L8.184,9.811a.738.738,0,1,1,1.043,1.043L7.513,12.567Zm5.364-4.4-2.98,5.96a.716.716,0,0,1-.671.447c-.149,0-.224,0-.3-.075a.658.658,0,0,1-.3-.969l2.98-5.96a.658.658,0,0,1,.969-.3A.717.717,0,0,1,14.591,9.885Zm4.321,3.2-2.235,2.235a.72.72,0,0,1-1.043,0,.72.72,0,0,1,0-1.043l1.714-1.714-1.714-1.714a.738.738,0,0,1,1.043-1.043l2.235,2.235A.72.72,0,0,1,18.912,13.089Z"
        fill="#004dbc"
        transform="translate(-2 -2.048)"
      />
    </svg>
  );
};
