import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* cspell:disable */
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import tagsGrayscale from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/tagsGrayscale.svg';
import tagsRemarketing from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/tagsRemarketing.svg';
import tagsStatic from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/tagsStatic.svg';
import { truncateString } from '@/src/modules/CampaignsModule/components/HtmlUpload/util/stringsUtils';

export const useRemarketingTags = () => {
  const { t } = useTranslation(['editorHtml']);

  const [remarketingTags, setRemarketingTags] = useState<Array<string[]>>([]);

  const serviceV1 = useService();

  const getShippingTags = useCallback(async () => {
    const response = await serviceV1.getV1ShippingTags();
    return response.statusOK ? response.tags : [];
  }, [serviceV1]);

  useEffect(() => {
    const fetchDataButtons = async () => {
      const shippingTagsResult = await getShippingTags();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { staticTags, rulesTags }: any = shippingTagsResult;
      setRemarketingTags([...staticTags, ...rulesTags]);
    };

    void fetchDataButtons();
  }, [getShippingTags]);

  const remarketingTagsList = remarketingTags
    .map((tag: any) => {
      return { id: tag.tagId, name: tag.name, color: tag.color || '#044C8A' };
    })
    .filter((tag) => tag.name !== 'mas reciente');

  const remarketingTagsPopup =
    Array.isArray(remarketingTagsList) && remarketingTagsList.length > 0
      ? `
    <div class='flex w-550 font-sm'>
        <div class='w-1/2 p-1'>
            <div class='w-full h-16 bg-gray-100 rounded flex justify-left items-center px-3'>
                <div class='flex justify-center items-center mr-2'>
                    <img src='${tagsRemarketing}'>
                </div>
                <div>
                    <h2 class='font-base font-medium'> ${t('TAGS.remarketingTags')} </h2>
                    <span class='font-xss'> ${t('TAGS.remarketingTagsSubtitle')} </span>
                </div>
            </div>
            <div class='w-full'>
            <ul class='divide-y'>
            ${remarketingTagsList
              .map(
                (tag, index) => `
                <li
                class="remarketingTag h-9 px-2 hover:bg-gray-100 cursor-pointer flex items-center"
                    id="${index + 1}"
                    data-type="remarketing"
                >
                    <span class='text-bluePrimary font-bold mr-2'> ${index + 1}º </span><span>${t(
                  'TAGS.recent'
                )}</span>
                </li>`
              )
              .join('')}
            </ul>
        </div>
        </div>
        <div class='w-1/2 p-1'>
        <div class='w-full h-16 bg-gray-100 rounded flex justify-left items-center px-3'>
                <div class='flex justify-center items-center mr-2'>
                    <img src='${tagsStatic}'>
                </div>
                <div>
                    <h2 class='font-base font-medium'> ${t('TAGS.staticTags')} </h2>
                    <span class='font-xss'> ${t('TAGS.staticTagsSubtitle')} </span>
                </div>
            </div>
            <div class='w-full'>
                <ul class='divide-y'>
                ${remarketingTagsList
                  .map(
                    (tag) => `
                    <li
                        class="staticTag h-9 px-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        style="color: ${tag.color};"
                        id="${tag.id}"
                        data-type="static"
                    >
                    <img src='${tagsGrayscale}' class='mr-2'/>
                    <span> ${truncateString(tag.name, 12)} </span>

                    </li>`
                  )
                  .join('')}
                </ul>
            </div>
        </div>
    </div>
    `
      : `<p><em> ${t('TAGS.noContentTags')} </em></p>`;

  return remarketingTagsPopup;
};
