/* cSpell:disable */

type ECommerceProps = {
  tableSize?: boolean;
  secondary?: boolean;
};

export const ECommerceIcon = ({ tableSize, secondary }: ECommerceProps) => {
  return (
    <>
      {
        <svg
          height={tableSize ? 19 : 18}
          viewBox="0 0 17.27 16.575"
          width={tableSize ? 19 : 18}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            data-name="Icon feather-shopping-cart"
            id="Icon_feather-shopping-cart"
            transform="translate(-0.5 -0.5)"
          >
            <path
              d="M13.388,30.694A.694.694,0,1,1,12.694,30,.694.694,0,0,1,13.388,30.694Z"
              data-name="Path 4267"
              fill="none"
              id="Path_4267"
              stroke={secondary ? '#96A6CE' : '#004dbc'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-5.642 -15.313)"
            />
            <path
              d="M29.888,30.694A.694.694,0,1,1,29.194,30,.694.694,0,0,1,29.888,30.694Z"
              data-name="Path 4268"
              fill="none"
              id="Path_4268"
              stroke={secondary ? '#96A6CE' : '#004dbc'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-14.507 -15.313)"
            />
            <path
              d="M1.5,1.5H4.276l1.86,9.294a1.388,1.388,0,0,0,1.388,1.117h6.746a1.388,1.388,0,0,0,1.388-1.117L16.77,4.97H4.97"
              data-name="Path 4269"
              fill="none"
              id="Path_4269"
              stroke={secondary ? '#96A6CE' : '#004dbc'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      }
    </>
  );
};
