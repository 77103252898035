import { useAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputSearch } from '@/lib/v2/components';
import Accordion2, { IOptionAccordion } from '@/lib/v2/components/Accordion/Accordion2';

import { ICategory, ICollection } from '@/src/ContactsModule/interfaces/Interfaces';
import {
  atomFiltersCollectionsSelected,
  IFiltersTemplateCatalog,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { useCatalogFilters } from '@/src/modules/CampaignsModule/hooks/useCatalogFilters';

export type ICategoryFilter = ICategory & {
  collections: IOptionAccordion[];
};

interface IFilters {
  categories: ICategory[] | null;
  collections: ICollection[] | null;
  filters: IFiltersTemplateCatalog;
}

const Filters = memo(({ categories, collections, filters }: IFilters) => {
  const { t } = useTranslation();
  const data = useMemo(() => {
    const result: ICategoryFilter[] = [];

    if (Array.isArray(categories) && Array.isArray(collections))
      for (const category of categories) {
        const children =
          collections
            ?.filter((item) => item.parentId === category.id)
            .map(({ id, name }) => ({
              id: typeof id === 'number' ? id : parseInt(id),
              name: t('CATALOG_TEMPLATES_COLLECTIONS.' + name),
            }))
            .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
        result.push({
          ...category,
          collections: children,
        });
      }

    return result.sort((a, b) => a.name.localeCompare(b.name));
  }, [categories, collections, t]);
  const [collectionsSelected, setCollectionsSelected] = useAtom(atomFiltersCollectionsSelected);

  const loading = !Array.isArray(collections);

  const handleUpdateCollections = useCallback(
    (categoryName: string, selected: IOptionAccordion[]) => {
      const result = {
        ...collectionsSelected,
        [categoryName]: selected,
      };
      setCollectionsSelected(result);
    },
    [collectionsSelected, setCollectionsSelected]
  );

  const categoriesMapping = (category: ICategoryFilter) => {
    const selected = category.collections.filter((item) => {
      const include = filters?.collections?.find((row) => row.id === item.id);
      return include;
    });
    return (
      <Accordion2
        items={category.collections ?? []}
        loading={loading}
        selected={selected ?? []}
        title={t('CATALOG_TEMPLATES_CATEGORIES.' + category.name)}
        onChange={(values) => handleUpdateCollections(category.name, values)}
      />
    );
  };

  return (
    <div className="mb-6 flex flex-col">{Array.isArray(data) && data.map(categoriesMapping)}</div>
  );
});

export const CatalogFilters = memo(() => {
  const { updateFilters, filters, categories, collections } = useCatalogFilters();
  const { t } = useTranslation();

  const handleUpdateSearch = useCallback(
    (value: string) => updateFilters('search', value),
    [updateFilters]
  );

  return (
    <div className="visible-scroll sticky top-0 mr-6 flex h-[100vh] w-full max-w-80 flex-col overflow-y-auto bg-white p-6">
      {/* Filter: Search  */}
      <div className="mb-6 flex flex-col">
        <InputSearch
          placeHolder={t('BEEFREE_TEMPLATES_GALLERY.searchPlaceholder')}
          value={filters?.search || ''}
          onChange={(e) => handleUpdateSearch(e.target.value)}
          onClear={() => handleUpdateSearch('')}
        />
      </div>

      {/* Filter: Category's  */}
      <div className="mb-6 flex flex-col">
        <Filters categories={categories} collections={collections} filters={filters} />
      </div>
    </div>
  );
});
