import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { atomFreeTrial } from '@/src/atoms/FreeTrial';
import { useLastReports } from '@/src/infrastructure/services/useLastReports';
import {
  INIT_ONBOARDING_NEW_SS,
  INIT_ONBOARDING_OLD_SS,
  ONBOARDING_NEW_FINISHED,
  ONBOARDING_OLD_FINISHED,
} from '@/src/modules/OnboardingModule/constants';
import { DashboardContainer } from '@/src/presentation/screens/DashBoard';
import MainContainer from '@/src/presentation/screens/MainContainer';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';

import Onboarding from './Onboarding';

interface HomeProps {
  userData: UserData;
}

const Home = ({ userData }: HomeProps) => {
  const [lastReports] = useLastReports({});
  const [freeTrialBlockState] = useAtom<IFreeTrialDemo>(atomFreeTrial);

  const noHasMessageSending = lastReports && lastReports?.length < 1;

  if (noHasMessageSending) {
    /** true if companyId is even, false in odd case */
    const isShowNewOnboarding = Number(userData.companyId) % 2 === 0;

    if (isShowNewOnboarding) return <Onboarding counter={freeTrialBlockState} />;
    return <DashboardContainer counter={freeTrialBlockState} userData={userData} />;
  } else {
    if (noHasMessageSending === false) {
      // only addActions when lastReports request finished
      const wasNewOnboarding = localStorage.getItem(INIT_ONBOARDING_NEW_SS);
      const wasOldOnboarding = localStorage.getItem(INIT_ONBOARDING_OLD_SS);
      if (wasNewOnboarding) {
        datadogRum.addAction(ONBOARDING_NEW_FINISHED, {
          email: userData.email,
        });
        localStorage.removeItem(INIT_ONBOARDING_NEW_SS);
      } else if (wasOldOnboarding) {
        datadogRum.addAction(ONBOARDING_OLD_FINISHED, {
          email: userData.email,
        });
        localStorage.removeItem(INIT_ONBOARDING_OLD_SS);
      }
    }

    return (
      <MainContainer counter={freeTrialBlockState} reports={lastReports} userData={userData} />
    );
  }
};

export default Home;
