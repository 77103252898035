import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTablePagination } from '@/lib/components/Table/useTablePagination';

import { useSearchParamsState } from '@/src/application/hooks/useSearchParamsState';
import { useURLParams } from '@/src/modules/ContactsModule/hooks/useURLParams';
import { useGetContext } from '@/src/utils/ContextUtils';

export interface ContactStateContext {
  searchQuery: string | undefined;
  tagsFilter: number[];
  groupsFilter: number[];
  statusFilter: string[];
  happinessFilter: string[];
  discardedFilter: string[];
  lastPageViewed: number;
  lastRefresh: number;
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  gotoPageIndex: number;
}

interface ContactsMutationContext {
  setSearchQuery: (newState: string) => void | null;
  setTagsFilter: (newState: number[]) => void | null;
  setGroupsFilter: (newState: number[]) => void | null;
  setStatusFilter: (newState: string[]) => void | null;
  setHappinessFilter: (newState: string[]) => void | null;
  setDiscardedFilter: (newState: string[]) => void | null;
  setLastRefresh: Dispatch<SetStateAction<number>>;
  setLastPageViewed: Dispatch<SetStateAction<number>>;
  setListTotalPagination: Dispatch<SetStateAction<number>>;
  changeTablePage: ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => void;
  resetPagination: () => void;
  resetFilters: () => void;
}

export const StateContactsContext = createContext<ContactStateContext | undefined>(undefined);
export const MutationContactsContext = createContext<ContactsMutationContext | undefined>(
  undefined
);

interface ContactsProviderProps {
  children: ReactNode;
}

const ContactsProvider: FC<ContactsProviderProps> = ({ children }): JSX.Element => {
  const { selectedTags, selectedGroups, selectedStatus } = useURLParams();
  const [listTotalPagination, setListTotalPagination] = useState(0);

  const [searchQuery, setSearchQuery] = useSearchParamsState<string>('filterSearch', '');
  const [tagsFilter, setTagsFilter] = useSearchParamsState<number[]>(
    'filterTags',
    selectedTags || []
  );
  const [groupsFilter, setGroupsFilter] = useSearchParamsState<number[]>(
    'filterGroups',
    selectedGroups || []
  );
  const [statusFilter, setStatusFilter] = useSearchParamsState<string[]>(
    'filterStatus',
    selectedStatus || []
  );
  const [discardedFilter, setDiscardedFilter] = useSearchParamsState<string[]>('filterStatus', []);
  const [happinessFilter, setHappinessFilter] = useSearchParamsState<string[]>(
    'filterHappiness',
    []
  );

  const [lastRefresh, setLastRefresh] = useState<number>(0);

  const {
    pageSize,
    pageIndex,
    totalPages,
    gotoPageIndex,
    changeTablePage,
    resetPagination,
    lastPageViewed,
    setLastPageViewed,
  } = useTablePagination(listTotalPagination);

  const resetFilters = useCallback(() => {
    setSearchQuery('');
    setTagsFilter([]);
    setGroupsFilter([]);
    setStatusFilter([]);
    setDiscardedFilter([]);
    setHappinessFilter([]);
  }, [
    setDiscardedFilter,
    setGroupsFilter,
    setHappinessFilter,
    setSearchQuery,
    setStatusFilter,
    setTagsFilter,
  ]);

  const memoizedMutations = useMemo<ContactsMutationContext>(
    () => ({
      setSearchQuery,
      setTagsFilter,
      setGroupsFilter,
      setStatusFilter,
      setHappinessFilter,
      setDiscardedFilter,
      setLastRefresh,
      changeTablePage,
      setListTotalPagination,
      setLastPageViewed,
      resetPagination,
      resetFilters,
    }),
    [
      setSearchQuery,
      setTagsFilter,
      setGroupsFilter,
      setStatusFilter,
      setHappinessFilter,
      setDiscardedFilter,
      setListTotalPagination,
      setLastPageViewed,
      changeTablePage,
      resetPagination,
      resetFilters,
    ]
  );

  const stringQuery = `${searchQuery}-${JSON.stringify(tagsFilter)}-${JSON.stringify(
    groupsFilter
  )}-${JSON.stringify(statusFilter)}-${JSON.stringify(discardedFilter)}-${JSON.stringify(
    happinessFilter
  )}`;

  useEffect(() => {
    if (lastPageViewed > 0) return;
    resetPagination();
  }, [stringQuery, resetPagination, lastPageViewed]);

  return (
    <MutationContactsContext.Provider value={memoizedMutations}>
      <StateContactsContext.Provider
        value={{
          searchQuery,
          tagsFilter,
          groupsFilter,
          statusFilter,
          discardedFilter,
          happinessFilter,
          lastPageViewed,
          lastRefresh,
          pageSize,
          pageIndex,
          totalPages,
          gotoPageIndex,
        }}
      >
        {children}
      </StateContactsContext.Provider>
    </MutationContactsContext.Provider>
  );
};

export const useStateContactsContext = (): ContactStateContext =>
  useGetContext<ContactStateContext>(StateContactsContext, 'ContactsProvider');
export const useMutationContactsContext = (): ContactsMutationContext =>
  useGetContext<ContactsMutationContext>(MutationContactsContext, 'ContactsProvider');

export default ContactsProvider;
