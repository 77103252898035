import { IContactActivity } from '@/src/ContactsModule/interfaces/Interfaces';
/* cspell:disable */

export const ActivityChannelMock: IContactActivity[] = [
  {
    reportId: 421751,
    date: new Date('2021-12-16T14:04:27'),
    activityType: 8,
    actionName: '#emBlueTraining - Pausas activas - Envio ES HOY',
    campaignName: 'Webinars 2021',
    channel: 1,
  },
  {
    reportId: 11010012,
    date: new Date('2021-12-16T13:58:24'),
    activityType: 1,
    actionName: '#emBlueTraining - Pausas activas - Envio ES HOY',
    campaignName: 'Webinars 2021',
    channel: 1,
  },
  {
    reportId: 4366,
    date: new Date('2021-12-14T18:45:25'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4331,
    date: new Date('2021-12-13T15:03:30'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4318,
    date: new Date('2021-12-12T12:47:50'),
    activityType: 12,
    actionName: ' [ingles] -2021 NPS Oficial by Vero Ortega [replicado]',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4256,
    date: new Date('2021-12-09T14:47:56'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4250,
    date: new Date('2021-12-09T11:50:37'),
    activityType: 12,
    actionName: ' [ingles] -2021 NPS Oficial by Vero Ortega [replicado]',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4239,
    date: new Date('2021-12-08T17:05:23'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4235,
    date: new Date('2021-12-08T14:19:36'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4193,
    date: new Date('2021-12-06T16:29:02'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4162,
    date: new Date('2021-12-03T20:36:15'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4147,
    date: new Date('2021-12-03T14:25:08'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4121,
    date: new Date('2021-12-02T15:24:58'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4089,
    date: new Date('2021-12-01T15:48:52'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4060,
    date: new Date('2021-11-30T15:42:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4038,
    date: new Date('2021-11-26T23:49:17'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 4012,
    date: new Date('2021-11-25T14:35:20'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3953,
    date: new Date('2021-11-24T11:20:10'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3950,
    date: new Date('2021-11-24T05:00:07'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 420170,
    date: new Date('2021-11-23T18:18:32'),
    activityType: 8,
    actionName: 'EventoOnline_emBlue_Refuerzo3',
    campaignName: 'Plantillas-Customer-Success',
    channel: 1,
  },
  {
    reportId: 10947222,
    date: new Date('2021-11-23T18:17:54'),
    activityType: 1,
    actionName: 'EventoOnline_emBlue_Refuerzo3',
    campaignName: 'Plantillas-Customer-Success',
    channel: 1,
  },
  {
    reportId: 3909,
    date: new Date('2021-11-23T14:06:01'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3853,
    date: new Date('2021-11-19T20:02:06'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3822,
    date: new Date('2021-11-18T20:12:46'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 417161,
    date: new Date('2021-11-17T15:11:20'),
    activityType: 8,
    actionName: '#emBlueTalks-Warketing-EsHoy',
    campaignName: 'Webinars 2021',
    channel: 1,
  },
  {
    reportId: 10929485,
    date: new Date('2021-11-17T15:06:57'),
    activityType: 1,
    actionName: '#emBlueTalks-Warketing-EsHoy',
    campaignName: 'Webinars 2021',
    channel: 1,
  },
  {
    reportId: 3737,
    date: new Date('2021-11-17T03:07:34'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3596,
    date: new Date('2021-11-11T13:17:37'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3547,
    date: new Date('2021-11-09T12:44:55'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3478,
    date: new Date('2021-11-04T22:50:15'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3454,
    date: new Date('2021-11-04T13:26:59'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3405,
    date: new Date('2021-11-02T19:32:25'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3380,
    date: new Date('2021-11-02T12:56:51'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3373,
    date: new Date('2021-11-02T10:30:20'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3362,
    date: new Date('2021-11-01T18:02:42'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3320,
    date: new Date('2021-10-29T17:29:24'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3280,
    date: new Date('2021-10-28T16:16:51'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3241,
    date: new Date('2021-10-27T17:40:09'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3203,
    date: new Date('2021-10-26T18:52:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3180,
    date: new Date('2021-10-26T01:39:00'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3178,
    date: new Date('2021-10-26T00:05:40'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3148,
    date: new Date('2021-10-25T14:20:48'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3133,
    date: new Date('2021-10-24T15:25:06'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3099,
    date: new Date('2021-10-22T13:58:14'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3081,
    date: new Date('2021-10-21T19:53:40'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3063,
    date: new Date('2021-10-21T14:57:16'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3061,
    date: new Date('2021-10-21T13:49:35'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3060,
    date: new Date('2021-10-21T13:27:57'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 3026,
    date: new Date('2021-10-19T22:27:53'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2933,
    date: new Date('2021-10-14T18:37:10'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2923,
    date: new Date('2021-10-14T14:16:58'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2918,
    date: new Date('2021-10-14T12:26:45'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2905,
    date: new Date('2021-10-13T20:31:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2904,
    date: new Date('2021-10-13T19:39:54'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2861,
    date: new Date('2021-10-12T13:33:21'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2831,
    date: new Date('2021-10-11T14:31:44'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2823,
    date: new Date('2021-10-09T17:11:54'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2804,
    date: new Date('2021-10-08T13:38:18'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2797,
    date: new Date('2021-10-07T19:12:58'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2668,
    date: new Date('2021-10-04T14:50:49'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2623,
    date: new Date('2021-10-01T19:26:11'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2609,
    date: new Date('2021-10-01T15:32:31'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2544,
    date: new Date('2021-09-30T14:15:20'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2523,
    date: new Date('2021-09-29T19:12:42'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2501,
    date: new Date('2021-09-29T15:20:05'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2475,
    date: new Date('2021-09-28T19:03:17'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2416,
    date: new Date('2021-09-27T03:03:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2411,
    date: new Date('2021-09-26T15:43:51'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2359,
    date: new Date('2021-09-23T14:49:41'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2304,
    date: new Date('2021-09-22T12:40:31'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2271,
    date: new Date('2021-09-21T12:18:11'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2166,
    date: new Date('2021-09-16T19:04:42'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2112,
    date: new Date('2021-09-15T16:38:29'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 2098,
    date: new Date('2021-09-15T14:02:56'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1862,
    date: new Date('2021-09-07T22:04:01'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1760,
    date: new Date('2021-09-05T23:05:00'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1752,
    date: new Date('2021-09-05T02:17:07'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1748,
    date: new Date('2021-09-04T16:22:12'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1741,
    date: new Date('2021-09-03T21:41:14'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1631,
    date: new Date('2021-09-02T10:50:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1518,
    date: new Date('2021-08-28T13:46:38'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1515,
    date: new Date('2021-08-27T21:55:32'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1514,
    date: new Date('2021-08-27T21:53:36'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1513,
    date: new Date('2021-08-27T21:48:02'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1512,
    date: new Date('2021-08-27T21:30:29'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1511,
    date: new Date('2021-08-27T21:27:48'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1510,
    date: new Date('2021-08-27T21:27:26'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1509,
    date: new Date('2021-08-27T21:23:15'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1508,
    date: new Date('2021-08-27T21:20:35'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1507,
    date: new Date('2021-08-27T21:18:05'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1506,
    date: new Date('2021-08-27T21:13:38'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1505,
    date: new Date('2021-08-27T21:12:06'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1504,
    date: new Date('2021-08-27T21:08:51'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1503,
    date: new Date('2021-08-27T21:05:34'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1502,
    date: new Date('2021-08-27T20:58:43'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1501,
    date: new Date('2021-08-27T20:57:08'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1500,
    date: new Date('2021-08-27T20:56:43'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1499,
    date: new Date('2021-08-27T20:56:17'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1498,
    date: new Date('2021-08-27T20:52:43'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1497,
    date: new Date('2021-08-27T20:51:14'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1495,
    date: new Date('2021-08-27T20:47:03'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1492,
    date: new Date('2021-08-27T20:45:23'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1481,
    date: new Date('2021-08-27T20:40:15'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1479,
    date: new Date('2021-08-27T20:39:54'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1471,
    date: new Date('2021-08-27T20:36:57'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1474,
    date: new Date('2021-08-27T20:36:56'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1476,
    date: new Date('2021-08-27T20:36:49'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1467,
    date: new Date('2021-08-27T20:34:17'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1454,
    date: new Date('2021-08-27T20:25:00'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1452,
    date: new Date('2021-08-27T20:23:59'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1445,
    date: new Date('2021-08-27T20:17:07'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1441,
    date: new Date('2021-08-27T20:13:55'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1436,
    date: new Date('2021-08-27T20:08:32'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1423,
    date: new Date('2021-08-27T15:00:15'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1418,
    date: new Date('2021-08-26T23:18:33'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1404,
    date: new Date('2021-08-26T16:44:53'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1398,
    date: new Date('2021-08-26T15:03:44'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1356,
    date: new Date('2021-08-24T21:47:53'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1354,
    date: new Date('2021-08-24T04:38:43'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
  {
    reportId: 1342,
    date: new Date('2021-08-19T21:50:21'),
    activityType: 12,
    actionName: '2021 NPS Oficial by Vero Ortega',
    campaignName: '',
    channel: 10,
  },
];
