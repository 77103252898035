export const RestorePageIcon = () => {
  return (
    <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8ZM12,18a5,5,0,0,1-4.58-3H9.13a3.508,3.508,0,1,0-.23-3.6L10.5,13h-4V9l1.3,1.3A5,5,0,1,1,12,18Z"
        fill="#004dbc"
        id="restore-page-svgrepo-com"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};
