import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';

import { atomReadyToStartImport } from '@/src/modules/ContactsModule/atoms/Step1';
import { atomSelectedGroups, atomStep4CreateGroup } from '@/src/modules/ContactsModule/atoms/Step4';

import { useResetFile } from './useResetFile';

import {
  atomDetailList,
  atomFileName,
  atomFiles,
  atomIdProcess,
  atomShowProgressNotification,
} from '@/modules/ContactsModule/atoms/Steps';

export const useResetImport = () => {
  const setFileName = useSetAtom(atomFileName);
  const setFiles = useSetAtom(atomFiles);
  const setReadyToStartImport = useSetAtom(atomReadyToStartImport);
  const setSelectedGroups = useSetAtom(atomSelectedGroups);
  const { resetStep1Loader } = useResetFile();
  const setIdProcess = useSetAtom(atomIdProcess);
  const setShowProgressNotification = useSetAtom(atomShowProgressNotification);
  const setCreateGroup = useSetAtom(atomStep4CreateGroup);
  const setResetDetailList = useSetAtom(atomDetailList);

  const resetImport = useCallback(() => {
    setFileName(RESET);
    setReadyToStartImport(RESET);
    setFiles(RESET);
    setShowProgressNotification(RESET);
    setIdProcess(RESET);
    setSelectedGroups(RESET);
    setCreateGroup(RESET);
    setResetDetailList(RESET);
    resetStep1Loader();
  }, [
    setFileName,
    setReadyToStartImport,
    setFiles,
    setShowProgressNotification,
    setIdProcess,
    setSelectedGroups,
    setCreateGroup,
    setResetDetailList,
    resetStep1Loader,
  ]);

  return {
    resetImport,
  };
};
