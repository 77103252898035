export const BlockedIcon = ({ color = '#CDCDCD' }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <mask height="16" id="mask0_51_1108" maskUnits="userSpaceOnUse" width="16" x="0" y="0">
        <rect fill="#D9D9D9" height="16" width="16" />
      </mask>
      <g mask="url(#mask0_51_1108)">
        <path
          d="M8.00015 15.2332C7.00015 15.2332 6.06126 15.0415 5.18348 14.6582C4.3057 14.2749 3.53904 13.7554 2.88348 13.0999C2.22793 12.4443 1.71126 11.6749 1.33348 10.7915C0.955704 9.90821 0.766815 8.96654 0.766815 7.96654C0.766815 6.96654 0.955704 6.03043 1.33348 5.15821C1.71126 4.28599 2.22793 3.52487 2.88348 2.87487C3.53904 2.22487 4.3057 1.71099 5.18348 1.33321C6.06126 0.955429 7.00015 0.766541 8.00015 0.766541C9.00015 0.766541 9.93904 0.955429 10.8168 1.33321C11.6946 1.71099 12.4613 2.22487 13.1168 2.87487C13.7724 3.52487 14.289 4.28599 14.6668 5.15821C15.0446 6.03043 15.2335 6.96654 15.2335 7.96654C15.2335 8.96654 15.0446 9.90821 14.6668 10.7915C14.289 11.6749 13.7724 12.4443 13.1168 13.0999C12.4613 13.7554 11.6946 14.2749 10.8168 14.6582C9.93904 15.0415 9.00015 15.2332 8.00015 15.2332ZM8.00015 13.1332C8.51126 13.1332 9.01126 13.0638 9.50015 12.9249C9.98904 12.786 10.4335 12.561 10.8335 12.2499L3.71682 5.14987C3.42793 5.5721 3.21404 6.0221 3.07515 6.49987C2.93626 6.97765 2.86682 7.46654 2.86682 7.96654C2.86682 9.39987 3.36682 10.6193 4.36682 11.6249C5.36682 12.6304 6.57793 13.1332 8.00015 13.1332ZM12.3001 10.7832C12.5779 10.361 12.7863 9.91098 12.9251 9.43321C13.064 8.95543 13.1335 8.46654 13.1335 7.96654C13.1335 6.54432 12.6335 5.33876 11.6335 4.34987C10.6335 3.36099 9.42237 2.86654 8.00015 2.86654C7.50015 2.86654 7.01404 2.93043 6.54182 3.05821C6.06959 3.18599 5.62237 3.38876 5.20015 3.66654L12.3001 10.7832Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
