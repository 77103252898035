import { InformationCircleIcon } from '@heroicons/react/solid';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, IconSvg, If, ProgressBar, Text } from '@/lib/v2/components';

import { useEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { atomContractDetail } from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';
import { numberToCurrency } from '@/src/utils/currency';

import { useConsumptionAndBillingTables } from '@/modules/MyPlanModule/hooks/useConsumptionAndBillingTables';
import { useManagePlanSecurity } from '@/modules/MyPlanModule/hooks/useManagePlanSecurity';
import { Consumption, ContractDetail, PaginationDataResponse } from '@/modules/MyPlanModule/types';

const PlanDetail = () => {
  const { t } = useTranslation();
  const contractDetail = useAtomValue(atomContractDetail);

  const { list: consumptionList, listIsLoading: consumptionListIsLoading } =
    useConsumptionAndBillingTables<PaginationDataResponse<Consumption[]>>('listConsumption');

  const { canAddToAutoDebit, jwtToken, isFreemiumClient, isOldPlan } = useManagePlanSecurity();

  const { env } = useEnv();

  const formUrl = useMemo(() => {
    const envAux = env === 'storybook' ? 'development' : env;
    const url = configData.endpoints[envAux].UNSUBSCRIBE_URL_FORM;
    return url;
  }, [env]);

  const handleCancelSubscription = useCallback(() => {
    window.open(`${formUrl}/?token=${jwtToken}`, '_blank');
  }, [jwtToken, formUrl]);

  const getPercentage = useCallback((limit: number, usage: number) => {
    const usageNum = Number(usage);
    const limitNum = Number(limit);
    if (usageNum === 0) return 0;
    const percentage = (usageNum * 100) / limitNum;
    return percentage;
  }, []);

  const percentageEmail = useMemo(() => {
    if (consumptionListIsLoading || !contractDetail || !consumptionList) return 0;
    return getPercentage(
      contractDetail?.planDetail.email ?? 0,
      consumptionList.result.length ? consumptionList.result[0].sent : 0
    );
  }, [consumptionList, consumptionListIsLoading, contractDetail, getPercentage]);

  const percentageSms = useMemo(() => {
    if (consumptionListIsLoading || !contractDetail || !consumptionList) return 0;
    return getPercentage(
      contractDetail?.planDetail.sms ?? 0,
      consumptionList.result.length ? consumptionList.result[0].sentSms : 0
    );
  }, [consumptionList, consumptionListIsLoading, contractDetail, getPercentage]);

  const percentageOnsite = useMemo(() => {
    if (consumptionListIsLoading || !contractDetail || !consumptionList) return 0;
    return getPercentage(
      contractDetail?.planDetail.onsite ?? 0,
      consumptionList.result.length ? consumptionList.result[0].onSiteImpressions : 0
    );
  }, [consumptionList, consumptionListIsLoading, contractDetail, getPercentage]);

  const UsageProgress = (props: {
    percentage: number;
    keyPlanDetail: keyof ContractDetail['planDetail'];
    keyConsumption: 'sent' | 'sentSms' | 'onSiteImpressions';
    text: string;
  }) => {
    const { percentage, keyPlanDetail, keyConsumption, text } = props;
    const consumedTotal: number = consumptionList?.result[0]
      ? (consumptionList?.result?.[0]?.[keyConsumption] as unknown as number)
      : 0;

    const limit = Number(contractDetail?.planDetail[keyPlanDetail]);
    const consumed = consumedTotal <= limit ? consumedTotal : limit;
    const surplus = consumedTotal > limit ? consumedTotal - limit : 0;

    return (
      <>
        {contractDetail?.planDetail && consumptionList?.result ? (
          <div className="flex flex-col gap-2">
            <Text fontWeight="medium" variant="headline">
              {percentage === Infinity
                ? t('PLAN_DETAIL.surplus')
                : `${numberToCurrency(percentage, true, 0)}%`}
            </Text>
            <div className="-mt-3 flex justify-between">
              <Text variant="text">{text}</Text>
              <If condition={keyPlanDetail === 'sms' && percentage !== 0}>
                <div className="flex items-center justify-center gap-2">
                  <IconSvg
                    className="mt-0.5 min-w-[20px]"
                    fillColor={'primary'}
                    height="20px"
                    svgComponent={<InformationCircleIcon />}
                    width="20px"
                  />
                  <Text variant="text">{t('PLAN_DETAIL.smsInfo')}</Text>
                </div>
              </If>
            </div>
            <ProgressBar percentage={percentage} status={loadingStatus.ready} />
            <div className="flex justify-between">
              <Text variant="text">{`${numberToCurrency(consumed, true)} de ${numberToCurrency(
                limit,
                true
              )}`}</Text>
              <If condition={surplus > 0}>
                <Text variant="text">{`${t('PLAN_DETAIL.surplus')} ${numberToCurrency(
                  surplus,
                  true
                )}`}</Text>
              </If>
            </div>
          </div>
        ) : (
          <div className="my-1 h-4 w-40 animate-pulse rounded-full bg-gray-200"></div>
        )}
      </>
    );
  };

  const fullPlanPrice = useMemo(() => {
    if (contractDetail?.planPrice !== undefined) {
      const currency = contractDetail?.currency?.replace('$', '') ?? '';
      const billingPeriod = t(
        `MY_PLAN.BILLING.billingPeriod.${contractDetail?.billingPeriod}`
      ).toLocaleLowerCase();
      const planPrice = +contractDetail.planPrice || 0;
      const planPriceToShow = numberToCurrency(planPrice);
      return `${currency} ${planPriceToShow}/${billingPeriod}`;
    }
    return undefined;
  }, [contractDetail, t]);

  return (
    <Card isHeightAuto className="basis-2/3">
      <CardSection className="border-b" paddingX="medium">
        <Text fontWeight="medium">{t('MY_PLAN.BILLING.planData')}</Text>
      </CardSection>
      <CardSection paddingX="medium">
        <div className="flex w-full flex-col gap-8">
          <div className="flex w-full">
            <div className="flex basis-1/3 flex-col">
              <Text variant="text">{t('MY_PLAN.BILLING.plan')}</Text>
              <If condition={contractDetail?.planName !== undefined}>
                <Text fontWeight="medium" variant="sub-headline-2">
                  {contractDetail?.planName}
                </Text>
              </If>
              <If condition={contractDetail?.planName === undefined}>
                <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
              </If>
            </div>
            <div className="flex basis-1/3 flex-col">
              <Text variant="text">{t('MY_PLAN.BILLING.planPrice')}</Text>
              <If condition={fullPlanPrice !== undefined}>
                <Text fontWeight="medium" variant="sub-headline-2">
                  {fullPlanPrice}
                </Text>
              </If>
              <If condition={fullPlanPrice === undefined}>
                <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
              </If>
            </div>
          </div>
          <div className="flex w-full flex-col gap-8">
            <UsageProgress
              keyConsumption="sent"
              keyPlanDetail="email"
              percentage={percentageEmail}
              text={t('MY_PLAN.BILLING.emails', {
                frequency: t(
                  `MY_PLAN.BILLING.billingPeriod.${contractDetail?.billingPeriod}`
                ).toLocaleLowerCase(),
              })}
            />
            <UsageProgress
              keyConsumption="sentSms"
              keyPlanDetail="sms"
              percentage={percentageSms}
              text={t('MY_PLAN.BILLING.sms', {
                frequency: t(
                  `MY_PLAN.BILLING.billingPeriod.${contractDetail?.billingPeriod}`
                ).toLocaleLowerCase(),
              })}
            />
            <UsageProgress
              keyConsumption="onSiteImpressions"
              keyPlanDetail="onsite"
              percentage={percentageOnsite}
              text={t('MY_PLAN.BILLING.onsite', {
                frequency: t(
                  `MY_PLAN.BILLING.billingPeriod.${contractDetail?.billingPeriod}`
                ).toLocaleLowerCase(),
              })}
            />
            <div className="flex justify-between">
              <Text isItalic variant="text">
                {t('MY_PLAN.BILLING.updates')}
              </Text>
              <If
                condition={
                  (canAddToAutoDebit && !isFreemiumClient) ||
                  (isOldPlan && contractDetail?.planName !== 'Free Trial')
                }
              >
                <Text
                  link
                  color="primary"
                  id="edit-my-account"
                  variant="text"
                  onClick={handleCancelSubscription}
                >
                  {t('MY_PLAN.BILLING.cancelContract')}
                </Text>
              </If>
            </div>
          </div>
        </div>
      </CardSection>
    </Card>
  );
};

export default memo(PlanDetail);
