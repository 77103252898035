import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Button, Input } from '@/lib/v2/components';

import { ValidatePasswordInformation } from '@/src/modules/AuthModule/components';
import HeaderLogin from '@/src/modules/AuthModule/components/HeaderLogin';
import { useLogin } from '@/src/modules/AuthModule/hooks/useLogin';

interface FormChangePassword {
  password: string;
  repeatPassword: string;
}

type ValidationFunctionKeys = keyof typeof validationFunctions;

export type PasswordRules = Record<ValidationFunctionKeys, boolean>;

const validationFunctions = {
  minLength: (value: string) => value.length >= 10,
  oneCapitalLetter: (value: string) => /^(?=.*[A-Z])/.test(value),
  oneNumber: (value: string) => /^(?=.*\d)/.test(value),
  oneLowercaseLetter: (value: string) => /^(?=.*[a-z])/.test(value),
  oneSpecialCharacter: (value: string) => /^(?=.*[!@#$&])/.test(value),
  repeatingChars: (value: string) => value.length >= 4 && !/(.)\1{3,}/.test(value),
  sequentialChars: (value: string) => value.length >= 4 && hasSequentialChars(value),
};

const hasSequentialChars = (value: string) => {
  /** cspell: disable */
  const sequentialRegex =
    // eslint-disable-next-line regexp/no-unused-capturing-group
    /(1234|2345|3456|4567|5678|6789|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/i;
  return !sequentialRegex.test(value);
  /** cspell:enable */
};

const ChangePassword = () => {
  const [isFocusPasswordInput, setIsFocusPasswordInput] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordRules>();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormChangePassword>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const password = watch('password');

  const CHANGE_RULES = useMemo(
    () => ({
      password: {
        required: t('AUTH.CHANGE_PASSWORD.passwordRequired'),
      },
      repeatPassword: {
        required: t('AUTH.CHANGE_PASSWORD.repeatPasswordRequired'),
        validate: (value: string) => {
          return value === password || t('AUTH.CHANGE_PASSWORD.passwordsDoNotMatch');
        },
      },
    }),
    [password, t]
  );
  /** cspell:disable */
  const userId = searchParams.get('usuarioId');
  const token = searchParams.get('token');
  //** cspell:enable */

  const { error, isLoading, changePassword, setError, checkToken } = useLogin();

  const handleOnChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const entries = Object.entries(validationFunctions);
      const validate: Array<[string, boolean]> = entries.map(([key, func]) => [key, func(value)]);
      setValue('password', value);
      const passwordWithErrorsMap = Object.fromEntries(validate) as PasswordRules;
      setPasswordErrors(passwordWithErrorsMap);
    },
    [setValue]
  );

  const validateAllRulesPassword = useCallback((validationObj?: PasswordRules): boolean => {
    const values = Object.values(validationObj ?? []);
    return values.every((value) => value === true);
  }, []);

  const passwordRulesAreValid = useMemo(
    () => validateAllRulesPassword(passwordErrors) && !!passwordErrors,
    [passwordErrors, validateAllRulesPassword]
  );

  const onSubmitChangePassword: SubmitHandler<FormChangePassword> = useCallback(
    (data) => {
      if (!passwordRulesAreValid) return;
      const { password, repeatPassword } = data;
      if (password === repeatPassword) {
        void changePassword(password, token!, userId!);
      } else {
        setError(t('AUTH.CHANGE_PASSWORD.passwordsDoNotMatch'));
      }
    },
    [changePassword, passwordRulesAreValid, setError, t, token, userId]
  );

  const handleFocusPasswordInput = useCallback(() => {
    setIsFocusPasswordInput(true);
  }, []);

  const handleBlurPasswordInput = useCallback(() => {
    setIsFocusPasswordInput(false);
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (token && userId) await checkToken(token, userId);
    }

    if (token && userId) void fetchData();
  }, [checkToken, token, userId]);

  return (
    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
      <HeaderLogin
        subTitle={t('AUTH.CHANGE_PASSWORD.subtitle')}
        title={t('AUTH.CHANGE_PASSWORD.title')}
      />
      <form className="flex w-full justify-center" onSubmit={handleSubmit(onSubmitChangePassword)}>
        <div className="relative flex w-full flex-col items-center gap-4 sm:w-[300px]">
          <ValidatePasswordInformation
            isShow={isFocusPasswordInput}
            passwordRules={passwordErrors}
          />
          <Input
            {...register('password', CHANGE_RULES.password)}
            error={errors?.password && true}
            id="password-input"
            label={t('AUTH.CHANGE_PASSWORD.newPassword')}
            message={errors?.password?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
            onBlur={handleBlurPasswordInput}
            onChange={handleOnChangePassword}
            onFocus={handleFocusPasswordInput}
          />
          <Input
            {...register('repeatPassword', CHANGE_RULES.repeatPassword)}
            error={errors?.repeatPassword && true}
            id="repeat-password-input"
            label={t('AUTH.CHANGE_PASSWORD.confirmPassword')}
            message={errors?.repeatPassword?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
          />
          {error && <p className={'message-error !text-center'}>{error}</p>}
          <Button
            fullWidth
            disabled={!passwordRulesAreValid}
            id="change-password-button"
            isLoading={isLoading}
            type="submit"
          >
            {t('AUTH.CHANGE_PASSWORD.create')}
          </Button>
          <Alert
            description={t('AUTH.CHANGE_PASSWORD.ALERT.description')}
            title={t('AUTH.CHANGE_PASSWORD.ALERT.title')}
            type="warning"
          />
        </div>
      </form>
    </div>
  );
};

export default memo(ChangePassword);
