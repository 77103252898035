import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components';

import FilterCampaignState from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/FilterBar/components/FilterCampaignState';
import FilterStrategy from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/FilterBar/components/FilterCampaignStrategy';

import { useFiltersDropdown } from './useFiltersDropdown';

interface IFiltersProps {
  onClose?: () => void;
}
const FiltersDropdown = ({ onClose }: IFiltersProps) => {
  const { t } = useTranslation();

  const { setSelectedState, setSelectedStrategy, filtersApplied, onApplyFilters } =
    useFiltersDropdown(onClose);

  const containerClasses = classNames(
    'flex flex-col w-300 overflow-y-auto visible-scrollbar overflow-y-scroll overflow-x-hidden ',
    {
      hidden: filtersApplied,
    }
  );

  return (
    <>
      <div className={containerClasses} id="filters-campaign">
        <FilterCampaignState currentItems={setSelectedState} />
        <FilterStrategy currentItems={setSelectedStrategy} />
      </div>
      <div className="mt-4 flex justify-end">
        <Button id="button-apply-filters-campaign" onClick={onApplyFilters}>
          {t('TABLE_FILTER_BAR.apply')}
        </Button>
      </div>
    </>
  );
};

export default memo(FiltersDropdown);
