export type ImageProps = {
  children?: JSX.Element | string;
  alt?: string;
  src: JSX.Element | string;
  size?: number | string;
  circle?: boolean;
  rounded?: boolean;
  padding?: string;
  backgroundClass?: string;
};

export const Icon = ({ src, size, circle, rounded, padding, backgroundClass }: ImageProps) => {
  const className = `w-32 h-32
        ${circle ? 'rounded-full overflow-hidden' : ''}
        ${rounded ? 'flex rounded-[6px] items-center justify-center' : ''}
        ${backgroundClass ? backgroundClass : ''}
      `;
  if (typeof src === 'string') {
    return (
      <img
        alt=""
        className={className}
        src={src}
        style={size ? { width: size, height: size, padding } : {}}
      />
    );
  } else {
    return (
      <div className={className} style={size ? { width: size, height: size, padding } : {}}>
        {src}
      </div>
    );
  }
};
