export const InvoiceIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="16"
        id="mask0_2201_3999"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="16"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="16" width="16" />
      </mask>
      <g mask="url(#mask0_2201_3999)">
        <path
          d="M2.73037 15C2.25144 15 1.84332 14.8313 1.50599 14.494C1.16866 14.1567 1 13.7486 1 13.2696V2.73037C1 2.25144 1.16866 1.84332 1.50599 1.50599C1.84332 1.16866 2.25144 1 2.73037 1H7.13482C7.37757 1 7.58249 1.08348 7.74957 1.25043C7.91652 1.41751 8 1.62243 8 1.86518C8 2.10794 7.91652 2.31286 7.74957 2.47994C7.58249 2.64689 7.37757 2.73037 7.13482 2.73037H2.73037V13.2696H13.2696V8.86518C13.2696 8.62243 13.3531 8.41751 13.5201 8.25043C13.6871 8.08348 13.8921 8 14.1348 8C14.3776 8 14.5825 8.08348 14.7496 8.25043C14.9165 8.41751 15 8.62243 15 8.86518V13.2696C15 13.7486 14.8313 14.1567 14.494 14.494C14.1567 14.8313 13.7486 15 13.2696 15H2.73037ZM13.2696 3.93611L6.9922 10.2135C6.83159 10.3743 6.63136 10.4539 6.39152 10.4524C6.1518 10.4508 5.95164 10.3697 5.79102 10.209C5.63028 10.0484 5.54991 9.84744 5.54991 9.6062C5.54991 9.36483 5.63028 9.16385 5.79102 9.00323L12.0639 2.73037H10.3864C10.1436 2.73037 9.93871 2.64689 9.77163 2.47994C9.60468 2.31286 9.5212 2.10794 9.5212 1.86518C9.5212 1.62243 9.60468 1.41751 9.77163 1.25043C9.93871 1.08348 10.1436 1 10.3864 1H14.1348C14.3776 1 14.5825 1.08348 14.7496 1.25043C14.9165 1.41751 15 1.62243 15 1.86518V5.61361C15 5.85637 14.9165 6.06129 14.7496 6.22837C14.5825 6.39532 14.3776 6.4788 14.1348 6.4788C13.8921 6.4788 13.6871 6.39532 13.5201 6.22837C13.3531 6.06129 13.2696 5.85637 13.2696 5.61361V3.93611Z"
          fill="#364365"
        />
      </g>
    </svg>
  );
};
