import { t } from 'i18next';
import { useForm } from 'react-hook-form';

import { MAX_SIZE_SUBJECT, TRANSLATE_RULES_EMAIL } from '@/modules/CampaignsModule/constants';
import { IEmailFormInfo } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useEmailForm = () => {
  const { control, handleSubmit, setValue, getValues } = useForm<IEmailFormInfo>();

  const RULES = {
    sender: {
      required: t(`${TRANSLATE_RULES_EMAIL}.sender`),
    },
    subject: {
      required: t(`${TRANSLATE_RULES_EMAIL}.subject`),
      maxLength: {
        value: MAX_SIZE_SUBJECT,
        message: t(`${TRANSLATE_RULES_EMAIL}.maxLength`, { max: MAX_SIZE_SUBJECT }),
      },
    },
    firstLine: {
      maxLength: {
        value: MAX_SIZE_SUBJECT,
        message: t(`${TRANSLATE_RULES_EMAIL}.maxLength`, { max: MAX_SIZE_SUBJECT }),
      },
    },
    recipients: {
      required: t(`${TRANSLATE_RULES_EMAIL}.recipients`),
    },
  };

  return {
    control,
    handleSubmit,
    setValue,
    getValues,
    RULES,
  };
};
