import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

interface EmptyTableWithoutDataProps {
  onClickAction?: () => void;
}

const EmptyTableWithoutData = ({ onClickAction }: EmptyTableWithoutDataProps) => {
  const { t } = useTranslation();

  return (
    <div className=" max-w-[682px] ">
      <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
        {t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescription')}
      </Text>
      <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
        {t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescriptionAlt')}
        <button
          className="cursor-pointer !text-14 font-medium text-emblue-primary"
          data-testid="nodata-campaigns"
          id="nodata-campaigns"
          role="link"
          onClick={onClickAction}
        >
          {t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE.emptyContactsAction')}
        </button>
      </Text>
    </div>
  );
};

export default EmptyTableWithoutData;
