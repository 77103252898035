import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomContractCountry,
  atomDownloadingReport,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomActionInfoWhatsApp,
  atomActionRecipients,
  atomActionReportWhatsApp,
  atomInfoWhatsAppPayload,
} from '@/src/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import {
  REPORT_PARAMS,
  TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT,
} from '@/src/modules/CampaignsModule/constants';
import {
  IReportParams,
  IScheduledWhatsAppDeliveryV1,
  IWhatsAppInfoPayload,
  IWhatsAppRecipientsResponseV1,
} from '@/src/modules/CampaignsModule/interfaces/WhatsApp';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

import {
  atomFileDownloadInfo,
  atomShowIndeterminateNotification,
} from '@/modules/ContactsModule/atoms/Steps';

export const useActionWhatsAppData = (actionId: number) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const serviceV1 = useService();

  const [infoWhatsAppPayload, setInfoWhatsAppPayload] = useAtom(atomInfoWhatsAppPayload);
  const setActionInfoWhatsApp = useSetAtom(atomActionInfoWhatsApp);
  const setActionReportWhatsApp = useSetAtom(atomActionReportWhatsApp);
  const setActionRecipients = useSetAtom(atomActionRecipients);
  const setContractCountry = useSetAtom(atomContractCountry);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const processingAction = useSetAtom(atomProcessingAction);
  const setShowProgressIndeterminateNotification = useSetAtom(atomShowIndeterminateNotification);
  const setFileDownloadInfo = useSetAtom(atomFileDownloadInfo);
  const downloadingReport = useSetAtom(atomDownloadingReport);

  const saveInfoWhatsApp = useCallback(
    async (payload: IWhatsAppInfoPayload) => {
      setLoading(true);
      await serviceV1.getSetInfoSms(payload);
      setLoading(false);
    },
    [serviceV1]
  );

  const getContractCountry = useCallback(async () => {
    const data = await serviceV1.getContractCountry();
    setContractCountry(data as string);
  }, [serviceV1, setContractCountry]);

  //cspell:disable
  const getWhatsAppInfo = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsInfo(actionId);
    setActionInfoWhatsApp(data);
    setInfoWhatsAppPayload({
      ...infoWhatsAppPayload,
      elementoId: data?.elementoId || Number(actionId),
      mensaje: data?.mensaje || '',
      campoId: data?.campo,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, serviceV1, setActionInfoWhatsApp, setInfoWhatsAppPayload]);
  //cspell:enable

  const getWhatsAppRecipients = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsRecipientsV1(actionId);
    setActionRecipients(data as IWhatsAppRecipientsResponseV1);
    setLoading(false);
  }, [actionId, serviceV1, setActionRecipients]);

  const setWhatsAppRecipients = useCallback(
    async (payload: unknown) => {
      await serviceV1.setSmsRecipientsV1(payload as object);
    },
    [serviceV1]
  );

  const setWhatsAppDelivery = useCallback(async () => {
    await serviceV1.setImmediateSMSDeliveryV1(actionId);
  }, [actionId, serviceV1]);

  const setScheduledWhatsAppDelivery = useCallback(
    async (queryParams: IScheduledWhatsAppDeliveryV1) => {
      queryParams;
      await serviceV1.setScheduledSMSDeliveryV1(queryParams);
    },
    [serviceV1]
  );

  //cspell:disable
  const getWhatsAppInfoReport = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsInfoReport(actionId);
    setActionReportWhatsApp(data);
    setLoading(false);
  }, [actionId, serviceV1, setActionReportWhatsApp]);
  //cspell:enable

  const getSummaryInfoWhatsApp = useCallback(async () => {
    setGlobalLoadingAction(true);
    await getWhatsAppInfo();
    await getWhatsAppRecipients();
    await getWhatsAppInfoReport();
    setGlobalLoadingAction(false);
  }, [getWhatsAppInfo, getWhatsAppInfoReport, getWhatsAppRecipients, setGlobalLoadingAction]);

  /* cspell:disable */
  const setConfirmWhatsApp = useCallback(async () => {
    setLoading(true);
    const dataService = await serviceV1.setConfirmSMSV1(actionId);
    setLoading(false);
    return dataService;
  }, [actionId, serviceV1]);
  /* cspell:enable */

  /* cspell:disable */
  const setPauseWhatsApp = useCallback(async () => {
    processingAction(true);
    const dataService = await serviceV1.setPauseSMSV1(actionId);
    processingAction(false);
    return dataService;
  }, [actionId, serviceV1, processingAction]);
  /* cspell:enable */

  /* cspell:disable */
  const getWhatsAppReport = useCallback(async () => {
    downloadingReport(true);
    const reportParamsWhatsApp: IReportParams[] = [
      ...REPORT_PARAMS,
      { clave: 'elementos', valor: actionId?.toString() || '' },
    ];
    /* cspell:enable */

    const response = await serviceV1?.downloadReport(reportParamsWhatsApp);
    const description = response
      ? t(`${TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT}.downloading`) || ''
      : t(`${TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT}.pendingDownload`) || '';

    setShowProgressIndeterminateNotification({
      show: true,
      description: description,
      status: loadingStatus.loading,
    });

    setFileDownloadInfo({
      nameDownload: t(`${TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT}.nameFile`, {
        date: new Date().toLocaleString('en-CA', { hour12: false }).replace(',', ''),
      }),
      descriptionDownloaded: t(`${TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT}.downloaded`),
    });

    downloadingReport(false);
  }, [
    actionId,
    downloadingReport,
    serviceV1,
    setFileDownloadInfo,
    setShowProgressIndeterminateNotification,
    t,
  ]);

  return {
    saveInfoWhatsApp,
    getContractCountry,
    getWhatsAppInfo,
    getWhatsAppRecipients,
    setWhatsAppRecipients,
    setWhatsAppDelivery,
    setScheduledWhatsAppDelivery,
    getSummaryInfoWhatsApp,
    setConfirmWhatsApp,
    setPauseWhatsApp,
    getWhatsAppInfoReport,
    getWhatsAppReport,
    loading,
  };
};
