import { t } from 'i18next';

export const formatLocalizedDate = (date: Date | string) => {
  date = typeof date === 'string' ? new Date(date) : date;
  return date.toLocaleDateString(t('TIMEZONE.locale'), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};
