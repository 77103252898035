import { format } from '@formkit/tempo';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import { SidebarList } from './SidebarList';
import { SidebarSkeleton } from './SidebarSkeleton';

import {
  atomCalendarLoaderSidebar,
  atomCurrentDateCalendar,
  atomListCalendarSidebar,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';

const CalendarSidebar = () => {
  const { i18n } = useTranslation();

  const listCalendarSidebar = useAtomValue(atomListCalendarSidebar);
  const currentDateCalendar = useAtomValue(atomCurrentDateCalendar);
  const calendarLoaderSidebar = useAtomValue(atomCalendarLoaderSidebar);

  const { getListCalendarSidebar } = useCampaignActions();

  useEffect(() => {
    void getListCalendarSidebar(currentDateCalendar);
  }, [getListCalendarSidebar, currentDateCalendar]);

  return (
    <>
      <Text
        className="mb-6 pt-2 capitalize"
        color="black"
        fontWeight="medium"
        variant="sub-headline"
      >
        {format(currentDateCalendar, 'D MMMM YYYY', i18n.language)}
      </Text>
      {calendarLoaderSidebar ? <SidebarSkeleton /> : <SidebarList list={listCalendarSidebar} />}
    </>
  );
};

export default CalendarSidebar;
