import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useGroup = () => {
  const service = useService();
  const { t } = useTranslation();

  /** DON’T REPLICATE CODE LIKE THIS, THE NEXT COPY AND PASTE, PLEASE REFACTOR */
  const createGroup = useCallback(
    async (value: string) => {
      const responseCreate = await service?.createGroup(value);
      const OkBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.OkBody');
      let alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.KoBody');
      let result = false;

      /** cspell:disable */
      if (responseCreate['notification']['content'][1].value === 'GRUPO_CREADO_OK') {
        result = true;
      }
      if (responseCreate['notification']['content'][1].value === 'GRUPO_CREADO_KO') {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.KoBody');
      }
      if (responseCreate['notification']['content'][1].value === 'GRUPO_USUARIO_EXISTENTE') {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.ExistingBody');
      }
      if (responseCreate['notification']['content'][1].value === 'NUMERO_MAXIMO_GRUPOS_USUARIO') {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.LimitBody');
      }
      /** cspell:enable */

      if (result) service?.incrementLastRefreshGroupsValue();

      toast({
        title: result
          ? t('MANAGE_GROUP_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_GROUP_MODAL.NOTIFICATION.AlertTitle'),
        body: result ? OkBody : alertBody,
        variant: result ? 'success' : 'error',
      });

      return result;
    },
    [service, t]
  );

  return { createGroup };
};
