export const ChatIcon = () => {
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00165011 9.64446C0.00165011 9.27611 -0.000135283 8.90776 0.00165011 8.53941C0.0061136 7.63261 0.608238 6.80617 1.47103 6.5365C1.57905 6.50256 1.6094 6.4597 1.60851 6.35389C1.6036 5.84133 1.60806 5.331 1.70358 4.8229C1.87676 3.90404 2.23964 3.07091 2.80606 2.33019C3.55459 1.35061 4.51513 0.662584 5.68947 0.282628C6.45496 0.0348305 7.23875 -0.0486615 8.04263 0.0267939C9.30847 0.145112 10.4185 0.623293 11.3728 1.45643C12.3749 2.33153 13.0033 3.43211 13.2783 4.73182C13.3894 5.25688 13.4051 5.79221 13.3872 6.32754C13.3832 6.45434 13.4225 6.50524 13.5474 6.54632C14.3973 6.82626 14.9887 7.63975 14.9954 8.53316C15.0012 9.28102 15.0017 10.0289 14.9954 10.7767C14.9847 12.0099 13.8264 13.0109 12.6048 12.8435C12.5137 12.831 12.478 12.8524 12.436 12.9252C11.8219 13.9878 10.9086 14.6451 9.71554 14.9152C9.4879 14.967 9.25536 14.9987 9.02058 14.9991C8.51308 15 8.00513 15.0027 7.49763 14.9991C6.89729 14.9951 6.42773 14.5205 6.42907 13.9249C6.43041 13.3306 6.90176 12.8618 7.50567 12.8582C7.86275 12.856 8.21983 12.8564 8.57691 12.8587C9.07191 12.8623 9.49147 13.1926 9.60663 13.6753C9.6352 13.7958 9.66823 13.8351 9.80213 13.7887C10.4846 13.5525 11.0296 13.1444 11.4242 12.5363C11.4875 12.4381 11.5032 12.376 11.4036 12.2832C10.935 11.8465 10.7087 11.3031 10.7131 10.6602C10.7185 9.95164 10.7127 9.24262 10.7158 8.53406C10.7198 7.63082 11.3286 6.80617 12.1923 6.53516C12.2682 6.51149 12.3191 6.49676 12.3227 6.39898C12.3611 5.39618 12.2236 4.42955 11.713 3.5473C10.9323 2.19803 9.77446 1.3841 8.23054 1.13318C6.00415 0.771525 3.80276 2.05159 2.99576 4.17238C2.72125 4.89345 2.65341 5.6413 2.67707 6.40389C2.67974 6.48337 2.71902 6.50569 2.7824 6.52757C3.73938 6.8602 4.28526 7.62949 4.28571 8.64255C4.28571 9.33996 4.28615 10.0378 4.28481 10.7352C4.28258 11.8063 3.49745 12.6993 2.38471 12.8435C1.35498 12.977 0.383725 12.3167 0.0913662 11.3241C0.0297701 11.1192 -0.00236703 10.9129 -0.000135283 10.6995C0.00343551 10.3481 0.000757415 9.99628 0.000757415 9.6449H0.00165011V9.64446Z"
        fill="white"
      />
    </svg>
  );
};
