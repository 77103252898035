import { SyntheticEvent, useCallback, useState } from 'react';

import { Checkbox } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';

export interface ISelectableItem {
  label: string;
  checked?: boolean;
  indeterminate?: boolean;
  id: string;
  disabled: boolean;
  onClick?: (event: SyntheticEvent<HTMLInputElement>, item: ISelectableItem) => void;
}

const ModalItem = (props: ISelectableItem) => {
  const [checked, setChecked] = useState(props.checked);
  const handleClickAccessibility = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;

      if (typeof props.onClick !== 'function' || target?.type !== 'checkbox') return;

      props.onClick(e, {
        ...props,
        checked: target.checked,
        indeterminate: false,
      });
      setChecked(target.checked);
    },
    [props]
  );

  return (
    <div className="w-full" role="button" tabIndex={0}>
      <Flex withGap alignment="start" className="mb-4" itemAlignment="center">
        <Checkbox
          checked={Boolean(checked)}
          disabled={props.disabled}
          id={props.id}
          indeterminate={Boolean(props.indeterminate)}
          value={props.id}
          onChange={(e) => handleClickAccessibility(e)}
        />
        <Text color={props.disabled ? 'light-black' : 'black'} variant="text">
          {props.label}
        </Text>
      </Flex>
    </div>
  );
};

export default ModalItem;
