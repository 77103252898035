import { useCallback, useEffect, useState } from 'react';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import copyToClipboard from '@/src/utils/Copy';

interface IButtonPreviewCopyLinkProps {
  actionId: number;
}

const useButtonPreviewCopyLink = ({ actionId }: IButtonPreviewCopyLinkProps) => {
  const serviceV1 = useService();

  const [isCopiedLink, setIsCopiedLink] = useState<boolean>(false);
  const [sharePreviewLink, setSharePreviewLink] = useState<string>('');
  const [loadingPreviewLink, setLoadingPreviewLink] = useState<boolean>(false);

  const getPreviewLinkService = useCallback(async () => {
    setLoadingPreviewLink(true);
    const hash = await serviceV1.getPreviewLink(actionId);
    const shareLink = `${window.location.origin}?tp=${hash}`;
    setSharePreviewLink(shareLink);
    setLoadingPreviewLink(false);
  }, [actionId, serviceV1]);

  useEffect(() => void getPreviewLinkService(), [getPreviewLinkService]);

  const handleCopyPreviewLink = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      await copyToClipboard(sharePreviewLink);
      setIsCopiedLink(true);
      window.setTimeout(() => setIsCopiedLink(false), 1500);
    },
    [sharePreviewLink]
  );

  return {
    state: {
      isCopiedLink,
      loadingPreviewLink,
    },
    event: {
      handleCopyPreviewLink,
    },
  };
};

export default useButtonPreviewCopyLink;
