import { IconSkeleton } from '@/lib/components/Skeletons/IconSkeleton';
import { Card, TextSkeleton } from '@/lib/index';
import { Avatar } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { QuestionIcon } from '@/lib/v2/icons/solid';

import { CardList, IStatusList } from '@/src/ContactsModule/interfaces/Interfaces';
import { CardType } from '@/src/ContactsModule/utils/enums/Enums';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export const FilterCards = ({ items, onAction, list, cardType }: CardList) => {
  const isEcommerce = cardType === CardType.Ecommerce;
  const renderCardContent = (cardData: IStatusList) => {
    if (list) {
      return (
        <div
          className={`font-medium text-emblue-subheadings
          ${isEcommerce ? 'xl:text-10 2xl:text-12' : 'text-20'}`}
        >
          {cardType === CardType.Status || cardType === CardType.Segment
            ? quantifier(cardData.number)
            : cardData.title || <TextSkeleton size="xs" />}
        </div>
      );
    }
    return <TextSkeleton size="xs" />;
  };

  const renderCardSubtitle = (cardData: IStatusList) => {
    if (cardType === CardType.Status || cardType === CardType.Segment) {
      return cardData.title ? (
        <div className="text-14 text-emblue-subheadings">{cardData.title}</div>
      ) : (
        <TextSkeleton size="sm" />
      );
    }
    return (
      <Flex withGap alignment="start">
        <div
          className={`font-medium text-emblue-subheadings ${
            isEcommerce ? 'xl:text-12 2xl:text-12' : 'text-20'
          }`}
        >
          {cardData.subtitle || quantifier(cardData.number) || <TextSkeleton size="xs" />}
        </div>
        {cardData.subtitle && (
          <div title={cardData.tooltip}>
            <QuestionIcon />
          </div>
        )}
      </Flex>
    );
  };

  return (
    <Flex className={`flex ${isEcommerce ? 'flex-wrap 2xl:flex-nowrap' : 'gap-6'}`}>
      {items &&
        items.map((cardData, key) => {
          return (
            <div key={key} className={`w-1/2 ${isEcommerce ? 'p-1' : ''}`}>
              <button
                className="card w-full rounded-md border border-transparent shadow-sm focus:ring-2 focus:ring-[#004DBC] focus:ring-offset-2"
                disabled={isEcommerce}
                title={cardType !== CardType.Ecommerce ? cardData.tooltip : ''}
                onClick={() => onAction && onAction(cardData.filter)}
              >
                <Card {...(isEcommerce ? { noPadded: true } : {})}>
                  <div className="h-[60px] p-2">
                    <Flex className={`flex items-start ${isEcommerce ? 'gap-2' : 'gap-6'}`}>
                      {cardData.icon ? (
                        <Avatar sizeL icon={cardData.icon} status={cardData.iconBgColor as ''} />
                      ) : (
                        <IconSkeleton size="sm" />
                      )}
                      <Flex
                        column
                        alignment={cardType === CardType.Segment ? 'center' : 'start'}
                        itemAlignment="start"
                      >
                        {renderCardContent(cardData)}
                        {renderCardSubtitle(cardData)}
                      </Flex>
                    </Flex>
                  </div>
                </Card>
              </button>
            </div>
          );
        })}
    </Flex>
  );
};
