import { Cell, Label, Pie, PieChart } from 'recharts';

import { IPieChartCard } from './types';

export const PieChartWidget = ({
  chartData,
  label,
  halfPie = false,
  maxValue = 100,
  minValue = -100,
}: IPieChartCard) => {
  const generateChartData = () => {
    let adjustedValue = 0;
    let adjustedRemainingValue = 0;
    if (maxValue && minValue) {
      adjustedValue = ((chartData[0].value - minValue) / (maxValue - minValue)) * 100;
      adjustedRemainingValue = 100 - adjustedValue;
    }
    return [
      { value: adjustedValue, color: '#004DBC' },
      { value: adjustedRemainingValue, color: '#CCDBF2' },
    ];
  };
  const startAngleHalfPie = 180;
  const startAngleFullPie = -305;
  const endAngleHalfPie = 0;
  const widthHalfPie = 300;
  const widthFullPie = 200;
  const height = 200;

  const startAngle = halfPie ? startAngleHalfPie : startAngleFullPie;
  const normalizedChartData = generateChartData();
  const dataSelected = halfPie ? normalizedChartData : chartData;
  const endAngle = halfPie ? endAngleHalfPie : undefined;
  const width = halfPie ? widthHalfPie : widthFullPie;
  return (
    <PieChart data-testid="pie-chart-component" height={height} width={width}>
      <Pie
        cx={'50%'}
        cy={'50%'}
        data={dataSelected}
        dataKey="value"
        endAngle={endAngle}
        fill="#CCDBF2"
        innerRadius={75}
        outerRadius={95}
        paddingAngle={1}
        startAngle={startAngle}
      >
        {chartData.map((entry) => (
          <Cell key={`cell-${entry.name}`} fill={entry.color} />
        ))}
        {label?.map((element) => {
          return (
            <Label
              key={element.id}
              dx={element.dx}
              dy={element.dy}
              fill={element.fill}
              fontSize={element.fontSize}
              fontWeight={element.fontWeight}
              id={element.id}
              position={element.position}
              value={element.value}
            />
          );
        })}
      </Pie>
    </PieChart>
  );
};
