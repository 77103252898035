import { FieldErrors, RegisterOptions } from 'react-hook-form';

import GroupContacts from '@/src/modules/RulesModule/components/FormTrigger/components/GroupContacts';
import RedirectExternalUrl from '@/src/modules/RulesModule/components/FormTrigger/components/RedirectExternalUrl/RedirectExternalUrl';
import RepeatedEmails from '@/src/modules/RulesModule/components/FormTrigger/components/RepeatedEmails';
import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';

import CopyExternalUrl from './components/CopyExternalUrl';

import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ITriggerExternalCallProps {
  errors: FieldErrors<ITriggerForm>;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
  rules: Record<string, RegisterOptions>;
}
const TriggerExternalCall = ({
  form,
  errors,
  disabled = false,
  rules,
}: ITriggerExternalCallProps) => {
  return (
    <>
      <CopyExternalUrl />
      <RepeatedEmails disabled={disabled} form={form} />
      <GroupContacts disabled={disabled} errors={errors} form={form} />
      <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="success" />
      <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="error" />
    </>
  );
};

export default TriggerExternalCall;
