/* cSpell:disable */

type ItemIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const ItemIcon = ({ tableSize, stroked }: ItemIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 3409" id="Grupo_3409" transform="translate(-0.318 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0.318 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M10.955,4.979v-1a3.984,3.984,0,1,0-7.967,0v1H0v8.465a2.49,2.49,0,0,0,2.49,2.49h8.963a2.49,2.49,0,0,0,2.49-2.49V4.979Zm-5.975-1a1.992,1.992,0,0,1,3.984,0v1H4.979ZM9.959,7.718a.747.747,0,1,1,.747-.747A.747.747,0,0,1,9.959,7.718Zm-5.975,0a.747.747,0,1,1,.747-.747A.747.747,0,0,1,3.984,7.718Z"
              data-name="Icon awesome-shopping-bag"
              fill="#004dbc"
              id="Icon_awesome-shopping-bag"
              transform="translate(3.347 1.951)"
            />
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7465" id="Grupo_7465" transform="translate(-0.318 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0.318 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M10.955,4.979v-1a3.984,3.984,0,1,0-7.967,0v1H0v8.465a2.49,2.49,0,0,0,2.49,2.49h8.963a2.49,2.49,0,0,0,2.49-2.49V4.979Zm-5.975-1a1.992,1.992,0,0,1,3.984,0v1H4.979ZM9.959,7.718a.747.747,0,1,1,.747-.747A.747.747,0,0,1,9.959,7.718Zm-5.975,0a.747.747,0,1,1,.747-.747A.747.747,0,0,1,3.984,7.718Z"
              data-name="Icon awesome-shopping-bag"
              fill="none"
              id="Icon_awesome-shopping-bag"
              stroke="#004dbc"
              strokeWidth="1"
              transform="translate(3.347 1.951)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
