import { ChangeEvent, ComponentType, forwardRef } from 'react';
import { Control, Controller, FieldValues, UseControllerProps } from 'react-hook-form';

export function withController<TOriginalProps extends object>(
  FieldComponent: ComponentType<TOriginalProps>
) {
  const displayName = FieldComponent.displayName;

  type ControllerHOCProps = {
    name?: string;
    control?: Record<string, unknown>;
    rules?: UseControllerProps['rules'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (e: any) => void;
  } & TOriginalProps;

  const ControllerHOC = forwardRef<unknown, ControllerHOCProps>(
    ({ name, control, rules, onChange, ...restOfProps }, ref) => {
      if (!control) {
        return (
          <FieldComponent
            ref={ref}
            name={name}
            onChange={onChange}
            {...(restOfProps as TOriginalProps)}
          />
        );
      }

      return (
        <Controller
          control={control as Control<FieldValues>}
          name={name ?? ''}
          render={({ field, fieldState }) => {
            const { error } = fieldState;
            return (
              <FieldComponent
                {...field}
                ref={ref}
                error={error && true}
                message={error?.message}
                onChange={(eventValue: ChangeEvent<unknown>) => {
                  field.onChange(eventValue);
                  onChange?.(eventValue);
                }}
                {...(restOfProps as TOriginalProps)}
              />
            );
          }}
          rules={rules}
        />
      );
    }
  );

  ControllerHOC.displayName = displayName;

  return ControllerHOC;
}
