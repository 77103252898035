export const GoogleMeetIcon = () => {
  return (
    <svg
      height="63.15"
      viewBox="0 0 74.631 63.15"
      width="74.631"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="google-meet" transform="translate(-3 -5)">
        <path
          d="M52.057,31.644v10.19a5.758,5.758,0,0,1-5.741,5.741H9V30.352H34.834V16Z"
          data-name="Path 3459"
          fill="#00ac47"
          id="Path_3459"
          transform="translate(11.223 20.575)"
        />
        <path
          d="M35.223,11V41L18,25.352V11Z"
          data-name="Path 3460"
          fill="#31a950"
          id="Path_3460"
          transform="translate(28.057 11.223)"
        />
        <path
          d="M20.223,5V22.223H3Z"
          data-name="Path 3461"
          fill="#ea4435"
          id="Path_3461"
          transform="translate(0 0)"
        />
        <rect
          data-name="Rectangle 3826"
          fill="#4285f4"
          height="31.177"
          id="Rectangle_3826"
          transform="translate(3 22.32)"
          width="17.32"
        />
        <path
          d="M52.057,10.741V22.223H50.622L34.834,36.575V22.223H9V5H46.316A5.758,5.758,0,0,1,52.057,10.741Z"
          data-name="Path 3462"
          fill="#ffba00"
          id="Path_3462"
          transform="translate(11.223 0)"
        />
        <path
          d="M20.223,21V38.223H8.741A5.758,5.758,0,0,1,3,32.482V21Z"
          data-name="Path 3463"
          fill="#0066da"
          id="Path_3463"
          transform="translate(0 29.927)"
        />
        <path
          d="M49.575,10.129V54.563a2.867,2.867,0,0,1-4.794,2.124l-9.559-8.7L18,32.346,33.787,17.994,35.223,16.7,44.781,8a2.867,2.867,0,0,1,4.794,2.124Z"
          data-name="Path 3464"
          fill="#00ac47"
          id="Path_3464"
          transform="translate(28.057 4.229)"
        />
        <path
          d="M35.223,10.55V41.838L18,26.194,33.787,11.842Z"
          data-name="Path 3465"
          fill="#188038"
          id="Path_3465"
          transform="translate(28.057 10.381)"
        />
      </g>
    </svg>
  );
};
