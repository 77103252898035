import configData from '@/src/config.json';
import { APIService } from '@/src/infrastructure/Protocol/APIService';
import { CustomFieldsService } from '@/src/infrastructure/Protocol/CustomFields/CustomFieldsService';
import { StubCustomFieldsService } from '@/src/infrastructure/Protocol/CustomFields/StubCustomFieldsService';

import { EnvironmentManager } from './EnvironmentManager';

export class CustomFieldsServiceFactory {
  private static envManager: EnvironmentManager = new EnvironmentManager();

  /**
   * Creates an instance of the custom fields service based on the current environment.
   *
   * - In non-storybook environments, it creates an instance of `CustomFieldsService` with an API service.
   * - In the storybook environment, it creates an instance of `StubCustomFieldsService`.
   *
   * @returns {CustomFieldsService | StubCustomFieldsService} An instance of the appropriate custom fields service.
   */
  static createService() {
    const env = CustomFieldsServiceFactory.envManager.getEnvironment();

    if (env !== 'storybook') {
      const { URL_EMA_APP_V2, JWT_DEV_TOKEN } = configData.endpoints[env];
      const jwt =
        JWT_DEV_TOKEN !== ''
          ? JWT_DEV_TOKEN
          : (window as Window & typeof globalThis & { __JWTToken: string }).__JWTToken;

      return CustomFieldsService.create(new APIService(URL_EMA_APP_V2, jwt));
    } else {
      return StubCustomFieldsService.create();
    }
  }
}
