import classNames from 'classnames';
import { FC, memo, MouseEventHandler, ReactNode } from 'react';

import { ColorsIcon, IconBaseProps } from './types';

import './IconSvg.tailwind.css';

export interface IconSvgProps extends IconBaseProps {
  /** pass component icon svg */
  svgComponent: JSX.Element | ReactNode;
  /** indicates the width of the container */
  width?: string;
  /** indicates the height of the container */
  height?: string;
  /** indicates that the icon will be rounded, with padding and background color */
  rounded?: boolean;
  /** indicate the background color of icon */
  bgColor?: Exclude<ColorsIcon, 'white'>;
  /** indicates padding size with rounded prop */
  withPadding?: 'small' | 'medium' | 'large';
  /** additional className */
  className?: string;
  /** onClick function icon */
  onClick?: MouseEventHandler<HTMLDivElement>;
  title?: string;
  id?: string;
  fullWidth?: boolean;
}

const IconSvg: FC<IconSvgProps> = ({
  svgComponent,
  fillColor,
  strokeColor,
  width = '16px',
  height = '16px',
  rounded,
  bgColor = 'primary',
  withPadding = 'large',
  className,
  onClick,
  title,
  id,
  fullWidth = false,
}): JSX.Element => {
  const classes = classNames(
    'eb-icon-svg',
    {
      [`eb-icon-svg--fill-${fillColor ?? ''}`]: fillColor,
      [`eb-icon-svg--stroke-${strokeColor ?? ''}`]: strokeColor,
      [`eb-icon-svg--bg-color-${bgColor}`]: bgColor && rounded,
      [`p-${withPadding === 'small' ? '2' : withPadding === 'medium' ? '3' : '4'}`]:
        bgColor && rounded,
      'rounded-full': rounded,
      'cursor-pointer': onClick,
      'w-full': fullWidth,
    },
    className
  );

  return (
    <div
      aria-hidden
      className={classes}
      data-testid="icon-svg-component"
      id={id}
      style={!fullWidth ? { width, height } : {}}
      title={title}
      onClick={onClick}
    >
      {svgComponent}
    </div>
  );
};

export default memo(IconSvg);
