import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouterSkeleton from '@/src/modules/SendersModule/components/RouterSkeleton';

const SendersLayout = lazy(() => import('../layout/SendersLayout'));
const SendersMain = lazy(() => import('../Screens/SendersMain'));
const SenderForm = lazy(() => import('../Screens/SenderForm'));
const DomainValidator = lazy(() => import('../Screens/DomainValidator/DomainValidator'));

export const SendersModuleRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route element={<SendersLayout />} path="/*">
          <Route element={<SendersMain />} path="senders" />
          <Route element={<DomainValidator />} path="senders/domains/validator" />
        </Route>

        <Route element={<SenderForm />} path="/senders/create" />
        <Route element={<SenderForm />} path="/senders/edit/:id" />
      </Routes>
    </Suspense>
  );
};
