/* cSpell:disable */

type HappinessProps = {
  tableSize?: boolean;
  secondary?: boolean;
};

export const HappinessIcon = ({ tableSize, secondary }: HappinessProps) => {
  return (
    <>
      {
        <svg
          height={tableSize ? 19 : 18}
          viewBox="0 0 19.549 18.279"
          width={tableSize ? 19 : 18}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="like-svgrepo-com" transform="translate(0 -16.331)">
            <g data-name="Grupo 4683" id="Grupo_4683" transform="translate(0 16.331)">
              <path
                d="M19.245,24.855a3.307,3.307,0,0,0-3.323-2.165H13.987a7.656,7.656,0,0,0,.691-3.1,6.2,6.2,0,0,0-.095-1.1,2.346,2.346,0,0,0-2.737-2.14c-.577.066-.775.614-1.253,1.942a18.871,18.871,0,0,1-1.729,3.821,6.47,6.47,0,0,1-2.48,2.027v-.11H0v9.486H6.386v-.523a8.058,8.058,0,0,1,1.711.023l1.143.664.854.533c.83.447,4.459.49,6.015.259a2.988,2.988,0,0,0,2.5-2C18.771,31.926,20.148,27.046,19.245,24.855Zm-1.837,7.27a1.751,1.751,0,0,1-1.481,1.1,17.984,17.984,0,0,1-5.241-.122l-.758-.475-1.435-.806a7.685,7.685,0,0,0-2.107-.079V25.5a7.883,7.883,0,0,0,3.53-2.7,19.786,19.786,0,0,0,1.859-4.081s.368-.917.457-1.129c.636,0,.987.353,1.117,1.111a5.077,5.077,0,0,1,.072.876,6.86,6.86,0,0,1-.98,3.4l-.637.971,4.116,0a2.076,2.076,0,0,1,2.164,1.39C18.71,26.847,17.8,30.751,17.408,32.125Z"
                data-name="Trazado 3463"
                fill={secondary ? '#96A6CE' : '#004dbc'}
                id="Trazado_3463"
                transform="translate(0 -16.331)"
              />
            </g>
          </g>
        </svg>
      }
    </>
  );
};
