import classNames from 'classnames';
import { memo, ReactNode } from 'react';
import { CalendarContainer } from 'react-datepicker';

export interface DateInputContainerProps {
  children: ReactNode;
  className?: string;
}

const DateInputContainer = ({ className, children }: DateInputContainerProps) => {
  const classes = classNames('min-w-[250px]', className);

  return (
    <CalendarContainer className={classes}>
      <div className="relative flex flex-col">{children}</div>
    </CalendarContainer>
  );
};

export default memo(DateInputContainer);
