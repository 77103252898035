import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';
import { numberToCurrency } from '@/src/utils/currency';

import { atomContractDetail } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { atomPlansInformation } from '@/modules/MyPlanModule/atoms/ManagePlan';
import { CardPlan } from '@/modules/MyPlanModule/components';
import { useDisabledPlanButton } from '@/modules/MyPlanModule/hooks/useDisabledPlanButton';
import { useFrequencyOptions } from '@/modules/MyPlanModule/hooks/useFrequencyOptions';
import { usePlanAdditionalInfo } from '@/modules/MyPlanModule/hooks/usePlanAdditionalInfo';
import { HandleSelectedPlan } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';
import { PlansIds } from '@/modules/MyPlanModule/types';
interface PlansProps {
  frequency: Frequency;
  onSelectedPlan: HandleSelectedPlan;
  downgradeIsPending?: boolean;
}

const Plans = ({ frequency, onSelectedPlan, downgradeIsPending }: PlansProps) => {
  const { t } = useTranslation();
  const { planAdditionalInfo } = usePlanAdditionalInfo();
  const { frequencyOptions } = useFrequencyOptions();
  const contractDetail = useAtomValue(atomContractDetail);

  const plans = useAtomValue(atomPlansInformation);

  const handleClickSelectedPlan = (id: string) => {
    onSelectedPlan(id as PlansIds);
  };

  const { isDisabledButton } = useDisabledPlanButton();

  return (
    <div className="mt-4 grid w-full grid-cols-1 gap-8 px-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {plans?.map((plan) => {
        const {
          id,
          name,
          price,
          actualPlan,
          volume: { email },
        } = plan;

        let textButton = t('MANAGE_PLAN.PLANS.selectPlan');
        if (name === 'Enterprise') textButton = t('MANAGE_PLAN.PLANS.requestQuote');
        if (actualPlan) textButton = t('MANAGE_PLAN.PLANS.actualPlanText');

        const includesAmount = numberToCurrency(
          email && email.volumeSteps.length ? email.volumeSteps[0].min : 0,
          true
        );

        let includesText = t('MANAGE_PLAN.PLANS.includesFree');
        if (name === 'Starter' || name === 'Advance') {
          includesText = t('MANAGE_PLAN.PLANS.includesText', {
            amount: includesAmount,
          });
        }

        const disabledButton = isDisabledButton(name, contractDetail?.billingPeriod);

        return (
          <CardPlan
            key={id}
            buttonText={textButton}
            disabledButton={
              (actualPlan && name === 'Free') ||
              disabledButton ||
              (name !== 'Enterprise' && downgradeIsPending)
            }
            features={planAdditionalInfo[name].features}
            frequency={frequency.value as 'monthly' | 'annually'}
            frequencyPriceSuffix={frequencyOptions[0].priceSuffix}
            id={id}
            includesText={includesText}
            isCurrentPlan={actualPlan}
            moreFeaturesPlanText={
              planAdditionalInfo[name].featureInclude &&
              `+ ${planAdditionalInfo[name].featureInclude ?? ''}`
            }
            name={planAdditionalInfo[name].name}
            noPrice={name === 'Enterprise'}
            price={price}
            subTitle={t('MANAGE_PLAN.PLANS.contactBtn')}
            onClick={handleClickSelectedPlan}
          />
        );
      })}
    </div>
  );
};

export default Plans;
