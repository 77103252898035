import { useAtomValue } from 'jotai';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Checkbox, Text } from '@/lib/v2/components';

import { atomTouchRules } from '@/src/modules/RulesModule/atoms/rules';

import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ITouchRulesCheckboxProps<T extends FieldValues> {
  form: IFormProps<T>;
  disabled?: boolean;
}
export const TouchRulesCheckbox = <T extends FieldValues>({
  form,
  disabled = false,
}: ITouchRulesCheckboxProps<T>) => {
  const { t } = useTranslation('rules');
  const { actionId } = useParams();

  const { action } = useActionData(Number(actionId));

  const touchRulesCheck = useAtomValue(atomTouchRules);
  const [touchRules, setTouchRules] = useState(touchRulesCheck ?? action?.touchRules);

  const onCheckedChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setTouchRules(e.target.checked);
  }, []);

  return (
    <div className="mt-8 flex">
      <Checkbox
        checked={touchRules}
        control={form.control}
        disabled={disabled}
        id="touchRules"
        label={
          <Text variant="text">
            {t(`${TRANSLATE_RULES_LABELS}.subordinate`)}{' '}
            <strong>{t(`${TRANSLATE_RULES_LABELS}.touchRule`)}</strong>
          </Text>
        }
        name="touchRules"
        onChange={onCheckedChange}
      />
    </div>
  );
};
