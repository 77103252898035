export const AttachmentIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M29.7443 8.72812C29.2792 8.27866 28.6813 7.94572 28.0169 7.7959V16.6187C28.0169 18.8161 26.3227 20.614 24.2632 20.614C22.4693 20.614 20.9579 19.2489 20.5925 17.4511L18.4332 19.5153C17.0713 20.8137 14.912 20.8137 13.5667 19.5153L2.23905 8.72812C1.55806 9.37735 1.14282 10.2763 1.14282 11.2751V26.6901C1.14282 28.671 2.78716 30.2858 4.83012 30.2858H27.1698C29.2128 30.2858 30.8571 28.671 30.8571 26.6901V11.2751C30.8571 10.2763 30.4419 9.37735 29.7609 8.72812H29.7443Z"
          fill="#0056B8"
        />
        <path
          d="M18.4335 17.4009L20.5096 15.37V7.6958H4.83032C4.48152 7.6958 4.13273 7.74574 3.80054 7.84562L13.5503 17.4176C14.9123 18.7493 17.0715 18.7493 18.4168 17.4176L18.4335 17.4009Z"
          fill="#0056B8"
        />
        <path
          d="M20.3268 6.63056V6.31427C20.3268 5.69833 20.5593 5.14899 20.9579 4.73282C21.3898 4.3 22.0043 4.08359 22.6521 4.08359C23.9642 4.08359 24.911 5.03246 24.9276 6.31427C24.9276 8.46171 24.9276 11.4415 24.9608 13.9552V16.0527C24.9608 16.4023 24.8778 16.6686 24.6951 16.8018C24.5954 16.885 24.3961 16.9683 24.0971 16.9183C23.549 16.8018 23.549 16.3357 23.549 16.0194C23.549 13.6222 23.549 9.82675 23.5158 7.42961V7.12996H21.7552V8.04554C21.7552 9.24411 21.7552 10.8589 21.7552 12.4403V16.1359C21.7552 16.885 22.0209 17.5509 22.4694 18.017C22.9179 18.4831 23.5656 18.7328 24.2632 18.7328C25.6916 18.7328 26.738 17.6341 26.738 16.1526V15.237C26.738 12.4902 26.738 8.71141 26.6882 6.13115C26.655 4.13353 25.1435 2.53543 23.0673 2.30238C21.041 2.08597 19.1475 3.35113 18.6492 5.23222V5.28216C18.5828 5.46528 18.533 5.66504 18.533 5.83151V6.59726H20.2936V6.63056H20.3268Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
