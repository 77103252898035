import { Outlet } from 'react-router-dom';

import { HeaderSectionActions } from '@/src/modules/CampaignsModule/components/HeaderSectionActions';

const ActionManagementLayout = () => {
  return (
    <div className="bg-emblue-white">
      <HeaderSectionActions />
      <Outlet />
    </div>
  );
};

export default ActionManagementLayout;
