export const Warning2Icon = ({ color = '#FFB114' }) => {
  return (
    <svg fill="none" height="14" viewBox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.64325 1.44016L1.05351 11.7C0.973775 11.8479 0.933872 12.0142 0.937759 12.1824C0.941646 12.3506 0.989189 12.5148 1.07567 12.6589C1.16215 12.8029 1.28457 12.9216 1.43078 13.0034C1.57699 13.0852 1.74192 13.127 1.90919 13.1249H13.0919C13.259 13.1266 13.4237 13.0844 13.5696 13.0025C13.7155 12.9206 13.8377 12.8019 13.9241 12.658C14.0105 12.5142 14.0581 12.3502 14.0622 12.1822C14.0663 12.0142 14.0268 11.848 13.9476 11.7L8.35379 1.44016C8.26835 1.28764 8.14411 1.16071 7.99382 1.07238C7.84353 0.98405 7.67259 0.9375 7.49852 0.9375C7.32444 0.9375 7.1535 0.98405 7.00321 1.07238C6.85292 1.16071 6.72869 1.28764 6.64325 1.44016ZM8.05438 5.69679L7.94067 9.56923H7.05636L6.94265 5.69679H8.05438ZM7.49811 11.6778C7.38427 11.6734 7.27423 11.6355 7.18166 11.5687C7.0891 11.502 7.0181 11.4093 6.97751 11.3023C6.93691 11.1952 6.92852 11.0785 6.95336 10.9667C6.9782 10.8549 7.03519 10.7529 7.11723 10.6734C7.19927 10.594 7.30274 10.5405 7.41476 10.5197C7.52679 10.4989 7.64242 10.5117 7.74727 10.5565C7.85212 10.6013 7.94156 10.6761 8.00445 10.7716C8.06735 10.8671 8.10093 10.9791 8.10101 11.0937C8.10028 11.1721 8.08402 11.2495 8.05319 11.3215C8.02236 11.3935 7.97757 11.4586 7.92143 11.513C7.8653 11.5674 7.79895 11.6099 7.72627 11.6382C7.65358 11.6665 7.57602 11.68 7.49811 11.6778Z"
        fill={color}
      />
    </svg>
  );
};
