import { IconProps } from './iconPropTypes';

export const RemarketingTableIcon = ({ color }: IconProps) => {
  return (
    <svg
      height="17.442"
      id="target"
      viewBox="0 0 18.202 17.442"
      width="18.202"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.127,24.253h0a.421.421,0,0,0,.367.118c.026,0,5.925-1.665,6.41-1.8.026-.013.052-.013.066-.026a.423.423,0,0,0,.21-.367A.391.391,0,0,0,63,21.854c-.026-.026-3.054-1.416-3.054-1.416h0s-1.39-3.028-1.4-3.054a.445.445,0,0,0-.328-.184.409.409,0,0,0-.367.21c-.013.026-.026.039-.026.066-.131.485-1.8,6.384-1.8,6.41A.383.383,0,0,0,56.127,24.253Z"
        data-name="Path 3294"
        fill={`${color}`}
        id="Path_3294"
        transform="translate(-46.852 -15.339)"
      />
      <path
        d="M16.875,7.775l.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043a.435.435,0,0,0,0-.629.483.483,0,0,0-.656,0l-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043-.03.029L16.368,7l-.045.043-.03.029-.03.029-.045.043a.435.435,0,0,0,0,.629A.483.483,0,0,0,16.875,7.775Zm1.671-2.859-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043a.436.436,0,0,0,0,.629.483.483,0,0,0,.656,0l.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043a.436.436,0,0,0,0-.629A.483.483,0,0,0,18.546,4.916Zm2.521-1.787a.483.483,0,0,0-.656,0l-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043-.03.029-.03.029-.045.043a.436.436,0,0,0,0,.629.483.483,0,0,0,.656,0l.045-.043.03-.029.03-.029.045-.043.03-.029L20.814,4l.045-.043.03-.029.03-.029.045-.043.03-.029.03-.029.045-.043A.435.435,0,0,0,21.068,3.129Zm-2.059,8.649-.179.043c-.671.186-1.283.343-1.835.5,0,.114.015.229.015.357A5.792,5.792,0,0,1,11.1,18.34a5.793,5.793,0,0,1-5.908-5.661A5.783,5.783,0,0,1,11.1,7.017a3,3,0,0,1,.358.014c.164-.586.358-1.229.567-1.944a.959.959,0,0,1,.045-.114,7.207,7.207,0,0,0-.97-.057A7.954,7.954,0,0,0,3,12.679a7.954,7.954,0,0,0,8.1,7.763,7.945,7.945,0,0,0,8.1-7.763,6.679,6.679,0,0,0-.06-.944A.187.187,0,0,1,19.009,11.778Z"
        data-name="Path 3295"
        fill={`${color}`}
        id="Path_3295"
        transform="translate(-3 -3)"
      />
      <path
        d="M34.243,47.289c.118-.406.3-1.088.616-2.189a3.366,3.366,0,1,0,3.566,3.553c-1.088.3-1.77.5-2.176.616a1.426,1.426,0,0,1-2.609-.8A1.4,1.4,0,0,1,34.243,47.289Z"
        data-name="Path 3296"
        fill={`${color}`}
        id="Path_3296"
        transform="translate(-27.041 -38.718)"
      />
    </svg>
  );
};
