import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import CardAction, { CardActionProps } from '@/lib/v2/organisms/CardAction/CardAction';

import { CallIcon } from '@/src/presentation/util/icon/CallIcon';
import { CountdownIcon } from '@/src/presentation/util/icon/CountdownIcon';
import { FormWidgetIcon } from '@/src/presentation/util/icon/FormWidgetIcon';
import { GoogleMeetIcon } from '@/src/presentation/util/icon/GoogleMeetIcon';
import { NpsIcon } from '@/src/presentation/util/icon/NpsIcon';
import { PromotionIcon } from '@/src/presentation/util/icon/PromotionIcon';
import { SkypeIcon } from '@/src/presentation/util/icon/SkypeIcon';
import { SubscriptionIcon } from '@/src/presentation/util/icon/SubscriptionIcon';
import { TwitchIcon } from '@/src/presentation/util/icon/TwitchIcon';
import { VideoPromotionIcon } from '@/src/presentation/util/icon/VideoPromotionIcon';
import { VideoSubscriptionIcon } from '@/src/presentation/util/icon/VideoSubscriptionIcon';
import { VimeoIcon } from '@/src/presentation/util/icon/VimeoIcon';
import { WhatsAppIcon } from '@/src/presentation/util/icon/WhatsAppIcon';
import { YoutubeIcon } from '@/src/presentation/util/icon/YoutubeIcon';
import { ZoomIcon } from '@/src/presentation/util/icon/ZoomIcon';

export interface ICardContainer {
  FiltersParams?: string;
}

export const CardContainer = ({ FiltersParams }: ICardContainer) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps
  const widgets = (window as any).__widgets || {};

  widgets.nps = widgets.nps || { active: true, blocked: true };

  const { t } = useTranslation();
  const cards: CardActionProps[] = useMemo(
    () => [
      {
        title: t('WIDGET_FORMS.title'),
        description: t('WIDGET_FORMS.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('form');
        },
        icon: <IconSvg height="53px" svgComponent={<FormWidgetIcon />} width="53px" />,
        active: true,
        withLabel:
          widgets.form && widgets.form.blocked ? t('WIDGET_FORMS.labelUpgradePlan') : undefined,
        btnTooltip:
          widgets.form && widgets.form.blocked ? t('WIDGET_FORMS.tooltipUpgradePlan') : undefined,
      },
      {
        title: t('WIDGET_PROMOTION.title'),
        description: t('WIDGET_PROMOTION.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('promotion');
        },
        icon: <IconSvg height="53px" svgComponent={<PromotionIcon />} width="53px" />,
        active: widgets.promotion ? widgets.promotion.active : true,
      },
      {
        title: t('WIDGET_VIDEO_PROMOTION.title'),
        description: t('WIDGET_VIDEO_PROMOTION.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('videoPromotion');
        },
        icon: <IconSvg height="53px" svgComponent={<VideoPromotionIcon />} width="53px" />,
        active: widgets.videoPromotion ? widgets.videoPromotion.active : true,
      },
      {
        title: t('WIDGET_SUBSCRIPTION.title'),
        description: t('WIDGET_SUBSCRIPTION.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('subscription');
        },
        icon: <IconSvg height="53px" svgComponent={<SubscriptionIcon />} width="53px" />,
        active: widgets.subscription ? widgets.subscription.active : true,
      },
      {
        title: t('WIDGET_VIDEO_SUBSCRIPTION.title'),
        description: t('WIDGET_VIDEO_SUBSCRIPTION.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('videoSubscription');
        },
        icon: <IconSvg height="53px" svgComponent={<VideoSubscriptionIcon />} width="53px" />,
        active: widgets.videoSubscription ? widgets.videoSubscription.active : true,
      },
      {
        title: t('WIDGET_GOOGLE_MEET.title'),
        description: t('WIDGET_GOOGLE_MEET.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('googleMeet');
        },
        icon: <IconSvg height="53px" svgComponent={<GoogleMeetIcon />} width="53px" />,
        active: widgets.googleMeet ? widgets.googleMeet.active : true,
      },
      {
        title: t('WIDGET_WHATSAPP.title'),
        description: t('WIDGET_WHATSAPP.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('whatsapp');
        },
        icon: <IconSvg height="53px" svgComponent={<WhatsAppIcon />} width="53px" />,
        active: widgets.whatsapp ? widgets.whatsapp.active : true,
      },
      {
        title: t('WIDGET_SKYPE.title'),
        description: t('WIDGET_SKYPE.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('skypeChat');
        },
        icon: <IconSvg height="53px" svgComponent={<SkypeIcon />} width="53px" />,
        active: widgets.skypeChat ? widgets.skypeChat.active : false,
      },
      {
        title: t('WIDGET_NPS.title'),
        description: t('WIDGET_NPS.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('nps');
        },
        icon: <IconSvg height="53px" svgComponent={<NpsIcon />} width="53px" />,
        active: widgets.nps ? widgets.nps.active : true,
        withLabel:
          widgets.nps && widgets.nps.blocked ? t('WIDGET_NPS.labelProfessionalPlan') : undefined,
        btnTooltip:
          widgets.nps && widgets.nps.blocked ? t('WIDGET_NPS.tooltipProfessionalPlan') : undefined,
      },
      {
        title: t('WIDGET_COUNTDOWN.title'),
        description: t('WIDGET_COUNTDOWN.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('countdown');
        },
        icon: <IconSvg height="53px" svgComponent={<CountdownIcon />} width="53px" />,
        active: widgets.countdown ? widgets.countdown.active : true,
      },
      {
        title: t('WIDGET_CALL.title'),
        description: t('WIDGET_CALL.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('phoneCall');
        },
        icon: <IconSvg height="53px" svgComponent={<CallIcon />} width="53px" />,
        active: widgets.phoneCall ? widgets.phoneCall.active : true,
      },
      {
        title: t('WIDGET_YOUTUBE.title'),
        description: t('WIDGET_YOUTUBE.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('youtubeLive');
        },
        icon: <IconSvg height="53px" svgComponent={<YoutubeIcon />} width="53px" />,
        active: widgets.youtubeLive ? widgets.youtubeLive.active : true,
      },
      {
        title: t('WIDGET_TWITCH.title'),
        description: t('WIDGET_TWITCH.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('twitchLive');
        },
        icon: <IconSvg height="53px" svgComponent={<TwitchIcon />} width="53px" />,
        active: widgets.twitchLive ? widgets.twitchLive.active : false,
      },
      {
        title: t('WIDGET_VIMEO.title'),
        description: t('WIDGET_VIMEO.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('vimeoLive');
        },
        icon: <IconSvg height="53px" svgComponent={<VimeoIcon />} width="53px" />,
        active: widgets.vimeoLive ? widgets.vimeoLive.active : true,
      },
      {
        title: t('WIDGET_ZOOM.title'),
        description: t('WIDGET_ZOOM.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('zoomCall');
        },
        icon: <IconSvg height="53px" svgComponent={<ZoomIcon />} width="53px" />,
        active: widgets.zoomCall ? widgets.zoomCall.active : true,
      },
      {
        title: t('WIDGET_SKYPE_CALL.title'),
        description: t('WIDGET_SKYPE_CALL.desc'),
        buttonText: t('ONSITE_DASHBOARD.buttonSelect'),
        onClickAction: () => {
          window.createNewWidgetV2('skypeCall');
        },
        icon: <IconSvg height="53px" svgComponent={<SkypeIcon />} width="53px" />,
        active: widgets.skypeCall ? widgets.skypeCall.active : false,
      },
    ],
    [t, widgets]
  );

  return (
    <div className="left grid w-full grid-cols-1 gap-6 pb-8  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {cards
        .filter((card) => card.active)
        .map((card) => {
          const { title, description, icon, onClickAction, buttonText, btnTooltip, withLabel } =
            card;
          const lineClamp = 'line-clamp-2';
          FiltersParams = FiltersParams
            ? FiltersParams.charAt(0).toUpperCase() + FiltersParams.slice(1)
            : FiltersParams;
          const isFiltered =
            !FiltersParams || title.toLowerCase().includes(FiltersParams.toLowerCase());
          return (
            isFiltered && (
              <button
                className="locked-card m-0 border-0 bg-transparent p-0"
                disabled={withLabel ? true : false}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  onClickAction?.(e);
                }}
              >
                <CardAction
                  btnTooltip={btnTooltip}
                  buttonText={buttonText}
                  descLineClamp={lineClamp}
                  description={description}
                  icon={icon}
                  title={title}
                  tooltip={description}
                  withLabel={withLabel}
                  onClickAction={onClickAction}
                />
              </button>
            )
          );
        })}
    </div>
  );
};
export default CardContainer;
