import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card } from '@/lib/components';
import { Flex, IconSvg, Text } from '@/lib/v2/components';
import { EmblueAcademyIcon } from '@/lib/v2/icons/general/EmblueAcademyIcon';

const EmblueAcademyCard = ({
  courseNameUrl,
  thumbnailsUrl,
}: {
  courseNameUrl: string;
  thumbnailsUrl: string;
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Flex column className="gap-6">
        <IconSvg height={'34px'} svgComponent={<EmblueAcademyIcon />} width={'214px'} />
        <Link
          className="relative w-full cursor-pointer overflow-hidden p-5 pb-[50%]"
          rel="noopener noreferrer"
          target="_blank"
          to={courseNameUrl}
        >
          <img
            alt={t('ONBOARDING.EMBLUE_ACADEMY.title')}
            className="absolute left-0 top-0 size-full object-cover"
            src={thumbnailsUrl}
          />
        </Link>
        <Text
          alignment="center"
          as="h3"
          className="leading-tight"
          color="black"
          fontWeight="medium"
          variant="sub-headline"
        >
          {t('ONBOARDING.EMBLUE_ACADEMY.title')}
        </Text>
      </Flex>
    </Card>
  );
};

export default EmblueAcademyCard;
