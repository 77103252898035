import { t } from 'i18next';
import { useCallback } from 'react';

import { toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useChangeWidgetStatus = (
  accountIdEmblue: string,
  onsiteToken: string
): {
  changeWidgetStatus: (
    newStatusNps: number,
    widgetId: number
  ) => Promise<{ widgetId: number; responseStatus?: string }>;
} => {
  const service = useService();
  onsiteToken && service?.setOnsiteToken(onsiteToken);

  const changeWidgetStatus = useCallback(
    async (newStatusNps: number, widgetId: number) => {
      const result = await service?.changeStatusWidgetNPS({
        widgetId,
        accountIdEmblue,
        newStatusNps,
      });

      if (newStatusNps) {
        toast({
          title:
            result?.responseStatus === 'OK'
              ? t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ActivatedTitle')
              : t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ErrorActivatedTitle'),
          body:
            result?.responseStatus === 'OK'
              ? t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ActivatedBody')
              : t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ErrorActivatedBody'),
          variant: result?.responseStatus === 'OK' ? 'success' : 'error',
        });
      } else {
        toast({
          title:
            result?.responseStatus === 'OK'
              ? t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.DeactivatedTitle')
              : t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ErrorDeactivatedTitle'),
          body:
            result?.responseStatus === 'OK'
              ? t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.DeactivatedBody')
              : t('NPS_SCREEN.NOTIFICATION_CHANGE_STATUS.ErrorDeactivatedBody'),
          variant: result?.responseStatus === 'OK' ? 'success' : 'error',
        });
      }
      return {
        widgetId,
        responseStatus: result?.responseStatus,
      };
    },
    [accountIdEmblue, service]
  );

  return { changeWidgetStatus };
};
