import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/lib/v2/components/Layout/Stack';

import { GroupWidgetsIcon } from '@/src/presentation/util/icon/GroupWidgetsIcon';

import CardContainer from './CardContainer';

import { OnsiteFilters } from '@/modules/OnSiteModule/components/OnsiteFilters';

const OnsiteScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>();

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleOnClear = () => {
    setSearchValue('');
  };

  return (
    <div className="bg-emblueLightGray">
      <div className="mx-8 min-h-screen">
        <section className="flex flex-row justify-between py-6">
          <Flex noGrow withGap itemAlignment="center">
            <GroupWidgetsIcon />
            <p className="text-[24px] font-medium leading-none text-[#364365]">
              {t('ONSITE_DASHBOARD.onsitePlugins')}
            </p>
          </Flex>
        </section>
        <span className="text-[16px]">{t('ONSITE_DASHBOARD.description')}</span>
        <section className="flex flex-row justify-between py-6">
          <Flex noGrow withGap itemAlignment="center">
            <OnsiteFilters onReset={handleOnClear} onSearch={handleOnSearch} />
          </Flex>
        </section>
        <CardContainer FiltersParams={searchValue} />
      </div>
    </div>
  );
};

export default OnsiteScreen;
