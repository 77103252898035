import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Table } from '@/lib/components/Table/Table';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';
import { Spinner } from '@/lib/components/Util/Spinner';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { TrackEvent } from '@/src/application/util/mixPanel';
import configData from '@/src/config.json';
import {
  IReport,
  ReportDataDetails,
  ReportDataElement,
} from '@/src/presentation/types/interfaces/IReport.interface';
import { convertToReportDataDTO, ReportDataDTO } from '@/src/presentation/types/types';
import { mixPanelEvents } from '@/src/presentation/util/enum/mixPanelEventsEnum';
import {
  ActionTableIcon,
  ClickTableIcon,
  DetailTableIcon,
  MailTableIcon,
  RatioTableIcon,
  TriggerTableIcon,
} from '@/src/presentation/util/icon';

import { redirectToActions } from './Main/Cards';

const Message = (reportData: IReport | undefined, userData: UserData, navigate: Function) => {
  if (reportData && reportData.element.id !== '') {
    TrackEvent(userData, mixPanelEvents.SingleReportButton, {
      actionId: reportData.element.id,
      actionName: reportData.element.name,
    });
    const data = convertToReportDataDTO(reportData);
    const message = configData.HOME.GO_TO_SINGLE_REPORT_MESSAGE;
    redirectToReport(RouteEnum.Reports, message, navigate, data);
  }
};
const ReportsTable = ({
  reports,
  userData,
}: {
  reports: IReport[] | undefined;
  userData: UserData;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {!reports && (
        <Flex alignment="center">
          <Spinner />
        </Flex>
      )}
      {reports && (
        <Table
          columns={[
            {
              Header: () => (
                <Flex alignment="start">
                  <ActionTableIcon />
                  <span>{t('REPORT_TABLE.action')}</span>
                </Flex>
              ),
              accessor: 'element',
              Cell: ({ cell: { value } }: { cell: { value: ReportDataElement } }) => (
                <Flex alignment="start">
                  {value.type.toLowerCase() === 'trigger' ? (
                    <TriggerTableIcon />
                  ) : (
                    <MailTableIcon />
                  )}
                  <div className="flex flex-col">
                    <p style={{ alignSelf: 'start', marginLeft: 5 }}> {value.name}</p>
                    <small className="text-gray-400" style={{ alignSelf: 'start', marginLeft: 5 }}>
                      {value.campaignName}
                    </small>
                  </div>
                </Flex>
              ),
            },
            {
              Header: () => (
                <Flex alignment="start">
                  <DetailTableIcon />
                  <span>{t('REPORT_TABLE.details')}</span>
                </Flex>
              ),
              accessor: 'details',
              Cell: ({ cell: { value } }: { cell: { value: ReportDataDetails } }) => (
                <Flex alignment="start">
                  <div className="flex flex-col">
                    <p style={{ alignSelf: 'start', marginLeft: 5 }}> {t('REPORT_TABLE.sent')} </p>
                    <small className="text-gray-400" style={{ alignSelf: 'start', marginLeft: 5 }}>
                      {value.deliveries} {t('REPORT_TABLE.people')} - {value.date}
                    </small>
                  </div>
                </Flex>
              ),
            },
            {
              Header: () => (
                <Flex alignment="start">
                  <RatioTableIcon />
                  <span>{t('REPORT_TABLE.openingRatio')}</span>
                </Flex>
              ),
              accessor: 'OR',
              Cell: ({ cell: { value } }: { cell: { value: string } }) => (
                <Flex alignment="start">
                  <p style={{ alignSelf: 'start', marginLeft: 5 }}> {value}% </p>
                </Flex>
              ),
            },
            {
              Header: () => (
                <Flex alignment="start">
                  <ClickTableIcon />
                  <span>{t('REPORT_TABLE.clickRatio')}</span>
                </Flex>
              ),
              accessor: 'CTR',
              Cell: ({ cell: { value } }: { cell: { value: string } }) => (
                <Flex alignment="start">
                  <p style={{ alignSelf: 'start', marginLeft: 5 }}> {value}% </p>
                </Flex>
              ),
            },
          ]}
          data={reports}
          onRowClick={(value) => {
            Message(
              reports.find((item: IReport) => {
                return item.element.id === value.element.id;
              }),
              userData,
              navigate
            );
          }}
        />
      )}
    </>
  );
};

const goToAllReportsHandler = (userData: UserData, version: string, navigate: Function) => {
  TrackEvent(userData, mixPanelEvents.GoToReportsButton);

  if (version === 'v2') {
    const message = configData.HOME.GO_TO_REPORTS_MESSAGE;
    redirectToActions(RouteEnum.Reports, message, navigate);
  } else {
    window.parent.postMessage({ message: configData.HOME.GO_TO_REPORTS_MESSAGE }, '*');
  }
};

const redirectToReport = (
  route: string,
  message: string,
  navigate: Function,
  data?: ReportDataDTO
) => {
  navigate(`/v2${route}`);

  setTimeout(() => {
    const iframe = document.getElementById('v1-container') as HTMLIFrameElement;
    const createActions = {
      payload: {
        event: 'HomeActions',
        message: message,
        data: data,
      },
    };
    if (iframe) {
      iframe && iframe.contentWindow?.postMessage(createActions, '*');
    }
  }, 500);
};

export { goToAllReportsHandler, ReportsTable };
