import { memo } from 'react';

import BillingMonth from './BillingMonth';
import LastInvoice from './LastInvoice';
import PlanDetail from './PlanDetail';

const InformationMyPlan = () => {
  return (
    <div className="mt-4 flex flex-col items-stretch gap-4 md:flex-row">
      <PlanDetail />
      <div className="flex basis-1/3 flex-col gap-4">
        <BillingMonth />
        <LastInvoice />
      </div>
    </div>
  );
};

export default memo(InformationMyPlan);
