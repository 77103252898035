export enum CardType {
  Status = 'status',
  Segment = 'segment',
  Ecommerce = 'ecommerce',
}

export enum ECommerceEnum {
  VTEX = 1,
  PRESTASHOP = 15,
  MAGENTO2 = 16,
  SHOPIFY = 19,
}

export enum CurrencySymbol {
  USD = '$',
}

export enum WidgetNPS {
  ID = '21',
}

export enum ERecurrenceType {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
}
