export const CountryIcon = () => {
  return (
    <svg height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.049,10.111H4.778a1.778,1.778,0,0,1,1.778,1.778v.889a1.778,1.778,0,0,0,1.778,1.778,1.778,1.778,0,0,1,1.778,1.778v2.618M7.444,3.831V5.222A2.222,2.222,0,0,0,9.667,7.444h.444a1.778,1.778,0,0,1,1.778,1.778,1.778,1.778,0,0,0,3.556,0,1.778,1.778,0,0,1,1.778-1.778h.946m-4.5,11.1V16.333a1.778,1.778,0,0,1,1.778-1.778h2.724M19,11a8,8,0,1,1-8-8A8,8,0,0,1,19,11Z"
        data-name="Path 3201"
        fill="none"
        id="Path_3201"
        stroke="#005adc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-2.5 -2.5)"
      />
    </svg>
  );
};
