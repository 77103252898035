import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { atomRefetchAllData } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDeleteModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';

interface DeleteCampaignsProps {
  campaigns: number[];
  redirect?: boolean;
}

const DeleteCampaignsRecurrent = ({ campaigns, redirect = false }: DeleteCampaignsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const [toggleDeleteModal, setToggleDeleteModal] = useAtom(atomToggleDeleteModal);

  const { deleteRecurrent, loading } = useCampaignRecurrent();

  const handleConfirmDeleteCampaignsRecurrent = useCallback(async () => {
    await deleteRecurrent(campaigns);
    setToggleDeleteModal(false);
    redirect ? navigate('/v2/campaigns/recurrent') : setRefetchAllData(true);
  }, [deleteRecurrent, campaigns, setToggleDeleteModal, redirect, navigate, setRefetchAllData]);

  return (
    <ModalConfirmation
      description={
        campaigns?.length > 1
          ? t('RECURRENT_MESSAGE.DELETE_RECURRENT.MULTIPLE.description')
          : t('RECURRENT_MESSAGE.DELETE_RECURRENT.SINGLE.description')
      }
      isLoading={loading}
      isOpen={toggleDeleteModal}
      textCancelButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.cancelButton')}
      textConfirmButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.successButton')}
      title={
        campaigns?.length > 1
          ? t('RECURRENT_MESSAGE.DELETE_RECURRENT.MULTIPLE.title')
          : t('RECURRENT_MESSAGE.DELETE_RECURRENT.SINGLE.title')
      }
      onClose={setToggleDeleteModal}
      onConfirmButton={handleConfirmDeleteCampaignsRecurrent}
    />
  );
};

export default DeleteCampaignsRecurrent;
