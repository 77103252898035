import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Flex, IconSvg, If, Spinner, Text } from '@/lib/v2/components';
import { CardTemplate } from '@/lib/v2/organisms';
import { CardTemplateProps } from '@/lib/v2/organisms/CardTemplate/CardTemplate';

import { WhatsAppTemplate } from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { useFilteredTemplates } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/hooks/useFilteredTemplates';
import { useOpenNewConversation } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/hooks/useOpenNewConversation';
import { TemplatesIcon } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/images/icons';

import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';
import {
  atomIsLoadingWhatsAppTemplates,
  atomWhatsAppTemplateSelected,
} from '@/modules/ContactsModule/atoms/WhatsApp';
import { CONTACT_PATH } from '@/modules/ContactsModule/constants';

interface TemplatesContainerProps {
  filterBy?: 'pending' | 'approved' | 'rejected';
  searchTerm?: string;
  onClose?: () => void;
}

const TemplatesContainer = ({
  filterBy = 'approved',
  searchTerm = '',
  onClose,
}: TemplatesContainerProps) => {
  const { t } = useTranslation('inbox');
  const location = useLocation();

  const { openNewConversation } = useOpenNewConversation();
  const { filteredTemplates } = useFilteredTemplates({ filterBy, searchTerm });

  const isLoadingTemplates = useAtomValue(atomIsLoadingWhatsAppTemplates);
  const setAtomWhatsAppTemplateSelected = useSetAtom(atomWhatsAppTemplateSelected);

  const isCampaignPath = location.pathname.includes(CAMPAIGNS_PATH);
  const isContactPath = location.pathname.includes(CONTACT_PATH);

  const onSelectTemplate = useCallback(
    async (template: WhatsAppTemplate) => {
      if (isCampaignPath) {
        setAtomWhatsAppTemplateSelected(template);
      }

      if (isContactPath) {
        await openNewConversation({
          templateContent: template.types['twilio/text']?.body || '',
          templateId: template.sid,
        });
      }

      onClose && onClose();
    },
    [isCampaignPath, isContactPath, onClose, openNewConversation, setAtomWhatsAppTemplateSelected]
  );

  const cardTemplatesList: CardTemplateProps[] = useMemo(
    () =>
      filteredTemplates.map((template: WhatsAppTemplate) => ({
        title: template.friendly_name,
        description: template.types['twilio/text']?.body || '',
        id: template.sid,
        buttonText: isCampaignPath
          ? t('INBOX.SEND_WHATSAPP_MODAL.apply')
          : t('INBOX.SEND_WHATSAPP_MODAL.send'),
        latestUpdate: formatDate(template.date_updated, DateFormat.ShortDate),
        onClick: () => onSelectTemplate(template),
      })),
    [filteredTemplates, isCampaignPath, t, onSelectTemplate]
  );

  return (
    <>
      <If condition={isLoadingTemplates}>
        <Flex alignment="center" className="min-h-[500px]" itemAlignment="center">
          <Spinner withoutOverlay />
        </Flex>
      </If>
      <If condition={!isLoadingTemplates && filteredTemplates.length > 0}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {cardTemplatesList.map((template) => (
            <CardTemplate key={template.id} {...template} />
          ))}
        </div>
      </If>
      <If condition={!isLoadingTemplates && filteredTemplates.length === 0}>
        <Flex
          column
          withGap
          alignment="center"
          className="min-h-[500px]"
          gapSize="medium"
          id="empty-template-container"
          itemAlignment="center"
        >
          <IconSvg height="130" svgComponent={<TemplatesIcon />} width="160" />
          <Text className="text-center" fontWeight="normal" variant="sub-headline-2">
            {t(
              `INBOX.SEND_WHATSAPP_MODAL.TEMPLATES_CONTAINER.NO_${filterBy.toUpperCase()}_TEMPLATES`
            )}
          </Text>
        </Flex>
      </If>
    </>
  );
};

export default memo(TemplatesContainer);
