export const ImageIcon = () => {
  return (
    <svg height="92" viewBox="0 0 128 92" width="128" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7831" id="Group_7831" transform="translate(2717.082 -1409.668)">
        <g id="Image" opacity="0.5" transform="translate(-2717.082 1409.668)">
          <rect
            data-name="Rectangle 4287"
            fill="#5f7cf9"
            height="91.978"
            id="Rectangle_4287"
            rx="1"
            transform="translate(0 0.022)"
            width="127.97"
          />
          <path
            d="M130,33.089V71.821a4.586,4.586,0,0,1-4.571,4.6H6.571A4.586,4.586,0,0,1,2,71.821v-2.3l56.686-42.78a4.549,4.549,0,0,1,5.486,0L79.3,38.149,103.9,13.355a4.551,4.551,0,0,1,6.491,0Z"
            data-name="Path 4478"
            fill="#8c9eff"
            id="Path_4478"
            transform="translate(-2 15.579)"
          />
          <path
            d="M22.565,20.2a4.547,4.547,0,0,1-1.737-.368,5.253,5.253,0,0,1-1.509-.966,4.621,4.621,0,0,1-.96-5.014,4.127,4.127,0,0,1,2.469-2.484,4.227,4.227,0,0,1,2.606-.276,2.731,2.731,0,0,1,.869.276,3.466,3.466,0,0,1,.823.414l.686.552a5.294,5.294,0,0,1,.96,1.518,4.621,4.621,0,0,1-.96,5.014,5.253,5.253,0,0,1-1.509.966A4.547,4.547,0,0,1,22.565,20.2Z"
            data-name="Path 4479"
            fill="#4062ed"
            id="Path_4479"
            transform="translate(55.149 12)"
          />
          <path
            d="M64.714,6H19V98H64.714a4.586,4.586,0,0,0,4.571-4.6V10.6A4.586,4.586,0,0,0,64.714,6Z"
            data-name="Path 4480"
            fill="#4062ed"
            id="Path_4480"
            transform="translate(58.714 -6)"
          />
          <path
            d="M22.246,12.262l-.686-.552a3.466,3.466,0,0,0-.823-.414,2.73,2.73,0,0,0-.869-.276H19v9.2a4.547,4.547,0,0,0,1.737-.368,5.253,5.253,0,0,0,1.509-.966,4.621,4.621,0,0,0,.96-5.014,5.3,5.3,0,0,0-.96-1.61Z"
            data-name="Path 4481"
            fill="#2f58dd"
            id="Path_4481"
            transform="translate(58.714 12.072)"
          />
          <path
            d="M43.183,13.355,19,37.735V76.421H64.714a4.586,4.586,0,0,0,4.571-4.6V33.089L49.674,13.355a4.551,4.551,0,0,0-6.491,0Z"
            data-name="Path 4482"
            fill="#5f7cf9"
            id="Path_4482"
            transform="translate(58.714 15.579)"
          />
        </g>
      </g>
    </svg>
  );
};
