import config from '@/src/config.json';
import {
  IReport,
  ReportDataDetails,
  ReportDataElement,
} from '@/src/presentation/types/interfaces/IReport.interface';
import { ReportDataDTO } from '@/src/presentation/types/types';
export class Report implements IReport {
  element: ReportDataElement;
  details: ReportDataDetails;
  CTR: number;
  OR: number;
  constructor(element: ReportDataElement, details: ReportDataDetails, CTR: number, OR: number) {
    this.element = element;
    this.details = details;
    this.OR = OR;
    this.CTR = CTR;
  }

  static mapReportsDataDTO(reportsDTO: ReportDataDTO[]): IReport[] {
    const numberOfRows: number = config.DATA_TO_SHOW;
    return reportsDTO.slice(0, numberOfRows).map(
      (reportDTO) =>
        ({
          // cSpell:disable
          element: {
            id: reportDTO.id,
            name: reportDTO.nombre,
            campaignName: reportDTO.nombre_campania,
            type: reportDTO.tipo,
            status: reportDTO.estado,
          },
          details: {
            date: reportDTO.fecha,
            deliveries: reportDTO.Cantidad_Enviados,
          },
          OR: reportDTO.OR,
          CTR: reportDTO.CTR,
          // cSpell:enable
        } as IReport)
    );
  }
}
