import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { CheckConfirmCircleIcon } from '@/lib/icon';

import { atomFiles } from '@/modules/ContactsModule/atoms/Steps';

export type StepProps = {
  id: string;
  name: string;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const Step = ({ steps }: { steps: Array<StepProps> }) => {
  const [files] = useAtom(atomFiles);

  const actualStep = useMemo(() => files?.step ?? 0, [files?.step]);

  return (
    <div className="lg:border-y lg:border-gray-200">
      <nav className="w-full">
        <ol className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-gray-200">
          {steps.map((step, stepIdx) => (
            <li key={step.id} className="relative overflow-hidden lg:flex-1">
              <div
                className={classNames(
                  stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                  stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                  'border border-gray-200 overflow-hidden lg:border-0'
                )}
              >
                {stepIdx < actualStep ? (
                  <>
                    <span
                      aria-hidden="true"
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-center text-sm font-medium'
                      )}
                    >
                      <span className="shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-[#004DBC]">
                          <CheckConfirmCircleIcon />
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col justify-center">
                        <span className="text-sm font-medium text-[#004DBC]">{step.name}</span>
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    {actualStep === stepIdx && (
                      <span
                        aria-hidden="true"
                        className="absolute left-0 top-0 h-full w-1 bg-[#004DBC] lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      />
                    )}
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-center text-sm font-medium'
                      )}
                    >
                      <span className="shrink-0">
                        <span
                          className={`
                                            ${
                                              stepIdx === 0 || actualStep === stepIdx
                                                ? 'border-[#004DBC] text-[#004DBC]'
                                                : 'border-zinc-600'
                                            }
                                            flex h-10 w-10 flex-col items-center justify-center rounded-full border-2
                                            `}
                        >
                          <span className=" h-fit w-fit">{step.id}</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col justify-center">
                        <span
                          className={`${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            (stepIdx === 0 || actualStep === stepIdx) && 'text-[#004DBC]'
                          } text-sm font-medium`}
                        >
                          {step.name}
                        </span>
                      </span>
                    </span>
                  </>
                )}
                {stepIdx !== 0 ? (
                  <>
                    <div aria-hidden="true" className="absolute inset-0 hidden w-3 lg:block">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="none"
                        preserveAspectRatio="none"
                        viewBox="0 0 12 82"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
