import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { Flex, Text } from '@/lib/v2/components';

import TooltipAccountLimit from '@/src/ContactsModule/presentation/Screens/GroupsMain/components/TooltipAccountLimit/TooltipAccountLimit';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

interface TitleProps {
  groupListLength?: number;
  isLoadingGroupsList?: boolean;
  isLoadingGroupsCountOfSearch: boolean;
  isLoadingGroupsCountTotal: boolean;
  searchQuery?: string;
  groupsCountSearch?: number;
  isLoadingAccountLimits: boolean;
  groupsCountTotal?: number;
  groupsLimit: number;
}

const Title = ({
  groupListLength,
  isLoadingGroupsList,
  isLoadingGroupsCountOfSearch,
  isLoadingGroupsCountTotal,
  isLoadingAccountLimits,
  searchQuery,
  groupsCountSearch,
  groupsCountTotal,
  groupsLimit,
}: TitleProps) => {
  const { t } = useTranslation();

  const showTooltip = useMemo(() => {
    const restGroups = groupsLimit - Number(groupsCountTotal);
    return restGroups <= 10 ? true : false;
  }, [groupsLimit, groupsCountTotal]);

  let content;

  if (isLoadingGroupsList || isLoadingGroupsCountOfSearch) {
    content = <TextSkeleton />;
  } else if (!searchQuery && groupsCountSearch) {
    content = <span>{t('GROUPS_MAIN.noFilters')}</span>;
  } else if (searchQuery && groupsCountSearch) {
    content = (
      <span>
        {' '}
        {t('GROUPS_MAIN.youHave')}{' '}
        <span className="font-medium text-[#004DBC]"> {quantifier(groupsCountSearch)} </span>{' '}
        {groupListLength === 1 ? t('GROUPS_MAIN.contactMatch') : t('GROUPS_MAIN.contactsMatch')}
      </span>
    );
  } else {
    content = t('noResultsFound');
  }

  return (
    <Flex column itemAlignment="start">
      <div className="flex items-center gap-2">
        <Text as="span" fontWeight="medium" variant="title-1">
          {' '}
          {t('GROUPS_MAIN.group')}
          <span className="text-[#004DBC]">
            {!isLoadingGroupsCountTotal && !isLoadingAccountLimits && (
              <span className="font-medium text-[#004DBC]">
                {` ${quantifier(groupsCountTotal) || 0}${
                  groupsLimit ? `/${quantifier(groupsLimit) || 0}` : ''
                }`}
              </span>
            )}
          </span>
        </Text>
        {showTooltip && (
          <TooltipAccountLimit
            email="soporte@embluemail.com"
            subtitle={t('GROUPS_MAIN.tooltip.contact')}
            title={t('GROUPS_MAIN.tooltip.title', { limit: groupsLimit })}
          />
        )}
      </div>
      {content}
    </Flex>
  );
};

export default memo(Title);
