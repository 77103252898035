// cspell:disable

export const AbandonedContactIcon = () => {
  return (
    <svg height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 7740" id="Grupo_7740" transform="translate(-1003 -347)">
        <g data-name="Grupo 7730" id="Grupo_7730" transform="translate(299)">
          <rect
            data-name="Rectángulo 4182"
            fill="none"
            height="56"
            id="Rectángulo_4182"
            transform="translate(704 347)"
            width="56"
          />
          <g data-name="Grupo 7503" id="Grupo_7503" transform="translate(-3520.521 -5479.853)">
            <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(4259.75 5863.185)">
              <path
                d="M386.11,331.53a12.583,12.583,0,0,0,.776,1.812h0C386.626,332.825,386.369,332.049,386.11,331.53Z"
                data-name="Trazado 3928"
                fill="#2552a0"
                id="Trazado_3928"
                transform="translate(-386.11 -331.53)"
              />
            </g>
            <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(4252.499 5854.629)">
              <path
                d="M315.57,248.308a.953.953,0,0,1,.776.259A.95.95,0,0,0,315.57,248.308Z"
                data-name="Trazado 3930"
                fill="#2552a0"
                id="Trazado_3930"
                transform="translate(-315.57 -248.301)"
              />
            </g>
            <g data-name="Grupo 6573" id="Grupo_6573" transform="translate(4235.064 5829.104)">
              <circle
                cx="12.455"
                cy="12.455"
                data-name="Elipse 1742"
                fill="#f6a658"
                id="Elipse_1742"
                r="12.455"
              />
            </g>
            <g data-name="Grupo 6574" id="Grupo_6574" transform="translate(4227.875 5853.795)">
              <path
                d="M115.13,254.078a24.411,24.411,0,0,0-8.81-13.66,1.937,1.937,0,0,0-1.822,0,15.558,15.558,0,0,1-17.62,0,1.936,1.936,0,0,0-1.824,0,23.512,23.512,0,0,0-8.81,13.66s-1.822,7.291,5.469,7.291h27.949C116.944,261.369,115.13,254.078,115.13,254.078Z"
                data-name="Trazado 3933"
                fill="#788f9a"
                id="Trazado_3933"
                transform="translate(-76.043 -240.19)"
              />
            </g>
            <path
              d="M158.425,0a12.455,12.455,0,0,0,0,24.911Z"
              data-name="Trazado 4551"
              fill="#f5b673"
              id="Trazado_4551"
              transform="translate(4089.094 5829.104)"
            />
            <path
              d="M95.686,243.153a15.54,15.54,0,0,1-8.808-2.734,1.936,1.936,0,0,0-1.824,0,23.512,23.512,0,0,0-8.81,13.66s-1.822,7.291,5.469,7.291H95.686Z"
              data-name="Trazado 4552"
              fill="#8fa3ad"
              id="Trazado_4552"
              transform="translate(4151.833 5613.605)"
            />
            <g
              data-name="Elipse 1885"
              fill="#fff"
              id="Elipse_1885"
              stroke="#fff"
              strokeWidth="1"
              transform="translate(4250.771 5854.852)"
            >
              <circle cx="11" cy="11" r="11" stroke="none" />
              <circle cx="11" cy="11" fill="none" r="10.5" />
            </g>
          </g>
        </g>
        <g id="color-lineal-magnifying-glass-svgrepo-com" transform="translate(1030.09 375.777)">
          <ellipse
            cx="9.742"
            cy="9.742"
            data-name="Elipse 1888"
            fill="#589fff"
            id="Elipse_1888"
            rx="9.742"
            ry="9.742"
            transform="translate(0.477 0.474)"
          />
          <ellipse
            cx="8.043"
            cy="8.043"
            data-name="Elipse 1889"
            fill="#def6fc"
            id="Elipse_1889"
            rx="8.043"
            ry="8.043"
            transform="translate(2.153 2.176)"
          />
          <g id="Layer_32" transform="translate(0 0)">
            <g data-name="Grupo 7738" id="Grupo_7738">
              <g data-name="Grupo 7736" id="Grupo_7736" transform="translate(16.713 16.647)">
                <path
                  d="M184.616,502.8l-1.116,1.116,2.589,2.649,1.116-1.116Z"
                  data-name="Trazado 4606"
                  fill="#7180bc"
                  id="Trazado_4606"
                  transform="translate(-183.5 -502.8)"
                />
                <path
                  d="M13.888,562.971l.987-.987a.843.843,0,0,0,0-1.185l-5.209-5.205a.843.843,0,0,0-1.185,0l-.987.987a.843.843,0,0,0,0,1.185l5.215,5.205A.835.835,0,0,0,13.888,562.971Z"
                  data-name="Trazado 4607"
                  fill="#f9c04a"
                  id="Trazado_4607"
                  transform="translate(-5.576 -553.61)"
                />
                <path
                  d="M13.544,562.225,7.15,555.831l.338-.338a.844.844,0,0,1,1.185,0l5.209,5.212a.843.843,0,0,1,0,1.185Z"
                  data-name="Trazado 4608"
                  fill="#ffda9f"
                  id="Trazado_4608"
                  transform="translate(-4.58 -553.513)"
                />
                <rect
                  data-name="Rectángulo 4270"
                  fill="#def6fc"
                  height="3.07"
                  id="Rectángulo_4270"
                  transform="translate(3.364 5.596) rotate(-135)"
                  width="1.454"
                />
                <rect
                  data-name="Rectángulo 4271"
                  fill="#fff"
                  height="1.315"
                  id="Rectángulo_4271"
                  transform="translate(4.61 4.355) rotate(-135)"
                  width="1.454"
                />
              </g>
              <g data-name="Grupo 7737" id="Grupo_7737">
                <path
                  d="M2.994,3.063a10.223,10.223,0,0,1,15.146,13.7l1.583,1.583a1.071,1.071,0,0,1,.49-.119,1.089,1.089,0,0,1,.762.315l.414.414h0l1.03,1.03,3.762,3.762a1.066,1.066,0,0,1,.315.762,1.089,1.089,0,0,1-.315.762l-.987.987a1.066,1.066,0,0,1-.762.315,1.078,1.078,0,0,1-.762-.315L19.9,22.483l-1.03-1.03h0l-.414-.414a1.066,1.066,0,0,1-.315-.762,1.031,1.031,0,0,1,.119-.49l-1.583-1.583A10.218,10.218,0,0,1,2.994,3.063ZM24.011,25.917a.6.6,0,0,0,.848,0l.987-.987a.6.6,0,0,0,0-.848l-3.6-3.6-1.838,1.838Zm-3.934-3.934,1.838-1.838-.7-.7-1.838,1.838ZM18.8,20.705l.248.248,1.838-1.838-.248-.248a.6.6,0,0,0-.848,0l-.987.987a.378.378,0,0,0-.046.053h0a.615.615,0,0,0-.129.371A.555.555,0,0,0,18.8,20.705Zm-.235-1.281.788-.788-1.52-1.52c-.123.136-.248.268-.384.4s-.265.258-.4.384ZM3.325,17.189a9.742,9.742,0,1,0,0-13.795A9.761,9.761,0,0,0,3.325,17.189Z"
                  data-name="Trazado 4609"
                  fill="#133260"
                  id="Trazado_4609"
                  transform="translate(0 -0.075)"
                />
                <path
                  d="M243.349,60.551a8.295,8.295,0,1,1,0,11.735A8.308,8.308,0,0,1,243.349,60.551Zm.331,11.4a7.818,7.818,0,1,0,0-11.073A7.83,7.83,0,0,0,243.68,71.955Z"
                  data-name="Trazado 4610"
                  fill="#133260"
                  id="Trazado_4610"
                  transform="translate(-238.994 -56.203)"
                />
              </g>
            </g>
          </g>
          <g data-name="Grupo 7739" id="Grupo_7739" transform="translate(7.077 5.68)">
            <path
              d="M399.8,174.18a2.228,2.228,0,0,1,.378-1.189,2.905,2.905,0,0,1,1.1-1,3.445,3.445,0,0,1,1.685-.394,3.589,3.589,0,0,1,1.583.331,2.562,2.562,0,0,1,1.06.9,2.209,2.209,0,0,1,.374,1.235,1.907,1.907,0,0,1-.212.921,2.812,2.812,0,0,1-.507.682q-.293.288-1.053.967a4.6,4.6,0,0,0-.338.338,1.25,1.25,0,0,0-.189.265,1.476,1.476,0,0,0-.1.242c-.023.079-.056.222-.1.424a.69.69,0,0,1-.735.642.75.75,0,0,1-.54-.209.823.823,0,0,1-.219-.623,2.278,2.278,0,0,1,.162-.9,2.222,2.222,0,0,1,.427-.669,9.433,9.433,0,0,1,.715-.682c.265-.232.454-.4.573-.523a1.9,1.9,0,0,0,.3-.391.963.963,0,0,0,.119-.47,1.093,1.093,0,0,0-.368-.834,1.351,1.351,0,0,0-.947-.341,1.306,1.306,0,0,0-1,.344,2.736,2.736,0,0,0-.543,1.01c-.139.467-.407.7-.8.7a.783.783,0,0,1-.583-.245A.75.75,0,0,1,399.8,174.18Zm3.017,6.772a.979.979,0,0,1-.659-.245.852.852,0,0,1-.281-.682.882.882,0,0,1,.272-.656.927.927,0,0,1,.669-.265.9.9,0,0,1,.656.265.891.891,0,0,1,.265.656.858.858,0,0,1-.278.679A.938.938,0,0,1,402.817,180.951Z"
              data-name="Trazado 4611"
              fill="#133260"
              id="Trazado_4611"
              transform="translate(-399.8 -171.6)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
