import { ButtonHTMLAttributes, memo } from 'react';

import { AddCirclePlusIcon } from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Spinner } from '@/lib/v2/components/Spinner';
import { Text } from '@/lib/v2/components/Text';

interface CreateItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  onItemCreated?: (value: string) => void;
  value: string;
  disabled?: boolean;
  isCreating?: boolean;
}

const CreateItem = ({
  title,
  onItemCreated,
  value,
  disabled,
  isCreating,
  /** Takes the rest of the native properties of an Button HTML (name, style, onmousedown, ondblclick, ...etc ) */
  ...restOfProps
}: CreateItemProps) => {
  const addItem = (value: string) => {
    Promise.resolve(onItemCreated?.(value))
      .then(() => {
        return;
      })
      .catch(console.error);
  };

  return (
    <button
      className="flex items-center gap-1 pt-2"
      disabled={disabled}
      onClick={() => addItem(value)}
      {...restOfProps}
    >
      {isCreating ? (
        <div className="-mr-2 -scale-50">
          <Spinner withoutOverlay />
        </div>
      ) : (
        <IconSvg className="!h-6 !w-6" svgComponent={<AddCirclePlusIcon />} />
      )}
      <Text fontWeight="bold" variant="text">
        {`${title} `}
        <span className="text-emblue-primary">{value}</span>
      </Text>
    </button>
  );
};

export default memo(CreateItem);
