import { IBackOfficeContract } from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';
// cspell:disable
export const mockGetAccounts = [];
export const mockGetPlans = [];
export const mockGetContract: IBackOfficeContract = {
  id: 51427,
  contractDetails: {
    status: 1,
    businessName: 'Test luis Lead scoring',
    fantasyName: 'Test luis Lead scoring',
    taxId: '99937844322',
    regionalBrand: null,
    createdDate: '2024-04-19T14:30:24.353Z',
    dueDate: '2025-04-19T14:30:24.353Z',
    country: 1,
    industry: null,
    farmerId: null,
    hunterId: 10,
    note: null,
    subscriptionId: 'sub_9',
  },
  clientDetails: {
    appAdminEmail: 'luis.e+LS@embluemail.com',
    appAdminName: 'Test luis lead scoring',
    billingEmail: 'luis.e+LS@embluemail.com',
    phone: '+54 (11) 11111111',
    address: 'palacios 605',
    city: null,
  },
  planDetails: {
    planId: 1,
    monthlyPrice: 0,
    priceExc: 0,
    currency: null,
    monthsWithDiscount: 3,
    discount: '20',
    period: 'mensual',
    finalPrice: 0,
  },
};
