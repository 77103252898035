export const FrequentContactIcon = () => {
  return (
    <svg
      data-name="Grupo 6479"
      height="30.494"
      id="Grupo_6479"
      viewBox="0 0 37.27 35.494"
      width="31.27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="9.558"
        cy="9.558"
        data-name="Elipse 1696"
        fill="#004dbc"
        id="Elipse_1696"
        rx="9.558"
        ry="9.558"
        transform="translate(5.591 0)"
      />
      <path
        d="M773.054,354.84a1.749,1.749,0,0,1-1.415-.22,1.848,1.848,0,0,1-.629-2.547l2.83-4.747a1.848,1.848,0,0,1,2.547-.629l3.113,1.855,1.855-3.112a1.855,1.855,0,0,1,3.176,1.918l-2.8,4.716a1.928,1.928,0,0,1-1.132.849,1.88,1.88,0,0,1-1.415-.22l-3.113-1.855-1.886,3.144A1.709,1.709,0,0,1,773.054,354.84Z"
        data-name="Trazado 3812"
        fill="#004dbc"
        id="Trazado_3812"
        transform="translate(-747.53 -319.407)"
      />
      <g data-name="Grupo 6439" id="Grupo_6439" transform="translate(0 19.111)">
        <path
          d="M717.129,341.142a5.054,5.054,0,0,1,.566-3.8l2.83-4.747a5.018,5.018,0,0,1,3.993-2.421,15.106,15.106,0,0,0-4.307-4.559,1.285,1.285,0,0,0-1.415,0,11.723,11.723,0,0,1-13.488,0,1.229,1.229,0,0,0-1.415,0c-5.313,3.553-6.823,10.438-6.823,10.438s-1.383,5.659,4.213,5.659h16.067A3.047,3.047,0,0,1,717.129,341.142Z"
          data-name="Trazado 3813"
          fill="#004dbc"
          id="Trazado_3813"
          transform="translate(-696.918 -325.387)"
        />
      </g>
    </svg>
  );
};
