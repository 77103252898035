import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  atomCurrentCampaignID,
  atomDetailCampaign,
  atomLoadingProcess,
  atomMoveCampaignPayload,
  atomMoveSuccess,
  atomRefetchAllData,
  atomRefetchDetailData,
  atomTotalsProcess,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import {
  EActionTypes,
  ICampaignActionsListPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ECampaignsTypeOptions } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { getParamsActions, getParamsPager } from '@/src/modules/CampaignsModule/utils/getParams';

import { useCampaignActions } from './useCampaignActions';
import { useCampaign } from './useCampaigns';
import { useFiltersActions } from './useFiltersActions';

import {
  atomActionsList,
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomOrderByAction,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';

const TRANSLATE_MODAL_MOVE = 'CAMPAIGN_ACTIONS_MAIN.MOVE_ACTIONS.TOAST_MESSAGES';

export const useActionsData = (type?: EActionTypes, channel?: number[]) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const orderByCreationDate = useFeatureFlag('defaultSortDateFinishActions');

  const { getList } = useCampaignActions();
  const { getDetailCampaign } = useCampaign();
  const { resetFilters } = useFiltersActions();

  const [actionsList, setActionsList] = useAtom(atomActionsList);
  const [refetchAllData, setRefetchAllData] = useAtom(atomRefetchAllData);
  const [filters, setFilters] = useAtom(atomFiltersAction);
  const [pager, setPager] = useAtom(atomPagerAction);
  const [refetchDetailData, setRefetchDetailData] = useAtom(atomRefetchDetailData);
  const [moveSuccess, setMoveSuccess] = useAtom(atomMoveSuccess);
  const [moveCampaignPayload, setMoveCampaignPayload] = useAtom(atomMoveCampaignPayload);

  const orderBy = useAtomValue(atomOrderByAction);
  const historySearchPagerAction = useAtomValue(atomHistorySearchPagerActions);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsActionsList = useAtomValue(atomTotalsProcess);

  const isCampaignAutomatic = detailCampaign.campaignTypeId === ECampaignsTypeOptions.AUTOMATIC;

  const setCurrentCampaignID = useSetAtom(atomCurrentCampaignID);

  const filtersCount = useMemo(() => Object.keys(filters ?? {}).length, [filters]);

  const getListActions = useCallback(
    (payload: ICampaignActionsListPayload) => void getList(payload),
    [getList]
  );

  useEffect(() => {
    setCurrentCampaignID(Number(id));
  }, [id, setCurrentCampaignID]);

  useEffect(() => {
    if (moveSuccess && moveCampaignPayload) {
      /** cspell:disable */
      const isSingle = moveCampaignPayload?.elementosId?.length === 1;
      /** cspell:enable */

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_MOVE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_MOVE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_MOVE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_MOVE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      resetFilters();
      setMoveSuccess(RESET);
      setMoveCampaignPayload(RESET);
    }
  }, [moveCampaignPayload, moveSuccess, setMoveCampaignPayload, setMoveSuccess, t, resetFilters]);

  useEffect(() => {
    if (refetchDetailData) {
      void getDetailCampaign({ campaignID: Number(id) });
      setRefetchDetailData(RESET);
    }
  }, [getDetailCampaign, id, refetchDetailData, setRefetchDetailData]);

  useEffect(() => {
    if (location?.state?.from === 'action-detail') {
      setPager(historySearchPagerAction?.pager ?? defaultPager);
      setFilters(historySearchPagerAction?.filters ?? {});
      navigate('.', { state: null });
      return;
    }

    if (!filters || !pager) {
      const filtersURL = getParamsActions();
      setFilters(filtersURL);

      const pagerURL = getParamsPager();
      setPager(pagerURL);
    }
  }, [location?.state?.from, setActionsList, setFilters, setPager]);

  const getParamsSearchAll = useCallback((): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentPage = pager?.page ?? defaultPager.page;
    const currentLimit = pager?.limit ?? defaultPager.limit;

    if (pager?.page) {
      currentPage !== defaultPager.page || queryParams.has('page')
        ? queryParams.set('page', currentPage.toString())
        : queryParams.delete('page');
    }

    if (pager?.limit) {
      currentLimit !== defaultPager.limit || queryParams.has('limit')
        ? queryParams.set('limit', currentLimit.toString())
        : queryParams.delete('limit');
    }

    const currentFilterKeys = new Set(queryParams.keys());

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (key === 'startDate' || key === 'endDate') {
          const timeStampCast = new Date(value as Date).getTime() / 1000;
          queryParams.set(key, timeStampCast.toString());
        } else if (Array.isArray(value)) {
          queryParams.set(key, value.join(','));
        } else if (value != null) {
          queryParams.set(key, String(value));
        }
        currentFilterKeys.delete(key);
      });
    }

    if (filters !== undefined && filters !== null) {
      currentFilterKeys.forEach((key) => {
        if (key !== 'page' && key !== 'limit') {
          queryParams.delete(key);
        }
      });
    }

    return `${location.pathname}?${queryParams.toString()}`;
  }, [filters, location.pathname, pager?.limit, pager?.page]);

  useEffect(() => {
    const URL = getParamsSearchAll();
    navigate(URL, { replace: true });
  }, [pager, filters, getParamsSearchAll, navigate]);

  const orderByCampaignTypeId = useMemo(() => {
    if (initialRender.current) {
      initialRender.current = false;

      if (type === EActionTypes.DRAFT) return { sortField: 'modificationDate', sortOrder: 'desc' };
      if (type === EActionTypes.FINISHED)
        return { sortField: orderByCreationDate ? 'creationDate' : 'exeDate', sortOrder: 'desc' };
    }

    if (detailCampaign?.campaignTypeId === ECampaignsTypeOptions.AUTOMATIC && !type) {
      return { sortField: 'channel', sortOrder: 'desc' };
    }

    return { ...orderBy };
  }, [type, detailCampaign?.campaignTypeId, orderBy]);

  useEffect(() => {
    const isValidCampaign = detailCampaign?.campaignTypeId && detailCampaign?.campaignId;
    const hasFiltersAndPager = filters && pager;

    if (!hasFiltersAndPager) return;

    const payload: ICampaignActionsListPayload = {
      ...filters,
      page: pager.page,
      limit: pager.limit,
      ...orderByCampaignTypeId,
    };

    if (type) {
      payload.type = type;
      channel && !filters.channel?.length && (payload.channel = channel);
      getListActions(payload);
      return;
    }

    if (isValidCampaign && !type) {
      payload.campaignId = detailCampaign.campaignId;
      getListActions(payload);
      return;
    }
  }, [
    orderBy,
    filters,
    pager?.page,
    pager?.limit,
    detailCampaign?.campaignId,
    detailCampaign?.campaignTypeId,
    getListActions,
  ]);

  useEffect(() => {
    if (!refetchAllData) return;

    const payload: ICampaignActionsListPayload = {
      page: pager?.page,
      limit: pager?.limit,
      ...filters,
      ...orderByCampaignTypeId,
    };

    if (detailCampaign?.campaignId) {
      payload.campaignId = detailCampaign.campaignId;
    }
    if (type) {
      payload.type = type;
    }

    void getListActions(payload);
    setRefetchAllData(RESET);
  }, [refetchAllData, detailCampaign?.campaignId, setRefetchAllData]);

  return {
    actionsList,
    loadingProcess,
    totalsActionsList,
    filtersCount,
    isCampaignAutomatic,
  };
};
