import { ReactNode } from 'react';

import { useStateTabContext } from './Tab';

export type TabPaneProps = {
  children?: ReactNode;
  tabId: number | string;
};

const TabPane = ({ children, tabId }: TabPaneProps) => {
  const { currentTab } = useStateTabContext();
  const active = currentTab === tabId;

  if (!active) return null;

  return <div className="relative w-full">{children}</div>;
};

export default TabPane;
