import React, { memo } from 'react';

import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';

export type StatsProps = {
  quantity: number;
  onDownload?: (data: StatsProps) => void;
  state: string;
};

export const StatsCards = memo(
  ({ stats, children }: { stats: StatsProps[]; children?: React.ReactNode }) => {
    return (
      <Flex column>
        <Flex withGap gapSize="medium">
          {stats.map((stat) => (
            <Flex
              key={stat.state}
              column
              withGap
              className="w-1/4 rounded-t-md bg-[#004DBC] px-8 py-4"
              gapSize="small"
              itemAlignment="start"
            >
              {stat.quantity !== undefined ? (
                <Text as="h1" color="white" variant="title-1">
                  {stat.quantity}
                </Text>
              ) : (
                <Text as="h1" color="white" variant="title-1">
                  ---
                </Text>
              )}
              <div className="flex w-full justify-between">
                <Text color="white" variant="sub-headline">
                  {stat.state}
                </Text>

                {stat.quantity > 0 && stat.onDownload && (
                  <IconSvg
                    fillColor="white"
                    svgComponent={<DownloadIcon />}
                    onClick={() => stat.onDownload && stat.onDownload(stat)}
                  />
                )}
              </div>
            </Flex>
          ))}
        </Flex>
        <>{children}</>
      </Flex>
    );
  }
);
