import { useUserData } from '@/src/application/hooks/useUserData';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';

export const useCurrentStep = (): [number, Error | undefined, boolean] => {
  const [userData] = useUserData();
  const [stepValue, error, isLoading] = useEmblue(
    ServiceMethods.getGetCurrentStep,
    userData.companyId
  );

  return [stepValue ?? StepEnum.sender, error, isLoading];
};
