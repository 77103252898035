import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type StatusBadgeProps = {
  status: string | undefined;
  subStatus?: string;
  gender?: 'masculine' | 'feminine';
};

export const StatusBadge = ({ status, subStatus, gender = 'masculine' }: StatusBadgeProps) => {
  const { t } = useTranslation();

  const currentStatus = useMemo(() => {
    return {
      new: t('STATUS_BADGE.new'),
      active: t('STATUS_BADGE.active'),
      frequent: t('STATUS_BADGE.frequent'),
      asleep: t('STATUS_BADGE.asleep'),
      inactive: gender === 'masculine' ? t('STATUS_BADGE.inactive') : t('STATUS_BADGE.inactiveFem'),
      discarded: t('STATUS_BADGE.discarded'),
      unsafe: t('STATUS_BADGE.unsafe'),
      suspended: t('STATUS_BADGE.suspended'),
      quarantine: t('STATUS_BADGE.quarantine'),
      blocked: t('STATUS_BADGE.blocked'),
      unsubscribe: t('STATUS_BADGE.unsubscribe'),
      complainers: t('STATUS_BADGE.complainers'),
    };
  }, [t]);

  const currentStatusColor = useMemo(() => {
    return {
      new: 'bg-emblueStatus-newStatus text-emblueStatus-newStatus',
      active: 'bg-emblueStatus-activeStatus text-emblueStatus-activeStatus',
      frequent: 'bg-emblueStatus-frequentStatus text-emblueStatus-frequentStatus',
      asleep: 'bg-emblueStatus-asleepStatus text-emblueStatus-asleepStatus',
      inactive: 'bg-emblueStatus-inactiveStatus text-emblueStatus-inactiveStatus',
      discarded: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      unsafe: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      suspended: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      quarantine: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      blocked: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      unsubscribe: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      complainers: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
    };
  }, []);

  const statusSelection = (parentStatus: string | undefined, childStatus: string | undefined) => {
    return childStatus || parentStatus;
  };

  return !status?.length || !status ? (
    <span
      className={
        'inline-flex items-center rounded bg-emblueStatus-noStatus px-4 py-1 text-base font-medium text-emblueStatus-noStatus'
      }
    >
      {t('STATUS_BADGE.noStatus')}
    </span>
  ) : (
    <span
      className={`inline-flex items-center rounded px-4 py-1 text-base font-medium ${
        currentStatusColor[status as keyof typeof currentStatusColor]
      }`}
    >
      {currentStatus[statusSelection(status, subStatus) as keyof typeof currentStatus]}
    </span>
  );
};
