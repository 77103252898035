import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, IconSvg } from '@/lib/v2/components';
import { CardAction } from '@/lib/v2/organisms';
import { CardActionProps } from '@/lib/v2/organisms/CardAction/CardAction';

import { TRANSLATE_INBOX_MAIN_FOOTER } from '@/src/modules/InboxModule/constants';
import {
  AgentAssignmentIcon,
  AutoResponseIcon,
  CentralizedInboxIcon,
  CrmIntegrationIcon,
  CustomTaggingIcon,
} from '@/src/modules/InboxModule/images/icons';

const InboxMainFooter = () => {
  const { t } = useTranslation('inbox');
  const [iconSize, setIconSize] = useState('56px');
  const [textSize, setTextSize] = useState<CardActionProps['textSize']>('text-14');

  const updateCardSize = useCallback(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1537) {
      setIconSize('64px');
      setTextSize('text-18');
    } else if (windowWidth >= 1281) {
      setIconSize('56px');
      setTextSize('text-14');
    } else {
      setIconSize('46px');
      setTextSize('text-14');
    }
  }, []);

  useEffect(() => {
    updateCardSize();

    const mediaQueryXl = window.matchMedia('(min-width: 1280px)');
    const mediaQuery2xl = window.matchMedia('(min-width: 1536px)');

    const handleMediaQueryChange = () => {
      updateCardSize();
    };

    mediaQueryXl.addEventListener('change', handleMediaQueryChange);
    mediaQuery2xl.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQueryXl.removeEventListener('change', handleMediaQueryChange);
      mediaQuery2xl.removeEventListener('change', handleMediaQueryChange);
    };
  }, [updateCardSize]);

  const cardActionsList = useMemo<CardActionProps[]>(() => {
    const actions: CardActionProps[] = [
      {
        description: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.TOTAL_CENTRALIZATION.DESCRIPTION`),
        title: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.TOTAL_CENTRALIZATION.TITLE`),
        id: 'total-centralization',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height={iconSize}
            svgComponent={<CentralizedInboxIcon />}
            width={iconSize}
            withPadding="large"
          />
        ),
      },
      {
        description: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.RESPONSE_AUTOMATION.DESCRIPTION`),
        title: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.RESPONSE_AUTOMATION.TITLE`),
        id: 'response-automation',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height={iconSize}
            svgComponent={<AutoResponseIcon />}
            width={iconSize}
            withPadding="large"
          />
        ),
      },
      {
        description: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.CRM_INTEGRATION.DESCRIPTION`),
        title: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.CRM_INTEGRATION.TITLE`),
        id: 'crm-integration',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height={iconSize}
            svgComponent={<CrmIntegrationIcon />}
            width={iconSize}
            withPadding="large"
          />
        ),
      },
      {
        description: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.EFFICIENT_ASSIGNMENT.DESCRIPTION`),
        title: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.EFFICIENT_ASSIGNMENT.TITLE`),
        id: 'efficient-assignment',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height={iconSize}
            svgComponent={<AgentAssignmentIcon />}
            width={iconSize}
            withPadding="large"
          />
        ),
      },
      {
        description: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.CUSTOM_TAGGING.DESCRIPTION`),
        title: t(`${TRANSLATE_INBOX_MAIN_FOOTER}.CUSTOM_TAGGING.TITLE`),
        id: 'custom-tagging',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height={iconSize}
            svgComponent={<CustomTaggingIcon />}
            width={iconSize}
            withPadding="large"
          />
        ),
      },
    ];

    return actions;
  }, [iconSize, t]);

  return (
    <Flex
      alignment="spaceBetween"
      className="mt-8 flex-wrap gap-4 border-t-[1px] border-emblue-borders pt-4 lg:mt-10 lg:flex-nowrap lg:pt-5 xl:mt-12 xl:pt-6"
      itemAlignment="start"
    >
      {cardActionsList.map((cardAction) => {
        return (
          <div
            key={cardAction.id}
            className="w-[calc(50%-8px)] max-w-full text-[12px] lg:w-auto lg:max-w-40 lg:text-[14px] xl:max-w-44 2xl:max-w-60 2xl:text-[18px]"
          >
            <CardAction
              {...cardAction}
              noBorder
              noPadding
              noShadow
              descriptionColor="dark"
              textSize={textSize}
              titleColor="primary"
            />
          </div>
        );
      })}
    </Flex>
  );
};

export default InboxMainFooter;
