import { timeout } from '@/src/compat/util';
import { ICategory, ICollection, ITag } from '@/src/ContactsModule/interfaces/Interfaces';

import { BeefreePreviewDataMock, BeefreeSaveLinksMock } from './BeefreeService.mocks';
import { IBeefreeService } from './IBeefreeService';

import { ILinkTemplateV1 } from '@/modules/CampaignsModule/adapters/TemplateAdapter';
import {
  IFiltersMyTemplateCatalog,
  IFiltersTemplateCatalog,
} from '@/modules/CampaignsModule/atoms/beefreeAtom';
import {
  IAuthBeefree,
  IAuthBeefreeResponse,
  IBeefreeMailPayload,
  IBeefreePreviewResponse,
  IBeefreeRowPayload,
  IBeefreeSaveLinks,
  IBeefreeSaveResponse,
  IBeefreeSaveTemplatePayload,
  IBeefreeSaveTemplateResponse,
  IEmailOfCatalog,
  IEmblueCatalogTemplatesResponse,
  IEmblueRowsResponse,
} from '@/modules/CampaignsModule/interfaces/Beefree';

export class StubBeefreeService implements IBeefreeService {
  static create(): IBeefreeService {
    return new StubBeefreeService();
  }

  /**
   * @desc Authentication in beefree
   *
   */
  async auth(payload: IAuthBeefree): Promise<IAuthBeefreeResponse> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @desc Save a email template
   */
  async saveEmail(payload: IBeefreeMailPayload): Promise<IBeefreeSaveTemplateResponse | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      return null;
    }
  }

  async deleteCurrentLinks(actionId: number): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @desc Get a email template of beefree
   */
  async getEmail(actionId: number): Promise<IBeefreeSaveResponse> {
    try {
      await timeout(2000);
      return { statusOK: true };
    } catch (error) {
      return { statusOK: false };
    }
  }

  /**
   * @desc Send a email with beefree
   */
  async sendEmail(id: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  /**
   * @desc Preview of a template html with beefree
   */
  async getPreview(id: number): Promise<IBeefreePreviewResponse | null> {
    await timeout(2000);
    return BeefreePreviewDataMock;
  }

  /**
   * @desc Save the links of a template with beefree
   */
  async saveTemplateLinks(
    id: number,
    links: ILinkTemplateV1[],
    linksTemporal: ILinkTemplateV1[]
  ): Promise<IBeefreeSaveLinks> {
    await timeout(2000);
    return BeefreeSaveLinksMock;
  }

  async saveTemplate(payload: IBeefreeSaveTemplatePayload) {
    await timeout(2000);
    return null;
  }

  /**
   * @desc Send a email with beefree
   */
  async getCategories(): Promise<ICategory[]> {
    await timeout(2000);
    return [];
  }

  /**
   * @desc Send a email with beefree
   */
  async getTags(): Promise<ITag[]> {
    await timeout(2000);
    return [];
  }

  /**
   * @desc Send a email with beefree
   */
  async getCollections(): Promise<ICollection[]> {
    await timeout(2000);
    return [];
  }
  /**
   * @desc Get the catalog of templates of email
   */
  async getMyTemplates(filters: IFiltersMyTemplateCatalog): Promise<ICollection[]> {
    await timeout(2000);
    return [];
  }

  /**
   * @desc Get a template of email
   */
  async getTemplate(): Promise<IEmailOfCatalog | null> {
    await timeout(2000);
    return null;
  }

  /**
   * @desc Get all templates of email of emblue
   */
  async getTemplatesEmblue(
    filters: IFiltersTemplateCatalog
  ): Promise<IEmblueCatalogTemplatesResponse> {
    await timeout(2000);
    return {
      data: [],
      pagination: {
        total: 0,
        page: 0,
      },
    };
  }

  /**
   * @desc Use a template into a action of a campaign
   */
  async useTemplate(): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  /**
   * @desc Delete a template
   */
  async deleteTemplateEmblue(id: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  /**
   * @desc Delete a template
   */
  async deleteTemplate(id: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  /**
   * @desc Delete a template
   */
  async getBeefreeTemplate(id: number): Promise<IEmailOfCatalog | null> {
    await timeout(2000);
    return null;
  }
  /**
   * @desc Save a email row
   */
  async saveRow(payload: IBeefreeRowPayload): Promise<IBeefreeSaveTemplateResponse | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      return null;
    }
  }
  /**
   * @desc Get a row saved by emblue
   */
  async getDefaultRows(): Promise<IEmblueRowsResponse> {
    try {
      await timeout(2000);
      return [];
    } catch (error) {
      return [];
    }
  }

  /**
   * @desc Get a row saved by user
   */
  async getRowsByUser(): Promise<IEmblueRowsResponse> {
    try {
      await timeout(2000);
      return [];
    } catch (error) {
      return [];
    }
  }

  async deleteRow(id: string): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }
}
