import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';

import { Button } from '@/lib/components/Button/Button';
import { CrossSimpleIcon, MagnifyingGlassIcon, WhiteMagnifyingGlassIcon } from '@/lib/icon';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

export type SearchInputBarProps = {
  placeHolder: string;
  withButton?: boolean;
  value?: string;
  // onAction?: (value: string) => void,
  onAction?: (value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  btnOnAction?: () => void;
  cleanBox?: () => void;
};

export type CleanBoxRef = { cleanBox: () => void };

export const SearchInputBar = forwardRef<CleanBoxRef, SearchInputBarProps>(
  ({ placeHolder, onChange, onAction, withButton, value, cleanBox }: SearchInputBarProps, ref) => {
    const [searchValue, setSearchValue] = useState<string>(value || '');
    const { resetFilter } = useResetSearch({ cleanBox, setSearchValue });

    const keyDownEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onAction && onAction(searchValue);
      }
    };

    const handlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      setSearchValue(event.target.value);
    };

    const handlerAction = () => {
      onAction && onAction(searchValue);
    };

    const handlerReset = () => {
      resetFilter();
    };

    useImperativeHandle(
      ref,
      () => {
        return { cleanBox: resetFilter };
      },
      [resetFilter]
    );

    return (
      <Flex alignment="spaceBetween">
        <>
          <div className="relative grow">
            {!withButton && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon />
              </div>
            )}
            <input
              className={`block h-9 w-full leading-5 ${
                onAction && withButton ? 'h-[38px] pl-2' : 'pl-10'
              } sm:text-sm rounded-md border border-gray-300 bg-white py-2 pr-3 text-14 shadow-sm placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:placeholder:text-gray-400`}
              id="search"
              name="search"
              placeholder={placeHolder}
              value={searchValue}
              onChange={handlerChange}
              onKeyDown={keyDownEnter}
            />
            {searchValue.length > 0 && (
              <div
                className={`absolute inset-y-0 ${
                  onAction && withButton ? 'right-11' : 'right-0'
                } flex items-center pl-3`}
              >
                <Button link Icon={<CrossSimpleIcon />} onAction={handlerReset}></Button>
              </div>
            )}
            {onAction && withButton && (
              <div className="absolute inset-y-0 right-0 flex items-center pl-2 text-14">
                <Button rightRounded onAction={handlerAction}>
                  <WhiteMagnifyingGlassIcon />
                </Button>
              </div>
            )}
          </div>
        </>
      </Flex>
    );
  }
);
