import { useEffect, useRef, useState } from 'react';

interface TimeConfig {
  gmt: number;
  language: string;
}

const DAYS = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  pt: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
};

const formatGMT = (gmt: number): string => {
  const prefix = gmt >= 0 ? '+' : '';
  return `GMT${prefix}${gmt.toString().padStart(2, '0')}:00`;
};

const useClockDisplay = ({ gmt, language }: TimeConfig): string => {
  const [formattedTime, setFormattedTime] = useState<string>('');
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    const formatDateTime = (): string => {
      const date = new Date();

      // Adjust the time according to the specified GMT
      const utc = date.getTime() + date.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(utc + 3600000 * gmt);

      const day = DAYS[language.toLocaleLowerCase() as keyof typeof DAYS][adjustedDate.getDay()];
      const dayOfMonth = adjustedDate.getDate().toString().padStart(2, '0');
      const hours = adjustedDate.getHours().toString().padStart(2, '0');
      const minutes = adjustedDate.getMinutes().toString().padStart(2, '0');

      return `${day} ${dayOfMonth} - ${hours}:${minutes} (${formatGMT(gmt)})`;
    };

    // Function to update the time
    const updateTime = () => setFormattedTime(formatDateTime());

    // Initial update
    updateTime();

    // Calculate the delay until the next minute
    const now = new Date();
    const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    // First update aligned to the minute
    const timeout = setTimeout(() => {
      updateTime();
      // Subsequent updates every minute
      timerRef.current = window.setInterval(updateTime, 60000);
    }, delay);

    return () => {
      clearTimeout(timeout);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [gmt, language]);

  return formattedTime;
};

export default useClockDisplay;
