import { EmblueLogo } from '@/src/assets';
import { urlWithPublicPath } from '@/src/presentation/util';

export const EmblueIcon = () => (
  <img
    alt="emblue logo"
    className="w-24 flex-none pr-3 md:w-[122px]"
    src={urlWithPublicPath(EmblueLogo as string)}
  />
);
