/* eslint-disable no-misleading-character-class */
/* eslint-disable regexp/no-obscure-range */
import { useTranslation } from 'react-i18next';

export interface IValidation {
  [key: string]: {
    pattern?: {
      value: RegExp;
      message: string;
    };
    required?: {
      value: boolean;
      message: string;
    };
  };
}

export const ValidateProfileForm = () => {
  const { t } = useTranslation();
  return {
    mainPhone: {
      pattern: {
        value: /^[\d+)(-]+$/,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isNumber'),
      },
      required: {
        value: false,
        message: t('CONTACTS_PROFILE_VALIDATIONS.thisIsRequired'),
      },
    },
    secondaryPhone: {
      pattern: {
        value: /^[\d+)(-]+$/,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isNumber'),
      },
    },
    names: {
      pattern: {
        value: /^(?! +$)[\wÀ-ÿ\s']+$/,
        message: t('CONTACTS_PROFILE_VALIDATIONS.rulesValidChars'),
      },
    },
    lastNames: {
      pattern: {
        value: /^(?! +$)[\wÀ-ÿ\s']+$/,
        message: t('CONTACTS_PROFILE_VALIDATIONS.rulesValidChars'),
      },
    },
    website: {
      pattern: {
        value: /^(http(s)?:\/\/)?[\w\-.][-\w]*\.[\w\-.][\w\-.~:/?#[\]@!$&'()*+,;=]*$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isWeb'),
      },
    },
    secondaryEmail: {
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-\d]+\.)+[a-z]{2,}))$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isEmail'),
      },
    },
    facebook: {
      pattern: {
        value: /^(https?:\/\/)?([a-z]+-[a-z]+\.)?facebook\.com\/[a-z\d(.?)^\s]/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    linkedin: {
      pattern: {
        value:
          // eslint-disable-next-line regexp/no-super-linear-backtracking
          /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)(((\w{2,3})?)|([^/]+\/(([\w|\-&#?=])+\/?)+))$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    twitter: {
      pattern: {
        value: /^(http(s)?:\/\/)?([a-z\d]+\.)?twitter\.com\/(#!\/)?\w+|@\w+$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    city: {
      pattern: {
        value: /^[a-z\d,.#/~\u00E0-\u00FF\-\s]+$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    address: {
      pattern: {
        value: /^[a-z\d,.#/~\u00E0-\u00FF\-\s]+$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    company: {
      pattern: {
        value: /^[ ,./-\u00F1\u0301]+$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
    position: {
      pattern: {
        value: /^[ ,./-\u00F1\u0301]+$/i,
        message: t('CONTACTS_PROFILE_VALIDATIONS.isValidCharacters'),
      },
    },
  };
};

export const restrictedSpaceBar: Record<string, boolean> = {
  mainPhone: true,
  secondaryPhone: true,
  secondaryEmail: true,
  website: true,
  linkedin: true,
  facebook: true,
  twitter: true,
};
