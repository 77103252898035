/**
 * Represents the different environments the application can run in.
 *
 * @typedef {('production' | 'staging' | 'development' | 'storybook' | 'migration' | 'na')} Environments
 */
export type Environments =
  | 'production'
  | 'staging'
  | 'development'
  | 'storybook'
  | 'migration'
  | 'na';

export class EnvironmentManager {
  private env: Environments;

  /**
   * Initializes a new instance of the EnvironmentManager class.
   * Sets the environment based on the current window location.
   */
  constructor() {
    this.env = this.getEnv();
  }

  /**
   * Determines the current environment based on the window location.
   *
   * @returns {Environments} The current environment.
   * @private
   */
  private getEnv(): Environments {
    if (window.location.hostname === 'app.embluemail.com') {
      return 'production';
    } else if (window.location.hostname.match('ema-app-local')) {
      return 'development';
    } else if (window.location.href.match('story')) {
      return 'storybook';
    } else if (window.location.hostname.match('appmigra')) {
      return 'migration';
    } else if (window.location.hostname.match('app-na')) {
      return 'na';
    } else {
      return 'staging';
    }
  }

  /**
   * Gets the current environment.
   *
   * @returns {Environments} The current environment.
   */
  getEnvironment(): Environments {
    return this.env;
  }
}
