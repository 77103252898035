import { atomWithReset } from 'jotai/utils';

import { WhatsAppTemplate } from '@/src/infrastructure/Protocol/Inbox/IInboxService';

interface TWhatsAppTab {
  contacts: boolean;
  groups: boolean;
}

/**
 * @desc Atom of send WhatsApp modal is open
 */

export const atomSendWhatsAppModalIsOpen = atomWithReset<boolean>(false);
atomSendWhatsAppModalIsOpen.debugLabel = 'atomSendWhatsAppModalIsOpen';

/**
 * @desc Atom of WhatsApp tab
 */

export const atomWhatsAppTab = atomWithReset<TWhatsAppTab>({
  contacts: true,
  groups: false,
});
atomWhatsAppTab.debugLabel = 'atomWhatsAppTab';

/**
 * @desc Atom of WhatsApp templates
 */
export const atomWhatsAppTemplates = atomWithReset<WhatsAppTemplate[]>([]);
atomWhatsAppTemplates.debugLabel = 'atomWhatsAppTemplates';

/**
 * @desc Atom of WhatsApp template selected
 */
export const atomWhatsAppTemplateSelected = atomWithReset<WhatsAppTemplate | null>(null);
atomWhatsAppTemplateSelected.debugLabel = 'atomWhatsAppTemplateSelected';

/**
 * @desc Atom of WhatsApp templates loading
 */
export const atomIsLoadingWhatsAppTemplates = atomWithReset<boolean>(false);
atomIsLoadingWhatsAppTemplates.debugLabel = 'atomIsLoadingWhatsAppTemplates';
