import { t } from 'i18next';

import { IDetailViewList } from '@/src/ContactsModule/components/ContactsViewInfo/ContactsDetailTable';

export const convertDataToViewInfo = (
  fileName: string,
  columnImport: number,
  selectedGroups?: string[]
) => {
  const data = [
    {
      mapping: {
        id: 1,
        nameField: t('IMPORT_MAIN.nameFile'),
        valueField: [fileName],
      },
    },
    {
      mapping: {
        id: 2,
        nameField: t('IMPORT_MAIN.mappedFieldsCapital'),
        valueField: [`${columnImport} ${t('IMPORT_MAIN.mappedFields')}`],
      },
    },
  ] as IDetailViewList[];

  if (selectedGroups)
    data.push({
      mapping: {
        id: 3,
        nameField: t('IMPORT_MAIN.groupsCapital'),
        valueField: [selectedGroups.join(', ')],
      },
    });

  return data;
};
