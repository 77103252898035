export const sortItemsByProperty = <T, K extends keyof T>(
  list: T[] | undefined,
  property: K
): T[] => {
  if (!list || list.length === 0) {
    return [];
  }

  return [...list].sort((a, b) =>
    String(a[property]).localeCompare(String(b[property]), 'en', { numeric: true })
  );
};
