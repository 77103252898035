export const Minus = () => {
  return (
    <svg className="ml-3 h-6 w-6 fill-slate-400" viewBox="0 0 18.07 2.25">
      <path
        d="M25.91,16.875H10.09a1.125,1.125,0,0,0,0,2.25H25.91a1.125,1.125,0,0,0,0-2.25Z"
        data-name="Icon ionic-ios-remove"
        id="Icon_ionic-ios-remove"
        transform="translate(-8.965 -16.875)"
      />
    </svg>
  );
};
