import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { IGroupEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useGroupList = ({
  identifier,
  page,
  pageSize,
  search,
  orderColumn,
  orderDirection,
  lastRefresh,
}: IGroupEmblueService): [
  { list: { group: IGroup }[] } | undefined,
  Error | undefined,
  boolean | undefined
] => {
  const [groupListValue, error, isLoading] = useEmblue(ServiceMethods.getGroups, {
    identifier,
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection: orderDirection,
    lastRefresh,
  });

  return [groupListValue, error, isLoading];
};
