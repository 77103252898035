export const NewsIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6032 15.6969C18.567 15.6141 18.5411 15.5493 18.5126 15.4872C18.0853 14.4902 17.658 13.4933 17.2256 12.4989C17.1583 12.3461 17.1712 12.2762 17.3292 12.1933C18.8544 11.4242 19.5432 9.62194 18.9243 8.04493C18.2976 6.4498 16.5653 5.5849 14.9261 6.0536C14.6482 6.13302 14.4514 6.03721 14.3357 5.76617C13.9473 4.85985 13.5563 3.95352 13.1678 3.04979C13.1342 2.97469 13.1031 2.89701 13.0591 2.79602C13.3413 2.67431 13.6003 2.55778 13.867 2.4542C13.9084 2.43867 13.9939 2.48786 14.0353 2.53189C14.2606 2.75458 14.4755 2.98763 14.706 3.20515C14.7604 3.25694 14.8614 3.28285 14.9417 3.28285C15.4803 3.28285 16.0215 3.27767 16.5601 3.26472C16.6404 3.26472 16.7388 3.23105 16.7931 3.17667C17.0158 2.95397 17.2308 2.71833 17.4405 2.48268C17.5182 2.39464 17.5829 2.37911 17.6943 2.42572C18.1811 2.6277 18.6731 2.82449 19.1651 3.01094C19.2868 3.05755 19.3179 3.12229 19.3127 3.244C19.2998 3.54697 19.3076 3.84994 19.2972 4.15033C19.2946 4.26426 19.3309 4.33419 19.4163 4.41446C19.8073 4.77699 20.1802 5.15764 20.5661 5.52276C20.6282 5.58232 20.7266 5.63152 20.8095 5.63411C21.1254 5.63411 21.4413 5.60822 21.7572 5.60822C21.8271 5.60822 21.9307 5.67036 21.9566 5.73251C22.1793 6.21934 22.3813 6.71393 22.5988 7.20334C22.648 7.31469 22.6247 7.38203 22.5418 7.45972C22.3166 7.67206 22.0913 7.88438 21.8815 8.10967C21.8245 8.16923 21.7883 8.27541 21.7883 8.36086C21.7883 8.89948 21.7961 9.44068 21.809 9.9793C21.809 10.0596 21.8427 10.158 21.8971 10.2098C22.1249 10.4376 22.3735 10.6474 22.6014 10.8753C22.6454 10.9193 22.6713 11.0281 22.648 11.085C22.4564 11.5926 22.2544 12.0949 22.0447 12.5947C22.0188 12.6543 21.9178 12.719 21.8504 12.7216C21.5423 12.7319 21.2316 12.7112 20.9234 12.7216C20.8328 12.7242 20.7266 12.7786 20.6619 12.8433C20.2838 13.2188 19.9135 13.602 19.5484 13.9878C19.4888 14.05 19.437 14.1484 19.437 14.2313C19.437 14.5394 19.45 14.8502 19.4707 15.1583C19.4785 15.2722 19.45 15.337 19.336 15.381C19.0952 15.4742 18.8596 15.583 18.6006 15.6943"
        fill="#A7B1CC"
      />
      <path
        d="M16.5759 10.9478C16.0864 9.80844 15.6048 8.68718 15.1128 7.54262C15.7084 7.30956 16.2781 7.34583 16.8063 7.68505C17.4485 8.09678 17.7437 8.70273 17.6609 9.46404C17.5858 10.1528 17.2051 10.6371 16.5759 10.9504"
        fill="#A7B1CC"
      />
      <path
        d="M14.9541 16.0003C15.3995 16.0159 15.8423 16.034 16.2877 16.0495C17.5125 15.7724 16.5907 14.3145 16.5907 14.3145L12.9188 5.59051C12.3775 4.0886 10.9689 4.95867 10.9689 4.95867C9.09147 6.61595 7.21667 8.27583 5.3341 9.93311C5.21757 10.0341 5.07515 10.1195 4.93014 10.1713C4.1248 10.4691 3.3117 10.7462 2.50636 11.0414C1.43431 11.435 0.947479 12.4838 1.32814 13.5584C1.57155 14.2472 1.82014 14.936 2.06615 15.6274C2.38724 16.5208 3.15115 16.9636 4.08078 16.7642C4.44331 16.6865 4.79289 16.5519 5.1606 16.4405C5.28231 16.671 5.40661 16.904 5.5309 17.1397C6.24561 18.4992 6.95772 19.8587 7.6776 21.2156C7.86404 21.5703 8.15407 21.6791 8.53214 21.5444C9.31158 21.2699 10.0884 20.9929 10.8627 20.7106C11.1683 20.5993 11.3443 20.3455 11.3081 20.0555C11.2925 19.9338 11.2433 19.8121 11.189 19.6981C10.6426 18.5743 10.0884 17.453 9.53945 16.3318C9.454 16.1583 9.37372 15.9822 9.28309 15.7854C9.38149 15.788 9.44105 15.7906 9.50061 15.7932C11.3184 15.8605 13.1389 15.9278 14.9567 15.9977"
        fill="#A7B1CC"
      />
    </svg>
  );
};
