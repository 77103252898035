export const DeleteIcon = ({ color = "#004dbc" }) => {
  return (
    <svg height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7475" id="Group_7475" transform="translate(-0.485 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.485 -0.163)"
          width="19"
        />
        <path
          d="M8.342,17.969a1.689,1.689,0,0,0,1.684,1.684H16.76a1.689,1.689,0,0,0,1.684-1.684v-9.5H8.342Zm8-12.628L15.5,4.5H11.288l-.842.842H7.5V7.026H19.286V5.342Z"
          data-name="Icon material-delete-forever"
          fill="none"
          id="Icon_material-delete-forever"
          stroke={color}
          strokeWidth="1"
          transform="translate(-3.474 -2.24)"
        />
        <path
          d="M10.34,12.009l1.187-1.187,1.793,1.785,1.785-1.785,1.187,1.187-1.785,1.785,1.785,1.785-1.187,1.187L13.32,14.981l-1.785,1.785-1.187-1.187,1.785-1.785Z"
          data-name="Icon material-delete-forever"
          fill={color}
          id="Icon_material-delete-forever-2"
          transform="translate(-3.397 -2.24)"
        />
      </g>
    </svg>
  );
};
