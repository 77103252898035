export const TwitchIcon = () => {
  return (
    <svg
      height="58.355"
      viewBox="0 0 55.798 58.355"
      width="55.798"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="twitch" transform="translate(-5.639 -3.604)">
        <path
          d="M61.434,4.212c0-.157-.025-.313-.047-.589q-25.427,0-50.849-.019a1.22,1.22,0,0,0-1.385.961C8.167,7.34,7.1,10.087,6.063,12.841a9.006,9.006,0,0,1-.424.851V54.174c4.678.071,9.335-.08,14.011.135v7.65h7.59c1.993-2.031,4.056-4,5.953-6.117a4.553,4.553,0,0,1,3.963-1.656c2.716.135,5.444.072,8.166.006a2.559,2.559,0,0,0,1.593-.652q6.993-6.9,13.9-13.88a1.981,1.981,0,0,0,.6-1.216Q61.459,21.33,61.434,4.212ZM56.5,35.623a2.064,2.064,0,0,1-.66,1.62c-2.565,2.523-5.085,5.094-7.668,7.6a4.458,4.458,0,0,1-2.134.618c-4.051.05-7.49.006-11.542.055a2.426,2.426,0,0,0-1.506.623c-2.423,2.29-4.558,4.612-7.014,6.907,0-2.321-.075-4.473-.073-6.763.013-.679-.221-.8-.9-.793l-4.3.013c-.832,0-5.374,0-6.153,0V8.727H56.5C56.452,18.874,56.5,25.57,56.5,35.623Z"
          data-name="Path 3468"
          fill="#520094"
          id="Path_3468"
        />
        <path
          d="M61.247,28.312a1.029,1.029,0,0,0-1.031-1.024c-1.363,0-3.015-.016-3.015-.016s-1,.258-.994,1.023c.026,3.671.04,10.748.055,13.41a1.033,1.033,0,0,0,1.046,1.025c1.019-.009,1.987-.021,2.951.02.789.034,1.011-.239,1-1.018C61.232,37.26,61.25,32.786,61.247,28.312Zm-14.289,0a1.029,1.029,0,0,0-1.031-1.024c-1.363,0-3.015-.016-3.015-.016s-1,.258-.994,1.023c.026,3.671.04,10.748.055,13.41a1.033,1.033,0,0,0,1.046,1.025c1.019-.009,1.987-.021,2.951.02.789.034,1.011-.239,1-1.018C46.943,37.26,46.961,32.786,46.958,28.312Z"
          data-name="Path 3469"
          fill="#520094"
          id="Path_3469"
          transform="translate(-13.463 -8.783)"
        />
      </g>
    </svg>
  );
};
