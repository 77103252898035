import { datadogRum } from '@datadog/browser-rum';
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FloatModal, TableSkeleton, TextSkeleton } from '@/lib/components';
import { ActivityCleanFilterIcon } from '@/lib/icon';
import { Button, Flex, InputSearch, Spinner, toast } from '@/lib/v2/components';

import ErrorBoundary from '@/src/application/components/ErrorBoundary';
import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import useMessage from '@/src/compat/useMessage';
import configData from '@/src/config.json';
import { ContactsGroupModal, ContactsTagModal } from '@/src/ContactsModule/components';
import { IUpdateItemsToPropsV2 } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { ITableNpsContact } from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { showNotification } from '@/src/utils/ShowToastNotification';

import { ContactsGroupsAssignmentModal } from '@/modules/ContactsModule/components/ContactsGroupsAssignmentModal';
import { ContactsTagsAssignmentModal } from '@/modules/ContactsModule/components/ContactsTagsAssignmentModal';
import {
  useHappinessMutationContext,
  useHappinessStateContext,
} from '@/modules/ContactsModule/contexts/HappinessContext';
import { HappinessTable } from '@/modules/ContactsModule/screens/HappinessMain/components/HappinessContactsInvolved/components/HappinessTable';
import { HappinessTableActionBar } from '@/modules/ContactsModule/screens/HappinessMain/components/HappinessContactsInvolved/components/HappinessTableActionBar';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';

type HappinessTableContainerProps = {
  setCountMessage?: Dispatch<SetStateAction<ReactNode | undefined>>;
  setLastRefreshParent?: Dispatch<SetStateAction<number>>;
};

export const HappinessTableContainer: FC<HappinessTableContainerProps> = ({
  setCountMessage,
  setLastRefreshParent,
}) => {
  const navigate = useNavigate();
  const service = useService();
  const { t } = useTranslation();
  const {
    npsTotalContactsCount,
    happinessFilter,
    isHappinessFilterApplied,
    npsContacts,
    searchQuery,
    isLoadingContacts,
    searchQueryHandler,
    totalContactsBySearch,
    pageSize,
    isAllResultsSelected,
    selectedContacts,
    showSelectAllResultsButton,
    lastRefresh,
  } = useHappinessStateContext();
  const {
    setShowSelectAllResultsButton,
    setOnSearch,
    handleOnChangeSearchQuery,
    handleClearSearchQuery,
    resetOnSearch,
    setIsAllResultsSelected,
    setLastRefresh,
  } = useHappinessMutationContext();

  const [isChecked, setIsChecked] = useState(false);
  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshContactsValue);
  const [lastRefreshGroupsAndTagsList, setLastRefreshGroupsAndTagsList] = useState(0);

  const [groups] = useEmblue(ServiceMethods.getGroups, {
    identifier: 'GROUPS_DROP_DOWN',
    lastRefresh: lastRefreshGroupsAndTagsList,
  });
  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, {
    identifier: 'TAGS_DROP_DOWN',
    lastRefresh: lastRefreshGroupsAndTagsList,
  });
  const [flowsLists] = useEmblue(ServiceMethods.getFlowsByAccount);

  const [showSpinnerByAction, setShowSpinnerByAction] = useState(false);
  /***** Hooks to handle contactActionBar clicks ******/
  const [flowId, setFlowId] = useState(0);
  const [confirmToFlow, setConfirmToFlow] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  /***** FIN Hooks to handle contactActionBar clicks ******/
  const [selectedContactsCountToAction, setSelectedContactsCountToAction] = useState<number>(
    configData.CONTACTS_ACTIONS.maxContactsToAction
  );
  const [maxGroupsToAdd, setMaxGroupsToAdd] = useState<number>();
  const [maxTagsToAdd, setMaxTagsToAdd] = useState<number>();
  const groupsListAux = groups?.list?.filter((g) => g && g.group && g.group.id).map((g) => g.group);

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
      setLastRefreshParent && setLastRefreshParent((prevState: number) => prevState + 1);
    }
  }, [initialLastRefreshValue, lastRefresh]);

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    setLastRefreshParent && setLastRefreshParent((prevState: number) => prevState + 1);
    service?.incrementLastRefreshContactsValue();
  }, [service]); // setLastRefreshParent must NOT be part of dependencies

  //Remove this when create and import contact V2 its on production
  useMessage(EventListenerType.RefreshContacts, () => {
    lastRefreshIncrement();
  });

  useEffect(() => {
    if (!setCountMessage) return;
    let countMessage: string | JSX.Element;
    //Waiting countOfSearch api response
    if (isLoadingContacts) {
      countMessage = <TextSkeleton />;
    }
    //No results case
    else if (!searchQuery && !isHappinessFilterApplied && !npsTotalContactsCount) {
      countMessage = t('noResultsFound');
    }
    //No filters applied case
    else if (!searchQuery && !isHappinessFilterApplied) {
      countMessage = <span>{t('CONTACTS_MAIN.noFilters')}</span>;
    }
    //Any filter applied case
    else if (
      (searchQuery && totalContactsBySearch) ||
      (happinessFilter.length && totalContactsBySearch)
    ) {
      countMessage = (
        <span>
          {' '}
          {t('CONTACTS_MAIN.youHave')}{' '}
          <span className="font-medium text-[#004DBC]"> {quantifier(totalContactsBySearch)} </span>{' '}
          {totalContactsBySearch === 1
            ? t('CONTACTS_MAIN.contactsMatchSingular')
            : t('CONTACTS_MAIN.contactsMatch')}
        </span>
      );
    }
    //No results case
    else {
      countMessage = t('noResultsFound');
    }
    setCountMessage(countMessage);
  }, [
    happinessFilter.length,
    isHappinessFilterApplied,
    isLoadingContacts,
    npsTotalContactsCount,
    searchQuery,
    setCountMessage,
    totalContactsBySearch,
  ]);

  useEffect(() => {
    if (!isChecked && selectedContacts.length > 0) {
      setIsChecked(true);
    }
    if (isChecked && selectedContacts.length === 0) {
      setIsChecked(false);
      setIsAllResultsSelected(false);
    }
    setShowSelectAllResultsButton(selectedContacts.length === pageSize);
    setSelectedContactsCountToAction(
      npsContacts?.count
        ? npsContacts?.count <= configData.CONTACTS_ACTIONS.maxContactsToAction
          ? npsContacts?.count
          : configData.CONTACTS_ACTIONS.maxContactsToAction
        : npsTotalContactsCount &&
          npsTotalContactsCount <= configData.CONTACTS_ACTIONS.maxContactsToAction
        ? npsTotalContactsCount
        : configData.CONTACTS_ACTIONS.maxContactsToAction
    );
  }, [selectedContacts]);

  useEffect(() => {
    if (confirmToFlow && flowId) {
      addContactToFlow(flowId).catch(console.error);
      setConfirmToFlow(false);
    }
  }, [confirmToFlow, flowId]);

  useEffect(() => {
    if (isAllResultsSelected) {
      const maxGroups = Math.floor(
        configData.CONTACTS_ACTIONS.maxRowsGroups / selectedContactsCountToAction
      );
      const maxTags = Math.floor(
        configData.CONTACTS_ACTIONS.maxRowsTags / selectedContactsCountToAction
      );
      setMaxGroupsToAdd(maxGroups === 0 ? 1 : maxGroups);
      setMaxTagsToAdd(maxTags === 0 ? 1 : maxTags);
    } else {
      setMaxGroupsToAdd(undefined);
      setMaxTagsToAdd(undefined);
    }
  }, [isAllResultsSelected]);

  // TODO: Refactor this to avoid refresh the entire window
  const redirectToView = (path: string, withRefresh?: boolean) => {
    navigate(path);
    withRefresh && navigate(0);
  };

  const getContactsIds = useCallback(
    (list?: ITableNpsContact[]) => {
      const listAux: ITableNpsContact[] =
        list ?? (npsContacts as { list: ITableNpsContact[] }).list;
      const contactsIds: number[] =
        selectedContacts.length === pageSize
          ? listAux.map((c) => c.contact.emailId) // all ids of page
          : listAux.filter((c, i) => selectedContacts.includes(i)).map((c) => c.contact.emailId); // ids of some contacts in page
      return contactsIds;
    },
    [npsContacts, pageSize, selectedContacts]
  );

  const addContactToFlow = async (flowsId: number) => {
    if (npsContacts === undefined) return;
    setShowSpinnerByAction(true);
    let result: boolean | undefined;
    if (isAllResultsSelected) {
      result = await service?.addFlowsToAllContacts({
        flowId: flowsId,
        search: searchQuery,
        tagsFilter: [],
        groupsFilter: [],
        statusFilter: [],
        happinessFilter,
        segmentsFilter: 0,
        discardedFilter: [],
        resultCount: selectedContactsCountToAction,
      });
    } else {
      const contactsIds = getContactsIds();
      result = await service?.addFlowsToContacts({ flowId: flowId, contactsIds: contactsIds });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      redirectToView(`/v2/contacts${window.location.search}`);
    }
  };

  const handleLastRefresh = useCallback(() => {
    setLastRefreshGroupsAndTagsList((prevState) => prevState + 1);
  }, []);

  /************************ Add / Remove contacts to groups and tags region ************************/

  const isGroupsByContactsEnabled = useFeatureFlag('groupsByContacts');
  const isTagsByContactsEnabled = useFeatureFlag('tagsByContacts');
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useUserData();

  const [contactsFilters, setContactsFilters] = useState<ContactTableSearchFilters>({
    search: searchQuery,
    tagsFilter: [],
    groupsFilter: [],
    statusFilter: [],
    happinessFilter,
    segmentsFilter: 0,
    discardedFilter: [],
    resultCount: selectedContactsCountToAction,
  });

  useEffect(() => {
    setContactsFilters({
      search: searchQuery,
      tagsFilter: [],
      groupsFilter: [],
      statusFilter: [],
      happinessFilter,
      segmentsFilter: 0,
      discardedFilter: [],
      resultCount: selectedContactsCountToAction,
    });
  }, [searchQuery, happinessFilter, selectedContactsCountToAction]);

  const handleUpdateContactsToGroups = async ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => {
    let result: boolean | undefined;

    const selectedItemsArray = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unselectedItems);

    if (isAllResultsSelected) {
      setShowGroupsModal(false);
      setIsLoading(true);
      try {
        result = await service?.addAllContactsToGroup({
          groupsToAssign: selectedItemsArray.map((g) => parseInt(g.id)),
          groupsToUnassign: unselectedItemsArray.map((g) => parseInt(g.id)),
          ...contactsFilters,
        });
      } catch (error) {
        datadogRum.addAction('addAllContactsHappinessToGroup.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    } else {
      setShowGroupsModal(false);
      setIsLoading(true);
      try {
        result = await service?.updateContactsToGroup({
          contactsIds: selectedContacts,
          groupsToAssign: selectedItemsArray.map((g) => parseInt(g.id)),
          groupsToUnassign: unselectedItemsArray.map((g) => parseInt(g.id)),
        });
      } catch (error) {
        datadogRum.addAction('addTableContactsHappinessToGroup.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    }

    setIsLoading(false);
    showNotification(result ? result : false);

    const totalSelected = [...selectedItemsArray, ...unselectedItemsArray];
    if (result) {
      if (totalSelected.length === 1) {
        redirectToView(`/v2/contacts/groups/${totalSelected.at(0)?.id}`, true);
      } else {
        redirectToView('/v2/contacts/groups');
      }
    }
    return;
  };

  const handleUpdateContactsToTags = async ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => {
    let result: boolean | undefined;

    const selectedItemsArray = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unselectedItems);

    if (isAllResultsSelected) {
      setShowTagsModal(false);
      setIsLoading(true);
      try {
        result = await service?.associateTagsToAllContacts({
          tagsToAssign: selectedItemsArray.map((t) => parseInt(t.id)),
          tagsToUnassign: unselectedItemsArray.map((t) => parseInt(t.id)),
          ...contactsFilters,
        });
      } catch (error) {
        datadogRum.addAction('addAllContactsHappinessToTag.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    } else {
      setShowTagsModal(false);
      setIsLoading(true);
      try {
        result = await service?.updateContactsToTags({
          contactsIds: selectedContacts,
          tagsToAssign: selectedItemsArray.map((t) => parseInt(t.id)),
          tagsToUnassign: unselectedItemsArray.map((t) => parseInt(t.id)),
        });
      } catch (error) {
        datadogRum.addAction('addTableContactsHappinessToTag.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    }

    setIsLoading(false);
    showNotification(result ? result : false);

    const totalSelected = [...selectedItemsArray, ...unselectedItemsArray];
    if (result) {
      if (totalSelected.length === 1) {
        redirectToView(`/v2/contacts/tags/${totalSelected.at(0)?.id}`, true);
      } else {
        redirectToView('/v2/contacts/tags');
      }
    }
    return;
  };

  /************************ END Add / Remove contacts to groups and tags region ************************/

  /************************ Remove this when feature in V2 is checked ************************/

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [unselectedGroups, setUnselectedGroups] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [unselectedTags, setUnselectedTags] = useState<number[]>([]);
  /* eslint-enable @typescript-eslint/no-unused-vars */

  useEffect(() => {
    if (selectedGroups.length >= 1) {
      updateContactsToGroupsLegacy().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  useEffect(() => {
    if (selectedTags.length >= 1) {
      updateContactsToTagsLegacy().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const updateContactsToGroupsLegacy = async () => {
    let result: boolean | undefined;
    if (!isAllResultsSelected) {
      setShowGroupsModal(false);
      setShowSpinnerByAction(true);
      result = await service?.updateContactsToGroup({
        contactsIds: getContactsIds(),
        groupsToAssign: selectedGroups,
        groupsToUnassign: unselectedGroups,
      });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      if (selectedGroups.length === 1)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        redirectToView(`/v2/contacts/groups/${selectedGroups.at(0)}`, true);
      else redirectToView('/v2/contacts/groups');
    }
    return;
  };

  const updateContactsToTagsLegacy = async () => {
    let result: boolean | undefined;
    if (!isAllResultsSelected) {
      setShowTagsModal(false);
      setShowSpinnerByAction(true);
      result = await service?.updateContactsToTags({
        contactsIds: getContactsIds(),
        tagsToAssign: selectedTags,
        tagsToUnassign: unselectedTags,
      });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      redirectToView(
        selectedTags.length === 1
          ? `/v2/contacts/tags/${selectedTags.at(0) || ''}`
          : '/v2/contacts/tags'
      );
    }
    return;
  };
  /************************ Remove this when feature in V2 is checked ************************/

  return (
    <ErrorBoundary>
      <Flex column>
        <Flex alignment="start">
          {!isChecked ? (
            <div className="z-10 px-5 pt-4">
              <Flex alignment="start" itemAlignment="center">
                <Flex
                  withGap
                  alignment="start"
                  className="mb-[-44px] xl:max-w-[900px] 2xl:max-w-fit"
                >
                  {npsTotalContactsCount && npsTotalContactsCount > 0 ? (
                    <>
                      <div className="w-[400px]">
                        <InputSearch
                          placeHolder={t('CONTACTS_FILTERS.searchInputBar')}
                          value={searchQueryHandler}
                          onAction={setOnSearch}
                          onChange={handleOnChangeSearchQuery}
                          onClear={handleClearSearchQuery}
                        />
                      </div>
                      <div className="w-auto">
                        <Button
                          fullWidth
                          standard
                          iconLeft={<ActivityCleanFilterIcon color="#004DBC" />}
                          id="clear-filters-button"
                          tooltip={t('CONTACTS_FILTERS.cleanAllFilters')}
                          onClick={resetOnSearch}
                        ></Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </div>
          ) : (
            <HappinessTableActionBar
              contactSelection={
                isAllResultsSelected ? selectedContactsCountToAction : selectedContacts.length
              }
              flowsLists={typeof flowsLists?.list === 'string' ? flowsLists.list : []}
              isAllResultsSelected={isAllResultsSelected}
              setConfirmToFlow={setConfirmToFlow}
              setFlowId={setFlowId}
              setShowGroupsModal={setShowGroupsModal}
              setShowTagsModal={setShowTagsModal}
            />
          )}
        </Flex>
        {showSelectAllResultsButton && (
          <Flex alignment="center">
            {!isAllResultsSelected ? (
              <div>
                <span>
                  {t('CONTACTS_ACTIONS_DROPDOWN.All')} <b>{pageSize}</b>{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.ContactsPageSelected')}
                </span>
                <span
                  aria-hidden="true"
                  className="ml-1 cursor-pointer rounded-md p-2 font-medium text-[#004dbc] hover:bg-slate-50"
                  onClick={() => setIsAllResultsSelected(true)}
                >
                  {selectedContactsCountToAction ===
                  configData.CONTACTS_ACTIONS.maxContactsToAction ? (
                    <span title={t('CONTACTS_ACTIONS_DROPDOWN.WorkingOnIncreasingNumber')}>
                      {t('CONTACTS_ACTIONS_DROPDOWN.SelectFirst')}{' '}
                      {quantifier(selectedContactsCountToAction)}{' '}
                      {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}
                    </span>
                  ) : (
                    <span>
                      {t('CONTACTS_ACTIONS_DROPDOWN.SelectAll')}{' '}
                      {quantifier(selectedContactsCountToAction)}{' '}
                      {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}
                    </span>
                  )}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  {t('CONTACTS_ACTIONS_DROPDOWN.All')}{' '}
                  <b>{quantifier(selectedContactsCountToAction)}</b>{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.AreSelected')}
                </span>
                <span
                  aria-hidden="true"
                  className="ml-1 cursor-pointer rounded-md p-2 font-medium text-[#004dbc] hover:bg-slate-50"
                  onClick={() => setIsAllResultsSelected(false)}
                >
                  {t('CONTACTS_ACTIONS_DROPDOWN.ClearSelection')}
                </span>
              </div>
            )}
          </Flex>
        )}
        {!npsContacts && (
          <div className="size-full py-10">
            <TableSkeleton />
          </div>
        )}
        {npsContacts && <HappinessTable />}
        {showGroupsModal && isGroupsByContactsEnabled && (
          <ContactsGroupsAssignmentModal
            allContactsCount={selectedContactsCountToAction}
            groups={groupsListAux || [{ id: 0, groupName: '' }]}
            isLoading={isLoading}
            isOpen={showGroupsModal}
            selectAllResults={isAllResultsSelected}
            selectedContacts={getContactsIds()}
            onAssignment={handleUpdateContactsToGroups}
            onClose={setShowGroupsModal}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showGroupsModal && !isGroupsByContactsEnabled && (
          <ContactsGroupModal
            groups={groupsListAux === undefined ? [{ id: 0, groupName: '' }] : groupsListAux}
            maxGroupsToAdd={maxGroupsToAdd}
            selectedContacts={
              isGroupsByContactsEnabled && getContactsIds().length < (maxGroupsToAdd ?? Infinity)
                ? getContactsIds()
                : undefined
            }
            setSelectedGroups={setSelectedGroups}
            setShowGroupsModal={setShowGroupsModal}
            setUnselectedGroups={setUnselectedGroups}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showTagsModal && isTagsByContactsEnabled && (
          <ContactsTagsAssignmentModal
            allContactsCount={selectedContactsCountToAction}
            isLoading={isLoading}
            isOpen={showTagsModal}
            selectAllResults={isAllResultsSelected}
            selectedContacts={getContactsIds()}
            tags={tags === undefined ? [{ id: 0, name: '' }] : tags.list}
            onAssignment={handleUpdateContactsToTags}
            onClose={setShowTagsModal}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showTagsModal && !isTagsByContactsEnabled && (
          <ContactsTagModal
            maxTagsToAdd={maxTagsToAdd}
            selectedContacts={
              isTagsByContactsEnabled && getContactsIds().length < (maxTagsToAdd ?? Infinity)
                ? getContactsIds()
                : undefined
            }
            setSelectedTags={setSelectedTags}
            setShowTagsModal={setShowTagsModal}
            setUnselectedTags={setUnselectedTags}
            tags={tags === undefined ? [{ id: 0, name: '' }] : tags.list}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showSpinnerByAction && (
          <FloatModal>
            <Spinner />
          </FloatModal>
        )}
      </Flex>
    </ErrorBoundary>
  );
};
