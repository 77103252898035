import cn from 'classnames';
import { FC } from 'react';

type ProcessVariant = 'highestNps' | 'lowestNps' | 'withoutProcess';

interface ProcessStatsProps {
  variant: ProcessVariant;
  score: number;
  name: string;
  processDescription: string;
}

export const ProcessStats: FC<ProcessStatsProps> = ({
  variant,
  score,
  name,
  processDescription,
}) => {
  const variants = {
    highestNps: {
      bgColor: 'bg-emblue-success-light',
      textColor: 'text-emblue-success',
      score: `+${score}`,
    },
    lowestNps: {
      bgColor: 'bg-emblue-insecure-hover',
      textColor: 'text-emblue-insecure',
      score: score,
    },
    withoutProcess: {
      bgColor: 'bg-emblue-gray-light',
      textColor: 'text-emblue-primary',
      score: 0,
    },
  };

  return (
    <>
      <div
        className={cn(
          'flex items-center justify-center rounded-full w-10 h-10',
          variants[variant].bgColor
        )}
      >
        <span className={cn('font-bold text-14', variants[variant].textColor)}>
          {variants[variant].score}
        </span>
      </div>
      <div className="flex flex-col text-left">
        <p className="font-medium text-emblue-primary">{name}</p>
        <p className="text-14 text-emblue-subheadings">{processDescription}</p>
      </div>
    </>
  );
};
