import JoditEditor from 'jodit-pro-react';
import { useAtom, useSetAtom } from 'jotai';
import { memo, useEffect, useMemo, useRef } from 'react';

import { Spinner } from '@/lib/v2/components';

import { useUserData } from '@/src/infrastructure/services/useUserData';
import { editorConfig } from '@/src/modules/CampaignsModule/components/HtmlUpload/config/joditEditor.config';
import {
  useHtml,
  useJoditCustomButtons,
} from '@/src/modules/CampaignsModule/components/HtmlUpload/hooks';

import { editorRefAtom, isLoadingCFAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';
import { IJodit } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

const EditPage = memo(() => {
  const [user] = useUserData();
  const userLanguage = user?.language ?? 'es';
  const editorRef = useRef<IJodit | null>(null);
  const { cleanedFileString } = useHtml();
  const setEditorRef = useSetAtom(editorRefAtom);
  const [isLoading] = useAtom(isLoadingCFAtom);

  const joditCustomButtons = useJoditCustomButtons();
  const editorConfigs = editorConfig();

  useEffect(() => {
    if (editorRef) {
      setEditorRef(editorRef);
    }
  }, [editorRef, setEditorRef]);

  const config = useMemo(
    () => ({
      ...editorConfigs,
      ...joditCustomButtons,
      language: userLanguage === 'pt' ? 'pt_br' : userLanguage,
    }),
    [editorConfigs, joditCustomButtons, userLanguage]
  );

  return (
    <>
      {isLoading ? (
        <div className="flex min-h-[600px] items-center justify-center">
          <Spinner withoutOverlay />
        </div>
      ) : (
        <JoditEditor ref={editorRef} config={config} value={cleanedFileString} />
      )}
    </>
  );
});

export default EditPage;
