import { UserData } from '@/src/application/hooks/useUserDataInterface';

export const userDataMock: UserData = {
  environment: 'development',
  name: 'Jesus Santiago',
  email: 'jesus.tovar@embluemail.com',
  plan: '',
  stepperComplete: false,
  language: 'en',
  agencyName: 'AutomationEmblue',
  contractId: '43207',
  companyId: '42307',
  companyName: 'AutomationEmblue',
  timeOffset: -3,
  integrationId: 15,
  integrationDeletedDate: undefined,
  daysSinceCreationDate: 216,
};

/** cspell:disable */
export const ROLES = {
  SUPER_ADMIN_ADMIN: 'SuperAdmin admin',
  SUPER_ADMIN_END_USER: 'SuperAdmin enduser',
  AGENCY_ADMIN: 'Agencia admin',
  AGENCY_END_USER: 'Agencia enduser',
  COMPANY_ADMIN: 'Empresa admin',
  COMPANY_END_USER: 'Empresa enduser',
};
/** cspell:enable */
