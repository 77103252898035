import { useTranslation } from 'react-i18next';

import { PlanType } from '@/src/infrastructure/interfaces/IResponses';

export const usePlanAdditionalInfo = () => {
  const { t } = useTranslation();

  const planAdditionalInfo: Record<
    PlanType,
    { name: string; featureInclude: string | undefined; features: string[] }
  > = {
    Free: {
      name: 'Freemium',
      featureInclude: undefined,
      features: [
        t('PLANS_ADDITIONAL_INFO.Freemium.featureOne'),
        t('PLANS_ADDITIONAL_INFO.Freemium.featureTwo'),
        t('PLANS_ADDITIONAL_INFO.Freemium.featureThree'),
        t('PLANS_ADDITIONAL_INFO.Freemium.featureFour'),
        t('PLANS_ADDITIONAL_INFO.Freemium.featureFive'),
      ],
    },
    Starter: {
      name: 'Starter',
      featureInclude: 'Freemium',
      features: [
        t('PLANS_ADDITIONAL_INFO.Starter.featureOne'),
        t('PLANS_ADDITIONAL_INFO.Starter.featureTwo'),
        t('PLANS_ADDITIONAL_INFO.Starter.featureThree'),
        t('PLANS_ADDITIONAL_INFO.Starter.featureFour'),
        t('PLANS_ADDITIONAL_INFO.Starter.featureFive'),
        t('PLANS_ADDITIONAL_INFO.Starter.featureSix'),
      ],
    },
    Advance: {
      name: 'Advance',
      featureInclude: 'Starter',
      features: [
        t('PLANS_ADDITIONAL_INFO.Advance.featureOne'),
        t('PLANS_ADDITIONAL_INFO.Advance.featureTwo'),
        t('PLANS_ADDITIONAL_INFO.Advance.featureFour'),
        t('PLANS_ADDITIONAL_INFO.Advance.featureFive'),
        t('PLANS_ADDITIONAL_INFO.Advance.featureSix'),
      ],
    },
    Enterprise: {
      name: 'Enterprise',
      featureInclude: 'Advance',
      features: [
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureOne'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureTwo'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureThree'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureFour'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureFive'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureSix'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureSEVEN'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureEight'),
        t('PLANS_ADDITIONAL_INFO.Enterprise.featureNINE'),
      ],
    },
    'Free Trial': { name: 'Free Trial', featureInclude: undefined, features: [] },
  };

  return { planAdditionalInfo };
};
