import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouterSkeleton from '@/src/modules/AutomationModule/components/RouterSkeleton';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModule/constants';
import AutomationLayout from '@/src/modules/AutomationModule/layouts/AutomationLayout';
import { CreateEventMain } from '@/src/modules/AutomationModule/screens/CreateEventMain';

const AutomationFlowsMain = lazy(
  () => import('@/modules/AutomationModule/screens/AutomationFlowsMain')
);
const AutomationEventsMain = lazy(
  () => import('@/modules/AutomationModule/screens/AutomationEventsMain')
);
const AutomationDetail = lazy(
  () => import('@/src/modules/AutomationModule/screens/AutomationDetail')
);

export const AutomationModuleRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route element={<AutomationLayout />} path="/">
          <Route index element={<AutomationFlowsMain />} />
          <Route element={<AutomationDetail />} path={`${AUTOMATION_PATHS.FLOWS_PATH}/:guid`} />
          <Route element={<AutomationEventsMain />} path={AUTOMATION_PATHS.EVENTS_PATH} />
          <Route
            element={<CreateEventMain isEditView />}
            path={`${AUTOMATION_PATHS.EVENTS_PATH}/:eventId`}
          />
          <Route element={<CreateEventMain />} path={`${AUTOMATION_PATHS.CREATE_EVENT_PATH}`} />
        </Route>
      </Routes>
    </Suspense>
  );
};
