import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Container, SecondaryNavigationMenu } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { EmblueLegacyContext } from '@/src/App';
import { useNavigationBar } from '@/src/application/hooks/useNavigationBar';
import ContactsProvider from '@/src/modules/ContactsModule/contexts/ContactsContext';
import { useMutationCreateSegmentModalContext } from '@/src/modules/ContactsModule/contexts/CreateSegmentModalContext';

const ContactsLayout = () => {
  const navigation = useNavigate();
  const { setContext } = useContext(EmblueLegacyContext);
  const { setCreateSegmentModal } = useMutationCreateSegmentModalContext();
  const { contactsNavigationLinks } = useNavigationBar();

  //TO DO, the useMessage it's does'nt work in this case.
  useEffect(() => {
    // eslint-disable-next-line
    window.addEventListener('message', ({ data }: any) => {
      if (data.payload?.event === 'createSegment') {
        navigation('/v2/contacts/segments');
        setContext(false);
        setCreateSegmentModal(true);
      }
    });
  }, []);

  return (
    <Flex column>
      <div
        className="contacts-body min-h-[calc(100vh_-_64px)] w-full bg-emblueLightGray"
        id="contacts-module"
      >
        <SecondaryNavigationMenu links={contactsNavigationLinks} />
        <ContactsProvider>
          <Container fluid="screen">
            <Outlet />
          </Container>
        </ContactsProvider>
      </div>
    </Flex>
  );
};

export default ContactsLayout;
