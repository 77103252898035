export const CampaignIcon = ({ color = '#0056B8' }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60058 2H2.39991C2.02965 2.00138 1.67502 2.16007 1.41385 2.44126C1.15267 2.72246 1.00628 3.10317 1.0068 3.49987L1 12.5002C1.00103 12.8976 1.14884 13.2785 1.41116 13.5595C1.67347 13.8406 2.02894 13.9989 2.39991 14H13.6001C13.9711 13.9989 14.3265 13.8406 14.5889 13.5595C14.8511 13.2785 14.999 12.8976 15 12.5002V4.99974C14.999 4.60229 14.8511 4.22142 14.5889 3.94038C14.3265 3.65934 13.9711 3.50097 13.6001 3.49987H10.8003H8.00049L6.60058 2Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
