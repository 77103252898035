export enum ProgressVelocity {
  slow = 'slow',
  medium = 'medium',
  fast = 'fast',
}

export enum velocityBar {
  slow = 2000,
  medium = 1000,
  fast = 500,
}
