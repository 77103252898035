/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { format, sameDay } from '@formkit/tempo';

import { LoadingIcon } from '@/lib/v2/icons/animated';

import { CalendarDays } from '@/src/modules/CampaignsModule/components/campaignCalendar/CalendarMain/components/CalendarDays';
import {
  classNames,
  getDayOfWeek,
  isSameMonth,
} from '@/src/modules/CampaignsModule/components/campaignCalendar/utils';

import { useCalendarActions } from './useCalendarActions.hooks';

import { CALENDAR_COL_START_CLASSES } from '@/modules/CampaignsModule/constants';

interface CalendarProps {
  currentDate?: Date;
  currentMonth: string;
}

const MAX_ACTIONS = 3;

const CalendarActions = ({ currentMonth }: CalendarProps) => {
  const {
    events: { handleDayClick },
    state: {
      currentDateCalendar,
      calendarLoader,
      calendarLoaderSidebar,
      listCalendar,
      calendarDays,
      currentMonthDate,
    },
  } = useCalendarActions(currentMonth);

  return (
    <>
      {calendarLoader && calendarLoaderSidebar && (
        <div className="absolute z-[1] m-auto flex size-full items-center justify-center bg-white bg-opacity-50">
          <LoadingIcon />
        </div>
      )}
      <div className="grid w-full grid-cols-7 gap-px">
        {calendarDays.map((day, index) => (
          <div
            key={`${day.getDate()}-${index}`}
            className={classNames(
              isSameMonth(day, currentMonthDate) ? 'bg-white' : 'bg-[#F4F5FA] text-emblue-disabled',
              'relative px-3 py-2 min-w-[138px] h-[128px] cursor-pointer',
              index === 0 ? CALENDAR_COL_START_CLASSES[getDayOfWeek(day)] : ''
            )}
            onClick={() => handleDayClick(day)}
          >
            <time
              className={classNames(
                'flex size-6 items-center justify-center rounded-full text-[13px]',
                sameDay(day, new Date()) ? 'bg-emblue-disabled text-white' : '',
                sameDay(day, currentDateCalendar) ? 'bg-emblue-primary text-white' : ''
              )}
              dateTime={format(day, 'YYYY-MM-DD')}
            >
              {format(day, 'D')}
            </time>
            {listCalendar && listCalendar.length > 0 && (
              <CalendarDays
                MAX_ACTIONS={MAX_ACTIONS}
                actionsList={listCalendar}
                currentMonthDate={currentMonthDate}
                day={day}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CalendarActions;
