import { FieldErrors } from 'react-hook-form';

import GroupContacts from '@/src/modules/RulesModule/components/FormTrigger/components/GroupContacts';
import RepeatedEmails from '@/src/modules/RulesModule/components/FormTrigger/components/RepeatedEmails';
import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';

import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ITriggerApiProps {
  errors: FieldErrors<ITriggerForm>;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
}
const TriggerApi = ({ form, errors, disabled = false }: ITriggerApiProps) => {
  return (
    <>
      <RepeatedEmails disabled={disabled} form={form} />
      <GroupContacts disabled={disabled} errors={errors} form={form} />
    </>
  );
};

export default TriggerApi;
