export const EmailIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7439" id="Group_7439" transform="translate(-676 -287)">
        <path
          d="M17.2,6.225v8.051A1.725,1.725,0,0,1,15.477,16H3.975A1.725,1.725,0,0,1,2.25,14.277V6.225m14.953,0A1.725,1.725,0,0,0,15.477,4.5H3.975A1.725,1.725,0,0,0,2.25,6.225m14.953,0v.186a1.725,1.725,0,0,1-.821,1.469L10.631,11.42a1.725,1.725,0,0,1-1.81,0L3.07,7.882a1.725,1.725,0,0,1-.82-1.469V6.225"
          data-name="Layout 3500"
          fill="none"
          id="Layout_3500"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(676.25 287)"
        />
        <rect
          data-name="Rectangle 4124"
          fill="none"
          height="20"
          id="Rectangle_4124"
          transform="translate(676 287)"
          width="20"
        />
      </g>
    </svg>
  );
};
