export const RightArrowNextIcon = (): JSX.Element => {
  return (
    <svg
      height="13.501"
      viewBox="0 0 20.243 13.501"
      width="20.243"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
        data-name="Icon ionic-ios-arrow-round-forward"
        fill={'#004dbc'}
        id="Icon_ionic-ios-arrow-round-forward"
        transform="translate(-7.875 -11.252)"
      />
    </svg>
  );
};
