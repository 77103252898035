import { ERecurrenceType } from '@/src/ContactsModule/utils/enums/Enums';

export const getRecurrence = (
  recurrenceId: ERecurrenceType,
  t: (key: string) => string
): string => {
  const RecurrenceToString = {
    [ERecurrenceType.DAY]: t(`RECURRENT_MESSAGE.day`),
    [ERecurrenceType.WEEK]: t(`RECURRENT_MESSAGE.week`),
    [ERecurrenceType.MONTH]: t(`RECURRENT_MESSAGE.month`),
  };

  return RecurrenceToString[recurrenceId] || '';
};
