import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardSection, IconSvg } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { ThumbIcon } from '@/lib/v2/icons/solid';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { useHappinessStateContext } from '@/src/modules/ContactsModule/contexts/HappinessContext';

import { HappinessCharts } from './components/HappinessCharts';
import { HappinessContactsInvolved } from './components/HappinessContactsInvolved';
import { HappinessFilterCards } from './components/HappinessFilterCards';
import { HappinessProcesses } from './components/HappinessProcesses';

export const HappinessMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { npsCount, npsRank, npsStats, npsGeneralValue, npsTotalStats } =
    useHappinessStateContext();

  const sectionTitle = useMemo(() => {
    const renderTitleCopy = (count?: number) => {
      if (!count || count > 1) {
        return t('HAPPINESS_DASHBOARD.activeQuantity');
      }
      return t('HAPPINESS_DASHBOARD.activeQuantitySingle');
    };
    return (
      <>
        ({npsCount ? npsCount : 0} {renderTitleCopy(npsCount)})
      </>
    );
  }, [npsCount, t]);

  const createNPSButton = useMemo(() => {
    const handleOnClickCreateNPSBtn = () => navigate('/v2/onsite');
    return (
      <Button
        iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
        id="happiness-create-nps-button"
        size="small"
        onClick={handleOnClickCreateNPSBtn}
      >
        {t('HAPPINESS_DASHBOARD.createNPS')}
      </Button>
    );
  }, [navigate, t]);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <SectionHeader
            actionsButtons={createNPSButton}
            icon={<ThumbIcon />}
            justifyContentActionsButtons="end"
            subtitle={t('HAPPINESS_DASHBOARD.sectionSubtitle')}
            title={t('HAPPINESS_DASHBOARD.sectionTitle')}
            titleDetail={sectionTitle}
          />
          {npsRank && <HappinessProcesses />}
        </CardSection>
      </Card>
      {!!npsCount && npsStats && !!npsGeneralValue && (
        <div className="mt-4">
          <HappinessCharts npsGeneralValue={npsGeneralValue} npsStats={npsStats} />
        </div>
      )}
      {!!(npsTotalStats?.detractors && npsTotalStats.passives && npsTotalStats.promoters) && (
        <div className="mt-4">
          <HappinessFilterCards />
        </div>
      )}
      <div className="mt-4">
        <HappinessContactsInvolved />
      </div>
    </>
  );
};
