import { Outlet } from 'react-router-dom';

import { Container } from '@/lib/v2/components';

const InboxLayout = () => {
  return (
    <>
      <Container fullHeight fluid="screen">
        <Outlet />
      </Container>
    </>
  );
};

export default InboxLayout;
