import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { Container } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { INIT_ONBOARDING_NEW_SS } from '@/src/modules/OnboardingModule/constants';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';

import { CardEmblue, CardRelevantLinks, CardStepper } from './components';

interface OnboardingProps {
  counter?: IFreeTrialDemo;
}

const Onboarding = ({ counter }: OnboardingProps) => {
  const { userData } = useStateUserContext();
  useEffect(() => {
    const onboardingDDSent = localStorage.getItem(INIT_ONBOARDING_NEW_SS);
    if (!onboardingDDSent) {
      datadogRum.addAction(INIT_ONBOARDING_NEW_SS, {
        email: userData.email,
      });
      localStorage.setItem(INIT_ONBOARDING_NEW_SS, 'true');
    }
  }, [userData.email]);

  return (
    <div className="min-h-[calc(100vh_-_64px)] w-full bg-emblueLightGray">
      <Container fluid="screen">
        <div className="flex w-full justify-end pb-4">
          {/*counter?.showCounter && !counter?.isBlocked && (
            <DemoTrialCounter freeTrialBlock={counter} />
          )*/}
        </div>
        <div className="flex size-full flex-col gap-4">
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <CardStepper />
            <CardRelevantLinks />
          </div>
          <CardEmblue />
        </div>
      </Container>
    </div>
  );
};

export default Onboarding;
