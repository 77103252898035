import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { memo } from 'react';

export interface DropdownItemProps {
  id: string | number;
  icon?: JSX.Element;
  label: string;
  value: string | number;
  onClick?: (item?: Omit<DropdownItemProps, 'onClick'>) => void;
  disabled?: boolean;
  tooltip?: string;
  checked?: boolean;
}

const DropdownItem = ({ onClick, ...restOfProps }: DropdownItemProps) => {
  const { id, label, disabled, tooltip, icon } = restOfProps;

  const handleOnClick = () => {
    onClick?.(restOfProps);
  };

  return (
    <div className="-mx-3 my-1" data-testid="dropdown-item-component" id={String(id)}>
      <Menu.Item disabled={disabled}>
        {({ active }) => (
          <button
            className={classNames(
              'flex gap-2 px-4 py-2 text-14 w-full rounded-md text-left',
              active ? 'bg-blue-300 text-gray-900' : 'text-gray-700',
              { '!text-gray-400': disabled }
            )}
            title={tooltip}
            onClick={handleOnClick}
          >
            {icon}
            {label}
          </button>
        )}
      </Menu.Item>
    </div>
  );
};

export default memo(DropdownItem);
