import { memo, MouseEventHandler } from 'react';

import { Button, Modal, Text } from '@/lib/v2/components';

interface ModalConfirmationProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  title?: string;
  description?: string;
  textCancelButton?: string;
  textConfirmButton?: string;
  onConfirmButton?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  onClickSecondaryButton?: MouseEventHandler<HTMLButtonElement>;
  textSecondaryButton?: string;
  isLoading?: boolean;
}

const ModalConfirmation = ({
  isOpen,
  onClose,
  title,
  description,
  textCancelButton = 'Cancel',
  textConfirmButton = 'Confirm',
  textSecondaryButton,
  onClickSecondaryButton,
  onConfirmButton,
  children,
  isLoading = false,
}: ModalConfirmationProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="flex w-full flex-col items-center gap-3 text-center">
        <div className="flex w-full flex-col items-center text-center">
          {title && (
            <Text alignment="center" fontWeight="medium">
              {title}
            </Text>
          )}
          {description && (
            <Text alignment="center" fontWeight="medium">
              {description}
            </Text>
          )}
          {children}
        </div>
        <div className="flex w-full flex-row items-center justify-center gap-3">
          {textSecondaryButton && onClickSecondaryButton ? (
            <Button
              fullWidth
              outline
              secondary
              id="secondary-button"
              onClick={onClickSecondaryButton}
            >
              {textSecondaryButton}
            </Button>
          ) : (
            <Button
              fullWidth
              outline
              secondary
              disabled={isLoading}
              id="cancel-button"
              onClick={() => onClose?.(false)}
            >
              {textCancelButton}
            </Button>
          )}
          <Button fullWidth id="confirm-button" isLoading={isLoading} onClick={onConfirmButton}>
            {textConfirmButton}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalConfirmation);
