export const ChatIcon = () => {
  return (
    <svg fill="none" height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="17"
        id="mask0_1_24"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="16.2373" width="16.2373" x="0.167969" y="0.457031" />
      </mask>
      <g mask="url(#mask0_1_24)">
        <path
          d="M4.08988 12.7725L2.69551 14.1669C2.45161 14.4108 2.17202 14.4661 1.85675 14.3328C1.54136 14.1996 1.38367 13.9606 1.38367 13.6157V3.21177C1.38367 2.78576 1.53369 2.42274 1.83374 2.12268C2.1338 1.82263 2.49682 1.67261 2.92283 1.67261H13.6506C14.0766 1.67261 14.4396 1.82263 14.7397 2.12268C15.0397 2.42274 15.1898 2.78576 15.1898 3.21177V11.2333C15.1898 11.6593 15.0397 12.0224 14.7397 12.3224C14.4396 12.6225 14.0766 12.7725 13.6506 12.7725H4.08988ZM4.90395 9.92877H8.96327C9.15497 9.92877 9.31565 9.86393 9.44532 9.73426C9.57499 9.60459 9.63983 9.44391 9.63983 9.25222C9.63983 9.06052 9.57499 8.89984 9.44532 8.77017C9.31565 8.6405 9.15497 8.57566 8.96327 8.57566H4.90395C4.71226 8.57566 4.55158 8.6405 4.4219 8.77017C4.29223 8.89984 4.22739 9.06052 4.22739 9.25222C4.22739 9.44391 4.29223 9.60459 4.4219 9.73426C4.55158 9.86393 4.71226 9.92877 4.90395 9.92877ZM4.90395 7.89911H11.6695C11.8612 7.89911 12.0219 7.83427 12.1515 7.7046C12.2812 7.57492 12.346 7.41424 12.346 7.22255C12.346 7.03086 12.2812 6.87018 12.1515 6.74051C12.0219 6.61083 11.8612 6.546 11.6695 6.546H4.90395C4.71226 6.546 4.55158 6.61083 4.4219 6.74051C4.29223 6.87018 4.22739 7.03086 4.22739 7.22255C4.22739 7.41424 4.29223 7.57492 4.4219 7.7046C4.55158 7.83427 4.71226 7.89911 4.90395 7.89911ZM4.90395 5.86944H11.6695C11.8612 5.86944 12.0219 5.80461 12.1515 5.67493C12.2812 5.54526 12.346 5.38458 12.346 5.19289C12.346 5.0012 12.2812 4.84052 12.1515 4.71084C12.0219 4.58117 11.8612 4.51634 11.6695 4.51634H4.90395C4.71226 4.51634 4.55158 4.58117 4.4219 4.71084C4.29223 4.84052 4.22739 5.0012 4.22739 5.19289C4.22739 5.38458 4.29223 5.54526 4.4219 5.67493C4.55158 5.80461 4.71226 5.86944 4.90395 5.86944Z"
          fill="#004DBC"
        />
      </g>
    </svg>
  );
};
