import { useAtom } from 'jotai';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '@/lib/components/ActionBar';
import {
  DeleteIconSolid,
  DistributeContactsIcon,
  DropdownCellPhoneIcon,
  DropdownMailIcon,
  DropdownPush,
  DropdownSmsIcon,
  EditStrongIcon,
  FlowsIcon,
} from '@/lib/icon';
import { Button } from '@/lib/v2/components/Button';
import { ExportUserIcon } from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomContactsDownload } from '@/src/atoms/Contacts';
import { ContactsDropDown } from '@/src/ContactsModule/components/ContactsDropDown';
import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export const dropdownOptions = {
  sendingType: [
    {
      title: 'Send Email',
      icon: <DropdownMailIcon />,
      onAction: () => {},
    },
    {
      title: 'Send Sms',
      icon: <DropdownSmsIcon />,
      onAction: () => {},
    },
    {
      title: 'Push Notifications',
      icon: <DropdownPush />,
      onAction: () => {},
    },
  ],
};

interface IGroupsActionBarProps {
  contactSelection: number;
  distributeIsDisabled: boolean;
  groupsSelected: IGroup[];
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setOpenDistributeContacts: Dispatch<SetStateAction<boolean>>;
  setOpenContactsToFlow: Dispatch<SetStateAction<boolean>>;
  setOpenContactsDownload: Dispatch<SetStateAction<boolean>>;
}

// TODO: Refactor all actionBar modals (ContactsActionBAr, GroupsActionBar, TagsActionBar, SegmentsActionBar ActionBar)
export const GroupsActionBar = ({
  contactSelection,
  distributeIsDisabled,
  setOpenDeleteModal,
  setOpenEditModal,
  setOpenDistributeContacts,
  setOpenContactsToFlow,
  setOpenContactsDownload,
  groupsSelected,
}: IGroupsActionBarProps) => {
  const { t } = useTranslation();
  const [contactsDownload] = useAtom(atomContactsDownload);

  const iconsActions = [
    {
      icon: <FlowsIcon />,
      onClick: () => setOpenContactsToFlow(true),
      tooltip: t('CONTACT_FLOW_MODAL.toolTip'),
      flagOn: true,
      disabled:
        contactSelection !== 1 || (contactSelection === 1 && groupsSelected?.[0]?.contacts === 0),
      disabledTooltip: '',
      id: 'connect-to-flow',
    },
    {
      icon: <EditStrongIcon />,
      onClick: () => setOpenEditModal(true),
      tooltip: t('MANAGE_GROUP_MODAL.edit.title'),
      flagOn: true,
      disabled: contactSelection !== 1,
      disabledTooltip: t('GROUP_ACTION_BAR.editOnly1'),
      id: 'edit-group',
    },
    {
      icon: <DistributeContactsIcon />,
      onClick: () => setOpenDistributeContacts(true),
      tooltip: t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.title'),
      flagOn: true,
      disabled: distributeIsDisabled,
      disabledTooltip: '',
      id: 'distribute-contacts',
    },
    {
      icon: <ExportUserIcon />,
      onClick: () => setOpenContactsDownload(true),
      tooltip: contactsDownload.show
        ? t('CONTACTS_ACTIONS_DROPDOWN.blockForDownload')
        : t('CONTACTS_ACTIONS_DROPDOWN.exportContactsCsv'),
      flagOn: true,
      disabled: contactSelection !== 1 || contactsDownload.show,
      disabledTooltip: '',
      id: 'export-csv',
    },
    {
      icon: <DeleteIconSolid />,
      onClick: () => setOpenDeleteModal(true),
      tooltip:
        contactSelection === 1
          ? t('GROUP_DELETE_MODAL.title')
          : t('GROUP_DELETE_MODAL.titlePlural'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'delete-group',
    },
  ];

  return (
    <ActionBar>
      <div>
        <section className="flex h-full items-center">
          <div className="text-xs ml-10 text-white" style={{ fontSize: '12px' }}>
            {`${quantifier(contactSelection)} ${t('CONTACTS_ACTIONS_DROPDOWN.Selected')}`}
          </div>
          <div className="ml-5 flex">
            {iconsActions.map((element) => {
              const {
                id,
                flagOn,
                disabled,
                icon,
                disabledTooltip,
                tooltip,
                onClick: handleOnClick,
              } = element;
              return (
                flagOn && (
                  <div key={id} className="mr-2">
                    <Button
                      {...(disabled && { disabled })}
                      fullWidth
                      outline
                      standard
                      iconLeft={icon}
                      id={id}
                      tooltip={disabled ? disabledTooltip : tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )
              );
            })}
          </div>
        </section>
      </div>
    </ActionBar>
  );
};
