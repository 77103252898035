//TO DO: remove these atoms from design kit and don't use this file, use react-hook-form
import deepEqual from 'fast-deep-equal';
import { atom } from 'jotai';
import { atomFamily, atomWithReset, RESET } from 'jotai/utils';
import { ChangeEvent } from 'react';

export type TValue = boolean | number | string | Blob | [] | any;

export interface IField<T extends TValue> {
  id?: string;
  validate?: (name: string, value: TValue, formData: IForm) => boolean;
  defaultValue?: TValue | T;
  value?: T;
  disabled?: boolean;
  label?: string;
  date?: Date | null;
  checked?: boolean;
  isDirty?: boolean;
  isRequired?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement> | any, field: IField<any>) => T;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
  step?: number;
  min?: number;
  max?: number;
  required?: string;
  pristine?: boolean;
  maxLength?: number;
  pattern?: {
    value: string;
    message?: string;
  };
}

export interface IFormFieldsConfig<T extends TValue> {
  [key: string]: IField<T>;
}

export interface IForm {
  [key: string]: IField<TValue>;
}

export enum IFormMode {
  ON_SUBMIT = 'onSubmit',
  ON_CHANGE = 'onChange',
}

interface IProps<T> {
  name: string;
  fields: IFormFieldsConfig<T>;
  options?: IFormOptions;
}

export interface IFormOptions {
  mounted?: boolean;
  mode?: IFormMode;
}

export interface IRegister<T extends TValue>
  extends Omit<IField<T>, 'pattern' | 'required' | 'pristine' | 'onChange'> {
  name?: string;
  value?: T;
  checked?: boolean;
  onChange?: (evt: any) => void;
  error?: boolean;
  message?: string;
}

export const atomCurrentForm = atomWithReset<null | IForm>(null);
atomCurrentForm.debugLabel = 'atomCurrentForm';

export const currentFormSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name, fields, options } = args;
  const { mounted = true } = options ?? {};
  const newAtom = atom(
    (get) => {
      if (!mounted) return {};

      const vForms = get(atomCurrentForm);
      const newForm = {
        ...fields,
        ...(vForms?.[name] ?? {}),
      };

      return newForm as IForm;
    },
    (get, set, arg: IForm | typeof RESET) => {
      if (!mounted) return {};
      const vForms = get(atomCurrentForm);

      if (arg === RESET) {
        set(atomCurrentForm, null);
        return {};
      }

      const newItem = {
        ...(fields?.[name] ?? {}),
        ...(vForms?.[name] ?? {}),
        ...arg,
      };

      const payload: IForm = {
        ...vForms,
        [name]: {
          ...fields,
          ...newItem,
        },
      };

      if (
        newItem?.maxLength &&
        typeof newItem?.value === 'string' &&
        newItem?.value.length > newItem?.maxLength
      )
        return vForms?.[name] ?? {};

      set(atomCurrentForm, payload);
    }
  );
  newAtom.debugLabel = '@atomFamily/currentFormSelector.' + name;

  return newAtom;
}, deepEqual);

export const currentValidSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name, fields, options } = args;
  const { mounted = true, mode } = options ?? {};

  const validateField = (field: IField<T>) => {
    const { value, pattern, required, defaultValue } = field;

    if (required && !pattern && (typeof value === 'string' || typeof defaultValue === 'string')) {
      const result =
        typeof value === 'undefined' ? (defaultValue !== '' ? true : false) : value !== '';

      return result;
    }

    if (!pattern?.value) {
      return true;
    }

    const exp = new RegExp(pattern?.value);

    if (typeof value !== 'string' && typeof defaultValue !== 'string') {
      return true;
    }

    const matches = (typeof value !== 'undefined' ? value : defaultValue).match(exp);

    return Array.isArray(matches) && matches?.length > 0 ? true : false;
  };

  const newAtom = atom((get) => {
    if (!mounted) return true;
    const vForms = get(atomCurrentForm);
    const result = [];
    if (vForms === null) return true;

    const form = (vForms?.[name] ?? fields ?? {}) as IForm;

    const getPristine = (fieldName: string, form: IForm) => {
      const input = form?.[fieldName as keyof typeof form] as IField<T>;
      if (input.value === undefined && input.checked === undefined) return true;
      return false;
    };

    for (const fieldName of Object.keys(form)) {
      const input = form?.[fieldName as keyof typeof form] as IField<T>;
      const field = {
        ...(fields?.[fieldName] ?? {}),
        ...input,
      };

      const isPristine = getPristine(fieldName, form);

      if (isPristine && mode === IFormMode.ON_SUBMIT) {
        continue;
      }

      if ((!field.validate && !field.pattern && !field.required) || form === null) continue;

      if (field.validate) {
        result.push(field.validate(fieldName, field, form));
      } else if (field.pattern || field.required) {
        result.push(validateField(field));
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return result?.every((item) => (item ? true : false));
  });

  newAtom.debugLabel = '@atomFamily/currentValidSelector.' + name;

  return newAtom;
}, deepEqual);

export const currentErrorsSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name, fields, options } = args;
  const { mounted, mode } = options ?? {};
  const validateField = (field: IField<T>) => {
    const { pattern, value, defaultValue } = field;

    if (pattern && pattern.value) {
      const exp = new RegExp(pattern?.value);
      const newValue = value === undefined ? defaultValue : value;

      if ((typeof value !== 'string' && typeof defaultValue !== 'string') || newValue === '')
        return false;

      return exp.test(newValue);
    }

    if (pattern && typeof field?.pattern?.value === 'undefined') return false;
    if (
      field.required &&
      !pattern &&
      ((typeof value === 'string' && value !== '') ||
        (value === undefined && typeof defaultValue === 'string' && defaultValue !== ''))
    )
      return true;

    return false;
  };

  const getPristine = (fieldName: string, form: IForm) => {
    const input = form?.[fieldName as keyof typeof form] as IField<T>;
    if (input.value === undefined && input.checked === undefined) return true;
    return false;
  };

  const newAtom = atom((get) => {
    if (!mounted) return {};
    const vForms = get(atomCurrentForm);
    const result = {} as { [key: string]: string };
    if (vForms === null) return true;

    const form = (vForms?.[name] ?? fields ?? {}) as IForm;

    for (const fieldName of Object.keys(form)) {
      const input = form?.[fieldName as keyof typeof form] as IField<T>;
      const field = {
        ...(fields?.[fieldName] ?? {}),
        ...input,
      };

      const isPristine = getPristine(fieldName, form);

      if (isPristine && mode === IFormMode.ON_SUBMIT) {
        continue;
      }

      if ((!field.validate && !field.pattern && !field.required) || form === null) continue;

      if (field.validate && !field.validate(fieldName, field as IField<T>, form)) {
        result[fieldName] = field?.required ?? field.pattern?.message ?? '';
      } else if (field.pattern && field.required && !validateField(field)) {
        result[fieldName] = field.pattern?.message || '';
      } else if (!field.pattern && field.required && !field.validate && !validateField(field)) {
        result[fieldName] = field?.required || '';
      }
    }

    return result;
  });

  newAtom.debugLabel = '@atomFamily/currentErrorsSelector.' + name;

  return newAtom;
}, deepEqual);

export const currentPristineSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name, options } = args;
  const { mounted = true } = options ?? {};
  const newAtom = atom((get) => {
    if (!mounted) return {};
    let vForms = get(atomCurrentForm);

    if (vForms === null) return true;

    const vSaved = localStorage.getItem('@form-persistence');
    if (vSaved && vForms === null) {
      vForms = JSON.parse(vSaved);
    }

    const result = true;

    const form = vForms?.[name];
    if (!form) return true;

    for (const fieldName of Object.keys(form)) {
      const input = form?.[fieldName as keyof typeof form] as IField<T>;

      if (input.value) return false;
    }

    return result;
  });

  newAtom.debugLabel = '@atomFamily/currentErrorsSelector.' + name;

  return newAtom;
}, deepEqual);

export const currentValidatingSelector = atomFamily(<T>(args: IProps<T>) => {
  const { name } = args;
  const newAtom = atom(false);

  newAtom.debugLabel = '@atomFamily/currentValidatingSelector.' + name;

  return newAtom;
}, deepEqual);
