import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Option, Select, Text } from '@/lib/v2/components';

import { ECustomFieldFilterType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';
import { WarningPhone } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/components/WarningPhone';
import { ISMSFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/hooks/useSmsForm';
import { useSmsFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/hooks/useSmsFormEvents';

import {
  atomActionInfoSms,
  atomInfoSmsPayload,
} from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { TRANSLATE_SMS_ACTION_FORM } from '@/modules/CampaignsModule/constants';
import { IFormProps, ISmsInfoPayload } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IPhoneInputProps {
  form: IFormProps<ISMSFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const INTERNAL_PHONE_FIELDS = [5, 6];

const PhoneInput = ({ form, rules, disabled = false }: IPhoneInputProps) => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const { control, setValue } = form;

  const actionInfoSms = useAtomValue(atomActionInfoSms);
  const [infoSmsPayload, setInfoSmsPayload] = useAtom(atomInfoSmsPayload);

  const {
    events: { getCustomFieldsFilter, translateCustomField },
    state: { loading: loadingCustomField },
  } = useCustomField();

  const { setInfoOnEvent } = useSmsFormEvents({ actionId: Number(actionId) });

  const customFieldsListPhone = useMemo(() => {
    const fields = getCustomFieldsFilter({
      ids: INTERNAL_PHONE_FIELDS,
      filterType: ECustomFieldFilterType.INTERNAL_NUMBER,
    });

    return fields.map(
      (field): Option => ({
        id: field.id,
        name: translateCustomField(field.name),
        value: field.id,
      })
    );
  }, [getCustomFieldsFilter, translateCustomField]);

  useEffect(() => {
    const currentPhoneField = customFieldsListPhone.find(
      (field) => Number(field.id) === Number(actionInfoSms?.campo)
    );

    if (!currentPhoneField) return;

    const phoneField = {
      id: currentPhoneField?.id,
      name: currentPhoneField?.name,
      value: currentPhoneField?.name,
    } as Option;

    setValue('phone', phoneField);
  }, [actionInfoSms, customFieldsListPhone, setValue]);

  const handleChangePhone = useCallback(
    (value: Option | Option[]) => {
      const selectedOption = value as Option;

      const payload: ISmsInfoPayload = {
        ...infoSmsPayload,
        campoId: Number(selectedOption.value),
      };

      setInfoSmsPayload(payload);
      setInfoOnEvent(payload);
    },
    [infoSmsPayload, setInfoOnEvent, setInfoSmsPayload]
  );

  return (
    <>
      <Text color="dark" fontWeight="normal" variant="text">
        {t(`${TRANSLATE_SMS_ACTION_FORM}.phoneField`)}
      </Text>
      <Select
        control={control}
        disabled={disabled}
        id="sms-phone-select"
        isLoading={loadingCustomField}
        name="phone"
        options={customFieldsListPhone}
        placeholder={t(`${TRANSLATE_SMS_ACTION_FORM}.phonePlaceholder`)}
        rules={rules.phone}
        onChange={handleChangePhone}
      />
      <WarningPhone />
    </>
  );
};

export default PhoneInput;
