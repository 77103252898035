/* cspell:disable */
export const AnalysisResult = {
  ProcessIdentifier: 297001710,
  Estado: null,
  Error: null,
  contactosDetectados: 310,
  contactosImportados: {
    cantidad: 310,
    detalle: {
      nuevos: 148,
      actualizados: 32,
      nuevos_corregidos: 28,
      actualizados_corregidos: 1,
      reactivados: 8,
      reactivados_corregidos: 0,
      sin_modificar: 0,
    },
  },
  cantidadDescartados: {
    cantidad: 12,
    detalle: {
      repetidos: 1,
      insuficientes: 0,
      invalidos: 11,
      basuras: 0,
    },
  },
};
