import { Dispatch, MouseEvent, SetStateAction } from 'react';

import { ISelectedDictionary } from '@/lib/v2/components/EntityAssignment/EntityAssignment';
import { ISelectableItem } from '@/lib/v2/components/Selectable';

import { CardType } from '@/src/ContactsModule/utils/enums/Enums';
import { SegmentsSwitcherEnum } from '@/src/ContactsModule/utils/enums/SegmentsSwitcher';

import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';

export interface ITableContact {
  contact: TableContact;
}

export interface ITableContactProps {
  contactList: ITableContact[];
  totalContacts?: number;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setSelectedContacts?: Dispatch<SetStateAction<number[]>>;
  toggleAllRowsSelected?: boolean;
  isDiscarded?: boolean;
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
  noNegativeMargin?: boolean;
  classNamePagination?: string;
  isEmptySegment?: boolean;
  setLastPageViewed: Dispatch<SetStateAction<number>>;
  lastPageViewed: number;
  pageIndex: number;
}

export interface ITableContactProfileTagsProps {
  activityList: IContactActivityTags[];
  activityCount?: number;
  activityTagsTotalCount?: number;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  onSearch?: (search: string) => void;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
}

export interface ITableContactProfileChannelProps {
  activityList: IContactActivity[];
  activityCount: number;
  activityTotalCount: number;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
}

export interface ITableContactProfileItemsProps {
  activityList: IContactActivityItems[];
  activityTotalCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
}

export interface ITableContactProfileGroupsProps {
  activityList: IContactActivityGroups[];
  activityTotalCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
}

export interface contactValues {
  contactValues: TableContact;
}

export enum ContactStatus {
  discarded = 1,
  inactive,
  new,
  frequent,
  asleep,
}
export interface TableContact {
  emailId: number;
  email: string;
  name?: string;
  lastName?: string;
  phone?: string;
  gender?: string;
  tags?: ITableContactTag[];
  origin?: 'csv' | 'integrations';
  status?: 'new' | 'frequent' | 'asleep' | 'inactive' | 'discarded' | '';
  created?: Date | string;
  lastSend?: Date | string;
  lastActivity?: Date | string;
  country?: string;
  company?: string;
  discarded?: string;
  subStatus?: string;
  npsStatus?: 'promoter' | 'passive' | 'detractor';
  npsScore?: number;
  npsCampaign?: string;
  npsDate?: Date;
}
export interface IContactExtraInfo {
  emailId: number;
  country?: string;
  company?: string;
}
export interface IContactExtendedData {
  emailId: number;
  created?: Date | null;
  lastUpdated?: Date | null;
  lastSend?: Date | null;
  lastOpening?: Date | null;
  lastClick?: Date | null;
  lastViral?: Date | null;
  lastActivity?: Date | null;
  lastWebsiteVisit?: Date | null;
  lastWebTag?: Date | null;
  lastWebItem?: Date | null;
  lastPushSubscription?: Date | null;
  lastPushSend?: Date | null;
  lastSendSMS?: Date | null;
}

export interface ITableContactTag {
  id: number;
  name: string;
}
export interface ITag {
  id: number;
  name: string;
  category?: string | null;
  color?: string | null;
  contacts?: number;
  activity?: number;
  updated?: string;
  isSystemTag?: boolean;
}
export interface ICollection {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  createdAt?: string;
  name: string;
  id: string | number;
  parentId?: number;
}
export interface ICategory {
  id: number;
  name: string;
  created_at?: string;
}
export interface IDesigner {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  avatar_url: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  display_name: string;
  id: string;
}

export interface ITagContent {
  id: number;
  content: string;
  url: string;
  encoding: number;
  tagContentTypeId?: number;
}

export interface ITableTagProps {
  tagList: { tag: ITag }[];
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  totalTagCount?: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setSelectedTags: Dispatch<SetStateAction<number[]>>;
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
  recalculateTag: (event: MouseEvent<HTMLButtonElement>, tagId: number) => void;
  isRecalculateTag: boolean;
}

//cspell: disable
/**
 * @TableReference **_gruposylistas_tipo**
 */
export type GroupTypeId = 1 | 2 | 3 | 4 | 5 | 6 | 7;

/**
 * @TableReference **_gruposylistas_tipo**
 */
export const groupTypeValues: Record<GroupType, GroupTypeId> = {
  system: 1,
  group: 2,
  segment: 3,
  /**
   * @deprecated
   */
  distributionList: 4,
  tag: 5,
  integration: 6,
  /**In table is integration2*/
  ecommerce: 7,
};
//cspell: enable

export type GroupType =
  | 'system'
  | 'group'
  | 'segment'
  | 'distributionList'
  | 'tag'
  | 'integration'
  | 'ecommerce';

/**
 * This is the equivalent to IGroup in ema-node
 * Must be used ONLY in Service layer (eg: EmblueService.ts)
 */
export type GroupAPIInterface = Omit<IGroup, 'groupName'> & { name: string };

export interface IGroup {
  id: number;
  groupName: string;
  contacts?: number;
  type?: GroupType;
}

export interface IGroupList {
  list: { group: IGroup }[];
}

export interface IGroupByContactsResponse {
  allInCommon: number[];
  partiallyInCommon: number[];
}
export interface IGroupByContactsQuery {
  contactsIds: number[];
  allResults?: boolean;
  filters?: ContactTableSearchFilters;
}
export interface IGroupAndTagsByContacts {
  id: number;
  type: string;
  name: string;
  isCommonToAllContacts: boolean;
}

export interface ITagsByContactsResponse {
  allInCommon: number[];
  partiallyInCommon: number[];
}
export interface ITagsByContactsQuery {
  contactsIds: number[];
  allResults?: boolean;
  filters?: ContactTableSearchFilters;
}

export interface ITableGroupProps {
  groupList: { group: IGroup }[];
  totalGroupCount?: number;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, () => void];
  setSelectedGroups: Dispatch<SetStateAction<number[]>>;
}

export interface ISegmentTableRow {
  id: number;
  name: string;
  contacts: number;
  segmentTypeId?: 0 | 1 | 2;
  lastTotalContactsUpdate?: string;
}

export interface ISegmentConditions {
  id: number;
  specie: string;
  instance: InstanceSegment[];
}
export interface InstanceSegment {
  logicOperator: number;
  validationType: KeyValuePairNumber[];
  values: KeyValuePairString[];
  secondaryValues: Record<string, string[]>;
  typeValue: Record<number, number>;
  typeSecondaryValue: Record<string, number>;
}

export interface ICustomField {
  icon: string;
  id: string;
  // cspell:disable
  integracion_id: string | null;
  name: string;
  number: string;
  reserved: boolean;
  reservedField: boolean;
  // cspell:disable
  solo_lectura: boolean;
  typeData: string;
  typeField: string;
  typeOption: string;
  values: string | null;
}

type InterestGroup = [id: string, name: string];

export interface InterestGroupsData {
  aaData: InterestGroup[];
  iTotalDisplayRecords: string;
  iTotalRecords: string;
  sEcho: string;
}

type Group = [id: string, name: string, memberCount: string, active: string, inactive: string];

export interface GroupsData {
  aaData: Group[];
  iTotalDisplayRecords: string;
  iTotalRecords: string;
  sEcho: string;
}
export interface KeyValuePair {
  Key: string;
  Value: KeyValuePair[];
}

export interface KeyValuePairNumber {
  Key: string;
  Value: number;
}
export interface KeyValuePairString {
  Key: string;
  Value: string[];
}

interface KeyValuePairArray {
  Key: string;
  Value: KeyValuePair[];
}

export type ProfileOptions = KeyValuePairArray[];

export interface IItem {
  name: string;
  lastActivity: Date | string;
  contacts: number;
}

export interface IItemTableProps {
  itemList: { item: IItem }[];
  totalItemCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
}

export interface ISegmentTableProps {
  segmentList: { segment: ISegmentTableRow }[] | number[] | undefined;
  totalSegmentCount: number;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?, (() => void)?];
  recalculateSegment: (event: MouseEvent<HTMLButtonElement>, segmentId: number) => void;
  isRecalculateSegment: boolean;
  segmentsSwitcher?: SegmentsSwitcherEnum;
  isVTEXEnabled?: boolean;
  setSelectedSegments?: Dispatch<SetStateAction<number[]>>;
  pageSize?: number;
}

export interface IStatus {
  id: string;
  name: string;
  state?: string;
  total?: number;
}

export interface IHappiness {
  id: string;
  name: string;
}
export interface IStubs {
  [name: string]: any;
}

export interface IContactActivity {
  reportId: number;
  date: Date;
  activityType: number;
  actionName: string;
  campaignName: string;
  channel: number;
}

export interface IContactActivityTags {
  id: number;
  tagDate: Date;
  tag: string;
  source: string;
  association: string;
  status: number;
  emailId: number;
}

export interface IContactActivityItems {
  id: number;
  date: Date;
  item: string;
  source: string;
}

export interface IContactActivityGroups {
  id: number;
  type: string;
  name: string;
  contacts: number;
}

export interface ICountries {
  id: string;
  value: string;
}

export enum ActivityType {
  SEND = 1,
  OPEN = 2,
  CLICK = 3,
  VIRAL = 4,
  SUBSCRIPTION = 5,
  UNSUBSCRIPTION = 6,
  COMPLAINT = 7,
  BOUND = 8,
  SENDED_SMS = 9,
  DELIVERED_SMS = 10,
  FAILED_SMS = 11,
  PUSH = 12,
}

export enum ChannelType {
  EMAIL = 1,
  TRIGGER = 2,
  SMS = 3,
  PUSH = 4, // Onsite
}

export interface activityChannel {
  id: number;
  title: number;
}

export interface ITagCategory {
  data: object;
}

export interface CardList {
  items: IStatusList[];
  onAction?: Function;
  list?: IStatusList[];
  cardType: CardType;
}

export interface IContactNPSInfoList {
  emails: { [x: string]: IContactNPSInfo[] };
}

export interface IContactNPSInfo {
  widgetName: string;
  value: number;
  date: string;
  status?: 'promoter' | 'passive' | 'detractor' | 'noRegistry' | undefined;
  email?: string;
}

export interface IContactNPSInfoActivity {
  npsCampaign: string;
  npsScore: number;
  npsDate: string;
  npsStatus: 'promoter' | 'passive' | 'detractor' | 'noRegistry';
}

export interface IContactNPSInfoActivityList {
  npsList: IContactNPSInfoActivity[];
  totalCount?: number;
  partialCount?: number;
}

export interface IContactECommerceActivity {
  converted?: boolean;
  error?: string;
  description: string;
  date: string;
  amount: string;
  statusInvoice: string;
}

export interface ITableNpsActivity {
  npsList: IContactNPSInfoActivity[];
  activityCount?: number;
  totalNpsCount?: number;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
}

export interface ITableECommerceActivity {
  activityECommerceList: IContactECommerceActivity[];
  activityCount?: number;
  totalECommerceCount?: number;
  changeTableOrder?: (element: { id: string; isAsc: boolean | undefined }) => void;
  changeTablePage?: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  totalPages?: number;
  isLoading?: boolean;
  gotoPageIndex?: number;
  callToAction?: [() => void, (() => void)?];
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
}

export interface IStatusList {
  state: string;
  number: number;
  subtitle?: string;
  total: number;
  title: string;
  icon: JSX.Element;
  tooltip: string;
  iconBgColor: string;
  filter: string;
  discarded: string;
}

export interface IModal {
  children: React.ReactNode;
  apply?: () => void;
  cancel?: () => void;
  toggleRef?: React.MutableRefObject<HTMLDivElement>;
  disabled?: boolean;
  title?: string;
  subTitle?: string;
  edit?: () => void;
}

/* cspell:disable */
export interface IContactFieldsTranslations {
  nombre: string;
  apellido: string;
  id_contacto: string;
  sexo: string;
  telefono_1: string;
  telefono_2: string;
  web_url: string;
  direccion: string;
  ciudad: string;
  pais: string;
  cumpleanios: string;
  empresa: string;
  cargo: string;
  email_secundario: string;
  id_trigger: string;
  recencia: string;
  frecuencia: string;
  monto: string;
  nps: string;
  clasificacion_ecommerce: string;
  [key: string]: string;
}
/* cspell:enable */

export interface IContactFieldsTranslationsToV1 {
  names: string;
  lastNames: string;
  idContact: string;
  gender: string;
  mainPhone: string;
  secondaryPhone: string;
  website: string;
  address: string;
  city: string;
  country: string;
  birthday: string;
  company: string;
  position: string;
  secondaryEmail: string;
  idTrigger: string;
  recency: string;
  frequency: string;
  amount: string;
  eCommerceClassification: string;
  [key: string]: string;
}
/* cspell:disable */
export interface IECommerceFieldsTranslations {
  convertida: string;
  descripcion: string;
  fecha: string;
  monto: string;
  status: string;
  [key: string]: string;
}

export interface DistributeGroupsResponse {
  resultado: string;
  grupos: number[];
}
/* cspell:enable */

export interface SegmentsSwitcher {
  segmentsSwitcher: SegmentsSwitcherEnum;
}

export interface IUpdateItemsToProps {
  selectedContacts: number[];
  selectedItems: ISelectableItem[];
  unselectedItems: ISelectableItem[];
}

export interface IUpdateItemsToPropsV2 {
  selectedContacts: number[];
  selectedItems: ISelectedDictionary;
  unselectedItems: ISelectedDictionary;
}
