import { useTranslation } from 'react-i18next';

import { Alert } from '@/lib/v2/components';

import { TRANSLATE_SMS_ACTION_FORM } from '@/modules/CampaignsModule/constants';

const WarningByCountry = () => {
  const { t } = useTranslation();
  return (
    <Alert
      className="mt-1"
      description={t(`${TRANSLATE_SMS_ACTION_FORM}.warning_co`)}
      type="info"
    />
  );
};

export default WarningByCountry;
