import { IQuickActionsProps } from '@/lib/v2/components/SmartFinder/SmartFinder.interface';

const QuickActions = ({ data }: IQuickActionsProps) => {
  return (
    <div className="flex flex-col space-y-2 px-6 py-4">
      {data.map((item) => (
        <button
          key={item.id}
          className="flex items-center space-x-2 rounded-md px-3 py-2 text-gray-700 outline-0 hover:bg-gray-300 focus:bg-gray-300"
          onClick={() => item.handleOnClick && item.handleOnClick()}
        >
          {item.icon}
          <span>{item.title}</span>
        </button>
      ))}
    </div>
  );
};

export default QuickActions;
