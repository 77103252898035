import { memo } from 'react';

interface SelectedOptionsProps {
  items: { id: number; name: string }[];
  deleteOption: (id: number) => () => void;
}

const SelectedOptions = ({ items, deleteOption }: SelectedOptionsProps) => {
  const selectedOptions = items.map((item) => (
    <span
      key={item.id}
      className="inline-flex h-5 items-center rounded-full bg-blue-50 pl-2 pr-0.5 text-base font-medium text-emblue-primary"
    >
      {item.name}
      <span
        className="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-emblue-primary
                hover:bg-indigo-200 hover:text-emblue-primary-hover focus:bg-blue-400
                focus:text-white focus:outline-none"
        role="button"
        onClick={deleteOption(item.id)}
      >
        <span className="sr-only">Remove small option</span>
        <svg className="size-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
          <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
        </svg>
      </span>
    </span>
  ));

  return <>{selectedOptions}</>;
};

export default memo(SelectedOptions);
