export const ProcessIcon = () => (
  <svg height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0)">
      <rect fill="none" height="16" transform="translate(0)" width="16" />
      <path
        d="M16.538,3H4.5a1.5,1.5,0,0,0-1.5,1.5L3,18.042l3.008-3.008H16.538a1.509,1.509,0,0,0,1.5-1.5V4.5A1.509,1.509,0,0,0,16.538,3ZM6.008,12.025V10.168l5.174-5.174a.372.372,0,0,1,.534,0l1.331,1.331a.372.372,0,0,1,0,.534L7.866,12.025Zm9.025,0H9.393l1.5-1.5h4.137Z"
        fill="#004dbc"
        transform="translate(-2.521 -1.042)"
      />
    </g>
  </svg>
);
