export const EmptyActivityIcon = () => {
  return (
    <svg
      height="160.756"
      viewBox="0 0 247.5 160.756"
      width="247.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Grupo 7100" id="Grupo_7100" transform="translate(-1034 -690.244)">
        <g opacity="0.2" transform="translate(2330.5 2107.744)">
          <g data-name="Grupo 6960" id="Grupo_6960" transform="translate(-1522 -1578)">
            <path
              d="M419,300H238a12.035,12.035,0,0,1-12-12V173a12.035,12.035,0,0,1,12-12H419a12.035,12.035,0,0,1,12,12V288A12.035,12.035,0,0,1,419,300Z"
              data-name="Trazado 4459"
              fill="#fff"
              id="Trazado_4459"
              stroke="#231f20"
              strokeWidth="1"
            />
            <rect
              data-name="Rectángulo 4139"
              fill="#f4f5fa"
              height="11"
              id="Rectángulo_4139"
              stroke="#231f20"
              strokeWidth="1"
              transform="translate(294.5 307.5)"
              width="67"
            />
            <rect
              data-name="Rectángulo 4140"
              fill="#f4f5fa"
              height="7"
              id="Rectángulo_4140"
              stroke="#231f20"
              strokeWidth="0.812"
              transform="translate(314.5 300.5)"
              width="27"
            />
            <circle
              cx="4"
              cy="4"
              fill="#f4f5fa"
              r="4"
              stroke="#231f20"
              strokeWidth="0.815"
              transform="translate(241.5 285.5)"
            />
            <path
              d="M419,161H402.5L374.7,300H419a12.035,12.035,0,0,0,12-12V173A12.035,12.035,0,0,0,419,161Z"
              data-name="Trazado 4460"
              fill="#dadada"
              id="Trazado_4460"
              stroke="#231f20"
              strokeWidth="1"
            />
            <path
              d="M407.5,276.5h-158a12.035,12.035,0,0,1-12-12v-80a12.035,12.035,0,0,1,12-12h158a12.035,12.035,0,0,1,12,12v80A12.035,12.035,0,0,1,407.5,276.5Z"
              data-name="Trazado 4461"
              fill="#004dbc"
              id="Trazado_4461"
              stroke="#231f20"
              strokeWidth="1"
            />
            <path
              d="M5.245,59.758,36.126,19.622,71.351,47.294l30.221-39"
              data-name="Trazado 3361"
              fill="none"
              fillRule="evenodd"
              id="Trazado_3361"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="10"
              transform="translate(274.592 190.475)"
            />
          </g>
        </g>
        <g data-name="Grupo 6959" id="Grupo_6959" transform="translate(189.5 33)">
          <circle cx="40" cy="40" fill="#fff" r="40" transform="translate(999 721)" />
          <g id="unhappy-result" transform="translate(992 718)">
            <path
              d="M39.35,13a1.65,1.65,0,1,1-1.65,1.65A1.677,1.677,0,0,1,39.35,13ZM9.65,13A1.65,1.65,0,1,1,8,14.65,1.677,1.677,0,0,1,9.65,13Z"
              data-name="Trazado 4447"
              fill="#002646"
              id="Trazado_4447"
              transform="translate(16.1 27.6)"
            />
            <path
              d="M29.677,31.3a1.657,1.657,0,0,1-1.168-.482L21.2,23.5a1.65,1.65,0,1,1,2.333-2.333l7.313,7.313a1.651,1.651,0,0,1,0,2.333A1.63,1.63,0,0,1,29.677,31.3Z"
              data-name="Trazado 4448"
              fill="#002646"
              id="Trazado_4448"
              transform="translate(45.342 45.277)"
            />
            <path
              d="M91.75,100a8.209,8.209,0,0,1-5.834-2.416l-11.55-11.55a8.254,8.254,0,0,1,0-11.669,8.707,8.707,0,0,1,11.665,0l11.55,11.55A8.248,8.248,0,0,1,91.75,100ZM80.2,75.316A4.905,4.905,0,0,0,76.7,83.7l11.55,11.55a5.072,5.072,0,0,0,7,0,4.951,4.951,0,0,0,0-7L83.7,76.7A4.964,4.964,0,0,0,80.2,75.316ZM40.6,80.2A39.6,39.6,0,1,1,80.2,40.6,39.644,39.644,0,0,1,40.6,80.2Zm0-75.9A36.3,36.3,0,1,0,76.9,40.6,36.343,36.343,0,0,0,40.6,4.3Z"
              data-name="Trazado 4449"
              fill="#002646"
              id="Trazado_4449"
            />
            <path
              d="M28.95,24.119a1.651,1.651,0,0,1-1.65-1.65,1.65,1.65,0,1,0-3.3,0,1.65,1.65,0,0,1-3.3,0,4.95,4.95,0,1,1,9.9,0A1.651,1.651,0,0,1,28.95,24.119ZM40.5,19.1a3.3,3.3,0,1,1,3.3-3.3A3.344,3.344,0,0,1,40.5,19.1Zm-29.7,0a3.3,3.3,0,1,1,3.3-3.3A3.344,3.344,0,0,1,10.8,19.1Z"
              data-name="Trazado 4450"
              fill="#002646"
              id="Trazado_4450"
              transform="translate(14.95 26.45)"
            />
            <path
              d="M29.677,31.3a1.657,1.657,0,0,1-1.168-.482L21.2,23.5a1.65,1.65,0,1,1,2.333-2.333l7.313,7.313a1.651,1.651,0,0,1,0,2.333A1.63,1.63,0,0,1,29.677,31.3Z"
              data-name="Trazado 4451"
              fill="#002646"
              id="Trazado_4451"
              transform="translate(45.342 45.277)"
            />
            <path
              d="M91.75,100a8.209,8.209,0,0,1-5.834-2.416l-11.55-11.55a8.254,8.254,0,0,1,0-11.669,8.707,8.707,0,0,1,11.665,0l11.55,11.55A8.248,8.248,0,0,1,91.75,100ZM80.2,75.316A4.905,4.905,0,0,0,76.7,83.7l11.55,11.55a5.072,5.072,0,0,0,7,0,4.951,4.951,0,0,0,0-7L83.7,76.7A4.964,4.964,0,0,0,80.2,75.316ZM40.6,80.2A39.6,39.6,0,1,1,80.2,40.6,39.644,39.644,0,0,1,40.6,80.2Zm0-75.9A36.3,36.3,0,1,0,76.9,40.6,36.343,36.343,0,0,0,40.6,4.3Z"
              data-name="Trazado 4452"
              fill="#002646"
              id="Trazado_4452"
            />
            <path
              d="M28.95,24.119a1.651,1.651,0,0,1-1.65-1.65,1.65,1.65,0,1,0-3.3,0,1.65,1.65,0,0,1-3.3,0,4.95,4.95,0,1,1,9.9,0A1.651,1.651,0,0,1,28.95,24.119ZM40.5,19.1a3.3,3.3,0,1,1,3.3-3.3A3.344,3.344,0,0,1,40.5,19.1Zm-29.7,0a3.3,3.3,0,1,1,3.3-3.3A3.344,3.344,0,0,1,10.8,19.1Z"
              data-name="Trazado 4453"
              fill="#002646"
              id="Trazado_4453"
              transform="translate(14.95 26.45)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
