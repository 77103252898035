import { MouseEventHandler } from 'react';

import './Card.tailwind.css';

export type CardProps = {
  children?: JSX.Element[] | JSX.Element | string;
  /** Red background color Card */
  red?: boolean;
  yellow?: boolean;
  blue?: boolean;
  secondary?: boolean;
  noShadow?: boolean;
  overflow?: boolean;
  onClick?: () => void;
  noPadded?: boolean;
};

export const Card = ({
  children,
  red,
  yellow,
  blue,
  secondary,
  noShadow,
  onClick,
  overflow,
  noPadded,
}: CardProps) => (
  <div
    className={`
      card flex size-full flex-col justify-center rounded-lg
      ${noPadded ? 'p-0' : 'p-4'}
      ${noShadow ? '' : 'shadow'}
      ${secondary ? 'secondary' : red ? 'red' : yellow ? 'yellow' : blue ? 'blue' : ''}
      ${red || yellow || blue ? 'white-content' : ''}
      ${overflow ? 'overflow-visible' : 'text-clip'}
    `}
    onClick={onClick as MouseEventHandler<HTMLDivElement>}
  >
    {children}
  </div>
);

Card.Header = ({
  children,
  borderBottom,
  noPaddingBottom,
  extend,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  borderBottom?: boolean;
  noPaddingBottom?: boolean;
  extend?: boolean;
}) => (
  <div
    className={`
    ${
      extend
        ? 'flex size-full grow items-center justify-between border-b border-gray-200 px-1 pb-4 text-center'
        : ''
    }
    ${noPaddingBottom ? '-mx-4 px-4' : 'px-4 pb-4'}
    ${borderBottom ? 'border-b border-gray-200' : ''}`}
  >
    {children}
  </div>
);

Card.Body = ({
  children,
  padded,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  padded?: boolean;
}) => <div className={`${padded ? '' : '-mx-4'} full-width p-4`}>{children}</div>;
