import { useCallback, useEffect, useRef, useState } from 'react';

import { TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IContactNPSInfoActivityList } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useContactInfo } from '@/src/infrastructure/services/useContactInfo';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ContactsProfileHappinessTable } from './ContactsProfileHappinessTable';
import { HappinessFilters } from './HappinessFilters';

export const ContactsProfileActivityHappinessTable = ({ emailId }: { emailId: number }) => {
  const [totalNpsContacts, setTotalNpsContacts] = useState<number>();
  const { pageSize, pageIndex, totalPages, changeTablePage, gotoPageIndex, resetPagination } =
    useTablePagination(totalNpsContacts);
  const [search, setSearchValue] = useState<string>('');
  const [orderColumn, setOrderColumn] = useState<string>('npsDate');
  const [orderDirection, setOrderDirection] = useState<string>('desc');

  const [qualificationFilter, setQualificationFilter] = useState<string[]>([]);

  const [dateFilter, setDateFilter] = useState<[Date | null, Date | null]>([null, null]);

  const startDate = dateFilter[0] !== null ? dateFilter[0] : null;
  const endDate = dateFilter[1] !== null ? dateFilter[1] : null;

  const [contactInfo] = useContactInfo(Number(emailId));
  const [npsList, , isLoadingNps] = useEmblue(ServiceMethods.getContactNPSActivity, {
    email: contactInfo && contactInfo.email,
    search: search,
    startDate: startDate,
    endDate: endDate,
    qualificationFilter: qualificationFilter,
    orderColumn: orderColumn,
    orderDirection: orderDirection,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
  });

  const activityNpsCount = { count: npsList?.partialCount ?? 0 };
  const activityNpsTotalCount = { count: npsList?.totalCount ?? 0 };

  const [activityNpsList, setActivityNpsList] = useState<IContactNPSInfoActivityList>();

  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });

  const initialStateOrderByList = [
    { id: 'npsDate', isAsc: false },
    { id: 'npsProcess', isAsc: undefined },
  ];
  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  useEffect(() => {
    if (npsList) {
      setActivityNpsList(npsList);
    }
  }, [npsList]);

  const cleanBoxRef = useRef<CleanBoxRef>(null);

  const resetActivityList = () => {
    setSearchValue('');
    resetPagination();
    setQualificationFilter([]);
    setOrderColumn('npsDate');
    setOrderDirection('desc');
    cleanBoxRef?.current?.cleanBox();
    setTableOrderByList(initialStateOrderByList);
    setDateFilter([null, null]);
  };

  useEffect(() => {
    if (!totalNpsContacts && activityNpsCount) {
      setTotalNpsContacts(activityNpsCount?.count);
    }
  }, [activityNpsList, totalNpsContacts, activityNpsCount]);

  useEffect(() => {
    if (activityNpsCount && activityNpsCount.count !== totalNpsContacts) {
      setTotalNpsContacts(activityNpsCount.count);
    }
  }, [activityNpsCount, totalNpsContacts, search]);

  useEffect(() => {
    resetPagination();
  }, [search, qualificationFilter, dateFilter, resetPagination]);

  const changeOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);

  return (
    <>
      <div className="mb-[-29px] py-2">
        {activityNpsTotalCount && activityNpsTotalCount.count > 0 && (
          <Flex alignment="start">
            <HappinessFilters
              cleanBoxRef={cleanBoxRef}
              filterDateSelected={dateFilter}
              filterNpsSelected={qualificationFilter}
              resetSearch={resetFilter}
              setDateFilter={setDateFilter}
              setQualificationFilter={setQualificationFilter}
              onReset={resetActivityList}
              onSearch={setSearchValue}
            />
          </Flex>
        )}
      </div>
      <Flex column>
        {!activityNpsList && (
          <span className="h-full w-full py-10">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {(activityNpsList || isLoadingNps) && npsList?.npsList && (
        <ContactsProfileHappinessTable
          callToAction={[resetActivityList]}
          changeTableOrder={changeOrderBy}
          changeTablePage={changeTablePage}
          gotoPageIndex={gotoPageIndex}
          isLoading={isLoadingNps}
          npsList={activityNpsList ? activityNpsList.npsList : []}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
          totalNpsCount={activityNpsTotalCount.count}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
