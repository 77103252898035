import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table, TextSkeleton } from '@/lib/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { ITableContactProfileItemsProps } from '@/src/ContactsModule/interfaces/Interfaces';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ContactsProfileItemsTable({
  activityList,
  activityTotalCount,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
}: ITableContactProfileItemsProps) {
  const { t } = useTranslation();
  const { dateFormatter } = useDate();

  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_ITEMS_TABLE.date')}</span>
      </Flex>
    ),
    accessor: 'date',
    id: 'date',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">
          <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
        </span>
      </Flex>
    ),
  };

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_ITEMS_TABLE.name')}</span>
      </Flex>
    ),
    accessor: 'item',
    id: 'item',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span
          className="self-start truncate text-[#364365] md:max-w-[500px] 2xl:max-w-[700px]"
          title={value}
        >
          {value}
        </span>
      </Flex>
    ),
  };

  const sourceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_ITEMS_TABLE.source')}</span>
      </Flex>
    ),
    accessor: 'source',
    id: 'source',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [dateColumn, nameColumn, sourceColumn].filter(
    (x) => x
  ) as Column<object>[];

  return (
    <div className="size-full pt-6 text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={activityList}
        emptyScreen={{
          totalCount: activityTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.activity')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityItemsAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityItemsDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyActivityTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
