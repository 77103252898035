import BeePlugin from '@mailupinc/bee-plugin';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, Dropdown, IconSvg, Switch, Text, Tooltip } from '@/lib/v2/components';
import { AirPlaneIcon, EyeIcon, StructureIcon } from '@/lib/v2/icons/outline';
import { StructureIcon as StructureIconSolid } from '@/lib/v2/icons/solid';

import { atomBeefreePristine } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import ModalTemplate from '@/src/modules/CampaignsModule/components/ModalTemplate';
import PreviewLive from '@/src/modules/CampaignsModule/components/PreviewLive';
import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';
import {
  useEmailSave,
  useEmailSaveTemplate,
  useMergeTags,
  usePreview,
  useSendPreview,
  useStructure,
} from '@/src/modules/CampaignsModule/hooks/useEditorActions';
import { useTemplates } from '@/src/modules/CampaignsModule/hooks/useTemplates';

interface IEditorActions {
  beefree: BeePlugin | undefined;
  disabled?: boolean;
  saved?: boolean;
}

const EditorActions = ({ beefree, disabled, saved }: IEditorActions) => {
  const { actionId } = useParams();
  const { action } = useActionData(parseInt(actionId || '0'));
  const { t } = useTranslation();
  const [pristine] = useAtom(atomBeefreePristine);
  const { showSendPreview, handleOpenPreview, goToBack, handleClosePreview, sendTestEmail } =
    useSendPreview();
  const { showMergeTags, toggleMergeTags } = useMergeTags({ beefree });
  const { showPreview, togglePreview } = usePreview({ beefree });
  const { showStructure, toggleStructure } = useStructure({ beefree });
  const { save } = useEmailSave({ beefree });
  const { saveTemplate } = useEmailSaveTemplate({ beefree });
  const { openTemplates } = useTemplates();

  if (showPreview) return null;

  return (
    <div className="z-2 relative flex flex-col bg-white shadow-md">
      <div className="flex items-center justify-between gap-4 p-4">
        <div className="flex w-full max-w-430 items-center gap-4 ">
          <Button standard className="w-7" onClick={() => void goToBack()}>
            <IconSvg fillColor="gray-dark" svgComponent={<ArrowBackIcon />} />
          </Button>

          <Tooltip isClickable noArrow alignment="start" content={action?.name} position="top">
            <div className="self-start text-left !text-14">
              <div className="emblue-subheadings w-full truncate font-medium">{action?.name}</div>
            </div>
          </Tooltip>
          <Text className="flex overflow-hidden truncate  capitalize">{}</Text>
          {pristine && <Text isItalic>/ {t('EDITOR_CONTENT.TOOLBAR.editing')}</Text>}
          {saved && <Text isItalic>/ {t('EDITOR_CONTENT.TOOLBAR.saved')}</Text>}
        </div>

        <div className="flex w-full items-center gap-4">
          <Tooltip content={t('EDITOR_CONTENT.TOOLBAR.showSendPreview')}>
            <Button standard disabled={disabled} onClick={() => void togglePreview()}>
              <IconSvg svgComponent={<EyeIcon />} />
            </Button>
          </Tooltip>

          <Tooltip content={t('EDITOR_CONTENT.TOOLBAR.sendPreview')}>
            <Button standard disabled={disabled} onClick={() => void handleOpenPreview()}>
              <IconSvg fillColor="gray-dark" svgComponent={<AirPlaneIcon />} />
            </Button>
          </Tooltip>

          <Tooltip
            content={
              showStructure
                ? t('EDITOR_CONTENT.TOOLBAR.hideStructure')
                : t('EDITOR_CONTENT.TOOLBAR.showStructure')
            }
          >
            <Button standard disabled={disabled} onClick={() => toggleStructure()}>
              <IconSvg
                fillColor="gray-dark"
                svgComponent={showStructure ? <StructureIconSolid /> : <StructureIcon />}
              />
            </Button>
          </Tooltip>

          <div className="mx-8 flex items-center gap-4" onClick={() => void toggleMergeTags()}>
            <Switch checked={showMergeTags} onChange={() => void 0} />
            <Text variant="text-sm">
              {showMergeTags
                ? t('EDITOR_CONTENT.TOOLBAR.hideCustomFields')
                : t('EDITOR_CONTENT.TOOLBAR.showCustomFields')}
            </Text>
          </div>
        </div>

        <div className="mr-6 flex w-430 items-center justify-end gap-4">
          <div className="w-32">
            <Dropdown
              menuOnTheLeft
              disabled={disabled}
              dropdownItems={[
                {
                  id: 1,
                  label: t('EDITOR_CONTENT.TOOLBAR.openTemplates'),
                  value: 'openTemplates',
                  onClick: () => void openTemplates(action),
                },
                {
                  id: 2,
                  label: t('EDITOR_CONTENT.TOOLBAR.saveTemplate'),
                  value: 'saveAsTemplate',
                  onClick: () => void saveTemplate(),
                },
              ]}
              label={t('EDITOR_CONTENT.TOOLBAR.templates')}
            />
          </div>
          <Button
            fullWidth
            primary
            classNameContainer="w-20"
            disabled={disabled}
            onClick={() => void save()}
          >
            {t('EDITOR_CONTENT.TOOLBAR.save')}
          </Button>
        </div>
      </div>

      <PreviewLive
        open={showSendPreview}
        onClose={handleClosePreview}
        onSave={save}
        onSend={sendTestEmail}
      />
      <ModalTemplate />
    </div>
  );
};

export default EditorActions;
