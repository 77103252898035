import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { ICampaignListPayload } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useMoveActions = () => {
  const { t } = useTranslation();
  const { getListAll, loading: loadingCampaign } = useCampaign();

  useEffect(() => {
    const payload: ICampaignListPayload = { sortField: 'name', sortOrder: 'asc' };
    void getListAll(payload);
  }, [getListAll]);

  const VALIDATION_RULES = useMemo(
    () => ({
      campaigns: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
      },
    }),
    [t]
  );

  return {
    loadingCampaign,
    VALIDATION_RULES,
  };
};
