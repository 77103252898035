import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Flex, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import {
  ActionMasterIcon,
  CalendarIcon,
  CampaignIcon,
  ClickRateIcon,
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailTriggerIcon,
  GroupsIcon,
  OpenRateIcon,
  SmsActionIcon,
  SmsAPIIcon,
  WhatsAppIcon,
} from '@/lib/v2/icons/outline';
import { ListIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { TitleHeadCell } from '@/modules/CampaignsModule/components/TitleHeadCell';
import { DROPDOWN_CHANNELS, WHATSAPP_PREFIX } from '@/modules/CampaignsModule/constants';
import {
  EChannelsID,
  ICampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { DateFormat, formatDate, formattedNumber } from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const ChannelInfoByID = {
  [EChannelsID.MASTER]: {
    icon: <ActionMasterIcon />,
    route: 'master',
  },
  [EChannelsID.EMAIL]: {
    icon: <EmailActionIcon />,
    route: 'email',
  },
  [EChannelsID.EMAIL_TRIGGER]: {
    icon: <EmailTriggerIcon />,
    route: 'email-trigger',
  },
  [EChannelsID.EMAIL_ATTACHMENT]: {
    icon: <EmailAttachmentIcon />,
    route: 'email-attachment',
  },
  [EChannelsID.EMAIL_API]: {
    icon: <EmailAPIIcon />,
    route: 'mail-api',
  },
  [EChannelsID.SMS]: {
    icon: <SmsActionIcon />,
    route: 'sms',
  },
  [EChannelsID.SMS_API]: {
    icon: <SmsAPIIcon />,
    route: 'sms-api',
  },
};

export const useActionsTableColumns = () => {
  const { t, i18n } = useTranslation();
  const outboundWhatsApp = useFeatureFlag('outboundWhatsApp');

  const isWhatsAppAction = useCallback(
    (actionName: string): boolean => {
      return actionName.startsWith(WHATSAPP_PREFIX) && outboundWhatsApp;
    },
    [outboundWhatsApp]
  );

  const getChannelLabel = useCallback(
    (channelId: number, actionName: string): string => {
      if (channelId === EChannelsID.SMS && isWhatsAppAction(actionName)) {
        return t('CAMPAIGN_ACTIONS_MAIN.CHANNEL.whatsapp');
      }
      const labelChannel =
        DROPDOWN_CHANNELS.find((channel) => channel.value === channelId)?.label ?? 'email';
      return t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${labelChannel}`);
    },
    [t, isWhatsAppAction]
  );

  const ICON_BY_ACTION_TYPE: Record<string, JSX.Element> = {
    email: <EmailActionIcon />,
    'email-trigger': <EmailTriggerIcon />,
    'email-attachment': <EmailAttachmentIcon />,
    'mail-api': <EmailAPIIcon />,
    sms: <SmsActionIcon />,
    'sms-api': <SmsAPIIcon />,
    whatsapp: <WhatsAppIcon />,
  };

  /** cspell:disable */
  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell icon={<ListIcon />} text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.name')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'action',
    maxWidth: '340px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const isWhatsApp = isWhatsAppAction(value.name);
      const actionName = isWhatsApp ? value.name.substring(WHATSAPP_PREFIX.length) : value.name;
      const channelRoute = isWhatsApp
        ? 'whatsapp'
        : ChannelInfoByID[value.channel as keyof typeof ChannelInfoByID]?.route || 'email';

      return (
        <div className={`grid grid-cols-campaigns gap-4 `}>
          <div className="shrink-0">
            <IconSvg
              rounded
              bgColor="headingIcon"
              fillColor="primary"
              height="auto"
              svgComponent={ICON_BY_ACTION_TYPE[channelRoute] || ICON_BY_ACTION_TYPE['email']}
              width="40px"
              withPadding="small"
            />
          </div>
          <Tooltip isClickable noArrow alignment="start" content={actionName} position="top">
            <div className="self-start text-left !text-14">
              <div className="emblue-subheadings max-w-[85%] truncate font-medium">
                {actionName}
              </div>
              <div className="emblue-text text-left font-normal">
                {getChannelLabel(value.channel, value.name)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    },
  };

  const campaignsNameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell
          icon={<CampaignIcon />}
          text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.campaign')}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'campaignsName',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {value.campaignName || '-'}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const executionDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell
          icon={<CalendarIcon />}
          text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.execution')}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'exeDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = value.exeDate
        ? formatDate(value.exeDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const audienceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell
          icon={<GroupsIcon />}
          text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.audience')}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'audience',
    width: '100px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.audience), i18n.language as keyof typeof ELanguageMap) ||
              '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const openingRateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell
          icon={<OpenRateIcon />}
          text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.openRate')}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'openingRate',
    width: '100px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {value.openingRate ? `${value.openingRate}%` : '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const clickRateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell
          icon={<ClickRateIcon />}
          text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.clickRate')}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'clickRate',
    width: '100px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {value.clickRate ? `${value.clickRate}%` : '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  /** cspell:enable */

  const defaultActionsColumns: Column<object>[] = [
    nameColumn,
    campaignsNameColumn,
    executionDateColumn,
    audienceColumn,
    openingRateColumn,
    clickRateColumn,
  ];

  const actionsColumns: Column<object>[] = defaultActionsColumns.filter((x) => x);

  return {
    ChannelInfoByID,
    actionsColumns,
  };
};
