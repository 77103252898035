import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { useFiltersCampaign } from '@/modules/CampaignsModule/hooks/useFiltersCampaign';
import { EFilterKeysCampaign } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputSearch = () => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersCampaign();
  const [reset, setReset] = useState(false);
  const filtersCampaign = useAtomValue(atomFiltersCampaign);

  const defaultValue = filtersCampaign?.search ?? '';
  const placeholder = t('CAMPAIGNS_MAIN.FILTERS.searchPlaceholder');
  const id = 'search-campaigns';

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      updateFilter(EFilterKeysCampaign.Search, querySearch);
    },
    [updateFilter]
  );

  const onClearSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
    updateFilter(EFilterKeysCampaign.Search, '');
  }, [updateFilter]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    id,
    defaultValue,
    placeholder,
    reset,
    handleOnClickSearch,
    onClearSearch,
    resetInputSearch,
  };
};
