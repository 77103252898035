import { useCallback } from 'react';

import {
  EChannelsID,
  HTML_ORIGIN_ID_MAP,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

interface IRoutePathActionParams {
  channel: EChannelsID;
  htmlOriginId: number;
  isSplit: boolean;
  originId: number;
  createSimpleEmail: boolean;
}

export const useActionTableUtils = () => {
  const getRoutePathAction = useCallback(
    ({ channel, htmlOriginId, isSplit, originId, createSimpleEmail }: IRoutePathActionParams) => {
      const channelRoutes: Record<number, string> = {
        3: 'email-trigger',
        9: 'email-attachment',
        4: 'mail-api',
        8: 'sms',
        5: 'sms-api',
      };

      if (!createSimpleEmail) return 'email';
      if (
        htmlOriginId === HTML_ORIGIN_ID_MAP.CONTENT_MANAGER_REMARKETING ||
        htmlOriginId === HTML_ORIGIN_ID_MAP.CONTENT_MANAGER_MANUAL_SUPPORT
      )
        return 'email-remarketing';
      if (channel === EChannelsID.EMAIL && originId === 1 && !isSplit) return 'email';
      if (isSplit || originId === 5) return 'email-split';

      return channelRoutes?.[channel] ?? 'email';
    },
    []
  );

  const DD_ACTION_TYPE_MAP: Record<number, string> = {};
  DD_ACTION_TYPE_MAP[EChannelsID.SMS] = 'sms';
  DD_ACTION_TYPE_MAP[EChannelsID.EMAIL] = 'email-simple';
  DD_ACTION_TYPE_MAP[EChannelsID.EMAIL_ATTACHMENT] = 'email-attachment';
  DD_ACTION_TYPE_MAP[EChannelsID.EMAIL_TRIGGER] = 'email-trigger';

  return {
    state: {
      DD_ACTION_TYPE_MAP,
    },
    events: {
      getRoutePathAction,
    },
  };
};
