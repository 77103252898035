import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomBillingInformation,
  atomContractDetail,
} from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';

export const useBilling = () => {
  const [data, , billingInformationIsLoading] = useEmblue(ServiceMethods.getBillingInformation);

  const [billingInformation, setBillingInformation] = useAtom(atomBillingInformation);
  const [contractDetail, setContractDetail] = useAtom(atomContractDetail);

  const contractDetailMemo = useMemo(() => {
    if (billingInformationIsLoading) return;
    return billingInformation?.contractDetails?.find(
      (detail) => detail.contractId === billingInformation.contractId
    );
  }, [
    billingInformation?.contractDetails,
    billingInformation?.contractId,
    billingInformationIsLoading,
  ]);

  useEffect(() => {
    if (contractDetailMemo) {
      setContractDetail(contractDetailMemo);
    }
  }, [contractDetail, contractDetailMemo, setContractDetail]);

  useEffect(() => {
    if (data) {
      setBillingInformation(data);
    }
  }, [data, setBillingInformation]);

  return { billingInformation, billingInformationIsLoading, contractDetail };
};
