export const CompletedIcon = () => {
  return (
    <svg fill="none" height="51" viewBox="0 0 51 51" width="51" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2498 0.000143591C39.1375 0.0621424 50.4213 11.3614 50.3903 25.2027C50.3748 39.0904 38.9825 50.4517 25.1413 50.3897C11.2381 50.3277 -0.107726 38.9199 0.000771738 25.0942C0.10927 11.2064 11.4396 -0.0463555 25.2498 0.000143591Z"
        fill="#18C678"
      />
      <path
        d="M13.377 22.1829C15.6244 24.4303 17.9339 26.7398 20.3053 29.1112C25.8542 23.7948 31.3256 18.5404 36.704 13.3945C37.944 14.526 39.153 15.6265 40.4705 16.8045C33.6816 23.3298 27.0012 29.7312 20.3673 36.1016C16.8334 32.5832 13.346 29.0802 9.90503 25.6393C11.0365 24.5078 12.2145 23.3608 13.377 22.1674V22.1829Z"
        fill="white"
      />
    </svg>
  );
};
