export const SegmentsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7246" id="Group_7246" transform="translate(-733 -461)">
        <rect
          data-name="Rectangle 4100"
          fill="none"
          height="20"
          id="Rectangle_4100"
          transform="translate(733 461)"
          width="20"
        />
        <g id="SEGMENT" transform="translate(732.325 459.011)">
          <g data-name="Group 6756" id="Group_6756" transform="translate(2.52 3.838)">
            <g data-name="Group 6755" id="Group_6755">
              <g
                fill="none"
                id="streak_3492_00000039827060622991078780000001872058829086658985_"
                transform="translate(-10.8 -9.485)"
              >
                <path
                  d="M17.914,18.906V11.6A7.223,7.223,0,0,0,10.8,18.714a7.115,7.115,0,0,0,14.227.192Z"
                  stroke="none"
                />
                <path
                  d="M 17.91355895996094 24.32711791992188 C 20.37438583374023 24.32711791992188 22.47857093811035 22.69707107543945 23.23614692687988 20.40581893920898 L 16.41355895996094 20.40581893920898 L 16.41355895996094 13.31435012817383 C 15.51365756988525 13.57645416259766 14.67749500274658 14.07350254058838 13.97549915313721 14.77549839019775 C 12.89503860473633 15.85595798492432 12.29999923706055 17.2545280456543 12.29999923706055 18.71355819702148 C 12.29999923706055 20.17258834838867 12.89503860473633 21.57115745544434 13.97549915313721 22.65161895751953 C 15.05595874786377 23.73207855224609 16.45452880859375 24.32711791992188 17.91355895996094 24.32711791992188 M 17.91355895996094 25.82711791992188 C 14.06838893890381 25.82711791992188 10.79999923706055 22.5587272644043 10.79999923706055 18.71355819702148 C 10.79999923706055 14.86838817596436 14.06838893890381 11.59999847412109 17.91355895996094 11.59999847412109 L 17.91355895996094 18.90581893920898 L 25.02711868286133 18.90581893920898 C 24.83485984802246 22.75097846984863 21.75872802734375 25.82711791992188 17.91355895996094 25.82711791992188 Z"
                  fill="#004dbc"
                  stroke="none"
                />
              </g>
              <g
                fill="none"
                id="streak_3493_00000165207470076178887490000009076980915610187162_"
                transform="translate(-6.648 -10.5)"
              >
                <path d="M15.3,18.575h8.075A8.124,8.124,0,0,0,15.3,10.5h0v8.075Z" stroke="none" />
                <path
                  d="M 21.69974899291992 17.07484436035156 C 21.42166137695312 15.90391635894775 20.81852340698242 14.82339191436768 19.93499374389648 13.93985366821289 C 19.05145645141602 13.05632400512695 17.9709300994873 12.45318698883057 16.80000305175781 12.17509841918945 L 16.80000305175781 17.07484436035156 L 21.69974899291992 17.07484436035156 M 23.37484359741211 18.57484436035156 L 15.30000305175781 18.57484436035156 L 15.30000305175781 10.50000381469727 C 19.72194290161133 10.50000381469727 23.37484359741211 14.15290451049805 23.37484359741211 18.57484436035156 Z"
                  fill="#004dbc"
                  stroke="none"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
