import { useSearchParamsState } from '@/src/application/hooks/useSearchParamsState';

import { useStateContactsContext } from '@/modules/ContactsModule/contexts/ContactsContext';

export const useFilterState = () => {
  const { searchQuery, tagsFilter, statusFilter, happinessFilter, groupsFilter } =
    useStateContactsContext();

  const [filterSearchApply] = useSearchParamsState('filterSearch', '');
  const [filterStatusApply] = useSearchParamsState('filterStatus', '');
  const [filterTagsApply] = useSearchParamsState('filterTags', '');
  const [filterGroupsApply] = useSearchParamsState('filterGroups', '');
  const [filterHappinessApply] = useSearchParamsState('filterHappiness', '');

  const isSearchQueryApplied =
    searchQuery && searchQuery.length > 0 && filterSearchApply.length > 0;
  const isStatusFilterApplied = statusFilter.length > 0 && filterStatusApply.length > 0;
  const isTagsFilterApplied = tagsFilter.length > 0 && filterTagsApply.length > 0;
  const isGroupsFilterApplied = groupsFilter.length > 0 && filterGroupsApply.length > 0;
  const isHappinessFilterApplied = happinessFilter.length > 0 && filterHappinessApply.length > 0;

  const isFilterApplied =
    isSearchQueryApplied ||
    isStatusFilterApplied ||
    isTagsFilterApplied ||
    isGroupsFilterApplied ||
    isHappinessFilterApplied;

  return { isFilterApplied };
};
