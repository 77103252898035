export const ContactActivityIcon = () => {
  return (
    <svg
      height="15.622"
      viewBox="0 0 15.575 15.622"
      width="15.575"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="activity" transform="translate(0.75 0.75)">
        <path
          d="M5.245,11.961,7.446,9.1l2.511,1.972,2.154-2.78"
          data-name="Path 3361"
          fill="none"
          fillRule="evenodd"
          id="Path_3361"
          stroke="#364365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-1.96 -2.767)"
        />
        <circle
          cx="1.413"
          cy="1.413"
          data-name="Ellipse 1572"
          fill="none"
          id="Ellipse_1572"
          r="1.413"
          stroke="#364365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(11.248)"
        />
        <path
          d="M9.71,1.62H4.366A3.481,3.481,0,0,0,.778,5.4v5.944a3.461,3.461,0,0,0,3.588,3.776h6.327a3.475,3.475,0,0,0,3.588-3.776V6.17"
          data-name="Path 3362"
          fill="none"
          fillRule="evenodd"
          id="Path_3362"
          stroke="#364365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-0.778 -1.001)"
        />
      </g>
    </svg>
  );
};
