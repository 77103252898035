import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TRANSLATE_WHATSAPP_SCHEDULING } from '@/modules/CampaignsModule/constants';

export type SchedulingWhatsAppForm = {
  sendDate: Date | null;
  sendTime: Date | null;
};

const useSchedulingForm = () => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, watch, getValues, resetField, reset } =
    useForm<SchedulingWhatsAppForm>();

  const RULES = {
    sendDate: {
      required: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.RULES.dropdown`),
    },
    sendTime: {
      required: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.RULES.dropdown`),
    },
  };

  return {
    getValues,
    control,
    RULES,
    setValue,
    watch,
    reset,
    handleSubmit,
    resetField,
  };
};

export default useSchedulingForm;
