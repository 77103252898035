import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { ItemsProvider } from '@/src/ContactsModule/contexts/ItemsContext';

const ItemsLayout = () => {
  return (
    <ItemsProvider>
      <Outlet />
    </ItemsProvider>
  );
};

export default memo(ItemsLayout);
