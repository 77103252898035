export const EcommerceIcon = () => {
  return (
    <svg fill="none" height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2857 13.3333H0.714286C0.524845 13.3333 0.343164 13.2455 0.209209 13.0893C0.0752549 12.933 0 12.721 0 12.5V0.833333C0 0.61232 0.0752549 0.400358 0.209209 0.244078C0.343164 0.0877975 0.524845 0 0.714286 0H19.2857C19.4752 0 19.6568 0.0877975 19.7908 0.244078C19.9247 0.400358 20 0.61232 20 0.833333V12.5C20 12.721 19.9247 12.933 19.7908 13.0893C19.6568 13.2455 19.4752 13.3333 19.2857 13.3333ZM1.42857 11.6667H18.5714V1.66667H1.42857V11.6667Z"
        fill="#9C59DF"
      />
      <path
        d="M10.0002 16.6667C9.81073 16.6667 9.62905 16.5789 9.4951 16.4226C9.36114 16.2663 9.28589 16.0543 9.28589 15.8333V13.3333C9.28589 13.1123 9.36114 12.9004 9.4951 12.7441C9.62905 12.5878 9.81073 12.5 10.0002 12.5C10.1896 12.5 10.3713 12.5878 10.5053 12.7441C10.6392 12.9004 10.7145 13.1123 10.7145 13.3333V15.8333C10.7145 16.0543 10.6392 16.2663 10.5053 16.4226C10.3713 16.5789 10.1896 16.6667 10.0002 16.6667Z"
        fill="#9C59DF"
      />
      <path
        d="M12.1427 16.6667H7.857C7.66756 16.6667 7.48588 16.5789 7.35193 16.4226C7.21797 16.2663 7.14272 16.0543 7.14272 15.8333C7.14272 15.6123 7.21797 15.4004 7.35193 15.2441C7.48588 15.0878 7.66756 15 7.857 15H12.1427C12.3322 15 12.5138 15.0878 12.6478 15.2441C12.7817 15.4004 12.857 15.6123 12.857 15.8333C12.857 16.0543 12.7817 16.2663 12.6478 16.4226C12.5138 16.5789 12.3322 16.6667 12.1427 16.6667ZM12.1427 8.33333H7.857C7.71415 8.3334 7.57457 8.28348 7.45628 8.19004C7.33799 8.09661 7.24643 7.96393 7.19343 7.80917L5.94486 4.16667H4.28557C4.09613 4.16667 3.91445 4.07887 3.7805 3.92259C3.64654 3.76631 3.57129 3.55435 3.57129 3.33333C3.57129 3.11232 3.64654 2.90036 3.7805 2.74408C3.91445 2.5878 4.09613 2.5 4.28557 2.5H6.42843C6.57128 2.49994 6.71087 2.54985 6.82916 2.64329C6.94745 2.73673 7.03901 2.8694 7.092 3.02417L7.19772 3.33333H12.857C12.9655 3.33337 13.0726 3.36224 13.1701 3.41776C13.2676 3.47327 13.353 3.55397 13.4197 3.65373C13.4865 3.75349 13.533 3.86969 13.5555 3.99351C13.578 4.11733 13.5761 4.24551 13.5499 4.36833L12.8356 7.70167C12.797 7.88203 12.7079 8.04217 12.5823 8.15663C12.4568 8.27108 12.302 8.33328 12.1427 8.33333ZM8.34057 6.66667H11.5849L11.942 5H7.76915L8.34057 6.66667Z"
        fill="#9C59DF"
      />
      <path
        d="M8.21425 10.8333C8.80598 10.8333 9.28568 10.2736 9.28568 9.58325C9.28568 8.8929 8.80598 8.33325 8.21425 8.33325C7.62252 8.33325 7.14282 8.8929 7.14282 9.58325C7.14282 10.2736 7.62252 10.8333 8.21425 10.8333Z"
        fill="#9C59DF"
      />
      <path
        d="M11.7855 10.8333C12.3773 10.8333 12.857 10.2736 12.857 9.58325C12.857 8.8929 12.3773 8.33325 11.7855 8.33325C11.1938 8.33325 10.7141 8.8929 10.7141 9.58325C10.7141 10.2736 11.1938 10.8333 11.7855 10.8333Z"
        fill="#9C59DF"
      />
    </svg>
  );
};
