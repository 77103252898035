import { timeout } from '@/src/compat/util';

import { mockGetAccounts, mockGetContract, mockGetPlans } from './BackOfficeAccountsService.mocks';
import { IBackOfficeAccountsService } from './IBackOfficeAccountsService';

import { IContractsBillingsPayloadForCSVDownload } from '@/modules/BackofficeModule/interfaces/contracts';
import { IBeefreeSaveResponse } from '@/modules/CampaignsModule/interfaces/Beefree';
import {
  ChargeSurplusResponse,
  IBackOfficeContract,
  IExecutive,
  IPlan,
  SMSPrices,
  Surplus,
  SurplusResult,
} from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';
import { DataResponse } from '@/modules/ContactsModule/types/Segments';

export class StubBackOfficeAccountsService implements IBackOfficeAccountsService {
  updatePriceSms(countryCode: string, price: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  chargeSurplusBulk(): Promise<SurplusResult[] | null> {
    throw new Error('Method not implemented.');
  }
  getSurplusListBulk(): Promise<Surplus[] | null> {
    throw new Error('Method not implemented.');
  }
  static create(): IBackOfficeAccountsService {
    return new StubBackOfficeAccountsService();
  }

  /**
   * @desc Get all accounts of emblue
   */
  async getAccounts(): Promise<IBeefreeSaveResponse | null> {
    try {
      await timeout(2000);
      return mockGetAccounts;
    } catch (error) {
      return null;
    }
  }

  /**
   * @desc Get all accounts of emblue
   */
  async getContract(id: number): Promise<IBackOfficeContract | null> {
    try {
      await timeout(2000);
      return mockGetContract;
    } catch (error) {
      return null;
    }
  }

  /**
   * @desc Get all accounts of emblue
   */
  async getPlans(): Promise<IPlan[]> {
    try {
      await timeout(2000);
      return mockGetPlans;
    } catch (error) {
      return [];
    }
  }

  /**
   * @desc Get all executives
   */
  async getExecutives(): Promise<IExecutive[] | null> {
    try {
      await timeout(2000);
      return [];
    } catch (error) {
      return [];
    }
  }

  /**
   * @desc Change status
   */
  async changeStatus(contractId: number, statusId: number): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @desc Create a contract
   */
  async createContract(payload: Omit<IBackOfficeContract, 'id'>): Promise<DataResponse<string>> {
    try {
      await timeout(2000);
      return { success: true, data: 'Contract created' };
    } catch (error) {
      return { success: false, data: 'Contract not created' };
    }
  }
  /**
   * @desc Update a contract
   */
  async updateContract(payload: IBackOfficeContract): Promise<DataResponse<string>> {
    try {
      await timeout(2000);
      return { success: true, data: 'Contract updated' };
    } catch (error) {
      return { success: false, data: 'Contract not updated' };
    }
  }

  async downloadContractsBillingsCSV(
    payload: IContractsBillingsPayloadForCSVDownload
  ): Promise<void> {
    try {
      await timeout(2000);
    } catch (error) {
      return;
    }
  }

  /**
   * @desc Get all sms prices by country
   */
  async getSmsPrices(): Promise<SMSPrices | null> {
    try {
      await timeout(2000);
      return [];
    } catch (error) {
      return null;
    }
  }

  async getSurplus(id: number, monthsAgo: number): Promise<Surplus | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      return null;
    }
  }
  async chargeSurplus(id: number, monthsAgo: number): Promise<ChargeSurplusResponse> {
    try {
      await timeout(2000);
      return {} as ChargeSurplusResponse;
    } catch (error) {
      return {} as ChargeSurplusResponse;
    }
  }
}
