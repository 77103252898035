/* eslint-disable pii/no-email */
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { memo, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Input, Text } from '@/lib/v2/components';

import HeaderLogin from '@/src/modules/AuthModule/components/HeaderLogin';
import { useLogin } from '@/src/modules/AuthModule/hooks/useLogin';

interface FormRecover {
  email: string;
}

const RecoverPassword = () => {
  const { t } = useTranslation();

  const RECOVER_RULES = useMemo(
    () => ({
      email: {
        required: t('AUTH.LOGIN.emailRequired'),
        pattern: {
          value: /[\w.%+-]+@[a-z\d.-]+\.[a-z]{2,4}$/i,
          message: 'Invalid email',
        },
      },
    }),
    [t]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRecover>();

  const navigate = useNavigate();

  const { recoverPassword, error, isLoading } = useLogin();

  const handleBackLogin = () => {
    navigate(-1);
  };

  const onSubmitRecoverPassword: SubmitHandler<FormRecover> = useCallback(
    (data) => {
      const { email } = data;
      void recoverPassword(email);
    },
    [recoverPassword]
  );

  /** cspell:disable */
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 text-center">
      <HeaderLogin
        className="mb-40"
        subTitle={t('AUTH.RECOVER_PASSWORD.title')}
        title={t('AUTH.RECOVER_PASSWORD.subTitle')}
      />
      <form onSubmit={handleSubmit(onSubmitRecoverPassword)}>
        <div className="flex w-full flex-col items-center gap-4 sm:w-[300px]">
          <Input
            {...register('email', RECOVER_RULES.email)}
            error={errors?.email && true}
            id="email-input"
            label={t('AUTH.LOGIN.emailLabel')}
            message={errors?.email?.message}
            placeHolder={t('AUTH.LOGIN.emailPlaceholder')}
          />
          <Button fullWidth id="recover-password-button" isLoading={isLoading} type="submit">
            {t('AUTH.RECOVER_PASSWORD.sendLink')}
          </Button>
          {error && <p className={'message-error !text-center'}>{error}</p>}
        </div>
      </form>
      <div className="mt-3 flex flex-row items-center gap-1">
        <Text
          link
          className="flex items-center gap-2"
          color="primary"
          variant="text"
          onClick={handleBackLogin}
        >
          <ArrowLeftIcon className="h-4 w-4" />
          {t('AUTH.RECOVER_PASSWORD.backLogin')}
        </Text>
      </div>
    </div>
  );
};

export default memo(RecoverPassword);
