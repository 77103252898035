export const FlowsIcon = () => {
  return (
    <svg
      className="h-5 w-5"
      height="20.104"
      viewBox="0 0 19.834 20.104"
      width="19.834"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.388,6.886h4.243a.6.6,0,0,0,.593-.593v-4.7A.6.6,0,0,0,20.631,1H16.388a.6.6,0,0,0-.593.593V3.471H13.761A2.05,2.05,0,0,0,11.712,5.52V8.483H10.291a.6.6,0,0,0-.593.593v1.473H6.738V8.712a.6.6,0,0,0-.593-.593H1.983a.6.6,0,0,0-.593.593v4.613a.6.6,0,0,0,.593.593H6.146a.6.6,0,0,0,.593-.593V11.489H9.7v1.473a.6.6,0,0,0,.593.593h1.421v3.029a2.05,2.05,0,0,0,2.049,2.049H15.8v1.879a.6.6,0,0,0,.593.593h4.243a.6.6,0,0,0,.593-.593v-4.7a.6.6,0,0,0-.593-.593H16.388a.6.6,0,0,0-.593.593V17.69H13.761a1.11,1.11,0,0,1-1.109-1.109V13.551h1.133a.6.6,0,0,0,.593-.593V9.076a.6.6,0,0,0-.593-.593H12.652V5.52a1.11,1.11,0,0,1,1.109-1.109H15.8V6.289A.593.593,0,0,0,16.388,6.886Z"
        fill="#004dbc"
        id="Flow"
        transform="translate(-1.39 -1)"
      />
    </svg>
  );
};
