export const ABTestIcon = () => {
  return (
    <svg
      height="21.669"
      viewBox="0 0 21.502 21.669"
      width="21.502"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5,17.669v-7.5a3.334,3.334,0,0,1,6.667,0v7.5M1.5,13.5H8.167M19,11.835H14.835m4.167,0a2.5,2.5,0,0,0,0-5H14.835v5m4.167,0a2.5,2.5,0,0,1,0,5H14.835v-5M11.5,1V22.669"
        fill="none"
        stroke="#004dbc"
        strokeWidth="1.5"
        transform="translate(-0.75 -1)"
      />
    </svg>
  );
};
