import { PlusIcon } from '@heroicons/react/solid';
import { ChangeEvent, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, IconSvg, If, Input, Text } from '@/lib/v2/components';
import { DeleteIcon } from '@/lib/v2/icons/outline';

import { useFormCreateEvent } from '@/modules/AutomationModule/hooks/useFormCreateEvent';
interface FormCreateEventProps {
  isEditView: boolean;
}

const FormCreateEvent = ({ isEditView }: FormCreateEventProps) => {
  const { t } = useTranslation();

  const {
    control,
    rules,
    stateForm,
    handleResetModelData,
    attributeFields,
    eventItemsFields,
    appendAttribute,
    appendEventItems,
    handleRemoveAttribute,
    handleRemoveEventItems,
    handleOnChangeInputReplaceSpace,
    handleSetValuesFormToAtom,
    isIntegrationEvent,
  } = useFormCreateEvent({ isEditView });

  const disabledInputs = isEditView && isIntegrationEvent;

  return (
    <div className="flex w-full flex-col py-14 sm:w-3/5 sm:pr-4 md:pr-8 lg:pr-14">
      <div className="flex flex-col gap-11 sm:flex-row">
        <Input
          isRequired
          control={control}
          disabled={disabledInputs}
          id="name"
          label={t('FormCreateEvent.inputName')}
          name="name"
          placeHolder={t('FormCreateEvent.inputNamePlaceholder')}
          rules={rules.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => (
            handleOnChangeInputReplaceSpace(e), handleSetValuesFormToAtom()
          )}
        />
        <Input
          isRequired
          control={control}
          disabled={disabledInputs}
          id="description"
          label={t('FormCreateEvent.inputDescription')}
          name="description"
          placeHolder={t('FormCreateEvent.inputDescriptionPlaceholder')}
          rules={rules.description}
          onChange={handleSetValuesFormToAtom}
        />
      </div>
      <div className="mt-14">
        <Checkbox
          checked={stateForm.modelData}
          control={control}
          disabled={disabledInputs}
          id="modelData"
          label={
            <Text fontWeight="medium" variant="text">
              {t('FormCreateEvent.checkboxLabel')}
            </Text>
          }
          name="modelData"
          onChange={() => (handleResetModelData(), handleSetValuesFormToAtom())}
        />
      </div>
      <If condition={!stateForm.modelData}>
        <Text className="mt-4" variant="text">
          <Trans
            components={{
              text: (
                <Text
                  link
                  href="https://help.embluemail.com/developers/automation-introduccion-y-configuracion-de-eventos/#%C2%BFQue_son_los_Eventos"
                  target="_blank"
                  variant="text"
                />
              ),
            }}
            i18nKey={'FormCreateEvent.moreInformation'}
          />
        </Text>
      </If>
      <If condition={stateForm.modelData}>
        <div className="mt-10 flex w-full gap-11">
          <div className="flex w-full flex-col sm:w-1/2">
            <Text fontWeight="medium" variant="text">
              {t('FormCreateEvent.attributes.title')}
            </Text>
            <Text variant="text">{t('FormCreateEvent.attributes.description')}</Text>
          </div>
          <div className="flex w-full flex-col sm:w-1/2">
            <Text fontWeight="medium" variant="text">
              {t('FormCreateEvent.items.title')}
            </Text>
            <Text variant="text">{t('FormCreateEvent.items.description')}</Text>
          </div>
        </div>
        <div className="mt-3 flex w-full gap-11">
          <div className="flex w-full flex-col gap-2 sm:w-1/2">
            {attributeFields.map((attribute, index) => {
              const name = `attributes.${index}.value`;

              return (
                <div key={attribute.id} className="flex gap-2">
                  <Input
                    control={control}
                    disabled={disabledInputs}
                    id={name}
                    name={name}
                    placeHolder={t('FormCreateEvent.addAttributePlaceholder')}
                    rules={rules.attributes}
                    onChange={handleSetValuesFormToAtom}
                  />
                  <If condition={!disabledInputs}>
                    <div className="flex h-[46px] items-center">
                      <IconSvg
                        fillColor="white"
                        id="deleteAttribute"
                        strokeColor="destructive"
                        svgComponent={<DeleteIcon />}
                        onClick={() => void handleRemoveAttribute(index)}
                      />
                    </div>
                  </If>
                </div>
              );
            })}
            <Text
              {...(!disabledInputs && { link: true })}
              as={'span'}
              className="flex items-center gap-1"
              color={disabledInputs ? 'gray' : 'primary'}
              id="addAttribute"
              variant="text"
              {...(!disabledInputs && { onClick: () => appendAttribute({ value: '' }) })}
            >
              <PlusIcon className="-mt-0.5 size-4" /> {t('FormCreateEvent.add')}
            </Text>
          </div>
          <div className="flex w-full flex-col gap-2 sm:w-1/2">
            {eventItemsFields.map((eventItem, index) => {
              const name = `eventItems.${index}.value`;

              return (
                <div key={eventItem.id} className="flex gap-2">
                  <Input
                    control={control}
                    disabled={disabledInputs}
                    id={name}
                    name={name}
                    placeHolder={t('FormCreateEvent.addItemPlaceholder')}
                    rules={rules.eventItems}
                    onChange={handleSetValuesFormToAtom}
                  />
                  <If condition={!disabledInputs}>
                    <div className="flex h-[46px] items-center">
                      <IconSvg
                        fillColor="white"
                        id="deleteEventItem"
                        strokeColor="destructive"
                        svgComponent={<DeleteIcon />}
                        onClick={() => void handleRemoveEventItems(index)}
                      />
                    </div>
                  </If>
                </div>
              );
            })}
            <Text
              {...(!disabledInputs && { link: true })}
              as={'span'}
              className="flex items-center gap-1"
              color={disabledInputs ? 'gray' : 'primary'}
              id="addEventItem"
              variant="text"
              {...(!disabledInputs && { onClick: () => appendEventItems({ value: '' }) })}
            >
              <PlusIcon className="-mt-0.5 size-4" /> {t('FormCreateEvent.add')}
            </Text>
          </div>
        </div>
      </If>
    </div>
  );
};

export default memo(FormCreateEvent);
