import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyActivityIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { GroupIcon } from '@/src/ContactsModule/Icons/GroupIcon';
import { ITableContactProfileGroupsProps } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export function ContactsProfileGroupsTable({
  activityList,
  activityTotalCount,
  changeTablePage,
  changeTableOrder,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
}: ITableContactProfileGroupsProps) {
  const { t } = useTranslation();

  const [tableOrderByList, setTableOrderByList] = useState<
    Array<{ id: string; isAsc: boolean | undefined }>
  >([
    {
      id: 'Name',
      isAsc: true,
    },
    {
      id: 'Contacts',
      isAsc: undefined,
    },
  ]);

  const typeColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_GROUPS_TABLE.type')}</span>
      </Flex>
    ),
    accessor: 'type',
    id: 'type',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <GroupIcon tableSize />
        <span className="self-start text-[#364365]">
          {value.toLowerCase() === 'group' ? t('PROFILE_GROUPS_TABLE.group') : 'Group'}
        </span>
      </Flex>
    ),
  };

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_GROUPS_TABLE.name')}</span>
        <SortTableArrow
          elementName={'Name'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'name',
    id: 'name',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('PROFILE_GROUPS_TABLE.contacts')}</span>
        <SortTableArrow
          elementName={'Contacts'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'contacts',
    id: 'contacts',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-[#364365]">{quantifier(value)}</span>
      </Flex>
    ),
  };

  const activityTableColumns: Column<object>[] = [typeColumn, nameColumn, contactColumn].filter(
    (x) => x
  ) as Column<object>[];

  return (
    <div className="size-full pt-6 text-base text-[#364365] 2xl:text-14">
      <Table
        // eslint-disable-next-line react/no-children-prop
        children={<></>}
        isPaginateOnOverflow
        classNamePagination="pr-4"
        columns={activityTableColumns}
        data={activityList}
        emptyScreen={{
          totalCount: activityTotalCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.activity')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityGroupsAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityGroupsDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyActivityTitle')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withCheckbox={false}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
