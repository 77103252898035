import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PieChartCard, PieSemiChartCard } from '@/lib/v2/organisms/Charts/';
import { IPieChart, IPieChartLabel } from '@/lib/v2/organisms/Charts/types';

import {
  NPS_FROM_VALUE,
  NPS_TO_VALUE,
} from '@/src/modules/ContactsModule/screens/HappinessMain/constants';
import { NpsStats } from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { customRounding } from '@/src/utils/CustomRounding';

interface HappinessChartsProps {
  npsStats: NpsStats;
  npsGeneralValue: number;
}

export const HappinessCharts: FC<HappinessChartsProps> = ({ npsStats, npsGeneralValue }) => {
  const { t } = useTranslation();
  const chartDataNPS: IPieChart[] = [
    {
      id: 'nps',
      name: 'NPS',
      value: customRounding(npsGeneralValue),
      color: '#004DBC',
      textColor: 'text-emblue-primary',
    },
  ];
  const chartDataPPD: IPieChart[] = Object.keys(npsStats)
    .filter((element) => element !== 'totalResponses')
    .map((element) => {
      if (element === 'promoters') {
        return {
          id: 'promoters',
          name: t('HAPPINESS_FILTER.promoters'),
          value: customRounding(npsStats.promoters),
          color: '#18C678',
          textColor: 'text-emblue-success',
        };
      }
      if (element === 'detractors') {
        return {
          id: 'detractors',
          name: t('HAPPINESS_FILTER.detractors'),
          value: customRounding(npsStats.detractors),
          color: '#FC644D',
          textColor: 'text-emblue-detractor',
        };
      }
      return {
        id: 'passives',
        name: t('HAPPINESS_FILTER.passives'),
        value: customRounding(npsStats.passives),
        color: '#FFB114',
        textColor: 'text-emblue-passive',
      };
    });

  const chartLabelPPD: IPieChartLabel[] = [
    {
      id: 'responses',
      dx: 0,
      dy: -15,
      fill: '#002646',
      fontSize: 14,
      fontWeight: '400',
      position: 'center',
      value: t('NPS_CHARTS.responses'),
    },
    {
      id: 'totalResponses',
      dx: 0,
      dy: 15,
      fill: '#002646',
      fontSize: 20,
      fontWeight: '600',
      position: 'center',
      value: npsStats?.totalResponses,
    },
  ];

  const chartLabelNPS: IPieChartLabel[] = [
    {
      id: 'NPS',
      dx: 0,
      dy: -20,
      fill: '#004DBC',
      fontSize: 36,
      fontWeight: '600',
      position: 'center',
      value: customRounding(npsGeneralValue),
    },
    {
      id: 'NPS general',
      dx: 0,
      dy: 30,
      fill: '#002646',
      fontSize: 20,
      fontWeight: '500',
      position: 'center',
      value: t('NPS_CHARTS.generalNPS'),
    },
    {
      id: 'positive',
      dx: 116,
      dy: -8,
      fill: '#002646',
      fontSize: 16,
      fontWeight: '500',
      position: 'center',
      value: NPS_TO_VALUE,
    },
    {
      id: 'negative',
      dx: -116,
      dy: -8,
      fill: '#002646',
      fontSize: 16,
      fontWeight: '500',
      position: 'center',
      value: NPS_FROM_VALUE,
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-4">
      <PieChartCard chartData={chartDataPPD} label={chartLabelPPD} />
      <PieSemiChartCard chartData={chartDataNPS} label={chartLabelNPS} />
    </div>
  );
};
