export const RocketBlockModalIcon = () => {
  return (
    <svg className="h-full w-full" viewBox="0 0 275.103 195.876" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient"
          x1="-0.531"
          x2="-0.528"
          y1="-2.62"
          y2="-2.62"
        >
          <stop offset="0" stopColor="#cce6ff" />
          <stop offset="0.47" stopColor="#9cd1ff" />
          <stop offset="1" stopColor="#004dbc" />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient-2"
          x1="-0.541"
          x2="-0.538"
          y1="-2.62"
          y2="-2.62"
        >
          <stop offset="0" stopColor="#cce6ff" stopOpacity="0.62" />
          <stop offset="0.18" stopColor="#e2f1ff" stopOpacity="0.62" />
          <stop offset="0.39" stopColor="#f6fbff" stopOpacity="0.62" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.62" />
        </linearGradient>
        <clipPath id="clip-path">
          <ellipse
            cx="0.925"
            cy="0.918"
            data-name="Ellipse 3"
            id="Ellipse_3"
            rx="0.925"
            ry="0.918"
          />
        </clipPath>
        <radialGradient
          cx="0.749"
          cy="3.39"
          gradientUnits="objectBoundingBox"
          id="radial-gradient"
          r="2.578"
        >
          <stop offset="0" stopColor="#2884fb" stopOpacity="0.639" />
          <stop offset="0.52" stopColor="#4f9afb" stopOpacity="0.49" />
          <stop offset="1" stopColor="#d0e4fc" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-0.531"
          x2="-0.528"
          xlinkHref="#linear-gradient"
          y1="-1.616"
          y2="-1.616"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-0.541"
          x2="-0.538"
          xlinkHref="#linear-gradient-2"
          y1="-1.616"
          y2="-1.616"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="-0.531"
          x2="-0.528"
          xlinkHref="#linear-gradient"
          y1="-0.623"
          y2="-0.623"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="-0.541"
          x2="-0.538"
          xlinkHref="#linear-gradient-2"
          y1="-0.623"
          y2="-0.623"
        />
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient-7"
          x1="-5.032"
          x2="-5.018"
          y1="-1.161"
          y2="-1.161"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient-8"
          x1="-4.27"
          x2="-4.259"
          y1="-0.347"
          y2="-0.347"
        >
          <stop offset="0" stopColor="#008e00" />
          <stop offset="1" stopColor="#008e00" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-9"
          x1="-7.749"
          x2="-7.726"
          xlinkHref="#linear-gradient-7"
          y1="-2.806"
          y2="-2.806"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="-4.192"
          x2="-4.181"
          xlinkHref="#linear-gradient-7"
          y1="-0.272"
          y2="-0.272"
        />
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient-11"
          x1="-14.13"
          x2="-14.095"
          y1="-3.008"
          y2="-3.008"
        >
          <stop offset="0" stopColor="#008c00" stopOpacity="0.329" />
          <stop offset="1" stopColor="#008c00" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="server-resource-scaling" transform="translate(-0.587 -0.655)">
        <rect
          data-name="Rectangle 3587"
          fill="none"
          height="195.876"
          id="Rectangle_3587"
          transform="translate(0.587 0.655)"
          width="273.565"
        />
        <path
          d="M279.668,237.96a4.557,4.557,0,0,0-4.555-4.554H145.384a4.557,4.557,0,0,0-4.555,4.554v37.56a4.557,4.557,0,0,0,4.555,4.554h129.73a4.557,4.557,0,0,0,4.555-4.554V237.96Z"
          data-name="Path 3285"
          fill="url(#linear-gradient)"
          fillRule="evenodd"
          id="Path_3285"
          transform="translate(-66.768 -110.811)"
        />
        <path
          d="M272.527,280.074v-38.4h-126.8a3.189,3.189,0,0,1-3.188-3.188v-1.893a3.189,3.189,0,0,1,3.188-3.188h130.2a4.558,4.558,0,0,1,4.518,3.975l.007.057.006.058.005.058,0,.058,0,.058,0,.058,0,.058,0,.059V275.52a4.557,4.557,0,0,1-4.555,4.554h-3.4Z"
          data-name="Path 3286"
          fill="url(#linear-gradient-2)"
          fillRule="evenodd"
          id="Path_3286"
          transform="translate(-67.583 -110.811)"
        />
        <circle
          cx="3.843"
          cy="3.843"
          data-name="Ellipse 2"
          fill="#53ba2f"
          id="Ellipse_2"
          r="3.843"
          transform="translate(88.236 143.858)"
        />
        <path
          d="M289.63,278.069a1.823,1.823,0,0,0-1.822-1.822H205.786a1.822,1.822,0,0,0-1.822,1.822v1.082a1.822,1.822,0,0,0,1.822,1.822h82.022a1.823,1.823,0,0,0,1.822-1.822Z"
          data-name="Path 3287"
          fill="#fff"
          fillRule="evenodd"
          id="Path_3287"
          transform="translate(-96.827 -131.208)"
        />
        <path
          d="M145.384,233.406H149.1v38.983H275.882a3.19,3.19,0,0,1,3.189,3.188v1.907c0,.107-.006.214-.016.319a4.553,4.553,0,0,1-3.941,2.272H145.384a4.557,4.557,0,0,1-4.555-4.554V237.96a4.556,4.556,0,0,1,4.555-4.554Z"
          data-name="Path 3288"
          fill="url(#radial-gradient)"
          fillRule="evenodd"
          id="Path_3288"
          transform="translate(-66.768 -110.811)"
        />
        <path
          d="M279.668,148.518a4.557,4.557,0,0,0-4.555-4.554H145.384a4.557,4.557,0,0,0-4.555,4.554v37.56a4.557,4.557,0,0,0,4.555,4.554h129.73a4.557,4.557,0,0,0,4.555-4.554Z"
          data-name="Path 3289"
          fill="url(#linear-gradient-3)"
          fillRule="evenodd"
          id="Path_3289"
          transform="translate(-66.768 -68.229)"
        />
        <path
          d="M272.527,190.633v-38.4h-126.8a3.189,3.189,0,0,1-3.188-3.188v-1.893a3.189,3.189,0,0,1,3.188-3.188h130.2a4.558,4.558,0,0,1,4.518,3.974l.007.057.006.058.005.057,0,.058,0,.058,0,.058,0,.058,0,.059v37.677a4.557,4.557,0,0,1-4.555,4.554h-3.4Z"
          data-name="Path 3290"
          fill="url(#linear-gradient-4)"
          fillRule="evenodd"
          id="Path_3290"
          transform="translate(-67.583 -68.229)"
        />
        <circle
          cx="3.843"
          cy="3.843"
          data-name="Ellipse 4"
          fill="#53ba2f"
          id="Ellipse_4"
          r="3.843"
          transform="translate(88.236 96.999)"
        />
        <path
          d="M289.63,188.627a1.823,1.823,0,0,0-1.822-1.822H205.786a1.823,1.823,0,0,0-1.822,1.822v1.082a1.823,1.823,0,0,0,1.822,1.822h82.022a1.823,1.823,0,0,0,1.822-1.822Z"
          data-name="Path 3291"
          fill="#fff"
          fillRule="evenodd"
          id="Path_3291"
          transform="translate(-96.827 -88.625)"
        />
        <path
          d="M145.384,143.964H149.1v38.983H275.882a3.19,3.19,0,0,1,3.189,3.188v1.908c0,.107-.006.214-.016.319a4.553,4.553,0,0,1-3.941,2.272H145.384a4.557,4.557,0,0,1-4.555-4.554v-37.56a4.556,4.556,0,0,1,4.555-4.554Z"
          data-name="Path 3292"
          fill="url(#radial-gradient)"
          fillRule="evenodd"
          id="Path_3292"
          transform="translate(-66.768 -68.229)"
        />
        <path
          d="M279.668,60.035a4.557,4.557,0,0,0-4.555-4.554H145.384a4.557,4.557,0,0,0-4.555,4.554V97.6a4.557,4.557,0,0,0,4.555,4.554h129.73a4.557,4.557,0,0,0,4.555-4.554V60.035Z"
          data-name="Path 3293"
          fill="url(#linear-gradient-5)"
          fillRule="evenodd"
          id="Path_3293"
          transform="translate(-66.768 -26.102)"
        />
        <path
          d="M272.527,102.15V63.75h-126.8a3.19,3.19,0,0,1-3.188-3.188V58.669a3.189,3.189,0,0,1,3.188-3.188h130.2a4.558,4.558,0,0,1,4.518,3.975l.007.057.006.057.005.058,0,.058,0,.058,0,.058,0,.058,0,.059V97.6a4.557,4.557,0,0,1-4.555,4.554Z"
          data-name="Path 3294"
          fill="url(#linear-gradient-6)"
          fillRule="evenodd"
          id="Path_3294"
          transform="translate(-67.583 -26.102)"
        />
        <circle
          cx="3.843"
          cy="3.843"
          data-name="Ellipse 6"
          fill="#53ba2f"
          id="Ellipse_6"
          r="3.843"
          transform="translate(88.236 50.642)"
        />
        <path
          d="M289.63,100.144a1.823,1.823,0,0,0-1.822-1.822H205.786a1.823,1.823,0,0,0-1.822,1.822v1.081a1.822,1.822,0,0,0,1.822,1.822h82.022a1.823,1.823,0,0,0,1.822-1.822Z"
          data-name="Path 3295"
          fill="#fff"
          fillRule="evenodd"
          id="Path_3295"
          transform="translate(-96.827 -46.499)"
        />
        <path
          d="M145.384,55.481H149.1V94.464H275.882a3.19,3.19,0,0,1,3.189,3.188v1.907c0,.107-.006.214-.016.319a4.554,4.554,0,0,1-3.941,2.271H145.384a4.557,4.557,0,0,1-4.555-4.554V60.035a4.557,4.557,0,0,1,4.555-4.554Z"
          data-name="Path 3296"
          fill="url(#radial-gradient)"
          fillRule="evenodd"
          id="Path_3296"
          transform="translate(-66.768 -26.102)"
        />
        <g data-name="Group 3952" id="Group_3952" transform="translate(7.427 65.254)">
          <path
            d="M117.73,259.511a.671.671,0,0,1-.4.233.431.431,0,0,1-.254-.037.365.365,0,0,1-.111-.085l-11.578-12.975a.44.44,0,0,1-.054-.455.747.747,0,0,1,.378-.4l26.048-12.06-29.053,9.348a.421.421,0,0,1-.458-.092l-9.271-10.728a.4.4,0,0,1,.026-.5.734.734,0,0,1,.53-.311l50.022-2.952a.382.382,0,0,1,.383.224.435.435,0,0,1-.081.458l-26.128,30.33ZM104.054,249.1l3.113,3.561a.459.459,0,0,1,.07.441.773.773,0,0,1-.327.426l-9.638,6.11a.544.544,0,0,1-.529.067.354.354,0,0,1-.1-.081.567.567,0,0,1,.041-.674l6.528-9.645a.758.758,0,0,1,.433-.312.4.4,0,0,1,.413.106Z"
            data-name="Path 3297"
            fill="#004dbc"
            fillRule="evenodd"
            id="Path_3297"
            transform="translate(-51.38 -173.728)"
          />
          <path
            d="M138.226,216.64c7.518.47,7.382,7.721,4.743,10.9L116.76,257.965c-1.953,2.175-2.962,2.211-4.663,2.332a6.692,6.692,0,0,1-4.971-1.745,5.079,5.079,0,0,1-.393-.4l-3.636-4.075-8.264,5.237a6.651,6.651,0,0,1-8.965-9.457l5.866-8.665-9.148-10.586a10.044,10.044,0,0,1-1.389-2.848,7.031,7.031,0,0,1,6.1-8.142c.06-.006.119-.009.179-.014l50.1-2.957a5.119,5.119,0,0,1,.646-.006Zm-.289,6.209c-16.69.907-33.392,1.659-50.057,2.955-.392.044-.763.5-.547.793q4.5,5.5,9.29,10.751a.441.441,0,0,0,.448.082l29.053-9.348c-8.694,4.025-17.543,7.729-26.08,12.076a.783.783,0,0,0-.184.137.543.543,0,0,0-.117.693q5.677,6.607,11.6,13a.521.521,0,0,0,.731-.1c8.916-9.957,17.972-19.823,26.17-30.379A.406.406,0,0,0,137.936,222.849ZM98.112,243.325a.725.725,0,0,0-.53.313c-2.221,3.2-4.418,6.422-6.553,9.682-.237.387.188.912.59.677,3.25-2.012,6.524-3.99,9.684-6.14a.849.849,0,0,0,.13-.115.589.589,0,0,0,.115-.713c-.98-1.258-2.05-2.446-3.148-3.6a.392.392,0,0,0-.289-.1Z"
            data-name="Path 3298"
            fill="#fff"
            id="Path_3298"
            transform="translate(-45.744 -168.079)"
          />
          <path
            d="M252.428,123.957c0-.061-6.216,42.375-48.734,68.845C101.4,256.485,41.946,246.857,28.577,225.8c2.8,9.369,14.9,27.213,65.491,21.395,45.735-5.26,103.766-37.223,131.335-64.073,15.668-15.26,28.089-44.2,27.026-59.16Z"
            data-name="Path 3299"
            fill="#70d1ff"
            fillRule="evenodd"
            id="Path_3299"
            transform="translate(-20.753 -123.957)"
          />
          <path
            d="M96.441,295.131s-9.756,2.913-33.392,2.342c-32.492-.785-52.294-24.444-46.962-37.862-3.736,5.824-3.53,14,3.026,23.809s22.44,18.543,45.108,18.737c9.553.082,21.426-.927,32.221-7.027Z"
            data-name="Path 3300"
            fill="#cce6ff"
            fillRule="evenodd"
            id="Path_3300"
            transform="translate(-13.644 -188.541)"
          />
          <path
            d="M223.174,191.484a1.171,1.171,0,1,1-1.493.719,1.171,1.171,0,0,1,1.493-.719Zm22.084-9.037a3.221,3.221,0,1,1-4.1,1.978A3.22,3.22,0,0,1,245.258,182.447Zm9.837-7.731a4.1,4.1,0,1,1-5.223,2.518A4.1,4.1,0,0,1,255.094,174.716Zm-31.433,2.053a1.464,1.464,0,1,1-1.866.9A1.464,1.464,0,0,1,223.661,176.769Zm11.107,12.576a4.1,4.1,0,1,1,2.149-5.384A4.1,4.1,0,0,1,234.768,189.345ZM236.795,177a3.221,3.221,0,1,1,1.689-4.231A3.223,3.223,0,0,1,236.795,177Zm19.76-10.4a1.464,1.464,0,1,1,.768-1.923A1.465,1.465,0,0,1,256.555,166.6Zm-9.587-11.178a1.171,1.171,0,1,1,.614-1.538A1.172,1.172,0,0,1,246.967,155.427Z"
            data-name="Path 3301"
            fill="#0084ff"
            fillRule="evenodd"
            id="Path_3301"
            transform="translate(-112.658 -137.869)"
          />
        </g>
        <path
          d="M387.659,78.124l8.263-8.222c18.426-18.335,21.688-37.242,22.245-44.35a2.65,2.65,0,0,0-.766-2.075,2.679,2.679,0,0,0-2.083-.782c-7.187.491-26.354,3.581-44.8,21.936l-8.261,8.22-2.569-.51a15.113,15.113,0,0,0-13.6,4.081l-9.859,9.81a2.225,2.225,0,0,0-.553,2.232,2.279,2.279,0,0,0,1.723,1.559l8.305,1.692a12.208,12.208,0,0,1,6.164,3.3l3.679,3.661L361.7,84.8l3.677,3.658A12.1,12.1,0,0,1,368.7,94.6l1.7,8.259a2.271,2.271,0,0,0,3.811,1.165l9.859-9.81a14.951,14.951,0,0,0,4.1-13.534Z"
          data-name="Path 3302"
          fill="#0bbc00"
          id="Path_3302"
          transform="translate(-159.485 -10.49)"
        />
        <path
          d="M410.64,12.13a8.445,8.445,0,0,1,8.082,8.621,61.124,61.124,0,0,1-3.985,17.644,84.984,84.984,0,0,1-19.884,30.4l-6.165,6.134c.052.29.051.29.1.593a20.844,20.844,0,0,1-5.677,17.474c-3.317,3.328-6.661,6.628-9.991,9.943-3.652,3.566-10.851,2.337-12.782-2.836a8.474,8.474,0,0,1-.3-1.012c-.608-2.835-1.133-5.688-1.755-8.519-.628-2.73-3.26-4.687-5.456-6.873-3.308-3.292-6.574-6.625-9.92-9.868a6.811,6.811,0,0,0-3.165-1.694c-4.983-1.092-10.633-1.214-13.217-4.829a7.974,7.974,0,0,1,.738-9.929c3.323-3.37,6.7-6.689,10.064-10.015a21.034,21.034,0,0,1,18.143-5.535c8.219-8.178,16.712-16.143,26.881-21.478a70.809,70.809,0,0,1,27.231-8.172,8.556,8.556,0,0,1,1.062-.047Zm-.151,5.532A50.913,50.913,0,0,0,395.8,20.628,78.05,78.05,0,0,0,365.543,39.6l-8.261,8.22c-5.7-1.132-11.725-.751-16.087,3.488-3.336,3.294-6.675,6.584-9.969,9.92a2.347,2.347,0,0,0,1.158,3.756c5.173,1.1,10.7,1.31,14.429,4.924q6.87,6.774,13.668,13.6c3.492,3.571,3.831,9.19,4.95,14.358a2.366,2.366,0,0,0,3.775,1.153c3.352-3.277,6.659-6.6,9.969-9.92a15.231,15.231,0,0,0,4.018-13.451l-.509-2.557c6.057-6.027,12.3-11.858,17.264-18.726,7.2-9.968,12.269-21.725,13.243-33.846a2.762,2.762,0,0,0-2.7-2.863Z"
          data-name="Path 3303"
          fill="#fff"
          id="Path_3303"
          transform="translate(-154.512 -5.463)"
        />
        <path
          d="M360.175,97.1l13.474-14.053a26.6,26.6,0,0,1,11.131,6.441c5.118,5,12.472,15.629,12.888,18.738s-12.017,12.007-14.06,12.3"
          data-name="Path 3304"
          fill="url(#linear-gradient-7)"
          fillRule="evenodd"
          id="Path_3304"
          transform="translate(-171.198 -39.228)"
        />
        <path
          d="M369.664,117.445s-9.775-7.167-5.858-8.783,23.828,15.785,22.848,19.909S376.946,127.339,369.664,117.445Z"
          data-name="Path 3305"
          fill="#008c00"
          fillRule="evenodd"
          id="Path_3305"
          transform="translate(-172.485 -51.371)"
        />
        <path
          d="M312.1,138.678a7.61,7.61,0,1,1-7.618,7.61A7.618,7.618,0,0,1,312.1,138.678ZM326.406,127.6a5.752,5.752,0,0,1-.826-2.984,5.854,5.854,0,0,1,10.656-3.348,11.1,11.1,0,0,1,1.932-.167,11.416,11.416,0,1,1-5.638,21.34,4.684,4.684,0,0,1-9.292-.84,4.426,4.426,0,0,1,.158-1.187,6.731,6.731,0,1,1,3.01-12.815Z"
          data-name="Path 3306"
          fill="#0084ff"
          fillRule="evenodd"
          id="Path_3306"
          transform="translate(-144.682 -56.232)"
        />
        <path
          d="M397.929,62.451s12.658,5.751,17.122,18.008c25.953-17.648,29.671-43.127,31.706-48.4"
          data-name="Path 3307"
          fill="url(#linear-gradient-8)"
          fillRule="evenodd"
          id="Path_3307"
          transform="translate(-189.172 -14.954)"
        />
        <path
          d="M362.78,83.12,349.892,97.173s-11.4.6-9.959-1.171c8.884-10.866,13.288-16.658,22.848-12.882Z"
          data-name="Path 3308"
          fill="url(#linear-gradient-9)"
          fillRule="evenodd"
          id="Path_3308"
          transform="translate(-161.5 -38.711)"
        />
        <path
          d="M411.295,72.948s-5.43-12.794-17.575-17.567c18.312-25.484,43.888-28.55,49.209-30.449"
          data-name="Path 3309"
          fill="url(#linear-gradient-10)"
          fillRule="evenodd"
          id="Path_3309"
          transform="translate(-167.24 -3.946)"
        />
        <path
          d="M392.925,96.8s12.4-3.271,11.717,2.928c-.836,7.606-25.117,25.717-25.776,25.179-2.682-2.189-5.21-3.1-5.273-4.684s4.858-5.854,9.373-11.126c1.633-1.905,9.959-12.3,9.959-12.3Z"
          data-name="Path 3310"
          fill="#009f00"
          fillRule="evenodd"
          id="Path_3310"
          transform="translate(-177.585 -45.362)"
        />
        <path
          d="M401.948,99.379s-22.036,22.291-24.018,22.837,16.4-21.666,16.4-21.666S400.316,97.821,401.948,99.379Z"
          data-name="Path 3311"
          fill="rgba(204,230,255,0.53)"
          fillRule="evenodd"
          id="Path_3311"
          transform="translate(-179.579 -46.773)"
        />
        <path
          d="M417.79,131.111,404.9,144.579l.586,9.369s12.635-9.613,14.06-15.225S417.79,131.111,417.79,131.111Z"
          data-name="Path 3312"
          fill="url(#linear-gradient-11)"
          fillRule="evenodd"
          id="Path_3312"
          transform="translate(-192.492 -62.109)"
        />
      </g>
    </svg>
  );
};
