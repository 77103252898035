import { useEffect } from 'react';

import EntityAssignment from '@/lib/v2/components/EntityAssignment';
import { EntityAssignmentProps } from '@/lib/v2/components/EntityAssignment/EntityAssignment';
import Modal from '@/lib/v2/components/Modal/Modal';

interface ModalAssignmentProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  items: { id: number; name: string }[];
  onCreate?: (toCreate: string) => Promise<void>;
  onApply: EntityAssignmentProps['onApply'];
  wordings: EntityAssignmentProps['wordings'];
  isLoading?: boolean;
  limitSelection?: number;
}

const ModalAssignment = ({
  isOpen,
  onClose,
  items,
  onCreate,
  onApply,
  wordings,
  isLoading,
  limitSelection,
}: ModalAssignmentProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <Modal noPadding withAuto open={isOpen} showCloseButton={!isLoading} onClose={onClose}>
      <EntityAssignment
        isLoading={isLoading}
        items={items}
        limitSelection={limitSelection}
        wordings={wordings}
        onApply={onApply}
        onClose={onClose}
        onCreate={onCreate}
      />
    </Modal>
  );
};

export default ModalAssignment;
