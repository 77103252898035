export const DownloadIcon = ({ color = '#364365' }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19532 11.4115C8.0748 11.4115 7.96263 11.3923 7.85879 11.3538C7.75494 11.3154 7.65623 11.2493 7.56264 11.1557L4.45304 8.04615C4.30433 7.89743 4.23093 7.7234 4.23287 7.52405C4.23478 7.3247 4.30818 7.14746 4.45304 6.99232C4.60818 6.83721 4.78638 6.75708 4.98764 6.75195C5.18893 6.74682 5.36713 6.82182 5.52227 6.97695L7.44534 8.90003V1.25C7.44534 1.03718 7.51713 0.858984 7.66072 0.715401C7.8043 0.571801 7.9825 0.5 8.19532 0.5C8.40813 0.5 8.58634 0.571801 8.72992 0.715401C8.8735 0.858984 8.94529 1.03718 8.94529 1.25V8.90003L10.8684 6.97695C11.0171 6.82823 11.1937 6.75483 11.3982 6.75675C11.6027 6.75868 11.7825 6.83721 11.9376 6.99232C12.0825 7.14746 12.1575 7.3231 12.1626 7.51925C12.1677 7.7154 12.0927 7.89103 11.9376 8.04615L8.82799 11.1557C8.73441 11.2493 8.63569 11.3154 8.53184 11.3538C8.42801 11.3923 8.31583 11.4115 8.19532 11.4115ZM2.50304 15.5C1.99791 15.5 1.57034 15.325 1.22034 14.975C0.870343 14.625 0.695343 14.1974 0.695343 13.6923V11.7307C0.695343 11.5179 0.767143 11.3397 0.910743 11.1961C1.05433 11.0525 1.23253 10.9808 1.44534 10.9808C1.65816 10.9808 1.83636 11.0525 1.97994 11.1961C2.12353 11.3397 2.19532 11.5179 2.19532 11.7307V13.6923C2.19532 13.7692 2.22737 13.8397 2.29147 13.9038C2.35558 13.9679 2.42611 14 2.50304 14H13.8876C13.9645 14 14.0351 13.9679 14.0992 13.9038C14.1633 13.8397 14.1953 13.7692 14.1953 13.6923V11.7307C14.1953 11.5179 14.2671 11.3397 14.4107 11.1961C14.5543 11.0525 14.7325 10.9808 14.9453 10.9808C15.1581 10.9808 15.3363 11.0525 15.4799 11.1961C15.6235 11.3397 15.6953 11.5179 15.6953 11.7307V13.6923C15.6953 14.1974 15.5203 14.625 15.1703 14.975C14.8203 15.325 14.3927 15.5 13.8876 15.5H2.50304Z"
        fill={color}
      />
    </svg>
  );
};
