export const SegmentationIcon = () => {
  return (
    <svg height="20" viewBox="0 0 39.399 39.377" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="segmentation" transform="translate(-1.985 -2.003)">
        <path
          d="M36.687,11.078A18.455,18.455,0,0,1,37.918,30.3L22.346,21.312V3.332a18.3,18.3,0,0,1,7.4,1.839,4.615,4.615,0,0,1,1.267-.82,19.684,19.684,0,0,0-14.688-1.6,4.5,4.5,0,0,1,1.135,1.063,17.667,17.667,0,0,1,3.57-.48V21.312L5.461,30.3a18.468,18.468,0,0,1,.286-17.761,4.531,4.531,0,0,1-.459-1.752c-8.651,12.871.695,30.594,16.4,30.591C38.051,41.34,47.173,22.489,37.3,9.7A4.559,4.559,0,0,1,36.687,11.078Zm-10.4,28.408a5.134,5.134,0,0,0-3.1-5.044,3.274,3.274,0,1,0-2.988,0,5.134,5.134,0,0,0-3.1,5.044A18.442,18.442,0,0,1,6.121,31.437L21.69,22.449l15.569,8.989a18.442,18.442,0,0,1-10.974,8.049Z"
          data-name="Path 4557"
          fill="#18c678"
          id="Path_4557"
          transform="translate(0 0)"
        />
        <path
          d="M10.1,16.188A5.217,5.217,0,0,0,7,21.427a7.105,7.105,0,0,0,9.188,0,5.217,5.217,0,0,0-3.1-5.24,3.274,3.274,0,1,0-2.988,0Z"
          data-name="Path 4558"
          fill="#18c678"
          id="Path_4558"
          transform="translate(-1.721 -2.748)"
        />
        <path
          d="M18.977,11.219a4.58,4.58,0,0,1-.9,2.724,6.5,6.5,0,0,1,1.91,2.98,7.526,7.526,0,0,0,2.927-1.5,5.2,5.2,0,0,0-3.1-5.237,3.276,3.276,0,1,0-4.719-3.5A4.591,4.591,0,0,1,18.977,11.219Zm9.845,10.115a7.105,7.105,0,0,0,9.188,0,5.217,5.217,0,0,0-3.1-5.24,3.274,3.274,0,1,0-2.988,0A5.216,5.216,0,0,0,28.821,21.334Z"
          data-name="Path 4559"
          fill="#18c678"
          id="Path_4559"
          transform="translate(-4.507 -0.686)"
        />
        <path
          d="M44.1,9.684a4.615,4.615,0,0,1,2.98,7.259,6.5,6.5,0,0,1,1.91,2.98,7.526,7.526,0,0,0,2.927-1.5,5.2,5.2,0,0,0-3.1-5.237,3.276,3.276,0,1,0-4.719-3.5Z"
          data-name="Path 4560"
          fill="#18c678"
          id="Path_4560"
          transform="translate(-14.473 -1.717)"
        />
      </g>
    </svg>
  );
};
