type AnyObject = {
  [key: string]: any;
};

export function convertValuesToString(obj: AnyObject): AnyObject {
  const newObj: AnyObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === 'number' || typeof value === 'boolean') {
        newObj[key] = value.toString();
      } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        newObj[key] = convertValuesToString(value);
      } else {
        newObj[key] = value;
      }
    }
  }

  return newObj;
}
