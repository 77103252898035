import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosResponse,
  GenericAbortSignal,
  ResponseType,
} from 'axios';

interface IOptions {
  withCredentials?: boolean;
  responseType?: ResponseType;
  signal?: GenericAbortSignal;
  headers?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
interface UnauthorizedV1Response {
  Code: number;
  Message: string;
}
// cspell:disable
interface IAPIServiceConfig {
  onRefresh: () => Promise<void>;
}
// cspell:enable

let failedRequests = 0;
const MAX_RETRIES = 2;
export class APIService {
  token?: string;
  baseURL: string;
  onRefresh?: () => Promise<void>;
  axiosInstance: AxiosInstance;

  // cspell:disable
  constructor(baseURL: string, token?: string, config?: IAPIServiceConfig) {
    this.token = token;
    this.baseURL = baseURL;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.onRefresh = config?.onRefresh ?? this.onTokenDisconnected;
    this.axiosInstance = axios.create();

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        failedRequests = 0;
        return response;
      },
      async (error: AxiosError) => {
        const { data, status } = error?.response ?? {};
        if (status === 401) {
          failedRequests += 1;

          if (failedRequests >= MAX_RETRIES) {
            failedRequests = 0;
            if (this.onRefresh && error.config) {
              try {
                await this.onRefresh();
                return this.axiosInstance.request(error.config);
              } catch (refreshError) {
                return Promise.reject(refreshError);
              }
            }
          }
        } else if (
          status === 500 &&
          (data as UnauthorizedV1Response).Code === 1 &&
          (data as UnauthorizedV1Response).Message === 'NOT_LOGED_IN'
        ) {
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }

  // cspell:enable
  // eslint-disable-next-line @typescript-eslint/require-await
  private onTokenDisconnected = async () => {
    localStorage.setItem('token-expired', 'true');
    const service = new APIService(window.location.origin);
    await service.get('/Services/Login.svc/Logout');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).__JWTToken = undefined;
    window.location.href = '/Account/Login';
  };

  async get<T>(
    url: string,
    body?: object,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.get(url, {
      withCredentials:
        options?.withCredentials !== undefined ? options.withCredentials : !this.token,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      responseType: options?.responseType || 'json',
      params: { ...(body || {}) },
      baseURL: this.baseURL,
      signal: options?.signal,
    });
  }

  async post<T>(
    url: string,
    body?: object,
    multipart?: boolean,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.post(
      url,
      { ...body },
      {
        withCredentials:
          options?.withCredentials !== undefined ? options.withCredentials : !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
          ...(options?.headers ? options.headers : {}),
        },
      }
    );
  }

  async patch<T>(
    url: string,
    body?: object,
    multipart?: boolean,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.patch(
      url,
      { ...body },
      {
        withCredentials:
          options?.withCredentials !== undefined ? options.withCredentials : !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  // Temporary fix!
  async postWithCredentials<T>(
    url: string,
    body?: object,
    multipart?: boolean
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.post(
      url,
      { ...body },
      {
        withCredentials: !!this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  async put<T>(
    url: string,
    body?: object,
    multipart?: boolean
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.put(
      url,
      { ...body },
      {
        withCredentials: !this.token,
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        },
      }
    );
  }

  async delete<T>(url: string, body?: object): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.delete(url, {
      withCredentials: !this.token,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      data: { ...(body || {}) },
      baseURL: this.baseURL,
    });
  }

  async postFormData<T>(
    url: string,
    formData: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.post(url, formData, {
      withCredentials: !this.token,
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': window.location.origin,
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
      onUploadProgress,
    });
  }

  async postRaw<T>(
    url: string,
    body: string,
    options?: IOptions
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.post(url, body, {
      withCredentials: !this.token,
      data: body,
      baseURL: this.baseURL,
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        ...(options?.headers ?? {}),
      },
    });
  }

  /* cspell:disable */
  async postOnsite<T>(
    url: string,
    token: string,
    body?: object
  ): Promise<{ data: T; status: number }> {
    return await this.axiosInstance.post(
      url,
      { ...body },
      {
        data: { ...body },
        baseURL: this.baseURL,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          ...(token ? { Authorization: `Onsite ${token}` } : {}),
        },
      }
    );
  }
}
