import { useEffect } from 'react';

export const Zendesk = () => {
  useEffect(() => {
    // cspell:disable
    const script = document.createElement('script');
    script.id = 'zsiqchat';
    script.innerHTML =
      'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq171f47854cafc1d4f0e4e721202ebfa67df5a6a9e3145a2e7a72d08b985dbd72", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';
    document.querySelector('html')?.setAttribute('lang', localStorage.getItem('idioma') ?? 'en');
    // cspell:enable
    document.querySelector('body')?.appendChild(script);
  }, []);

  return null;
};
