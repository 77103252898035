import { CustomFieldsServiceFactory } from './CustomFieldsServiceFactory';

export class CustomFieldsServiceSingleton {
  private static instance: ReturnType<typeof CustomFieldsServiceFactory.createService> | null =
    null;

  /**
   * Retrieves the single instance of the custom fields service.
   * If the instance does not exist, it creates one using the CustomFieldsServiceFactory.
   *
   * @returns {ReturnType<typeof CustomFieldsServiceFactory.createService>} The singleton instance of the custom fields service.
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = CustomFieldsServiceFactory.createService();
    }
    return this.instance;
  }
}
