import { CheckIcon, MinusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, If, Text } from '@/lib/v2/components';

import { AppSectionsForPlans, PlanType } from '@/src/infrastructure/interfaces/IResponses';
import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';
import { numberToCurrency } from '@/src/utils/currency';

import {
  atomDowngradeIsPending,
  atomPlansInformation,
} from '@/modules/MyPlanModule/atoms/ManagePlan';
import { useFrequencyOptions } from '@/modules/MyPlanModule/hooks/useFrequencyOptions';
import { usePlanAdditionalInfo } from '@/modules/MyPlanModule/hooks/usePlanAdditionalInfo';
import { HandleSelectedPlan } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';
import { PlansIds } from '@/modules/MyPlanModule/types';

type Sections = Section[];
interface Section {
  name: AppSectionsForPlans;
  features: Feature[];
}

interface Feature {
  name: string;
  plans: { [key: string]: boolean | string };
  highlight?: boolean;
  text?: string;
}

interface ComparisonOfPlansProps {
  frequency: Frequency;
  onSelectedPlan: HandleSelectedPlan;
}

const ComparisonOfPlans = ({ frequency, onSelectedPlan }: ComparisonOfPlansProps) => {
  const { t } = useTranslation();
  const downgradeIsPending = useAtomValue(atomDowngradeIsPending);
  const plansInformation = useAtomValue(atomPlansInformation);
  const { frequencyOptions } = useFrequencyOptions();
  const { planAdditionalInfo } = usePlanAdditionalInfo();

  const sections = useMemo<Sections>(() => {
    const sectionsMap = {} as Record<
      AppSectionsForPlans,
      { name: AppSectionsForPlans; features: Feature[] }
    >;
    plansInformation?.forEach((plan) => {
      const { features: featuresPlan, name: planName } = plan;
      featuresPlan.forEach((feat) => {
        const { section: sectionName, name: featureName, text } = feat;
        if (!sectionsMap[sectionName]) {
          sectionsMap[sectionName] = { name: sectionName, features: [] } as {
            name: AppSectionsForPlans;
            features: Feature[];
          };
        }

        const featureIndex = sectionsMap[sectionName].features.findIndex(
          (f) => f.name === featureName
        );

        const value = text ?? true;

        type PlanTypeO = Exclude<PlanType, 'Free Trial'>;

        if (featureIndex !== -1) {
          const currentPlans = sectionsMap[sectionName].features[featureIndex].plans;
          if (typeof text === 'string') {
            currentPlans[planName as PlanTypeO] = text;
          }
          sectionsMap[sectionName].features[featureIndex].plans = {
            ...currentPlans,
          };
        } else {
          const plan: { [K in PlanTypeO]: boolean | string } = {
            Free: planName !== 'Free' ? false : value,
            Starter: planName === 'Advance' || planName === 'Enterprise' ? false : value,
            Advance: planName === 'Enterprise' ? false : value,
            Enterprise: value,
          };
          sectionsMap[sectionName].features.push({
            name: featureName,
            plans: {
              ...plan,
            },
          });
        }
      });
    });

    return Object.values(sectionsMap);
  }, [plansInformation]);

  const isMinorPlan = (planName: PlanType) => {
    const currentPlanIndex = plansInformation?.findIndex((plan) => plan.actualPlan) ?? 0;
    const planNameIndex = plansInformation?.findIndex((plan) => plan.name === planName) ?? 0;
    if (planNameIndex < currentPlanIndex) return true;
    return false;
  };

  return (
    <div className="sticky top-0 h-[calc(95vh-73px)] w-full rounded-md bg-white p-6">
      <div className="mx-auto h-full">
        <div className="isolate h-full">
          <div className="relative -mx-6 h-full">
            <div className="relative size-full overflow-y-scroll">
              <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                <thead className="z-2 sticky top-0 bg-white">
                  <tr>
                    <td />
                    {plansInformation?.map((plan) => {
                      const { id, name } = plan;
                      return (
                        <th key={id} className="px-2 pt-6 xl:pt-8 2xl:px-8" scope="col">
                          <Text as="h3" color="primary" fontWeight="semibold">
                            {planAdditionalInfo[name].name}
                          </Text>
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="sr-only">Price</span>
                    </th>
                    {plansInformation?.map((plan) => {
                      const { id, price, name, actualPlan } = plan;
                      let textButton = t('MANAGE_PLAN.PLANS.selectPlan');
                      if (name === 'Enterprise') textButton = t('MANAGE_PLAN.PLANS.requestQuote');
                      if (actualPlan) textButton = t('MANAGE_PLAN.PLANS.actualPlanText');

                      const disabledButton = isMinorPlan(name);
                      return (
                        <td key={id} className="px-2 pt-2 2xl:px-8">
                          <If condition={name !== 'Enterprise'}>
                            <Text className="my-4" variant="title-3">
                              {numberToCurrency(price[frequency.value].perMonth)}
                              <Text
                                as={'span'}
                                color="light-black"
                                fontWeight="medium"
                                variant="text"
                              >
                                {frequencyOptions[0].priceSuffix}
                              </Text>
                            </Text>
                          </If>
                          <If condition={name === 'Enterprise'}>
                            <Text className="my-4" variant="title-3">
                              {t('MANAGE_PLAN.PLANS.contactBtn')}
                            </Text>
                          </If>
                          <Button
                            fullWidth
                            primary
                            disabled={
                              (actualPlan && name === 'Free') ||
                              disabledButton ||
                              (name !== 'Enterprise' && downgradeIsPending)
                            }
                            id={name}
                            outline={actualPlan}
                            standard={actualPlan}
                            onClick={() => onSelectedPlan(id as PlansIds)}
                          >
                            {textButton}
                          </Button>
                        </td>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sections.map((section, sectionIdx) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          className={classNames(
                            sectionIdx === 0 ? 'pt-8' : 'pt-16',
                            'pb-4 text-sm font-semibold leading-6 text-gray-900'
                          )}
                          colSpan={4}
                          scope="colgroup"
                        >
                          {t(`COMPARISON_OF_PLANS.SECTION.${section.name}`)}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                        </th>
                      </tr>
                      {section.features.map((feature, index) => (
                        <tr key={feature.name}>
                          <th
                            className="text-sm py-4 font-normal leading-6 text-gray-900"
                            scope="row"
                          >
                            {t(`COMPARISON_OF_PLANS.FEATURES.${section.name}.${index + 1}`)}
                            <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                          </th>
                          {plansInformation?.map((plan) => {
                            const { id, name } = plan;

                            return (
                              <td key={id} className="px-2 py-4 2xl:px-8">
                                {typeof feature.plans[name] === 'string' ? (
                                  <div className="text-sm text-center leading-6 text-gray-500">
                                    {t(
                                      `COMPARISON_OF_PLANS.FEATURES_VALUES.${feature.plans[name]}`
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {feature.plans[name] === true ? (
                                      <CheckIcon
                                        aria-hidden="true"
                                        className="mx-auto size-5 text-emblue-primary"
                                      />
                                    ) : (
                                      <MinusIcon
                                        aria-hidden="true"
                                        className="mx-auto size-5 text-gray-400"
                                      />
                                    )}

                                    <span className="sr-only">
                                      {feature.plans[name] === true ? 'Included' : 'Not included'}{' '}
                                      in {name}
                                    </span>
                                  </>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ComparisonOfPlans);
