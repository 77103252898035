import { useAtomValue } from 'jotai';
import { isValidElement, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { chatShow } from '@/lib/components/SupportCard';
import { Alert, Button, ButtonProps, Card, CardSection, If, Text } from '@/lib/v2/components';

import PriceText from './PriceText';
import TotalPrice from './TotalPrice';

import { atomBillingInformation } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { RadioSwitch } from '@/modules/MyPlanModule/components';
import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { usePlanPriceInformation } from '@/modules/MyPlanModule/hooks/usePlanPriceInformation';

interface PlanPriceInformationProps {
  checkoutButton?: ReactElement<ButtonProps>;
  isStepThree?: boolean;
  prorationAmount?: number;
  prorationAmountIsLoading?: boolean;
}

const PlanPriceInformation = ({
  checkoutButton,
  isStepThree = false,
  prorationAmount,
  prorationAmountIsLoading,
}: PlanPriceInformationProps) => {
  if (checkoutButton && (!isValidElement(checkoutButton) || checkoutButton.type !== Button)) {
    throw new Error('The "checkoutButton" prop must be a Button component v2');
  }

  const { t } = useTranslation();

  const {
    annuallyTotal,
    frequencyOptions,
    frequency,
    handleFrequency,
    planNameSelected,
    planPrice,
    monthSuffixText,
    emailAmount,
    priceEmails,
    isFreemiumPlan,
    smsAmount,
    priceSms,
    onsiteAmount,
    isAnnuallySelected,
    priceOnsite,
    monthlyTotal,
    priceIncreaseEmail,
    priceIncreaseOnsite,
    priceIncreaseSms,
    proratedPrices,
    hasProrations,
    alertText,
  } = usePlanPriceInformation();

  const { getCountryOption } = useCountriesOptions();
  const billingInformation = useAtomValue(atomBillingInformation);

  const countryName = billingInformation?.country
    ? getCountryOption(billingInformation?.country.toString()).name
    : 'America Central';

  return (
    <Card isHeightAuto noShadow color="gray">
      <CardSection>
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full justify-center">
            <RadioSwitch options={frequencyOptions} value={frequency} onChange={handleFrequency} />
          </div>
          <div className="mt-4 flex justify-between">
            <PriceText
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              price={planPrice}
              proratedPrice={proratedPrices?.plan}
              title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.planPrice', {
                planName: planNameSelected,
              })}
            />
          </div>
          <div className="mt-4 flex justify-between">
            <PriceText
              amount={emailAmount}
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              price={priceEmails}
              priceIncrease={priceIncreaseEmail}
              proratedPrice={proratedPrices?.email}
              title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.emails')}
              type="Emails"
            />
          </div>
          <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
            <PriceText
              amount={smsAmount}
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              price={priceSms}
              priceIncrease={priceIncreaseSms}
              proratedPrice={proratedPrices?.sms}
              title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.sms')}
              type="SMS"
            />
          </div>
          <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
            <PriceText
              amount={onsiteAmount}
              isAnnuallySelected={isAnnuallySelected}
              monthSuffixText={monthSuffixText}
              price={priceOnsite}
              priceIncrease={priceIncreaseOnsite}
              proratedPrice={proratedPrices?.onsite}
              title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.onsite')}
              type="Onsite"
            />
          </div>
          <div className="mt-4 w-full border-b" />
          <div className="flex flex-col py-4">
            <TotalPrice
              annuallyTotal={annuallyTotal}
              isAnnuallySelected={isAnnuallySelected}
              isFreemiumPlan={isFreemiumPlan}
              monthSuffixText={monthSuffixText}
              monthlyTotal={monthlyTotal}
              proratedTotalPrice={proratedPrices?.total}
              prorationAmount={prorationAmount}
              prorationAmountIsLoading={prorationAmountIsLoading}
            />
          </div>
          {checkoutButton}
          <If condition={!isFreemiumPlan}>
            <div className="flex flex-col gap-4">
              <If condition={hasProrations && isStepThree}>
                <Alert description={alertText} type="info" />
              </If>
              <Text isItalic variant="text-sm">
                <Trans
                  components={{
                    li: (
                      <Text
                        isItalic
                        link
                        color="primary"
                        fontWeight="medium"
                        id="link-chat"
                        variant="text-sm"
                        onClick={chatShow}
                      />
                    ),
                  }}
                  i18nKey="MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.warning"
                  values={{ countryName: countryName }}
                />
              </Text>
              <If condition={alertText !== '' && !hasProrations}>
                <Alert description={alertText} type="info" />
              </If>
            </div>
          </If>
        </div>
      </CardSection>
    </Card>
  );
};

export default PlanPriceInformation;
