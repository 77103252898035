/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { ReactNode, useEffect } from 'react';

import { getEnv } from '@/src/application/hooks/util/useEnv';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { parseJwt } from '@/src/compat/util';
import configData from '@/src/config.json';

import { SOURCE_INBOX } from '@/modules/AuthModule/constants';

interface InboxProviderProps {
  children: ReactNode;
}

const InboxProvider: React.FC<InboxProviderProps> = ({ children }): JSX.Element => {
  const env = getEnv();
  const limitInboxAgent = useFeatureFlag('limitInboxAgent');

  useEffect(() => {
    const handleInboxRedirection = () => {
      const { JWT_DEV_TOKEN } = configData.endpoints[env === 'storybook' ? 'development' : env];
      const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : (window as any).__JWTToken;

      const urlParams = new URLSearchParams(window.location.search);
      const sourceParam = urlParams.get('source');

      if (sourceParam && !localStorage.getItem('redirectInbox')) {
        localStorage.setItem('redirectInbox', 'true');
      }

      const redirectInboxValue = localStorage.getItem('redirectInbox');

      if (jwtToken && (redirectInboxValue || sourceParam === SOURCE_INBOX)) {
        localStorage.removeItem('redirectInbox');

        const baseUrl = configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
        const loginPath = '/emblue/login';
        const redirectUrl = `${String(baseUrl)}${loginPath}?token=${String(jwtToken)}`;

        window.location.href = redirectUrl;
        return;
      }

      if (jwtToken && limitInboxAgent) {
        try {
          const userPayload = jwtToken ? parseJwt(String(jwtToken)) : {};

          const isInboxAgent = userPayload.isInboxAgent === 'True';

          if (isInboxAgent) {
            const baseUrl =
              configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
            const loginPath = '/emblue/login';
            const redirectUrl = `${String(baseUrl)}${loginPath}?token=${String(jwtToken)}`;

            window.location.href = redirectUrl;
            return;
          }
        } catch (error) {
          console.error('Error parsing JWT token:', error);
        }
      }
    };

    handleInboxRedirection();
  }, [env, limitInboxAgent]);

  return <>{children}</>;
};

export default InboxProvider;
