import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Text } from '@/lib/v2/components';

import SMSCountryModalPrice from '@/src/modules/MyPlanModule/screens/ManagePlan/components/SMSCountryModalPrice';
import { numberToCurrency } from '@/src/utils/currency';

interface AdditionalCostsProps {
  additionalPriceEmail: number;
  additionalPriceSms: number;
  additionalPriceOnsite: number;
}

const AdditionalCosts = ({ additionalPriceEmail, additionalPriceOnsite }: AdditionalCostsProps) => {
  const { t } = useTranslation();

  const [countrySmsModalIsOpen, setCountrySmsModalIsOpen] = useState(false);

  const openCountryPriceModal = useCallback(() => setCountrySmsModalIsOpen(true), []);

  return (
    <>
      <Card noShadow color="gray">
        <CardSection>
          <Text fontWeight="medium">{t('MANAGE_PLAN.ADDITIONAL_COSTS.title')}</Text>
          <div className="flex w-full flex-row">
            <Text className="pr-2" variant="text">
              {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalEmails', {
                price: numberToCurrency(additionalPriceEmail),
              })}
            </Text>
            <Text className="pr-2" variant="text">
              {'  |  '}
              {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalSms')}{' '}
              <Text
                as="span"
                color="primary"
                id="open-country-price-modal"
                variant="text"
                onClick={openCountryPriceModal}
              >
                {t('MANAGE_PLAN.ADDITIONAL_COSTS.SMSOpenModalPriceTitle')}
              </Text>
              {'  |  '}
            </Text>
            <Text variant="text">
              {t('MANAGE_PLAN.ADDITIONAL_COSTS.additionalOnsite', {
                price: numberToCurrency(additionalPriceOnsite),
              })}
            </Text>
          </div>
        </CardSection>
      </Card>
      <SMSCountryModalPrice isOpen={countrySmsModalIsOpen} onClose={setCountrySmsModalIsOpen} />
    </>
  );
};

export default memo(AdditionalCosts);
