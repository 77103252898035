import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Accordion, Button, Collapse, Input, RadioButton, Text, toast } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import Select, { Option } from '@/lib/v2/components/Select/Select';
import { EmailIcon, UsersIcon } from '@/lib/v2/icons/solid';

import { ContactsTagsAndGroups } from '@/src/ContactsModule/components/ContactTagsAndGroups/ContactsTagsAndGroups';
import { geolocation } from '@/src/ContactsModule/utils/Geolocation';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { cleanCustomFields, cleanObject, formatFields } from './helper';

import { CustomsFieldsProps } from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';

type sexProp = 'male' | 'female' | 'n/a' | undefined;

type FormInput = {
  email: string;
  name: string;
  phoneNumber: string;
  surName: string;
  secondEmail: string;
  phoneNumber2: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  country: string;
  busyness: string;
  position: string;
  webUrl: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  customField: string;
  [key: string]: string;
};

export const CreateContacts = () => {
  const { t } = useTranslation();
  const [selectedTags, setSelectedTags] = useState<Option[]>([
    { id: 0, name: t('IMPORT_MAIN.noTags') } as Option,
  ]);
  const [selectedGroups, setSelectedGroups] = useState<Option[]>([
    { id: 0, name: t('IMPORT_MAIN.noGroups') } as Option,
  ]);

  const countryOptions: Option[] = Object.entries(geolocation).map(([id, name]) => ({
    id,
    name,
  }));
  const [countrySelected, setCountrySelected] = useState<Option>();
  const [sex, setSex] = useState<sexProp>(undefined);
  const [date, setDate] = useState<Date>();
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<number>(0);
  const navigate = useNavigate();
  const service = useService();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormInput>();

  const handleDateOnChange: eventOnChangeDateInput = useCallback((e) => {
    if (e instanceof Date) {
      setDate(e);
    }
  }, []);

  const createContact = async (email: string) => {
    const response: number = await service?.createContactsByEmail({ email });
    return response;
  };

  const verifyContactsExist = async (email: string) => {
    const emailId: number = (await service?.verifyContactsExist({ email })) as number;

    return emailId;
  };

  const setValuesInputs = (customFields: CustomsFieldsProps[]) => {
    const fields = cleanCustomFields(customFields);

    fields.forEach((field) => {
      const { name, value } = field;
      setValue(name, value);
    });
  };

  const addContactsToGroup = async (emailId: number) => {
    const idGroups: number[] = selectedGroups
      .filter((g) => g.id !== 0)
      .map((g) => {
        return g.id as number;
      });

    if (idGroups.length > 0) {
      await service?.addContactsToGroup({ contactsIds: [emailId], groupsToAssign: idGroups });
    }
  };

  const addTagsToGroup = async (emailId: number) => {
    const idTags: number[] = selectedTags
      .filter((t) => t.id !== 0)
      .map((t) => {
        return t.id as number;
      });

    if (idTags.length > 0) {
      await service?.addTagsToGroup({ emailId: emailId, associatedTags: idTags });
    }
  };

  const getCustomFields = async (emailId: number) => {
    return await service?.getCustomFieldsByEmailId({ emailId });
  };

  const onBlurEmail = handleSubmit(async (values) => {
    const { email } = values;
    if (!email) return;
    let emailId = await verifyContactsExist(email);
    if (emailId === 0) {
      emailId = await createContact(email);
    } else {
      const customField = (await getCustomFields(emailId)) as CustomsFieldsProps[];
      setValuesInputs(customField);
    }
    return setEmailId(emailId);
  });

  const onHandleChangeCountry = (value: Option | Option[]) => {
    setCountrySelected(value as Option);
  };

  const settingExtrapolateInputs = (values: FormInput) => {
    if (countrySelected) {
      values = { ...values, country: countrySelected?.id.toString() };
    }
    if (sex) {
      const sexSelected = sex === 'male' ? 'M' : 'F';
      values = { ...values, sex: sexSelected };
    }
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      values = { ...values, birthday: formattedDate };
    }

    return values;
  };

  const onHandleCancel = () => {
    navigate('../');
  };

  const onActionSubmit = handleSubmit(async (values) => {
    values = settingExtrapolateInputs(values);
    const fields = cleanObject(values);
    const formattedFields = formatFields(fields);

    if (formattedFields !== '') {
      await service?.editCustomFields({ emailId: emailId, modifiedFields: formattedFields });
    }
    await addContactsToGroup(emailId);
    await addTagsToGroup(emailId);

    toast({
      show: true,
      title: t('IMPORT_MAIN.createContactsSuccess'),
      autoCloseDelay: 5000,
    });
    navigate(`../${emailId}`);
  });

  return (
    <div className="h-full w-full divide-y rounded-md border border-[#D3DDEA] bg-white">
      <Divide>
        <Flex column itemAlignment="start">
          <div className="p-4 px-10">
            <Flex withGap alignment="start" gapSize="medium">
              <UsersIcon />
              <Text variant="headline">{t('CREATE_CONTACT.addContact')}</Text>
            </Flex>
          </div>
          <div className="w-full p-8">
            <Flex withGap gapSize="medium" itemAlignment="start">
              <div className="w-3/4">
                <Flex column alignment="start" itemAlignment="start">
                  <Text fontWeight="semibold">{t('CREATE_CONTACT.basicInfoTitle')}</Text>
                  <Text variant="text">{t('CREATE_CONTACT.basicInfoBody')}</Text>
                </Flex>
              </div>
              <Flex column>
                <Flex withGap gapSize="medium" itemAlignment="start">
                  <Flex column withGap gapSize="medium" itemAlignment="start">
                    <Input
                      {...register('email', {
                        required: true,
                        pattern: /^[a-z\d._%+-]+@[a-z\d.-]+\.[a-z]{2,4}$/,
                      })}
                      disabled={emailId !== 0}
                      iconLeft={<EmailIcon fillColor="#D3DDEA" />}
                      id="emailOptions"
                      label={`${t('CREATE_CONTACT.email')}*`}
                      onBlur={onBlurEmail}
                    />
                    <Input
                      label={t('CREATE_CONTACT.name')}
                      {...register('name')}
                      id="nameOptions"
                    />
                    <div className="eb-input label">{t('CREATE_CONTACT.sex')}</div>
                    <Flex withGap gapSize="small">
                      <RadioButton
                        checked={sex === 'n/a'}
                        id="n/a"
                        label="n/a"
                        name="sex"
                        onChange={(e) => setSex(e.target.id)}
                      />
                      <RadioButton
                        checked={sex === 'male'}
                        id="male"
                        label={t('CREATE_CONTACT.male')}
                        name="sex"
                        onChange={(e) => setSex(e.target.id)}
                      />
                      <RadioButton
                        checked={sex === 'female'}
                        id="female"
                        label={t('CREATE_CONTACT.female')}
                        name="sex"
                        onChange={(e) => setSex(e.target.id)}
                      />
                    </Flex>
                    {!viewAll && (
                      <Button secondary id="viewAllButton" onClick={() => setViewAll(true)}>
                        {t('CREATE_CONTACT.viewAll')}
                      </Button>
                    )}
                  </Flex>
                  <Flex column withGap gapSize="medium" itemAlignment="start">
                    <Input
                      label={t('CREATE_CONTACT.numberPhone')}
                      {...register('phoneNumber')}
                      id="numberOptions"
                    />
                    <Input
                      label={t('CREATE_CONTACT.surname')}
                      {...register('surName')}
                      id="surnameOptions"
                    />
                  </Flex>
                </Flex>
                <Collapse isOpened={viewAll}>
                  <div className="w-full pt-6">
                    <Flex withGap gapSize="medium" itemAlignment="start">
                      <Flex column withGap gapSize="medium" itemAlignment="start">
                        <div className="grid w-full grid-cols-2 gap-6">
                          <Input
                            iconLeft={<EmailIcon fillColor="#D3DDEA" />}
                            label={t('CREATE_CONTACT.secondEmail')}
                            {...register('secondEmail')}
                            id="secondEmailOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.numberPhone2')}
                            {...register('phoneNumber2')}
                            id="phoneNumber2Options"
                          />
                          <DateInput
                            date={date}
                            id="dateOptions"
                            label={t('CREATE_CONTACT.birthday')}
                            onChange={handleDateOnChange}
                          />
                        </div>
                        <div className="grid w-full grid-cols-2 gap-6">
                          <Input
                            label={t('CREATE_CONTACT.address')}
                            {...register('address')}
                            id="addressOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.city')}
                            {...register('city')}
                            id="cityOptions"
                          />
                          <Select
                            label={t('CREATE_CONTACT.country')}
                            {...register('country')}
                            id="countryOptions"
                            options={countryOptions}
                            value={countrySelected}
                            onChange={onHandleChangeCountry}
                          />
                        </div>
                        <div className="grid w-full grid-cols-2 gap-6">
                          <Input
                            label={t('CREATE_CONTACT.industry')}
                            {...register('busyness')}
                            id="busynessOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.position')}
                            {...register('position')}
                            id="positionOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.web')}
                            {...register('webUrl')}
                            id="webOptions"
                          />
                        </div>
                        <div className="grid w-full grid-cols-2 gap-6">
                          <Input
                            label={t('CREATE_CONTACT.facebook')}
                            {...register('facebook')}
                            id="facebookOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.twitter')}
                            {...register('twitter')}
                            id="twitterOptions"
                          />
                          <Input
                            label={t('CREATE_CONTACT.linkedin')}
                            {...register('linkedin')}
                            id="linkedinOptions"
                          />
                        </div>
                        <Flex itemAlignment="start">
                          <Button secondary id="hideButton" onClick={() => setViewAll(false)}>
                            {t('CREATE_CONTACT.seeLess')}
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
                </Collapse>
              </Flex>
            </Flex>
          </div>
          <div className="w-full p-8">
            <Accordion title={t('CREATE_CONTACT.tagsAndGroups')}>
              <ContactsTagsAndGroups
                withTag
                selectedGroups={selectedGroups}
                selectedTags={selectedTags}
                setSelectedGroups={setSelectedGroups}
                setSelectedTags={setSelectedTags}
              />
            </Accordion>
          </div>
          <div className="w-full border-emblue-secondary bg-[#F9FAFB] px-8 py-4">
            <Flex withGap alignment="end" gapSize="small">
              <div className="mr-12">
                <Text>{t('CREATE_CONTACT.permission')}</Text>
              </div>
              <Button secondary id="cancelButton" onClick={onHandleCancel}>
                {t('CREATE_SENDER_MODAL.cancel')}
              </Button>
              <Button disabled={emailId === 0} id="createContactButton" onClick={onActionSubmit}>
                {t('CREATE_SENDER_MODAL.save')}
              </Button>
            </Flex>
          </div>
        </Flex>
      </Divide>
    </div>
  );
};
