export const CopyFileIcon = ({ fillColor = '#004dbc' }) => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon" transform="translate(-364.006)">
        <rect fill="none" height="16" transform="translate(364.006)" width="16" />
        <g data-name="Grupo 3955" id="Grupo_3955" transform="translate(365.334 0.375)">
          <g data-name="Grupo 3953" id="Grupo_3953">
            <path
              d="M8.906,2A1.906,1.906,0,0,0,7,3.906v7.625a1.906,1.906,0,0,0,1.906,1.906h5.719a1.906,1.906,0,0,0,1.906-1.906V6.207a1.906,1.906,0,0,0-.558-1.347l-2.3-2.3A1.906,1.906,0,0,0,12.324,2Z"
              fill={fillColor}
              transform="translate(-3.187 -2)"
            />
            <path
              d="M3,7.906A1.906,1.906,0,0,1,4.906,6v9.532h7.625a1.906,1.906,0,0,1-1.906,1.906H4.906A1.906,1.906,0,0,1,3,15.532Z"
              fill={fillColor}
              transform="translate(-3 -2.187)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
