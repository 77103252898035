import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Text } from '@/lib/v2/components';

import { numberToCurrency } from '@/src/utils/currency';

interface PriceTextProps {
  title: string;
  type?: 'Emails' | 'SMS' | 'Onsite';
  proratedPrice?: number;
  amount?: number;
  price: number;
  priceIncrease?: number;
  monthSuffixText: string;
  isAnnuallySelected: boolean;
}

const PriceText = ({
  proratedPrice,
  price,
  amount,
  priceIncrease,
  monthSuffixText,
  isAnnuallySelected,
  title,
  type,
}: PriceTextProps) => {
  const { t } = useTranslation();
  const useProratedPrice = useCallback(
    (isAnnuallySelected: boolean, proratedPrice?: number): boolean => {
      return !isAnnuallySelected && !!proratedPrice;
    },
    []
  );

  const hasProratedPriceVar = useProratedPrice(isAnnuallySelected, proratedPrice);
  const boldPrice = hasProratedPriceVar ? (proratedPrice as number) : price;
  return (
    <>
      <div className="flex flex-col">
        <Text fontWeight="medium">{title}</Text>
        <If condition={type !== undefined}>
          <Text variant="text">
            {numberToCurrency(amount ?? 0, true)} {type} {monthSuffixText}
          </Text>
        </If>
      </div>
      <div className="flex flex-col items-end">
        <Text fontWeight="medium">
          USD {numberToCurrency(boldPrice)}
          {hasProratedPriceVar ? undefined : monthSuffixText} {type === 'SMS' ? '**' : ''}
        </Text>
        <If condition={priceIncrease !== 0 && type !== undefined}>
          <Text variant="text">
            {`${priceIncrease && priceIncrease > 0 ? '+' : '-'} USD ${numberToCurrency(
              Math.abs(priceIncrease ?? 0)
            )}`}
          </Text>
        </If>
        <If condition={hasProratedPriceVar}>
          <Text variant="text">
            {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.nextMonthPrice')} USD{' '}
            {numberToCurrency(price)}
            {monthSuffixText}
          </Text>
        </If>
        {/* Only 1 if is true at the same time */}
      </div>
    </>
  );
};

export default memo(PriceText);
