export const DomainIcon = () => {
  return (
    <svg height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19,11a8,8,0,0,1-8,8m8-8a8,8,0,0,0-8-8m8,8H3m8,8a8,8,0,0,1-8-8m8,8c1.473,0,2.667-3.582,2.667-8S12.473,3,11,3m0,16c-1.473,0-2.667-3.582-2.667-8S9.527,3,11,3M3,11a8,8,0,0,1,8-8"
        data-name="Path 3200"
        fill="none"
        id="Path_3200"
        stroke="#005adc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-2.5 -2.5)"
      />
    </svg>
  );
};
