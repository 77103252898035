import { memo } from 'react';

import { IconSvg } from '@/lib/v2/components/IconSvg';

import { MetadataOption, OptionExternal } from './Interfaces';

interface SelectedOptionsProps {
  indexedOptions?: Record<number, MetadataOption>;
  value: OptionExternal[];
  deleteOption: (index: number) => () => void;
}

const SelectedOptions = ({ value, deleteOption }: SelectedOptionsProps) => {
  const selectedOptions = value.map((option, index) => (
    <div
      key={option?.id}
      className="ml-0.5 inline-flex items-center rounded-full bg-blue-50 pl-2 pr-0.5 text-base font-medium text-emblue-primary"
    >
      {option?.metadata?.icon && (
        <span className="mr-2">
          <IconSvg svgComponent={option?.metadata?.icon} />
        </span>
      )}
      {option?.name}
      <button
        className="ml-0.5 inline-flex h-[16px] w-[17px] shrink-0 items-center justify-center rounded-full p-1 text-emblue-primary hover:bg-indigo-200 hover:text-emblue-primary-hover
                focus:bg-blue-400 focus:text-white focus:outline-none"
        onClick={deleteOption(index)}
      >
        <span className="sr-only">Remove small option</span>
        <svg className="h-[8px] w-[9px]" fill="none" stroke="currentColor" viewBox="0 0 8 8">
          <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
        </svg>
      </button>
    </div>
  ));

  return <>{selectedOptions}</>;
};

export default memo(SelectedOptions);
