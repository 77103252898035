/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _ from 'lodash';

import { FiltersType, ISegmentFilter } from '@/src/modules/ContactsModule/types/Segments';

//Arbitrary Ids in order to match the selects in filters names of editSegmentView
export const contactSystemActivityFieldsIDs: Record<string, number> = {
  lastOpening: 100,
  lastClick: 101,
  lastViral: 102,
  lastActivity: 103,
  lastWebsiteVisit: 104,
  lastWebTag: 105,
  lastWebItem: 106,
  lastPushSubscription: 107,
  lastPushSend: 108,
  lastSendSMS: 109,
} as const;

// IDs to match with V1
export const contactRelationFieldsIDs: Record<string, number> = {
  groups: 6,
  discardedGroup: 7,
  tags: 8,
  items: 24,
  geoLocation: 20,
  rank: 18,
  devices: 17,
  socialNetworks: 16,
  email: 21,
  channels: 26,
} as const;

// IDs to match with V1
export const contactActivityFieldsIDs: Record<string, number> = {
  sendings: 22,
  opens: 10,
  clicks: 11,
  bounces: 27,
} as const;

/**
 * Adapter of the ISegmentFilter to work in the segment editing view
 * add "key" to the filter and "id" to the field if doesn't exists
 * @returns filters that match FiltersType
 */
export const adaptV2SegmentToFront = (filters: ISegmentFilter[]): FiltersType => {
  const filtersType: FiltersType = (filters as FiltersType).map((f, i) => {
    if (!f.field!.id) {
      if (contactSystemActivityFieldsIDs[f.field!.name]) {
        f.field!.id = contactSystemActivityFieldsIDs[f.field!.name];
      } else if (contactRelationFieldsIDs[f.field!.name]) {
        f.field!.id = contactRelationFieldsIDs[f.field!.name];
      } else {
        f.field!.id = contactActivityFieldsIDs[f.field!.name];
      }
    }
    f.key = f.key ?? _.uniqueId((i * 3).toString());
    return f;
  });
  return filtersType;
};
