import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouterSkeleton from '@/src/modules/ApiManagerModule/components/RouterSkeleton';
import ApiManagerLayout from '@/src/modules/ApiManagerModule/layouts/ApiManagerLayout';

const ApiManagerMain = lazy(() => import('@/src/modules/ApiManagerModule/screens/ApiManagerMain'));

export const ApiManagerModuleRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route element={<ApiManagerLayout />} path="/*">
          <Route index element={<ApiManagerMain />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
