export const TooltipIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="tooltip" transform="translate(19948 10476)">
        <rect
          data-name="Rectángulo 4180"
          fill="none"
          height="20"
          id="Rectángulo_4180"
          transform="translate(-19948 -10476)"
          width="20"
        />
        <g data-name="Grupo 7902" id="Grupo_7902" transform="translate(0.089 -0.285)">
          <path
            d="M851.745,7.673a7.673,7.673,0,1,0-15.345,0v.085h.021c-.021.574.128,4.421,6.546,10.777a1.545,1.545,0,0,0,2.189.021l.021-.021c6.419-6.334,6.567-10.181,6.546-10.777h.021V7.673Z"
            fill="#7e8081"
            id="_Trazado_2"
            transform="translate(-20782.162 -10475.216)"
          />
          <path
            d="M9.373,6.562a.656.656,0,0,1,.656.656V10.5a.656.656,0,1,1-1.312,0V7.218A.656.656,0,0,1,9.373,6.562Zm0,7.217a.656.656,0,1,0-.656-.656A.656.656,0,0,0,9.373,13.779Z"
            data-name="Trazado 4612"
            fill="#fff"
            fillRule="evenodd"
            id="Trazado_4612"
            transform="translate(-19947.463 -10476.354)"
          />
        </g>
      </g>
    </svg>
  );
};
