export const ClickTableIcon = () => {
  return (
    <svg className="mr-2 flex h-4 w-4 self-center" viewBox="0 0 17.32 17.319">
      <path
        d="M13.734,13.734l-1.8,4.5-3.6-9.909,9.909,3.6Zm0,0,4.5,4.5M6.7,2.239l.7,2.61M4.849,7.4,2.238,6.7M12.788,3.87,10.877,5.782m-5.1,5.095-1.91,1.911"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-1.626 -1.627)"
      />
    </svg>
  );
};
