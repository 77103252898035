import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { useFiltersCampaign } from '@/modules/CampaignsModule/hooks/useFiltersCampaign';
import { EFilterKeysCampaign } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useFiltersDropdown = (onClose?: () => void) => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersCampaign();
  const [filtersCampaign, setFiltersCampaign] = useAtom(atomFiltersCampaign);
  const [selectedState, setSelectedState] = useState<number[]>([]);
  const [selectedStrategy, setSelectedStrategy] = useState<number[]>([]);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const countFilters =
    (filtersCampaign?.state?.length ?? 0) + (filtersCampaign?.strategy?.length ?? 0);
  const placeholderFilters =
    countFilters > 0
      ? `${t('TABLE_FILTER_BAR.filters')} (${countFilters})`
      : t('TABLE_FILTER_BAR.filters');

  const onApplyFilters = useCallback(() => {
    updateFilter(EFilterKeysCampaign.State, selectedState);
    setFiltersCampaign((prevFilters) => ({
      ...prevFilters,
      state: selectedState,
      strategy: selectedStrategy,
    }));
    setFiltersApplied(true);
    onClose?.();
  }, [onClose, selectedState, selectedStrategy, setFiltersCampaign, updateFilter]);

  return {
    placeholderFilters,
    setSelectedState,
    setSelectedStrategy,
    filtersApplied,
    onApplyFilters,
  };
};
