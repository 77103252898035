import { useEffect } from 'react';

import { InputSearch } from '@/lib/v2/components';
import { InputSearchProps } from '@/lib/v2/components/InputSearch/InputSearch';
import { useInputFilter } from '@/lib/v2/organisms/TableFilterBar/hooks/useInputFilter';

export interface InputSearchFilterProps extends InputSearchProps {
  id: string;
  reset?: boolean;
  defaultValue?: string;
}
const InputSearchFilter = ({
  onAction,
  defaultValue,
  id,
  placeholder,
  reset,
  ...props
}: InputSearchFilterProps) => {
  const { value, onChange, onClear, setValue } = useInputFilter({ defaultValue });

  useEffect(() => {
    if (reset) {
      setValue('');
    }
  }, [reset, setValue]);

  const handleOnAction = () => onAction?.(value);

  const handleOnClear = () => {
    onClear();
  };

  return (
    <div className="w-72">
      <InputSearch
        standard
        autoComplete="off"
        id={id}
        placeHolder={placeholder}
        value={value}
        onAction={handleOnAction}
        onChange={onChange}
        onClear={handleOnClear}
        {...props}
      />
    </div>
  );
};

export default InputSearchFilter;
