import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IUpdateItemsToPropsV2 } from '@/src/ContactsModule/interfaces/Interfaces';

import { useTag } from '@/modules/ContactsModule/hooks/useTag';

interface ITagsByContactsProps {
  selectedContacts: number[];
  selectAllResults: boolean;
  onAssignment: ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => Promise<void>;
  onLastRefresh?: () => void;
  allContactsCount: number;
}

export const useTagsByContacts = ({
  selectedContacts = [],
  selectAllResults,
  onAssignment,
  onLastRefresh,
  allContactsCount,
}: ITagsByContactsProps) => {
  const { t } = useTranslation();

  const { createTag } = useTag();

  const onApply = async ({ selectedItems, unselectedItems }: IUpdateItemsToPropsV2) => {
    await onAssignment({ selectedContacts, selectedItems, unselectedItems });
  };

  const handleOnCreate = useCallback(
    async (value: string) => {
      const tagCreated = await createTag(value);
      if (tagCreated) onLastRefresh?.();
    },
    [createTag, onLastRefresh]
  );

  const wordings = useMemo(() => {
    const contactCount = selectAllResults ? allContactsCount : selectedContacts.length;

    return {
      title: t('CONTACTS_ACTIONS_DROPDOWN.AddToTag'),
      reminder: selectAllResults ? t('CONTACT_TAG_MODAL.reminder') : undefined,
      search: {
        placeholder: t('CONTACT_TAG_MODAL.inputDescription'),
      },
      actions: {
        apply: t('CONTACT_TAG_MODAL.apply'),
        cancel: t('CONTACT_TAG_MODAL.cancel'),
      },
      selectedCount: contactCount,
      description: {
        selected: t('CONTACT_TAG_MODAL.contactsToAssign', { count: contactCount }),
        unselected: t('CONTACT_TAG_MODAL.contactsToUnassign', { count: contactCount }),
      },
      labelInputCreate: t('ITEM_SEARCH_MODAL.Tag'),
    };
  }, [t, selectAllResults, selectedContacts.length, allContactsCount]);

  return {
    wordings,
    onApply,
    handleOnCreate,
  };
};
