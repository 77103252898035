export const MicrophoneIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_889_1470)">
        <path
          d="M10.3382 11.423C11.7216 11.423 12.8299 10.3063 12.8299 8.92297L12.8382 3.92297C12.8382 2.53964 11.7216 1.42297 10.3382 1.42297C8.95488 1.42297 7.83822 2.53964 7.83822 3.92297V8.92297C7.83822 10.3063 8.95488 11.423 10.3382 11.423ZM14.7549 8.92297C14.7549 11.423 12.6382 13.173 10.3382 13.173C8.03822 13.173 5.92155 11.423 5.92155 8.92297H4.50488C4.50488 11.7646 6.77155 14.1146 9.50488 14.523V17.2563H11.1716V14.523C13.9049 14.123 16.1716 11.773 16.1716 8.92297H14.7549Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_889_1470">
          <rect fill="white" height="20" transform="translate(0.450195)" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
