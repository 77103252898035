export const SmsActionIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="-1 -2 24 22"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3169_6581)">
        <path
          d="M0.600098 5.04V16.8067C0.600098 17.7563 1.38371 18.5301 2.34617 18.5301C2.74285 18.5301 3.12978 18.3926 3.44193 18.1464L3.52322 18.0825L3.5915 18.0057L7.05112 14.1912C7.07388 14.1912 7.09339 14.1912 7.1129 14.1912H16.7374C19.6118 14.1912 21.4001 12.7043 21.4001 10.3158V5.04C21.4001 2.44366 19.3094 0.333328 16.7374 0.333328H5.26278C2.69083 0.333328 0.600098 2.44366 0.600098 5.04ZM1.8942 5.04C1.8942 3.1439 3.39966 1.60592 5.25953 1.60592H16.7374C18.5973 1.60592 20.1027 3.1439 20.1027 5.04V10.3158C20.1027 12.2055 18.5973 12.9186 16.7374 12.9186H7.1129C6.88529 12.9186 6.66419 12.9282 6.45284 12.941L2.6258 17.1616C2.54126 17.2288 2.44371 17.2607 2.34942 17.2607C2.11856 17.2607 1.89746 17.0785 1.89746 16.8099V5.04H1.8942Z"
          fill="#0056B8"
        />
        <path
          d="M11.0568 7.6907H5.99413C5.5942 7.6907 5.26904 8.00406 5.26904 8.38775C5.26904 8.77145 5.5942 9.0848 5.99413 9.0848H11.0568C11.4567 9.0848 11.7818 8.77145 11.7818 8.38775C11.7818 8.00406 11.4567 7.6907 11.0568 7.6907Z"
          fill="#0056B8"
        />
        <path
          d="M16.5265 5.18388H5.99804C5.5981 5.18388 5.27295 5.49724 5.27295 5.88093C5.27295 6.26463 5.5981 6.57798 5.99804 6.57798H16.5265C16.9264 6.57798 17.2516 6.26463 17.2516 5.88093C17.2516 5.49724 16.9264 5.18388 16.5265 5.18388Z"
          fill="#0056B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3169_6581">
          <rect fill="white" height="18.2" transform="translate(0.600098 0.333328)" width="20.8" />
        </clipPath>
      </defs>
    </svg>
  );
};
