import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { useFiltersRecurrent } from '@/modules/CampaignsModule/hooks/useFiltersCampaignRecurrent';
import { EFilterKeysRecurrentMessage } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputDate = () => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersRecurrent();
  const filtersRecurrent = useAtomValue(atomFiltersRecurrent);

  const id = 'date-campaigns-action';
  const placeholder = t('RECURRENT_MESSAGE.FILTERS.date');

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersRecurrent?.startDate ?? null,
    filtersRecurrent?.endDate ?? null,
  ]);

  useEffect(() => {
    if (!filtersRecurrent || Object.keys(filtersRecurrent).length === 0) {
      setDateRange([null, null]);
    }
  }, [filtersRecurrent]);

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        event[0] &&
          event[1] &&
          updateFilter(EFilterKeysRecurrentMessage.Date, [event[0], event[1]]);
      }
    },
    [updateFilter]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    id,
    dateRange,
    placeholder,
    handleOnClickDate,
    resetDateRange,
  };
};
