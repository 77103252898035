import classNames from 'classnames';
import { memo, ReactNode, useEffect, useState } from 'react';
import { FieldArray } from 'react-hook-form';

import { Button, Collapse, Text } from '@/lib/v2/components';

type Color = 'gray' | 'transparent';
export interface AccordionProps {
  startOpen?: boolean;
  title?: string;
  children?: ReactNode;
  noShadow?: boolean;
  color?: Color;
  id?: string;
  btnIcon?: JSX.Element | ReactNode;
  btnText?: string;
  btnActive?: boolean | string | FieldArray;
  onClickButton?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  title,
  children,
  startOpen = false,
  noShadow = false,
  color = 'gray',
  id,
  btnIcon,
  btnText,
  btnActive,
  onClickButton,
  onClose,
}: AccordionProps): JSX.Element => {
  const [isOpened, setOpened] = useState<boolean>(startOpen);

  const handleOpen = () => {
    setOpened((prevOpened) => !prevOpened);
  };

  const classes = classNames(
    'rounded my-0 rounded-lg border',
    { shadow: !noShadow },
    { 'bg-[#FAFBFC]': color === 'gray' },
    { 'bg-transparent': color === 'transparent' }
  );

  const handleButtonClick = () => {
    !isOpened && handleOpen();
    onClickButton && onClickButton();
  };

  useEffect(() => {
    if (!isOpened && onClose) {
      onClose();
    }
  }, [isOpened, onClose]);

  const buttonStyle = btnActive ? 'text-emblue-primary' : 'text-[#364365]';
  const buttonProps = btnActive ? {} : { standard: true };
  const idButton = `edit-${(title ?? '').toLowerCase().replace(/\s+/g, '-')}`;

  return (
    <div className={classes} data-testid="accordion-component" id={id}>
      <div
        className={`flex h-[52px] flex-row items-center px-4 py-1 transition-all ${
          isOpened ? 'border-b-[1px] border-[#D3DDEA]' : 'border-b-[1px] border-transparent'
        }`}
      >
        <div
          aria-hidden="true"
          className={'flex flex-row items-center gap-4'}
          role="button"
          onClick={handleOpen}
        >
          <span
            className={`relative h-full w-[10px] ${
              !isOpened ? 'rotate-180' : 'rotate-0'
            } transition-all`}
          >
            <span className="relative top-0.5 inline-block h-[10px] w-[10px] rotate-45 border-l-2 border-t-2 border-emblue-primary bg-transparent"></span>
          </span>
          <Text id={title} variant="text">
            {title}
          </Text>
        </div>
        {btnText && (
          <span className="ml-auto flex items-center">
            <Button
              outline
              iconLeft={btnIcon}
              id={idButton}
              onClick={handleButtonClick}
              {...buttonProps}
            >
              <p className={`hidden ${buttonStyle} 2xl:block`}>{btnText}</p>
            </Button>
          </span>
        )}
      </div>
      <Collapse isOpened={isOpened}>
        <div className={`${!btnText ? 'p-4' : ''}`}>{children}</div>
      </Collapse>
    </div>
  );
};

export default memo(Accordion);
