export const EmailActionIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="-1 -2 20 20"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3169_6897)">
        <path
          d="M17.7997 11.8327C17.7684 11.9457 17.7428 12.0587 17.7087 12.1687C17.4643 12.9161 16.8191 13.3824 16.009 13.3969C15.6821 13.4027 15.3552 13.3969 15.0284 13.3969C10.7108 13.3969 6.39039 13.3969 2.07282 13.3969C1.29685 13.3969 0.705636 13.0754 0.373078 12.3512C0.270753 12.1311 0.211063 11.8704 0.20822 11.6271C0.199693 8.54223 0.199693 5.45447 0.205378 2.36671C0.20822 1.35581 0.998401 0.602695 2.03871 0.599798C3.44285 0.596902 4.84414 0.599798 6.24827 0.599798C9.48005 0.599798 12.709 0.599798 15.9379 0.599798C16.7992 0.599798 17.4558 1.05746 17.7059 1.82795C17.7428 1.93802 17.7684 2.05099 17.7969 2.16395V11.8327H17.7997ZM1.29969 2.61003V2.81279C1.29969 5.71516 1.29969 8.61464 1.29969 11.517C1.29969 12.0094 1.55551 12.2759 2.03871 12.2788C6.67747 12.2788 11.3191 12.2788 15.9578 12.2788C16.4382 12.2788 16.6968 12.0123 16.6968 11.517C16.6968 8.61464 16.6968 5.71516 16.6968 2.81279C16.6968 2.75775 16.6912 2.70272 16.6883 2.61872C16.6059 2.68823 16.5434 2.73748 16.4837 2.78961C14.3775 4.57101 12.2741 6.35531 10.1651 8.13671C9.44879 8.74209 8.5506 8.74788 7.84 8.1454C5.93277 6.532 4.02553 4.91571 2.1183 3.29941C1.8568 3.07638 1.59246 2.85624 1.29969 2.60713M1.99892 1.72947C2.06145 1.7874 2.10124 1.82505 2.14388 1.85981C4.26713 3.65569 6.38755 5.45447 8.5108 7.25035C8.85473 7.54291 9.1475 7.54291 9.49427 7.25035C11.626 5.44578 13.7578 3.64121 15.8896 1.83954C15.9237 1.81057 15.9635 1.79319 16.0033 1.77291C15.9919 1.75843 15.9806 1.74395 15.9692 1.72947H1.99892Z"
          fill="#004dbc"
        />
      </g>
      <defs>
        <clipPath id="clip0_3169_6897">
          <rect fill="white" height="12.8" transform="translate(0.199951 0.599976)" width="17.6" />
        </clipPath>
      </defs>
    </svg>
  );
};
