import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { ImmediateDeliveryIcon, ScheduledDeliveryIcon } from '@/lib/v2/icons/general';

import { atomDisableEditAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { ImmediateDeliveryForm } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/components/ImmediateDeliveryForm';
import { SchedulingDeliveryForm } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/components/SchedulingDeliveryForm';
import { DELIVERY_OPTIONS } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useDeliveryOptions = () => {
  const { t } = useTranslation();
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const immediateDelivery = {
    id: DELIVERY_OPTIONS.immediate,
    icon: <IconSvg height="40px" svgComponent={<ImmediateDeliveryIcon />} width="40px" />,
    title: t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.immediateDelivery'),
    description: t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.immediateDeliverySubtitle'),
    children: <ImmediateDeliveryForm disabled={disableEditAction} />,
  };

  const scheduledDelivery = {
    id: DELIVERY_OPTIONS.scheduled,
    icon: <IconSvg height="40px" svgComponent={<ScheduledDeliveryIcon />} width="40px" />,
    title: t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.scheduledDelivery'),
    description: t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.scheduledDeliverySubtitle'),
    children: <SchedulingDeliveryForm disabled={disableEditAction} />,
  };

  return { immediateDelivery, scheduledDelivery };
};
