import { Text, Tooltip } from '@/lib/v2/components';
import { TooltipIcon } from '@/lib/v2/icons/solid';

interface TooltipAccountLimitProps {
  title?: string;
  subtitle?: string;
  email?: string;
}
const TooltipAccountLimit = ({ title, subtitle, email }: TooltipAccountLimitProps) => {
  return (
    <Tooltip
      isClickable
      isDark
      noArrow
      alignment="start"
      content={
        <div className="flex flex-col gap-4">
          <Text alignment="center" color="white" variant="text-sm">
            {title}
          </Text>
          <div className="flex w-72 flex-row justify-center gap-1">
            <Text color="white" variant="text-sm">
              {subtitle}
            </Text>
            <Text
              link
              color="white"
              href={`mailto:${email}`}
              textDecoration="underline"
              variant="text-sm"
            >
              {email}
            </Text>
          </div>
        </div>
      }
      position="top"
    >
      <TooltipIcon />
    </Tooltip>
  );
};

export default TooltipAccountLimit;
