import { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown, SearchInputBar } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { ActivityCleanFilterIcon, ActivityFilterIcon, ChannelsTableIcon } from '@/lib/icon';
import { Button } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import {
  ChannelTypeString,
  StatusTypeString,
} from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { inboxFilters } from '@/src/infrastructure/Protocol/Inbox/Inbox.mocks';

export interface IInboxFilterProps {
  onSearch?: (search: string) => void;
  filterChannel?: Dispatch<SetStateAction<ChannelTypeString | undefined>>;
  filterStatus?: Dispatch<SetStateAction<StatusTypeString | undefined>>;
  onReset?: () => void;
  resetSearch?: () => void;
  filterChannelSelected?: string | undefined;
  filterStatusSelected?: string | undefined;
  cleanBoxRef?: RefObject<CleanBoxRef>;
}

export const InboxFilters = ({
  onSearch,
  resetSearch,
  onReset,
  cleanBoxRef,
  filterChannel,
  filterStatus,
  filterChannelSelected,
  filterStatusSelected,
}: IInboxFilterProps) => {
  const { t } = useTranslation(['inbox']);

  return (
    <>
      <Flex alignment="start" itemAlignment="center">
        <Flex withGap alignment="start">
          <div className="w-[250px]">
            <SearchInputBar
              ref={cleanBoxRef}
              withButton
              cleanBox={resetSearch}
              placeHolder={t('INBOX.FILTERS.SEARCH.inputBarPlaceholder')}
              onAction={(ev) => onSearch && onSearch(ev)}
            />
          </div>
          <div className="w-auto">
            <DropDown
              overflowWidth
              btnOnAction={(itemFilterList: string[]) => {
                filterChannel && filterChannel(itemFilterList[0] as ChannelTypeString);
              }}
              btnText={t('INBOX.FILTERS.APPLY')}
              checkBox={true}
              iconHead={<ChannelsTableIcon color="#004DBC" />}
              itemList={inboxFilters?.channels?.map((x) => ({
                id: String(x.id),
                title: String(x.name),
              }))}
              titleHead={t('INBOX.FILTERS.CHANNEL.label')}
              tooltip={t('INBOX.FILTERS.CHANNEL.tooltip')}
              totalSelectedChk={filterChannelSelected ? 1 : 0}
              valuesSelected={filterChannelSelected ? [filterChannelSelected] : []}
            />
          </div>
          <div className="w-auto">
            <DropDown
              overflowWidth
              btnOnAction={(itemFilterList: string[]) => {
                filterStatus && filterStatus(itemFilterList[0] as StatusTypeString);
              }}
              btnText={t('INBOX.FILTERS.APPLY')}
              checkBox={true}
              iconHead={<ActivityFilterIcon color="#004DBC" />}
              itemList={inboxFilters?.activities?.map((x) => ({
                id: String(x.id),
                title: String(x.name),
              }))}
              titleHead={t('INBOX.FILTERS.STATUS.label')}
              tooltip={t('INBOX.FILTERS.STATUS.tooltip')}
              totalSelectedChk={filterStatusSelected ? 1 : 0}
              valuesSelected={filterStatusSelected ? [filterStatusSelected] : []}
            />
          </div>
          <div className="z-10 w-auto">
            <Button
              standard
              className="!px-2.5"
              iconLeft={<ActivityCleanFilterIcon color="#004DBC" />}
              id="clear-filters-button"
              tooltip={t('INBOX.FILTERS.CLEAN_FILTER')}
              onClick={onReset}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
};
