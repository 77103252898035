export const EyeIcon = () => {
  return (
    <svg
      height="16.043"
      viewBox="0 0 20.936 16.043"
      width="20.936"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1.234 -3.75)">
        <path
          d="M11.5,13.991A2.5,2.5,0,1,0,9,11.5,2.5,2.5,0,0,0,11.5,13.991Z"
          fill="#004dbc"
          transform="translate(0.206 0.276)"
        />
        <path
          d="M1.321,11.234a10.943,10.943,0,0,1,20.762-.007,1.717,1.717,0,0,1,0,1.082,10.943,10.943,0,0,1-20.761.007,1.713,1.713,0,0,1,0-1.082Zm15.486.538a5.1,5.1,0,1,1-5.1-5.1,5.1,5.1,0,0,1,5.1,5.1Z"
          fill="#004dbc"
          fillRule="evenodd"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
};
