import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import {
  EFilterKeysAction,
  EFilterKeysCampaign,
  IFiltersTableCampaign,
  IPager,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { clearObjectFalsy } from './clearObjectFalsy';
import { convertQueryParamToNumberArray } from './convertQueryParamToNumberArray';
import { getDateFromTimestamp } from './getDateFromTimestamp';

export const getParamsCampaigns = (): IFiltersTableCampaign => {
  const queryParams = new URLSearchParams(window.location.search);

  const search = queryParams.get(EFilterKeysCampaign.Search) || '';
  const state = convertQueryParamToNumberArray(queryParams.get(EFilterKeysCampaign.State));
  const strategy = convertQueryParamToNumberArray(queryParams.get(EFilterKeysCampaign.Strategy));
  const startDate = getDateFromTimestamp(queryParams.get(EFilterKeysCampaign.StartDate));
  const endDate = getDateFromTimestamp(queryParams.get(EFilterKeysCampaign.EndDate));
  const dateRange = startDate && endDate ? { startDate, endDate } : {};

  const filters: IFiltersTableCampaign = {
    search,
    state,
    strategy,
    ...dateRange,
  };

  return clearObjectFalsy(filters);
};

export const getParamsActions = (): IFiltersTableCampaign => {
  const queryParams = new URLSearchParams(window.location.search);

  const search = queryParams.get(EFilterKeysAction.Search) || '';
  const status = convertQueryParamToNumberArray(queryParams.get(EFilterKeysAction.Status));
  const channel = convertQueryParamToNumberArray(queryParams.get(EFilterKeysAction.Channel));
  const startDate = getDateFromTimestamp(queryParams.get(EFilterKeysAction.StartDate));
  const endDate = getDateFromTimestamp(queryParams.get(EFilterKeysAction.EndDate));
  const dateRange = startDate && endDate ? { startDate, endDate } : {};

  const filters: IFiltersTableCampaign = {
    search,
    status,
    channel,
    ...dateRange,
  };

  return clearObjectFalsy(filters);
};

export const getParamsPager = (): IPager => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    page: Number(queryParams.get('page')) || defaultPager.page,
    limit: Number(queryParams.get('limit')) || defaultPager.limit,
  };
};
