/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAtom } from 'jotai';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Option, Select } from '@/lib/v2/components';

import { isLoadingUploadAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';
import { FormInput } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

interface IUploadUrl {
  closeModal: MouseEventHandler<HTMLButtonElement>;
  urlUploader: (url: string, code: string) => Promise<void>;
}

const UploadUrl = ({ closeModal, urlUploader }: IUploadUrl) => {
  const { t } = useTranslation(['editorHtml']);

  const [isLoading, setIsLoading] = useAtom(isLoadingUploadAtom);

  const handleSubmitData = useCallback(
    async (formData: FormInput) => {
      setIsLoading(true);
      const {
        url,
        code: { value },
      } = formData;
      await urlUploader(url, value as string);
    },
    [urlUploader]
  );

  const { control, handleSubmit } = useForm<FormInput>({
    defaultValues: { code: { name: 'UTF-8', id: 2, value: 'UTF-8' } },
  });

  const options: Option[] = [
    { name: 'UTF-7', id: 1, value: 'UTF-7' },
    { name: 'UTF-8', id: 2, value: 'UTF-8' },
    { name: 'UTF-32', id: 3, value: 'UTF-32' },
    { name: 'ISO-8859-1', id: 4, value: 'ISO-8859-1' },
    { name: 'Windows 1251', id: 5, value: 'Windows 1251' },
    { name: 'Windows 1252', id: 6, value: 'Windows 1252' },
    { name: 'Macintosh', id: 7, value: 'Macintosh' },
  ];

  const RULES = useMemo(
    () => ({
      url: {
        required: t('URL_NOT_FOUND_ALERT.desc'),
      },
    }),
    [t]
  );

  return (
    <>
      <div className="flex w-full items-center justify-center py-10 align-middle">
        <div className="flex w-3/4 flex-col items-start justify-center gap-4 align-middle">
          <form className="w-full" onSubmit={handleSubmit(handleSubmitData)}>
            <div className="mb-3 flex w-full flex-col items-start">
              <Select
                control={control}
                id="origin-html-select"
                label={t('origin')}
                name="code"
                options={options}
              />
            </div>
            <div className="flex w-full flex-col items-start">
              <Input control={control} id="url-input" label="URL" name="url" rules={RULES.url} />
            </div>
            <div className="flex w-full items-center justify-end gap-2 pt-8">
              <Button outline onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button primary isLoading={isLoading} type="submit">
                {t('confirm')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadUrl;
