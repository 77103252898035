import { CalendarActions } from './components/CalendarActions';
import { CalendarHeader } from './components/CalendarHeader';
import { DaysOfWeek } from './components/DaysOfWeek';
import { useCalendar } from './useCalendar.hooks';

const CalendarMain = () => {
  const {
    events: { nextMonth, previousMonth, handleToday },
    state: { currentMonth, currentDate },
  } = useCalendar();

  return (
    <div className="flex h-full flex-col pb-8">
      <CalendarHeader
        currentMonth={currentMonth}
        onNextMonth={nextMonth}
        onPrevMonth={previousMonth}
        onToday={handleToday}
      />
      <main>
        <header className="grid grid-cols-7 gap-px overflow-hidden rounded-t-md border border-[#EEF0F2] bg-[#EEF0F2] text-center">
          <DaysOfWeek />
        </header>
        <div className="text-xs relative flex border border-[#EEF0F2] bg-[#EEF0F2]">
          <CalendarActions currentDate={currentDate} currentMonth={currentMonth} />
        </div>
      </main>
    </div>
  );
};

export default CalendarMain;
