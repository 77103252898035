import { ExclamationIcon } from '@heroicons/react/solid';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DeleteIconSolid } from '@/lib/icon';
import { Button, Card, IconSvg, Select, Switch, Text } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';

import DynamicFieldComponent from './DynamicFieldComponent';

import { useFilter } from '@/modules/ContactsModule/hooks/useFilter';
import { RestrictedPair } from '@/modules/ContactsModule/hooks/useFiltersValidator';
import { IOptionFilter, ISegmentFilter } from '@/modules/ContactsModule/types/Segments';

export interface FilterProps {
  isLoading: boolean;
  filterOptions: IOptionFilter[];
  daysOptions: Option[];
  index: number;
  onDelete?: (index: number) => void;
  operator?: 'and' | 'or';
  filterValue: Partial<ISegmentFilter & { key?: number | string }>;
  restrictedFilter?: RestrictedPair;
}

const Filter = ({
  filterOptions,
  daysOptions,
  index,
  onDelete,
  operator,
  isLoading,
  filterValue,
  restrictedFilter,
}: FilterProps) => {
  const { t } = useTranslation();

  const showAlertRestriction = restrictedFilter
    ? restrictedFilter?.filterTwo === filterValue.field?.name
    : false;

  const {
    valueGroupOption,
    groupOptions,
    handleOnChangeGroup,
    filterOptionValuesWithDisabled,
    conditional,
    conditionalOptionsWithDisabledOptions,
    filter,
    handleOnChangeConditional,
    handleOnChangeFilter,
    handleOnChangeLastDays,
    handleOnChangeSwitch,
    lastDays,
    lastDaysDisabled,
  } = useFilter({
    index,
    isLoading,
    daysOptions,
    filterOptions,
    filterValue,
  });
  return (
    <Card noBorder noShadow color="gray">
      <div className="flex w-full flex-col gap-5 md:flex-row">
        <div className="flex w-full max-w-full flex-none gap-5 md:w-2/6">
          <div className="w-1/2">
            <Select
              id="group-select"
              isLoading={isLoading}
              label={t('SEGMENT_FILTERS.filters')}
              options={groupOptions ?? []}
              orderBy="asc"
              placeholder={t('SEGMENT_FILTERS.FORM.placeholderGroup')}
              searchPlaceholder={t('SEGMENT_FILTERS.searchPlaceholder')}
              value={valueGroupOption}
              onChange={handleOnChangeGroup}
            />
          </div>
          <div className="w-1/2">
            <Select
              withSearch
              disabled={valueGroupOption ? false : true}
              id="field-select"
              isLoading={isLoading}
              label={t('SEGMENT_FILTERS.field')}
              options={filterOptionValuesWithDisabled ?? []}
              orderBy="asc"
              searchPlaceholder={t('SEGMENT_FILTERS.searchPlaceholder')}
              value={filter}
              onChange={handleOnChangeFilter}
            />
          </div>
        </div>
        {filter && (
          <>
            <div className="w-full flex-none md:w-[180px]">
              <Select
                disabled={showAlertRestriction}
                id="conditional-select"
                label={t('SEGMENT_FILTERS.Conditional')}
                options={conditionalOptionsWithDisabledOptions ?? []}
                orderBy="asc"
                value={conditional}
                onChange={handleOnChangeConditional}
              />
            </div>
            <div className="w-full flex-auto md:w-1/5">
              <DynamicFieldComponent
                disabled={showAlertRestriction}
                fieldType={filter?.fieldType}
                index={index}
                isBeetWeen={filterValue.condition === 'bt'}
                isGreaterThan={filterValue.condition === 'gte'}
                isSmallerThan={filterValue.condition === 'lte'}
                lastDays={filterValue.condition === 'last-days'}
                options={filter.optionsValues}
              />
            </div>
            {(filter.andSwitch || operator) && (
              <div className="mt-6 flex">
                <Switch
                  withLabels
                  checked={operator === 'and'}
                  id="operator-switch"
                  leftLabel={t('SEGMENT_FILTERS.OR')}
                  rightLabel={t('SEGMENT_FILTERS.AND')}
                  onChange={handleOnChangeSwitch}
                />
              </div>
            )}
            {filter.daysSelect && !lastDaysDisabled && (
              <div className="flex-none">
                <Select
                  id="last-days-select"
                  label={t('SEGMENT_FILTERS.registeredTheLastDays')}
                  options={daysOptions}
                  value={lastDays}
                  onChange={handleOnChangeLastDays}
                />
              </div>
            )}
          </>
        )}
        <div className="mt-5 flex-none items-center">
          <Button
            standard
            iconLeft={<IconSvg svgComponent={<DeleteIconSolid />} />}
            id="delete-filter-button"
            onClick={() => onDelete?.(index)}
          />
        </div>
      </div>
      {showAlertRestriction && (
        <div className="flex w-full items-center gap-2 pt-2">
          <IconSvg
            fillColor="passive"
            height="20px"
            svgComponent={<ExclamationIcon />}
            width="20px"
          />
          <Text variant="text-sm">
            <Trans
              components={{ bold: <strong /> }}
              i18nKey="SEGMENT_FILTERS.alertRestriction"
              values={{
                firstFilterSelected: t(
                  `SEGMENTS_CONDITIONS.FILTERS.${restrictedFilter?.filterOne}`
                ),
                lastSelectedFilter: t(`SEGMENTS_CONDITIONS.FILTERS.${restrictedFilter?.filterTwo}`),
              }}
            />
          </Text>
        </div>
      )}
    </Card>
  );
};

export default memo(Filter);
