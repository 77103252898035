import { ISenderActivity, ISenderScore } from '@/modules/DashboardsModule/interfaces/Dashboard';

//Cspell:disable
export const senderScoreMock: ISenderScore[] = [
  {
    fecha: '2024-07-14',
    score: 7.53,
  },
  {
    fecha: '2024-07-11',
    score: 7.31,
  },
  {
    fecha: '2024-07-09',
    score: 7.07,
  },
  {
    fecha: '2024-07-07',
    score: 7.03,
  },
];

export const senderActivityMock: ISenderActivity[] = [
  {
    dias: 7,
    enviados: 2,
    acciones: 1,
    opens: 1,
    clicks: 1,
  },
  {
    dias: 15,
    enviados: 3,
    acciones: 2,
    opens: 2,
    clicks: 2,
  },
  {
    dias: 30,
    enviados: 4,
    acciones: 3,
    opens: 3,
    clicks: 3,
  },
  {
    dias: 180,
    enviados: 5,
    acciones: 4,
    opens: 4,
    clicks: 4,
  },
];
