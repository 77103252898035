import classNames from 'classnames';
import {
  ButtonHTMLAttributes,
  FC,
  forwardRef,
  memo,
  MouseEventHandler,
  ReactNode,
  Ref,
} from 'react';

import Spinning from './Spinning';

import './Button.tailwind.css';

export type SizeButton = 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Changes the size of the button, giving it more or less padding */
  size?: SizeButton;
  /** Determines the color of the component */
  primary?: boolean;
  /** Determines the color of the component */
  secondary?: boolean;
  /** Determines the color of the component */
  tertiary?: boolean;
  /** Determines the color of the component */
  destructive?: boolean;
  /** Accepts a component or a string as a child */
  children?: JSX.Element | string;
  /** Action click button */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Accepts an icon that is positioned to the left */
  iconLeft?: JSX.Element | ReactNode;
  /** Accepts an icon that is positioned to the Right */
  iconRight?: JSX.Element | ReactNode;
  /** Indicates the type of button */
  typeButton?: 'button' | 'reset' | 'submit';
  /** Takes the full width of the container */
  fullWidth?: boolean;
  /** Button with transparent background and border and text according to its variant color */
  outline?: boolean;
  gray?: boolean;
  link?: boolean;
  roundedFull?: boolean;
  /** place a title prop hover */
  tooltip?: string;
  /** disable the button  */
  disabled?: boolean;
  /** button standard, with background white */
  standard?: boolean;
  /** Loading state button, spinner */
  isLoading?: boolean;
  /** Add transparent border & background, no text color for only icon */
  onlyIcon?: boolean;
  classNameContainer?: string;
}

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      size = 'medium',
      primary = true,
      secondary,
      tertiary,
      destructive,
      iconLeft,
      iconRight,
      children,
      onClick,
      link,
      standard,
      fullWidth = false,
      outline = false,
      gray = false,
      tooltip,
      roundedFull,
      disabled,
      typeButton = 'button',
      isLoading = false,
      onlyIcon = false,
      id = '',
      className,
      classNameContainer,
      /** Takes the rest of the native properties of an Button HTML (name, style, onmousedown, ondblclick, ...etc ) */
      ...restOfProps
    },
    ref?: Ref<HTMLButtonElement>
  ) => {
    const classes = classNames(
      'eb-button',
      `eb-button--${size}`,
      {
        'eb-button--primary': primary,
        'eb-button--secondary': secondary,
        'eb-button--tertiary': tertiary,
        'eb-button--destructive': destructive,
        'eb-button--outline--primary': primary && outline,
        'eb-button--outline--secondary': secondary && outline,
        'eb-button--outline--tertiary': tertiary && outline,
        'eb-button--outline--gray': gray && outline,
        'eb-button--outline--destructive': destructive && outline,
        'w-full': fullWidth,
        'eb-button--link': link,
        'eb-button--standard': standard,
        'opacity-25 cursor-not-allowed': disabled,
        'cursor-not-allowed': isLoading,
        '!rounded-full': roundedFull,
        'eb-button--only-icon': onlyIcon,
      },
      className
    );

    const classesChildrenContainer = classNames('flex flex-row items-center', {
      'gap-2': children,
    });

    return (
      <div className={classNames('flex', { 'w-full': fullWidth }, classNameContainer)}>
        <button
          ref={ref}
          className={classes}
          data-testid={`button-component`}
          disabled={disabled || isLoading}
          id={id}
          title={tooltip || ''}
          type={typeButton}
          onClick={onClick}
          {...restOfProps}
        >
          <div className={classesChildrenContainer}>
            {isLoading && <Spinning />}
            {!isLoading && iconLeft}
            {typeof children === 'string' ? <span>{children}</span> : children}
            {!isLoading && iconRight}
          </div>
        </button>
      </div>
    );
  }
);

export default memo(Button);
