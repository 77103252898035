import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import {
  EFilterKeysRecurrentMessage,
  IFilterValueTypesRecurrentMessage,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import {
  atomFiltersRecurrent,
  atomPagerCampaignsRecurrent,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

const DEFAULT_PAGER = { page: defaultPager.page };

export const useFiltersRecurrent = () => {
  const [filters, setFilters] = useAtom(atomFiltersRecurrent);
  const [pager, setPager] = useAtom(atomPagerCampaignsRecurrent);

  const updateFilter = useCallback(
    (key: keyof IFilterValueTypesRecurrentMessage, value: any) => {
      if (key === EFilterKeysRecurrentMessage.Date) {
        const [startDate, endDate] = value as Date[];

        const clearedFilters = clearObjectFalsy({
          ...filters,
          startDate: startDate,
          endDate: endDate,
        });

        pager && setPager({ limit: pager?.limit, ...DEFAULT_PAGER });
        setFilters({ ...clearedFilters });
      } else {
        const clearedFilters = clearObjectFalsy({ ...filters, [key]: value });

        pager && setPager({ limit: pager?.limit, ...DEFAULT_PAGER });
        setFilters({ ...clearedFilters });
      }
    },
    [filters, pager, setFilters, setPager]
  );

  const resetFilters = useCallback(() => {
    setPager({ limit: pager?.limit || defaultPager.limit, ...DEFAULT_PAGER });
    setFilters({});
  }, [pager?.limit, setFilters, setPager]);

  return { updateFilter, resetFilters };
};
