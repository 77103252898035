import { useTranslation } from 'react-i18next';

import { Alert, Checkbox, Text } from '@/lib/v2/components';

import { useEmailTouchRules } from './useEmailTouchRules.hooks';

import { TRANSLATE_CREATE_EMAIL_ACTION } from '@/modules/CampaignsModule/constants';
import { IEmailFormInfo, IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface ITouchRulesInputProps {
  form: IFormProps<IEmailFormInfo>;
  disabled?: boolean;
}
const TouchRulesInput = ({ form, disabled = false }: ITouchRulesInputProps) => {
  const { t } = useTranslation();

  const {
    events: { handleChangeTouchRules },
    state: { touchRules, showTouchRulesContent },
  } = useEmailTouchRules({ setValue: form.setValue });

  const { control } = form;
  return (
    <>
      <Checkbox
        checked={showTouchRulesContent}
        control={control}
        disabled={disabled}
        id="create-action-touch-rules"
        label={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.applyTouchRules`)}
        name="touchRules"
        onChange={handleChangeTouchRules}
      />
      {!showTouchRulesContent ? (
        <Text
          className="start-8 ml-5 mt-2"
          fontWeight="medium"
          id="create-action-touch-rules-label"
          variant="text"
        >
          {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.criteriaTouchRules`)}
          <a
            className="ml-1 text-[#004DBC]"
            href="https://help.embluemail.com/acciones/touch-rules/"
            id="create-action-touch-rules-link"
            rel="noreferrer"
            target="_blank"
          >
            {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.moreInformationAbout`)}
          </a>
        </Text>
      ) : (
        <>
          <Text
            className="start-8 my-2"
            fontWeight="medium"
            id="create-action-touch-rules-label"
            variant="text"
          >
            {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.applyCriteriaTouchRules`, {
              emails: touchRules?.emails,
              days: touchRules?.days,
              messages_text:
                Number(touchRules?.emails) > 1 || 0
                  ? t(`${TRANSLATE_CREATE_EMAIL_ACTION}.messages`)
                  : t(`${TRANSLATE_CREATE_EMAIL_ACTION}.message`),
              days_text:
                Number(touchRules?.days) > 1 || 0
                  ? t(`${TRANSLATE_CREATE_EMAIL_ACTION}.days`)
                  : t(`${TRANSLATE_CREATE_EMAIL_ACTION}.day`),
            })}
          </Text>
          <Alert
            className="mb-1"
            description={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.applyHelpCriteriaTouchRules`)}
            type="info"
          />
        </>
      )}
    </>
  );
};

export default TouchRulesInput;
