import { Transition } from '@headlessui/react';
import { Fragment, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Card, IconSvg, Input, Text } from '@/lib/v2/components';
import { CloseIcon, EditPencilIcon } from '@/lib/v2/icons/outline';

type RenameItemProps = {
  id?: string | number;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  placeholder?: string;
  rightClick: number;
  topClick: number;
  value?: string;
  isLoading?: boolean;
  isLoadingExternal?: boolean;
  onActionButton?: (value: string) => void;
  actionButtonText: string;
  rules?: {
    input: object;
  };
};

interface FormInput {
  input: string;
}

const RenameItem = ({
  id = 'rename-item',
  isOpen,
  onClose,
  title,
  rightClick,
  topClick,
  placeholder,
  value,
  isLoading,
  isLoadingExternal,
  actionButtonText,
  onActionButton,
  rules,
}: RenameItemProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, setValue, reset } = useForm<FormInput>({
    mode: 'onChange',
  });

  const RULES = {
    input: {
      required: t('RENAME_ITEM.required'),
    },
  };

  const onAction = handleSubmit((values) => {
    const { input } = values;
    if (value && value === input) {
      onClose();
      return;
    }
    onActionButton?.(input);
  });

  const right = `${rightClick + 8}px`;
  const top = `${topClick}px`;

  useEffect(() => {
    if (value) setValue('input', value);
  }, [setValue, value, reset]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Transition
      appear
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={isOpen}
    >
      <div className="fixed z-10 w-[380px]" style={{ left: right, top }}>
        <Card>
          <div className="flex w-full flex-col items-center gap-3 text-center">
            <button
              className="absolute right-4 top-4 z-10 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2
                  focus:ring-emblue-primary focus:ring-offset-2"
              type="button"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close</span>
              <IconSvg svgComponent={<CloseIcon />} />
            </button>
            <div className="flex w-full flex-col">
              <div className="flex items-center gap-2">
                <EditPencilIcon />
                <Text>{title}</Text>
              </div>
              <Input
                autoComplete="off"
                control={control}
                id={`${String(id)}-input`}
                name="input"
                placeHolder={placeholder}
                rules={rules?.input ?? RULES.input}
              />
            </div>
            <div className="flex w-full">
              <Button
                id={`${String(id)}-submit`}
                isLoading={isLoading || isLoadingExternal}
                onClick={onAction}
              >
                <>{actionButtonText}</>
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Transition>
  );
};

export default memo(RenameItem);
