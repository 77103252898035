export const ECommerceIcon = () => {
  return (
    <svg className="w-full" viewBox="0 0 28 20">
      <g id="e-commerce" transform="translate(-2 -6)">
        <path
          d="M29,22H3a1,1,0,0,1-1-1V7A1,1,0,0,1,3,6H29a1,1,0,0,1,1,1V21A1,1,0,0,1,29,22ZM4,20H28V8H4Z"
          data-name="Path 3144"
          fill="#9c59df"
          id="Path_3144"
        />
        <path
          d="M16,26a1,1,0,0,1-1-1V22a1,1,0,0,1,2,0v3A1,1,0,0,1,16,26Z"
          data-name="Path 3145"
          fill="#9c59df"
          id="Path_3145"
        />
        <path
          d="M19,26H13a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Zm0-10H13a1,1,0,0,1-.929-.629L10.323,11H8A1,1,0,0,1,8,9h3a1,1,0,0,1,.929.629l.148.371H20a1,1,0,0,1,.97,1.242l-1,4A1,1,0,0,1,19,16Zm-5.323-2h4.542l.5-2H12.877Z"
          data-name="Path 3146"
          fill="#9c59df"
          id="Path_3146"
        />
        <circle
          cx="1.5"
          cy="1.5"
          data-name="Ellipse 1532"
          fill="#9c59df"
          id="Ellipse_1532"
          r="1.5"
          transform="translate(12 16)"
        />
        <circle
          cx="1.5"
          cy="1.5"
          data-name="Ellipse 1533"
          fill="#9c59df"
          id="Ellipse_1533"
          r="1.5"
          transform="translate(17 16)"
        />
      </g>
    </svg>
  );
};
