import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Accordion,
  Button,
  DateInput,
  IconSvg,
  Input,
  InputTextArea,
  Select,
  toast,
} from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Option } from '@/lib/v2/components/Select/Select';
import { EditPencilIcon } from '@/lib/v2/icons/outline';

import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';
import { ContactsTextareaModal } from '@/src/ContactsModule/components/ContactsModals';
import {
  CustomFieldValue,
  DataType,
  FieldType,
  IContactCustom,
  IContactCustomFields,
  IContactFormResponse,
  OptionType,
} from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import { dirtyValues, translateField } from '@/src/ContactsModule/utils/formModifiers';
import {
  IValidation,
  restrictedSpaceBar,
  ValidateProfileForm,
} from '@/src/ContactsModule/utils/validateProfileForm';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import { convertStringToDate } from '@/src/utils/Date/dateUtils';

export const ContactsProfileDetailInformation = ({
  contactDetailInfo,
  countries,
  permanentEmblue,
  title,
  sortFields,
  onUpdate,
}: {
  contactDetailInfo: IContactCustom;
  countries?: Option[];
  permanentEmblue?: boolean;
  title?: string;
  sortFields?: boolean;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [user] = useUserData();
  const service = useService();
  const { emailId } = useParams();

  const {
    control,
    setFocus,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    formState: { errors, dirtyFields, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      contactCustomFields: sortFields
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          contactDetailInfo.contactCustomFields
            .sort((e: any, b: any) => e.name.localeCompare(b.name))
            .filter((e) => e.readOnly !== true)
        : contactDetailInfo.contactCustomFields.filter((e) => e.readOnly !== true),
    },
  });
  const [isReadonly, setIsReadonly] = useState(true);
  const [errorCount, setErrorCount] = useState(0);
  const [responseForm, setResponseForm] = useState<IContactFormResponse>({
    statusOk: false,
    statusError: false,
  });

  const { fields, update } = useFieldArray({ control, name: 'contactCustomFields' });

  let counterCFsNotNulls = 0;

  const onCancel = () => {
    setIsReadonly(!isReadonly);
    reset();
  };

  useEffect(() => {
    if (fields) {
      reset({ contactCustomFields: fields });
    }
  }, [isSubmitSuccessful]);

  const transformFieldValue = (field: IContactCustomFields): string | null => {
    const transformationMap = {
      [OptionType.RADIO]: (value: CustomFieldValue) => {
        if ('id' in (value as Record<string, unknown>)) {
          return (value as { id: number }).id.toString();
        }
        return null;
      },
      [OptionType.COMBO]: (value: CustomFieldValue) => {
        if (Array.isArray(value)) {
          return (value as Array<{ name: string }>).map((item) => item.name).join(';');
        }
        return null;
      },
      [DataType.DATE]: (value: string | undefined) => {
        if (!value) {
          return null;
        }
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    };

    if (
      (field.optionType === OptionType.RADIO || field.optionType === OptionType.COMBO) &&
      field.value !== null &&
      typeof field.value === 'object'
    ) {
      const transformFunction = transformationMap[field.optionType];
      return transformFunction ? transformFunction(field.value) : null;
    }

    if (field.dataType === DataType.DATE && field.value !== null) {
      const transformFunction = transformationMap[field.dataType];
      return transformFunction ? transformFunction(field.value) : null;
    }

    return null;
  };

  const onSubmit = async (data: IContactCustom) => {
    data.contactCustomFields.forEach((field: IContactCustomFields) => {
      const transformedValue = transformFieldValue(field);
      if (transformedValue !== null) {
        field.value = transformedValue;
      }
    });

    const contactCustomFields = transformContactCustomFields(data);
    contactCustomFields &&
      contactCustomFields.map((value: Object, index: number) => {
        update(index, data.contactCustomFields[index]);
      });

    const query: { emailId: number; customData: {} } = {
      emailId: Number(emailId),
      customData: contactCustomFields,
    };
    const response =
      service &&
      ((await service.updateContactEditCustomFields(query)) as IContactFormResponse | undefined);
    setIsReadonly(response && response.statusError ? false : !isReadonly);
    response && setResponseForm(response);
    alertContactFormStatus(response, errorCount);
    showExtendedModal && setShowExtendedModal(false);
    onUpdate();
  };

  const isSaveDisabled =
    Object.values(dirtyFields).length === 0 && responseForm && responseForm.statusError !== true;

  const transformContactCustomFields = (data: IContactCustom) => {
    const { contactCustomFields }: IContactCustom = dirtyValues(
      dirtyFields,
      data as unknown as []
    ) as IContactCustom;
    return (contactCustomFields || [])
      .map((element, index) => {
        if (Object.keys(element).length !== 0) {
          return {
            field: translateFieldTo_V1(data.contactCustomFields[index].name),
            value: data.contactCustomFields[index].value,
            fieldType: data.contactCustomFields[index].fieldType,
          };
        }
        return {};
      })
      .filter((element) => Object.keys(element).length !== 0 || false);
  };

  const translateFieldTo_V1 = (field: string): string => {
    return translateField[field] || field;
  };

  const alertContactFormStatus = (res: IContactFormResponse | undefined, errCount: number) => {
    res &&
      res.statusOk &&
      toast({
        title: t('CONTACTS_PROFILE.doneTitle'),
        body: t('CONTACTS_PROFILE.doneSending'),
        variant: 'success',
        autoCloseDelay: 5000,
      });

    errCount > 2 &&
      res &&
      res.statusError &&
      (setErrorCount(errCount + 1),
      toast({
        title: t('CONTACTS_PROFILE.alertTitle'),
        body: t('CONTACTS_PROFILE.errorSending'),
        variant: 'error',
        autoCloseDelay: 5000,
      }));

    res && res.statusError && setErrorCount(errCount + 1);
  };

  const onAccept = () => {
    const fieldsWatch = watch('contactCustomFields');
    fieldsWatch[idExtended.current]['value'] = valueExtended;
    setValue(`contactCustomFields.${idExtended.current}.value`, valueExtended, {
      shouldDirty: true,
    });
    setShowExtendedModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRead = (inputName?: any) => {
    setIsReadonly(!isReadonly);
    setFocus(inputName);
    resetField(inputName);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCancel = () => {
    setIsReadonly(true);
  };

  const convertOptions = (defaultValues: string): Option[] => {
    const arr = defaultValues?.split(';');
    return arr?.map((element: string) => {
      return {
        id: element,
        name: element,
      };
    });
  };

  const [showExtendedModal, setShowExtendedModal] = useState(false);
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(toggleRef, () => setShowExtendedModal(false));

  const idExtended = useRef(0);
  const [valueExtended, setValueExtended] = useState<string>();

  const openExtendedById = (id: number, value: string) => {
    idExtended.current = id;
    setValueExtended(value);
    setShowExtendedModal(true);
  };

  const validateFormRule: IValidation = ValidateProfileForm();
  const getValidationRule = (fieldName: string) => {
    return validateFormRule[fieldName];
  };

  const handleKeyDownSpaceBar = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  const shouldDisableField = (fieldToCheck: string) => {
    const disabledFieldNames = ['nps', 'recency', 'frequency', 'amount', 'eCommerceClassification'];
    return disabledFieldNames.includes(fieldToCheck);
  };

  const convertValueToOption = (value: string, index: number): Option => {
    return {
      id: index,
      name: value,
    };
  };

  const createOptionsFromValues = (values: string | string[]): Option[] => {
    if (values === '') {
      return [];
    }

    const arrayOfValues = Array.isArray(values) ? values : values.split(';');

    return arrayOfValues.map((value, index) => {
      return {
        id: index,
        name: value,
      };
    });
  };

  const createMultiOptionFromElement = (
    element: IContactCustomFields
  ): { id: number; name: string }[] => {
    const result: { id: number; name: string }[] = [];

    if (element.optionType === OptionType.COMBO && element.defaultValues) {
      const defaultValues: string[] = element.defaultValues.split(';');

      defaultValues.forEach((value: string, index: number) => {
        result.push({
          id: index,
          name: value,
        });
      });
    }

    return result;
  };

  const userLanguage = user?.language ?? '';

  return (
    <div className="static w-full">
      <form>
        <Accordion
          // eslint-disable-next-line react/no-children-prop
          children={
            <div className="bg-white px-4 py-2">
              <div className="visible-scrollbar max-h-[32rem] overflow-auto pb-4 pr-2">
                <Flex column noGrow withGap gapSize="medium">
                  {fields &&
                    fields.map((element: IContactCustomFields, i: number) => {
                      const comboOptionType = element.optionType === OptionType.COMBO;
                      const radioOptionType = element.optionType === OptionType.RADIO;
                      const alphanumericDataType = element.dataType === DataType.ALPHANUMERIC;
                      const dateDataType = element.dataType === DataType.DATE;
                      const extendedFieldType = element.fieldType === FieldType.EXTENDED;
                      const isPermanentField = element.emblueField === permanentEmblue;
                      counterCFsNotNulls = element.name ? ++counterCFsNotNulls : counterCFsNotNulls;

                      const selectedValue = watch(`contactCustomFields.${i}.value`) as
                        | string
                        | Option;
                      const selectedOption =
                        typeof selectedValue === 'object'
                          ? selectedValue
                          : convertValueToOption(selectedValue, i);
                      const multiSelectedValues =
                        typeof selectedValue === 'object'
                          ? selectedValue
                          : comboOptionType && createOptionsFromValues(selectedValue);
                      const multiSelectOptions = createMultiOptionFromElement(element);

                      const defaultOptions = element.defaultValues
                        ? comboOptionType
                          ? multiSelectOptions
                          : convertOptions(element.defaultValues)
                        : countries || [];

                      const fieldName = t(`CONTACT_INFORMATION.${element.name}`);
                      const displayName = permanentEmblue
                        ? fieldName.charAt(0) + fieldName.slice(1)
                        : element.name.charAt(0) + element.name.slice(1).toLowerCase();

                      const fieldDate = convertStringToDate(selectedValue);

                      return (
                        <>
                          {isPermanentField &&
                            element.optionType === OptionType.NONE &&
                            element.dataType !== DataType.DATE && (
                              <div key={i} className="w-11/12">
                                <Input
                                  key={element.id}
                                  control={control}
                                  disabled={shouldDisableField(element.name)}
                                  error={errors?.['contactCustomFields']?.[i]?.['value'] && true}
                                  id={element.name}
                                  label={displayName}
                                  message={errors?.['contactCustomFields']?.[i]?.['value']?.message}
                                  name={`contactCustomFields.${i}.value`}
                                  placeHolder={'-'}
                                  readOnly={showExtendedModal ? showExtendedModal : isReadonly}
                                  rules={getValidationRule(element.name) || {}}
                                  seeMore={
                                    isPermanentField && alphanumericDataType && extendedFieldType
                                  }
                                  type={element.dataType === DataType.NUMERIC ? 'number' : 'text'}
                                  onChange={(e) =>
                                    setValue(`contactCustomFields.${i}.value`, e.target.value, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    })
                                  }
                                  onClick={(e) =>
                                    !isReadonly &&
                                    isPermanentField &&
                                    alphanumericDataType &&
                                    extendedFieldType &&
                                    (e.target instanceof HTMLInputElement ||
                                      e.target instanceof HTMLTextAreaElement)
                                      ? openExtendedById(i, e.target.value)
                                      : null
                                  }
                                  onKeyDown={
                                    dateDataType
                                      ? (e) => e.preventDefault()
                                      : restrictedSpaceBar[element.name]
                                      ? handleKeyDownSpaceBar
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          {isPermanentField && dateDataType && (
                            <div className="w-11/12">
                              {fieldDate && (
                                <DateInput
                                  control={control}
                                  date={fieldDate}
                                  dateFormat={userLanguage === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                  label={displayName}
                                  maxDate={new Date()}
                                  readOnly={isReadonly}
                                  onChange={(selectedOption) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                    setValue(`contactCustomFields.${i}.value`, selectedOption, {
                                      shouldDirty: true,
                                    });
                                  }}
                                />
                              )}
                              {!fieldDate && isReadonly ? (
                                <Input
                                  label={displayName}
                                  placeholder={'-'}
                                  readOnly={isReadonly}
                                />
                              ) : (
                                !fieldDate && (
                                  <DateInput
                                    control={control}
                                    date={fieldDate}
                                    dateFormat={userLanguage === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                                    label={displayName}
                                    maxDate={new Date()}
                                    readOnly={isReadonly}
                                    onChange={(selectedOption) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                      setValue(`contactCustomFields.${i}.value`, selectedOption, {
                                        shouldDirty: true,
                                      });
                                    }}
                                  />
                                )
                              )}
                            </div>
                          )}
                          {((isPermanentField && radioOptionType) ||
                            (isPermanentField && comboOptionType)) && (
                            <div className="w-11/12">
                              <Select
                                key={element.id}
                                control={control}
                                disabled={shouldDisableField(element.name)}
                                id={element.name}
                                label={displayName}
                                multiSelect={comboOptionType}
                                name={`contactCustomFields.${i}.value`}
                                options={defaultOptions}
                                placeholder={t('SELECT.selectItem')}
                                readOnly={isReadonly}
                                searchPlaceholder={t('CONTACTS_FILTERS.search')}
                                value={
                                  comboOptionType
                                    ? multiSelectedValues || undefined
                                    : selectedOption || undefined
                                }
                                withSearch={
                                  radioOptionType && element.dataType === DataType.COUNTRY
                                }
                                onChange={(selectedOption) => {
                                  if (selectedOption && selectedOption.value) {
                                    setValue(
                                      `contactCustomFields.${i}.value`,
                                      selectedOption.value,
                                      {
                                        shouldDirty: true,
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  {
                    // eslint-disable-next-line eqeqeq
                    counterCFsNotNulls == 0 && (
                      <span className="pt-2 opacity-50">
                        {t('CONTACTS_PROFILE.noCustomFields')}
                      </span>
                    )
                  }
                </Flex>
              </div>
              {!isReadonly ? (
                <div className="py-3 pr-3">
                  <Flex withGap alignment="end">
                    <Button key="cancel" secondary id="btnCancel" onClick={onCancel}>
                      {t('CONTACTS_PROFILE.cancel')}
                    </Button>
                    <Button
                      key="save"
                      disabled={isSaveDisabled}
                      id="btnSubmit"
                      isLoading={isSubmitting}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('CONTACTS_PROFILE.save')}
                    </Button>
                  </Flex>
                </div>
              ) : null}
            </div>
          }
          btnActive={!user.deniedPermissions?.deniedContactsPermissions ? !isReadonly : null}
          btnIcon={
            <IconSvg
              strokeColor={isReadonly ? 'secondary' : 'primary'}
              svgComponent={<EditPencilIcon />}
            />
          }
          btnText={t('CONTACTS_PROFILE.edit')}
          startOpen={permanentEmblue}
          title={title}
          onClickButton={() => handleRead()}
          onClose={() => handleCancel()}
        />
        {showExtendedModal && (
          <div className="w-11/12">
            <ContactsTextareaModal
              // eslint-disable-next-line react/no-children-prop
              children={
                <InputTextArea
                  focusClick={true}
                  name={'contactCustomFieldsTextArea'}
                  value={valueExtended}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    setValueExtended(event.target.value)
                  }
                />
              }
              apply={onAccept}
              cancel={() => setShowExtendedModal(false)}
              toggleRef={toggleRef}
            />
          </div>
        )}
      </form>
    </div>
  );
};
