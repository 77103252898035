import { IContactCustom } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';

interface ContactData {
  [key: string]: string;
}

const extractContactData = (fields: IContactCustom): ContactData => {
  const data: ContactData = {};

  for (const field of fields.contactCustomFields) {
    if (field.name === 'gender' && field.value !== 'F' && field.value !== 'M') {
      data[field.name] = 'invalidData';
    } else {
      data[field.name] = field.value || '';
    }
  }

  return data;
};

export default extractContactData;
