import { memo, ReactChild, useEffect } from 'react';

import { DataCard } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';

import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

export type Description = {
  icon: ReactChild;
  description: string;
};

export type ProgressCardProps = {
  title: string;
  dataCard: Description[];
  info?: {
    descriptionInfo: string;
    href: string;
  };
  setHiddenProgressCard?: () => void;
  step: number;
  percentage: number;
  status: loadingStatus;
};

export const ProgressCard = memo(({ progressCard }: { progressCard: ProgressCardProps }) => {
  useEffect(() => {
    if (!Array.isArray(progressCard.dataCard) || progressCard.dataCard.length === 0) return;

    const length = progressCard.dataCard.length - 1;

    if (progressCard.step === length && progressCard.setHiddenProgressCard) {
      progressCard.setHiddenProgressCard();
    }
  }, [progressCard]);

  return (
    <div className="w-full">
      <Flex column withGap gapSize="medium">
        <Text className="mb-2" variant="headline">
          {progressCard.title}
        </Text>
        <DataCard
          dataList={progressCard.dataCard}
          percentage={progressCard.percentage}
          status={progressCard.status}
          step={progressCard.step}
        />
        <a href={progressCard.info?.href}>{progressCard.info?.descriptionInfo}</a>
      </Flex>
    </div>
  );
});
