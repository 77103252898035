import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import { toast } from '@/lib/v2/components';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

interface ItemPreview {
  showItemPreview: (itemName: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemProps: any;
  openModal: boolean;
  closeModal: () => void;
}

export const useItemPreview = (): ItemPreview => {
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState<string>('');
  const [itemProps] = useEmblue(ServiceMethods.getItemProps, { itemName: selectedItemName });

  const [openModal, setOpenModal] = useState(false);

  const showItemPreview = (itemName: string) => {
    setSelectedItemName(itemName);
    setPreviewEnabled(true);
  };

  useEffect(() => {
    if (previewEnabled && itemProps && !Object.keys(itemProps).length) {
      showNotification();
      setSelectedItemName('');
    }
    if (previewEnabled && itemProps && Object.keys(itemProps).length) {
      setOpenModal(true);
      setSelectedItemName('');
    }
  }, [itemProps, previewEnabled]);

  const showNotification = () => {
    toast({
      title: t('ITEMS_NOTIFICATION.error.title'),
      body: t('ITEMS_NOTIFICATION.error.body'),
      variant: 'error',
      autoCloseDelay: 3000,
    });
  };

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  return { showItemPreview, itemProps, openModal, closeModal };
};
