import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/lib/v2/components/Layout/Stack';

export type PaginationProps = {
  functions: {
    canPreviousPage: boolean;
    previousPage: () => void;
    canNextPage: boolean;
    nextPage: () => void;
    pageCount: number;
    pageIndex: number;
    pageOptions: number[];
    pageSize: number;
    setPageSize: (pageSize: number) => void;
  };
  resetScroll?: boolean;
};

export const Pagination = ({ resetScroll, ...props }: PaginationProps) => {
  const { t } = useTranslation();

  const {
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    pageIndex,
    pageOptions,
    pageSize,
    setPageSize,
  } = props.functions;

  const resetScrollPosition = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Flex withGap className="relative z-[2]">
      <Flex noGrow>
        <span className="mr-1"> {t('PAGINATION.displayRows')} </span>
        <select
          className="cursor-pointer rounded border border-[#D3DDEA] bg-[#FAFBFD] px-1 py-2 hover:bg-[#F6FAFF]"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pSize) => (
            <option key={pSize} value={pSize}>
              {pSize}
            </option>
          ))}
        </select>
      </Flex>
      <Flex noGrow withGap>
        <span className="font-medium">{pageIndex + 1}</span>
        <span> {t('PAGINATION.of')} </span>
        <span className="font-medium">{pageOptions.length}</span>
      </Flex>
      <div>
        <nav aria-label="Pagination" className="inline-flex -space-x-px rounded-md shadow-sm">
          <button
            className="inline-flex cursor-pointer items-center rounded-l-md border border-[#D3DDEA] bg-[#FAFBFD] px-1 py-2 hover:bg-[#F6FAFF] disabled:opacity-25"
            disabled={!canPreviousPage}
            title={t('PAGINATION.previousPage')}
            onClick={() => {
              previousPage();
              resetScroll && pageSize > 10 && resetScrollPosition();
            }}
          >
            <span className="sr-only relative"> {t('PAGINATION.previousPage')} </span>
            <ChevronLeftIcon aria-hidden="true" className="size-6 fill-[#96A6CE]" />
          </button>
          <button
            className="inline-flex cursor-pointer items-center rounded-r-md border border-[#D3DDEA] bg-[#FAFBFD] px-1 py-2 hover:bg-[#F6FAFF] disabled:opacity-25"
            disabled={!canNextPage}
            title={t('PAGINATION.nextPage')}
            onClick={() => {
              nextPage();
              resetScroll && pageSize > 10 && resetScrollPosition();
            }}
          >
            <span className="sr-only relative"> {t('PAGINATION.nextPage')} </span>
            <ChevronRightIcon aria-hidden="true" className="size-6 fill-[#96A6CE]" />
          </button>
        </nav>
      </div>
    </Flex>
  );
};
