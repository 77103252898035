import { useCallback, useEffect, useState } from 'react';

import { useTablePagination } from '@/lib/components/Table/useTablePagination';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { Note, NoteFilters, NotesResponse } from '@/src/modules/ContactsModule/types/Notes';

export const useNotes = (contactId: string, filters: NoteFilters) => {
  const [notes, setNotes] = useState<NotesResponse>();
  const { totalPages, changeTablePage, pageIndex, pageSize } = useTablePagination(notes?.length);

  const indexOfLastNote = pageIndex * pageSize;
  const indexOfFirstNote = indexOfLastNote - pageSize;

  const service = useService();
  const getListNotes = useCallback(async () => {
    const response = await service?.getNotesContactData(contactId, filters);
    setNotes(response);
  }, [contactId, filters, service]);

  const createNote = useCallback(
    async (note: Note): Promise<boolean> => {
      const response = await service?.createNote(note);
      if (response) return true;
      return false;
    },
    [service]
  );

  const deleteNote = useCallback(
    async (noteId: number): Promise<boolean> => {
      const response = await service?.deleteNoteContactData(noteId);
      if (response) return response.success;
      return false;
    },
    [service]
  );

  const editNote = useCallback(
    async (note: Note): Promise<boolean> => {
      const response = await service?.editNoteContactData(note);
      if (response) return response.success;
      return false;
    },
    [service]
  );

  useEffect(() => {
    void getListNotes();
  }, [getListNotes]);

  return {
    isLoading: !notes,
    totalPages,
    changeTablePage,
    notes: notes?.slice(indexOfFirstNote, indexOfLastNote),
    getListNotes,
    createNote,
    deleteNote,
    editNote,
  };
};
