export const SmsIcon = () => {
  return (
    <svg
      className="h-14 w-14 sm:h-14 sm:w-14 md:h-14 md:w-14 lg:h-16 lg:w-16"
      viewBox="0 0 65.616 57.354"
    >
      <g transform="translate(0 0.001)">
        <g transform="translate(0 0)">
          <path
            className="fill-[#fc7e00]"
            d="M53.487,0H12.131A12.363,12.363,0,0,0,0,12.581V55.7a1.615,1.615,0,0,0,2.626,1.288L9.746,51.2c.772-.044,1.568-.076,2.385-.076H53.487A12.339,12.339,0,0,0,65.616,38.59V12.581A12.361,12.361,0,0,0,53.487,0"
            transform="translate(0 -0.001)"
          />
          <line
            data-name="Line 119"
            fill="none"
            id="Line_119"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.242"
            transform="translate(14.841 18.164)"
            x2="37.073"
          />
          <line
            data-name="Line 120"
            fill="none"
            id="Line_120"
            stroke="#efece4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.242"
            transform="translate(14.841 34.185)"
            x2="17.823"
          />
          <line
            data-name="Line 121"
            fill="none"
            id="Line_121"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.242"
            transform="translate(14.841 26.175)"
            x2="37.073"
          />
        </g>
      </g>
    </svg>
  );
};

export const SmsImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-orange/25'}>
    <SmsIcon />
  </div>
);
