import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

import { useItemPreview } from '@/src/ContactsModule/presentation/Screens/ItemsMain/useItemPreview';

export interface ItemsStateContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemProps: any | undefined;
  openModal: boolean;
}

interface ItemsMutationContext {
  showItemPreview: Function;
  closeModal: () => void;
}

export const StateItemsContext = createContext<ItemsStateContext | undefined>(undefined);
export const MutationItemsContext = createContext<ItemsMutationContext | undefined>(undefined);

interface ItemsProviderProps {
  children: ReactNode;
}

const ItemsProvider: FC<ItemsProviderProps> = ({ children }): JSX.Element => {
  const { showItemPreview, itemProps, openModal, closeModal } = useItemPreview();

  const memoizedMutations = useMemo<ItemsMutationContext>(
    () => ({
      showItemPreview,
      closeModal,
    }),
    [showItemPreview, closeModal]
  );

  return (
    <MutationItemsContext.Provider value={memoizedMutations}>
      <StateItemsContext.Provider value={{ itemProps, openModal }}>
        {children}
      </StateItemsContext.Provider>
    </MutationItemsContext.Provider>
  );
};

export const useStateItemsContext = (): ItemsStateContext =>
  useGetContext<ItemsStateContext>(StateItemsContext);
export const useMutationItemsContext = (): ItemsMutationContext =>
  useGetContext<ItemsMutationContext>(MutationItemsContext);

const useGetContext = <T,>(contextName: React.Context<T | undefined>): T => {
  const context = useContext<T | undefined>(contextName);
  if (!context) {
    throw new Error('ItemsProvider is required');
  }
  return context;
};

export default ItemsProvider;
