export const FolderIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0807 4.80005H4.31977C3.81199 4.8017 3.32565 4.99214 2.96747 5.32957C2.60928 5.667 2.40852 6.12385 2.40923 6.5999L2.3999 17.4002C2.40131 17.8772 2.60403 18.3342 2.96377 18.6715C3.32351 19.0087 3.81102 19.1987 4.31977 19.2H19.68C20.1888 19.1987 20.6763 19.0087 21.0361 18.6715C21.3957 18.3342 21.5985 17.8772 21.5999 17.4002V8.39973C21.5985 7.92279 21.3957 7.46576 21.0361 7.12851C20.6763 6.79126 20.1888 6.60121 19.68 6.5999H15.8403H12.0006L10.0807 4.80005Z"
        fill="#0056B8"
      />
    </svg>
  );
};
