import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/lib/components/Modal';
import { closeModal } from '@/lib/components/Modal/Modal';
import { Button, Checkbox, Input, InputTextArea, Select, Text, toast } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import { Option } from '@/lib/v2/components/Select/Select';

import { ICategory, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { TagsMergeParams } from '@/src/infrastructure/interfaces/IV1RequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const ButtonsSection = ({
  onAction,
  onClose,
}: {
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  return (
    <div className="pt-4">
      <Flex withGap alignment="end" gapSize="medium">
        <Button outline onClick={onClose}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_TAG_MODAL.close')}
          </div>
        </Button>
        <Button typeButton="submit" onClick={onAction}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_TAG_MODAL.confirm')}
          </div>
        </Button>
      </Flex>
    </div>
  );
};
export type FormInput = {
  tag: string;
  category: Option;
  checkbox: boolean;
  source?: Option;
  content?: string;
  coding?: Option;
  url?: string;
};

type Modes = 'create' | 'edit' | 'merge';
interface ICreateAndEditTagModalProps {
  tagsCategories?: ICategory[];
  editProps?: IEditProps;
  mode?: Modes;
  lastRefreshIncrement?: Function;
  tagsForMerge?: ITag[];
  wordings?: {
    title?: string;
  };
}
interface IEditProps {
  tagId: number;
  tagName: string;
  tagCategory?: string;
  matchContent: boolean;
  contentType?: number;
  content?: string;
  encoding?: number;
  url?: string;
}

enum ContentType {
  TEXT = 1,
  URL = 2,
}

export const CreateAndEditTagModal = ({
  tagsCategories,
  editProps,
  lastRefreshIncrement,
  wordings,
  mode = 'create',
  tagsForMerge,
}: ICreateAndEditTagModalProps) => {
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      wordings?.title ||
      (editProps ? t('MANAGE_TAG_MODAL.edit.title') : t('MANAGE_TAG_MODAL.title')),
    [wordings, t, editProps]
  );

  const listSource = [
    {
      id: ContentType.TEXT,
      name: t('MANAGE_TAG_MODAL.sourceText'),
    },
    {
      id: ContentType.URL,
      name: t('MANAGE_TAG_MODAL.sourceUrl'),
    },
  ];

  const listCategoryAux = [
    {
      name: t('TAGS_TABLE.noCategory'),
      id: '0',
    },
  ];
  const listEncoding = [
    {
      name: 'ISO-8859-1',
      id: '1',
    },
    {
      name: 'Windows-1251',
      id: '2',
    },
    {
      name: 'Windows-1252',
      id: '3',
    },
    {
      name: 'UTF-16',
      id: '4',
    },
    {
      name: 'Macintosh',
      id: '5',
    },
    {
      name: 'UTF-8',
      id: '6',
    },
  ];
  const formatTag =
    tagsCategories &&
    tagsCategories.map((x) => ({
      name: x.name === 'noCategory' ? t('TAGS_TABLE.noCategory') : x.name,
      id: String(x.id),
    }));

  const [textArea, setTextArea] = useState(editProps?.contentType === 2 ? false : true);
  const service = useService();
  const [categoryTags, setCategoryTags] = useState(formatTag);

  const { control, watch, handleSubmit } = useForm<FormInput>({
    defaultValues: {
      tag: editProps?.tagName ?? '',
      category: categoryTags?.find((category) => category.name === editProps?.tagCategory) ?? {
        id: 0,
        name: t('TAGS_TABLE.noCategory'),
      }, // idCategory OR 0 => Without category
      checkbox: editProps?.contentType ? true : false,
      source: editProps?.contentType
        ? listSource.find((s) => s.id === editProps.contentType)
        : listSource[0],
      content: editProps ? editProps.content : undefined,
      coding: editProps?.encoding
        ? listEncoding.find((e) => Number(e.id) === editProps.encoding)
        : listEncoding[0],
      url: editProps?.url ?? undefined,
    },
  });
  const { checkbox, category } = watch();

  useEffect(() => {
    const formatCategories =
      tagsCategories &&
      tagsCategories.map((x) => ({
        name: x.name === 'noCategory' ? t('TAGS_TABLE.noCategory') : x.name,
        id: String(x.id),
      }));
    setCategoryTags(formatCategories);
  }, [tagsCategories, t]);

  const RULES = {
    tag: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    category: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    checkbox: {
      required: false,
    },
    source: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    content: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    coding: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    url: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    onActionCreate: async (value: FormInput) => {
      if (!value.checkbox) {
        value.url = '';
        value.content = '';
        value.source = undefined;
        value.coding = undefined;
      }
      const responseCreate = await service?.createTag(
        value.tag,
        Number(value.category.id),
        Number(value.source?.id ?? 0),
        value.content,
        true,
        value.url,
        Number(value.coding?.id ?? 0)
      );
      const OkBody = t('MANAGE_TAG_MODAL.NOTIFICATION.OkBody');
      let alertBody = t('MANAGE_TAG_MODAL.NOTIFICATION.KoBody');
      let result = false;

      /* cspell:disable */
      if (responseCreate['notification']['content'].id_notificacion === 'CREAR_TAG_OK') {
        result = true;
      }
      if (responseCreate['notification']['content'].id_notificacion === 'CREAR_TAG_KO') {
        alertBody = t('MANAGE_TAG_MODAL.NOTIFICATION.KoBody');
      }
      if (responseCreate['notification']['content'].id_notificacion === 'TAG_EXISTENTE_OK') {
        alertBody = t('MANAGE_TAG_MODAL.NOTIFICATION.ExistingBody');
      }
      if (responseCreate['notification']['content'].id_notificacion === 'TAG_DUPLICADO') {
        alertBody = t('MANAGE_TAG_MODAL.NOTIFICATION.DuplicateBody');
      }
      /* cspell:enable */

      result && lastRefreshIncrement && lastRefreshIncrement();
      closeModal();
      toast({
        title: result
          ? t('MANAGE_TAG_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_TAG_MODAL.NOTIFICATION.AlertTitle'),
        body: result ? OkBody : alertBody,
        variant: result ? 'success' : 'error',
      });
    },
    onActionEdit: async (value: FormInput) => {
      if (editProps === undefined) {
        return;
      }
      if (!value.checkbox) {
        value.url = '';
        value.content = '';
        value.source = undefined;
        value.coding = undefined;
      }

      const result = await service?.editTag({
        tagId: editProps.tagId,
        tagName: value.tag,
        tagCategoryId: Number(value.category.id),
        content: value.content,
        encoding: Number(value.coding?.id ?? 0),
        tagUrl: value.url,
        contentType: Number(value.source?.id ?? 0),
      });

      result?.statusOk && lastRefreshIncrement && lastRefreshIncrement();

      const bodySuccess = t('MANAGE_TAG_MODAL.edit.NOTIFICATION.OkBody');
      const bodyTagRepeated = t('MANAGE_TAG_MODAL.edit.NOTIFICATION.ExistingBody');
      const bodyContentError = t('MANAGE_TAG_MODAL.edit.NOTIFICATION.contentErrorBody');
      const bodyUnknownError = t('MANAGE_TAG_MODAL.edit.NOTIFICATION.KoBody');

      toast({
        title: result?.statusOk
          ? t('MANAGE_TAG_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_TAG_MODAL.NOTIFICATION.AlertTitle'),
        body: result?.statusOk
          ? bodySuccess
          : result?.errorType.repeatedName
          ? bodyTagRepeated
          : result?.errorType.contentError
          ? bodyContentError
          : bodyUnknownError,
        variant: result?.statusOk ? 'success' : 'error',
      });
      closeModal();
    },
    onActionMerge: async (value: FormInput) => {
      if (mode !== 'merge' || tagsForMerge?.length === 0) return;

      if (!value.checkbox) {
        value.url = '';
        value.content = '';
        value.source = undefined;
        value.coding = undefined;
      }

      const params: TagsMergeParams = {
        tags: tagsForMerge?.map((item): number => item.id) ?? [],
        url: value?.url ?? null,
        encoding: parseInt(value?.coding?.id?.toString() ?? '0') || null,
        name: value.tag,
        category: parseInt(value.category.id?.toString() ?? '0') || null,
        content: value?.source?.id
          ? {
              value: value.content ?? null,
              typeId: parseInt(value?.source?.id?.toString() ?? '0'),
            }
          : null,
      };

      const result = await service?.mergeTags(params);

      const bodySuccess = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.OkBody');
      const bodyTagRepeated = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.ExistingBody');
      const bodyContentError = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.contentErrorBody');
      const bodyTimeoutError = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.contentErrorTimeout');
      const bodyDownloadContent = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.downloadErrorContent');
      const bodyUnknownError = t('MANAGE_TAG_MODAL.merge.NOTIFICATION.KoBody');
      result && lastRefreshIncrement && lastRefreshIncrement();

      const title = result?.statusOk
        ? t('MANAGE_TAG_MODAL.NOTIFICATION.OkTitle')
        : t('MANAGE_TAG_MODAL.NOTIFICATION.AlertTitle');
      let message = result?.statusOk ? bodySuccess : false;

      if (result?.errorType.repeatedName) message = bodyTagRepeated;

      if (result?.errorType.contentError) message = bodyContentError;

      if (result?.errorType.timeoutDownload) message = bodyTimeoutError;

      if (result?.errorType.downloadContent) message = bodyDownloadContent;

      toast({
        title: title,
        body: message || bodyUnknownError,
        variant: result?.statusOk ? 'success' : 'error',
      });
      closeModal();
    },
  };

  const onAction = handleSubmit((values: FormInput) => {
    if (mode === 'create') return RULES.onActionCreate(values).catch(console.error);

    if (mode === 'edit') return RULES.onActionEdit(values).catch(console.error);
    if (mode === 'merge') return RULES.onActionMerge(values).catch(console.error);
  });

  return (
    <Modal isFloat={true} show={true}>
      <div className="h-full p-6">
        <Divide>
          <Flex column gapSize="small" itemAlignment="stretch">
            <Modal.Title buttonClose={false}>
              <Text color="light-black" variant="headline">
                {title}
              </Text>
            </Modal.Title>
            <div className="h-[86%]">
              <Modal.Body>
                <form>
                  <div className="w-[80vw] p-[1%] py-4 sm:w-[70vw] md:w-[40vw] lg:w-[30vw]">
                    <div className="w-full">
                      <section className="mb-3 flex flex-col gap-3">
                        <Input
                          control={control}
                          label={t('MANAGE_TAG_MODAL.fieldName')}
                          name="tag"
                          rules={RULES.tag}
                        />
                        <Select
                          control={control}
                          label={t('MANAGE_TAG_MODAL.fieldCategory')}
                          name="category"
                          options={
                            categoryTags && categoryTags.length > 0 ? categoryTags : listCategoryAux
                          }
                          rules={RULES.category}
                          value={category}
                        />
                        <Checkbox
                          checked={checkbox}
                          control={control}
                          id="checkbox"
                          label={t('MANAGE_TAG_MODAL.fieldCheckBox')}
                          name="checkbox"
                          rules={RULES.checkbox}
                        />
                        <div className={checkbox ? '' : 'hidden'}>
                          <Select
                            control={control}
                            name="source"
                            options={listSource}
                            rules={RULES.source}
                            onChange={(e) => {
                              setTextArea(e.id === ContentType.TEXT);
                            }}
                          />
                        </div>
                        {checkbox && textArea && (
                          <div className={checkbox && textArea ? '' : 'hidden'}>
                            <InputTextArea
                              control={control}
                              name="content"
                              rows={6}
                              rules={RULES.content}
                            />
                          </div>
                        )}
                        {checkbox && !textArea && (
                          <>
                            <div className={checkbox && !textArea ? '' : 'hidden'}>
                              <Select
                                control={control}
                                label={t('MANAGE_TAG_MODAL.fieldCoding')}
                                name="coding"
                                options={listEncoding}
                                rules={RULES.coding}
                              />
                            </div>
                            <div className={checkbox && !textArea ? '' : 'hidden'}>
                              <Input
                                control={control}
                                label={t('MANAGE_TAG_MODAL.fieldUrl')}
                                name="url"
                                rules={RULES.url}
                              />
                            </div>
                          </>
                        )}
                      </section>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <ButtonsSection onAction={onAction} onClose={closeModal} />
            </Modal.Footer>
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};
