export const TagsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7407" id="Group_7407" transform="translate(0.33)">
        <rect
          data-name="Rectangle 3240"
          fill="none"
          height="20"
          id="Rectangle_3240"
          transform="translate(-0.33)"
          width="20"
        />
        <path
          d="M6.333,6.333h.008M6.333,3H10.5a1.659,1.659,0,0,1,1.178.488l5.833,5.833a1.667,1.667,0,0,1,0,2.357l-5.833,5.833a1.667,1.667,0,0,1-2.357,0L3.488,11.679A1.662,1.662,0,0,1,3,10.5V6.333A3.333,3.333,0,0,1,6.333,3Z"
          data-name="Traced 3206"
          fill="none"
          id="Traced_3206"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-0.33)"
        />
      </g>
    </svg>
  );
};
