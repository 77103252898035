import { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, IconSvg, Modal, Spinner, Text, toast } from '@/lib/v2/components';
import { CodeIcon, CopyFileIcon } from '@/lib/v2/icons/solid';

import { useLanguage } from '@/src/application/hooks/useLanguage';
import { useUserData } from '@/src/application/hooks/useUserData';
import { useVersion } from '@/src/application/hooks/useVersion';

interface InstallCodeModalProps {
  scriptCode: string;
  showInstallCodeModal: boolean;
  setShowInstallCodeModal: Dispatch<SetStateAction<boolean>>;
}

const InstallCodeModal: FC<InstallCodeModalProps> = ({
  scriptCode,
  showInstallCodeModal,
  setShowInstallCodeModal,
}) => {
  const { t } = useTranslation();
  const [userData] = useUserData();
  const { version } = useVersion();
  const { language } = useLanguage({ userData, version });

  const scriptCodeRender = `
    <script>
      ${scriptCode}
    </script>
  `;

  const informationMessage = useCallback(() => {
    try {
      toast({
        title: t('NPS_SCREEN.NOTIFICATION_CODE_MODAL.OkTitle'),
        body: t('NPS_SCREEN.NOTIFICATION_CODE_MODAL.OkBody'),
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: t('NPS_SCREEN.NOTIFICATION_CODE_MODAL.ErrorTitle'),
        body: t('NPS_SCREEN.NOTIFICATION_CODE_MODAL.ErrorBody'),
        variant: 'error',
      });
    }
  }, []);

  return (
    <Modal open={showInstallCodeModal} onClose={() => setShowInstallCodeModal(false)}>
      <Flex alignment="start" className="relative pb-5">
        <IconSvg
          rounded
          bgColor="headingIcon"
          height="40px"
          svgComponent={<CodeIcon />}
          width="40px"
          withPadding="small"
        />
        <Text as="h1" className="ml-2 text-16 font-medium text-emblue-subheadings md:text-21">
          {t('NPS_SCREEN.installCodeModalTitle')}
        </Text>
        <div className="absolute bottom-0 right-[-40px] w-[150%] border-b-[1px] border-[#D3DDEA]" />
      </Flex>
      <Text as="p" className="mt-5 !text-14 text-emblue-text">
        {t('NPS_SCREEN.installCodeModalDescription')}{' '}
        <Text as="span" className="!text-14 text-emblue-primary">
          &lt;/body&gt;
        </Text>
        {language === 'en' && ' tag'}
      </Text>
      <Flex column className="mt-4 rounded-md bg-emblue-tiles px-6 pb-6 pt-9">
        <Flex>
          {scriptCode ? (
            <Text
              as="p"
              className="whitespace-pre-line break-all text-left !text-14 font-medium text-white"
            >
              {scriptCodeRender}
            </Text>
          ) : (
            <Flex alignment="center" className="h-full w-full">
              <Spinner withoutOverlay />
            </Flex>
          )}
        </Flex>
        <Button
          tertiary
          className="mt-9"
          disabled={!scriptCode}
          iconLeft={<CopyFileIcon fillColor="#fff" />}
          id="copy-code-button"
          onClick={() => {
            setShowInstallCodeModal(false);
            informationMessage();
            void navigator.clipboard.writeText(scriptCodeRender);
          }}
        >
          {t('NPS_SCREEN.installCodeModalCTA')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default memo(InstallCodeModal);
