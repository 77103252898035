import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-layout-masonry';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { LoadingIcon } from '@/lib/v2/icons/animated';
import { DeleteIcon } from '@/lib/v2/icons/outline/DeleteIcon';

import { ModalDelete } from '@/src/modules/CampaignsModule/components/ModalDelete/ModalDelete';
import { SkeletonMyCatalogTemplates } from '@/src/modules/CampaignsModule/components/SkeletonMyCatalogTemplates';
import { useMyTemplates } from '@/src/modules/CampaignsModule/hooks/useMyTemplates';
import { IEmailOfCatalog } from '@/src/modules/CampaignsModule/interfaces/Beefree';

interface ICatalogItems {
  data: IEmailOfCatalog;
  onUse: (templateId: number) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
  onPreview: (template: IEmailOfCatalog) => void;
}

const TemplateItem = ({ data, onUse, onDelete, onPreview }: ICatalogItems) => {
  const { thumbnail, title } = data ?? {};
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  if (!data) {
    console.warn('Template is corrupted', data);
    return null;
  }

  return (
    <div
      className={`group flex min-h-56 flex-row justify-between ${
        loading ? 'animate-pulse' : ''
      } flex-col overflow-hidden rounded-md bg-gray-100 shadow-md`}
    >
      <div className="relative h-full">
        <img
          alt={title}
          className="w-full"
          loading="lazy"
          src={thumbnail}
          onLoad={() => setLoading(false)}
        />
        <div className="absolute left-0 top-0 hidden size-full flex-col items-center justify-center gap-4 bg-black/80 backdrop-opacity-75 group-hover:flex">
          <Button
            gray
            outline
            standard
            className="absolute right-2 top-2"
            onClick={() => data.catalogId && void onDelete(data.catalogId)}
          >
            <IconSvg fillColor="gray-dark" svgComponent={<DeleteIcon color="transparent" />} />
          </Button>
          <Button
            fullWidth
            gray
            outline
            standard
            classNameContainer="max-w-28"
            onClick={() => onPreview(data)}
          >
            {t('BEEFREE_TEMPLATES_GALLERY.preview')}
          </Button>
          <Button
            fullWidth
            gray
            outline
            standard
            classNameContainer="max-w-28"
            onClick={() => void onUse(data.id)}
          >
            {t('BEEFREE_TEMPLATES_GALLERY.use')}
          </Button>
        </div>
      </div>
      <Text className="border-t-[1px] border-t-gray-200 bg-white p-2" variant="text">
        {data.title}
      </Text>
    </div>
  );
};

export const MyCatalogTemplates = memo(() => {
  const { t } = useTranslation();
  const {
    catalog,
    getCatalog,
    masonryRef,
    loadingRef,
    handleUseEmail,
    handleDeleteEmail,
    handlePreviewEmail,
    showModalDelete,
    setShowModalDelete,
    hasMore,
  } = useMyTemplates();

  const modalDeleteWordings = useMemo(
    () => ({
      title: t('CATALOG_MODAL_DELETE_TEMPLATE.title'),
      message: t('CATALOG_MODAL_DELETE_TEMPLATE.message'),
      actions: {
        cancel: t('CATALOG_MODAL_DELETE_TEMPLATE.cancel'),
        accept: t('CATALOG_MODAL_DELETE_TEMPLATE.accept'),
      },
    }),
    [t]
  );
  // eslint-disable-next-line @typescript-eslint/require-await
  const handleClose = useCallback(async () => {
    setShowModalDelete(null);
  }, [setShowModalDelete]);
  const handleOpenDelete = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (item: IEmailOfCatalog) => {
      setShowModalDelete(item);
    },
    [setShowModalDelete]
  );
  /**
   * @desc Mapper of catalog of emails
   */
  const catalogMapper = useCallback(
    (item: IEmailOfCatalog, key: number) => {
      return (
        <TemplateItem
          key={key}
          data={item}
          onDelete={() => handleOpenDelete(item)}
          onPreview={handlePreviewEmail}
          onUse={handleUseEmail}
        />
      );
    },
    [handleOpenDelete, handlePreviewEmail, handleUseEmail]
  );

  if (!Array.isArray(catalog)) return <SkeletonMyCatalogTemplates />;

  return (
    <div ref={masonryRef} className="visible-scroll w-full gap-4 px-0 py-4 ">
      <InfiniteScroll
        className="w-full"
        dataLength={catalog?.length ?? 0}
        endMessage={
          <Text alignment="center" className="py-6" color="light-black" fontWeight="normal">
            <b>{t('noMoreResults')}</b>
          </Text>
        }
        hasMore={hasMore}
        loader={
          <div className="w-full">
            <SkeletonMyCatalogTemplates columns={5} />
          </div>
        }
        next={getCatalog}
      >
        <Masonry columns={6} gap={16}>
          {catalog?.map(catalogMapper)}
        </Masonry>
      </InfiniteScroll>

      {hasMore && (
        <div ref={loadingRef} className="flex w-full items-center justify-center gap-4 p-4">
          <IconSvg fillColor="gray-dark" svgComponent={<LoadingIcon color="#000" />} />{' '}
          <Text variant="headline">{t('BEEFREE_TEMPLATES_GALLERY.loading')}</Text>
        </div>
      )}
      <ModalDelete
        data={showModalDelete}
        open={showModalDelete ? true : false}
        wordings={modalDeleteWordings}
        onAccept={handleDeleteEmail}
        onCancel={handleClose}
      />
    </div>
  );
});
