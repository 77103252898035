export const OpenRateIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7641 4.62971V5.17771C12.7638 5.42274 12.6974 5.66315 12.5718 5.87353C12.4462 6.08392 12.2661 6.25645 12.0505 6.37291L8.14247 8.47691M8.14247 8.47691L10.9537 9.99131M8.14247 8.47691L7.52567 8.14491C7.3278 8.03862 7.10669 7.98299 6.88207 7.98299C6.65746 7.98299 6.43634 8.03862 6.23847 8.14491L5.62087 8.47691M0.999274 4.62971V5.17771C0.99952 5.42274 1.06596 5.66315 1.19157 5.87353C1.31718 6.08392 1.49729 6.25645 1.71287 6.37291L5.62087 8.47691M5.62087 8.47691L2.80887 9.99051M12.7633 10.9649C12.7633 11.3256 12.62 11.6715 12.3649 11.9266C12.1099 12.1816 11.764 12.3249 11.4033 12.3249H2.35687C1.99618 12.3249 1.65026 12.1816 1.39521 11.9266C1.14016 11.6715 0.996874 11.3256 0.996874 10.9649V4.53771C0.996486 4.29163 1.06288 4.05005 1.18897 3.83872C1.31506 3.6274 1.49613 3.45424 1.71287 3.33771L6.23927 0.904114C6.43714 0.797819 6.65826 0.742188 6.88287 0.742188C7.10749 0.742188 7.3286 0.797819 7.52647 0.904114L12.0497 3.33771C12.2661 3.45446 12.4469 3.6277 12.5727 3.83901C12.6985 4.05032 12.7646 4.29179 12.7641 4.53771V10.9665L12.7633 10.9649Z"
        stroke="#004DBC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
