import { useCallback, useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export const useRecalculateSegment = (): [
  (segmentId: number, segmentlastRefresh: number) => void,
  unknown
] => {
  const [segmentRecalculate, setSegmentRecalculate] = useState<number>();
  const [lastRefresh, setLastRefresh] = useState<number>(0);
  const [recalculateSegmentResponse] = useEmblue(ServiceMethods.recalculateSegment, {
    segmentId: segmentRecalculate,
    lastRefresh,
  });

  const recalculateSegment = useCallback((segmentId: number, segmentlastRefresh: number) => {
    setSegmentRecalculate(segmentId);
    setLastRefresh(segmentlastRefresh);
  }, []);

  return [recalculateSegment, recalculateSegmentResponse];
};
