export const ExportContactIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon" transform="translate(-367 -185)">
        <rect
          data-name="Rectangle 3518"
          fill="none"
          height="20"
          id="Rectangle_3518"
          transform="translate(367 185)"
          width="20"
        />
        <g data-name="Group 4036" id="Group_4036" transform="translate(-0.262 -0.742)">
          <path
            d="M10.574,4.5,7.5,7.567H9.806v5.388h1.537V7.567h2.306Z"
            data-name="Icon material-import-export"
            fill="#004dbc"
            id="Icon_material-import-export"
            transform="translate(371.875 183.983)"
          />
          <path
            d="M8,9A3,3,0,1,0,5,6,3,3,0,0,0,8,9Zm0,2a6,6,0,0,1,6,6H2a6,6,0,0,1,6-6Z"
            data-name="Path 3300"
            fill="#004dbc"
            id="Path_3300"
            transform="translate(367 186)"
          />
        </g>
      </g>
    </svg>
  );
};
