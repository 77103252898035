import { atom } from 'jotai';

import { CreateEventForm, EventData } from '@/src/modules/AutomationModule/types/events';

export const initialCreateEventForm: CreateEventForm = {
  name: '',
  description: '',
  modelData: false,
  attributes: [],
  eventItems: [],
};

export const atomCreateEventForm = atom<CreateEventForm>(initialCreateEventForm);
atomCreateEventForm.debugLabel = 'atomCreateEventForm';

export const atomEventData = atom<EventData | undefined>(undefined);
atomEventData.debugLabel = 'atomEventData';

export const atomCreateEventFormError = atom<string>('');
atomCreateEventFormError.debugLabel = 'atomCreateEventFormError';

export const atomCreateEventFormIsValid = atom<boolean>(false);
atomCreateEventFormIsValid.debugLabel = 'atomCreateEventFormIsValid';
