import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { TFirstProgressStep } from '@/src/ContactsModule/interfaces/Steps';

export const atomFirstStepInternal = atom<TFirstProgressStep>('readyToSelectFile');
atomFirstStepInternal.debugLabel = 'atomFirstStepInternal';

export const atomFirstStepUploadPercentage = atom<number>(0);
atomFirstStepUploadPercentage.debugLabel = 'atomFirstStepUploadPercentage';

export const atomReadyToStartImport = atomWithReset<boolean>(false);
atomReadyToStartImport.debugLabel = 'atomReadyToStartImport';
