/* eslint-disable no-nested-ternary */
/* cspell:disable */
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, Spinner, Text } from '@/lib/v2/components';
import { UploadArrowIcon } from '@/lib/v2/icons/solid';

import UploadUrl from '@/src/modules/CampaignsModule/components/HtmlUpload/components/UploadUrl/UploadUrl';
import UploadZone from '@/src/modules/CampaignsModule/components/HtmlUpload/components/UploadZone/UploadZone';
import { useUploadFile } from '@/src/modules/CampaignsModule/components/HtmlUpload/hooks';

import {
  isLoadingUploadAtom,
  isUploadFilePageModalOpenAtom,
} from '@/modules/CampaignsModule/atoms/htmlEditorAtom';

const UploadFilePage = () => {
  const { t } = useTranslation(['editorHtml']);
  const setIsUploadFileOpen = useSetAtom(isUploadFilePageModalOpenAtom);
  const closeModal = () => setIsUploadFileOpen(false);
  const isLoading = useAtomValue(isLoadingUploadAtom);

  const {
    isUrlUploader,
    setIsUrlUploader,
    getAndSaveHtmlByUrl,
    getRootProps,
    getInputProps,
    open,
    isDraged,
    fileName,
  } = useUploadFile();

  return (
    <>
      <section className="relative flex h-full flex-col overflow-hidden">
        <div className="mb-6 flex flex-row border-b pb-2">
          <div className="flex w-10 items-center justify-start">
            <IconSvg
              fullWidth
              rounded
              bgColor="headingIcon"
              svgComponent={<UploadArrowIcon />}
              withPadding="small"
            />
          </div>
          <div className="flex w-full items-center justify-start pl-2">
            <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
              {t('importFile')}
            </Text>
          </div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="text-bluePrimary mb-2 flex w-full items-center justify-start gap-4">
            <Button
              link
              classNameContainer={`border-b-[3px] ${
                !isUrlUploader ? 'border-[#004DBC]' : 'border-transparent'
              } border-solid`}
              onClick={() => setIsUrlUploader(false)}
            >
              {t('uploadFile')}
            </Button>
            <Button
              link
              classNameContainer={`border-b-[3px] ${
                isUrlUploader ? 'border-[#004DBC]' : 'border-transparent'
              } border-solid`}
              onClick={() => setIsUrlUploader(true)}
            >
              {t('fromUrl')}
            </Button>
          </div>
        </div>
        <div className="h-full min-h-[375px] pt-5">
          {!isLoading ? (
            isUrlUploader ? (
              <UploadUrl closeModal={closeModal} urlUploader={getAndSaveHtmlByUrl} />
            ) : (
              <>
                <UploadZone
                  ctaText={t('searchLocally')}
                  fileName={fileName}
                  inputProps={getInputProps}
                  isDraged={isDraged}
                  plainText={t('dragDropFile')}
                  rootProps={getRootProps({ className: 'dropzone' })}
                  onClickHandler={open}
                />
                <div className="flex justify-center pb-2 pt-12">
                  <Button secondary onClick={closeModal}>
                    {t('cancel')}
                  </Button>
                </div>
              </>
            )
          ) : (
            <div className="h-[280px]">
              <Spinner withoutOverlay />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default UploadFilePage;
