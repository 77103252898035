/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

export type AppVersion = 'v1' | 'v2';

export const useVersion = () => {
  const version = useMemo<AppVersion>(
    () =>
      (window as any).__onsiteEnabled === true
        ? 'v2'
        : window.location.pathname.toLowerCase().startsWith('/v2') ||
          window.parent.location.search.match(/story/)
        ? 'v2'
        : 'v1',
    []
  );

  return { version };
};
