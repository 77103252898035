import { useEffect, useState } from 'react';

import { useTablePagination } from '@/lib/components/Table/useTablePagination';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { Billing, Consumption, PaginationDataResponse } from '@/src/modules/MyPlanModule/types';

export const useConsumptionAndBillingTables = <
  T extends PaginationDataResponse<Consumption[]> | PaginationDataResponse<Billing[]>
>(
  serviceMethod: 'listConsumption' | 'listBilling'
) => {
  const [totalList, setTotalList] = useState(0);

  const {
    changeTablePage,
    pageIndex,
    pageSize,
    totalPages,
    changeOrderBy,
    orderColumn,
    orderDirection,
  } = useTablePagination(totalList);

  const [list, , listIsLoading] = useEmblue(
    serviceMethod === 'listConsumption'
      ? ServiceMethods.getListConsumption
      : ServiceMethods.getListBilling,
    {
      limit: pageSize,
      page: pageIndex,
      ...(orderColumn && { sortField: orderColumn }),
      ...(orderDirection && { sortOrder: orderDirection }),
    }
  );

  useEffect(() => {
    if (list) {
      setTotalList(list.pager.countTotal);
    }
  }, [list]);

  return {
    list: list as T,
    listIsLoading,
    changeOrderBy,
    changeTablePage,
    totalPages,
  };
};
