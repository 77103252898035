import { t } from 'i18next';

import { CustomsFieldsProps } from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';

export const translateCustomFields = (customsFields: CustomsFieldsProps[]) => {
  return customsFields.map((element: CustomsFieldsProps) => {
    const fields = element;
    let translationName = t('CUSTOM_FIELDS.' + fields.name);
    translationName = translationName.includes('CUSTOM_FIELDS.') ? fields.name : translationName;

    fields.name = translationName;

    return fields;
  });
};
