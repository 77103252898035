import { Route, Routes } from 'react-router-dom';

import { CreateContacts } from '@/src/ContactsModule/presentation/Screens/CreateContacts/CreateContacts';
import { GroupDetail } from '@/src/ContactsModule/presentation/Screens/GroupDetail/GroupDetail';
import { GroupsMain } from '@/src/ContactsModule/presentation/Screens/GroupsMain';
import { ItemsMain } from '@/src/ContactsModule/presentation/Screens/ItemsMain';
import { TagDetail } from '@/src/ContactsModule/presentation/Screens/TagDetail/TagDetail';
import { TagsMain } from '@/src/ContactsModule/presentation/Screens/TagsMain';
import ContactsLayout from '@/src/modules/ContactsModule/layouts/ContactsLayout/ContactsLayout';
import HappinessLayout from '@/src/modules/ContactsModule/layouts/HappinessLayout/HappinessLayout';
import ItemsLayout from '@/src/modules/ContactsModule/layouts/ItemsLayout/ItemsLayout';
import SegmentLayout from '@/src/modules/ContactsModule/layouts/SegmentLayout/SegmentLayout';
import { CreateSegment } from '@/src/modules/ContactsModule/screens/CreateSegment';
import { HappinessMain } from '@/src/modules/ContactsModule/screens/HappinessMain';
import { SegmentDetail } from '@/src/modules/ContactsModule/screens/SegmentDetail';
import { SegmentsMain } from '@/src/modules/ContactsModule/screens/SegmentsMain';

import { ContactsProfile } from '@/modules/ContactsModule/screens/ContactProfile/ContactsProfile';
import { ContactsMain } from '@/modules/ContactsModule/screens/ContactsMain';
import { DiscardedMain } from '@/modules/ContactsModule/screens/DiscardedMain';
import { ImportMain } from '@/modules/ContactsModule/screens/ImportMain/ImportMain';

export const ContactsModuleRouter = () => {
  return (
    <Routes>
      <Route element={<ContactsLayout />} path="/">
        <Route index element={<ContactsMain />} />
        <Route element={<ContactsProfile />} path="/:emailId" />
        <Route element={<TagsMain />} path="/tags" />
        <Route element={<TagDetail />} path="/tags/:tagId" />
        <Route element={<ItemsLayout />} path="/items">
          <Route index element={<ItemsMain />} />
        </Route>
        <Route element={<GroupsMain />} path="/groups" />
        <Route element={<GroupDetail />} path="/groups/:groupId" />
        <Route element={<SegmentLayout />} path="/segments">
          <Route index element={<SegmentsMain />} />
          <Route element={<SegmentDetail />} path=":segmentId" />
          <Route element={<CreateSegment />} path="create-segment/:segmentName" />
          <Route element={<CreateSegment />} path="edit-segment/:segmentId" />
        </Route>
        <Route element={<DiscardedMain />} path="/discarded" />
        <Route element={<ImportMain />} path="/import" />
        <Route element={<CreateContacts />} path="/create-contact" />
        <Route element={<HappinessLayout />} path="/happiness">
          <Route index element={<HappinessMain />} />
        </Route>
      </Route>
    </Routes>
  );
};
