import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { CreateEventBody } from '@/src/infrastructure/interfaces/IAutomation.interface';
import { useAutomationService } from '@/src/infrastructure/Protocol/Automation/useBeefreeService';
import {
  atomCreateEventForm,
  atomCreateEventFormError,
  initialCreateEventForm,
} from '@/src/modules/AutomationModule/atoms/createEventAtoms';
import { EVENT_ALREADY_EXISTS } from '@/src/modules/AutomationModule/constants';

export const useCreateEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const service = useAutomationService();
  const { eventId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [createEventForm, setCreateEventForm] = useAtom(atomCreateEventForm);
  const setCreateEventFormError = useSetAtom(atomCreateEventFormError);

  const handleGoToCreateEvent = useCallback(() => {
    setCreateEventForm(initialCreateEventForm);
    navigate(`create`);
  }, [navigate, setCreateEventForm]);

  const transformFormToCreateEventBody = useCallback((): CreateEventBody => {
    return {
      name: createEventForm.name,
      description: createEventForm.description,
      attributes: createEventForm.attributes.map((attribute) => attribute.value),
      items: createEventForm.eventItems.map((item) => item.value),
    };
  }, [createEventForm]);

  const createEvent = useCallback(async () => {
    const createEventBody = transformFormToCreateEventBody();
    const response = await service.createEvent(createEventBody);
    return response;
  }, [service, transformFormToCreateEventBody]);

  const editEvent = useCallback(async () => {
    const body = transformFormToCreateEventBody();
    const editEventBody = { ...body, id: eventId ? +eventId : 0 };
    const response = await service.editEvent(editEventBody);
    return response;
  }, [eventId, service, transformFormToCreateEventBody]);

  const handleCreateEvent = useCallback(async () => {
    setIsLoading(true);
    const response = await createEvent();
    if (response.success) {
      toast({
        id: 'createEventSuccess',
        title: t('useCreateEvent.createEvent.success.title'),
        body: t('useCreateEvent.createEvent.success.body'),
        variant: 'success',
      });
      navigate(-1);
    } else if (response.data === EVENT_ALREADY_EXISTS) {
      setCreateEventFormError(t('useCreateEvent.createEvent.error.eventAlreadyExists'));
    } else {
      toast({
        id: 'createEventError',
        title: t('useCreateEvent.createEvent.error.title'),
        body: t('useCreateEvent.createEvent.error.body'),
        variant: 'error',
      });
    }
    setIsLoading(false);
  }, [createEvent, navigate, setCreateEventFormError, t]);

  const handleEditEvent = useCallback(async () => {
    setIsLoading(true);
    const response = await editEvent();
    if (response.success) {
      toast({
        id: 'editEventSuccess',
        title: t('useCreateEvent.editEvent.success.title'),
        body: t('useCreateEvent.editEvent.success.body'),
        variant: 'success',
      });
      navigate(-1);
    } else if (response.data === EVENT_ALREADY_EXISTS) {
      setCreateEventFormError(t('useCreateEvent.createEvent.error.eventAlreadyExists'));
    } else {
      toast({
        id: 'editEventError',
        title: t('useCreateEvent.editEvent.error.title'),
        body: t('useCreateEvent.editEvent.error.body'),
        variant: 'error',
      });
    }
    setIsLoading(false);
  }, [editEvent, navigate, setCreateEventFormError, t]);

  return {
    handleGoToCreateEvent,
    handleCreateEvent,
    isLoading,
    createEvent,
    handleEditEvent,
    editEvent,
  };
};
