export const MiniThumbIcon = () => {
  return (
    <svg
      height="24"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 0.302)">
        <rect fill="none" height="24" transform="translate(0 -0.302)" width="24" />
        <g transform="translate(3.511 -12.429)">
          <g transform="translate(0 16.331)">
            <path
              d="M16.417,23.6a2.821,2.821,0,0,0-2.835-1.847h-1.65a6.531,6.531,0,0,0,.59-2.647,5.288,5.288,0,0,0-.081-.935,2,2,0,0,0-2.335-1.826c-.492.057-.661.524-1.069,1.657a16.1,16.1,0,0,1-1.475,3.259,5.519,5.519,0,0,1-2.116,1.729V22.9H0v8.092H5.448v-.446a6.874,6.874,0,0,1,1.459.019l.975.566.729.454c.708.381,3.8.418,5.131.221A2.549,2.549,0,0,0,15.878,30.1C16.013,29.635,17.187,25.471,16.417,23.6ZM14.85,29.8a1.494,1.494,0,0,1-1.263.941,15.341,15.341,0,0,1-4.471-.1l-.646-.405-1.225-.688a6.555,6.555,0,0,0-1.8-.067v-5.33a6.725,6.725,0,0,0,3.012-2.3,16.879,16.879,0,0,0,1.586-3.481s.314-.782.39-.963c.542,0,.842.3.953.948a4.331,4.331,0,0,1,.062.747,5.852,5.852,0,0,1-.836,2.9l-.543.828h3.511a1.771,1.771,0,0,1,1.846,1.186C15.96,25.3,15.187,28.632,14.85,29.8Z"
              fill="#004dbc"
              transform="translate(0 -16.331)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
