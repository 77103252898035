export const EditIcon = ({ color = "#004dbc" }) => {
  return (
    <svg
      data-name="Group 7496"
      height="20"
      id="Group_7496"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3179" fill="none" height="20" id="Rectangle_3179" width="20" />
      <path
        d="M14.2,11.023V14.57a1.68,1.68,0,0,1-1.68,1.68H4.68A1.68,1.68,0,0,1,3,14.57V6.73A1.68,1.68,0,0,1,4.68,5.05H8.227"
        data-name="Layout 4092"
        fill="none"
        id="Layout_4092"
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(0 0.75)"
      />
      <path
        d="M168,217.876v0l.6-2a3.371,3.371,0,0,1,.844-1.417l6.149-6.149,2.015,1.945-6.184,6.184a3.371,3.371,0,0,1-1.417.844l-2,.6Zm10.312-8.332h0L176.3,207.6l1.072-1.072a1.4,1.4,0,0,1,1.98,1.98l-1.037,1.037Z"
        data-name="Subtraction 2"
        fill={ color }
        id="Subtraction_2"
        transform="translate(-161.51 -203.809)"
      />
    </svg>
  );
};
