import { useAtom } from 'jotai';

import { atomModalPreview } from '@/src/atoms/Catalogs';

export const useModalPreview = () => {
  const [showModalPreview, setShowModalPreview] = useAtom(atomModalPreview);

  return {
    showModalPreview,
    setShowModalPreview,
  };
};
