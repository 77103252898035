import { useAtomValue } from 'jotai';
import { useRef } from 'react';

import {
  atomListSearchCampaigns,
  atomLoadingSearchCampaigns,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';

import { SearchCampaignsInput } from './components/SearchCampaignsInput';
import { SearchCampaignsList } from './components/SearchCampaignsList';
import { useSearchCampaigns } from './hooks/useSearchCampaigns';

const CampaignGeneralFilterBar = () => {
  const parentContainerRef = useRef<HTMLDivElement>(null);
  useSearchCampaigns({ containerRootRef: parentContainerRef });

  const listSearchCampaigns = useAtomValue(atomListSearchCampaigns);
  const loadingSearchCampaigns = useAtomValue(atomLoadingSearchCampaigns);

  return (
    <>
      <div ref={parentContainerRef} className="relative">
        <SearchCampaignsInput />
        <div className="absolute left-0 top-12">
          <SearchCampaignsList list={listSearchCampaigns} loading={loadingSearchCampaigns} />
        </div>
      </div>
    </>
  );
};

export default CampaignGeneralFilterBar;
