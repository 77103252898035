import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, Flex, IconSvg, Text } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon } from '@/lib/v2/icons/solid';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { atomSelectedRowsWithID } from '@/src/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDuplicateCampaignModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { DeleteCampaignsRecurrent } from '@/src/modules/CampaignsModule/components/CampaignRecurrentModal/DeleteCampaignsRecurrent';
import { DuplicateCampaignRecurrent } from '@/src/modules/CampaignsModule/components/CampaignRecurrentModal/DuplicateCampaignRecurrent';
import { CreateRecurrentAction } from '@/src/modules/CampaignsModule/components/CampaignsModal/CreateRecurrentAction';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { useCampaignModal } from '@/src/modules/CampaignsModule/hooks/useCampaignModal';
import { useRecurrentResetAtomOnUnmount } from '@/src/modules/CampaignsModule/hooks/useRecurrentResetAtomOnUnmount';
import { formattedNumber } from '@/src/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/src/modules/CampaignsModule/utils/formattedNumber';

import { TableContainer } from '@/modules/CampaignsModule/components/CampaignsRecurrent/TableContainer';
import { useRecurrentData } from '@/modules/CampaignsModule/hooks/useCampaignRecurrentData';

const CampaignRecurrentMain = () => {
  const { t, i18n } = useTranslation();

  const [toggleDuplicateCampaignModal, setToggleDuplicateCampaignModal] = useAtom(
    atomToggleDuplicateCampaignModal
  );
  const selectedCampaignsByID = useAtomValue(atomSelectedRowsWithID);

  const { recurrentMessageList, loadingProcess, totalsCampaignRecurrentList, filtersCount } =
    useRecurrentData();
  const {
    handleOnOpenModal,
    handleOnCloseModal,
    toggleDeleteModal,
    toggleEditCreateCampaignModal,
  } = useCampaignModal();

  const { resetAtomProcess } = useRecurrentResetAtomOnUnmount();

  useEffect(() => {
    return () => resetAtomProcess();
  }, []);

  const handleOnCloseDuplicateModal = () => {
    setToggleDuplicateCampaignModal(false);
  };

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button
                  iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                  id="create-campaigns-button"
                  size="small"
                  onClick={handleOnOpenModal}
                >
                  {t('RECURRENT_MESSAGE.button')}
                </Button>
              }
              icon={<IconSvg height="20px" svgComponent={<CampaignsIcon />} width="20px" />}
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={filtersCount}
                  countRecordsPartial={totalsCampaignRecurrentList.partial}
                  dataList={recurrentMessageList}
                  i18nKey="RECURRENT_MESSAGE.search"
                  loading={loadingProcess}
                />
              }
              title={t('RECURRENT_MESSAGE.title')}
              titleDetail={
                recurrentMessageList?.length && !loadingProcess ? (
                  <Text as="span" color="primary" fontWeight="medium" id="total-count-campaigns">
                    {`
                  ${
                    totalsCampaignRecurrentList.total &&
                    ` (${
                      formattedNumber(
                        totalsCampaignRecurrentList.total,
                        i18n.language as keyof typeof ELanguageMap
                      ) || 0
                    })`
                  }`}
                  </Text>
                ) : (
                  ' (0)'
                )
              }
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
      {toggleEditCreateCampaignModal && <CreateRecurrentAction onClose={handleOnCloseModal} />}
      {toggleDeleteModal && <DeleteCampaignsRecurrent campaigns={selectedCampaignsByID} />}
      {toggleDuplicateCampaignModal && (
        <DuplicateCampaignRecurrent onClose={handleOnCloseDuplicateModal} />
      )}
    </>
  );
};

export default CampaignRecurrentMain;
