import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { IconSvg } from '@/lib/v2/components/IconSvg';

import { OptionExternal } from './Interfaces';

export interface SelectOptionProps {
  option: OptionExternal;
}

const SelectOption = ({ option }: SelectOptionProps) => {
  return (
    <Listbox.Option
      className={({ active, disabled }) =>
        classNames('relative cursor-default select-none py-2 pl-2 pr-5', {
          'bg-emblue-secondary-hover': active,
          'text-gray-900': !active,
          '!text-emblue-disabled': disabled,
        })
      }
      disabled={option.disabled}
      value={option}
    >
      {({ selected }) => {
        return (
          <>
            <span
              className={classNames(
                selected ? 'font-semibold' : 'font-normal',
                'truncate text-left flex gap-3'
              )}
            >
              {option?.metadata?.icon && <IconSvg svgComponent={option?.metadata?.icon} />}
              {option.name}
              {option?.metadata?.alt && (
                <span className="text-emblue-primary">{option?.metadata?.alt}</span>
              )}
            </span>
            {selected && (
              <span
                className={'absolute inset-y-0 right-0 flex items-center pr-1 text-emblue-primary'}
              >
                <CheckIcon aria-hidden="true" className="size-5" />
              </span>
            )}
          </>
        );
      }}
    </Listbox.Option>
  );
};

export default SelectOption;
