export const GroupsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7404" id="Group_7404" transform="translate(-0.485 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.485 -0.163)"
          width="19"
        />
        <g data-name="Group 7242" id="Group_7242" transform="translate(0.907 2.807)">
          <ellipse
            cx="1.965"
            cy="1.965"
            data-name="Ellipse 1791"
            fill="#004dbc"
            id="Ellipse_1791"
            rx="1.965"
            ry="1.965"
            transform="translate(12.848 3.836)"
          />
          <ellipse
            cx="1.965"
            cy="1.965"
            data-name="Ellipse 1792"
            fill="#004dbc"
            id="Ellipse_1792"
            rx="1.965"
            ry="1.965"
            transform="translate(1.244 3.836)"
          />
          <path
            d="M-1218.8,148.441a3.684,3.684,0,0,0-1.5-2.246.175.175,0,0,0-.281,0,2.651,2.651,0,0,1-2.62.187,8.042,8.042,0,0,1,1.871,3.275h1.591C-1218.521,149.658-1218.8,148.441-1218.8,148.441Z"
            data-name="Layout 4241"
            fill="#004dbc"
            id="Layout_4241"
            transform="translate(1236.797 -138.334)"
          />
          <path
            d="M-1233.3,146.382a2.42,2.42,0,0,1-2.62-.187.175.175,0,0,0-.281,0,4.241,4.241,0,0,0-1.5,2.246s-.281,1.217.936,1.217h1.591A8.046,8.046,0,0,1-1233.3,146.382Z"
            data-name="Layout 4242"
            fill="#004dbc"
            id="Layout_4242"
            transform="translate(1237.73 -138.334)"
          />
          <g data-name="Group 6921" id="Group_6921" transform="translate(2.867)">
            <ellipse
              cx="3.743"
              cy="3.65"
              data-name="Ellipse 1789"
              fill="#004dbc"
              id="Ellipse_1789"
              rx="3.743"
              ry="3.65"
              transform="translate(2.402)"
            />
            <path
              d="M-1222.346,150.2a8.051,8.051,0,0,0-2.807-4.3.6.6,0,0,0-.561,0,4.781,4.781,0,0,1-5.521,0,.6.6,0,0,0-.562,0,7.741,7.741,0,0,0-2.807,4.3s-.561,2.339,1.685,2.339h8.8C-1221.784,152.539-1222.346,150.2-1222.346,150.2Z"
              data-name="Layout 4233"
              fill="#004dbc"
              id="Layout_4233"
              transform="translate(1234.667 -138.315)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
