import { useAtomValue } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { atomHistorySearchPagerActions } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';

interface BackToCampaignsModalProps {
  onClose: (value: boolean) => void;
  isOpen: boolean;
}

const MODAL_BACK_TO_CAMPAIGN = 'CAMPAIGN_ACTIONS_MAIN.MODAL_BACK_TO_CAMPAIGN';

const BackToCampaignsModal = ({ onClose, isOpen }: BackToCampaignsModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const infoCampaignV1 = useAtomValue(atomInfoCampaignV1);
  const historySearchPagerAction = useAtomValue(atomHistorySearchPagerActions);

  const handleOnConfirmModal = useCallback(() => {
    navigate(`${infoCampaignV1?.route ?? `/v2/campaigns/${String(campaignId)}`}`, {
      replace: true,
      state: { from: 'action-detail', data: { ...historySearchPagerAction } },
    });
  }, [campaignId, historySearchPagerAction, infoCampaignV1?.route, navigate]);

  return (
    <ModalConfirmation
      description={t(`${MODAL_BACK_TO_CAMPAIGN}.description`)}
      isOpen={isOpen}
      textCancelButton={t(`${MODAL_BACK_TO_CAMPAIGN}.cancel`)}
      textConfirmButton={t(`${MODAL_BACK_TO_CAMPAIGN}.confirm`)}
      title={t(`${MODAL_BACK_TO_CAMPAIGN}.title`)}
      onClose={onClose}
      onConfirmButton={handleOnConfirmModal}
    />
  );
};

export default memo(BackToCampaignsModal);
