import React, { cloneElement, ReactElement, useRef, useState } from 'react';

import { useOnClickOutside } from '@/lib/v2/hooks/useOnClickOutside';

export interface IDropdownCustom {
  children: ReactElement;
  open?: boolean;
  action: ReactElement;
  noPadding?: boolean;
}

const DropDownCustom: React.FC<IDropdownCustom> = ({
  children,
  action,
  open = false,
  noPadding = false,
}) => {
  const [show, setShow] = useState(open);
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(toggleRef, () => handleToggle());

  const handleToggle = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <div className="relative">
      {cloneElement(action, { onClick: handleToggle })}
      {show && (
        <div
          ref={toggleRef}
          className={
            'absolute left-0 top-full mt-2 max-h-[200] w-auto min-w-52 rounded-lg bg-white shadow-md ' +
            (noPadding ? '' : 'p-6')
          }
        >
          {cloneElement(children, { onClose: handleToggle })}
        </div>
      )}
    </div>
  );
};

export default DropDownCustom;
