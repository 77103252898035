export const ClicksIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7419" id="Group_7419" transform="translate(-0.318 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.318 -0.163)"
          width="20"
        />
        <path
          d="M12.561,17.4,11.5,13.447m0,0L9.543,15.183l.444-7.389,4.078,6.177L11.5,13.447ZM10.187,2.25V4.006m4.552.13L13.5,5.376m3.126,3.311H14.869M6.876,12,5.636,13.238m-.13-4.551H3.75M6.876,5.376,5.636,4.136"
          data-name="Layout 4470"
          fill="none"
          id="Layout_4470"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(0.068 0.087)"
        />
      </g>
    </svg>
  );
};
