export const EmblueLogo = () => {
  return (
    <svg
      height="20.394"
      viewBox="0 0 84.009 20.394"
      width="84.009"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="emblue-logo" transform="translate(0.1 0)">
        <path
          d="M843.075,3.337a3.337,3.337,0,0,0-6.675,0v.037h.009c-.009.25.056,1.923,2.848,4.687a.673.673,0,0,0,.952.009l.009-.009c2.792-2.755,2.857-4.428,2.847-4.687h.009V3.337Z"
          data-name="Path 3402"
          fill="#004dbc"
          id="Path_3402"
          transform="translate(-759.166)"
        />
        <path
          d="M12.288,77.334c0-3.827-2.773-6.934-6.194-6.934C2.627,70.4-.1,73.451-.1,77.334s2.718,6.934,6.185,6.934h.074a6.154,6.154,0,0,0,5.3-2.82l.361-.527-1.461-1.146-.4.582a4.391,4.391,0,0,1-3.772,2.034H6.1a4.534,4.534,0,0,1-4.262-4.123H12.288ZM1.841,76.391A4.542,4.542,0,0,1,6.1,72.267a4.573,4.573,0,0,1,4.29,4.123Z"
          data-name="Path 3403"
          fill="#004dbc"
          id="Path_3403"
          transform="translate(0 -63.892)"
        />
        <path
          d="M173.986,84.086h-1.84l.009-.934-.028-7.451c0-2.034-1.276-3.624-2.9-3.624a2.647,2.647,0,0,0-2.062,1.054c-.074.092-.2.287-.2.287-.046.074-.25.462-.287.536l-.037.092a1.725,1.725,0,0,0-.083.222l-.028.083-.028.111c-.018.046-.028.092-.046.148a1.032,1.032,0,0,0-.028.12l-.018.111c-.009.037-.019.083-.028.12a.827.827,0,0,0-.018.166l-.028.2c-.009.12-.018.25-.018.379l.009,8.394h-1.858l-.009-.157.009-8.237c0-2.034-1.276-3.624-2.9-3.624s-2.9,1.59-2.9,3.624l-.028,7.461.009.934H156.8V75.71c0-3.023,1.969-5.381,4.576-5.492h.055c.037,0,.083-.009.12-.009a4.389,4.389,0,0,1,2.764,1.017,4.974,4.974,0,0,1,1.072,1.211l.009-.018c.065-.1.129-.2.194-.3a2.748,2.748,0,0,1,.213-.25l.1-.12c.028-.037.065-.074.092-.111a4.273,4.273,0,0,1,.37-.342.527.527,0,0,0,.074-.074,5.8,5.8,0,0,1,.481-.351,4.285,4.285,0,0,1,2.293-.675c2.672,0,4.761,2.422,4.761,5.5Z"
          data-name="Path 3404"
          fill="#004dbc"
          id="Path_3404"
          transform="translate(-142.395 -63.71)"
        />
        <path
          d="M383.354,28.458a5.055,5.055,0,0,0,1.858-3.846,5.289,5.289,0,0,0-5.436-5.112l-5.649.009-.028,9.975.028,8.57,6.675.018a5.27,5.27,0,0,0,5.408-5.112A5.122,5.122,0,0,0,383.354,28.458Zm-7.387-7.072h3.8a3.42,3.42,0,0,1,3.578,3.236,3.429,3.429,0,0,1-3.587,3.236l-3.79.018Zm4.826,14.81-4.807.028-.009-6.49h4.817a3.415,3.415,0,0,1,3.559,3.236A3.4,3.4,0,0,1,380.793,36.2Z"
          data-name="Path 3405"
          fill="#004dbc"
          id="Path_3405"
          transform="translate(-339.605 -17.697)"
        />
        <path
          d="M526.6,19.7h1.858V38.255H526.6Z"
          data-name="Path 3406"
          fill="#004dbc"
          id="Path_3406"
          transform="translate(-478.007 -17.879)"
        />
        <path
          d="M732.979,77.334c0-3.827-2.773-6.934-6.185-6.934-3.467,0-6.194,3.051-6.194,6.934s2.718,6.934,6.185,6.934h.074a6.154,6.154,0,0,0,5.3-2.82l.36-.527-1.461-1.137-.416.582A4.4,4.4,0,0,1,726.85,82.4h-.065a4.534,4.534,0,0,1-4.262-4.123H732.97Zm-10.447-.943a4.279,4.279,0,0,1,8.552,0Z"
          data-name="Path 3407"
          fill="#004dbc"
          id="Path_3407"
          transform="translate(-654.071 -63.892)"
        />
        <path
          d="M583.538,84.449A5.4,5.4,0,0,1,578,79.207V70.6h1.858v8.607a3.679,3.679,0,0,0,7.331,0V70.6h1.886l-.028,8.607A5.383,5.383,0,0,1,583.538,84.449Z"
          data-name="Path 3408"
          fill="#004dbc"
          id="Path_3408"
          transform="translate(-524.655 -64.073)"
        />
      </g>
    </svg>
  );
};
