import { useLocation } from 'react-router-dom';

export const useQueryParamsCheckoutCompleted = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success') === 'true';
  const cancel = queryParams.get('cancel') === 'true';

  return { success, cancel };
};
