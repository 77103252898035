import { useAtomValue } from 'jotai';
import { memo, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { StateCard } from '@/lib/components/StateCard/StateCard';
import SmartFinder from '@/lib/v2/components/SmartFinder';
import { NotifyDownloadDiscarded } from '@/lib/v2/organisms/NotifyDownloadDiscarded/NotifyDownloadDiscarded';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import ScrollToTop from '@/src/application/util/ScrollToTop';
import { atomShowAutomationV1 } from '@/src/atoms/Automation';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import { V1CompatHandler } from '@/src/compat/V1CompatHandlerContainer';
import AppLayout from '@/src/layouts/AppLayout';
import Logout from '@/src/modules/AuthModule/screens/Logout';

import { ApiManagerModuleRouter } from '@/modules/ApiManagerModule/router/ApiManagerModuleRouter';
import { AUTOMATION_PATHS } from '@/modules/AutomationModule/constants';
import { AutomationModuleRouter } from '@/modules/AutomationModule/router/AutomationModuleRouter';
import {
  AutomationVersion,
  useAutomationLogger,
} from '@/modules/CampaignsModule/hooks/useAutomationLogger';
import { CampaignsModuleRouter } from '@/modules/CampaignsModule/router/CampaignsModuleRouter';
import CreateSegmentModalProvider from '@/modules/ContactsModule/contexts/CreateSegmentModalContext';
import { ContactsModuleRouter } from '@/modules/ContactsModule/router/ContactsModuleRouter';
import { InboxModuleRouter } from '@/modules/InboxModule/router/InboxModuleRouter';
import { MyPlanModuleRouter } from '@/modules/MyPlanModule';
import { MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { NpsModuleRouter } from '@/modules/NPSModule/router/NpsModuleRouter';
import { Home } from '@/modules/OnboardingModule/screens';
import { SendersModuleRouter } from '@/modules/SendersModule/router/SendersModuleRouter';

interface AppRouterVersionTwoProps {
  contactsEnabled: boolean;
}

const AppRouterVersionTwo = ({ contactsEnabled }: AppRouterVersionTwoProps) => {
  const showNpsModule = useFeatureFlag('showNpsModule');
  const showCampaignV1 = useAtomValue(atomShowCampaignsV1);
  const showAutomationV1 = useAtomValue(atomShowAutomationV1);
  const showCampaigns = useFeatureFlag('showCampaigns');
  const showAutomation = useFeatureFlag('showAutomation');
  const showSmartFinder = useFeatureFlag('showSmartFinder');
  const showInboxModule = useFeatureFlag('showInboxModule');
  const { userData } = useStateUserContext();
  const { logger } = useAutomationLogger();
  const { UI } = useParams();
  const uiCommit = UI as string;

  const watchAutomationOpen = (evt: MessageEvent<{ type: string }>) => {
    const isReturnToAutomationV1 =
      typeof showAutomationV1 === 'string' ? showAutomationV1 !== 'true' : false;
    if (evt?.data?.type === 'automation.open' && (isReturnToAutomationV1 || !showAutomation)) {
      void logger('load', AutomationVersion.V1, 'action');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('profile/showCampaignV1');
    }, 10000);
    setTimeout(() => {
      localStorage.removeItem('profile/showAutomationV1');
    }, 10000);

    window.addEventListener('message', watchAutomationOpen);

    return () => {
      window.removeEventListener('message', watchAutomationOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<AppLayout userData={userData} />} path="/">
          <Route
            index
            element={
              <>
                <V1CompatHandler enabled={false} targetIframe={'/?v2=true'} />
                <Home userData={userData} />
              </>
            }
          />
          <Route
            element={<V1CompatHandler enabled={true} targetIframe={'/?v2=true'} />}
            path="dashboard"
          />

          <Route
            element={
              <CreateSegmentModalProvider>
                {contactsEnabled ? (
                  <>
                    <ContactsModuleRouter />
                    <V1CompatHandler enabled={false} targetIframe={'/?v2=true'} />
                  </>
                ) : (
                  <V1CompatHandler enabled targetIframe={'/?v2=true'} />
                )}
              </CreateSegmentModalProvider>
            }
            path="contacts/*"
          />
          <Route element={<MyPlanModuleRouter />} path={`${MY_PLAN_PATHS.MODULE_PATH}/*`} />

          <Route
            element={
              showCampaignV1 !== 'true' && showCampaigns ? (
                <>
                  <CampaignsModuleRouter />
                  <V1CompatHandler enabled={false} tab="/v2/campaigns" targetIframe={'/?v2=true'} />
                </>
              ) : (
                <V1CompatHandler enabled targetIframe={'/?v2=true'} />
              )
            }
            path="campaigns/*"
          />

          <Route element={<V1CompatHandler enabled targetIframe={'/?v2=true'} />} path="reports" />
          <Route
            element={
              showAutomationV1 !== 'true' && showAutomation ? (
                <>
                  <V1CompatHandler
                    enabled={false}
                    tab="/v2/automation"
                    targetIframe={'/?v2=true' + (uiCommit ? '&UI=' + uiCommit : '')}
                  />
                  <AutomationModuleRouter />
                </>
              ) : (
                <V1CompatHandler
                  enabled
                  targetIframe={
                    '/?v2=true&forceAutomationV1=' + (showAutomationV1 === 'true').toString()
                  }
                />
              )
            }
            path={`${AUTOMATION_PATHS.MODULE_PATH}/*`}
          />
          <Route element={<V1CompatHandler enabled targetIframe={'/?v2=true'} />} path="onsite" />

          <Route
            element={<V1CompatHandler enabled targetIframe={'/?v2=true'} />}
            path="configuration"
          />

          <Route element={<SendersModuleRouter />} path="configuration/*" />

          <Route element={<ApiManagerModuleRouter />} path="api-manager/*" />

          <Route
            element={
              <>
                <V1CompatHandler enabled targetIframe={`/${window.location.search}&v2=true`} />
              </>
            }
            path="sharing"
          />

          {showNpsModule && (
            <Route
              element={
                <>
                  <NpsModuleRouter />
                  <V1CompatHandler enabled={false} targetIframe={'/?v2=true'} />
                </>
              }
              path="nps/*"
            />
          )}
          {showInboxModule && <Route element={<InboxModuleRouter />} path="inbox/*" />}

          <Route element={<Logout />} path="logout" />
        </Route>
      </Routes>
      <NotifyDownloadDiscarded />
      <StateCard />
      {showSmartFinder && <SmartFinder />}
    </>
  );
};

export default memo(AppRouterVersionTwo);
