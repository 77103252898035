export const WhatsAppIcon = () => {
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 0C8.97 0 0 8.97 0 20C0 23.19 0.77 26.34 2.23 29.17L0.0900002 36.83C-0.15 37.7 0.1 38.63 0.73 39.27C1.21 39.74 1.85 40 2.5 40C2.73 40 2.95 39.97 3.17 39.91L10.83 37.77C13.66 39.23 16.82 40 20 40C31.03 40 40 31.03 40 20C40 8.97 31.03 0 20 0ZM30.36 27.37C29.92 28.6 27.76 29.79 26.79 29.88C25.82 29.97 24.91 30.32 20.45 28.56C15.07 26.44 11.67 20.93 11.41 20.57C11.14 20.22 9.25 17.71 9.25 15.1C9.25 12.5 10.62 11.22 11.1 10.69C11.59 10.16 12.16 10.03 12.51 10.03C12.87 10.03 13.22 10.03 13.53 10.04C13.9 10.06 14.32 10.08 14.72 10.96C15.19 12 16.22 14.62 16.35 14.89C16.48 15.15 16.57 15.46 16.39 15.81C16.22 16.16 16.13 16.38 15.86 16.69C15.6 17 15.31 17.38 15.07 17.62C14.81 17.88 14.53 18.17 14.84 18.7C15.15 19.23 16.21 20.96 17.78 22.36C19.8 24.16 21.5 24.72 22.03 24.99C22.56 25.25 22.87 25.21 23.18 24.85C23.49 24.5 24.5 23.31 24.86 22.78C25.21 22.25 25.56 22.34 26.05 22.52C26.53 22.69 29.13 23.97 29.66 24.24C30.19 24.5 30.54 24.63 30.67 24.85C30.8 25.07 30.8 26.13 30.36 27.37Z"
        fill="white"
      />
    </svg>
  );
};
