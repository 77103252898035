type RecalculateIconIconProps = {
  tableSize?: boolean;
};

export const RecalculateIcon = ({ tableSize }: RecalculateIconIconProps) => {
  return (
    <svg
      height={tableSize ? 19 : 20}
      viewBox="0 0 20 20"
      width={tableSize ? 19 : 20}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 7420" id="Group_7420" transform="translate(-0.455)">
        <rect
          data-name="Rectangle 3180"
          fill="none"
          height={tableSize ? 19 : 20}
          id="Rectangle_3180"
          transform="translate(0.455)"
          width={tableSize ? 19 : 20}
        />
        <path
          d="M20.967,8.576a8.771,8.771,0,1,0,2.28,8.386h-2.28a6.574,6.574,0,1,1-6.193-8.769A6.484,6.484,0,0,1,19.4,10.144l-3.53,3.53h7.673V6Z"
          data-name="Icon material-refresh"
          fill="#004dbc"
          id="Icon_material-refresh"
          transform="translate(-4.56 -5)"
        />
      </g>
    </svg>
  );
};
