import {
  EStrategyOptionsName,
  IStrategy,
  ModalType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

const TRANSLATE_MODAL = 'CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS';
const TRANSLATE_MODAL_INPUTS = `${TRANSLATE_MODAL}.INPUTS_FORM`;

const STRATEGY_OPTIONS: IStrategy[] = [
  { strategyId: 1, name: EStrategyOptionsName.MARKETING },
  { strategyId: 2, name: EStrategyOptionsName.SALES },
  { strategyId: 3, name: EStrategyOptionsName.REMARKETING },
  { strategyId: 4, name: EStrategyOptionsName.NURTURING },
  { strategyId: 5, name: EStrategyOptionsName.LEAD_GENERATION },
  { strategyId: 6, name: EStrategyOptionsName.LEAD_RECOVERY },
  { strategyId: 7, name: EStrategyOptionsName.CROSS_SELLING },
  { strategyId: 8, name: EStrategyOptionsName.UP_SELLING },
  { strategyId: 9, name: EStrategyOptionsName.LAUNCH },
];

const STRATEGY_MAP_BY_ID: { [key: number]: string } = {
  1: 'marketing',
  2: 'sales',
  3: 'remarketing',
  4: 'nurturing',
  5: 'lead-generation',
  6: 'lead-recovery',
  7: 'cross-selling',
  8: 'up-selling',
  9: 'launch',
  10: 'other',
};

const MIN_DATE = '01/01/2010';

const MODAL_TITLE_KEYS = {
  [ModalType.EDIT]: `${TRANSLATE_MODAL}.titleModalEdit`,
  [ModalType.CREATE]: `${TRANSLATE_MODAL}.titleModal`,
  [ModalType.DUPLICATE]: `${TRANSLATE_MODAL}.titleModalDuplicate`,
};

const SUBMIT_BUTTON_KEYS = {
  [ModalType.EDIT]: `${TRANSLATE_MODAL}.updateButton`,
  [ModalType.CREATE]: `${TRANSLATE_MODAL}.successButton`,
  [ModalType.DUPLICATE]: `${TRANSLATE_MODAL}.successButtonDuplicate`,
};

export {
  EStrategyOptionsName,
  MIN_DATE,
  MODAL_TITLE_KEYS,
  STRATEGY_MAP_BY_ID,
  STRATEGY_OPTIONS,
  SUBMIT_BUTTON_KEYS,
  TRANSLATE_MODAL,
  TRANSLATE_MODAL_INPUTS,
};
