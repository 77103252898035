import { useMemo } from 'react';

import { useEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { HtmlEditorService } from './HtmlEditorService';
import { StubHtmlEditorService } from './StubHtmlEditorService';

export const useHtmlEditorService = () => {
  const { env } = useEnv();

  const service = useMemo(() => {
    if (env !== 'storybook') {
      const { URL_EMA_APP_V2, URL_EMA_APP, JWT_DEV_TOKEN, URL_HTML_UPLOAD, URL_API_NODE } =
        configData.endpoints[env];
      const jwt =
        JWT_DEV_TOKEN !== ''
          ? JWT_DEV_TOKEN
          : (window as Window & typeof globalThis & { __JWTToken: string }).__JWTToken;

      return HtmlEditorService.create({
        api: new APIService(''),
        jwt: jwt,
        url_ema_app_v2: URL_EMA_APP_V2,
        url_ema_app_v1: URL_EMA_APP,
        url_html_upload: URL_HTML_UPLOAD,
        url_api_node: URL_API_NODE,
      });
    } else {
      return StubHtmlEditorService.create();
    }
  }, [env]);

  return service;
};
