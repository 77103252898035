import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo } from 'react';

import { Button, If, Text } from '@/lib/v2/components';

import { Price } from '@/src/infrastructure/interfaces/IResponses';
import { numberToCurrency } from '@/src/utils/currency';

export interface CardPlanProps {
  id: string;
  name: string;
  price?: Price;
  noPrice?: boolean;
  frequency?: keyof Price;
  frequencyPriceSuffix?: string;
  href?: string;
  features?: string[];
  isCurrentPlan?: boolean;
  buttonText: string;
  onClick?: (id: string) => void;
  subTitle?: string;
  includesText?: string;
  moreFeaturesPlanText?: string;
  disabledButton?: boolean;
}

const CardPlan = ({
  id,
  name,
  price,
  frequency,
  frequencyPriceSuffix,
  href,
  features,
  isCurrentPlan,
  buttonText,
  noPrice = false,
  onClick,
  subTitle,
  includesText,
  moreFeaturesPlanText,
  disabledButton,
}: CardPlanProps) => {
  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <div
      key={id}
      className={classNames(
        isCurrentPlan ? 'ring-2 ring-emblue-primary' : 'ring-1 ring-gray-200',
        'rounded-3xl p-8'
      )}
    >
      <Text as="h3" color={'black'} fontWeight="semibold">
        {name}
      </Text>
      <If condition={!noPrice}>
        <div className="mb-4 flex flex-col">
          <Text variant="title-3">
            {price && frequency && numberToCurrency(price[frequency].perMonth)}
            <Text as={'span'} color="light-black" fontWeight="medium" variant="text">
              {frequencyPriceSuffix}
            </Text>
          </Text>
          <Text isItalic className="-mt-2" variant="text">
            {includesText}
          </Text>
        </div>
      </If>
      <If condition={noPrice}>
        <Text className="mb-3 mt-4" variant="title-3">
          {subTitle}
        </Text>
      </If>
      <a href={href} rel="noreferrer" target="_blank">
        <Button
          fullWidth
          primary
          disabled={disabledButton}
          id={name}
          outline={isCurrentPlan}
          standard={isCurrentPlan}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </a>
      <Text className="mb-3 mt-8" color="primary" fontWeight="medium" variant="text">
        {moreFeaturesPlanText}
      </Text>
      <ul className="space-y-3 text-14 leading-6 text-gray-600">
        {features?.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-emblue-primary" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(CardPlan);
