export const ZoomIcon = () => {
  return (
    <svg height="72" id="zoom" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
      <rect
        data-name="Rectangle 3824"
        fill="#2d8cff"
        height="72"
        id="Rectangle_3824"
        rx="15"
        width="72"
      />
      <path
        d="M115.469,175.835a2.48,2.48,0,0,0,2.687-1.131c.283-.424.283-1.131.283-2.687V150.666a5.959,5.959,0,0,0-.283-2.687,1.979,1.979,0,0,0-2.687-1.131c-2.969,1.98-9.474,7.777-9.615,10.181a10.131,10.131,0,0,0-.113,2.121v5.373a10.131,10.131,0,0,0,.113,2.121,4.717,4.717,0,0,0,1.131,2.687c1.7,1.273,7.353,6.363,8.625,6.363ZM64,151.8c0-2.121,0-3.252.424-3.818.283-.566,1.131-1.131,1.555-1.555C66.545,146,67.535,146,69.8,146h18.24c5.373,0,8.06,0,10.181,1.131,1.555,1.131,3.252,2.121,4.242,4.242,1.131,2.121,1.131,4.808,1.131,10.181v9.615c0,2.121,0,3.252-.424,3.818-.283.566-1.131,1.131-1.555,1.555-.566.424-1.555.424-3.818.424H79.554c-5.373,0-8.06,0-10.181-1.131-1.555-1.131-3.252-2.121-4.242-4.242C64,169.472,64,166.786,64,161.412Z"
        data-name="Path 3413"
        fill="#fff"
        id="Path_3413"
        transform="translate(-54.95 -125.356)"
      />
    </svg>
  );
};

export const ZoomImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueLightBlue/25'}>
    <ZoomIcon />
  </div>
);
