import { useAtomValue } from 'jotai';

import { TableSkeleton } from '@/lib/components';
import { Flex } from '@/lib/v2/components';

import { ActionsTable } from '@/src/modules/CampaignsModule/components/RecurrentActions/ActionsTable';

import { ActionBarButtons } from './components/ActionBarButtons';
import { FilterBar } from './components/FilterBar';

import { atomTotalsProcess } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomRecurrentActionsList,
  atomTotalActionsSelectedRows,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

export const TableContainer = () => {
  const actionsList = useAtomValue(atomRecurrentActionsList);
  const totalSelectedRows = useAtomValue(atomTotalActionsSelectedRows);
  const totalsRecurrentList = useAtomValue(atomTotalsProcess);

  return (
    <Flex column>
      {actionsList ? (
        <>
          <Flex alignment="start" className="mb-[16px]">
            {totalSelectedRows > 0 ? (
              <ActionBarButtons />
            ) : (
              <>{totalsRecurrentList.total > 0 && <FilterBar />}</>
            )}
          </Flex>
          <Flex column withGap itemAlignment="stretch">
            <ActionsTable />
          </Flex>
        </>
      ) : (
        <div className="size-full py-10">
          <TableSkeleton columns={6} />
        </div>
      )}
    </Flex>
  );
};
