import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { atomCatalogTemplateMenuBar } from './CatalogTemplateMenuBar.atoms';

export type CatalogTemplateButtonProps = {
  name: string;
  to: string;
  current: boolean;
};

interface IUseCatalogTemplateLink {
  links: CatalogTemplateButtonProps[];
  linksMapper: (item: CatalogTemplateButtonProps, index: number) => JSX.Element;
}

export const useCatalogTemplateLink = (): IUseCatalogTemplateLink => {
  const { t } = useTranslation();
  const { actionId = 0 } = useParams();

  const links: CatalogTemplateButtonProps[] = [
    {
      name: t('BEEFREE_TEMPLATES_GALLERY.gallery'),
      to: `/v2/campaigns/catalogs/email/${actionId}`,
      current: location.pathname === `/v2/campaigns/catalogs/email/${actionId}`,
    },
    {
      name: t('BEEFREE_TEMPLATES_GALLERY.myTemplates'),
      to: `/v2/campaigns/catalogs/email/${actionId}/my-templates`,
      current: location.pathname === `/v2/campaigns/catalogs/email/${actionId}/my-templates`,
    },
  ];

  const linksMapper = (item: CatalogTemplateButtonProps) => {
    const isCurrent = item.current;
    const linkClasses = classNames(
      isCurrent
        ? 'border-[#004DBC] text-[#004DBC] border-b-[5px] py-6 px-3'
        : 'border-transparent text-[#364365] hover:border-gray-300',
      'hover:text-gray-700 inline-flex text-sm py-6 px-3'
    );

    return (
      <NavLink key={item.name} className={linkClasses} to={item.to}>
        {item.name}
      </NavLink>
    );
  };

  return { links, linksMapper };
};
