import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useFiltersActions } from '@/modules/CampaignsModule/hooks/useFiltersActions';
import { EFilterKeysAction } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputSearch = () => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersActions();
  const [reset, setReset] = useState(false);
  const filtersAction = useAtomValue(atomFiltersAction);

  const defaultValue = filtersAction?.search ?? '';
  const placeholder = t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder');
  const id = 'search-campaigns-action';

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      updateFilter(EFilterKeysAction.Search, querySearch);
    },
    [updateFilter]
  );

  const onClearSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
    updateFilter(EFilterKeysAction.Search, '');
  }, [updateFilter]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    id,
    defaultValue,
    placeholder,
    reset,
    handleOnClickSearch,
    onClearSearch,
    resetInputSearch,
  };
};
