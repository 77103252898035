import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { ECustomFieldsByType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';
import { useActionEmailData } from '@/src/modules/CampaignsModule/hooks/useActionEmailData';

import { EButtonType, ISubjectChangeButtonsProps } from './SubjectButtons.interface';

interface ISubjectButtonsProps {
  actionId: number;
  onChangeButtons?: ({ item, type }: ISubjectChangeButtonsProps) => void;
}

interface IOnChangeButtonParams {
  item?: Omit<DropdownItemProps, 'onClick'>;
  type?: EButtonType;
}

export const useSubjectButtons = ({ actionId, onChangeButtons }: ISubjectButtonsProps) => {
  const [tagsList, setTagsList] = useState<{ name: string; ruleId: number }[]>([]);
  const { getShippingTags, loading } = useActionEmailData(Number(actionId));
  const {
    events: { getCustomFieldsByType, translateCustomField },
    state: { loading: loadingCustomField },
  } = useCustomField();

  useEffect(() => {
    const fetchDataButtons = async () => {
      const shippingTagsResult = await getShippingTags();
      setTagsList(shippingTagsResult);
    };
    void fetchDataButtons();
  }, [getShippingTags]);

  const onChangeButton = useCallback(
    ({ item, type }: IOnChangeButtonParams) => {
      let subjectMessage = '';

      if (type === EButtonType.TAG)
        subjectMessage = `$RT{${String(item?.id)}${String(item?.label)}}`;
      if (type === EButtonType.CUSTOM_FIELD)
        subjectMessage = `$\{${String(item?.value)}#${String(item?.label)}}`;
      if (type === EButtonType.EMOJI) subjectMessage = String(item?.value);

      onChangeButtons?.({ item: subjectMessage, type: type as EButtonType });
    },
    [onChangeButtons]
  );

  const tagsItemsList: DropdownItemProps[] = useMemo(() => {
    return (tagsList || []).map(
      (tag): DropdownItemProps => ({
        id: tag.ruleId,
        label: `#${tag.ruleId}º ${tag.name}`,
        value: tag.ruleId,
        onClick: (event) => onChangeButton({ item: event, type: EButtonType.TAG }),
      })
    );
  }, [onChangeButton, tagsList]);

  const customFieldsList = useMemo(() => {
    const fields = getCustomFieldsByType({ type: ECustomFieldsByType.ALL });

    return fields.map(
      (field): DropdownItemProps => ({
        id: field.id,
        label: translateCustomField(field.name),
        value: field.metadata.internalNumber,
        onClick: (event) =>
          onChangeButton?.({
            item: event,
            type: EButtonType.CUSTOM_FIELD,
          }),
      })
    );
  }, [getCustomFieldsByType, onChangeButton, translateCustomField]);

  return {
    events: {
      onChangeButton,
    },
    state: {
      loading: loading || loadingCustomField,
      tagsItemsList,
      customFieldsList,
    },
  };
};
