import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { IContactECommerceActivity } from '@/src/ContactsModule/interfaces/Interfaces';
import { CurrencySymbol } from '@/src/ContactsModule/utils/enums/Enums';

interface IECommerceSummary {
  totalPurchases: string | undefined;
  averageAmount: string | undefined;
}

export const useECommerceSummary = (activities?: {
  list: IContactECommerceActivity[];
}): IECommerceSummary => {
  const [summary, setSummary] = useState<IECommerceSummary>({
    totalPurchases: undefined,
    averageAmount: undefined,
  });

  useEffect(() => {
    if (!activities) {
      return;
    }

    const calculateTotalAmount = (): number => {
      return activities.list.reduce((total: number, activity: IContactECommerceActivity) => {
        if (activity.statusInvoice === 'canceled') {
          return total;
        }
        return total + parseFloat(activity.amount);
      }, 0);
    };

    const calculateAverageAmount = (): number => {
      const numActivities = activities.list.filter(
        (activity) => activity.statusInvoice !== 'canceled'
      ).length;
      if (numActivities === 0) {
        return 0;
      }
      const totalAmount: number = calculateTotalAmount();
      const averageAmount = totalAmount / numActivities;
      return averageAmount;
    };

    const decimalSeparator = t('TIMEZONE.locale') === 'en-US' ? '.' : ',';

    const totalPurchases = `${CurrencySymbol.USD}${calculateTotalAmount()
      .toFixed(2)
      .replace('.', decimalSeparator)}`;
    const averageAmount = `${CurrencySymbol.USD}${calculateAverageAmount()
      .toFixed(2)
      .replace('.', decimalSeparator)}`;
    setSummary({ totalPurchases, averageAmount });
  }, [activities]);

  return summary;
};
