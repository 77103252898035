import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { ContactClickIcon } from '@/lib/icon/ContactClickIcon';
import { ContactMailIcon } from '@/lib/icon/ContactMailIcon';
import { ContactMailOpenedIcon } from '@/lib/icon/ContactMailOpenedIcon copy';
import { ContactMessagesIcon } from '@/lib/icon/ContactMessagesIcon';
import { ColorsIcon } from '@/lib/icon/types';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { InsightCard } from '@/lib/v2/organisms';

import { IContactActivity } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ChannelFilters } from './ChannelFilters';
import { ContactsProfileChannelTable } from '.';

export type BannerCardsProps = {
  value: number | string | undefined;
  description: string;
  tooltip: string;
  icon: JSX.Element;
  iconBgColor: Exclude<ColorsIcon, 'white'>;
};

export interface contactActivityInfo {
  activity?: number;
  messages?: number;
  openings?: number;
  clicks?: number;
}

export const ContactsProfileActivityChannelTable = ({ emailId }: { emailId: number }) => {
  const { t } = useTranslation();
  const [totalChannelContacts, setTotalChannelContacts] = useState<number>();
  const {
    pageSize,
    pageIndex,
    totalPages,
    isLoading,
    setIsLoading,
    changeTablePage,
    gotoPageIndex,
    resetPagination,
  } = useTablePagination(totalChannelContacts);
  const [search, setSearchValue] = useState<string>();
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [channelFilter, setChannelFilter] = useState<number[]>([]);
  const [activityFilter, setActivityFilter] = useState<string[]>([]);
  const [activityChannelListValue] = useEmblue(ServiceMethods.getContactActivityChannel, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    searchActionName: search,
    activityType: activityFilter,
    channels: channelFilter,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const [activityChannelCount] = useEmblue(ServiceMethods.getContactActivityChannelCount, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    searchActionName: search,
    activityType: activityFilter,
    channels: channelFilter,
    orderByColumn: orderColumn,
    orderByDirection: orderDirection,
  });
  const [activityChannelTotalCount] = useEmblue(ServiceMethods.getContactActivityChannelCount, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
  });

  // This method is executed many times, it will be modified with the use of context
  const [activityCount] = useEmblue(ServiceMethods.getContactActivityChannelCount, {
    emailId: Number(emailId),
  });
  const [contactMessagesInfo] = useEmblue(ServiceMethods.getContactActivitySummary, {
    emailId: Number(emailId),
  });
  const { activity, messages, openings, clicks } = {
    activity: activityCount?.count,
    ...contactMessagesInfo,
  };

  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });

  const [activityChannelList, setActivityChannelList] = useState<{ list: IContactActivity[] }>();

  useEffect(() => {
    if (activityChannelListValue) {
      setActivityChannelList(activityChannelListValue);
    }
  }, [activityChannelListValue]);

  const initialStateOrderByList = [
    { id: 'Date', isAsc: undefined },
    { id: 'ActionName', isAsc: undefined },
  ];
  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const cleanBoxRef = useRef<CleanBoxRef>(null);

  const resetActivityList = () => {
    resetPagination();
    setSearchValue('');
    setChannelFilter([]);
    setActivityFilter([]);
    setOrderColumn(undefined);
    setOrderDirection('desc');
    cleanBoxRef?.current?.cleanBox();
    setTableOrderByList(initialStateOrderByList);
  };

  useEffect(() => {
    if (!totalChannelContacts && activityChannelCount) {
      setTotalChannelContacts(activityChannelCount?.count);
    }
  }, [activityChannelList, activityChannelCount, totalChannelContacts]);

  useEffect(() => {
    if (activityChannelCount && activityChannelCount.count !== totalChannelContacts) {
      setTotalChannelContacts(activityChannelCount.count);
    }
  }, [activityChannelCount, totalChannelContacts, search]);

  useEffect(() => {
    activityChannelList && totalChannelContacts && setIsLoading(false);
  }, [activityChannelList, totalChannelContacts]);

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
      setIsLoading(true);
    },
    [orderDirection]
  );

  const contactBannerCards: BannerCardsProps[] = useMemo(
    () => [
      {
        value: activity && activity,
        description: t('CONTACT_PROFILE_BANNER.actions'),
        tooltip: t('CONTACT_PROFILE_BANNER.actionsDesc'),
        icon: <ContactMessagesIcon />,
        iconBgColor: 'primary',
      },
      {
        value: messages && messages,
        description: t('CONTACT_PROFILE_BANNER.messages'),
        tooltip: t('CONTACT_PROFILE_BANNER.messagesDesc'),
        icon: <ContactMailIcon />,
        iconBgColor: 'often',
      },
      {
        value: openings && openings,
        description: t('CONTACT_PROFILE_BANNER.openings'),
        tooltip: t('CONTACT_PROFILE_BANNER.openingsDesc'),
        icon: <ContactMailOpenedIcon />,
        iconBgColor: 'tertiary',
      },
      {
        value: clicks && clicks,
        description: t('CONTACT_PROFILE_BANNER.click'),
        tooltip: t('CONTACT_PROFILE_BANNER.clickDesc'),
        icon: <ContactClickIcon />,
        iconBgColor: 'sleepy',
      },
    ],
    [activity, messages, openings, clicks, t]
  );

  return (
    <>
      <div className="-mb-[32px] py-2">
        {contactBannerCards && (
          <div className="grid w-full grid-cols-1 flex-wrap gap-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4">
            {contactBannerCards.map((card) => (
              <InsightCard
                key={card.description}
                noShadow
                description={card.description}
                icon={
                  <IconSvg
                    rounded
                    bgColor={card.iconBgColor}
                    height="60px"
                    svgComponent={card.icon}
                    width="60px"
                    withPadding="large"
                  />
                }
                id={card.description}
                isLoading={typeof card.value === 'undefined'}
                title={quantifier(card.value) ?? 0}
                titleColor="primary"
                tooltip={card.tooltip}
              />
            ))}
          </div>
        )}
        {activityChannelTotalCount && activityChannelTotalCount.count > 0 && (
          <Flex alignment="start" className="pt-2">
            <ChannelFilters
              cleanBoxRef={cleanBoxRef}
              filterActivity={setActivityFilter}
              filterActivitySelected={activityFilter}
              filterChannel={setChannelFilter}
              filterChannelSelected={channelFilter}
              resetSearch={resetFilter}
              onReset={resetActivityList}
              onSearch={setSearchValue}
            />
          </Flex>
        )}
      </div>
      <Flex column>
        {!activityChannelList && (
          <span className="size-full py-8">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {activityChannelList && activityChannelCount && (
        <ContactsProfileChannelTable
          activityCount={activityChannelCount ? activityChannelCount.count : 0}
          activityList={activityChannelList ? activityChannelList.list : []}
          activityTotalCount={activityChannelTotalCount ? activityChannelTotalCount.count : 0}
          callToAction={[resetActivityList]}
          changeTableOrder={changeOrderBy}
          changeTablePage={changeTablePage}
          gotoPageIndex={gotoPageIndex}
          isLoading={false} // TODO: Remove this in future refactor because the table keep loading infinitely in local
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
