import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface HeaderProps {
  title: string;
  description: string;
}

const Header = ({ title, description }: HeaderProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-4">
      <div className="flex flex-col items-center gap-1">
        <Text fontWeight="medium" variant="headline">
          {title}
        </Text>
        <Text alignment="center" variant="text">
          {description}
        </Text>
      </div>
    </div>
  );
};

export default memo(Header);
