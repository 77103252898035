import classNames from 'classnames';
import { memo, MouseEventHandler, ReactNode } from 'react';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, Flex, IconSvg, Text } from '@/lib/v2/components';

type Variations = 'default' | 'single';
type JustifyContentBase =
  | 'normal'
  | 'start'
  | 'end'
  | 'center'
  | 'between'
  | 'around'
  | 'evenly'
  | 'stretch';

export interface SectionHeaderProps {
  /** Receives an IconSvg component v2 */
  icon?: ReactNode;
  /** Section header title */
  title?: string;
  /** Section header title node */
  titleNode?: ReactNode;
  /** Section header title complement detail {ReactNode | string} */
  titleDetail?: ReactNode | string;
  /** Variation section header {default | single} */
  variation?: Variations;
  /** Show separator between title & titleDetail */
  hasSeparatorTitle?: boolean;
  /** Section header subtitle {ReactNode | string} */
  subtitle?: ReactNode | string;
  /** Show divide line */
  withoutDivide?: boolean;
  /** Show divide line */
  withoutPadding?: boolean;
  /** Receives a ReactNode element for actions */
  actionsButtons?: ReactNode;
  /** Show back button */
  backButton?: boolean;
  /** Callback function when clicking the button */
  onBack?: MouseEventHandler<HTMLButtonElement>;
  /** Change the justify content of the actionsButtons */
  justifyContentActionsButtons?: JustifyContentBase;
  /** Show loading skeleton in titleDetail & subtitle */
  isLoading?: boolean;
  /** Sticky for header in scroll */
  sticky?: boolean;
  /** Shadow on bottom for header */
  hasShadow?: boolean;
}

const SectionHeader = ({
  icon,
  title,
  titleNode,
  titleDetail,
  variation = 'default',
  hasSeparatorTitle,
  subtitle,
  withoutDivide,
  withoutPadding,
  actionsButtons,
  backButton,
  onBack,
  justifyContentActionsButtons = 'end',
  isLoading,
  sticky,
  hasShadow,
}: SectionHeaderProps) => {
  const classes = classNames('flex flex-row gap-3 items-center');
  const classesActionsButtons = classNames(
    'flex flex-row gap-2 flex-none flex-shrink w-full items-center',
    {
      [`justify-${justifyContentActionsButtons}`]: justifyContentActionsButtons,
    }
  );

  return (
    <>
      <div
        className={`${sticky ? 'sticky top-0 z-10 rounded bg-white' : ''} ${
          hasShadow ? 'z-10 shadow-md' : ''
        }w-full ${withoutPadding ? '' : 'p-4'}`}
        data-testid="table-header"
      >
        <div className={classes}>
          <Flex alignment="spaceBetween">
            {isLoading && (
              <div className="flex w-full animate-pulse flex-col">
                <div className="my-2 h-4 w-1/4 rounded-full bg-gray-200"></div>
                <div className="my-1 h-2 w-1/4 rounded-full bg-gray-200"></div>
              </div>
            )}
            {!isLoading && (
              <>
                <Flex withGap alignment="start">
                  {backButton && (
                    <Button standard className="mr-2" id="back-button" size="tiny" onClick={onBack}>
                      <IconSvg svgComponent={<ArrowBackIcon />} />
                    </Button>
                  )}
                  {icon && (
                    <div className="h-full">
                      <IconSvg
                        fullWidth
                        rounded
                        bgColor="headingIcon"
                        svgComponent={icon}
                        withPadding="medium"
                      />
                    </div>
                  )}
                  <div className="flex flex-col">
                    <Text
                      as="h3"
                      className="!2xl:text-20 !text-16"
                      fontWeight="medium"
                      variant="title-1"
                    >
                      {title}
                      {hasSeparatorTitle && (
                        <Text
                          as="span"
                          className="!2xl:text-20 !text-16 text-emblue-primary"
                          fontWeight="medium"
                          variant="title-1"
                        >
                          {' - '}
                        </Text>
                      )}
                      {titleDetail && (
                        <Text
                          as="span"
                          className="!2xl:text-20 !text-16 text-emblue-primary"
                          fontWeight="medium"
                          variant="title-1"
                        >
                          {titleDetail}
                        </Text>
                      )}
                    </Text>
                    {titleNode ? titleNode : null}
                    {variation === 'default' && (
                      <Text as="h5" className="!text-14 text-emblue-subheadings">
                        {subtitle}
                      </Text>
                    )}
                  </div>
                </Flex>
                {actionsButtons && <div className={classesActionsButtons}>{actionsButtons}</div>}
              </>
            )}
          </Flex>
        </div>
      </div>
      {!withoutDivide && <div className="h-[1px] w-full bg-[#e5e7eb]"></div>}
    </>
  );
};

export default memo(SectionHeader);
