export const RemoveIcon = () => {
  return (
    <svg height="21.9" viewBox="0 0 17.033 21.9" width="17.033" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.717,23.966A2.44,2.44,0,0,0,11.15,26.4h9.733a2.44,2.44,0,0,0,2.433-2.433V9.367H8.717ZM11.71,15.3l1.715-1.715,2.591,2.579L18.6,13.588,20.311,15.3l-2.579,2.579,2.579,2.579L18.6,22.178,16.017,19.6l-2.579,2.579-1.715-1.715L14.3,17.883Zm8.565-9.587L19.058,4.5H12.975L11.758,5.717H7.5V8.15H24.533V5.717Z"
        data-name="Icon material-delete-forever"
        fill="#004dbc"
        id="Icon_material-delete-forever"
        transform="translate(-7.5 -4.5)"
      />
    </svg>
  );
};
