export const ShoppingCartIcon = () => {
  return (
    <svg height="20" viewBox="0 0 37.997 37.011" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="shopping-cart-21-svgrepo-com" transform="translate(-0.001 -6.639)">
        <path
          d="M147.082,434.225a2.654,2.654,0,1,0,.962,1.168A2.646,2.646,0,0,0,147.082,434.225Zm-.5,2.619a1.079,1.079,0,0,1-.389.472,1.068,1.068,0,0,1-1.014.1,1.071,1.071,0,0,1,.417-2.058,1.059,1.059,0,0,1,.416.084,1.074,1.074,0,0,1,.472.389,1.075,1.075,0,0,1,.1,1.014Z"
          data-name="Path 4561"
          fill="#9c59df"
          id="Path_4561"
          transform="translate(-132.332 -395.433)"
        />
        <path
          d="M12.522,35.3a2.059,2.059,0,0,1-2.061-2.011,2.032,2.032,0,0,1,.441-1.253,1.987,1.987,0,0,1,.529-.455,2.46,2.46,0,0,1,.748-.287l18.133-2.951a2.89,2.89,0,0,0,2.366-2.276l2.218-10.9h0a1.925,1.925,0,0,0-1.887-2.308H7.9L7.045,9.914h0a3.756,3.756,0,0,0-2.811-2.6l-3.07-.657a.962.962,0,1,0-.4,1.882L3.831,9.2A1.828,1.828,0,0,1,5.2,10.466L10.81,29.734a3.937,3.937,0,0,0-1.781,1.65,4.006,4.006,0,0,0-.492,1.853h0v.1h0a3.986,3.986,0,0,0,3.985,3.888H22.709c0-.1-.014-.188-.014-.283a8.566,8.566,0,0,1,.161-1.642H12.522Zm13.71-19.14a1.019,1.019,0,0,1,1.264.693l2.066,7.071A1.019,1.019,0,0,1,27.6,24.5l-2.065-7.071A1.02,1.02,0,0,1,26.232,16.16Zm-4.457.018a1.018,1.018,0,0,1,1.264.692L25.3,24.62a1.019,1.019,0,1,1-1.957.571l-2.263-7.749A1.018,1.018,0,0,1,21.774,16.179Zm-4.459.016a1.019,1.019,0,0,1,1.264.693l2.449,8.384a1.019,1.019,0,1,1-1.957.571l-2.449-8.384A1.02,1.02,0,0,1,17.316,16.195Zm-4.458.018a1.019,1.019,0,0,1,1.264.693l2.64,9.039a1.019,1.019,0,1,1-1.957.572l-2.64-9.04A1.019,1.019,0,0,1,12.858,16.213Z"
          data-name="Path 4562"
          fill="#9c59df"
          id="Path_4562"
        />
        <path
          d="M337.916,324.561a6.709,6.709,0,1,0,6.708,6.709A6.708,6.708,0,0,0,337.916,324.561Zm-.38,9.843-3.207-2.566,1.217-1.521,1.664,1.332,2.8-3.6,1.539,1.2Z"
          data-name="Path 4563"
          fill="#9c59df"
          id="Path_4563"
          transform="translate(-306.628 -294.328)"
        />
      </g>
    </svg>
  );
};
