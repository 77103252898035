import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StatusBadge } from '@/lib/components';
import { CountryIcon, GenderIcon } from '@/lib/icon';
import { Avatar, IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import {
  AddUserIcon,
  BrowserIcon,
  ContactActivityIcon,
  EmailIcon,
  PhoneIcon,
  UpdateIcon,
} from '@/lib/v2/icons/outline';
import { HeaderBanner } from '@/lib/v2/organisms';

import useWindowWidth from '@/src/application/hooks/useWindowWidth';
import { IContactCustom } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import { IContactExtendedData } from '@/src/ContactsModule/interfaces/Interfaces';
import extractContactData from '@/src/ContactsModule/utils/extractContactData';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useContactInfo } from '@/src/infrastructure/services/useContactInfo';
import { useDate } from '@/src/infrastructure/services/useDate';

import ContactsProfileButtons from './ContactsProfileButtons/ContactsProfileButtons';

import { DiscaredStatus } from '@/modules/ContactsModule/types/Contacts';

interface IContactInfo {
  name: string;
  lastName: string;
  status: 'new' | 'frequent' | 'asleep' | 'inactive' | 'discarded' | undefined;
  subStatus: string;
  email: string;
  phone: string;
  gender: string;
  country: string;
}

const HeaderBannerProfile = ({
  emailId,
  contactDetailInfo,
}: {
  emailId: number;
  contactDetailInfo?: IContactCustom;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lastRefresh, setLastRefresh] = useState(0);

  const [contactInfo] = useContactInfo(Number(emailId), lastRefresh);

  const [extendedData] = useEmblue(ServiceMethods.getContactExtendedData, {
    emailId: Number(emailId),
  });
  // TO DO: change this hook to tailwind responsive css, many re renders
  const windowWidth = useWindowWidth();
  const { dateFormatter } = useDate();

  const [formattedContactExtendedData, setFormattedContactExtendedData] =
    useState<IContactExtendedData>();

  useEffect(() => {
    setFormattedContactExtendedData(extendedData && extendedData.contactExtendedData);
  }, [extendedData]);

  const contactData = useMemo(() => {
    return contactDetailInfo && extractContactData(contactDetailInfo);
  }, [contactDetailInfo]);

  const { name, lastName, status, subStatus, email, phone, gender, country }: IContactInfo =
    useMemo(() => {
      return {
        name: contactData?.names || '',
        lastName: contactData?.lastNames || '',
        status: contactInfo?.status || undefined,
        subStatus: contactInfo?.discarded || '',
        email: contactInfo?.email || '',
        phone: contactData?.mainPhone || '',
        gender: contactData?.gender ? t(`CONTACT_INFORMATION.${contactData?.gender}`) : '',
        country: contactData?.country || '',
      };
    }, [contactInfo, contactData, t]);

  const avatar = useMemo(() => {
    return (
      <Avatar key={emailId} sizeL email={email} lastName={lastName} name={name} status={status} />
    );
  }, [email, emailId, lastName, name, status]);

  const content = useMemo(() => {
    return [
      {
        id: 0,
        text: email,
        icon: <EmailIcon />,
      },
      {
        id: 1,
        text: phone,
        icon: <PhoneIcon />,
      },
      {
        id: 2,
        text: country,
        icon: <CountryIcon />,
      },
      {
        id: 3,
        text: gender,
        icon: <GenderIcon />,
      },
    ];
  }, [country, email, gender, phone]);

  const footerContent = useMemo(() => {
    return [
      {
        id: 0,
        title: t('CONTACTS_PROFILE.created'),
        value:
          formattedContactExtendedData && formattedContactExtendedData.created
            ? dateFormatter(formattedContactExtendedData.created)
            : null,
        icon: <AddUserIcon />,
        fallback: t('CONTACTS_PROFILE.withoutRegistry'),
      },
      {
        id: 1,
        title: t('CONTACTS_PROFILE.update'),
        value:
          formattedContactExtendedData && formattedContactExtendedData.lastUpdated
            ? dateFormatter(formattedContactExtendedData.lastUpdated)
            : null,
        icon: <UpdateIcon />,
        fallback: t('CONTACTS_PROFILE.withoutRegistry'),
      },
      {
        id: 2,
        title: t('CONTACTS_PROFILE.lastWebsiteVisit'),
        value:
          formattedContactExtendedData && formattedContactExtendedData.lastWebsiteVisit
            ? dateFormatter(formattedContactExtendedData.lastWebsiteVisit)
            : null,
        icon: <BrowserIcon />,
        fallback: t('CONTACTS_PROFILE.withoutRegistry'),
      },
      {
        id: 3,
        title: t('CONTACTS_PROFILE.lastActivity'),
        value:
          formattedContactExtendedData && formattedContactExtendedData.lastActivity
            ? dateFormatter(formattedContactExtendedData.lastActivity)
            : null,
        icon: <ContactActivityIcon />,
        fallback: t('CONTACTS_PROFILE.withoutRegistry'),
      },
    ];
  }, [dateFormatter, formattedContactExtendedData, t]);

  const renderContent = useMemo(() => {
    return (
      <>
        <Flex withGap alignment="start">
          {!name && !lastName ? (
            <div className="font-medium xl:text-[16px] 2xl:text-[20px]">
              {t('CONTACTS_PROFILE.myEmBlueContact')}
            </div>
          ) : (
            <div
              className="max-w-[500px] truncate text-[24px] font-medium xl:text-[16px] 2xl:text-[20px]"
              title={`${name ? name : ''} ${lastName ? lastName : ''}`}
            >
              {name} {lastName}
            </div>
          )}
          <p className="text-left text-[15px]">- ID: {emailId}</p>
          <StatusBadge status={status || ''} subStatus={subStatus || ''} />
        </Flex>
        <Flex
          noGrow
          withGap
          alignment="start"
          className="max-w-[800px]"
          gapSize="medium"
          itemAlignment="start"
        >
          {content.map((subtitle) => {
            if (!subtitle.text || subtitle.text === t('SELECT.selectItem')) {
              return null;
            }
            return (
              <Flex
                key={subtitle.id}
                noGrow
                alignment="start"
                className={`w-fit ${
                  windowWidth <= 1536 ? 'max-w-[245px] truncate' : ''
                } text-[14px] font-medium text-[#111827]`}
              >
                {subtitle.text && (
                  <IconSvg
                    className="mr-1"
                    height="18px"
                    svgComponent={subtitle.icon}
                    width="18px"
                  />
                )}
                <div className="truncate whitespace-nowrap">{subtitle.text}</div>
              </Flex>
            );
          })}
        </Flex>
      </>
    );
  }, [status, subStatus, name, lastName, t, content, windowWidth]);

  const renderExtraInfo = useMemo(() => {
    const borderClasses = ['border-r', 'border-x', 'border-x', 'border-l'];
    return (
      <Flex noGrow alignment="spaceEvenly" className="h-11">
        {footerContent &&
          footerContent.map((footer, index) => {
            return (
              <Flex
                key={footer.id}
                withGap
                alignment="center"
                className={`align-text-bottom ${borderClasses[index]}`}
                gapSize="small"
                itemAlignment="center"
              >
                {footer && footer.value ? (
                  <>
                    {windowWidth <= 1536 ? (
                      <IconSvg
                        svgComponent={footer.icon}
                        title={footer.title}
                        withPadding="small"
                      />
                    ) : (
                      <span className="text-[12px] text-[#364365]">{footer.title}</span>
                    )}
                    <span className="text-[12px] text-[#111827]">
                      <>{footer.value}</>
                    </span>
                  </>
                ) : (
                  <>
                    {windowWidth <= 1536 ? (
                      <IconSvg
                        svgComponent={footer.icon}
                        title={footer.title}
                        withPadding="small"
                      />
                    ) : (
                      <span className="text-[12px] text-[#364365]">{footer.title}</span>
                    )}
                    <span className="text-[12px] text-[#111827]">{footer.fallback ?? ''}</span>
                  </>
                )}
              </Flex>
            );
          })}
      </Flex>
    );
  }, [footerContent, windowWidth]);

  const isDiscardedUser =
    (contactInfo?.discarded as DiscaredStatus) === 'suspended' ||
    (contactInfo?.discarded as DiscaredStatus) === 'quarantine';

  return (
    <HeaderBanner
      backButton
      withFooter
      actionsButtons={
        <ContactsProfileButtons
          emailId={emailId}
          havePhoneNumber={!!phone}
          isDiscardedUser={isDiscardedUser}
          setLastRefresh={setLastRefresh}
        />
      }
      content={renderContent}
      footerContent={renderExtraInfo}
      icon={avatar}
      onBack={() => navigate && navigate(-1)}
    />
  );
};
export default memo(HeaderBannerProfile);
