export const ArrowCircleIcon = () => (
  <svg
    className="h-5 w-5"
    data-name="Group 3942"
    id="Group_3942"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.293,9.707a1,1,0,0,1,0-1.414l4-4a1,1,0,0,1,1.414,0l4,4a1,1,0,1,1-1.414,1.414L11,7.414V15a1,1,0,0,1-2,0V7.414L6.707,9.707a1,1,0,0,1-1.414,0Z"
      data-name="Path 3284"
      fill="#fff"
      fillRule="evenodd"
      id="Path_3284"
      transform="translate(2.126 2)"
    />
    <g data-name="Ellipse 1" fill="none" id="Ellipse_1" stroke="#fff" strokeWidth="2">
      <circle cx="12" cy="12" r="12" stroke="none" />
      <circle cx="12" cy="12" fill="none" r="11" />
    </g>
  </svg>
);
