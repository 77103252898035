export const DeviceIcon = () => {
  return (
    <svg height="14.37" viewBox="0 0 16 14.37" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.781,14.252H2.658a1.79,1.79,0,0,1-.553-.055A1.383,1.383,0,0,1,1,12.87V5.382A1.393,1.393,0,0,1,2.216,4.028C2.354,4.028,2.465,4,2.6,4H14.375A1.439,1.439,0,0,1,15.95,5.575c0,.359.028.718,0,1.105a.542.542,0,0,0,.332.608A1.335,1.335,0,0,1,17,8.532v8.373a1.459,1.459,0,0,1-1.271,1.437c-.111,0-.221.028-.359.028H4.924a1.325,1.325,0,0,1-1.437-1.078,1.286,1.286,0,0,1,1.188-1.52h.718c.083,0,.221-.111.221-.166C5.67,15.136,5.725,14.722,5.781,14.252ZM14.955,7.04V5.658c0-.525-.111-.636-.636-.636H2.658c-.525,0-.636.111-.636.636v6.936c0,.525.111.663.636.663h7.489V8.615a1.473,1.473,0,0,1,1.52-1.547h1.216C13.546,7.04,14.237,7.04,14.955,7.04ZM16.033,12.7V8.725c0-.525-.166-.691-.663-.691H11.777c-.47,0-.636.166-.636.636v8.041c0,.47.166.636.636.636H15.37c.5,0,.663-.166.663-.663ZM10.092,14.28H6.775c-.055.5-.111.967-.166,1.465h3.482Zm.083,3.067a4.549,4.549,0,0,1-.055-.58.1.1,0,0,0-.083-.028H4.813a.476.476,0,0,0-.332.276c-.055.193.138.3.332.3H5.836C7.273,17.347,8.71,17.347,10.174,17.347Z"
        data-name="Path 3202"
        fill="#005adc"
        id="Path_3202"
        transform="translate(-1 -4)"
      />
    </svg>
  );
};
