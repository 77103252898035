/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEventHandler, ReactNode } from 'react';

import { Flex } from '@/lib/v2/components/Layout/Stack';

import withDeprecated from '@/src/application/HOCs/WithDeprecated';

import './Button.tailwind.css';

export type ButtonProps = {
  children?: JSX.Element | string;
  id?: string;
  Icon?: JSX.Element | ReactNode;
  onAction?: MouseEventHandler<HTMLButtonElement> | (() => void);
  typeButton?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  stroked?: boolean;
  plain?: boolean;
  link?: boolean;
  roundedFull?: boolean;
  rightRounded?: boolean;
  deleted?: boolean;
  tooltip?: string;
  large?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  standard?: boolean;
  cancel?: boolean;
};

const _Button = ({
  standard,
  Icon,
  children,
  onAction,
  link,
  fullWidth,
  stroked,
  plain,
  deleted,
  tooltip,
  roundedFull,
  rightRounded,
  large,
  secondary,
  disabled,
  cancel,
  typeButton = 'button',
}: ButtonProps) => {
  const type = 'primary';
  return (
    <button
      className={`text-sm h-[38px] items-center rounded-md border border-transparent px-3 py-2 text-center
                font-medium
                leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                ${type} ${fullWidth ? 'w-full' : ''}
                ${secondary ? 'secondary' : ''}
                ${stroked ? 'stroked' : ''}
                ${plain ? 'plain' : ''}
                ${link ? 'link rounded-none !text-black shadow-none' : ''}
                ${roundedFull ? 'rounded-full p-0' : ''}
                ${rightRounded ? 'h-9 rounded-l-sm px-4 py-0' : ''}
                ${deleted ? 'deleted' : ''}
                ${large ? 'h-[50px] w-[120px]' : ''}
                ${disabled ? 'opacity-25' : ''}
                ${standard ? 'standard' : ''}
                ${cancel ? '!bg-slate-200 !text-black hover:!bg-slate-100' : ''}
                `}
      disabled={disabled}
      title={tooltip || ''}
      type={typeButton}
      onClick={onAction as any}
    >
      <Flex withGap alignment={'center'} gapSize="small">
        {Icon as any}
        {typeof children === 'string' ? <span>{children}</span> : children}
      </Flex>
    </button>
  );
};

/**
 * @deprecated use Button version 2. import { Button } from "@lib/v2/components";
 */
export const Button = withDeprecated(
  _Button,
  'use Button v2 -> import { Button } from "@lib/v2/components";'
);
