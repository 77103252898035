import { datadogRum } from '@datadog/browser-rum';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import {
  ActionMasterIcon,
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailTriggerIcon,
  SmsActionIcon,
  SmsAPIIcon,
} from '@/lib/v2/icons/outline';
import { FolderIcon } from '@/lib/v2/icons/solid';

import { useUserData } from '@/src/infrastructure/services/useUserData';
import { useSearchCampaignsData } from '@/src/modules/CampaignsModule/components/SearchCampaigns/hooks/useSearchCampaignsData';

import {
  atomHasMoreSearchCampaigns,
  atomKeywordSearchCampaigns,
  atomListSearchCampaigns,
  atomPayloadSearchCampaigns,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  DROPDOWN_ACTIONS_STATE,
  DROPDOWN_CHANNELS,
  DROPDOWN_STATE,
  LIST_ACTIONS_STATE,
  RECURRENT_CAMPAIGN,
  STATES_PROCESS_ACTION,
  VARIATION_STATUS_BADGE,
} from '@/modules/CampaignsModule/constants';
import {
  IListSearchCampaigns,
  ISearchSourceType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useSearchCampaignsItems = () => {
  const { t } = useTranslation();
  const [userData] = useUserData();

  const [payloadSearchCampaigns, setPayloadSearchCampaigns] = useAtom(atomPayloadSearchCampaigns);
  const hasMoreSearchCampaigns = useAtomValue(atomHasMoreSearchCampaigns);
  const setListSearchCampaigns = useSetAtom(atomListSearchCampaigns);
  const setKeywordSearchCampaigns = useSetAtom(atomKeywordSearchCampaigns);

  const listRef = useRef<HTMLDivElement>(null);

  const { searchCampaignsGlobal, loadingMore } = useSearchCampaignsData();

  const CAMPAIGN_STATE_ICONS = useMemo<{ [key: number]: JSX.Element }>(
    () => ({
      1: <FolderIcon />,
      2: <ActionMasterIcon />,
    }),
    []
  );

  const ACTION_TYPE_ICONS = useMemo<{ [key: number]: JSX.Element }>(
    () => ({
      1: <EmailActionIcon />,
      3: <EmailTriggerIcon />,
      4: <EmailAPIIcon />,
      5: <SmsAPIIcon />,
      8: <SmsActionIcon />,
      9: <EmailAttachmentIcon />,
    }),
    []
  );

  const ACTION_TYPE_PATH = useMemo<{ [key: number]: string }>(
    () => ({
      1: 'email',
      3: 'email-trigger',
      4: 'mail-api',
      5: 'sms-api',
      8: 'sms',
      9: 'email-attachment',
    }),
    []
  );

  const getStateLabelColor = (status: number, source: string) => {
    const isCampaign = source === ISearchSourceType.CAMPAIGN;

    const stateText = isCampaign
      ? DROPDOWN_STATE.find((state) => state.value === status)
      : DROPDOWN_ACTIONS_STATE.find((state) => state.value === status);

    const stateColor = !isCampaign
      ? LIST_ACTIONS_STATE.find((actionState) => actionState.values.includes(status))
      : undefined;

    return {
      text: stateText?.label,
      variantColor: isCampaign ? VARIATION_STATUS_BADGE[stateText?.label ?? ''] : stateColor?.badge,
    };
  };

  const getLabelByType = (type: number, source: string): string => {
    if (source === ISearchSourceType.CAMPAIGN) return t(`PROFILE_CHANNEL_TABLE.${source}`);

    const channelItem = DROPDOWN_CHANNELS.find((channel) => channel.value === type) || {
      label: '',
    };

    return t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${channelItem.label}`);
  };

  const handleScroll = useCallback(async () => {
    if (listRef?.current) {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      const roundedScrollHeight = Math.round(scrollHeight);
      const roundedScrollTop = Math.round(scrollTop);
      const roundedClientHeight = Math.round(clientHeight);

      if (roundedScrollHeight - roundedScrollTop <= roundedClientHeight) {
        if (!hasMoreSearchCampaigns) return;

        await searchCampaignsGlobal({
          keyword: payloadSearchCampaigns.search,
          page: payloadSearchCampaigns.page + 1,
        });
      }
    }
  }, [
    hasMoreSearchCampaigns,
    listRef,
    payloadSearchCampaigns.page,
    payloadSearchCampaigns.search,
    searchCampaignsGlobal,
  ]);

  const getLinkByType = useCallback(
    (item: IListSearchCampaigns) => {
      const { id, type, source, actionId } = item;
      const basePath = '/v2/campaigns';

      if (source === ISearchSourceType.CAMPAIGN) {
        return type === RECURRENT_CAMPAIGN ? `${basePath}/recurrent/${id}` : `${basePath}/${id}`;
      }
      if (STATES_PROCESS_ACTION.finished.includes(item.status)) {
        return `${basePath}/${id}/${ACTION_TYPE_PATH[type]}/${actionId}/summary`;
      }

      return `${basePath}/${id}/${ACTION_TYPE_PATH[type]}/${actionId}`;
    },
    [ACTION_TYPE_PATH]
  );

  const getStatusColor = useCallback(
    (statusId: number): { badge: string; background: ColorsIcon } | null => {
      const group = LIST_ACTIONS_STATE.find((group) => group.values.includes(statusId));
      return group
        ? {
            badge: group.badge,
            background: group.background,
          }
        : null;
    },
    []
  );

  const getColorsByType = useCallback(
    (item: IListSearchCampaigns) => {
      const { source, type, status } = item;

      if (type === RECURRENT_CAMPAIGN) {
        return status === 1
          ? { background: 'success_new', fill: 'success_new' }
          : { background: 'inactive', fill: 'inactive' };
      }
      if (source === ISearchSourceType.CAMPAIGN)
        return { background: 'inactive', fill: 'inactive' };

      return getStatusColor(status);
    },
    [getStatusColor]
  );

  const handleDatadogClick = useCallback(() => {
    setListSearchCampaigns(undefined);
    setKeywordSearchCampaigns('');
    setPayloadSearchCampaigns({ ...payloadSearchCampaigns, search: '' });

    //TODO: move this metrics to custom hook for Datadog Search Campaigns
    datadogRum.addAction('searchCampaign.onSearchClick', {
      accountId: Number(userData.companyId),
      email: userData.email,
      keyword: payloadSearchCampaigns.search,
    });
  }, [
    setListSearchCampaigns,
    setKeywordSearchCampaigns,
    setPayloadSearchCampaigns,
    payloadSearchCampaigns,
    userData,
  ]);

  return {
    events: {
      getStateLabelColor,
      getLabelByType,
      getLinkByType,
      getColorsByType,
      handleDatadogClick,
      handleScroll,
    },
    state: {
      listRef,
      loadingMore,
    },
    constants: {
      CAMPAIGN_STATE_ICONS,
      ACTION_TYPE_ICONS,
      ACTION_TYPE_PATH,
    },
  };
};
