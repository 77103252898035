import classNames from 'classnames';
import { FC, memo, ReactNode } from 'react';

type Padding = 'small' | 'medium' | 'large' | 'extraLarge';
interface CardSectionProps {
  /** receives any element or string as a child  */
  children?: ReactNode;
  /** remove padding to section container */
  noPadding?: boolean;
  /** show the card section disabled */
  disabled?: boolean;
  /** extra class names */
  className?: string;
  paddingX?: Padding;
  paddingY?: Padding;
  overflowHidden?: boolean;
}

const CardSection: FC<CardSectionProps> = ({
  children,
  noPadding = false,
  disabled,
  className,
  paddingX = 'small',
  paddingY = 'small',
  overflowHidden = false,
}): JSX.Element => {
  const classes = classNames(
    'eb-card-section',
    {
      '!p-0': noPadding,
      'px-4': paddingX === 'small',
      'py-4': paddingY === 'small',
      'px-6': paddingX === 'medium',
      'py-6': paddingY === 'medium',
      'px-8': paddingX === 'large',
      'py-8': paddingY === 'large',
      'px-10': paddingX === 'extraLarge',
      'py-10': paddingY === 'extraLarge',
      'opacity-50	': disabled,
      'overflow-hidden': overflowHidden,
    },
    className
  );

  return <div className={classes}>{children}</div>;
};

export default memo(CardSection);
