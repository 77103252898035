import { memo, useEffect } from 'react';

interface IframeOnsiteProps {
  idName?: string;
  title?: string;
  urlIframe?: string;
}

const IframeOnsite = ({ idName = '', title, urlIframe }: IframeOnsiteProps) => {
  useEffect(() => {
    const iframeElement = document.getElementById(idName) as HTMLIFrameElement;

    const loadListener = () => {
      const iframeWindow = iframeElement.contentWindow;

      if (iframeWindow && typeof iframeWindow.createNewWidgetV2 === 'function') {
        iframeWindow.createNewWidgetV2('nps');
      }
    };

    if (iframeElement) {
      iframeElement.addEventListener('load', loadListener);
    }

    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener('load', loadListener);
      }
    };
  }, [idName]);

  return (
    <div id={idName} style={{ width: '100%', height: '600px' }}>
      <iframe height="100%" src={urlIframe} title={title} width="100%" />
    </div>
  );
};
export default memo(IframeOnsite);
