import { ReactNode, SyntheticEvent } from 'react';

import { Button, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import IndeterminateBar from './SubComponents/Indeterminate';
import ProgressBar from './SubComponents/Progress';

interface IProgressNotification {
  icon: ReactNode;
  onAction?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  percentage?: number;
  hideBar?: boolean;
  error?: boolean;
  indeterminate?: boolean;
  wordings: {
    action?: string;
    message: string;
  };
}

const ProgressNotification = (props: IProgressNotification) => {
  const {
    icon,
    wordings,
    percentage,
    error = false,
    hideBar,
    indeterminate,
    onAction,
    isLoading,
  } = props;

  return (
    <Flex
      noGrow
      withGap
      alignment="start"
      className="w-[475px] rounded-lg border-2 border-gray-200 bg-[#F4F5FA] p-4 shadow-2xl"
      itemAlignment="center"
    >
      {/* Icon */}
      <Flex noGrow>{icon}</Flex>

      {/* Percentage */}
      <Flex column className="mx-2">
        {!hideBar && percentage && !indeterminate && (
          <ProgressBar error={error} percentage={percentage} />
        )}
        {indeterminate && !hideBar && <IndeterminateBar />}
        <Flex className={hideBar ? '' : 'mt-2'}>
          <Text>{wordings.message}</Text>

          {!hideBar && !error && !indeterminate && <Text>{percentage} %</Text>}
        </Flex>
      </Flex>

      {/* Action */}
      {wordings?.action && (
        <Flex noGrow alignment="end">
          <Button isLoading={isLoading} onClick={onAction}>
            {wordings?.action}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default ProgressNotification;
