export const PencilIcon = () => {
  return (
    <svg
      height="20.076"
      viewBox="0 0 20.875 20.076"
      width="20.875"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0.5 0.671)">
        <path
          d="M18,30h7.49"
          fill="none"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.615 -11.095)"
        />
        <path
          d="M19.595,5a2.393,2.393,0,0,1,3.354,0,2.322,2.322,0,0,1,0,3.313L8.972,22.119,4.5,23.223l1.118-4.417Z"
          fill="#004dbc"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-4.5 -4.318)"
        />
      </g>
    </svg>
  );
};
