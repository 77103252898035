import { createContext, isValidElement, ReactElement } from 'react';

import { useGetContext } from '@/src/utils/ContextUtils';

import TabContent, { TabContentProps } from './TabContent';
import TabNav, { TabNavProps } from './TabNav';

interface TabProps {
  children?: [ReactElement<TabNavProps>, ReactElement<TabContentProps>];
  currentTab: string | number;
}

interface StateContext {
  currentTab: string | number;
}

const StateTabContext = createContext<StateContext | undefined>(undefined);

const Tab = ({ children, currentTab }: TabProps) => {
  const initialState = { currentTab };

  if (!children) {
    return <h3>TabNav and TabContent were not provided as children of Tab.</h3>;
  }

  const [tabNav, tabContent] = children;

  if (
    !isValidElement(tabNav) ||
    !isValidElement(tabContent) ||
    tabNav.type !== TabNav ||
    tabContent.type !== TabContent
  ) {
    throw new Error(
      'The "children" prop in Tab must contain first one TabNav component and the second one TabContent component.'
    );
  }

  return (
    <StateTabContext.Provider value={initialState}>
      <div className="flex h-full w-full flex-col gap-4">{children}</div>
    </StateTabContext.Provider>
  );
};

export const useStateTabContext = (): StateContext =>
  useGetContext<StateContext>(StateTabContext, 'Tab component context');

export default Tab;
