import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';

import { Modal } from '@/lib/v2/components';

import { atomModalPreview } from '@/src/atoms/Catalogs';

const PreviewTemplate = () => {
  const [{ show, html }, setModalPreview] = useAtom(atomModalPreview);

  const handleClose = useCallback(() => {
    setModalPreview(RESET);
  }, [setModalPreview]);

  return (
    <Modal
      externalClose
      noPadding
      withAuto
      open={show}
      showCloseButton={false}
      onClose={handleClose}
    >
      <div className="w-[80vw] max-w-[800px] overflow-hidden rounded-lg" id="iframeShadow">
        <iframe className="h-[90vh] max-h-[900px] w-full" srcDoc={html} title="preview" />
      </div>
    </Modal>
  );
};

export default PreviewTemplate;
