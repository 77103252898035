import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GroupTableIcon } from '@/lib/icon';
import { Card, CardSection, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ContactsTableContainer } from '@/src/ContactsModule/components/ContactsTableContainer/ContactsTableContainer';
import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import SectionHeaderGroup from '@/modules/ContactsModule/components/SectionHeaderGroup/SectionHeaderGroup';
import { useFilterState } from '@/modules/ContactsModule/hooks/useFilterState';

export const GroupDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [subtitleCountMessage, setSubtitleCountMessage] = useState<ReactNode>();
  const [contactsCountFiltered, setContactsCountFiltered] = useState<number>();
  const [group, setGroup] = useState<IGroup>();
  const [groupInfo] = useEmblue(ServiceMethods.getGroupInfo, { groupId: Number(groupId) });
  const { isFilterApplied } = useFilterState();

  useEffect(() => {
    if (groupInfo && !group) {
      setGroup(groupInfo.groupInfo);
    }
  }, [group, groupInfo]);

  const title = useMemo(() => {
    return (
      <Flex column itemAlignment="start">
        <Flex withGap alignment="start">
          <Text as="span" fontWeight="medium" variant="title-1">
            {t('GROUPS_MAIN.group')} -
          </Text>
          {group && (
            <div className="text-[24px] font-medium text-[#004DBC]"> {group.groupName} </div>
          )}
          {
            group && group.contacts && (
              <span className="text-[24px] font-medium text-[#004DBC]">
                (
                {quantifier(
                  contactsCountFiltered && contactsCountFiltered > group?.contacts
                    ? contactsCountFiltered
                    : group.contacts
                )}
                )
              </span>
            ) // In case of action executed and group?.contacts is cached
          }
        </Flex>
        {isFilterApplied
          ? subtitleCountMessage && subtitleCountMessage
          : t('CONTACTS_MAIN.noFilters')}
      </Flex>
    );
  }, [isFilterApplied, contactsCountFiltered, group, subtitleCountMessage, t]);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeaderGroup
            backButton
            icon={<GroupTableIcon />}
            title={title}
            onBack={() => navigate({ pathname: '/v2/contacts/groups' })}
          />
          <div className="size-full">
            <ContactsTableContainer
              isDisableGroupDropdown
              setContactsCountFiltered={setContactsCountFiltered}
              setCountMessage={setSubtitleCountMessage}
            />
          </div>
        </Flex>
      </CardSection>
    </Card>
  );
};
