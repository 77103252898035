import { useCallback } from 'react';

type ButtonsEditorConfig = Record<string, string[]>;

const buttonsEditorToHide: ButtonsEditorConfig = {
  'email-split': ['content-way'],
  'email-remarketing': ['source-beefree', 'import-way', 'source-way', 'source-html-editor'],
};

const useEmailButtonsEditorV1 = () => {
  const emailButtonEditorToggle = useCallback(
    (iframeDocument: HTMLIFrameElement | undefined, actionType: string) => {
      if (!iframeDocument) return;

      const iframeRender = iframeDocument?.contentWindow?.document;
      const containerHTMLModuleV1 = iframeRender?.querySelector(
        'body #main div#html-module'
      ) as HTMLElement;
      const actionButtonsEditorV1 = containerHTMLModuleV1?.querySelectorAll(
        '.action-buttons .block-action'
      );

      if (actionButtonsEditorV1 && buttonsEditorToHide[actionType]) {
        actionButtonsEditorV1.forEach((blockAction) => {
          const hasClassToHide = buttonsEditorToHide[actionType].some((className) =>
            blockAction.classList.contains(className)
          );
          if (hasClassToHide) {
            (blockAction as HTMLElement).style.display = 'none';
          }
        });
      }

      containerHTMLModuleV1.style.opacity = '1';
    },
    []
  );

  const hideEmailButtonEditorContainerV1 = useCallback(
    (iframeDocument: HTMLIFrameElement | undefined) => {
      if (!iframeDocument) return;

      const iframeRender = iframeDocument?.contentWindow?.document;

      const intervalId = setInterval(() => {
        const containerHTMLModuleV1 = iframeRender?.querySelector(
          'body #main div#html-module'
        ) as HTMLElement;
        if (containerHTMLModuleV1) {
          containerHTMLModuleV1.style.opacity = '0';
          clearInterval(intervalId);
        }
      }, 100);
    },
    []
  );

  return { emailButtonEditorToggle, hideEmailButtonEditorContainerV1 };
};

export default useEmailButtonsEditorV1;
