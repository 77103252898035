export const EcommerceVtexIcon = () => {
  return (
    <svg
      height="31.217"
      viewBox="0 0 33.04 31.217"
      width="33.04"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M223.813,186.14H199.141a2.807,2.807,0,0,0-2.243,4.116l2.468,5.164h-4.474a1.62,1.62,0,0,0-1.408.879,1.985,1.985,0,0,0-.047,1.786l7.938,16.6a1.576,1.576,0,0,0,2.908,0l2.156-4.485,2.7,5.659a2.432,2.432,0,0,0,4.486.006L226,190.152A2.735,2.735,0,0,0,223.813,186.14ZM212.73,197.127,207.4,208.218a1.049,1.049,0,0,1-1.935,0l-5.281-11.044a1.321,1.321,0,0,1,.029-1.189,1.079,1.079,0,0,1,.936-.588H211.79a1.049,1.049,0,0,1,.913.571A1.286,1.286,0,0,1,212.73,197.127Z"
        data-name="Path 4621"
        fill="#f71963"
        id="Path_4621"
        transform="translate(-193.248 -186.14)"
      />
    </svg>
  );
};
