import { FieldErrors, RegisterOptions } from 'react-hook-form';

import { PresenceConditionSelect } from '@/src/modules/RulesModule/components/FormCondition/components/PresenceConditionSelect';
import RecipientDropdownRules from '@/src/modules/RulesModule/components/FormCondition/components/RecipientDropdownRules';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IConditionDateProps {
  errors: FieldErrors<IConditionForm>;
  form: IFormProps<IConditionForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const ConditionDate = ({ errors, form, rules, disabled = false }: IConditionDateProps) => {
  return (
    <>
      <PresenceConditionSelect disabled={disabled} errors={errors} form={form} rules={rules} />
      <div className="mr-8">
        <RecipientDropdownRules disabled={disabled} form={form} rules={rules} />
      </div>
    </>
  );
};

export default ConditionDate;
