export const NpsPromoterIcon = () => {
  return (
    <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.375,3.375a9,9,0,1,0,9,9A9,9,0,0,0,12.375,3.375Zm0,16.2a7.2,7.2,0,1,1,7.2-7.2A7.221,7.221,0,0,1,12.375,19.575Zm3.15-8.1a1.35,1.35,0,1,0-1.35-1.35A1.326,1.326,0,0,0,15.525,11.475Zm-6.3,0a1.35,1.35,0,1,0-1.35-1.35A1.326,1.326,0,0,0,9.225,11.475Zm3.15,5.85a4.9,4.9,0,0,0,4.59-3.15H7.785A4.9,4.9,0,0,0,12.375,17.325Z"
        data-name="Icon ionic-md-happy"
        fill="#18c678"
        id="Icon_ionic-md-happy"
        transform="translate(-3.375 -3.375)"
      />
    </svg>
  );
};
