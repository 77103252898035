export const RulesDateIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M19.3333 24.0008C18.4 24.0008 17.6111 23.6786 16.9667 23.0342C16.3222 22.3897 16 21.6008 16 20.6675C16 19.7341 16.3222 18.9453 16.9667 18.3008C17.6111 17.6564 18.4 17.3342 19.3333 17.3342C20.2667 17.3342 21.0556 17.6564 21.7 18.3008C22.3444 18.9453 22.6667 19.7341 22.6667 20.6675C22.6667 21.6008 22.3444 22.3897 21.7 23.0342C21.0556 23.6786 20.2667 24.0008 19.3333 24.0008ZM6.76234 29.6051C5.92278 29.6051 5.20734 29.3095 4.616 28.7182C4.02467 28.1268 3.729 27.4114 3.729 26.5718V8.09648C3.729 7.25693 4.02467 6.54148 4.616 5.95015C5.20734 5.35882 5.92278 5.06315 6.76234 5.06315H8V3.81748C8 3.41837 8.13845 3.08182 8.41534 2.80782C8.692 2.5336 9.02989 2.39648 9.429 2.39648C9.82812 2.39648 10.1647 2.5336 10.4387 2.80782C10.7129 3.08182 10.85 3.41837 10.85 3.81748V5.06315H21.15V3.81748C21.15 3.41837 21.2885 3.08182 21.5653 2.80782C21.842 2.5336 22.1799 2.39648 22.579 2.39648C22.9781 2.39648 23.3147 2.5336 23.5887 2.80782C23.8629 3.08182 24 3.41837 24 3.81748V5.06315H25.2377C26.0772 5.06315 26.7927 5.35882 27.384 5.95015C27.9753 6.54148 28.271 7.25693 28.271 8.09648V26.5718C28.271 27.4114 27.9753 28.1268 27.384 28.7182C26.7927 29.3095 26.0772 29.6051 25.2377 29.6051H6.76234ZM6.76234 26.5718H25.2377V13.3342H6.76234V26.5718Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
