import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { CloseIcon } from '@/lib/v2/icons/outline/';

export interface ToastProps {
  show?: boolean;
  onClose?: (id?: string) => void;
  id?: string;
  title: string;
  body?: string;
  variant?: 'success' | 'error' | 'warning';
  autoCloseDelay?: number;
  sticky?: boolean;
}

const Toast = ({
  show = true,
  onClose,
  title,
  body,
  variant = 'success',
  autoCloseDelay = 5000,
  sticky = false,
  id,
}: ToastProps) => {
  const [percentageDelay, setPercentageDelay] = useState<number>(0);

  const handleClose = useCallback(() => {
    onClose?.(id ?? '');
  }, [id, onClose]);

  useEffect(() => {
    if (sticky) return;

    let interval: NodeJS.Timer | undefined = undefined;

    if (show && autoCloseDelay) {
      let delay = 0;
      interval = setInterval(() => {
        delay += 50;
        const percentage = (delay * 100) / autoCloseDelay;
        setPercentageDelay(percentage);
      }, 50);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [autoCloseDelay, handleClose, show]);

  useEffect(() => {
    if (percentageDelay >= 100 && !sticky) {
      handleClose();
    }
  }, [handleClose, percentageDelay]);

  return (
    <Transition
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show}
    >
      <div
        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5"
        data-testid="notification-component"
        id={id}
      >
        {autoCloseDelay && !sticky && (
          <div className="h-1 w-full bg-gray-200">
            <div className="h-1 bg-emblue-primary" style={{ width: `${percentageDelay}%` }}></div>
          </div>
        )}
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              {variant === 'success' && (
                <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />
              )}
              {variant === 'error' && <ExclamationCircleIcon className="h-6 w-6 text-red-500" />}
              {variant === 'warning' && (
                <ExclamationCircleIcon className="h-6 w-6 text-orange-400" />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5 text-left">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="text-sm mt-1 text-gray-500">{body}</p>
            </div>
            <div className="ml-4 flex shrink-0">
              <button
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                type="button"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <CloseIcon height="12" width="12" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default memo(Toast);
