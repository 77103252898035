export const SendActionsPostMessage = (message: string, event: string, extra = {}) => {
  const validator = (): void => {
    const iframe = document.getElementById('v1-container') as HTMLIFrameElement;
    if (!iframe || !iframe.contentWindow || sessionStorage.getItem('@intercom') === 'false') {
      setTimeout(validator, 100);
      return;
    }

    iframe.contentWindow?.postMessage(
      {
        type: message,
        payload: {
          event: event,
          message,
          ...extra,
        },
      },
      '*'
    );
  };

  validator();
};
