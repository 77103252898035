import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, memo, ReactNode, RefObject, useRef } from 'react';

import { IconSvg } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline';

interface SidePanelProps {
  children: ReactNode;
  open: boolean;
  onClose?: (value: boolean) => void;
  externalClose?: boolean;
  noPadding?: boolean;
  id?: string;
  side?: 'left' | 'right';
  mountTransitionEnd?: (refContainerSidePanel?: RefObject<HTMLDivElement>) => void;
  showCloseButton?: boolean;
}

const SidePanel = ({
  children,
  open,
  onClose,
  externalClose = false,
  noPadding = false,
  id,
  side = 'right',
  mountTransitionEnd,
  showCloseButton = false,
}: SidePanelProps) => {
  const refSidePanel = useRef<HTMLDivElement>(null);

  const classesContainer = classNames(
    'transform bg-white shadow-xl transition-all h-[calc(100vh-64px)]',
    { 'p-8': !noPadding },
    'w-full max-w-lg',
    {
      'rounded-l-lg': side === 'right',
      'rounded-r-lg': side === 'left',
    }
  );

  const classesClose = classNames(
    'absolute z-10 rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emblue-primary focus:ring-offset-2 p-1',
    {
      'right-0 top-0': side === 'right' && !noPadding,
      'right-6 top-6': side === 'right' && noPadding,
      'left-0 top-0': side === 'left' && !noPadding,
      'left-6 top-6': side === 'left' && noPadding,
    }
  );

  const handleAfterEnter = () => {
    mountTransitionEnd?.(refSidePanel);
  };

  return (
    <Transition
      appear
      afterEnter={handleAfterEnter}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-full"
      show={open}
    >
      <div className="relative z-[999999]" data-testid="side-panel-component" id={id}>
        <div className="fixed inset-0 bg-black/25" />

        <div
          aria-hidden
          className="fixed inset-0 z-[999999] overflow-hidden"
          onClick={() => externalClose && onClose?.(false)}
        >
          <div className={'flex min-h-full items-end justify-end'}>
            <Transition.Child
              as={Fragment}
              enter={`ease-out duration-300 transform ${
                side === 'left' ? '-translate-x-full' : 'translate-x-full'
              }`}
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo={`opacity-0 transform ${
                side === 'left' ? '-translate-x-full' : 'translate-x-full'
              }`}
            >
              <div
                ref={refSidePanel}
                aria-hidden
                className={classesContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="relative flex size-full flex-col">
                  {showCloseButton && (
                    <button className={classesClose} type="button" onClick={() => onClose?.(false)}>
                      <span className="sr-only">Close</span>
                      <IconSvg svgComponent={<CloseIcon />} />
                    </button>
                  )}

                  <div className="relative size-full">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default memo(SidePanel);
