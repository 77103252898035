import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import HappinessProvider from '@/src/modules/ContactsModule/contexts/HappinessContext';

const HappinessLayout = () => (
  <HappinessProvider>
    <Outlet />
  </HappinessProvider>
);

export default memo(HappinessLayout);
