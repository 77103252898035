import { If, Iframe, Modal, Spinner } from '@/lib/v2/components';

interface IModalPreviewRulePageProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  content: string;
}

const ModalPreviewRulePage = ({ open, onClose, loading, content }: IModalPreviewRulePageProps) => {
  return (
    <Modal withAuto open={open} onClose={onClose}>
      <div className="mb-2 mt-8 flex w-[800px] flex-col pt-4">
        <If condition={loading}>
          <div className="flex h-300 items-center justify-center">
            <Spinner withoutOverlay />
          </div>
        </If>
        <If condition={!loading}>
          <Iframe
            fullHeight
            withoutOverflow
            html={content}
            id="content-html-redirect-page"
            title="html"
          />
        </If>
      </div>
    </Modal>
  );
};

export default ModalPreviewRulePage;
