export const DeleteIcon = () => {
  return (
    <svg height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7473" id="Group_7473" transform="translate(-0.485 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.485 -0.163)"
          width="19"
        />
        <path
          d="M8.342,17.969a1.689,1.689,0,0,0,1.684,1.684H16.76a1.689,1.689,0,0,0,1.684-1.684V7.867H8.342Zm2.071-5.994L11.6,10.789l1.793,1.785,1.785-1.785,1.187,1.187L14.58,13.76l1.785,1.785-1.187,1.187-1.785-1.785-1.785,1.785-1.187-1.187,1.785-1.785Zm5.927-6.634L15.5,4.5H11.288l-.842.842H7.5V7.026H19.286V5.342Z"
          data-name="Icon material-delete-forever"
          fill="#004dbc"
          id="Icon_material-delete-forever"
          transform="translate(-3.474 -2.24)"
        />
      </g>
    </svg>
  );
};
