import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { Option } from '@/lib/v2/components';

import {
  useMutationCreateSegmentContext,
  useStateCreateSegmentContext,
} from '@/src/modules/ContactsModule/contexts/CreateSegmentContext';
import { FilterProps } from '@/src/modules/ContactsModule/screens/CreateSegment/components/SegmentFilters/Filter';
import {
  ConditionsOption,
  ContactField,
  Field,
  FilterType,
  IOptionFilter,
  ISegmentFilter,
  ISegmentFilterID,
  SegmentFilterCondition,
  SegmentFilterField,
  SegmentFilterRelationType,
} from '@/src/modules/ContactsModule/types/Segments';
import { groupObjectByKey } from '@/src/presentation/util';

import { useGetListConditionalOptions } from './useGetListConditionalOptions';

type useFilterProps = Omit<FilterProps, 'onDelete' | 'operator'>;

export const useFilter = ({
  filterOptions,
  isLoading,
  index,
  filterValue,
  daysOptions,
}: useFilterProps) => {
  const [valueGroupOption, setValueGroupOption] = useState<Option>();

  const { filters } = useStateCreateSegmentContext();
  const { setFilters } = useMutationCreateSegmentContext();

  const duplicateFiltersAllowed: SegmentFilterRelationType[] = useMemo(
    () => ['tags', 'groups', 'devices', 'socialNetworks', 'channels', 'ecommerceGroups'],
    []
  );

  //disable option on previously selected filters
  const filterOptionsWithDisabledOptions = useMemo(() => {
    const copyFilterOptions = _.cloneDeep(filterOptions);
    const filterOptionsIsValid = !isLoading && filterOptions.length > 0;
    if (filterOptionsIsValid) {
      return copyFilterOptions.map((option) => {
        const optionCountPreviouslySelected = filters.filter(
          (filter, ind) => index !== ind && filter?.field?.id === Number(option.id)
        ).length;

        const optionPreviouslySelected = filters.find(
          (filter, ind) => index !== ind && filter?.field?.id === Number(option.id)
        );

        if (optionPreviouslySelected) {
          if (option.optionsValues) {
            option.optionsValues = option.optionsValues.map((val) => {
              if ('values' in optionPreviouslySelected) {
                optionPreviouslySelected?.values?.forEach((valPrev) => {
                  if (valPrev === val.value?.toString()) val.disabled = true;
                });
              }
              return val;
            });
          }
        }

        let optionPreviouslySelectedWithOptionTypeCombo = false;

        if (
          optionPreviouslySelected &&
          'field' in optionPreviouslySelected &&
          (optionPreviouslySelected.field as ContactField).metadata !== undefined
        ) {
          const optionType = (optionPreviouslySelected.field as ContactField).metadata.optionType;
          optionPreviouslySelectedWithOptionTypeCombo = optionType === 'combo';
        }

        const isOptionDuplicateAllowed =
          duplicateFiltersAllowed.some((optionAllowed) => optionAllowed === option.value) ||
          optionPreviouslySelectedWithOptionTypeCombo;

        option.disabled = isOptionDuplicateAllowed
          ? optionCountPreviouslySelected === 2
          : optionCountPreviouslySelected === 1;

        return option;
      });
    }
  }, [duplicateFiltersAllowed, filterOptions, filters, index, isLoading]);

  const groupedFilterOptions = useMemo(() => {
    const copyFilterOptionsWithDisabledOptions = _.cloneDeep(filterOptionsWithDisabledOptions);
    if (!copyFilterOptionsWithDisabledOptions?.length) return {};
    const groupedOptions = groupObjectByKey(copyFilterOptionsWithDisabledOptions, 'group');
    return groupedOptions;
  }, [filterOptionsWithDisabledOptions]);

  const groupOptions = useMemo<Option[]>(() => {
    return Object.keys(groupedFilterOptions).map((key, index) => ({
      id: index + 1,
      name: String(key),
      value: String(key),
    }));
  }, [groupedFilterOptions]);

  const filterOptionValuesWithDisabled = useMemo(() => {
    if (valueGroupOption) {
      const valuesListOptions = groupedFilterOptions[valueGroupOption.value as string];
      return valuesListOptions;
    }
  }, [groupedFilterOptions, valueGroupOption]);

  // value Option filter select
  const filter = useMemo(() => {
    const filterOptionsIsValid = !isLoading && filterValue.field && filterOptions.length > 0;
    if (filterOptionsIsValid) {
      const {
        field: { id },
      } = filterValue as FilterType & {
        field: SegmentFilterCondition | ContactField;
      };
      const filterSelected = filterOptionsWithDisabledOptions?.find(
        (option) => Number(option.id) === Number(id)
      );
      const groupOptionValue = groupOptions.find(
        (option) => option.value === filterSelected?.group
      );

      setValueGroupOption(groupOptionValue);
      return filterSelected;
    }
  }, [
    filterOptions.length,
    filterOptionsWithDisabledOptions,
    filterValue,
    groupOptions,
    isLoading,
  ]);

  let dataType: string;
  if (_.isNil(filter?.field?.metadata.optionType)) {
    dataType = (filter as IOptionFilter)?.dataType;
  } else if (filter?.field?.metadata.optionType && filter?.field?.type === 'string') {
    dataType = filter?.field?.metadata.optionType.toUpperCase();
  } else {
    dataType = filter?.field?.type ? filter?.field.type.toUpperCase() : 'STRING';
  }

  const conditionalOptions = useGetListConditionalOptions(dataType);

  const conditionalOptionsWithDisabledOptions = useMemo(() => {
    const copyConditionalOptions = _.cloneDeep(conditionalOptions);
    return copyConditionalOptions.map((conditionalOption) => {
      const currentOptionFilterSelected = filters[index];
      const optionPreviouslySelected = filters.find(
        (filter, ind) =>
          index !== ind && filter?.field?.id === currentOptionFilterSelected?.field?.id
      );
      const conditionPrevious = optionPreviouslySelected?.condition;
      if (conditionalOption.value === conditionPrevious) conditionalOption.disabled = true;
      return conditionalOption;
    });
  }, [conditionalOptions, filters, index]);

  // value Option conditional select
  const conditional = useMemo(() => {
    if (conditionalOptions) {
      return conditionalOptions?.find((option) => option.value === filterValue.condition);
    }
  }, [conditionalOptions, filterValue.condition]);

  const lastDaysDisabled = useMemo(() => {
    const copyDaysOptions = _.cloneDeep(daysOptions);
    let disabled = false;
    copyDaysOptions.forEach((dayOption) => {
      const currentOptionFilterSelected = filters[index];
      const optionPreviouslySelected = filters.find(
        (filter, ind) =>
          index !== ind && filter?.field?.id === currentOptionFilterSelected?.field?.id
      );
      const dayOldPrevious = (optionPreviouslySelected as ISegmentFilterID)?.daysOld;
      if (dayOption.value === dayOldPrevious) disabled = true;
    });
    return disabled;
  }, [daysOptions, filters, index]);

  // value Option lastDays select
  const lastDays = useMemo(() => {
    if ('daysOld' in filterValue) {
      return daysOptions?.find((option) => Number(option.value) === Number(filterValue.daysOld));
    }
  }, [daysOptions, filterValue]);

  const handleOnChangeGroup = useCallback(
    (val: Option | Option[]) => {
      const value = val as Option;
      setValueGroupOption(value);
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        newFilters[index] = {
          ...newFilters[index],
          dataType: undefined,
          condition: undefined,
          operator: undefined,
          field: undefined,
          values: undefined,
        };
        return newFilters;
      });
    },
    [index, setFilters]
  );

  const handleOnChangeFilter = useCallback(
    (val: Option | Option[]) => {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        const value = val as IOptionFilter;
        newFilters[index].condition = undefined;
        newFilters[index].dataType = value.dataType;
        if ('daysOld' in newFilters[index]) delete (newFilters[index] as ISegmentFilterID).daysOld;
        if ('values' in newFilters[index])
          delete (newFilters[index] as ISegmentFilter & { values?: string[] }).values;
        if (newFilters[index].dataType === 'ID' || newFilters[index].dataType === 'STRING')
          (newFilters[index] as ISegmentFilterID).operator = 'or';
        else delete (newFilters[index] as ISegmentFilter & { operator?: string[] }).operator;
        if (newFilters[index].dataType !== 'ACTIVITY') {
          if ('lastDays' in newFilters[index])
            delete (newFilters[index] as ISegmentFilter & { lastDays?: number }).lastDays;
          const filterItem = newFilters[index] as ISegmentFilter & { values: string[] };
          filterItem.values = [];
          newFilters[index] = filterItem;
        }
        if (value?.field) {
          const { value: deletedValue, ...newField } = {
            ...value?.field,
            name: value?.value ?? value?.name,
          } as Field;
          newFilters[index].field = newField;
        } else
          newFilters[index].field = {
            source: value.groupValue,
            name: value.value,
            id: value.id,
          } as SegmentFilterField;
        return newFilters;
      });
    },
    [index, setFilters]
  );

  const handleOnChangeConditional = useCallback(
    (val: Option | Option[]) => {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        const value = val as ConditionsOption;
        if ('values' in newFilters[index]) {
          (newFilters[index] as ISegmentFilter & { values: string[] }).values = [];
        }
        newFilters[index].condition = value.value;
        return newFilters;
      });
    },
    [index, setFilters]
  );

  const handleOnChangeSwitch = useCallback(
    (and: boolean) => {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        (newFilters[index] as ISegmentFilterID).operator = and ? 'and' : 'or';
        return newFilters;
      });
    },
    [index, setFilters]
  );

  const handleOnChangeLastDays = useCallback(
    (val: Option | Option[]) => {
      setFilters((prevFilters) => {
        const newFilters = _.cloneDeep(prevFilters);
        const filterItem = newFilters[index] as ISegmentFilter & { daysOld: number };
        filterItem.daysOld = Number((val as Option).value);
        return newFilters;
      });
    },
    [index, setFilters]
  );

  return {
    valueGroupOption,
    groupOptions,
    handleOnChangeGroup,
    filterOptionValuesWithDisabled,
    filter,
    handleOnChangeFilter,
    conditionalOptionsWithDisabledOptions,
    conditional,
    handleOnChangeConditional,
    handleOnChangeSwitch,
    lastDaysDisabled,
    handleOnChangeLastDays,
    lastDays,
  };
};
