import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SummarySection } from './SummarySection';
import { SummarySubSection } from './SummarySubSection';

interface SummaryDataElement {
  title: string;
  content: string | JSX.Element | string[];
  singleLine?: boolean;
}

export interface SummaryDataSection {
  title: string;
  elements: SummaryDataElement[];
}

interface SummaryActionProps {
  summaryData: SummaryDataSection[];
}

const SummaryAction = ({ summaryData }: SummaryActionProps) => {
  const { t } = useTranslation();

  return (
    <>
      {summaryData.map((section, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SummarySection key={index} title={t(section.title)}>
          {section.elements.map((subSection, subIndex) => (
            <SummarySubSection
              // eslint-disable-next-line react/no-array-index-key
              key={subIndex}
              singleLine={subSection.singleLine}
              text={subSection.content}
              title={subSection.title}
            />
          ))}
        </SummarySection>
      ))}
    </>
  );
};

export default memo(SummaryAction);
