import { ActionPreviewEmail } from '@/src/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail';
import { ActionPreviewHeader } from '@/src/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail/components/ActionPreviewHeader';

const ActionPreview = () => {
  return (
    <div className="flex h-full flex-col bg-white">
      <ActionPreviewHeader />
      <ActionPreviewEmail />
    </div>
  );
};

export default ActionPreview;
