import axios from 'axios';

import { Environments } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';

export const getOnsiteToken = async (env: Environments): Promise<string | undefined> => {
  if (env !== 'storybook') {
    const { URL_WIDGET_API } = configData.endpoints[env];
    const { ONSITE_USER, ONSITE_PASSWORD } = configData;
    try {
      const data = await axios.post(
        `${URL_WIDGET_API}/api/v1/auth`,
        { username: ONSITE_USER, password: ONSITE_PASSWORD },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      const { token } = data.data.data;
      return token as string;
    } catch (e) {
      console.error(e);
    }
  }
};
