import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { atomInfoModalPreviewRulePage } from '@/src/modules/RulesModule/atoms/rules';

export const useRedirectExternalCall = () => {
  const [infoModalPreview] = useAtom(atomInfoModalPreviewRulePage);
  const rulesService = useRulesService();
  const [loading, setLoading] = useState<boolean>(false);
  const [contentHtmlPreviewRule, setContentHtmlPreviewRule] = useState<string>('');

  const getRedirectHtml = useCallback(
    async (isSuccessRedirect: boolean) => {
      setLoading(true);

      const html = await rulesService.getRedirectRulePageHtml(isSuccessRedirect);
      setContentHtmlPreviewRule(html);

      setLoading(false);
    },
    [rulesService]
  );

  useEffect(() => {
    if (infoModalPreview.isOpen) {
      const isSuccessPage = infoModalPreview.type === 'success';
      void getRedirectHtml(isSuccessPage);
    }
  }, [infoModalPreview.isOpen, infoModalPreview.type, getRedirectHtml]);

  return {
    contentHtmlPreviewRule,
    loading,
  };
};
