export const ImageCampaignIcon = () => {
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
      <rect height="40" transform="matrix(-1 0 0 1 40 0)" width="40" />
      <path
        clipRule="evenodd"
        d="M29.6583 5H10.3417C8.485 5 7.81 5.19333 7.13167 5.55667C6.45333 5.92 5.92 6.45333 5.55667 7.13167C5.19333 7.81 5 8.48333 5 10.3417V22.8583L12.3183 16.1033C12.5839 15.8583 12.8994 15.6738 13.2432 15.5624C13.5869 15.451 13.9507 15.4155 14.3095 15.4583C14.6683 15.5011 15.0136 15.6211 15.3216 15.8101C15.6295 15.9992 15.8928 16.2527 16.0933 16.5533L21.31 24.3783L25.1483 21.9117C25.6601 21.5829 26.2741 21.452 26.8754 21.5434C27.4767 21.6348 28.0241 21.9423 28.415 22.4083L34.9917 30.25C34.9971 30.0528 34.9999 29.8556 35 29.6583V10.3417C35 8.48333 34.8067 7.81 34.4433 7.13167C34.08 6.45333 33.5467 5.92 32.8683 5.55667C32.19 5.19333 31.5167 5 29.6583 5ZM13.8633 19.2167L5 27.395V29.6567C5 31.5133 5.19333 32.1883 5.55667 32.8667C5.9119 33.5377 6.46064 34.0864 7.13167 34.4417C7.81 34.805 8.48333 34.9983 10.3417 34.9983H29.6583C31.5167 34.9983 32.19 34.805 32.8683 34.4417C33.1615 34.2857 33.4332 34.0925 33.6767 33.8667L26.3333 25.1117L22.4233 27.625C21.8716 27.9795 21.2028 28.103 20.5608 27.969C19.9189 27.8349 19.3554 27.454 18.9917 26.9083L13.8633 19.2167ZM26.6867 10C28.5367 10 30 11.3867 30 13.3133C30 15.2417 28.535 16.6667 26.6867 16.6667C24.7983 16.6667 23.3333 15.2417 23.3333 13.3133C23.3333 11.3867 24.7983 10 26.6867 10Z"
        fill="#5E6984"
        fillRule="evenodd"
      />
    </svg>
  );
};
