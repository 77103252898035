export const PixelTrackingIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#1E1E1E" height="20" width="20" />
      <g id="Step 1">
        <rect fill="#F4F5FA" height="800" transform="translate(-58 -575)" width="1280" />
        <g filter="url(#filter0_d_130_1869)" id="Rectangle 4426">
          <rect fill="white" height="240" rx="8" width="1232" x="-34" y="-85" />
          <rect height="239" rx="7.5" stroke="#D3DDEA" width="1231" x="-33.5" y="-84.5" />
        </g>
        <rect fill="#CFF6FE" height="40" id="Rectangle 4429" rx="4" width="40" x="-10" y="-10" />
        <g clipPath="url(#clip0_130_1869)" id="pixel-tracking 1">
          <path
            d="M17.31 2.26999C17.71 2.26999 18.07 2.25999 18.43 2.26999C18.82 2.28999 19.01 2.48999 19.02 2.88999C19.02 2.94999 19.02 3.00999 19.02 3.07999C19.02 6.99999 19.02 10.93 19.02 14.85C19.02 15.47 18.85 15.64 18.23 15.64C12.41 15.64 6.59 15.64 0.77 15.64C0.17 15.64 0 15.46 0 14.87C0 10.92 0 6.96999 0 3.01999C0 2.44999 0.18 2.26999 0.76 2.26999C1.07 2.26999 1.37 2.26999 1.71 2.26999V2.70999C1.71 5.65999 1.71 8.59999 1.71 11.55C1.71 12.74 2.38 13.41 3.58 13.41C7.53 13.41 11.48 13.41 15.43 13.41C16.65 13.41 17.32 12.75 17.32 11.53C17.32 8.59999 17.32 5.65999 17.32 2.72999V2.26999H17.31Z"
            fill="#11CFFA"
            id="Vector"
          />
          <path
            d="M16.2001 5.64C16.2001 7.1 16.2001 8.54001 16.2001 9.97001C16.2001 10.54 16.2001 11.11 16.2001 11.68C16.2001 12.07 16.0001 12.27 15.6101 12.29C15.5201 12.29 15.4401 12.29 15.3501 12.29C11.4601 12.29 7.57008 12.29 3.69008 12.29C2.98008 12.29 2.83008 12.14 2.83008 11.45C2.83008 9.64 2.83008 7.84 2.83008 6.03V5.63H16.2101L16.2001 5.64ZM13.6301 9.51C13.8801 9.27 14.1101 9.06 14.3201 8.84C14.6801 8.46 14.5801 8.02 14.0901 7.85C13.0301 7.48 11.9701 7.12 10.8901 6.79C10.7001 6.73 10.3901 6.79 10.2601 6.91C10.1301 7.04 10.0901 7.35 10.1501 7.54C10.4701 8.6 10.8301 9.65 11.1901 10.7C11.3701 11.21 11.8101 11.32 12.2001 10.94C12.4201 10.73 12.6201 10.5 12.8801 10.23C13.1301 10.51 13.3301 10.75 13.5501 10.96C13.8101 11.22 14.1501 11.22 14.3701 11C14.5901 10.78 14.5701 10.44 14.3201 10.17C14.1101 9.95001 13.8801 9.74 13.6301 9.49V9.51ZM6.72008 7.84C7.25008 7.84 7.78008 7.84 8.31008 7.84C8.72008 7.84 8.95008 7.62001 8.95008 7.28C8.95008 6.94 8.71008 6.74 8.30008 6.73C7.25008 6.73 6.20008 6.73 5.15008 6.73C4.74008 6.73 4.51008 6.95 4.51008 7.29C4.51008 7.63 4.75008 7.83 5.16008 7.84C5.68008 7.84 6.20008 7.84 6.72008 7.84ZM6.69008 10.07C7.22008 10.07 7.75008 10.07 8.28008 10.07C8.70008 10.07 8.93008 9.87 8.94008 9.54C8.95008 9.19 8.71008 8.97001 8.28008 8.97001C7.24008 8.97001 6.21008 8.97001 5.17008 8.97001C4.75008 8.97001 4.52008 9.17 4.51008 9.5C4.50008 9.85 4.74008 10.07 5.17008 10.07C5.68008 10.07 6.18008 10.07 6.69008 10.07Z"
            fill="#11CFFA"
            id="Vector_2"
          />
          <path
            d="M16.2001 4.48H2.85008C2.85008 4.37 2.83008 4.28 2.83008 4.18C2.83008 3.03 2.83008 1.88 2.83008 0.73C2.83008 0.2 3.01008 0 3.53008 0C7.52008 0 11.5001 0 15.4901 0C16.0001 0 16.1901 0.19 16.2001 0.69C16.2001 1.94 16.2001 3.19 16.2001 4.48ZM5.04008 1.71C4.73008 1.71 4.49008 1.98 4.51008 2.3C4.53008 2.6 4.78008 2.83 5.07008 2.82C5.38008 2.82 5.62008 2.55 5.60008 2.23C5.58008 1.93 5.33008 1.7 5.04008 1.71ZM7.27008 1.71C6.96008 1.71 6.72008 1.98 6.74008 2.3C6.76008 2.6 7.01008 2.83 7.30008 2.82C7.61008 2.82 7.85008 2.55 7.83008 2.23C7.81008 1.93 7.56008 1.7 7.27008 1.71ZM9.51008 1.71C9.22008 1.71 8.97008 1.95 8.96008 2.25C8.96008 2.56 9.20008 2.82 9.51008 2.82C9.82008 2.82 10.0701 2.56 10.0601 2.25C10.0501 1.95 9.80008 1.72 9.51008 1.72V1.71Z"
            fill="#11CFFA"
            id="Vector_3"
          />
          <path
            d="M12.29 17.9C12.85 17.9 13.34 17.9 13.84 17.9C14.27 17.9 14.53 18.11 14.52 18.46C14.52 18.8 14.27 19.02 13.83 19.02C10.95 19.02 8.06 19.02 5.18 19.02C4.75 19.02 4.5 18.8 4.5 18.46C4.5 18.12 4.76 17.91 5.19 17.91C5.68 17.91 6.18 17.91 6.71 17.91V16.79H12.29V17.91V17.9Z"
            fill="#11CFFA"
            id="Vector_4"
          />
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="248"
          id="filter0_d_130_1869"
          width="1240"
          x="-38"
          y="-85"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_130_1869" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_130_1869"
            mode="normal"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_130_1869">
          <rect fill="white" height="19.02" width="19.02" />
        </clipPath>
      </defs>
    </svg>
  );
};
