export const RemoveIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <mask height="18" id="mask0_7_388" maskUnits="userSpaceOnUse" width="18" x="0" y="0">
        <rect fill="#D9D9D9" height="18" width="18" />
      </mask>
      <g mask="url(#mask0_7_388)">
        <path
          d="M4.5 9.75C4.2875 9.75 4.10938 9.67812 3.96563 9.53437C3.82188 9.39062 3.75 9.2125 3.75 9C3.75 8.7875 3.82188 8.60937 3.96563 8.46562C4.10938 8.32187 4.2875 8.25 4.5 8.25H13.5C13.7125 8.25 13.8906 8.32187 14.0344 8.46562C14.1781 8.60937 14.25 8.7875 14.25 9C14.25 9.2125 14.1781 9.39062 14.0344 9.53437C13.8906 9.67812 13.7125 9.75 13.5 9.75H4.5Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
