import { CheckCircleIcon } from '@heroicons/react/outline';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import ProgressNotification from '@/lib/v2/components/ProgressNotification';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';

import { atomContactsDownload, defaultContactDownload } from '@/src/atoms/Contacts';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const DownloadContacts = () => {
  const [contactsDownload, setContactsDownload] = useAtom(atomContactsDownload);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const service = useService();

  const iconLoading = useMemo(
    () => (
      <IconSvg
        rounded
        bgColor="primary-dark"
        fillColor="white"
        height="42px"
        svgComponent={<DownloadIcon />}
        width="42px"
        withPadding="medium"
      />
    ),
    []
  );

  const iconSuccess = useMemo(
    () => (
      <IconSvg
        fillColor={'white'}
        height="42px"
        svgComponent={<CheckCircleIcon color="#18C678" />}
        width="42px"
      />
    ),
    []
  );
  const validateStatusDownload = useCallback(async () => {
    const response = await service?.getStatusContactsExport();

    if (response.status === 'success') {
      setContactsDownload((prevContactDownload) => ({
        ...prevContactDownload,
        show: true,
        error: false,
        status: 'done',
        file: {
          ...prevContactDownload.file,
          url: response.url,
          token: response.token,
        },
        wordings: {
          message: t('NOTIFICATION_CONTACTS_DOWNLOAD.exported'),
          action: t('NOTIFICATION_CONTACTS_DOWNLOAD.download'),
        },
      }));
    } else {
      setTimeout(function () {
        void validateStatusDownload();
      }, 3000);
    }
  }, [service, setContactsDownload, t]);

  useEffect(() => {
    if (!contactsDownload.show || contactsDownload.status === 'done') return;

    void validateStatusDownload();
  }, [contactsDownload, validateStatusDownload]);

  const fetchFile = useCallback((url: string, token: string) => {
    return new Promise((resolve) => {
      const request = new XMLHttpRequest();
      request.onloadstart = function () {
        request.responseType = 'blob';
      };
      request.onload = function () {
        if (request.readyState === 4 && request.status === 200) {
          resolve(request.response);
        }
      };
      request.open('GET', url);

      request.setRequestHeader('EmblueAuth', 'ApplicationToken:' + token);

      request.send();
    });
  }, []);

  const handleDownload = useCallback(async () => {
    setIsLoading(true);
    const file = await fetchFile(contactsDownload.file.url, contactsDownload.file.token);

    if (!(file instanceof Blob)) return;

    const link = document.createElement('a');

    link.href = URL.createObjectURL(file);
    document.querySelector('body')?.appendChild(link);
    link.download = `${contactsDownload?.file?.name || 'group'}.zip`;
    link.click();
    document.body.removeChild(link);

    await service?.finishDownloadReport();

    setContactsDownload(defaultContactDownload);

    setIsLoading(false);
  }, [
    contactsDownload.file?.name,
    contactsDownload.file.token,
    contactsDownload.file.url,
    fetchFile,
    service,
    setContactsDownload,
  ]);

  if (!contactsDownload.show) return null;

  return (
    <div className="fixed inset-x-5 bottom-5">
      <ProgressNotification
        indeterminate
        error={contactsDownload.error}
        hideBar={contactsDownload.status === 'done'}
        icon={contactsDownload.status !== 'done' ? iconLoading : iconSuccess}
        isLoading={isLoading}
        wordings={contactsDownload.wordings}
        onAction={handleDownload}
      />
    </div>
  );
};

export default DownloadContacts;
