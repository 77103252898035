import { CountryIcon, DeviceIcon, DomainIcon, GenderIcon } from '@/lib/icon';

export const filterDataArray = [
  {
    name: 'Domain',
    icon: <DomainIcon />,
    value: [
      {
        id: 1,
        title: 'http://embluemail.com',
      },
    ],
  },
  {
    name: 'Country',
    icon: <CountryIcon />,
    value: [
      {
        id: 2,
        title: 'Argentina',
      },
      {
        id: 3,
        title: 'Peru',
      },
      {
        id: 4,
        title: 'Colombia',
      },
      {
        id: 5,
        title: 'Venezuela',
      },
      {
        id: 6,
        title: 'Argentina',
      },
      {
        id: 7,
        title: 'Peru',
      },
      {
        id: 8,
        title: 'Colombia',
      },
      {
        id: 9,
        title: 'Venezuela',
      },
    ],
  },
  {
    name: 'Device',
    icon: <DeviceIcon />,
    value: [
      {
        id: 10,
        title: 'Desktop',
      },
      {
        id: 11,
        title: 'Movil',
      },
      {
        id: 12,
        title: 'Tablet',
      },
    ],
  },
  {
    name: 'Gender',
    icon: <GenderIcon />,
    value: [
      {
        id: 13,
        title: 'Men',
      },
      {
        id: 14,
        title: 'Woman',
      },
      {
        id: 15,
        title: 'N/A',
      },
    ],
  },
];
