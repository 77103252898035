// cspell:disable

export const AbandonedNewContactsIcon = () => {
  return (
    <svg height="52" viewBox="0 0 52 52" width="52" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 8173" id="Group_8173" transform="translate(-1000.75 -347.749)">
        <g data-name="Group 8456" id="Group_8456" transform="translate(13558.75 20136.75)">
          <g data-name="Group 8459" id="Group_8459">
            <g data-name="Group 7953" id="Group_7953" transform="translate(-12557.75 -19788.75)">
              <rect
                data-name="Rectangle 4375"
                fill="none"
                height="52"
                id="Rectangle_4375"
                transform="translate(-0.25 -0.251)"
                width="52"
              />
            </g>
            <g data-name="Group 8460" id="Group_8460">
              <g data-name="Group 7952" id="Group_7952" transform="translate(-12554.666 -19786.68)">
                <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(29.314 31.342)">
                  <path
                    d="M386.11,331.53a11.571,11.571,0,0,0,.714,1.667h0C386.585,332.721,386.348,332.007,386.11,331.53Z"
                    data-name="Trazado 3928"
                    fill="#2552a0"
                    id="Trazado_3928"
                    transform="translate(-386.11 -331.53)"
                  />
                </g>
                <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(22.645 23.474)">
                  <path
                    d="M315.57,248.307a.876.876,0,0,1,.714.238A.873.873,0,0,0,315.57,248.307Z"
                    data-name="Trazado 3930"
                    fill="#2552a0"
                    id="Trazado_3930"
                    transform="translate(-315.57 -248.301)"
                  />
                </g>
                <g data-name="Grupo 6573" id="Grupo_6573" transform="translate(6.611 0)">
                  <circle
                    cx="11.454"
                    cy="11.454"
                    data-name="Elipse 1742"
                    fill="#f6a658"
                    id="Elipse_1742"
                    r="11.454"
                  />
                </g>
                <g data-name="Grupo 6574" id="Grupo_6574" transform="translate(0 22.708)">
                  <path
                    d="M111.989,252.962a22.449,22.449,0,0,0-8.1-12.562,1.782,1.782,0,0,0-1.675,0,14.308,14.308,0,0,1-16.2,0,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h21.516C109.471,259.667,111.989,252.962,111.989,252.962Z"
                    data-name="Trazado 3933"
                    fill="#788f9a"
                    id="Trazado_3933"
                    transform="translate(-76.043 -240.19)"
                  />
                </g>
                <path
                  d="M157.424,0a11.454,11.454,0,0,0,0,22.909Z"
                  data-name="Path 4758"
                  fill="#f5b673"
                  id="Path_4758"
                  transform="translate(-139.359 0)"
                />
                <path
                  d="M94.108,242.915a14.291,14.291,0,0,1-8.1-2.515,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h12.85Z"
                  data-name="Path 4759"
                  fill="#8fa3ad"
                  id="Path_4759"
                  transform="translate(-76.042 -217.482)"
                />
              </g>
              <g data-name="Group 8454" id="Group_8454">
                <g data-name="Group 8451" id="Group_8451" transform="translate(9 -38)">
                  <g data-name="Group 8453" id="Group_8453" transform="translate(-38 39)">
                    <rect
                      data-name="Rectangle 4424"
                      fill="#fc7e00"
                      height="23"
                      id="Rectangle_4424"
                      rx="3"
                      transform="translate(-12503 -19766)"
                      width="20"
                    />
                    <path
                      d="M1194.05,973.223V990h12.831V978.157h-4.442a.493.493,0,0,1-.493-.493v-4.441h-7.895Zm8.883.309v3.638h3.638l-1.819-1.819-1.819-1.819Zm-4.441,6.6a1.481,1.481,0,1,1-1.48,1.481,1.488,1.488,0,0,1,1.48-1.481Zm4.409,0a1.481,1.481,0,1,1-1.479,1.481,1.488,1.488,0,0,1,1.479-1.481Zm-4.409.987a.486.486,0,1,0,.351.142.485.485,0,0,0-.351-.142Zm4.409,0a.486.486,0,1,0,.351.142.486.486,0,0,0-.351-.142Zm-2.436,2.466a4.762,4.762,0,0,1,3.3,1.635.493.493,0,1,1-.679.709,3.838,3.838,0,0,0-2.621-1.357,3.8,3.8,0,0,0-2.622,1.357.493.493,0,1,1-.679-.709,4.718,4.718,0,0,1,3.3-1.635Z"
                      data-name="Path 5238"
                      fill="#fff"
                      fillRule="evenodd"
                      id="Path_5238"
                      transform="translate(-13692.836 -20736.111)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
