import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { TableSkeleton } from '@/lib/components';
import { TableBar } from '@/lib/components/TableBar';
import { Flex } from '@/lib/v2/components';

import { CampaignsTable } from '@/src/modules/CampaignsModule/components/Campaigns/CampaignsTable';

import { ActionBarButtons } from './components/ActionBarButtons';
import { FilterBar } from './components/FilterBar';

import {
  atomCampaignList,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

export function TableContainer() {
  const campaignsList = useAtomValue(atomCampaignList);
  const totalCampaignsList = useAtomValue(atomTotalsProcess);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);

  const wrapperActionsTable = classNames({
    'mt-[-52px]': campaignsList && campaignsList?.length > 0,
  });

  return (
    <Flex column>
      {campaignsList ? (
        <>
          {totalCampaignsList?.total > 0 && (
            <TableBar>{totalSelectedRows > 0 ? <ActionBarButtons /> : <FilterBar />}</TableBar>
          )}
          <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
            <CampaignsTable />
          </Flex>
        </>
      ) : (
        <div className="size-full py-10">
          <TableSkeleton columns={7} />
        </div>
      )}
    </Flex>
  );
}
