export const DropdownSmsIcon = () => {
  return (
    <svg
      data-name="Grupo 3952"
      height="13.913"
      id="Grupo_3952"
      viewBox="0 0 20 13.913"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        data-name="Rectángulo 2762"
        fill="#00bca6"
        height="13.913"
        id="Rectángulo_2762"
        rx="3"
        transform="translate(0)"
        width="20"
      />
      <path
        d="M13.12,3h-9a1.055,1.055,0,0,0-1.119.975L3,12.745,5.249,10.8H13.12a1.061,1.061,0,0,0,1.124-.975V3.975A1.061,1.061,0,0,0,13.12,3ZM6.936,7.385H5.811V6.411H6.936Zm2.249,0H8.06V6.411H9.184Zm2.249,0H10.309V6.411h1.124Z"
        data-name="Icon material-sms"
        fill="#fff"
        id="Icon_material-sms"
        transform="translate(1.638 0.208)"
      />
    </svg>
  );
};
