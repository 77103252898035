import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { PlanType } from '@/src/infrastructure/interfaces/IResponses';
import {
  atomBillingInformation,
  atomContractDetail,
} from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';
import {
  atomCurrentStep,
  atomFrequency,
  atomSelectedPlanData,
} from '@/src/modules/MyPlanModule/atoms/ManagePlan';
import { RadioSwitchOption } from '@/src/modules/MyPlanModule/components/RadioSwitch/RadioSwitch';
import { CONTACT_US_URL } from '@/src/modules/MyPlanModule/constants';
import { PlansIds, SelectedPlanData } from '@/src/modules/MyPlanModule/types';

import { useFrequencyOptions } from './useFrequencyOptions';
import { usePlan } from './usePlan';
import { usePlanAdditionalInfo } from './usePlanAdditionalInfo';

export type HandleSelectedPlan = (id: PlansIds) => void;

export const useSelectedPlanData = () => {
  const [selectedPlanData, setSelectedPlanData] = useAtom(atomSelectedPlanData);
  const [frequency, setFrequency] = useAtom(atomFrequency);
  const setCurrentStep = useSetAtom(atomCurrentStep);
  const { plan, getPlanInformationById, getPlanNameById } = usePlan();
  const { frequencyOptions } = useFrequencyOptions();
  const { planAdditionalInfo } = usePlanAdditionalInfo();
  const contractDetail = useAtomValue(atomContractDetail);

  const {
    selectedRanges: { rangeEmail, rangeOnsite, rangeSms },
  } = selectedPlanData;

  const planName = (plan && planAdditionalInfo[plan?.name].name) ?? planAdditionalInfo.Free.name;

  const isMoreOption =
    typeof rangeEmail === 'string' ||
    typeof rangeSms === 'string' ||
    typeof rangeOnsite === 'string';

  let emailAmount = typeof rangeEmail === 'string' ? 0 : rangeEmail,
    smsAmount = typeof rangeSms === 'string' ? 0 : rangeSms,
    onsiteAmount = typeof rangeOnsite === 'string' ? 0 : rangeOnsite;

  if (typeof rangeEmail === 'string') {
    const email = plan?.volume.email.volumeSteps ?? [];
    emailAmount = email.length && email[email.length - 1].max;
  }
  if (typeof rangeSms === 'string') {
    const sms = plan?.volume.sms.volumeSteps ?? [];
    smsAmount = sms.length && sms[sms.length - 1].max;
  }
  if (typeof rangeOnsite === 'string') {
    const onsite = plan?.volume.onsite.volumeSteps ?? [];
    onsiteAmount = onsite.length && onsite[onsite.length - 1].max;
  }

  const handleSelectPlan = useCallback(
    (id: PlansIds) => {
      if (id === 'plan-free' || id === 'plan-starter' || id === 'plan-advance') {
        const plan = getPlanInformationById(id);
        const emailMin = plan?.volume.email.volumeSteps[0].min ?? 0;
        const smsMin = plan?.volume.sms.volumeSteps[0].min ?? 0;
        const onsiteMin = plan?.volume.onsite.volumeSteps[0].min ?? 0;

        let currentEmail = 0;
        let currentSms = 0;
        let currentOnSite = 0;

        if (contractDetail?.planDetail && plan?.actualPlan) {
          const { email, onsite, sms } = contractDetail.planDetail;
          currentEmail = email;
          currentSms = sms;
          currentOnSite = onsite;
        }

        setSelectedPlanData((prevState) => {
          const copyPrevState = JSON.parse(JSON.stringify(prevState)) as SelectedPlanData;
          copyPrevState.pristine = false;
          copyPrevState.planId = id;
          copyPrevState.selectedRanges = {
            rangeEmail: plan?.actualPlan ? currentEmail : emailMin,
            rangeOnsite: plan?.actualPlan ? currentOnSite : onsiteMin,
            rangeSms: plan?.actualPlan ? currentSms : smsMin,
          };
          return copyPrevState;
        });
        setCurrentStep(2);
      } else {
        window.open(CONTACT_US_URL, '_blanc');
      }
    },
    [contractDetail?.planDetail, getPlanInformationById, setCurrentStep, setSelectedPlanData]
  );

  const handleFrequency = useCallback(
    (value: RadioSwitchOption) => {
      setFrequency(value as typeof frequency);
    },
    [setFrequency]
  );

  const nextPlan = useMemo(() => {
    const plansIds: PlansIds[] = ['plan-free', 'plan-starter', 'plan-advance', 'plan-enterprise'];

    const currentPlanId = plansIds.indexOf(selectedPlanData.planId);

    if (currentPlanId === -1) {
      throw new Error('Current plan not recognized');
    }

    if (currentPlanId === plansIds.length) {
      return undefined;
    }
    return plansIds[currentPlanId + 1];
  }, [selectedPlanData.planId]);

  const isFreemiumPlan = plan?.id === 'plan-free';

  return {
    isMoreOption,
    selectedPlanData,
    setSelectedPlanData,
    planName,
    plan,
    handleSelectPlan,
    frequency: frequency ?? frequencyOptions[0],
    handleFrequency,
    emailAmount,
    smsAmount,
    onsiteAmount,
    nextPlan,
    getPlanNameById,
    setCurrentStep,
    frequencyOptions,
    isFreemiumPlan,
  };
};
