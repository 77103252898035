import withDeprecated from '@/src/application/HOCs/WithDeprecated';

import './Text.tailwind.css';

export type LinkProps = {
  children?: JSX.Element | string;
  onClick?: () => void;
};

export const _Link = ({ children, onClick }: LinkProps) => (
  <a className="link cursor-pointer text-[11px] text-gray-600" onClick={onClick}>
    {children}
  </a>
);

/**
 * @deprecated use new Text component v2 -> import { Text } from "@/lib/v2/components"
 */
export const Link = withDeprecated(
  _Link,
  'use new Text component v2 -> import { Text } from "@/lib/v2/components";'
);
