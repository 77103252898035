import { useEffect, useState } from 'react';

import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';

export const useCheckAccountInboxExists = () => {
  const inboxService = useInboxService();
  const [inboxAccountExists, setInboxAccountExists] = useState(false);
  const [isLoadingCheckInbox, setIsLoadingCheckInbox] = useState(false);

  useEffect(() => {
    const checkInbox = async () => {
      setIsLoadingCheckInbox(true);
      const exists = await inboxService.checkInboxAccountExists();
      setInboxAccountExists(exists);
      setIsLoadingCheckInbox(false);
    };

    void checkInbox();
  }, [inboxService]);

  return { inboxAccountExists, isLoadingCheckInbox };
};
