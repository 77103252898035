import { ChangeEvent, KeyboardEvent, memo, useCallback, useState } from 'react';

import { AddCirclePlusIcon } from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Input } from '@/lib/v2/components/Input';
import { Text } from '@/lib/v2/components/Text';

interface CreateItemProps {
  title: string;
  isCreating: boolean;
  onCreateItemClick: () => void;
  placeHolder?: string;
  onItemCreated?: (value: string) => void;
  value?: string;
}

const CreateItem = ({
  title,
  isCreating,
  onCreateItemClick,
  placeHolder,
  onItemCreated,
  value,
}: CreateItemProps) => {
  const [input, setInput] = useState('');

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const itemToCreate = e.target.value;

    if (itemToCreate.match(/^[\w .-]+$/)) {
      setInput(itemToCreate);
    }
  }, []);

  const handleKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ') {
      e.stopPropagation();
    }

    if (e.code === 'Enter') {
      addItem(input);
    }
  };

  const addItem = (value: string) => {
    Promise.resolve(onItemCreated?.(value))
      .then(() => {
        return;
      })
      .catch(console.error);
    setInput('');
  };

  if (!isCreating) {
    return (
      <button className="flex items-center gap-1 p-2" onClick={onCreateItemClick}>
        <IconSvg className="!h-6 !w-6" svgComponent={<AddCirclePlusIcon />} />
        <Text fontWeight="bold" variant="text">
          {title}
        </Text>
      </button>
    );
  }

  return (
    <div className="flex w-full flex-col gap-1 p-2">
      <Text fontWeight="bold" variant="text">
        {title}:
      </Text>

      <div className="flex w-full gap-2 pt-2">
        <Input
          autoFocus={true}
          placeHolder={placeHolder}
          value={input || value}
          onChange={handleOnChange}
          onKeyDown={handleKeyEvent}
        />
        <button onClick={() => addItem(input)}>
          <IconSvg className="!h-6 !w-6" svgComponent={<AddCirclePlusIcon />} />
        </button>
      </div>
    </div>
  );
};

export default memo(CreateItem);
