import { useTranslation } from 'react-i18next';

export const useSignatures = () => {
  const { t } = useTranslation(['editorHtml']);

  const signatures = [
    { name: t('subscribe'), number: '1', value: 'subscribe' },
    { name: t('shareOnFacebook'), number: '2', value: 'facebook' },
    { name: t('shareOnTwitter'), number: '3', value: 'twitter' },
    { name: t('seeMyBrowser'), number: '4', value: 'online' },
    { name: t('updateProfile'), number: '5', value: 'update' },
    { name: t('legal'), number: '6', value: 'legal' },
    { name: t('senderData'), number: '7', value: 'data' },
    { name: t('unsubscribe'), number: '8', value: 'unsubscription' },
  ];

  const signaturesNameList = signatures.map(({ name }) => name);

  const getSignatureValue = (signatureName: string) => {
    const selectedSignature = signatures.find((obj) => obj.name === signatureName);
    return selectedSignature?.value;
  };

  return { signaturesNameList, getSignatureValue };
};
