import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';

export const useFrequencyOptions = () => {
  const { t } = useTranslation();

  const frequencyOptions = useMemo<[Frequency, Frequency]>(
    () => [
      {
        value: 'monthly',
        label: t('MANAGE_PLAN.STEP_ONE.CONTENT.monthly'),
        priceSuffix: t('MANAGE_PLAN.STEP_ONE.CONTENT.monthSuffix'),
      },
      {
        value: 'annually',
        label: t('MANAGE_PLAN.STEP_ONE.CONTENT.annually'),
        priceSuffix: t('MANAGE_PLAN.STEP_ONE.CONTENT.yearSuffix'),
      },
    ],
    [t]
  );

  return { frequencyOptions };
};
