export const RfmCartIcon = () => {
  return (
    /* spell-checker: disable */
    <svg
      height="25.311"
      id="shopping-cart-solid-arrow"
      viewBox="0 0 30.019 29.311"
      width="26.019"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36,13.19a2.99,2.99,0,1,0,2.99,2.99,3,3,0,0,0-2.99-2.99Z"
        data-name="Trazado 4643"
        fill="#1654ac"
        id="Trazado_4643"
        transform="translate(3.36 10.142)"
      />
      <path
        d="M15,13.19h0a2.99,2.99,0,1,0,2.99,2.99A3,3,0,0,0,15,13.19Z"
        data-name="Trazado 4644"
        fill="#1654ac"
        id="Trazado_4644"
        transform="translate(9.235 10.142)"
      />
      <path
        d="M8.473,6.138,7.253,0H0V3.538H4.352L7.359,18.556a4.221,4.221,0,0,0,4.122,3.4H23.545a4.19,4.19,0,0,0,4.122-3.414l2.353-12.4H8.473Zm13.3,8.579-3.2,3.219c-1.061,1.256-1.362.8-2.087,0l-3.308-3.29a.721.721,0,0,1,.566-1.168h2.388V10.1a.717.717,0,0,1,.725-.725h1.309a.717.717,0,0,1,.725.725v3.379H21.28A.732.732,0,0,1,21.776,14.718Z"
        data-name="Trazado 4645"
        fill="#1654ac"
        id="Trazado_4645"
      />
    </svg>
  );
};
