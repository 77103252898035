import { memo, MouseEventHandler, ReactNode } from 'react';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, Card, CardFooter, CardSection, IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
interface HeaderBannerProps {
  backButton?: boolean;
  onBack?: MouseEventHandler<HTMLButtonElement>;
  actionsButtons?: ReactNode;
  withFooter?: boolean;
  icon?: ReactNode | JSX.Element;
  content?: ReactNode;
  footerContent?: ReactNode;
}
const HeaderBanner = ({
  backButton,
  onBack,
  actionsButtons,
  withFooter,
  icon,
  content,
  footerContent,
}: HeaderBannerProps) => {
  return (
    <>
      <Card>
        <CardSection>
          <Flex alignment="spaceBetween">
            <Flex alignment="start">
              {backButton && (
                <div className="mr-4">
                  <Button standard size="tiny" onClick={onBack}>
                    <IconSvg svgComponent={<ArrowBackIcon />} />
                  </Button>
                </div>
              )}
              <div className="mr-2 text-[30px]">{icon && icon}</div>
              <Flex column alignment="start" itemAlignment="start">
                {content}
              </Flex>
            </Flex>
            {actionsButtons && actionsButtons}
          </Flex>
        </CardSection>
        <>
          {withFooter && footerContent && (
            <CardFooter noPadding withDivide color="gray">
              {footerContent}
            </CardFooter>
          )}
        </>
      </Card>
    </>
  );
};
export default memo(HeaderBanner);
