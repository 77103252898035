import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { If } from '@/lib/v2/components/If';
import { Input, InputProps } from '@/lib/v2/components/Input';
import Text from '@/lib/v2/components/Text/Text';
import { CloseIcon } from '@/lib/v2/icons/outline';

interface TextToInputEditor extends InputProps {
  onClickConfirmEdition?: (value: string) => void;
  isDisabledConfirmButton?: boolean;
}

const TextToInputEditor = (props: TextToInputEditor) => {
  const { defaultValue, value, onChange, isDisabledConfirmButton } = props;
  const { onClickConfirmEdition, ...restInputsProps } = props;

  const [previousControlledValue, setPreviousControlledValue] = useState(value);
  const [internalValue, setInternalValue] = useState(defaultValue ?? '');
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const classesButton = classNames('my-1 h-[38px] w-full rounded px-3 py-2 hover:bg-gray-300', {
    'cursor-pointer': !restInputsProps.disabled,
    'cursor-not-allowed': restInputsProps.disabled,
  });

  const handleClickText = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleClickCancelEdition = useCallback(() => {
    if (inputRef.current && internalValue && value) {
      inputRef.current.value = internalValue;
      if (onChange && value !== previousControlledValue) {
        onChange({ target: { value: previousControlledValue } } as ChangeEvent<HTMLInputElement>);
      }
    }
    setIsEditing(false);
  }, [internalValue, value, onChange, previousControlledValue]);

  const handleConfirmEdition = useCallback(() => {
    const value = inputRef.current?.value ?? '';
    setInternalValue(value);
    setPreviousControlledValue(value);
    setIsEditing(false);
    onClickConfirmEdition?.(value);
  }, [onClickConfirmEdition]);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current?.focus();
    }
  });

  return (
    <div className="flex w-full">
      <If condition={!isEditing}>
        <button
          className={classesButton}
          onClick={!restInputsProps.disabled ? handleClickText : undefined}
        >
          <Text className="h-full" color="primary" variant="text">
            {internalValue}
          </Text>
        </button>
      </If>
      <If condition={isEditing}>
        <div className="relative w-full">
          <Input
            ref={inputRef}
            {...restInputsProps}
            {...(value && { value })}
            {...(defaultValue && { defaultValue: internalValue })}
          />
          <div className="absolute z-[999] flex gap-2">
            <button
              className={`flex items-center rounded border bg-gray-100 p-1 shadow ${
                isDisabledConfirmButton ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              disabled={isDisabledConfirmButton}
              onClick={handleConfirmEdition}
            >
              <IconSvg
                fillColor="primary"
                id="deleteEventItem"
                strokeColor="primary"
                svgComponent={<CheckIcon />}
              />
            </button>
            <button
              className="flex items-center rounded border bg-gray-100 p-1 shadow"
              onClick={handleClickCancelEdition}
            >
              <IconSvg
                fillColor="destructive"
                height="12px"
                id="deleteEventItem"
                strokeColor="destructive"
                svgComponent={<CloseIcon />}
                width="16px"
              />
            </button>
          </div>
        </div>
      </If>
    </div>
  );
};

export default memo(TextToInputEditor);
