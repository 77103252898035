import { useAtomValue, useSetAtom } from 'jotai';
import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MessageInput } from './components/MessageInput';
import { PhoneInput } from './components/PhoneInput';
import { RecipientDropdownSms } from './components/RecipientDropdownSms';
import { WarningByCountry } from './components/WarningByCountry';
import { useSmsForm } from './hooks/useSmsForm';

import {
  atomDisableEditAction,
  atomGlobalLoadingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomContractCountry,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { EStepHeaderAction } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface ISmsFormProps {
  loading: boolean;
}

const SmsForm = ({ loading }: ISmsFormProps) => {
  const navigate = useNavigate();

  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const setCurrentStep = useSetAtom(atomHeaderCurrentStep);
  const contractCountry = useAtomValue(atomContractCountry);
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const { control, RULES, handleSubmit, setValue, getValues } = useSmsForm();
  const formProps = useMemo(
    () => ({ control, setValue, getValues }),
    [control, setValue, getValues]
  );

  const handleSubmitData = useCallback(() => {
    setCurrentStep(EStepHeaderAction.MIDDLE);
    navigate('scheduling');
  }, [navigate, setCurrentStep]);

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setGlobalLoadingAction(false);
  }, [handleSubmit, handleSubmitData, setCallbackStep, setGlobalLoadingAction]);

  return (
    <div className="visible-scrollbar h-[calc(100vh_-_240px)] overflow-y-auto overflow-x-hidden pb-4 pl-1">
      <form
        autoComplete="off"
        className="pb-4 pr-3"
        id="immediate-form"
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
          handleSubmit(handleSubmitData);
        }}
      >
        {contractCountry === 'CO' && (
          <div className="mb-12">
            <WarningByCountry />
          </div>
        )}
        <div className="mb-12">
          <MessageInput
            disabled={disableEditAction}
            form={formProps}
            loading={loading}
            rules={RULES}
          />
        </div>
        <div>
          <RecipientDropdownSms
            disabled={disableEditAction}
            form={formProps}
            loading={loading}
            rules={RULES}
          />
          <div className="mt-6">
            <PhoneInput disabled={disableEditAction} form={formProps} rules={RULES} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SmsForm;
