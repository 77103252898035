import { ITableContact, TableContact } from '@/src/ContactsModule/interfaces/Interfaces';

/* cspell:disable */
export const contactListMock: ITableContact[] = [
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro 111',
      lastName: 'Gonzalez',
      phone: undefined,
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: '',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 800,
      email: 'alejandro.g@embluemail.com',
      name: 'Alejandro',
      lastName: 'Gonzalez',
      phone: '541112345678',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
        { id: 92387, name: 'newsletter' },
        { id: 92388, name: 'marketing' },
      ],
      origin: 'csv',
      status: 'new',
      created: new Date(2018, 11, 24, 10, 33),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 801,
      email: 'ana.c@embluemail.com',
      name: 'Ana',
      lastName: 'Cortes',
      phone: '5195555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92388, name: 'marketing' },
        { id: 92387, name: 'newsletter' },
      ],
      origin: 'csv',
      status: 'inactive',
      created: new Date(2020, 10, 22, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
  {
    contact: {
      emailId: 802,
      email: 'diego.p@embluemail.com',
      name: 'Diego',
      lastName: 'Perez',
      phone: '541155555555',
      tags: [
        { id: 92385, name: 'release' },
        { id: 92386, name: 'emblue' },
      ],
      origin: 'integrations',
      status: 'asleep',
      created: new Date(2020, 4, 5, 8, 36),
      lastSend: new Date(2022, 5, 15, 14, 52),
    },
  },
];

export const contactListMockList: ITableContact[][] = [
  [
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
  ],
  [
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g2222@embluemail.com',
        name: 'Alejandro 2222',
        lastName: 'Gonzalez 2222',
        phone: '12345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: '541112345678',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: '5195555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: '541155555555',
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
  ],
  [
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
      },
    },
  ],
  [
    {
      contact: {
        emailId: 800,
        email: 'alejandro.g@embluemail.com',
        name: 'Alejandro',
        lastName: 'Gonzalez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
          { id: 92387, name: 'newsletter' },
          { id: 92388, name: 'marketing' },
        ],
        origin: 'csv',
        status: 'new',
        created: new Date(2018, 11, 24, 10, 33),
        lastSend: new Date(2022, 5, 15, 14, 52),
        npsStatus: 'promoter',
        npsCampaign: 'Campaign 1',
      },
    },
    {
      contact: {
        emailId: 801,
        email: 'ana.c@embluemail.com',
        name: 'Ana',
        lastName: 'Cortes',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92388, name: 'marketing' },
          { id: 92387, name: 'newsletter' },
        ],
        origin: 'csv',
        status: 'inactive',
        created: new Date(2020, 10, 22, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
        npsStatus: 'passive',
        npsCampaign: 'Campaign 6546',
      },
    },
    {
      contact: {
        emailId: 802,
        email: 'diego.p@embluemail.com',
        name: 'Diego',
        lastName: 'Perez',
        phone: undefined,
        tags: [
          { id: 92385, name: 'release' },
          { id: 92386, name: 'emblue' },
        ],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 4, 5, 8, 36),
        lastSend: new Date(2022, 5, 15, 14, 52),
        npsStatus: 'detractor',
        npsCampaign: 'Campaign Welcome',
      },
    },
    {
      contact: {
        emailId: 805,
        email: 'juan.p@embluemail.com',
        name: 'Juan',
        lastName: 'Perez',
        phone: undefined,
        tags: [],
        origin: 'integrations',
        status: 'asleep',
        created: new Date(2020, 8, 5, 10, 36),
        lastSend: new Date(2022, 6, 15, 20, 52),
      },
    },
  ],
  [],
];

export const getImportConfigurationNetworkResponse = {
  registros: 84,
  columnas: [
    {
      id_campo_personalizado: 193975,
      value: ['Alvarez', 'Rodriguez', 'Lopez', 'Riquelme', 'Milito'],
    },
    { id_campo_personalizado: 193991, value: ['Rodrigo', 'Darío', 'Pablo', 'Fernando', 'Juan'] },
    {
      id_campo_personalizado: 0,
      value: [
        'test@embluemail.com',
        'test@embluemail.com',
        'test@embluemail.com',
        'test@embluemail.com',
        'test@embluemail.com',
      ],
    },
    {
      id_campo_personalizado: 0,
      value: ['17 Aug 2022', '31 Jul 2016', '31 Oct 2016', '03 Nov 2020', '12 May 2020'],
    },
    {
      id_campo_personalizado: 0,
      value: ['New-Member', 'Renewed', 'Renewed', 'Renewed', 'Renewed'],
    },
    {
      id_campo_personalizado: 0,
      value: ['12 Aug 2022', '01 Mar 2023', '19 Jul 2019', '30 Oct 2020', '29 Jun 2021'],
    },
  ],
  indiceColumnaMail: 1,
  delimitadorsugerido: ',',
  cabezalesSugerido: true,
  reprocesarArchivo: false,
  columnasEspeciales: {},
};

export const getListCustomFieldsNetworkResponse = [
  {
    id: '193991',
    nombre: 'email',
    valor: null,
    tipoCampo: '1',
    tipoDato: '4',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '0',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193975',
    nombre: 'nombre',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '1',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193976',
    nombre: 'apellido',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '2',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193977',
    nombre: 'id_contacto',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '3',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193978',
    nombre: 'sexo',
    valor: null,
    tipoCampo: '1',
    tipoDato: '5',
    tipoOpcion: '2',
    valores: 'M;F;Masculino;Femenino;H;M;Hombre;Mujer',
    esFijoEmblue: true,
    numero: '4',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193979',
    nombre: 'telefono_1',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '5',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193980',
    nombre: 'telefono_2',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '6',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193981',
    nombre: 'web_url',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '7',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193982',
    nombre: 'linkedin',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '8',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193983',
    nombre: 'facebook',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '9',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193984',
    nombre: 'twitter',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '10',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193985',
    nombre: 'direccion',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '11',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193986',
    nombre: 'ciudad',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '12',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193987',
    nombre: 'pais',
    valor: null,
    tipoCampo: '1',
    tipoDato: '6',
    tipoOpcion: '2',
    valores:
      'AD;AE;AF;AG;AI;AL;AM;AO;AQ;AR;AS;AT;AU;AW;AX;AZ;BA;BB;BD;BE;BF;BG;BH;BI;BJ;BL;BM;BN;BO;BQ;BR;BS;BT;BV;BW;BY;BZ;CA;CC;CD;CF;CG;CH;CI;CK;CL;CM;CN;CO;CR;CU;CV;CW;CX;CY;CZ;DE;DJ;DY;DK;DM;DO;DZ;EC;EG;EH;ER;ES;ET;EW;FI;FJ;FK;FM;FO;FR;GA;GB;GD;GE;GF;GG;GH;GI;GL;GM;GN;GP;GQ;GR;GS;GT;GU;GW;GY;HK;HM;HN;HR;HT;HU;ID;IE;IL;IM;IN;IO;IQ;IR;IS;IT;JE;JM;JO;JP;KE;KG;KH;KI;KM;KN;KP;KR;KW;KY;KZ;LA;LB;LC;LI;LK;LR;LS;LT;LU;LV;LY;MA;MC;MD;ME;MF;MG;MH;MK;ML;MM;MN;MO;MP;MQ;MR;MS;MT;MU;MV;MW;MX;MY;MZ;NA;NC;NE;NF;NG;NI;NL;NO;NP;NR;NU;NZ;OM;PA;PE;PF;PG;PH;PK;PL;PM;PN;PR;PS;PT;PW;PY;QA;RE;RS;RU;RW;SB;SC;SE;SG;SI;SJ;SK;SM;SN;SO;SS;ST;SX;SY;SZ;TD;TF;TG;TJ;TK;TM;TN;TR;TT;TW;UA;UG;UM;UY;UZ;VC;VE;VG;VN;VU;WF;XK;YE;YT;ZM;ZW;ZZ;RO;SA;SD;SH;SL;SR;SV;TC;TH;TL;TO;TV;TZ;US;VA;VI;WS;ZA',
    esFijoEmblue: true,
    numero: '13',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193988',
    nombre: 'cumpleanios',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '14',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193989',
    nombre: 'empresa',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '15',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193990',
    nombre: 'cargo',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '16',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193992',
    nombre: 'email_secundario',
    valor: null,
    tipoCampo: '1',
    tipoDato: '4',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '17',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193993',
    nombre: 'id_trigger',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '18',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193994',
    nombre: 'recencia',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '19',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193995',
    nombre: 'frecuencia',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '20',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193996',
    nombre: 'monto',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '21',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1524580',
    nombre: 'nps',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '22',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1588964',
    nombre: 'clasificacion_ecommerce',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '23',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '194035',
    nombre: 'Negocio',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '26',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '194036',
    nombre: 'Area Negocio',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '27',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '195793',
    nombre: 'edad',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '28',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '329243',
    nombre: 'token',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '29',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '390568',
    nombre: 'telefono',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '30',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '411760',
    nombre: 'selecion multiple',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: 'opcion 1;opcion 2;opcion 3',
    esFijoEmblue: false,
    numero: '31',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '411761',
    nombre: 'seleccion unica',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '2',
    valores: 'opcion a;opcion b;opcion c',
    esFijoEmblue: false,
    numero: '32',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '493613',
    nombre: 'vigencia',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '33',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '494732',
    nombre: 'celular',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '34',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '558619',
    nombre: 'FechadeEnvio',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '35',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '560583',
    nombre: 'Campo_Opciones_Test',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '2',
    valores: '11;22',
    esFijoEmblue: false,
    numero: '36',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '560585',
    nombre: 'EstadoCivil',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '2',
    valores: 'SI;DIVORCIADO;SOLTERO;CONVIVIENT;CASADO',
    esFijoEmblue: false,
    numero: '37',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '563968',
    nombre: 'NRO_DEUDOR',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '38',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '563969',
    nombre: 'COD_BANCO',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '39',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '563970',
    nombre: 'cliente',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '40',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '563971',
    nombre: 'emaill',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '41',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '568976',
    nombre: 'Puntos',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '42',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '568977',
    nombre: 'Nivel',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '43',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '577916',
    nombre: 'fechahoy',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '44',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '644987',
    nombre: 'asunto',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '45',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '714642',
    nombre: 'GUID',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '46',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1017549',
    nombre: 'testDesa',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '47',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1017584',
    nombre: 'TestMultiDesa',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: 'Si;No',
    esFijoEmblue: false,
    numero: '48',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1041165',
    nombre: 'fil_baja_usuario',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: '0;1',
    esFijoEmblue: false,
    numero: '49',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1104237',
    nombre: 'genero',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '50',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1180233',
    nombre: 'fecha_y_hora',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '51',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1180234',
    nombre: 'TipoDocumento',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '52',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1180235',
    nombre: 'fecha_agregado',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '53',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1277048',
    nombre: 'ficha_matricula',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '54',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1319059',
    nombre: 'Gb_Actual_Blindaje',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '55',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1319060',
    nombre: 'Gb_Nuevo_Blindaje',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '56',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1319061',
    nombre: 'SEGMENTO',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '57',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1396072',
    nombre: 'tipo_contacto_test',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: 'id_cliente;cf_potencial;pruebas',
    esFijoEmblue: false,
    numero: '58',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1469712',
    nombre: 'felicidadTest',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '59',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1492957',
    nombre: 'Es contacto',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: 'si;no',
    esFijoEmblue: false,
    numero: '60',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1499797',
    nombre: 'MTO_DISPONIBLE',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '61',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1499798',
    nombre: 'id_d_cliente',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '62',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1504766',
    nombre: 'new',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '63',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1504767',
    nombre: 'marca',
    valor: null,
    tipoCampo: '1',
    tipoDato: '2',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '64',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1512124',
    nombre: 'telf_',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '65',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1525319',
    nombre: 'pruebantres',
    valor: null,
    tipoCampo: '1',
    tipoDato: '3',
    tipoOpcion: '3',
    valores: 'UNO;DOS;TRES',
    esFijoEmblue: false,
    numero: '66',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '884729',
    nombre: 'testsoporte',
    valor: null,
    tipoCampo: '2',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '96',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1146595',
    nombre: 'Facturaciones AFIP',
    valor: null,
    tipoCampo: '2',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '97',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1333843',
    nombre: 'pruebaJulian',
    valor: null,
    tipoCampo: '2',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '98',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1333844',
    nombre: 'TestAntonioCampoExte',
    valor: null,
    tipoCampo: '2',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '99',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '1452385',
    nombre: 'Notas',
    valor: null,
    tipoCampo: '2',
    tipoDato: '3',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: false,
    numero: '100',
    cantidadContactos: 0,
    solo_lectura: false,
    integracion_id: null,
  },
  {
    id: '193997',
    nombre: 'fecha_creacion',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '200',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '193998',
    nombre: 'ultima_apertura',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '201',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '193999',
    nombre: 'ultimo_click',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '202',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '194000',
    nombre: 'ultimo_viral',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '203',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '194001',
    nombre: 'ultima_actividad',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '204',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '194002',
    nombre: 'ultima_visita_web',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '205',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '194003',
    nombre: 'ultimo_tag_web',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '206',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '194004',
    nombre: 'ultimo_item_web',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '207',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '253666',
    nombre: 'ultima_suscripcion_push',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '208',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '253667',
    nombre: 'ultimo_envio_push',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '209',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
  {
    id: '253668',
    nombre: 'ultimo_envio_sms',
    valor: null,
    tipoCampo: '1',
    tipoDato: '1',
    tipoOpcion: '1',
    valores: null,
    esFijoEmblue: true,
    numero: '210',
    cantidadContactos: 0,
    solo_lectura: true,
    integracion_id: 0,
  },
];

export type TableContactWithSubRows = TableContact & { subRows: SubRows[] };
export interface SubRows {
  emailId: number;
  email: string;
  name: string;
  lastName: string;
  phone?: string;
}

export const subRowsMock: SubRows[] = [
  {
    emailId: 900,
    email: 'jesus.tovar@embluemail.com',
    name: 'Jesus',
    lastName: 'Tovar',
    phone: '541155555555',
  },
  {
    emailId: 901,
    email: 'luis.escalante@embluemail.com',
    name: 'Luis',
    lastName: 'Escalante',
    phone: '523455552355',
  },
  {
    emailId: 902,
    email: 'vanessa.olmos@embluemail.com',
    name: 'Vanessa',
    lastName: 'Olmos',
    phone: '523455552355',
  },
];

export const contactsMock: TableContact[] = [
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro 111',
    lastName: 'Gonzalez',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: '',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 800,
    email: 'alejandro.g@embluemail.com',
    name: 'Alejandro',
    lastName: 'Gonzalez',
    phone: '541112345678',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
      {
        id: 92388,
        name: 'marketing',
      },
    ],
    origin: 'csv',
    status: 'new',
    created: '2018-12-24T15:33:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 801,
    email: 'ana.c@embluemail.com',
    name: 'Ana',
    lastName: 'Cortes',
    phone: '5195555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92388,
        name: 'marketing',
      },
      {
        id: 92387,
        name: 'newsletter',
      },
    ],
    origin: 'csv',
    status: 'inactive',
    created: '2020-11-22T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
  {
    emailId: 802,
    email: 'diego.p@embluemail.com',
    name: 'Diego',
    lastName: 'Perez',
    phone: '541155555555',
    tags: [
      {
        id: 92385,
        name: 'release',
      },
      {
        id: 92386,
        name: 'emblue',
      },
    ],
    origin: 'integrations',
    status: 'asleep',
    created: '2020-05-05T13:36:00.000Z',
    lastSend: '2022-06-15T19:52:00.000Z',
  },
];
