import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { CopyToClipboard, Text } from '@/lib/v2/components';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';

const CopyExternalUrl = () => {
  const { t } = useTranslation('rules');

  const rulesData = useAtomValue(atomRuleData);

  return (
    <div className="flex flex-col pb-4 pt-2">
      <Text truncate fontWeight="medium" variant="text">
        {`${t(`${TRANSLATE_RULES_LABELS}.externalUrl`)}`}
      </Text>

      <div className="flex gap-3">
        <Text truncate fontWeight="normal" variant="text">
          {rulesData.externalUrl ?? ''}
        </Text>
        <CopyToClipboard color="dark" id="copyExternalUrl" value={rulesData.externalUrl ?? ''} />
      </div>
    </div>
  );
};

export default CopyExternalUrl;
