import { datadogRum } from '@datadog/browser-rum';
import { useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { WhatsAppIcon } from '@/lib/v2/icons/solid';
import { CardAction } from '@/lib/v2/organisms/CardAction';
import { CardActionProps } from '@/lib/v2/organisms/CardAction/CardAction';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { IRestricted } from '@/src/presentation/types/types';

import {
  atomActionInputModalIsOpen,
  atomActionTypeWithOptionalRule,
  atomCreateRules,
} from '@/modules/CampaignsModule/atoms/createActionsAtom';
import {
  APIEmailIcon,
  APISmsIcon,
  EmailRemarketingIcon,
  EmailSplitIcon,
  SimpleEmailIcon,
  SimpleSmsIcon,
  TriggerEmailIcon,
} from '@/modules/CampaignsModule/images/icons';
import { AttachmentIcon } from '@/modules/CampaignsModule/images/icons/AttachmentIcon';
import { ERulesActionType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface ActionsTabPaneContainerProps {
  filterBy?: 'all' | 'email' | 'sms' | 'inbox';
  onClose?: () => void;
  restrictions?: IRestricted | null;
  onLabelClick?: (action: ActionTypeName) => void;
}

const ActionsTabPaneContainer = ({
  filterBy = 'all',
  onClose,
  restrictions,
  onLabelClick,
}: ActionsTabPaneContainerProps) => {
  const { t } = useTranslation();

  const setActionTypeWithOptionalRule = useSetAtom(atomActionTypeWithOptionalRule);
  const setActionInputModalIsOpen = useSetAtom(atomActionInputModalIsOpen);
  const setCreateRules = useSetAtom(atomCreateRules);

  const showRulesTypeModalActions = useFeatureFlag('showRulesTypeModalActions');
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');
  const outboundWhatsApp = useFeatureFlag('outboundWhatsApp');

  const handleOpenCreateActionNameModal = useCallback(
    (actionType: ActionTypeName) => {
      setActionTypeWithOptionalRule({ name: actionType });
      setActionInputModalIsOpen(true);
      datadogRum.addAction('campaigns.onCreateAction', {
        actionType,
      });
      onClose?.();
    },
    [onClose, setActionInputModalIsOpen, setActionTypeWithOptionalRule]
  );

  const handleDetailRules = useCallback(() => {
    setCreateRules(ERulesActionType.RULES);
  }, [setCreateRules]);

  const cardActionsList = useMemo<CardActionProps[]>(() => {
    const actions: CardActionProps[] = [
      ...(outboundWhatsApp
        ? [
            {
              description: t('ALL_CARDS_ACTIONS.inboxWhatsapp.description'),
              title: t('ALL_CARDS_ACTIONS.inboxWhatsapp.title'),
              buttonText: t('ALL_CARDS_ACTIONS.inboxWhatsapp.buttonText'),
              withLabel:
                !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendWhatsapp
                  ? 'Upgrade plan'
                  : undefined,
              id: 'inbox-whatsapp',
              disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendWhatsapp,
              icon: (
                <IconSvg
                  rounded
                  bgColor="headingIcon"
                  fillColor="primary"
                  height="56px"
                  svgComponent={<WhatsAppIcon />}
                  width="56px"
                  withPadding="medium"
                />
              ),
              onLabelClick: () => onLabelClick && onLabelClick('whatsapp'),
              onClickAction: () => handleOpenCreateActionNameModal('whatsapp'),
            },
          ]
        : []),
      {
        description: t('ALL_CARDS_ACTIONS.simpleEmail.description'),
        title: t('ALL_CARDS_ACTIONS.simpleEmail.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'simple-email',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<SimpleEmailIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onClickAction: () => handleOpenCreateActionNameModal('email'),
      },
      ...(createSimpleEmail
        ? [
            {
              description: t('ALL_CARDS_ACTIONS.emailSplit.description'),
              title: t('ALL_CARDS_ACTIONS.emailSplit.title'),
              buttonText: t('ALL_CARDS_ACTIONS.emailSplit.buttonText'),
              id: 'email-split',
              icon: (
                <IconSvg
                  rounded
                  bgColor="headingIcon"
                  height="56px"
                  svgComponent={<EmailSplitIcon />}
                  width="56px"
                  withPadding="medium"
                />
              ),
              onClickAction: () => handleOpenCreateActionNameModal('email-split'),
            },
            {
              description: t('ALL_CARDS_ACTIONS.emailRemarketing.description'),
              title: t('ALL_CARDS_ACTIONS.emailRemarketing.title'),
              buttonText: t('ALL_CARDS_ACTIONS.emailRemarketing.buttonText'),
              id: 'email-remarketing',
              icon: (
                <IconSvg
                  rounded
                  bgColor="headingIcon"
                  height="56px"
                  svgComponent={<EmailRemarketingIcon />}
                  width="56px"
                  withPadding="medium"
                />
              ),
              onClickAction: () => handleOpenCreateActionNameModal('email-remarketing'),
            },
          ]
        : []),
      {
        description: t('ALL_CARDS_ACTIONS.emailAttachment.description'),
        title: t('ALL_CARDS_ACTIONS.emailAttachment.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'attachments-email',
        withLabel:
          !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendWithAttachment
            ? 'Upgrade plan'
            : undefined,
        disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendWithAttachment,
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<AttachmentIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onLabelClick: () => onLabelClick && onLabelClick('email-attachment'),
        onClickAction: () => handleOpenCreateActionNameModal('email-attachment'),
      },
      {
        description: t('ALL_CARDS_ACTIONS.triggerEmail.description'),
        title: t('ALL_CARDS_ACTIONS.triggerEmail.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'trigger-email',
        withLabel:
          !restrictions?.allAllowed && restrictions?.elementsBlocked?.triggers
            ? 'Upgrade plan'
            : undefined,
        disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.triggers,
        badgeText: t('ALL_CARDS_ACTIONS.triggerEmail.badgeText'),
        badgeColor: 'sleepy',
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<TriggerEmailIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onLabelClick: () => onLabelClick && onLabelClick('email-trigger'),
        onClickAction: () =>
          showRulesTypeModalActions
            ? handleDetailRules()
            : handleOpenCreateActionNameModal('email-trigger'),
      },
      {
        description: t('ALL_CARDS_ACTIONS.apiEmail.description'),
        title: t('ALL_CARDS_ACTIONS.apiEmail.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'email-api',
        badgeText: t('ALL_CARDS_ACTIONS.triggerEmail.badgeText'),
        badgeColor: 'sleepy',
        withLabel:
          !restrictions?.allAllowed && restrictions?.elementsBlocked?.mailByApi
            ? 'Upgrade plan'
            : undefined,
        disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.mailByApi,
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<APIEmailIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onLabelClick: () => onLabelClick && onLabelClick('mail-api'),
        onClickAction: () => handleOpenCreateActionNameModal('mail-api'),
      },
      {
        description: t('ALL_CARDS_ACTIONS.simpleSms.description'),
        title: t('ALL_CARDS_ACTIONS.simpleSms.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        withLabel:
          !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendSms
            ? 'Upgrade plan'
            : undefined,
        id: 'simple-sms',
        disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.sendSms,
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<SimpleSmsIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onLabelClick: () => onLabelClick && onLabelClick('sms'),
        onClickAction: () => handleOpenCreateActionNameModal('sms'),
      },
      {
        description: t('ALL_CARDS_ACTIONS.apiSms.description'),
        title: t('ALL_CARDS_ACTIONS.apiSms.title'),
        buttonText: t('ALL_CARDS_ACTIONS.simpleEmail.buttonText'),
        id: 'sms-api',
        badgeText: t('ALL_CARDS_ACTIONS.triggerEmail.badgeText'),
        badgeColor: 'sleepy',
        withLabel:
          !restrictions?.allAllowed && restrictions?.elementsBlocked?.smsApi
            ? 'Upgrade plan'
            : undefined,
        disabled: !restrictions?.allAllowed && restrictions?.elementsBlocked?.smsApi,
        icon: (
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="56px"
            svgComponent={<APISmsIcon />}
            width="56px"
            withPadding="medium"
          />
        ),
        onLabelClick: () => onLabelClick && onLabelClick('sms-api'),
        onClickAction: () => handleOpenCreateActionNameModal('sms-api'),
      },
    ];

    return actions;
  }, [
    restrictions,
    showRulesTypeModalActions,
    createSimpleEmail,
    handleDetailRules,
    handleOpenCreateActionNameModal,
    onLabelClick,
    t,
    outboundWhatsApp,
  ]);

  const filterCardActions = (filterParam: ActionsTabPaneContainerProps['filterBy']) => {
    if (!filterParam || filterParam === 'all') {
      return cardActionsList;
    }

    const filteredActions = cardActionsList.filter((action) => {
      return action?.id && action?.id.toLowerCase().includes(filterParam.toLowerCase());
    });

    return filteredActions;
  };

  const createActionsList = filterCardActions(filterBy);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
      {createActionsList.map((cardAction) => {
        return <CardAction key={cardAction.id} {...cardAction} justifyActionButton="end" />;
      })}
    </div>
  );
};

export default memo(ActionsTabPaneContainer);
