export const MainIcon = () => {
  return (
    <svg className="w-full" viewBox="0 0 22.785 18">
      <g id="mail" transform="translate(1.393 1)">
        <path
          d="M2,0H18a2.006,2.006,0,0,1,2,2V14a2.006,2.006,0,0,1-2,2H2a2.006,2.006,0,0,1-2-2V2A2.006,2.006,0,0,1,2,0Z"
          data-name="Path 3152"
          fill="none"
          id="Path_3152"
          stroke="#fc7e00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M20,2,10,9,0,2"
          data-name="Path 3153"
          fill="none"
          id="Path_3153"
          stroke="#fc7e00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
