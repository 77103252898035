import { JSX, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { ProgressBar } from '@/lib/v2/components/ProgressBar';
import { SuccessIcon, WarningIcon } from '@/lib/v2/icons/outline';
import { FileWithLine } from '@/lib/v2/icons/solid';

import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

export type LoadingProps = {
  percentage: number;
  description: string;
  status: loadingStatus;
  id?: string;
};

interface IStatusDictionary {
  initial: {
    icon?: null;
    description?: null;
  };
  loading: {
    icon: JSX.Element;
    description: string;
  };
  ready: {
    icon: JSX.Element;
    description: string;
  };
  error: {
    icon: JSX.Element;
    description: string;
  };
}
export const LoadingNotification = ({ percentage, description, status, id }: LoadingProps) => {
  const { t } = useTranslation();

  const statusDictionary: IStatusDictionary = useMemo(
    () => ({
      [loadingStatus.initial]: {},
      [loadingStatus.loading]: {
        icon: <FileWithLine />,
        description,
      },
      [loadingStatus.ready]: {
        icon: <SuccessIcon />,
        description: t('IMPORT_MAIN.fullLoad'),
      },
      [loadingStatus.error]: {
        icon: <WarningIcon />,
        description: t('IMPORT_MAIN.errorLoad'),
      },
    }),
    [description, t]
  );

  if (!statusDictionary?.[status]) return null;

  return (
    <div
      className="fixed inset-x-0 bottom-0 z-[999] ml-4 h-16 w-96 cursor-pointer rounded-t-md bg-[#CCD7F4] p-4"
      id={id}
    >
      <Flex>
        {statusDictionary[status].icon}

        <div className="relative top-1 w-full pl-5">
          <Flex column itemAlignment="stretch">
            <ProgressBar percentage={percentage} status={status} />
            <Flex>
              <Text>{statusDictionary[status].description}</Text>
              {status !== loadingStatus.error && <Text>{percentage}%</Text>}
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};
