import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { Option, Select } from '@/lib/v2/components';

import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { useTriggerOptions } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useTriggerOptions';

import { atomTriggerType } from '@/modules/RulesModule/atoms/rules';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ITriggerSelectProps {
  form: IFormProps<ITriggerForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const TriggerSelect = ({ form, disabled = false, rules }: ITriggerSelectProps) => {
  const { triggerOptions } = useTriggerOptions();

  const [triggerType, setTriggerType] = useAtom(atomTriggerType);

  const handleTriggerChange = useCallback(
    (value: Option | Option[]) => {
      if (Array.isArray(value)) return;
      setTriggerType(value);
    },
    [setTriggerType]
  );

  return (
    <div className="mb-5 mr-8 w-full">
      <Select
        isRequired
        control={form.control}
        disabled={disabled}
        name="trigger"
        options={triggerOptions}
        rules={rules.trigger}
        value={triggerType ?? triggerOptions[0]}
        onChange={handleTriggerChange}
      />
    </div>
  );
};

export default TriggerSelect;
