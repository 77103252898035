import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { atomRefetchAllData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDeleteModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';

interface DeleteCampaignsProps {
  campaigns: number[];
  redirect?: boolean;
}

const DeleteCampaigns = ({ campaigns, redirect = false }: DeleteCampaignsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const [toggleDeleteModal, setToggleDeleteModal] = useAtom(atomToggleDeleteModal);

  const { deleteCampaign, loading } = useCampaign();

  const handleConfirmDeleteCampaigns = useCallback(async () => {
    await deleteCampaign(campaigns);
    setToggleDeleteModal(false);
    redirect ? navigate('/v2/campaigns') : setRefetchAllData(true);
  }, [deleteCampaign, campaigns, setToggleDeleteModal, redirect, navigate, setRefetchAllData]);

  return (
    <ModalConfirmation
      description={
        campaigns?.length > 1
          ? t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.MULTIPLE.description')
          : t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.SINGLE.description')
      }
      isLoading={loading}
      isOpen={toggleDeleteModal}
      textCancelButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.cancelButton')}
      textConfirmButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.successButton')}
      title={
        campaigns?.length > 1
          ? t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.MULTIPLE.title')
          : t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.SINGLE.title')
      }
      onClose={setToggleDeleteModal}
      onConfirmButton={handleConfirmDeleteCampaigns}
    />
  );
};

export default DeleteCampaigns;
