export const DistributeContactsIcon = () => {
  return (
    <svg
      height="23.499"
      viewBox="0 0 23.629 23.499"
      width="23.629"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Distribution" transform="translate(-4.631 -5.354)">
        <path
          d="M69.9,66.541a.2.2,0,0,0-.349-.131l-.785.785L66.892,65.32a.5.5,0,0,0-.741,0l-1.264,1.264a.5.5,0,0,0,0,.741L66.761,69.2l-.785.785a.2.2,0,0,0,.131.349l3.924.262a.206.206,0,0,0,.218-.218Z"
          data-name="Trazado 3144"
          fill="#005adc"
          id="Trazado_3144"
          transform="translate(-41.988 -41.786)"
        />
        <path
          d="M4.936,66.642a.2.2,0,0,1,.349-.131l.828.828,1.875-1.875a.5.5,0,0,1,.741,0l1.264,1.264a.5.5,0,0,1,0,.741L8.119,69.345l.785.785a.2.2,0,0,1-.131.349l-3.924.262a.206.206,0,0,1-.218-.218Z"
          data-name="Trazado 3145"
          fill="#005adc"
          id="Trazado_3145"
          transform="translate(0 -41.887)"
        />
        <g data-name="Grupo 3383" id="Grupo_3383" transform="translate(4.631 5.354)">
          <path
            d="M69.9,9.554a.2.2,0,0,1-.349.131L68.766,8.9l-1.875,1.875a.5.5,0,0,1-.741,0L64.886,9.51a.5.5,0,0,1,0-.741l1.875-1.875-.785-.785a.2.2,0,0,1,.131-.349L70.031,5.5a.206.206,0,0,1,.218.218Z"
            data-name="Trazado 3146"
            fill="#005adc"
            id="Trazado_3146"
            transform="translate(-46.619 -5.455)"
          />
          <path
            d="M4.936,9.452a.2.2,0,0,0,.349.131l.828-.828L7.988,10.63a.5.5,0,0,0,.741,0L9.993,9.365a.5.5,0,0,0,0-.741L8.119,6.749,8.9,5.965a.2.2,0,0,0-.131-.349L4.849,5.354a.206.206,0,0,0-.218.218Z"
            data-name="Trazado 3147"
            fill="#005adc"
            id="Trazado_3147"
            transform="translate(-4.631 -5.354)"
          />
        </g>
        <g data-name="Grupo 3384" id="Grupo_3384" transform="translate(13.045 7.621)">
          <circle
            cx="1.787"
            cy="1.787"
            data-name="Elipse 1491"
            fill="#005adc"
            id="Elipse_1491"
            r="1.787"
            transform="translate(1.613)"
          />
          <path
            d="M33.258,30.071h5.493a.671.671,0,0,0,.654-.741,3.251,3.251,0,0,0-3.4-2.7,3.226,3.226,0,0,0-3.444,2.834C32.56,30.115,33.258,30.071,33.258,30.071Z"
            data-name="Trazado 3148"
            fill="#005adc"
            id="Trazado_3148"
            transform="translate(-32.56 -22.485)"
          />
        </g>
        <g data-name="Grupo 3385" id="Grupo_3385" transform="translate(13.045 17.213)">
          <circle
            cx="1.787"
            cy="1.787"
            data-name="Elipse 1492"
            fill="#005adc"
            id="Elipse_1492"
            r="1.787"
            transform="translate(1.613)"
          />
          <path
            d="M33.258,61.908h5.493a.671.671,0,0,0,.654-.741,3.286,3.286,0,0,0-3.4-2.7A3.226,3.226,0,0,0,32.56,61.3C32.56,61.908,33.258,61.908,33.258,61.908Z"
            data-name="Trazado 3149"
            fill="#005adc"
            id="Trazado_3149"
            transform="translate(-32.56 -54.322)"
          />
        </g>
        <g data-name="Grupo 3388" id="Grupo_3388" transform="translate(6.549 12.679)">
          <g data-name="Grupo 3386" id="Grupo_3386" transform="translate(0 0)">
            <circle
              cx="1.787"
              cy="1.787"
              data-name="Elipse 1493"
              fill="#005adc"
              id="Elipse_1493"
              r="1.787"
              transform="translate(1.613)"
            />
            <path
              d="M11.7,46.858h5.493a.671.671,0,0,0,.654-.741,3.286,3.286,0,0,0-3.4-2.7A3.226,3.226,0,0,0,11,46.248C11,46.858,11.7,46.858,11.7,46.858Z"
              data-name="Trazado 3150"
              fill="#005adc"
              id="Trazado_3150"
              transform="translate(-10.998 -39.272)"
            />
          </g>
          <g data-name="Grupo 3387" id="Grupo_3387" transform="translate(12.992 0)">
            <circle
              cx="1.787"
              cy="1.787"
              data-name="Elipse 1494"
              fill="#005adc"
              id="Elipse_1494"
              r="1.787"
              transform="translate(1.613)"
            />
            <path
              d="M54.82,46.858h5.493a.671.671,0,0,0,.654-.741,3.286,3.286,0,0,0-3.4-2.7,3.226,3.226,0,0,0-3.444,2.834C54.122,46.858,54.82,46.858,54.82,46.858Z"
              data-name="Trazado 3151"
              fill="#005adc"
              id="Trazado_3151"
              transform="translate(-54.122 -39.272)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
