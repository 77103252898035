import { useAtom, useSetAtom } from 'jotai';
import { ChangeEvent, useCallback } from 'react';

import { useSearchCampaignsData } from '@/src/modules/CampaignsModule/components/SearchCampaigns/hooks/useSearchCampaignsData';

import {
  atomKeywordSearchCampaigns,
  atomListSearchCampaigns,
  atomPayloadSearchCampaigns,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

export const useSearchCampaignsInput = () => {
  const [keywordSearchCampaigns, setKeywordSearchCampaigns] = useAtom(atomKeywordSearchCampaigns);
  const setListSearchCampaigns = useSetAtom(atomListSearchCampaigns);
  const { searchCampaignsGlobal } = useSearchCampaignsData();
  const [payloadSearchCampaigns, setPayloadSearchCampaigns] = useAtom(atomPayloadSearchCampaigns);

  const handleOnChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setKeywordSearchCampaigns(event.target.value);
    },
    [setKeywordSearchCampaigns]
  );

  const handleOnClickSearch = useCallback(async () => {
    if (keywordSearchCampaigns?.length === 0) return;
    if (keywordSearchCampaigns === payloadSearchCampaigns?.search) return;
    keywordSearchCampaigns &&
      (await searchCampaignsGlobal({ keyword: keywordSearchCampaigns, page: 1 }));
  }, [keywordSearchCampaigns, payloadSearchCampaigns, searchCampaignsGlobal]);

  const handleOnClearSearch = useCallback(() => {
    setKeywordSearchCampaigns('');
    setListSearchCampaigns(undefined);
    if (payloadSearchCampaigns.search) {
      setPayloadSearchCampaigns({ ...payloadSearchCampaigns, search: '' });
    }
  }, [
    payloadSearchCampaigns,
    setKeywordSearchCampaigns,
    setListSearchCampaigns,
    setPayloadSearchCampaigns,
  ]);

  return {
    events: {
      handleOnChangeSearch,
      handleOnClickSearch,
      handleOnClearSearch,
    },
    state: {
      keywordSearchCampaigns,
    },
  };
};
