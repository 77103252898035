export const UserIcon = () => {
  return (
    <svg className="w-full" viewBox="0 0 21.97 17">
      <path
        d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,1,0,2,0,6,6,0,1,1,12,0,1,1,0,0,0,2,0,8,8,0,0,0-4.7-7.28ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,2.995,2.995,0,0,1,1.5,5.59,1,1,0,0,0-.05,1.7l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"
        fill="#18c678"
        id="users"
        transform="translate(-1 -3.5)"
      />
    </svg>
  );
};
