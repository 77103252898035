export const CopyIcon = ({ color = '#004dbc' }) => {
  return (
    <svg height="21" viewBox="0 0 18 21" width="18" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 8547" id="Grupo_8547" transform="translate(45.49 34.961)">
        <g data-name="Grupo 7900" id="Grupo_7900" transform="translate(-45.49 -34.961)">
          <path
            d="M7.5,3.375A1.875,1.875,0,0,1,9.375,1.5H9.75A3.75,3.75,0,0,1,13.5,5.25V7.125A1.875,1.875,0,0,0,15.375,9H17.25A3.75,3.75,0,0,1,21,12.75v3.375A1.876,1.876,0,0,1,19.125,18H9.375A1.875,1.875,0,0,1,7.5,16.125Z"
            fill={color}
            transform="translate(-3 -1.5)"
          />
          <path
            d="M15,5.25a5.23,5.23,0,0,0-1.279-3.434,9.768,9.768,0,0,1,6.963,6.963A5.23,5.23,0,0,0,17.25,7.5H15.375A.375.375,0,0,1,15,7.125ZM4.875,6H6V16.125A3.375,3.375,0,0,0,9.375,19.5H16.5v1.125A1.876,1.876,0,0,1,14.625,22.5H4.875A1.875,1.875,0,0,1,3,20.625V7.875A1.875,1.875,0,0,1,4.875,6Z"
            fill={color}
            transform="translate(-3 -1.5)"
          />
        </g>
      </g>
    </svg>
  );
};
