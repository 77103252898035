import { SenderScoreDTO } from '@/src/presentation/types/DTOs/SenderScoreDTO';
import { ISenderScore } from '@/src/presentation/types/interfaces/ISenderScore.interface';

// TODO: Ask before making a class like this!
export class SenderScore implements ISenderScore {
  date: Date;
  scoring: number;

  constructor(date: Date, scoring: number) {
    this.date = date;
    this.scoring = scoring;
  }

  static mapSenderScoreDTO(senderscorings: SenderScoreDTO[]): ISenderScore[] {
    return senderscorings.map((scoring) => new SenderScore(scoring.fecha, scoring.score));
  }
}
