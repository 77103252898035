export const FilterIcon = () => {
  return (
    <svg
      height="15.712"
      viewBox="0 0 15.714 15.712"
      width="15.714"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.979,0H.738A.737.737,0,0,0,.217,1.258L5.894,6.936v6.325a.737.737,0,0,0,.314.6l2.456,1.718a.737.737,0,0,0,1.159-.6V6.936L15.5,1.258A.737.737,0,0,0,14.979,0Z"
        data-name="Icon awesome-filter"
        fill="#004dbc"
        id="Icon_awesome-filter"
        transform="translate(-0.001)"
      />
    </svg>
  );
};
