import { memo } from 'react';

import { Text } from '@/lib/v2/components';

interface SubSectionProps {
  title: string;
  text: React.ReactNode;
  singleLine?: boolean;
}

const SummarySubSection = ({ title, text, singleLine }: SubSectionProps) => {
  return (
    <div className="mt-6">
      <Text className="mb-3 text-emblue-subheadings" fontWeight="normal" variant="text">
        {title} {singleLine && <span className="font-medium">{text}</span>}
      </Text>
      {Array.isArray(text) ? (
        text.map((item, index) => (
          <Text
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="pl-1 text-emblue-subheadings"
            fontWeight="medium"
            variant="text"
          >
            {item}
          </Text>
        ))
      ) : (
        <Text className="text-emblue-subheadings" fontWeight="medium" variant="text">
          {!singleLine && text}
        </Text>
      )}
    </div>
  );
};

export default memo(SummarySubSection);
