export const MailPorApi = () => {
  return (
    <svg
      className="w-8 fill-current text-emblue-blue-dark group-hover:fill-current group-hover:text-emblue-white group-focus:fill-current group-focus:text-white sm:w-8 md:w-10 lg:w-12 xl:w-16"
      viewBox="0 0 51.98 53.281"
    >
      <g data-name="Group 34">
        <path
          d="M49.749 26.641a23.8 23.8 0 0 0-.779-6.053l3.007-2.213-5.834-10.11-3.425 1.5A23.589 23.589 0 0 0 32.24 3.711L31.827 0H20.149l-.412 3.711A23.588 23.588 0 0 0 9.262 9.766l-3.425-1.5L0 18.375l3.007 2.213a23.908 23.908 0 0 0 0 12.105L0 34.906l5.837 10.11 3.425-1.5a23.587 23.587 0 0 0 10.479 6.054l.412 3.711h11.674l.412-3.711a23.587 23.587 0 0 0 10.478-6.054l3.425 1.5 5.837-10.11-3.007-2.212a23.8 23.8 0 0 0 .777-6.053Zm-19.28 20.15-1.091.241-.347 3.127h-6.082l-.347-3.127-1.091-.241a20.517 20.517 0 0 1-10.726-6.2l-.754-.822-2.884 1.264-3.043-5.271L6.632 33.9l-.335-1.064a20.753 20.753 0 0 1 0-12.395l.335-1.064-2.531-1.862 3.043-5.271 2.884 1.264.754-.822A20.517 20.517 0 0 1 21.509 6.49l1.091-.241.349-3.127h6.086l.347 3.127 1.091.241a20.518 20.518 0 0 1 10.726 6.2l.754.822 2.884-1.264 3.043 5.271-2.531 1.86.335 1.064a20.753 20.753 0 0 1 0 12.395l-.335 1.062 2.531 1.862-3.043 5.271-2.888-1.261-.754.822a20.517 20.517 0 0 1-10.724 6.197Z"
          data-name="Path 1"
        />
        <path
          d="m19.793 23.067-2.23-2.184-5.709 5.83 5.83 5.709 2.184-2.231-3.6-3.525Z"
          data-name="Path 2"
        />
        <path
          d="m32.187 23.067 3.525 3.6-3.6 3.525 2.184 2.231 5.83-5.709-5.709-5.83Z"
          data-name="Path 3"
        />
        <path d="m22.253 36.858 4.44-21.1 3.056.643-4.44 21.1Z" data-name="Path 4" />
      </g>
    </svg>
  );
};
