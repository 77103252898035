export const VideoPromotionIcon = () => {
  return (
    <svg height="53" viewBox="0 0 53 53" width="53" xmlns="http://www.w3.org/2000/svg">
      <g data-name="20" id="_20" transform="translate(-1.627 -1.948)">
        <rect
          data-name="Rectangle 3823"
          fill="#90caf9"
          height="53"
          id="Rectangle_3823"
          rx="2"
          transform="translate(1.626 1.948)"
          width="53"
        />
        <path
          d="M30.338,17.86,11.942,7.348A2.628,2.628,0,0,0,8,9.634V30.658a2.628,2.628,0,0,0,3.942,2.286l18.4-10.512a2.628,2.628,0,0,0,0-4.573Z"
          data-name="Path 3403"
          fill="#1e88e5"
          id="Path_3403"
          transform="translate(9.775 8.141)"
        />
      </g>
    </svg>
  );
};
