import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { If, Input, Option, Select } from '@/lib/v2/components';

import { ITriggerForm } from '@/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IRedirectSelectProps {
  form: IFormProps<ITriggerForm>;
  redirectPageOptions: Option[];
  showInputField: boolean;
  rules: Record<string, RegisterOptions>;
  disabled: boolean;
  redirectFieldName: keyof ITriggerForm;
  destinationUrlFieldName: keyof ITriggerForm;
}

const RedirectSelect = ({
  form,
  redirectPageOptions,
  showInputField,
  rules,
  disabled,
  redirectFieldName,
  destinationUrlFieldName,
}: IRedirectSelectProps) => {
  const { t } = useTranslation('rules');

  return (
    <div className="w-full flex-col">
      <Select
        isRequired
        control={form.control}
        disabled={disabled}
        name={redirectFieldName}
        options={redirectPageOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.${redirectFieldName}`)}
        rules={rules[redirectFieldName]}
        onChange={(value) => form.setValue(redirectFieldName, value as Option)}
      />
      <If condition={showInputField}>
        <Input
          isRequired
          control={form.control}
          disabled={disabled}
          id={destinationUrlFieldName}
          name={destinationUrlFieldName}
          placeHolder={t(`${TRANSLATE_RULES_LABELS}.destinationUrl`)}
          rules={rules[destinationUrlFieldName]}
        />
      </If>
    </div>
  );
};

export default RedirectSelect;
