import { memo, useMemo } from 'react';

interface IProgressbar {
  percentage: number;
  error: boolean;
}

const Progress = memo(({ percentage, error }: IProgressbar) => {
  const colors = useMemo(
    () => ({
      loading: 'bg-blue-600',
      error: 'bg-[#FF4261]',
      done: 'bg-[#18C678]',
    }),
    []
  );

  const status = useMemo(() => {
    if (error) return 'error';
    return percentage < 100 ? 'loading' : 'done';
  }, [error, percentage]);

  const currentColor = useMemo(() => colors[status], [colors, status]);

  return (
    <div className="h-1.5 w-full rounded-full bg-gray-200 " data-testid="progressbar-sub-component">
      <div
        className={`${currentColor} h-1.5 rounded-full transition-all`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
});

export default Progress;
