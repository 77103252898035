import { ChevronDownIcon } from '@heroicons/react/outline';
import { memo, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CrossSimpleIcon } from '@/lib/icon';
import { Checkbox, IconSvg, Text } from '@/lib/v2/components';

import { AccordionSkeleton } from './AccordionSkeleton';

export interface IOptionAccordion {
  id: number;
  name: string;
}

interface AccordionItem {
  item: IOptionAccordion;
  onSelected: (item: IOptionAccordion) => void;
  checked: boolean;
}

/**
 * @desc Item of the accordion
 */
const AccordionItem = ({ item, onSelected, checked }: AccordionItem) => {
  const id = `checkbox-${item.id}`;
  return (
    <div className="mb-2 flex flex-row">
      <Checkbox checked={checked} id={id} label={item.name} onChange={() => onSelected(item)} />
    </div>
  );
};

export interface IAccordionSelected {
  [key: string]: IOptionAccordion;
}

interface IAccordion {
  title: string | React.ReactElement;
  items: IOptionAccordion[];
  show?: boolean;
  onChange: (itemsSelected: IOptionAccordion[]) => void;
  selected: IOptionAccordion[];
  loading?: boolean;
}

// TO DO: why two Accordion components?
const Accordion2 = memo(
  ({ items, title, show = false, onChange, selected, loading = false }: IAccordion) => {
    // Indicator of display items
    const [showItems, setShowItems] = useState(show);
    const { t } = useTranslation();

    /**
     * @desc Alternate of display
     */
    const handleToggle = useCallback(() => {
      setShowItems(!showItems);
    }, [showItems]);

    /**
     * @desc Handler of selection
     */
    const handleSelection = useCallback(
      (item: IOptionAccordion) => {
        let result = selected;

        if (!Array.isArray(result)) {
          onChange([item]);
          return;
        }

        const exist = selected.find((option) => option.id === item.id);

        if (exist) result = result.filter((option) => option.id !== item.id);
        else result.push(item);

        onChange(result);
      },
      [onChange, selected]
    );

    /**
     * @desc Clear the selection
     */
    const handleClearSelection = useCallback(
      (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onChange([]);
      },
      [onChange]
    );

    /**
     * @desc Mapper of filters
     */
    const itemMapper = useCallback(
      (item: IOptionAccordion, key: number) => {
        const checked =
          Array.isArray(selected) && selected?.find((option) => option.id === item.id)
            ? true
            : false;
        return (
          <AccordionItem key={key} checked={checked} item={item} onSelected={handleSelection} />
        );
      },
      [handleSelection, selected]
    );

    return (
      <div className="flex flex-col">
        {/* Header */}
        <button
          className="mb-4 flex flex-row justify-between border-b-[1px] border-b-gray-200 pb-2"
          onClick={handleToggle}
        >
          <Text color="primary">{title}</Text>

          <div className="flex flex-row items-center gap-2">
            {Array.isArray(selected) && selected.length > 0 && (
              <button
                className="flex cursor-pointer flex-row items-center gap-2 rounded bg-blue-100 p-1 px-2"
                onClick={handleClearSelection}
              >
                <Text color="light-black" variant="text-sm">
                  {selected.length} {t('filters')}
                </Text>
                <IconSvg svgComponent={<CrossSimpleIcon />} width="12px" />
              </button>
            )}

            <IconSvg svgComponent={<ChevronDownIcon />} />
          </div>
        </button>

        {/* Content */}
        <div className={`flex flex-col ${showItems ? '' : 'hidden'}`}>
          {!loading ? items.map(itemMapper) : <AccordionSkeleton />}
        </div>
      </div>
    );
  }
);

export default Accordion2;
