import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';

import { atomModalMappedFields } from '@/modules/ContactsModule/atoms/Step4';
import { atomFiles } from '@/modules/ContactsModule/atoms/Steps';

type MappedFieldProps = {
  listMappedField?: Array<string>;
};

export const MappedFieldModal = ({ listMappedField }: MappedFieldProps) => {
  const { t } = useTranslation();
  const [files] = useAtom(atomFiles);
  const fields = listMappedField ?? files?.columnsImports?.columnsImportsNames;
  const [isOpenModalMappedFields, setIsOpenModalMappedFields] = useAtom(atomModalMappedFields);

  if (!fields) return null;

  return (
    <Modal
      externalClose={true}
      open={isOpenModalMappedFields}
      onClose={() => setIsOpenModalMappedFields(false)}
    >
      <div className="my-1.5 rounded-md">
        <Divide>
          <Flex column withGap gapSize="small" itemAlignment="stretch">
            <Text variant="headline">{t('IMPORT_MAIN.mappedField')}</Text>
            <Divide>
              <Flex
                column
                withGap
                className="visible-scrollbar h-fit max-h-[400px] overflow-auto"
                gapSize="small"
                itemAlignment="stretch"
              >
                {fields?.map((fieldElement) => {
                  return (
                    <div key={fieldElement} className="py-4 capitalize">
                      <Text>{fieldElement}</Text>
                    </div>
                  );
                })}
              </Flex>
            </Divide>
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};
