import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CardSection, Flex } from '@/lib/v2/components';

import {
  atomDeliveryType,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { WhatsAppScheduling } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling';
import { useDeliveryWhatsApp } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling/hooks/useDeliveryWhatsApp';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import { EStepHeaderAction } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const WhatsAppSchedulingMain = () => {
  const { actionId } = useParams();

  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);
  const setDeliveryTypeSelected = useSetAtom(atomDeliveryType);
  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);

  const [immediateDelivery, scheduledDelivery] = useDeliveryWhatsApp();

  const deliveryOptionsWhatsApp = useDeliveryWhatsApp();
  const { getWhatsAppInfo, loading } = useActionWhatsAppData(Number(actionId));

  useEffect(() => void getWhatsAppInfo(), [getWhatsAppInfo]);

  useEffect(() => {
    if (currentStep !== EStepHeaderAction.MIDDLE) {
      setCurrentStep(EStepHeaderAction.MIDDLE);
    }
  }, [currentStep, setCurrentStep]);

  // cspell:disable
  useEffect(() => {
    if (actionInfoWhatsApp) {
      setDeliveryTypeSelected(
        actionInfoWhatsApp?.inmediato ? immediateDelivery.id : scheduledDelivery.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionInfoWhatsApp]);
  // cspell:enable

  return (
    <div className="relative z-[0] bg-white">
      <CardSection noPadding>
        <Flex column>
          <WhatsAppScheduling
            deliveryOptions={deliveryOptionsWhatsApp}
            loading={loading && !(Object.keys(actionInfoWhatsApp).length > 0)}
          />
        </Flex>
      </CardSection>
    </div>
  );
};

export default WhatsAppSchedulingMain;
