import { IconProps } from './iconPropTypes';

export const ActivityCleanFilterIcon = ({ color = '#FFF' }: IconProps) => {
  return (
    <svg
      height="16.094"
      id="filter-off-svgrepo-com"
      viewBox="0 0 15.895 16.094"
      width="15.895"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.055,11.8,10.3,5.095H21.327a.654.654,0,0,1,.745.646v.6a.981.981,0,0,1-.3.7Z"
        data-name="Path 3448"
        fill={`${color}`}
        id="Path_3448"
        transform="translate(-6.177 -3.356)"
      />
      <path
        d="M17.647,16.2,3.043,1.6,2.3,2.3,3.341,3.339h-.6A.654.654,0,0,0,2,3.984v.6a.981.981,0,0,0,.3.7l5.663,5.712V16l3.974,1.689V11.932l5.017,5.017Z"
        data-name="Path 3449"
        fill={`${color}`}
        id="Path_3449"
        transform="translate(-2 -1.6)"
      />
    </svg>
  );
};
