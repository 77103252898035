import { memo } from 'react';

import { AddCirclePlusIcon } from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Text } from '@/lib/v2/components/Text';

interface CreateItemProps {
  title: string;
  onItemCreated?: (value: string) => void;
  value: string;
  disabled?: boolean;
}

const CreateSelectableItem = ({ title, onItemCreated, value, disabled }: CreateItemProps) => {
  const addItem = (value: string) => {
    Promise.resolve(onItemCreated?.(value))
      .then(() => {
        return;
      })
      .catch(console.error);
  };

  return (
    <button
      className="flex items-center gap-1 pt-2"
      disabled={disabled}
      onClick={() => addItem(value)}
    >
      <IconSvg className="!h-6 !w-6" svgComponent={<AddCirclePlusIcon />} />
      <Text fontWeight="bold" variant="text">
        {`${title} "${value}"`}
      </Text>
    </button>
  );
};

export default memo(CreateSelectableItem);
