import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { Modal } from '@/lib/components';
import { closeModal } from '@/lib/components/Modal/Modal';
import { Heading } from '@/lib/components/Text/Heading';
import { Button } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

interface IDeleteModalProps {
  // TODO: this should be onActionDelete function, and not a SetStateAction
  setConfirmDelete: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  JSXElementDescription?: JSX.Element;
  /**
   * Passing this prop avoids modifying the DOM :D
   * Please USE ME
   */
  v2Modal?: {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
  };
  metadata?: {
    entity?: string;
  };
}

export const DeleteModal = ({
  setConfirmDelete,
  title,
  description,
  JSXElementDescription,
  v2Modal,
  metadata,
}: IDeleteModalProps) => {
  return (
    <Modal isFloat id={`delete-${metadata?.entity ?? ''}`} show={v2Modal?.showModal}>
      <div className="p-6">
        <Modal.Title onAction={() => (v2Modal ? v2Modal.setShowModal(false) : closeModal())}>
          <Heading>
            <span className="text-[#01263F]">{title}</span>
          </Heading>
        </Modal.Title>
        <Modal.Body>
          <div className="w-[35rem] pb-[5%] pt-[2%]">
            {JSXElementDescription === undefined ? description : JSXElementDescription}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Flex withGap alignment="start" gapSize="medium">
            <Button
              destructive
              id={`btn-delete-${metadata?.entity ?? ''}`}
              onClick={() => {
                setConfirmDelete(true);
                v2Modal ? v2Modal.setShowModal(false) : closeModal();
              }}
            >
              {t('delete')}
            </Button>
          </Flex>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
