export const AutomationIcon = () => {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_130_1958)">
        <path
          d="M14.3197 8.32603C16.0136 8.32603 17.7076 8.33724 19.4015 8.32603C19.8759 8.32603 20.0114 8.48292 20.0001 8.93116C19.9662 10.0069 19.9775 11.0827 20.0001 12.1473C20.0001 12.5059 19.9097 12.674 19.5145 12.674C16.0136 12.674 12.5015 12.674 9.00064 12.674C8.63926 12.674 8.52633 12.5395 8.53762 12.1921C8.56021 11.0715 8.56021 9.9509 8.53762 8.8303C8.53762 8.4493 8.67314 8.32603 9.04581 8.32603C10.8075 8.33724 12.5692 8.32603 14.331 8.32603H14.3197Z"
          fill="#004DBC"
        />
        <path
          d="M4.50599 0C5.82728 0 7.15986 0.011206 8.48115 0C8.8877 0 9.02321 0.134472 9.01192 0.526681C8.98933 1.63607 9.00063 2.74546 9.01192 3.84365C9.01192 4.21345 8.8877 4.34792 8.50373 4.34792C5.83857 4.33671 3.17341 4.33671 0.496957 4.34792C0.112993 4.34792 -0.0112305 4.22465 -0.0112305 3.85486C0.0226487 2.74546 0.0226487 1.63607 6.25858e-05 0.526681C6.25858e-05 0.134472 0.112993 0 0.530836 0C1.85212 0.022412 3.1847 0 4.50599 0Z"
          fill="#004DBC"
        />
        <path
          d="M4.46067 20.9888C3.15067 20.9888 1.84068 20.9776 0.530686 20.9888C0.124136 20.9888 -0.0113809 20.8655 -8.78001e-05 20.4621C0.0224983 19.3527 0.0224983 18.2433 -8.78001e-05 17.1451C-8.78001e-05 16.7753 0.10155 16.6409 0.496807 16.6409C3.16197 16.6521 5.82713 16.6521 8.50358 16.6409C8.87625 16.6409 9.02306 16.7529 9.01177 17.1339C9.00048 18.2209 8.98918 19.3191 9.01177 20.4061C9.01177 20.8431 8.88755 21 8.43582 20.9888C7.11454 20.9664 5.78196 20.9888 4.46067 20.9888Z"
          fill="#004DBC"
        />
        <path
          d="M17.6962 20.9888C17.0863 20.9888 16.4652 20.9888 15.8554 20.9888C15.5392 20.9888 15.4263 20.8767 15.4263 20.563C15.4263 19.3975 15.4263 18.2209 15.4263 17.0555C15.4263 16.7417 15.5618 16.6409 15.8667 16.6409C17.0976 16.6409 18.3173 16.6521 19.5482 16.6409C19.887 16.6409 20.0112 16.7529 20 17.0891C19.9887 18.2433 19.9887 19.3975 20 20.5405C20 20.8767 19.8644 20.9888 19.5369 20.9776C18.9271 20.9664 18.306 20.9776 17.6962 20.9776V20.9888Z"
          fill="#004DBC"
        />
        <path
          d="M14.4778 5.28922C14.7714 5.28922 14.9973 5.28922 15.2231 5.28922C15.5619 5.28922 15.6297 5.45731 15.4829 5.72625C15.1667 6.28655 14.8392 6.83564 14.5117 7.39594C14.3197 7.70971 14.139 7.65368 13.9809 7.37353C13.6647 6.83564 13.3485 6.29775 13.0323 5.74866C12.8629 5.45731 12.9419 5.28922 13.3033 5.28922C13.5292 5.28922 13.7438 5.28922 13.9922 5.28922C13.9922 4.68409 14.0374 4.1238 13.9809 3.5747C13.9131 2.93596 13.3711 2.51013 12.6935 2.49893C11.903 2.48772 11.1125 2.49893 10.322 2.48772C10.1751 2.48772 10.0283 2.33084 9.89282 2.24119C10.0283 2.17396 10.1639 2.03948 10.3107 2.03948C11.1012 2.01707 11.8917 2.01707 12.6822 2.03948C13.6986 2.05069 14.4552 2.79028 14.4891 3.79882C14.5004 4.28068 14.4891 4.75133 14.4891 5.30042L14.4778 5.28922Z"
          fill="#004DBC"
        />
        <path
          d="M3.77183 13.5256C4.04287 13.5256 4.26873 13.5256 4.4833 13.5256C4.8108 13.5256 4.93502 13.6713 4.75433 13.9851C4.43812 14.5229 4.12192 15.0608 3.80571 15.6099C3.61373 15.9461 3.42175 15.9013 3.24106 15.5875C2.94744 15.072 2.64253 14.5678 2.34891 14.0523C2.12305 13.6601 2.19081 13.548 2.65382 13.5256C2.8458 13.5256 3.03779 13.5256 3.29753 13.5256C3.29753 13.055 3.29753 12.6067 3.29753 12.1697C3.29753 11.0379 4.03157 10.2983 5.16088 10.2759C5.95139 10.2647 6.74191 10.2647 7.53242 10.2759C7.65665 10.2759 7.79216 10.3991 7.91639 10.4664C7.78087 10.5448 7.65665 10.6905 7.52113 10.7017C6.77579 10.7241 6.03045 10.7017 5.2964 10.7129C4.29132 10.7129 3.77183 11.2284 3.77183 12.2257C3.77183 12.6403 3.77183 13.055 3.77183 13.5256Z"
          fill="#004DBC"
        />
        <path
          d="M12.3433 18.5907C12.3433 18.3218 12.3433 18.1201 12.3433 17.9071C12.3433 17.5598 12.4788 17.4253 12.8176 17.6158C13.3597 17.9296 13.9017 18.2433 14.4551 18.5571C14.7487 18.7252 14.76 18.9157 14.4551 19.0838C13.9017 19.4087 13.3371 19.7225 12.7837 20.0475C12.4901 20.2156 12.3659 20.0811 12.3433 19.7898C12.3433 19.5656 12.3433 19.3415 12.3433 19.0614C11.6657 19.0614 11.022 19.0838 10.3896 19.0502C10.2315 19.0502 10.0734 18.8821 9.91528 18.8036C10.0734 18.7364 10.2315 18.6131 10.3896 18.6019C11.0107 18.5795 11.6431 18.6019 12.3433 18.6019V18.5907Z"
          fill="#004DBC"
        />
      </g>
      <defs>
        <clipPath id="clip0_130_1958">
          <rect fill="white" height="21" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
