import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import {
  ContactField,
  ISegmentFilter,
  ISegmentFilterActivity,
  ISegmentFilterDate,
  ISegmentFilterID,
  ISegmentFilterString,
} from '@/modules/ContactsModule/types/Segments';

const GeneralFilters = ({
  condition,
  operator,
  values,
  lastDays,
}: {
  condition: ISegmentFilter['condition'];
  operator: 'or' | 'and';
  values: string[];
  lastDays?: number;
}) => {
  const { t } = useTranslation();

  // If the condition is between => "and" is the obvious operator
  operator = condition === 'bt' ? 'and' : operator;
  return (
    <>
      <em>{t(`SEGMENTS_CONDITIONS.CONDITIONS.${condition}`)} </em>
      {values.map((value, i) => {
        return (
          // the values in blue and italic medium concatenated with operators in black and simple
          <em key={value}>
            <i className="font-medium text-emblue-primary">{value} </i>
            {i !== values.length - 1 && <em>{t(`SEGMENTS_CONDITIONS.${operator}`)} </em>}
          </em>
        );
      })}
      {lastDays && (
        <>
          <em>{t('SEGMENT_FILTERS.registeredTheLastDays')} </em>
          <i className="font-medium text-emblue-primary">{lastDays} </i>
          <i>{t('SEGMENTS_CONDITIONS.dayOrDays')}</i>
        </>
      )}
    </>
  );
};

const ActivityTypeFilterRow = ({ f }: { f: ISegmentFilterActivity }) => {
  const { t } = useTranslation();
  return (
    <>
      <em>
        {f.condition === 'with'
          ? t('SEGMENTS_CONDITIONS.CONDITIONS.withRegistered')
          : t('SEGMENTS_CONDITIONS.CONDITIONS.notRegistered')}{' '}
      </em>
      <i className="font-medium text-emblue-primary">{f.lastDays} </i>
      <i>{t('SEGMENTS_CONDITIONS.dayOrDays')}</i>
    </>
  );
};

const DateTypeFilterRow = ({ f }: { f: ISegmentFilterDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <GeneralFilters
        condition={f.condition}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        operator={'or'}
        values={f.values.map((date) => date.split('T')[0])}
      />
      {f.condition === 'last-days' && <i>{t('SEGMENTS_CONDITIONS.dayOrDays')}</i>}
    </>
  );
};

const StringTypeFilterRow = ({ f }: { f: ISegmentFilterString }) => {
  let auxCondition: ISegmentFilterID['condition'] | undefined = undefined;
  if (f.field.metadata?.optionType) {
    auxCondition = f.condition === 'contains' ? 'equal' : 'distinct';
  }
  return (
    <>
      <GeneralFilters
        condition={auxCondition ?? f.condition}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        operator={f.operator}
        values={f.values}
      />
    </>
  );
};

export const SegmentFilterRows = ({ filters }: { filters: ISegmentFilter[] }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full ">
      {filters.map((filter) => {
        const isUserCF = (filter.field as ContactField).metadata?.defaultField === false;
        return (
          <div key={filter.field.name} className="border-b-[1px] p-4 text-14">
            <Text alignment="left">
              <b className="text-emblue-primary">
                {isUserCF
                  ? filter.field.name ?? (filter.field as ContactField).metadata.internalColumn
                  : t(`SEGMENTS_CONDITIONS.FILTERS.${filter.field.name}`)}{' '}
              </b>
              {
                // eslint-disable-next-line no-nested-ternary
                filter.dataType === 'ACTIVITY' ? (
                  <ActivityTypeFilterRow f={filter} />
                ) : // eslint-disable-next-line no-nested-ternary
                filter.dataType === 'DATE' ? (
                  <DateTypeFilterRow f={filter} />
                ) : filter.dataType === 'STRING' ? (
                  <StringTypeFilterRow f={filter} />
                ) : (
                  <GeneralFilters
                    condition={filter.condition}
                    lastDays={filter.dataType === 'ID' ? filter.daysOld : undefined}
                    operator={filter.dataType === 'ID' ? filter.operator : 'or'}
                    values={filter.values}
                  />
                )
              }
            </Text>
          </div>
        );
      })}
    </div>
  );
};
