export const ArrowDownIcon = () => {
  return (
    <svg height="8.44" viewBox="0 0 14.761 8.44" width="14.761" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.383,5.9,12.965.31a1.05,1.05,0,0,1,1.49,0,1.064,1.064,0,0,1,0,1.494L8.131,8.133a1.053,1.053,0,0,1-1.455.031L.308,1.808A1.055,1.055,0,0,1,1.8.314Z"
        data-name="Icon ionic-ios-arrow-down"
        fill="#004dbc"
        id="Icon_ionic-ios-arrow-down"
      />
    </svg>
  );
};
