import { IFlowData } from '@/src/modules/AutomationModule/types/flows';

export enum EMaxMinLength {
  MIN_LENGTH = 2,
  NAME_MAX = 60,
  OBJECTIVE_MAX = 150,
  OTHER_STRATEGY_MAX = 30,
  DESCRIPTION_EVENT_MAX = 250,
}

export interface ICheckNameResource {
  id?: string;
  name?: string;
}

export enum ModalType {
  EDIT = 'edit',
}

export enum ScreenType {
  MAIN = 'main',
}

export interface ITableCampaignActions {
  action: IFlowData;
}

export interface IOrderByCell {
  id: string;
  isAsc: boolean | undefined;
}
