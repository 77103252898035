import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { useFiltersRecurrent } from '@/modules/CampaignsModule/hooks/useFiltersCampaignRecurrent';

export const useResetButton = (resetSearch: () => void, resetDateRange: () => void) => {
  const { resetFilters } = useFiltersRecurrent();
  const filtersRecurrent = useAtomValue(atomFiltersRecurrent);

  const id = 'clear-filters-campaigns-action';
  const disabled = Object.keys(filtersRecurrent ?? {}).length === 0;

  const handleClickReset = useCallback(() => {
    resetSearch();
    resetDateRange();
    resetFilters();
  }, [resetDateRange, resetFilters, resetSearch]);

  return {
    id,
    disabled,
    handleClickReset,
  };
};
