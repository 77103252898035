export const MoveIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3339 15.0312H0.93945C0.420609 15.0312 0 14.6106 0 14.0917V0.939449C0 0.420609 0.42058 2.93578e-05 0.93945 0H6.57615V3.7578C6.57615 4.27567 6.99517 4.69566 7.5156 4.69725H11.2734V6.57615H3.72844V10.3339H11.2734V14.0917C11.2734 14.6096 10.8544 15.0296 10.3339 15.0312ZM12.1835 7.5156V6.08796C12.1835 5.43918 12.9861 5.13521 13.4159 5.6212L15.5264 8.0079C15.763 8.27546 15.762 8.67775 15.524 8.94409L13.4135 11.306C12.9824 11.7885 12.1835 11.4836 12.1835 10.8366V9.3945H4.66789V7.5156H12.1835ZM11.244 3.7578H7.48624V0H7.56683C7.81601 0 8.05493 0.0989945 8.23113 0.275171L10.9689 3.0129C11.1451 3.18911 11.244 3.42805 11.244 3.67721V3.7578Z"
        fill="#0056B8"
      />
    </svg>
  );
};
