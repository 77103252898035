import { IContactCustom } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';

/* cspell:disable */
export const contactCustomMock: IContactCustom = {
  contactCustomFields: [
    {
      id: 4685,
      number: 1,
      name: 'nombre',
      value: 'Alejandra Veronica',
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4686,
      number: 2,
      name: 'apellido',
      value: 'Vazquez',
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4687,
      number: 3,
      name: 'id_contacto',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4688,
      number: 4,
      name: 'sexo',
      value: null,
      fieldType: 1,
      dataType: 5,
      optionType: 2,
      defaultValues: 'M;F',
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4689,
      number: 5,
      name: 'telefono_1',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4690,
      number: 6,
      name: 'telefono_2',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4691,
      number: 7,
      name: 'web_url',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4692,
      number: 8,
      name: 'linkedin',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4693,
      number: 9,
      name: 'facebook',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4694,
      number: 10,
      name: 'twitter',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4695,
      number: 11,
      name: 'direccion',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4696,
      number: 12,
      name: 'ciudad',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4697,
      number: 13,
      name: 'pais',
      value: null,
      fieldType: 1,
      dataType: 6,
      optionType: 2,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4698,
      number: 14,
      name: 'cumpleanios',
      value: '1977-12-06',
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4699,
      number: 15,
      name: 'empresa',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4700,
      number: 16,
      name: 'cargo',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 29007,
      number: 17,
      name: 'email_secundario',
      value: null,
      fieldType: 1,
      dataType: 4,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 37298,
      number: 18,
      name: 'id_trigger',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 141314,
      number: 19,
      name: 'recencia',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 141315,
      number: 20,
      name: 'frecuencia',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 141316,
      number: 21,
      name: 'monto',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: true,
      readOnly: false,
    },
    {
      id: 4898,
      number: 26,
      name: 'Codigo Cliente',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4899,
      number: 27,
      name: 'Codigo Provincia',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'J;B;Q;A;C;D;E;F;G;H;K;L;M;N;P;R;S;T;U;V;W;X;Y;Z',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4900,
      number: 28,
      name: 'Actualizacion',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'S;N',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 123109,
      number: 29,
      name: 'Show',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4902,
      number: 30,
      name: 'Codigo de Grupo',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'I;N;A;E;S;V;B',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4903,
      number: 31,
      name: 'Puntos',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4904,
      number: 32,
      name: 'Empleado',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'Si;No',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4905,
      number: 33,
      name: 'DNI',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4906,
      number: 34,
      name: 'Codigo Postal',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4907,
      number: 35,
      name: 'fech_ulti_movimiento',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 4908,
      number: 36,
      name: 'Fecha',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 139632,
      number: 37,
      name: 'COMENZAR',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 194660,
      number: 38,
      name: 'Localidad',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 5473,
      number: 39,
      name: 'Razon social',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 194661,
      number: 40,
      name: 'Provincia',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 209281,
      number: 41,
      name: 'Zona BS AS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'AMBA;CABA;ZONA NORTE;ZONA SUR;ZONA OESTE;INTERIOR BS AS',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 225429,
      number: 42,
      name: 'Nombre EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 225856,
      number: 43,
      name: 'Promedio canjes EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 225857,
      number: 44,
      name: 'Esperado EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 235160,
      number: 45,
      name: 'Anterior canjes EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 10547,
      number: 46,
      name: 'Fecha de Nacimiento',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 10548,
      number: 47,
      name: 'Edad',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 10549,
      number: 48,
      name: 'Fecha Primer Movimie',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 334558,
      number: 49,
      name: 'Categoria EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'ALTO;MEDIO;BAJO;SA NULA;NULO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 398568,
      number: 50,
      name: 'Puntos disponibles',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 507131,
      number: 51,
      name: 'nombre_completo',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 507132,
      number: 52,
      name: 'Nombre Completo',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11635,
      number: 53,
      name: 'Dir EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11636,
      number: 54,
      name: 'Loc EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11684,
      number: 55,
      name: 'Tarjeta',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11685,
      number: 56,
      name: 'Super',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 539017,
      number: 57,
      name: 'fecha_vencimiento',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11778,
      number: 58,
      name: 'BNA',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'SI;NO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 539054,
      number: 59,
      name: 'fecha_expiracion',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 11836,
      number: 60,
      name: 'GNC',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'Si;No',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 12031,
      number: 61,
      name: 'Recibir mails',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 2,
      defaultValues: 'SI;NO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 13788,
      number: 62,
      name: 'Abandonador',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'SI;NO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 539427,
      number: 63,
      name: 'km_expiracion',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 18921,
      number: 64,
      name: 'ACTIVO',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'SI;NO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 21145,
      number: 65,
      name: 'Prefuga',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 3,
      defaultValues: 'SI;NO',
      emblueField: false,
      readOnly: false,
    },
    {
      id: 539055,
      number: 66,
      name: 'fecha_ult_movimiento',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 890333,
      number: 67,
      name: 'Infinia',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 890334,
      number: 68,
      name: 'Apies',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1162048,
      number: 69,
      name: 'Codigo de beneficio',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1207933,
      number: 70,
      name: 'NIVEL',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1191736,
      number: 71,
      name: 'contrasena',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1207942,
      number: 72,
      name: 'DESCRIPCION',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1221942,
      number: 73,
      name: 'URLUNICA',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 34679,
      number: 74,
      name: 'Provincia EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 35696,
      number: 75,
      name: 'Codigo_Cliente',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1244648,
      number: 76,
      name: 'NOSENCONTRAMOS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 45652,
      number: 77,
      name: 'Nro EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1244649,
      number: 78,
      name: 'LLENASTE',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 80979,
      number: 79,
      name: 'Codigo EESS',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 102329,
      number: 80,
      name: 'Fecha_Vencen_Puntos',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 112245,
      number: 81,
      name: 'Fecha Nacimiento',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 114984,
      number: 82,
      name: 'Cantidad_KM_Vencen',
      value: null,
      fieldType: 1,
      dataType: 2,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 118357,
      number: 83,
      name: 'Comercios',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 118112,
      number: 84,
      name: 'Fecha Carga Ultima',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1244650,
      number: 85,
      name: 'AHORRASTE',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1244651,
      number: 86,
      name: 'ACUMULASTE',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1315826,
      number: 87,
      name: 'CARGA',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1315861,
      number: 88,
      name: 'AHORRO',
      value: null,
      fieldType: 1,
      dataType: 3,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
    {
      id: 1338229,
      number: 89,
      name: 'ultimo_carrito',
      value: null,
      fieldType: 1,
      dataType: 1,
      optionType: 1,
      defaultValues: null,
      emblueField: false,
      readOnly: false,
    },
  ],
};
