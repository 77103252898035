import { useAtom } from 'jotai';
import { lazy, memo, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Step, StepProps } from '@/lib/components/Step/Step';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';
import { Option } from '@/lib/v2/components/Select/Select';

import { ITableMappingField } from '@/src/ContactsModule/components/ContactsMappingField/ContactsMappingField';
import { SelectorEnum } from '@/src/ContactsModule/components/ContactsMappingField/selectorsValues';
import { IDetailViewList } from '@/src/ContactsModule/components/ContactsViewInfo/ContactsDetailTable';
import { convertDataToViewInfo } from '@/src/ContactsModule/utils/ConvertDataToViewInfo';
import { useResetImport } from '@/src/modules/ContactsModule/hooks/useResetImport';
import {
  FinishError,
  FinishSuccess,
} from '@/src/modules/ContactsModule/screens/ImportMain/components/Finish';
import Skeleton from '@/src/modules/ContactsModule/screens/ImportMain/components/Skeleton';
import { ImportSteps } from '@/src/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';
import { StateOption } from '@/src/modules/ContactsModule/screens/ImportMain/StateOptionsEnum.enum';
import { ColumnProcessProps } from '@/src/presentation/types/interfaces/IImport.interface';

import {
  atomFileDataQuantity,
  atomListCustomField,
  atomMappingList,
} from '@/modules/ContactsModule/atoms/Step2';
import { atomSelectedGroups } from '@/modules/ContactsModule/atoms/Step4';
import { atomDetailList, atomFileName, atomFiles } from '@/modules/ContactsModule/atoms/Steps';

const Step1 = lazy(
  () => import('@/src/modules/ContactsModule/screens/ImportMain/components/Step1')
);
const Step2 = lazy(
  () => import('@/src/modules/ContactsModule/screens/ImportMain/components/Step2')
);
const Step3 = lazy(
  () => import('@/src/modules/ContactsModule/screens/ImportMain/components/Step3')
);
const Step4 = lazy(
  () => import('@/src/modules/ContactsModule/screens/ImportMain/components/Step4')
);

export interface INoImport {
  id: StateOption.noImport;
  name: string;
  value: string;
}

const Steps = () => {
  const navigate = useNavigate();
  const { resetImport } = useResetImport();
  const [files, setFiles] = useAtom(atomFiles);
  const [mappingList, setMappingList] = useAtom(atomMappingList);
  const [, setFileDataQuantity] = useAtom(atomFileDataQuantity);
  const [listCustomField, setListCustomField] = useAtom(atomListCustomField);

  const [fileName] = useAtom(atomFileName);
  const [details, setDetailList] = useAtom(atomDetailList);
  const [selectedGroups] = useAtom(atomSelectedGroups);

  const { t } = useTranslation();

  const noImport: INoImport = useMemo(
    () => ({
      id: StateOption.noImport,
      name: t('IMPORT_MAIN.noImport'),
      value: t('IMPORT_MAIN.noImport'),
    }),
    [t]
  );

  const steps = useMemo(
    () =>
      [
        { id: '01', name: t('IMPORT_MAIN.import') },
        { id: '02', name: t('IMPORT_MAIN.mappingField') },
        { id: '03', name: t('IMPORT_MAIN.dataCleaning') },
        { id: '04', name: t('IMPORT_MAIN.confirmation') },
      ] as Array<StepProps>,
    [t]
  );

  const cancelProcess = useCallback(() => {
    resetImport();
    navigate({ pathname: `/v2/${RouteEnum.ContactsImport}` });
  }, [navigate, resetImport]);

  const convertToColumnsProcess = useCallback(() => {
    const aux = mappingList.mappingList
      .map((element: { mapping: ITableMappingField }, index: number) => {
        let format;
        const dataType = element.mapping.formatColumn?.dataType;
        if (dataType === SelectorEnum.country || dataType === SelectorEnum.date) {
          format = element.mapping.formatColumn?.selected?.id;
        } else if (dataType === SelectorEnum.sex) {
          format = element.mapping.formatColumn?.selected?.value;
        }

        return {
          format: format ? format.toString() : null,
          idSpecialField: null,
          idCustomField: element.mapping.mappingField.id,
          columnNumber: (index + 1).toString(),
        } as ColumnProcessProps;
      })
      .filter((column: ColumnProcessProps) => {
        return column.idCustomField !== StateOption.noImport;
      });
    return aux;
  }, [mappingList?.mappingList]);

  useEffect(() => {
    if (files?.step !== ImportSteps.mappingField) return;

    if (mappingList && mappingList.mappingList.length !== 0) {
      let noImportColumn = 0;
      const excludedColumn: number[] = [];
      let i = 0;

      for (const element of mappingList.mappingList) {
        if (Number(element.mapping.mappingField.id) === StateOption.noImport) {
          noImportColumn++;
          excludedColumn.push(i);
        }
        i++;
      }
      const importColumnCount = mappingList.mappingList.length - noImportColumn;
      setFiles((prevFiles) => ({
        ...prevFiles,
        mappingList: mappingList,
        columnsImports: {
          ...files?.columnsImports,
          columnsImportsNames: files?.columnsImports?.columnsImportsNames ?? [],
          importColumn: importColumnCount,
          noImportColumn: noImportColumn,
          totalColumns: mappingList.mappingList.length,
          excludedColumn: excludedColumn,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.step, mappingList]);

  useEffect(() => {
    const { step, columnsImports, file } = files ?? {};
    const fileNameSelected = file?.name ?? fileName;

    if (step === ImportSteps.dataCleaning && columnsImports && fileNameSelected && !details) {
      const detail: IDetailViewList[] = convertDataToViewInfo(
        fileNameSelected,
        columnsImports.importColumn
      );
      if (detail) {
        setDetailList(detail);
      }
    } else if (step === ImportSteps.tagsAndGroup && columnsImports) {
      const selectedGroupsNames = selectedGroups
        .filter((g) => g?.id !== 0 && g?.name)
        .map((g) => g.name as string);

      const listGroupsSelected = Array.isArray(selectedGroups)
        ? (selectedGroups
            .filter((item) => item?.id)
            .map((sg: Option) => {
              return sg.id;
            }) as number[])
        : [];
      setFiles((prevFiles) => ({
        ...prevFiles,
        groups: { selectedGroupsId: listGroupsSelected, selectedGroupsNames: selectedGroupsNames },
      }));

      const auxSelectedGroupsName = selectedGroupsNames?.slice(0, 5) || [''];
      const detail: IDetailViewList[] = convertDataToViewInfo(
        fileNameSelected,
        columnsImports.importColumn,
        auxSelectedGroupsName
      );
      if (detail) {
        setDetailList(detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.step, selectedGroups]);

  useEffect(() => {
    if (listCustomField.length > 0) return;

    setMappingList({
      mappingList: files?.mappingList?.mappingList ?? [],
    });
    setFileDataQuantity({
      quantityColumns: files?.quantityColumns as number,
      quantityContacts: files?.quantityContacts as number,
    });
    setListCustomField(files?.selectListFields ?? []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialOutsidePage = localStorage.getItem('@import/outside') === 'true';

    initialOutsidePage && localStorage.setItem('@import/outside', 'false');

    return () => {
      const endOutsidePage = localStorage.getItem('@import/outside') === 'true';
      !endOutsidePage && localStorage.setItem('@import/outside', 'true');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Skeleton />}>
      <Step steps={steps} />

      {files.step === ImportSteps.import ? <Step1 noImport={noImport} /> : null}

      {files.step === ImportSteps.mappingField ? (
        <Step2 convertToColumnsProcess={convertToColumnsProcess} noImport={noImport} />
      ) : null}

      {files.step === ImportSteps.dataCleaning ? <Step3 cancelProcess={cancelProcess} /> : null}

      {files.step === ImportSteps.tagsAndGroup ? <Step4 cancelProcess={cancelProcess} /> : null}

      <FinishSuccess />
      <FinishError />
    </Suspense>
  );
};

export default memo(Steps);
