export const UserIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20">
      <path
        d="M10,10.9c-2.4,0-4.4-2-4.4-4.4S7.5,2,10,2s4.4,2,4.4,4.4S12.4,10.9,10,10.9z M10,3.5c-1.6,0-2.9,1.3-2.9,2.9S8.4,9.4,10,9.4
	c1.6,0,2.9-1.3,2.9-2.9S11.6,3.5,10,3.5z"
        fill="#004DBC"
      />
      <path
        d="M15,16.8c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.1-0.4,0-0.7l0,0l0,0c0,0-0.5-2.2-2.1-3.6c-1.1,0.6-2.2,0.9-3.4,0.9
	c-1.2,0-2.4-0.3-3.4-0.9c-1.5,1.3-2,3.4-2,3.6l0,0l0,0.1c-0.1,0.2,0,0.6,0,0.7c0,0,0.2,0.1,0.5,0.1H15 M15,18.3H5
	c-2.6,0-1.9-2.6-1.9-2.6s0.6-3.2,3.1-4.9c0.2-0.1,0.4-0.1,0.6,0c1.8,1.3,4.3,1.3,6.3,0c0.2-0.1,0.4-0.1,0.6,0
	c2.5,1.7,3.1,4.9,3.1,4.9S17.6,18.3,15,18.3z"
        fill="#004DBC"
      />
    </svg>
  );
};
