export const ReportIconTable = () => {
  return (
    <svg height="18" viewBox="0 0 13.5 18" width="13.5">
      <path
        d="M6.25,2A2.25,2.25,0,0,0,4,4.25v13.5A2.25,2.25,0,0,0,6.25,20h9a2.25,2.25,0,0,0,2.25-2.25V8.091a2.25,2.25,0,0,0-.659-1.59L13,2.659A2.25,2.25,0,0,0,11.409,2ZM8.5,13.25a1.125,1.125,0,1,0-2.25,0v3.375a1.125,1.125,0,1,0,2.25,0Zm2.25-3.375A1.125,1.125,0,0,1,11.875,11v5.625a1.125,1.125,0,0,1-2.25,0V11A1.125,1.125,0,0,1,10.75,9.875Zm4.5-1.125a1.125,1.125,0,1,0-2.25,0v7.875a1.125,1.125,0,1,0,2.25,0Z"
        data-name="Path 3115"
        fill="#fff"
        id="Path_3115"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};
