import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { JWT } from '@/src/infrastructure/enum/DeniedPermissions';
import { UserDataDTO } from '@/src/presentation/types/DTOs/UserDataDTO';
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const initUserData = (user: any): Partial<UserData> => {
  const userData = {
    stepperComplete: false,
    name:
      user.given_name !== undefined || user.family_name !== undefined
        ? `${user.given_name}${' '}${user.family_name}`
        : '',
    plan: '',
    environment: window.location.host.slice(0, window.location.host.indexOf('.')),
    email: user.email !== undefined ? user.email : '',
    companyId: user.accountId !== undefined ? user.accountId : '',
    contractId: user.contractId !== undefined ? user.contractId : '',
    agencyName: user.agencyName !== undefined ? user.agencyName : '',
    language: 'en',
    companyName: user.accountName !== undefined ? user.accountName : '',
    isSSO: user.isSSO !== undefined && user.isSSO === 'True',
    type: user?.type,
    v: user?.v,
    app: user?.app,
  };

  if (user.deniedPermissions !== undefined && user.deniedPermissions !== '') {
    return {
      ...userData,
      deniedPermissions:
        user.deniedPermissions !== undefined
          ? {
              deniedReportsPermissions: user.deniedPermissions.includes(
                JWT.deniedReportsPermissions
              ),
              deniedContactsPermissions: user.deniedPermissions.includes(
                JWT.deniedContactsPermissions
              ),
              deniedCampaignsPermissions: user.deniedPermissions.includes(
                JWT.deniedCampaignsPermissions
              ),
              deniedActionsPermissions: user.deniedPermissions.includes(
                JWT.deniedActionsPermissions
              ),
            }
          : undefined,
    };
  }
  return userData;
};

export const mapExtendedUserDataToUser = (
  userdata: UserDataDTO,
  plan: string,
  user: Partial<UserData>
): void => {
  user.name = userdata.nombre_usuario;
  user.email = userdata.email_usuario;
  user.contractId = userdata.contrato;
  user.companyId = userdata.id_empresa.toString();
  user.agencyName = userdata.nombre_agencia;
  user.language = userdata.cultura;
  user.plan = plan;
  user.companyName = userdata.nombre_empresa;
};
