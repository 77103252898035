import { datadogRum } from '@datadog/browser-rum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { TextSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Avatar, Flex, Text } from '@/lib/v2/components';
import { EmptySearchIcon, EmptyWidgetsIcon } from '@/lib/v2/icons/general';
import { AverageIcon, ContactsIcon, DateIcon } from '@/lib/v2/icons/outline';
import { MiniThumbIcon, ProcessIcon } from '@/lib/v2/icons/solid';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { useUserData } from '@/src/application/hooks/useUserData';
import { NpsBadge } from '@/src/ContactsModule/components/Badges/NpsBadge';
import { NpsStatus } from '@/src/ContactsModule/utils/NpsStatus';
import { useDate } from '@/src/infrastructure/services/useDate';
import { TableNpsContact } from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { customRounding } from '@/src/utils/CustomRounding';

import {
  useHappinessMutationContext,
  useHappinessStateContext,
} from '@/modules/ContactsModule/contexts/HappinessContext';

export function HappinessTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dateFormatter } = useDate();
  const [userData] = useUserData();

  const classNamePagination = 'px-5 lg:px-5 2xl:px-5';

  const {
    npsTotalContactsCount,
    npsContacts,
    totalPages,
    isLoadingContacts,
    gotoPageIndex,
    isAllResultsSelected,
    tableOrderByList,
  } = useHappinessStateContext();
  const {
    changeTableOrderBy,
    changeTablePage,
    setSelectedContacts,
    setTableOrderByList,
    resetContactList,
  } = useHappinessMutationContext();

  const goToContactView = (emailId: number) => navigate(`../${emailId}`);

  const contactsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactsIcon />
        <span className="text-emblue-primary"> {t('HAPPINESS_DASHBOARD.contactsColumn')} </span>
        <SortTableArrow
          elementName={'contactName'}
          getTableOrderByList={changeTableOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'contact',
    id: 'contact',
    Cell: ({ cell: { value } }: { cell: { value: TableNpsContact } }) => (
      <Flex withGap alignment="start">
        <Avatar email={value.email} lastName={value.lastName} name={value.name} />
        <Flex column noGrow>
          <span
            className="self-start truncate font-medium text-[#364365] md:max-w-[200px] 2xl:max-w-[300px]"
            title={`${value.name} ${value.lastName}`}
          >
            {' '}
            {value.name} {value.lastName}{' '}
          </span>
          <span
            className="self-start truncate text-[#364365] md:max-w-[200px] 2xl:max-w-[300px]"
            title={value.email}
          >
            {' '}
            {value.email}{' '}
          </span>
        </Flex>
      </Flex>
    ),
  };

  const happinessColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <MiniThumbIcon />
        <span className="text-emblue-primary"> {t('HAPPINESS_DASHBOARD.happinessColumn')} </span>
      </Flex>
    ),
    accessor: 'contact.happiness',
    id: 'happiness',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex alignment="start">
        <NpsBadge npsStatus={NpsStatus(value)} />
      </Flex>
    ),
  };

  const lastProcessDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <DateIcon />
        <span className="text-emblue-primary">
          {' '}
          {t('HAPPINESS_DASHBOARD.lastProcessDateColumn')}{' '}
        </span>
      </Flex>
    ),
    accessor: 'contact.lastProcessDate',
    id: 'lastProcessDate',
    Cell: ({ cell: { value } }: { cell: { value: Date } }) => (
      <Flex alignment="start">
        <>{(value && dateFormatter(value)) || <TextSkeleton size="sm" />}</>
      </Flex>
    ),
  };

  const processesColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ProcessIcon />
        <span className="text-emblue-primary"> {t('HAPPINESS_DASHBOARD.processesColumn')} </span>
      </Flex>
    ),
    accessor: 'contact.processes',
    id: 'processes',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start">
        <span>{value}</span>
      </Flex>
    ),
  };

  const averageColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <AverageIcon />
        <span className="text-emblue-primary"> {t('HAPPINESS_DASHBOARD.averageColumn')} </span>
      </Flex>
    ),
    accessor: 'contact.average',
    id: 'average',
    width: '18%',
    Cell: ({ cell: { value } }: { cell: { value: number } }) => (
      <Flex withGap alignment="start" gapSize="small">
        <span>{customRounding(value)}</span>
      </Flex>
    ),
  };

  const contactsTableColumns: Column<object>[] = [
    contactsColumn,
    happinessColumn,
    lastProcessDateColumn,
    processesColumn,
    averageColumn,
  ].filter((x) => x) as Column<object>[];

  const EmptyScreenTableDescription = useMemo(() => {
    const handleOnClickEmptyTableCTA = () => {
      datadogRum.addAction('click on Happiness Empty table CTA', {
        email: userData.email,
      });
      navigate('/v2/onsite');
    };
    return (
      <>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('HAPPINESS_DASHBOARD.emptyScreenDescription1')}
        </Text>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('HAPPINESS_DASHBOARD.emptyScreenDescription2')}
        </Text>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('HAPPINESS_DASHBOARD.emptyScreenDescription3')}
          <button
            className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
            data-testid="nps-screen-empty-table-create-link"
            role="link"
            onClick={handleOnClickEmptyTableCTA}
          >
            {t('HAPPINESS_DASHBOARD.emptyStateCTA')}
          </button>
        </Text>
      </>
    );
  }, [navigate, t]);

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        classNamePagination={classNamePagination}
        columns={contactsTableColumns}
        data={npsContacts?.list}
        emptyScreen={{
          totalCount: (npsTotalContactsCount ? npsTotalContactsCount : 0) || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.contacts')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptySearchIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={resetContactList}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={EmptyScreenTableDescription}
              image={<EmptyWidgetsIcon />}
              title={t('HAPPINESS_DASHBOARD.emptyStateTitle')}
              onClickAction={resetContactList}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoadingContacts}
        noNegativeMargin={true}
        paginationWithPadding={false}
        setSelectedRows={setSelectedContacts}
        toggleAllRowsSelectedValue={isAllResultsSelected}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => goToContactView(value.contact.emailId)}
      />
    </div>
  );
}
