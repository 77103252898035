import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Button, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyContactsIcon, EmptyTagsIcon } from '@/lib/v2/icons/general';
import { RecalculateIcon } from '@/lib/v2/icons/outline/RecalculateIcon';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { ActivityIcon } from '@/src/ContactsModule/Icons/ActivityIcon';
import { BulletListIcon } from '@/src/ContactsModule/Icons/BulletListIcon';
import { CategoryIcon } from '@/src/ContactsModule/Icons/CategoryIcon';
import { ContactsIcon } from '@/src/ContactsModule/Icons/ContactsIcon';
import { ReloadIcon } from '@/src/ContactsModule/Icons/ReloadIcon';
import { ITableTagProps, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

export function TagsTable({
  tagList,
  changeTableOrder,
  totalTagCount,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
  setSelectedTags,
  tableOrderByList,
  setTableOrderByList,
  recalculateTag,
  isRecalculateTag,
}: ITableTagProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tagId = useRef(0);

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <BulletListIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('TAGS_TABLE.name')} </span>
        <SortTableArrow
          elementName={'Name'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'tag',
    Cell: ({ cell: { value } }: { cell: { value: ITag } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start font-medium text-[#364365]"> {value.name} </span>
        </Flex>
      </Flex>
    ),
  };
  const categoryColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <CategoryIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('TAGS_TABLE.category')} </span>
      </Flex>
    ),
    accessor: 'tag',
    id: 'category',
    Cell: ({ cell: { value } }: { cell: { value: ITag } }) => (
      <Flex withGap alignment="start">
        {value.category ? (
          <span className="self-start font-medium text-[#364365]"> {value.category} </span>
        ) : (
          <span className="opacity-50"> {t('TAGS_TABLE.noCategory')} </span>
        )}
      </Flex>
    ),
  };
  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactsIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('TAGS_TABLE.contact')} </span>
        <SortTableArrow
          elementName={'Contacts'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'tag',
    id: 'contacts',
    Cell: ({ cell: { value } }: { cell: { value: ITag } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {value.contacts ? (
          <span className="self-start font-medium text-[#364365]">
            {' '}
            {quantifier(value.contacts)}{' '}
          </span>
        ) : (
          <span className="opacity-50"> {t('TAGS_TABLE.noContact')} </span>
        )}
      </Flex>
    ),
  };
  const activityColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ActivityIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('TAGS_TABLE.activity')} </span>
      </Flex>
    ),
    accessor: 'tag',
    id: 'activity',
    Cell: ({ cell: { value } }: { cell: { value: ITag } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {value.activity ? (
          <span className="self-start font-medium text-[#364365]">
            {' '}
            {quantifier(value.activity)}{' '}
          </span>
        ) : (
          <span className={'opacity-50'}> {t('TAGS_TABLE.noActivity')} </span>
        )}
      </Flex>
    ),
  };
  const recalculateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <RecalculateIcon tableSize />
        <span className="text-[#004DBC]"> {t('SEGMENTS_MAIN.recalculateSegment')} </span>
      </Flex>
    ),
    accessor: 'tag',
    id: 'tagId',
    width: '25%',
    Cell: ({ cell: { value } }: { cell: { value: ITag } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {
          <Button
            outline
            secondary
            disabled={
              // eslint-disable-next-line no-nested-ternary
              isRecalculateTag
                ? value.id !== tagId.current
                  ? true
                  : false
                : value.updated
                ? !JSON.parse(value.updated)
                : false
            }
            iconLeft={<ReloadIcon />}
            isLoading={isRecalculateTag && value.id === tagId.current}
            size={'medium'}
            onClick={(e) => {
              tagId.current = value.id;
              recalculateTag(e, value.id);
            }}
          >
            <div className="ml-3">
              {isRecalculateTag && value.id === tagId.current
                ? t('TAGS_MAIN.recalculatingTag')
                : t('TAGS_MAIN.recalculateTag')}
            </div>
          </Button>
        }
      </Flex>
    ),
  };
  const tagsTableColumns: Column<object>[] = [
    nameColumn,
    categoryColumn,
    contactColumn,
    activityColumn,
    recalculateColumn,
  ].filter((x) => x) as Column<object>[];

  const goToTagDetail = (tag: ITag) => {
    tag.contacts !== 0 && navigate(`../tags/${tag.id}`);
  };

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        paginationWithPadding
        columns={tagsTableColumns}
        data={tagList}
        emptyScreen={{
          totalCount: totalTagCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.tags')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={
                <>
                  <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                    {t('EMPTY_SCREEN_TABLE.emptyTagsDescription1')}
                  </Text>
                  <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
                    {t('EMPTY_SCREEN_TABLE.emptyTagsDescription2')}
                    <button
                      className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
                      data-testid="nps-screen-empty-table-create-link"
                      role="link"
                      onClick={callToAction && callToAction[1]}
                    >
                      {t('EMPTY_SCREEN_TABLE.emptyTagsAction')}
                    </button>
                  </Text>
                </>
              }
              image={<EmptyTagsIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyTagsTitle')}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        setSelectedRows={setSelectedTags}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => {
          goToTagDetail(value.tag);
        }}
      />
    </div>
  );
}
