import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import {
  EActionTypes,
  ICampaignActionsListPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ECampaignsTypeOptions, EStates } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { getParamsActions, getParamsPager } from '@/src/modules/CampaignsModule/utils/getParams';

import { useCampaignRecurrent } from './useCampaignRecurrent';
import { useRecurrentActions } from './useRecurrentActions';

import {
  atomCurrentCampaignsRecurrentID,
  atomDetailCampaignRecurrent,
  atomLoadingProcess,
  atomRefetchAllData,
  atomRefetchDetailData,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomOrderByAction,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

export const useRecurrentActionsData = (type?: EActionTypes) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);

  const { getList, actionsList, getActionMaster } = useRecurrentActions();
  const { getDetailRecurrentMessage } = useCampaignRecurrent();

  const [refetchAllData, setRefetchAllData] = useAtom(atomRefetchAllData);
  const [filters, setFilters] = useAtom(atomFiltersAction);
  const [pager, setPager] = useAtom(atomPagerAction);
  const [refetchDetailData, setRefetchDetailData] = useAtom(atomRefetchDetailData);

  const orderBy = useAtomValue(atomOrderByAction);
  const historySearchPagerAction = useAtomValue(atomHistorySearchPagerActions);
  const isRecurrentMessage = location.pathname.includes('recurrent');

  const detailRecurrent = useAtomValue(atomDetailCampaignRecurrent);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsActionsList = useAtomValue(atomTotalsProcess);

  const setCurrentRecurrentID = useSetAtom(atomCurrentCampaignsRecurrentID);

  const filtersCount = useMemo(() => {
    return Object.keys(filters ?? {}).length;
  }, [filters]);

  const getListActions = useCallback(
    (payload: ICampaignActionsListPayload) => void getList(payload),
    [getList]
  );

  const getListActionMaster = useCallback(
    (payload: ICampaignActionsListPayload) => void getActionMaster(payload),
    [getActionMaster]
  );

  useEffect(() => {
    setCurrentRecurrentID(Number(id));
  }, [id, setCurrentRecurrentID]);

  useEffect(() => {
    void getDetailRecurrentMessage({ campaignID: Number(id) });
    setRefetchDetailData(RESET);
  }, [getDetailRecurrentMessage, id, setRefetchDetailData, isRecurrentMessage]);

  useEffect(() => {
    if (location?.state?.from === 'action-detail') {
      setPager(historySearchPagerAction?.pager ?? defaultPager);
      setFilters(historySearchPagerAction?.filters ?? {});
      navigate('.', { state: null });
      return;
    }

    if (!filters || !pager) {
      const filtersURL = getParamsActions();
      setFilters(filtersURL);

      const pagerURL = getParamsPager();
      setPager(pagerURL);
    }
  }, [location?.state?.from, setFilters, setPager]);

  const getParamsSearchAll = useCallback((): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentPage = pager?.page ?? defaultPager.page;
    const currentLimit = pager?.limit ?? defaultPager.limit;

    if (pager?.page) {
      currentPage !== defaultPager.page || queryParams.has('page')
        ? queryParams.set('page', currentPage.toString())
        : queryParams.delete('page');
    }

    if (pager?.limit) {
      currentLimit !== defaultPager.limit || queryParams.has('limit')
        ? queryParams.set('limit', currentLimit.toString())
        : queryParams.delete('limit');
    }

    const currentFilterKeys = new Set(queryParams.keys());

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (key === 'startDate' || key === 'endDate') {
          const timeStampCast = new Date(value as Date).getTime() / 1000;
          queryParams.set(key, timeStampCast.toString());
        } else if (Array.isArray(value)) {
          queryParams.set(key, value.join(','));
        } else if (value != null) {
          queryParams.set(key, String(value));
        }
        currentFilterKeys.delete(key);
      });
    }

    if (filters !== undefined && filters !== null) {
      currentFilterKeys.forEach((key) => {
        if (key !== 'page' && key !== 'limit') {
          queryParams.delete(key);
        }
      });
    }

    return `${location.pathname}?${queryParams.toString()}`;
  }, [filters, location.pathname, pager?.limit, pager?.page]);

  useEffect(() => {
    const URL = getParamsSearchAll();
    navigate(URL, { replace: true });
  }, [pager, filters, getParamsSearchAll, navigate]);

  const orderByCampaignTypeId = useMemo(() => {
    if (initialRender.current) {
      initialRender.current = false;

      if (type === EActionTypes.DRAFT) return { sortField: 'modificationDate', sortOrder: 'desc' };
      if (type === EActionTypes.FINISHED) return { sortField: 'exeDate', sortOrder: 'desc' };
    }

    if (detailRecurrent?.campaignTypeId === ECampaignsTypeOptions.AUTOMATIC && !type) {
      return { sortField: 'channel', sortOrder: 'desc' };
    }

    return { ...orderBy };
  }, [type, detailRecurrent?.campaignTypeId, orderBy]);

  useEffect(() => {
    if (detailRecurrent.campaignId) {
      const payload: ICampaignActionsListPayload = {
        campaignId: detailRecurrent.campaignId,
      };

      getListActionMaster(payload);
    }
  }, [detailRecurrent.campaignId, getListActionMaster]);

  useEffect(() => {
    const isValidCampaign = detailRecurrent?.campaignTypeId && detailRecurrent?.campaignId;
    const hasFiltersAndPager = filters && pager;

    if (!hasFiltersAndPager) return;

    const payload: ICampaignActionsListPayload = {
      ...filters,
      page: pager.page,
      limit: pager.limit,
      channel: [1],
      ...orderByCampaignTypeId,
    };

    if (type) {
      payload.type = type;
      getListActions(payload);
      return;
    }

    if (isValidCampaign && !type) {
      payload.campaignId = detailRecurrent.campaignId;
      getListActions(payload);
      return;
    }
  }, [
    orderBy,
    filters,
    pager?.page,
    pager?.limit,
    detailRecurrent?.campaignId,
    detailRecurrent?.campaignTypeId,
    getListActions,
  ]);

  useEffect(() => {
    if (!refetchAllData) return;

    const payload: ICampaignActionsListPayload = {
      page: pager?.page,
      limit: pager?.limit,
      channel: [1],
      ...filters,
      ...orderByCampaignTypeId,
    };

    if (detailRecurrent?.campaignId) {
      payload.campaignId = detailRecurrent.campaignId;
    }
    if (type) {
      payload.type = type;
    }

    void getListActions(payload);
    setRefetchAllData(RESET);
  }, [refetchAllData, detailRecurrent?.campaignId, setRefetchAllData]);

  const isActionActive = detailRecurrent?.state === EStates.ACTIVE;

  return {
    actionsList,
    loadingProcess,
    totalsActionsList,
    filtersCount,
    isActionActive,
    pager,
  };
};
