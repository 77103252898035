import { format } from '@formkit/tempo';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

const DaysOfWeek = () => {
  const { i18n } = useTranslation();
  const days = Array.from({ length: 7 }, (_, i) => i);
  const dayNames = days.map((day) => format(new Date(2024, 0, day), 'ddd', i18n.language));

  return (
    <>
      {dayNames.map((dayName, index) => (
        <Text
          key={index}
          alignment="center"
          className="min-w-[138px] py-1.5 uppercase"
          fontWeight="bold"
          variant="text"
        >
          {dayName}
        </Text>
      ))}
    </>
  );
};

export default DaysOfWeek;
