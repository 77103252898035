export const EmblueAcademy = () => {
  return (
    <svg height="25" id="Group" viewBox="0 0 159 25" width="159" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.877,15.267H29.562a2.186,2.186,0,0,0,.865,1.405,2.845,2.845,0,0,0,1.74.518,2.943,2.943,0,0,0,2.187-.873l1.009,1.161a3.384,3.384,0,0,1-1.369.979,5.438,5.438,0,0,1-4.2-.2,3.81,3.81,0,0,1-1.562-1.468,4.437,4.437,0,0,1-.014-4.245A3.8,3.8,0,0,1,29.7,11.08a4.347,4.347,0,0,1,2.138-.528,4.193,4.193,0,0,1,2.1.518,3.675,3.675,0,0,1,1.446,1.458A4.431,4.431,0,0,1,35.9,14.7c0,.13-.01.317-.029.556ZM30.307,12.6a2.218,2.218,0,0,0-.759,1.42h4.565a2.22,2.22,0,0,0-.74-1.415,2.456,2.456,0,0,0-3.066-.01Z"
        data-name="Path 6914"
        fill="#0151d3"
        id="Path_6914"
      />
      <path
        d="M50.283,11.44a3.567,3.567,0,0,1,.889,2.638v4.6H49.288V14.318a2.348,2.348,0,0,0-.466-1.588,1.691,1.691,0,0,0-1.341-.532,1.966,1.966,0,0,0-1.523.623,2.561,2.561,0,0,0-.572,1.784v4.077H43.5V14.318a2.347,2.347,0,0,0-.466-1.588A1.691,1.691,0,0,0,41.7,12.2a1.962,1.962,0,0,0-1.528.619,2.571,2.571,0,0,0-.567,1.789v4.077H37.717V10.648h1.792V11.67a2.97,2.97,0,0,1,1.129-.83,3.829,3.829,0,0,1,1.509-.288,3.653,3.653,0,0,1,1.6.341,2.594,2.594,0,0,1,1.105,1,3.33,3.33,0,0,1,1.283-.983,4.185,4.185,0,0,1,1.735-.355,3.274,3.274,0,0,1,2.412.883Z"
        data-name="Path 6915"
        fill="#0151d3"
        id="Path_6915"
      />
      <path
        d="M62.628,14.16a2.664,2.664,0,0,1,.514,1.664,2.436,2.436,0,0,1-1.057,2.115,5.328,5.328,0,0,1-3.075.743H53.767V8.154h4.945a4.876,4.876,0,0,1,2.878.729,2.342,2.342,0,0,1,1.009,2.01,2.651,2.651,0,0,1-.36,1.386,2.447,2.447,0,0,1-.995.931,2.824,2.824,0,0,1,1.389.955Zm-6.9-4.475v2.9H58.5a2.844,2.844,0,0,0,1.576-.369,1.213,1.213,0,0,0,.548-1.074,1.238,1.238,0,0,0-.548-1.089A2.834,2.834,0,0,0,58.5,9.684Zm5.44,5.943q0-1.518-2.278-1.521H55.722v3.036h3.167q2.278,0,2.278-1.521Z"
        data-name="Path 6916"
        fill="#0151d3"
        id="Path_6916"
      />
      <path
        d="M65.055,7.521h1.884V18.683H65.055Z"
        data-name="Path 6917"
        fill="#0151d3"
        id="Path_6917"
      />
      <path
        d="M77.2,10.648v8.034H75.405V17.661a3.031,3.031,0,0,1-1.129.835,3.628,3.628,0,0,1-1.461.293,3.479,3.479,0,0,1-2.537-.9,3.52,3.52,0,0,1-.927-2.657v-4.59h1.884v4.331a2.325,2.325,0,0,0,.49,1.616,1.8,1.8,0,0,0,1.394.532,2.112,2.112,0,0,0,1.605-.624,2.5,2.5,0,0,0,.6-1.8V10.639H77.2Z"
        data-name="Path 6918"
        fill="#0151d3"
        id="Path_6918"
      />
      <path
        d="M87.208,15.267H80.894a2.186,2.186,0,0,0,.865,1.405,2.845,2.845,0,0,0,1.74.518,2.943,2.943,0,0,0,2.186-.873l1.009,1.161a3.385,3.385,0,0,1-1.37.979,5.438,5.438,0,0,1-4.2-.2,3.81,3.81,0,0,1-1.562-1.468,4.437,4.437,0,0,1-.014-4.245,3.8,3.8,0,0,1,1.485-1.468,4.346,4.346,0,0,1,2.138-.528,4.193,4.193,0,0,1,2.1.518,3.675,3.675,0,0,1,1.446,1.458,4.431,4.431,0,0,1,.519,2.173c0,.13-.01.317-.029.556ZM81.638,12.6a2.217,2.217,0,0,0-.759,1.42h4.565A2.22,2.22,0,0,0,84.7,12.6a2.456,2.456,0,0,0-3.066-.01Z"
        data-name="Path 6919"
        fill="#0151d3"
        id="Path_6919"
      />
      <path
        d="M94.612,11.406a3.274,3.274,0,0,1,.942,2.566v4.71H93.776V17.7a2.223,2.223,0,0,1-.985.806,3.836,3.836,0,0,1-1.543.278,3.778,3.778,0,0,1-1.581-.307,2.424,2.424,0,0,1-1.048-.859,2.184,2.184,0,0,1-.37-1.242,2.12,2.12,0,0,1,.807-1.736,4.007,4.007,0,0,1,2.537-.652h2.081v-.12a1.651,1.651,0,0,0-.5-1.3,2.179,2.179,0,0,0-1.5-.451,4.392,4.392,0,0,0-1.336.211,3.3,3.3,0,0,0-1.11.585l-.74-1.367a4.6,4.6,0,0,1,1.523-.739,6.72,6.72,0,0,1,1.884-.254,3.931,3.931,0,0,1,2.72.849Zm-1.72,5.684a1.751,1.751,0,0,0,.774-.926v-.931H91.719c-1.086,0-1.629.355-1.629,1.07a.952.952,0,0,0,.408.811,1.857,1.857,0,0,0,1.129.3,2.41,2.41,0,0,0,1.259-.321Z"
        data-name="Path 6920"
        fill="#f87c04"
        id="Path_6920"
      />
      <path
        d="M99.49,18.26a3.844,3.844,0,0,1-1.552-1.468,4.119,4.119,0,0,1-.557-2.13,4.073,4.073,0,0,1,.557-2.12,3.86,3.86,0,0,1,1.542-1.458,4.722,4.722,0,0,1,2.254-.528,4.332,4.332,0,0,1,2.085.48,3.135,3.135,0,0,1,1.35,1.386l-1.446.844a2.321,2.321,0,0,0-.865-.835,2.355,2.355,0,0,0-1.139-.278,2.393,2.393,0,0,0-1.749.686,2.453,2.453,0,0,0-.692,1.828,2.469,2.469,0,0,0,.687,1.828,2.382,2.382,0,0,0,1.754.686,2.356,2.356,0,0,0,1.139-.278,2.32,2.32,0,0,0,.865-.835l1.446.844a3.235,3.235,0,0,1-1.364,1.391,4.251,4.251,0,0,1-2.071.489,4.724,4.724,0,0,1-2.245-.528Z"
        data-name="Path 6921"
        fill="#f87c04"
        id="Path_6921"
      />
      <path
        d="M112.59,11.406a3.276,3.276,0,0,1,.942,2.566v4.71h-1.778V17.7a2.23,2.23,0,0,1-.985.806,3.838,3.838,0,0,1-1.543.278,3.778,3.778,0,0,1-1.581-.307,2.42,2.42,0,0,1-1.047-.859,2.177,2.177,0,0,1-.37-1.242,2.12,2.12,0,0,1,.807-1.736,4.007,4.007,0,0,1,2.537-.652h2.081v-.12a1.649,1.649,0,0,0-.5-1.3,2.178,2.178,0,0,0-1.5-.451,4.39,4.39,0,0,0-1.336.211,3.3,3.3,0,0,0-1.11.585l-.74-1.367a4.6,4.6,0,0,1,1.523-.739,6.724,6.724,0,0,1,1.884-.254,3.932,3.932,0,0,1,2.72.849Zm-1.72,5.684a1.75,1.75,0,0,0,.773-.926v-.931H109.7c-1.086,0-1.629.355-1.629,1.07a.954.954,0,0,0,.408.811,1.861,1.861,0,0,0,1.13.3,2.41,2.41,0,0,0,1.259-.321Z"
        data-name="Path 6922"
        fill="#f87c04"
        id="Path_6922"
      />
      <path
        d="M123.9,7.521V18.683H122.1V17.647a2.967,2.967,0,0,1-1.154.859,3.867,3.867,0,0,1-1.513.288,4.238,4.238,0,0,1-2.086-.513,3.688,3.688,0,0,1-1.456-1.453,4.351,4.351,0,0,1-.529-2.158,4.289,4.289,0,0,1,.529-2.149,3.663,3.663,0,0,1,1.456-1.444,4.252,4.252,0,0,1,2.086-.513,3.787,3.787,0,0,1,1.46.273,2.978,2.978,0,0,1,1.13.811V7.526H123.9Zm-3.027,9.349a2.237,2.237,0,0,0,.86-.883,2.923,2.923,0,0,0,0-2.648,2.237,2.237,0,0,0-.86-.883,2.58,2.58,0,0,0-2.441,0,2.232,2.232,0,0,0-.86.883,2.923,2.923,0,0,0,0,2.648,2.262,2.262,0,0,0,.86.883,2.58,2.58,0,0,0,2.441,0Z"
        data-name="Path 6923"
        fill="#f87c04"
        id="Path_6923"
      />
      <path
        d="M133.906,15.267h-6.314a2.185,2.185,0,0,0,.865,1.405,2.843,2.843,0,0,0,1.739.518,2.944,2.944,0,0,0,2.187-.873l1.009,1.161a3.387,3.387,0,0,1-1.37.979,5.436,5.436,0,0,1-4.2-.2,3.809,3.809,0,0,1-1.562-1.468,4.438,4.438,0,0,1-.015-4.245,3.8,3.8,0,0,1,1.485-1.468,4.35,4.35,0,0,1,2.139-.528,4.193,4.193,0,0,1,2.1.518,3.679,3.679,0,0,1,1.446,1.458,4.431,4.431,0,0,1,.519,2.173c0,.13-.01.317-.029.556ZM128.337,12.6a2.216,2.216,0,0,0-.76,1.42h4.565a2.213,2.213,0,0,0-.74-1.415,2.455,2.455,0,0,0-3.065-.01Z"
        data-name="Path 6924"
        fill="#f87c04"
        id="Path_6924"
      />
      <path
        d="M148.313,11.44a3.568,3.568,0,0,1,.889,2.638v4.6h-1.884V14.318a2.344,2.344,0,0,0-.466-1.588,1.69,1.69,0,0,0-1.34-.532,1.968,1.968,0,0,0-1.524.623,2.564,2.564,0,0,0-.572,1.784v4.077h-1.883V14.318a2.349,2.349,0,0,0-.466-1.588,1.693,1.693,0,0,0-1.341-.532,1.962,1.962,0,0,0-1.528.619,2.57,2.57,0,0,0-.567,1.789v4.077h-1.884V10.648h1.792V11.67a2.973,2.973,0,0,1,1.13-.83,3.826,3.826,0,0,1,1.509-.288,3.643,3.643,0,0,1,1.605.341,2.635,2.635,0,0,1,1.11,1,3.324,3.324,0,0,1,1.283-.983,4.181,4.181,0,0,1,1.734-.355,3.276,3.276,0,0,1,2.413.883Z"
        data-name="Path 6925"
        fill="#f87c04"
        id="Path_6925"
      />
      <path
        d="M159,10.648l-3.767,8.682a4.013,4.013,0,0,1-1.283,1.842,3.082,3.082,0,0,1-1.822.532,3.783,3.783,0,0,1-1.191-.2,2.639,2.639,0,0,1-.966-.542l.754-1.386a2.057,2.057,0,0,0,.639.408,1.947,1.947,0,0,0,.745.149,1.3,1.3,0,0,0,.822-.254,2.3,2.3,0,0,0,.6-.859l.134-.317-3.513-8.063h1.961l2.533,5.943,2.546-5.943h1.807Z"
        data-name="Path 6926"
        fill="#f87c04"
        id="Path_6926"
      />
      <path
        d="M19.962,5.271V2.533A2.533,2.533,0,0,0,17.424,0H3.489A3.482,3.482,0,0,0,0,3.5V21.484A3.518,3.518,0,0,0,3.522,25H19.366a3.518,3.518,0,0,0,3.522-3.516V8.188a2.918,2.918,0,0,0-2.922-2.916ZM1.826,2.8H16.367a.293.293,0,1,0,0-.585H2.215a1.838,1.838,0,0,1,1.3-.528H17.42a.846.846,0,0,1,.846.844V5.267H3.518a1.822,1.822,0,0,1-1.153-.413h14a.293.293,0,1,0,0-.585H1.889a1.848,1.848,0,0,1-.154-.422H16.372a.293.293,0,1,0,0-.585H1.706a1.8,1.8,0,0,1,.12-.48ZM20.653,21.647a1.675,1.675,0,0,1-1.677,1.664H3.522a1.829,1.829,0,0,1-1.831-1.827V6.456a3.527,3.527,0,0,0,1.826.508H6.555L19.063,6.95A1.676,1.676,0,0,1,20.74,8.634Z"
        data-name="Path 6927"
        fill="#0151d3"
        id="Path_6927"
      />
      <path
        d="M7.486,20.083a.692.692,0,0,0,.711,0l6.824-3.933L8.2,12.217a.694.694,0,0,0-.351-.1.8.8,0,0,0-.36.1.7.7,0,0,0-.356.614V19.46a.693.693,0,0,0,.356.614Z"
        data-name="Path 6928"
        fill="#f87c04"
        id="Path_6928"
      />
      <path
        d="M8.121,10.222a.82.82,0,0,1,.428.12L16.8,15.1,8.549,19.858a.867.867,0,0,1-.428.12.884.884,0,0,1-.437-.12.848.848,0,0,1-.432-.743V11.09a.835.835,0,0,1,.432-.743.9.9,0,0,1,.437-.12m0-1.29a2.158,2.158,0,0,0-1.081.293,2.131,2.131,0,0,0-1.076,1.866v8.025a2.162,2.162,0,0,0,2.158,2.158A2.113,2.113,0,0,0,9.2,20.985l8.256-4.758a1.287,1.287,0,0,0,0-2.235L9.2,9.233a2.148,2.148,0,0,0-1.076-.293Z"
        data-name="Path 6929"
        fill="#0151d3"
        id="Path_6929"
      />
    </svg>
  );
};
