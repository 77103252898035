export const SimpleEmailIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.767 5.62476L18.4362 16.5266C17.0818 17.8308 14.9181 17.8308 13.5637 16.5266L2.23295 5.62476C1.55575 6.27686 1.14282 7.1965 1.14282 8.21646V23.8001C1.14282 25.8066 2.79453 27.4285 4.82614 27.4285H27.1738C29.2054 27.4285 30.8571 25.8066 30.8571 23.8001V8.21646C30.8571 7.21322 30.4442 6.29358 29.767 5.62476Z"
        fill="#0056B8"
      />
      <path
        d="M18.4362 14.0354L28.1482 4.7053C27.8344 4.6217 27.5206 4.57153 27.1902 4.57153H4.80956C4.47921 4.57153 4.14887 4.6217 3.85156 4.7053L13.5636 14.0354C14.918 15.3396 17.0818 15.3396 18.4362 14.0354Z"
        fill="#0056B8"
      />
    </svg>
  );
};
