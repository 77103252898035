import classNames from 'classnames';
import { forwardRef, isValidElement, ReactElement, Ref } from 'react';

import TabPane, { TabPaneProps } from './TabPane';

export type TabContentProps = {
  /** Receives components of type TabPane v2 */
  children: ReactElement<TabPaneProps> | ReactElement<TabPaneProps>[];
  className?: string;
};

const TabContent = forwardRef(
  ({ children, className }: TabContentProps, ref: Ref<HTMLDivElement>) => {
    if (!Array.isArray(children)) {
      children = [children];
    }

    for (const child of children) {
      if (!isValidElement(child) || child.type !== TabPane) {
        throw new Error(
          'the "children" prop in TabContent only accepts components of type TabPane v2'
        );
      }
    }

    const classes = classNames('relative w-full', className);

    return (
      <div ref={ref} className={classes}>
        {children}
      </div>
    );
  }
);

export default TabContent;
