export const StructureIcon = () => {
  return (
    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.61765 18.294C1.27653 18.294 1 18.0175 1 17.6764L1 5.32346C1 4.98234 1.27653 4.70581 1.61765 4.70581C1.95876 4.70581 2.23529 4.98234 2.23529 5.32346L2.23529 17.6764C2.23529 18.0175 1.95876 18.294 1.61765 18.294Z"
        fill="#364365"
        fillRule="evenodd"
        stroke="#374365"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        clipRule="evenodd"
        d="M4.70595 1.61765C4.70595 1.27653 4.98248 0.999999 5.3236 0.999999L17.6765 1C18.0177 1 18.2942 1.27653 18.2942 1.61765C18.2942 1.95876 18.0177 2.23529 17.6765 2.23529L5.3236 2.23529C4.98248 2.23529 4.70595 1.95876 4.70595 1.61765Z"
        fill="#364365"
        fillRule="evenodd"
        stroke="#374365"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        clipRule="evenodd"
        d="M21.3823 18.294C21.0412 18.294 20.7646 18.0175 20.7646 17.6764L20.7646 5.32346C20.7646 4.98234 21.0412 4.70581 21.3823 4.70581C21.7234 4.70581 21.9999 4.98234 21.9999 5.32346L21.9999 17.6764C21.9999 18.0175 21.7234 18.294 21.3823 18.294Z"
        fill="#364365"
        fillRule="evenodd"
        stroke="#374365"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        clipRule="evenodd"
        d="M4.70595 21.3823C4.70595 21.0412 4.98248 20.7646 5.3236 20.7646L17.6765 20.7646C18.0177 20.7646 18.2942 21.0412 18.2942 21.3823C18.2942 21.7234 18.0177 21.9999 17.6765 21.9999L5.3236 21.9999C4.98248 21.9999 4.70595 21.7234 4.70595 21.3823Z"
        fill="#364365"
        fillRule="evenodd"
        stroke="#374365"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        clipRule="evenodd"
        d="M6.40581 6.40581V16.594H16.594V6.40581H6.40581ZM5.9411 4.70581C5.25887 4.70581 4.70581 5.25887 4.70581 5.9411V17.0588C4.70581 17.741 5.25887 18.294 5.9411 18.294H17.0588C17.741 18.294 18.294 17.741 18.294 17.0588V5.9411C18.294 5.25887 17.741 4.70581 17.0588 4.70581H5.9411Z"
        fill="#364365"
        fillRule="evenodd"
      />
      <path d="M5.99993 5.99993H16.9999V16.9999H5.99993V5.99993Z" fill="#364365" />
    </svg>
  );
};
