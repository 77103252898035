export const TagAddIconSolid = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7430" id="Group_7430" transform="translate(-0.318 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.318 -0.163)"
          width="20"
        />
        <g data-name="Group 7431" id="Group_7431" transform="translate(-2.682 -5.218)">
          <g data-name="Group 6774" id="Group_6774" transform="translate(4 11.374)">
            <path
              d="M6.786,4.5V9.073M9.073,6.786H4.5"
              data-name="Path 3413"
              fill="none"
              id="Path_3413"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              transform="translate(-4.5 -4.5)"
            />
            <line
              data-name="Line 75"
              fill="none"
              id="Line_75"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeWidth="1.5"
              transform="translate(0.096 7.468)"
              x2="4.544"
            />
          </g>
          <path
            d="M13.321,7.256a.721.721,0,0,1,0,1.019L8.276,13.321a.721.721,0,0,1-1.019,0L2.211,8.276A.719.719,0,0,1,2,7.766v-3.6A2.162,2.162,0,0,1,4.162,2h3.6a.719.719,0,0,1,.51.211ZM4.162,4.883a.721.721,0,1,0-.721-.721A.721.721,0,0,0,4.162,4.883Z"
            data-name="Path 4094"
            fill="#004dbc"
            fillRule="evenodd"
            id="Path_4094"
            transform="translate(8.378 7.055)"
          />
        </g>
      </g>
    </svg>
  );
};
