import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import CatalogTemplateMenuBar from '@/src/modules/CampaignsModule/components/CatalogTemplates';

const CampaignsLayout = () => {
  return (
    <div>
      <CatalogTemplateMenuBar />
      <Outlet />
    </div>
  );
};

export default memo(CampaignsLayout);
