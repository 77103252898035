import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/organisms';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useRecurrentForm } from '@/src/modules/CampaignsModule/components/CampaignsModal/CreateRecurrentAction/hooks/useRecurrentForm';

import {
  atomLoadingDetail,
  atomSelectedRowRecurrentName,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDuplicateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { atomActionDragDrop } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { useDeprecatedBanner } from '@/modules/CampaignsModule/hooks/useDeprecatedBanner';

const TRANSLATE_MODAL = 'RECURRENT_MESSAGE.CREATE_RECURRENT';
const TRANSLATE_MODAL_DUPLICATE = 'RECURRENT_MESSAGE.DUPLICATE_RECURRENT';

interface IDuplicateCampaignRecurrentProps {
  onClose: (value?: boolean) => void;
}

const DuplicateCampaignRecurrent = ({ onClose }: IDuplicateCampaignRecurrentProps) => {
  const { t } = useTranslation();

  const disableLegacyAction = useFeatureFlag('disableLegacyActionDuplication');

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForRow } = useDeprecatedBanner();
  const { VALIDATION_RULES, handleCloseModal, handleValidation, loading, handleDuplicate } =
    useRecurrentForm({ onClose });
  const actionDragDrop = useAtomValue(atomActionDragDrop);
  const recurrentName = useAtomValue(atomSelectedRowRecurrentName);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const toggleDuplicateCampaignModal = useAtomValue(atomToggleDuplicateCampaignModal);
  const disabledSubmitButton = disableLegacyAction && actionDragDrop;

  return (
    <ModalSingleInput
      validateFieldsOnOpen
      alert={
        disabledSubmitButton
          ? {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.disableActions'),
              type: 'warning',
            }
          : !showDeprecatedDragDropForRow
          ? {
              description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
              type: 'info',
            }
          : {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured'),
              type: 'warning',
            }
      }
      disabledSubmitButton={disabledSubmitButton}
      id="duplicate-recurrent-modal"
      isLoading={loadingDetail || loading}
      isOpen={toggleDuplicateCampaignModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(recurrentName)} ${t(`${TRANSLATE_MODAL}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleDuplicate}
      onValidateInputFetch={handleValidation}
    />
  );
};
export default DuplicateCampaignRecurrent;
