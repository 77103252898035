import { useEffect, useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { IReport } from '@/src/presentation/types/interfaces/IReport.interface';
import { Report } from '@/src/presentation/types/models/Report.class';

export const useLastReports = ({
  initialArray,
}: {
  initialArray?: IReport[];
}): [IReport[] | undefined, Error | undefined, boolean] => {
  const [reportsValue, error, reportIsLoading] = useEmblue(ServiceMethods.getLastReports);
  const [reports, setReports] = useState(initialArray);
  useEffect(() => {
    if (reportsValue) {
      setReports(Report.mapReportsDataDTO(reportsValue.aaData as any[]));
    }
  }, [reportsValue]);
  return [reports, error, reportIsLoading];
};
