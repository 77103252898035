import { IItem } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { IItemsEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export const useItemList = ({
  lastDays = 10,
  dataSource,
  page,
  pageSize,
  search,
  orderColumn,
  orderDirection,
}: IItemsEmblueService): [
  (
    | {
        list: { item: IItem }[];
      }
    | undefined
  ),
  Error | undefined,
  boolean | undefined
] => {
  const [itemListValue, error, isLoading] = useEmblue(ServiceMethods.getItems, {
    lastDays,
    dataSource,
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection: orderDirection,
  });

  return [itemListValue, error, isLoading];
};
