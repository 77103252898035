export const ExportUserIcon = () => {
  return (
    <svg
      className="h-5 w-5"
      height="19.836"
      viewBox="0 0 18 19.836"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Grupo 4037" id="Grupo_4037" transform="translate(-393.32 -188.695)">
        <path
          d="M4,17.377v1.115a3.186,3.186,0,0,0,3,3.344H17a3.186,3.186,0,0,0,3-3.344V17.377m-4-4.459-4,4.459m0,0L8,12.918m4,4.459V4"
          data-name="Trazado 3327"
          fill="none"
          id="Trazado_3327"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(390.32 185.695)"
        />
      </g>
    </svg>
  );
};
