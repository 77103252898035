export const BlockedContactIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 1C9.82453 1 7.69773 1.64523 5.88879 2.85392C4.07985 4.06262 2.66999 5.78054 1.83743 7.79052C1.00487 9.80051 0.786895 12.0123 1.21133 14.146C1.63577 16.2798 2.68347 18.2398 4.22184 19.7781C5.76022 21.3165 7.72028 22.3642 9.85407 22.7886C11.9879 23.2131 14.1996 22.9952 16.2096 22.1627C18.2196 21.3301 19.9376 19.9203 21.1463 18.1113C22.355 16.3024 23 14.1756 23 12C22.9762 9.08998 21.8097 6.30596 19.7519 4.24821C17.6942 2.19045 14.9101 1.02381 12.0001 1ZM11.9546 5.40002C12.7076 5.39502 13.4543 5.53498 14.1544 5.81226L5.95129 14.0156C5.6958 13.3094 5.55644 12.5665 5.53893 11.8157C5.53374 10.9719 5.696 10.1354 6.01645 9.35484C6.33689 8.57427 6.80928 7.86513 7.40583 7.26838C8.00238 6.67163 8.7113 6.19917 9.49176 5.87847C10.2722 5.55776 11.1108 5.39513 11.9546 5.40002ZM12.0001 18.279H11.909C11.2052 18.2768 10.507 18.1527 9.8456 17.9123L18.0034 9.75449C18.2437 10.416 18.3679 11.114 18.3702 11.8178C18.3852 12.6627 18.2313 13.5022 17.9176 14.2869C17.6039 15.0717 17.1369 15.7858 16.5436 16.3876C15.9503 16.9894 15.2426 17.4666 14.4624 17.7914C13.6822 18.1162 12.8452 18.282 12.0001 18.279Z"
        fill="#FF715B"
      />
    </svg>
  );
};
