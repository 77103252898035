export const AddCirclePlusIcon = () => {
  return (
    <svg
      className="h-[24px] w-[24px] lg:h-[28px] lg:w-[28px] xl:h-[32px] xl:w-[32px] 2xl:h-[36px] 2xl:w-[36px]"
      fill="#005ADC"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        fillRule="evenodd"
      />
    </svg>
  );
};
