/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { useAlertMessages } from '@/src/modules/CampaignsModule/components/HtmlUpload/hooks';

import { editorStyle } from './joditEditorIframeStyle';

import { Jodit } from '@/modules/CampaignsModule/components/HtmlUpload/enums';
import { IUploadEvent } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

export const editorConfig = () => {
  const {
    unknownError,
    errorAddingFile,
    unsupportedFile,
    directoryExist,
    errorLoadingFile,
    maxFilesAllowed,
  } = useAlertMessages();

  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const { URL_EMA_APP_V2, JODIT_KEY, JWT_DEV_TOKEN } = configData.endpoints[env];
  const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : ((window as any).__JWTToken as string);

  const apiUrl = `${URL_EMA_APP_V2}/api/v2.1`;

  const { t } = useTranslation(['editorHtml']);

  const closeGallery = () => {
    const closeButton = document.querySelector('.jodit-toolbar-button_close button') as HTMLElement;
    closeButton?.click();
  };

  const MAX_FILES_ALLOWED = 10;

  return {
    loadExternalConfig: false,
    cleanHTML: {
      fillEmptyParagraph: false,
    },
    iframe: true,
    iframeStyle: editorStyle,
    showPlaceholder: false,
    autofocus: true,
    height: '100%',
    width: '100%',
    minHeight: '75vh',
    sizeLG: 800,
    sizeMD: 600,
    sizeSM: 400,
    license: JODIT_KEY,
    disablePlugins: ['buttonGenerator'],
    toolbarInlineDisabledButtons: ['buttonGenerator'],
    uploader: {
      url: `${apiUrl}/editor/images/upload`,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prepareData: function (formData: any) {
        const files = [];

        for (const pair of formData.entries()) {
          if (pair[1] instanceof File) {
            files.push(pair[1]);
          }
        }

        if (files.length > MAX_FILES_ALLOWED) {
          for (const key of formData.keys()) {
            formData.delete(key);
          }
          maxFilesAllowed();
          closeGallery();
          return false;
        }

        return formData;
      },
      isSuccess: function (e: IUploadEvent) {
        if (!e.success) {
          errorLoadingFile();
          closeGallery();
        }

        if (e !== undefined) {
          let messagesObject = '';
          e.data.messages &&
            e.data.messages.map((message: string) => {
              if (message) {
                const messageObject = message.split(' ');
                const messageFinal = messageObject.length > 1 ? messageObject[1] : messageObject[0];
                const messageInitial = messageObject.length > 1 ? messageObject[0] : '';
                messagesObject += `${messageInitial} ${t(`Error.${messageFinal}`)}`;
              }
            });

          if (messagesObject) {
            toast({
              title: t('LOADING_FILE_ALERT.title'),
              body: messagesObject,
              variant: 'error',
              autoCloseDelay: 10000,
            });
          }

          const errorHandlers: Record<Jodit.errorAddingFile | Jodit.unsupportedFile, () => void> = {
            [Jodit.errorAddingFile]: errorAddingFile,
            [Jodit.unsupportedFile]: unsupportedFile,
          };

          if (e.data.error.length) {
            const errorType = (Object.values(Jodit) as string[]).find((type) =>
              e.data.error[0].includes(type)
            ) as Jodit.errorAddingFile | Jodit.unsupportedFile | undefined;

            if (errorType && errorHandlers[errorType]) {
              errorHandlers[errorType]();
              closeGallery();
              return false;
            }
          }

          return e.success;
        }
      },
      error: function () {
        errorLoadingFile();
      },
    },
    filebrowser: {
      ajax: {
        url: `${apiUrl}/editor/images`,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        method: 'GET',
        xhr: function () {
          const xhr = new XMLHttpRequest();
          xhr.addEventListener('load', function () {
            if (xhr.status >= 400) {
              unknownError();
            }
          });
          return xhr;
        },
      },
      buttons: [
        'filebrowser.home',
        '|',
        'filebrowser.upload',
        'filebrowser.remove',
        'filebrowser.update',
        'filebrowser.select',
        'filebrowser.edit',
        '|',
        'filebrowser.preview',
        '|',
        '|',
        '|',
      ],
      isSuccess: function (e: IUploadEvent) {
        const errorHandlers: Record<Jodit.directoryExist, () => void> = {
          [Jodit.directoryExist]: directoryExist,
        };

        if (e.data.messages?.length) {
          const errorType = (Object.values(Jodit) as string[]).find((type: string) =>
            e.data.messages?.includes(type)
          ) as Jodit.directoryExist | undefined;

          const closeGallery: HTMLElement = document.querySelector(
            '.jodit-toolbar-button_close button'
          ) as HTMLElement;

          if (errorType && errorHandlers[errorType]) {
            errorHandlers[errorType]();
            closeGallery.click();
          }
        }

        if (e !== undefined) {
          const element: HTMLElement = document.querySelector(
            '.jodit-toolbar-button_home button'
          ) as HTMLElement;

          if (
            e.data.messages &&
            (e.data.messages.includes(Jodit.directoryCreated) ||
              e.data.messages.includes(Jodit.fileRemove))
          ) {
            const path = e.data.sources[0].path;
            if (path === Jodit.emptyPath || path === Jodit.basePath) {
              element.click();
              return;
            }
          }
        }
        return e.success;
      },
      height: 580,
    },
    image: {
      useImageEditor: false,
    },
    buttons:
      'bold,italic,underline,strikethrough,fontsize,font,brush,eraser,copyformat,|,align,ul,ol,indent,outdent,superscript,subscript,paragraph,|,image,hr,table,link,symbols,|,cut,copy,paste,selectall,undo,redo,|,source',
  };
};
