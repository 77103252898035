import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { SuccessIcon } from '@/lib/v2/icons/outline';
import { LinkIcon } from '@/lib/v2/icons/solid';

import useButtonPreviewCopyLink from './hooks/useButtonPreviewCopyLink';

const ButtonPreviewCopyLink = () => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const {
    state: { isCopiedLink, loadingPreviewLink },
    event: { handleCopyPreviewLink },
  } = useButtonPreviewCopyLink({ actionId: Number(actionId) });

  return (
    <Tooltip
      alignment="center"
      content={
        <Text alignment="center" color="dark" variant="text-sm">
          {isCopiedLink ? t('CopyToClipboard.TOOLTIP.copied') : t('CopyToClipboard.TOOLTIP.copy')}
        </Text>
      }
      position="bottom"
    >
      <Button
        gray
        outline
        className="!size-[38px]"
        disabled={loadingPreviewLink}
        id="preview__edit-copy-link"
        size="small"
        onClick={handleCopyPreviewLink}
      >
        <IconSvg svgComponent={isCopiedLink ? <SuccessIcon /> : <LinkIcon />} />
      </Button>
    </Tooltip>
  );
};

export default ButtonPreviewCopyLink;
