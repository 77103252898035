import classNames from 'classnames';

import { RangeSlider, Text } from '@/lib/v2/components';
import { RangeConfig } from '@/lib/v2/components/RangeSlider/RangeSlider';

import { numberToCurrency } from '@/src/utils/currency';

interface RangeWithLabel {
  onChange: (value: string | number) => void;
  value?: number | string;
  label?: string;
  config: RangeConfig[];
  disabled?: boolean;
  id?: string;
}

const RangeWithLabel = ({
  onChange,
  value,
  label,
  config,
  disabled = false,
  id,
}: RangeWithLabel) => {
  const classes = classNames('flex w-full flex-col gap-2', {
    '!cursor-not-allowed opacity-50': disabled,
  });

  return (
    <div className={classes}>
      <Text fontWeight="medium" variant="headline">
        {typeof value === 'number' ? numberToCurrency(value, true) : value} {label}
      </Text>
      <div className="flex w-full gap-4">
        <div className="-ml-6 w-full">
          <RangeSlider
            withMoreOptionRange
            withSuffix
            config={config}
            disabled={disabled}
            id={id}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeWithLabel;
