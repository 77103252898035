import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';

import {
  atomFiltersAction,
  atomStatusFilterCount,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { DROPDOWN_ACTIONS_STATE_GROUPED } from '@/modules/CampaignsModule/constants';
import { IGroupedStateOption } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IFilterCampaignStatusProps {
  currentItems: (updatedStatus: number[]) => void;
}

const FilterStatus = ({ currentItems }: IFilterCampaignStatusProps) => {
  const { t } = useTranslation();
  const filtersAction = useAtomValue(atomFiltersAction);
  const setStatusFilterCount = useSetAtom(atomStatusFilterCount);

  const [selectedStatus, setSelectedStatus] = useState<number[]>(filtersAction?.status ?? []);

  useEffect(() => {
    currentItems(selectedStatus);
  }, [selectedStatus, currentItems]);

  const statusItems = useMemo(() => {
    return DROPDOWN_ACTIONS_STATE_GROUPED.map((state) => ({
      ...state,
      label: t(`CAMPAIGN_ACTIONS_MAIN.STATES.${state.label}`),
      checked: selectedStatus.includes(state.values[0]),
      value: state.values[0],
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [selectedStatus, t]);

  useEffect(() => {
    const checkedCount = statusItems.filter((item) => item.checked).length;
    setStatusFilterCount(checkedCount);
  }, [statusItems, setStatusFilterCount]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    const category: IGroupedStateOption | undefined = DROPDOWN_ACTIONS_STATE_GROUPED.find((cat) =>
      cat.values.includes(Number(value))
    );

    setSelectedStatus((prevSelected) => {
      if (checked && category) {
        const newStatus = new Set([...prevSelected, ...category.values]);
        return Array.from(newStatus);
      } else if (!checked && category) {
        return prevSelected.filter((item) => !category.values.includes(item));
      }
      return prevSelected;
    });
  }, []);

  const classNameScrollable =
    'overflow-y-auto flex flex-col gap-2 visible-scrollbar  overflow-hidden max-h-[150px]';

  return (
    <div className="mb-4">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGN_ACTIONS_MAIN.FILTERS.state')}
      </Text>
      <div className={classNameScrollable}>
        {statusItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`status-${item.id}`}
            label={item.label}
            value={item.value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterStatus);
