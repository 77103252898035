export const CampaignIcon = ({ color = 'white', bgColor = '#0056B8' }) => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <rect fill={bgColor} height="32" rx="4" width="32" />
      <path
        d="M11.3352 24.0628C10.4579 23.9107 9.64487 23.4858 9.00224 22.8436C6.66704 21.1367 5.96682 20.5283 5.5 20.1626V25.2808C5.5 25.6042 5.62296 25.9143 5.84182 26.1429C6.06068 26.3715 6.35752 26.5 6.66704 26.5H16C16.3095 26.5 16.6064 26.3715 16.8252 26.1429C17.0441 25.9143 17.167 25.6042 17.167 25.2808V20.1626C16.7002 20.5283 16 21.1379 13.667 22.8436C13.0247 23.4856 12.2121 23.9105 11.3352 24.0628ZM16 16.75H6.66704C6.35752 16.75 6.06068 16.8784 5.84182 17.1071C5.62296 17.3357 5.5 17.6458 5.5 17.9692V18.5788C6.43363 19.3103 6.31693 19.3103 9.70022 21.8694C10.0503 22.1133 10.7506 22.8448 11.333 22.8448C11.9154 22.8448 12.6167 22.1133 12.9657 21.9913C16.349 19.4264 16.2323 19.4264 17.1704 18.6995V17.9692C17.1704 17.8088 17.1401 17.65 17.0813 17.5018C17.0224 17.3537 16.9361 17.2191 16.8274 17.1058C16.7187 16.9926 16.5896 16.9029 16.4476 16.8418C16.3057 16.7807 16.1535 16.7495 16 16.75ZM25.3318 13.0959H13.6659C13.3564 13.0959 13.0596 13.2244 12.8407 13.453C12.6218 13.6817 12.4989 13.9918 12.4989 14.3151V15.5343H16C16.5796 15.5349 17.1383 15.7605 17.5676 16.1674C17.9969 16.5743 18.2661 17.1333 18.3229 17.7359L18.333 17.7277V22.8459H25.333C25.6425 22.8459 25.9393 22.7175 26.1582 22.4889C26.377 22.2602 26.5 21.9501 26.5 21.6268V14.3128C26.4994 13.9896 26.376 13.68 26.157 13.4518C25.938 13.2236 25.6412 13.0956 25.3318 13.0959ZM24.1648 17.9704H21.833V15.5308H24.1659L24.1648 17.9704ZM11.3352 14.3128C11.3358 13.6669 11.5814 13.0476 12.0183 12.5905C12.4552 12.1335 13.0476 11.876 13.6659 11.8744H21.8318V8.21919C21.8318 7.89604 21.709 7.58612 21.4904 7.35751C21.2718 7.1289 20.9752 7.00031 20.6659 7H9C8.69048 7 8.39364 7.12845 8.17478 7.35709C7.95591 7.58573 7.83296 7.89584 7.83296 8.21919V15.5308H11.3352V14.3128Z"
        fill={color}
      />
    </svg>
  );
};
