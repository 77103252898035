export const TriggerTableIcon = () => {
  return (
    <svg className="mr-2 flex h-8 w-8 self-center" viewBox="0 0 40 40">
      <g data-name="icono Contenedor" id="icono_Contenedor">
        <rect
          data-name="Rectangle 3006"
          fill="none"
          height="40"
          id="Rectangle_3006"
          rx="6"
          width="40"
        />
      </g>
      <g data-name="Icono Trigger 02" id="Icono_Trigger_02" transform="translate(4 7)">
        <path
          d="M344.577,199.736Z"
          data-name="Path 18"
          fill="#18c678"
          id="Path_18"
          transform="translate(-323.039 -190.585)"
        />
        <path
          d="M121.78,135.515l-5.015-5.509a2.6,2.6,0,0,0-3.61.008,2.494,2.494,0,0,0-.644,1.1,2.6,2.6,0,0,0-3.356.232,2.529,2.529,0,0,0-.646,1.1,2.6,2.6,0,0,0-3.354.233,2.532,2.532,0,0,0-.64,1.076l-2.363-2.363A2.553,2.553,0,0,0,98.54,135l9.078,9.078-5.382.845a2.987,2.987,0,0,0-2.553,2.943,2,2,0,0,0,2,2h12.573a7.3,7.3,0,0,0,5.188-2.149l2.093-2.1a7.327,7.327,0,0,0,.239-10.109Zm-1.183,9.17-2.093,2.1a5.969,5.969,0,0,1-4.245,1.757H101.684a.668.668,0,0,1-.667-.667,1.652,1.652,0,0,1,1.416-1.625l6.687-1.051a.666.666,0,0,0,.369-1.129l-10-10a1.219,1.219,0,1,1,1.724-1.724l3.916,3.912c.009.01.022.022.032.032l2.726,2.724a.667.667,0,1,0,.943-.943l-2.748-2.748a1.22,1.22,0,0,1,.031-1.7,1.243,1.243,0,0,1,1.715,0l1.311,1.312c.008.008.015.016.023.023l1.392,1.391a.667.667,0,0,0,.943-.943l-1.408-1.407a1.223,1.223,0,0,1,.025-1.709,1.243,1.243,0,0,1,1.715,0l1.392,1.4h.006a.667.667,0,1,0,.927-.959l-.08-.08a1.22,1.22,0,0,1,.03-1.7,1.263,1.263,0,0,1,1.693-.023l4.993,5.487a5.991,5.991,0,0,1-.195,8.269Z"
          data-name="Path 17"
          fill="#18c678"
          id="Path_17"
          transform="translate(-91.683 -124.536)"
        />
        <path
          d="M54.129,99.549a5.995,5.995,0,1,0-7.926,7.925.667.667,0,0,0,.549-1.216,4.661,4.661,0,1,1,6.161-6.161.667.667,0,1,0,1.216-.548Z"
          data-name="Path 19"
          fill="#18c678"
          id="Path_19"
          transform="translate(-39.999 -93.344)"
        />
        <path
          d="M17.035,59.782a8.662,8.662,0,1,0-10.594,10.6.633.633,0,0,0,.171.023.667.667,0,0,0,.171-1.311,7.327,7.327,0,1,1,8.964-8.964.661.661,0,0,0,.816.473A.669.669,0,0,0,17.035,59.782Z"
          data-name="Path 20"
          fill="#18c678"
          id="Path_20"
          transform="translate(-0.001 -53.344)"
        />
      </g>
    </svg>
  );
};
