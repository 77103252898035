export const StateIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 6145" id="Group_6145" transform="translate(0.135)">
        <rect
          data-name="Rectangle 3178"
          fill="none"
          height="20"
          id="Rectangle_3178"
          transform="translate(-0.135)"
          width="20"
        />
        <path
          d="M9.977,2a7.977,7.977,0,1,0,7.977,7.977A7.98,7.98,0,0,0,9.977,2Zm0,14.358a6.381,6.381,0,1,1,6.381-6.381A6.39,6.39,0,0,1,9.977,16.358Zm3.1-9.341-4.69,4.69-1.5-1.5a.8.8,0,1,0-1.125,1.125L7.823,13.4a.794.794,0,0,0,1.125,0L14.2,8.142a.794.794,0,0,0,0-1.125A.8.8,0,0,0,13.072,7.017Z"
          data-name="Layout 3216"
          fill="#004dbc"
          id="Layout_3216"
          transform="translate(-0.674 0.024)"
        />
      </g>
    </svg>
  );
};
