import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { UsersIcon } from '@/lib/v2/icons/solid';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { SectionHeader } from '@/modules/ContactsModule/components';

interface ContactsFoundProps {
  numberOfContacts: number;
  isLoading: boolean;
}

const ContactsFound = ({ numberOfContacts, isLoading }: ContactsFoundProps) => {
  const { t } = useTranslation();

  const title = isLoading ? (
    <div className="w-40 animate-pulse">
      <div className="h-[36px] py-3">
        <div className="h-full w-full rounded-md bg-gray-200"></div>
      </div>
    </div>
  ) : (
    <>
      <Text color="primary" fontWeight="medium" variant="title-1">
        {quantifier(numberOfContacts)}
      </Text>
      <Text fontWeight="medium" variant="title-1">
        {t('SEGMENT_FILTERS.contactsFound')}
      </Text>
    </>
  );

  return (
    <Card>
      <CardSection noPadding>
        <div className="flex flex-col">
          <SectionHeader
            withoutDivide
            icon={<IconSvg height="24px" svgComponent={<UsersIcon />} width="24px" />}
            title={title}
          />
          {/* <CardContainer>
                        <div className='w-full md:w-[300px]'>
                            <InputSearch placeHolder={t('SEGMENT_FILTERS.placeholderInputSearch')} />
                        </div>
                    </CardContainer>
                    <ContactsFoundTable /> */}
        </div>
      </CardSection>
    </Card>
  );
};

export default memo(ContactsFound);
