import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { IconSvg, Text, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyContactsIcon, EmptyGroupIcon } from '@/lib/v2/icons/general';
import { CopyIcon } from '@/lib/v2/icons/solid';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { BulletListIcon } from '@/src/ContactsModule/Icons/BulletListIcon';
import { ContactsIcon } from '@/src/ContactsModule/Icons/ContactsIcon';
import { IGroup, ITableGroupProps } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import CopyToClipboard from '@/src/utils/Copy';

export function GroupsTable({
  groupList,
  totalGroupCount,
  changeTableOrder,
  changeTablePage,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
  setSelectedGroups,
}: ITableGroupProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tableOrderByList, setTableOrderByList] = useState([
    {
      id: 'GroupName',
      isAsc: true,
    },
    {
      id: 'Contacts',
      isAsc: undefined,
    },
  ]);

  const handleCopy = useCallback(
    async (e: MouseEvent, id: number) => {
      e.stopPropagation();
      await CopyToClipboard(id);
      toast({
        title: t('COPY_NOTIFICATION.title'),
        body: t('COPY_NOTIFICATION.body'),
      });
    },
    [t]
  );

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <BulletListIcon stroked tableSize />
        <span className="text-[#004DBC]">{t('GROUPS_TABLE.name')} </span>
        <SortTableArrow
          elementName={'GroupName'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    width: '60%',
    accessor: 'group',
    Cell: ({ cell: { value } }: { cell: { value: IGroup } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span className="self-start font-medium text-[#364365]"> {value.groupName} </span>

          <Flex withGap alignment="start" itemAlignment="center">
            <span className="self-start font-medium text-[#707070]"> ID: {value.id} </span>
            <IconSvg
              height="12px"
              svgComponent={<CopyIcon color={'#707070'} />}
              width="12px"
              onClick={(e) => void handleCopy(e, value.id)}
            />
          </Flex>
        </Flex>
      </Flex>
    ),
  };
  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactsIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('GROUPS_TABLE.contact')} </span>
        <SortTableArrow
          elementName={'Contacts'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'group',
    id: 'contacts',
    width: '40%',
    Cell: ({ cell: { value } }: { cell: { value: IGroup } }) => (
      <Flex withGap alignment="start" gapSize="small">
        {value.contacts ? (
          <span className="self-start font-medium text-[#364365]">
            {' '}
            {quantifier(value.contacts)}{' '}
          </span>
        ) : (
          <span className={'opacity-50'}> {t('GROUPS_TABLE.noContact')} </span>
        )}
      </Flex>
    ),
  };
  const groupsTableColumns: Column<object>[] = [nameColumn, contactColumn].filter(
    (x) => x
  ) as Column<object>[];

  const goToGroupDetail = (group: IGroup) => {
    group.contacts !== 0 && navigate(`../groups/${group.id}`);
  };

  const EmptyScreenTableNoDataDescription = useMemo(
    () => (
      <>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyGroupsDescription1')}
        </Text>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyGroupsDescription2')}
          <button
            className="ml-1 cursor-pointer !text-20 font-medium text-emblue-primary"
            data-testid="nps-screen-empty-table-create-link"
            role="link"
            onClick={callToAction && callToAction[1]}
          >
            {t('EMPTY_SCREEN_TABLE.emptyGroupsAction')}
          </button>
        </Text>
      </>
    ),
    [callToAction, t]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        paginationWithPadding
        columns={groupsTableColumns}
        data={groupList}
        emptyScreen={{
          totalCount: totalGroupCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.groups')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={EmptyScreenTableNoDataDescription}
              image={<EmptyGroupIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyGroupsTitle')}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        setSelectedRows={setSelectedGroups}
        withCheckbox={true}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
        onRowClick={(value) => {
          goToGroupDetail(value.group);
        }}
      />
    </div>
  );
}
