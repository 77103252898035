import { FC } from 'react';

import { Card, Flex, Text } from '@/lib/v2/components';

import { ChartSkeleton } from './ChartSkeleton';
import { PieChartWidget } from './PieChartWidget';
import { IPieChart, IPieChartCard } from './types';

export const PieChartCard: FC<IPieChartCard> = ({ chartData, label }) => {
  return (
    <Card className="h-[236px]">
      {chartData ? (
        <Flex withGap alignment="center" gapSize="large">
          <PieChartWidget chartData={chartData} label={label} />
          <div>
            <Flex column withGap gapSize="medium" itemAlignment="start">
              {chartData.map((element: IPieChart) => {
                const textColor = element?.textColor;
                return (
                  <Flex key={element.id} withGap gapSize="large">
                    <Text fontWeight="normal">{element.name}</Text>
                    <Text
                      as="p"
                      className={`!text-20 ${textColor ?? 'text-emblue-primary'}`}
                      fontWeight="bold"
                      variant="text"
                    >
                      {element.value}%
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </div>
        </Flex>
      ) : (
        <ChartSkeleton />
      )}
    </Card>
  );
};
