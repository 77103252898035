import { Flex } from '@/lib/v2/components/Layout/Stack';

export const CloudSkeleton = () => {
  return (
    <div className="w-1/3 animate-pulse py-16">
      <Flex>
        <Flex column withGap gapSize="small">
          <Flex column withGap gapSize="medium">
            <div className="h-1 w-32 -translate-x-20 rounded-full bg-gray-200"></div>
            <div className="h-2 w-36 translate-x-6 rounded-full bg-gray-200"></div>
          </Flex>
          <Flex column withGap gapSize="small">
            <Flex alignment="spaceBetween">
              <div className="h-3 w-36 rounded-full bg-gray-200"></div>
              <div className="h-4 w-48 rounded-full bg-gray-200"></div>
            </Flex>
          </Flex>
          <Flex column withGap gapSize="medium">
            <div className="h-3 w-36 -translate-x-5 rounded-full bg-gray-200"></div>
            <Flex column withGap gapSize="small">
              <div className="h-4 w-48 -translate-x-32 rounded-full bg-gray-200"></div>
              <div className="h-4 w-48 translate-x-28 rounded-full bg-gray-200"></div>
              <div className="h-3 w-36 -translate-x-64 rounded-full bg-gray-200"></div>
              <div className="h-1 w-36 -translate-x-16 rounded-full bg-gray-200"></div>
              <div className="h-2 w-36 translate-x-28 rounded-full bg-gray-200"></div>
              <div className="h-4 w-48 -translate-x-28 rounded-full bg-gray-200"></div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
