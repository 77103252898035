export const CustomSegmentIcon = () => {
  return (
    <svg
      height="51.393"
      id="stats-marketing-svgrepo-com"
      viewBox="0 0 51.393 51.393"
      width="51.393"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M236,10.2a26.68,26.68,0,0,1,26.68,26.68H236Z"
        data-name="Path 4795"
        fill="#d35b38"
        id="Path_4795"
        transform="translate(-212.311 -9.175)"
      />
      <path
        d="M55.529,72.863A22.665,22.665,0,1,1,32.864,50.2V72.863Z"
        data-name="Path 4796"
        fill="#d3ddea"
        id="Path_4796"
        transform="translate(-9.175 -45.159)"
      />
      <g data-name="Group 8017" id="Group_8017" transform="translate(10.741 1.683)">
        <path
          d="M327.191,122.116H317.049v-6.843a5.069,5.069,0,0,1,5.068-5.075h0a5.075,5.075,0,0,1,5.075,5.075v6.843Z"
          data-name="Path 4797"
          fill="#ffad61"
          id="Path_4797"
          transform="translate(-295.966 -100.821)"
        />
        <circle
          cx="3.164"
          cy="3.164"
          data-name="Ellipse 1920"
          fill="#ffad61"
          id="Ellipse_1920"
          r="3.164"
          transform="translate(22.987)"
        />
        <path
          d="M117.149,271.778H107.007v-6.843a5.069,5.069,0,0,1,5.068-5.075h0a5.075,5.075,0,0,1,5.075,5.075Z"
          data-name="Path 4798"
          fill="#ffad61"
          id="Path_4798"
          transform="translate(-107.007 -235.459)"
        />
        <circle
          cx="3.164"
          cy="3.164"
          data-name="Ellipse 1921"
          fill="#ffad61"
          id="Ellipse_1921"
          r="3.164"
          transform="translate(1.904 15.023)"
        />
      </g>
      <g data-name="Group 8018" id="Group_8018">
        <path
          d="M48.382,15.132a27.963,27.963,0,0,0-7.467-9.124,4.185,4.185,0,0,0-7.031-4.07A27.533,27.533,0,0,0,23.689,0a1.024,1.024,0,0,0-1.024,1.024V4.038A23.688,23.688,0,0,0,6.938,44.454,23.688,23.688,0,0,0,47.355,28.728h3.014A1.024,1.024,0,0,0,51.393,27.7,27.408,27.408,0,0,0,48.382,15.132ZM36.892,2.707a2.14,2.14,0,1,1-2.14,2.14A2.143,2.143,0,0,1,36.892,2.707Zm-13.2,46.638A21.642,21.642,0,0,1,22.665,6.086V27.7a1.024,1.024,0,0,0,1.024,1.024H45.306A21.668,21.668,0,0,1,23.689,49.345ZM24.713,26.68V2.067a25.44,25.44,0,0,1,8.143,1.667,4.186,4.186,0,0,0,7.012,4.057A25.429,25.429,0,0,1,49.325,26.68H24.713Z"
          data-name="Path 4799"
          fill="#4d3d36"
          id="Path_4799"
        />
        <path
          d="M294.49,113.966a1.024,1.024,0,0,0,0-2.047h-1.931V106.1a6.095,6.095,0,1,0-12.19,0v5.819h-1.931a1.024,1.024,0,1,0,0,2.047Zm-12.073-2.047V106.1a4.047,4.047,0,1,1,8.095,0v5.819Z"
          data-name="Path 4800"
          fill="#4d3d36"
          id="Path_4800"
          transform="translate(-249.568 -89.963)"
        />
        <path
          d="M84.447,263.627a1.024,1.024,0,0,0,0-2.048H82.516V255.76a6.095,6.095,0,1,0-12.19,0v5.819H68.395a1.024,1.024,0,0,0,0,2.048Zm-12.073-2.048V255.76a4.047,4.047,0,1,1,8.095,0v5.819Z"
          data-name="Path 4801"
          fill="#4d3d36"
          id="Path_4801"
          transform="translate(-60.609 -224.602)"
        />
        <path
          d="M119.96,164.611a4.188,4.188,0,1,0-4.188-4.188A4.193,4.193,0,0,0,119.96,164.611Zm0-6.328a2.14,2.14,0,1,1-2.14,2.14A2.143,2.143,0,0,1,119.96,158.283Z"
          data-name="Path 4802"
          fill="#4d3d36"
          id="Path_4802"
          transform="translate(-104.151 -140.553)"
        />
        <path
          d="M262.124,370.437h.51a1.024,1.024,0,0,0,0-2.047h-.51a1.024,1.024,0,1,0,0,2.047Z"
          data-name="Path 4803"
          fill="#4d3d36"
          id="Path_4803"
          transform="translate(-234.892 -331.412)"
        />
      </g>
    </svg>
  );
};
