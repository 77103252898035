import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ECustomFieldFilterType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';
import SummaryAction, {
  SummaryDataSection,
} from '@/src/modules/CampaignsModule/components/SummaryAction/SummaryAction';

import {
  atomActionInfoSms,
  atomActionRecipients,
  atomActionReportSms,
} from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomActionData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  STATES_PROCESS_ACTION,
  TRANSLATE_SMS_ACTION_REPORT,
  TRANSLATE_SMS_ACTION_SUMMARY,
} from '@/modules/CampaignsModule/constants';
import {
  IListRecipientsSMSByType,
  IRecipientsSMSByType,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { formatDateTimeLocal } from '@/modules/CampaignsModule/utils/formatDate';

const SmsSummary = () => {
  /** cspell:disable */
  const { t } = useTranslation();

  const actionInfoSms = useAtomValue(atomActionInfoSms);
  const actionReportSms = useAtomValue(atomActionReportSms);
  const actionRecipients = useAtomValue(atomActionRecipients);
  const actionData = useAtomValue(atomActionData);

  const { finished } = STATES_PROCESS_ACTION;

  const {
    events: { getCustomFieldsFilter, translateCustomField },
  } = useCustomField();

  const customFieldsListPhone = useMemo(() => {
    return getCustomFieldsFilter({
      ids: [actionInfoSms.campo],
      filterType: ECustomFieldFilterType.ID_CUSTOM_TYPE,
    });
  }, [actionInfoSms.campo, getCustomFieldsFilter]);

  const recipientsByType = useMemo(() => {
    const data =
      actionRecipients?.grupos.reduce((acc: { [key: string]: IRecipientsSMSByType[] }, item) => {
        const key = item.tipo.toUpperCase();

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {}) || [];

    return data as unknown as IListRecipientsSMSByType;
  }, [actionRecipients?.grupos]);

  const dateMessage = useMemo(() => {
    const schedulingType = actionInfoSms?.inmediato
      ? t(`${TRANSLATE_SMS_ACTION_SUMMARY}.scheduledImmediate`)
      : t(`${TRANSLATE_SMS_ACTION_SUMMARY}.scheduledMessage`);

    if (actionInfoSms?.inmediato) {
      return `${schedulingType}.`;
    }

    const { anio, mes, dia, hora, minutos } = actionInfoSms;
    const schedulingDate = new Date(+anio, +mes - 1, +dia, +hora, +minutos);
    const dateLocal = formatDateTimeLocal(schedulingDate);
    const messageDate = `${dateLocal.date.toString()}, ${dateLocal.time.toString()}`;

    return `${schedulingType}. ${messageDate || ''}`;
  }, [actionInfoSms, t]);

  const getContentElement = useCallback((title: string, content: string | string[]) => {
    return {
      title,
      content: content,
    };
  }, []);

  const summaryData = useMemo((): SummaryDataSection[] => {
    const { total, depurados, pendings, sent, enviados, fallidos, cantidad_clicks } =
      actionReportSms;

    const { PERFIL, USUARIO, INTERES, INTEGRACION } = recipientsByType;

    const baseSummaryData = [
      {
        title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.shipping`),
        elements: [
          getContentElement(t(`${TRANSLATE_SMS_ACTION_SUMMARY}.shippingDate`), dateMessage),
        ],
      },
      {
        title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.recipientsLabel`),
        elements: [
          {
            title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.recipients.segments`),
            content: PERFIL ? PERFIL?.map(({ nombre }: IRecipientsSMSByType) => nombre) : '-',
          },
          {
            title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.recipients.groups`),
            content: USUARIO ? USUARIO?.map(({ nombre }: IRecipientsSMSByType) => nombre) : '-',
          },
          {
            title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.recipients.tags`),
            content: INTERES ? INTERES?.map(({ nombre }: IRecipientsSMSByType) => nombre) : '-',
          },
          {
            title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.recipients.integrations`),
            content: INTEGRACION
              ? INTEGRACION?.map(({ nombre }: IRecipientsSMSByType) => nombre)
              : '-',
          },
          {
            title: t(`${TRANSLATE_SMS_ACTION_SUMMARY}.phoneLabel`),
            content:
              customFieldsListPhone.length === 1
                ? translateCustomField(customFieldsListPhone[0].name)
                : '-',
          },
        ],
      },
    ];

    if (!finished.includes(Number(actionData?.status))) return baseSummaryData;

    return [
      ...baseSummaryData,
      {
        title: t(`${TRANSLATE_SMS_ACTION_REPORT}.report`),
        elements: [
          {
            title: t(`${TRANSLATE_SMS_ACTION_REPORT}.state`),
            content: [
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.totalRecipients`)}: ${total}`,
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.debugged`)}: ${depurados}`,
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.processing`)}: ${pendings}`,
            ],
          },
          {
            title: t(`${TRANSLATE_SMS_ACTION_REPORT}.results`),
            content: [
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.sent`)}: ${sent}`,
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.confirmed`)}: ${enviados}`,
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.failed`)}: ${fallidos}`,
              `${t(`${TRANSLATE_SMS_ACTION_REPORT}.clicks`)}: ${cantidad_clicks}`,
            ],
          },
        ],
      },
    ];
  }, [
    actionReportSms,
    t,
    getContentElement,
    dateMessage,
    recipientsByType,
    actionInfoSms?.campo,
    finished,
    actionData?.status,
  ]);
  /** cspell:enable */

  return (
    <div className="visible-scrollbar max-h-[calc(100vh_-_240px)] overflow-y-auto">
      <SummaryAction summaryData={summaryData} />
    </div>
  );
};

export default SmsSummary;
