import classNames from 'classnames';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';

type TooltipProps = {
  /** Content that triggers the tooltip on hover */
  children: ReactNode;
  /** Tooltip content to display */
  content: ReactNode;
  /** Positions tooltip relative to target element */
  position?: 'bottom' | 'top' | 'left' | 'right';
  /** Tooltip alignment */
  alignment?: 'start' | 'center' | 'end';
  /** Change background to dark color */
  isDark?: boolean;
  /** Remove the arrow */
  noArrow?: boolean;
  /** Hold the tooltip with the mouse */
  isClickable?: boolean;
  /** take full width of your container */
  isFullWidth?: boolean;
  className?: string;
};

const Tooltip = ({
  /** The element that will act as a trigger for the tooltip. */
  children,
  /** The content that will be displayed within the tooltip. */
  content,
  position = 'bottom',
  alignment = 'center',
  isDark = false,
  isClickable = false,
  isFullWidth = false,
  className,
}: TooltipProps) => {
  const [showTransition, setShowTransition] = useState(false);
  const [visible, setVisible] = useState(false);

  const timerRef = useRef<number | null>(null);
  const timerDebounceRef = useRef<number | null>(null);

  const classes = classNames('relative', className);
  const classesTooltipContainer = classNames(
    'text-12 2xl:text-14 absolute z-[999] p-2 text-sm rounded-lg border shadow-md transition-all duration-300 ease-in-out transform',
    showTransition ? 'opacity-100 scale-100' : 'opacity-0 scale-90',
    {
      'left-full top-1/2 translate-y-[-50%] ml-2': position === 'right' && alignment === 'center',
      'left-1/2 bottom-full translate-x-[-50%] mb-2': position === 'top' && alignment === 'center',
      'right-full top-1/2 translate-y-[-50%] mr-2': position === 'left' && alignment === 'center',
      'left-1/2 top-full translate-x-[-50%] mt-2': position === 'bottom' && alignment === 'center',

      'left-full top-0 ml-2': position === 'right' && alignment === 'start',
      'left-0 bottom-full mb-2': position === 'top' && alignment === 'start',
      'right-full top-0 mr-2': position === 'left' && alignment === 'start',
      'left-0 top-full mt-2': position === 'bottom' && alignment === 'start',

      'left-full bottom-0 ml-2': position === 'right' && alignment === 'end',
      'right-0 bottom-full mb-2': position === 'top' && alignment === 'end',
      'right-full bottom-0 mr-2': position === 'left' && alignment === 'end',
      'right-0 top-full mt-2': position === 'bottom' && alignment === 'end',
    },
    {
      'bg-white': !isDark,
      'bg-[#364365] border-[#364365]': isDark,
      'text-black': !isDark,
      'text-white': isDark,
      'min-w-[100px] w-auto': !isFullWidth,
      'w-full': isFullWidth,
    }
  );

  const requestAnimationId = useRef<number>();

  useEffect(() => {
    if (visible) {
      requestAnimationId.current = requestAnimationFrame(() => {
        setShowTransition(true);
      });
    } else if (requestAnimationId.current) {
      cancelAnimationFrame(requestAnimationId.current);
    }
    return () => {
      if (requestAnimationId.current) cancelAnimationFrame(requestAnimationId.current);
    };
  }, [visible, requestAnimationId]);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }
    timerDebounceRef.current = window.setTimeout(() => {
      setVisible(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    if (isClickable) {
      timerRef.current = window.setTimeout(() => {
        setShowTransition(false);
      }, 300);
    } else {
      if (timerDebounceRef.current) {
        clearTimeout(timerDebounceRef.current);
      }
      timerDebounceRef.current = window.setTimeout(() => {
        setShowTransition(false);
      }, 200);
    }
  };

  const handleTooltipEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleTooltipLeave = () => {
    setShowTransition(false);
  };

  const handleTransitionEnd = () => {
    if (!showTransition) {
      setVisible(false);
    }
  };

  return (
    <div className={classes}>
      <div
        className="size-full"
        data-testid="tooltip-dispatch"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {visible && (
        <div
          className={classesTooltipContainer}
          data-testid="tooltip-container"
          onMouseEnter={isClickable ? handleTooltipEnter : undefined}
          onMouseLeave={isClickable ? handleTooltipLeave : undefined}
          onTransitionEnd={handleTransitionEnd}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default memo(Tooltip);
