import { memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@/lib/v2/components';

interface ButtonsSection {
  disabledSubmitButton?: boolean;
  id?: string | number;
  textAction?: string;
  textClose?: string;
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
}

const ButtonsSection = ({
  onAction,
  onClose,
  id = 'ButtonsSection',
  disabledSubmitButton,
  textAction,
  textClose,
  isLoading,
}: ButtonsSection) => {
  const { t } = useTranslation();

  const cancel = textClose ?? t('CREATE_SEGMENT_MODAL.cancel');
  const action = textAction ?? t('CREATE_SEGMENT_MODAL.confirm');

  return (
    <div className="mt-4 pt-4">
      <Flex withGap alignment="end" gapSize="small">
        <Button outline disabled={isLoading} id={`${id}-cancel`} onClick={onClose}>
          {cancel}
        </Button>
        <Button
          disabled={disabledSubmitButton}
          id={`${id}-submit`}
          isLoading={isLoading}
          typeButton="submit"
          onClick={onAction}
        >
          {action}
        </Button>
      </Flex>
    </div>
  );
};

export default memo(ButtonsSection);
