export const MyPlanIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="24"
        id="mask0_95_2457"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="24"
        x="0"
        y="0"
      >
        <rect fill="black" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_95_2457)">
        <path
          d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 12H20V8H4V12Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
