import { format } from '@formkit/tempo';

interface IntervalInput {
  startDate: Date;
  endDate: Date;
}

export const eachDayOfInterval = ({ startDate, endDate }: IntervalInput): Date[] => {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const isSameMonth = (dateLeft: Date, dateRight: Date): boolean => {
  const formattedYearLeft = format(dateLeft, 'YYYY');
  const formattedMonthLeft = format(dateLeft, 'MM');
  const formattedYearRight = format(dateRight, 'YYYY');
  const formattedMonthRight = format(dateRight, 'MM');

  return formattedYearLeft === formattedYearRight && formattedMonthLeft === formattedMonthRight;
};

export const getDayOfWeek = (date: Date): number => date.getDay();

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');
