import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { useFiltersRecurrent } from '@/modules/CampaignsModule/hooks/useFiltersCampaignRecurrent';
import { EFilterKeysRecurrentMessage } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useFiltersDropdown = (onClose?: () => void) => {
  const { t } = useTranslation();

  const filters = useAtomValue(atomFiltersRecurrent);

  const { updateFilter } = useFiltersRecurrent();
  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);

  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedStateRecurrent, setSelectedStateRecurrent] = useState<number[]>(
    filtersRecurrent?.state ?? []
  );

  const countFilters = (filters?.state?.length ?? 0) + (filters?.strategy?.length ?? 0);
  const placeholderFilters =
    countFilters > 0
      ? `${t('CAMPAIGNS_MAIN.FILTERS.filters')} (${countFilters})`
      : t('CAMPAIGNS_MAIN.FILTERS.filters');

  const onApplyFilters = useCallback(() => {
    updateFilter(EFilterKeysRecurrentMessage.State, selectedStateRecurrent);
    setFiltersRecurrent((prevFilters) => ({
      ...prevFilters,
      state: selectedStateRecurrent,
    }));
    setFiltersApplied(true);
    onClose?.();
  }, [updateFilter, selectedStateRecurrent, setFiltersRecurrent, onClose]);

  return { filtersApplied, placeholderFilters, onApplyFilters, setSelectedStateRecurrent };
};
