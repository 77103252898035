/* cspell:disable */
export const editorStyle = `
html, body {
    margin: 5px;
    height: 95%;
    font-family: ui-sans-serif, system-ui;
    font-size: 16px;
    color: #333;
    line-height: 1;
}
table, th, td {
    border: 1px solid lightgrey;
}
div[contenteditable='true']:focus {
    border: none;
}
div[contenteditable='true'] {
    height: 100%;
    outline: none;
    padding: 8px;
}
div[contenteditable='true'] table tr td {
    border: 1px solid #bfbfbf;
}
.jodit-wysiwyg table {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.jodit-wysiwyg table tr td {
    padding: 0.2em !important;
}
.jodit-wysiwyg a {
    color: -webkit-link;
    text-decoration: underline;
}
.jodit-wysiwyg ul,
.jodit-wysiwyg ol {
    margin-left: 1.5em;
}
.jodit-wysiwyg ul li,
.jodit-wysiwyg ol li {
    list-style: inherit;
}
`;
