/* cSpell:disable */
import { ICountries } from '@/src/ContactsModule/interfaces/Interfaces';

export const getCountryNameById = (id: string, countries: ICountries[]): string => {
  const country = countries.find((country) => country.id === id);
  return country ? country.value : id;
};

export const getTranslatedName = (name: string, translations: Record<string, string>): string => {
  return translations[name] || name;
};

export const getTranslatedValue = (
  name: string,
  value: string,
  translationsECommerce: Record<string, string>,
  countriesMock: ICountries[]
): string => {
  const translations: { [key: string]: () => string } = {
    eCommerceClassification: () => translationsECommerce[value] || value,
    country: () => getCountryNameById(value, countriesMock),
    gender: () => {
      if (value === 'Masculino') return 'M';
      if (value === 'Femenino') return 'F';
      return value;
    },
  };

  return Object.hasOwn(translations, name) ? translations[name]() : value;
};
