import { useCallback } from 'react';

import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import { IWhatsAppInfoPayload } from '@/modules/CampaignsModule/interfaces/WhatsApp';

interface IWhatsAppFormEventsProps {
  actionId: number;
}

export const useWhatsAppFormEvents = ({ actionId }: IWhatsAppFormEventsProps) => {
  const { saveInfoWhatsApp } = useActionWhatsAppData(Number(actionId));

  const setInfoOnEvent = useCallback(
    (payload: IWhatsAppInfoPayload) => void saveInfoWhatsApp(payload),
    [saveInfoWhatsApp]
  );

  return { setInfoOnEvent };
};
