export const ViewsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7459" id="Group_7459" transform="translate(-192 -355)">
        <rect
          data-name="Rectangle 3246"
          fill="none"
          height="20"
          id="Rectangle_3246"
          transform="translate(192 355)"
          width="20"
        />
        <g data-name="Group 7461" id="Group_7461" transform="translate(191.81 354.387)">
          <path
            d="M11.5,13.991A2.5,2.5,0,1,0,9,11.5,2.5,2.5,0,0,0,11.5,13.991Z"
            data-name="Traced 3344"
            fill="#004dbc"
            id="Traced_3344"
            transform="translate(-1.306 -0.883)"
          />
          <path
            d="M1.308,10.153a9.362,9.362,0,0,1,17.763-.006,1.469,1.469,0,0,1,0,.926,9.362,9.362,0,0,1-17.762.006,1.466,1.466,0,0,1,0-.926Zm13.249.46A4.367,4.367,0,1,1,10.19,6.246a4.367,4.367,0,0,1,4.367,4.367Z"
            data-name="Traced 3345"
            fill="#004dbc"
            fillRule="evenodd"
            id="Traced_3345"
            transform="translate(0)"
          />
        </g>
      </g>
    </svg>
  );
};
