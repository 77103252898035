import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTablePagination } from '@/lib/components/Table/useTablePagination';

import { useSearchParamsState } from '@/src/application/hooks/useSearchParamsState';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useGetWidgetsCount } from '@/src/infrastructure/services/useGetWidgetsCount';
import { useWidgetNpsList } from '@/src/infrastructure/services/useWidgetNpsList';
import { NPSWidgets } from '@/src/modules/NPSModule/interfaces';
import { useGetContext } from '@/src/utils/ContextUtils';

import { useURLParams } from '@/modules/ContactsModule/hooks/useURLParams';

interface StateContext {
  searchQuery: string;
  pageSize: number;
  totalPages: number;
  isLoadingWidgets?: boolean;
  gotoPageIndex: number;
  lastRefresh: number;
  npsWidgets?: NPSWidgets;
  statusFilter: string[];
  totalNpsWidgets?: number;
  tableOrderByList: Array<{ id: string; isAsc: boolean | undefined }>;
  initialStateOrderByList: Array<{ id: string; isAsc: boolean | undefined }>;
  allWidgets: number;
  widgetsActive: number;
  widgetsInactive: number;
  isLoadingWidgetsCount: boolean;
  isRecycleBin: boolean;
}

interface MutationContext {
  setLastRefresh: Dispatch<SetStateAction<number>>;
  setStatusFilter: (newState: string[]) => void | null;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  changeTablePage: ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => void;
  setSearchQuery: (newState: string) => void | null;
  setOrderColumn: Dispatch<SetStateAction<string | undefined>>;
  setOrderDirection: Dispatch<SetStateAction<string>>;
  setTableOrderByList: Dispatch<SetStateAction<Array<{ id: string; isAsc: boolean | undefined }>>>;
  lastRefreshIncrement: () => void;
  setIsRecycleBin: Dispatch<SetStateAction<boolean>>;
  resetPagination: () => void;
}

interface NpsProviderProps {
  children: React.ReactElement;
}

const NpsStateContext = createContext<StateContext | undefined>(undefined);
const NpsMutationContext = createContext<MutationContext | undefined>(undefined);

const NpsProvider = ({ children }: NpsProviderProps) => {
  const service = useService();
  const [lastRefresh, setLastRefresh] = useState<number>(0);
  const { selectedStatus } = useURLParams();
  const [statusFilter, setStatusFilter] = useSearchParamsState<string[]>(
    'filterStatus',
    selectedStatus || []
  );
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>('filterSearch', '');
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const statusFilterFormat = statusFilter.join(', ');

  const [totalNpsWidgets, setTotalNpsWidgets] = useState<number>(0);

  const [isRecycleBin, setIsRecycleBin] = useState<boolean>(false);
  const typeOfList = isRecycleBin ? 'recycle_bin' : 'list';

  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshNpsValue);

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
    }
  }, [initialLastRefreshValue]);

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    service?.incrementLastRefreshNpsValue();
  }, [service]);

  const {
    pageSize,
    pageIndex,
    totalPages,
    setIsLoading,
    gotoPageIndex,
    changeTablePage,
    resetPagination,
  } = useTablePagination(totalNpsWidgets);

  const [npsWidgets, , isLoading] = useWidgetNpsList(
    {
      search: searchQuery,
      status: statusFilterFormat,
      orderColumn: orderColumn,
      orderDirection: orderDirection,
      pageNumber: pageIndex,
      rowsPerPage: pageSize,
      lastRefresh,
    },
    typeOfList
  );

  const [allWidgets, , isLoadingWidgetsCount] = useGetWidgetsCount('', lastRefresh);
  const [widgetsActive] = useGetWidgetsCount('active', lastRefresh);
  const [widgetsInactive] = useGetWidgetsCount('inactive', lastRefresh);

  useEffect(() => {
    setTotalNpsWidgets(npsWidgets?.countPartial ?? 0);
  }, [npsWidgets?.countPartial]);

  const initialStateOrderByList = useMemo(
    () => [
      { id: 'name', isAsc: undefined },
      { id: 'views', isAsc: undefined },
      { id: 'actions', isAsc: undefined },
      { id: 'ctr', isAsc: undefined },
      { id: 'creationDays', isAsc: undefined },
      { id: 'status', isAsc: undefined },
    ],
    []
  );

  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const memoizedMutations: MutationContext = useMemo<MutationContext>(
    () => ({
      setLastRefresh,
      setStatusFilter,
      setIsLoading,
      changeTablePage,
      setSearchQuery,
      setOrderColumn,
      setOrderDirection,
      setTableOrderByList,
      lastRefreshIncrement,
      setIsRecycleBin,
      resetPagination,
    }),
    [
      setLastRefresh,
      setStatusFilter,
      setIsLoading,
      changeTablePage,
      setSearchQuery,
      setOrderColumn,
      setOrderDirection,
      setTableOrderByList,
      lastRefreshIncrement,
      setIsRecycleBin,
      resetPagination,
    ]
  );

  const memoizedStates: StateContext = useMemo<StateContext>(
    () => ({
      lastRefresh,
      npsWidgets,
      isLoadingWidgets: isLoading,
      gotoPageIndex,
      totalPages,
      searchQuery,
      pageSize,
      statusFilter,
      tableOrderByList,
      totalNpsWidgets,
      initialStateOrderByList,
      allWidgets: allWidgets?.count ?? 0,
      widgetsActive: widgetsActive?.count ?? 0,
      widgetsInactive: widgetsInactive?.count ?? 0,
      isLoadingWidgetsCount,
      isRecycleBin,
    }),
    [
      lastRefresh,
      npsWidgets,
      isLoading,
      gotoPageIndex,
      totalPages,
      searchQuery,
      pageSize,
      statusFilter,
      tableOrderByList,
      totalNpsWidgets,
      initialStateOrderByList,
      allWidgets,
      widgetsActive,
      widgetsInactive,
      isLoadingWidgetsCount,
      isRecycleBin,
    ]
  );

  return (
    <NpsMutationContext.Provider value={memoizedMutations}>
      <NpsStateContext.Provider value={memoizedStates}>{children}</NpsStateContext.Provider>
    </NpsMutationContext.Provider>
  );
};

export const useNpsMutationContext = (): MutationContext =>
  useGetContext<MutationContext>(NpsMutationContext, 'NpsMutationContext');
export const useNpsStateContext = (): StateContext =>
  useGetContext<StateContext>(NpsStateContext, 'NpsStateContext');

export default NpsProvider;
