import classNames from 'classnames';
import { memo } from 'react';
import DatePicker from 'react-datepicker';

import { withController } from '@/lib/v2/HOCs/WithController';

import './TimeInput.tailwind.css';

export type eventOnChangeTimeInput = (time: Date | string) => void;

export interface TimeInputProps {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  error?: boolean;
  message?: string;
  isLoading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: eventOnChangeTimeInput;
  isRequired?: boolean;
  /** show skeleton loading */
  format?: string;
  date?: Date | null;
  minTime?: Date | undefined;
}

const TimeInput = ({
  onChange,
  id,
  label,
  name,
  placeholder = 'Select time',
  disabled = false,
  error,
  message,
  readOnly = false,
  isRequired,
  isLoading,
  minTime,
  format = 'h:mm a',
  date: initialDate,
}: TimeInputProps): JSX.Element => {
  const handleOnChange = (date: Date | null) => {
    if (date) {
      onChange && onChange(date);
    }
  };

  const maxTime = minTime ? new Date(minTime) : undefined;
  maxTime?.setHours(23, 59);

  const classesContainer = classNames('time-input--container');
  const classesMessage = classNames({ 'message-error': error });
  const classesContainerInput = classNames('relative rounded-md', {
    'time-input--container-error': error,
    readOnly: readOnly,
    'input-time-disabled': disabled,
  });

  if (isLoading) {
    return (
      <div className="flex w-full animate-pulse flex-col" data-testid="time-input-component">
        <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>
        <div className="my-1 h-[38px] w-full rounded bg-gray-200"></div>
      </div>
    );
  }

  return (
    <div className={classesContainer} {...(id && { id })} data-testid="time-input-component">
      {label && (
        <label className="label" htmlFor={name}>
          {label} {isRequired && <span className="text-red-500"> * </span>}
        </label>
      )}
      <div className={classesContainerInput} id="time-input-group">
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          dateFormat={format}
          disabled={disabled || readOnly}
          maxTime={maxTime}
          minTime={minTime}
          placeholderText={placeholder}
          selected={initialDate}
          timeIntervals={10}
          onChange={(date) => handleOnChange(date)}
        />
      </div>
      {error && (
        <p className={classesMessage} id={`${name}-message`}>
          {message}
        </p>
      )}
    </div>
  );
};

export default memo(withController(TimeInput));
