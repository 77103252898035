import { useAtomValue } from 'jotai';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityCleanFilterIcon } from '@/lib/icon';
import { Button, DateInput, Dropdown, Flex, IconSvg, InputSearch } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';
import { StateIcon } from '@/lib/v2/icons/outline';
import { ChannelIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { atomDetailCampaignRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomActionType,
  atomFiltersAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { SearchCampaigns } from '@/modules/CampaignsModule/components/SearchCampaigns';
import {
  DROPDOWN_ACTIONS_STATE_GROUPED,
  DROPDOWN_CHANNELS,
} from '@/modules/CampaignsModule/constants';
import { useFilterRecurrentActions } from '@/modules/CampaignsModule/hooks/useFilterRecurrentActions';
import {
  EActionTypes,
  EDateFilterType,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ECampaignsTypeOptions,
  EFilterKeysAction,
  IGroupedStateOption,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

const FilterBar = () => {
  const { t } = useTranslation();

  const campaignSearchAll = useFeatureFlag('campaignSearchAll');
  const { updateFilter, resetFilters } = useFilterRecurrentActions();

  const detailRecurrent = useAtomValue(atomDetailCampaignRecurrent);
  const filters = useAtomValue(atomFiltersAction);
  const actionType = useAtomValue(atomActionType);

  const [querySearch, setQuerySearch] = useState<string>(filters?.search ?? '');
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filters?.startDate ?? null,
    filters?.endDate ?? null,
  ]);

  useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) {
      setQuerySearch('');
      setDateRange([null, null]);
    }
  }, [filters]);

  const handleOnClickSearch = useCallback(() => {
    updateFilter(EFilterKeysAction.Search, querySearch);
  }, [querySearch, updateFilter]);

  const handleOnChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuerySearch(event.target.value);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setQuerySearch('');
    updateFilter(EFilterKeysAction.Search, '');
  }, [updateFilter]);

  const handleOnClickState = useCallback(
    (data: DropdownItemProps[]) => {
      const stateIDs: number[] = [];
      data.forEach((item) => {
        const category: IGroupedStateOption | undefined = DROPDOWN_ACTIONS_STATE_GROUPED.find(
          (cat) => cat.id === item.id
        );
        if (category) {
          stateIDs.push(...category.values);
        }
      });
      updateFilter(EFilterKeysAction.Status, stateIDs);
    },
    [updateFilter]
  );

  const handleOnClickChannel = useCallback(
    (data: DropdownItemProps[]) => {
      const channelID: number[] = data.map((item) => item.value as number);
      updateFilter(EFilterKeysAction.Channel, channelID);
    },
    [updateFilter]
  );

  const { dateFilterType, dateInputPlaceholderText } =
    actionType === EActionTypes.FINISHED
      ? {
          dateFilterType: EDateFilterType.EXE_DATE,
          dateInputPlaceholderText: t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.exeDate'),
        }
      : {
          dateFilterType: EDateFilterType.CREATION_DATE,
          dateInputPlaceholderText: t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.creationDate'),
        };

  const handleOnChangeDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        event[0] &&
          event[1] &&
          updateFilter(EFilterKeysAction.Date, [event[0], event[1]], dateFilterType);
      }
    },
    [dateFilterType, updateFilter]
  );

  const handleReset = useCallback(() => {
    setQuerySearch('');
    setDateRange([null, null]);
    resetFilters();
  }, [resetFilters]);

  return (
    <Flex withGap alignment="start" className="z-10 mb-[-72px] max-w-fit px-4">
      <div className="w-72">
        {campaignSearchAll ? (
          <SearchCampaigns />
        ) : (
          <InputSearch
            autoComplete="off"
            id="search-recurrent-action"
            placeHolder={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder')}
            value={querySearch}
            onAction={handleOnClickSearch}
            onChange={handleOnChangeSearch}
            onClear={handleOnClearSearch}
          />
        )}
      </div>
      {!actionType && (
        <Dropdown
          checkBox
          isScrollable
          buttonOnClick={handleOnClickState}
          buttonText={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.button')}
          dropdownItems={DROPDOWN_ACTIONS_STATE_GROUPED.map((state) => ({
            ...state,
            label: t(`CAMPAIGN_ACTIONS_MAIN.STATES.${state.label}`),
            checked: state.values.some((val) => filters?.status?.map(Number).includes(val)),
            value: state.values[0],
          })).sort((a, b) => a.label.localeCompare(b.label))}
          icon={<IconSvg svgComponent={<StateIcon />} />}
          id="state-campaigns-action"
          label={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.state')}
        />
      )}
      {(actionType || detailRecurrent?.campaignTypeId === ECampaignsTypeOptions.MANUAL) && (
        <Dropdown
          checkBox
          buttonOnClick={handleOnClickChannel}
          buttonText={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.button')}
          dropdownItems={DROPDOWN_CHANNELS.map((channel) => ({
            ...channel,
            label: t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${channel.label}`),
            checked: filters?.channel?.map(Number).includes(Number(channel?.value)),
          })).sort((a, b) => a.label.localeCompare(b.label))}
          icon={<IconSvg svgComponent={<ChannelIcon />} />}
          id="channels-campaigns-action"
          label={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.channel')}
        />
      )}
      {
        <DateInput
          isRequired
          selectsRange
          endDate={dateRange[1]}
          id="date-campaigns-action"
          maxDate={new Date()}
          name="date"
          placeholder={dateInputPlaceholderText}
          startDate={dateRange[0]}
          onChange={handleOnChangeDate}
        />
      }
      <Button
        standard
        disabled={Object.keys(filters ?? {}).length === 0}
        iconLeft={<IconSvg fillColor="primary" svgComponent={<ActivityCleanFilterIcon />} />}
        id="clear-filters-button"
        tooltip={t('CAMPAIGN_ACTIONS_MAIN.FILTERS.buttonClear')}
        onClick={handleReset}
      />
    </Flex>
  );
};

export default FilterBar;
