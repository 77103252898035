/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';

const payloadFromData = (data: any) => {
  const { payload, value } = data;

  // Standard payload format for useMessage
  if (payload) {
    return payload;
  }

  // Legacy v1 format for useMessage
  return { data: { value } };
};

const postMessage = (
  data: any,
  target: { postMessage: (arg0: any, arg1: string) => any },
  _origin = '*'
) => target?.postMessage(data, '*');

type WatchEvent = {
  origin: string;
  source: any;
  data: {
    type: string;
  };
};

const useMessage = (watch: any, eventHandler: (arg0: (data: any) => any, arg1: any) => void) => {
  const [history, setHistory] = useState([]);
  const [origin, setOrigin] = useState<string>();
  const [source, setSource] = useState();

  const originRef = useRef<string>();
  const sourceRef = useRef();

  originRef.current = origin;
  sourceRef.current = source;

  const sendToSender = (data: any) => postMessage(data, sourceRef.current!, originRef.current);

  const sendToParent = (data: any) => {
    const { opener } = window;
    if (!opener) throw new Error('Parent window has closed');
    postMessage(data, opener);
  };

  const onWatchEventHandler = useCallback(
    ({ origin, source, data }: WatchEvent) => {
      const { type } = data;
      const payload = payloadFromData(data);
      if (type === watch) {
        setSource(source);
        setOrigin(origin);
        setHistory((old): any => {
          return [...old, payload];
        });
        eventHandler(sendToSender, payload);
      }
    },
    [watch, eventHandler, setSource, setOrigin]
  );

  useEffect(() => {
    window.addEventListener('message', onWatchEventHandler);
    return () => window.removeEventListener('message', onWatchEventHandler);
  }, [watch, source, origin, onWatchEventHandler]);

  return { history, sendToParent };
};

export default useMessage;
