export const SearchIcon = ({ color = '#004dbc' }) => {
  return (
    <svg
      data-name="Group 7375"
      height="20"
      id="Group_7375"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3306" fill="none" height="20" id="Rectangle_3306" width="20" />
      <g data-name="Icon feather-search" id="Icon_feather-search" transform="translate(3 3)">
        <path
          d="M16.134,10.317A5.817,5.817,0,1,1,10.317,4.5,5.817,5.817,0,0,1,16.134,10.317Z"
          data-name="Traced 3816"
          fill="none"
          id="Traced_3816"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-4.5 -4.5)"
        />
        <path
          d="M28.453,28.453l-3.478-3.478"
          data-name="Traced 3817"
          fill="none"
          id="Traced_3817"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-14.392 -14.253)"
        />
      </g>
    </svg>
  );
};
