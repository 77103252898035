/* cspell:disable */
export const customFieldsListMock = {
  defaultFields: [
    {
      id: 1414527,
      name: 'email',
      type: 'email',
      metadata: {
        optionType: null,
        internalColumn: 'email',
        internalId: 1414527,
        internalNumber: 0,
        defaultField: true,
        fieldType: 'normal',
      },
    },
    {
      id: 1414511,
      name: 'nombre',
      type: 'string',
      metadata: {
        optionType: null,
        internalColumn: 'nombre',
        internalId: 1414511,
        internalNumber: 1,
        defaultField: true,
        fieldType: 'normal',
      },
    },
  ],
  customFields: [
    {
      id: 1446710,
      name: 'test',
      type: 'string',
      metadata: {
        optionType: null,
        internalColumn: 'test',
        internalId: 1446710,
        internalNumber: 26,
        defaultField: false,
        fieldType: 'normal',
      },
    },
    {
      id: 1642351,
      name: 'Multiple Options',
      type: 'string',
      metadata: {
        optionType: 'combo',
        defaultValues: ['opcion1', 'opcion2'],
        internalColumn: 'Multiple Options',
        internalId: 1642351,
        internalNumber: 27,
        defaultField: false,
        fieldType: 'normal',
      },
    },
  ],
  activityFields: [
    {
      name: 'lastSend',
      metadata: {
        internalColumn: 'ultimo_enviado',
      },
      type: 'date',
    },
    {
      name: 'lastOpening',
      metadata: {
        internalColumn: 'ultima_apertura',
      },
      type: 'date',
    },
    {
      name: 'lastClick',
      metadata: {
        internalColumn: 'ultimo_click',
      },
      type: 'date',
    },
  ],
};
/* cspell:enable */
