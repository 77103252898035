export enum EButtonType {
  TAG = 'tag',
  CUSTOM_FIELD = 'customField',
  EMOJI = 'emoji',
}

export interface ISubjectChangeButtonsProps {
  item: string;
  type: EButtonType;
}

export interface ISubjectButtonsProps {
  id: string;
  actionId: number;
  onChangeButtons: ({ item, type }: ISubjectChangeButtonsProps) => void;
  searchPlaceholder?: string;
  disabled?: boolean;
  vertical?: boolean;
  exclude?: EButtonType[];
}
