//TODO: will migrate those strings to a key/value object or an enum.

/* cspell:disable */
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import iconCustomFields from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconCustomFields.svg';
import iconFlow from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconFlow.svg';
import iconMargin from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconMargin.svg';
import iconNps from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconNps.svg';
import iconRemarketing from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconRemarketing.svg';
import iconSignatures from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconSignatures.svg';
import iconTags from '@/src/modules/CampaignsModule/components/HtmlUpload/icons/iconTags.svg';
import {
  getCurrentTagsSelection,
  getTagsFromLink,
} from '@/src/modules/CampaignsModule/components/HtmlUpload/util/tagsHelpers';

import { useCustomTags, useRemarketingTags, useSignatures, useWidgetsNps } from './index';
import useCustomFields from './useCustomFields';

import { htmlEditorAtom } from '@/modules/CampaignsModule/atoms/htmlEditorAtom';

export const useJoditCustomButtons = () => {
  const { t } = useTranslation(['editorHtml']);
  const tagsPopup = useCustomTags();
  const html = useAtomValue(htmlEditorAtom);
  const { signaturesNameList, getSignatureValue } = useSignatures();
  const { customFieldsNameList } = useCustomFields();
  const remarketingTagsPopup = useRemarketingTags();
  const { widgetsNameList, getWidgetModalInfo, isInsertWidgetActive } = useWidgetsNps();

  let currentEditor: any = null;
  let currentWidget: any = null;
  let currentElement: any = null;

  const selectIframeContent = () => {
    const iframe = document.querySelector('.jodit-wysiwyg_iframe') as HTMLIFrameElement;
    const iframeDocument = iframe.contentDocument || iframe.contentWindow!.document;
    return iframeDocument;
  };

  const joditCustomButtons = useCallback(
    () => ({
      events: {
        afterInit(editor: any) {
          if (editor) {
            currentEditor = editor;
            if (html) {
              currentEditor.value = `${html} `;
              currentEditor.value = html;
            }
            currentEditor.container.addEventListener('click', (e: any) => {
              currentElement = e.target;
              if (currentElement.getAttribute('type') === 'widgetNps') {
                const pencilButton = selectIframeContent().querySelectorAll('span[ref="pencil"]');
                const linkButton = selectIframeContent().querySelectorAll(
                  '.jodit-toolbar-button_link'
                );
                const unlinkButton = selectIframeContent().querySelectorAll(
                  '.jodit-toolbar-button_unlink'
                );
                pencilButton.forEach((element: any) => {
                  element.setAttribute(
                    'style',
                    'background-color: lightGray; cursor: not-allowed; pointer-events: none;'
                  );
                });
                linkButton.forEach((element: any) => {
                  element.setAttribute(
                    'style',
                    'background-color: lightGray; cursor: not-allowed; pointer-events: none;'
                  );
                });
                unlinkButton.forEach((element: any) => {
                  element.setAttribute(
                    'style',
                    'background-color: lightGray; cursor: not-allowed; pointer-events: none;'
                  );
                });
              } else {
                const pencilButton = selectIframeContent().querySelectorAll('span[ref="pencil"]');
                const linkButton = selectIframeContent().querySelectorAll(
                  '.jodit-toolbar-button_link'
                );
                const unlinkButton = selectIframeContent().querySelectorAll(
                  '.jodit-toolbar-button_unlink'
                );
                pencilButton.forEach((element: any) => {
                  element.setAttribute('style', 'pointer-events: auto;');
                });
                linkButton.forEach((element: any) => {
                  element.setAttribute('style', 'pointer-events: auto;');
                });
                unlinkButton.forEach((element: any) => {
                  element.setAttribute('style', 'pointer-events: auto;');
                });
              }
            });
          }
        },
        beforeCommand: (command: string) => {
          //TODO: migrate those strings to a key/value object or an enum.
          if (currentEditor) {
            if (
              command === 'backspacebutton' ||
              command === 'deletebutton' ||
              command === 'tablebin' ||
              command === 'tableempty' ||
              command === 'tablebinrow' ||
              command === 'tablebincol' ||
              command === 'tablebincolumn'
            ) {
              const selectedNode = currentEditor.selection.current();
              if (
                selectedNode?.previousElementSibling?.title === 'widgetNps' ||
                selectedNode?.nextElementSibling?.title === 'widgetNps' ||
                selectedNode?.wholeText === currentWidget?.title ||
                selectedNode?.wholeText === currentWidget?.description
              ) {
                const widgetNpsElements =
                  currentEditor.container.querySelectorAll('[type="widgetNps"]');
                widgetNpsElements.forEach((element: any) => {
                  element.remove();
                });
              }
            }
          }
        },
        change: (newHtml: string, prevHtml: string) => {
          if (currentEditor) {
            if (currentEditor.container) {
              const widgetName =
                currentEditor.container.querySelector('table[type="widgetNps"]')?.id;
              if (widgetName && !currentWidget) {
                // eslint-disable-next-line promise/always-return
                void getWidgetModalInfo(widgetName).then(({ widget }) => {
                  currentWidget = widget;
                });
              }

              const links = currentEditor.container.querySelectorAll('a[type="widgetNps"]');
              links.forEach((link: any) => {
                if (link.getAttribute('href') !== link.getAttribute('data-href')) {
                  link.setAttribute('href', link.getAttribute('data-href'));
                }
              });

              const widgetNpsElements =
                currentEditor.container.querySelectorAll('[type="widgetNps"]');
              let isWidgetNps = widgetNpsElements.length > 0;

              if (isWidgetNps) {
                const newLinks =
                  newHtml?.includes('widgetNps') &&
                  currentEditor.create.fromHTML(newHtml)?.querySelectorAll('a[type="widgetNps"]');
                const prevLinks =
                  prevHtml?.includes('widgetNps') &&
                  currentEditor.create.fromHTML(prevHtml)?.querySelectorAll('a[type="widgetNps"]');

                const newImages =
                  newHtml?.includes('widgetNps') &&
                  currentEditor.create.fromHTML(newHtml)?.querySelectorAll('img[type="widgetNps"]');
                const prevImages =
                  prevHtml?.includes('widgetNps') &&
                  currentEditor.create
                    .fromHTML(prevHtml)
                    ?.querySelectorAll('img[type="widgetNps"]');

                if (
                  newLinks?.length < prevLinks?.length ||
                  newImages?.length < prevImages?.length
                ) {
                  widgetNpsElements.forEach((element: any) => {
                    element.remove();
                    isWidgetNps = false;
                  });
                }
              }
            }
          }
        },
      },
      inlinePopup: {
        buttons: ['link'],
      },
      controls: {
        tableMargin: {
          name: t('showHideMargins'),
          tooltip: t('showHideMargins'),
          iconURL: iconMargin,
          exec() {
            const iframeDocument = selectIframeContent();
            const elementJodit = iframeDocument.body;

            if (elementJodit) {
              elementJodit.classList.toggle('jodit-wysiwyg');
              elementJodit.style.overflowY = 'scroll';
            }
          },
          isActive: (editor: any) => {
            const current = editor.s.current();
            return Boolean(current?.parentElement?.closest('.jodit-wysiwyg'));
          },
        },
        customFields: {
          name: t('customizedFields'),
          tooltip: t('customizedFields'),
          iconURL: iconCustomFields,
          list: customFieldsNameList.map((item) => item.name),
          exec(editor: any, _: any, { control }: any) {
            const customFieldNumbers = customFieldsNameList.reduce((acc: any, item) => {
              acc[item.name] = item.number;
              return acc;
            }, {});
            const fieldName: string = control.args && control.args[0];
            if (fieldName !== undefined) {
              const fieldNumber: string = customFieldNumbers[fieldName];
              if (fieldNumber !== undefined) {
                editor.s.insertHTML('${' + fieldNumber + '#' + fieldName + '}');
                editor.setEditorValue();
              }
            }
            return false;
          },
        },
        signatures: {
          name: t('signatures'),
          tooltip: t('signatures'),
          iconURL: iconSignatures,
          list: signaturesNameList,
          exec(editor: any, _: any, { control }: any) {
            const signatureName = control.args && control.args[0];
            if (signatureName !== undefined) {
              const signatureValue = getSignatureValue(signatureName);
              editor.s.insertHTML(`$FE{${signatureValue}}`);
              editor.setEditorValue();
            }
            return false;
          },
        },
        flowField: {
          name: t('flowField'),
          tooltip: t('flowField'),
          iconURL: iconFlow,
          exec(editor: any, _: any, { control }: any) {
            editor.s.insertHTML('$BF{variable}');
            editor.setEditorValue();
            return false;
          },
        },
        tags: {
          name: t('TagsRules'),
          tooltip: t('TagsRules'),
          iconURL: iconTags,
          popup: (editor: any, current: any, close: any, button: any) => {
            const NoTagAssignPopupContent = editor.create.fromHTML(
              `<p><em>${t('TAGS.nonExistentTags')}</em></p>`
            );
            const popupContent = editor.create.fromHTML(tagsPopup);
            const tagButtons = popupContent.querySelectorAll('.tagButton');

            if (tagButtons && tagButtons.length === 0) return NoTagAssignPopupContent;

            if (current) {
              try {
                if (
                  current.parentElement.nodeName === 'A' ||
                  current.parentElement.closest('a') ||
                  current.nodeName === 'A'
                ) {
                  // Get the correct selected link
                  const linkSelected =
                    current.nodeName === 'A'
                      ? current
                      : current.parentElement.nodeName === 'A'
                      ? current.parentElement
                      : current.parentElement.closest('a');

                  // Get existing tags from linkSelected and mark them active in the popup
                  let linkTags = getTagsFromLink(linkSelected, popupContent);

                  // Assign functions to tags buttons
                  tagButtons?.forEach((tagButton: any) => {
                    tagButton.addEventListener('click', function () {
                      const tagIdSelected = tagButton.id;

                      linkTags = getCurrentTagsSelection(linkTags!, tagIdSelected, tagButton);

                      linkTags.length > 0
                        ? linkSelected.setAttribute('tags', linkTags.join(';'))
                        : linkSelected.removeAttribute('tags');

                      return false;
                    });
                  });
                  return popupContent;
                }
              } catch (error: any) {
                NoTagAssignPopupContent;
                throw new Error(error.message);
              }
            }

            // Assign functions to tags buttons
            tagButtons.forEach((tagButton: any) => {
              tagButton.addEventListener('click', function (e: any) {
                const tagId = tagButton.id;
                const tagName = tagButton.title.trim();
                const tagContent = '$T' + `{${tagId}#${tagName}}`;

                editor.s.insertHTML(tagContent);
                editor.setEditorValue();
                close();
              });
            });
            return popupContent;
          },
        },
        remarketing: {
          name: t('remarketing'),
          tooltip: t('remarketing'),
          iconURL: iconRemarketing,
          popup: (editor: any, current: any, close: any, button: any) => {
            const popupContent = editor.create.fromHTML(remarketingTagsPopup);
            const remarketingTagButtons = popupContent.querySelectorAll('.remarketingTag');
            const staticTagButtons = popupContent.querySelectorAll('.staticTag');

            const insertTagContent = (e: any) => {
              const tagSelected = e.target.closest('li');
              const tagType = tagSelected.getAttribute('data-type');
              const tagId = tagSelected.getAttribute('id');
              const tagName = tagSelected.textContent.trim();
              const tagContent =
                (tagType === 'remarketing' ? '$RT' : '$T') + `{${tagId}#${tagName}}`;

              editor.s.insertHTML(tagContent);
              editor.setEditorValue();
              close();
            };

            remarketingTagButtons.forEach((rmktTagButton: any) => {
              rmktTagButton.addEventListener('click', insertTagContent);
            });
            staticTagButtons.forEach((staticTagButton: any) => {
              staticTagButton.addEventListener('click', insertTagContent);
            });

            return popupContent;
          },
        },
        insertWigetNps: {
          name: t('NPS.widgetNps'),
          tooltip:
            widgetsNameList && widgetsNameList.length > 0
              ? t('NPS.widgetNpsTooltip')
              : t('NPS.widgetNpsTooltip2'),
          iconURL: iconNps,
          ...(widgetsNameList && widgetsNameList.length > 0 && { list: widgetsNameList }),
          ...((!widgetsNameList || widgetsNameList.length <= 0) && {
            popup: (editor: any, current: any, close: any, button: any) => {
              //popup content
              const createPopupHTML = () => {
                return editor.create.fromHTML(`
                                <div class="bg-bluePrimary p-2 rounded-md m-0">
                                    <p class="text-white">${t('NPS.widgetNpsTooltip2')}</p>
                                </div>
                            `);
              };
              const popupHTML = createPopupHTML();
              return popupHTML;
            },
          }),
          ...(widgetsNameList &&
            widgetsNameList.length > 0 && {
              async exec(editor: any, _: any, { control }: any) {
                // Get widget info
                const widgetName = control.args && control.args[0];
                const {
                  contentSelector,
                  error,
                  traditionalWidget,
                  smileyWidget,
                  defaultOnErrorImage,
                } = await getWidgetModalInfo(widgetName);

                const widgetNpsAdded =
                  selectIframeContent().querySelectorAll('[type="widgetNps"]').length > 0;

                // Show popup error if widget is already added
                if (widgetNpsAdded && widgetName !== undefined) {
                  // eslint-disable-next-line no-var
                  var dialog = editor.getInstance('Dialog');
                  dialog.setContent(error);
                  dialog.open();
                  setTimeout(() => {
                    dialog.close();
                  }, 3000);
                }

                // Show popup widget type selector if widget is not added
                if (!widgetNpsAdded && widgetName !== undefined) {
                  // eslint-disable-next-line no-var
                  var dialog = editor.getInstance('Dialog');
                  dialog.setContent(contentSelector);
                  dialog.open();
                }

                const insertwidgetContent = (e: any) => {
                  const target =
                    e.target.closest('.traditionalWidget') || e.target.closest('.smileyWidget');
                  const targetId = target.getAttribute('id');

                  if (targetId === 'smiley') {
                    editor.s.insertHTML(smileyWidget);
                    editor.setEditorValue();
                    dialog.close();
                  }

                  if (targetId === 'traditional') {
                    editor.s.insertHTML(traditionalWidget);
                    editor.setEditorValue();
                    dialog.close();
                  }
                };

                // Add button functionality and remove unnecessary elements from editor JODIT
                const traditionalSelector = document.querySelectorAll('.traditionalWidget');
                const onErrorImage = document.querySelectorAll('img[title="onErrorImage"]');
                const smileySelector = document.querySelectorAll('.smileyWidget');
                const closeButton = document.querySelectorAll('.closeButton');
                const resizerElements = document.querySelectorAll('.jodit-dialog__resizer');
                const headerElements = document.querySelectorAll('.jodit-dialog__header');
                const panelElements = document.querySelectorAll('.jodit-dialog__panel');

                traditionalSelector?.forEach((element: any) => {
                  element.addEventListener('click', insertwidgetContent);
                });
                onErrorImage &&
                  onErrorImage.forEach((element: any) => {
                    element.addEventListener('error', () => defaultOnErrorImage(element));
                  });
                smileySelector?.forEach((element: any) => {
                  element.addEventListener('click', insertwidgetContent);
                });
                closeButton?.forEach((element: any) => {
                  element.addEventListener('click', () => {
                    dialog.close();
                  });
                });
                resizerElements?.forEach((resizerElement: any) => {
                  resizerElement.style.display = 'none';
                });
                headerElements?.forEach((headerElements: any) => {
                  headerElements.style.display = 'none';
                });
                panelElements?.forEach((panelElements: any) => {
                  panelElements.style.background = 'transparent';
                  panelElements.style.boxShadow = 'none';
                });
                // Finish event listeners

                return false;
              },
            }),
        },
      },
      extraButtons: [
        'tableMargin',
        'customFields',
        'tags',
        'remarketing',
        'signatures',
        'flowField',
        'insertWigetNps',
      ].filter(
        (button) =>
          (isInsertWidgetActive && widgetsNameList.length > 0) || button !== 'insertWigetNps'
      ),
    }),
    [
      tagsPopup,
      signaturesNameList,
      getSignatureValue,
      customFieldsNameList,
      getWidgetModalInfo,
      isInsertWidgetActive,
    ]
  );
  return joditCustomButtons();
};
