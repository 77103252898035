import { useAtom } from 'jotai';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupPatternIcon, TagPatternIcon } from '@/lib/icon';
import { RadioButton, Text } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import Select, { Option } from '@/lib/v2/components/Select/Select';

import {
  ProgressCard,
  ProgressCardProps,
} from '@/src/ContactsModule/components/ContactsViewInfo/ProgressCard';
import { getDataCardGroups } from '@/src/ContactsModule/contexts/ImportFileContext/DataCard';
import { IGroup, IGroupList } from '@/src/ContactsModule/interfaces/Interfaces';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import {
  atomStep4CreateGroup,
  atomStep4Groups,
  atomStep4Tags,
} from '@/modules/ContactsModule/atoms/Step4';
import { atomFiles, atomShowProgressNotification } from '@/modules/ContactsModule/atoms/Steps';
import { useGroup } from '@/modules/ContactsModule/hooks/useGroup';

export const ContactsTagsAndGroups = memo(
  ({
    selectedTags,
    setSelectedTags,
    selectedGroups,
    setSelectedGroups,
    withTag,
    showGroupError = false,
  }: {
    withTag?: boolean;
    selectedTags?: Option[];
    setSelectedTags?: Dispatch<SetStateAction<Option[]>>;
    selectedGroups: Option[];
    setSelectedGroups: Dispatch<SetStateAction<Option[]>>;
    showGroupError?: boolean;
  }) => {
    const service = useService();
    const [tagsOptions, setTagsOption] = useAtom(atomStep4Tags);
    const [isCreateGroup, setIsCreateGroup] = useAtom(atomStep4CreateGroup);
    const [groupOptions, setGroupOptions] = useAtom(atomStep4Groups);
    const [showProgressNotification] = useAtom(atomShowProgressNotification);

    const { t } = useTranslation();
    const { createGroup } = useGroup();
    const [files] = useAtom(atomFiles);

    const progressCard: ProgressCardProps = useMemo(
      () => ({
        title: t('IMPORT_MAIN.importingList'),
        dataCard: getDataCardGroups(t),
        status: showProgressNotification.status,
        step: showProgressNotification.step,
        percentage: showProgressNotification.percentage,
        mode: showProgressNotification.velocity,
      }),
      [showProgressNotification, t]
    );

    const show = useMemo(() => files.showStateCard, [files.showStateCard]);

    const getAndFormatTagsOptions = useCallback(async () => {
      const tags = await service?.getTagsDropDown({ identifier: 'TAGS_DROP_DOWN' });

      if (tags) {
        const tagListMapping: Option[] = tags.list.map((tag: { id: any; name: any }) => {
          return { id: tag.id, name: tag.name, value: tag.name } as Option;
        });
        setTagsOption(tagListMapping);
      }
    }, [service, setTagsOption]);

    const getAndFormatGroupsOptions = useCallback(async () => {
      const groups: IGroupList = await service?.getGroups({ identifier: 'GROUPS_DROP_DOWN' });

      if (groups) {
        const groupsMapping: Option[] = groups.list
          .map(({ group }: { group: IGroup }) => {
            if (!group.id) return;
            return {
              id: group.id,
              name: group.groupName,
              value: group.groupName,
            } as Option;
          })
          .filter((group): group is Option => group !== undefined);
        setGroupOptions(groupsMapping);
        return groupsMapping;
      }
    }, [service, setGroupOptions]);

    useEffect(() => {
      if (tagsOptions.length === 0 && groupOptions.length === 0) {
        void getAndFormatTagsOptions();
        void getAndFormatGroupsOptions();
      }
    }, [
      getAndFormatTagsOptions,
      getAndFormatGroupsOptions,
      tagsOptions.length,
      groupOptions.length,
    ]);

    const onHandleChangeTags = useCallback(
      (val: Option | Option[]) => {
        if (setSelectedTags) {
          if (Array.isArray(val) && val.length === 0) {
            setSelectedTags([{ id: 0, name: t('IMPORT_MAIN.noTags') } as Option]);
          } else if (Array.isArray(val)) {
            const filterTags = val.filter((tag) => tag.id !== 0);
            setSelectedTags(filterTags);
          } else {
            setSelectedTags([val]);
          }
        }
      },
      [setSelectedTags, t]
    );

    const onHandleChangeGroups = useCallback(
      (val: Option | Option[]) => {
        if (Array.isArray(val) && val.length === 0) {
          setSelectedGroups([]);
        } else if (Array.isArray(val)) {
          const filterGroups = val.filter((group) => group.id !== 0);
          setSelectedGroups(filterGroups);
        } else {
          setSelectedGroups([val]);
        }
      },
      [setSelectedGroups]
    );

    const onHandleCreateGroup = useCallback(
      async (value: string) => {
        const optionForCreate = value.trim();
        if (optionForCreate === '') return;

        const groupCreated = await createGroup(optionForCreate);

        if (groupCreated) {
          const newOptions = await getAndFormatGroupsOptions();

          const newItem = newOptions?.find((item) => item.name === optionForCreate) as Option;

          const data = [...selectedGroups, newItem];

          setSelectedGroups(data);
        }
      },
      [selectedGroups, createGroup, getAndFormatGroupsOptions, setSelectedGroups]
    );

    return (
      <div className="size-full p-2">
        <Divide>
          {!show ? (
            <Flex column withGap gapSize="small" itemAlignment="start">
              {withTag && selectedTags && setSelectedTags && (
                <div className="w-full">
                  <Flex column itemAlignment="start">
                    <Text variant="headline">{t('IMPORT_MAIN.tags')}</Text>
                  </Flex>
                  <div className="w-full p-8">
                    <Flex withGap gapSize="large" itemAlignment="start">
                      <Text as={'h1'} color="black" fontWeight="medium">
                        <div className="w-52">
                          <TagPatternIcon />
                        </div>
                      </Text>
                      <div className="max-w-xs">
                        <Text as="p">{t('IMPORT_MAIN.tagsDescription')}</Text>
                      </div>
                      <div className="w-full">
                        <Select
                          multiSelect
                          id="import-groups-select"
                          label={t('IMPORT_MAIN.tagSelectValueLabel')}
                          options={tagsOptions || []}
                          orderBy="asc"
                          placeholder={t('IMPORT_MAIN.tagSelectPlaceholder')}
                          value={selectedTags}
                          onChange={onHandleChangeTags}
                        />
                      </div>
                    </Flex>
                  </div>
                </div>
              )}
              <div className="w-full">
                <div className="w-full p-8">
                  <Flex withGap gapSize="large" itemAlignment="start">
                    <Flex column>
                      <div className="mb-6 w-full text-justify">
                        <Text
                          alignment="justify"
                          as="p"
                          color="primary"
                          fontWeight="medium"
                          variant="sub-headline"
                        >
                          {t('IMPORT_MAIN.groupsTitle')}
                          <br />
                          {t('IMPORT_MAIN.groupsSubtitle')}
                        </Text>
                      </div>

                      <Flex column>
                        <Flex withGap alignment="start" className="mb-4" itemAlignment="start">
                          <RadioButton
                            checked={!isCreateGroup}
                            name="assignGroup"
                            onChange={() => setIsCreateGroup(false)}
                          />

                          <Text fontWeight="medium" variant="text">
                            {t('IMPORT_MAIN.groupLaterCreate')}
                          </Text>
                        </Flex>

                        <Flex withGap alignment="start" className="mb-4" itemAlignment="start">
                          <RadioButton
                            checked={isCreateGroup}
                            name="assignGroup"
                            onChange={() => setIsCreateGroup(true)}
                          />
                          <Text fontWeight="medium" variant="text">
                            {t('IMPORT_MAIN.assignGroup')}
                          </Text>
                        </Flex>
                      </Flex>

                      <div className={`w-full ${isCreateGroup ? '' : 'hidden'}`}>
                        <Select
                          multiSelect
                          withInputCreate
                          withSearch
                          id="import-group-select"
                          label={t('IMPORT_MAIN.groupSelectValueLabel')}
                          labelInputCreate={t('CONTACT_GROUP_MODAL.createGroup')}
                          options={groupOptions || []}
                          orderBy="asc"
                          placeHolderInputCreate={t('IMPORT_MAIN.groupCreateSelectPlaceholder')}
                          placeholder={t('IMPORT_MAIN.groupSelectPlaceholder')}
                          searchPlaceholder={t('IMPORT_MAIN.search')}
                          value={selectedGroups}
                          onChange={onHandleChangeGroups}
                          onCreate={onHandleCreateGroup}
                        />

                        {showGroupError && (
                          <Text color="alert" variant="text">
                            {t('IMPORT_MAIN.groupsRequired')}
                          </Text>
                        )}
                      </div>
                    </Flex>
                    <Flex alignment="center" className="justify-center">
                      <div className="w-52">
                        <GroupPatternIcon />
                      </div>
                    </Flex>
                  </Flex>
                </div>
              </div>
            </Flex>
          ) : (
            <div className="m-auto w-1/2 py-8">
              <ProgressCard progressCard={progressCard} />
            </div>
          )}
        </Divide>
      </div>
    );
  }
);
