const SidebarSkeleton = () => {
  return (
    <div className="w-full">
      <section className="animate-pulse">
        <div className="mt-6">
          <div className="mb-6 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="mb-2 h-3 w-auto rounded-full bg-gray-300 "></div>
          <div className="flex w-full gap-2">
            <div className="h-3 w-32 rounded-full bg-gray-300 "></div>
            <div className="h-3 w-20 rounded-full bg-gray-300 "></div>
          </div>
        </div>
        <div className="mt-6">
          <div className="mb-6 h-3 w-200 rounded-full bg-gray-300"></div>
          <div className="mb-2 h-3 w-auto rounded-full bg-gray-300 "></div>
          <div className="flex w-full gap-2">
            <div className="h-3 w-32 rounded-full bg-gray-300 "></div>
            <div className="h-3 w-20 rounded-full bg-gray-300 "></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SidebarSkeleton;
