/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { ICustomFieldsResponse } from '@/src/infrastructure/interfaces/ICustomFields';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { ICustomFieldsService, IEmblueApiMethodNameMap } from './ICustomFieldsService';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class CustomFieldsService implements ICustomFieldsService {
  private api: APIService;
  private pathContacts = 'api/v2.1/contacts';

  private constructor(api: APIService) {
    this.api = api;
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error CustomFieldsService[${method}]:`, message);
  }

  /**
   * @param api APIService
   * @returns {ICustomFieldsService}
   * @description Create new instance of CampaignsService
   */
  static create(api: APIService): ICustomFieldsService {
    return new CustomFieldsService(api);
  }

  /**
   * Fetches custom fields from the API.
   *
   * @returns {Promise<ICustomFieldsResponse>} A promise that resolves to the custom fields response.
   * @throws {AxiosError<Error>} If there is an error fetching the custom fields, the error is caught and handled.
   */
  async getCustomFields(): Promise<ICustomFieldsResponse> {
    try {
      const { data } = await this.api.get<ICustomFieldsResponse>(`${this.pathContacts}/fields`);
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCustomFields');
      return {} as ICustomFieldsResponse;
    }
  }
}

export const CustomFieldsServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  CustomFieldsService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof CustomFieldsService);
