export const FileWritten = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#CFF6FE]">
      <svg
        className="relative left-2.5"
        data-name="Group 7362"
        height="55%"
        id="Group_7362"
        viewBox="0 0 58.443 51.591"
        width="55%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.517,13.064V0H2.305A2.3,2.3,0,0,0,0,2.305V46.877a2.3,2.3,0,0,0,2.305,2.305H34.582a2.3,2.3,0,0,0,2.305-2.305V15.37H23.823A2.312,2.312,0,0,1,21.517,13.064Zm6.148,22.67a1.156,1.156,0,0,1-1.153,1.153H10.374a1.156,1.156,0,0,1-1.153-1.153v-.768a1.156,1.156,0,0,1,1.153-1.153H26.512a1.156,1.156,0,0,1,1.153,1.153Zm0-6.148a1.156,1.156,0,0,1-1.153,1.153H10.374a1.156,1.156,0,0,1-1.153-1.153v-.768a1.156,1.156,0,0,1,1.153-1.153H26.512a1.156,1.156,0,0,1,1.153,1.153Zm0-6.916v.768a1.156,1.156,0,0,1-1.153,1.153H10.374a1.156,1.156,0,0,1-1.153-1.153V22.67a1.156,1.156,0,0,1,1.153-1.153H26.512A1.156,1.156,0,0,1,27.665,22.67Zm9.222-10.96V12.3h-12.3V0h.586A2.3,2.3,0,0,1,26.81.672l9.4,9.414A2.3,2.3,0,0,1,36.887,11.71Z"
          data-name="Icon awesome-file-alt"
          fill="#008bd0"
          id="Icon_awesome-file-alt"
          transform="translate(0)"
        />
        <g data-name="Group 7352" id="Group_7352" transform="translate(28.141 21.628)">
          <g data-name="Icon feather-edit-3" id="Icon_feather-edit-3" transform="translate(0 0)">
            <g
              data-name="Path 3465"
              fill="#008bd0"
              id="Path_3465"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-4.5 -4.318)"
            >
              <path
                d="M 2.432297468185425 33.33560943603516 L 3.045867443084717 30.91197967529297 L 4.640547275543213 24.6129093170166 L 4.74317741394043 24.20755004882812 L 5.040697574615479 23.91374015808105 L 24.97410774230957 4.229160308837891 C 25.89560699462891 3.319170236587524 27.11941719055176 2.818030118942261 28.42010688781738 2.818030118942261 C 29.72078704833984 2.818030118942261 30.9445972442627 3.319170236587524 31.86608695983887 4.229160308837891 C 32.79263687133789 5.144140243530273 33.30290603637695 6.362070083618164 33.30290603637695 7.658600330352783 C 33.30291748046875 8.955130577087402 32.79264831542969 10.17306995391846 31.86609649658203 11.08806037902832 L 11.93267726898193 30.77264976501465 L 11.63896751403809 31.06269073486328 L 11.2382173538208 31.16163063049316 L 4.859517574310303 32.73638916015625 L 2.432297468185425 33.33560943603516 Z"
                stroke="none"
              />
              <path
                d="M 28.42010116577148 4.318016052246094 C 27.55436515808105 4.318016052246094 26.68862724304199 4.644159317016602 26.0280876159668 5.296449661254883 L 6.094676971435547 24.98104095458984 L 4.499996185302734 31.28009986877441 L 10.87868690490723 29.7053394317627 L 30.81210708618164 10.02075958251953 C 32.1331787109375 8.716180801391602 32.1331787109375 6.601030349731445 30.81210708618164 5.296449661254883 C 30.15157318115234 4.644159317016602 29.28583717346191 4.318016052246094 28.42010116577148 4.318016052246094 M 28.42009735107422 1.318008422851562 C 30.11788749694824 1.318008422851562 31.71600723266602 1.972831726074219 32.92005920410156 3.161838531494141 C 34.13423919677734 4.360870361328125 34.80290603637695 5.957839965820312 34.80290603637695 7.658599853515625 C 34.80290603637695 9.359369277954102 34.13423919677734 10.95634078979492 32.92006683349609 12.15535926818848 L 12.98663711547852 31.83993911743164 C 12.60217666625977 32.21961212158203 12.12231826782227 32.4883918762207 11.59773826599121 32.61788940429688 L 5.219047546386719 34.19264984130859 C 4.195949554443359 34.44523239135742 3.1153564453125 34.14248657226562 2.372417449951172 33.3951416015625 C 1.629467010498047 32.64778900146484 1.333118438720703 31.56542015075684 1.591747283935547 30.5438404083252 L 3.186428070068359 24.24478149414062 C 3.320697784423828 23.71440887451172 3.597438812255859 23.23085975646973 3.986717224121094 22.84643936157227 L 23.92012786865234 3.161849975585938 C 25.12418746948242 1.972831726074219 26.7223072052002 1.318008422851562 28.42009735107422 1.318008422851562 Z"
                fill="#cce8f6"
                stroke="none"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
