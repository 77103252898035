export const UserIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7494" id="Group_7494" transform="translate(0 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0 -0.163)"
          width="20"
        />
        <g data-name="Group 6604" id="Group_6604" transform="translate(3 -1.003)">
          <g data-name="Group 6567" id="Group_6567" transform="translate(13.473 15.267)">
            <path
              d="M-1225.1,99.2a5.24,5.24,0,0,0,.324.756h0C-1224.884,99.74-1224.992,99.416-1225.1,99.2Z"
              data-name="Traced 3928"
              id="Traced_3928"
              transform="translate(1225.1 -99.2)"
            />
          </g>
          <g data-name="Group 6568" id="Group_6568" transform="translate(10.123 11.809)">
            <path
              d="M-1228.2,96Z"
              data-name="Traced 3929"
              id="Traced_3929"
              transform="translate(1228.2 -96)"
            />
          </g>
          <g data-name="Group 6569" id="Group_6569" transform="translate(10.446 11.701)">
            <path
              d="M-1227.9,95.9a.4.4,0,0,1,.324.108.4.4,0,0,0-.324-.108Z"
              data-name="Traced 3930"
              id="Traced_3930"
              transform="translate(1227.9 -95.9)"
            />
          </g>
          <g data-name="Group 6573" id="Group_6573" transform="translate(2.558 2.84)">
            <circle
              cx="4.431"
              cy="4.431"
              data-name="Ellipse 1742"
              id="Ellipse_1742"
              r="4.431"
              transform="translate(0 0)"
            />
          </g>
          <g data-name="Group 6574" id="Group_6574" transform="translate(0 11.621)">
            <path
              d="M-1223.662,100.769a8.685,8.685,0,0,0-3.134-4.863.689.689,0,0,0-.648,0,5.535,5.535,0,0,1-6.268,0,.689.689,0,0,0-.648,0,8.365,8.365,0,0,0-3.134,4.863s-.648,2.594,1.945,2.594h9.942C-1223.013,103.363-1223.662,100.769-1223.662,100.769Z"
              data-name="Traced 3933"
              id="Traced_3933"
              transform="translate(1237.567 -95.825)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
