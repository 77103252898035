import { Card } from '@/lib/index';
import { IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { ImageIcon } from '@/lib/v2/icons/general/ImageIcon';

export type ItemPreviewProps = {
  name: string;
  url: string;
  image: string;
};

export const ItemPreview = ({ name, url, image }: ItemPreviewProps) => {
  return (
    <Card secondary>
      <Flex column withGap>
        {image ? (
          <img alt={name} className="h-80 w-96 rounded-lg object-contain" src={image} />
        ) : (
          <div className="h-80 w-96 rounded-lg">
            <Flex alignment="center" itemAlignment="center">
              <IconSvg height="92px" svgComponent={<ImageIcon />} width="128px" />
            </Flex>
          </div>
        )}
        <Flex column withGap itemAlignment="start">
          <Text as="h2" color="primary" fontWeight="bold" variant="headline">
            {' '}
            {name}{' '}
          </Text>
          <Text as="span" className="w-full" fontWeight="medium" variant="text">
            {' '}
            <a className="break-words" href={url} rel="noopener noreferrer" target="_blank">
              {' '}
              {url}{' '}
            </a>{' '}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
