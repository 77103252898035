import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components/Select/Select';

import { ContactStatus } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { ListProfile } from '@/src/modules/ContactsModule/types/Segments';
import {
  mapValuesOptionsChannels,
  mapValuesOptionsEcommerceGroups,
  mapValuesOptionsListProfiles,
  mapValuesOptionsNotAddressees,
  mapValuesOptionsTags,
  mapValuesOptionsUserGroups,
} from '@/src/modules/ContactsModule/util/mapsValuesToOptions';

export const useGetListValuesOptions = () => {
  const { t } = useTranslation();
  const [userGroupsOptions, setUserGroupsOptions] = useState<Option[]>();
  const [tagsOptions, setTagsOptions] = useState<Option[]>();
  const [notAddresseesOptions, setNotAddresseesOptions] = useState<Option[]>();
  const [channelsOptions, setChannelsOptions] = useState<Option[]>();
  const [geoLocalizationOptions, setGeoLocalizationOptions] = useState<Option[]>();
  const [devicesOptions, setDevicesOptions] = useState<Option[]>();
  const [socialNetworkOptions, setSocialNetworkOptions] = useState<Option[]>();
  const [genderOptions, setGenderOptions] = useState<Option[]>();
  const [ecommerceGroupsOptions, setEcommerceGroupsOptions] = useState<Option[]>();

  const rankList: Option[] = useMemo(
    () => [
      {
        id: ContactStatus.discarded,
        name: t('STATUS_FILTER.discarded'),
        value: ContactStatus.discarded,
      },
      {
        id: ContactStatus.inactive,
        name: t('STATUS_FILTER.inactive'),
        value: ContactStatus.inactive,
      },
      { id: ContactStatus.new, name: t('STATUS_FILTER.new'), value: ContactStatus.new },
      {
        id: ContactStatus.frequent,
        name: t('STATUS_FILTER.frequent'),
        value: ContactStatus.frequent,
      },
      { id: ContactStatus.asleep, name: t('STATUS_FILTER.asleep'), value: ContactStatus.asleep },
    ],
    [t]
  );

  const [valuesOptions, , valuesOptionsIsLoading] = useEmblue(
    ServiceMethods.getValuesSegmentsFiltersContacts
  );

  const devicesAdapter = (data: ListProfile[]) => {
    /* cspell:disable */
    let devices = mapValuesOptionsListProfiles(data, 'dispositivos');

    if (Array.isArray(devices)) {
      const dictionary = {
        escritorio: 'desktop',
        movil: 'mobile',
        tablet: 'tablet',
      };
      devices = devices.map((item) => {
        const name = (item.name as string).toLowerCase() as keyof typeof dictionary;
        return {
          ...item,
          name: t(`SEGMENTS_CONDITIONS.${dictionary[name]}`),
        };
      });
    }

    return devices;
  };

  const genderAdapter = (data: ListProfile[]) => {
    const gender = mapValuesOptionsListProfiles(data, 'sexo');

    return gender.map((item) => {
      const dictionary = {
        femenino: 'female',
        masculino: 'male',
      };
      const name = (item.name as string).toLowerCase() as keyof typeof dictionary;
      return {
        ...item,
        name: t(`SEGMENTS_CONDITIONS.${dictionary[name]}`),
      };
    });
  };

  useEffect(() => {
    if (!valuesOptions) return;
    const { userGroups, tags, channels, notAddressees, listProfiles, ecommerceGroups } =
      valuesOptions;

    setUserGroupsOptions(mapValuesOptionsUserGroups(userGroups));
    setTagsOptions(mapValuesOptionsTags(tags));
    setChannelsOptions(mapValuesOptionsChannels(channels));
    setNotAddresseesOptions(mapValuesOptionsNotAddressees(notAddressees));
    setGeoLocalizationOptions(mapValuesOptionsListProfiles(listProfiles, 'paises'));
    setDevicesOptions(devicesAdapter(listProfiles));
    setSocialNetworkOptions(mapValuesOptionsListProfiles(listProfiles, 'redes_sociales'));
    setGenderOptions(genderAdapter(listProfiles));
    /* cspell:enable */
    setEcommerceGroupsOptions(mapValuesOptionsEcommerceGroups(ecommerceGroups));
  }, [t, valuesOptions]);

  return {
    isLoading: valuesOptionsIsLoading,
    userGroups: userGroupsOptions,
    tags: tagsOptions,
    channels: channelsOptions,
    notAddressees: notAddresseesOptions,
    getLocalization: geoLocalizationOptions,
    devices: devicesOptions,
    socialNetwork: socialNetworkOptions,
    rank: rankList,
    gender: genderOptions,
    ecommerceGroups: ecommerceGroupsOptions,
  };
};
