export const ActivityIcon = () => {
  return (
    <svg
      data-name="Group 6143"
      height="20"
      id="Group_6143"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3179" fill="none" height="20" id="Rectangle_3179" width="20" />
      <g id="activity" transform="translate(2.424 1.952)">
        <path
          d="M5.245,12.461l2.5-3.251L10.6,11.452l2.448-3.159"
          data-name="Layout 3361"
          fill="none"
          fillRule="evenodd"
          id="Layout_3361"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-1.512 -2.013)"
        />
        <circle
          cx="1.606"
          cy="1.606"
          data-name="Ellipse 1572"
          fill="none"
          id="Ellipse_1572"
          r="1.606"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(12.781)"
        />
        <path
          d="M10.928,1.62H4.855a3.955,3.955,0,0,0-4.077,4.3v6.754c0,2.516,1.53,4.291,4.077,4.291h7.19a3.949,3.949,0,0,0,4.077-4.291V6.791"
          data-name="Layout 3362"
          fill="none"
          fillRule="evenodd"
          id="Layout_3362"
          stroke="#004dbc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-0.778 -0.916)"
        />
      </g>
    </svg>
  );
};
