// cspell:disable

export const WebVisitorsWithoutInteractionIcon = () => {
  return (
    <svg
      height="31.914"
      viewBox="0 0 40.655 31.914"
      width="40.655"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter filterUnits="userSpaceOnUse" height="23.928" id="a" width="39.718" x="0" y="0">
          <feOffset dy="2" in="SourceAlpha" />
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodOpacity=".161" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 8175">
        <g data-name="Group 265">
          <path
            d="M33.013 24.984h-26.3a.713.713 0 0 1-.711-.711V4.711A.713.713 0 0 1 6.713 4h26.3a.713.713 0 0 1 .711.711v19.563a.713.713 0 0 1-.711.71Z"
            data-name="Path 140"
            fill="#e7eefd"
          />
          <path
            d="m33.467 4.173-.006.006a.787.787 0 0 1 .263.592v1.871l-10.635 7.781a6.977 6.977 0 0 1-1.931 1.15l12.273 9.268a.713.713 0 0 0 .287-.568V4.713a.684.684 0 0 0-.251-.54Z"
            data-name="Path 141"
            fill="#cfdbfc"
          />
          <g filter="url(#a)">
            <path
              d="M23.188 14.522a4.646 4.646 0 0 1-6.657 0L6 6.66V4.771A.745.745 0 0 1 6.711 4h26.3a.745.745 0 0 1 .711.771V6.66Z"
              data-name="Path 145"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 271" transform="translate(26.793 18.053)">
          <circle cx="6.931" cy="6.931" data-name="Ellipse 156" fill="#fc7e00" r="6.931" />
          <path
            d="M6.931 2.12a5.978 5.978 0 0 1 5.614 3.537 5.756 5.756 0 0 0-5.614-4.482 5.756 5.756 0 0 0-5.615 4.482A5.973 5.973 0 0 1 6.931 2.12Z"
            data-name="Path 319"
            fill="rgba(255,255,255,0.4)"
          />
        </g>
        <path
          d="M33.715 21.081v4.332m0 3.468h.009v.009h-.009Z"
          data-name="Path 325"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
