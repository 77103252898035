import BeePlugin from '@mailupinc/bee-plugin';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  atomBeefreePreview,
  atomBeefreeSaving,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

import { useSendEmailTest } from './useSendEmailTest';

import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

export const useSendPreview = () => {
  const navigate = useNavigate();
  const [newRulePath, setNewRulePath] = useAtom(atomNewRulePath);

  const { sendTestEmail } = useSendEmailTest();

  const [showSendPreview, setShowSendPreview] = useState(false);

  const handleClosePreview = useCallback(() => setShowSendPreview(false), []);

  const goToBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let newUrl = window.location.pathname.replaceAll('/editor', '');
    if (newRulePath) {
      newUrl = window.location.pathname.replaceAll('/editor', '/email');
      setNewRulePath(false);
    }
    navigate(newUrl);
  };

  const handleOpenPreview = useCallback(() => {
    setShowSendPreview(true);
  }, []);

  return {
    handleOpenPreview,
    handleClosePreview,
    goToBack,
    showSendPreview,
    sendTestEmail,
  };
};

interface IUseMergeTags {
  beefree?: BeePlugin;
}

export const useMergeTags = ({ beefree }: IUseMergeTags) => {
  const [showMergeTags, setShowMergeTags] = useState(false);

  const toggleMergeTags = useCallback(() => {
    beefree?.toggleMergeTagsPreview();
    setShowMergeTags(!showMergeTags);
  }, [beefree, showMergeTags]);

  return {
    toggleMergeTags,
    showMergeTags,
  };
};

interface IUsePreview {
  beefree?: BeePlugin;
}

export const usePreview = ({ beefree }: IUsePreview) => {
  const [showPreview, setShowPreview] = useAtom(atomBeefreePreview);

  const togglePreview = useCallback(() => {
    beefree?.togglePreview();
    setShowPreview(!showPreview);
  }, [beefree, setShowPreview, showPreview]);

  return {
    togglePreview,
    showPreview,
  };
};

interface IUseStructure {
  beefree?: BeePlugin;
}
export const useStructure = ({ beefree }: IUseStructure) => {
  const [showStructure, setShowStructure] = useState(false);

  const toggleStructure = useCallback(() => {
    beefree?.toggleStructure();
    setShowStructure(!showStructure);
  }, [beefree, showStructure]);

  return {
    showStructure,
    toggleStructure,
  };
};

interface IUseSaveMail {
  beefree?: BeePlugin;
}
export const useEmailSave = ({ beefree }: IUseSaveMail) => {
  const setBeefreeSaving = useSetAtom(atomBeefreeSaving);
  const save = useCallback(() => {
    return new Promise<void>((resolve) => {
      setBeefreeSaving(true);
      localStorage.removeItem('@beefree/template.create');
      beefree?.save();
      resolve();
    });
  }, [beefree, setBeefreeSaving]);
  return {
    save,
  };
};
export const useEmailSaveTemplate = ({ beefree }: IUseSaveMail) => {
  const saveTemplate = useCallback(() => {
    localStorage.setItem('@beefree/template.create', 'true');
    beefree?.saveAsTemplate();
    beefree?.save();
  }, [beefree]);

  return {
    saveTemplate,
  };
};
