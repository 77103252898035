import { useState } from 'react';

import useMessage from '@/src/compat/useMessage';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';

import { EventListenerType } from './enums/EventListenerEnum';

export const useStepWithPostMessage = (): [number, Error | undefined] => {
  const [step, setStep] = useState<number>(StepEnum.sender);
  useMessage(EventListenerType.ActualStep, (_, event) => {
    setStep(event.data.value);
  });
  return [step, undefined];
};
