export const NewContactIcon = () => {
  return (
    <svg height="30.745" viewBox="0 0 38.5 35.745" width="32.5" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 6155" id="Grupo_6155" transform="translate(0)">
        <ellipse
          cx="9.642"
          cy="9.642"
          data-name="Elipse 1695"
          fill="#18c678"
          id="Elipse_1695"
          rx="9.642"
          ry="9.642"
          transform="translate(5.608 0)"
        />
        <path
          d="M782.115,132.821h-4.25v-4.25a1.875,1.875,0,0,0-1.872-1.871,1.9,1.9,0,0,0-1.871,1.871v4.25h-4.25A1.875,1.875,0,0,0,768,134.693a1.9,1.9,0,0,0,1.871,1.871h4.25v4.25a1.875,1.875,0,0,0,1.871,1.871,1.9,1.9,0,0,0,1.872-1.871v-4.25h4.25a1.871,1.871,0,1,0,0-3.743Z"
          data-name="Trazado 3800"
          fill="#18c678"
          id="Trazado_3800"
          transform="translate(-745.486 -106.972)"
        />
        <g data-name="Grupo 6433" id="Grupo_6433" transform="translate(0 19.28)">
          <path
            d="M722.482,139.849v-1.078H721.4a5.043,5.043,0,1,1,0-10.086h1.078v-1.078a2.1,2.1,0,0,1,.032-.444,14.552,14.552,0,0,0-2-1.649,1.3,1.3,0,0,0-1.427,0,11.826,11.826,0,0,1-13.607,0,1.24,1.24,0,0,0-1.427,0c-5.36,3.584-6.883,10.53-6.883,10.53s-1.4,5.709,4.25,5.709h21.505A3.83,3.83,0,0,1,722.482,139.849Z"
            data-name="Trazado 3801"
            fill="#18c678"
            id="Trazado_3801"
            transform="translate(-697.018 -125.287)"
          />
        </g>
      </g>
    </svg>
  );
};
