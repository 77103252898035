import withDeprecated from '@/src/application/HOCs/WithDeprecated';

import './Text.tailwind.css';

export type HeadingProps = {
  children?: JSX.Element | string;
};

export const _Heading = ({ children }: HeadingProps) => (
  <h3 className="heading text-18 font-bold">{children}</h3>
);

/**
 * @deprecated use new Text component v2 -> import { Text } from "@/lib/v2/components"
 */
export const Heading = withDeprecated(
  _Heading,
  'use new Text component v2 -> import { Text } from "@/lib/v2/components";'
);
