export const CreateIcon = ({ color = '#004dbc' }) => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7253" id="Group_7253" transform="translate(-192 -355)">
        <rect
          data-name="Rectangle 3246"
          fill="none"
          height="20"
          id="Rectangle_3246"
          transform="translate(192 355)"
          width="20"
        />
        <path
          d="M11.759,4.5V19.018m7.259-7.259H4.5"
          data-name="Layout 3410"
          fill="none"
          id="Layout_3410"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(190 353)"
        />
      </g>
    </svg>
  );
};
