import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { Card, CardSection, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useMutationUserContext } from '@/src/application/context/UserContext';
import { ContactsActionDropdown } from '@/src/ContactsModule/components/ContactsActionDropdown';
import { ContactsTableContainer } from '@/src/ContactsModule/components/ContactsTableContainer/ContactsTableContainer';
import { ContactsIcon } from '@/src/ContactsModule/Icons/ContactsIcon';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { SectionHeader } from '@/modules/ContactsModule/components';
import { ContactsFilterCards } from '@/modules/ContactsModule/screens/ContactsMain/components';

export const ContactsMain = () => {
  const { t } = useTranslation();
  const { toggleAccountLimits } = useMutationUserContext();
  const [lastRefresh, setLastRefresh] = useState<number>(0);
  const [subtitleCountMessage, setSubtitleCountMessage] = useState<ReactNode>();
  const [totalContactsCount, setTotalContactsCount] = useState<number>();

  const [statusCount, , isLoadingStatusCount] = useEmblue(ServiceMethods.getContactStatusCount, {
    lastRefresh,
  });

  const [groupsCountOfTotal] = useEmblue(ServiceMethods.getGroupsCountOfSearch, {
    lastRefresh,
  });

  const countGroupsTotal = groupsCountOfTotal?.count;

  useEffect(() => {
    toggleAccountLimits('groups', countGroupsTotal ?? 0);
  }, [countGroupsTotal, toggleAccountLimits]);

  const title = useMemo(() => {
    return (
      <Flex column itemAlignment="start">
        <div className="flex flex-row gap-2">
          <Text as="span" fontWeight="medium" variant="title-1">
            {`${t('CONTACTS_MAIN.contacts')} -`}
          </Text>
          <Text color="primary" fontWeight="medium" variant="title-1">
            {t('CONTACTS_MAIN.all')}
          </Text>
          {totalContactsCount === undefined ? (
            <div className="flex items-center">
              <TextSkeleton size="xs" />
            </div>
          ) : (
            <Text color="primary" fontWeight="medium" id="total-contacts" variant="title-1">
              {`(${quantifier(totalContactsCount) ?? '0'})`}
            </Text>
          )}
        </div>
        {subtitleCountMessage && subtitleCountMessage}
      </Flex>
    );
  }, [subtitleCountMessage, t, totalContactsCount]);

  const showStatusCards = useMemo(() => localStorage.getItem('showStatusCards') === 'true', []);

  return (
    <>
      {showStatusCards && statusCount?.list?.length ? (
        <ContactsFilterCards
          items={statusCount?.list as []}
          titleIsLoading={isLoadingStatusCount}
        />
      ) : (
        <></>
      )}
      <Card>
        <CardSection noPadding>
          <Flex column noGrow itemAlignment="start">
            <SectionHeader
              actionsButtons={<ContactsActionDropdown setLastRefreshParent={setLastRefresh} />}
              icon={<ContactsIcon />}
              justifyContentActionsButtons="end"
              title={title}
            />
            <div className="size-full">
              <ContactsTableContainer
                setContactsCount={setTotalContactsCount}
                setCountMessage={setSubtitleCountMessage}
                setLastRefreshParent={setLastRefresh}
              />
            </div>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};
