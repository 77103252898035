/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const TextSkeleton = ({ size }: { size?: 'xs' | 'sm' | 'xxs' }) => {
  return (
    <div className="max-w-fit animate-pulse py-2 " role="status">
      <div
        className={`h-2 rounded-full bg-gray-200
            ${!size && 'w-48'}
            ${size === 'xxs' && 'w-[35px]'}
            ${size === 'xs' && 'w-[70px]'}
            ${size === 'sm' && 'w-[130px]'}
            `}
      ></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
