import { useCallback, useEffect, useState } from 'react';

import { ICheckNameResource } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { useCampaign } from './useCampaigns';

export function useValidNameResource(data?: ICheckNameResource) {
  const { isValidNameResource } = useCampaign();
  const [isValid, setIsValid] = useState<boolean>(false);

  const fetchServiceValidName = useCallback(
    async (data: ICheckNameResource) => {
      if (!data?.name) return;
      const response = await isValidNameResource(data);
      return response;
    },
    [isValidNameResource]
  );

  useEffect(() => {
    return () => {
      setIsValid(false);
    };
  }, [data?.name, isValidNameResource]);

  return { fetchServiceValidName, isValid };
}
