import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlockedContactIcon,
  QuarantineContactIcon,
  SuspendedContactIcon,
  UnsafeContactIcon,
} from '@/lib/icon';
import { IconSvg } from '@/lib/v2/components';
import { InsightCardProps } from '@/lib/v2/organisms/InsightCard/InsightCard';

import { IStatusList } from '@/src/ContactsModule/interfaces/Interfaces';

import { FilterCardsList } from '@/modules/ContactsModule/components';
import { useMutationContactsContext } from '@/modules/ContactsModule/contexts/ContactsContext';
import { DiscaredStatus } from '@/modules/ContactsModule/types/Contacts';

interface ContactCardsDiscardedProps {
  statusList: IStatusList[];
  titleIsLoading?: boolean;
}

type DiscardedReasonsGroups =
  | 'blockedAndUnsafe'
  | 'unsubscribedAndComplainers'
  | 'quarantine'
  | 'suspended';

const CardsFilterDiscarded = ({
  statusList,
  titleIsLoading = true,
}: ContactCardsDiscardedProps) => {
  const { t } = useTranslation();
  const { setStatusFilter } = useMutationContactsContext();

  const cardList = useMemo<(InsightCardProps & { filter: DiscardedReasonsGroups })[]>(
    () => [
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="tertiary"
            svgComponent={<SuspendedContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_DISCARDED_CARDS.suspended'),
        title: 0,
        tooltip: t('STATUS_DISCARDED_CARDS.suspendedTooltip'),
        isLoading: true,
        filter: 'suspended',
        onClick: () => {
          setStatusFilter(['suspended'] as DiscaredStatus[]);
        },
        id: 'suspended-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="tertiary"
            svgComponent={<QuarantineContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_DISCARDED_CARDS.quarantine'),
        title: 0,
        tooltip: t('STATUS_DISCARDED_CARDS.quarantineTooltip'),
        isLoading: true,
        filter: 'quarantine',
        onClick: () => {
          setStatusFilter(['quarantine'] as DiscaredStatus[]);
        },
        id: 'quarantine-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="tertiary"
            svgComponent={<BlockedContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_DISCARDED_CARDS.blocked'),
        title: 0,
        tooltip: t('STATUS_DISCARDED_CARDS.blockedTooltip'),
        isLoading: true,
        filter: 'blockedAndUnsafe',
        onClick: () => {
          setStatusFilter(['blocked', 'unsafe'] as DiscaredStatus[]);
        },
        id: 'blocked-card',
      },
      {
        icon: (
          <IconSvg
            fullWidth
            rounded
            bgColor="tertiary"
            svgComponent={<UnsafeContactIcon />}
            withPadding="medium"
          />
        ),
        description: t('STATUS_DISCARDED_CARDS.unsafe'),
        title: 0,
        tooltip: t('STATUS_DISCARDED_CARDS.unsafeTooltip'),
        isLoading: true,
        filter: 'unsubscribedAndComplainers',
        onClick: () => {
          setStatusFilter(['unsubscribe', 'complainers'] as DiscaredStatus[]);
        },
        id: 'unsafe-card',
      },
    ],
    [setStatusFilter, t]
  );

  const newStatusList = useMemo(() => {
    if (!statusList) return [];
    const groupedData: Record<
      DiscardedReasonsGroups,
      { discarded: DiscardedReasonsGroups; total: number }
    > = {
      blockedAndUnsafe: { discarded: 'blockedAndUnsafe', total: 0 },
      unsubscribedAndComplainers: {
        discarded: 'unsubscribedAndComplainers',
        total: 0,
      },
      quarantine: { discarded: 'quarantine', total: 0 },
      suspended: { discarded: 'suspended', total: 0 },
    };

    statusList.forEach((item) => {
      const discardedCategory = item.discarded as DiscaredStatus;
      const totalValue = item.total;

      const keyDiscardedCategoryMap: Record<DiscaredStatus, DiscardedReasonsGroups> = {
        blocked: 'blockedAndUnsafe',
        complainers: 'unsubscribedAndComplainers',
        quarantine: 'quarantine',
        suspended: 'suspended',
        unsafe: 'blockedAndUnsafe',
        unsubscribe: 'unsubscribedAndComplainers',
      };

      if (groupedData[keyDiscardedCategoryMap[discardedCategory]]) {
        groupedData[keyDiscardedCategoryMap[discardedCategory]].total += totalValue;
      }
    });

    const groupedArray = Object.values(groupedData);
    return groupedArray;
  }, [statusList]);

  return (
    <FilterCardsList
      cardsList={cardList}
      keyFilter="discarded"
      statusList={newStatusList as IStatusList[]}
      titleIsLoading={titleIsLoading}
    />
  );
};

export default memo(CardsFilterDiscarded);
