import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalTemplatesInbox } from '@/lib/v2/organisms/ModalTemplatesInbox';

import { useTabsPanes } from './hooks/useTabsPanes';

import { atomMessageWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';

type SendWhatsAppModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SendWhatsAppModal = ({ isOpen, onClose }: SendWhatsAppModalProps) => {
  const { t } = useTranslation('inbox');
  const messageWhatsApp = useAtomValue(atomMessageWhatsApp);

  const { getTemplates } = useTabsPanes({ onClose });

  useEffect(() => {
    if (isOpen || !!messageWhatsApp.original) {
      void getTemplates().finally(() => {
        window.dispatchEvent(new Event('resize'));
      });
    }
  }, [isOpen, getTemplates, messageWhatsApp.original]);

  return (
    //TODO: check if can be replaced by ModalActionCreation to use the same component for both actions
    <ModalTemplatesInbox
      currentTab={1}
      isOpen={isOpen}
      title={t('INBOX.SEND_WHATSAPP_MODAL.title')}
      onClose={onClose}
    />
  );
};

export default SendWhatsAppModal;
