export const EditPencilIconNew = () => (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <mask height="14" id="mask0_741_783" maskUnits="userSpaceOnUse" width="14" x="0" y="0">
      <rect fill="#364365" height="14" width="14" />
    </mask>
    <path
      d="M2.33317 13.9998C2.01234 13.9998 1.73768 13.8856 1.50921 13.6571C1.28074 13.4287 1.1665 13.154 1.1665 12.8332C1.1665 12.5123 1.28074 12.2377 1.50921 12.0092C1.73768 11.7807 2.01234 11.6665 2.33317 11.6665H11.6665C11.9873 11.6665 12.262 11.7807 12.4905 12.0092C12.7189 12.2377 12.8332 12.5123 12.8332 12.8332C12.8332 13.154 12.7189 13.4287 12.4905 13.6571C12.262 13.8856 11.9873 13.9998 11.6665 13.9998H2.33317ZM2.33317 9.9165V8.26859C2.33317 8.19081 2.34775 8.11546 2.37692 8.04255C2.40609 7.96963 2.44984 7.904 2.50817 7.84567L8.8665 1.50192C8.97345 1.39498 9.09741 1.31234 9.23838 1.254C9.37935 1.19567 9.52761 1.1665 9.68317 1.1665C9.83873 1.1665 9.98942 1.19567 10.1353 1.254C10.2811 1.31234 10.4123 1.39984 10.529 1.5165L11.3311 2.33317C11.4478 2.44012 11.5328 2.5665 11.5863 2.71234C11.6398 2.85817 11.6665 3.00887 11.6665 3.16442C11.6665 3.31025 11.6398 3.45366 11.5863 3.59463C11.5328 3.7356 11.4478 3.86442 11.3311 3.98109L4.98734 10.3248C4.929 10.3832 4.86338 10.4269 4.79046 10.4561C4.71755 10.4853 4.6422 10.4998 4.56442 10.4998H2.9165C2.75123 10.4998 2.61268 10.4439 2.50088 10.3321C2.38907 10.2203 2.33317 10.0818 2.33317 9.9165ZM9.68317 3.9665L10.4998 3.14984L9.68317 2.33317L8.8665 3.14984L9.68317 3.9665Z"
      fill="#364365"
    />
  </svg>
);
