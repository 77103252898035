import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo } from 'react';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

import {
  atomFileDownloadInfo,
  atomShowIndeterminateNotification,
} from '@/modules/ContactsModule/atoms/Steps';

const fetchFile = (url: string, token: string) => {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.onloadstart = function () {
      request.responseType = 'blob';
    };
    request.onload = function () {
      if (request.readyState === 4 && request.status === 200) {
        resolve(request.response);
      }
    };
    request.open('GET', url);

    request.setRequestHeader('EmblueAuth', 'ApplicationToken:' + token);

    request.send();
  });
};

export const useNotificationDownload = () => {
  const service = useService();
  const [showProgressNotification, setShowProgressNotification] = useAtom(
    atomShowIndeterminateNotification
  );
  const fileDownloadInfo = useAtomValue(atomFileDownloadInfo);

  const { show, description } = showProgressNotification;

  const wordings = useMemo(
    () => ({
      message: description,
    }),
    [description]
  );

  const downloadFile = useCallback(
    async (url: string, token: string) => {
      const file = await fetchFile(url, token);

      if (!(file instanceof Blob)) return;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      document.querySelector('body')?.appendChild(link);
      link.download = fileDownloadInfo.nameDownload;
      link.click();
      document.body.removeChild(link);
    },
    [fileDownloadInfo.nameDownload]
  );

  const verifyStatus = useCallback(async () => {
    const { status, url, token } = await service.getStatusContactsExport();
    if (status === 'loading') {
      setTimeout(() => {
        void verifyStatus();
      }, 2000);
      return;
    }

    await downloadFile(url, token);

    setShowProgressNotification({
      show: true,
      description: fileDownloadInfo.descriptionDownloaded || '',
      status: loadingStatus.ready,
    });

    setTimeout(() => {
      setShowProgressNotification(RESET);
    }, 3000);
  }, [downloadFile, fileDownloadInfo.descriptionDownloaded, service, setShowProgressNotification]);

  useEffect(() => {
    if (!showProgressNotification.show || showProgressNotification.status !== loadingStatus.loading)
      return;
    void verifyStatus();
  }, [showProgressNotification, verifyStatus]);

  return {
    show,
    wordings,
  };
};
