type FilterType<T> = { [K in keyof T]: T[K] };

export const clearObjectFalsy = <T>(newFilter: FilterType<T>): FilterType<T> => {
  const filteredFilter: FilterType<T> = {} as FilterType<T>;

  for (const key in newFilter) {
    if (Object.prototype.hasOwnProperty.call(newFilter, key)) {
      const value = newFilter[key];

      const isValidValue =
        value &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'string' && value === '');

      isValidValue && (filteredFilter[key] = value);
    }
  }

  return filteredFilter;
};
