export enum ELanguageMap {
  en = 'en-US',
  es = 'es-ES',
  pt = 'pt-BR',
}

/**
 * Formats a given number according to the specified language.
 *
 * @param {number} inputNumber - The number to be formatted.
 * @param {keyof typeof ELanguageMap} lang - The language code specifying the format (e.g., 'en', 'es', 'pt').
 * @returns {string} The formatted number as a string.
 *
 * @example
 * // Usage example
 * const number = formattedNumber(1234567, 'es');
 * console.log(number); // Output: '1.234.567'
 */
export const formattedNumber = (
  inputNumber: number,
  lang: keyof typeof ELanguageMap
): string | undefined => {
  if (!inputNumber) return;
  return inputNumber.toLocaleString(ELanguageMap[lang] || ELanguageMap.es, { useGrouping: true });
};
