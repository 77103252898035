import { datadogRum } from '@datadog/browser-rum';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useHtmlEditorLogger = () => {
  const service = useService();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logger = async (actionId: string, type: 'action' | 'error', data: unknown = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { email, companyId } = await service.getUserData();

    if (type === 'action') {
      datadogRum.addAction('htmlEditor.' + actionId, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        email,
        accountId: Number(companyId),
      });
    } else {
      datadogRum.addError('htmlEditor.' + actionId, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        email,
        accountId: Number(companyId),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        error: data,
      });
    }
  };

  return { logger };
};
