import { useEffect } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectExternalSearch, Text } from '@/lib/v2/components';

import { ISMSFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsForm/hooks/useSmsForm';
import { TRANSLATE_CREATE_EMAIL_ACTION } from '@/src/modules/CampaignsModule/constants';
import { IFormProps } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { useRecipientsWhatsApp } from './useRecipientsWhatsApp.hooks';

interface IRecipientDropdownSmsProps {
  loading?: boolean;
  form: IFormProps<ISMSFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const RecipientDropdownWhatsApp = ({
  form,
  loading,
  rules,
  disabled = false,
}: IRecipientDropdownSmsProps) => {
  const { t } = useTranslation();

  const {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: { setRecipientsSearch },
    state: { recipientListPayload, recipientsOptions, loading: loadingRecipients },
  } = useRecipientsWhatsApp({ setValue: form.setValue });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void getRecipients(recipientListPayload), [getRecipients]);

  const { control } = form;

  return (
    <>
      <Text
        className="sticky top-0 z-10 mb-9 border-b border-solid border-emblue-borders bg-emblue-white pb-2"
        color="primary"
        fontWeight="medium"
        variant="sub-headline"
      >
        {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.recipients`)}
      </Text>
      <SelectExternalSearch
        multiSelect
        control={control}
        disabled={disabled}
        id="create-action-recipients-select"
        isLoading={loading}
        isSearching={loadingRecipients}
        name="recipients"
        options={recipientsOptions}
        placeholder={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.recipientsPlaceholder`)}
        rules={rules.recipients}
        searchPlaceholder={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.searchGroupsSelect`)}
        onChange={handleChangeRecipients}
        onChangeSearch={setRecipientsSearch}
        onClose={handleCloseRecipients}
        onLastItem={handleLoadLastRecipients}
      />
    </>
  );
};

export default RecipientDropdownWhatsApp;
