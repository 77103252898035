export const EmailTriggerIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.59402 5.83318C5.57375 7.40803 7.55348 8.98289 9.53321 10.5549C10.2708 11.1391 11.2031 11.1363 11.9467 10.5465C14.1329 8.81073 16.3192 7.07218 18.5054 5.33363C18.5674 5.28282 18.6323 5.23485 18.7179 5.16711C18.7179 5.1784 19.7535 4.34864 19.7535 4.34864C19.4791 3.62894 18.8093 3.19995 17.9389 3.19995C14.5873 3.19995 11.2327 3.19995 7.88098 3.19995C6.42347 3.19995 4.96597 3.19995 3.50846 3.19995C2.42861 3.20277 1.60839 3.93658 1.60544 4.92157C1.59659 7.93016 1.59954 10.9388 1.60839 13.9474C1.60839 14.1844 1.6733 14.4384 1.77952 14.6529C2.12767 15.3585 2.74135 15.6718 3.54387 15.6718C5.60916 15.6718 6.02517 15.6718 7.45907 15.6718C7.82492 15.6718 8.18782 15.6718 8.55367 15.6718H8.68349C9.28243 15.6718 8.87527 15.6718 9.4742 15.6718C9.53911 15.6718 9.60402 15.6718 9.66893 15.6633C9.84891 15.6407 9.98758 15.5476 10.079 15.398C10.3003 15.0311 10.0259 14.5767 9.53321 14.5796C8.89592 14.5824 9.26767 14.5824 8.62743 14.5852V14.5796C6.08122 14.5796 6.05467 14.5796 3.50846 14.5796C3.00689 14.5796 2.74135 14.3199 2.74135 13.8373C2.74135 11.0093 2.74135 8.18417 2.74135 5.3562V5.15864C3.04525 5.40136 3.31963 5.61586 3.59107 5.83318M17.9655 4.30348C17.9655 4.30348 17.9891 4.3317 18.0009 4.34581C17.9626 4.36839 17.9183 4.3825 17.8829 4.41073C15.6701 6.16903 13.4573 7.92452 11.2445 9.68282C10.8845 9.9707 10.5806 9.96788 10.2236 9.68282C8.01965 7.93298 5.81864 6.18032 3.61468 4.43048C3.57042 4.39662 3.52911 4.35993 3.4642 4.30348H17.9626H17.9655Z"
        fill="#004DBC"
      />
      <path
        d="M20.5598 15.7453C19.7573 15.7453 19.0404 16.0868 18.5565 16.6259L16.3761 15.0934C16.4735 14.8309 16.5266 14.5458 16.5266 14.2523C16.5266 13.7189 16.3525 13.225 16.0604 12.8186L18.8928 10.6341C19.3472 10.9897 19.9284 11.2014 20.5628 11.2014C22.0203 11.2014 23.2034 10.0696 23.2034 8.67539C23.2034 7.28117 22.0203 6.14941 20.5628 6.14941C19.1053 6.14941 17.9222 7.28117 17.9222 8.67539C17.9222 9.08745 18.0254 9.47693 18.2083 9.82125L15.2609 12.096C14.8596 11.8618 14.3905 11.7291 13.886 11.7291C12.4285 11.7291 11.2454 12.8609 11.2454 14.2551C11.2454 15.6493 12.4285 16.7811 13.886 16.7811C14.6413 16.7811 15.3228 16.4763 15.8038 15.9909L18.0284 17.5544C17.9576 17.783 17.9192 18.0257 17.9192 18.2741C17.9192 19.6683 19.1023 20.8001 20.5598 20.8001C22.0173 20.8001 23.2004 19.6683 23.2004 18.2741C23.2004 16.8799 22.0173 15.7481 20.5598 15.7481M20.5598 7.1965C21.4154 7.1965 22.1088 7.86256 22.1088 8.67821C22.1088 9.49387 21.4125 10.1599 20.5598 10.1599C19.7072 10.1599 19.0109 9.49387 19.0109 8.67821C19.0109 7.86256 19.7072 7.1965 20.5598 7.1965ZM13.886 15.7397C13.0304 15.7397 12.337 15.0736 12.337 14.2579C12.337 13.4423 13.0333 12.7762 13.886 12.7762C14.7387 12.7762 15.435 13.4423 15.435 14.2579C15.435 15.0736 14.7387 15.7397 13.886 15.7397ZM20.5598 19.7586C19.7042 19.7586 19.0109 19.0926 19.0109 18.2769C19.0109 17.4613 19.7072 16.7952 20.5598 16.7952C21.4125 16.7952 22.1088 17.4613 22.1088 18.2769C22.1088 19.0926 21.4125 19.7586 20.5598 19.7586Z"
        fill="#004DBC"
      />
    </svg>
  );
};
