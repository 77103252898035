import { useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { RegisterOptions, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { ViewsIcon as ViewsIconSolid } from '@/lib/v2/icons/solid';

import RedirectSelect from './components/RedirectSelect';

import { atomInfoModalPreviewRulePage } from '@/modules/RulesModule/atoms/rules';
import { ITriggerForm } from '@/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { useTriggerOptions } from '@/modules/RulesModule/components/FormTrigger/hooks/useTriggerOptions';
import {
  TRANSLATE_RULES_CALL_TO_ACTION,
  TRANSLATE_RULES_LABELS,
} from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IRedirectOptionProps {
  loading?: boolean;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
  rules: Record<string, RegisterOptions>;
  type: 'success' | 'error';
}

const RedirectExternalUrl = ({ form, disabled = false, rules, type }: IRedirectOptionProps) => {
  const { t } = useTranslation('rules');

  const setInfoModalPreview = useSetAtom(atomInfoModalPreviewRulePage);

  const { redirectPageOptions } = useTriggerOptions();

  const redirectFieldName = type === 'success' ? 'redirectSuccess' : 'redirectError';
  const destinationUrlFieldName =
    type === 'success' ? 'destinationSuccessUrl' : 'destinationErrorUrl';

  const redirectValue = useWatch({
    control: form.control,
    name: redirectFieldName,
  });

  const showPreviewButton = useMemo(
    () => redirectValue?.value === redirectPageOptions[0]?.value,
    [redirectValue, redirectPageOptions]
  );

  const showInputField = useMemo(
    () => redirectValue?.value === redirectPageOptions[1]?.value,
    [redirectValue, redirectPageOptions]
  );

  return (
    <div className="mb-5 flex w-full flex-col">
      <Text className="whitespace-nowrap" fontWeight="medium" variant="text">
        {t(`${TRANSLATE_RULES_LABELS}.${redirectFieldName}`)}
      </Text>
      <div className="flex items-start gap-4">
        <RedirectSelect
          destinationUrlFieldName={destinationUrlFieldName}
          disabled={disabled}
          form={form}
          redirectFieldName={redirectFieldName}
          redirectPageOptions={redirectPageOptions}
          rules={rules}
          showInputField={showInputField}
        />
        <div className="mt-1">
          <Button
            outline
            standard
            disabled={!showPreviewButton}
            id="button-modal-preview"
            size="medium"
            title={t(`${TRANSLATE_RULES_CALL_TO_ACTION}.previewEmail`)}
            onClick={() => setInfoModalPreview({ isOpen: true, type })}
          >
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ViewsIconSolid />}
              width="13px"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RedirectExternalUrl;
