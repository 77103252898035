export const CountdownIcon = () => {
  return (
    <svg
      height="65.715"
      viewBox="0 0 55.984 65.715"
      width="55.984"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="stopwatch" transform="translate(-6.924 -2.501)">
        <path
          d="M59.074,38.985A24.506,24.506,0,0,1,34.569,63.492c-32.507-1.29-32.5-47.726,0-49.012A24.505,24.505,0,0,1,59.074,38.985Z"
          data-name="Path 3438"
          fill="#84d2f6"
          id="Path_3438"
          transform="translate(0.38 1.393)"
        />
        <path
          d="M33.861,14.48a24.425,24.425,0,0,0-17.576,7.437C41.241,8.342,65.321,39.588,46.038,60.249,67.525,47.78,59.068,14.726,33.861,14.48Z"
          data-name="Path 3439"
          fill="#57c1ef"
          id="Path_3439"
          transform="translate(1.088 1.393)"
        />
        <path
          d="M48.392,12.753,59.413,21.5a4.019,4.019,0,0,0-.649-5.648L54.046,12.1a4.019,4.019,0,0,0-5.654.649ZM27.68,8.436l14.065.236a4.019,4.019,0,0,0-3.953-4.088l-6.028-.1A4.019,4.019,0,0,0,27.68,8.436Z"
          data-name="Path 3440"
          fill="#84d2f6"
          id="Path_3440"
          transform="translate(2.413 0.23)"
        />
        <path
          d="M36.621,36.876a4.18,4.18,0,1,1-4.186-4.186A4.18,4.18,0,0,1,36.621,36.876Z"
          data-name="Path 3441"
          fill="#57c1ef"
          id="Path_3441"
          transform="translate(2.481 3.51)"
        />
        <path
          d="M54.791,20.223a1.116,1.116,0,0,0,1.563-.18,5.143,5.143,0,0,0-.829-7.218L50.8,9.077A5.112,5.112,0,0,0,47.024,8c-1.4.088-4.836,1.836-3.257,3.477ZM47.278,10.215a2.9,2.9,0,0,1,2.138.611l4.723,3.75a2.9,2.9,0,0,1,.989,3.067L46.2,10.557a2.9,2.9,0,0,1,1.081-.343Z"
          data-name="Path 3442"
          fill="#1b87c9"
          id="Path_3442"
          transform="translate(4.236 0.635)"
        />
        <path
          d="M36.038,12.1l.02-3.272,5.874.1a1.116,1.116,0,0,0,1.132-1.1A5.144,5.144,0,0,0,38.014,2.6l-6.028-.1a5.173,5.173,0,0,0-5.22,5.05,1.124,1.124,0,0,0,1.1,1.135l5.964.1L33.806,12.1C-4.265,15.68-.085,68.136,34.916,68.217,69.878,68.136,74.13,15.706,36.038,12.1ZM29.881,5.548a2.974,2.974,0,0,1,2.067-.815l6.028.1a2.9,2.9,0,0,1,2.65,1.833l-11.39-.191a2.913,2.913,0,0,1,.645-.928Zm5.034,60.436c-11.985,0-25.205-8.075-25.91-25.766,1.419-34.37,50.378-34.37,51.819-.045C60.123,57.907,46.9,65.983,34.916,65.984Z"
          data-name="Path 3443"
          fill="#1b87c9"
          id="Path_3443"
          transform="translate(0 0)"
        />
        <path
          d="M55.15,28.007a1.116,1.116,0,0,0-1.968,1.05C60.6,42.5,49.76,60.214,34.43,59.814A21.117,21.117,0,0,1,13.34,38.707c-.5-15.971,18.69-26.739,32.049-18.013a1.116,1.116,0,0,0,1.191-1.889C31.76,9.125,10.56,21.017,11.109,38.724A23.357,23.357,0,0,0,34.731,62.049C51.538,62.353,63.341,42.682,55.15,28.007Z"
          data-name="Path 3444"
          fill="#1b87c9"
          id="Path_3444"
          transform="translate(0.485 1.493)"
        />
        <path
          d="M44.467,34.056a1.116,1.116,0,0,0,1.116-1.116V27.276a1.162,1.162,0,0,0-1.116-1.116H38.8a1.116,1.116,0,1,0,0,2.233h2.749l-5.512,4.943a5.325,5.325,0,0,0-7.732,6.94l-3.765,3.377a1.116,1.116,0,1,0,1.49,1.661L29.8,41.935c4.6,3.285,10.546-2.046,7.7-6.912l5.85-5.246V32.94a1.116,1.116,0,0,0,1.115,1.116ZM30.13,36.415c1.928-4.072,7.766-.735,5.188,3.126a3.057,3.057,0,0,1-5.188-3.126Z"
          data-name="Path 3445"
          fill="#1b87c9"
          id="Path_3445"
          transform="translate(2.002 2.751)"
        />
      </g>
    </svg>
  );
};
