import { useCallback, useEffect, useState } from 'react';

import { useBackOfficeService } from '@/src/infrastructure/Protocol/BackOfficeAccounts/useBackOfficeAccounts';

import { SmsCountry } from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';

export const useSmsManagePlan = () => {
  const service = useBackOfficeService();
  const [smsPrices, setSmsPrices] = useState<SmsCountry[]>();

  const getSms = useCallback(async () => {
    /* cspell:disable*/
    const countries = [
      'Argentina',
      'Bolivia',
      'Brasil',
      'Chile',
      'Colombia',
      'Ecuador',
      'Mexico',
      'Paraguay',
      'Perú',
      'Uruguay',
      'USA',
    ];
    /* cspell:enable*/
    const sms = await service.getSmsPrices();
    if (!sms) return;
    const filteredSms = sms.filter((item: SmsCountry) =>
      ['ARG', 'BOL', 'BRA', 'CHI', 'COL', 'ECU', 'MEX', 'PAR', 'PER', 'URU', 'USA'].includes(
        item.country
      )
    );
    const formattedName = filteredSms.map((countryData: SmsCountry) => {
      const countryName = countries.find(
        (country) => country.toUpperCase().substring(0, 3) === countryData.country
      );
      return { ...countryData, country: countryName };
    });
    setSmsPrices(formattedName as SmsCountry[]);
  }, [service]);

  useEffect(() => {
    void getSms();
  }, [getSms]);

  return { smsPrices };
};
