import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Table } from '@/lib/components/Table';
import { Button, IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { EmptyContactsIcon, EmptyTagsIcon } from '@/lib/v2/icons/general';
import { ViewsIcon } from '@/lib/v2/icons/solid';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { useMutationItemsContext } from '@/src/ContactsModule/contexts/ItemsContext';
import { ActivityIcon } from '@/src/ContactsModule/Icons/ActivityIcon';
import { BulletListIcon } from '@/src/ContactsModule/Icons/BulletListIcon';
import { ContactsIcon } from '@/src/ContactsModule/Icons/ContactsIcon';
import { ViewIcon } from '@/src/ContactsModule/Icons/ViewIcon';
import { IItem, IItemTableProps } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { useDate } from '@/src/infrastructure/services/useDate';

export function ItemsTable({
  itemList,
  totalItemCount,
  changeTablePage,
  changeTableOrder,
  totalPages,
  isLoading,
  gotoPageIndex,
  callToAction,
}: IItemTableProps) {
  const { t } = useTranslation();
  const { dateFormatter } = useDate();

  const { showItemPreview } = useMutationItemsContext();

  const initialStateOrderByList = [
    { id: 'LastActivity', isAsc: false },
    { id: 'Name', isAsc: undefined },
  ];
  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <BulletListIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.name')} </span>
        <SortTableArrow
          elementName={'Name'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'item',
    width: '50%',
    Cell: ({ cell: { value } }: { cell: { value: IItem } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <span
            className="max-w-[400px] self-start truncate font-medium text-[#364365] xl:max-w-[500px] 2xl:max-w-[600px]"
            title={value.name}
          >
            {value.name}
          </span>
        </Flex>
      </Flex>
    ),
  };
  const contactColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ContactsIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.contact')} </span>
      </Flex>
    ),
    accessor: 'item',
    id: 'contacts',
    width: '15%',
    Cell: ({ cell: { value } }: { cell: { value: IItem } }) => (
      <Flex withGap alignment="start" gapSize="small">
        <span className="self-start font-medium text-[#364365]">
          {' '}
          {`${quantifier(value.contacts)}`}{' '}
        </span>
      </Flex>
    ),
  };
  const lastActivityColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <ActivityIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.lastActivity')} </span>
        <SortTableArrow
          elementName={'LastActivity'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'item',
    id: 'lastActivity',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: IItem } }) => (
      <Flex alignment="start">
        <span className="text-[#364365]">
          <>{value ? dateFormatter(value.lastActivity) : '-'}</>
        </span>
      </Flex>
    ),
  };
  const previewColumn = {
    Header: () => (
      <Flex withGap alignment="center">
        <ViewIcon stroked tableSize />
        <span className="text-[#004DBC]"> {t('ITEMS_TABLE.preview')} </span>
      </Flex>
    ),
    accessor: 'item',
    id: 'preview',
    width: '15%',
    Cell: ({ cell: { value } }: { cell: { value: IItem } }) => (
      <Flex alignment="center">
        <Button
          outline
          secondary
          iconLeft={<IconSvg fillColor="secondary" svgComponent={<ViewsIcon />} />}
          size={'medium'}
          onClick={(event) => {
            event.stopPropagation();
            showItemPreview(value.name);
          }}
          // isLoading={isRecalculateTag && value.id === tagId.current}
        />
      </Flex>
    ),
  };
  const itemsTableColumns: Column<object>[] = [
    nameColumn,
    contactColumn,
    lastActivityColumn,
    previewColumn,
  ].filter((x) => x) as Column<object>[];

  const EmptyItemsNoDataDescription = useMemo(
    () => (
      <>
        <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
          {t('EMPTY_SCREEN_TABLE.emptyItemsDescription1')}
        </Text>
        <div className="flex justify-center">
          <Text className="text-center !text-20 text-emblue-text" fontWeight="normal">
            {t('EMPTY_SCREEN_TABLE.emptyItemsDescription2')}
          </Text>
          <Button link className="!text-20" size="tiny" onClick={callToAction && callToAction[1]}>
            {t('EMPTY_SCREEN_TABLE.emptyItemsAction')}
          </Button>
        </div>
      </>
    ),
    [t]
  );

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        paginationWithPadding
        columns={itemsTableColumns}
        data={itemList}
        emptyScreen={{
          totalCount: totalItemCount || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.items')}
              description={t('EMPTY_SCREEN_TABLE.callToAction')}
              image={<EmptyContactsIcon />}
              title={t('EMPTY_SCREEN_TABLE.noResults')}
              onClickAction={callToAction && callToAction[0]}
            />
          ),
          noData: (
            <EmptyScreenTable
              description={EmptyItemsNoDataDescription}
              image={<EmptyTagsIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyItemsTitle')}
              onClickAction={callToAction && callToAction[1]}
            />
          ),
        }}
        gotoPageIndex={gotoPageIndex}
        isLoading={isLoading}
        withPagination={{
          totalPages: totalPages,
          fetchData: changeTablePage,
        }}
      />
    </div>
  );
}
