import { useCallback } from 'react';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useValidateEmailOrOrganization = (
  endpointValidation: 'email' | 'organization' = 'email'
) => {
  const service = useService();

  const checkIfEmailExist = useCallback(
    async (value: string) => {
      const encodedValue = encodeURIComponent(value);
      const response =
        endpointValidation === 'email'
          ? await service.emailExists(encodedValue)
          : await service.organizationExists(encodedValue);
      return response;
    },
    [endpointValidation, service]
  );

  return { checkIfEmailExist };
};
