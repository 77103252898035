import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import {
  atomKeywordSearchCampaigns,
  atomListSearchCampaigns,
  atomPayloadSearchCampaigns,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

interface ISearchCampaignsProps {
  containerRootRef: React.RefObject<HTMLDivElement>;
}

export const useSearchCampaigns = ({ containerRootRef }: ISearchCampaignsProps) => {
  const setListSearchCampaigns = useSetAtom(atomListSearchCampaigns);
  const [payloadSearchCampaigns, setPayloadSearchCampaigns] = useAtom(atomPayloadSearchCampaigns);
  const setKeywordSearchCampaigns = useSetAtom(atomKeywordSearchCampaigns);

  const handleClickOutsideContainer = useCallback(
    (event: MouseEvent) => {
      if (containerRootRef.current && !containerRootRef.current.contains(event.target as Node)) {
        setListSearchCampaigns(undefined);
        setKeywordSearchCampaigns('');
        setPayloadSearchCampaigns({ ...payloadSearchCampaigns, search: '' });
      }
    },
    [
      setListSearchCampaigns,
      setKeywordSearchCampaigns,
      setPayloadSearchCampaigns,
      payloadSearchCampaigns,
      containerRootRef,
    ]
  );

  useEffect(() => {
    return () => {
      setListSearchCampaigns(undefined);
      setKeywordSearchCampaigns('');
      setPayloadSearchCampaigns({ ...payloadSearchCampaigns, search: '' });
    };
  }, [setKeywordSearchCampaigns, setListSearchCampaigns, setPayloadSearchCampaigns]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideContainer);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainer);
    };
  }, [handleClickOutsideContainer]);

  return {
    events: {
      handleClickOutsideContainer,
    },
  };
};
