export const CampaignsIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.84453 15.2381C5.06273 15.1084 4.3382 14.7459 3.76553 14.1981C1.68453 12.7421 1.06053 12.2231 0.644531 11.9111V16.2771C0.644531 16.5529 0.754102 16.8175 0.94914 17.0125C1.14418 17.2075 1.40871 17.3171 1.68453 17.3171H10.0015C10.2774 17.3171 10.5419 17.2075 10.7369 17.0125C10.932 16.8175 11.0415 16.5529 11.0415 16.2771V11.9111C10.6255 12.2231 10.0015 12.7431 7.92253 14.1981C7.35013 14.7457 6.62598 15.1082 5.84453 15.2381ZM10.0015 9.00011H1.68453C1.40871 9.00011 1.14418 9.10968 0.94914 9.30471C0.754102 9.49975 0.644531 9.76428 0.644531 10.0401L0.644531 10.5601C1.47653 11.1841 1.37253 11.1841 4.38753 13.3671C4.69953 13.5751 5.32353 14.1991 5.84253 14.1991C6.36153 14.1991 6.98653 13.5751 7.29753 13.4711C10.3125 11.2831 10.2085 11.2831 11.0445 10.6631V10.0401C11.0445 9.90328 11.0175 9.76779 10.9651 9.64142C10.9126 9.51505 10.8358 9.40026 10.7389 9.30365C10.642 9.20704 10.527 9.1305 10.4004 9.07841C10.2739 9.02632 10.1384 8.99971 10.0015 9.00011ZM18.3175 5.88311H7.92153C7.64571 5.88311 7.38118 5.99268 7.18614 6.18771C6.9911 6.38275 6.88153 6.64728 6.88153 6.92311V7.96311H10.0015C10.5181 7.9636 11.016 8.15607 11.3985 8.50313C11.781 8.8502 12.0209 9.32707 12.0715 9.84111L12.0805 9.83411V14.2001H18.3185C18.5944 14.2001 18.8589 14.0905 19.0539 13.8955C19.249 13.7005 19.3585 13.4359 19.3585 13.1601V6.92111C19.358 6.64545 19.2481 6.38129 19.0529 6.18665C18.8577 5.99202 18.5932 5.88284 18.3175 5.88311ZM17.2775 10.0411H15.1995V7.96011H17.2785L17.2775 10.0411ZM5.84453 6.92111C5.84506 6.37014 6.06396 5.84184 6.45327 5.45197C6.84258 5.06209 7.37056 4.84243 7.92153 4.84111H15.1985V1.72311C15.1985 1.44745 15.0891 1.18308 14.8943 0.988068C14.6995 0.793059 14.4352 0.683371 14.1595 0.683105L3.76353 0.683105C3.48771 0.683105 3.22318 0.792677 3.02814 0.987714C2.8331 1.18275 2.72353 1.44728 2.72353 1.72311V7.96011H5.84453V6.92111Z"
        fill="#004DBC"
      />
    </svg>
  );
};
