export const NpsDetractorIcon = () => {
  return (
    <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Icon ionic-md-sad" id="Icon_ionic-md-sad" transform="translate(-3.375 -3.375)">
        <path
          d="M15.236,20.25a4.893,4.893,0,0,0-4.422,2.769h8.845A4.893,4.893,0,0,0,15.236,20.25Z"
          data-name="Path 4096"
          fill="#fc644d"
          id="Path_4096"
          transform="translate(-2.861 -6.49)"
        />
        <path
          d="M12.375,3.375a9,9,0,1,0,9,9A9,9,0,0,0,12.375,3.375Zm0,16.2a7.2,7.2,0,1,1,7.2-7.2A7.221,7.221,0,0,1,12.375,19.575Z"
          data-name="Path 4097"
          fill="#fc644d"
          id="Path_4097"
        />
        <path
          d="M22.275,14.85a1.35,1.35,0,1,0-1.35-1.35A1.326,1.326,0,0,0,22.275,14.85Z"
          data-name="Path 4098"
          fill="#fc644d"
          id="Path_4098"
          transform="translate(-6.75 -3.375)"
        />
        <path
          d="M12.037,14.85a1.35,1.35,0,1,0-1.35-1.35A1.326,1.326,0,0,0,12.037,14.85Z"
          data-name="Path 4099"
          fill="#fc644d"
          id="Path_4099"
          transform="translate(-2.812 -3.375)"
        />
      </g>
    </svg>
  );
};
