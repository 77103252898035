export const HappyFace = () => {
  return (
    <svg height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.375,3.375a13,13,0,1,0,13,13A13,13,0,0,0,16.375,3.375Zm0,23.4a10.4,10.4,0,1,1,10.4-10.4A10.431,10.431,0,0,1,16.375,26.775Zm4.55-11.7a1.95,1.95,0,1,0-1.95-1.95A1.915,1.915,0,0,0,20.925,15.075Zm-9.1,0a1.95,1.95,0,1,0-1.95-1.95A1.915,1.915,0,0,0,11.825,15.075Zm4.55,8.45a7.083,7.083,0,0,0,6.63-4.55H9.745A7.083,7.083,0,0,0,16.375,23.525Z"
        fill="#18c678"
        transform="translate(-3.375 -3.375)"
      />
    </svg>
  );
};
