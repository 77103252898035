import { useAtom } from 'jotai';
import { useCallback } from 'react';

import {
  atomFirstStepInternal,
  atomFirstStepUploadPercentage,
} from '@/modules/ContactsModule/atoms/Step1';

export const useResetFile = () => {
  const [, setInternalStep] = useAtom(atomFirstStepInternal);
  const [, setUploadPercentage] = useAtom(atomFirstStepUploadPercentage);

  const resetStep1Loader = useCallback(() => {
    setInternalStep('readyToSelectFile');
    setUploadPercentage(0);
  }, [setInternalStep, setUploadPercentage]);

  return {
    resetStep1Loader,
  };
};
