export const EmailIcon = ({ fillColor = '#004dbc' }) => {
  return (
    <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.495 5.44501L17.205 15.225C15.975 16.395 14.01 16.395 12.78 15.225L2.49 5.44501C1.875 6.03001 1.5 6.85501 1.5 7.77001V21.75C1.5 23.55 3 25.005 4.845 25.005H25.14C26.985 25.005 28.485 23.55 28.485 21.75V7.77001C28.485 6.87001 28.11 6.04501 27.495 5.44501Z"
        fill={fillColor}
      />
      <path
        d="M17.205 12.99L26.025 4.62C25.74 4.545 25.455 4.5 25.155 4.5H4.82996C4.52996 4.5 4.22996 4.545 3.95996 4.62L12.78 12.99C14.01 14.16 15.975 14.16 17.205 12.99Z"
        fill={fillColor}
      />
    </svg>
  );
};
