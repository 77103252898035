import { useState } from 'react';

import { TrackEvent } from '@/src/application/util/mixPanel';
import useMessage from '@/src/compat/useMessage';
import { mixPanelEvents } from '@/src/presentation/util/enum/mixPanelEventsEnum';

import { EventListenerType } from './enums/EventListenerEnum';
import { UserData } from './useUserDataInterface';

export const useUserDataWithPostMessage = (): [UserData, Error | undefined] => {
  const [userData, setUserData] = useState<UserData>({
    stepperComplete: false,
    name: '',
    plan: '',
    environment: '',
    email: '',
    contractId: '',
    companyId: '',
    agencyName: '',
    companyName: '',
  });
  useMessage(EventListenerType.UserData, (send, event) => {
    const obj: UserData = {
      stepperComplete: event.data.value.stepperComplete,
      /* cspell:disable */
      name: event.data.value.nombre,
      plan: event.data.value.plan,
      environment: event.data.value.environment,
      email: event.data.value.email,
      contractId: event.data.value.contractId,
      companyId: event.data.value.companyId,
      agencyName: event.data.value.agencyName,
      companyName: event.data.value.companyName,
    };
    if (obj.stepperComplete === true) {
      TrackEvent(obj, mixPanelEvents.StepperComplete);
    }
    setUserData(obj);
  });
  return [userData, undefined];
};
