import { memo } from 'react';

import { IconSvg } from '@/lib/v2/components';
import { Dropdown } from '@/lib/v2/components/Dropdown';
import EmojiPicker, {
  EmojiPickerLanguage,
  IEmojiPickerEvent,
} from '@/lib/v2/components/EmojiPicker/EmojiPicker';
import { ClinicalNotesIcon, TagsIcon } from '@/lib/v2/icons/outline';

import { useUserData } from '@/src/infrastructure/services/useUserData';

import { EButtonType, ISubjectButtonsProps } from './SubjectButtons.interface';
import { useSubjectButtons } from './useSubjectButtons.hooks';

const SubjectButtons = ({
  id,
  actionId,
  searchPlaceholder,
  disabled = false,
  vertical = false,
  exclude,
  onChangeButtons,
}: ISubjectButtonsProps) => {
  const [user] = useUserData();

  const {
    events: { onChangeButton },
    state: { loading, tagsItemsList, customFieldsList },
  } = useSubjectButtons({ actionId, onChangeButtons });

  return (
    <div className={`flex gap-2 ${vertical ? 'flex-col' : ''}`}>
      {!exclude?.includes(EButtonType.EMOJI) && (
        <EmojiPicker
          menuOnTheLeft
          disabled={disabled}
          id={id}
          lang={(user?.language || 'en') as EmojiPickerLanguage}
          onSelectEmoji={(event: IEmojiPickerEvent) =>
            onChangeButton({
              item: {
                id: event.id,
                label: event.name,
                value: event.native,
              },
              type: EButtonType.EMOJI,
            })
          }
        />
      )}
      {!exclude?.includes(EButtonType.CUSTOM_FIELD) && (
        <Dropdown
          isScrollable
          menuOnTheLeft
          withSearch
          disabled={disabled || loading || customFieldsList.length === 0}
          dropdownItems={customFieldsList}
          iconRight={<IconSvg svgComponent={<ClinicalNotesIcon />} />}
          id={`${id}__custom-fields`}
          label=""
          searchPlaceholder={searchPlaceholder}
        />
      )}
      {!exclude?.includes(EButtonType.TAG) && (
        <Dropdown
          isScrollable
          menuOnTheLeft
          withSearch
          disabled={disabled || loading || tagsItemsList.length === 0}
          dropdownItems={tagsItemsList}
          iconRight={<IconSvg strokeColor="secondary" svgComponent={<TagsIcon />} />}
          id={`${id}__tags`}
          label=""
          searchPlaceholder={searchPlaceholder}
        />
      )}
    </div>
  );
};

export default memo(SubjectButtons);
