/* cSpell:disable */

type PhoneIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const PhoneIcon = ({ tableSize, stroked }: PhoneIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7472" id="Grupo_7472" transform="translate(-0.084)">
            <g data-name="Grupo 4402" id="Grupo_4402" transform="translate(4.86 2.538)">
              <g data-name="Grupo 4402" id="Grupo_4402-2">
                <g data-name="Icon feather-smartphone" id="Icon_feather-smartphone">
                  <path
                    d="M8.992,3h7.462a1.492,1.492,0,0,1,1.492,1.492v11.94a1.492,1.492,0,0,1-1.492,1.492H8.992A1.492,1.492,0,0,1,7.5,16.432V4.492A1.492,1.492,0,0,1,8.992,3Z"
                    data-name="Trazado 3359"
                    fill="#004dbc"
                    id="Trazado_3359"
                    transform="translate(-7.5 -3)"
                  />
                  <path
                    d="M18,27h0"
                    data-name="Trazado 3360"
                    fill="#004dbc"
                    id="Trazado_3360"
                    transform="translate(-12.776 -15.06)"
                  />
                </g>
              </g>
            </g>
            <rect
              data-name="Rectángulo 4052"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_4052"
              transform="translate(0.084)"
              width={tableSize ? 19 : 32}
            />
            <circle
              cx="1"
              cy="1"
              data-name="Elipse 1875"
              fill="#fff"
              id="Elipse_1875"
              r="1"
              transform="translate(8.86 14)"
            />
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7469" id="Grupo_7469" transform="translate(-0.084)">
            <g data-name="Grupo 4402" id="Grupo_4402" transform="translate(4.86 2.538)">
              <g data-name="Grupo 4402" id="Grupo_4402-2">
                <g data-name="Icon feather-smartphone" id="Icon_feather-smartphone">
                  <path
                    d="M8.992,3h7.462a1.492,1.492,0,0,1,1.492,1.492v11.94a1.492,1.492,0,0,1-1.492,1.492H8.992A1.492,1.492,0,0,1,7.5,16.432V4.492A1.492,1.492,0,0,1,8.992,3Z"
                    data-name="Trazado 3359"
                    fill="none"
                    id="Trazado_3359"
                    stroke="#004dbc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    transform="translate(-7.5 -3)"
                  />
                  <path
                    d="M18,27h0"
                    data-name="Trazado 3360"
                    fill="none"
                    id="Trazado_3360"
                    stroke="#004dbc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    transform="translate(-12.776 -15.06)"
                  />
                </g>
              </g>
            </g>
            <rect
              data-name="Rectángulo 4052"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_4052"
              transform="translate(0.084)"
              width={tableSize ? 19 : 32}
            />
          </g>
        </svg>
      )}
    </>
  );
};
