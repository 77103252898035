import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/lib/v2/components';

type ErrorType = 'NonExistentTags' | 'NotEnoughTags';
type AlertType = 'success' | 'warning' | 'info' | 'error';

interface ErrorAlertProps {
  errors: (ErrorType | string)[];
}

interface ErrorConfig {
  title: string;
  type: AlertType;
}

const ErrorAlert = ({ errors }: ErrorAlertProps) => {
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState<ErrorType | null>(null);

  const errorConfigs: Record<ErrorType, ErrorConfig> = {
    NonExistentTags: {
      title: t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.ERRORS.NonExistentTags`),
      type: 'error',
    },
    NotEnoughTags: {
      title: t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.ERRORS.NotEnoughTags`),
      type: 'error',
    },
  };

  useEffect(() => {
    if (!errors || errors.length === 0) {
      setCurrentError(null);
      return;
    }

    const validErrors = [...new Set(errors.filter((err): err is ErrorType => err in errorConfigs))];
    const errorType = validErrors.find((err) => errorConfigs[err].type === 'error');
    const warningType = validErrors.find((err) => errorConfigs[err].type === 'warning');

    setCurrentError(errorType ?? warningType ?? null);
  }, [errors]);

  if (!currentError) {
    return null;
  }

  const { title, type } = errorConfigs[currentError];

  return <Alert title={title} type={type} />;
};

export default memo(ErrorAlert);
