export const TextSmsIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_372_4178)">
        <path
          d="M16.6667 1.66663H3.33335C2.41669 1.66663 1.67502 2.41663 1.67502 3.33329L1.66669 18.3333L5.00002 15H16.6667C17.5834 15 18.3334 14.25 18.3334 13.3333V3.33329C18.3334 2.41663 17.5834 1.66663 16.6667 1.66663ZM7.50002 9.16663H5.83335V7.49996H7.50002V9.16663ZM10.8334 9.16663H9.16669V7.49996H10.8334V9.16663ZM14.1667 9.16663H12.5V7.49996H14.1667V9.16663Z"
          fill="#A7B1CC"
        />
      </g>
    </svg>
  );
};
