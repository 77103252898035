import { t } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { toast } from '@/lib/v2/components';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  EChannelsID,
  ICampaignActionsListPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import {
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomActionMaster,
  atomRecurrentActionsList,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

const TRANSLATE_MODAL_DELETE = 'CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.TOAST_MESSAGES';

export const useRecurrentActions = () => {
  const service = useCampaignsService();
  const serviceV1 = useService();

  const [actionsList, setActionsList] = useAtom(atomRecurrentActionsList);
  const [actionMaster, setActionMaster] = useAtom(atomActionMaster);
  const setTotalsCampaignList = useSetAtom(atomTotalsProcess);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);

  const [loading, setLoading] = useState<boolean>(false);

  const getList = useCallback(
    async (payload: ICampaignActionsListPayload) => {
      if (actionsList) return;
      setLoadingProcess(true);

      const response = await service.getActionsByCampaign(payload);

      setActionsList(response?.actions || []);

      const total = response?.total ?? 0;
      const partial = response?.partial ?? 0;

      setTotalsCampaignList({
        total: total > 0 ? total - 1 : 0,
        partial: partial || 0,
      });

      setLoadingProcess(false);
    },
    [service, setActionsList, setLoadingProcess, setTotalsCampaignList]
  );

  const getActionMaster = useCallback(
    async (payload: ICampaignActionsListPayload) => {
      if (actionMaster) return;
      const master = await service.getActionsByCampaign({
        ...payload,
        channel: [EChannelsID.MASTER],
      });

      setActionMaster(master.actions?.[0]);
    },
    [actionMaster, service, setActionMaster]
  );

  const deleteAction = useCallback(
    async (actionsId: number[]) => {
      const isSingle: boolean = actionsId?.length === 1;
      setLoading(true);

      const { statusOK } = await serviceV1.deleteV1Actions(actionsId);

      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1]
  );

  return {
    getList,
    actionsList,
    deleteAction,
    loading,
    getActionMaster,
  };
};
