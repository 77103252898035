import { useAtom, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useState } from 'react';
import { UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';

import { Button, Input, SelectPhoneNumber, Text, toast } from '@/lib/v2/components';

import { formValidations } from '@/src/constants/FormValidations';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomDealResponseIds,
  atomFormStepOne,
  atomSignUpCurrentStep,
  atomStepCompleted,
} from '@/src/modules/AuthModule/atoms/SignUp';
import { useValidateEmailOrOrganization } from '@/src/modules/AuthModule/hooks/useValidateEmailOrOrganization';

export interface FormStepOne {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  countryCode?: string;
}

/**cspell:disable */
const forbiddenDomains = [
  'gmail',
  'hotmail',
  'yahoo',
  'outlook',
  'email',
  'icloud',
  'gmil',
  'mail',
  'gamil',
  'gmai',
  'gemail',
  'gimail',
  'gmil',
  'gmli',
  'gmaio',
  'gmali',
  'gmsil',
];
/**cspell:enable */

const SignUpFormOne = () => {
  const { t } = useTranslation();
  const setSignUpCurrentStep = useSetAtom(atomSignUpCurrentStep);
  const setStepCompleted = useSetAtom(atomStepCompleted);
  const [formData, setFormData] = useAtom(atomFormStepOne);
  const setDealResponseIds = useSetAtom(atomDealResponseIds);

  const { checkIfEmailExist } = useValidateEmailOrOrganization();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get('plan');
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const utmContent = searchParams.get('utm_content');

  let queryParams = '';
  if (plan) queryParams += `plan=${plan}&`;
  if (utmSource) queryParams += `utm_source=${utmSource}&`;
  if (utmMedium) queryParams += `utm_medium=${utmMedium}&`;
  if (utmCampaign) queryParams += `utm_campaign=${utmCampaign}&`;
  if (utmContent) queryParams += `utm_content=${utmContent}`;

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    setError,
    handleSubmit,
  } = useForm<FormStepOne>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 500,
  });
  const { formRegExp, inputLengths } = formValidations;
  const rules: { [K in keyof FormStepOne]: UseControllerProps['rules'] } = {
    name: {
      pattern: {
        value: formRegExp.letters,
        message: t('FORMS_RULES_MESSAGES.onlyLetters'),
      },
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
    lastName: {
      pattern: {
        value: formRegExp.letters,
        message: t('FORMS_RULES_MESSAGES.onlyLetters'),
      },
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
    phone: {
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
      required: t('FORMS_RULES_MESSAGES.required'),
      validate: (value: string) => {
        const isValidPhone = isValidPhoneNumber(value ?? '');
        return isValidPhone || t('SIGN_UP_FORM_ONE.phoneNoValid');
      },
    },
    email: {
      required: t('FORMS_RULES_MESSAGES.required'),
      pattern: {
        value: formRegExp.email,
        message: t('SIGN_UP_FORM_ONE.invalidEmail'),
      },
      validate: (value: string) => {
        if (value) {
          const emailDomain = value.includes('@')
            ? value?.split('@')[1].toLowerCase().split('.')[0]
            : '';
          const containsSomeIllegalEmail = forbiddenDomains.some(
            (forbiddenDomain) => emailDomain === forbiddenDomain
          );

          if (containsSomeIllegalEmail) {
            return t('SIGN_UP_FORM_ONE.emailNotAllowed');
          }
        }
        return true;
      },
    },
  };

  const service = useService();

  const handleNextClick = useCallback(
    async (data: FormStepOne) => {
      setIsLoading(true);
      const emailExistResponse = await checkIfEmailExist(data.email);
      if (emailExistResponse) {
        setError('email', { type: 'validate', message: t('SIGN_UP_FORM_ONE.emailExists') });
      } else {
        const countryCode = parsePhoneNumber(data?.phone ?? '')?.country ?? 'AR';
        data.countryCode = countryCode;
        const response = await service.setDeal(1, data, queryParams);
        if (response?.personId && response?.dealId) {
          setFormData(data);
          setStepCompleted(2);
          setSignUpCurrentStep(2);
          setDealResponseIds(response);
        } else {
          toast({
            title: t('SignUpFormOne.error'),
            body: t('SignUpFormOne.errorPipeDrive'),
            variant: 'error',
          });
        }
      }
      setIsLoading(false);
    },
    [
      checkIfEmailExist,
      queryParams,
      service,
      setDealResponseIds,
      setError,
      setFormData,
      setSignUpCurrentStep,
      setStepCompleted,
      t,
    ]
  );

  useEffect(() => {
    if (formData) {
      setValue('name', formData.name);
      setValue('lastName', formData.lastName);
      setValue('phone', formData.phone);
      setValue('email', formData.email);
      void trigger();
    }
  }, [formData, setValue, trigger]);

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleNextClick)}>
      <Text className="mb-4" color="primary" fontWeight="medium" variant="headline">
        {t('SIGN_UP_FORM_ONE.title')}
      </Text>
      <Input
        isRequired
        control={control}
        id="name"
        label={t('SIGN_UP_FORM_ONE.name')}
        name="name"
        placeHolder={t('SIGN_UP_FORM_ONE.namePlaceHolder')}
        rules={rules.name}
      />
      <Input
        isRequired
        control={control}
        id="lastName"
        label={t('SIGN_UP_FORM_ONE.lastName')}
        name="lastName"
        placeHolder={t('SIGN_UP_FORM_ONE.lastNamePlaceHolder')}
        rules={rules.lastName}
      />
      <SelectPhoneNumber
        isRequired
        control={control}
        id="phone"
        label={t('SIGN_UP_FORM_ONE.phone')}
        name="phone"
        rules={rules.phone}
      />
      <Input
        isRequired
        control={control}
        id="email"
        label={t('SIGN_UP_FORM_ONE.email')}
        name="email"
        placeHolder={t('SIGN_UP_FORM_ONE.emailPlaceHolder')}
        rules={rules.email}
      />
      <Button fullWidth className="mt-6" disabled={!isValid} isLoading={isLoading} type="submit">
        {t('SIGN_UP_FORM_ONE.next')}
      </Button>
    </form>
  );
};

export default memo(SignUpFormOne);
