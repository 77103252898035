/* eslint-disable regexp/no-unused-capturing-group */
import { t } from 'i18next';

import { CurrencySymbol } from './enums/Enums';

export const quantifier = (value: number | string | undefined, isCurrency: boolean = false) => {
  if (value === undefined) {
    return null;
  }
  const separator = t('TIMEZONE.locale') === 'en-US' ? ',' : '.';
  const decimalSeparator = t('TIMEZONE.locale') === 'en-US' ? '.' : ',';

  isCurrency && typeof value === 'string' ? (value = value.replace(',', '.')) : null;

  if (isCurrency) {
    const numericValue = Number(value);
    const roundedValue = numericValue.toFixed(2);
    const hasTwoDecimals = roundedValue === value.toString();
    return (
      CurrencySymbol.USD +
      (hasTwoDecimals ? roundedValue : roundedValue.replace('.', decimalSeparator))
    );
  } else {
    const numericValue = Number(value);
    const hasTwoDecimals = numericValue.toFixed(2) === numericValue.toString();

    return hasTwoDecimals
      ? value
      : value.toLocaleString(t('TIMEZONE.locale'), { useGrouping: true }).replace(',', separator);
  }
};
