import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Flex, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { atomActionType } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { TableContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { useActionsData } from '@/src/modules/CampaignsModule/hooks/useActionsData';
import { useResetAtomOnUnmount } from '@/src/modules/CampaignsModule/hooks/useResetAtomOnUnmount';
import { EActionTypes } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { formattedNumber } from '@/src/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/src/modules/CampaignsModule/utils/formattedNumber';

const ActionsFinished = () => {
  const { t, i18n } = useTranslation();

  const [isMount, setIsMount] = useState(false);
  const setActionType = useSetAtom(atomActionType);
  const { resetAtomActionsList } = useResetAtomOnUnmount();
  const { actionsList, loadingProcess, totalsActionsList, filtersCount } = useActionsData(
    EActionTypes.FINISHED
  );

  useEffect(() => {
    setIsMount(true);
  }, [isMount]);

  useEffect(() => {
    setActionType(EActionTypes.FINISHED);
    return () => {
      resetAtomActionsList();
    };
  }, []);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={filtersCount}
                  countRecordsPartial={totalsActionsList.partial}
                  dataList={actionsList}
                  i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                  loading={loadingProcess}
                />
              }
              title={t('CAMPAIGN_ACTIONS_MAIN.titleFinished')}
              titleDetail={
                actionsList?.length && !loadingProcess && isMount ? (
                  <Text as="span" color="primary" fontWeight="medium" id="total-count-actions">
                    {`${
                      totalsActionsList.total
                        ? ` (${formattedNumber(
                            totalsActionsList.total,
                            i18n.language as keyof typeof ELanguageMap
                          )})`
                        : ' (0)'
                    }`}
                  </Text>
                ) : (
                  ' (0)'
                )
              }
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default ActionsFinished;
