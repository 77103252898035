import classNames from 'classnames';
import Parser from 'html-react-parser';
import { memo, ReactNode } from 'react';

import './Text.tailwind.css';

type Element = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'legend' | 'a';

type Alignment = 'left' | 'center' | 'right' | 'justify';

type FontWeight =
  | 'ultralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black';

type Color =
  | 'white'
  | 'black'
  | 'gray'
  | 'light-black'
  | 'dark'
  | 'alert'
  | 'primary'
  | 'secondary'
  | 'tertiary';

type Variant =
  | 'text-sm'
  | 'text'
  | 'sub-headline'
  | 'sub-headline-2'
  | 'headline'
  | 'title-1'
  | 'title-2'
  | 'title-3';

interface TextProps {
  /** Text to display */
  children?: ReactNode;
  /** Adjust horizontal alignment of text */
  alignment?: Alignment;
  /** Adjust color of text */
  color?: Color;
  /** Link text */
  link?: boolean;
  /** Adjust weight of text */
  fontWeight?: FontWeight;
  /** HTML id attribute */
  id?: string;
  /** The element type */
  as?: Element;
  /** allows you to define the look of a font family */
  isItalic?: boolean;
  /** Typographic style of text */
  variant?: Variant;
  /** function onClick when it is of type link */
  onClick?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  /** Extra classNames */
  className?: string;
  /** text decoration component */
  textDecoration?: 'underline' | 'overline' | 'line-through' | 'no-underline';
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  renderHtml?: string;
  truncate?: boolean;
}

const Text = ({
  children,
  id,
  color,
  alignment = 'left',
  fontWeight,
  isItalic = false,
  variant = 'sub-headline',
  link,
  onClick,
  as: AsComponent = 'p',
  textDecoration,
  className,
  href,
  target,
  renderHtml,
  truncate,
}: TextProps): JSX.Element => {
  const classes = classNames(
    'eb-text',
    {
      [`eb-text--${variant}`]: true,
      [`eb-text--color-${color ?? ''}`]: color,
      [`text-${alignment}`]: true,
      [`eb-text--${fontWeight ?? ''}`]: fontWeight,
      italic: isItalic,
      'not-italic': !isItalic,
      'eb-text--link': link,
      truncate: truncate,
    },
    textDecoration,
    className
  );

  AsComponent = link ? 'a' : AsComponent;

  return (
    <AsComponent
      {...(onClick && { role: 'button' })}
      {...(href && link && AsComponent === 'a' && { href })}
      className={classes}
      {...(id && { id })}
      {...(target && link && AsComponent === 'a' && { target })}
      {...(onClick && { onClick })}
      data-testid="text-component"
    >
      {renderHtml ? Parser(renderHtml) : children}
    </AsComponent>
  );
};

export default memo(Text);
