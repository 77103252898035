import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, Modal, Text } from '@/lib/v2/components';

import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';
import { ISegmentTableRow } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { SegmentFilterRows } from './SegmentFilterRows';

import { SEGMENT_PATH } from '@/modules/ContactsModule/constants';
import { ISegmentFilter } from '@/modules/ContactsModule/types/Segments';

type SegmentConditionModalProps = {
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  segmentInfo: ISegmentTableRow;
  segmentFilters: ISegmentFilter[];
  isEmblueSegment: boolean;
};

export const SegmentConditionModal = ({
  setShowModal,
  segmentInfo,
  segmentFilters,
  isEmblueSegment,
}: SegmentConditionModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(toggleRef, () => closeModal());

  const closeModal = useCallback(() => setShowModal && setShowModal(false), [setShowModal]);
  const editSegment = useCallback(
    () => navigate(`${SEGMENT_PATH}/edit-segment/${segmentInfo.id}`),
    [navigate, segmentInfo]
  );

  return (
    <Modal noPadding open onClose={closeModal}>
      <div ref={toggleRef} className="divide-y">
        {/* title */}
        <div className="h-full p-4">
          <Flex column itemAlignment="start">
            <Text
              color="light-black"
              fontWeight="semibold"
              id="segmentConditionsTitle"
              variant="headline"
            >
              {t('SEGMENTS_MAIN.segmentCondition')}
            </Text>
            <Text id="segmentConditionsSubtitle" variant="text">
              {`${t('SEGMENTS_MAIN.segmentHas')} ${quantifier(segmentInfo.contacts) ?? 0} ${
                segmentInfo && segmentInfo.contacts > 1
                  ? t('SEGMENTS_MAIN.contacts')
                  : t('SEGMENTS_MAIN.contact')
              }`}
            </Text>
          </Flex>
        </div>

        {/* body */}
        <div className="h-[70vh] overflow-y-auto">
          <SegmentFilterRows filters={segmentFilters} />
        </div>

        {/* footer */}
        <div className="rounded-b-lg border-t-[1px] bg-slate-100 py-2 pr-4">
          <Flex withGap alignment="end">
            <Button
              disabled={isEmblueSegment}
              id="editSegmentBtnModal"
              tooltip={isEmblueSegment ? t('EDIT_SEGMENT.bodyMessageCannotEdit') : ''}
              onClick={editSegment}
            >
              {t('SEGMENTS_MAIN.editSegment')}
            </Button>
          </Flex>
        </div>
      </div>
    </Modal>
  );
};
