import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, IconSvg, Text } from '@/lib/v2/components';

import configData from '@/src/config.json';
import {
  AcademyIcon,
  GlossaryIcon,
  HelpIcon,
  NewsIcon,
} from '@/src/modules/OnboardingModule/images/icons';

interface RelevantItem {
  title: string;
  description: string;
  icon: JSX.Element;
  redirectUrl: string;
}

const CardRelevantLinks = () => {
  const { t } = useTranslation();
  const relevantItems = useMemo<RelevantItem[]>(
    () => [
      {
        title: t('ONBOARDING.RELEVANT_LINKS.newsTitle'),
        description: t('ONBOARDING.RELEVANT_LINKS.newsDescription'),
        icon: <IconSvg height="24px" svgComponent={<NewsIcon />} width="24px" />,
        redirectUrl: 'https://www.embluemail.com/novedades/',
      },
      {
        title: t('ONBOARDING.RELEVANT_LINKS.academyTitle'),
        description: t('ONBOARDING.RELEVANT_LINKS.academyDescription'),
        icon: <IconSvg height="24px" svgComponent={<AcademyIcon />} width="24px" />,
        redirectUrl: configData.TOP_BAR.ACADEMY.url,
      },
      {
        title: t('ONBOARDING.RELEVANT_LINKS.helpTitle'),
        description: t('ONBOARDING.RELEVANT_LINKS.helpDescription'),
        icon: <IconSvg height="24px" svgComponent={<HelpIcon />} width="24px" />,
        redirectUrl: configData.TOP_BAR.MANUAL.url,
      },
      {
        title: t('ONBOARDING.RELEVANT_LINKS.glossaryTitle'),
        description: t('ONBOARDING.RELEVANT_LINKS.glossaryDescription'),
        icon: <IconSvg height="24px" svgComponent={<GlossaryIcon />} width="24px" />,
        redirectUrl: 'https://help.embluemail.com/glosario/',
      },
    ],
    [t]
  );
  return (
    <div className="basis-1/3">
      <Card>
        <CardSection noPadding>
          <div className="p-4">
            <Text fontWeight="medium" variant="sub-headline">
              {t('ONBOARDING.RELEVANT_LINKS.title')}
            </Text>
          </div>
          <div className="grid grid-cols-2 divide-x divide-y md:grid-cols-1">
            {relevantItems.map((item) => {
              const { title, description, icon, redirectUrl } = item;
              return (
                <a
                  key={title}
                  className="flex min-h-[75px] w-full gap-4 p-4 first:border-t"
                  href={redirectUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div>{icon}</div>
                  <Text as="span" color="primary" fontWeight="medium" variant="text">
                    {title}
                    <Text as="span" variant="text">
                      {` ${description}`}
                    </Text>
                  </Text>
                </a>
              );
            })}
          </div>
        </CardSection>
      </Card>
    </div>
  );
};

export default CardRelevantLinks;
