import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components';

import FilterState from '@/src/modules/CampaignsModule/components/CampaignsRecurrent/TableContainer/components/FilterBar/components/FilterState';

import { useFiltersDropdown } from './useFiltersDropdown';

interface IFiltersProps {
  onClose?: () => void;
}
const FiltersDropdown = ({ onClose }: IFiltersProps) => {
  const { t } = useTranslation();

  const { filtersApplied, onApplyFilters, setSelectedStateRecurrent } = useFiltersDropdown(onClose);

  const containerClasses = classNames('flex flex-col w-300', {
    hidden: filtersApplied,
  });

  return (
    <div className={containerClasses} id="filters-campaign">
      <FilterState currentItems={setSelectedStateRecurrent} />
      <div className="flex justify-end">
        <Button id="button-apply-filters-recurrent" onClick={onApplyFilters}>
          {t('TABLE_FILTER_BAR.apply')}
        </Button>
      </div>
    </div>
  );
};

export default memo(FiltersDropdown);
