export const NpsIcon = () => {
  return (
    <svg
      height="41.673"
      viewBox="0 0 81.807 41.673"
      width="81.807"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 4515" id="Group_4515" transform="translate(-15887.71 -4185.967)">
        <path
          d="M50.885,79A26.885,26.885,0,0,0,24,105.885H77.77A26.885,26.885,0,0,0,50.885,79Z"
          data-name="Path 3423"
          fill="#dcdcf9"
          id="Path_3423"
          transform="translate(15877.727 4121.082)"
        />
        <path
          d="M50.885,79.336a28.212,28.212,0,0,1,14.838,4.131,26.875,26.875,0,0,0-29.675,0,28.212,28.212,0,0,1,14.838-4.131ZM76.157,96.694a5.128,5.128,0,0,1-5.011,6.167H30.625a5.128,5.128,0,0,1-5.011-6.167A26.832,26.832,0,0,0,24,105.885H77.77A26.832,26.832,0,0,0,76.157,96.694Z"
          data-name="Path 3424"
          fill="#cacadb"
          id="Path_3424"
          transform="translate(15877.727 4121.082)"
        />
        <path
          d="M68.368,111.179a2.016,2.016,0,0,1-2.785-2.917c.805-.769,14.391-12.926,14.391-12.926S69.174,110.41,68.368,111.179Z"
          data-name="Path 3425"
          fill="#cacadb"
          id="Path_3425"
          transform="translate(15864.297 4115.726)"
        />
        <path
          d="M50.885,81.016a26.88,26.88,0,0,1,26.8,24.865l.083,0a26.885,26.885,0,0,0-53.77,0h.083a26.881,26.881,0,0,1,26.8-24.869Z"
          data-name="Path 3426"
          fill="#f0f0ff"
          id="Path_3426"
          transform="translate(15877.727 4121.082)"
        />
        <path
          d="M44.374,72.442A26.885,26.885,0,0,1,71.26,99.327H81.917A2.7,2.7,0,0,0,84.6,96.411a40.33,40.33,0,0,0-80.448,0,2.7,2.7,0,0,0,2.681,2.916H17.489A26.885,26.885,0,0,1,44.374,72.442Z"
          data-name="Path 3427"
          fill="#ffbd5a"
          id="Path_3427"
          transform="translate(15884.237 4127.639)"
        />
        <path
          d="M44.274,62.361C65.838,62.361,83.39,78.3,84.086,98.2a2.719,2.719,0,0,0,.514-1.787,40.33,40.33,0,0,0-80.448,0,2.725,2.725,0,0,0,.318,1.474C5.337,78.13,22.821,62.361,44.274,62.361Z"
          data-name="Path 3428"
          fill="#fed18c"
          id="Path_3428"
          transform="translate(15884.235 4127.639)"
        />
        <path
          d="M80.084,103.535a31.7,31.7,0,1,0-63.4,0H21.6a26.885,26.885,0,0,1,53.77,0Z"
          data-name="Path 3429"
          fill="#e4ad5d"
          id="Path_3429"
          transform="translate(15880.125 4123.432)"
        />
        <path
          d="M93.87,69.24a40.436,40.436,0,0,1,13.866,16.071,40,40,0,0,1,3.824,14.458,2.706,2.706,0,0,1-2.682,2.917H98.219A26.881,26.881,0,0,0,86.367,80.4Z"
          data-name="Path 3430"
          fill="#99db6e"
          id="Path_3430"
          transform="translate(15857.278 4124.282)"
        />
        <path
          d="M114,101.557a2.735,2.735,0,0,0,.517-1.789,40,40,0,0,0-3.824-14.458A40.436,40.436,0,0,0,96.822,69.24l-.048.072-1.4,2.086C106.23,77.753,113.552,88.843,114,101.557Z"
          data-name="Path 3431"
          fill="#b1f1a8"
          id="Path_3431"
          transform="translate(15854.325 4124.282)"
        />
        <path
          d="M88.984,80.055,86.37,83.943a26.88,26.88,0,0,1,11.85,22.288h4.713A31.669,31.669,0,0,0,88.984,80.055Z"
          data-name="Path 3432"
          fill="#84bf6f"
          id="Path_3432"
          transform="translate(15857.277 4120.736)"
        />
        <path
          d="M29.337,80.388a26.88,26.88,0,0,0-11.85,22.288H6.828a2.706,2.706,0,0,1-2.682-2.917A40,40,0,0,1,7.97,85.308a40.451,40.451,0,0,1,13.9-16.1Z"
          data-name="Path 3433"
          fill="#ea5a6b"
          id="Path_3433"
          transform="translate(15884.238 4124.292)"
        />
        <path
          d="M23.257,71.284,21.871,69.21a40.451,40.451,0,0,0-13.9,16.1A40,40,0,0,0,4.147,99.758a2.743,2.743,0,0,0,.321,1.476C5.023,88.584,12.392,77.572,23.257,71.284Z"
          data-name="Path 3434"
          fill="#ed7381"
          id="Path_3434"
          transform="translate(15884.237 4124.292)"
        />
        <path
          d="M16.685,106.182H21.6A26.881,26.881,0,0,1,33.454,83.893l-2.667-3.987a31.668,31.668,0,0,0-14.1,26.276Z"
          data-name="Path 3435"
          fill="#c8556b"
          id="Path_3435"
          transform="translate(15880.124 4120.785)"
        />
        <path
          d="M64.3,110.465a2.016,2.016,0,1,1-2.743-2.957c.816-.757,16.647-12.692,16.647-12.692S65.115,109.708,64.3,110.465Z"
          data-name="Path 3436"
          fill="#8a8e93"
          id="Path_3436"
          transform="translate(15865.624 4115.896)"
        />
        <path
          d="M44.047,58A41.124,41.124,0,0,0,3.152,96.035a3.424,3.424,0,0,0,.9,2.57A3.314,3.314,0,0,0,6.5,99.67H81.59A3.314,3.314,0,0,0,84.04,98.6a3.422,3.422,0,0,0,.9-2.569A41.123,41.123,0,0,0,44.047,58ZM79.525,81.322l-10.91,5.207A27.744,27.744,0,0,0,60,76.549L66.74,66.526a40.02,40.02,0,0,1,12.785,14.8ZM43.073,95.931c.433-.4,5.91-4.57,11.3-8.65C49.9,92.35,45.335,97.5,44.9,97.9a1.344,1.344,0,0,1-1.829-1.971ZM59.767,83.189a.672.672,0,0,0-.909-.981c-1.623,1.224-15.881,11.977-16.7,12.736a2.693,2.693,0,0,0-.457,3.383H17.851a26.033,26.033,0,0,1,2.264-10l1.637.781a.672.672,0,1,0,.579-1.213l-1.635-.78a26.386,26.386,0,0,1,8.146-9.445l1.029,1.542a.672.672,0,1,0,1.118-.747l-1.034-1.549a26.041,26.041,0,0,1,13.42-4.11v2a.672.672,0,1,0,1.344,0v-2a26.041,26.041,0,0,1,13.419,4.11L57.1,78.462a.672.672,0,1,0,1.116.748l1.036-1.544A26.385,26.385,0,0,1,67.4,87.11l-1.638.781a.672.672,0,1,0,.579,1.213l1.64-.782a26.034,26.034,0,0,1,2.265,10.005h-23.9C48.829,95.632,58.555,84.568,59.767,83.189ZM58.889,75.8a27.38,27.38,0,0,0-14.169-4.338V59.36a39.415,39.415,0,0,1,20.9,6.419ZM43.375,71.458A27.379,27.379,0,0,0,29.207,75.8L22.5,65.758a39.414,39.414,0,0,1,20.873-6.4Zm-21.99-4.952,6.709,10.042a27.742,27.742,0,0,0-8.615,9.982L8.569,81.323A40.02,40.02,0,0,1,21.385,66.506ZM5.039,97.688a2.046,2.046,0,0,1-.545-1.558,39.168,39.168,0,0,1,3.5-13.595L18.9,87.742a27.374,27.374,0,0,0-2.4,10.584H6.5a1.982,1.982,0,0,1-1.466-.638Zm78.017,0a1.983,1.983,0,0,1-1.466.638H71.6a27.374,27.374,0,0,0-2.4-10.586L80.1,82.535a39.168,39.168,0,0,1,3.5,13.6,2.048,2.048,0,0,1-.545,1.558Z"
          data-name="Path 3437"
          fill="#603913"
          id="Path_3437"
          transform="translate(15884.564 4127.968)"
        />
      </g>
    </svg>
  );
};
