import { REGEX_CUSTOM_FIELDS } from '@/src/modules/CampaignsModule/constants';

/**
 * Extracts custom field patterns from the input string.
 *
 * This function searches for all occurrences of the pattern `${number#string}`
 * in the given input string and returns them as an array of matches.
 *
 * @param {string} input - The input string to search for custom field patterns.
 * @returns {string[]} An array of matching patterns found in the input string.
 *                     If no matches are found, an empty array is returned.
 */
export function getCustomFields(input: string): string[] {
  const matches = input.match(REGEX_CUSTOM_FIELDS);
  return matches || [];
}
