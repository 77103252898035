type ViewIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const ViewIcon = ({ tableSize, stroked }: ViewIconProps) => {
  return (
    <>
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Group 7460" id="Group_7460" transform="translate(-192 -355)">
            <rect
              data-name="Rectangle 3246"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectangle_3246"
              transform="translate(192 355)"
              width={tableSize ? 19 : 32}
            />
            <g data-name="Group 7462" id="Group_7462" transform="translate(191.413 354.058)">
              <path
                d="M2.029,11.218a.869.869,0,0,1,0-.549,9.021,9.021,0,0,1,17.115,0,.858.858,0,0,1,0,.549,9.02,9.02,0,0,1-17.114,0Z"
                data-name="Traced 3342"
                fill="none"
                id="Traced_3342"
                stroke="#004dbc"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                transform="translate(0 0)"
              />
              <path
                d="M14.153,11.577A2.577,2.577,0,1,1,11.577,9,2.577,2.577,0,0,1,14.153,11.577Z"
                data-name="Traced 3343"
                fill="none"
                id="Traced_3343"
                stroke="#004dbc"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                transform="translate(-0.99 -0.635)"
              />
            </g>
          </g>
        </svg>
      )}
    </>
  );
};
