export const EmptyGroupIcon = () => {
  return (
    <svg
      height="158.573"
      viewBox="0 0 281.919 158.573"
      width="281.919"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-810.041 -652.989)">
        <g opacity="0.6" transform="translate(770.536 593.539)">
          <path
            d="M166.28,204.561a316.846,316.846,0,0,1-50.352,7.615c-4.3.263-8.677.422-13.034.422s-8.73-.158-13.025-.422a317.055,317.055,0,0,1-50.36-7.615s-.641-21.246,12.875-35.746,36.668-15.765,50.51-15.765,37,1.291,50.518,15.765S166.28,204.561,166.28,204.561Z"
            data-name="Trazado 4397"
            fill="#004dbc"
            id="Trazado_4397"
            transform="translate(0 -11.393)"
          />
          <path
            d="M122.908,161.12v52.038c-4.3.263-8.677.422-13.034.422s-8.73-.158-13.025-.422V161.12Z"
            data-name="Trazado 4398"
            fill="#f4f5fa"
            id="Trazado_4398"
            transform="translate(-6.98 -12.376)"
          />
          <ellipse
            cx="23.705"
            cy="27.165"
            fill="#fff"
            rx="23.705"
            ry="27.165"
            transform="translate(79.524 76.093)"
          />
          <path
            d="M108.713,133.124c-13.315,0-24.153-12.392-24.153-27.622,0-16.178,1.757-27.622,24.153-27.622S132.874,89.3,132.874,105.5C132.874,120.731,122.036,133.124,108.713,133.124Zm0-54.339c-21.632,0-23.248,10.539-23.248,26.717,0,14.729,10.434,26.717,23.248,26.717s23.257-11.988,23.257-26.717C131.969,89.4,130.345,78.785,108.713,78.785Z"
            data-name="Trazado 4399"
            fill="#231f20"
            id="Trazado_4399"
            transform="translate(-5.484 -2.243)"
          />
          <path
            d="M129.1,119.142c1.159-7.237,13.174-9.02,12.577-.492a12.568,12.568,0,0,1-3.206,8.01c-2.055,2.09-5.375,3.083-8.054,1.88"
            data-name="Trazado 4400"
            fill="#fff"
            id="Trazado_4400"
            transform="translate(-10.906 -6.513)"
          />
          <path
            d="M132.865,129.417a6.569,6.569,0,0,1-2.7-.562.452.452,0,1,1,.369-.826,6.938,6.938,0,0,0,7.553-1.757,12.173,12.173,0,0,0,3.065-7.72c.3-4.233-2.582-5.024-3.829-5.173-3.206-.36-7.219,1.915-7.843,5.77a.457.457,0,1,1-.878-.149c.7-4.391,5.217-6.93,8.835-6.517,2.433.272,4.927,2.038,4.637,6.148a13.07,13.07,0,0,1-3.329,8.291,8.344,8.344,0,0,1-5.884,2.494Z"
            data-name="Trazado 4401"
            fill="#231f20"
            id="Trazado_4401"
            transform="translate(-10.841 -6.45)"
          />
          <path
            d="M93.254,119.142c-1.159-7.237-13.174-9.02-12.577-.492a12.612,12.612,0,0,0,3.2,8.01c2.064,2.09,5.375,3.083,8.063,1.88"
            data-name="Trazado 4402"
            fill="#fff"
            id="Trazado_4402"
            transform="translate(-5.009 -6.513)"
          />
          <path
            d="M89.393,129.411a8.344,8.344,0,0,1-5.858-2.538,13.086,13.086,0,0,1-3.337-8.291c-.281-4.1,2.213-5.867,4.637-6.148,3.627-.413,8.142,2.161,8.844,6.517a.465.465,0,0,1-.378.527.457.457,0,0,1-.518-.378c-.624-3.856-4.629-6.148-7.843-5.77-1.247.149-4.128.94-3.838,5.173a12.185,12.185,0,0,0,3.074,7.72,6.85,6.85,0,0,0,7.544,1.757.454.454,0,1,1,.378.826A6.57,6.57,0,0,1,89.393,129.411Z"
            data-name="Trazado 4403"
            fill="#231f20"
            id="Trazado_4403"
            transform="translate(-4.951 -6.444)"
          />
          <path
            d="M96.85,157.267s3.25,7.079,12.726,7.079a15.308,15.308,0,0,0,13.332-7.079l-1.273-27.8H98.115Z"
            data-name="Trazado 4404"
            fill="#fff"
            id="Trazado_4404"
            transform="translate(-6.98 -8.523)"
          />
          <path
            d="M109.512,164.74c-9.661,0-13.1-7.272-13.174-7.342a.456.456,0,0,1,0-.211L97.6,129.38a.457.457,0,0,1,.457-.43h23.52a.457.457,0,0,1,.457.43l1.23,27.806a.448.448,0,0,1-.053.237A15.914,15.914,0,0,1,109.512,164.74Zm-12.3-7.632c.43.878,3.7,6.728,12.3,6.728a15.018,15.018,0,0,0,12.876-6.736l-1.238-27.227H98.481Z"
            data-name="Trazado 4405"
            fill="#231f20"
            id="Trazado_4405"
            transform="translate(-6.917 -8.46)"
          />
          <path
            d="M98.235,142.6a19.322,19.322,0,0,0,7.105,6.236c4.664,2.573,11.628,5.472,17.284,4.611l-.931-11.725a53.224,53.224,0,0,1-12.472.088c-3.654-.325-7.421-.676-10.768-2.09C97.883,139.435,97.515,141.473,98.235,142.6Z"
            data-name="Trazado 4406"
            fill="#dadada"
            id="Trazado_4406"
            transform="translate(-7.1 -9.767)"
          />
          <path
            d="M120.411,153.947c-5.7,0-11.909-2.925-15.343-4.831A19.322,19.322,0,0,1,97.8,142.74a3.346,3.346,0,0,1-.053-3.337.7.7,0,0,1,.826-.211c3.337,1.405,7.211,1.757,10.618,2.046a52.171,52.171,0,0,0,12.375-.079.448.448,0,0,1,.334.1.422.422,0,0,1,.167.307L123,153.315a.439.439,0,0,1-.378.465A14.2,14.2,0,0,1,120.411,153.947ZM98.358,140.088a2.828,2.828,0,0,0,.184,2.222h0a18.611,18.611,0,0,0,6.956,6.078c3.7,2.046,10.706,5.322,16.6,4.62l-.878-10.882a54.358,54.358,0,0,1-12.12,0C105.691,141.845,101.809,141.5,98.358,140.088Zm-.132-.061Z"
            data-name="Trazado 4407"
            fill="#231f20"
            id="Trazado_4407"
            transform="translate(-7.039 -9.7)"
          />
          <path
            d="M108.687,139.192h1.932a16.582,16.582,0,0,0,16.512-16.582V96.35s-5.761,1.66-17.566,1.66S92,96.35,92,96.35v26.26a16.591,16.591,0,0,0,16.687,16.582Z"
            data-name="Trazado 4408"
            fill="#fff"
            id="Trazado_4408"
            transform="translate(-6.39 -4.492)"
          />
          <path
            d="M127.029,96.227v26.26a16.582,16.582,0,0,1-16.582,16.582h-1.862A16.591,16.591,0,0,1,92,122.487V96.227s5.77,1.66,17.566,1.66,17.566-1.66,17.566-1.66m0-.878a.791.791,0,0,0-.246,0c-.053,0-5.814,1.625-17.3,1.625s-17.241-1.607-17.293-1.625a1.014,1.014,0,0,0-1.133.878v26.26a17.5,17.5,0,0,0,17.425,17.487h1.932a17.513,17.513,0,0,0,17.5-17.487V96.227A.878.878,0,0,0,127.134,95.349Z"
            data-name="Trazado 4409"
            fill="#231f20"
            id="Trazado_4409"
            transform="translate(-6.288 -4.369)"
          />
          <path
            d="M70.067,210.934a.448.448,0,0,1-.448-.413L66.01,175.39a.457.457,0,0,1,.4-.5.474.474,0,0,1,.5.4l3.6,35.131a.448.448,0,0,1-.4.492Z"
            data-name="Trazado 4410"
            fill="#231f20"
            id="Trazado_4410"
            transform="translate(-3.226 -14.052)"
          />
          <path
            d="M152.676,210.952h-.053a.448.448,0,0,1-.4-.492l3.61-35.131a.457.457,0,0,1,.878.1l-3.61,35.131A.457.457,0,0,1,152.676,210.952Z"
            data-name="Trazado 4411"
            fill="#231f20"
            id="Trazado_4411"
            transform="translate(-13.72 -14.07)"
          />
          <path
            d="M307.777,105.607c0,15.027,37.23,73.889-23.713,73.889s-23.713-58.845-23.713-73.889,1-27.227,23.713-27.227S307.777,90.57,307.777,105.607Z"
            data-name="Trazado 4412"
            fill="#fff"
            id="Trazado_4412"
            transform="translate(-25.512 -2.304)"
          />
          <path
            d="M283.967,179.863c-15.264,0-25.716-3.759-31.056-11.18-9-12.515-2.169-32.927,2.819-47.831,2.169-6.464,4.04-12.05,4.04-15.326,0-16.2,1.757-27.666,24.2-27.666s24.2,11.462,24.2,27.666c0,3.276,1.862,8.862,4.031,15.326,4.989,14.931,11.83,35.315,2.819,47.831C309.674,176.122,299.223,179.863,283.967,179.863Zm0-101.1c-21.676,0-23.292,10.592-23.292,26.761,0,3.425-1.888,9.073-4.084,15.616-4.927,14.711-11.681,34.868-2.942,47.005,5.164,7.176,15.361,10.812,30.318,10.812s25.154-3.636,30.309-10.812c8.739-12.138,1.985-32.294-2.942-47.005-2.187-6.543-4.084-12.19-4.084-15.616,0-16.125-1.616-26.744-23.283-26.744Z"
            data-name="Trazado 4413"
            fill="#231f20"
            id="Trazado_4413"
            transform="translate(-25.442 -2.241)"
          />
          <path
            d="M342.92,204.565a316.848,316.848,0,0,1-50.352,7.615c-4.3.263-8.677.422-13.034.422s-8.73-.158-13.025-.422a317.056,317.056,0,0,1-50.36-7.615s-.641-21.246,12.876-35.72,36.668-15.765,50.51-15.765,37.011,1.291,50.518,15.765S342.92,204.565,342.92,204.565Z"
            data-name="Trazado 4414"
            fill="#004dbc"
            id="Trazado_4414"
            transform="translate(-21.501 -11.397)"
          />
          <path
            d="M299.548,161.12v52.038c-4.3.263-8.677.422-13.034.422s-8.73-.158-13.025-.422V161.12Z"
            data-name="Trazado 4415"
            fill="#f4f5fa"
            id="Trazado_4415"
            transform="translate(-28.482 -12.376)"
          />
          <path
            d="M246.707,210.934a.448.448,0,0,1-.448-.413l-3.61-35.131a.457.457,0,0,1,.4-.5.474.474,0,0,1,.5.4l3.6,35.131a.439.439,0,0,1-.4.492Z"
            data-name="Trazado 4416"
            fill="#231f20"
            id="Trazado_4416"
            transform="translate(-24.727 -14.052)"
          />
          <path
            d="M329.316,210.937h-.053a.448.448,0,0,1-.4-.492l3.61-35.131a.466.466,0,0,1,.5-.4.457.457,0,0,1,.4.5l-3.61,35.131A.457.457,0,0,1,329.316,210.937Z"
            data-name="Trazado 4417"
            fill="#231f20"
            id="Trazado_4417"
            transform="translate(-35.221 -14.054)"
          />
          <path
            d="M305.94,119.142c1.159-7.246,13.174-9.029,12.594-.483a12.577,12.577,0,0,1-3.206,8.019,7.447,7.447,0,0,1-8.071,1.888"
            data-name="Trazado 4418"
            fill="#fff"
            id="Trazado_4418"
            transform="translate(-32.432 -6.512)"
          />
          <path
            d="M309.71,129.493a6.587,6.587,0,0,1-2.7-.553.457.457,0,1,1,.378-.826,6.989,6.989,0,0,0,7.553-1.757,12.26,12.26,0,0,0,3.083-7.738c.29-4.242-2.591-5.041-3.847-5.182-3.232-.36-7.228,1.915-7.852,5.779a.457.457,0,0,1-.878-.149c.7-4.391,5.226-6.947,8.853-6.534,2.433.281,4.927,2.046,4.646,6.148a13.061,13.061,0,0,1-3.337,8.308A8.388,8.388,0,0,1,309.71,129.493Z"
            data-name="Trazado 4419"
            fill="#231f20"
            id="Trazado_4419"
            transform="translate(-32.372 -6.456)"
          />
          <path
            d="M270.062,119.142c-1.159-7.246-13.174-9.029-12.594-.483a12.621,12.621,0,0,0,3.206,8.019,7.45,7.45,0,0,0,8.071,1.888"
            data-name="Trazado 4420"
            fill="#fff"
            id="Trazado_4420"
            transform="translate(-26.529 -6.512)"
          />
          <path
            d="M266.17,129.489a8.4,8.4,0,0,1-5.884-2.538,13.1,13.1,0,0,1-3.329-8.308c-.29-4.1,2.2-5.867,4.637-6.148,3.627-.413,8.159,2.169,8.853,6.534a.439.439,0,0,1-.378.518.457.457,0,0,1-.518-.369c-.615-3.864-4.646-6.148-7.852-5.779-1.256.141-4.137.94-3.838,5.182a12.2,12.2,0,0,0,3.074,7.738,6.956,6.956,0,0,0,7.562,1.757.452.452,0,0,1,.369.826,6.561,6.561,0,0,1-2.7.588Z"
            data-name="Trazado 4421"
            fill="#231f20"
            id="Trazado_4421"
            transform="translate(-26.466 -6.451)"
          />
          <path
            d="M273.64,157.4s3.25,7.088,12.753,7.088c7.571,0,13.341-7.088,13.341-7.088l-1.265-27.85H274.9Z"
            data-name="Trazado 4422"
            fill="#fff"
            id="Trazado_4422"
            transform="translate(-28.5 -8.533)"
          />
          <path
            d="M286.328,164.872c-9.661,0-13.174-7.281-13.174-7.351a.43.43,0,0,1-.044-.211l1.273-27.85a.457.457,0,0,1,.448-.43H298.4a.457.457,0,0,1,.448.43l1.273,27.85a.483.483,0,0,1-.105.307C299.783,157.917,294.03,164.872,286.328,164.872Zm-12.3-7.632c.43.878,3.7,6.728,12.3,6.728,6.71,0,12.059-5.841,12.884-6.789l-1.247-27.227H275.271Z"
            data-name="Trazado 4423"
            fill="#231f20"
            id="Trazado_4423"
            transform="translate(-28.435 -8.47)"
          />
          <path
            d="M275.025,142.712a19.322,19.322,0,0,0,7.123,6.236c4.664,2.582,11.646,5.489,17.311,4.62l-.931-11.769a53.47,53.47,0,0,1-12.5.079c-3.662-.325-7.43-.676-10.785-2.09C274.673,139.532,274.3,141.57,275.025,142.712Z"
            data-name="Trazado 4424"
            fill="#dadada"
            id="Trazado_4424"
            transform="translate(-28.62 -9.776)"
          />
          <path
            d="M297.236,154.1c-5.7,0-11.927-2.925-15.361-4.831a19.383,19.383,0,0,1-7.281-6.394,3.346,3.346,0,0,1-.053-3.337.7.7,0,0,1,.826-.211c3.346,1.414,7.219,1.757,10.636,2.046a51.932,51.932,0,0,0,12.4-.079.466.466,0,0,1,.334.1.439.439,0,0,1,.158.307l.931,11.769a.439.439,0,0,1-.369.474,15.478,15.478,0,0,1-2.222.158Zm-22.089-13.912a2.828,2.828,0,0,0,.184,2.222h0A18.743,18.743,0,0,0,282.3,148.5c3.706,2.046,10.733,5.331,16.626,4.62l-.878-10.9a54.452,54.452,0,0,1-12.138,0C282.49,141.95,278.6,141.607,275.148,140.193Zm-.132-.061Z"
            data-name="Trazado 4425"
            fill="#231f20"
            id="Trazado_4425"
            transform="translate(-28.558 -9.717)"
          />
          <path
            d="M304.02,103.1v19.111a16.6,16.6,0,0,1-16.591,16.6H285.5a16.608,16.608,0,0,1-16.617-16.6V104.087l10.451-.878,1.2-8.291,2.635,9.38,7.413-.984c.764-3.311,0-10.469-.105-10.794L295.79,102.6Z"
            data-name="Trazado 4426"
            fill="#fff"
            id="Trazado_4426"
            transform="translate(-27.92 -4.025)"
          />
          <path
            d="M287.366,139.2h-1.932a17.082,17.082,0,0,1-17.074-17.056V104.016a.448.448,0,0,1,.422-.448l10.091-.878,1.151-7.9a.448.448,0,0,1,.413-.386.422.422,0,0,1,.465.334l2.521,9,6.7-.878c.624-3.329-.1-9.951-.167-10.293a.467.467,0,1,1,.878-.316l5.226,9.819,7.975.527a.448.448,0,0,1,.422.448v19.111A17.074,17.074,0,0,1,287.366,139.2Zm-18.092-34.762v17.706a16.169,16.169,0,0,0,16.16,16.152h1.932a16.169,16.169,0,0,0,16.152-16.152V103.463l-7.808-.518a.466.466,0,0,1-.369-.237l-4.26-8.01a35.064,35.064,0,0,1-.132,8.66.439.439,0,0,1-.386.343l-7.413.984a.457.457,0,0,1-.5-.325l-2.038-7.316-.878,6.148a.448.448,0,0,1-.413.386Z"
            data-name="Trazado 4427"
            fill="#231f20"
            id="Trazado_4427"
            transform="translate(-27.857 -3.955)"
          />
          <path
            d="M252.746,174.3c-17.082-18.3-46.347-19.928-63.851-19.928s-46.768,1.625-63.851,19.928-16.266,45.143-16.266,45.143,40.673,10.135,80.116,10.135,80.116-10.162,80.116-10.162S269.82,192.6,252.746,174.3Z"
            data-name="Trazado 4428"
            fill="#004dbc"
            id="Trazado_4428"
            transform="translate(-8.432 -11.554)"
          />
          <path
            d="M214.185,164.286l-11.664,40.3-4.8,16.608L192.9,204.564,181.25,164.286C189.146,161.941,206.29,161.941,214.185,164.286Z"
            data-name="Trazado 4429"
            fill="#f4f5fa"
            id="Trazado_4429"
            transform="translate(-17.254 -12.547)"
          />
          <ellipse
            cx="29.958"
            cy="34.341"
            fill="#fff"
            rx="29.958"
            ry="34.341"
            transform="translate(150.927 59.907)"
          />
          <path
            d="M196.265,129.036c-16.766,0-30.415-15.607-30.415-34.789,0-20.376,2.187-34.8,30.415-34.8s30.415,14.421,30.415,34.8C226.679,113.429,213.04,129.036,196.265,129.036Zm0-68.681c-27.455,0-29.51,13.42-29.51,33.893,0,18.681,13.244,33.884,29.51,33.884s29.51-15.2,29.51-33.884c0-20.473-2.055-33.893-29.51-33.893Z"
            data-name="Trazado 4430"
            fill="#231f20"
            id="Trazado_4430"
            transform="translate(-15.379 0)"
          />
          <path
            d="M222,111.415c1.458-9.143,16.643-11.418,15.888-.615-.255,3.7-1.44,7.474-4.04,10.118s-6.8,3.9-10.188,2.389"
            data-name="Trazado 4431"
            fill="#fff"
            id="Trazado_4431"
            transform="translate(-22.214 -5.373)"
          />
          <path
            d="M226.785,124.353a8.23,8.23,0,0,1-3.355-.694.465.465,0,0,1-.228-.606.457.457,0,0,1,.6-.228c3.039,1.37,7.026.422,9.661-2.284,2.872-2.925,3.715-6.965,3.917-9.837.263-3.785-1.59-6.28-4.953-6.666-4.119-.474-9.248,2.45-10.039,7.386a.465.465,0,0,1-.527.378.439.439,0,0,1-.369-.518c.878-5.445,6.534-8.651,11.031-8.142,3.021.343,6.148,2.529,5.761,7.623-.211,3.03-1.107,7.29-4.172,10.408a10.478,10.478,0,0,1-7.325,3.179Z"
            data-name="Trazado 4432"
            fill="#231f20"
            id="Trazado_4432"
            transform="translate(-22.151 -5.312)"
          />
          <path
            d="M176.793,111.415c-1.467-9.143-16.643-11.418-15.9-.615.263,3.7,1.449,7.474,4.049,10.118s6.8,3.9,10.179,2.389"
            data-name="Trazado 4433"
            fill="#fff"
            id="Trazado_4433"
            transform="translate(-14.773 -5.373)"
          />
          <path
            d="M171.841,124.353a10.452,10.452,0,0,1-7.334-3.179c-3.065-3.118-3.961-7.378-4.172-10.408-.36-5.094,2.74-7.281,5.753-7.623,4.514-.509,10.162,2.7,11.04,8.142a.457.457,0,1,1-.878.141c-.79-4.936-5.92-7.9-10.039-7.386-3.373.386-5.217,2.881-4.953,6.666.193,2.872,1.036,6.912,3.917,9.837,2.635,2.705,6.64,3.645,9.661,2.284a.448.448,0,0,1,.6.228.457.457,0,0,1-.228.606,8.229,8.229,0,0,1-3.364.694Z"
            data-name="Trazado 4434"
            fill="#231f20"
            id="Trazado_4434"
            transform="translate(-14.705 -5.312)"
          />
          <path
            d="M181.25,159.661s4.11,8.95,16.09,8.95a19.311,19.311,0,0,0,16.845-8.95l-1.6-35.131H182.848Z"
            data-name="Trazado 4435"
            fill="#fff"
            id="Trazado_4435"
            transform="translate(-17.254 -7.922)"
          />
          <path
            d="M197.281,169.005c-12.129,0-16.459-9.125-16.5-9.213a.448.448,0,0,1,0-.211l1.6-35.131a.448.448,0,0,1,.448-.43h29.738a.448.448,0,0,1,.448.43l1.6,35.131a.378.378,0,0,1-.053.237A19.963,19.963,0,0,1,197.281,169.005Zm-15.633-9.5c.483.94,4.751,8.59,15.633,8.59a19.1,19.1,0,0,0,16.389-8.607l-1.581-34.6h-28.86Z"
            data-name="Trazado 4436"
            fill="#231f20"
            id="Trazado_4436"
            transform="translate(-17.195 -7.86)"
          />
          <path
            d="M183,141.139c2.064,3.32,5.489,5.928,8.985,7.9,5.893,3.258,14.7,6.921,21.843,5.832l-1.168-14.86a67.512,67.512,0,0,1-15.809.105c-4.611-.4-9.371-.878-13.6-2.635C182.55,137.125,182.058,139.69,183,141.139Z"
            data-name="Trazado 4437"
            fill="#dadada"
            id="Trazado_4437"
            transform="translate(-17.403 -9.495)"
          />
          <path
            d="M211.047,155.439c-7.167,0-15-3.689-19.322-6.078s-7.281-5.041-9.143-8.027a4.189,4.189,0,0,1-.07-4.084.747.747,0,0,1,.878-.228c4.242,1.757,9.134,2.213,13.464,2.591a66.211,66.211,0,0,0,15.668-.1.457.457,0,0,1,.334.088.474.474,0,0,1,.158.307l1.177,14.86a.43.43,0,0,1-.369.465A18.349,18.349,0,0,1,211.047,155.439Zm-27.912-17.566a3.513,3.513,0,0,0,.176,3.012h0c1.757,2.863,4.751,5.463,8.835,7.729,4.716,2.635,13.657,6.78,21.158,5.832L212.2,140.465a68.286,68.286,0,0,1-15.4,0c-4.365-.386-9.3-.834-13.657-2.591ZM183.056,137.874Z"
            data-name="Trazado 4438"
            fill="#231f20"
            id="Trazado_4438"
            transform="translate(-17.347 -9.435)"
          />
          <path
            d="M196.186,136.816h2.442a20.964,20.964,0,0,0,20.964-20.964V82.67s-7.29,2.1-22.185,2.1-22.176-2.1-22.176-2.1v33.181a20.964,20.964,0,0,0,20.956,20.964Z"
            data-name="Trazado 4439"
            fill="#fff"
            id="Trazado_4439"
            transform="translate(-16.521 -2.826)"
          />
          <path
            d="M219.466,82.543v33.181A20.964,20.964,0,0,1,198.5,136.689H196.06A20.964,20.964,0,0,1,175.1,115.725V82.543s7.29,2.1,22.176,2.1,22.185-2.1,22.185-2.1M175.1,81.665a.94.94,0,0,0-.545.176.878.878,0,0,0-.36.729v33.181a21.887,21.887,0,0,0,21.86,21.869H198.5a21.9,21.9,0,0,0,21.869-21.869V82.543a.878.878,0,0,0-.36-.729.94.94,0,0,0-.545-.176.878.878,0,0,0-.255,0c-.07,0-7.369,2.055-21.957,2.055s-21.86-2.038-21.957-2.055a.79.79,0,0,0-.246,0Z"
            data-name="Trazado 4440"
            fill="#231f20"
            id="Trazado_4440"
            transform="translate(-16.396 -2.7)"
          />
          <path
            d="M199.217,184.072l-5.647-4.356,5.647-4.356,5.647,4.356Z"
            data-name="Trazado 4441"
            fill="#fff"
            id="Trazado_4441"
            transform="translate(-18.753 -14.109)"
          />
          <path
            d="M204.138,206.962l-4.8,16.608-4.8-16.635,2.9-24.416.061-.509h3.689l.053.527Z"
            data-name="Trazado 4442"
            fill="#fff"
            id="Trazado_4442"
            transform="translate(-18.87 -14.918)"
          />
          <path
            d="M147.416,227.389a.448.448,0,0,1-.448-.4L142.41,182.57a.448.448,0,0,1,.4-.5.465.465,0,0,1,.492.4l4.558,44.414a.457.457,0,0,1-.4.5Z"
            data-name="Trazado 4443"
            fill="#231f20"
            id="Trazado_4443"
            transform="translate(-12.526 -14.926)"
          />
          <path
            d="M251.834,227.389h0a.457.457,0,0,1-.4-.5l4.558-44.414a.466.466,0,0,1,.492-.4.448.448,0,0,1,.4.5l-4.558,44.414a.448.448,0,0,1-.492.4Z"
            data-name="Trazado 4444"
            fill="#231f20"
            id="Trazado_4444"
            transform="translate(-25.796 -14.926)"
          />
          <path
            d="M108.77,219.208h0a.448.448,0,0,1-.43-.474c0-.343,1.757-34.736,20.429-48.6,16.96-12.612,43.317-15.493,43.58-15.519a.457.457,0,0,1,.088.878c-.255,0-26.348,2.881-43.132,15.343-18.286,13.6-20.051,47.567-20.06,47.91a.457.457,0,0,1-.474.457Z"
            data-name="Trazado 4445"
            fill="#231f20"
            id="Trazado_4445"
            transform="translate(-8.379 -11.584)"
          />
          <path
            d="M282.335,219.208a.457.457,0,0,1-.457-.43c0-.343-1.757-34.305-20.069-47.91-16.758-12.463-42.869-15.317-43.132-15.343a.457.457,0,1,1,.1-.878c.263,0,26.621,2.907,43.58,15.519,18.637,13.859,20.411,48.253,20.429,48.6a.466.466,0,0,1-.43.474Z"
            data-name="Trazado 4446"
            fill="#231f20"
            id="Trazado_4446"
            transform="translate(-21.746 -11.584)"
          />
        </g>
        <g data-name="Grupo 6951" id="Grupo_6951" transform="translate(-109.103 7.995)">
          <circle
            cx="26.522"
            cy="26.522"
            fill="#fff"
            r="26.522"
            transform="translate(1143.453 746.322)"
          />
          <path
            d="M33.943,3.375A30.568,30.568,0,1,0,64.51,33.943,30.563,30.563,0,0,0,33.943,3.375Zm13.3,32.919H36.294V47.242a2.351,2.351,0,0,1-4.7,0V36.294H20.643a2.351,2.351,0,0,1,0-4.7H31.591V20.643a2.351,2.351,0,0,1,4.7,0V31.591H47.242a2.351,2.351,0,0,1,0,4.7Z"
            data-name="Icon ionic-ios-add-circle"
            fill="#fc7e00"
            id="Icon_ionic-ios-add-circle"
            transform="translate(1136.552 739.057)"
          />
        </g>
      </g>
    </svg>
  );
};
