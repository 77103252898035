import classNames from 'classnames';
import { memo, ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  fluid?: 'fluid' | 'screen' | 'card';
  noFullWidth?: boolean;
  fullHeight?: boolean;
  id?: string;
  background?: 'white' | 'gray';
  noPaddingY?: boolean;
  className?: string;
}

const Container = ({
  children,
  fluid = 'card',
  noFullWidth = false,
  id,
  fullHeight,
  background,
  noPaddingY = false,
  className,
}: ContainerProps) => {
  const classes = classNames('relative flex flex-1 flex-col', {
    'w-full': !noFullWidth,
    'py-8': fluid === 'fluid',
    'px-3 lg:px-4 2xl:px-12': fluid === 'card',
    'px-[1.7%] py-6 2xl:px-[3%] 2xl:py-8': fluid === 'screen',
    'h-full': fullHeight,
    'bg-emblueLightGray': background === 'gray',
    'bg-emblue-white': background === 'white',
    '!py-0': noPaddingY,
  });

  const screenClasses = classNames('relative size-full flex flex-col', className);

  if (fluid === 'screen') {
    return (
      <div className={screenClasses} data-testid="container-component" id={id}>
        <div className={classes}>{children}</div>
      </div>
    );
  }

  return <div className={classes}>{children}</div>;
};

export default memo(Container);
