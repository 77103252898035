import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Text } from '@/lib/v2/components/Text';
import { Tooltip } from '@/lib/v2/components/Tooltip';
import { SuccessIcon } from '@/lib/v2/icons/outline';
import { CopyIcon } from '@/lib/v2/icons/solid';

import { copy } from '@/src/utils/Copy';

interface CopyToClipboardProps {
  value: string;
  copiedText?: string;
  copyText?: string;
  color?: 'dark' | 'light';
  id?: string;
}

const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { t } = useTranslation();
  const {
    value,
    color = 'dark',
    copiedText = t('CopyToClipboard.TOOLTIP.copied'),
    copyText = t('CopyToClipboard.TOOLTIP.copy'),
    id,
  } = props;
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const timeoutRef = useRef<number | null>(null);

  const handleCopyToClipboard = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
      event.preventDefault();
      event.stopPropagation();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      await copy(value);
      setIsCopied(true);
      timeoutRef.current = window.setTimeout(() => setIsCopied(false), 1500);
    },
    []
  );

  return (
    <div className="flex">
      <Tooltip
        alignment="center"
        content={
          <Text alignment="center" color="dark" variant="text-sm">
            {isCopied ? copiedText : copyText}
          </Text>
        }
        position="top"
      >
        <button
          className="flex items-center"
          {...(id && { id })}
          onClick={(event) => void handleCopyToClipboard(event, value)}
        >
          <IconSvg
            height="15px"
            {...(color === 'light' && !isCopied && { fillColor: 'white' })}
            svgComponent={isCopied ? <SuccessIcon /> : <CopyIcon color={'#5E6984'} />}
            width="15px"
          />
        </button>
      </Tooltip>
    </div>
  );
};

export default memo(CopyToClipboard);
