export const CloudComputingErrorIcon = () => {
  return (
    <svg
      fill="none"
      height="81"
      viewBox="0 0 103 81"
      width="103"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_184_3963)">
        <path
          d="M85.8174 26.8373C83.7679 18.661 78.8777 11.4834 72.0239 6.59159C65.17 1.6998 56.8006 -0.586243 48.4165 0.143387C40.0325 0.873017 32.1821 4.57059 26.273 10.5731C20.3639 16.5756 16.7827 24.4904 16.1715 32.8983C11.3748 33.683 7.04995 36.2508 4.06003 40.0891C1.07012 43.9275 -0.364903 48.7541 0.0413789 53.6056C0.447661 58.4572 2.66536 62.9769 6.25186 66.2626C9.83837 69.5483 14.5299 71.3585 19.39 71.3317H35.4816V64.8797H19.39C17.69 64.8945 16.0039 64.5719 14.4291 63.9305C12.8542 63.2891 11.4217 62.3415 10.2144 61.1426C9.00701 59.9436 8.04867 58.517 7.39466 56.9451C6.74066 55.3731 6.40394 53.687 6.40394 51.984C6.40394 50.281 6.74066 48.5948 7.39466 47.0229C8.04867 45.451 9.00701 44.0243 10.2144 42.8254C11.4217 41.6264 12.8542 40.6789 14.4291 40.0375C16.0039 39.3961 17.69 39.0735 19.39 39.0883C20.2442 39.0883 21.0633 38.7484 21.6673 38.1434C22.2712 37.5384 22.6105 36.7179 22.6105 35.8623C22.605 28.6538 25.2784 21.7013 30.1099 16.3592C34.9415 11.017 41.5851 7.6677 48.7468 6.96359C55.9085 6.25949 63.0754 8.25103 68.8516 12.5503C74.6277 16.8496 78.5995 23.1487 79.9932 30.2207C80.1223 30.8828 80.4554 31.4876 80.9455 31.9502C81.4357 32.4128 82.0583 32.7099 82.7257 32.7995C86.753 33.369 90.4156 35.4451 92.9768 38.61C95.538 41.775 96.8077 45.794 96.5306 49.8588C96.2535 53.9235 94.4501 57.7324 91.4832 60.5191C88.5163 63.3059 84.6059 64.8639 80.5387 64.8797H67.6657V71.3317H80.5387C86.042 71.3157 91.349 69.2824 95.4583 65.6156C99.5676 61.9487 102.195 56.902 102.844 51.4278C103.494 45.9536 102.121 40.4306 98.9838 35.9011C95.847 31.3716 91.1635 28.1489 85.8174 26.8412V26.8373Z"
          fill="url(#paint0_linear_184_3963)"
        />
        <path
          clipRule="evenodd"
          d="M66.3678 57.376L61.6513 52.6514L52.3802 61.9383L43.1092 52.6514L38.3927 57.376L47.6637 66.663L38.0668 76.2755L42.7833 81.0001L52.3841 71.3876L61.984 81.004L66.7006 76.2755L57.1007 66.659L66.3678 57.376Z"
          fill="url(#paint1_linear_184_3963)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_184_3963"
          x1="51.4878"
          x2="51.4878"
          y1="0.0102539"
          y2="71.332"
        >
          <stop stopColor="#3C8CFF" />
          <stop offset="1" stopColor="#044C8A" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_184_3963"
          x1="52.3837"
          x2="52.3837"
          y1="52.6514"
          y2="81.004"
        >
          <stop stopColor="#FF0000" />
          <stop offset="1" stopColor="#611010" />
        </linearGradient>
        <clipPath id="clip0_184_3963">
          <rect fill="white" height="81" width="103" />
        </clipPath>
      </defs>
    </svg>
  );
};
