import { Flex } from '@/lib/v2/components/Layout/Stack';

import withDeprecated from '@/src/application/HOCs/WithDeprecated';

const _FloatModal = ({
  children,
  id = '',
}: {
  children: JSX.Element[] | JSX.Element | string;
  id?: string;
}) => (
  <div className="relative z-[9999]">
    <div className="bg-opacity/75 fixed inset-0 bg-gray-500 transition-opacity"></div>
    <div
      className="fixed inset-0 z-10 overflow-y-auto"
      data-testid={`v1modal-component--${id}`}
      id={id}
    >
      <Flex alignment="center" itemAlignment="center">
        {typeof children === 'string' ? <span>{children}</span> : children}
      </Flex>
    </div>
  </div>
);

/**
 * @deprecated use new Modal version 2. import { Modal } from "@lib/v2/components";
 */
const FloatModal = withDeprecated(
  _FloatModal,
  'use new Modal component v2 -> import { Modal } from "@/lib/v2/components";'
);

export default FloatModal;
