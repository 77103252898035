export const SuspendedContactIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.752 4.22197C18.2158 2.68354 16.2585 1.63587 14.1278 1.2114C11.997 0.78692 9.78827 1.00475 7.7811 1.8373C5.77392 2.66985 4.05851 4.07975 2.8515 5.8887C1.64449 7.69765 1 9.82438 1 12C1 14.1756 1.64449 16.3024 2.8515 18.1113C4.05851 19.9203 5.77392 21.3301 7.7811 22.1627C9.78827 22.9952 11.997 23.2131 14.1278 22.7886C16.2585 22.3641 18.2158 21.3165 19.752 19.778C20.7731 18.7693 21.5856 17.5688 22.1429 16.2453C22.7002 14.9218 22.9913 13.5013 22.9998 12.065C23.0083 10.6286 22.734 9.20461 22.1925 7.87458C21.6509 6.54455 20.8527 5.33453 19.8437 4.31373C19.7978 4.26565 19.7979 4.22197 19.752 4.22197ZM13.4371 16.3627L11.9619 14.8855L10.4868 16.3627C10.2841 16.5657 10.0434 16.7267 9.77856 16.8365C9.51367 16.9464 9.22995 17.003 8.94324 17.003C8.65653 17.003 8.37255 16.9464 8.10766 16.8365C7.84277 16.7267 7.60213 16.5657 7.3994 16.3627C7.19666 16.1597 7.03579 15.9185 6.92607 15.6533C6.81635 15.388 6.75986 15.1038 6.75986 14.8167C6.75986 14.5295 6.81635 14.2453 6.92607 13.98C7.03579 13.7148 7.19666 13.4736 7.3994 13.2706L8.87452 11.7935L7.3994 10.3163C7.19666 10.1133 7.03579 9.87233 6.92607 9.60707C6.81635 9.34181 6.75986 9.05742 6.75986 8.7703C6.75986 8.48318 6.81635 8.19893 6.92607 7.93367C7.03579 7.66841 7.19666 7.42742 7.3994 7.2244C7.60213 7.02138 7.84277 6.86028 8.10766 6.7504C8.37255 6.64053 8.65653 6.58396 8.94324 6.58396C9.22995 6.58396 9.51367 6.64053 9.77856 6.7504C10.0434 6.86028 10.2841 7.02138 10.4868 7.2244L11.9619 8.70148L13.4371 7.2244C13.8465 6.81438 14.4019 6.58396 14.9809 6.58396C15.56 6.58396 16.1153 6.81438 16.5248 7.2244C16.9342 7.63442 17.164 8.19044 17.164 8.7703C17.164 9.35016 16.9342 9.90631 16.5248 10.3163L15.0496 11.7935L16.5248 13.2706C16.9342 13.6806 17.164 14.2368 17.164 14.8167C17.164 15.3965 16.9342 15.9527 16.5248 16.3627C16.1153 16.7727 15.56 17.003 14.9809 17.003C14.4019 17.003 13.8465 16.7727 13.4371 16.3627Z"
        fill="#FF715B"
      />
    </svg>
  );
};
