export const SmsSimple = () => {
  return (
    <svg
      className="w-8 fill-current text-emblue-blue-dark group-hover:fill-current group-hover:text-emblue-white group-focus:fill-current group-focus:text-white sm:w-8 md:w-10 lg:w-12 xl:w-16"
      viewBox="0 0 51.98 47.433"
    >
      <g data-name="Group 39">
        <path
          d="M12.129 0a5.225 5.225 0 0 0-5.2 5.2v1.733H5.196a5.224 5.224 0 0 0-5.2 5.2v20.792a5.208 5.208 0 0 0 5.2 5.2l1.949-.107v9.421l2.924-2.924 6.5-6.5 23.283.107a5.222 5.222 0 0 0 5.2-5.2V31.19h1.733a5.225 5.225 0 0 0 5.2-5.2V5.198a5.225 5.225 0 0 0-5.2-5.2H12.131Zm0 3.465h34.654a1.692 1.692 0 0 1 1.733 1.733V25.99a1.692 1.692 0 0 1-1.733 1.733H45.05V12.129a5.226 5.226 0 0 0-5.2-5.2H10.394V5.196a1.692 1.692 0 0 1 1.733-1.733Zm-3.9 10.288a1.647 1.647 0 0 1 .217 0 1.8 1.8 0 0 1 .217 0 1.668 1.668 0 0 1 .217 0h13.861a1.733 1.733 0 1 1 0 3.465H8.88a1.763 1.763 0 1 1-.65-3.465Zm0 6.931a1.69 1.69 0 0 1 .217 0 1.69 1.69 0 0 1 .217 0 1.724 1.724 0 0 1 .217 0h27.723a1.733 1.733 0 1 1 0 3.465H8.88a1.763 1.763 0 1 1-.65-3.465Zm0 6.931a1.669 1.669 0 0 1 .217 0 1.669 1.669 0 0 1 .217 0 1.713 1.713 0 0 1 .217 0h20.792a1.733 1.733 0 1 1 0 3.465H8.88a1.763 1.763 0 1 1-.65-3.466Z"
          data-name="Path 16"
        />
      </g>
    </svg>
  );
};
