import { memo, ReactNode } from 'react';

import StepperItem, { StatusStepperItem, StepperItemProps } from './StepperItem';
import StepperItemHorizontal from './StepperItemHorizontal';

import './Stepper.tailwind.css';

export type Step = Omit<StepperItemProps, 'status'> & {
  children?: ReactNode;
  onClick?: () => void;
};

export type Steps = Step[];

export interface StepperProps {
  currentStep: number;
  steps: Steps;
  isLoading?: boolean;
  isHorizontal?: boolean;
}

const Stepper = ({ steps, currentStep, isLoading = false, isHorizontal = false }: StepperProps) => {
  if (isLoading) {
    return (
      <div className="flex w-full animate-pulse flex-col gap-4">
        <div className="flex w-full flex-col gap-2">
          <div className="h-3 w-10 rounded-md bg-gray-200"></div>
          <div className="h-6 w-20 rounded-md bg-gray-200"></div>
          <div className="h-4 w-2/3 rounded-md bg-gray-200"></div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <div className="h-3 w-10 rounded-md bg-gray-200"></div>
          <div className="h-6 w-20 rounded-md bg-gray-200"></div>
          <div className="h-4 w-2/3 rounded-md bg-gray-200"></div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <div className="h-3 w-10 rounded-md bg-gray-200"></div>
          <div className="h-6 w-20 rounded-md bg-gray-200"></div>
          <div className="h-4 w-2/3 rounded-md bg-gray-200"></div>
        </div>
      </div>
    );
  }

  const getStatus = (stepNumber: number) => {
    let status: StatusStepperItem;
    if (stepNumber < currentStep || currentStep === 0) status = 'done';
    else if (stepNumber === currentStep) status = 'current';
    else status = 'upcoming';
    return status;
  };

  if (isHorizontal) {
    return (
      <div className="flex flex-col">
        <div className="flex w-full justify-between">
          {steps.map((step, index) => {
            const { id, ...restProps } = step;
            const stepNumber: number = index + 1;
            const status = getStatus(stepNumber);

            return <StepperItemHorizontal key={id} id={id} status={status} {...restProps} />;
          })}
        </div>
        <div className="relative w-full">
          {steps.map((step, index) => {
            const { children } = step;
            const stepNumber: number = index + 1;
            const status = getStatus(stepNumber);

            if (status === 'current') return children;
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {steps.map((step, index) => {
        const { id, ...restProps } = step;
        const stepNumber: number = index + 1;
        const status = getStatus(stepNumber);

        return <StepperItem key={id} id={id} status={status} {...restProps} />;
      })}
    </div>
  );
};

export default memo(Stepper);
