export const DuplicateIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <mask height="16" id="mask0_1446_11241" maskUnits="userSpaceOnUse" width="16" x="0" y="0">
        <rect fill="#D9D9D9" height="16" width="16" />
      </mask>
      <g mask="url(#mask0_1446_11241)">
        <path
          d="M6.18621 12.6927C5.68765 12.6927 5.26279 12.5211 4.91163 12.178C4.56047 11.8349 4.38489 11.4198 4.38489 10.9327V1.76003C4.38489 1.27289 4.56047 0.857771 4.91163 0.514663C5.26279 0.171554 5.68765 0 6.18621 0H13.1987C13.6972 0 14.1221 0.171554 14.4733 0.514663C14.8244 0.857771 15 1.27289 15 1.76003V10.9327C15 11.4198 14.8244 11.8349 14.4733 12.178C14.1221 12.5211 13.6972 12.6927 13.1987 12.6927H6.18621ZM2.80132 16C2.30276 16 1.87789 15.8284 1.52674 15.4853C1.17558 15.1422 1 14.7271 1 14.24V4.18732C1 3.9404 1.0869 3.73197 1.2607 3.56202C1.43463 3.39221 1.64795 3.3073 1.90066 3.3073C2.15337 3.3073 2.36669 3.39221 2.54062 3.56202C2.71442 3.73197 2.80132 3.9404 2.80132 4.18732V14.24H10.7144C10.9672 14.24 11.1805 14.3249 11.3544 14.4947C11.5282 14.6646 11.6151 14.8731 11.6151 15.12C11.6151 15.3669 11.5282 15.5753 11.3544 15.7453C11.1805 15.9151 10.9672 16 10.7144 16H2.80132Z"
          fill="#5E6984"
        />
      </g>
    </svg>
  );
};
