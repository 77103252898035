import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { atomIframeTransport } from '@/src/compat/atoms/IframeTransport.atoms';

export const isIframeReady = () => {
  const main = document.querySelector('#v1-container-parent') as HTMLElement;
  const iframe = document.querySelector('#v1-container') as HTMLElement;
  const container = document.querySelector('#iframe-section .container-iframe') as HTMLElement;

  if (container?.innerHTML !== '' || !iframe || !container || !main) return;

  return true;
};

export const useIframeEmbeddedStart = () => {
  const [iframeTransport, setIframeTransport] = useAtom(atomIframeTransport);

  const getIframeStyles = useCallback(() => {
    const iframe = document.querySelector('#v1-container') as HTMLElement;
    const height = window.getComputedStyle(iframe).height;

    setIframeTransport({
      position: iframe.style.position,
      inset: iframe.style.inset,
      marginTop: iframe.style.marginTop,
      width: iframe.style.width,
      height: height,
      top: iframe.style.top,
      left: iframe.style.left,
    });
  }, [setIframeTransport]);

  const startIframe = useCallback(() => {
    if (Object.keys(iframeTransport).length === 0) return;

    // Elements
    const containerTransport = document.querySelector(
      '#iframe-section .container-iframe'
    ) as HTMLElement;
    const containerIframe = document.querySelector('#v1-container-parent') as HTMLElement;
    const iframe = document.querySelector('#v1-container') as HTMLElement;
    const main = document.querySelector('#v1-container-parent') as HTMLElement;

    // Width of the card of the action
    const width = window.getComputedStyle(containerTransport).width;

    // Remove z-index conflicted
    document.querySelector('._app__container')?.setAttribute('style', 'position: relative;');

    // Clear styles of iframe
    iframe.setAttribute('style', '');
    main.setAttribute('style', '');

    // Positions
    const rect = containerTransport.getBoundingClientRect();

    // Assign styles of container of iframe
    containerIframe.style.top = rect.top.toString() + 'px';
    containerIframe.style.left = rect.left.toString() + 'px';
    containerIframe.style.width = width;
    containerIframe.style.height = iframeTransport.height;
    containerIframe.style.position = 'absolute';
    // Assign class of sizes to iframe
    iframe.classList.add('w-full');
    iframe.classList.add('h-full');
    // Assign styles to iframe
    iframe.style.position = 'relative';
    // Assign styles of the card of the action
    containerTransport.style.height = iframeTransport.height;
  }, [iframeTransport]);

  useEffect(() => {
    if (!isIframeReady()) return;
    getIframeStyles();
  }, [getIframeStyles]);

  useEffect(() => {
    const handler = () => {
      startIframe();
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [startIframe]);

  return {
    startIframe,
  };
};

export const useIframeEmbeddedEnd = () => {
  const [iframeTransport, setIframeTransport] = useAtom(atomIframeTransport);

  const recoveryIframe = useCallback(() => {
    if (Object.keys(iframeTransport).length === 0) return;

    const containerIframe = document.querySelector('#v1-container-parent') as HTMLElement;
    const iframe = document.querySelector('#v1-container') as HTMLIFrameElement;
    const iframeBody = iframe.contentDocument || iframe?.contentWindow?.document;
    const main = iframeBody?.querySelector('#main') as HTMLElement;
    const topNav = iframeBody?.querySelector('#h-top') as HTMLElement;

    containerIframe.setAttribute('style', 'position:relative;');
    iframe.setAttribute('style', '');
    iframe.setAttribute('class', '');
    // Menu show
    if (topNav) topNav.style.display = 'block';
    // Clear styles for main
    if (main) main.classList.remove('embedded');

    for (const style of Object.keys(iframeTransport)) {
      if (iframe && typeof style === 'string' && style !== 'zIndex') {
        const styleName = style as keyof typeof iframeTransport;
        const styleValue = iframeTransport[styleName];
        if (iframe.style) {
          (iframe.style as any)[styleName] = styleValue;
        }
      }
    }

    // Remove z-index conflicted
    document
      .querySelector('._app__container')
      ?.setAttribute('style', 'position: relative;z-index:999;');

    setIframeTransport({});
  }, [iframeTransport, setIframeTransport]);

  return {
    recoveryIframe,
  };
};
