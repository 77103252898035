import { Spinner } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

export type ModalProps = {
  children?: React.ReactNode;
  isFloat?: boolean;
  show?: boolean;
  id?: string;
};

const Loading = ({ show = true, id = '' }: ModalProps) => {
  return show ? (
    <div className="absolute top-0 z-[9999] h-full w-full overflow-hidden">
      <div className="bg-opacity/75 absolute inset-0 bg-gray-500 transition-opacity"></div>
      <div
        className="absolute inset-0 z-[9999] overflow-y-auto"
        data-testid={`v1modal-component--${id}`}
        id={id}
      >
        <Flex alignment="center" itemAlignment="center">
          <div className={`relative z-[99999]`}>
            <div className=" bg-gray-500 transition-opacity"></div>
            <div id={id}>
              <Flex alignment="center" itemAlignment="center">
                <div className={`relative z-10 rounded-l text-left shadow-xl transition-all`}>
                  <Spinner withoutOverlay />
                </div>
              </Flex>
            </div>
          </div>
        </Flex>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
