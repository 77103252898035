import { useTranslation } from 'react-i18next';

import { Card, IconSvg, Text } from '@/lib/v2/components';
import { HappyFace, NeutralFace, SadFace } from '@/lib/v2/icons/outline';

import {
  NpsHappinessFilters,
  NpsStats,
} from '@/src/modules/ContactsModule/screens/HappinessMain/types';

import {
  useHappinessMutationContext,
  useHappinessStateContext,
} from '@/modules/ContactsModule/contexts/HappinessContext';

export const HappinessFilterCards = () => {
  const { t } = useTranslation();
  const { npsTotalStats, happinessFilter, isHappinessFilterApplied } = useHappinessStateContext();
  const { setHappinessFilter } = useHappinessMutationContext();

  const { detractors, passives, promoters } = npsTotalStats as unknown as NpsStats;

  const handleOnClick = (newFilter: NpsHappinessFilters[]) => {
    if (newFilter[0] === happinessFilter[0] && isHappinessFilterApplied) return;
    setHappinessFilter(newFilter);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      <Card className="!pl-4" id="detractors-card">
        <button className="grid grid-cols-auto-1fr" onClick={() => handleOnClick(['detractors'])}>
          <IconSvg
            fullWidth
            rounded
            bgColor="insecure"
            svgComponent={<SadFace />}
            withPadding="medium"
          />
          <div className="ml-4 flex flex-col">
            <Text className="font-medium text-emblue-primary">{detractors}</Text>
            <Text className="font-medium text-emblue-subheadings" variant="sub-headline">
              {t('HAPPINESS_DASHBOARD.detractors')}
            </Text>
          </div>
        </button>
      </Card>
      <Card className="!pl-4" id="passives-card">
        <button className="grid grid-cols-auto-1fr" onClick={() => handleOnClick(['passives'])}>
          <IconSvg
            fullWidth
            rounded
            bgColor="passiveHover"
            svgComponent={<NeutralFace />}
            withPadding="medium"
          />
          <div className="ml-4 flex flex-col">
            <Text className="font-medium text-emblue-primary">{passives}</Text>
            <Text className="font-medium text-emblue-subheadings" variant="sub-headline">
              {t('HAPPINESS_DASHBOARD.passives')}
            </Text>
          </div>
        </button>
      </Card>
      <Card className="!pl-4" id="promoters-card">
        <button className="grid grid-cols-auto-1fr" onClick={() => handleOnClick(['promoters'])}>
          <IconSvg
            fullWidth
            rounded
            bgColor="success"
            svgComponent={<HappyFace />}
            withPadding="medium"
          />

          <div className="ml-4 flex flex-col">
            <Text className="font-medium text-emblue-primary">{promoters}</Text>
            <Text className="font-medium text-emblue-subheadings" variant="sub-headline">
              {t('HAPPINESS_DASHBOARD.promoters')}
            </Text>
          </div>
        </button>
      </Card>
    </div>
  );
};
