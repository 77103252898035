export const CloseIcon = ({
  width,
  height,
  color = '#919296',
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  return (
    <svg
      data-name="Icon ionic-md-close"
      height={height}
      id="Icon_ionic-md-close"
      viewBox="0 0 20.953 20.953"
      width={width}
    >
      <path
        d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z"
        data-name="Icon ionic-md-close"
        fill={color}
        id="Icon_ionic-md-close-2"
        transform="translate(-7.523 -7.523)"
      />
    </svg>
  );
};
