export const EmptyActionIcon = () => {
  return (
    <svg
      fill="none"
      height="127"
      viewBox="0 0 226 127"
      width="226"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1720_7104)">
        <path
          d="M166.519 113.422C184.226 113.422 198.58 99.1189 198.58 81.4748C198.58 63.8307 184.226 49.5273 166.519 49.5273C148.812 49.5273 134.457 63.8307 134.457 81.4748C134.457 99.1189 148.812 113.422 166.519 113.422Z"
          fill="white"
        />
        <path
          d="M75.9098 110.966C69.7592 109.951 64.0591 107.117 59.5538 102.834C43.182 91.449 38.2728 87.3907 35 84.9511V119.09C35 121.247 35.862 123.315 37.3964 124.841C38.9309 126.366 41.012 127.222 43.182 127.222H108.614C110.784 127.222 112.865 126.366 114.4 124.841C115.934 123.315 116.796 121.247 116.796 119.09V84.9511C113.523 87.3907 108.614 91.4568 92.258 102.834C87.7548 107.116 82.0577 109.95 75.9098 110.966ZM108.614 62.1891H43.182C41.012 62.1891 38.9309 63.0458 37.3964 64.5709C35.862 66.0959 35 68.1644 35 70.3211V74.3872C41.5456 79.2664 40.7274 79.2664 64.4472 96.336C66.9018 97.9624 71.811 102.842 75.8941 102.842C79.9772 102.842 84.8942 97.9624 87.341 97.1492C111.061 80.0406 110.243 80.0406 116.82 75.1926V70.3211C116.82 69.2513 116.607 68.1919 116.195 67.2037C115.782 66.2155 115.177 65.318 114.415 64.5626C113.653 63.8071 112.748 63.2086 111.752 62.8013C110.757 62.394 109.691 62.186 108.614 62.1891ZM174.038 37.8162H92.2502C90.0802 37.8162 87.999 38.673 86.4646 40.1981C84.9302 41.7231 84.0682 43.7916 84.0682 45.9483V54.0804H108.614C112.678 54.0843 116.595 55.5893 119.604 58.3031C122.614 61.0169 124.501 64.7457 124.899 68.7651L124.97 68.7104V102.85H174.046C176.216 102.85 178.297 101.993 179.832 100.468C181.366 98.9426 182.228 96.8742 182.228 94.7174V45.9327C182.224 43.7773 181.359 41.7117 179.823 40.1898C178.288 38.6679 176.207 37.8142 174.038 37.8162ZM165.856 70.329H149.508V54.057H165.864L165.856 70.329ZM75.9098 45.9327C75.914 41.6245 77.6361 37.4936 80.699 34.445C83.7618 31.3965 87.9155 29.6788 92.2502 29.6685H149.5V5.28786C149.5 3.13244 148.639 1.0652 147.107 -0.45964C145.574 -1.98448 143.495 -2.84217 141.326 -2.84424L59.538 -2.84424C57.368 -2.84424 55.2869 -1.98747 53.7525 -0.462403C52.2181 1.06266 51.3561 3.13109 51.3561 5.28786V54.057H75.9098V45.9327Z"
          fill="#6694D7"
        />
        <path
          d="M205.977 108.292C205.626 108.292 205.289 108.154 205.04 107.907L199.182 102.063C199.055 101.941 198.954 101.796 198.884 101.635C198.815 101.474 198.778 101.301 198.776 101.125C198.774 100.95 198.808 100.776 198.874 100.614C198.941 100.452 199.039 100.305 199.163 100.181C199.288 100.057 199.436 99.9588 199.599 99.8925C199.761 99.8263 199.936 99.793 200.112 99.7947C200.288 99.7965 200.461 99.8331 200.623 99.9026C200.784 99.972 200.93 100.073 201.052 100.199L206.914 106.04C207.161 106.287 207.301 106.622 207.301 106.972C207.301 107.321 207.161 107.656 206.914 107.903C206.791 108.027 206.646 108.125 206.485 108.192C206.324 108.259 206.151 108.293 205.977 108.292Z"
          fill="#002646"
        />
        <circle cx="178.5" cy="79.6558" fill="white" r="30.5" />
        <path
          d="M219.387 127C218.519 127.002 217.658 126.832 216.856 126.501C216.053 126.17 215.324 125.684 214.711 125.07L205.453 115.845C204.214 114.609 203.517 112.933 203.517 111.186C203.517 109.438 204.214 107.762 205.453 106.526C206.736 105.373 208.401 104.735 210.128 104.735C211.855 104.735 213.521 105.373 214.803 106.526L224.061 115.75C224.987 116.671 225.618 117.846 225.873 119.124C226.129 120.402 225.999 121.728 225.498 122.932C224.998 124.137 224.15 125.166 223.062 125.89C221.975 126.614 220.696 127.001 219.387 127ZM210.13 107.285C209.348 107.278 208.582 107.504 207.93 107.933C207.278 108.362 206.768 108.975 206.467 109.693C206.166 110.412 206.087 111.204 206.24 111.968C206.392 112.731 206.77 113.432 207.324 113.981L216.582 123.206C217.338 123.925 218.343 124.325 219.387 124.325C220.432 124.325 221.437 123.925 222.193 123.206C222.936 122.465 223.354 121.459 223.354 120.411C223.354 119.362 222.936 118.357 222.193 117.615L212.935 108.391C212.182 107.669 211.174 107.272 210.13 107.285ZM178.389 111.186C172.111 111.186 165.974 109.331 160.755 105.856C155.535 102.38 151.466 97.4407 149.064 91.6615C146.662 85.8822 146.033 79.5229 147.258 73.3876C148.483 67.2524 151.506 61.6168 155.945 57.1936C160.384 52.7703 166.039 49.758 172.196 48.5377C178.354 47.3173 184.736 47.9436 190.535 50.3375C196.335 52.7313 201.293 56.7852 204.78 61.9864C208.268 67.1876 210.13 73.3025 210.13 79.5579C210.12 87.9434 206.773 95.9827 200.823 101.912C194.872 107.841 186.804 111.177 178.389 111.186ZM178.389 50.5656C172.634 50.5656 167.009 52.266 162.224 55.4517C157.439 58.6374 153.71 63.1654 151.508 68.4631C149.306 73.7607 148.729 79.5901 149.852 85.2141C150.975 90.838 153.746 96.004 157.815 100.059C161.884 104.113 167.068 106.875 172.712 107.993C178.356 109.112 184.207 108.538 189.523 106.343C194.84 104.149 199.384 100.433 202.581 95.6652C205.778 90.8975 207.484 85.2921 207.484 79.5579C207.475 71.8715 204.407 64.5025 198.952 59.0673C193.498 53.6322 186.103 50.5747 178.389 50.5656Z"
          fill="#002646"
        />
        <path
          d="M181.034 87.5202C180.683 87.52 180.347 87.3811 180.099 87.134C179.851 86.8869 179.711 86.5518 179.711 86.2023C179.711 85.8528 179.572 85.5176 179.324 85.2705C179.076 85.0234 178.739 84.8845 178.389 84.8845C178.038 84.8845 177.702 85.0234 177.454 85.2705C177.205 85.5176 177.066 85.8528 177.066 86.2023C177.066 86.5519 176.927 86.8871 176.679 87.1342C176.431 87.3813 176.094 87.5202 175.744 87.5202C175.393 87.5202 175.056 87.3813 174.808 87.1342C174.56 86.8871 174.421 86.5519 174.421 86.2023C174.421 85.1538 174.839 84.1482 175.583 83.4068C176.327 82.6654 177.336 82.2488 178.389 82.2488C179.441 82.2488 180.45 82.6654 181.194 83.4068C181.938 84.1482 182.356 85.1538 182.356 86.2023C182.356 86.5518 182.217 86.8869 181.969 87.134C181.721 87.3811 181.384 87.52 181.034 87.5202ZM190.291 83.5116C189.768 83.5116 189.257 83.357 188.822 83.0674C188.387 82.7778 188.048 82.3661 187.848 81.8845C187.648 81.4029 187.595 80.873 187.697 80.3617C187.799 79.8504 188.051 79.3808 188.421 79.0122C188.791 78.6436 189.262 78.3926 189.775 78.2909C190.289 78.1892 190.82 78.2414 191.304 78.4409C191.787 78.6404 192.2 78.9782 192.491 79.4116C192.781 79.845 192.937 80.3546 192.937 80.8759C192.927 81.5721 192.646 82.2372 192.152 82.7295C191.658 83.2219 190.99 83.5025 190.291 83.5116ZM166.486 83.5116C165.963 83.5116 165.451 83.357 165.016 83.0674C164.581 82.7778 164.242 82.3661 164.042 81.8845C163.842 81.4029 163.79 80.873 163.892 80.3617C163.994 79.8504 164.246 79.3808 164.616 79.0122C164.985 78.6436 165.457 78.3926 165.97 78.2909C166.483 78.1892 167.015 78.2414 167.498 78.4409C167.981 78.6404 168.395 78.9782 168.685 79.4116C168.976 79.845 169.131 80.3546 169.131 80.8759C169.122 81.5721 168.84 82.2372 168.346 82.7295C167.852 83.2219 167.185 83.5025 166.486 83.5116Z"
          fill="#002646"
        />
      </g>
      <defs>
        <clipPath id="clip0_1720_7104">
          <rect fill="white" height="127" width="226" />
        </clipPath>
      </defs>
    </svg>
  );
};
