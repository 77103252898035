import { TwoStepAuthentication } from '@/src/modules/AuthModule/components/TwoStepAuthentication';

const MultiFactorAuthentication = () => {
  return (
    <div className="flex size-full items-center justify-center">
      <TwoStepAuthentication />
    </div>
  );
};

export default MultiFactorAuthentication;
