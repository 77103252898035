import classNames from 'classnames';
import { memo } from 'react';

import { ColorsBadge } from './types';

import './BadgeText.tailwind.css';

export type BadgeTextProps = {
  variantColor?: ColorsBadge;
  text: string | number;
};

const BadgeText = ({ text, variantColor = 'primary' }: BadgeTextProps) => {
  const classes = classNames('eb-badgeText', `eb-badgeText--colors-${variantColor}`);

  return <span className={classes}>{text}</span>;
};

export default memo(BadgeText);
