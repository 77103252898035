import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { useFiltersRecurrent } from '@/modules/CampaignsModule/hooks/useFiltersCampaignRecurrent';
import { EFilterKeysRecurrentMessage } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputSearch = () => {
  const { t } = useTranslation();
  const { updateFilter } = useFiltersRecurrent();
  const filtersRecurrent = useAtomValue(atomFiltersRecurrent);
  const [reset, setReset] = useState(false);

  const defaultValue = filtersRecurrent?.search ?? '';
  const placeholder = t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder');
  const id = 'search-campaigns-action';

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      updateFilter(EFilterKeysRecurrentMessage.Search, querySearch);
    },
    [updateFilter]
  );

  const onClearSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
    updateFilter(EFilterKeysRecurrentMessage.Search, '');
  }, [updateFilter]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    id,
    defaultValue,
    placeholder,
    reset,
    handleOnClickSearch,
    onClearSearch,
    resetInputSearch,
  };
};
