export const ReasonsIcon = () => {
  return (
    <svg height="20" id="check" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0H20V20H0Z" data-name="Layout 3061" fill="none" id="Layout_3061" />
      <path
        d="M9.118,7.019a2.944,2.944,0,0,1,3.773,0,2.134,2.134,0,0,1,0,3.3,2.63,2.63,0,0,1-.6.393,1.99,1.99,0,0,0-1.29,1.625v.667m8-2a8,8,0,1,1-8-8A8,8,0,0,1,19.01,11Zm-8,4.67h.007v.007H11Z"
        data-name="Layout 4690"
        fill="none"
        id="Layout_4690"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(-1 -1.01)"
      />
    </svg>
  );
};
