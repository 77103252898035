import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomFiltersRecurrent,
  atomHistorySearchPagerRecurrent,
  atomPagerCampaignsRecurrent,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleEditCreateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { atomHistorySearchPagerActions } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useFiltersRecurrent } from '@/modules/CampaignsModule/hooks/useFiltersCampaignRecurrent';
import {
  IRecurrenceMessage,
  ITableCampaigns,
  ITableCampaignsRecurrent,
  ITablePager,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useHandleEvents = () => {
  const navigate = useNavigate();
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const setPageParams = useSetAtom(atomPagerCampaignsRecurrent);
  const filters = useAtomValue(atomFiltersRecurrent);
  const pager = useAtomValue(atomPagerCampaignsRecurrent);

  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);
  const setHistorySearchPagerCampaign = useSetAtom(atomHistorySearchPagerRecurrent);
  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);

  const { resetFilters } = useFiltersRecurrent();

  const handleClickEmptyScreen = useCallback(() => {
    setToggleEditCategoryModal(true);
  }, [setToggleEditCategoryModal]);

  const handleClickEmptyScreenNoResult = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  const handleChangeTablePage = useCallback(
    ({ pageIndex, pageSize }: ITablePager) => {
      setPageParams({ page: ++pageIndex, limit: pageSize });
    },
    [setPageParams]
  );

  const handleClickReport = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: IRecurrenceMessage) => {
      event.stopPropagation();
      navigate(`/v2/campaigns/${value.campaignId}/report`);
      setInfoCampaignV1({
        campaign: {
          id: Number(value?.campaignId),
          name: value?.name ?? '',
        },
      });
    },
    [navigate, setInfoCampaignV1]
  );

  const handleRowClick = useCallback(
    (value: ITableCampaignsRecurrent) => {
      const { campaignId } = value?.campaign || { campaignId: 0 };
      setHistorySearchPagerCampaign({
        filters: { ...filters },
        pager: {
          page: pager?.page || defaultPager.page,
          limit: pager?.limit || defaultPager.limit,
        },
      });
      setHistorySearchPagerAction(RESET);
      navigate(`/v2/campaigns/recurrent/${campaignId}/`);
    },
    [
      filters,
      navigate,
      pager?.limit,
      pager?.page,
      setHistorySearchPagerAction,
      setHistorySearchPagerCampaign,
    ]
  );

  return {
    handleChangeTablePage,
    handleClickReport,
    handleClickEmptyScreen,
    handleClickEmptyScreenNoResult,
    handleRowClick,
  };
};
