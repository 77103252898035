import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DropDown } from '@/lib/components';
import { Button, InputSearch } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { StateIcon } from '@/lib/v2/icons/outline';
import { CleanFiltersIcon } from '@/lib/v2/icons/solid';

import {
  useNpsMutationContext,
  useNpsStateContext,
} from '@/src/modules/NPSModule/contexts/NpsContext';

export interface INPSTableFilter {
  filterDiscarded?: (filter: string[]) => void;
  onReset?: () => void;
  filterDiscardedStatusSelected?: string[];
  disableStatusDropdown?: boolean;
}

export const NPSTableFilters = ({ onReset, disableStatusDropdown }: INPSTableFilter) => {
  const { t } = useTranslation();
  const { statusFilter, searchQuery, isRecycleBin } = useNpsStateContext();
  const { setStatusFilter, setSearchQuery, lastRefreshIncrement } = useNpsMutationContext();
  const [, setSearchParams] = useSearchParams();

  const status = ['active', 'inactive'];

  const handleStatus = (statusSelectedList: string[]) => {
    lastRefreshIncrement();
    setStatusFilter(statusSelectedList);
  };

  const resetOnSearch = () => {
    lastRefreshIncrement();
    onReset && onReset();
    setSearchParams({});
    handleStatus;
  };

  const [searchQueryHandler, setSearchQueryHandler] = useState(searchQuery);

  const getOnSearch = () => {
    lastRefreshIncrement();
    setSearchQuery(searchQueryHandler ?? '');
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQueryHandler(event.target.value);
  };

  const handleClear = () => {
    setSearchQuery('');
    setSearchQueryHandler('');
  };

  useEffect(() => {
    setSearchQueryHandler(searchQuery);
  }, [searchQuery]);

  return (
    <Flex alignment="start" itemAlignment="center">
      <Flex withGap alignment="start" className="mb-[-90px] xl:max-w-[900px] 2xl:max-w-full">
        <div className="w-[340px]">
          <InputSearch
            id={'search-nps-widgets'}
            placeHolder={t('NPS_SCREEN.searchInputBar')}
            value={searchQueryHandler}
            onAction={getOnSearch}
            onChange={handleOnChange}
            onClear={handleClear}
          />
        </div>
        {!disableStatusDropdown && !isRecycleBin && (
          <div className="w-auto">
            <DropDown
              checkBox
              overflowWidth
              btnOnAction={handleStatus}
              btnText={t('CONTACTS_FILTERS.apply')}
              iconHead={<StateIcon />}
              itemList={status?.map((x) => ({
                id: x,
                title: t(`NPS_SCREEN.${x}`),
              }))}
              titleHead={t('CONTACTS_FILTERS.status')}
              tooltip={t('CONTACTS_FILTERS.filterStatus')}
              totalSelectedChk={statusFilter.length}
              valuesSelected={statusFilter}
            />
          </div>
        )}
        {!disableStatusDropdown && (
          <div className="w-auto">
            <Button
              fullWidth
              standard
              iconLeft={<CleanFiltersIcon />}
              id="clean-filters"
              tooltip={t('CONTACTS_FILTERS.cleanAllFilters')}
              onClick={resetOnSearch}
            />
          </div>
        )}
      </Flex>
    </Flex>
  );
};
