import classNames from 'classnames';
import { ChangeEventHandler, FormEvent, memo, ReactNode, TextareaHTMLAttributes } from 'react';

import { Button, IconSvg, Modal, Text } from '@/lib/v2/components';
import { EditPencilIcon } from '@/lib/v2/icons/outline';
import { DeleteIcon } from '@/lib/v2/icons/solid';

export type MenuItemsNote = Array<{
  icon?: ReactNode;
  text: string;
  onAction?: () => void;
}>;
export interface NotesProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  textSaveButton?: string;
  textCancelButton?: string;
  placeHolder?: string;
  defaultValue?: string | number | readonly string[] | undefined;
  value?: string | number | readonly string[] | undefined;
  name?: string;
  id?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
  onActionSave?: (textNote: string) => void;
  headerTitle?: string;
  withModal?: boolean;
  open?: boolean;
  onCloseModal?: (value: boolean) => void;
  disableButton?: boolean;
  /** to indicate that there is a validation error */
  error?: boolean;
  /** validation information or error message  */
  message?: string;
  minimumCharacters?: number;
  tooltipDelete?: string;
  onActionDelete?: () => void;
}

const Notes = ({
  textSaveButton,
  textCancelButton,
  placeHolder,
  defaultValue,
  value,
  name = 'notes',
  id,
  onChange,
  className,
  onActionSave,
  headerTitle = 'Add Note',
  withModal,
  open = false,
  onCloseModal,
  disableButton,
  error,
  message,
  minimumCharacters = 1,
  onActionDelete,
  tooltipDelete,
  ...restOfProps
}: NotesProps) => {
  const classesTextArea = classNames(
    'block w-full resize-none border-0 p-2 placeholder-gray-500 focus:outline-none sm:text-sm'
  );

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      [name: string]: { value: string };
    };
    if (target[name].value.length >= minimumCharacters) {
      onActionSave?.(target[name].value);
    }
  };

  const Note = (
    <form
      className={`relative w-full ${className}`}
      data-testid="notes-component"
      {...(id && { id: `${id}-container` })}
      onSubmit={handleOnSubmit}
    >
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm">
        <div className="flex w-full flex-row items-center justify-between border-b border-gray-200 px-2 py-3">
          <div className="flex flex-row gap-1">
            <IconSvg height={'20px'} svgComponent={<EditPencilIcon />} width={'20px'} />
            <Text color="primary" fontWeight="bold">
              {headerTitle}
            </Text>
          </div>
          <div className="pr-9">
            {onActionDelete && (
              <IconSvg
                height="20px"
                svgComponent={<DeleteIcon />}
                title={tooltipDelete}
                width="20px"
                onClick={onActionDelete}
                {...(id && { id: `${id}-delete-button` })}
              />
            )}
          </div>
        </div>
        <label className="sr-only" htmlFor={name}>
          {name}
        </label>
        <textarea
          className={classesTextArea}
          defaultValue={defaultValue}
          {...(id && { id: `${id}-textarea` })}
          name={name}
          placeholder={placeHolder}
          rows={5}
          value={value}
          onChange={onChange}
          {...restOfProps}
        />
        {error && (
          <p className={'message-error'} {...(id && { id: `${id}-message` })}>
            {message}
          </p>
        )}
        <div aria-hidden="true">
          <div className="py-1">
            <div className="h-0" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      {textSaveButton && (
        <div className="absolute inset-x-px bottom-0">
          <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-3 sm:px-3">
            <div className="flex"></div>
            <div className="flex shrink-0 gap-2">
              {textCancelButton && (
                <Button
                  secondary
                  size="small"
                  onClick={() => onCloseModal?.(false)}
                  {...(id && { id: `${id}-cancel-button` })}
                >
                  {textCancelButton}
                </Button>
              )}
              <Button
                disabled={disableButton}
                size="small"
                type="submit"
                {...(id && { id: `${id}-save-button` })}
              >
                {textSaveButton}
              </Button>
            </div>
          </div>
        </div>
      )}
    </form>
  );

  // eslint-disable-next-line react/no-children-prop
  return withModal ? <Modal children={Note} noPadding open={open} onClose={onCloseModal} /> : Note;
};

export default memo(Notes);
