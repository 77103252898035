export const ImmediateDeliveryIcon = () => {
  return (
    <svg fill="none" height="35" viewBox="0 0 40 35" width="40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.078 9.17061C35.8731 9.17061 36.5951 9.46817 37.1414 9.9511L37.1609 9.93158C37.717 10.4633 38.0634 11.195 38.0634 12.0048V24.5267C38.0634 26.1413 36.6975 27.4487 35.0146 27.4487H16.5316C14.8487 27.4487 13.4829 26.1413 13.4829 24.5267V12.0048C13.4829 11.2584 13.7804 10.5755 14.2634 10.0584L14.239 10.034C14.7951 9.49744 15.5609 9.16085 16.4048 9.16085H35.078M35.078 7.62427H16.4048C15.1853 7.62427 14.0341 8.08768 13.1609 8.93158C13.0438 9.04378 12.9463 9.17549 12.8731 9.31207C12.2682 10.0877 11.9365 11.0291 11.9365 12.0096V24.5316C11.9365 26.995 13.9999 28.9999 16.5316 28.9999H35.0146C37.5463 28.9999 39.6097 26.995 39.6097 24.5316V12.0096C39.6097 10.795 39.117 9.65841 38.2243 8.81451C38.1268 8.72183 38.0195 8.64378 37.9024 8.58037C37.0926 7.96085 36.1024 7.62427 35.0731 7.62427H35.078Z"
        fill="#DCE2F7"
      />
      <path
        d="M20.3077 34.8292C29.6696 34.8292 37.2589 27.2399 37.2589 17.878C37.2589 8.51608 29.6696 0.926758 20.3077 0.926758C10.9458 0.926758 3.35645 8.51608 3.35645 17.878C3.35645 27.2399 10.9458 34.8292 20.3077 34.8292Z"
        fill="#DCE2F7"
      />
      <path
        d="M37.1614 9.93652L27.7907 18.6926C26.6688 19.7414 24.8785 19.7414 23.7566 18.6926L14.3858 9.93652C13.8297 10.4682 13.4834 11.1999 13.4834 12.0097V24.5316C13.4834 26.1463 14.8493 27.4536 16.5322 27.4536H35.0151C36.698 27.4536 38.0639 26.1463 38.0639 24.5316V12.0097C38.0639 11.1999 37.7175 10.4634 37.1614 9.93652Z"
        fill="white"
      />
      <path
        d="M27.7807 19.0731L37.2441 10.0438C36.688 9.50724 35.9222 9.17065 35.0783 9.17065H16.4051C15.5612 9.17065 14.7954 9.50236 14.2393 10.0438L23.7027 19.0731C24.8344 20.156 26.6441 20.156 27.7758 19.0731H27.7807Z"
        fill="#004BC2"
      />
      <path
        d="M5.91707 21.3951C4.78537 21.3951 2.14634 21.3951 1.01951 21.3951C0.37561 21.3951 0 21.0829 0 20.5707C0 20.0585 0.37561 19.7561 1.02439 19.7561C3.28293 19.7561 13.0829 19.7561 15.3415 19.7561C15.9854 19.7561 16.3659 20.0683 16.361 20.5805C16.361 21.0927 15.9805 21.3951 15.3366 21.4C14.2049 21.4 7.04878 21.4 5.92195 21.4L5.91707 21.3951Z"
        fill="#18C678"
      />
      <path
        d="M8.94627 24.712C8.30237 24.712 7.92676 24.3998 7.92676 23.8876C7.92676 23.3754 8.30237 23.073 8.95115 23.073C11.2097 23.073 16.1707 23.073 18.4341 23.073C19.078 23.073 19.4585 23.3852 19.4536 23.8974C19.4536 24.4096 19.0731 24.712 18.4292 24.7169"
        fill="#18C678"
      />
      <path
        d="M8.31676 17.7609C7.66798 17.7609 7.02408 17.7706 6.3753 17.7609C5.8192 17.7511 5.46798 17.4096 5.47774 16.9316C5.48749 16.4682 5.82896 16.1413 6.35579 16.1365C7.66798 16.1267 8.9753 16.1267 10.2875 16.1365C10.8387 16.1365 11.1948 16.4828 11.1851 16.9657C11.1753 17.4438 10.8192 17.756 10.2533 17.7657C9.60457 17.7755 8.96066 17.7657 8.31188 17.7657L8.31676 17.7609Z"
        fill="#18C678"
      />
      <path
        d="M2.97032 17.7511C2.69714 17.7511 2.42397 17.7657 2.1508 17.7511C1.67275 17.7169 1.34593 17.3657 1.35568 16.9218C1.36544 16.4974 1.68251 16.1511 2.13617 16.1316C2.69714 16.1072 3.263 16.1072 3.82397 16.1316C4.30202 16.1511 4.62885 16.5169 4.6191 16.9608C4.60934 17.3852 4.29227 17.7169 3.83373 17.7511C3.54593 17.7706 3.25324 17.7511 2.96544 17.7511H2.97032Z"
        fill="#18C678"
      />
    </svg>
  );
};
