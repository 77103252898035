import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MAX_SIZE_SUBJECT, TRANSLATE_RULES_EMAIL } from '@/modules/CampaignsModule/constants';

export type SchedulingFormState = {
  scheduledDeliveryDate: Date | null;
  scheduledDeliveryTime: Date | null;
  reinforcementSubject: string;
  reinforcementDate: Date | null;
  reinforcementTime: Date | null;
  reinforcementCheckbox: boolean;
};

const useSchedulingForm = () => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, watch, getValues, resetField, reset, clearErrors } =
    useForm<SchedulingFormState>();

  const reinforcementCheckbox = watch('reinforcementCheckbox');

  const RULES = {
    reinforcementSubject: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.subject`) : false,
      maxLength: {
        value: MAX_SIZE_SUBJECT,
        message: t(`${TRANSLATE_RULES_EMAIL}.maxLength`, { max: MAX_SIZE_SUBJECT }),
      },
    },
    scheduledDeliveryDate: {
      required: t(`${TRANSLATE_RULES_EMAIL}.date`),
    },
    scheduledDeliveryTime: {
      required: t(`${TRANSLATE_RULES_EMAIL}.time`),
    },
    reinforcementDate: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.date`) : false,
    },
    reinforcementTime: {
      required: reinforcementCheckbox ? t(`${TRANSLATE_RULES_EMAIL}.time`) : false,
    },
  };

  return {
    getValues,
    control,
    RULES,
    setValue,
    watch,
    reset,
    handleSubmit,
    resetField,
    clearErrors,
  };
};

export default useSchedulingForm;
