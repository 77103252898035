export const DragAndDropIcon = () => {
  return (
    <svg fill="none" height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.2939 66.7102C53.9039 66.7102 68.1796 52.4344 68.1796 34.8244C68.1796 17.2144 53.9039 2.93872 36.2939 2.93872C18.6839 2.93872 4.4082 17.2144 4.4082 34.8244C4.4082 52.4344 18.6839 66.7102 36.2939 66.7102Z"
        fill="#DCE2F7"
      />
      <path
        d="M49.8127 15.1348H22.9229C19.4333 15.1348 16.6045 17.9636 16.6045 21.4531V48.3429C16.6045 51.8325 19.4333 54.6613 22.9229 54.6613H49.8127C53.3022 54.6613 56.131 51.8325 56.131 48.3429V21.4531C56.131 17.9636 53.3022 15.1348 49.8127 15.1348Z"
        fill="white"
      />
      <path
        d="M49.078 68.7672C48.3433 68.7672 47.7555 69.0611 47.0208 69.0611C45.4045 69.0611 43.7882 68.6202 42.4657 67.5917C38.0576 64.359 33.5025 61.1264 29.0943 57.8937C28.0657 57.159 27.6249 55.8366 28.0657 54.6611C28.5065 53.4855 29.682 52.7508 31.0045 52.8978C33.2086 53.1917 35.2657 53.4855 37.4698 53.7794C37.4698 53.7794 37.7637 53.7794 37.9106 53.7794C37.9106 53.6325 37.9106 53.4855 37.7637 53.3386C34.9718 47.0202 32.3269 40.5549 29.5351 34.2366C28.9474 32.7672 29.5351 31.2978 30.7106 30.5631C32.18 29.8284 33.7963 30.5631 34.3841 31.8855C34.3841 32.0325 34.3841 32.1794 34.531 32.3264C36.882 37.91 39.2331 43.3468 41.731 48.9304C42.0249 49.5182 42.1718 49.6651 42.4657 49.5182C42.7596 49.5182 42.7596 49.0774 42.4657 48.6366C41.5841 46.5794 40.7025 44.6692 39.8208 42.6121C39.38 41.5835 39.5269 40.5549 40.4086 39.6733C41.2902 38.7917 42.3188 38.6447 43.3474 39.0855C44.082 39.3794 44.6698 39.9672 44.9637 40.7019C45.8453 42.6121 46.58 44.5223 47.4616 46.5794C47.4616 46.5794 47.4616 46.8733 47.6086 46.8733C47.7555 46.8733 48.0494 47.1672 48.1963 47.0202C48.1963 47.0202 48.4902 46.7264 48.4902 46.5794C48.4902 46.5794 48.4902 46.2855 48.4902 46.1386C47.9025 44.6692 47.1678 43.1998 46.58 41.7304C45.9923 40.408 46.58 38.9386 47.7555 38.3508C48.931 37.6162 50.4004 37.91 51.282 39.0855C51.429 39.3794 51.5759 39.6733 51.7229 39.9672C52.3106 41.2896 52.8984 42.6121 53.4861 44.0815C53.4861 44.0815 53.4861 44.2284 53.4861 44.3753C53.4861 44.6692 53.78 44.8162 54.0739 44.6692C54.3678 44.6692 54.3678 44.3753 54.3678 44.0815C54.3678 43.7876 54.0739 43.4937 54.0739 43.3468C53.78 42.6121 53.4861 41.8774 53.1922 41.1427C52.6045 39.8202 53.1922 38.2039 54.6616 37.6162C55.9841 37.0284 57.6004 37.6162 58.1882 38.7917C58.1882 38.7917 58.3351 39.0855 58.3351 39.2325C60.3923 44.2284 62.5963 49.0774 64.6535 54.0733C65.9759 57.0121 65.5351 59.8039 63.331 62.1549C62.8902 62.7427 62.1555 63.1835 61.5678 63.6243C57.3065 65.3876 53.0453 67.2978 48.7841 69.0611L49.078 68.7672Z"
        fill="#004BC2"
      />
      <path
        d="M54.8084 6.46509C59.9512 6.46509 64.2125 10.7263 64.2125 15.8692C64.2125 21.012 59.9512 25.2733 54.8084 25.2733C49.6655 25.2733 45.4043 21.012 45.4043 15.8692C45.4043 10.7263 49.6655 6.46509 54.8084 6.46509ZM53.9267 16.6039C53.9267 16.6039 53.9267 16.7508 53.9267 16.8977C53.9267 17.6324 53.9267 18.3671 53.9267 19.1018C53.9267 19.3957 53.9267 19.5426 54.0737 19.6896C54.3676 19.9835 54.6614 19.9835 54.9553 19.9835C55.2492 19.9835 55.5431 19.5426 55.5431 19.1018C55.5431 18.3671 55.5431 17.4855 55.5431 16.7508C55.5431 16.7508 55.5431 16.7508 55.5431 16.4569C55.5431 16.4569 55.69 16.4569 55.837 16.4569C56.5716 16.4569 57.3063 16.4569 58.041 16.4569C58.4818 16.4569 58.9227 16.0161 58.9227 15.5753C58.9227 15.1345 58.4818 14.6937 58.041 14.6937C57.3063 14.6937 56.5716 14.6937 55.837 14.6937C55.837 14.6937 55.837 14.6937 55.5431 14.6937C55.5431 14.6937 55.5431 14.5467 55.5431 14.3998C55.5431 13.6651 55.5431 12.9304 55.5431 12.0488C55.5431 11.461 55.1023 11.1671 54.6614 11.1671C54.2206 11.1671 53.7798 11.6079 53.7798 12.0488C53.7798 12.7835 53.7798 13.5181 53.7798 14.3998C53.7798 14.3998 53.7798 14.3998 53.7798 14.6937C53.7798 14.6937 53.6329 14.6937 53.4859 14.6937C52.7512 14.6937 51.8696 14.6937 51.1349 14.6937C50.6941 14.6937 50.4002 14.9875 50.2533 15.4284C50.2533 16.0161 50.5472 16.4569 51.1349 16.4569C52.0165 16.4569 52.8982 16.4569 53.7798 16.4569L53.9267 16.6039Z"
        fill="#FA7E23"
      />
      <path
        d="M53.9269 16.604C53.0453 16.604 52.1637 16.604 51.282 16.604C50.6943 16.604 50.4004 16.1632 50.4004 15.5754C50.4004 15.1346 50.8412 14.8407 51.282 14.8407C52.0167 14.8407 52.8984 14.8407 53.633 14.8407C53.633 14.8407 53.633 14.8407 53.9269 14.8407C53.9269 14.8407 53.9269 14.6938 53.9269 14.5469C53.9269 13.8122 53.9269 13.0775 53.9269 12.1958C53.9269 11.6081 54.2208 11.3142 54.8086 11.3142C55.2494 11.3142 55.6902 11.755 55.6902 12.1958C55.6902 12.9305 55.6902 13.6652 55.6902 14.5469C55.6902 14.5469 55.6902 14.5469 55.6902 14.8407C55.6902 14.8407 55.8371 14.8407 55.9841 14.8407C56.7188 14.8407 57.4535 14.8407 58.1881 14.8407C58.7759 14.8407 59.0698 15.1346 59.0698 15.7224C59.0698 16.1632 58.629 16.604 58.1881 16.604C57.4535 16.604 56.7188 16.604 55.9841 16.604C55.9841 16.604 55.9841 16.604 55.6902 16.604C55.6902 16.604 55.6902 16.7509 55.6902 16.8979C55.6902 17.6326 55.6902 18.5142 55.6902 19.2489C55.6902 19.6897 55.3963 19.9836 55.1024 20.1305C54.8086 20.1305 54.3677 20.1305 54.2208 19.8367C54.0739 19.6897 54.0739 19.3958 54.0739 19.2489C54.0739 18.5142 54.0739 17.7795 54.0739 17.0448C54.0739 17.0448 54.0739 17.0448 54.0739 16.7509L53.9269 16.604Z"
        fill="white"
      />
    </svg>
  );
};
