import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { IconSvg } from '@/lib/v2/components';
import { DragAndDropIcon, HtmlEditorIcon } from '@/lib/v2/icons/general';
import { InsightCardProps } from '@/lib/v2/organisms/InsightCard/InsightCard';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

const ACTIONS_CARDS = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.CARD_LIST';
const DRAG_AND_DROP_CARD_ID = 'drag-and-drop-card';
const HTML_EDITOR_CARD_ID = 'html-editor-card';

export const useSimpleEmail = () => {
  const { t } = useTranslation();
  const setLocation = useNavigate();
  const { campaignId, actionId } = useParams();
  const showCreateEmailActionCard = useFeatureFlag('showCreateEmailActionCard');

  const EDITOR_ROUTE = useMemo(
    () => (route: string) => {
      return `/v2/campaigns/${campaignId}/email/${actionId}/${route}`;
    },
    [campaignId, actionId]
  );

  const cardList = useMemo<InsightCardProps[]>(
    () =>
      [
        {
          icon: (
            <IconSvg
              height={'80px'}
              svgComponent={<DragAndDropIcon />}
              width={'80px'}
              withPadding="small"
            />
          ),
          description: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.description`),
          title: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.title`),
          isLoading: true,
          onClick: () => {
            setLocation(EDITOR_ROUTE('editor'));
          },
          id: DRAG_AND_DROP_CARD_ID,
        },
        {
          icon: (
            <IconSvg
              height={'80px'}
              svgComponent={<HtmlEditorIcon />}
              width={'80px'}
              withPadding="small"
            />
          ),
          description: t(`${ACTIONS_CARDS}.HTML_EDITOR.description`),
          title: t(`${ACTIONS_CARDS}.HTML_EDITOR.title`),
          isLoading: true,
          onClick: () => {
            setLocation(EDITOR_ROUTE('html-editor'));
          },
          id: HTML_EDITOR_CARD_ID,
        },
      ].filter((card) => showCreateEmailActionCard || card.id !== HTML_EDITOR_CARD_ID),
    [t, setLocation, campaignId, actionId, showCreateEmailActionCard]
  );

  return { cardList };
};
