//cspell: disable
export const ContentHtmlSuccessPageMock = `
<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <title>¡El formulario se completó correctamente!</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!-- Inclusión de la fuente Roboto -->
    <link href="https://fonts.googleapis.com/css?family=Roboto:400&display=swap" rel="stylesheet">
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: 'Roboto', Arial, sans-serif;
            background-color: #f4f4f4;
        }
        .container {
            max-width: 748px;
            margin: 0 auto;
            background-color: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 8px;
            overflow: hidden;
        }
        .header {
            background-color: #0056B8;
            padding: 20px 36px;
            text-align: left;
        }
        .header img {
            width: 147px;
            height: auto;
            display: block;
            border: 0;
        }
        .content {
            padding: 24px 30px;
            text-align: center;
        }
        .content h1 {
            color: #111827;
            font-size: 24px;
            font-weight: 400;
            margin: 0 0 24px;
        }
        .content img {
            width: 72px;
            height: 66px;
            border: 0;
        }
        .footer {
            padding: 20px 0;
            text-align: center;
            font-size: 10px;
            color: #625B71;
        }
        .footer a {
            color: #0056B8;
            text-decoration: none;
        }
        @media (max-width: 768px) {
            .container {
                width: 100%;
            }
            .content {
                padding: 16px 20px;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <!-- Encabezado -->
        <div class="header">
            <img src="https://files.embluemail.com/uo/5971/rulesEmblueLogo.png" alt="Logo de emBlue">
        </div>
        <!-- Contenido -->
        <div class="content">
            <h1>¡El formulario se completó correctamente!</h1>
            <img src="https://files.embluemail.com/uo/5971/rulesFormSuccess.png" alt="Icono de éxito">
        </div>
        <!-- Pie de página -->
        <div class="footer">
            <p>Formulario creado a través de <a href="https://www.embluemail.com" target="_blank">emBluemail</a></p>
        </div>
    </div>
</body>
</html>
`;
