export const DropdownMailIcon = () => {
  return (
    <svg
      data-name="Grupo 3951"
      height="13.913"
      id="Grupo_3951"
      viewBox="0 0 20 13.913"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        data-name="Rectángulo 2761"
        fill="#3761e7"
        height="13.913"
        id="Rectángulo_2761"
        rx="3"
        transform="translate(0)"
        width="20"
      />
      <path
        d="M13.465,6h-9.3A1.161,1.161,0,0,0,3.006,7.163L3,14.139A1.166,1.166,0,0,0,4.163,15.3h9.3a1.166,1.166,0,0,0,1.163-1.163V7.163A1.166,1.166,0,0,0,13.465,6Zm0,8.139h-9.3V8.325l4.651,2.907,4.651-2.907ZM8.814,10.07,4.163,7.163h9.3Z"
        data-name="Icon material-mail-outline"
        fill="#fff"
        id="Icon_material-mail-outline"
        transform="translate(1.447 -3.694)"
      />
    </svg>
  );
};
