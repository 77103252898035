import { Button } from '@/lib/v2/components/Button';
import CreateItem from '@/lib/v2/components/EntityAssignment/components/CreateItem';
import { EntityAssignmentProps } from '@/lib/v2/components/EntityAssignment/EntityAssignment';
import { IconSvg } from '@/lib/v2/components/IconSvg';
import { Input } from '@/lib/v2/components/Input';
import { Text } from '@/lib/v2/components/Text';
import { CreateIcon, RemoveIcon } from '@/lib/v2/icons/outline';

interface AssignmentOptionsProps {
  items: { id: number; name: string }[];
  handleSelectItem: (id: number) => void;
  handleDeselectItem: (id: number) => void;
  selected: { [key: number]: { id: number; name: string } };
  unselected: { [key: number]: { id: number; name: string } };
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showCreateButton: boolean;
  onCreateItem: (value: string) => void;
  searchTerm: string;
  isCreating: boolean;
  wordings: EntityAssignmentProps['wordings'];
  limitSelection: number;
  selectionCount: number;
}

const AssignmentOptions = ({
  items,
  handleSelectItem,
  handleDeselectItem,
  selected,
  unselected,
  onSearchChange,
  showCreateButton,
  onCreateItem,
  searchTerm,
  isCreating,
  wordings,
  limitSelection,
  selectionCount,
}: AssignmentOptionsProps) => {
  return (
    <div className="relative w-96 overflow-hidden">
      <div className="sticky top-0 z-10 flex items-center bg-white pr-5">
        <Input
          id="search-input"
          placeHolder={wordings.search.placeholder}
          value={searchTerm}
          onChange={onSearchChange}
        />
      </div>
      {showCreateButton && searchTerm.length > 0 && (
        <CreateItem
          disabled={isCreating}
          id="create-item-button"
          isCreating={isCreating}
          title={wordings.labelInputCreate || 'Create item'}
          value={searchTerm}
          onItemCreated={onCreateItem}
        />
      )}
      <div className="visible-scrollbar mt-3 max-h-56 overflow-y-auto pr-5">
        {items.map((item) => (
          <div key={item.id} className="flex items-center justify-between px-1 py-2">
            <Text fontWeight="normal" variant="text">
              {item.name}
            </Text>
            <div className="flex gap-2">
              <Button
                outline
                secondary
                disabled={
                  !!selected[item.id] || (selectionCount >= limitSelection && !unselected[item.id])
                }
                id="select-button"
                size="tiny"
                onClick={() => handleSelectItem(item.id)}
              >
                <IconSvg
                  className="mx-1"
                  height="12px"
                  strokeColor="secondary"
                  svgComponent={<CreateIcon />}
                  width="12px"
                  withPadding="small"
                />
              </Button>
              <Button
                outline
                secondary
                disabled={
                  !!unselected[item.id] || (selectionCount >= limitSelection && !selected[item.id])
                }
                id="deselect-button"
                size="tiny"
                onClick={() => handleDeselectItem(item.id)}
              >
                <IconSvg
                  className="mx-1"
                  height="12px"
                  strokeColor="secondary"
                  svgComponent={<RemoveIcon />}
                  width="12px"
                  withPadding="small"
                />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssignmentOptions;
