// cspell:disable

export const NoEngagementIcon = () => {
  return (
    <svg height="52" viewBox="0 0 52 52" width="52" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 8449" id="Group_8449" transform="translate(-639 -325.499)">
        <g data-name="Group 8173" id="Group_8173" transform="translate(-363.75 -21.25)">
          <g data-name="Group 8180" id="Group_8180">
            <g data-name="Group 7953" id="Group_7953" transform="translate(1003 347)">
              <rect
                data-name="Rectangle 4375"
                fill="none"
                height="52"
                id="Rectangle_4375"
                transform="translate(-0.25 -0.251)"
                width="52"
              />
              <g data-name="Group 7952" id="Group_7952" transform="translate(3.084 2.07)">
                <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(29.314 31.342)">
                  <path
                    d="M386.11,331.53a11.571,11.571,0,0,0,.714,1.667h0C386.585,332.721,386.348,332.007,386.11,331.53Z"
                    data-name="Trazado 3928"
                    fill="#2552a0"
                    id="Trazado_3928"
                    transform="translate(-386.11 -331.53)"
                  />
                </g>
                <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(22.645 23.474)">
                  <path
                    d="M315.57,248.307a.876.876,0,0,1,.714.238A.873.873,0,0,0,315.57,248.307Z"
                    data-name="Trazado 3930"
                    fill="#2552a0"
                    id="Trazado_3930"
                    transform="translate(-315.57 -248.301)"
                  />
                </g>
                <g data-name="Grupo 6573" id="Grupo_6573" transform="translate(6.611 0)">
                  <circle
                    cx="11.454"
                    cy="11.454"
                    data-name="Elipse 1742"
                    fill="#f6a658"
                    id="Elipse_1742"
                    r="11.454"
                  />
                </g>
                <g data-name="Grupo 6574" id="Grupo_6574" transform="translate(0 22.708)">
                  <path
                    d="M111.989,252.962a22.449,22.449,0,0,0-8.1-12.562,1.782,1.782,0,0,0-1.675,0,14.308,14.308,0,0,1-16.2,0,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h21.516C109.471,259.667,111.989,252.962,111.989,252.962Z"
                    data-name="Trazado 3933"
                    fill="#788f9a"
                    id="Trazado_3933"
                    transform="translate(-76.043 -240.19)"
                  />
                </g>
                <path
                  d="M157.424,0a11.454,11.454,0,0,0,0,22.909Z"
                  data-name="Path 4758"
                  fill="#f5b673"
                  id="Path_4758"
                  transform="translate(-139.359 0)"
                />
                <path
                  d="M94.108,242.915a14.291,14.291,0,0,1-8.1-2.515,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h12.85Z"
                  data-name="Path 4759"
                  fill="#8fa3ad"
                  id="Path_4759"
                  transform="translate(-76.042 -217.482)"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 8448" id="Group_8448">
          <g
            data-name="Group 8447"
            id="Group_8447"
            transform="translate(20266 -12716.377) rotate(-90)"
          >
            <g
              data-name="magnet-svgrepo-com (1)"
              id="magnet-svgrepo-com_1_"
              transform="translate(-13088 -19601)"
            >
              <path
                d="M16.478,31.265c-3.207,0-5.784-1.261-5.784-4.3s2.558-4.226,5.784-4.226h4.927V16.97H16.478c-5.626,0-10.34,2.826-11.534,7.6H4.65V27c0,6.23,5.3,10.029,11.828,10.029h4.927V31.265Z"
                data-name="Path 5224"
                fill="#c62828"
                id="Path_5224"
                transform="translate(-4.65 -14.539)"
              />
              <path
                d="M94.05,93.24h5.491v5.765H94.05Z"
                data-name="Path 5225"
                fill="#82aec0"
                id="Path_5225"
                transform="translate(-77.295 -76.515)"
              />
              <path
                d="M94.05,16.97h5.491v5.765H94.05Z"
                data-name="Path 5226"
                fill="#82aec0"
                id="Path_5226"
                transform="translate(-77.295 -14.539)"
              />
              <path
                d="M16.478,18.293c-3.207,0-5.784-1.261-5.784-4.3s2.558-4.226,5.784-4.226h4.927V4H16.478C9.952,4,4.65,7.8,4.65,14.029s5.3,10.029,11.828,10.029h4.927V18.293Z"
                data-name="Path 5227"
                fill="#f44336"
                id="Path_5227"
                transform="translate(-4.65 -4)"
              />
              <path
                d="M94.05,80.26h5.491v5.765H94.05Z"
                data-name="Path 5228"
                fill="#e0e0e0"
                id="Path_5228"
                transform="translate(-77.295 -65.967)"
              />
              <path
                d="M94.05,4h5.491V9.765H94.05Z"
                data-name="Path 5229"
                fill="#e0e0e0"
                id="Path_5229"
                transform="translate(-77.295 -4)"
              />
            </g>
          </g>
          <path
            d="M2.2-2.831a3.819,3.819,0,0,1,.216-1.417,2.907,2.907,0,0,1,.79-1.039,4.041,4.041,0,0,0,.765-.833,1.307,1.307,0,0,0,.19-.677q0-1.075-.99-1.075a1,1,0,0,0-.752.289,1.137,1.137,0,0,0-.3.8H.286a2.448,2.448,0,0,1,.784-1.9,3.052,3.052,0,0,1,2.1-.686,3.065,3.065,0,0,1,2.088.651A2.317,2.317,0,0,1,6-6.878a2.239,2.239,0,0,1-.241,1.02,4.2,4.2,0,0,1-.844,1.064l-.514.489a1.743,1.743,0,0,0-.552,1.086l-.025.388ZM2.019-.885a.924.924,0,0,1,.289-.7,1.028,1.028,0,0,1,.74-.275,1.028,1.028,0,0,1,.74.275.924.924,0,0,1,.289.7.917.917,0,0,1-.282.69,1.03,1.03,0,0,1-.746.272A1.03,1.03,0,0,1,2.3-.2.917.917,0,0,1,2.019-.885Z"
            data-name="Path 5243"
            fill="#364365"
            id="Path_5243"
            transform="translate(681 348.5)"
          />
          <path
            d="M2.2-2.831a3.819,3.819,0,0,1,.216-1.417,2.907,2.907,0,0,1,.79-1.039,4.041,4.041,0,0,0,.765-.833,1.307,1.307,0,0,0,.19-.677q0-1.075-.99-1.075a1,1,0,0,0-.752.289,1.137,1.137,0,0,0-.3.8H.286a2.448,2.448,0,0,1,.784-1.9,3.052,3.052,0,0,1,2.1-.686,3.065,3.065,0,0,1,2.088.651A2.317,2.317,0,0,1,6-6.878a2.239,2.239,0,0,1-.241,1.02,4.2,4.2,0,0,1-.844,1.064l-.514.489a1.743,1.743,0,0,0-.552,1.086l-.025.388ZM2.019-.885a.924.924,0,0,1,.289-.7,1.028,1.028,0,0,1,.74-.275,1.028,1.028,0,0,1,.74.275.924.924,0,0,1,.289.7.917.917,0,0,1-.282.69,1.03,1.03,0,0,1-.746.272A1.03,1.03,0,0,1,2.3-.2.917.917,0,0,1,2.019-.885Z"
            data-name="Path 5244"
            fill="#364365"
            id="Path_5244"
            transform="translate(666.584 348.5)"
          />
        </g>
      </g>
    </svg>
  );
};
