import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { TypeTranslation } from '@/src/ContactsModule/contexts/ImportFileContext/DataCard';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useMfa = () => {
  const service = useService();
  const { t } = useTranslation();
  const [sendCodeIsLoading, setSendCodeIsLoading] = useState(false);

  const [phoneNumberData, phoneNumberError, phoneNumberIsLoading] = useEmblue(
    ServiceMethods.getSmsPhoneNumber
  );

  const [auditFilterResult, auditFilterError, auditFilterIsLoading] = useEmblue(
    ServiceMethods.getAuditFilter
  );

  const sendSmsCode = useCallback(
    async (phoneNumber?: string) => {
      try {
        setSendCodeIsLoading(true);
        const response = await service.sendSmsCode(phoneNumber);
        if (response.success) {
          toast({
            title: t('AUTH.TWO_AUTHENTICATE.notification.success'),
            body: t('AUTH.TWO_AUTHENTICATE.notification.sendMsmCodeSuccess'),
          });
        }
        setSendCodeIsLoading(false);
        return response;
      } catch (error) {
        setSendCodeIsLoading(false);
        toast({
          variant: 'error',
          title: (error as Error).message,
        });
      }
    },
    [service, t]
  );

  const verifySmsCode = useCallback(
    async (code: string, phoneNumber?: string) => {
      try {
        setSendCodeIsLoading(true);
        const response = await service.verifySmsCode(code, phoneNumber);
        if (response.success) {
          toast({
            title: t('AUTH.TWO_AUTHENTICATE.notification.success'),
            body: t('AUTH.TWO_AUTHENTICATE.notification.sendMsmCodeSuccess'),
          });
        }
        setSendCodeIsLoading(false);
        return response as unknown as { success: boolean; token: string; mismatch: boolean };
      } catch (error: any) {
        setSendCodeIsLoading(false);
        toast({
          variant: 'error',
          title: (error as Error).message,
        });
      }
    },
    [service, t]
  );

  useEffect(() => {
    if (phoneNumberError?.message && !phoneNumberIsLoading) {
      toast({
        variant: 'error',
        title: phoneNumberError.message,
      });
    }
  }, [phoneNumberError?.message, phoneNumberIsLoading]);

  useEffect(() => {
    if (auditFilterError?.message && !auditFilterIsLoading) {
      toast({
        variant: 'error',
        title: auditFilterError.message,
      });
    }
  }, [auditFilterError?.message, auditFilterIsLoading]);

  return {
    verifiedPhoneNumber: !!phoneNumberData?.phoneLastFourNumbers,
    phoneNumberIsLoading,
    auditFilterIsLoading,
    auditFilterResult,
    sendCodeIsLoading,
    verifySmsCode,
    sendSmsCode,
  };
};

export const handleInvalidMFA = (t: TypeTranslation) => {
  const timeToReadMS = 15000;
  toast({
    id: 'invalid-mfa-notification',
    title: t('MFA.actionBlockedByMFATitle'),
    variant: 'error',
    body: t('MFA.actionBlockedByMFABody'),
    autoCloseDelay: timeToReadMS,
  });

  // refresh in 5 seconds
  setTimeout(() => {
    document.cookie =
      'mfaCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.embluemail.com';
    window.location.reload();
  }, timeToReadMS);
};
