import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Tab, TabContent, TabItem, TabNav, TabPane } from '@/lib/v2/components';

interface BillingAndConsumptionTablesProps {
  consumptionTable: JSX.Element;
  billingTable: JSX.Element;
}

const BillingAndConsumptionTables = ({
  consumptionTable,
  billingTable,
}: BillingAndConsumptionTablesProps) => {
  const [currentTab, setCurrentTab] = useState(1);
  const { t } = useTranslation();

  return (
    <Card className="mt-4">
      <CardSection>
        <Tab currentTab={currentTab}>
          <TabNav withDivide gap="medium">
            <TabItem tabId={1} onClick={() => setCurrentTab(1)}>
              {t('MY_PLAN.TABS.consumption')}
            </TabItem>
            <TabItem tabId={2} onClick={() => setCurrentTab(2)}>
              {t('MY_PLAN.TABS.billings')}
            </TabItem>
          </TabNav>
          <TabContent>
            <TabPane tabId={1}>
              <div className="mt-2">{consumptionTable}</div>
            </TabPane>
            <TabPane tabId={2}>
              <div className="mt-2">{billingTable}</div>
            </TabPane>
          </TabContent>
        </Tab>
      </CardSection>
    </Card>
  );
};

export default memo(BillingAndConsumptionTables);
