import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { EButtonType } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';

export enum ECustomFieldsByType {
  DEFAULT_FIELDS = 'defaultFields',
  CUSTOM_FIELDS = 'customFields',
  ALL = 'all',
}

export enum ESERVICES_STATUS_ATOM {
  HAS_ERROR = 'hasError',
  LOADING = 'loading',
  HAS_DATA = 'hasData',
}

export interface ICustomFieldsParams {
  type?: ECustomFieldsByType;
}

export interface IonChangeButtonParams {
  item?: Omit<DropdownItemProps, 'onClick'>;
  type?: EButtonType;
}

export enum ECustomFieldFilterType {
  INTERNAL_NUMBER = 'internalNumber',
  ID_CUSTOM_TYPE = 'id',
}

export interface ICustomFieldsFilterParams {
  ids: number[];
  filterType: ECustomFieldFilterType;
}
