import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, If, Text } from '@/lib/v2/components';

import { numberToCurrency } from '@/src/utils/currency';
import { convertDate } from '@/src/utils/Date/dateUtils';

import { atomLastBill } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { useInvoiceData } from '@/modules/MyPlanModule/hooks/useInvoiceData';
import { InvoiceIcon } from '@/modules/MyPlanModule/images/InvoiceIcon';

const LastInvoice = () => {
  const { t } = useTranslation();
  const { openInvoiceNewTab } = useInvoiceData();
  const lastBill = useAtomValue(atomLastBill);
  let currentInvoiceDate = new Date();
  let month = '';
  let year = '';

  const currentYear = currentInvoiceDate.getFullYear();
  const currentMonth = currentInvoiceDate.getMonth();
  const currentDay = currentInvoiceDate.getDate();

  if (currentDay === 1) {
    currentInvoiceDate = new Date(currentYear, currentMonth - 1, 2);
  } else {
    currentInvoiceDate.setDate(2);
  }

  if (lastBill?.period) {
    const dateWithoutSpaces = lastBill.period.trim();
    const splitDate = dateWithoutSpaces.split('/');
    month = splitDate[0];
    year = splitDate[1];
    const dateWithFirstDay = new Date(Number(year), Number(month) - 1, 2);
    currentInvoiceDate = dateWithFirstDay;
  }

  return (
    <Card isHeightAuto>
      <CardSection className="border-b" paddingX="medium">
        <Text fontWeight="medium">{t('MY_PLAN.BILLING.invoiceTitle')}</Text>
      </CardSection>
      <CardSection paddingX="medium">
        <If condition={lastBill?.period !== undefined}>
          <div className="flex items-center justify-between">
            <Text>{convertDate(currentInvoiceDate.toISOString(), true)}</Text>
            <div className="flex items-center gap-2">
              <Text>{numberToCurrency(lastBill?.totalBillingAmount ?? 0, false, 2)}</Text>
              <Button
                outline
                standard
                iconLeft={<InvoiceIcon />}
                onClick={() => {
                  void openInvoiceNewTab(lastBill?.period.trim() ?? '');
                }}
              ></Button>
            </div>
          </div>
        </If>
        <If condition={lastBill?.period === undefined}>
          <div className="flex justify-between">
            <Text color="gray">{convertDate(currentInvoiceDate.toISOString(), true)}</Text>
            <Text color="gray">USD $0</Text>
          </div>
        </If>
      </CardSection>
    </Card>
  );
};

export default LastInvoice;
