export const AllsIcon = () => {
  return (
    <svg
      data-name="Group 7438"
      height="20"
      id="Group_7438"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3179" fill="none" height="20" id="Rectangle_3179" width="20" />
      <path
        d="M4.6,10.2H2.272a1.246,1.246,0,0,0-.914.4A1.35,1.35,0,0,0,1,11.522V15.5a1.352,1.352,0,0,0,.358.919,1.246,1.246,0,0,0,.914.4h.943l3.521,1.721a.6.6,0,0,0,.833-.728L7.234,16.8l2.094.013a1.246,1.246,0,0,0,.914-.4A1.352,1.352,0,0,0,10.6,15.5V12.327h1.655l-.691,1.865a.6.6,0,0,0,.889.712l3.968-2.576h.922a1.643,1.643,0,0,0,1.177-.5A1.7,1.7,0,0,0,19,10.643V4.684A1.7,1.7,0,0,0,18.521,3.5,1.643,1.643,0,0,0,17.344,3H6.256a1.643,1.643,0,0,0-1.177.5A1.7,1.7,0,0,0,4.6,4.684Zm4.8,1.322V15.5a.15.15,0,0,1-.036.1.055.055,0,0,1-.033.02L6.4,15.6a.6.6,0,0,0-.573.79l.148.443L3.617,15.679a.6.6,0,0,0-.263-.061H2.272a.052.052,0,0,1-.036-.02.15.15,0,0,1-.036-.1V11.522a.15.15,0,0,1,.036-.1.052.052,0,0,1,.036-.02H9.328a.052.052,0,0,1,.036.02.15.15,0,0,1,.036.1Zm1.142-.4h2.575a.6.6,0,0,1,.563.808l-.361.976,2.6-1.688a.6.6,0,0,1,.327-.1h1.1a.443.443,0,0,0,.317-.136.5.5,0,0,0,.139-.347V4.684a.5.5,0,0,0-.139-.347.443.443,0,0,0-.317-.136H6.256a.443.443,0,0,0-.317.136.5.5,0,0,0-.139.347V10.2H9.328a1.246,1.246,0,0,1,.914.4A1.335,1.335,0,0,1,10.542,11.127ZM15.4,6a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,15.4,6ZM11.8,6A1.2,1.2,0,1,0,13,7.2,1.2,1.2,0,0,0,11.8,6ZM8.2,6A1.2,1.2,0,1,0,9.4,7.2,1.2,1.2,0,0,0,8.2,6Z"
        fill="#004dbc"
        fillRule="evenodd"
        id="conversation"
        transform="translate(0 -0.832)"
      />
    </svg>
  );
};
