import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { defaultPager } from '@/src/modules/CampaignsModule/constants';
import {
  EFilterKeysAction,
  IFilterValueTypesAction,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import {
  atomFiltersAction,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

const DEFAULT_PAGER = { page: 1 };

export const useFilterRecurrentActions = () => {
  const [filters, setFilters] = useAtom(atomFiltersAction);
  const [pager, setPager] = useAtom(atomPagerAction);

  const updateFilter = useCallback(
    (key: keyof IFilterValueTypesAction, value: any, dateFieldKey?: string) => {
      if (key === EFilterKeysAction.Date) {
        const [startDate, endDate] = value as Date[];

        const currentFilters = {
          ...filters,
          startDate: startDate,
          endDate: endDate,
          ...(dateFieldKey && { dateFilterField: dateFieldKey }),
        };

        const clearedFilters = clearObjectFalsy(currentFilters);

        pager && setPager({ limit: pager?.limit, ...DEFAULT_PAGER });
        setFilters({ ...clearedFilters });
      } else {
        const currentFilters = { ...filters, [key]: value };
        const clearFilters = clearObjectFalsy(currentFilters);

        pager && setPager({ limit: pager?.limit, ...DEFAULT_PAGER });
        setFilters({ ...clearFilters });
      }
    },
    [filters, pager, setFilters, setPager]
  );

  const resetFilters = useCallback(() => {
    setPager({ limit: pager?.limit || defaultPager.limit, ...DEFAULT_PAGER });
    setFilters({});
  }, [pager?.limit, setFilters, setPager]);

  return { updateFilter, resetFilters };
};
