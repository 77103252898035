import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Notes } from '@/lib/v2/organisms';

import { ContactsProfileNotesTable } from './ContactsProfileNotesTable';
import { NotesFilters } from './NotesFilters';

import { useNotes } from '@/modules/ContactsModule/hooks/useNotes';
import { Note, NoteFilters } from '@/modules/ContactsModule/types/Notes';

export const ContactsProfileActivityNotesTable = ({ emailId }: { emailId: number }) => {
  const [openNote, setOpenNote] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [notesFilters, setNotesFilters] = useState<NoteFilters>({
    startDate: '',
    endDate: '',
  });

  const {
    isLoading,
    notes,
    getListNotes,
    createNote,
    editNote,
    deleteNote,
    changeTablePage,
    totalPages,
  } = useNotes(emailId.toString(), notesFilters);

  const { t } = useTranslation();

  const handleSaveNote = useCallback(
    async (text: string) => {
      const note: Note = {
        content: text,
        contactId: emailId,
        marked: false,
        attached: '',
      };
      const noteCreated = await createNote(note);
      if (noteCreated) {
        await getListNotes();
        toast({
          variant: 'success',
          title: t('PROFILE_NOTES_TABLE.noteSuccessfully'),
          autoCloseDelay: 5000,
          id: 'note-created-notification',
        });
      } else {
        toast({
          variant: 'error',
          title: t('PROFILE_NOTES_TABLE.errorCreateNoteMessage'),
          autoCloseDelay: 5000,
          id: 'error-creating-note-notification',
        });
      }
      setOpenNote(false);
      setNoteText('');
    },
    [createNote, emailId, getListNotes, t]
  ) as (text: string) => void;

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setNoteText(event.target.value);

  const handleChangeFilters = (filters: NoteFilters) => setNotesFilters(filters);

  const isEmptyNotes = useMemo(
    () => (notes && notes.length > 0) || notesFilters.startDate.length > 0,
    [notes, notesFilters.startDate]
  );

  return (
    <>
      <Flex alignment="start">
        <NotesFilters
          isEmptyNotes={isEmptyNotes}
          onFilter={handleChangeFilters}
          onOpenNote={setOpenNote}
        />
      </Flex>
      {isLoading && (
        <Flex column>
          {
            <span className="size-full py-10">
              <TableSkeleton />
            </span>
          }
        </Flex>
      )}
      {!isLoading && (
        <ContactsProfileNotesTable
          changeTablePage={changeTablePage}
          isLoading={isLoading}
          notesList={notes ? notes : []}
          notesTotalCount={notes ? notes.length : 0}
          totalPages={totalPages}
          onDeleteNote={deleteNote}
          onEditNote={editNote}
          onGetListNotes={getListNotes}
          onOpenNote={setOpenNote}
        />
      )}
      <Notes
        withModal
        disableButton={noteText.length === 0}
        headerTitle={t('PROFILE_NOTES_TABLE.addNote')}
        id="add-note"
        open={openNote}
        textSaveButton={t('PROFILE_NOTES_TABLE.save')}
        value={noteText}
        onActionSave={handleSaveNote}
        onChange={handleOnChange}
        onCloseModal={setOpenNote}
      />
    </>
  );
};
