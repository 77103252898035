export const SegmentsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 3409" id="Group_3409" transform="translate(-0.349 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.349 -0.163)"
          width="19"
        />
        <g id="SEGMENT" transform="translate(1.383 1.694)">
          <g data-name="Group 6756" id="Group_6756">
            <g data-name="Group 6755" id="Group_6755">
              <path
                d="M18.06,19.056V11.6a7.372,7.372,0,0,0-7.26,7.26,7.261,7.261,0,0,0,14.519.2Z"
                fill="#004dbc"
                id="streak_3492_00000039827060622991078780000001872058829086658985_"
                transform="translate(-10.8 -9.442)"
              />
              <path
                d="M15.3,18.741h8.241A8.29,8.29,0,0,0,15.3,10.5h0v8.241Z"
                fill="#004dbc"
                id="streak_3493_00000165207470076178887490000009076980915610187162_"
                transform="translate(-6.471 -10.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
