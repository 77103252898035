import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Stepper, Steps } from '@/lib/v2/components';

import { atomSignUpCurrentStep, atomStepCompleted } from '@/src/modules/AuthModule/atoms/SignUp';
import {
  SignUpFinished,
  SignUpFormOne,
  SignUpFormThree,
  SignUpFormTwo,
} from '@/src/modules/AuthModule/components';

const SignUp = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useAtom(atomSignUpCurrentStep);
  const stepCompleted = useAtomValue(atomStepCompleted);

  const steps: Steps = useMemo(() => {
    return [
      {
        id: 'one',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_ONE.stepName'),
        onClick: () => {
          if (currentStep === 0 || stepCompleted < 1) return;
          setCurrentStep(1);
        },
      },
      {
        id: 'two',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_TWO.stepName'),
        onClick: () => {
          if (currentStep === 0 || stepCompleted < 2) return;
          setCurrentStep(2);
        },
      },
      {
        id: 'three',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_THREE.stepName'),
        onClick: () => {
          if (currentStep === 0 || stepCompleted < 3) return;
          setCurrentStep(3);
        },
      },
    ];
  }, [currentStep, setCurrentStep, stepCompleted, t]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full justify-center">
        <div className="w-full md:translate-x-[10%] lg:translate-x-[14%]">
          <Stepper isHorizontal currentStep={currentStep} steps={steps} />
        </div>
      </div>
      <div className="mx-auto w-full md:w-3/4 lg:w-7/12">
        <If condition={currentStep === 1}>
          <SignUpFormOne />
        </If>
        <If condition={currentStep === 2}>
          <SignUpFormTwo />
        </If>
        <If condition={currentStep === 3}>
          <SignUpFormThree />
        </If>
        <If condition={currentStep === 0}>
          <SignUpFinished />
        </If>
      </div>
    </div>
  );
};

export default SignUp;
