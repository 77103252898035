import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/lib/v2/components/Layout/Stack';
import Text from '@/lib/v2/components/Text/Text';
import { CloudComputingUploadIcon } from '@/lib/v2/icons/outline';

import { ImportSteps } from '@/src/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

import { atomFiles } from '@/modules/ContactsModule/atoms/Steps';
import { useResetImport } from '@/modules/ContactsModule/hooks/useResetImport';

export const FinishSuccess = () => {
  const { t } = useTranslation();
  const { resetImport } = useResetImport();
  const [files] = useAtom(atomFiles);

  if (files.step !== ImportSteps.finish || files.state !== loadingStatus.ready) return null;

  return (
    <>
      <div className="relative flex h-3/4 flex-col items-center justify-center p-10 text-center">
        <CloudComputingUploadIcon />
        <Text className="text-xs m-4 text-gray-500">{t('IMPORT_MAIN.uploadingOk')}</Text>
        <button onClick={resetImport}>
          <Flex column>
            <Text>
              <span className="text-sky-600">{t('IMPORT_MAIN.uploadOtherFile')}</span>
            </Text>
          </Flex>
        </button>
      </div>

      <Flex
        withGap
        alignment="end"
        className="border-emblue-border w-full border-t px-10 py-6"
        gapSize="small"
      />
    </>
  );
};

export default FinishSuccess;
