import React from 'react';

type Props = {};

const TwoStepAuthenticationLoading = (props: Props) => {
  return (
    <div className="flex h-full w-full animate-pulse flex-col items-center gap-4 py-4 sm:w-[300px]">
      <div className="h-20 w-20 rounded-full bg-slate-200"></div>
      <div className="h-4 w-36 rounded bg-slate-200"></div>
      <div className="flex w-full flex-col gap-3">
        <div className="h-2 w-full rounded bg-slate-200"></div>
        <div className="h-2 w-full rounded bg-slate-200"></div>
        <div className="h-2 w-full rounded bg-slate-200"></div>
      </div>
      <div className="mt-8 h-9 w-full rounded bg-slate-200"></div>
      <div className="mt-4 h-10 w-full rounded bg-slate-200"></div>
    </div>
  );
};

export default TwoStepAuthenticationLoading;
