import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { atomWhatsAppTemplates } from '@/modules/ContactsModule/atoms/WhatsApp';

interface UseFilteredTemplatesParams {
  filterBy?: 'pending' | 'approved' | 'rejected';
  searchTerm?: string;
}

export const useFilteredTemplates = ({ filterBy, searchTerm = '' }: UseFilteredTemplatesParams) => {
  const templates = useAtomValue(atomWhatsAppTemplates);

  const filteredTemplates = useMemo(() => {
    let filtered = templates;

    if (filterBy) {
      filtered = filtered.filter(
        (template) => template.approvalStatus?.toLowerCase() === filterBy.toLowerCase()
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((template) => {
        const searchIn = `${template.friendly_name} ${template.types['twilio/text']?.body || ''} ${
          template.sid
        }`.toLowerCase();
        return searchIn.includes(searchTerm.toLowerCase());
      });
    }

    return filtered;
  }, [templates, filterBy, searchTerm]);

  return { filteredTemplates };
};
