import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { chatShow } from '@/lib/components/SupportCard';
import { Modal, Text } from '@/lib/v2/components';

import { useSmsManagePlan } from '@/modules/MyPlanModule/hooks/useSms';

interface SMSCountryModalPriceProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const SMSCountryModalPrice = ({ isOpen, onClose }: SMSCountryModalPriceProps) => {
  const { t } = useTranslation();

  const { smsPrices } = useSmsManagePlan();
  const sortedSmsPrices = smsPrices?.sort((a, b) => a.country.localeCompare(b.country));

  return (
    <Modal noPadding open={isOpen} onClose={onClose}>
      <Text className="pl-8 pt-8" color="black" fontWeight="bold">
        {t('MANAGE_PLAN.ADDITIONAL_COSTS.SMSModalPriceTitle')}
      </Text>
      <div className="my-5 w-full border-b px-8" />
      <table className="w-full">
        <thead>
          <tr>
            <th className="mt-2 h-8">
              <Text alignment="left" className="pb-4 pl-8" color="black" fontWeight="semibold">
                {t('MANAGE_PLAN.ADDITIONAL_COSTS.country')}
              </Text>
            </th>
            <th className="mt-2 h-8">
              <Text alignment="right" className="pb-4 pr-8" color="black" fontWeight="semibold">
                {t('MANAGE_PLAN.ADDITIONAL_COSTS.price')}
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedSmsPrices?.length &&
            sortedSmsPrices.map((item, index) => {
              const rowClass = index % 2 === 0 ? 'bg-gray-100' : 'bg-white';
              return (
                <tr key={item.country} className={rowClass}>
                  <td className="h-10">
                    <Text className="pl-8 text-left" variant="text">
                      {item.country}
                    </Text>
                  </td>
                  <td className="h-10">
                    <Text className="pr-8 text-right" variant="text">
                      USD ${item.price * 1000}
                    </Text>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="my-8 w-full border-b px-8" />
      <div className="flex w-full flex-row pb-8 pl-8">
        <Text isItalic variant="text-sm">
          <Trans
            components={{
              li: (
                <Text
                  isItalic
                  link
                  color="primary"
                  fontWeight="medium"
                  id="link-chat"
                  variant="text-sm"
                  onClick={chatShow}
                />
              ),
            }}
            i18nKey="MANAGE_PLAN.ADDITIONAL_COSTS.messageText"
          />
        </Text>
      </div>
    </Modal>
  );
};

export default memo(SMSCountryModalPrice);
