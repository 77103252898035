import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

import { useUserData } from '@/src/infrastructure/services/useUserData';

export const useInboxMetrics = () => {
  const [userData] = useUserData();

  const trackTryInboxClick = useCallback(() => {
    datadogRum.addAction('inbox.onTryInboxClick', {
      accountId: Number(userData.companyId),
      companyName: userData.companyName,
      email: userData.email,
    });
  }, [userData.companyId, userData.companyName, userData.email]);

  const trackYoutubeVideoPlay = useCallback(() => {
    datadogRum.addAction('inbox.onYoutubeVideoPlay', {
      accountId: Number(userData.companyId),
      companyName: userData.companyName,
      email: userData.email,
    });
  }, [userData.companyId, userData.companyName, userData.email]);

  const trackInboxLandingPageClick = useCallback(() => {
    datadogRum.addAction('inbox.onInboxLandingPageClick', {
      accountId: Number(userData.companyId),
      companyName: userData.companyName,
      email: userData.email,
    });
  }, [userData.companyId, userData.companyName, userData.email]);

  return {
    trackTryInboxClick,
    trackYoutubeVideoPlay,
    trackInboxLandingPageClick,
  };
};
