export const LogoutIcon = () => {
  return (
    <svg
      height="39.487"
      id="power"
      viewBox="0 0 39.487 39.487"
      width="39.487"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 6" fill="none" height="39.487" id="Rectangle_6" width="39.487" />
      <line
        data-name="Line 1"
        fill="none"
        id="Line_1"
        stroke="#919296"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.125"
        transform="translate(19.742 7.404)"
        y2="11.723"
      />
      <path
        d="M27.853,9.321a13.574,13.574,0,1,1-14.808,0"
        data-name="Path 3161"
        fill="none"
        id="Path_3161"
        stroke="#919296"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.125"
        transform="translate(-0.705 -0.956)"
      />
    </svg>
  );
};
