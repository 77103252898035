import { useAtomValue } from 'jotai';
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import {
  atomFiltersCampaign,
  atomStrategyList,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { STRATEGY_MAP_BY_ID } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';
import { strategyOrderOtherOption } from '@/modules/CampaignsModule/utils';

interface IFilterCampaignStrategyProps {
  currentItems: (updatedStrategy: number[]) => void;
}

const FilterStrategy = ({ currentItems }: IFilterCampaignStrategyProps) => {
  const { t } = useTranslation();
  const filters = useAtomValue(atomFiltersCampaign);

  const [strategiesList, setStrategiesList] = useState<DropdownItemProps[]>([]);
  const strategyList = useAtomValue(atomStrategyList);
  const [selectedStrategies, setSelectedStrategies] = useState<number[]>(filters?.strategy ?? []);

  useEffect(() => {
    const strategiesListCast = strategyOrderOtherOption(strategyList);
    setStrategiesList(strategiesListCast);
  }, [strategyList]);

  useEffect(() => {
    currentItems(selectedStrategies);
  }, [selectedStrategies, currentItems]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    setSelectedStrategies((prevSelected) =>
      checked
        ? [...prevSelected, Number(value)]
        : prevSelected.filter((item) => item !== Number(value))
    );
  }, []);

  const strategiesItems = useMemo(() => {
    return strategiesList.map((strategy) => ({
      ...strategy,
      label: t(
        `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${STRATEGY_MAP_BY_ID[Number(strategy.id)]}`,
        strategy.label
      ),
      value: t(`CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${strategy.value}`, String(strategy.value)),
      checked: selectedStrategies.includes(Number(strategy.id)),
    }));
  }, [strategiesList, t, selectedStrategies]);

  const classNameScrollable = 'flex flex-col gap-2 max-h-[150px]';
  return (
    <div className="mb-4" id="filters-campaign-strategy">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGNS_MAIN.FILTERS.strategy')}
      </Text>
      <div className={classNameScrollable}>
        {strategiesItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`strategy-${item.id}`}
            label={item.label}
            value={item.id}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterStrategy);
