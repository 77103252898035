import classNames from 'classnames';
import { memo, MouseEventHandler, useMemo } from 'react';

import Button, { SizeButton } from '@/lib/v2/components/Button/Button';
import { Dropdown, DropdownItemProps } from '@/lib/v2/components/Dropdown';

type ButtonColor = 'primary' | 'standard';

interface ButtonWithDropdownProps {
  id?: string;
  color?: ButtonColor;
  children?: JSX.Element | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: SizeButton;
  dropdownItems: DropdownItemProps[];
  isLoading?: boolean;
}

const ButtonWithDropdown = ({
  id,
  color = 'primary',
  children,
  onClick,
  size,
  dropdownItems,
  isLoading,
}: ButtonWithDropdownProps) => {
  if (!dropdownItems) {
    throw new Error('dropdownItems is required');
  }

  const itemsMemo = useMemo(() => {
    return dropdownItems;
  }, [dropdownItems]);

  const classesButton = classNames('!rounded-r-none !border-r focus:!ring-0 focus:!ring-offset-0', {
    '!border-r-blue-300': color === 'primary',
  });

  return (
    <div className="flex">
      <Button
        className={classesButton}
        disabled={isLoading}
        id={id}
        isLoading={isLoading}
        size={size}
        {...(color === 'primary' && { primary: true })}
        {...(color === 'standard' && { standard: true, secondary: true, outline: true })}
        onClick={onClick}
      >
        {children}
      </Button>
      <Dropdown
        menuOnTheLeft
        className="!rounded-l-none !border-l-0 focus:!ring-0 focus:!ring-offset-0"
        color={color}
        disabled={isLoading}
        dropdownItems={itemsMemo}
        id={`${id}-dropdown`}
        label=""
        size={size}
      />
    </div>
  );
};

export default memo(ButtonWithDropdown);
