import { IContactFieldsTranslationsToV1 } from '@/src/ContactsModule/interfaces/Interfaces';

interface StringArray {
  [key: number]: string;
}

export const dirtyValues = (dirtyFields: object | boolean, allValues: []): object => {
  if (Array.isArray(dirtyFields)) {
    dirtyFields = dirtyFields.map((el, index) => {
      return Object.fromEntries(
        Object.keys(el).map((key) => {
          return [allValues[index]['name'], allValues[index][key]];
        })
      );
    });
  }
  if (dirtyFields === true) {
    return allValues;
  }

  if (Array.isArray(dirtyFields)) {
    return dirtyFields;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key: StringArray) => [
      key,
      dirtyValues(
        dirtyFields[key as keyof typeof dirtyFields],
        allValues[key as keyof typeof dirtyFields]
      ),
    ])
  );
};

/* cspell:disable */
export const translateField: IContactFieldsTranslationsToV1 = {
  names: 'nombre',
  lastNames: 'apellido',
  idContact: 'id_contacto',
  gender: 'sexo',
  mainPhone: 'telefono_1',
  secondaryPhone: 'telefono_2',
  website: 'web_url',
  address: 'direccion',
  city: 'ciudad',
  country: 'pais',
  birthday: 'cumpleanios',
  company: 'empresa',
  position: 'cargo',
  secondaryEmail: 'email_secundario',
  idTrigger: 'id_trigger',
  recency: 'recencia',
  frequency: 'frecuencia',
  amount: 'monto',
  eCommerceClassification: 'clasificacion_ecommerce',
};
/* cspell:enable */
