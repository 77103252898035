import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import CreateSegmentProvider from '@/src/modules/ContactsModule/contexts/CreateSegmentContext';

const SegmentLayout = () => {
  return (
    <CreateSegmentProvider>
      <Outlet />
    </CreateSegmentProvider>
  );
};

export default memo(SegmentLayout);
