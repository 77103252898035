export const AirPlaneIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3053 0.698283C18.0214 0.411002 17.6682 0.201782 17.2799 0.0908355C16.8915 -0.0201105 16.4811 -0.0290315 16.0883 0.0649344L3.41998 2.73292C2.69043 2.83296 2.00329 3.13468 1.43596 3.6041C0.868636 4.07352 0.443646 4.69199 0.208856 5.38987C-0.0259345 6.08775 -0.0611996 6.83731 0.107032 7.55414C0.275263 8.27098 0.640309 8.92661 1.16106 9.44721L2.52132 10.8065C2.59495 10.8801 2.65333 10.9675 2.69314 11.0637C2.73295 11.1599 2.75339 11.263 2.75331 11.367V13.8751C2.75506 14.2278 2.83625 14.5755 2.99084 14.8924L2.98451 14.898L3.0051 14.9186C3.23709 15.385 3.61601 15.7622 4.08349 15.9921L4.10407 16.0127L4.10962 16.0063C4.42661 16.1609 4.77436 16.2421 5.12704 16.2438H7.63537C7.84523 16.2437 8.04658 16.3268 8.19516 16.475L9.55463 17.8343C9.91926 18.203 10.3532 18.4958 10.8316 18.6959C11.3099 18.8961 11.8231 18.9996 12.3417 19.0005C12.7738 19 13.2029 18.9294 13.6125 18.7915C14.304 18.5644 14.9184 18.1486 15.3862 17.5911C15.854 17.0335 16.1567 16.3563 16.2601 15.6358L18.9324 2.94113C19.0312 2.545 19.0254 2.12999 18.9155 1.73679C18.8055 1.34359 18.5953 0.985721 18.3053 0.698283ZM3.64247 9.68867L2.28142 8.32935C1.96449 8.02008 1.74236 7.62684 1.6411 7.19578C1.53984 6.76471 1.56366 6.31371 1.70976 5.8957C1.8514 5.46687 2.11325 5.08773 2.46415 4.80339C2.81506 4.51904 3.24026 4.34146 3.68919 4.29175L16.2316 1.65147L4.33527 13.5481V11.367C4.33647 11.0554 4.27586 10.7466 4.15694 10.4585C4.03802 10.1704 3.86316 9.90876 3.64247 9.68867ZM14.7043 15.3651C14.6435 15.8023 14.462 16.214 14.1802 16.5539C13.8984 16.8938 13.5275 17.1484 13.109 17.2891C12.6905 17.4299 12.2411 17.4513 11.8111 17.3508C11.3812 17.2503 10.9877 17.0321 10.675 16.7204L9.31314 15.3587C9.09332 15.1377 8.83185 14.9625 8.54387 14.8432C8.25589 14.7239 7.9471 14.6628 7.63537 14.6636H5.45404L17.352 2.77092L14.7043 15.3651Z"
        fill="#364365"
      />
    </svg>
  );
};
