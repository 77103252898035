import { ExclamationIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';

// TODO remove this when the feature flag showDragDropRemovalNotice is removed
interface DeprecatedBannerProps {
  isNegativeMargin?: boolean;
}

const DeprecatedBanner = ({ isNegativeMargin = false }: DeprecatedBannerProps) => {
  const { t } = useTranslation();

  const bannerClassNames = classNames(
    'warning-campaigns relative inset-x-0 flex w-full items-center gap-2 border border-emblue-passive bg-[#FEFCEA] px-3 py-1',
    { '-mb-4': isNegativeMargin, 'mb-4': !isNegativeMargin }
  );

  return (
    <div className={bannerClassNames}>
      <IconSvg
        className="relative"
        fillColor="passive"
        height="16px"
        svgComponent={<ExclamationIcon />}
        width="16px"
      />
      <div className="text-14 text-emblue-text">
        <span className="font-medium">
          {t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured')}
        </span>{' '}
        {t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.description')}
      </div>
    </div>
  );
};

export default DeprecatedBanner;
