import { useAtomValue } from 'jotai';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';

import { atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { DROPDOWN_CHANNELS } from '@/modules/CampaignsModule/constants';

interface IFilterCampaignChannelProps {
  currentItems: (updatedChannel: number[]) => void;
}

const FilterChannel = ({ currentItems }: IFilterCampaignChannelProps) => {
  const { t } = useTranslation();
  const filtersAction = useAtomValue(atomFiltersAction);

  const [selectedChannel, setSelectedChannel] = useState<number[]>(filtersAction?.channel ?? []);

  useEffect(() => {
    currentItems(selectedChannel);
  }, [selectedChannel, currentItems]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    setSelectedChannel((prevSelected) =>
      checked
        ? [...prevSelected, Number(value)]
        : prevSelected.filter((item) => item !== Number(value))
    );
  }, []);

  const channelItems = DROPDOWN_CHANNELS.map((channel) => ({
    ...channel,
    label: t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${channel.label}`),
    checked: selectedChannel.includes(Number(channel.value)),
  })).sort((a, b) => a.label.localeCompare(b.label));

  const classNameScrollable = 'flex flex-col gap-2 max-h-[180px]';

  return (
    <div className="mb-4">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGN_ACTIONS_MAIN.FILTERS.channel')}
      </Text>
      <div className={classNameScrollable}>
        {channelItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`channel-${item.id}`}
            label={item.label}
            value={item.value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterChannel);
