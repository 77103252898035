import { memo } from 'react';

import { Option } from './Select';

interface SelectedOptionsProps {
  value: Option[];
  deleteOption: (index: number) => () => void;
}

const SelectedOptions = ({ value, deleteOption }: SelectedOptionsProps) => {
  const selectedOptions = value.map((option, index) => (
    <span
      key={option?.id}
      className="ml-0.5 inline-flex items-center rounded-full bg-blue-50 pl-2 pr-0.5 text-base font-medium text-emblue-primary"
    >
      {option?.name}
      <span
        className="ml-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full
                text-emblue-primary hover:bg-indigo-200 hover:text-emblue-primary-hover
                focus:bg-blue-400 focus:text-white focus:outline-none"
        role="button"
        onClick={deleteOption(index)}
      >
        <span className="sr-only">Remove small option</span>
        <svg className="h-2 w-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
          <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
        </svg>
      </span>
    </span>
  ));

  return <>{selectedOptions}</>;
};

export default memo(SelectedOptions);
