import { t } from 'i18next';

import { Option } from '@/lib/v2/components';

import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';
import {
  Channel,
  ECommerceSegmentsV1Name,
  ListProfile,
  Tag,
} from '@/src/modules/ContactsModule/types/Segments';

export const mapValuesOptionsUserGroups = (userGroupData: Array<string[]>): Option[] => {
  return userGroupData.map((group) => ({
    id: Number(group[0]),
    name: group[1],
    value: group[0],
  }));
};

export const mapValuesOptionsTags = (tagsData: Tag[]): Option[] => {
  return tagsData.map((tag) => ({ id: tag.tagId, name: tag.name, value: tag.tagId }));
};

export const mapValuesOptionsChannels = (channelsData: Channel[]): Option[] => {
  return channelsData.map((channel) => ({
    id: channel.channelTypeId,
    name: channel.description,
    value: channel.channelTypeId,
  }));
};

export const mapValuesOptionsNotAddressees = (notAddresseesData: Array<string[]>): Option[] => {
  /* cspell:disable */
  const translation: Record<string, string> = {
    Cuarentena: 'quarantine',
    Desuscripto: 'unsubscribe',
    Suspendidos: 'suspended',
    Denunciantes: 'complainers',
    Inseguros: 'unsafe',
    'Lista negra': 'blocked',
  };
  return notAddresseesData.map(
    (notAddressee): Option => ({
      id: notAddressee[0],
      name: t(`STATUS_FILTER.${translation[notAddressee[1]]}`).toString(),
      value: notAddressee[1],
    })
  );
};

export const mapValuesOptionsListProfiles = (
  listProfiles: ListProfile[],
  keyValue: string
): Option[] => {
  return listProfiles
    .find((listProfile) => listProfile.Key === keyValue)
    ?.Value.map((item) => ({ id: item.Key, name: item.Value, value: item.Key })) as Option[];
};

export const mapValuesOptionsEcommerceGroups = (ecommerceGroups: IGroup[]) => {
  /** cspell:disable */
  const translationsKeys: Record<ECommerceSegmentsV1Name, string> = {
    'RFM HIGH': 'RfmHigh',
    'RFM MED': 'RfmMedium',
    'RFM LOW': 'RfmLow',
    Clientes: 'clients',
    'Compraron al menos una vez': 'purchasedAtLeast1Time',
    'Compraron en el último mes': 'purchasedOnlyAMonthAgo',
    'Compraron en los últimos 10 días': 'tenDaysSinceLastPurchase',
    'Nunca Compraron': 'neverPurchased',
    Compraron: 'custommersBought',
  };
  /** cspell:enable */

  return ecommerceGroups.map((ecommerceGroup) => {
    let key = translationsKeys[ecommerceGroup.groupName as ECommerceSegmentsV1Name];
    let tag = '';

    if (key === undefined) {
      key = translationsKeys[ecommerceGroup.groupName.split(':')[0] as ECommerceSegmentsV1Name];
      tag = ecommerceGroup.groupName.split(':')[1];
    }

    return {
      id: ecommerceGroup.id,
      name: t(`EMBLUE_SEGMENTS.CARDS.ECOMMERCE.${key}.title`, {
        ...(tag.length > 0 && { tag }),
      }),
      value: ecommerceGroup.id,
    };
  });
};
