export const WhatsAppIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 40 40" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 -0.00195312C8.97229 -0.00195312 0 8.97034 0 19.998C0 23.2734 0.862708 26.3349 2.26172 29.0645L0.09375 36.8262C-0.411203 38.6296 1.37193 40.4126 3.17578 39.9082L10.9434 37.7402C13.6707 39.1363 16.7278 39.998 20 39.998C31.0277 39.998 40 31.0258 40 19.998C40 8.97034 31.0277 -0.00195312 20 -0.00195312ZM20 2.99805C29.4063 2.99805 37 10.5918 37 19.998C37 29.4043 29.4063 36.998 20 36.998C16.9984 36.998 14.1906 36.2175 11.7422 34.8535C11.3971 34.6612 10.99 34.6127 10.6094 34.7188L3.22266 36.7793L5.28516 29.3965C5.3917 29.0153 5.34326 28.6074 5.15039 28.2617C3.78365 25.8115 3 23.0026 3 19.998C3 10.5918 10.5937 2.99805 20 2.99805ZM13.2402 11C12.9212 11 12.4058 11.1197 11.9668 11.5977C11.5288 12.0737 10.2949 13.2281 10.2949 15.5781C10.2949 17.9281 12.0051 20.1976 12.2441 20.5176C12.4821 20.8346 15.5473 25.8126 20.4023 27.7266C24.4363 29.3166 25.2568 28.9999 26.1328 28.9199C27.0088 28.8419 28.9594 27.7664 29.3574 26.6504C29.7554 25.5344 29.7557 24.5798 29.6387 24.3828C29.5197 24.1838 29.2007 24.0632 28.7227 23.8242C28.2457 23.5852 25.8989 22.4305 25.4609 22.2715C25.0229 22.1125 24.7028 22.0318 24.3848 22.5098C24.0668 22.9878 23.152 24.0629 22.873 24.3809C22.594 24.7009 22.3159 24.741 21.8379 24.502C21.3589 24.261 19.8221 23.7579 17.9961 22.1309C16.5761 20.8659 15.6208 19.3022 15.3418 18.8242C15.0638 18.3482 15.3118 18.0866 15.5508 17.8496C15.7658 17.6356 16.0286 17.2927 16.2676 17.0137C16.5046 16.7347 16.5832 16.5368 16.7422 16.2188C16.9012 15.9017 16.8221 15.6218 16.7031 15.3828C16.5841 15.1438 15.6555 12.7809 15.2305 11.8359C14.8735 11.0419 14.4972 11.0247 14.1562 11.0117C13.8783 11.0007 13.5582 11 13.2402 11Z"
        fill="white"
      />
    </svg>
  );
};
