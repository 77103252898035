import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, CardSection, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { ContactsTableContainer } from '@/src/ContactsModule/components/ContactsTableContainer/ContactsTableContainer';
import { TagIcon } from '@/src/ContactsModule/Icons/TagIcon';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { SectionHeader } from '@/modules/ContactsModule/components';
import { useFilterState } from '@/modules/ContactsModule/hooks/useFilterState';

export const TagDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tagId } = useParams();

  const [subtitleCountMessage, setSubtitleCountMessage] = useState<ReactNode>();
  const [contactsCountFiltered, setContactsCountFiltered] = useState<number>();
  const [tag, setTag] = useState<ITag>();
  const [tagInfo] = useEmblue(ServiceMethods.getTagInfo, { tagId: Number(tagId) });

  const { isFilterApplied } = useFilterState();

  useEffect(() => {
    if (tagInfo && !tag) {
      setTag(tagInfo.tagInfo);
    }
  }, [tag, tagInfo]);

  const title = useMemo(() => {
    return (
      <Flex column itemAlignment="start">
        <Flex withGap alignment="start">
          <Text as="span" fontWeight="medium" variant="title-1">
            {t('TAGS_MAIN.tag')} -
            {tag ? (
              <span className="text-[24px] font-medium text-[#004DBC]"> {tag.name} </span>
            ) : null}
            {
              tag && tag.contacts && (
                <span className="text-[24px] font-medium text-[#004DBC]">
                  (
                  {quantifier(
                    contactsCountFiltered && contactsCountFiltered > tag?.contacts
                      ? contactsCountFiltered
                      : tag.contacts
                  )}
                  )
                </span>
              ) // In case of action executed and tag?.contacts is cached
            }
          </Text>
        </Flex>
        {isFilterApplied
          ? subtitleCountMessage && subtitleCountMessage
          : t('CONTACTS_MAIN.noFilters')}
      </Flex>
    );
  }, [contactsCountFiltered, isFilterApplied, subtitleCountMessage, t, tag]);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeader
            backButton
            icon={<TagIcon />}
            title={title}
            onBack={() => navigate({ pathname: '/v2/contacts/tags' })}
          />
          <div className="h-full w-full">
            <ContactsTableContainer
              isDisableTagDropdown
              setContactsCountFiltered={setContactsCountFiltered}
              setCountMessage={setSubtitleCountMessage}
            />
          </div>
        </Flex>
      </CardSection>
    </Card>
  );
};
