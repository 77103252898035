import { CardSection, Flex } from '@/lib/v2/components';

import { EmailAction } from '@/modules/CampaignsModule/components/actionManagement/EmailAction';

const EmailMain = () => {
  return (
    <div className="relative bg-white">
      <CardSection noPadding>
        <Flex column>
          <EmailAction />
        </Flex>
      </CardSection>
    </div>
  );
};

export default EmailMain;
