import { IconProps } from './iconPropTypes';

export const ActivityFilterIcon = ({ color }: IconProps) => {
  return (
    <svg
      height="17.775"
      viewBox="0 0 17.111 17.775"
      width="17.111"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="activity" transform="translate(1 1)">
        <path
          d="M5.245,12.231,7.608,9.16l2.7,2.118,2.313-2.984"
          data-name="Path 3361"
          fill="none"
          fillRule="evenodd"
          id="Path_3361"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-1.718 -2.36)"
        />
        <circle
          cx="1.518"
          cy="1.518"
          data-name="Ellipse 1572"
          fill="none"
          id="Ellipse_1572"
          r="1.518"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(12.076 0)"
        />
        <path
          d="M10.774,1.62H4.793A3.9,3.9,0,0,0,.778,5.853V12.5c0,2.478,1.507,4.226,4.015,4.226h7.081A3.889,3.889,0,0,0,15.889,12.5V6.712"
          data-name="Path 3362"
          fill="none"
          fillRule="evenodd"
          id="Path_3362"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-0.778 -0.955)"
        />
      </g>
    </svg>
  );
};
