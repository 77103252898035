export const InactiveContactIcon = () => {
  return (
    <svg
      height="30.494"
      viewBox="0 0 35.402 35.494"
      width="30.402"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Grupo 7241" id="Grupo_7241" transform="translate(-47.806 0)">
        <path
          d="M25.567,35.494H4.373a4.238,4.238,0,0,1-3.535-1.4A4.568,4.568,0,0,1,0,31.328a6.876,6.876,0,0,1,.151-1.5A19.935,19.935,0,0,1,1.573,25.93a19.086,19.086,0,0,1,2.1-3.428,14.251,14.251,0,0,1,3.314-3.136,1.1,1.1,0,0,1,.677-.225,1.39,1.39,0,0,1,.74.225,11.793,11.793,0,0,0,6.783,2.138,11.627,11.627,0,0,0,6.729-2.138,1.217,1.217,0,0,1,.7-.213,1.33,1.33,0,0,1,.72.213c.091.06.182.123.272.186-.163.2-.318.415-.461.633a6.781,6.781,0,0,0-1.1,3.717,4.767,4.767,0,0,0,1.386,3.307,4.909,4.909,0,0,0,1.984,1.229,7.727,7.727,0,0,0-.031.85,5.122,5.122,0,0,0,.4,2.036,4.954,4.954,0,0,0-.562,2.31,5.089,5.089,0,0,0,.342,1.858ZM15.143,19.151a9.546,9.546,0,1,1,3.6-.7A9.545,9.545,0,0,1,15.143,19.151Z"
          data-name="Sustracción 1"
          fill="#96a6ce"
          id="Sustracción_1"
          transform="translate(47.806 0)"
        />
        <g data-name="Grupo 7156" id="Grupo_7156" transform="translate(72.999 19.556)">
          <g data-name="Grupo 7154" id="Grupo_7154">
            <path
              d="M528.007,717.847a4.285,4.285,0,0,0-1.8-1.512,6.1,6.1,0,0,0-2.583-.535,5.638,5.638,0,0,0-2.772.661,4.958,4.958,0,0,0-1.827,1.669,3.871,3.871,0,0,0-.63,2.016,1.582,1.582,0,0,0,.472,1.1,1.666,1.666,0,0,0,1.2.5,1.624,1.624,0,0,0,1.606-1.354,4.022,4.022,0,0,1,.724-1.386,1.687,1.687,0,0,1,1.228-.378,1.65,1.65,0,0,1,1.165.409,1.219,1.219,0,0,1,.409.976.925.925,0,0,1-.126.5,1.92,1.92,0,0,1-.378.5c-.157.158-.472.441-.85.787a13.166,13.166,0,0,0-1.134,1.071,3.5,3.5,0,0,0-.724,1.134,3.858,3.858,0,0,0-.283,1.543,1.686,1.686,0,0,0,.472,1.26,1.574,1.574,0,0,0,1.1.441,1.43,1.43,0,0,0,1.512-1.291c.063-.283.126-.5.157-.63a1.118,1.118,0,0,1,.126-.284,1.454,1.454,0,0,1,.22-.315l.472-.473c.756-.693,1.323-1.2,1.606-1.48a5.334,5.334,0,0,0,.85-1.134,3.36,3.36,0,0,0,.378-1.606A3.75,3.75,0,0,0,528.007,717.847Z"
              data-name="Trazado 4463"
              fill="#96a6ce"
              id="Trazado_4463"
              transform="translate(-518.4 -715.8)"
            />
          </g>
          <g data-name="Grupo 7155" id="Grupo_7155" transform="translate(3.15 12.253)">
            <path
              d="M530.258,754.7a1.912,1.912,0,0,0-1.323.536,1.784,1.784,0,0,0-.535,1.291,1.692,1.692,0,0,0,.567,1.354,1.843,1.843,0,0,0,1.26.472,1.9,1.9,0,0,0,1.26-.472,1.737,1.737,0,0,0,.567-1.354,1.784,1.784,0,0,0-.536-1.291A1.663,1.663,0,0,0,530.258,754.7Z"
              data-name="Trazado 4464"
              fill="#96a6ce"
              id="Trazado_4464"
              transform="translate(-528.4 -754.7)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
