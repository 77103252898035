import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { chatShow } from '@/lib/components/SupportCard';
import { Button, Card, CardSection, IconSvg, If, Text } from '@/lib/v2/components';

import PriceText from '@/src/modules/MyPlanModule/screens/ManagePlan/components/PlanPriceInformation/PriceText';

import UpdatePlanStatus from './UpdatePlanStatus';

import { atomBillingFormLocal } from '@/modules/MyPlanModule/atoms/ManagePlan';
import { BILLING_FORM_DATA_KEY, MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { usePlanPriceInformation } from '@/modules/MyPlanModule/hooks/usePlanPriceInformation';
import { CancelledIcon } from '@/modules/MyPlanModule/images/CancelledIcon';
import { CompletedIcon } from '@/modules/MyPlanModule/images/CompletedIcon';
import TotalPrice from '@/modules/MyPlanModule/screens/ManagePlan/components/PlanPriceInformation/TotalPrice';
import { PlansIds, SelectedPlanData } from '@/modules/MyPlanModule/types';

interface PaymentStatusProps {
  success: boolean;
}

const PaymentStatus = ({ success }: PaymentStatusProps) => {
  const { t } = useTranslation();
  const billingForm = useAtomValue(atomBillingFormLocal);
  const { getCountrySymbol } = useCountriesOptions();

  const {
    annuallyTotal,
    frequencyOptions,
    handleFrequency,
    planNameSelected,
    planPrice,
    monthSuffixText,
    emailAmount,
    priceEmails,
    isFreemiumPlan,
    smsAmount,
    priceSms,
    onsiteAmount,
    isAnnuallySelected,
    priceOnsite,
    monthlyTotal,
    setSelectedPlanData,
    frequency,
  } = usePlanPriceInformation(getCountrySymbol(billingForm?.billingData.country.toString()));
  const { getCountryOption } = useCountriesOptions();

  useEffect(() => {
    if (billingForm) {
      const newFrequency = frequencyOptions.find((freq) => freq.value === billingForm.period);
      if (newFrequency) handleFrequency(newFrequency);
      setSelectedPlanData((prevSelected) => {
        const copyPrevSelected = JSON.parse(JSON.stringify(prevSelected)) as SelectedPlanData;
        copyPrevSelected.pristine = false;
        copyPrevSelected.planId = billingForm.planId as PlansIds;
        copyPrevSelected.planName = billingForm.planName;
        copyPrevSelected.selectedRanges = {
          rangeEmail: billingForm.volume.email,
          rangeOnsite: billingForm.volume.onsite,
          rangeSms: billingForm.volume.sms,
        };
        return copyPrevSelected;
      });
    }
  }, [billingForm, frequencyOptions, handleFrequency, setSelectedPlanData]);

  const countryName = billingForm?.billingData.country
    ? getCountryOption(billingForm?.billingData.country.toString()).name
    : 'America Central';

  const proratedPrices = billingForm?.proratedPrices;

  if (billingForm?.isEditingPlan) {
    const {
      increaseEmail,
      priceIncreaseEmail,
      increaseSms,
      priceIncreaseSms,
      increaseOnsite,
      priceIncreaseOnsite,
    } = billingForm;

    const downgradesItems: string[] = [];
    [
      { Email: priceIncreaseEmail },
      { SMS: priceIncreaseSms },
      { Onsite: priceIncreaseOnsite },
    ].forEach((item) => {
      if (item) {
        const keys = Object.keys(item);
        const key = keys[0] as 'Email' | 'SMS' | 'Onsite';

        if ((item[key] ?? 0) < 0) {
          downgradesItems.push(key);
        }
      }
    });

    const downgradesItemsText = downgradesItems.join(` ${t('PAYMENT_STATUS.and')} `);

    return (
      <UpdatePlanStatus
        downgrades={downgradesItemsText}
        frequency={frequency}
        increaseEmail={increaseEmail}
        increaseOnSite={increaseOnsite}
        increaseSms={increaseSms}
        isSuccess={success}
        priceIncreaseEmail={priceIncreaseEmail}
        priceIncreaseOnSite={priceIncreaseOnsite}
        priceIncreaseSms={priceIncreaseSms}
        total={isAnnuallySelected ? annuallyTotal : monthlyTotal}
      />
    );
  }

  return (
    <div className="relative flex w-full justify-center pt-4">
      <div className="w-full sm:max-w-[538px]">
        <Card noShadow color="gray">
          <CardSection>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full flex-col items-center gap-2">
                <If condition={success}>
                  <IconSvg height="50px" svgComponent={<CompletedIcon />} width="50px" />
                  <Text fontWeight="medium" variant="headline">
                    {t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.confirmPayment')}
                  </Text>
                </If>
                <If condition={!success}>
                  <IconSvg height="50px" svgComponent={<CancelledIcon />} width="50px" />
                  <Text fontWeight="medium" variant="headline">
                    {t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.cancelled')}
                  </Text>
                </If>
              </div>
              <div className="mt-4 flex justify-between">
                <PriceText
                  isAnnuallySelected={isAnnuallySelected}
                  monthSuffixText={monthSuffixText}
                  price={planPrice}
                  proratedPrice={proratedPrices?.plan}
                  title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.planPrice', {
                    planName: planNameSelected,
                  })}
                />
              </div>
              <div className="mt-4 flex justify-between">
                <PriceText
                  amount={emailAmount}
                  isAnnuallySelected={isAnnuallySelected}
                  monthSuffixText={monthSuffixText}
                  price={priceEmails}
                  priceIncrease={0}
                  proratedPrice={proratedPrices?.email}
                  title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.emails')}
                  type="Emails"
                />
              </div>
              <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
                <PriceText
                  amount={smsAmount}
                  isAnnuallySelected={isAnnuallySelected}
                  monthSuffixText={monthSuffixText}
                  price={priceSms}
                  priceIncrease={0}
                  proratedPrice={proratedPrices?.sms}
                  title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.sms')}
                  type="SMS"
                />
              </div>
              <div className={`flex justify-between ${isFreemiumPlan ? 'opacity-50' : ''}`}>
                <PriceText
                  amount={onsiteAmount}
                  isAnnuallySelected={isAnnuallySelected}
                  monthSuffixText={monthSuffixText}
                  price={priceOnsite}
                  priceIncrease={0}
                  proratedPrice={proratedPrices?.onsite}
                  title={t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.onsite')}
                  type="Onsite"
                />
              </div>
              <div className="mt-4 w-full border-b" />
              <TotalPrice
                annuallyTotal={annuallyTotal}
                isAnnuallySelected={isAnnuallySelected}
                isFreemiumPlan={isFreemiumPlan}
                monthSuffixText={monthSuffixText}
                monthlyTotal={monthlyTotal}
                proratedTotalPrice={proratedPrices?.total}
              />
              <div className="flex flex-col">
                <Text isItalic variant="text-sm">
                  <Trans
                    components={{
                      li: (
                        <Text
                          isItalic
                          link
                          color="primary"
                          fontWeight="medium"
                          variant="text-sm"
                          onClick={chatShow}
                        />
                      ),
                    }}
                    i18nKey="MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.warning"
                    values={{ countryName: countryName }}
                  />
                </Text>
              </div>
              <div className="flex w-full justify-center">
                <div className="w-full sm:max-w-[400px]">
                  <Button
                    fullWidth
                    id="action"
                    onClick={() => {
                      localStorage.removeItem(BILLING_FORM_DATA_KEY);
                      window.location.href = `/v2/${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}`;
                    }}
                  >
                    {success
                      ? t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.goToBilling')
                      : t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.retry')}
                  </Button>
                </div>
              </div>
            </div>
          </CardSection>
        </Card>
      </div>
    </div>
  );
};

export default PaymentStatus;
