import { CheckIcon } from '@heroicons/react/solid';

import { Heading } from '@/lib/components/Text/Heading';
import { SubHeading } from '@/lib/components/Text/SubHeading';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import './Stepper.tailwind.css';

export interface IStep {
  id: number;
  name: string;
  description: string;
  status: 'upcoming' | 'current' | 'done';
  taskTitle: string;
  taskDescription: string;
  onClick: () => void;
  clickCallToAction: string;
  onSkip: () => void;
  skipCallToAction: string;
  image: () => JSX.Element;
}

export const StepperUpcomingIcon = () => (
  <span className="stepper-item-icon relative z-10 mr-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
    <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
  </span>
);

export const StepperCurrentIcon = () => (
  <span className="stepper-item-icon relative z-10 mr-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-emblueGreen bg-white">
    <span className="h-2.5 w-2.5 rounded-full bg-emblueGreen" />
  </span>
);

export const StepperDoneIcon = () => (
  <span className="stepper-item-icon relative z-10 mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-emblueGreen group-hover:bg-emblueGreen">
    <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
  </span>
);

export const StepperStep = ({ step }: { step: IStep }) => (
  <div className={`stepper-item group hover:cursor-pointer ${step.status}`}>
    <Flex withGap alignment="center">
      <Flex column noGrow>
        <div className="pre-spacer"></div>
        <div className="spacer"></div>
        {step.status === 'upcoming' ? (
          <StepperUpcomingIcon />
        ) : step.status === 'current' ? (
          <StepperCurrentIcon />
        ) : (
          <StepperDoneIcon />
        )}
      </Flex>
      <Flex column itemAlignment="start" textAlignment="left">
        <Heading>{step.name}</Heading>
        <SubHeading>{step.description}</SubHeading>
      </Flex>
    </Flex>
  </div>
);

export const Stepper = ({ steps }: { steps: IStep[] }) => {
  return (
    <>
      <Flex column itemAlignment="start">
        {steps.map((step) => (
          <StepperStep key={step.name} step={step}></StepperStep>
        ))}
      </Flex>
    </>
  );
};
