import { useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useRecalculateTag = () => {
  const [tagIdList, setTagIdList] = useState<number[]>([]);
  const [recalculateTagResponse] = useEmblue(ServiceMethods.recalculateTag, {
    tagIdList: tagIdList,
  });

  const recalculateTag = (tagId: number) => {
    const tagIds: number[] = [tagId];
    setTagIdList(tagIds);
  };

  return [recalculateTag, recalculateTagResponse];
};
