import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type RangeConfig = {
  min: number;
  max: number;
  step: number;
};

type RangeSliderProps = {
  withMoreOptionRange?: boolean;
  config: RangeConfig[];
  withSuffix?: boolean;
  moreText?: string;
  value?: number | string;
  onChange: (value: number | string) => void;
  disabled?: boolean;
  id?: string;
};

const more = 'More';

const RangeSlider = ({
  config,
  withSuffix,
  onChange,
  withMoreOptionRange = false,
  moreText,
  value,
  disabled,
  id,
}: RangeSliderProps) => {
  const min = config[0].min;
  const moreValueText = moreText ?? more;
  const [valueRange, setValueRange] = useState<string | number>(value ?? min);
  const [isDragging, setIsDragging] = useState(false);
  const [isSliderReady, setIsSliderReady] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  const labels = useMemo(() => {
    const labelsRange = new Set<string | number>();
    config.forEach(({ min, max, step }) => {
      for (let i = min; i <= max; i += step) {
        labelsRange.add(i);
      }
    });
    if (withMoreOptionRange) {
      labelsRange.add(moreValueText);
    }
    return Array.from(labelsRange);
  }, [config, moreValueText, withMoreOptionRange]);

  const totalSpaces = labels.length - 1;

  const formatNumberWithSuffix = useCallback((number: number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
      const numberInThousands = number / 1000;
      const formattedNumber =
        numberInThousands % 1 === 0 ? numberInThousands.toFixed(0) : numberInThousands.toFixed(1);
      return formattedNumber + 'k';
    } else {
      return number.toString();
    }
  }, []);

  const updateValue = useCallback(
    (clientX: number) => {
      if (!isSliderReady || !sliderRef.current) return;

      const rect = sliderRef.current.getBoundingClientRect();
      const offset = clientX - rect.left;
      const sliderWidth = sliderRef.current.offsetWidth;

      const clickPositionPercent = (offset / sliderWidth) * 100;

      let closestLabel = labels[0];
      let smallestDistance = Infinity;

      labels.forEach((label, index) => {
        const labelPositionPercent = (index * 100) / totalSpaces;
        const distance = Math.abs(labelPositionPercent - clickPositionPercent);
        if (distance < smallestDistance) {
          smallestDistance = distance;
          closestLabel = label;
        }
      });

      const val = closestLabel;

      setValueRange(val);
      if (val !== valueRange) {
        !withMoreOptionRange
          ? onChange?.(Number(val))
          : (onChange as (value: number | string) => void)?.(val);
      }
    },
    [isSliderReady, labels, onChange, totalSpaces, valueRange, withMoreOptionRange]
  );

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    setIsDragging(true);
    updateValue(e.clientX);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (isDragging) {
      updateValue(e.clientX);
    }
  };

  const handleMouseUp = () => {
    if (disabled) return;
    setIsDragging(false);
  };

  const renderLabel = useCallback(
    (label: number | string) => {
      if (withMoreOptionRange && typeof label === 'string') {
        return moreText ?? more;
      }
      return withSuffix ? formatNumberWithSuffix(Number(label)) : label;
    },
    [formatNumberWithSuffix, moreText, withMoreOptionRange, withSuffix]
  );

  useEffect(() => {
    if (sliderRef.current) {
      setIsSliderReady(true);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setValueRange(value);
      onChange?.(value);
    } else {
      setValueRange(min);
      onChange(min);
    }
  }, [value, min]); //don't add onChange method

  const width = useMemo(() => {
    const currentSteps = labels.findIndex((label) => label === valueRange);

    return `${(currentSteps * 100) / totalSpaces}%`;
  }, [labels, totalSpaces, valueRange]);

  const classes = classNames('flex h-5 w-full items-center', {
    'cursor-pointer': !disabled,
    'cursor-not-allowed': disabled,
  });

  return (
    <div className="flex h-14 w-full flex-col items-center px-8">
      <div
        ref={sliderRef}
        className={classes}
        id={id}
        role="button"
        tabIndex={0}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div className="relative h-1 w-full rounded-full bg-[#D9D9D9]">
          <div className="absolute top-0 h-1 rounded-full bg-emblue-primary" style={{ width }} />
          <div
            className="absolute -top-2 size-5 rounded-full border-4 border-emblue-primary bg-emblue-white"
            style={{
              left: width,
              transform: 'translateX(-50%)',
            }}
          />
        </div>
      </div>
      <div className="relative mt-2 w-full justify-between">
        {labels.map((label, index) => {
          const positionPercent = (index * 100) / totalSpaces;
          return (
            <span
              key={label}
              className="absolute text-14"
              style={{
                left: `${positionPercent}%`,
                transform: 'translateX(-50%)',
              }}
            >
              {renderLabel(label)}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default memo(RangeSlider);
