import { Flex } from '@/lib/v2/components/Layout/Stack';

import { BigColumnSkeleton } from '..';

export const TableSkeleton = ({ columns = 5 }) => {
  return (
    <Flex>
      {new Array(columns).fill('').map((_, key) => (
        <BigColumnSkeleton key={key} />
      ))}
    </Flex>
  );
};
