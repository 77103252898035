export const SkypeIcon = () => {
  return (
    <svg
      data-name="Layer 2"
      height="63"
      id="Layer_2"
      viewBox="0 0 63 63"
      width="63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 1" id="Layer_1">
        <rect
          data-name="Rectangle 3830"
          fill="#00aff0"
          height="63"
          id="Rectangle_3830"
          rx="6.48"
          width="63"
        />
        <path
          d="M40.537,27.674a16.391,16.391,0,0,0,.394-3.528A16.61,16.61,0,0,0,24.146,7.667a17.092,17.092,0,0,0-2.849.241,9.729,9.729,0,0,0-5.15-1.468,9.62,9.62,0,0,0-9.707,9.554,9.444,9.444,0,0,0,1.227,4.821A16.237,16.237,0,0,0,24.037,40.34a17.093,17.093,0,0,0,3.068-.285,10.014,10.014,0,0,0,4.58,1.4A9.62,9.62,0,0,0,41.37,31.9,9.422,9.422,0,0,0,40.537,27.674Zm0,0"
          data-name="Path 3466"
          fill="#fff"
          id="Path_3466"
          transform="translate(7.671 7.672)"
        />
        <path
          d="M26.339,26.693a6.333,6.333,0,0,1-2.827,2.191,11.351,11.351,0,0,1-4.383.789A10.65,10.65,0,0,1,14.068,28.6a6.574,6.574,0,0,1-2.191-2.038,4.383,4.383,0,0,1-.877-2.5,1.6,1.6,0,0,1,.526-1.205,1.885,1.885,0,0,1,1.359-.5A1.665,1.665,0,0,1,14,22.748a3,3,0,0,1,.789,1.161,7.406,7.406,0,0,0,.855,1.512,3.4,3.4,0,0,0,1.293,1.008,5.171,5.171,0,0,0,2.191.394,5.259,5.259,0,0,0,2.827-.833A2.367,2.367,0,0,0,23.118,24a2.016,2.016,0,0,0-.635-1.534,4.12,4.12,0,0,0-1.622-.9q-.986-.307-2.673-.657a20.73,20.73,0,0,1-3.725-1.118,6,6,0,0,1-2.389-1.753,4.383,4.383,0,0,1-.877-2.761,4.383,4.383,0,0,1,.942-2.783,5.829,5.829,0,0,1,2.7-1.885,11.877,11.877,0,0,1,4.142-.657,11.2,11.2,0,0,1,3.309.438,7.165,7.165,0,0,1,2.3,1.161,4.909,4.909,0,0,1,1.337,1.512,3.265,3.265,0,0,1,.416,1.534,1.841,1.841,0,0,1-1.841,1.8,1.622,1.622,0,0,1-1.1-.329,4.382,4.382,0,0,1-.811-1.1,4.974,4.974,0,0,0-1.359-1.687,4.229,4.229,0,0,0-2.542-.614,4.667,4.667,0,0,0-2.63.657,1.885,1.885,0,0,0-.986,1.578,1.534,1.534,0,0,0,.329.986,2.739,2.739,0,0,0,.942.723,6.749,6.749,0,0,0,1.205.46l2.016.482a32.046,32.046,0,0,1,3.177.833,10.124,10.124,0,0,1,2.41,1.1,4.689,4.689,0,0,1,1.556,1.643,4.908,4.908,0,0,1,.57,2.432A5.281,5.281,0,0,1,26.339,26.693Zm0,0"
          data-name="Path 3467"
          fill="#00aff0"
          id="Path_3467"
          transform="translate(13.104 11.852)"
        />
      </g>
    </svg>
  );
};
