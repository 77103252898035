import { timeout } from '@/src/compat/util';

import { senderActivityMock, senderScoreMock } from './Dashboard.mocks';
import { IDashboardService } from './IDashboardService';

import { ISenderActivity, ISenderScore } from '@/modules/DashboardsModule/interfaces/Dashboard';

export class StubDashboardService implements IDashboardService {
  static create(): IDashboardService {
    return new StubDashboardService();
  }

  /**
   * Retrieves the data for the score overview.
   * @returns A promise that resolves to an array of ISenderScore objects or null if an error occurs.
   */
  async getDataScoreOverview(): Promise<ISenderScore[] | null> {
    try {
      await timeout(2000);
      return senderScoreMock;
    } catch (error) {
      return null;
    }
  }

  /**
   * Retrieves the sender activity.
   * @returns A Promise that resolves to an array of ISenderActivity objects or null if an error occurs.
   */
  async getSenderActivity(): Promise<ISenderActivity[] | null> {
    try {
      await timeout(2000);
      return senderActivityMock;
    } catch (error) {
      return null;
    }
  }
}
