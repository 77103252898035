import { IconProps } from './iconPropTypes';

export const ActivitySmsIcon = ({ color }: IconProps) => {
  return (
    <svg height="15.75" viewBox="0 0 18 15.75" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9,2.25c-4.971,0-9,3.273-9,7.312a6.49,6.49,0,0,0,2,4.595A8.944,8.944,0,0,1,.077,17.525a.279.279,0,0,0-.053.306A.275.275,0,0,0,.281,18a8.04,8.04,0,0,0,4.943-1.807A10.722,10.722,0,0,0,9,16.875c4.971,0,9-3.273,9-7.313S13.971,2.25,9,2.25ZM4.507,11.812H4.078a.282.282,0,0,1-.281-.281v-.562a.282.282,0,0,1,.281-.281h.432c.211,0,.366-.123.366-.232a.186.186,0,0,0-.074-.134l-.77-.661a1.3,1.3,0,0,1-.468-.988A1.429,1.429,0,0,1,5.055,7.316h.429a.282.282,0,0,1,.281.281V8.16a.282.282,0,0,1-.281.281H5.052c-.211,0-.366.123-.366.232a.186.186,0,0,0,.074.134l.77.661A1.3,1.3,0,0,1,6,10.455,1.427,1.427,0,0,1,4.507,11.812Zm6.743-.281a.282.282,0,0,1-.281.281h-.562a.282.282,0,0,1-.281-.281v-2.4L9.253,11.1a.281.281,0,0,1-.5,0L7.875,9.134v2.4a.282.282,0,0,1-.281.281H7.031a.282.282,0,0,1-.281-.281V7.875a.564.564,0,0,1,.563-.562h.562a.56.56,0,0,1,.5.309L9,8.866l.622-1.245a.564.564,0,0,1,.5-.309h.563a.564.564,0,0,1,.562.562Zm1.7.281h-.432a.282.282,0,0,1-.281-.281v-.562a.282.282,0,0,1,.281-.281h.432c.211,0,.366-.123.366-.232a.186.186,0,0,0-.074-.134l-.77-.661A1.3,1.3,0,0,1,12,8.673a1.429,1.429,0,0,1,1.491-1.357h.429A.282.282,0,0,1,14.2,7.6V8.16a.282.282,0,0,1-.281.281h-.432c-.211,0-.366.123-.366.232a.186.186,0,0,0,.074.134l.77.661a1.3,1.3,0,0,1,.468.988A1.424,1.424,0,0,1,12.948,11.812Z"
        data-name="Icon awesome-sms"
        fill={`${color}`}
        id="Icon_awesome-sms"
        transform="translate(0 -2.25)"
      />
    </svg>
  );
};
