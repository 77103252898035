/* cspell:disable */
import {
  NpsContacts,
  NpsRank,
  NpsTotalStats,
} from '@/src/modules/ContactsModule/screens/HappinessMain/types';

export const happinessStatsMock: NpsTotalStats[] = [
  {
    detractors: 100,
    passives: 600,
    promoters: 300,
    total: 1000,
  },
  {
    detractors: 0,
    passives: 0,
    promoters: 0,
    total: 0,
  },
];

export const processesMock: NpsRank[] = [
  {
    highestNps: {
      widgetId: 3200,
      widgetName: 'NPS DEMO',
      promoters: 6,
      detractors: 0,
      passives: 1,
      nps: 86,
    },
    lowestNps: {
      widgetId: 13963,
      widgetName: '2023 NPS Oficial (EN)',
      promoters: 8,
      detractors: 8,
      passives: 1,
      nps: -15,
    },
  },
  {
    highestNps: {
      widgetId: 3200,
      widgetName: 'NPS DEMO',
      promoters: 6,
      detractors: 0,
      passives: 1,
      nps: 86,
    },
    lowestNps: {
      widgetId: 3200,
      widgetName: 'NPS DEMO',
      promoters: 6,
      detractors: 0,
      passives: 1,
      nps: 86,
    },
  },
  {
    singleNps: {
      widgetId: 3200,
      widgetName: 'NPS DEMO',
      promoters: 6,
      detractors: 0,
      passives: 1,
      nps: 86,
    },
  },
  {},
];

export const npsContactListMockList: NpsContacts[] = [
  {
    list: [
      {
        contact: {
          emailId: 256144,
          email: 'roger+44058@sistemascloud.com',
          name: 'roger',
          lastName: 'roger',
          lastProcessDate: '2023-03-15T16:37:03.000Z',
          happiness: 9,
          processes: 1,
          average: 9,
        },
      },
      {
        contact: {
          emailId: 269930,
          email: 'webmaster+26123@grupopet.com',
          name: 'webmaster',
          lastName: 'webmaster',
          lastProcessDate: '2023-05-24T00:03:58.000Z',
          happiness: 0,
          processes: 1,
          average: 0,
        },
      },
      {
        contact: {
          emailId: 267007,
          email: 'gloria.cardoso+36884@nakednuts.com.br',
          name: 'gloria',
          lastName: 'cardoso',
          lastProcessDate: '2023-04-13T20:27:42.000Z',
          happiness: 10,
          processes: 1,
          average: 10,
        },
      },
      {
        contact: {
          emailId: 255511,
          email: 'exequiel.arguello+43585@gmail.com',
          name: 'exequiel',
          lastName: 'arguello',
          lastProcessDate: '2023-03-08T20:23:16.000Z',
          happiness: 9,
          processes: 1,
          average: 9,
        },
      },
      {
        contact: {
          emailId: 270476,
          email: 'carvajalalclaudia+45869@gmail.com',
          name: 'claudia',
          lastName: 'carvajalal',
          lastProcessDate: '2023-06-07T00:02:27.000Z',
          happiness: 8,
          processes: 1,
          average: 8,
        },
      },
      {
        contact: {
          emailId: 268277,
          email: 'ecajaleon+44917@expressaduanas.com',
          name: 'ecajaleon',
          lastName: 'ecajaleon',
          lastProcessDate: '2023-05-03T23:39:27.000Z',
          happiness: 0,
          processes: 1,
          average: 0,
        },
      },
      {
        contact: {
          emailId: 267609,
          email: 'marianab+40649@digital-express.com.ar',
          name: 'mariana',
          lastName: 'b',
          lastProcessDate: '2023-04-27T21:35:40.000Z',
          happiness: 10,
          processes: 1,
          average: 10,
        },
      },
      {
        contact: {
          emailId: 270299,
          email: 'mmassouh+45724@soldati.com',
          name: 'mmassouh',
          lastName: 'mmassouh',
          lastProcessDate: '2023-05-31T17:32:35.000Z',
          happiness: 10,
          processes: 1,
          average: 10,
        },
      },
      {
        contact: {
          emailId: 267357,
          email: 'alberto.d+5971@embluemail.com',
          name: 'alberto',
          lastName: 'duque',
          lastProcessDate: '2023-04-26T01:30:51.000Z',
          happiness: 10,
          processes: 1,
          average: 10,
        },
      },
    ],
    count: 9,
  },
  {
    list: [],
    count: 0,
  },
];
