import { useTranslation } from 'react-i18next';

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: { date: string };
    value: number;
  }>;
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className="rounded-md bg-black p-1 text-[12px] text-white">
        <p>{`${t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.date')}: ${
          payload[0].payload.date
        }`}</p>
        <p>{`${t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.score')}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
