export const TagsIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7406" id="Group_7406" transform="translate(0.166 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(-0.166 -0.163)"
          width="20"
        />
        <path
          d="M0,8.077V1.538A1.538,1.538,0,0,1,1.538,0H8.077A1.538,1.538,0,0,1,9.164.45l6.788,6.788a1.538,1.538,0,0,1,0,2.175L9.413,15.952a1.538,1.538,0,0,1-2.175,0L.45,9.164A1.538,1.538,0,0,1,0,8.077ZM3.588,2.05A1.538,1.538,0,1,0,5.126,3.588,1.538,1.538,0,0,0,3.588,2.05Z"
          data-name="Icon awesome-tag"
          fill="#004dbc"
          id="Icon_awesome-tag"
          transform="translate(1.717 2.112)"
        />
      </g>
    </svg>
  );
};
