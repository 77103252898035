import { Flex } from '@/lib/v2/components/Layout/Stack';

type MenuBarProps = {
  tabs: JSX.Element[];
};

export const MenuBar = ({ tabs }: MenuBarProps) => {
  return (
    <Flex noGrow withGap alignment="start" className="pl-4" gapSize="medium" itemAlignment="center">
      <nav className="flex flex-wrap items-center">{tabs.map((tab) => tab)}</nav>
    </Flex>
  );
};
