export const InfoIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 15 16" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.86256 8C1.86256 4.41 4.57261 1.5 7.91594 1.5C11.2593 1.5 13.9693 4.41 13.9693 8C13.9693 11.59 11.2593 14.5 7.91594 14.5C4.57261 14.5 1.86256 11.59 1.86256 8ZM7.26776 7.03867C7.97927 6.65667 8.7808 7.34733 8.58771 8.176L8.14752 10.0667L8.1736 10.0533C8.283 10.0017 8.40671 9.99719 8.51912 10.0408C8.63153 10.0843 8.72403 10.1727 8.77748 10.2874C8.83092 10.4022 8.84122 10.5347 8.80625 10.6574C8.77127 10.7801 8.69369 10.8837 8.58957 10.9467L8.56474 10.9613C7.85261 11.3433 7.05108 10.6527 7.24417 9.824L7.68498 7.93333L7.6589 7.94667C7.60398 7.97944 7.54345 7.99994 7.48095 8.00693C7.41846 8.01393 7.35529 8.00726 7.29525 7.98734C7.23522 7.96743 7.17956 7.93467 7.13163 7.89105C7.08369 7.84742 7.04448 7.79383 7.01634 7.7335C6.98821 7.67317 6.97174 7.60735 6.96792 7.54C6.9641 7.47266 6.97302 7.40518 6.99413 7.34163C7.01524 7.27808 7.04811 7.21978 7.09077 7.17023C7.13342 7.12069 7.18497 7.08092 7.24231 7.05333L7.26776 7.03867ZM7.91594 6C8.03944 6 8.15787 5.94732 8.2452 5.85355C8.33252 5.75979 8.38158 5.63261 8.38158 5.5C8.38158 5.36739 8.33252 5.24021 8.2452 5.14645C8.15787 5.05268 8.03944 5 7.91594 5C7.79244 5 7.67401 5.05268 7.58668 5.14645C7.49935 5.24021 7.4503 5.36739 7.4503 5.5C7.4503 5.63261 7.49935 5.75979 7.58668 5.85355C7.67401 5.94732 7.79244 6 7.91594 6Z"
        fill="#0056B8"
        fillRule="evenodd"
      />
    </svg>
  );
};
