export type ActionBarProps = {
  children?: React.ReactNode;
  id?: string;
  bg?: string;
  className?: string;
};

export const ActionBar = ({
  children,
  id = '',
  bg = 'bg-[#004DBC]',
  className = '',
}: ActionBarProps) => {
  return (
    <div
      className={`h-20 w-full ${bg} ${className}`}
      data-testid={`actionBar-component--${id}`}
      id={id}
      style={{ display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'space-between' }}
    >
      {children}
    </div>
  );
};
