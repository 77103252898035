import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import GroupContactsDropdown from '@/src/modules/RulesModule/components/FormTrigger/components/GroupContactsDropdown';

import { ITriggerForm } from '@/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IGroupContactsProps {
  errors: FieldErrors<ITriggerForm>;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
}

const GroupContacts = ({ form, errors, disabled = false }: IGroupContactsProps) => {
  const { t } = useTranslation('rules');

  return (
    <div className="mb-5 mr-8 w-full">
      <Text className="mr-2 whitespace-nowrap" fontWeight="medium" variant="text">
        {t(`${TRANSLATE_RULES_LABELS}.groupContacts`)}
      </Text>
      <GroupContactsDropdown disabled={disabled} errors={errors} form={form} />
      {errors.groupContacts?.message && typeof errors.groupContacts.message === 'string' && (
        <Text color="alert" variant="text-sm">
          {errors.groupContacts.message}
        </Text>
      )}
    </div>
  );
};

export default GroupContacts;
