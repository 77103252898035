import { Card, CardSection, Flex } from '@/lib/v2/components';

import { InboxMainFooter } from '@/src/modules/InboxModule/components/InboxMainFooter';
import { InboxMainHeader } from '@/src/modules/InboxModule/components/InboxMainHeader';

const InboxMain = () => {
  return (
    <Card>
      <CardSection noPadding>
        <Flex column className="p-8 lg:p-12 xl:p-16 2xl:px-36">
          <InboxMainHeader />
          <InboxMainFooter />
        </Flex>
      </CardSection>
    </Card>
  );
};

export default InboxMain;
