import { HTMLProps, memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/lib/v2/components/Layout/Stack';
import Text from '@/lib/v2/components/Text/Text';
import { CloudComputingIcon, CloudComputingUploadIcon } from '@/lib/v2/icons/outline';

type UploadZoneProps = {
  rootProps: HTMLProps<HTMLDivElement>;
  inputProps: () => HTMLProps<HTMLInputElement>;
  isDraged: boolean;
  fileName: string;
  onClickHandler: MouseEventHandler;
};

export const UploadZone = memo(
  ({ rootProps, inputProps, isDraged, fileName, onClickHandler }: UploadZoneProps) => {
    const { t } = useTranslation();

    const onHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
      onClickHandler(event);
      event.stopPropagation();
    };

    return (
      <div
        {...rootProps}
        className="border-lightblue-500 relative flex h-3/4 flex-col items-center justify-center rounded-3xl border-4 border-dashed bg-emblue-secondary-hover p-10 text-center"
      >
        {!fileName ? <CloudComputingIcon /> : <CloudComputingUploadIcon />}
        <input {...inputProps()} />
        <Text className="text-xs m-4 text-gray-500">{fileName}</Text>
        <button onClick={(e) => onHandler(e)}>
          <Flex column>
            {!fileName ? (
              <>
                {isDraged ? (
                  <Text>{t('UPLOAD_ZONE.drop')}</Text>
                ) : (
                  <Text>
                    {t('UPLOAD_ZONE.push')}
                    <span className="text-sky-600" id="import-pick-upload">
                      {t('UPLOAD_ZONE.chooseFile')}
                    </span>
                    {t('UPLOAD_ZONE.add')}
                  </Text>
                )}
                <Text>{t('UPLOAD_ZONE.size')}</Text>
              </>
            ) : (
              <>
                <Text>{t('IMPORT_MAIN.readyToUpload')}</Text>
                <span className="text-sky-600" id="import-change-file">
                  {t('IMPORT_MAIN.changeFile')}
                </span>
              </>
            )}
          </Flex>
        </button>
      </div>
    );
  }
);
