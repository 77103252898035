import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/lib/components/Card/Card';
import { StepperCard } from '@/lib/components/Stepper/StepperCard';
import { SupportCard } from '@/lib/components/SupportCard';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useStep } from '@/src/application/hooks/useStep';
import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import { DemoTrialCounter } from '@/src/ContactsModule/components/DemoTrialCounter';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  AutomationCard,
  ContactListCard,
  ConversationCard,
  CreateCampaignCard,
  CreateContactCard,
  CreateSmsCard,
  CreateWidgetCard,
  ECommerceCard,
  FacebookCard,
  GetNewClientsCard,
  OmnichannelCard,
  UserActionInfoCard,
} from '@/src/presentation/components/Main/Cards';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';
import { DashboardIcon } from '@/src/presentation/util/icon';

import { atomSignalSendersOnboarding } from '@/modules/OnboardingModule/atoms/OnboardingModule.atoms';
import { INIT_ONBOARDING_OLD_SS } from '@/modules/OnboardingModule/constants';

interface DashboardComponentProps extends Omit<DashboardContainerProps, 'counter'> {
  freeTrialBlock?: IFreeTrialDemo;
}

export const DashboardComponent = ({ userData, freeTrialBlock }: DashboardComponentProps) => {
  const { t } = useTranslation();
  const service = useService();

  const [signalSenders] = useAtom(atomSignalSendersOnboarding);

  const { step, currentStep, steps, nextStep, loading } = useStep({ userData });
  const [showCampaignsV1] = useAtom(atomShowCampaignsV1);

  const handleSenderFinished = useCallback(
    (complete: boolean) => {
      if (complete && step === StepEnum.sender) {
        service?.setNextStep(userData.companyId, StepEnum.sender);
        nextStep(steps, StepEnum.sender);
      }
    },
    [nextStep, service, step, steps, userData.companyId]
  );

  useEffect(() => {
    if (!signalSenders.complete || !step || step !== StepEnum.sender) return;
    handleSenderFinished(true);
  }, [handleSenderFinished, signalSenders, step]);

  const haveSenders = useCallback(async () => {
    const { pager } = await service.getSenders();

    if (pager.countTotal > 0) handleSenderFinished(true);
  }, [handleSenderFinished, service]);

  useEffect(() => {
    if (!signalSenders.complete && step === StepEnum.sender && !loading) void haveSenders();
  }, [haveSenders, loading, signalSenders.complete, step]);

  return (
    <>
      <div className="bg-emblueLightGray">
        <SupportCard />
        <div className="mx-8 min-h-screen pb-8">
          <section className="flex flex-col py-6">
            <div className="mb-4 flex flex-row justify-between">
              <Flex noGrow withGap itemAlignment="center">
                <div className="h-[37px] w-9 self-center rounded-[5px] bg-emblue-blue p-[7px]">
                  <DashboardIcon />
                </div>
                <p className="title font-medium">{t('home')}</p>
              </Flex>
              {/*freeTrialBlock?.showCounter && !freeTrialBlock?.isBlocked && (
                <DemoTrialCounter freeTrialBlock={freeTrialBlock} />
              )*/}
            </div>
            <Flex column itemAlignment="start">
              <p className="text-lg font-medium text-[#44588D]">{t('DASHBOARD.quickGuide')}</p>
              <p className="text-2xl font-medium text-[#364365]">
                {t('DASHBOARD.startGrowingFastWith')}{' '}
                <strong className="text-[#004DBC]">emBlue</strong>
              </p>
            </Flex>
          </section>
          {step === StepEnum.finished || !currentStep ? (
            <div></div>
          ) : (
            <div className={'mb-6'}>
              <StepperCard currentStep={currentStep} steps={steps} />
            </div>
          )}

          <div
            className={`mb-8 grid grid-cols-4 justify-items-center gap-6 ${
              !showCampaignsV1 ? 'hidden' : ''
            }`}
          >
            <CreateContactCard userData={userData} />
            <CreateCampaignCard userData={userData} />
            <CreateSmsCard userData={userData} />
            <CreateWidgetCard userData={userData} />
          </div>
          <div className={`grid grid-cols-4 justify-items-center gap-6`}>
            <div className="col-span-4 gap-6">
              <Card>
                <div className="px-4 pb-6 pt-4">
                  <Flex column withGap itemAlignment="start" textAlignment="left">
                    <p className="text-[16px] font-semibold text-[#004dbc]">
                      {t('DASHBOARD.becomeOmnichannel')}
                    </p>
                    <p className="text-[36px] font-semibold text-black">
                      {t('DASHBOARD.allChannelsInOnePlace')}
                    </p>
                    <p className="text-[20px] text-[#6b7280]">
                      {t('DASHBOARD.findOutAllYouCanDoWithEmblue')}
                    </p>
                  </Flex>
                </div>
                <div className="grid grid-cols-4 gap-6">
                  <ContactListCard />
                  <GetNewClientsCard />
                  <UserActionInfoCard />
                  <ECommerceCard />
                  <FacebookCard />
                  <ConversationCard />
                  <AutomationCard />
                  <OmnichannelCard />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface DashboardContainerProps {
  userData: UserData;
  counter?: IFreeTrialDemo;
}
export const DashboardContainer = ({ userData, counter }: DashboardContainerProps) => {
  useEffect(() => {
    const onboardingDDSent = localStorage.getItem(INIT_ONBOARDING_OLD_SS);
    if (!onboardingDDSent) {
      datadogRum.addAction(INIT_ONBOARDING_OLD_SS, {
        email: userData.email,
      });
      localStorage.setItem(INIT_ONBOARDING_OLD_SS, 'true');
    }
  }, [userData.email]);

  return <DashboardComponent freeTrialBlock={counter} userData={userData} />;
};
