import mixpanel from 'mixpanel-browser';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import configData from '@/src/config.json';

/**
 * Tracks all events with pre-set properties, sending the user data and an event name.
 */
export function TrackEvent(
  userData: UserData,
  eventName: string,
  optionalParameters: { [x: string]: string } = {}
) {
  if (userData.email !== '' && userData.email !== undefined) {
    const propertiesDict: { [x: string]: string } = {
      contractId: userData.contractId,
      companyId: userData.companyId,
      agencyName: userData.agencyName,
      plan: userData?.plan ?? '',
      environment: userData.environment,
    };

    Object.entries(optionalParameters).forEach(([key, value]) => (propertiesDict[key] = value));

    mixpanel.track(eventName, propertiesDict);
  }
}

/**
 * Inits Mixpanel
 */
export function IdentifyUser(userData: UserData) {
  mixpanel.init(configData.HOME.MIXPANEL_PROJECT_TOKEN, { debug: false });
  if (userData.email !== '' && userData.email !== undefined) {
    mixpanel.identify(userData.email);
    mixpanel.people.set({ $name: userData.name, $email: userData.email });
  }
}
