import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Option, Select } from '@/lib/v2/components';

export type FormInput = {
  names: string;
  fieldQuantity: Option;
};

interface FormDistributeProps {
  onClose: (isOpen: boolean) => void;
  onSubmit: (data: FormInput) => void;
  isLoading: boolean;
  quantityOptions: Option[];
}

const FormDistribute = ({ onClose, onSubmit, isLoading, quantityOptions }: FormDistributeProps) => {
  const { t } = useTranslation();

  const RULES = useMemo(
    () => ({
      names: {
        required: t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.emptyField'),
      },
      fieldQuantity: {
        required: t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.emptyField'),
      },
    }),
    [t]
  );

  const { control, handleSubmit } = useForm<FormInput>();

  return (
    <form
      className="flex w-full flex-col items-center justify-center divide-y"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full py-4 sm:w-3/4">
        <section className="mb-3 flex flex-col gap-3">
          <Input
            control={control}
            id="name-input"
            label={t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.fieldName')}
            name="names"
            rules={RULES.names}
          />
          <Select
            control={control}
            id="quantity-select"
            label={t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.fieldQuantity')}
            name="fieldQuantity"
            options={quantityOptions}
            rules={RULES.fieldQuantity}
          />
        </section>
      </div>
      <div className="flex w-full justify-end gap-2 pt-2">
        <Button outline disabled={isLoading} id="close-button" onClick={() => onClose(false)}>
          {t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.close')}
        </Button>
        <Button id="confirm-button" isLoading={isLoading} type="submit">
          {t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.confirm')}
        </Button>
      </div>
    </form>
  );
};

export default FormDistribute;
