import { useTranslation } from 'react-i18next';

import { Button } from '@/lib/v2/components';

import { PlanCustomization } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/PlanCustomization';
import { PlanPriceInformation } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/PlanPriceInformation';

import { useSelectedPlanData } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';

export interface Frequency {
  value: string;
  label: string;
  priceSuffix: string;
}

const StepTwoManagePlan = () => {
  const { t } = useTranslation();
  const { isMoreOption, setCurrentStep } = useSelectedPlanData();

  return (
    <div className="relative size-full px-6">
      <div className="flex flex-col gap-8 md:flex-row">
        <div className="relative basis-2/3">
          <PlanCustomization />
        </div>
        <div className="relative basis-1/3">
          <PlanPriceInformation
            checkoutButton={
              <Button
                fullWidth
                disabled={isMoreOption}
                id="continue"
                onClick={() => setCurrentStep(3)}
              >
                {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.continue')}
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StepTwoManagePlan;
