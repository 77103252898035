/* cSpell:disable */

type ActivityIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const ActivityIcon = ({ tableSize, stroked }: ActivityIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          data-name="Grupo 7432"
          height={tableSize ? 19 : 32}
          id="Grupo_7432"
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            data-name="Rectángulo 3179"
            fill="none"
            height={tableSize ? 19 : 32}
            id="Rectángulo_3179"
            width={tableSize ? 19 : 32}
          />
          <g data-name="Grupo 7433" id="Grupo_7433" transform="translate(-0.659 -0.178)">
            <ellipse
              cx="1.742"
              cy="1.742"
              data-name="Elipse 1574"
              fill="#004dbc"
              id="Elipse_1574"
              rx="1.742"
              ry="1.742"
              transform="translate(15.519 1.178)"
            />
            <path
              d="M17.416,6.535v7.071c0,2.729-1.692,4.653-4.421,4.653H5.2c-2.762,0-4.421-1.924-4.421-4.653V6.282C.778,3.553,2.47,1.62,5.2,1.62h7.5C12.694,5.186,14.827,6.535,17.416,6.535Z"
              data-name="Trazado 3514"
              fill="#004dbc"
              fillRule="evenodd"
              id="Trazado_3514"
              transform="translate(0.881 0.321)"
            />
            <path
              d="M5.245,12.813,7.957,9.288l3.094,2.43L13.7,8.293"
              data-name="Trazado 3516"
              fill="none"
              fillRule="evenodd"
              id="Trazado_3516"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(0.461 -0.305)"
            />
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          data-name="Grupo 6143"
          height={tableSize ? 19 : 32}
          id="Grupo_6143"
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            data-name="Rectángulo 3179"
            fill="none"
            height={tableSize ? 19 : 32}
            id="Rectángulo_3179"
            width={tableSize ? 19 : 32}
          />
          <g id="activity" transform="translate(2.424 1.952)">
            <path
              d="M5.245,12.461l2.5-3.251L10.6,11.452l2.448-3.159"
              data-name="Trazado 3361"
              fill="none"
              fillRule="evenodd"
              id="Trazado_3361"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-1.512 -2.013)"
            />
            <circle
              cx="1.606"
              cy="1.606"
              data-name="Elipse 1572"
              fill="none"
              id="Elipse_1572"
              r="1.606"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(12.781)"
            />
            <path
              d="M10.928,1.62H4.855a3.955,3.955,0,0,0-4.077,4.3v6.754c0,2.516,1.53,4.291,4.077,4.291h7.19a3.949,3.949,0,0,0,4.077-4.291V6.791"
              data-name="Trazado 3362"
              fill="none"
              fillRule="evenodd"
              id="Trazado_3362"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-0.778 -0.916)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
