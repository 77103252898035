import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { IconSvg, IconSvgProps, Text } from '@/lib/v2/components';

interface TitleHeadCellProps {
  text: string;
  icon?: ReactElement<IconSvgProps>;
}
const TitleHeadCell = ({ text, icon }: TitleHeadCellProps) => {
  //TODO - Migrate to new version designKit
  const location = useLocation();
  const isCampaignsPage =
    location.pathname.includes('campaigns') || location.pathname.includes('api-manager');
  return (
    <>
      {icon && <IconSvg height="16px" svgComponent={icon} width="16px" />}
      <Text
        className="whitespace-nowrap"
        color={isCampaignsPage ? 'black' : 'primary'}
        fontWeight="medium"
        variant="text"
      >
        {text}
      </Text>
    </>
  );
};

export default TitleHeadCell;
