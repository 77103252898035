export const BrowserIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7940" id="Group_7940" transform="translate(-157)">
        <rect
          data-name="Rectangle 4347"
          fill="none"
          height="16"
          id="Rectangle_4347"
          transform="translate(157)"
          width="16"
        />
        <g id="browser-svgrepo-com" transform="translate(158 1.777)">
          <path
            d="M3,8.667V14.89a1.556,1.556,0,0,0,1.556,1.556h10.89A1.556,1.556,0,0,0,17,14.89V8.667m-14,0V5.556A1.556,1.556,0,0,1,4.556,4h10.89A1.556,1.556,0,0,1,17,5.556V8.667m-14,0H17"
            data-name="Path 4695"
            fill="none"
            id="Path_4695"
            stroke="#364365"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-3 -4)"
          />
          <circle
            cx="0.683"
            cy="0.683"
            data-name="Ellipse 1908"
            fill="#364365"
            id="Ellipse_1908"
            r="0.683"
            transform="translate(1.367 1.51)"
          />
          <circle
            cx="0.683"
            cy="0.683"
            data-name="Ellipse 1909"
            fill="#364365"
            id="Ellipse_1909"
            r="0.683"
            transform="translate(4.101 1.51)"
          />
        </g>
      </g>
    </svg>
  );
};
