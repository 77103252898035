import { useEffect } from 'react';

import { useIframeEmbeddedEnd, useIframeEmbeddedStart } from './hooks/IframeTransport.hooks';
import Loading from './Loading';
interface IProps {
  loading: boolean;
}

const IframeTransport = ({ loading }: IProps) => {
  const { startIframe } = useIframeEmbeddedStart();
  const { recoveryIframe } = useIframeEmbeddedEnd();

  useEffect(() => {
    !loading && startIframe();

    return () => recoveryIframe();
  }, [loading]);

  return (
    <div className="relative h-full w-full overflow-hidden" id="iframe-section">
      {loading ? <Loading /> : null}
      <div className="container-iframe h-full min-h-[500px] w-full"></div>
    </div>
  );
};

export default IframeTransport;
