export const DeleteIconNew = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_4314_6950)">
        <path
          d="M6.5 17C6.0875 17 5.73438 16.8531 5.44062 16.5594C5.14687 16.2656 5 15.9125 5 15.5V5.5H4.75C4.5375 5.5 4.35938 5.42854 4.21563 5.28563C4.07188 5.14271 4 4.96563 4 4.75438C4 4.54313 4.07188 4.36458 4.21563 4.21875C4.35938 4.07292 4.5375 4 4.75 4H8V3.75C8 3.5375 8.07188 3.35937 8.21563 3.21562C8.35938 3.07187 8.5375 3 8.75 3H11.25C11.4625 3 11.6406 3.07187 11.7844 3.21562C11.9281 3.35937 12 3.5375 12 3.75V4H15.25C15.4625 4 15.6406 4.07146 15.7844 4.21437C15.9281 4.35729 16 4.53437 16 4.74562C16 4.95687 15.9281 5.13542 15.7844 5.28125C15.6406 5.42708 15.4625 5.5 15.25 5.5H15V15.491C15 15.9137 14.8531 16.2708 14.5594 16.5625C14.2656 16.8542 13.9125 17 13.5 17H6.5ZM13.5 5.5H6.5V15.5H13.5V5.5ZM8.74563 14C8.95688 14 9.13542 13.9281 9.28125 13.7844C9.42708 13.6406 9.5 13.4625 9.5 13.25V7.75C9.5 7.5375 9.42854 7.35938 9.28562 7.21562C9.14271 7.07187 8.96562 7 8.75437 7C8.54313 7 8.36458 7.07187 8.21875 7.21562C8.07292 7.35938 8 7.5375 8 7.75V13.25C8 13.4625 8.07146 13.6406 8.21438 13.7844C8.35729 13.9281 8.53438 14 8.74563 14ZM11.2456 14C11.4569 14 11.6354 13.9281 11.7812 13.7844C11.9271 13.6406 12 13.4625 12 13.25V7.75C12 7.5375 11.9285 7.35938 11.7856 7.21562C11.6427 7.07187 11.4656 7 11.2544 7C11.0431 7 10.8646 7.07187 10.7188 7.21562C10.5729 7.35938 10.5 7.5375 10.5 7.75V13.25C10.5 13.4625 10.5715 13.6406 10.7144 13.7844C10.8573 13.9281 11.0344 14 11.2456 14Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
