import { datadogRum } from '@datadog/browser-rum';
import { Component, ErrorInfo, ReactNode } from 'react';

import { Card, Text } from '@/lib/v2/components';

import { AUTH_PATHS } from '@/modules/AuthModule/constants';

interface Props {
  children?: ReactNode;
}

interface State {
  error: string;
  errorInfo: string;
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.stack };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    const pathname = window.location.pathname;
    if (pathname === AUTH_PATHS.LOGIN_PATH) {
      datadogRum.addAction('loginV2.ErrorBoundary', {
        error: error.message,
        errorInfo: errorInfo.componentStack?.toString(),
      });
    } else {
      // TODO: resolve the pathname and track errors by screen. eg: contacts, tags, segments, etc
      datadogRum.addAction('ErrorBoundary.', {
        error: error.message,
        errorInfo: errorInfo.componentStack?.toString(),
        pathname,
      });
    }
    console.error('ErrorBoundary error ->', error.message);
    console.error('ErrorBoundary errorInfo ->', errorInfo);
    this.setState({ errorInfo: errorInfo.componentStack?.toString() ?? 'ErrorBoundary' });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;

    if (hasError) {
      return (
        <div className="p-4">
          <Card>
            <div className="bg-gray-100 p-3">
              <Text variant="headline">
                There was an error in loading this page.{' '}
                <Text
                  link
                  as="span"
                  color="primary"
                  variant="headline"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload this page
                </Text>{' '}
              </Text>
            </div>
            <div className="p-3">
              <details className="whitespace-pre">
                <summary>Click for error details</summary>
                <Text>{error && error}</Text>
                <br />
                <Text>{errorInfo && errorInfo}</Text>
              </details>
            </div>
          </Card>
        </div>
      );
    }
    // next code block goes here
    return this.props.children;
  }
}
