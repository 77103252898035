import { MAX_LENGTH_SMS } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/constants';

/**
 * Calculates the length and SMS count metrics for a given message.
 * @param {string} message The message for which to calculate metrics.
 * @returns {{ length: number, count: number }} Object containing length and SMS count metrics.
 */
export const calculateSMSMetrics = (message: string): { length: number; count: number } => {
  const lengthSMS = [...message].length;
  const countSMS = Math.floor(lengthSMS / (MAX_LENGTH_SMS + 1)) + 1;

  return { length: lengthSMS, count: countSMS };
};
