import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DomainSkeleton } from '@/lib/components/Skeletons';
import { InputSearch, Text } from '@/lib/v2/components';
import { Alert } from '@/lib/v2/components/Alert';

import { IDomainDetail } from '@/modules/SendersModule/Screens/DomainValidator/DomainValidator.hooks';

interface IStatusDictionary {
  success: {
    title: string;
  };
  warning: {
    title: string;
  };
  error: {
    title: string;
  };
}
export interface Details {
  title: string;
  description: string;
  status: 'warning' | 'error' | 'success' | 'info' | 'initial';
}

interface IStatusDetail {
  status: Details['status'];
  description: string;
}

const StatusDetail = ({ status, description }: IStatusDetail) => {
  const { t } = useTranslation();
  const statusDictionary: IStatusDictionary = {
    success: {
      title: t('SENDERS_MAIN.registerIsValid'),
    },
    warning: {
      title: t('SENDERS_MAIN.registerWithWarning'),
    },
    error: {
      title: t('SENDERS_MAIN.registerWithError'),
    },
  };

  const details: Details = {
    ...statusDictionary[status as keyof IStatusDictionary],
    description,
    status,
  };

  return (
    <div className="mb-9 flex w-full flex-row items-start">
      <Alert
        className="w-full"
        title={details.title}
        type={details.status === 'initial' ? 'info' : details.status}
      >
        <div
          dangerouslySetInnerHTML={{ __html: details.description }}
          className="w-full break-all"
        />
      </Alert>
    </div>
  );
};

export interface IDomainValidator {
  data?: IDomainDetail[];
  onSearch?: (domain: string) => void;
  loading?: boolean;
}

export const DomainValidator = ({ data, onSearch, loading }: IDomainValidator) => {
  const { t } = useTranslation();
  const [domain, setDomain] = useState('');
  const [error, setError] = useState(false);

  const handleSearch = () => {
    const domainRegex = /^[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    if (domainRegex.test(domain)) {
      setError(false);
      onSearch && onSearch(domain);
    } else {
      setError(true);
    }
  };

  return (
    <div className="flex flex-col">
      {onSearch && (
        <div className="mb-8 flex">
          <InputSearch
            isRequired
            actionText={t('SENDERS_MAIN.validate')}
            error={error}
            message={t('SENDERS_MAIN.requireDomainValid')}
            pattern={''}
            placeHolder={t('SENDERS_MAIN.DOMAIN_VALIDATOR.placeholderEmail')}
            showMessage={error}
            value={domain}
            onAction={handleSearch}
            onChange={(e) => setDomain(e.target.value)}
          />
        </div>
      )}

      <div className="flex flex-col">
        {data &&
          Object.values(data).map(
            ({ title, description, status = 'success' }: IDomainDetail, key) => (
              <div key={key} className="flex w-full flex-col">
                <Text className="mb-4" renderHtml={title} variant="sub-headline" />
                <StatusDetail description={description} status={status} />
              </div>
            )
          )}
      </div>

      {loading && <DomainSkeleton />}
    </div>
  );
};
