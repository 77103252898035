export const MergeTagIcon = () => {
  return (
    <svg
      data-name="tag unity"
      height="27.173"
      id="tag_unity"
      viewBox="0 0 26.33 27.173"
      width="26.33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.439,10.644l5.033-4.981a1.252,1.252,0,0,1,.882-.363h4.773a1.263,1.263,0,0,1,1.245,1.245v4.773a1.472,1.472,0,0,1-.363.882l-4.981,4.981a1.285,1.285,0,0,1-1.764,0L5.439,12.408A1.194,1.194,0,0,1,5.439,10.644Zm9.183-1.556a1.141,1.141,0,1,0-1.141-1.141A1.152,1.152,0,0,0,14.622,9.087Z"
        data-name="Trazado 3152"
        fill="#004dbc"
        id="Trazado_3152"
        transform="translate(-5.05 -5.3)"
      />
      <path
        d="M32.439,39.444l5.033-4.981a1.252,1.252,0,0,1,.882-.363h4.773a1.262,1.262,0,0,1,1.245,1.245v4.773a1.472,1.472,0,0,1-.363.882l-4.981,4.981a1.285,1.285,0,0,1-1.764,0L32.439,41.26A1.254,1.254,0,0,1,32.439,39.444Zm9.183-1.556a1.141,1.141,0,1,0-1.141-1.141A1.152,1.152,0,0,0,41.622,37.887Z"
        data-name="Trazado 3153"
        fill="#004dbc"
        id="Trazado_3153"
        transform="translate(-18.042 -19.158)"
      />
      <g data-name="Grupo 3389" id="Grupo_3389" transform="translate(10.247 10.117)">
        <rect
          data-name="Rectángulo 3151"
          fill="#004dbc"
          height="5.344"
          id="Rectángulo_3151"
          transform="translate(2.075)"
          width="1.245"
        />
        <rect
          data-name="Rectángulo 3152"
          fill="#004dbc"
          height="5.344"
          id="Rectángulo_3152"
          transform="translate(0 3.32) rotate(-90)"
          width="1.245"
        />
      </g>
    </svg>
  );
};
