import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';
import { WHATSAPP_PREFIX } from '@/src/modules/CampaignsModule/constants';

import { atomSelectedRowActionName } from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomInfoCampaignV1,
  atomRefetchAllData,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface useEditActionProps {
  campaignId?: string | number;
  actionId?: string | number;
}

export const useEditActions = ({ campaignId, actionId }: useEditActionProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { refetch } = useActionData(Number(actionId));
  const { editAction, loading } = useCampaignActions();
  const { fetchServiceValidName } = useValidNameResource();

  const { inputLengths, formRegExp } = formValidations;

  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const actionName = useAtomValue(atomSelectedRowActionName);
  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp, inputLengths, t]
  );

  const handleCloseModal = useCallback(() => {
    setToggleEditActionsModal(false);
  }, [setToggleEditActionsModal]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: campaignId ? Number(campaignId) : Number(id),
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGN_ACTIONS_MAIN.existAction'),
      };
    },
    [campaignId, fetchServiceValidName, id, t]
  );

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      if (actionName === nameValue) {
        handleCloseModal();
        return;
      }

      const newName = actionName?.startsWith(WHATSAPP_PREFIX) ? `whatsapp-${nameValue}` : nameValue;

      await editAction({ name: newName, campaignElementId: Number(actionId) });
      setRefetchAllData(true);
      handleCloseModal();
    },
    [actionId, actionName, editAction, handleCloseModal, setRefetchAllData]
  );

  const handleApplyRename = useCallback(
    async (value: string) => {
      const newName = actionName?.startsWith(WHATSAPP_PREFIX) ? `whatsapp-${value}` : value;

      const statusOK = await editAction({ name: newName, campaignElementId: Number(actionId) });
      if (statusOK) {
        refetch();

        setInfoCampaignV1({
          ...infoCampaignV1,
          action: { id: Number(actionId), name: newName },
        });
      }
      handleCloseModal();
    },
    [actionId, actionName, editAction, handleCloseModal, refetch, setInfoCampaignV1, infoCampaignV1]
  );

  return {
    VALIDATION_RULES,
    handleValidation,
    handleApplyRename,
    handleSubmitData,
    handleCloseModal,
    loading,
  };
};
