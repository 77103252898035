export const SimpleSmsIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3602 2.85718H6.64021C3.60275 2.85718 1.14307 5.44731 1.14307 8.62232V28.3909C1.14307 29.0259 1.85291 29.3768 2.33164 28.9758L5.55069 26.3188C5.89735 26.3021 6.26053 26.2854 6.6237 26.2854H25.3602C28.3977 26.2854 30.8574 23.712 30.8574 20.537V8.62232C30.8574 5.4306 28.3977 2.85718 25.3602 2.85718Z"
        fill="#0056B8"
      />
      <path
        d="M15.9341 19.2335H7.8617C7.46551 19.2335 7.15186 18.916 7.15186 18.5149C7.15186 18.1139 7.46551 17.7964 7.8617 17.7964H15.9341C16.3303 17.7964 16.6439 18.1139 16.6439 18.5149C16.6439 18.916 16.3303 19.2335 15.9341 19.2335Z"
        fill="#FAFBFD"
      />
      <path
        d="M24.6503 15.5572H7.8617C7.46551 15.5572 7.15186 15.2397 7.15186 14.8387C7.15186 14.4376 7.46551 14.1201 7.8617 14.1201H24.6503C25.0465 14.1201 25.3601 14.4376 25.3601 14.8387C25.3601 15.2397 25.0465 15.5572 24.6503 15.5572Z"
        fill="#FAFBFD"
      />
      <path
        d="M24.6503 11.881H7.8617C7.46551 11.881 7.15186 11.5635 7.15186 11.1624C7.15186 10.7613 7.46551 10.4438 7.8617 10.4438H24.6503C25.0465 10.4438 25.3601 10.7613 25.3601 11.1624C25.3601 11.5635 25.0465 11.881 24.6503 11.881Z"
        fill="#FAFBFD"
      />
    </svg>
  );
};
