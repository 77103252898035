import { IconProps } from './iconPropTypes';

export const ActivityTriggerIcon = ({ color }: IconProps) => {
  return (
    <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <g id="Trigger-hover-2" transform="translate(-0.5 -0.5)">
        <path
          d="M18.5,9.5a9,9,0,1,1-9-9,9,9,0,0,1,9,9"
          data-name="Path 3441"
          fill={`${color}`}
          id="Path_3441"
        />
        <path
          d="M20.715,15.063h.672c.056,0,.084,0,.084.056a5.53,5.53,0,0,1,.5,1.343,4.908,4.908,0,0,1-.308,3.1,4.4,4.4,0,0,1-.952,1.427,3.467,3.467,0,0,1-.616.5,4.325,4.325,0,0,1-1.344.671c-.28.084-.56.14-.84.2a1.135,1.135,0,0,1-.308,0h-.84a2.933,2.933,0,0,1-.84-.14,4.1,4.1,0,0,1-1.344-.587,4.9,4.9,0,0,1-2.239-4.5,4.691,4.691,0,0,1,1.12-2.741,5,5,0,0,1,2.1-1.454,4.651,4.651,0,0,1,2.043-.28,5.091,5.091,0,0,1,1.987.587.154.154,0,0,1,.084.14v.727a.279.279,0,0,1-.14-.112,4.2,4.2,0,0,0-6.382,2.35,3.856,3.856,0,0,0-.084,1.874,4.2,4.2,0,0,0,2.407,3.133,3.837,3.837,0,0,0,1.847.364,3.7,3.7,0,0,0,1.092-.168A4.208,4.208,0,0,0,20.995,19.4a4.2,4.2,0,0,0-.252-4.279.087.087,0,0,0-.028-.056"
          data-name="Path 3442"
          fill="#fff"
          id="Path_3442"
          transform="translate(-7.786 -7.994)"
        />
        <path
          d="M26.383,21.961a3.226,3.226,0,0,1-.751,4.2,3.294,3.294,0,0,1-4.338-4.953,3.243,3.243,0,0,1,3.894-.41c0,.034-.034.034-.034.068l-.444.444c-.034.034-.068.034-.137.034a2.5,2.5,0,1,0,1.4,2.972,2.281,2.281,0,0,0-.137-1.708c-.034-.034-.034-.068,0-.137l.478-.478c.034,0,.034,0,.068-.034"
          data-name="Path 3443"
          fill="#fff"
          id="Path_3443"
          transform="translate(-14.077 -14.132)"
        />
        <path
          d="M32.963,12.7c-.1.547-.239,1.332-.239,1.332s.922-.1,1.5-.137a.181.181,0,0,1,.137.307l-.82.82c-.034.034-1.127,0-1.2.034-.922.922-3.006,3.04-3.074,3.074-.034.034-.034.068-.034.137a.864.864,0,0,1-.649,1.025.845.845,0,0,1-.991-.854.826.826,0,0,1,.888-.82.31.31,0,0,0,.239-.1c.854-.854,2.9-2.869,3.074-3.04.034-.034.068-1.127.1-1.161l.786-.786c.1-.1.307.034.273.171"
          data-name="Path 3444"
          fill="#fff"
          id="Path_3444"
          transform="translate(-19.337 -8.559)"
        />
      </g>
    </svg>
  );
};
