export const EditStrongIcon = () => {
  return (
    <svg height="21" viewBox="0 0 23.63 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.516,3.416l3.7,3.7a.4.4,0,0,1,0,.566l-8.96,8.96-3.807.423a.8.8,0,0,1-.882-.882l.423-3.807,8.96-8.96A.4.4,0,0,1,16.516,3.416Zm6.646-.939-2-2a1.6,1.6,0,0,0-2.264,0L17.443,1.927a.4.4,0,0,0,0,.566l3.7,3.7a.4.4,0,0,0,.566,0l1.452-1.452a1.6,1.6,0,0,0,0-2.264ZM15.753,14.205v4.176H2.626V5.254h9.427A.5.5,0,0,0,12.4,5.11l1.641-1.641a.492.492,0,0,0-.349-.841H1.969A1.97,1.97,0,0,0,0,4.6v14.44a1.97,1.97,0,0,0,1.969,1.969h14.44a1.97,1.97,0,0,0,1.969-1.969V12.564a.493.493,0,0,0-.841-.349L15.9,13.857A.5.5,0,0,0,15.753,14.205Z"
        data-name="Icon awesome-edit"
        fill="#004dbc"
        id="Icon_awesome-edit"
        transform="translate(0 -0.007)"
      />
    </svg>
  );
};
