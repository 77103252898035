import { useCallback, useState } from 'react';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useBillingPortal = () => {
  const service = useService();
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useStateUserContext();

  const getBillingPortalUrl = useCallback(async () => {
    const { url } = await service.getBillingPortalSession(userData?.language as 'en' | 'es' | 'pt');
    return url;
  }, [service, userData?.language]);

  const handleRedirectBillingPortal = useCallback(async () => {
    setIsLoading(true);
    const url = await getBillingPortalUrl();
    setIsLoading(false);
    window.open(url, '_blank');
  }, [getBillingPortalUrl]);

  return { getBillingPortalUrl, handleRedirectBillingPortal, isLoading };
};
