import { sameDay } from '@formkit/tempo';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { isSameMonth } from '@/src/modules/CampaignsModule/components/campaignCalendar/utils';

import { TRANSLATE_CALENDAR_MAIN, WHATSAPP_PREFIX } from '@/modules/CampaignsModule/constants';
import { ICalendarCampaignActions } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export interface DayActionsProps {
  actionsList: ICalendarCampaignActions[];
  day: Date;
  MAX_ACTIONS: number;
  currentMonthDate: Date;
}

const CalendarDays = ({ actionsList, day, MAX_ACTIONS, currentMonthDate }: DayActionsProps) => {
  const { t } = useTranslation();
  const outboundWhatsApp = useFeatureFlag('outboundWhatsApp');

  const filteredActions = useMemo(
    () =>
      actionsList?.filter((action) => action?.sendDate && sameDay(day, new Date(action?.sendDate))),
    [actionsList, day]
  );

  const actionsCount = useMemo(() => filteredActions.length, [filteredActions]);
  const displayedActions = useMemo(
    () => filteredActions.slice(0, MAX_ACTIONS),
    [filteredActions, MAX_ACTIONS]
  );
  const remainingActions = useMemo(() => actionsCount - MAX_ACTIONS, [actionsCount, MAX_ACTIONS]);

  const formatActionName = useCallback(
    (name: string) => {
      return outboundWhatsApp ? name.replace(WHATSAPP_PREFIX, '') : name;
    },
    [outboundWhatsApp]
  );

  return (
    <ol className="mt-2">
      {displayedActions.map((action) => (
        <li key={action.id}>
          <div className="group flex">
            <p
              className={`flex-auto truncate text-[14px]
              ${
                isSameMonth(day, currentMonthDate)
                  ? 'text-emblue-black'
                  : 'pointer-events-none text-emblue-disabled'
              }
              `}
            >
              {formatActionName(action.name)}
            </p>
          </div>
        </li>
      ))}
      {remainingActions > 0 && (
        <li className="text-[13px] text-emblue-disabled">
          {t(`${TRANSLATE_CALENDAR_MAIN}.more`, { actionCount: remainingActions })}
        </li>
      )}
    </ol>
  );
};

export default CalendarDays;
