import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

interface ArrowProps {
  className?: string;
  style?: object;
  onClick?: () => void;
  direction: 'right' | 'left';
}

const Arrow = ({ className, onClick, direction }: ArrowProps) => {
  const classes = classNames(className, 'before:content-none h-10 w-10');
  return (
    <button className={classes} onClick={onClick}>
      {direction === 'right' ? (
        <ChevronRightIcon className="h-10 w-10 text-gray-300 hover:text-white" />
      ) : (
        <ChevronLeftIcon className="h-10 w-10 text-gray-300 hover:text-white" />
      )}
    </button>
  );
};

export default Arrow;
