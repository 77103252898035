import { Stepper2 } from '@/src/assets';

import { urlWithPublicPath } from '..';

export const ContactsStepperImg = () => {
  return (
    <div className="flex h-full flex-col">
      <img alt="" src={urlWithPublicPath(Stepper2)} style={{ height: '300px' }} />
    </div>
  );
};
