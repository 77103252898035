import { IconProps } from './iconPropTypes';

export const ActivityEmailIcon = ({ color }: IconProps) => {
  return (
    <svg height="14.4" viewBox="0 0 18 14.4" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.341,7.712H6.941a1.792,1.792,0,0,0-1.791,1.8l-.009,10.8a1.8,1.8,0,0,0,1.8,1.8h14.4a1.8,1.8,0,0,0,1.8-1.8V9.512A1.8,1.8,0,0,0,21.341,7.712Zm0,3.6-7.2,4.5-7.2-4.5v-1.8l7.2,4.5,7.2-4.5v1.8Z"
        data-name="Icon metro-mail"
        fill={`${color}`}
        id="Icon_metro-mail"
        transform="translate(-5.141 -7.712)"
      />
    </svg>
  );
};
