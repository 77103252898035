/**
 * Validates if the string is a valid JSON and extracts the content of the 'body' field.
 * If it's not a valid JSON or doesn't have a 'body' field, it returns the original string.
 * @param input - The string to validate and process
 * @returns The content of the 'body' field if valid, or the original string
 */
export function extractBodyFromJson(input: string): string {
  try {
    const parsedJson = JSON.parse(input) as Record<string, unknown>;

    if (typeof parsedJson === 'object' && parsedJson !== null && 'body' in parsedJson) {
      return parsedJson.body as string;
    }

    return input;
  } catch (error) {
    return input;
  }
}
