import { useTranslation } from 'react-i18next';

import { InvoiceDetailV2 } from '@/src/infrastructure/interfaces/IResponses';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { generateInvoiceHTML } from '@/src/modules/MyPlanModule/utils/generateInvoice';

export const useInvoiceData = () => {
  const service = useService();
  const { t } = useTranslation();

  const fetchInvoiceData = async (month: string, year: string) => {
    const response: InvoiceDetailV2 = await service.getInvoiceData(month, year);
    return response;
  };

  const openInvoiceNewTab = async (date: string) => {
    const [month, year] = date.split('/');
    const invoiceData = await fetchInvoiceData(month, year);
    if (invoiceData) {
      const invoiceHTML = generateInvoiceHTML(invoiceData, t);
      const newTab = window.open();
      if (newTab) {
        newTab.document.write(invoiceHTML);
        newTab.document.close();
      }
    }
  };

  return { openInvoiceNewTab };
};
