import {
  URL_REGEX,
  URL_TO_REPLACE,
} from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/constants';

/**
 * Replaces URLs found in the input string with a specified short link.
 * @param {string} input The input string containing URLs to be replaced.
 * @returns {string} A new string with URLs replaced by a predefined short link.
 */
export const replaceUrlsWithShortLinks = (input: string): string =>
  input.replace(URL_REGEX, URL_TO_REPLACE);
