import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '@/lib/v2/components';

import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';

import ComparisonOfPlans from './ComparisonOfPlans';

import { HandleSelectedPlan } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';

interface ModalComparisonOfPlansProps {
  isOpen: boolean;
  onClose: (close: boolean) => void;
  frequency: Frequency;
  onSelectedPlan: HandleSelectedPlan;
}

const ModalComparisonOfPlans = ({
  isOpen,
  onClose,
  frequency,
  onSelectedPlan,
}: ModalComparisonOfPlansProps) => {
  const { t } = useTranslation();

  return (
    <Modal noPadding withAuto open={isOpen} onClose={onClose}>
      <div className="relative h-auto max-h-[95vh] w-[95vw]">
        <div className="border-b p-6">
          <Text fontWeight="medium">{t('MANAGE_PLAN.MODAL_COMPARISON.title')}</Text>
        </div>
        <ComparisonOfPlans frequency={frequency} onSelectedPlan={onSelectedPlan} />
      </div>
    </Modal>
  );
};

export default memo(ModalComparisonOfPlans);
