export const AverageIcon = () => {
  return (
    <svg
      height="10.698"
      viewBox="0 0 7.836 10.698"
      width="7.836"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.267-.677a2.025,2.025,0,0,1,.161.383.205.205,0,0,1-.044.219.521.521,0,0,1-.3.1q-.215.021-.6.021-.321,0-.506-.014a1.48,1.48,0,0,1-.291-.044A.317.317,0,0,1,5.537-.1a.5.5,0,0,1-.082-.137L3.794-3.432,2.14-.239A.645.645,0,0,1,2.051-.1.317.317,0,0,1,1.9-.017,1.493,1.493,0,0,1,1.6.027q-.188.014-.5.014Q.752.041.557.021a.414.414,0,0,1-.263-.1A.22.22,0,0,1,.267-.294,2.046,2.046,0,0,1,.438-.677L2.557-4.539.588-8.169A2.676,2.676,0,0,1,.42-8.562a.2.2,0,0,1,.034-.219A.5.5,0,0,1,.745-8.87q.212-.017.6-.017.321,0,.509.014a1.564,1.564,0,0,1,.3.044.3.3,0,0,1,.154.085.746.746,0,0,1,.085.137L3.944-5.681,5.455-8.606a.746.746,0,0,1,.085-.137.291.291,0,0,1,.144-.085,1.308,1.308,0,0,1,.273-.044q.174-.014.482-.014.342,0,.54.017a.441.441,0,0,1,.273.092.214.214,0,0,1,.038.219,2.424,2.424,0,0,1-.154.39L5.168-4.56Zm.82-9.358a1.254,1.254,0,0,1-.065.485.222.222,0,0,1-.215.137H1.313a.228.228,0,0,1-.222-.133,1.226,1.226,0,0,1-.065-.482,1.283,1.283,0,0,1,.065-.489.227.227,0,0,1,.222-.14H7.807a.222.222,0,0,1,.215.137A1.254,1.254,0,0,1,8.087-10.035Z"
        fill="#004dbc"
        transform="translate(-0.251 10.657)"
      />
    </svg>
  );
};
