export const TagCloudIcon = () => {
  return (
    <svg
      height="18.113"
      viewBox="0 0 22.343 18.113"
      width="22.343"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 7489" id="Group_7489" transform="translate(-1141.261 -486.785)">
        <path
          d="M18.768,9.044A3.355,3.355,0,0,0,15.64,4.484a3.334,3.334,0,0,0-1.861.566A5.586,5.586,0,0,0,3.351,7.836c0,.094,0,.189.007.283A5.028,5.028,0,0,0,5.027,17.89H17.874a4.468,4.468,0,0,0,.894-8.846Z"
          data-name="Icon awesome-cloud"
          fill="#004DBC"
          id="Icon_awesome-cloud"
          transform="translate(1141.261 484.535)"
        />
        <g
          data-name="Icon feather-tag"
          id="Icon_feather-tag"
          transform="translate(1147.493 494.521)"
        >
          <path
            d="M12.58,8.88,8.885,12.575a1.031,1.031,0,0,1-1.458,0L3,8.153V3H8.153L12.58,7.427A1.031,1.031,0,0,1,12.58,8.88Z"
            data-name="Path 3456"
            fill="#fff"
            id="fill-white"
            stroke="#004DBC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(-3 -3)"
          />
          <path
            d="M10.5,10.5h0"
            data-name="Path 3457"
            id="Path_3457"
            stroke="#004DBC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            transform="translate(-7.923 -7.923)"
          />
        </g>
      </g>
    </svg>
  );
};
