import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';

import { smsPriceByCountryIdMap } from '@/src/modules/MyPlanModule/constants';

/**cspell:disable */
const data = `id_pais,es,en,pt,code
1,Argentina,Argentina,Argentina,AR
2,Afganistán,Afghanistan,Afeganistão,AF
3,Antigua y Barbuda,Antigua And Barbuda,Antígua e Barbuda,AG
4,Anguilla,Anguilla,Anguilla,AI
5,Albania,Albania,Albânia,AL
6,Armenia,Armenia,Armênia,AM
7,Antillas Holandesas,Netherlands Antilles,Antilhas Holandesas,AN
8,Angola,Angola,Angola,AO
9,Antártida,Antarctica,Antártida,AQ
10,Andorra,Andorra,Amdorra,AD
11,Samoa Americana,American Samoa,Samoa Americana,AS
12,Austria,Austria,Áustria,AT
13,Australia,Australia,Austrália,AU
14,Aruba,Aruba,Aruba,AW
15,Azerbaiyán,Azerbaijan,Azerbaijão,AZ
16,Bosnia y Herzegovinb,Bosnia AND Herzegovina,Bósnia e Herzegovina,BA
17,Barbados,Barbados,Barbados,BB
18,Bangladesh,Bangladesh,Bangladesh,BD
19,Bélgica,Belgium,Bélgica,BE
20,Burkina Faso,Burkina Faso,Burkina Faso,BF
21,Bulgaria,Bulgaria,Bulgária,BG
22,Bahrein,Bahrain,Bahrein,BH
23,Burundi,Burundi,Burundi,BI
24,Benin,Benin,Benin,BJ
25,Bermudas,Bermuda,Bermuda,BM
26,Brunei,Brunei,Brunei,BN
27,Bolivia,Bolivia,Bolívia,BO
28,Brasil,Brazil,Brasil,BR
29,Bahams,The Bahamas,Bahamas,BS
30,Bután,Bhutan,Butão,BT
31,Isla Bouvet,Bouvet Island,Ilha Bouvet,BV
32,Botswana,Botswana,Botsuana,BW
33,Bielorrusia,Belarus,Belarus,BY
34,Belice,Belize,Belize,BZ
35,Canadá,Canada,Canadá,CA
36,Islas de Cocos o Keeling,Cocos (Keeling) Islands,Ilhas Cocos (Keeling),CC
37,República Democrática del Congo,Democractic Republic of the,Congo República Popular do,CD
38,República Centroafricana,Central African Republic,República Centro-Africana,CF
40,Suiza,Switzerland,Suíça,CH
41,Costa de Marfíl,Cote D'Ivoire (Ivory Coast),Costa do Marfim,CI
42,Islas Cook,Cook Islands,Ilhas Cook,CK
43,Chile,Chile,Chile,CL
44,Camerún,Cameroon,Camarões,CM
45,China,China,China,CN
46,Colombia,Colombia,Colômbia,CO
47,Costa Rica,Costa Rica,Costa Rica,CR
48,Cuba,Cuba,Cuba,CU
49,Cabo Verde,Cape Verde,Cabo Verde,CV
50,Isla de Christmas,Christmas Island,Ilhas Christmas,CX
51,Chipre,Cyprus,Chipre,CY
52,República Checa,Czech Republic,República Tcheca,CZ
53,Alemania,Germany,Alemanha,DE
54,Djibouti,Djibouti,Djibuti,DJ
55,Dinamarca,Denmark,Dinamarca,DK
56,Dominica,Dominica,Dominica,DM
57,República Dominicana,Dominican Republic,República Dominicana,DO
58,Argelia,Algeria,Argélia,DZ
59,Ecuador,Ecuador,Equador,EC
60,Estonia,Estonia,Estônia,EE
61,Egipto,Egypt,Egito,EG
62,Eritrea,Eritrea,Eritréia,ER
63,España,Spain,Espanha,ES
64,Etiopía,Ethiopia,Etiópia,ET
65,Finlandia,Finland,Finlândia,FI
66,Fiji,Fiji Islands,Fiji,FJ
67,Islas Malvinas,Falkland Islands (Islas Malvinas),Ilhas Malvinas,FK
68,Micronesia,Micronesia,Micronésia,FM
69,Islas Faroe,Faroe Islands,Ilhas Faroés,FO
70,Francia,France,França,FR
71,Gabón,Gabon,Gabão,GA
72,Granada,Grenada,Grenada,GD
73,Georgia,Georgia,Geórgia,GE
74,Guayana Francesa,French Guiana,Guiana Francesa,GF
75,Ghana,Ghana,Gana,GH
76,Gibraltar,Gibraltar,Gibraltar,GI
77,Groenlandia,Greenlan,Groenlândia,GL
78,Gambia,Gambia,The Gâmbia,GM
79,Guinea,Guinea,Guiné,GN
80,Guadalupe,Guadeloupe,Guadalupe,GP
81,Guinea Ecuatorial,Equatorial Guinea,Guiné Equatorial,GQ
82,Grecia,Greece,Grécia,GR
84,Guatemala,Guatemala,Guatemala,GT
85,Guam,Guam,Guam,GU
86,Guinea-Bissau,Guinea-Bissau,Guiné-Bissau,GW
87,Guayana,Guyana,Guiana,GY
88,Hong Kong,Hong Kong,República Popular da China,HK
89,Islas Heard y McDonald,Heard AND McDonald Islands,Ilhas Heard e McDonald,HM
90,Honduras,Honduras,Honduras,HN
91,Croacia (Hrvatska),Croatia (Hrvatska),Croácia (Hrvatska),HR
92,Haití,Haiti,Haiti,HT
93,Hungría,Hungary,Hungria,HU
94,Indonesia,Indonesia,Indonésia,ID
95,Irlanda,Ireland,Irlanda,IE
96,Israel,Israel,Israel,IL
97,India,India,Índia,IN
98,Territorios británicos del océano Índico,British Indian Ocean Territory,Território Britânico do Oceano Índico,IO
99,Irak,Iraq,Iraque,IQ
100,Irán,Iran,Irã,IR
101,Islandia,Iceland,Islândia,IS
102,Italia,Italy,Itália,IT
103,Jamaica,Jamaica,Jamaica,JM
104,Jordania,Jordan,Jordânia,JO
105,Japón,Japan,Japão,JP
106,Kenia,Kenya,Quênia,KE
107,Kirguizistán,Kyrgyzstan,Quirguistão,KG
108,Camboya,Cambodia,Camboja,KH
109,Kiribati,Kiribati,Kiribati,KI
110,Comoras,Comoros,Comores,KM
111,San Cristóbal y Nieves,Saint Kitts And Nevis,Saint Kitts e Névis,KN
112,Corea del Norte,Korea North,Coréia do Norte,KP
113,Corea,Korea,Coréia,KR
114,Kuwait,Kuwait,Kuwait,KW
115,Islas Caimán,Cayman Islands,Ilhas Cayman,KY
116,Kazajistán,Kazakhstan,Cazaquistão,KZ
117,Laos,Laos,Laos,LA
118,Líbano,Lebanon,Líbano,LB
119,Santa Lucía,Saint Lucia,Santa Lúcia,LC
120,Liechtenstein,Liechtenstein,Liechtenstein,LI
121,Sri Lanka,Sri Lanka,Sri Lanka,LK
122,Liberia,Liberia,Libéria,LR
123,Lesotho,Lesotho,Lesoto,LS
124,Lituania,Lithuania,Lituânia,LT
125,Luxemburgo,Luxembourg,Luxemburgo,LU
126,Letonia,Latvia,Letônia,LV
127,Libia,Libya,Líbia,LY
128,Marruecos,Morocco,Marrocos,MA
129,Mónaco,Monaco,Mônaco,MC
130,Moldavia,Moldova,Moldávia,MD
131,Madagascar,Madagascar,Madagascar,MG
132,Islas Marshall,Marshall Islands,Ilhas Marshall,MH
133,Macedonia,Former Yugoslav Republic of,Macedônia,MK
134,Malí,Mali,Mali,ML
135,Birmania,Myanmar,Myanma,MM
136,Mongolia,Mongolia,Mongólia,MN
137,Macao,Macau S.A.R.,Macau,MO
138,Islas Marianas del Norte,Northern Mariana Islands,Ilhas Marianas do Norte,MP
139,Martinica,Martinique,Martinica,MQ
140,Mauritania,Mauritania,Mauritânia,MR
141,Montserrat,Montserrat,Montserrat,MS
142,Malta,Malta,Malta,MT
143,Mauricio,Mauritius,Maurício,MU
144,Maldivas,Maldives,Maldivas,MV
145,Malawi,Malawi,Malaui,MW
146,México,Mexico,México,MX
147,Malasia,Malaysia,Malásia,MY
148,Mozambique,Mozambique,Moçambique,MZ
149,Namibia,Namibia,Namíbia,NA
150,Nueva Caledonia,New Caledonia,Nova Caledônia,NC
151,Níger,Niger,Níger,NE
152,Norfolk,Norfolk Island,Ilha Norfolk,NF
153,Nigeria,Nigeria,Nigéria,NG
154,Nicaragua,Nicaragua,Nicarágua,NI
155,Países Bajos,The Netherlands,Holanda,NL
157,Noruega,Norway,Noruega,NO
158,Nepal,Nepal,Nepal,NP
159,Nauru,Nauru,Nauru,NR
160,Niue,Niue,Niue,NU
161,Nueva Zelanda,New Zealand,Nova Zelândia,NZ
162,Omán,Oman,Omã,OM
163,Panamá,Panama,Panamá,PA
164,Perú,Peru,Peru,PE
165,Polinesia Francesa,French Polynesia,Polinésia Francesa,PF
166,Papúa Nueva Guinea,Papua new Guinea,Papua-Nova Guiné,PG
167,Filipinas,Philippines,Filipinas,PH
168,Paquistán,Pakistan,Paquistão,PK
169,Polonia,Poland,Polônia,PL
170,St. Pierre y Miquelon,Saint Pierre AND Miquelon,St. Pierre e Miquelon,PM
171,Pitcairn,Pitcairn Island,Pitcairn,PN
172,Puerto Rico,Puerto Rico,Porto Rico,PR
173,Portugal,Portugal,Portugal,PT
174,Islas Palau,Palau,Palau,PW
175,Paraguay,Paraguay,Paraguai,PY
176,Qatar,Qatar,Catar,QA
177,Reunión,Reunion,Reunião,RE
178,Rumania,Romania,Romênia,RO
179,Rusia,Russia,Rússia,RU
180,Ruanda,Rwanda,Ruanda,RW
181,Arabia Saudí,Saudi Arabia,Arábia Saudita,SA
182,Islas Salomón,Solomon Islands,Ilhas Salomão,SB
183,Seychelles,Seychelles,Seychelles,SC
184,Sudán,Sudan,Sudão,SD
185,Suecia,Sweden,Suécia,SE
186,Singapore,Singapore,Singapore,SG
187,Santa Helena,Saint Helena,Santa Helena,SH
188,Eslovenia,Slovenia,Eslovênia,SI
189,Islas Svalbard y Jan Mayen,Svalbard And Jan Mayen Islands,Ilhas Svalbard e Jan Mayen,SJ
190,República Eslovaca,Slovakia,Eslováquia,SK
191,Sierra Leona,Sierra Leone,Serra Leoa,SL
192,San Marino,San Marino,San Marino,SM
193,Senegal,Senegal,Senegal,SN
194,Somalia,Somalia,Somália,SO
195,Surinam,Suriname,Suriname,SR
196,Santo Tomé y Príncipe,Sao Tome AND Principe,São Tomé e Príncipe,ST
197,El Salvador,El Salvador,El Salvador,SV
198,Siria,Syria,Síria,SY
199,Suazilandia,Swaziland,Suazilândia,SZ
200,Islas Turks y Caicos,Turks And Caicos Islands,Ilhas Turks e Caicos,TC
201,Chad,Chad,Chade,TD
202,Territorios franceses del Sur,French Southern Territories,Territórios Franceses do Sul,TF
203,Togo,Togo,Togo,TG
204,Tailandia,Thailand,Tailândia,TH
205,Tayikistán,Tajikistan,Tadjiquistão,TJ
206,Islas Tokelau,Tokelau,Tokelau,TK
207,Turkmenistán,Turkmenistan,Turcomenistão,TM
208,Túnez,Tunisia,Tunísia,TN
209,Tonga,Tonga,Tonga,TO
210,Timor Oriental,East Timor,Timor Oriental,TP
211,Turquía,Turkey,Turquia,TR
212,Trinidad y Tobago,Trinidad And Tobago,Trinidad e Tobago,TT
213,Tuvalu,Tuvalu,Tuvalu,TV
214,Taiwán,Taiwan,Taiwan,TW
215,Tanzania,Tanzania,Tanzânia,TZ
216,Ucrania,Ukraine,Ucrânia,UA
217,Uganda,Uganda,Uganda,UG
218,Reino Unido,United Kingdom,Reino Unido,UK
219,Islas menores de Estados Unidos,United States Minor Outlying Islands,Territórios Insulares dos EUA,UM
220,Estados Unidos,United States,Estados Unidos,US
221,Uruguay,Uruguay,Uruguai,UY
222,Uzbekistán,Uzbekistan,Uzbequistão,UZ
223,Ciudad del Vaticano (Santa Sede),Vatican City State (Holy See),Cidade do Vaticano (Santa Sé),VA
224,San Vicente y Granadinas,Saint Vincent And The Grenadines,São Vicente e Granadinas,VC
225,Venezuela,Venezuela,Venezuela,VE
226,Islas Vírgenes (Reino Unido),Virgin Islands (British),Ilhas Virgens Britânicas,VG
227,Islas Vírgenes (EE.UU.),Virgin Islands (US),Ilhas Virgens Americanas,VI
228,Vietnam,Vietnam,Vietnã,VN
229,Vanuatu,Vanuatu,Vanuatu,VU
230,Islas Wallis y Futuna,Wallis And Futuna Islands,Ilhas Wallis e Futuna,WF
231,Samoa,Samoa,Samoa,WS
232,Yemen,Yemen,Iêmen,YE
233,Mayotte,Mayotte,Mayotte,YT
234,Yugoslavia,Yugoslavia,Iugoslávia,YU
235,República de Sudáfrica,South Africa,África do Sul,ZA
236,Zambia,Zambia,Zâmbia,ZM
237,Zimbabue,Zimbabwe,Zimbábue,ZW
238,Eminatos Arabes,Eminatos Arabes,Eminatos Arabes,EA
240,Otro,Other,Outro,OT
241,Gran Bretaña,Great Britain,Grã-Bretanha,GB`;
/**cspell:enable */

type Country = {
  id: number;
  es: string;
  en: string;
  pt: string;
};

type Language = 'es' | 'en' | 'pt';

export const useCountriesOptions = () => {
  const {
    i18n: { language },
  } = useTranslation();

  function mapCsvData(csvData: string): Country[] {
    const rows = csvData.split('\n');

    rows.shift();

    const mappedData = rows.map((row) => {
      const columns = row.split(',');

      return {
        id: parseInt(columns[0], 10),
        es: columns[1],
        en: columns[2],
        pt: columns[3],
      };
    });

    return mappedData;
  }

  const mappedCountries = useMemo(() => {
    return mapCsvData(data);
  }, []);
  const countriesOptions: Option[] = useMemo(() => {
    const lang = language?.toLowerCase() ?? 'es';
    const mapped = mappedCountries.map(
      (country) =>
      ({
        id: country.id,
        value: country.id,
        name: country[lang as Language],
      } as Option)
    );
    const ordered = mapped.sort((a, b) => (a.name as string).localeCompare(b.name as string, lang));
    return ordered;
  }, [language, mappedCountries]);

  const getCountryOption = useCallback(
    (countryId: string | number) => {
      const country = countriesOptions.find((country) => country.id === Number(countryId));
      if (country) return country;
      return countriesOptions[0];
    },
    [countriesOptions]
  );

  const getCountrySymbol = useCallback((countryId?: string | number) => {
    return smsPriceByCountryIdMap.get(Number(countryId) || 0) ?? 'America Central';
  }, []);

  return { countriesOptions, getCountryOption, getCountrySymbol };
};
