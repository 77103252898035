import async from 'async';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const iframeInit = async (iframe: HTMLIFrameElement) => {
  let gotMessage = false;
  sessionStorage.setItem('@intercom', 'false');
  const handler = ({ data }: any) => {
    if (data.type === 'emblueCompat') {
      if (data.payload.event === 'initConfirm') {
        console.log('received reply', data);
        gotMessage = true;
        sessionStorage.setItem('@intercom', 'true');
      }
    }
  };
  window.addEventListener('message', handler);
  await async.retry(25000, (callback) => {
    if (gotMessage) {
      callback(null);
      return;
    }
    if (iframe.contentWindow) {
      iframe.contentWindow?.postMessage(
        {
          type: 'emblueCompat',
          payload: {
            event: 'init',
          },
        },
        '*'
      );
    }
    setTimeout(() => {
      callback(gotMessage ? null : new Error('internal timeout'));
    }, 50);
  });
  window.removeEventListener('message', handler);
};

export const sendEvent = (event: string, data?: object, iframe?: HTMLIFrameElement) => {
  if (!iframe) {
    iframe = document.getElementById('v1-container') as HTMLIFrameElement;
  }
  if (iframe?.contentWindow) {
    const msg = {
      type: 'emblueCompat',
      payload: { event, ...data },
    };
    iframe.contentWindow?.postMessage(msg, '*');
  } else {
    console.error('Iframe is not loaded?');
  }
};
