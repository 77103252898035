import { format, monthEnd, monthStart, parse, weekEnd, weekStart } from '@formkit/tempo';
import { useAtom, useAtomValue } from 'jotai';
import { useLayoutEffect, useMemo } from 'react';

import { eachDayOfInterval } from '@/src/modules/CampaignsModule/components/campaignCalendar/utils';

import {
  atomCalendarLoader,
  atomCalendarLoaderSidebar,
  atomCurrentDateCalendar,
  atomListCalendar,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { ICalendarActionsPayload } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useCalendarActions = (currentMonth: string) => {
  const [currentDateCalendar, setCurrentDateCalendar] = useAtom(atomCurrentDateCalendar);
  const calendarLoader = useAtomValue(atomCalendarLoader);
  const calendarLoaderSidebar = useAtomValue(atomCalendarLoaderSidebar);
  const { getListCalendar } = useCampaignActions();
  const listCalendar = useAtomValue(atomListCalendar);

  const currentMonthDate = parse(currentMonth, 'MMM YYYY');

  const startOfMonth = useMemo(
    () => weekStart(monthStart(parse(currentMonth, 'MMM YYYY'))),
    [currentMonth]
  );
  const endOfMonth = useMemo(
    () => weekEnd(monthEnd(parse(currentMonth, 'MMM YYYY'))),
    [currentMonth]
  );
  const calendarDays = useMemo(
    () => eachDayOfInterval({ startDate: startOfMonth, endDate: endOfMonth }),
    [startOfMonth, endOfMonth]
  );

  const handleDayClick = (date: Date) => setCurrentDateCalendar(date);

  useLayoutEffect(() => {
    const payload: ICalendarActionsPayload = {
      startDate: format(startOfMonth, 'YYYY-MM-DD'),
      endDate: format(endOfMonth, 'YYYY-MM-DD'),
      actionStatus: [2, 23, 18, 3, 4, 8, 9, 17, 7, 6, 5, 12],
    };

    const fetchList = async () => await getListCalendar(payload);
    void fetchList();
  }, [endOfMonth, getListCalendar, startOfMonth]);

  return {
    events: {
      handleDayClick,
    },
    state: {
      currentDateCalendar,
      calendarLoader,
      calendarLoaderSidebar,
      listCalendar,
      calendarDays,
      currentMonthDate,
    },
  };
};
