import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemsListProps } from '@/lib/components/DropDown/DropDown';
import { toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const flowsListsAux: ItemsListProps[] = [
  {
    id: '1',
    title: 'flows 1',
  },
  {
    id: '2',
    title: 'flows 2',
  },
  {
    id: '3',
    title: 'flows 3',
  },
];
/** cspell:disable */
interface IContactsToFlows {
  type: 'usuario' | 'perfil';
}
/** cspell:enable */

export const useConnectContactsToFlows = ({ type }: IContactsToFlows) => {
  const [openConnectToFlows, setOpenConnectToFlows] = useState(false);
  const [showLoadingFlows, setShowLoadingFlows] = useState(false);
  const service = useService();
  const { t } = useTranslation();

  const showNotification = useCallback(
    (result: boolean) => {
      toast({
        title: result
          ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
          : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
        body: result
          ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
          : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
        variant: result ? 'success' : 'error',
      });
    },
    [t]
  );

  const handleContactToFlow = useCallback(
    async (flowId: number, groupsId: number[]) => {
      setShowLoadingFlows(true);

      const result = await service?.addGroupsToFlow({
        groupsId,
        type,
        flowId,
      });

      setShowLoadingFlows(false);
      showNotification(result ? result : false);

      if (result) setOpenConnectToFlows(false);

      return result;
    },
    [service, showNotification, type, setShowLoadingFlows]
  );

  return {
    flowsListsAux,
    openConnectToFlows,
    setOpenConnectToFlows,
    handleContactToFlow,
    showLoadingFlows,
  };
};
