export const NpsStatus = (npsScore: number | undefined): string => {
  if (!npsScore && npsScore !== 0) {
    return 'noRegistry';
  }

  if (npsScore >= 9) {
    return 'promoter';
  }

  if (npsScore >= 7 && npsScore <= 8) {
    return 'passive';
  }

  if (npsScore >= 0 && npsScore <= 6) {
    return 'detractor';
  }

  return 'noRegistry';
};
