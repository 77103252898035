import { IconProps } from './iconPropTypes';

export const ItemsTableIcon = ({ color }: IconProps) => {
  return (
    <svg
      height="15.09"
      viewBox="0 0 14.321 15.09"
      width="14.321"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        data-name="Icon feather-shopping-bag"
        id="Icon_feather-shopping-bag"
        transform="translate(-3.5 -2.3)"
      >
        <path
          d="M6.553,3,4.5,5.738v9.583A1.369,1.369,0,0,0,5.869,16.69h9.583a1.369,1.369,0,0,0,1.369-1.369V5.738L14.767,3Z"
          data-name="Path 3452"
          fill="none"
          id="Path_3452"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
          transform="translate(0 0)"
        />
        <path
          d="M4.5,9H16.821"
          data-name="Path 3453"
          fill="none"
          id="Path_3453"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(0 -3.262)"
        />
        <path
          d="M17.476,15A2.738,2.738,0,0,1,12,15"
          data-name="Path 3454"
          fill="none"
          id="Path_3454"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-4.078 -6.524)"
        />
      </g>
    </svg>
  );
};
