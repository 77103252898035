import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Text } from '@/lib/v2/components';

const LINK_DETAIL_GROUP_TEST =
  'https://help.embluemail.com/primeros-pasos/como-crear-un-grupo-de-prueba-en-emblue/';

const GroupTestTooltip = () => {
  const { t } = useTranslation();
  return (
    <div className="w-72 p-2">
      <Text fontWeight="normal" variant="text">
        {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.copyPreviewTooltip')}
        <Link className="ml-1 text-emblue-primary" target="_blank" to={LINK_DETAIL_GROUP_TEST}>
          {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.moreInformationPreview`)}
        </Link>
      </Text>
    </div>
  );
};

export default GroupTestTooltip;
