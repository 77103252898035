import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Flex,
  IconSvg,
  If,
  Input,
  InputTextArea,
  Text,
  Tooltip,
} from '@/lib/v2/components';
import { CloseIcon, CreateIcon, SuccessIcon } from '@/lib/v2/icons/outline';
import { ExclamationIcon, TextSmsIcon } from '@/lib/v2/icons/solid';

import { WhatsAppTemplate } from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { replaceUrlsWithShortLinks } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/utils';
import Skeleton from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/components/Skeleton/Skeleton';
import { IWhatsAppFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/hooks/useWhatsAppForm';
import { useWhatsAppFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/hooks/useWhatsAppFormEvents';
import { extractBodyFromJson } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/utils/extractBodyFromJson';
import { SubjectButtons } from '@/src/modules/CampaignsModule/components/SubjectButtons';
import {
  EButtonType,
  ISubjectChangeButtonsProps,
} from '@/src/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';
import { IFormProps } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import {
  atomActionInfoWhatsApp,
  atomInfoWhatsAppPayload,
  atomMessageWhatsApp,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { IWhatsAppInfoPayload } from '@/modules/CampaignsModule/interfaces/WhatsApp';
import {
  atomIsLoadingWhatsAppTemplates,
  atomSendWhatsAppModalIsOpen,
  atomWhatsAppTemplates,
  atomWhatsAppTemplateSelected,
} from '@/modules/ContactsModule/atoms/WhatsApp';
import { SendWhatsAppModal } from '@/modules/ContactsModule/components/SendWhatsAppModal';

interface IMessageInputProps {
  loading?: boolean;
  form: IFormProps<IWhatsAppFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

enum ETemplateOption {
  IDLE = 'idle',
  SELECT = 'select',
  CREATE = 'create',
}

const MessageInput = ({ loading, form, rules, disabled = false }: IMessageInputProps) => {
  const { t } = useTranslation('inbox');
  const { actionId } = useParams();

  const [messageWhatsApp, setMessageWhatsApp] = useAtom(atomMessageWhatsApp);
  const [infoWhatsAppPayload, setInfoWhatsAppPayload] = useAtom(atomInfoWhatsAppPayload);
  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);

  const { setInfoOnEvent } = useWhatsAppFormEvents({ actionId: Number(actionId) });

  const { control, setValue } = form;

  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [templateOption, setTemplateOption] = useState<ETemplateOption>(ETemplateOption.IDLE);

  const [sendWhatsAppModalIsOpen, setSendWhatsAppModalIsOpen] = useAtom(
    atomSendWhatsAppModalIsOpen
  );
  const [selectedTemplate, setSelectedTemplate] = useAtom(atomWhatsAppTemplateSelected);
  const isLoadingTemplates = useAtomValue(atomIsLoadingWhatsAppTemplates);
  const whatsAppTemplates = useAtomValue(atomWhatsAppTemplates);

  const handleCloseSendWhatsAppModal = useCallback(() => {
    setSendWhatsAppModalIsOpen(false);
  }, [setSendWhatsAppModalIsOpen]);

  /* cspell:disable */
  useEffect(() => {
    const message = actionInfoWhatsApp?.mensaje ?? '';

    setValue('message', message);
    setMessageWhatsApp({ ...messageWhatsApp, original: message });
  }, [actionInfoWhatsApp?.mensaje, setMessageWhatsApp, setValue]);
  /* cspell:enable */

  const handleChangeMessage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setMessageWhatsApp({ ...messageWhatsApp, original: value });
    },
    [messageWhatsApp, setMessageWhatsApp]
  );

  /* cspell:disable */
  const handleBlurMessage = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: value };

      setInfoOnEvent(payload);
      setInfoWhatsAppPayload(payload);
    },
    [infoWhatsAppPayload, setInfoOnEvent, setInfoWhatsAppPayload]
  );
  /* cspell:enable */

  /* cspell:disable */
  useEffect(() => {
    if (selectedTemplate) {
      const variables = { ...selectedTemplate.variables };
      const firstKey = Object.keys(variables)[0];
      if (firstKey) {
        variables[firstKey] = '${1#name}';
      }

      const messageJSON = JSON.stringify({
        to: '',
        from: '',
        ContentSid: selectedTemplate.sid,
        body: selectedTemplate.types['twilio/text']?.body || '',
        ContentVariables: variables,
      });
      setValue('message', messageJSON);
      setMessageWhatsApp({
        ...messageWhatsApp,
        original: selectedTemplate.types['twilio/text']?.body || '',
      });

      const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: messageJSON };
      setInfoOnEvent(payload);
      setInfoWhatsAppPayload(payload);
    }
  }, [selectedTemplate]);
  /* cspell:enable */

  useEffect(() => {
    if (whatsAppTemplates && !!messageWhatsApp.original && !selectedTemplate) {
      const templateFound = whatsAppTemplates.find(
        (template: WhatsAppTemplate) =>
          template.types['twilio/text']?.body === extractBodyFromJson(messageWhatsApp.original)
      );

      if (templateFound) {
        setSelectedTemplate(templateFound);
      }
    }
  }, [whatsAppTemplates, messageWhatsApp.original, setSelectedTemplate]);

  /* cspell:disable */
  const handleChangeSubjectButton = useCallback(
    ({ item }: ISubjectChangeButtonsProps) => {
      const currentMessage = messageWhatsApp.original;
      const message = currentMessage ? `${currentMessage} ${item}` : item;
      setValue('message', message);
      setMessageWhatsApp({ ...messageWhatsApp, original: message });

      const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: message };

      setInfoOnEvent(payload);
      setInfoWhatsAppPayload(payload);
    },
    [
      setValue,
      setMessageWhatsApp,
      messageWhatsApp,
      infoWhatsAppPayload,
      setInfoOnEvent,
      setInfoWhatsAppPayload,
    ]
  );
  /* cspell:enable */

  const handleChangeShortUrl = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      const currentWhatsApp = messageWhatsApp.original;
      const shortenedWhatsApp = replaceUrlsWithShortLinks(currentWhatsApp);

      setValue('message', checked ? shortenedWhatsApp : currentWhatsApp);

      const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, track: !!checked };

      setInfoOnEvent(payload);
      setInfoWhatsAppPayload(payload);
      setMessageWhatsApp({
        ...messageWhatsApp,
        original: currentWhatsApp,
        shortened: checked ? shortenedWhatsApp : '',
        track: !!checked,
      });
    },
    [
      infoWhatsAppPayload,
      messageWhatsApp,
      setInfoOnEvent,
      setInfoWhatsAppPayload,
      setMessageWhatsApp,
      setValue,
    ]
  );

  /* cspell:disable */
  const handleOnDeleteTemplateSelection = useCallback(() => {
    setTemplateOption(ETemplateOption.IDLE);
    setSelectedTemplate(RESET);
    setMessageWhatsApp({ ...messageWhatsApp, original: '' });

    const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: '' };
    setInfoOnEvent(payload);
    setInfoWhatsAppPayload(payload);
  }, [
    setTemplateOption,
    setSelectedTemplate,
    messageWhatsApp,
    setMessageWhatsApp,
    infoWhatsAppPayload,
    setInfoOnEvent,
    setInfoWhatsAppPayload,
  ]);
  /* cspell:enable */

  return (
    <>
      <div>
        <Text
          className="sticky top-0 z-[1] border-b border-solid border-emblue-borders bg-emblue-white pb-2"
          color="primary"
          fontWeight="medium"
          variant="sub-headline"
        >
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.message`)}
        </Text>
        <Text className="mt-4" color="dark" fontWeight="normal" variant="text">
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageDisclaimer`)}
        </Text>

        <div className="mb-10">
          <If condition={isLoadingTemplates}>
            <Skeleton className="mt-9 h-10 w-full" />
          </If>
          <If condition={!isLoadingTemplates}>
            <If condition={templateOption === ETemplateOption.CREATE}>
              <div className="mt-9">
                <Input
                  placeHolder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.templatePlaceholder`)}
                  type="text"
                />
              </div>
            </If>
          </If>
          <Flex
            withGap
            className={templateOption === ETemplateOption.CREATE ? 'mt-4' : 'mt-9'}
            gapSize="small"
            itemAlignment="start"
            textAlignment="center"
          >
            <If condition={isLoadingTemplates}>
              <Skeleton className="mt-4 h-5 w-32" />
            </If>
            <If condition={!isLoadingTemplates}>
              <If condition={templateOption === ETemplateOption.IDLE && !messageWhatsApp.original}>
                <Flex
                  withGap
                  alignment="center"
                  className="ml-52"
                  gapSize="medium"
                  itemAlignment="center"
                >
                  <Button
                    outline
                    secondary
                    disabled={disabled || messageWhatsApp.track}
                    id="select-template-button"
                    size="small"
                    onClick={() => setSendWhatsAppModalIsOpen(true)}
                  >
                    {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.selectTemplateButtonText`)}
                  </Button>
                  <Tooltip
                    isFullWidth
                    alignment="start"
                    className="min-w-80"
                    content={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.createTemplateTooltip`)}
                  >
                    <Button
                      disabled
                      outline
                      secondary
                      iconLeft={<IconSvg strokeColor="gray" svgComponent={<CreateIcon />} />}
                      id="create-template-button"
                      size="small"
                      onClick={() => setTemplateOption(ETemplateOption.CREATE)}
                    >
                      {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.createTemplateButtonText`)}
                    </Button>
                  </Tooltip>
                </Flex>
              </If>
              <If condition={!!messageWhatsApp.original}>
                <div className="size-full">
                  <Card className="!py-2 !pl-4 !pr-2">
                    <Flex noGrow alignment="spaceBetween" itemAlignment="center">
                      <Flex noGrow withGap alignment="start" gapSize="small" itemAlignment="center">
                        <IconSvg height="16" svgComponent={<TextSmsIcon />} width="16" />
                        <Text className="-mt-0.5" fontWeight="medium" variant="text">
                          {selectedTemplate?.friendly_name ?? '-'}
                        </Text>
                      </Flex>
                      <Flex noGrow withGap alignment="end" gapSize="small" itemAlignment="center">
                        <Button disabled outline secondary size="small">
                          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.edit`)}
                        </Button>
                        <Button
                          link
                          secondary
                          size="small"
                          onClick={handleOnDeleteTemplateSelection}
                        >
                          <IconSvg fillColor="gray-dark" svgComponent={<CloseIcon />} />
                        </Button>
                      </Flex>
                    </Flex>
                  </Card>
                  <Flex
                    noGrow
                    withGap
                    alignment="start"
                    className="mt-10"
                    gapSize="small"
                    itemAlignment="center"
                  >
                    <IconSvg svgComponent={<SuccessIcon />} />
                    <Text color="primary" fontWeight="medium" variant="text">
                      {t(
                        `${TRANSLATE_WHATSAPP_ACTION_FORM}.VALIDATED_MESSAGE.${String(
                          selectedTemplate?.approvalStatus
                        )}`,
                        {
                          defaultValue: 'rejected',
                        }
                      )}
                    </Text>
                  </Flex>
                </div>
              </If>
              <If condition={templateOption === ETemplateOption.CREATE}>
                <>
                  <div className="-mt-1 w-full">
                    <InputTextArea
                      ref={messageRef}
                      control={control}
                      disabled={disabled || messageWhatsApp.track}
                      id="whatsapp_message"
                      isLoading={loading}
                      name="message"
                      rows={7}
                      rules={rules.message}
                      onBlur={handleBlurMessage}
                      onChange={handleChangeMessage}
                    />
                    {!loading && (
                      <div
                        className={`flex gap-1 ${
                          messageWhatsApp.count > 1 ? 'justify-between' : 'justify-end'
                        }`}
                      >
                        {messageWhatsApp.count > 1 && (
                          <Text
                            className="mt-1 flex w-4/5 gap-2"
                            fontWeight="normal"
                            variant="text"
                          >
                            <IconSvg
                              className="mt-0.5 min-w-[20px]"
                              fillColor="passive"
                              height="20px"
                              svgComponent={<ExclamationIcon />}
                              width="20px"
                            />
                            {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageDisclaimerLength`)}
                          </Text>
                        )}
                      </div>
                    )}
                  </div>
                  <SubjectButtons
                    vertical
                    actionId={Number(actionId)}
                    disabled={disabled || messageWhatsApp.track}
                    exclude={[EButtonType.TAG]}
                    id="whatsapp_form-buttons"
                    searchPlaceholder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.searchDropdown`)}
                    onChangeButtons={handleChangeSubjectButton}
                  />
                </>
              </If>
            </If>
          </Flex>
          <If condition={messageWhatsApp?.customFields > 0}>
            <Alert
              className="mt-4"
              description={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageDisclaimerCustomFields`)}
              type="info"
            />
          </If>
        </div>
        <If condition={templateOption === ETemplateOption.CREATE}>
          <>
            <div className="mb-10">
              <Checkbox
                checked={messageWhatsApp.track}
                control={control}
                disabled={disabled}
                id="whatsapp_short-track"
                label={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.shortener`)}
                onChange={handleChangeShortUrl}
              />
              <Text className="pl-5" color="dark" fontWeight="normal" variant="text">
                {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.shortenerTracking`)}
              </Text>
            </div>

            <Flex withGap alignment="end" gapSize="small" itemAlignment="center">
              <Button
                outline
                secondary
                id="cancel-button"
                size="small"
                onClick={() => setTemplateOption(ETemplateOption.IDLE)}
              >
                {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.cancel`)}
              </Button>
              <Button primary id="validate-button" size="small" onClick={() => alert('validate')}>
                {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.validate`)}
              </Button>
            </Flex>
          </>
        </If>
      </div>
      <SendWhatsAppModal isOpen={sendWhatsAppModalIsOpen} onClose={handleCloseSendWhatsAppModal} />
    </>
  );
};

export default MessageInput;
