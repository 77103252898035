import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Table } from '@/lib/components';
import { Button } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Text } from '@/lib/v2/components/Text';
import { UploadArrowIcon } from '@/lib/v2/icons/solid';

export type DetailViewInfoProps = {
  id: number;
  nameField: string;
  valueField: Array<string>;
};

export interface IDetailViewList {
  mapping: DetailViewInfoProps;
}

interface ContactsDetailTableProps {
  detailList: IDetailViewList[];
  setIsOpenModalMappedFields: Dispatch<SetStateAction<boolean>>;
}

export const ContactsDetailTable = memo(
  ({ detailList, setIsOpenModalMappedFields }: ContactsDetailTableProps) => {
    const { t } = useTranslation();
    const nameField = {
      Header: () => (
        <Flex withGap alignment="start">
          <UploadArrowIcon />
          <Text>{t('IMPORT_MAIN.details')}</Text>
        </Flex>
      ),
      accessor: 'mapping.nameField',
      id: 'nameField',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => (
        <Flex withGap alignment="start">
          <Flex noGrow withGap gapSize="medium">
            <span className="self-start font-medium text-[#004DBC]">{value}</span>
          </Flex>
        </Flex>
      ),
    };

    const valueField = {
      accessor: 'mapping',
      id: 'mapping.valueField',
      Cell: ({ cell: { value } }: { cell: { value: DetailViewInfoProps } }) => {
        return (
          <Flex withGap alignment="start">
            <Flex noGrow withGap gapSize="medium">
              <div>{value.valueField}</div>
              {value.id === 2 && (
                <Button
                  secondary
                  id="import-details-view"
                  onClick={() => {
                    setIsOpenModalMappedFields(true);
                  }}
                >
                  {t('IMPORT_MAIN.view')}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      },
    };

    const detailFieldColumn: Column<object>[] = [nameField, valueField] as Column<object>[];

    return (
      <div className="w-full text-base text-[#364365] 2xl:text-14">
        <Table columns={detailFieldColumn} data={detailList} />
      </div>
    );
  }
);
