import { t } from 'i18next';
import { useState } from 'react';

import { ActivityCleanFilterIcon } from '@/lib/icon';
import { Button, DateInput } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { CreateIcon } from '@/lib/v2/icons/outline';

import { NoteFilters } from '@/modules/ContactsModule/types/Notes';

interface NotesFiltersProps {
  onOpenNote: (openValue: boolean) => void;
  onFilter: (filters: NoteFilters) => void;
  isEmptyNotes: boolean;
}

export const NotesFilters = ({ onOpenNote, onFilter, isEmptyNotes }: NotesFiltersProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const [startDate, endDate] = dateRange;

  const handleOnChange: eventOnChangeDateInput = (e) => {
    if (Array.isArray(e)) {
      setDateRange(e as [Date | null, Date | null]);
      const [startDate, endDate] = e;
      if (dateRange[0] !== null && startDate !== null && endDate !== null)
        onFilter({ startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    }
  };

  const clearFilters = () => {
    setDateRange([null, null]);
    onFilter({ startDate: '', endDate: '' });
  };

  const minDate = new Date();
  minDate.setFullYear(new Date().getFullYear() - 60);

  return (
    <>
      <div className="z-10 mb-[-60px]  flex flex-row justify-start gap-3">
        {isEmptyNotes && (
          <>
            <div className="w-[260px]">
              <DateInput
                selectsRange
                endDate={endDate}
                iconColor="primary"
                id="date-filter-notes-input"
                maxDate={new Date()}
                minDate={minDate}
                placeholder="dd/MM/yyyy - dd/MM/yyyy"
                startDate={startDate}
                onChange={handleOnChange}
              />
            </div>
            <Button
              standard
              className="!px-2.5"
              iconLeft={<ActivityCleanFilterIcon color="#004DBC" />}
              id="clear-filters-button"
              tooltip={t('NOTES_FILTERS.cleanAllFilters')}
              onClick={clearFilters}
            />
          </>
        )}
        <div>
          <Button
            standard
            className="!px-2"
            iconLeft={<CreateIcon />}
            id="create-note-button"
            onClick={() => onOpenNote(true)}
          ></Button>
        </div>
      </div>
    </>
  );
};
