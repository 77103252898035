export const WidgetIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.66667 0C1.22464 0 0.800716 0.175593 0.488155 0.488149C0.175595 0.800706 0 1.22462 0 1.66665V13.8887C0 14.3307 0.175595 14.7547 0.488155 15.0672C0.800716 15.3798 1.22464 15.5554 1.66667 15.5554H6.66667C6.66667 16.1387 6.65 16.4365 6.50667 16.7253C6.37333 16.9909 6.04444 17.4031 5.05889 17.8953C4.83328 18.0083 4.65282 18.1946 4.54719 18.4237C4.44156 18.6528 4.41705 18.9111 4.4777 19.156C4.53834 19.4009 4.68052 19.6178 4.88086 19.7712C5.0812 19.9245 5.32776 20.0051 5.58 19.9997H14.42C14.6742 20.0045 14.9224 19.9222 15.1234 19.7666C15.3245 19.611 15.4663 19.3914 15.5256 19.1442C15.584 18.8967 15.5559 18.6367 15.4459 18.4074C15.3359 18.1782 15.1507 17.9935 14.9211 17.8842C13.9511 17.3964 13.6256 16.9887 13.4933 16.7253C13.3489 16.4365 13.3333 16.1387 13.3333 15.5554H18.3333C18.7754 15.5554 19.1993 15.3798 19.5118 15.0672C19.8244 14.7547 20 14.3307 20 13.8887V1.66665C20 1.22462 19.8244 0.800706 19.5118 0.488149C19.1993 0.175593 18.7754 0 18.3333 0H1.66667ZM11.5367 17.7776C11.5264 17.758 11.5164 17.7384 11.5067 17.7187C11.11 16.9253 11.11 16.1409 11.1111 15.6131V15.5554H8.88889V15.6131C8.88889 16.1409 8.89 16.9253 8.49333 17.7187C8.48353 17.7384 8.47353 17.758 8.46333 17.7776H11.5367ZM17.7778 11.111H2.22222V13.3332H17.7778V11.111Z"
        fill="#18C678"
        fillRule="evenodd"
      />
    </svg>
  );
};
