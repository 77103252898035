import { useMemo } from 'react';

import { useEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { BeefreeService } from './BeefreeService';
import { StubBeefreeService } from './StubBeefreeService';

export const useBeefreeService = () => {
  const { env } = useEnv();

  const service = useMemo(() => {
    if (env !== 'storybook') {
      const { URL_EMA_APP_V2, URL_EMA_APP, JWT_DEV_TOKEN } = configData.endpoints[env];
      const jwt =
        JWT_DEV_TOKEN !== ''
          ? JWT_DEV_TOKEN
          : (window as Window & typeof globalThis & { __JWTToken: string }).__JWTToken;

      return BeefreeService.create({
        api: new APIService(''),
        jwt: jwt,
        url_ema_app_v2: URL_EMA_APP_V2,
        url_ema_app_v1: URL_EMA_APP,
      });
    } else {
      return StubBeefreeService.create();
    }
  }, [env]);

  return service;
};
