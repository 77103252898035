export const ContactMailOpenedIcon = () => {
  return (
    <svg
      height="27.83"
      viewBox="0 0 32.469 27.83"
      width="32.469"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.719,14.477a3.015,3.015,0,0,0-1.469-2.628L18.484,4.5,3.719,11.849A3.19,3.19,0,0,0,2.25,14.477V29.238A3.1,3.1,0,0,0,5.342,32.33H31.627a3.1,3.1,0,0,0,3.092-3.092ZM18.484,21.894,6.064,13.777l12.42-6.184L30.9,13.777Z"
        data-name="Icon ionic-md-mail-open"
        fill="#fc7e00"
        id="Icon_ionic-md-mail-open"
        transform="translate(-2.25 -4.5)"
      />
    </svg>
  );
};
