import { NpsHappinessFilters } from './types';

export const NPS_FROM_VALUE = '-100';
export const NPS_TO_VALUE = '100';
export const HAPPINESS_FILTERS_INITIAL: NpsHappinessFilters[] = [
  'promoters',
  'detractors',
  'passives',
];
export const HAPPINESS_STATUS_INITIAL = 'active';
