import { useCallback, useEffect, useState } from 'react';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { truncateString } from '@/src/modules/CampaignsModule/components/HtmlUpload/util/stringsUtils';

export const useCustomTags = () => {
  const [tags, setTags] = useState<ITag[]>([]);
  const service = useService();

  const getTagsForHtmlEditor = useCallback(async () => {
    const tagsListResponse = await service.getTagsDropDown({ identifier: 'TAGS_DROP_DOWN' });

    if (tagsListResponse) {
      setTags(tagsListResponse.list);
    }
  }, [service]);

  useEffect(() => {
    void getTagsForHtmlEditor();
  }, []);

  const tagsList = tags.map((tag) => {
    return { id: tag.id, name: tag.name, color: tag.color ? tag.color : '#044C8A' };
  });

  const tagsPopup = `
    <div class="tagsContainer">
        <div class="tagsButtonContainer">
            ${tagsList
              .map(
                (tag) => `
            <button
                class="tagButton"
                style="color: ${tag.color};
                border-color: ${tag.color};"
                title="${tag.name}"
                id="${tag.id}"> ${truncateString(tag.name, 12)}
            </button>`
              )
              .join('')}
        </div>
    </div>`;

  return tagsPopup;
};
