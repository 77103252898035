export const DropdownPush = () => {
  return (
    <svg
      data-name="Grupo 3953"
      height="13.913"
      id="Grupo_3953"
      viewBox="0 0 20 13.913"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        data-name="Rectángulo 2763"
        fill="#9c59df"
        height="13.913"
        id="Rectángulo_2763"
        rx="3"
        transform="translate(0)"
        width="20"
      />
      <path
        d="M6.022,4.52l-.767-.7A4.963,4.963,0,0,0,3.045,7.65H4.118A4.042,4.042,0,0,1,6.022,4.52ZM12.669,7.65h1.073a4.993,4.993,0,0,0-2.21-3.826l-.762.7A4.065,4.065,0,0,1,12.669,7.65Zm-1.057.244A3.012,3.012,0,0,0,9.2,4.813V4.481a.808.808,0,0,0-1.609,0v.331A3,3,0,0,0,5.175,7.893V10.33L4.1,11.305v.487h8.583v-.487l-1.073-.975ZM8.393,13.255a1.031,1.031,0,0,0,.215-.019,1.071,1.071,0,0,0,.772-.575.9.9,0,0,0,.08-.38H7.315A1.035,1.035,0,0,0,8.393,13.255Z"
        data-name="Icon material-notifications-active"
        fill="#fff"
        id="Icon_material-notifications-active"
        transform="translate(1.867 -1.546)"
      />
    </svg>
  );
};
