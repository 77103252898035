export const getTagsFromLink = (
  linkSelected: HTMLElement,
  popupContent: HTMLElement
): string[] | null => {
  const tagsAttribute = linkSelected.getAttribute('tags');
  let linkTags: string[] | null = null;

  if (tagsAttribute && tagsAttribute !== '') {
    linkTags = tagsAttribute.split(';');
    linkTags.forEach((linkTag: string) => {
      const buttonRelated = popupContent.querySelector(`[id='${linkTag}']`) as HTMLElement;
      if (buttonRelated) {
        buttonRelated.style.backgroundColor = buttonRelated.style.color;
        buttonRelated.style.color = 'white';
      }
    });
  }

  return linkTags;
};

export const getCurrentTagsSelection = (
  linkTags: string[],
  tagIdSelected: string,
  tagButton: HTMLElement
): string[] => {
  linkTags = linkTags ? linkTags : [];

  if (linkTags?.includes(tagIdSelected)) {
    linkTags = linkTags.filter((e) => e !== tagIdSelected);
    tagButton.style.color = tagButton.style.backgroundColor;
    tagButton.style.backgroundColor = 'inherit';
  } else {
    tagButton.style.backgroundColor = tagButton.style.color;
    tagButton.style.color = 'white';
    linkTags.push(tagIdSelected);
  }

  return linkTags;
};
