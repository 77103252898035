export const DownloadIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11.364C7.85437 11.364 7.71499 11.3388 7.58187 11.2885C7.44859 11.2381 7.32734 11.1583 7.21811 11.0493L3.62003 7.45095C3.40158 7.2325 3.29544 6.97382 3.30162 6.67492C3.30779 6.37601 3.41393 6.11741 3.62003 5.89913C3.84222 5.67677 4.10488 5.56251 4.40801 5.55633C4.7113 5.55016 4.97404 5.65816 5.19623 5.88035L6.89068 7.5748V1.10932C6.89068 0.798058 6.99771 0.535316 7.21178 0.321092C7.426 0.10703 7.68874 0 8 0C8.31126 0 8.574 0.10703 8.78822 0.321092C9.00229 0.535316 9.10932 0.798058 9.10932 1.10932V7.5748L10.8038 5.88035C11.0221 5.65816 11.2828 5.54918 11.5861 5.55341C11.8893 5.55764 12.1519 5.67092 12.3741 5.89327C12.5841 6.11546 12.6922 6.37503 12.6984 6.67199C12.7046 6.96895 12.5965 7.2286 12.3741 7.45095L8.78189 11.0493C8.67266 11.1583 8.55141 11.2381 8.41813 11.2885C8.28501 11.3388 8.14563 11.364 8 11.364ZM2.21863 16C1.60457 16 1.08128 15.7837 0.648767 15.3512C0.216256 14.9187 0 14.3954 0 13.7814V11.965C0 11.6538 0.107031 11.391 0.321092 11.1768C0.535316 10.9627 0.798058 10.8557 1.10932 10.8557C1.42058 10.8557 1.68332 10.9627 1.89754 11.1768C2.1116 11.391 2.21863 11.6538 2.21863 11.965V13.7814H13.7814V11.965C13.7814 11.6538 13.8884 11.391 14.1025 11.1768C14.3167 10.9627 14.5794 10.8557 14.8907 10.8557C15.2019 10.8557 15.4647 10.9627 15.6789 11.1768C15.893 11.391 16 11.6538 16 11.965V13.7814C16 14.3954 15.7837 14.9187 15.3512 15.3512C14.9187 15.7837 14.3954 16 13.7814 16H2.21863Z"
        fill="#5E6984"
      />
    </svg>
  );
};
