import { useNavigate } from 'react-router-dom';

export const useRedirectToItemIFrameV1 = () => {
  const navigate = useNavigate();

  const redirectToItemIFrameV1 = (pathV2: string, idElementsFlowClick: string[]) => {
    const interval = setInterval(() => {
      const iframe = document.getElementById('v1-container') as HTMLIFrameElement;

      if (iframe) {
        idElementsFlowClick.forEach((id, index) => {
          const itemElement = iframe.contentDocument?.getElementById(id);
          if (itemElement) {
            itemElement?.click();
            if (index === idElementsFlowClick.length - 1) clearInterval(interval);
          }
        });
      }
    }, 500);

    navigate(pathV2);
  };

  return { redirectToItemIFrameV1 };
};
