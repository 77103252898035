import { useEffect } from 'react';

const Logout = () => {
  useEffect(() => {
    const baseUrl = window.location.origin;

    window.location.href = `${baseUrl}/Services/Login.svc/Logout`;

    setTimeout(() => {
      window.location.href = baseUrl;
    }, 100);
  }, []);

  return null;
};

export default Logout;
