import { atomWithReset } from 'jotai/utils';

interface IAtomModalPreview {
  html: string;
  show: boolean;
}

export const atomModalPreview = atomWithReset<IAtomModalPreview>({
  show: false,
  html: '',
});
