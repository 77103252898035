export const GroupWidgetsIcon = () => {
  return (
    <svg
      height="29.825"
      viewBox="0 0 29.825 29.825"
      width="29.825"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 7285" id="Group_7285" transform="translate(-315.727 -128.122)">
        <g data-name="Group 4086" id="Group_4086" transform="translate(200)">
          <rect
            data-name="Rectangle 2394"
            fill="#004dbc"
            height="29.825"
            id="Rectangle_2394"
            rx="3"
            transform="translate(115.727 128.122)"
            width="29.825"
          />
        </g>
        <path
          d="M4.145,12.246h6.871A1.145,1.145,0,0,0,12.161,11.1V4.23a1.145,1.145,0,0,0-1.145-1.145H4.145A1.145,1.145,0,0,0,3,4.23V11.1A1.145,1.145,0,0,0,4.145,12.246Zm0,11.451h6.871a1.145,1.145,0,0,0,1.145-1.145V15.681a1.145,1.145,0,0,0-1.145-1.145H4.145A1.145,1.145,0,0,0,3,15.681v6.871A1.145,1.145,0,0,0,4.145,23.7Zm11.451,0h6.871a1.145,1.145,0,0,0,1.145-1.145V15.681a1.145,1.145,0,0,0-1.145-1.145H15.6a1.145,1.145,0,0,0-1.145,1.145v6.871A1.145,1.145,0,0,0,15.6,23.7ZM23.947,6.856,19.841,2.75a1.144,1.144,0,0,0-1.619,0L14.116,6.856a1.144,1.144,0,0,0,0,1.619l4.106,4.106a1.144,1.144,0,0,0,1.619,0l4.106-4.106a1.144,1.144,0,0,0,0-1.619Z"
          fill="#fff"
          id="widget-svgrepo-com"
          transform="translate(317.38 129.598)"
        />
      </g>
    </svg>
  );
};
