export const WhatsAppIcon = () => {
  return (
    <svg
      height="50.844"
      viewBox="0 0 50.845 50.844"
      width="50.845"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
        >
          <stop offset="0" stopColor="#61fd7d" />
          <stop offset="1" stopColor="#2bb826" />
        </linearGradient>
      </defs>
      <g id="whatsapp" transform="translate(0.021 -0.978)">
        <path
          d="M50.819,38.92c0,.278-.008.882-.025,1.348a26.777,26.777,0,0,1-.268,3.284,10.941,10.941,0,0,1-.921,2.752,9.8,9.8,0,0,1-4.313,4.309,10.963,10.963,0,0,1-2.768.921,26.875,26.875,0,0,1-3.259.263c-.467.017-1.071.025-1.348.025l-25.035,0c-.278,0-.882-.009-1.348-.025a26.79,26.79,0,0,1-3.284-.268A10.942,10.942,0,0,1,5.5,50.6a9.8,9.8,0,0,1-4.309-4.313,10.96,10.96,0,0,1-.921-2.768A26.9,26.9,0,0,1,0,40.264c-.017-.467-.025-1.071-.025-1.348l0-25.035c0-.278.008-.882.025-1.348A26.787,26.787,0,0,1,.276,9.248,10.939,10.939,0,0,1,1.2,6.5,9.8,9.8,0,0,1,5.51,2.187a10.965,10.965,0,0,1,2.768-.921A26.874,26.874,0,0,1,11.538,1C12,.986,12.608.978,12.885.978l25.035,0c.278,0,.882.008,1.348.025a26.79,26.79,0,0,1,3.284.268,10.94,10.94,0,0,1,2.752.921,9.8,9.8,0,0,1,4.309,4.313,10.961,10.961,0,0,1,.921,2.768,26.906,26.906,0,0,1,.263,3.26c.017.467.025,1.071.025,1.348l0,25.035Z"
          data-name="Path 3411"
          fill="url(#linear-gradient)"
          id="Path_3411"
          transform="translate(0 0)"
        />
        <path
          d="M184.529,140.933a18.234,18.234,0,0,0-28.7,21.992l-2.587,9.445,9.665-2.535a18.224,18.224,0,0,0,8.713,2.218h.007a18.233,18.233,0,0,0,12.9-31.121Zm-12.9,28.043h-.006a15.134,15.134,0,0,1-7.713-2.112l-.553-.328-5.735,1.5,1.531-5.59-.36-.573a15.155,15.155,0,1,1,12.837,7.1Zm8.312-11.346c-.456-.228-2.7-1.33-3.113-1.482s-.721-.228-1.025.228-1.177,1.482-1.443,1.786-.531.342-.987.114a12.443,12.443,0,0,1-3.664-2.26,13.722,13.722,0,0,1-2.534-3.155c-.266-.456-.028-.7.2-.929.2-.2.456-.532.683-.8a3.106,3.106,0,0,0,.456-.76.838.838,0,0,0-.038-.8c-.114-.228-1.025-2.47-1.4-3.381-.37-.888-.745-.768-1.025-.782s-.569-.016-.873-.016a1.674,1.674,0,0,0-1.215.57,5.107,5.107,0,0,0-1.594,3.8,8.857,8.857,0,0,0,1.86,4.711c.228.3,3.212,4.9,7.782,6.877a26.207,26.207,0,0,0,2.6.959,6.249,6.249,0,0,0,2.869.18c.875-.131,2.7-1.1,3.075-2.165a3.806,3.806,0,0,0,.266-2.166c-.114-.19-.418-.3-.873-.532Z"
          data-name="Path 3412"
          fill="#fff"
          id="Path_3412"
          transform="translate(-145.657 -127.927)"
        />
      </g>
    </svg>
  );
};
