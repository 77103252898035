export const DetailTableIcon = () => {
  return (
    <svg className="mr-2 flex h-4 w-4 self-center" viewBox="0 0 13.444 17">
      <path
        d="M8.556,11h5.333M8.556,14.556h5.333M15.667,19H6.778A1.778,1.778,0,0,1,5,17.222V4.778A1.778,1.778,0,0,1,6.778,3h4.965a.889.889,0,0,1,.628.26l4.813,4.813a.889.889,0,0,1,.26.628v8.521A1.778,1.778,0,0,1,15.667,19Z"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-4.5 -2.5)"
      />
    </svg>
  );
};
