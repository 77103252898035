export const GroupsIcon = () => {
  return (
    <svg
      data-name="Group 7405"
      height="20"
      id="Group_7405"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3174" fill="none" height="20" id="Rectangle_3174" width="20" />
      <g data-name="Group 7242" id="Group_7242" transform="translate(0.915 2.83)">
        <g
          data-name="Ellipse 1791"
          fill="none"
          id="Ellipse_1791"
          stroke="#004dbc"
          strokeWidth="1.5"
          transform="translate(12.954 3.868)"
        >
          <ellipse cx="1.981" cy="1.981" rx="1.981" ry="1.981" stroke="none" />
          <ellipse cx="1.981" cy="1.981" fill="none" rx="1.231" ry="1.231" />
        </g>
        <g
          data-name="Ellipse 1792"
          fill="none"
          id="Ellipse_1792"
          stroke="#004dbc"
          strokeWidth="1.5"
          transform="translate(1.255 3.868)"
        >
          <ellipse cx="1.981" cy="1.981" rx="1.981" ry="1.981" stroke="none" />
          <ellipse cx="1.981" cy="1.981" fill="none" rx="1.231" ry="1.231" />
        </g>
        <g
          data-name="Layout 4241"
          fill="none"
          id="Layout_4241"
          transform="translate(1236.909 -138.27)"
        >
          <path
            d="M-1218.766,148.46a3.715,3.715,0,0,0-1.51-2.264.177.177,0,0,0-.283,0,2.673,2.673,0,0,1-2.642.189,8.106,8.106,0,0,1,1.887,3.3h1.6C-1218.483,149.687-1218.766,148.46-1218.766,148.46Z"
            stroke="none"
          />
          <path
            d="M -1219.709228515625 149.6866455078125 L -1221.313232421875 149.6866455078125 C -1221.501708984375 149.0262145996094 -1222.06787109375 147.5166320800781 -1223.200073242188 146.3844757080078 C -1222.4453125 146.7618408203125 -1221.407592773438 146.7619476318359 -1220.558349609375 146.1957702636719 C -1220.463989257812 146.1015625 -1220.36962890625 146.101318359375 -1220.275268554688 146.1957702636719 C -1219.048828125 146.9505462646484 -1218.765625 148.4601135253906 -1218.765625 148.4601135253906 C -1218.765625 148.4601135253906 -1218.482666015625 149.6866149902344 -1219.709228515625 149.6866455078125 Z"
            fill="#004dbc"
            stroke="none"
          />
        </g>
        <g
          data-name="Layout 4242"
          fill="none"
          id="Layout_4242"
          transform="translate(1237.73 -138.27)"
        >
          <path
            d="M-1233.267,146.384a2.44,2.44,0,0,1-2.642-.189.177.177,0,0,0-.283,0,4.276,4.276,0,0,0-1.51,2.264s-.283,1.227.943,1.227h1.6A8.113,8.113,0,0,1-1233.267,146.384Z"
            stroke="none"
          />
          <path
            d="M -1236.050659179688 146.125 C -1236.00341796875 146.125 -1235.956298828125 146.1485900878906 -1235.9091796875 146.1957702636719 C -1235.154541015625 146.7617645263672 -1234.116577148438 146.8562927246094 -1233.267333984375 146.3844451904297 C -1234.399536132812 147.5166320800781 -1234.965698242188 149.0261840820312 -1235.154541015625 149.6866149902344 L -1236.75830078125 149.6866149902344 C -1237.98486328125 149.6866149902344 -1237.70166015625 148.4600830078125 -1237.70166015625 148.4600830078125 C -1237.70166015625 148.4600830078125 -1237.324340820312 146.9505310058594 -1236.192138671875 146.1957702636719 C -1236.14501953125 146.1485900878906 -1236.097778320312 146.125 -1236.050659179688 146.125 Z"
            fill="#004dbc"
            stroke="none"
          />
        </g>
        <g data-name="Group 6921" id="Group_6921" transform="translate(2.89)">
          <g
            data-name="Ellipse 1789"
            fill="none"
            id="Ellipse_1789"
            stroke="#004dbc"
            strokeWidth="1.5"
            transform="translate(2.422)"
          >
            <ellipse cx="3.774" cy="3.68" rx="3.774" ry="3.68" stroke="none" />
            <ellipse cx="3.774" cy="3.68" fill="none" rx="3.024" ry="2.93" />
          </g>
          <g
            data-name="Layout 4233"
            fill="none"
            id="Layout_4233"
            transform="translate(1234.667 -138.254)"
          >
            <path
              d="M-1222.244,150.236a8.117,8.117,0,0,0-2.83-4.34.6.6,0,0,0-.566,0,4.82,4.82,0,0,1-5.566,0,.6.6,0,0,0-.566,0,7.8,7.8,0,0,0-2.83,4.34s-.566,2.359,1.7,2.359h8.868C-1221.678,152.594-1222.244,150.236-1222.244,150.236Z"
              stroke="none"
            />
            <path
              d="M -1224.036865234375 151.0944519042969 C -1223.864868164062 151.0944519042969 -1223.760009765625 151.0719909667969 -1223.712036132812 151.0558319091797 C -1223.678466796875 150.9552612304688 -1223.674560546875 150.7303924560547 -1223.706420898438 150.5716552734375 C -1223.733276367188 150.4650573730469 -1224.196166992188 148.7105407714844 -1225.433959960938 147.5342102050781 C -1226.337036132812 148.0244445800781 -1227.348754882812 148.2805938720703 -1228.400756835938 148.2806243896484 C -1229.453735351562 148.2806549072266 -1230.50390625 148.0139312744141 -1231.426025390625 147.5199890136719 C -1232.644897460938 148.6350555419922 -1233.1083984375 150.4364776611328 -1233.14013671875 150.5648498535156 L -1233.1357421875 150.5510559082031 L -1233.14306640625 150.5782012939453 C -1233.174682617188 150.7380523681641 -1233.173950195312 150.9599304199219 -1233.144653320312 151.0718994140625 C -1233.102416992188 151.0821380615234 -1233.026489257812 151.0944519042969 -1232.9052734375 151.0944519042969 L -1224.036865234375 151.0944519042969 M -1224.036865234375 152.5944519042969 L -1232.9052734375 152.5944519042969 C -1235.169677734375 152.5944519042969 -1234.603637695312 150.2357482910156 -1234.603637695312 150.2357482910156 C -1234.603637695312 150.2357482910156 -1233.943237304688 147.3109741210938 -1231.773193359375 145.895751953125 C -1231.58447265625 145.8013610839844 -1231.395751953125 145.8014831542969 -1231.207275390625 145.895751953125 C -1229.603149414062 147.0279541015625 -1227.339233398438 147.1222839355469 -1225.640869140625 145.895751953125 C -1225.4521484375 145.8014221191406 -1225.263549804688 145.8014221191406 -1225.074829101562 145.895751953125 C -1222.90478515625 147.4053344726562 -1222.244384765625 150.2357482910156 -1222.244384765625 150.2357482910156 C -1222.244384765625 150.2357482910156 -1221.678466796875 152.5944519042969 -1224.036865234375 152.5944519042969 Z"
              fill="#004dbc"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
