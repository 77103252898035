export const ListIcon = () => {
  return (
    <svg viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
      <g id="list" transform="translate(-2.5 -3)">
        <circle
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 1534"
          fill="#18c678"
          id="Ellipse_1534"
          r="2.5"
          transform="translate(2.5 3)"
        />
        <path
          d="M12.5,6h14"
          data-name="Path 3147"
          fill="none"
          id="Path_3147"
          stroke="#18c678"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 1535"
          fill="#18c678"
          id="Ellipse_1535"
          r="2.5"
          transform="translate(2.5 12)"
        />
        <path
          d="M12.5,15h14"
          data-name="Path 3148"
          fill="none"
          id="Path_3148"
          stroke="#18c678"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 1536"
          fill="#18c678"
          id="Ellipse_1536"
          r="2.5"
          transform="translate(2.5 21)"
        />
        <path
          d="M12.5,24h14"
          data-name="Path 3149"
          fill="none"
          id="Path_3149"
          stroke="#18c678"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
