import classNames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';

import { Flex } from '@/lib/v2/components';

const TableBar = ({ children }: { children: JSX.Element }) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const cachedRef = stickyRef.current;
    if (!cachedRef) return;

    const observer = new IntersectionObserver(([e]) => setIsSticky(e.boundingClientRect.top <= 0), {
      threshold: [1],
    });

    observer.observe(cachedRef);

    const handleScroll = () => {
      const stickyTop = cachedRef.getBoundingClientRect().top;
      setIsSticky(stickyTop <= 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.unobserve(cachedRef);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const classesSticky = classNames('sticky top-0 z-[2] bg-white transition-all', {
    '!h-16': !isSticky,
    '!h-14': isSticky,
  });

  return (
    <Flex ref={stickyRef} alignment="start" className={classesSticky}>
      {children}
    </Flex>
  );
};

export default memo(TableBar);
