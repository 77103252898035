export const YoutubeIcon = () => {
  return (
    <svg height="48.125" viewBox="0 0 70 48.125" width="70" xmlns="http://www.w3.org/2000/svg">
      <g id="youtube" transform="translate(0 -3)">
        <path
          d="M67.025,7.637c-1.9-3.378-3.959-4-8.155-4.235C54.679,3.118,44.139,3,35.009,3c-9.148,0-19.692.118-23.879.4-4.187.241-6.252.857-8.168,4.239C1.006,11.011,0,16.821,0,27.049v.035C0,37.269,1.006,43.123,2.962,46.461c1.916,3.378,3.977,3.99,8.164,4.274,4.191.245,14.735.389,23.883.389,9.131,0,19.67-.144,23.866-.385,4.2-.284,6.256-.9,8.155-4.274C69,43.127,70,37.274,70,27.089v-.035c0-10.233-1-16.043-2.975-19.416Z"
          data-name="Path 3446"
          fill="#f44336"
          fillRule="evenodd"
          id="Path_3446"
        />
        <path
          d="M6,31.75V5.5L27.875,18.625Z"
          data-name="Path 3447"
          fill="#fafafa"
          fillRule="evenodd"
          id="Path_3447"
          transform="translate(20.25 8.438)"
        />
      </g>
    </svg>
  );
};
