export const SadFace = () => {
  return (
    <svg height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2,20.25a7.068,7.068,0,0,0-6.388,4H23.589A7.068,7.068,0,0,0,17.2,20.25Z"
        fill="#fc644d"
        transform="translate(-4.202 -5.25)"
      />
      <path
        d="M16.375,3.375a13,13,0,1,0,13,13A13,13,0,0,0,16.375,3.375Zm0,23.4a10.4,10.4,0,1,1,10.4-10.4A10.431,10.431,0,0,1,16.375,26.775Z"
        fill="#fc644d"
        transform="translate(-3.375 -3.375)"
      />
      <path
        d="M22.875,16.05a1.95,1.95,0,1,0-1.95-1.95A1.915,1.915,0,0,0,22.875,16.05Z"
        fill="#fc644d"
        transform="translate(-5.325 -4.35)"
      />
      <path
        d="M12.637,16.05a1.95,1.95,0,1,0-1.95-1.95A1.915,1.915,0,0,0,12.637,16.05Z"
        fill="#fc644d"
        transform="translate(-4.187 -4.35)"
      />
    </svg>
  );
};
