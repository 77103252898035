export const UsersIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7400" id="Group_7400" transform="translate(0 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0 -0.163)"
          width="20"
        />
        <g data-name="Group 6604" id="Group_6604" transform="translate(0.959 0.837)">
          <g data-name="Group 6567" id="Group_6567" transform="translate(11.799 13.724)">
            <path
              d="M-1225.1,99.2a4.6,4.6,0,0,0,.284.663h0C-1224.911,99.673-1225.005,99.389-1225.1,99.2Z"
              data-name="Path 3928"
              fill="#004dbc"
              id="Path_3928"
              transform="translate(1225.1 -99.2)"
            />
          </g>
          <g data-name="Group 6568" id="Group_6568" transform="translate(8.865 10.695)">
            <path
              d="M-1228.2,96Z"
              data-name="Path 3929"
              fill="#004dbc"
              id="Path_3929"
              transform="translate(1228.2 -96)"
            />
          </g>
          <g data-name="Group 6569" id="Group_6569" transform="translate(9.149 10.6)">
            <path
              d="M-1227.9,95.9a.348.348,0,0,1,.284.095.348.348,0,0,0-.284-.095Z"
              data-name="Path 3930"
              fill="#004dbc"
              id="Path_3930"
              transform="translate(1227.9 -95.9)"
            />
          </g>
          <g data-name="Group 6570" id="Group_6570" transform="translate(9.811 7.691)">
            <path
              d="M-1219.155,97.155A7.605,7.605,0,0,0-1221.9,92.9a.6.6,0,0,0-.568,0,4.633,4.633,0,0,1-3.975.663,5.364,5.364,0,0,1-.757,1.23c.095,0,.095.095.189.095a8.157,8.157,0,0,1,3.029,4.448h3.123C-1218.587,99.521-1219.155,97.155-1219.155,97.155Z"
              data-name="Path 3931"
              fill="#004dbc"
              id="Path_3931"
              transform="translate(1227.2 -92.825)"
            />
          </g>
          <path
            d="M-1225.3,84.7a3.941,3.941,0,0,0-3.6,2.366,4.717,4.717,0,0,1,2.745,4.259,3.217,3.217,0,0,1-.095.946,2.918,2.918,0,0,0,.947.095,3.838,3.838,0,0,0,3.88-3.88A3.817,3.817,0,0,0-1225.3,84.7Z"
            data-name="Path 3932"
            fill="#004dbc"
            id="Path_3932"
            transform="translate(1237.103 -84.7)"
          />
          <g data-name="Group 6573" id="Group_6573" transform="translate(2.24 2.84)">
            <circle
              cx="3.88"
              cy="3.88"
              data-name="Ellipse 1742"
              fill="#004dbc"
              id="Ellipse_1742"
              r="3.88"
              transform="translate(0 0)"
            />
          </g>
          <g data-name="Group 6574" id="Group_6574" transform="translate(0 10.53)">
            <path
              d="M-1225.389,100.155a7.606,7.606,0,0,0-2.745-4.259.6.6,0,0,0-.568,0,4.847,4.847,0,0,1-5.49,0,.6.6,0,0,0-.568,0,7.326,7.326,0,0,0-2.745,4.259s-.568,2.271,1.7,2.271h8.707C-1224.821,102.426-1225.389,100.155-1225.389,100.155Z"
              data-name="Path 3933"
              fill="#004dbc"
              id="Path_3933"
              transform="translate(1237.567 -95.825)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
