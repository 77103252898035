import { useEffect, useState } from 'react';

import { TableContact } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export const useContactInfo = (
  emailId: number,
  lastRefresh?: number
): [TableContact | undefined, Error | undefined, boolean | undefined] => {
  const [contactInfoValue, error, contactInfoValueIsLoading] = useEmblue(
    ServiceMethods.getContactInfo,
    { emailId, lastRefresh }
  );

  return [contactInfoValue?.contactInfo, error, contactInfoValueIsLoading];
};
