import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import {
  IExecutedEvent,
  IExecutedTrigger,
} from '@/src/infrastructure/interfaces/IAutomation.interface';
import { IFlowData } from '@/src/modules/AutomationModule/types/flows';

import {
  IAutomationEvent,
  IEventsRowTable,
  IFilterHistorical,
  IPager,
  ITotalsEvents,
} from '@/modules/AutomationModule/types/events';

export const atomEvents = atomWithReset<IEventsRowTable[]>([]);

export const atomExecutedEvents = atomWithReset<IAutomationEvent[]>([]);

export const atomPagerEvents = atomWithReset<IPager>({
  page: 1,
  limit: 10,
});
atomPagerEvents.debugLabel = 'atomPagerEvents';

export const atomEventsSearch = atomWithReset('');

export const atomEventsTotalsProcess = atomWithReset<ITotalsEvents>({ total: 0 });
atomEventsTotalsProcess.debugLabel = 'atomEventsTotalsProcess';

export const atomEventsLoadingProcess = atomWithReset<boolean>(true);
atomEventsLoadingProcess.debugLabel = 'atomEventsLoadingProcess';

export const atomSelectedRows = atom<number[]>([]);
atomSelectedRows.debugLabel = 'atomSelectedRows';

export const atomFilterType = atomWithReset<number[]>([]);
atomFilterType.debugLabel = 'atomFilterType';

export const atomFilterHistorical = atomWithReset<null | IFilterHistorical>(null);

export const atomShowDetailEventsExecution = atomWithReset<boolean>(false);
export const atomItemDetailEventsExecution = atomWithReset<IAutomationEvent | IFlowData | null>(
  null
);
