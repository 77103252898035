import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { Column } from 'react-table';

import { Table, TableSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { ChangeTablePage } from '@/lib/components/Table/useTablePagination';
import { Button, Flex } from '@/lib/v2/components';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import noInvoice from '@/src/assets/lottiefiles/no-invoices.json';
import { Billing } from '@/src/modules/MyPlanModule/types';
import { numberToCurrency } from '@/src/utils/currency';
import { convertDate } from '@/src/utils/Date/dateUtils';

import { useInvoiceData } from '@/modules/MyPlanModule/hooks/useInvoiceData';
import { InvoiceIcon } from '@/modules/MyPlanModule/images/InvoiceIcon';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: noInvoice,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface ConsumptionTableProps {
  billingList?: Billing[];
  billingListIsLoading: boolean;
  totalBillingList: number;
  totalPages: number;
  changeTablePage: ChangeTablePage;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
}

const BillingTable = ({
  billingListIsLoading,
  changeTableOrder,
  changeTablePage,
  totalBillingList,
  totalPages,
  billingList,
}: ConsumptionTableProps) => {
  const { t } = useTranslation();
  const { openInvoiceNewTab } = useInvoiceData();

  const initialStateOrderByList = [
    { id: 'period', isAsc: true },
    { id: 'totalBillingAmount', isAsc: true },
    { id: 'planName', isAsc: true },
  ];

  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('MY_PLAN.CONSUMPTION_TABLE.date')}</span>
        <SortTableArrow
          elementName={'period'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'period',
    id: 'period',
    width: '25%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <div className="flex">
        <span className="grow-0 self-start text-14 text-[#364365]">{convertDate(value)}</span>
      </div>
    ),
  };

  const amountColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('MY_PLAN.CONSUMPTION_TABLE.amount')}</span>
        <SortTableArrow
          elementName={'totalBillingAmount'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'totalBillingAmount',
    id: 'totalBillingAmount',
    width: '80%',
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: Billing } };
    }) => {
      return (
        <Flex withGap alignment="start">
          <span className="self-start text-14 text-[#364365]">
            {`${numberToCurrency(original.totalBillingAmount, false)}`}
          </span>
        </Flex>
      );
    },
  };

  const planColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('MY_PLAN.CONSUMPTION_TABLE.plan')}</span>
        <SortTableArrow
          elementName={'planName'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'planName',
    id: 'planName',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{value}</span>
      </Flex>
    ),
  };

  const invoiceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-[#004DBC]">{t('MY_PLAN.CONSUMPTION_TABLE.invoice')}</span>
      </Flex>
    ),
    accessor: 'period',
    id: 'invoice',
    width: '20%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <Button
          outline
          standard
          iconLeft={<InvoiceIcon />}
          onClick={() => {
            void openInvoiceNewTab(value);
          }}
        ></Button>
      </Flex>
    ),
  };

  const billingTableColumns: Column<object>[] = [
    dateColumn,
    planColumn,
    amountColumn,
    invoiceColumn,
  ].filter((x) => x) as Column<object>[];

  if (!billingList) {
    return (
      <div className="size-full py-10">
        <TableSkeleton />
      </div>
    );
  }

  return (
    <Table
      isPaginateOnOverflow
      classNamePagination="pr-4"
      columns={billingTableColumns || []}
      data={billingList}
      emptyScreen={{
        totalCount: totalBillingList || 0,
        noResult: <EmptyScreenTable title={t('EMPTY_SCREEN_TABLE.emptyNoteTitle')} />,
        noData: (
          <EmptyScreenTable
            image={<Lottie options={defaultOptions} width={150} />}
            title={t('MY_PLAN.BILLING.noInvoice')}
          />
        ),
      }}
      isLoading={billingListIsLoading}
      withCheckbox={false}
      withPagination={{
        totalPages,
        fetchData: changeTablePage,
      }}
    />
  );
};

export default BillingTable;
