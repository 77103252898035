import {
  FieldsResponse,
  ISegment,
  ISegmentFilterID,
  ValuesSegmentsFiltersContactsResponse,
} from '@/src/modules/ContactsModule/types/Segments';

/* spell-checker: disable */
export const fieldsMock: FieldsResponse = {
  defaultFields: [
    {
      id: 1464440,
      name: 'names',
      type: 'string',
      metadata: {
        optionType: null,
        internalColumn: 'nombre',
        internalId: 1464440,
        internalNumber: 1,
        defaultField: true,
        fieldType: 'normal',
      },
      value: 'name',
    },
    {
      id: 1464441,
      name: 'lastNames',
      type: 'string',
      metadata: {
        optionType: null,
        internalColumn: 'apellido',
        internalId: 1464441,
        internalNumber: 2,
        defaultField: true,
        fieldType: 'normal',
      },
      value: 'lastName',
    },
  ],
  customFields: [
    {
      id: 1513244,
      name: 'CF text multiple opt',
      type: 'string',
      metadata: {
        optionType: 'combo',
        defaultValues: ['op1', 'op2', 'op3', 'op4'],
        internalColumn: 'CF text multiple opt',
        internalId: 1513244,
        internalNumber: 31,
        defaultField: false,
        fieldType: 'normal',
      },
      value: 'CF text multiple opt',
    },
  ],
  activityFields: [],
};

export const valuesMock: ValuesSegmentsFiltersContactsResponse = {
  userGroups: [['2383084', 'Grupo para primer envio', '1', '0', '0']],
  tags: [
    {
      quantityActivity: 0,
      amountFrequency: 0,
      quantityUsers: 0,
      layerId: 1,
      categoryName: null,
      color: null,
      containerId: null,
      content: null,
      contentType: null,
      name: 'Emblue test',
      orderDefault: null,
      tagCategoryId: null,
      tagId: 99612,
      lastUse: '/Date(-62135578800000-0500)/',
    },
    {
      quantityActivity: 0,
      amountFrequency: 0,
      quantityUsers: 0,
      layerId: 1,
      categoryName: null,
      color: null,
      containerId: null,
      content: null,
      contentType: null,
      name: 'embluers',
      orderDefault: null,
      tagCategoryId: null,
      tagId: 99125,
      lastUse: '/Date(-62135578800000-0500)/',
    },
  ],
  channels: [
    {
      channelTypeId: 1,
      description: 'Email',
    },
    {
      channelTypeId: 2,
      description: 'Push',
    },
    {
      channelTypeId: 3,
      description: 'SMS',
    },
  ],
  notAddressees: [
    ['2383075', 'Cuarentena'],
    ['2383076', 'Desuscripto'],
    ['2383078', 'Suspendidos'],
    ['2383079', 'Denunciantes'],
    ['2383080', 'Inseguros'],
    ['2383081', 'Lista negra'],
  ],
  listProfiles: [
    {
      Key: 'dominios',
      Value: [],
    },
    {
      Key: 'paises',
      Value: [
        {
          Key: 'AD',
          Value: 'Andorra ',
        },
        {
          Key: 'AE',
          Value: 'United Arab Emirates ',
        },
        {
          Key: 'AF',
          Value: 'Afghanistan ',
        },
        {
          Key: 'AG',
          Value: 'Antigua and Barbuda ',
        },
      ],
    },
    {
      Key: 'dispositivos',
      Value: [
        {
          Key: '1',
          Value: 'Escritorio',
        },
        {
          Key: '2',
          Value: 'Movil',
        },
        {
          Key: '3',
          Value: 'Tablet',
        },
      ],
    },
    {
      Key: 'sexo',
      Value: [
        {
          Key: 'M',
          Value: 'Masculino',
        },
        {
          Key: 'F',
          Value: 'Femenino',
        },
      ],
    },
    {
      Key: 'redes_sociales',
      Value: [
        {
          Key: '1',
          Value: 'Twitter',
        },
        {
          Key: '2',
          Value: 'Facebook',
        },
        {
          Key: '3',
          Value: 'LinkedIn',
        },
      ],
    },
  ],
  ecommerceGroups: [
    {
      id: 455274,
      groupName: 'Clientes',
      contacts: 26699,
      type: 'ecommerce',
    },
    {
      id: 455276,
      groupName: 'Compraron al menos una vez',
      contacts: 14943,
      type: 'ecommerce',
    },
    {
      id: 455278,
      groupName: 'Compraron en el último mes',
      contacts: 0,
      type: 'ecommerce',
    },
    {
      id: 455277,
      groupName: 'Compraron en los últimos 10 días',
      contacts: 0,
      type: 'ecommerce',
    },
    {
      id: 455284,
      groupName: 'Compraron: Accesorios',
      contacts: 3543,
      type: 'ecommerce',
    },
    {
      id: 455283,
      groupName: 'Compraron: Hombre',
      contacts: 13700,
      type: 'ecommerce',
    },
    {
      id: 455282,
      groupName: 'Compraron: Mujer',
      contacts: 11677,
      type: 'ecommerce',
    },
    {
      id: 455285,
      groupName: 'Compraron: Sale',
      contacts: 696,
      type: 'ecommerce',
    },
    {
      id: 2644811,
      groupName: 'Compraron: test',
      contacts: 0,
      type: 'ecommerce',
    },
    {
      id: 455275,
      groupName: 'Nunca Compraron',
      contacts: 12316,
      type: 'ecommerce',
    },
    {
      id: 455281,
      groupName: 'RFM HIGH',
      contacts: 5769,
      type: 'ecommerce',
    },
    {
      id: 455279,
      groupName: 'RFM LOW',
      contacts: 4419,
      type: 'ecommerce',
    },
    {
      id: 455280,
      groupName: 'RFM MED',
      contacts: 4755,
      type: 'ecommerce',
    },
  ],
};

export const SegmentFilterMock: ISegment = {
  id: 123,
  name: 'nuevo segmento',
  filters: [
    {
      key: '2',
      dataType: 'ID',
      condition: 'equal',
      operator: 'or',
      field: {
        source: 'relation',
        name: 'tags',
        id: 8,
      },
      values: ['99125'],
      daysOld: 90,
    } as ISegmentFilterID,
    {
      key: '33',
      dataType: 'ID',
      condition: 'equal',
      field: {
        source: 'relation',
        name: 'groups',
        id: 6,
      },
      operator: 'or',
      values: ['2383084'],
    } as ISegmentFilterID,
  ],
};
