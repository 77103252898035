export const TagPatternIcon = () => {
  return (
    <svg className="h-full w-full" viewBox="0 0 329.504 225.949" xmlns="http://www.w3.org/2000/svg">
      <g id="Group_8058" transform="translate(-129.131 -788.693)">
        <g id="Group_8057">
          <g id="tags" transform="translate(97.131 756.493)">
            <path
              d="M225.265,100.222h0l-62.4-62.8-.46-.46h-.164a12.515,12.515,0,0,0-8.905-3.648l-29.542-1.084-1.282-.033a13,13,0,0,0-9.1,3.878L104.5,45.016a12.98,12.98,0,0,1,8.544-3.319l1.249.033L143.8,42.814a12.593,12.593,0,0,1,8.938,3.648h.164l.46.46,62.37,62.8a9.283,9.283,0,0,1,0,12.783l9.53-9.5A9.315,9.315,0,0,0,225.265,100.222Z"
              fill="#004dbc"
              id="Path_4713"
              transform="translate(-48.676 0)"
            />
            <path
              d="M162.13,143.522h.1l-62.338-62.8-.427-.46h-.1a12.362,12.362,0,0,0-8.84-3.648L60.918,75.533,59.6,75.5a13.009,13.009,0,0,0-8.412,3.22c-.1.1-.23.2-.329.3l-.394.394L35.91,94.034A13.638,13.638,0,0,0,32,103.728v.033l.033,1.249,1.939,28.885v.624a13.907,13.907,0,0,0,3.155,7.229l1.676,1.61,61.22,61.91.92.92a8.957,8.957,0,0,0,12.257-.427l4.3-4.338a5.46,5.46,0,0,0,.46-.427l8.84-8.873a.577.577,0,0,0-.164.131l35.589-35.917A9.045,9.045,0,0,0,162.13,143.522ZM57.829,96.531a14.285,14.285,0,1,1-6.8,12.191A14.1,14.1,0,0,1,57.829,96.531Z"
              fill="#004dbc"
              id="Path_4714"
              transform="translate(0 -29.071)"
            />
          </g>
          <g id="Group_8056" transform="translate(-4027.586 -732.481)">
            <path
              d="M12800.106,11033.785v41.424l52.643-.391"
              fill="none"
              id="Path_4613"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-8511.168 -9389.652)"
            />
            <path
              d="M13178.769,11215.369l23.18-34.416h33.3"
              fill="none"
              id="Path_4614"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-8838.626 -9533.178)"
            />
            <path
              d="M13204.773,11365.746l27.047,18.309"
              fill="none"
              id="Path_4615"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-8865.231 -9679.607)"
            />
            <path
              d="M13431.031,11633.52h20.865s24.807-17.121,33.678-23.035"
              fill="none"
              id="Path_4616"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-9053.472 -9924.666)"
            />
            <path
              d="M13631.187,11214.605l20.738,34.539h16.846"
              fill="none"
              id="Path_4617"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-9233.922 -9568.609)"
            />
            <path
              d="M13653.916,11179.721h27.4l30.926-22.791"
              fill="none"
              id="Path_4618"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-9246.512 -9531.834)"
            />
            <path
              d="M14053.98,11208.168v50.279h-22.543"
              fill="none"
              id="Path_4619"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-9578.896 -9578.965)"
            />
            <path
              d="M13743.021,11648.916l22.619,17.182v39.1h-60.076"
              fill="none"
              id="Path_4620"
              stroke="#111827"
              strokeWidth="3"
              transform="translate(-9291.416 -9963.803)"
            />
            <g id="Group_7372" transform="translate(4316.392 1655.464)">
              <g id="Group_7361" transform="translate(0 0)">
                <g id="Group_7359" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#18c678"
                    id="Ellipse_1742"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#18c678"
                    id="Path_3933"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7373" transform="translate(4360.673 1681.427)">
              <g id="Group_7361-2" transform="translate(0 0)">
                <g id="Group_7359-2" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#11cffa"
                    id="Ellipse_1742-2"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360-2" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#11cffa"
                    id="Path_3933-2"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7374" transform="translate(4382.206 1619.104)">
              <g id="Group_7361-3" transform="translate(0 0)">
                <g id="Group_7359-3" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#18c678"
                    id="Ellipse_1742-3"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360-3" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#18c678"
                    id="Path_3933-3"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7375" transform="translate(4455.078 1598.174)">
              <g id="Group_7361-4" transform="translate(0 0)">
                <g id="Group_7359-4" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#11cffa"
                    id="Ellipse_1742-4"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360-4" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#11cffa"
                    id="Path_3933-4"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7376" transform="translate(4424.714 1653.911)">
              <g id="Group_7361-5" transform="translate(0 0)">
                <g id="Group_7359-5" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#18c678"
                    id="Ellipse_1742-5"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360-5" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#18c678"
                    id="Path_3933-5"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7377" transform="translate(4394.231 1710.763)">
              <g id="Group_7361-6" transform="translate(0 0)">
                <g id="Group_7359-6" transform="translate(5.699 0)">
                  <circle
                    cx="9.873"
                    cy="9.873"
                    fill="#18c678"
                    id="Ellipse_1742-6"
                    r="9.873"
                    transform="translate(0)"
                  />
                </g>
                <g id="Group_7360-6" transform="translate(0 19.564)">
                  <path
                    d="M-1206.584,106.842s-1.445-6.983-6.983-10.836a1.535,1.535,0,0,0-1.445,0,12.333,12.333,0,0,1-13.966,0,1.535,1.535,0,0,0-1.445,0c-5.538,3.612-6.983,10.836-6.983,10.836s-1.445,5.779,4.334,5.779h22.154C-1205.139,112.621-1206.584,106.842-1206.584,106.842Z"
                    fill="#18c678"
                    id="Path_3933-6"
                    transform="translate(1237.567 -95.825)"
                  />
                </g>
              </g>
            </g>
            <g id="csv-svgrepo-com" transform="translate(4243.322 1528.671)">
              <path
                d="M170.645,160.789l-7.322-82.876H119.1v75.669Z"
                fill="#fff"
                id="Path_4101"
                transform="translate(-119.096 -77.913)"
              />
              <path
                d="M300.125,153.582V103.136L282.109,95.93,274.9,77.913H255.711l.115,82.876Z"
                fill="#e8e6e6"
                id="Path_4102"
                transform="translate(-211.484 -77.913)"
              />
              <path
                d="M314.991,103.136V77.913l25.223,25.223Z"
                fill="#fff"
                id="Path_4103"
                transform="translate(-251.573 -77.913)"
              />
              <rect
                fill="#b3da73"
                height="39.636"
                id="Rectangle_4223"
                transform="translate(0 75.669)"
                width="88.642"
              />
            </g>
          </g>
        </g>
        <path
          d="M14.966-14.807H9.521V0H5.859V-14.807H.488v-2.966H14.966ZM25.623-3.662H19.2L17.981,0H14.087L20.7-17.773H24.1L30.75,0H26.855ZM20.19-6.628h4.443L22.4-13.281ZM46.216-2.246a6.356,6.356,0,0,1-2.8,1.837,11.707,11.707,0,0,1-4,.653A7.932,7.932,0,0,1,35.37-.763a6.717,6.717,0,0,1-2.686-2.924,10.29,10.29,0,0,1-.97-4.5V-9.4a10.928,10.928,0,0,1,.9-4.608A6.637,6.637,0,0,1,35.2-16.986a7.454,7.454,0,0,1,3.961-1.031A7.424,7.424,0,0,1,44.1-16.51a6.443,6.443,0,0,1,2.112,4.388H42.651a3.544,3.544,0,0,0-1.08-2.234,3.45,3.45,0,0,0-2.3-.708,3.247,3.247,0,0,0-2.844,1.4,7.382,7.382,0,0,0-.989,4.175V-8.35A7.027,7.027,0,0,0,36.5-4.126,3.637,3.637,0,0,0,39.612-2.7a4.062,4.062,0,0,0,2.942-.879V-6.641H39.221v-2.7h6.995Z"
          fill="#fff"
          id="Path_4715"
          transform="translate(237 899)"
        />
      </g>
    </svg>
  );
};
