import { SmsCountries } from '@/src/infrastructure/interfaces/IResponses';

export const MY_PLAN_PATHS = {
  MODULE_PATH: 'my_plan',
  BILLING_AND_CONSUMPTION_PATH: 'billing_and_consumption',
  MANAGE_PLAN: 'manage_plan',
};

export const CONTACT_US_URL = 'https://www.embluemail.com/landing/activacion/';

export const BILLING_FORM_DATA_KEY = 'billingFromData';

export const smsPriceByCountryIdMap: Map<number, SmsCountries> = new Map<number, SmsCountries>([
  [1, 'ARG'],
  [221, 'URU'],
  [28, 'BRA'],
  [164, 'PER'],
  [27, 'BOL'],
  [43, 'CHI'],
  [146, 'MEX'],
  [59, 'ECU'],
  [175, 'PAR'],
  [220, 'USA'],
  // [, 'America Central'], this is the default
  [46, 'COL'],
  [197, 'SAL'],
  [84, 'GT'],
  [163, 'PAN'],
]);

/** cspell:disable */
export const OLD_PLANS = {
  CUSTOM_MADE: 'A Medida',
  PROFESSIONAL: 'Professional',
  STANDARD: 'Standard',
  ENTERPRICE: 'Enterprise',
};
