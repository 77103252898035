import { IconProps } from './iconPropTypes';

export const ChannelsTableIcon = ({ color }: IconProps) => {
  return (
    <svg
      height="18.118"
      viewBox="0 0 20.906 18.118"
      width="20.906"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.181,11.362h-2.7a1.447,1.447,0,0,0-1.062.468A1.568,1.568,0,0,0,1,12.9v4.615a1.57,1.57,0,0,0,.415,1.068,1.447,1.447,0,0,0,1.062.468h1.1l4.09,2A.7.7,0,0,0,8.63,20.2l-.39-1.168,2.432.015a1.447,1.447,0,0,0,1.062-.468,1.57,1.57,0,0,0,.415-1.068v-3.68h1.923L13.27,16a.7.7,0,0,0,1.033.826l4.608-2.992h1.071a1.909,1.909,0,0,0,1.367-.579,1.975,1.975,0,0,0,.557-1.376V4.955a1.975,1.975,0,0,0-.557-1.376A1.909,1.909,0,0,0,19.982,3H7.1a1.909,1.909,0,0,0-1.367.579,1.975,1.975,0,0,0-.557,1.376ZM10.756,12.9v4.615a.174.174,0,0,1-.042.118.064.064,0,0,1-.038.023l-3.4-.02a.7.7,0,0,0-.666.917l.172.515L4.04,17.725a.7.7,0,0,0-.306-.07H2.477a.06.06,0,0,1-.042-.024.174.174,0,0,1-.042-.118V12.9a.174.174,0,0,1,.042-.118.06.06,0,0,1,.042-.024h8.2a.06.06,0,0,1,.042.024.174.174,0,0,1,.042.118Zm1.326-.459h2.991a.7.7,0,0,1,.654.939l-.42,1.134,3.018-1.96a.7.7,0,0,1,.38-.112h1.277a.515.515,0,0,0,.368-.158.577.577,0,0,0,.162-.4V4.955a.576.576,0,0,0-.162-.4.515.515,0,0,0-.368-.158H7.1a.515.515,0,0,0-.368.158.576.576,0,0,0-.162.4v6.407h4.1a1.447,1.447,0,0,1,1.062.468A1.551,1.551,0,0,1,12.082,12.439Zm5.642-5.955a1.394,1.394,0,1,0,1.394,1.394A1.394,1.394,0,0,0,17.725,6.484Zm-4.181,0a1.394,1.394,0,1,0,1.394,1.394A1.394,1.394,0,0,0,13.543,6.484Zm-4.181,0a1.394,1.394,0,1,0,1.394,1.394A1.394,1.394,0,0,0,9.362,6.484Z"
        fill={`${color}`}
        fillRule="evenodd"
        id="conversation"
        transform="translate(-1 -3)"
      />
    </svg>
  );
};
