import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useState } from 'react';
import { UseControllerProps, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'react-phone-number-input';

import { Button, Checkbox, RadioButton, Text, toast } from '@/lib/v2/components';

import { geolocationES } from '@/src/ContactsModule/utils/Geolocation';
import { StepThreeBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomDealResponseIds,
  atomFormStepOne,
  atomFormStepTwo,
  atomSignUpCurrentStep,
  atomStepCompleted,
} from '@/src/modules/AuthModule/atoms/SignUp';
import { TERMS_URL } from '@/src/modules/AuthModule/constants';

interface FormStepThree {
  contact: string;
  terms: boolean;
}

const SignUpFormThree = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const setCurrentStep = useSetAtom(atomSignUpCurrentStep);
  const setStepCompleted = useSetAtom(atomStepCompleted);
  const formDataOne = useAtomValue(atomFormStepOne);
  const formDataTwo = useAtomValue(atomFormStepTwo);
  const dealResponseIds = useAtomValue(atomDealResponseIds);

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    watch,
  } = useForm<FormStepThree>({ mode: 'onChange', reValidateMode: 'onChange', delayError: 500 });

  const rules: Record<keyof FormStepThree, UseControllerProps['rules']> = {
    contact: {
      required: t('SignUpFormThree.groupStepThree'),
    },
    terms: {
      required: t('SignUpFormThree.terms'),
    },
  };

  const { contact, terms } = watch();

  const service = useService();

  const handleFinishClick = async (data: FormStepThree) => {
    setIsLoading(true);

    const countryCode = parsePhoneNumber(formDataOne?.phone ?? '')?.country ?? 'AR';
    const countryName = geolocationES[countryCode as keyof typeof geolocationES];

    const body: StepThreeBody = {
      name: formDataOne?.name ?? '',
      lastName: formDataOne?.lastName ?? '',
      phone: formDataOne?.phone ?? '',
      email: formDataOne?.email ?? '',
      company: formDataTwo?.company ?? '',
      country: countryName ?? 'Argentina',
      countryCode,
      industry: formDataTwo?.industry.id as number,
      numberOfContacts: formDataTwo?.numberOfContacts.id as number,
      role: formDataTwo?.role ?? '',
      youUseEmblue: formDataTwo?.youUseEmblue.id as number,
      personId: dealResponseIds?.personId ?? 0,
      dealId: dealResponseIds?.dealId ?? 0,
      orgId: dealResponseIds?.orgId ?? 0,
      contact: +data.contact,
      language: (language.toUpperCase() as 'ES' | 'EN' | 'PT') ?? 'ES',
    };

    const response = await service.setDeal(3, body);
    if (response) {
      setStepCompleted(0);
      setCurrentStep(0);
    } else {
      toast({
        body: t('SignUpFormThree.error'),
        variant: 'error',
        id: 'error',
        title: t('SignUpFormThree.errorTitle'),
      });
    }
    setIsLoading(false);
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleFinishClick)}>
      <Text className="mb-4" color="primary" fontWeight="medium" variant="headline">
        {t('SignUpFormThree.title')}
      </Text>
      <div className="flex flex-col gap-8">
        <RadioButton
          checked={contact === '578'}
          control={control}
          id="phone-number"
          label={t('SignUpFormThree.phone')}
          name="contact"
          rules={rules.contact}
          value="578"
        />
        <RadioButton
          checked={contact === '579'}
          control={control}
          id="email"
          label={t('SignUpFormThree.email')}
          name="contact"
          rules={rules.contact}
          value="579"
        />
        <RadioButton
          checked={contact === '580'}
          control={control}
          id="schedule"
          label={t('SignUpFormThree.schedule')}
          name="contact"
          rules={rules.contact}
          value="580"
        />
        <RadioButton
          checked={contact === '581'}
          control={control}
          id="whatsapp"
          label={t('SignUpFormThree.whatsapp')}
          name="contact"
          rules={rules.contact}
          value="581"
        />
        <RadioButton
          checked={contact === '582'}
          control={control}
          id="self-manage"
          label={t('SignUpFormThree.selfManage')}
          name="contact"
          rules={rules.contact}
          value="582"
        />
        {errors && (
          <Text color="alert" variant="text-sm">
            {errors?.contact?.message}
          </Text>
        )}
        <Checkbox
          checked={terms}
          control={control}
          id="terms"
          label={
            <Text isItalic variant="text">
              <Trans
                components={{
                  li: (
                    <Text
                      isItalic
                      link
                      color="primary"
                      fontWeight="medium"
                      variant="text"
                      onClick={() => window.open(TERMS_URL, '_blank')}
                    />
                  ),
                }}
                i18nKey="SignUpFormThree.termsCheck"
              />
            </Text>
          }
          name="terms"
          rules={rules.terms}
          value={terms ? 'yes' : 'no'}
        />
      </div>
      <Button fullWidth className="mt-6" disabled={!isValid} isLoading={isLoading} type="submit">
        {t('SignUpFormThree.finish')}
      </Button>
    </form>
  );
};

export default memo(SignUpFormThree);
