export const StatusCampaignIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 12 14" width="12">
      <path
        d="M8.875 5.22c.911.59 1.367.886 1.523 1.264.136.33.136.703 0 1.033-.156.377-.612.672-1.523 1.262L4.597 11.55c-1.01.655-1.516.982-1.933.947a1.255 1.255 0 0 1-.914-.525c-.25-.348-.25-.965-.25-2.201V4.229c0-1.235 0-1.853.25-2.201.217-.304.55-.495.914-.526.417-.034.922.293 1.933.948l4.278 2.77Z"
        stroke="#004DBC"
      />
    </svg>
  );
};
