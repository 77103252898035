export const ReportIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 12 14" width="12" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3256_4913)">
        <path
          clipRule="evenodd"
          d="M1.75 0H4C4.66304 0 5.29893 0.263392 5.76777 0.732233C6.23661 1.20107 6.5 1.83696 6.5 2.5V3.75C6.5 4.44067 7.06 5 7.75 5H9C9.66304 5 10.2989 5.26339 10.7678 5.73223C11.2366 6.20107 11.5 6.83696 11.5 7.5V12.75C11.5 13.44 10.94 14 10.25 14H1.75C1.41848 14 1.10054 13.8683 0.866117 13.6339C0.631696 13.3995 0.5 13.0815 0.5 12.75V1.25C0.5 0.559333 1.06 0 1.75 0ZM4.5 10.5C4.5 10.3674 4.44732 10.2402 4.35355 10.1464C4.25979 10.0527 4.13261 10 4 10C3.86739 10 3.74022 10.0527 3.64645 10.1464C3.55268 10.2402 3.5 10.3674 3.5 10.5V11C3.5 11.1326 3.55268 11.2598 3.64645 11.3536C3.74022 11.4473 3.86739 11.5 4 11.5C4.13261 11.5 4.25979 11.4473 4.35355 11.3536C4.44732 11.2598 4.5 11.1326 4.5 11V10.5ZM6 8.5C6.13261 8.5 6.25979 8.55268 6.35355 8.64645C6.44732 8.74022 6.5 8.86739 6.5 9V11C6.5 11.1326 6.44732 11.2598 6.35355 11.3536C6.25979 11.4473 6.13261 11.5 6 11.5C5.86739 11.5 5.74022 11.4473 5.64645 11.3536C5.55268 11.2598 5.5 11.1326 5.5 11V9C5.5 8.86739 5.55268 8.74022 5.64645 8.64645C5.74022 8.55268 5.86739 8.5 6 8.5ZM8.5 7.5C8.5 7.36739 8.44732 7.24022 8.35355 7.14645C8.25979 7.05268 8.13261 7 8 7C7.86739 7 7.74022 7.05268 7.64645 7.14645C7.55268 7.24022 7.5 7.36739 7.5 7.5V11C7.5 11.1326 7.55268 11.2598 7.64645 11.3536C7.74022 11.4473 7.86739 11.5 8 11.5C8.13261 11.5 8.25979 11.4473 8.35355 11.3536C8.44732 11.2598 8.5 11.1326 8.5 11V7.5Z"
          fill="#0056B8"
          fillRule="evenodd"
        />
        <path
          d="M7.50013 2.5C7.5013 1.65903 7.19847 0.845972 6.64746 0.210663C7.76299 0.503972 8.7806 1.08831 9.5962 1.90392C10.4118 2.71953 10.9962 3.73714 11.2895 4.85266C10.6542 4.30165 9.8411 3.99883 9.00013 4H7.75013C7.68382 4 7.62024 3.97366 7.57335 3.92677C7.52647 3.87989 7.50013 3.8163 7.50013 3.75V2.5Z"
          fill="#0056B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3256_4913">
          <rect fill="white" height="14" width="12" />
        </clipPath>
      </defs>
    </svg>
  );
};
