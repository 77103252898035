import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { setCookieFor1Day, setCookieFor30Days } from '@/src/compat/util';
import { useMfa } from '@/src/modules/AuthModule/hooks/useMfa';

import GetCode from './GetCode';
import GetPhoneNumber from './GetPhoneNumber';
import TwoStepAuthenticationLoading from './TwoStepAuthenticationLoading';

type StepTwoFactor = 'getPhoneNumber' | 'getCode';

const TwoStepAuthentication = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const [sendingPhoneVerification, setSendingPhoneVerification] = useState(false);
  const [step, setStep] = useState<StepTwoFactor>('getPhoneNumber');

  const {
    verifiedPhoneNumber,
    auditFilterIsLoading,
    auditFilterResult,
    phoneNumberIsLoading,
    sendSmsCode,
    verifySmsCode,
    sendCodeIsLoading,
  } = useMfa();

  const handleOnChangeNumber = useCallback((number?: string) => {
    setPhoneNumber(number ?? '');
  }, []);

  const handleNextClick = useCallback(
    async (phoneNumber: string) => {
      const response = await sendSmsCode(phoneNumber);
      if (response?.success) setStep('getCode');
    },
    [sendSmsCode]
  );

  const handleSkip = useCallback(() => {
    setCookieFor1Day('skippedMfa', 'true');
    window.location.href = '/v2';
  }, []);

  const handleResendCode = useCallback(() => {
    void sendSmsCode(phoneNumber);
  }, [phoneNumber, sendSmsCode]);

  const handleConfirmCode = useCallback(
    async (token: string) => {
      const code = await verifySmsCode(token, phoneNumber);
      if (code?.token) {
        setCookieFor30Days('mfaCookie', code.token);
        setErrorCode(false);
        window.location.href = '/v2';
      } else if (code?.mismatch) {
        toast({
          variant: 'error',
          title: t('AUTH.TWO_AUTHENTICATE.notification.error'),
          body: t('AUTH.TWO_AUTHENTICATE.notification.securityCheckContactSupport'),
        });
      } else {
        setErrorCode(true);
      }
    },
    [phoneNumber, t, verifySmsCode]
  );

  useEffect(() => {
    // const verifiedPhoneNumberFake = false;
    if (!phoneNumberIsLoading && verifiedPhoneNumber) {
      setStep('getCode');
      if (!sendingPhoneVerification) {
        setSendingPhoneVerification(true);
        void sendSmsCode()
          .catch(() => {})
          .finally(() => {
            setSendingPhoneVerification(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberIsLoading, auditFilterIsLoading, verifiedPhoneNumber, sendSmsCode]);

  if (phoneNumberIsLoading || auditFilterIsLoading || !auditFilterResult?.country) {
    return <TwoStepAuthenticationLoading />;
  }

  return (
    <div className="flex w-full flex-col items-center gap-4 py-4 sm:w-[300px]">
      {step === 'getPhoneNumber' && (
        <GetPhoneNumber
          canSkip={auditFilterResult?.success}
          defaultCountry={auditFilterResult?.country}
          isLoading={sendCodeIsLoading}
          phoneNumber={phoneNumber}
          onChange={handleOnChangeNumber}
          onNext={handleNextClick}
          onSkip={handleSkip}
        />
      )}
      {step === 'getCode' && (
        <GetCode
          canSkip={auditFilterResult?.success}
          hasError={errorCode}
          isLoading={sendCodeIsLoading}
          onConfirm={handleConfirmCode}
          onResendCode={handleResendCode}
          onSkip={handleSkip}
        />
      )}
    </div>
  );
};

export default TwoStepAuthentication;
