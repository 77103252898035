export const BlockModal = ({ children }: { children?: JSX.Element[] | JSX.Element | string }) => (
  <div className="fixed bottom-0 left-0 z-[999] flex h-1/2 max-h-72 w-full items-center justify-center opacity-100">
    {children}
  </div>
);

BlockModal.Title = ({ children }: { children?: JSX.Element[] | JSX.Element | string }) => (
  <h1 className="text-32 font-bold">{children}</h1>
);

BlockModal.Body = ({ children }: { children?: JSX.Element[] | JSX.Element | string }) => (
  <div className="flex h-full w-2/3 flex-col space-y-5 bg-white">{children}</div>
);

BlockModal.SideBar = ({ children }: { children?: JSX.Element[] | JSX.Element | string }) => (
  <div className="flex h-full w-1/3 flex-col items-center space-y-2 bg-[#F9FAFB] pt-10">
    {children}
  </div>
);
