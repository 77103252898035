import { ISegmentTableRow } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { ISegmentsEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export const useSegmentList = ({
  identifier,
  page,
  pageSize,
  search,
  orderColumn,
  orderDirection,
  lastRefresh,
  segmentTypes,
}: ISegmentsEmblueService): [
  { list: { segment: ISegmentTableRow }[] } | undefined,
  Error | undefined,
  boolean
] => {
  const [segmentListValue, error, isLoading] = useEmblue(ServiceMethods.getSegments, {
    identifier,
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection: orderDirection,
    lastRefresh,
    segmentTypes,
  });

  return [segmentListValue, error, isLoading];
};
