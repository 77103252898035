import { timeout } from '@/src/compat/util';

import { IHtmlEditorService } from './IHtmlEditorService';

import {
  IGetElementHtml,
  ISendElement,
  NpsWidgets,
} from '@/modules/CampaignsModule/interfaces/HTMLEditor';

export class StubHtmlEditorService implements IHtmlEditorService {
  static create(): IHtmlEditorService {
    return new StubHtmlEditorService();
  }

  /**
   * @desc Update a email template
   */
  async updateElementHtml(sendElement: ISendElement): Promise<{ status: number } | null> {
    try {
      await timeout(2000);
      return { status: 200 };
    } catch (error) {
      console.log('Error saving HTML:', error);
      return null;
    }
  }

  /**
   * @desc Get a email template of html editor
   */
  async getElementHtml(actionId: number): Promise<IGetElementHtml | null> {
    try {
      await timeout(2000);
      return { result: '<html><head></head></html>' };
    } catch (error) {
      return null;
    }
  }

  /**
   * @desc Get HTML editor
   */
  async getUrlAndUpdateElementHtml(): Promise<{ status: number } | null> {
    try {
      await timeout(2000);
      return { status: 200 };
    } catch (error) {
      console.log('Error saving HTML:', error);
      return null;
    }
  }

  /**
   * @desc Get widgets list
   */
  async getWidgetsList(): Promise<NpsWidgets[]> {
    try {
      await timeout(2000);
      return [
        {
          id: 1,
          name: 'test',
          title: 'title',
          description: 'description',
          imageUrl: 'imageUrl',
          views: 2,
          actions: 1,
          ctr: 3,
          creationDays: 1,
          status: 'status',
          countPartial: 1,
        },
      ];
    } catch (error) {
      return [];
    }
  }

  /**
   * @desc Generate widget token
   */
  async generateWidgetToken(): Promise<string> {
    try {
      await timeout(2000);
      return 'token';
    } catch (error) {
      return '';
    }
  }
}
