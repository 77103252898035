export const GenderIcon = () => {
  return (
    <svg height="16" viewBox="0 0 15.026 16" width="15.026" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.538,13.331V14.6H9.056v.993H7.538V17H6.545V15.593H5.083V14.6H6.518V13.414c0-.055-.138-.138-.221-.166A4.045,4.045,0,0,1,2.932,9.993,4.212,4.212,0,0,1,6.876,4.917a.582.582,0,0,0,.552-.3,4.208,4.208,0,0,1,6.345-.772.936.936,0,0,1,.166.166c.69-.662,1.379-1.324,2.124-2.014H15.014V1h2.841V3.759H16.89V2.545c-.828.772-1.572,1.49-2.345,2.234a4.266,4.266,0,0,1,.579,2.455,4.214,4.214,0,0,1-3.752,3.89,1.1,1.1,0,0,0-.966.5,3.92,3.92,0,0,1-2.648,1.628C7.676,13.276,7.621,13.3,7.538,13.331Zm.772-8.248a4.307,4.307,0,0,1,2.841,5.076,3.2,3.2,0,0,0,2.924-2.676,3.252,3.252,0,0,0-2.069-3.559A3.146,3.146,0,0,0,8.311,5.083Zm1.352,5.9a4.141,4.141,0,0,1-2.4-1.959,4.235,4.235,0,0,1-.441-3.062,3.136,3.136,0,0,0-2.9,2.4A3.21,3.21,0,0,0,5.745,12.09,3.244,3.244,0,0,0,9.663,10.986ZM7.842,6.021A3.172,3.172,0,0,0,10.1,10.048,3.177,3.177,0,0,0,7.842,6.021Z"
        data-name="Path 3204"
        fill="#005adc"
        id="Path_3204"
        transform="translate(-2.829 -1)"
      />
    </svg>
  );
};
