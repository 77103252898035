/* cSpell:disable */

type DateIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const DateIcon = ({ tableSize, stroked = true }: DateIconProps) => {
  return (
    <>
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 6142" id="Grupo_6142" transform="translate(-0.455)">
            <rect
              data-name="Rectángulo 3180"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3180"
              transform="translate(0.455)"
              width={tableSize ? 19 : 32}
            />
            <path
              d="M7.306,6.444V3m6.889,3.444V3M6.444,9.889h8.611M4.722,18.5H16.778A1.722,1.722,0,0,0,18.5,16.778V6.444a1.722,1.722,0,0,0-1.722-1.722H4.722A1.722,1.722,0,0,0,3,6.444V16.778A1.722,1.722,0,0,0,4.722,18.5Z"
              data-name="Trazado 3166"
              fill="none"
              id="Trazado_3166"
              stroke="#004dbc"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(-0.045 -0.5)"
            />
          </g>
        </svg>
      )}
    </>
  );
};
