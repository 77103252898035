import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, InputSearch, Modal, Text } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import { SegmentsIcon } from '@/lib/v2/icons/solid';

import { SegmentsCardsContainer } from './SegmentsCardsContainer';

type CreateEmblueSegmentProps = {
  isShow: boolean;
  FiltersParams?: string;
  onClose?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setCreateCustomSegment?: Dispatch<SetStateAction<boolean>>;
  lastRefreshIncrement?: () => void;
};

export const CreateEmblueSegmentModal = ({
  isShow = false,
  onClose,
  setCreateCustomSegment,
  lastRefreshIncrement,
  setIsLoading,
}: CreateEmblueSegmentProps) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');

  const handleOnSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const handleOnClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleOnCloseModal = useCallback(
    (value: boolean) => {
      onClose?.(value);
      handleOnClear();
    },
    [handleOnClear, onClose]
  );

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const parentElement = parentRef.current;
    const childElement = childRef.current;

    if (parentElement && childElement) {
      if (childElement.scrollHeight > parentElement.clientHeight) {
        childElement.classList.add('overflow-y-scroll');
      } else {
        childElement.classList.remove('overflow-y-scroll');
      }
    }
  });

  return (
    <Modal
      externalClose
      noPadding
      withAuto
      id={'segment-modal-create'}
      open={isShow}
      onClose={handleOnCloseModal}
    >
      <div className="h-[75vh] w-[80vw] px-16 py-[22px]">
        <Divide>
          <Flex
            ref={parentRef}
            column
            withGap
            alignment="start"
            gapSize="small"
            itemAlignment="stretch"
          >
            <div className="relative">
              <Flex column withGap gapSize="small" itemAlignment="start">
                <Flex withGap alignment="start" gapSize="small">
                  <IconSvg height={'24px'} svgComponent={<SegmentsIcon />} width={'24px'} />
                  <Text color="light-black" fontWeight="medium" variant="headline">
                    {t('CREATE_SEGMENT_MODAL.title')}
                  </Text>
                </Flex>
                <div className="w-[300px]">
                  <InputSearch
                    placeHolder={t('CREATE_SEGMENT_MODAL.inputSearch')}
                    onChange={handleOnSearch}
                    onClear={handleOnClear}
                  ></InputSearch>
                </div>
              </Flex>
            </div>
            <div ref={childRef} className="overflow-y-scroll py-6 pr-2">
              <SegmentsCardsContainer
                FiltersParams={searchValue}
                lastRefreshIncrement={lastRefreshIncrement}
                setCreateCustomSegment={setCreateCustomSegment}
                setIsLoading={setIsLoading}
                setShowModal={onClose}
              />
            </div>
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};
