import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BigColumnSkeleton, Button, SearchInputBar } from '@/lib/components';
import { DownArrowIcon } from '@/lib/icon/DownArrowIcon';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useFilterElement } from '@/src/application/hooks/useFilterElement';
import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

type DropdownProps = {
  searchPlaceHolder?: string;
  overflowWidth?: boolean;
  iconHead?: JSX.Element;
  titleHead?: string;
  checkBox?: boolean;
  subtitle?: string;
  search?: boolean;
  btnText?: string;
  wFull?: boolean;
  plainIcon?: boolean;
  plain?: boolean;
  fractionWidth?: boolean;
  tooltip?: string;
  totalSelectedChk?: number;
  children?: JSX.Element;
  btnOnAction?: (itemFilterList: string[]) => void;
  itemList?: ItemsListProps[];
  valuesSelected?: string[];
  id?: string;
};

export type ItemsListProps = {
  id: string;
  title: string;
  tooltip?: string;
  count?: number;
  value?: string;
  icon?: JSX.Element;
  state?: boolean;
  onAction?: () => void;
};

export interface ItemSelected {
  id: string;
  state: boolean;
}

export const DropDown = ({
  searchPlaceHolder = '',
  valuesSelected,
  totalSelectedChk,
  iconHead,
  titleHead,
  subtitle,
  search,
  checkBox,
  btnText,
  btnOnAction,
  itemList,
  overflowWidth,
  wFull,
  plain,
  children,
  fractionWidth,
  tooltip,
  plainIcon,
  id,
}: DropdownProps) => {
  const [searchItem, setItemGroup] = useState('');
  const [toggle, setToggle] = useState(false);
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(toggleRef, () => setToggle(false));
  const { resetFilter } = useResetSearch({ cleanBox: setItemGroup as () => void });

  const { t } = useTranslation();
  const setItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setItemGroup(value);
  };
  itemList && itemList.sort((e, b) => e.title.localeCompare(b.title, 'en', { numeric: true }));
  const [elementItems] = useFilterElement(itemList as [], valuesSelected as string[], searchItem);

  const [firstCheckedState, setFirstCheckedState] = useState<ItemsListProps[]>(elementItems);
  const [secondCheckedState, setSecondCheckedState] = useState<ItemsListProps[]>(elementItems);

  const [elementItemsFiltered] = useFilterElement(
    secondCheckedState,
    valuesSelected as string[],
    searchItem
  );

  const elementSort = (element: ItemsListProps[]) => {
    const sortedList =
      element &&
      [...element]
        ?.sort((e) => (e.state ? -1 : 1))
        ?.sort((a, b) => (a.state ? a.title.localeCompare((b.state && b.title) || '') : 1));

    return sortedList;
  };

  const listItemsCheckbox = elementSort(firstCheckedState);
  const listItemsNames = elementSort(elementItems);

  useEffect(() => {
    elementItems && setFirstCheckedState(elementItems);
    listItemsNames && setSecondCheckedState(listItemsNames);
    setListIds(valuesSelected as string[]);
  }, [toggle, searchItem, itemList]);

  const [listIds, setListIds] = useState<Array<string>>([]);

  const onChange = (itemId: string, oldItemStates: ItemsListProps[]) => {
    const updatedCheckedState = oldItemStates.map((item) => {
      item.state = item.id === itemId ? !item.state : item.state;
      return item;
    });
    const checkSelectedList: Array<string> = [...listIds];
    oldItemStates.map((item) => {
      if (item.id === itemId && !checkSelectedList.includes(itemId)) {
        checkSelectedList.push(itemId);
      } else if (item.id === itemId && checkSelectedList.includes(itemId)) {
        checkSelectedList.splice(checkSelectedList.indexOf(item.id), 1);
      }
      return item;
    });
    setFirstCheckedState(updatedCheckedState);
    setListIds(checkSelectedList);
  };

  useEffect(() => {
    listItemsCheckbox && setFirstCheckedState(listItemsCheckbox);
    listItemsNames && setSecondCheckedState(listItemsNames);
    resetFilter();
  }, [!toggle]);

  const onAction = () => {
    btnOnAction && btnOnAction(listIds);
    setToggle(!toggle);
  };

  return (
    <div
      ref={toggleRef}
      className={`max-h-xl inline-block h-[38px] text-left ${plain ? 'flex' : 'relative w-full'}`}
      data-testid="dropdown-component"
      id={String(id)}
    >
      <button
        className={`h-full ${wFull ? '' : 'w-full'}
                    px-4 py-1
                    ${plain ? 'shadow-none' : 'rounded-md border'}
                    text-sm border-gray-300 bg-white font-medium text-gray-700 shadow-sm hover:bg-gray-50
                    focus:outline-none focus:ring-2 focus:ring-[#004DBC] focus:ring-offset-2 focus:ring-offset-gray-100`}
        title={tooltip || ''}
        type="button"
        onClick={() => setToggle(!toggle)}
      >
        <Flex>
          <div className="pr-2">{iconHead && iconHead}</div>
          <div className="whitespace-nowrap text-14">
            {titleHead && titleHead}
            {totalSelectedChk! >= 1 ? <span className="pl-1">({totalSelectedChk})</span> : null}
          </div>
          {plainIcon || <DownArrowIcon color="black" />}
        </Flex>
      </button>
      {toggle && (
        <div
          className={`z-[1] origin-top-right ${overflowWidth ? 'w-fit max-w-[30rem]' : 'w-full'} ${
            fractionWidth ? 'w-3/4' : ''
          } absolute right-0 mt-2 ${
            !plain ? 'rounded-md' : ''
          } bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            plain ? 'mt-9' : ''
          } `}
          style={{ right: 'auto' }}
        >
          {elementItemsFiltered ? (
            <div className="w-full">
              {children && children}
              {search && (
                <section className="mb-2 w-full">
                  <SearchInputBar
                    cleanBox={resetFilter}
                    placeHolder={searchPlaceHolder}
                    value={searchItem}
                    onChange={setItem}
                  />
                </section>
              )}
              {subtitle && (
                <section className="box-border border-b-[1px] border-[#D3DDEA] pb-1">
                  <div className="w-fit pl-3 pt-1 text-12 uppercase text-[#D3DDEA] lg:text-12 xl:text-14 2xl:text-14">
                    {subtitle}
                  </div>
                </section>
              )}
              <section className={'visible-scrollbar max-h-60 min-w-200 overflow-y-auto pb-1'}>
                <div className="mt-2 divide-y">
                  <Flex column itemAlignment="stretch">
                    {elementItemsFiltered?.length
                      ? elementItemsFiltered?.map((item: ItemsListProps, i) => {
                          return (
                            <div
                              key={i}
                              className="h-[26px] py-4 lg:h-[28px] xl:h-[30px] 2xl:h-[32px]"
                              data-testid="dropdown-item-component"
                              id={String(id)}
                            >
                              <Flex alignment="start" itemAlignment="center">
                                {checkBox ? (
                                  <div className="pl-3">
                                    <Flex noGrow>
                                      <input
                                        checked={
                                          (secondCheckedState &&
                                            secondCheckedState[i] &&
                                            secondCheckedState[i].state) ??
                                          false
                                        }
                                        className="rounded border-gray-300 bg-gray-100 text-blue-600"
                                        type="checkbox"
                                        value={item.id}
                                        onChange={() => onChange(item.id, secondCheckedState)}
                                      />
                                      <div className="ml-2 flex w-fit gap-1 text-left text-14">
                                        {item.title && item.title}
                                        {item.count && <span>({item.count}) </span>}
                                      </div>
                                    </Flex>
                                  </div>
                                ) : (
                                  <span className="w-full text-14">
                                    <Flex>
                                      <button
                                        key={i}
                                        className="h-10 w-full cursor-pointer hover:bg-[#CCDEF8]"
                                        title={item.title}
                                        onClick={() => (
                                          item.onAction && item.onAction(), setToggle(!toggle)
                                        )}
                                      >
                                        <Flex alignment="start">
                                          {item.icon && item.icon}
                                          <div className="ml-2 w-fit text-14">
                                            {item.title && item.title}
                                          </div>
                                        </Flex>
                                      </button>
                                    </Flex>
                                  </span>
                                )}
                              </Flex>
                            </div>
                          );
                        })
                      : search && (
                          <div className="p-4 text-14">{t('CONTACTS_FILTERS.noResultsFound')}</div>
                        )}
                  </Flex>
                </div>
              </section>
              {btnText && btnOnAction && (
                <div className="pt-2">
                  <Flex withGap alignment="end" gapSize="small">
                    <Button onAction={onAction}>
                      <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">{btnText}</div>
                    </Button>
                  </Flex>
                </div>
              )}
            </div>
          ) : (
            <BigColumnSkeleton />
          )}
        </div>
      )}
    </div>
  );
};
