import { LoadingIcon } from '@/lib/v2/icons/animated';

const SearchLoader = () => {
  return (
    <div className="eb-card visible-scrollbar max-h-[220px] w-[532px] cursor-pointer flex-col overflow-y-auto bg-white py-2">
      <div className="flex items-center justify-center py-3">
        <LoadingIcon />
      </div>
    </div>
  );
};

export default SearchLoader;
