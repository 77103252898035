import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActionEmailData } from '@/src/modules/CampaignsModule/hooks/useActionEmailData';

import { ModalUpload } from './components/ModalUpload/ModalUpload';
import EditPage from './pages/EditPage';
import HeaderSection from './HeaderSection';

const HtmlEditor = memo(() => {
  const { actionId } = useParams();

  const { getEmailInfo } = useActionEmailData(Number(actionId));
  useEffect(() => void getEmailInfo(), [getEmailInfo]);

  return (
    <div className="min-h-[calc(100vh_-_64px)] bg-emblue-white">
      <HeaderSection />
      <div className="p-10">
        <EditPage />
      </div>
      <ModalUpload />
    </div>
  );
});

export default HtmlEditor;
