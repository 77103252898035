export const PrestashopStatusMap: { [key: string]: string } = {
  '1': 'waitingForPayment',
  '2': 'accepted',
  '3': 'inProgress',
  '4': 'shipped',
  '5': 'delivered',
  '6': 'cancelled',
  '7': 'refunded',
  '8': 'paymentError',
  '9': 'pendingOutOfStock',
  '10': 'waitingPaymentBankTransfer',
  '11': 'remotePaymentAccepted',
  '12': 'onBackOrder',
  '13': 'awaitingCashOnDeliveryValidation',
  '14': 'waitingForPaypalPayment',
  '15': 'waitingForCreditCardPayment',
  '16': 'waitingForLocalPayment',
  '17': 'AuthorizedToBeCaptureByMerchant',
  '18': 'PartialRefund',
  '19': 'waitingCapture',
};
