import Editor from '@monaco-editor/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, CopyToClipboard, Text } from '@/lib/v2/components';

import { useTruncateString } from '@/src/application/hooks/useTruncateString';

import { useStructureDataEvent } from '@/modules/AutomationModule/hooks/useStructureDataEvent';

const url = 'https://track.embluemail.com/contacts/event';

const StructureDataEvent = () => {
  const { t } = useTranslation();
  const { handleEditorWillMount, jsonEvent, apiKey } = useStructureDataEvent();

  const curl = useMemo(() => {
    return `curl --request POST --url ${url}
    --header 'Authorization: Basic ${apiKey}'
    --header 'Content-Type: application/json' --data
    '${JSON.stringify(jsonEvent)}'`;
  }, [apiKey, jsonEvent]);

  const apiKeyTruncate = useTruncateString(apiKey, 6);

  return (
    <div className="flex w-full flex-col gap-4 py-14 sm:w-2/5 sm:pl-14">
      <Alert description={t('StructureDataEvent.descriptionAlertInfo')} type="info" />
      <Text fontWeight="medium" variant="text">
        {t('StructureDataEvent.dataStructure')}
      </Text>
      <div className="flex w-full flex-col gap-4 rounded bg-[#002646] pb-2">
        <div className="mx-6 mt-6 flex justify-between">
          <Text color="white" variant="text">
            {`POST ${url}`}
          </Text>
          <CopyToClipboard color="light" id="copyCurl" value={curl} />
        </div>
        <Editor
          beforeMount={handleEditorWillMount}
          defaultLanguage="json"
          defaultValue={JSON.stringify(jsonEvent, null, 2)}
          height={'320px'}
          options={{
            readOnly: true,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            lineNumbers: 'off',
            contextmenu: false,
          }}
          theme="my-theme"
          value={JSON.stringify(jsonEvent, null, 2)}
          width="100%"
        />
      </div>
      <Text fontWeight="medium" variant="text">
        {t('StructureDataEvent.useJSON')}
      </Text>
      <div className="flex flex-col pl-4">
        <Text variant="text">Content-Type:</Text>
        <Text variant="text">application/json</Text>
      </div>
      <div className="flex flex-col pl-4">
        <Text variant="text">Authorization:</Text>
        <div className="flex w-2/3 gap-4">
          <Text variant="text">Basic {apiKeyTruncate}</Text>
          <CopyToClipboard id="copyApiKey" value={apiKey} />
        </div>
      </div>
    </div>
  );
};

export default memo(StructureDataEvent);
