import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { atomActionPathBeefreeNav } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  EChannelsID,
  ICampaignActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const useTemplates = () => {
  const navigate = useNavigate();
  const { actionType } = useParams();

  const [, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const setActionPathBeefreeNav = useSetAtom(atomActionPathBeefreeNav);

  const openTemplates = useCallback(
    (action: ICampaignActions | null) => {
      if (action === null) return;

      const { name, channel, actionId } = action;

      if (!actionId) return;

      setInfoCampaignV1({
        campaign: {
          id: Number(action.campaignId),
          name: action.campaignName ?? '',
          isAutomatic: channel === EChannelsID.MASTER,
        },
        action: {
          id: actionId,
          name: name,
        },
      });

      setActionPathBeefreeNav(actionType as ActionTypeName);
      navigate('/v2/campaigns/catalogs/email/' + (actionId ? actionId.toString() : ''));
    },
    [actionType, navigate, setActionPathBeefreeNav, setInfoCampaignV1]
  );

  return {
    openTemplates,
  };
};
