import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabItemProps } from '@/lib/v2/components/Tab/TabItem';
import { TabPaneProps } from '@/lib/v2/components/Tab/TabPane';

import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';
import { TemplatesContainer } from '@/src/modules/ContactsModule/components/SendWhatsAppModal/components/TemplatesContainer';

import {
  atomIsLoadingWhatsAppTemplates,
  atomWhatsAppTemplates,
} from '@/modules/ContactsModule/atoms/WhatsApp';

interface TabsPanesParams {
  searchTerm?: string;
  onClose?: () => void;
}

export const useTabsPanes = ({ searchTerm = '', onClose }: TabsPanesParams) => {
  const { t } = useTranslation('inbox');
  const inboxService = useInboxService();

  const setWhatsAppTemplates = useSetAtom(atomWhatsAppTemplates);

  const setIsLoadingTemplates = useSetAtom(atomIsLoadingWhatsAppTemplates);

  const getTemplates = useCallback(async () => {
    setIsLoadingTemplates(true);

    try {
      const templates = await inboxService.getWhatsAppTemplatesByAccount();

      setWhatsAppTemplates(templates || []);
    } catch (error) {
      console.error('Error to obtain WhatsApp templates:', error);
    } finally {
      setIsLoadingTemplates(false);
    }
  }, [inboxService, setWhatsAppTemplates, setIsLoadingTemplates]);

  const refetch = useCallback(() => {
    void getTemplates();
  }, [getTemplates]);

  const tabItems = useMemo<TabItemProps[]>(() => {
    return [
      {
        children: t('INBOX.SEND_WHATSAPP_MODAL.APPROVAL_STATUS.approved'),
        tabId: 1,
        id: 'approved-tab',
      },
      {
        children: t('INBOX.SEND_WHATSAPP_MODAL.APPROVAL_STATUS.pending'),
        tabId: 2,
        id: 'pending-tab',
      },
      {
        children: t('INBOX.SEND_WHATSAPP_MODAL.APPROVAL_STATUS.rejected'),
        tabId: 3,
        id: 'rejected-tab',
      },
    ];
  }, []);

  const { tabPanes } = useMemo<{
    tabPanes: TabPaneProps[];
  }>(() => {
    const tabPanes = [
      {
        tabId: 1,
        children: (
          <TemplatesContainer filterBy="approved" searchTerm={searchTerm} onClose={onClose} />
        ),
      },
      {
        tabId: 2,
        children: (
          <TemplatesContainer filterBy="pending" searchTerm={searchTerm} onClose={onClose} />
        ),
      },
      {
        tabId: 3,
        children: (
          <TemplatesContainer filterBy="rejected" searchTerm={searchTerm} onClose={onClose} />
        ),
      },
    ];

    return { tabPanes };
  }, [searchTerm, onClose]);

  return {
    state: {
      tabItems,
      tabPanes,
    },
    getTemplates,
    refetch,
  };
};
