import { useCallback, useEffect, useRef, useState } from 'react';

import { TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import {
  ChannelTypeString,
  IInboxEvent,
  StatusTypeString,
} from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ContactsProfileInboxTable } from './ContactsProfileInboxTable';
import { InboxFilters } from './InboxFilters';

export const ContactsProfileActivityInboxTable = ({ emailId }: { emailId: number }) => {
  const [search, setSearchValue] = useState<string>();
  const [channelFilter, setChannelFilter] = useState<ChannelTypeString | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<StatusTypeString | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const inboxService = useInboxService();
  const [inboxEventsList, setInboxEventsList] = useState<IInboxEvent[]>([]);
  const [totalInboxEvents, setTotalInboxEvents] = useState<number>(0);

  const { pageIndex, pageSize, resetPagination, changeTablePage } = useTablePagination();

  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });

  const getInboxEventsList = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await inboxService.getEventsInbox({
        contactId: emailId,
        limit: pageSize,
        offset: pageIndex - 1,
        channel: channelFilter,
        status: statusFilter,
        description: search,
      });
      setInboxEventsList(data.events);
      setTotalInboxEvents(data.total || 0);
    } catch (error) {
      console.error('Error obtaining the inbox events:', error);
    } finally {
      setIsLoading(false);
    }
  }, [emailId, inboxService, pageIndex, pageSize, channelFilter, statusFilter, search]);

  useEffect(() => {
    void getInboxEventsList();
  }, [getInboxEventsList]);

  const cleanBoxRef = useRef<CleanBoxRef>(null);

  const resetActivityList = () => {
    resetPagination();
    setSearchValue('');
    setChannelFilter(undefined);
    setStatusFilter(undefined);
    cleanBoxRef.current?.cleanBox();
  };

  return (
    <>
      <div className="mb-[-32px] py-2">
        {inboxEventsList && inboxEventsList.length > 0 && (
          <Flex alignment="start" className="pt-2">
            <InboxFilters
              cleanBoxRef={cleanBoxRef}
              filterChannel={setChannelFilter}
              filterChannelSelected={channelFilter}
              filterStatus={setStatusFilter}
              filterStatusSelected={statusFilter}
              resetSearch={resetFilter}
              onReset={resetActivityList}
              onSearch={setSearchValue}
            />
          </Flex>
        )}
      </div>
      <Flex column>
        {isLoading ? (
          <span className="size-full py-8">
            <TableSkeleton />
          </span>
        ) : (
          <ContactsProfileInboxTable
            changeTablePage={changeTablePage}
            gotoPageIndex={pageIndex}
            inboxEventList={inboxEventsList}
            inboxEventTotalCount={totalInboxEvents}
            isLoading={isLoading}
            totalPages={Math.ceil(totalInboxEvents / pageSize)}
          />
        )}
      </Flex>
    </>
  );
};
