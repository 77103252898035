export const BadgeNewIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 44 24" width="44" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#FC7E00" height="24" rx="6" width="44" />
      <path
        d="M16.8115 7.04688V17H15.4854L10.4746 9.32324V17H9.15527V7.04688H10.4746L15.5059 14.7441V7.04688H16.8115ZM22.0273 17.1367C21.5124 17.1367 21.0452 17.0501 20.626 16.877C20.2113 16.6992 19.8535 16.4508 19.5527 16.1318C19.2565 15.8128 19.0286 15.4346 18.8691 14.9971C18.7096 14.5596 18.6299 14.0811 18.6299 13.5615V13.2744C18.6299 12.6729 18.7188 12.1374 18.8965 11.668C19.0742 11.194 19.3158 10.793 19.6211 10.4648C19.9264 10.1367 20.2728 9.88835 20.6602 9.71973C21.0475 9.55111 21.4486 9.4668 21.8633 9.4668C22.3919 9.4668 22.8477 9.55794 23.2305 9.74023C23.6178 9.92253 23.9346 10.1777 24.1807 10.5059C24.4268 10.8294 24.609 11.2122 24.7275 11.6543C24.846 12.0918 24.9053 12.5703 24.9053 13.0898V13.6572H19.3818V12.625H23.6406V12.5293C23.6224 12.2012 23.554 11.8822 23.4355 11.5723C23.3216 11.2624 23.1393 11.0072 22.8887 10.8066C22.638 10.6061 22.2962 10.5059 21.8633 10.5059C21.5762 10.5059 21.3118 10.5674 21.0703 10.6904C20.8288 10.8089 20.6214 10.9867 20.4482 11.2236C20.2751 11.4606 20.1406 11.75 20.0449 12.0918C19.9492 12.4336 19.9014 12.8278 19.9014 13.2744V13.5615C19.9014 13.9124 19.9492 14.2428 20.0449 14.5527C20.1452 14.8581 20.2887 15.127 20.4756 15.3594C20.667 15.5918 20.8971 15.7741 21.166 15.9062C21.4395 16.0384 21.7493 16.1045 22.0957 16.1045C22.5423 16.1045 22.9206 16.0133 23.2305 15.8311C23.5404 15.6488 23.8115 15.4049 24.0439 15.0996L24.8096 15.708C24.6501 15.9495 24.4473 16.1797 24.2012 16.3984C23.9551 16.6172 23.652 16.7949 23.292 16.9316C22.9365 17.0684 22.515 17.1367 22.0273 17.1367ZM28.2549 15.6875L30.1553 9.60352H30.9893L30.8252 10.8135L28.8906 17H28.0771L28.2549 15.6875ZM26.9766 9.60352L28.5967 15.7559L28.7129 17H27.8584L25.7119 9.60352H26.9766ZM32.8076 15.708L34.3525 9.60352H35.6104L33.4639 17H32.6162L32.8076 15.708ZM31.1738 9.60352L33.0332 15.585L33.2451 17H32.4385L30.4492 10.7998L30.2852 9.60352H31.1738Z"
        fill="white"
      />
    </svg>
  );
};
