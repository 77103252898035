import { IItemsEmblueServiceResponse } from '@/src/infrastructure/Protocol/ServiceInterfaces';

/* spell-checker: disable */
export const itemMock: IItemsEmblueServiceResponse = {
  count: 591,
  list: [
    {
      name: 'Afeitadora Philco AE5100PN Con Cabezal Flotante',
      lastActivity: '2022-10-25T19:52:37',
      contacts: 1,
    },
    {
      name: 'Afeitadora Philips S1121/41',
      lastActivity: '2022-10-25T19:53:14',
      contacts: 2,
    },
    {
      name: 'Afeitadora Philips S3122/51 Seco y Humedo',
      lastActivity: '2022-10-25T13:42:33',
      contacts: 1,
    },
    {
      name: 'Afeitadora Phiplps Oneblade QP2521/10',
      lastActivity: '2022-10-25T15:39:11',
      contacts: 1,
    },
    {
      name: 'Aire Acondicionado Port?til',
      lastActivity: '2022-10-25T20:47:38',
      contacts: 3,
    },
    {
      name: 'Aire Acondicionado Port?til Wins 3500 Watts f/c',
      lastActivity: '2022-10-25T20:48:22',
      contacts: 3,
    },
    {
      name: 'AIRE ACONDICIONADO PORTATIL SURREY 3500 W 551IPQ1201-IDQ101.FRIO CALOR.',
      lastActivity: '2022-10-25T20:48:10',
      contacts: 6,
    },
    {
      name: 'Aire Acondicionado Split',
      lastActivity: '2022-10-26T05:24:21',
      contacts: 82,
    },
    {
      name: 'Aire Acondicionado Ventana Philco Frio 5100 Watts',
      lastActivity: '2022-10-25T17:22:50',
      contacts: 1,
    },
    {
      name: 'Aire Piso Techo Sansei 94SAPTH6TRHC5PI 21000Watts',
      lastActivity: '2022-10-25T20:04:17',
      contacts: 1,
    },
  ],
  filteredTotal: 0,
};
