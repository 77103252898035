import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown, SearchInputBar } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { ActivityCleanFilterIcon } from '@/lib/icon';
import { Button, DateInput, IconSvg } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { HappinessIcon } from '@/src/ContactsModule/Icons/HappinessIcon';
export interface INpsFilter {
  onSearch?: (search: string) => void;
  setQualificationFilter?: (filter: string[]) => void;
  setDateFilter?: (filter: [Date | null, Date | null]) => void;
  filterNpsSelected?: string[];
  filterDateSelected?: [Date | null, Date | null] | [];
  onReset?: () => void;
  resetSearch?: () => void;
  cleanBoxRef?: RefObject<CleanBoxRef>;
}

export const HappinessFilters = ({
  onSearch,
  resetSearch,
  onReset,
  cleanBoxRef,
  setQualificationFilter,
  filterNpsSelected,
  setDateFilter,
  filterDateSelected = [],
}: INpsFilter) => {
  const { t } = useTranslation();

  const npsFilters = [
    { id: 1, title: 'promoters' },
    { id: 2, title: 'passives' },
    { id: 3, title: 'detractors' },
  ];

  const handleOnChange: eventOnChangeDateInput = (e) => {
    if (Array.isArray(e)) {
      setDateFilter && setDateFilter(e as [Date | null, Date | null]);
    }
  };

  const [startDate, endDate] = filterDateSelected;

  return (
    <>
      <Flex alignment="start" itemAlignment="center">
        <Flex withGap alignment="start">
          <div className="w-[250px]">
            <SearchInputBar
              ref={cleanBoxRef}
              withButton
              cleanBox={resetSearch}
              placeHolder={t('NPS_FILTERS.searchInputBar')}
              onAction={(ev) => onSearch && onSearch(ev)}
            />
          </div>
          <div className="md:w-150 2xl:w-250">
            <DateInput
              selectsRange
              endDate={endDate}
              maxDate={new Date()}
              placeholder={'dd/MM/yyyy - dd/MM/yyyy'}
              startDate={startDate}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-auto">
            <DropDown
              checkBox
              overflowWidth
              btnOnAction={(itemFilterList: string[]) => {
                setQualificationFilter &&
                  setQualificationFilter(itemFilterList.map((i) => String(i)));
              }}
              btnText={t('CONTACTS_FILTERS.apply')}
              iconHead={<HappinessIcon />}
              itemList={npsFilters?.map((x) => ({
                id: String(x.id),
                title: String(t(`PROFILE_NPS_FILTERS.activityType.${x.id}`)),
              }))}
              titleHead={t('NPS_FILTERS.qualification')}
              tooltip={t('NPS_FILTERS.filterNps')}
              totalSelectedChk={filterNpsSelected?.length}
              valuesSelected={filterNpsSelected && filterNpsSelected.map((i) => String(i))}
            />
          </div>
          <div className="z-10 w-auto">
            <Button
              standard
              iconLeft={<IconSvg fillColor="primary" svgComponent={<ActivityCleanFilterIcon />} />}
              id="clear-filters-button"
              tooltip={t('CONTACTS_FILTERS.cleanAllFilters')}
              onClick={onReset}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
};
