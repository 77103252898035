export const AddContact = () => {
  return (
    <svg className="w-full" viewBox="0 0 22 22">
      <path
        d="M9,12A5,5,0,1,0,4,7a5,5,0,0,0,5,5ZM9,4A3,3,0,1,1,6,7,3,3,0,0,1,9,4ZM22,4H20V2a1,1,0,0,0-2,0V4H16a1,1,0,0,0,0,2h2V8a1,1,0,0,0,2,0V6h2a1,1,0,0,0,0-2ZM12,13H6a5.006,5.006,0,0,0-5,5v2a3,3,0,0,0,3,3H14a3,3,0,0,0,3-3V18a5.006,5.006,0,0,0-5-5Zm3,7a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V18a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3Z"
        data-name="Add Contact"
        fill="#9c59df"
        id="Add_Contact"
        transform="translate(-1 -1)"
      />
    </svg>
  );
};
