import { Children, cloneElement, forwardRef, ReactNode } from 'react';

export type StackProps = {
  children?: ReactNode | ReactNode[] | JSX.Element;
  withGap?: boolean;
  noGrow?: boolean;
  gapSize?: 'small' | 'medium' | 'large';
  column?: boolean;
  /**
   * DEFAULT spaceBetween: center but spacing elements putting all the space between.
   * center: classical centering.
   * spaceEvenly: center but spacing elements evenly, leaving space in the top and bottom.
   */
  alignment?: 'center' | 'spaceEvenly' | 'spaceBetween' | 'start' | 'end';
  /**
   * DEFAULT center
   */
  textAlignment?: 'center' | 'right' | 'left';
  /**
   * DEFAULT center
   */
  itemAlignment?: 'center' | 'start' | 'end' | 'baseline' | 'stretch';
  className?: string;
  id?: string;
};

const vAlignments = {
  center: 'justify-center',
  spaceEvenly: 'justify-evenly',
  spaceBetween: 'justify-between',
  start: 'justify-start',
  end: 'justify-end',
};

const tAlignments = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

const iAlignments = {
  center: 'items-center',
  start: 'items-start',
  end: 'items-end',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
};

export const Divide = ({ vertical, children }: { vertical?: boolean; children: JSX.Element }) => {
  const mappedChildren = Children.map(children, (child) => {
    return cloneElement(child, {
      extraClassName: `${vertical ? 'divide-x' : 'divide-y'}`,
    });
  });
  return <>{mappedChildren}</>;
};

export const Flex = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      children,
      withGap,
      gapSize,
      column,
      alignment,
      textAlignment,
      itemAlignment,
      noGrow,
      className,
      id,
      ...rest
    }: StackProps,
    ref
  ) => {
    const { extraClassName = '' } = rest as { extraClassName: string };
    return (
      <div
        ref={ref}
        className={`flex
      ${noGrow ? '' : 'size-full grow'}
      ${(itemAlignment && iAlignments[itemAlignment]) || 'items-center'}
      ${(textAlignment && tAlignments[textAlignment]) || 'text-center'}
      ${(alignment && vAlignments[alignment]) || 'justify-between'}
      ${
        withGap
          ? gapSize === 'small'
            ? 'gap-2'
            : gapSize === 'medium'
            ? 'gap-6'
            : gapSize === 'large'
            ? 'gap-12'
            : 'gap-2'
          : ''
      }
      ${column ? 'flex-col' : ''}
      ${extraClassName}
      ${className || ''}`}
        data-testid="flex-component"
        id={id}
      >
        {children}
      </div>
    );
  }
);

export const Grid = ({ children, withGap, gapSize, column }: StackProps) => {
  return (
    <div
      className={`grid grow text-center
      ${
        withGap
          ? gapSize === 'small'
            ? 'gap-2'
            : gapSize === 'medium'
            ? 'gap-6'
            : gapSize === 'large'
            ? 'gap-12'
            : 'gap-2'
          : ''
      }
      ${column ? '' : 'w-full grid-flow-col'}
      grid-cols-1`}
    >
      {children}
    </div>
  );
};
