import classNames from 'classnames';
import { memo, MouseEventHandler, ReactNode } from 'react';

import { ArrowBackIcon } from '@/lib/icon';
import { Container, IconSvg } from '@/lib/v2/components';
import { JustifyContentBase } from '@/lib/v2/types';

interface SectionHeaderProps {
  icon?: ReactNode;
  title?: ReactNode;
  withoutDivide?: boolean;
  actionsButtons?: ReactNode;
  backButton?: boolean;
  onBack?: MouseEventHandler<HTMLDivElement>;
  justifyContentActionsButtons?: JustifyContentBase;
  children?: ReactNode;
  isLoading?: boolean;
  alternativeText?: ReactNode;
}

const SectionHeader = ({
  icon,
  title,
  withoutDivide = false,
  actionsButtons,
  backButton,
  onBack,
  justifyContentActionsButtons = 'start',
  children,
  isLoading,
  alternativeText,
}: SectionHeaderProps) => {
  const classes = classNames('flex flex-row gap-3 items-center py-4');

  const classesActionsButtons = classNames('flex flex-1', {
    [`justify-${justifyContentActionsButtons}`]: justifyContentActionsButtons,
  });

  return (
    <>
      <Container>
        <div className={classes}>
          {isLoading && (
            <div className="flex w-full animate-pulse flex-col">
              <div className="my-2 h-5 w-1/4 rounded-full bg-gray-200"></div>
            </div>
          )}
          {!isLoading && (
            <>
              <div className="flex flex-row items-center gap-3">
                {backButton && (
                  <IconSvg
                    height="22px"
                    svgComponent={<ArrowBackIcon />}
                    width="22px"
                    onClick={onBack}
                  />
                )}
                <div className="h-full">{icon}</div>
                {title}
                {alternativeText}
              </div>
              <div className={classesActionsButtons}>{actionsButtons}</div>
              {children}
            </>
          )}
        </div>
      </Container>
      {!withoutDivide && <div className="h-[1px] w-full bg-[#e5e7eb]"></div>}
    </>
  );
};

export default memo(SectionHeader);
