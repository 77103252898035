export const CameraIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_889_1465)">
        <path
          d="M9.15 11.55C10.4755 11.55 11.55 10.4755 11.55 9.15C11.55 7.82452 10.4755 6.75 9.15 6.75C7.82452 6.75 6.75 7.82452 6.75 9.15C6.75 10.4755 7.82452 11.55 9.15 11.55Z"
          fill="#9DA3A7"
        />
        <path
          d="M6.75 1.5L5.3775 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3H12.6225L11.25 1.5H6.75ZM9 12.75C6.93 12.75 5.25 11.07 5.25 9C5.25 6.93 6.93 5.25 9 5.25C11.07 5.25 12.75 6.93 12.75 9C12.75 11.07 11.07 12.75 9 12.75Z"
          fill="#9DA3A7"
        />
      </g>
      <defs>
        <clipPath id="clip0_889_1465">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
