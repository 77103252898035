import { MyCatalogTemplates } from '@/src/modules/CampaignsModule/components/MyCatalogTemplates';
import PreviewTemplate from '@/src/modules/CampaignsModule/components/PreviewTemplate';

export const MyTemplates = () => {
  return (
    <div className="flex flex-row p-4">
      <MyCatalogTemplates />
      <PreviewTemplate />
    </div>
  );
};
