import { useTranslation } from 'react-i18next';

import Text from '@/lib/v2/components/Text/Text';
import { CloudComputingIcon, CloudComputingUploadIcon } from '@/lib/v2/icons/outline';

import { IUploadZone } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

const UploadZone = ({
  rootProps,
  inputProps,
  isDraged,
  fileName = 'File Name',
  plainText = 'Some Plain Text',
  ctaText = 'Some CTA Text',
  onClickHandler,
}: IUploadZone) => {
  const { t } = useTranslation(['editorHtml']);
  return (
    <>
      <button className="w-full" onClick={onClickHandler}>
        <div
          {...rootProps}
          className="border-lightblue-500 h-ful relative flex flex-col items-center justify-center rounded-3xl border-4 border-dashed bg-emblue-secondary-hover p-10 text-center"
        >
          {isDraged && (
            <div className="bg-darkGray absolute flex size-full items-center justify-center rounded-3xl text-center opacity-70">
              <p className="text-3xl font-medium text-white">{t('dropFile')}</p>
            </div>
          )}
          {!fileName ? <CloudComputingIcon /> : <CloudComputingUploadIcon />}
          <input {...inputProps()} />
          <div className="h-14">
            <p className="text-xs m-4 text-gray-500">{fileName}</p>
          </div>
          <Text>
            {plainText}
            <span className="text-sky-600 underline" id="import-pick-upload">
              {ctaText}
            </span>
          </Text>
        </div>
      </button>
    </>
  );
};

export default UploadZone;
