import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BadgeText, Button, Flex, Grid, IconSvg, If, Text } from '@/lib/v2/components';
import { WhatsAppIcon } from '@/lib/v2/icons/general';

import { TRANSLATE_INBOX_MAIN_HEADER } from '@/src/modules/InboxModule/constants';
import { useInboxMetrics } from '@/src/modules/InboxModule/hooks/useInboxMetrics';
import { ThumbnailPreview } from '@/src/modules/InboxModule/images/icons';

const InboxMainHeader = () => {
  const { t } = useTranslation('inbox');
  const { trackTryInboxClick, trackYoutubeVideoPlay } = useInboxMetrics();
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = useCallback(() => {
    trackYoutubeVideoPlay();
    setShowVideo(true);
  }, [trackYoutubeVideoPlay]);

  const callToActionUrl = useMemo(
    () => 'https://www.embluemail.com/producto/whatsapp-universal-inbox/#quiero-probarlo',
    []
  );

  const openCallToAction = useCallback(
    () => window.open(callToActionUrl, '_blank'),
    [callToActionUrl]
  );

  const handleTryClick = useCallback(() => {
    openCallToAction();
    trackTryInboxClick();
  }, [openCallToAction, trackTryInboxClick]);

  return (
    <Grid className="gap-8 lg:grid-cols-2">
      <Flex
        column
        alignment="start"
        className="max-w-full gap-0 lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl"
        itemAlignment="start"
      >
        <BadgeText text={t(`${TRANSLATE_INBOX_MAIN_HEADER}.BADGE_TEXT`)} variantColor="success" />
        <Flex withGap alignment="start" gapSize="small" itemAlignment="start">
          <Text
            className="font-montserrat !text-[40px] 2xl:!text-[60px]"
            color="primary"
            fontWeight="semibold"
            variant="headline"
          >
            {t(`${TRANSLATE_INBOX_MAIN_HEADER}.TITLE`)}
          </Text>
          <IconSvg height="32" svgComponent={<WhatsAppIcon />} width="32" />
        </Flex>
        <Text
          className="-mt-6 font-montserrat !text-[35px] 2xl:!text-[50px]"
          color="dark"
          fontWeight="semibold"
          variant="sub-headline"
        >
          {t(`${TRANSLATE_INBOX_MAIN_HEADER}.SUBTITLE`)}
        </Text>
        <Text
          className="!text-[15px] 2xl:!text-[18px]"
          color="dark"
          fontWeight="normal"
          variant="sub-headline-2"
        >
          {t(`${TRANSLATE_INBOX_MAIN_HEADER}.DESCRIPTION_1`)}{' '}
          <strong>{t(`${TRANSLATE_INBOX_MAIN_HEADER}.DESCRIPTION_2`)}</strong>
        </Text>
        <Text
          className="!text-[15px] 2xl:!text-[18px]"
          color="dark"
          fontWeight="normal"
          variant="sub-headline-2"
        >
          {t(`${TRANSLATE_INBOX_MAIN_HEADER}.DESCRIPTION_3`)}
        </Text>
        <Text
          className="!text-[15px] 2xl:!text-[18px]"
          color="dark"
          fontWeight="normal"
          variant="sub-headline-2"
        >
          <strong>{t(`${TRANSLATE_INBOX_MAIN_HEADER}.DESCRIPTION_4`)}</strong>
        </Text>
        <Button className="mt-6" size="small" onClick={handleTryClick}>
          {t(`${TRANSLATE_INBOX_MAIN_HEADER}.BUTTON_TEXT`)}
        </Button>
      </Flex>
      <Flex alignment="center">
        <If condition={!showVideo}>
          <IconSvg
            fullWidth
            className="max-w-[400px] cursor-pointer lg:max-w-[450px] xl:max-w-[500px] 2xl:max-w-full"
            svgComponent={<ThumbnailPreview />}
            onClick={handleVideoClick}
          />
        </If>
        <If condition={showVideo}>
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            className="aspect-video w-full min-w-[400px] rounded-lg shadow-lg lg:min-w-[450px] xl:min-w-[500px] 2xl:min-w-[600px]"
            id="universal-inbox-video"
            loading="lazy"
            referrerPolicy="strict-origin-when-cross-origin"
            src="https://www.youtube.com/embed/OOSMbVi4dlQ?autoplay=1"
            title="Universal Inbox"
          />
        </If>
      </Flex>
    </Grid>
  );
};

export default InboxMainHeader;
