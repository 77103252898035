import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { atomShowModalSignal } from '@/lib/v2/atoms/atomsSmartFinder';

export const useShowModalSignal = (type: string, onOpen: () => void) => {
  const [showModalSignal, setShowModalSignal] = useAtom(atomShowModalSignal);

  useEffect(() => {
    if (type === showModalSignal) {
      onOpen && onOpen();
      setShowModalSignal('');
    }
  }, [onOpen, setShowModalSignal, showModalSignal, type]);

  return {};
};
