import { Flex } from '@/lib/v2/components/Layout/Stack';

export const BigColumnSkeleton = () => {
  return (
    <div
      className="max-w-fit animate-pulse space-y-4 divide-y divide-gray-200 p-4  md:p-6"
      role="status"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const SimpleColumnSkeleton = () => {
  return (
    <div
      className="max-w-fit animate-pulse space-y-4 divide-y divide-gray-200 p-4  md:p-6"
      role="status"
    >
      <div className="flex items-center justify-between">
        <div className="h-2.5 w-12 rounded-full bg-gray-300 "></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div className="h-2.5 w-12 rounded-full bg-gray-300 "></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div className="h-2.5 w-12 rounded-full bg-gray-300 "></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div className="h-2.5 w-12 rounded-full bg-gray-300 "></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div className="h-2.5 w-12 rounded-full bg-gray-300 "></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const FormColumnSkeleton = () => {
  return (
    <div
      className="w-full animate-pulse space-y-4 divide-y divide-gray-200 p-4  md:p-6"
      role="status"
    >
      <Flex withGap gapSize="small">
        <div className="mb-1 size-[8px] rotate-45 border-b-2 border-r-2 border-gray-700 bg-transparent"></div>
        <div className="h-2 w-40 rounded-md bg-gray-200 "></div>
        <div className="ml-20 h-8 w-20 rounded-md bg-gray-200 "></div>
      </Flex>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2 w-full rounded-md bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2 w-full rounded-md bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2 w-full rounded-md bg-gray-200 "></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
