import { IconProps } from './iconPropTypes';

export const NotesIcon = ({ color }: IconProps) => {
  return (
    <svg
      height="14.419"
      viewBox="0 0 14.419 14.419"
      width="14.419"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="notes" transform="translate(-2.495 -2.495)">
        <path
          d="M13.906,8.9v6.211a.6.6,0,0,1-.6.6H4.3a.6.6,0,0,1-.6-.6V7.906h4.41l3.6-3.6H4.3a1.8,1.8,0,0,0-1.8,1.8v9.008a1.8,1.8,0,0,0,1.8,1.8H13.3a1.8,1.8,0,0,0,1.8-1.8V7.7Zm1.808-3.507.849-.849a1.2,1.2,0,1,0-1.7-1.7l-.849.849,1.7,1.7Z"
          data-name="Path 3461"
          fill={`${color}`}
          id="Path_3461"
        />
        <path
          d="M17.921,5.909l-4.942,4.943-.495,2.472,2.472-.495L19.9,7.887Z"
          data-name="Path 3462"
          fill={`${color}`}
          id="Path_3462"
          transform="translate(-4.992 -1.407)"
        />
      </g>
    </svg>
  );
};
