import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

import { NPSWidgets } from '@/modules/NPSModule/interfaces';

export const useWidgetNpsList = (
  {
    pageNumber,
    rowsPerPage,
    search,
    status,
    orderColumn,
    orderDirection,
    lastRefresh,
  }: {
    pageNumber?: number;
    rowsPerPage?: number;
    search?: string;
    status?: string;
    orderColumn?: string;
    orderDirection?: string;
    lastRefresh: number;
  },
  typeOfList: 'list' | 'recycle_bin'
): [NPSWidgets | undefined, Error | undefined, boolean | undefined] => {
  const [npsWidgets, error, isLoadingWidgets] = useEmblue(
    ServiceMethods.getNpsList,
    {
      pageNumber,
      rowsPerPage,
      search,
      status,
      orderColumn,
      orderDirection,
      lastRefresh,
    },
    typeOfList
  );

  return [npsWidgets, error, isLoadingWidgets];
};
