import EventEmitter from 'events';
import _ from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';

import { AddToastEvent } from './helper';
import Toast, { ToastProps } from './Toast';

export interface ToastContainerProps {
  id?: string;
  positionBottom?: boolean;
}

export const toastEvent = new EventEmitter();

const ToastContainer = ({ positionBottom, id }: ToastContainerProps) => {
  const [notifications, setNotifications] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    (toastArgs: ToastProps) => {
      setNotifications((prevNotifications) => {
        const newNotifications = _.cloneDeep(prevNotifications);
        newNotifications.push({ id: _.uniqueId(), ...toastArgs });
        return newNotifications;
      });
    },
    [setNotifications]
  );

  useEffect(() => {
    toastEvent.on(AddToastEvent, addToast);
    return () => {
      toastEvent.off(AddToastEvent, addToast);
    };
  }, [addToast]);

  const handleClose = useCallback(
    (id?: string) => {
      setNotifications((prevNotifications) => {
        const newNotifications = _.cloneDeep(prevNotifications);
        return newNotifications.filter((notification) => notification.id !== id);
      });
    },
    [setNotifications]
  );

  return (
    <div
      aria-live="assertive"
      className={`${
        positionBottom ? 'inset-x-0 bottom-0' : 'inset-0'
      } pointer-events-none fixed z-[999] flex items-end px-4 py-6 sm:items-start sm:p-6`}
      data-testid="toast-container-component"
      id={id}
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {notifications.map((notificationProps) => {
          return <Toast key={notificationProps.id} {...notificationProps} onClose={handleClose} />;
        })}
      </div>
    </div>
  );
};

export default memo(ToastContainer);
