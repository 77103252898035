import { useTranslation } from 'react-i18next';

import SelectedOptions from '@/lib/v2/components/EntityAssignment/components/SelectedOptions';
import {
  EntityAssignmentProps,
  ISelectedDictionary,
} from '@/lib/v2/components/EntityAssignment/EntityAssignment';
import { Text } from '@/lib/v2/components/Text';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';

interface SelectedEntityPanelProps {
  selected: ISelectedDictionary;
  unselected: ISelectedDictionary;
  deleteSelected: (id: number) => () => void;
  deleteUnselected: (id: number) => () => void;
  wordings: EntityAssignmentProps['wordings'];
}

const SelectedEntityPanel = ({
  selected,
  unselected,
  deleteSelected,
  deleteUnselected,
  wordings,
}: SelectedEntityPanelProps) => {
  const { t } = useTranslation();

  const selectedItems = Object.values(selected);
  const unselectedItems = Object.values(unselected);
  const contactText =
    Number(wordings.selectedCount) === 1 ? t('CONTACTS_MAIN.contact') : t('CONTACTS_MAIN.contacts');

  return (
    <div className="flex w-72 flex-col gap-4 pl-7 pr-3">
      <Text color="primary" fontWeight="medium" variant="sub-headline">
        {`${quantifier(wordings.selectedCount) ?? '0'} ${contactText}`}
      </Text>
      <div className="flex flex-col gap-2">
        <Text fontWeight="medium" variant="text">
          {wordings.description.selected}
        </Text>
        <div className="visible-scrollbar h-24 overflow-y-auto pr-2">
          <div className="flex flex-wrap gap-2">
            <SelectedOptions deleteOption={deleteSelected} items={selectedItems} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Text fontWeight="medium" variant="text">
          {wordings.description.unselected}
        </Text>
        <div className="visible-scrollbar h-24 overflow-y-auto pr-2">
          <div className="flex flex-wrap gap-2">
            <SelectedOptions deleteOption={deleteUnselected} items={unselectedItems} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedEntityPanel;
