import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import {
  atomBillingInformation,
  atomContractDetail,
} from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';

export const useManagePlanSecurity = () => {
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const { JWT_DEV_TOKEN } = configData.endpoints[env];
  const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : ((window as any).__JWTToken as string);

  const contractDetail = useAtomValue(atomContractDetail);
  const billingInformation = useAtomValue(atomBillingInformation);

  const billingInformationIsLoading = billingInformation === undefined;

  const canAddToAutoDebit = useMemo(() => {
    const planName = contractDetail?.planName;
    if (planName && !billingInformation?.hasStripeSubscription) {
      if (planName === 'Advance' || planName === 'Starter') {
        return true;
      }
    }
    return false;
  }, [billingInformation?.hasStripeSubscription, contractDetail]);

  const isFreemiumClient = useMemo(() => {
    const planName = contractDetail?.planName;
    return planName === 'Free' && !billingInformation?.hasStripeSubscription;
  }, [billingInformation?.hasStripeSubscription, contractDetail?.planName]);

  const isOldPlan = useMemo(() => {
    const planName = contractDetail?.planName;
    if (planName) {
      if (planName !== 'Advance' && planName !== 'Starter' && planName !== 'Free') {
        return true;
      }
    }
    return false;
  }, [contractDetail]);

  return { canAddToAutoDebit, billingInformationIsLoading, jwtToken, isFreemiumClient, isOldPlan };
};
