import { IconProps } from './iconPropTypes';

export const ArrowDownTableIcon = ({ color = '#96a6ce' }: IconProps) => {
  return (
    <svg height="7" viewBox="0 0 7 5" width="10" xmlns="http://www.w3.org/2000/svg">
      <g id="down" transform="translate(0 -8)">
        <path
          d="M6,11h7L9.5,16,6,11Z"
          fill={`${color}`}
          fillRule="evenodd"
          id="path"
          transform="translate(-6 -3)"
        />
      </g>
    </svg>
  );
};
