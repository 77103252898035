export const HtmlEditorIcon = () => {
  return (
    <svg fill="none" height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 67.5919C53.4477 67.5919 67.5919 53.4477 67.5919 36C67.5919 18.5524 53.4477 4.4082 36 4.4082C18.5524 4.4082 4.4082 18.5524 4.4082 36C4.4082 53.4477 18.5524 67.5919 36 67.5919Z"
        fill="#DCE2F7"
      />
      <path
        d="M36.8818 9.698C44.5226 9.698 52.3104 9.698 59.9512 9.698C61.7144 9.698 62.5961 10.4327 62.5961 12.3429C62.5961 22.6286 62.5961 35.4123 62.5961 45.698C62.5961 46.1388 62.5961 46.7266 62.3022 47.1674C62.0083 47.9021 61.2736 48.196 60.392 48.196C59.6573 48.196 58.9226 48.196 58.1879 48.196C43.494 48.196 28.8002 48.196 14.1063 48.196C13.8124 48.196 13.5185 48.196 13.2247 48.196C12.1961 48.196 11.4614 47.3143 11.3145 46.2858C11.3145 46.1388 11.3145 45.9919 11.3145 45.698C11.3145 35.4123 11.3145 22.6286 11.3145 12.196C11.3145 10.4327 12.0491 9.698 13.9594 9.698C21.7471 9.698 29.3879 9.698 37.1757 9.698H36.8818ZM16.6042 16.4572C17.3389 16.4572 18.0736 15.7225 18.0736 14.9878C18.0736 14.1062 17.3389 13.5184 16.6042 13.5184C15.7226 13.5184 15.1349 14.2531 15.1349 14.9878C15.1349 15.7225 15.8696 16.4572 16.6042 16.4572ZM22.4818 14.9878C22.4818 14.1062 21.7471 13.5184 21.0124 13.5184C20.1308 13.5184 19.543 14.2531 19.543 14.9878C19.543 15.7225 20.2777 16.4572 21.0124 16.4572C21.7471 16.4572 22.4818 15.7225 22.4818 14.9878Z"
        fill="#004BC2"
      />
      <path
        d="M37.1752 19.249C44.0813 19.249 50.9874 19.249 57.8936 19.249C58.7752 19.249 59.0691 19.6898 59.0691 19.8368C59.0691 19.9837 59.0691 20.5715 58.0405 20.5715C44.2283 20.5715 30.416 20.5715 16.6038 20.5715C15.8691 20.5715 15.5752 20.2776 15.5752 19.8368C15.5752 19.396 15.8691 19.249 16.7507 19.249C23.6568 19.249 30.563 19.249 37.4691 19.249H37.1752Z"
        fill="white"
      />
      <path
        d="M50.9874 15.5755C48.6364 15.5755 46.2854 15.5755 44.0813 15.5755C43.9344 15.5755 43.6405 15.5755 43.4936 15.5755C43.1997 15.5755 43.0527 15.1347 43.0527 14.9878C43.0527 14.8408 43.0527 14.547 43.4936 14.4C43.9344 14.2531 43.7874 14.4 44.0813 14.4C48.7833 14.4 53.6323 14.4 58.3344 14.4C58.4813 14.4 58.7752 14.4 58.9221 14.4C59.0691 14.547 59.3629 14.8408 59.3629 14.9878C59.3629 15.1347 59.0691 15.4286 58.9221 15.5755C58.7752 15.5755 58.4813 15.5755 58.3344 15.5755C55.9833 15.5755 53.6323 15.5755 51.2813 15.5755H50.9874Z"
        fill="white"
      />
      <path
        d="M16.6042 16.4571C15.8695 16.4571 15.1348 15.8693 15.1348 14.9877C15.1348 14.253 15.7225 13.5183 16.6042 13.5183C17.4858 13.5183 18.0735 14.1061 18.0735 14.9877C18.0735 15.7224 17.4858 16.4571 16.6042 16.4571Z"
        fill="white"
      />
      <path
        d="M22.4817 14.9877C22.4817 15.8693 21.894 16.4571 21.0124 16.4571C20.2777 16.4571 19.543 15.7224 19.543 14.9877C19.543 14.253 20.2777 13.5183 21.0124 13.5183C21.894 13.5183 22.4817 14.1061 22.4817 14.9877Z"
        fill="white"
      />
      <path
        d="M33.5017 24.9797H16.8976C16.0049 24.9797 15.2812 25.7034 15.2812 26.5961V37.3226C15.2812 38.2153 16.0049 38.9389 16.8976 38.9389H33.5017C34.3943 38.9389 35.118 38.2153 35.118 37.3226V26.5961C35.118 25.7034 34.3943 24.9797 33.5017 24.9797Z"
        fill="white"
      />
      <path
        d="M39.8204 38.9387C38.9388 38.9387 38.2041 38.204 38.2041 37.3224V26.7428C38.2041 25.8612 38.9388 25.1265 39.8204 25.1265H56.4245C57.3061 25.1265 58.0408 25.8612 58.0408 26.7428V37.3224C58.0408 38.204 57.3061 38.9387 56.4245 38.9387"
        fill="white"
      />
      <path
        d="M58.9222 37.6165C64.065 37.6165 68.1793 41.7307 68.1793 46.8736C68.1793 52.0165 63.9181 56.1307 58.9222 56.1307C53.7793 56.1307 49.665 52.0165 49.665 46.8736C49.665 41.7307 53.7793 37.6165 58.9222 37.6165ZM58.1875 47.7552C58.1875 47.7552 58.1875 47.9022 58.1875 48.0491C58.1875 48.7838 58.1875 49.5185 58.1875 50.2532C58.1875 50.5471 58.1875 50.694 58.3344 50.8409C58.6283 51.1348 58.9222 51.1348 59.2161 51.1348C59.5099 51.1348 59.8038 50.694 59.8038 50.2532C59.8038 49.5185 59.8038 48.7838 59.8038 47.9022C59.8038 47.9022 59.8038 47.9022 59.8038 47.7552C59.8038 47.7552 59.9508 47.7552 60.0977 47.7552C60.8324 47.7552 61.5671 47.7552 62.3018 47.7552C62.7426 47.7552 63.1834 47.3144 63.1834 46.8736C63.1834 46.4328 62.7426 45.992 62.3018 45.992C61.5671 45.992 60.8324 45.992 60.0977 45.992C60.0977 45.992 60.0977 45.992 59.8038 45.992C59.8038 45.992 59.8038 45.845 59.8038 45.6981C59.8038 44.9634 59.8038 44.2287 59.8038 43.494C59.8038 42.9063 59.363 42.6124 58.9222 42.6124C58.4814 42.6124 58.0406 43.0532 58.0406 43.494C58.0406 44.2287 58.0406 44.9634 58.0406 45.6981C58.0406 45.6981 58.0406 45.6981 58.0406 45.992C58.0406 45.992 57.8936 45.992 57.7467 45.992C57.012 45.992 56.1303 45.992 55.3957 45.992C54.9548 45.992 54.661 46.2858 54.514 46.7267C54.514 47.3144 54.8079 47.7552 55.3957 47.7552C56.2773 47.7552 57.012 47.7552 57.8936 47.7552H58.1875Z"
        fill="#FA7E23"
      />
      <path
        d="M58.1882 47.7552C57.3066 47.7552 56.4249 47.7552 55.6902 47.7552C55.1025 47.7552 54.8086 47.3143 54.8086 46.7266C54.8086 46.2858 55.2494 45.9919 55.6902 45.9919C56.4249 45.9919 57.3066 45.9919 58.0412 45.9919C58.0412 45.9919 58.0412 45.9919 58.3351 45.9919C58.3351 45.9919 58.3351 45.845 58.3351 45.698C58.3351 44.9633 58.3351 44.2286 58.3351 43.4939C58.3351 43.0531 58.629 42.6123 59.2168 42.6123C59.6576 42.6123 60.0984 43.0531 60.0984 43.4939C60.0984 44.2286 60.0984 44.9633 60.0984 45.698C60.0984 45.698 60.0984 45.698 60.0984 45.9919C60.0984 45.9919 60.2453 45.9919 60.3923 45.9919C61.127 45.9919 61.8617 45.9919 62.5963 45.9919C63.0372 45.9919 63.478 46.2858 63.478 46.8735C63.478 47.3143 63.1841 47.7552 62.5963 47.7552C61.8617 47.7552 61.127 47.7552 60.3923 47.7552C60.3923 47.7552 60.3923 47.7552 60.0984 47.7552V47.9021C60.0984 48.6368 60.0984 49.3715 60.0984 50.2531C60.0984 50.6939 59.8045 50.9878 59.5106 51.1348C59.2168 51.1348 58.7759 51.1348 58.629 50.8409C58.4821 50.6939 58.4821 50.4001 58.4821 50.2531C58.4821 49.5184 58.4821 48.7837 58.4821 48.049C58.4821 48.049 58.4821 48.049 58.4821 47.7552H58.1882Z"
        fill="white"
      />
    </svg>
  );
};
