import { memo, MouseEventHandler, ReactElement } from 'react';
import { NavLink, To, useLocation } from 'react-router-dom';

import { Text } from '@/lib/v2/components/Text';

export interface Link {
  key: string;
  /** The name of the route that will shows on navigation bar */
  name?: string;
  /** The path of the route, if it's not a component */
  to?: To;
  /** Determines if the component or navigation tab is showing or not */
  isHidden?: boolean;
  /** The component that will render if it's a component */
  component?: ReactElement;
  /** onClick tab link */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  id?: string;
}

interface SecondaryNavigationMenuProps {
  links: Link[];
}

const SecondaryNavigationMenu = ({ links }: SecondaryNavigationMenuProps): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <div className="relative z-10 flex h-[48px] w-full items-center justify-between bg-white px-6 shadow">
      <div className="flex h-full">
        {links.map((item: Link) => {
          const { to, component, isHidden = false, key, name, onClick, id } = item;
          return to && !component && !isHidden ? (
            <NavLink
              key={key}
              className={`mx-3 flex items-center px-2
                ${
                  pathname === to
                    ? 'border-b-[3px] border-[#004DBC]'
                    : 'border-b-[3px] border-transparent'
                }`}
              id={id}
              to={to}
              onClick={onClick}
            >
              <Text
                as="h4"
                color={pathname === to ? 'primary' : 'black'}
                fontWeight={pathname === to ? 'medium' : 'normal'}
                variant="text"
              >
                {name}
              </Text>
            </NavLink>
          ) : (
            <div key={key} className="flex items-center">
              {component}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(SecondaryNavigationMenu);
