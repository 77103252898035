import { Dispatch, SetStateAction } from 'react';

import { useSearchParamsState } from '@/src/application/hooks/useSearchParamsState';

export const useResetSearch = ({
  cleanBox,
  setSearchValue,
}: {
  cleanBox?: () => void;
  setSearchValue?: Dispatch<SetStateAction<string>>;
}) => {
  const resetFilter = () => {
    setSearchValue && setSearchValue('');
    cleanBox && cleanBox();
  };
  return { resetFilter };
};

export const useResetSearchParams = ({
  cleanBox,
  setSearchValue,
}: {
  cleanBox?: () => void;
  setSearchValue?: Dispatch<SetStateAction<string>>;
}) => {
  const [, setFilterSearchSelectedParams] = useSearchParamsState('filterSearch', '');
  const resetFilter = () => {
    setSearchValue && setSearchValue('');
    cleanBox && cleanBox();
    setFilterSearchSelectedParams('');
  };
  return { resetFilter };
};
