import { useAtomValue, useSetAtom } from 'jotai';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DateInput, TimeInput } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import useSchedulingForm, {
  SchedulingWhatsAppForm,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling/hooks/useSchedulingForm';

import { atomProcessingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomCallbackStep } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { CAMPAIGNS_PATH, TRANSLATE_WHATSAPP_SCHEDULING } from '@/modules/CampaignsModule/constants';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import { IScheduledSMSDeliveryV1 } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const SchedulingDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  /* cspell:disable */
  const { t } = useTranslation('inbox');
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const setProcessingAction = useSetAtom(atomProcessingAction);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);

  const { control, RULES, handleSubmit, setValue } = useSchedulingForm();
  const { setScheduledWhatsAppDelivery } = useActionWhatsAppData(Number(actionId));

  const dateWhatsApp = useMemo(() => {
    if (!actionInfoWhatsApp) return;

    const { anio, mes, dia, hora, minutos } = actionInfoWhatsApp;

    const dateTime = new Date(
      parseInt(anio),
      parseInt(mes) - 1,
      parseInt(dia),
      parseInt(hora),
      parseInt(minutos)
    );

    const date = new Date(parseInt(anio), parseInt(mes) - 1, parseInt(dia));

    return {
      date,
      dateTime,
    };
  }, [actionInfoWhatsApp]);

  const [scheduledDate, setScheduledDate] = useState<Date | undefined>(
    !actionInfoWhatsApp?.inmediato ? dateWhatsApp?.date : undefined
  );
  const [scheduledTime, setScheduledTime] = useState<Date | undefined>(
    !actionInfoWhatsApp?.inmediato ? dateWhatsApp?.dateTime : undefined
  );
  const [scheduledMinDateTime, setScheduledMinDateTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!dateWhatsApp?.date) return;

    setValue('sendDate', dateWhatsApp?.date);
    setValue('sendTime', dateWhatsApp?.date);
  }, [actionInfoWhatsApp, dateWhatsApp?.date, setValue]);

  const handleSubmitData = useCallback(
    async (formData: SchedulingWhatsAppForm) => {
      if (!actionId) return;

      setProcessingAction(true);

      const { sendDate, sendTime } = formData;
      if (!sendDate || !sendTime) return;

      const schedulingDate = new Date(
        sendDate.getFullYear(),
        sendDate.getMonth(),
        sendDate.getDate()
      );
      const schedulingTimestamp = schedulingDate.getTime();

      const queryParams: IScheduledSMSDeliveryV1 = {
        elementoId: +actionId,
        hora: sendTime.getHours(),
        minuto: sendTime.getMinutes(),
        milis: schedulingTimestamp,
      };

      await setScheduledWhatsAppDelivery(queryParams);

      setProcessingAction(false);
      navigate(`${CAMPAIGNS_PATH}/${Number(campaignId)}/whatsapp/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, navigate, setProcessingAction, setScheduledWhatsAppDelivery]
    /* actionId, campaignId, navigate */
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback((event) => {
    const currentDate = new Date();
    const inputDate = event as Date;
    const newInputDate = new Date(inputDate);

    if (newInputDate?.getDate() === currentDate.getDate()) {
      newInputDate.setHours(currentDate.getHours(), currentDate.getMinutes() + 10);
    }

    setScheduledDate(inputDate);
    setScheduledTime(undefined);
    setScheduledMinDateTime(newInputDate);
  }, []);

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setScheduledTime(event as Date);
  }, []);

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setProcessingAction(false);
  }, [handleSubmit, handleSubmitData, setCallbackStep, setProcessingAction]);

  /* cspell:enable */
  return (
    <form
      className="pt-12"
      id="immediate-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="flex gap-3">
        <div className="flex w-[200px]">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={scheduledDate}
            disabled={disabled}
            id="sendDate"
            label={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.date`)}
            minDate={new Date()}
            name="sendDate"
            placeholder={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.datePlaceholder`)}
            rules={RULES.sendDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={scheduledTime}
            disabled={disabled}
            id="sendTime"
            label={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.time`)}
            minTime={scheduledMinDateTime || new Date()}
            name="sendTime"
            placeholder={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.timePlaceholder`)}
            rules={RULES.sendTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default SchedulingDeliveryForm;
