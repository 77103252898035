import { Trans, useTranslation } from 'react-i18next';

import { Card, CardSection, IconSvg, If, Text } from '@/lib/v2/components';

import { Frequency } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/StepOneManagePlan/StepOneManagePlan';
import { numberToCurrency } from '@/src/utils/currency';

import { BILLING_FORM_DATA_KEY, MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { CancelledIcon } from '@/modules/MyPlanModule/images/CancelledIcon';
import { CompletedIcon } from '@/modules/MyPlanModule/images/CompletedIcon';

type UpdatePlanStatusProps = {
  isSuccess: boolean;
  increaseEmail: number;
  priceIncreaseEmail: number;
  increaseSms: number;
  priceIncreaseSms: number;
  increaseOnSite: number;
  priceIncreaseOnSite: number;
  total: number;
  frequency: Frequency;
  downgrades: string;
};

const UpdatePlanStatus = ({
  isSuccess,
  increaseEmail,
  priceIncreaseEmail,
  increaseSms,
  priceIncreaseSms,
  increaseOnSite,
  priceIncreaseOnSite,
  total,
  frequency,
  downgrades,
}: UpdatePlanStatusProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full justify-center pt-4">
      <div className="flex w-full flex-col gap-4 sm:max-w-[538px]">
        <Card isHeightAuto noShadow color="gray">
          <CardSection>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full flex-col items-center gap-2">
                <If condition={isSuccess}>
                  <IconSvg height="50px" svgComponent={<CompletedIcon />} width="50px" />
                  <Text fontWeight="medium" variant="headline">
                    {t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.updatedPlan')}
                  </Text>
                </If>
                <If condition={!isSuccess}>
                  <IconSvg height="50px" svgComponent={<CancelledIcon />} width="50px" />
                  <Text fontWeight="medium" variant="headline">
                    {t('MANAGE_PLAN.STEP_TWO.PAYMENT_STATUS.cancelled')}
                  </Text>
                </If>
                <Text
                  color="primary"
                  fontWeight="medium"
                  variant="text"
                  onClick={() => {
                    localStorage.removeItem(BILLING_FORM_DATA_KEY);
                    window.location.href = `/v2/${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}`;
                  }}
                >
                  {t('UPDATE_PLAN_STATUS.seeDetail')}
                </Text>
              </div>
              <If condition={priceIncreaseEmail !== 0}>
                <div className="mt-4 flex justify-between">
                  <div className="flex flex-col">
                    <Text fontWeight="medium">
                      {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.emails')}
                    </Text>
                    <Text variant="text">{`${increaseEmail > 0 ? '+' : '-'} ${numberToCurrency(
                      Math.abs(increaseEmail),
                      true
                    )} emails`}</Text>
                  </div>
                  <div className="flex flex-col items-end">
                    <Text fontWeight="medium" variant="title-1">
                      {`${priceIncreaseEmail > 0 ? '' : '-'} USD ${numberToCurrency(
                        Math.abs(priceIncreaseEmail)
                      )}`}
                    </Text>
                  </div>
                </div>
              </If>
              <If condition={priceIncreaseSms !== 0}>
                <div className={'flex justify-between'}>
                  <div className="flex flex-col">
                    <Text fontWeight="medium">
                      {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.sms')}
                    </Text>
                    <Text variant="text">{`${increaseSms > 0 ? '+' : '-'} ${numberToCurrency(
                      Math.abs(increaseSms),
                      true
                    )} SMS`}</Text>
                  </div>
                  <div className="flex flex-col items-end">
                    <Text fontWeight="medium" variant="title-1">
                      {`${priceIncreaseSms > 0 ? '' : '-'} USD ${numberToCurrency(
                        Math.abs(priceIncreaseSms)
                      )}`}
                    </Text>
                  </div>
                </div>
              </If>
              <If condition={priceIncreaseOnSite !== 0}>
                <div className={'flex justify-between'}>
                  <div className="flex flex-col">
                    <Text fontWeight="medium">
                      {t('MANAGE_PLAN.STEP_TWO.PLAN_PRICE_INFORMATION.onsite')}
                    </Text>
                    <Text variant="text">{`${increaseOnSite > 0 ? '+' : '-'} ${numberToCurrency(
                      Math.abs(increaseOnSite),
                      true
                    )} SMS`}</Text>
                  </div>
                  <div className="flex flex-col items-end">
                    <Text fontWeight="medium" variant="title-1">
                      {`${priceIncreaseOnSite > 0 ? '' : '-'} USD ${numberToCurrency(
                        Math.abs(priceIncreaseOnSite)
                      )}`}
                    </Text>
                  </div>
                </div>
              </If>
              <div className="mt-4 w-full border-b" />
              <div className="flex justify-between">
                <Text fontWeight="medium">
                  {t('UPDATE_PLAN_STATUS.totalText', { frequency: frequency.label })}
                </Text>
                <Text fontWeight="medium">{`USD ${numberToCurrency(Math.abs(total))}${
                  frequency.priceSuffix
                }`}</Text>
              </div>
            </div>
          </CardSection>
        </Card>
        <Card isHeightAuto noShadow color="gray">
          <CardSection>
            <Text isItalic alignment="justify" variant="text-sm">
              {t('UPDATE_PLAN_STATUS.subscriptionSuccess')}
              <If condition={downgrades.length > 0}>
                <Text isItalic alignment="justify" as="span" variant="text-sm">
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey={'UPDATE_PLAN_STATUS.downgradeNotification'}
                    values={{ value: downgrades }}
                  />
                </Text>
              </If>
              <Text isItalic alignment="justify" as="span" variant="text-sm">
                {t('UPDATE_PLAN_STATUS.smsNotification')}
              </Text>
            </Text>
          </CardSection>
        </Card>
      </div>
    </div>
  );
};

export default UpdatePlanStatus;
