export const EmailSplitIcon = () => {
  return (
    <svg fill="none" height="25" viewBox="0 0 39 25" width="39" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5776_499)">
        <path
          d="M27.0831 8.6598C27.5239 8.52788 27.94 8.29844 28.2923 7.96719L36.6555 0.110416C36.3901 0.0415854 36.1118 0 35.8232 0H16.5581C16.2696 0 15.9912 0.0401514 15.7259 0.110416L15.7607 0.143398C14.0903 0.546346 13.7308 1.66772 13.7308 1.66772C13.5075 2.08644 13.3799 2.55822 13.3799 3.06155V4.27326H17.9268L17.908 4.25462H20.1365L20.1554 4.27326H23.5409C23.5409 4.27326 25.7231 4.17001 26.709 6.23638C26.7496 6.31811 26.7888 6.40129 26.8236 6.48589C26.825 6.49019 26.8265 6.49306 26.8294 6.49736C26.8294 6.50023 26.8294 6.5031 26.8323 6.50597C26.9917 6.90891 27.0831 7.34628 27.0831 7.80659V8.65837V8.6598Z"
          fill="#0056B8"
        />
        <path
          d="M34.0645 13.9885H33.0495V15.4225H33.9978C34.2588 15.4225 34.4618 15.3608 34.6082 15.2375C34.7546 15.1142 34.8271 14.945 34.8271 14.7284C34.8271 14.2423 34.5719 13.9942 34.063 13.9871L34.0645 13.9885Z"
          fill="#0056B8"
        />
        <path
          d="M34.7068 12.5545C34.7068 12.3136 34.6357 12.14 34.4951 12.0339C34.353 11.9278 34.1297 11.8748 33.8252 11.8748H33.0495V13.2141H33.8687C34.427 13.2041 34.7068 12.9847 34.7068 12.553V12.5545Z"
          fill="#0056B8"
        />
        <path
          d="M38.0576 0.8862L28.2923 10.0622C27.94 10.3935 27.5239 10.6229 27.0831 10.7548V19.2469H35.8218C37.5762 19.2469 39 17.876 39 16.1868V3.06011C39 2.20976 38.639 1.43972 38.0576 0.884766V0.8862ZM35.4419 15.9014C35.1128 16.1638 34.6415 16.2986 34.0326 16.3029H31.9432V10.9886H33.8252C34.4777 10.9886 34.9721 11.1119 35.3085 11.3586C35.6449 11.6052 35.8145 11.968 35.8145 12.4441C35.8145 12.7036 35.7464 12.9345 35.6115 13.1324C35.4767 13.3303 35.2882 13.4765 35.0475 13.5683C35.323 13.6357 35.5405 13.7734 35.6985 13.9813C35.8566 14.1878 35.9363 14.4416 35.9363 14.7399C35.9363 15.2504 35.771 15.6375 35.4419 15.9V15.9014Z"
          fill="#0056B8"
        />
        <path
          d="M14.9125 13.7218L23.2741 5.86359C23.0088 5.79476 22.7304 5.75317 22.4419 5.75317H3.17823C2.8897 5.75317 2.61131 5.79333 2.34598 5.86359L10.7077 13.7218C11.8763 14.8202 13.7424 14.8202 14.9125 13.7218Z"
          fill="#0056B8"
        />
        <path d="M19.4782 19.6098H20.8209L20.1467 17.6208L19.4782 19.6098Z" fill="#0056B8" />
        <path
          d="M14.9124 15.8154C13.7438 16.9139 11.8778 16.9139 10.7077 15.8154L0.942449 6.6394C0.36103 7.19435 0 7.96297 0 8.81475V21.94C0 23.6306 1.42237 25.0001 3.17823 25.0001H22.4419C24.1963 25.0001 25.6201 23.6292 25.6201 21.94V8.81475C25.6201 7.9644 25.2591 7.19435 24.6777 6.6394L14.9124 15.8154ZM21.4936 21.5915L21.121 20.4959H19.1796L18.8098 21.5915H17.6325L19.6334 16.2772H20.6599L22.671 21.5915H21.4936Z"
          fill="#0056B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5776_499">
          <rect fill="white" height="25" width="39" />
        </clipPath>
      </defs>
    </svg>
  );
};
