export const TriggerEmailIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0496 11.7255L27.8608 2.4227C27.5521 2.33704 27.2262 2.28564 26.8831 2.28564H4.27627C3.93322 2.28564 3.60733 2.33704 3.29858 2.4227L13.1097 11.7255C14.4819 13.0275 16.6774 13.0275 18.0496 11.7255Z"
        fill="#0056B8"
      />
      <path
        d="M16.4203 21.8506C16.4546 19.5892 18.2213 17.6875 20.434 17.5333H20.7942C21.6861 17.5333 22.4923 17.7732 23.1955 18.2186C23.47 18.0473 23.7444 17.876 24.036 17.7046C23.8645 15.5803 24.825 13.9013 26.7118 13.1475C27.2435 12.9248 27.7924 12.822 28.3584 12.822C29.1817 12.822 29.9536 13.0618 30.6225 13.4901V5.91772C30.6225 4.90693 30.1937 3.99892 29.5248 3.3479L18.067 14.2268C16.6948 15.5289 14.4993 15.5289 13.1271 14.2268L1.66929 3.3479C0.98319 3.99892 0.571533 4.92406 0.571533 5.91772V21.4737C0.571533 23.4782 2.23531 25.1057 4.2936 25.1057H17.844C16.9692 24.2834 16.4032 23.1013 16.4375 21.8849V21.8506H16.4203Z"
        fill="#0056B8"
      />
      <path
        d="M30.3481 24.2832C29.3189 23.3581 27.4322 23.1696 26.0257 24.5059C25.2538 24.0262 24.482 23.5294 23.813 23.1182V20.7197C24.1218 20.5313 24.4991 20.2914 24.8765 20.0516C25.271 19.8117 25.6655 19.5719 26.0428 19.332C27.5694 20.5484 29.1474 20.6169 30.3824 19.5376C31.4973 18.5611 31.7546 16.865 30.9999 15.6315C30.1766 14.2952 28.5985 13.7812 27.1749 14.3466C25.6312 14.9633 24.9965 16.4196 25.4254 18.3555C24.7221 18.8009 24.0189 19.2464 23.2985 19.6747C23.1955 19.7261 22.9726 19.6747 22.8697 19.589C22.1836 19.0065 21.4117 18.7838 20.5198 18.8352C18.9932 18.938 17.7411 20.2914 17.7068 21.8847C17.6725 23.3752 18.856 24.7629 20.3139 24.9685C21.3945 25.1227 22.3208 24.7801 23.1098 24.0262C23.8988 24.5231 24.6707 25.0028 25.4254 25.4653C25.0308 27.5041 25.6655 28.9089 27.2264 29.5085C28.6157 30.0396 30.2109 29.5085 30.9656 28.2579C31.7717 26.9387 31.4973 25.294 30.3652 24.2832H30.3481Z"
        fill="#0056B8"
      />
      <path
        d="M26.7632 16.5397C26.8147 16.4369 26.8661 16.3341 26.9176 16.2485C26.9519 16.1971 26.9862 16.1628 27.0205 16.1114C27.3292 15.7345 27.8095 15.4775 28.3412 15.4775C28.7872 15.4775 29.216 15.6831 29.5419 15.9915C29.6276 16.0772 29.6963 16.1628 29.7477 16.2485C29.8506 16.3855 29.9192 16.5397 29.9707 16.6939C29.9707 16.7453 30.005 16.7967 30.0222 16.8481C30.0222 16.9509 30.0565 17.0708 30.0565 17.1736C30.0565 17.3621 30.0221 17.5334 29.9707 17.7047C29.9364 17.8075 29.8849 17.9274 29.8335 18.0131C29.6791 18.3043 29.4218 18.5442 29.1302 18.7155C28.8901 18.8526 28.5985 18.9211 28.2898 18.9211C28.1697 18.9211 28.0668 18.9211 27.9467 18.8868C27.4493 18.784 27.0376 18.4585 26.8147 18.0131C26.7117 17.8246 26.6431 17.6019 26.626 17.3792V17.2079C26.626 16.968 26.6774 16.7453 26.7632 16.5397Z"
        fill="#FAFBFD"
      />
      <path
        d="M22.1319 22.9986C22.0633 23.0843 21.9775 23.17 21.8918 23.2385C21.806 23.307 21.7203 23.3755 21.6173 23.4269C21.3772 23.564 21.1028 23.6325 20.8112 23.6325C20.6397 23.6325 20.4681 23.5983 20.2966 23.5469C20.0736 23.4783 19.885 23.3755 19.7134 23.2214C19.662 23.1871 19.6277 23.1528 19.5934 23.1014C19.5076 23.0158 19.439 22.9301 19.3875 22.8444C19.3532 22.7931 19.3189 22.7588 19.3018 22.7074C19.1646 22.4675 19.0959 22.1934 19.0959 21.9022C19.0959 21.7823 19.0959 21.6623 19.1303 21.5595C19.1303 21.4567 19.1817 21.3368 19.2332 21.234C19.2846 21.1312 19.3361 21.0284 19.3875 20.9428C19.5076 20.7543 19.6791 20.6001 19.8507 20.4802C20.1251 20.2918 20.4681 20.189 20.8283 20.189C21.1199 20.189 21.3944 20.2575 21.6345 20.3946C21.8746 20.5145 22.0805 20.7029 22.2348 20.9256C22.4235 21.1998 22.5264 21.5253 22.5264 21.8679C22.5264 22.2791 22.3892 22.6731 22.1319 22.9644V22.9986Z"
        fill="#FAFBFD"
      />
      <path
        d="M28.5127 28.3268H28.3412C28.2212 28.3268 28.1011 28.3268 27.981 28.2926C27.8609 28.2754 27.758 28.2412 27.6551 28.1898C27.6037 28.1726 27.5522 28.1384 27.5007 28.1212C27.0719 27.8814 26.746 27.4702 26.6603 26.9562C26.6603 26.8363 26.626 26.7164 26.626 26.5965C26.626 26.2367 26.746 25.9112 26.9347 25.6371C27.0033 25.5514 27.0719 25.4658 27.1405 25.3801C27.2606 25.2602 27.3978 25.1574 27.5351 25.0888C27.638 25.0374 27.7409 24.9861 27.8438 24.9689C27.9982 24.9175 28.1697 24.9004 28.3584 24.9004C28.8043 24.9004 29.2331 25.106 29.5419 25.4144C29.6619 25.5343 29.7649 25.6713 29.8335 25.8084C29.8678 25.8598 29.8849 25.9112 29.9021 25.9626C29.9878 26.1682 30.0393 26.3909 30.0393 26.6136C30.0393 27.5045 29.3704 28.2412 28.4956 28.3268H28.5127Z"
        fill="#FAFBFD"
      />
    </svg>
  );
};
