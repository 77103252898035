import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Option, toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

import { useBeefreeLogger } from './useBeefreeLogger';

export const useSendEmailTest = () => {
  const service = useService();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { logger } = useBeefreeLogger();

  const sendTestEmail = useCallback(
    async (actionId: number, contact: Option, group: Option) => {
      if (!actionId || !group.id) return;

      setLoading(true);

      const groupId = typeof group.id === 'string' ? parseInt(group.id) : group.id;
      const contactId = typeof contact.id === 'string' ? parseInt(contact.id) : contact.id;
      try {
        const success = await service.sendEmailTest(actionId, groupId, contactId);

        if (success) {
          await logger('sendTest', 'action');
        } else {
          await logger('sendTest', 'error');
        }

        toast({
          title: success
            ? t('EDITOR_CONTENT.PREVIEW_LIVE.notification.success.title')
            : t('EDITOR_CONTENT.PREVIEW_LIVE.notification.error.title'),
          body: success
            ? t('EDITOR_CONTENT.PREVIEW_LIVE.notification.success.body')
            : t('EDITOR_CONTENT.PREVIEW_LIVE.notification.error.body'),
          variant: success ? 'success' : 'error',
        });
      } catch (error) {
        // cspell:disable-next-line
        if ((error as Error).message === 'GRUPO_PRUEBA_SIN_DESTINATARIOS') {
          await logger('sendTest', 'error', {
            actionId,
            contact,
            group,
          });
          toast({
            title: t('EDITOR_CONTENT.PREVIEW_LIVE.notification.errorGroupWithoutRecipients.title'),
            body: t('EDITOR_CONTENT.PREVIEW_LIVE.notification.errorGroupWithoutRecipients.body'),
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [service, t]
  );

  return {
    sendTestEmail,
    loading,
  };
};
