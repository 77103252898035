export const QuestionCircleIcon = () => {
  return (
    <svg className="h-full w-full" fill="gray" version="1.1" viewBox="0 0 310.277 310.277">
      <g>
        <path
          d="M155.139,0C69.598,0,0,69.598,0,155.139c0,85.547,69.598,155.139,155.139,155.139
                    s155.139-69.592,155.139-155.139C310.283,69.592,240.686,0,155.139,0z M149.291,227.815c-7.309,0-12.322-5.639-12.322-12.948
                    c0-7.727,5.227-13.157,12.536-13.157c7.315,0,12.322,5.43,12.322,13.157C161.822,222.176,157.018,227.815,149.291,227.815z
                    M169.549,149.494c-9.183,10.86-12.53,20.049-11.904,30.706l0.209,5.43h-16.29l-0.418-5.43
                    c-1.253-11.283,2.506-23.599,12.954-36.135c9.404-11.069,14.625-19.219,14.625-28.617c0-10.651-6.689-17.751-19.852-17.96
                    c-7.518,0-15.872,2.506-21.093,6.474l-5.012-13.157c6.892-5.012,18.796-8.354,29.87-8.354c24.023,0,34.882,14.828,34.882,30.706
                    C187.521,127.357,179.579,137.59,169.549,149.494z"
          fill="#d1d5db"
        />
      </g>
    </svg>
  );
};
