export const EmptyTagsIcon = () => {
  return (
    <svg
      height="176.989"
      viewBox="0 0 188.735 176.989"
      width="188.735"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Grupo 6958" id="Grupo_6958" transform="translate(1242.776 -1302.423)">
        <g data-name="Grupo 6956" id="Grupo_6956" opacity="0.6" transform="translate(-41)">
          <path
            d="M212.8,79.4l6.7,82.2a9.621,9.621,0,0,1-8.7,10.4l-83.2,7.8a9.606,9.606,0,0,1-10.5-8.8l-6.4-83.6a9.641,9.641,0,0,1,3.4-8.1l39.5-33.1a9.482,9.482,0,0,1,11.2-.8L208.3,72A9.521,9.521,0,0,1,212.8,79.4Z"
            data-name="Trazado 4454"
            fill="#004dbc"
            id="Trazado_4454"
            transform="translate(-1312.454 1282.027)"
          />
          <circle
            cx="10.3"
            cy="10.3"
            fill="#dadada"
            r="10.3"
            stroke="#231f20"
            strokeWidth="1.082"
            transform="translate(-1160.754 1342.927)"
          />
          <path
            d="M166.5,81c-14.4-16.5-29-54.4-9.6-59.4,20-5.1,18.6,22.4,16.5,28.6"
            data-name="Trazado 4455"
            fill="none"
            id="Trazado_4455"
            stroke="#231f20"
            strokeWidth="1.082"
            transform="translate(-1312.454 1282.027)"
          />
          <path
            d="M259.1,72.5l34.2,75.1a9.586,9.586,0,0,1-4.7,12.7L213,195.9a9.654,9.654,0,0,1-12.9-4.8l-34.4-76.4a9.47,9.47,0,0,1,.5-8.8l25.9-44.5a9.682,9.682,0,0,1,10.3-4.6l50,10.3A9.4,9.4,0,0,1,259.1,72.5Z"
            data-name="Trazado 4456"
            fill="#f4f5fa"
            id="Trazado_4456"
            stroke="#231f20"
            strokeWidth="1.082"
            transform="translate(-1312.454 1282.027)"
          />
          <path
            d="M259.1,72.5a9.562,9.562,0,0,0-6.8-5.4l-45.2-9.3a9.5,9.5,0,0,0-7.5,4.7L173.7,107a9.77,9.77,0,0,0-.5,8.8l34.4,76.4a9.46,9.46,0,0,0,3.7,4.2,5.775,5.775,0,0,0,1.7-.6l75.7-35.6a9.673,9.673,0,0,0,4.7-12.7Z"
            data-name="Trazado 4457"
            fill="#004dbc"
            id="Trazado_4457"
            transform="translate(-1312.454 1282.027)"
          />
          <circle
            cx="10.3"
            cy="10.3"
            fill="#dadada"
            r="10.3"
            stroke="#231f20"
            strokeWidth="1.082"
            transform="translate(-1114.254 1353.727)"
          />
          <path
            d="M216.1,89.7c-19.2-10.6-45.7-41.3-29.2-52.6C204,25.5,212,51.9,212.1,58.5"
            data-name="Trazado 4458"
            fill="none"
            id="Trazado_4458"
            stroke="#231f20"
            strokeWidth="1.082"
            transform="translate(-1312.454 1282.027)"
          />
        </g>
        <g data-name="Grupo 6957" id="Grupo_6957" transform="translate(-2255.103 675.275)">
          <circle
            cx="26.522"
            cy="26.522"
            fill="#fff"
            r="26.522"
            transform="translate(1143.453 746.322)"
          />
          <path
            d="M33.943,3.375A30.568,30.568,0,1,0,64.51,33.943,30.563,30.563,0,0,0,33.943,3.375Zm13.3,32.919H36.294V47.242a2.351,2.351,0,0,1-4.7,0V36.294H20.643a2.351,2.351,0,0,1,0-4.7H31.591V20.643a2.351,2.351,0,0,1,4.7,0V31.591H47.242a2.351,2.351,0,0,1,0,4.7Z"
            data-name="Icon ionic-ios-add-circle"
            fill="#fc7e00"
            id="Icon_ionic-ios-add-circle"
            transform="translate(1136.552 739.057)"
          />
        </g>
      </g>
    </svg>
  );
};
