import { Text } from '@/lib/v2/components';

interface ICountSMSProps {
  count: number;
  length: number;
  maxLength: number;
}

const SMS_Text = 'SMS';

const CountSMS = ({ count, length, maxLength }: ICountSMSProps) => {
  return (
    <div className="w-32 pr-2">
      <Text alignment="right" className="mt-1" fontWeight="normal" variant="text">
        {length}/{maxLength}
        <Text as="span" className="ml-2" color="primary" fontWeight="bold" variant="text">
          {` ${count} `}
        </Text>
        {SMS_Text}
      </Text>
    </div>
  );
};

export default CountSMS;
