import { useTranslation } from 'react-i18next';

import { InformationIcon } from '@/lib/icon';
import { Flex } from '@/lib/v2/components/Layout/Stack';

//TO DO: migrate this method to a hook
export const chatShow = () => {
  // cspell:disable
  const zohoChat = document.querySelector("[data-id='zsalesiq']") as HTMLButtonElement;
  if (zohoChat) zohoChat?.click();
  // cspell:enable
};

export const SupportCard = () => {
  const { t } = useTranslation();

  return (
    <div className="h-modal fixed left-[-78px] z-50 flex items-center justify-start md:h-full">
      <button
        className="card h-6 w-44 -rotate-90 cursor-pointer rounded-lg p-2 shadow"
        onClick={chatShow}
      >
        <Flex withGap alignment="center" gapSize="small">
          <InformationIcon />
          <div className="text-12 font-medium text-sky-700">{t('needHelp')}</div>
        </Flex>
      </button>
    </div>
  );
};
