import { CardSection, Flex } from '@/lib/v2/components';

import { WhatsAppAction } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction';

const WhatsAppMain = () => {
  return (
    <div className="relative z-[0] bg-white">
      <CardSection noPadding>
        <Flex column>
          <WhatsAppAction />
        </Flex>
      </CardSection>
    </div>
  );
};

export default WhatsAppMain;
