import { useCallback, useEffect, useState } from 'react';

export type ChangeTablePage = ({
  pageSize,
  pageIndex,
}: {
  pageSize: number;
  pageIndex: number;
}) => void;

export const useTablePagination = (listTotal?: number) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gotoPageIndex, setGotoPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [lastPageViewed, setLastPageViewed] = useState<number>(0);
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [orderColumn, setOrderColumn] = useState('');

  const changeTablePage = useCallback(
    ({ pageSize: vPageSize, pageIndex: vPageIndex }: { pageSize: number; pageIndex: number }) => {
      setPageSize(vPageSize);
      setPageIndex(++vPageIndex);
      setIsLoading(true);
      setGotoPageIndex(1);
    },
    []
  );

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'asc' : 'desc';
      setOrderDirection(orderDirectionValue);
      setOrderColumn(element.id);
      setIsLoading(true);
    },
    [setIsLoading]
  );

  useEffect(() => {
    if (listTotal && pageSize) {
      setTotalPages(Math.ceil(listTotal / pageSize));
    }
  }, [listTotal, pageSize]);

  const resetPagination = useCallback(() => {
    setPageIndex(1);
    setGotoPageIndex(0);
    setLastPageViewed(0);
    setOrderColumn('');
    setOrderDirection('desc');
  }, []);

  return {
    pageSize,
    pageIndex,
    totalPages,
    isLoading,
    setIsLoading,
    gotoPageIndex,
    setGotoPageIndex,
    changeTablePage,
    resetPagination,
    lastPageViewed,
    setLastPageViewed,
    changeOrderBy,
    orderColumn,
    orderDirection,
  };
};
