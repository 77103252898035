export const ContactMailIcon = () => {
  return (
    <svg
      data-name="Icon ionic-ios-mail"
      height="22.478"
      id="Icon_ionic-ios-mail"
      viewBox="0 0 32.469 22.478"
      width="32.469"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.578,10.361l-8.4,8.44a.148.148,0,0,0,0,.216l5.877,6.176a.99.99,0,0,1,0,1.417,1.027,1.027,0,0,1-1.436,0l-5.854-6.153a.162.162,0,0,0-.226,0L24.113,21.89a6.326,6.326,0,0,1-4.48,1.864,6.453,6.453,0,0,1-4.574-1.918l-1.374-1.378a.162.162,0,0,0-.226,0L7.605,26.61a1.027,1.027,0,0,1-1.436,0,.99.99,0,0,1,0-1.417l5.877-6.176a.162.162,0,0,0,0-.216L3.64,10.361a.154.154,0,0,0-.265.108V27.357a2.488,2.488,0,0,0,2.5,2.464H33.346a2.488,2.488,0,0,0,2.5-2.464V10.469A.156.156,0,0,0,35.578,10.361Z"
        data-name="Path 2778"
        fill="#11cffa"
        id="Path_2778"
        transform="translate(-3.375 -7.343)"
      />
      <path
        d="M19.424,23.556a4.032,4.032,0,0,0,3.033-1.4L34.63,8.474a2.3,2.3,0,0,0-1.536-.6H5.762a2.286,2.286,0,0,0-1.536.6L16.4,22.151A4.033,4.033,0,0,0,19.424,23.556Z"
        data-name="Path 2779"
        fill="#11cffa"
        id="Path_2779"
        transform="translate(-3.19 -7.875)"
      />
    </svg>
  );
};
