import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { IOptionAccordion } from '@/lib/v2/components/Accordion/Accordion2';

import { ICategory, ICollection } from '@/src/ContactsModule/interfaces/Interfaces';
import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import {
  atomFiltersCatalog,
  atomFiltersCatalogData,
  atomFiltersCategories,
  atomFiltersCollections,
  atomFiltersCollectionsSelected,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

export type ICategoryFilter = ICategory &
  {
    collections: IOptionAccordion[];
  }[];
export const useCatalogFilters = () => {
  const [catalog] = useAtom(atomFiltersCatalogData);
  const [categories, setCategories] = useAtom(atomFiltersCategories);
  const [collectionsSelected, setCollectionsSelected] = useAtom(atomFiltersCollectionsSelected);
  const [collections, setCollections] = useAtom(atomFiltersCollections);
  const [filters, setFilters] = useAtom(atomFiltersCatalog);
  const service = useBeefreeService();

  const getFilters = useCallback(async () => {
    const categoriesData = (await service.getCategories()) ?? [];
    const collectionsData = (await service.getCollections()) ?? [];

    setCategories(categoriesData);
    setCollections(collectionsData);
  }, [service, setCategories, setCollections]);

  const updateFilters = useCallback(
    (type: string, value: IOptionAccordion[] | string) => {
      setFilters({
        ...filters,
        [type]: value,
      });
    },
    [filters, setFilters]
  );

  useEffect(() => {
    void getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result: IOptionAccordion[] = [];

    if (collectionsSelected === null) return;

    const selected: ICollection[][] = Object.values(collectionsSelected);
    for (const collections of selected) {
      if (Array.isArray(collections))
        for (const item of collections) {
          result.push({
            id: typeof item.id === 'number' ? item.id : parseInt(item.id),
            name: item.name,
          });
        }
    }
    updateFilters(
      'collections',
      result.map((item) => ({ id: item.id, name: item.name }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsSelected]);

  return {
    catalog,
    updateFilters,
    categories,
    collections,
    filters,
    collectionsSelected,
    setCollectionsSelected,
  };
};
