import { useState } from 'react';

import { Container } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useNpsStateContext } from '@/src/modules/NPSModule/contexts/NpsContext';

import { WidgetsFilterCards } from '@/modules/NPSModule/components/WidgetsFilterCards';
import { WidgetsMain } from '@/modules/NPSModule/components/WidgetsMain/WidgetsMain';

export const NPSMain = () => {
  const [iFrameOnsite, setIframeOnsite] = useState(true);
  const handleCardToggle = (showCards: boolean) => {
    setIframeOnsite(!showCards);
  };
  const { isRecycleBin } = useNpsStateContext();

  return (
    <Flex column>
      <div
        className="contacts-body min-h-[calc(100vh_-_64px)] w-full bg-emblueLightGray"
        id="contacts-module"
      >
        <Container fluid="screen">
          {iFrameOnsite && !isRecycleBin && <WidgetsFilterCards />}
          <WidgetsMain onCardToggle={handleCardToggle} />
        </Container>
      </div>
    </Flex>
  );
};
