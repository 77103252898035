export const SubscriptionIcon = () => {
  return (
    <svg
      height="58.355"
      id="pay-per-click"
      viewBox="0 0 58.355 58.355"
      width="58.355"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.957,45.59a.912.912,0,0,0,.912.912h3.776c-.259-.589-.5-1.2-.743-1.824H16.868a.912.912,0,0,0-.912.912ZM54.708,0H3.647A3.651,3.651,0,0,0,0,3.647v35.56a3.651,3.651,0,0,0,3.647,3.647H19.162c-.16-.315-.359-.681-.556-1.042-.134-.246-.277-.508-.422-.781H3.647a1.824,1.824,0,0,1-1.824-1.824V3.647A1.824,1.824,0,0,1,3.647,1.824H54.708a1.824,1.824,0,0,1,1.824,1.824v35.56a1.824,1.824,0,0,1-1.824,1.824H46.5v1.824h8.206a3.651,3.651,0,0,0,3.647-3.647V3.647A3.651,3.651,0,0,0,54.708,0Z"
        data-name="Path 3404"
        fill="#2dcc85"
        id="Path_3404"
      />
      <path
        d="M42.887,63.459c.4,1.067.814,2.17,1.255,3.154a25.5,25.5,0,0,0,2.917,5.706,8.273,8.273,0,0,1,1.833,5.231,1.485,1.485,0,0,0,1.491,1.475H61.732a1.487,1.487,0,0,0,1.494-1.475,8.488,8.488,0,0,1,1.142-2.919,14.343,14.343,0,0,0,2.057-6.614v-4.1c0-.052.007-.1.007-.158V58.65a3.627,3.627,0,0,0-3.71-3.531,3.855,3.855,0,0,0-.824.09,3.74,3.74,0,0,0-3.108-1.616,3.865,3.865,0,0,0-.636.052,3.758,3.758,0,0,0-3.474-1.909V41.626a3.635,3.635,0,1,0-7.269,0V55.382c-.356-.482-.711-.923-1.059-1.316a3.768,3.768,0,0,0-5.21-.378c-2.4,2-.541,5.424.82,7.922A17.278,17.278,0,0,1,42.887,63.459Zm-.579-8.37a1.945,1.945,0,0,1,2.68.19,22.631,22.631,0,0,1,2.544,3.633.912.912,0,0,0,1.7-.456V41.626a1.775,1.775,0,0,1,1.781-1.807,1.805,1.805,0,0,1,1.841,1.807V52.847a.912.912,0,0,0,1.239.851,2.136,2.136,0,0,1,.76-.142,1.847,1.847,0,0,1,1.853,1.387.912.912,0,0,0,1.264.646,1.992,1.992,0,0,1,.817-.172A1.883,1.883,0,0,1,60.54,56.51a.885.885,0,0,0,.072.134.912.912,0,0,0,1.248.494,1.989,1.989,0,0,1,.861-.2,1.805,1.805,0,0,1,1.886,1.707v5.114a.891.891,0,0,0-.007.112v4.142a12.944,12.944,0,0,1-1.863,5.8A10.6,10.6,0,0,0,61.422,77.2H50.711a10.024,10.024,0,0,0-2.128-5.885,23.848,23.848,0,0,1-2.735-5.351c-.008-.023-.017-.046-.027-.068-.423-.939-.831-2.027-1.226-3.08a18.242,18.242,0,0,0-1.032-2.08C42.5,58.8,41.054,56.135,42.309,55.089Z"
        data-name="Path 3405"
        fill="#2dcc85"
        id="Path_3405"
        transform="translate(-21.753 -20.671)"
      />
      <path
        d="M59.234,43.269v.005a8.665,8.665,0,1,0-10.916.18v-7.28a5.458,5.458,0,1,1,10.916,0Z"
        data-name="Path 3406"
        fill="#2dcc85"
        id="Path_3406"
        transform="translate(-24.484 -15.219)"
      />
      <path
        d="M43.283,41.714a5.6,5.6,0,0,1,3.6,1.52h.07c.182,0,.362.01.54.026.072.007.143.02.214.029.1.014.205.026.306.045s.182.041.273.063c.075.018.151.035.225.056.1.03.207.065.309.1.055.019.11.037.164.058.113.043.224.092.333.142l.113.053c.119.058.236.121.35.187l.069.041q.185.111.36.234l.031.023c.125.09.245.183.362.282h5.075a3.651,3.651,0,0,0,3.647-3.647V23.147A3.651,3.651,0,0,0,55.678,19.5H14.647A3.651,3.651,0,0,0,11,23.147v17.78a3.651,3.651,0,0,0,3.647,3.647h7.948a4.88,4.88,0,0,1,1.611-2.348,5.594,5.594,0,0,1,5.613-.909v-.263a10.487,10.487,0,1,1,11.676-.665,5.586,5.586,0,0,1,1.789,1.326Z"
        data-name="Path 3407"
        fill="#2dcc85"
        id="Path_3407"
        transform="translate(-5.985 -10.61)"
      />
    </svg>
  );
};

export const SubscriptionImage = () => (
  <div className={'flex h-32 w-32 items-center justify-center rounded-full bg-emblueLightBlue/25'}>
    <SubscriptionIcon />
  </div>
);
