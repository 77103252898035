import { PlusCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import _ from 'lodash';
import {
  HTMLInputTypeAttribute,
  KeyboardEvent,
  memo,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useOnClickOutside } from '@/lib/v2/hooks/useOnClickOutside';

import './MultiValueInput.tailwind.css';

export interface MultiValueInputProps {
  /** controlled input value */
  value?: string[];
  /** default value input not controlled */
  defaultValue?: string;
  /** indicate the type of input (text, number, email, etc) */
  type?: HTMLInputTypeAttribute;
  /** name label of input */
  label?: string;
  /** name of input */
  name?: string;
  /** show input placeholder message */
  placeHolder?: string;
  /** HTML id attribute */
  id?: string;
  /** callback function onClick of input */
  onClick?: MouseEventHandler<HTMLInputElement>;
  /** callback function onChange of input */
  onChange?: (values: string[]) => void;
  /** indicates that it can only have a range between value. */
  isBeetWeen?: boolean;
  disabled?: boolean;
}

const MultiValueInput = ({
  value,
  label,
  onClick,
  onChange,
  type = 'text',
  name,
  id,
  placeHolder,
  isBeetWeen = false,
  disabled,
}: MultiValueInputProps): JSX.Element => {
  const [selected, setSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [valueBeetWeen, setValueBeetWeen] = useState<string[]>(value || []);

  const divRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside<HTMLDivElement>(divRef, () => setSelected(false));

  const classes = classNames('eb-multi-value-input');
  const classesDiv = classNames('eb-multi-value-input--input', 'relative overflow-x-hidden', {
    '!border-emblue-primary': selected,
    disabled,
  });
  const classesInput = classNames('eb-multi-value-input--input');

  const deleteValue = useCallback(
    (index?: number) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (isBeetWeen) {
        setValueBeetWeen([]);
        onChange?.([]);
      }
      if (Array.isArray(value) && index !== undefined && !isBeetWeen) {
        const newValues = [...value];
        newValues.splice(index, 1);
        onChange?.(newValues);
      }
    },
    [isBeetWeen, onChange, value]
  );

  const renderLabels =
    Array.isArray(value) &&
    value.map((val, index) => (
      <span
        key={`${val}-${_.uniqueId(index.toString())}`}
        className="ml-0.5 inline-flex items-center rounded-full bg-blue-50 pl-2 pr-0.5
                text-base font-medium text-emblue-primary"
      >
        {val}
        <button
          className="ml-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full
                    text-emblue-primary hover:bg-indigo-200 hover:text-emblue-primary-hover
                    focus:bg-blue-400 focus:text-white focus:outline-none"
          type="button"
          onClick={deleteValue(index)}
        >
          <span className="sr-only">Remove small option</span>
          <svg className="h-2 w-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
            <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
          </svg>
        </button>
      </span>
    ));

  const renderLabelsBeetWeen = Array.isArray(value) && isBeetWeen && (
    <span
      className="ml-0.5 inline-flex items-center rounded-full bg-blue-50 pl-2 pr-0.5
                text-base font-medium text-emblue-primary"
    >
      {`${valueBeetWeen[0] ? valueBeetWeen[0] : ''} - ${valueBeetWeen[1] ? valueBeetWeen[1] : ''}`}
      <button
        className="ml-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full
                    text-emblue-primary hover:bg-indigo-200 hover:text-emblue-primary-hover
                    focus:bg-blue-400 focus:text-white focus:outline-none"
        type="button"
        onClick={deleteValue()}
      >
        <svg className="h-2 w-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
          <path d="M1 1l6 6m0-6L1 7" strokeLinecap="round" strokeWidth="1.5" />
        </svg>
      </button>
    </span>
  );

  const multiValuesElements = isBeetWeen ? renderLabelsBeetWeen : renderLabels;

  const addValue = () => {
    if (inputValue.length === 0) return;
    let newValues: string[] = [];
    if (isBeetWeen) {
      newValues = valueBeetWeen?.length === 2 ? [inputValue] : [...valueBeetWeen, inputValue];
      newValues.length === 2 ? onChange?.(newValues) : onChange?.([]);
      setValueBeetWeen(newValues);
    } else {
      newValues = value ? [...value, inputValue] : [];
      onChange?.(newValues);
    }
    setInputValue('');
  };

  const keyDownEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.length > 0) {
      addValue();
    }
  };

  const handleClickOpenInput = useCallback(() => {
    setSelected((prevSelected) => !prevSelected);
  }, []);

  useEffect(() => {
    if (selected) inputRef.current?.focus();
  }, [selected]);

  useEffect(() => {
    if (isBeetWeen && value?.length !== 0) {
      setValueBeetWeen(value?.slice(0, 2) || []);
    }
  }, [isBeetWeen, value]);

  return (
    <div className={classes} data-testid="multi-value-input-component">
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <div ref={divRef} className="relative rounded-md">
        <div
          aria-hidden
          className={classesDiv}
          onClick={disabled ? undefined : handleClickOpenInput}
        >
          <div className="eb-multi-value-input--div flex flex-row text-[#A7B1CC]">
            {(value && value?.length > 0) || (valueBeetWeen && valueBeetWeen?.length > 0)
              ? multiValuesElements
              : placeHolder}
          </div>
        </div>
        {selected && (
          <div className="absolute z-20 w-full">
            <div className="relative rounded-md">
              <input
                ref={inputRef}
                name={name}
                type={type}
                value={inputValue}
                {...(id && { id })}
                className={classesInput}
                placeholder={placeHolder}
                onChange={(e) => setInputValue(e.target.value)}
                onClick={onClick}
                onKeyDown={keyDownEnter}
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                id="add-value-button"
                onClick={addValue}
              >
                <PlusCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-500" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(MultiValueInput);
