export const AcademyIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6147 5.7572V3.38828C19.6147 2.17905 18.6356 1.20001 17.4264 1.20001H5.40697C4.58528 1.20001 3.81603 1.52636 3.24493 2.11786C2.69422 2.68896 2.39701 3.43198 2.39992 4.22162V19.7638C2.39992 21.4422 3.76067 22.8 5.43611 22.8H19.0989C20.7773 22.8 22.1351 21.4393 22.1351 19.7638V8.27472C22.1351 6.88484 21.0075 5.75428 19.6176 5.75428M3.97921 3.61555H16.5202C16.6601 3.61555 16.7708 3.50191 16.7708 3.36497C16.7708 3.22802 16.6572 3.11438 16.5202 3.11438H4.31429C4.60859 2.82008 5.01652 2.65983 5.43611 2.65983H17.4293C17.8314 2.65983 18.1578 2.98617 18.1578 3.38828V5.7572H5.43611C5.06023 5.7572 4.71348 5.62316 4.43958 5.40171H16.5173C16.6572 5.40171 16.7679 5.28807 16.7679 5.15113C16.7679 5.01418 16.6542 4.90054 16.5173 4.90054H4.02874C3.97047 4.7869 3.92384 4.66452 3.8947 4.53631H16.5173C16.6572 4.53631 16.7679 4.42268 16.7679 4.28573C16.7679 4.14878 16.6542 4.03514 16.5173 4.03514H3.86848C3.88596 3.89236 3.92093 3.7525 3.97338 3.62138M20.212 19.9066C20.2062 20.7021 19.5593 21.3431 18.7667 21.3431H11.6425C11.6425 21.3431 11.6163 21.346 11.6017 21.346H5.43611C4.56488 21.346 3.85974 20.6409 3.85974 19.7697V6.77994C4.33178 7.06549 4.87374 7.21701 5.43611 7.21701H8.05561L18.8454 7.20244C19.6467 7.20244 20.2965 7.85513 20.2907 8.65934L20.2149 19.9037L20.212 19.9066Z"
        fill="#A7B1CC"
      />
      <path
        d="M17.4523 13.2805L10.331 9.16917C10.0425 9.00308 9.73074 8.91858 9.4044 8.91858C8.99355 8.91858 8.67012 9.05553 8.47198 9.16917C7.88922 9.50425 7.54248 10.1074 7.54248 10.7805V17.7153C7.54248 18.3884 7.88922 18.9887 8.47198 19.3267C8.67012 19.4432 8.99646 19.5773 9.40731 19.5773C9.73366 19.5773 10.0454 19.4928 10.3339 19.3267L17.4552 15.2153C17.802 15.0172 18.0147 14.6471 18.0147 14.2479C18.0147 13.8487 17.802 13.4816 17.4552 13.2805M15.3398 15.1454L9.47433 11.7595C9.36652 11.6954 9.26162 11.675 9.17129 11.675C9.02852 11.675 8.9178 11.7275 8.85952 11.7595C8.80998 11.7887 8.7284 11.8499 8.66138 11.946V10.7776C8.66138 10.39 8.92071 10.1977 9.03434 10.1336C9.10136 10.0928 9.23831 10.0317 9.41023 10.0317C9.52095 10.0317 9.64624 10.0579 9.77736 10.1336L16.8987 14.245L15.3398 15.1454Z"
        fill="#A7B1CC"
      />
    </svg>
  );
};
