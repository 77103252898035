import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';

import {
  atomCampaignsRecurrentList,
  atomDetailCampaignRecurrent,
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomActionMaster,
  atomActionType,
  atomRecurrentActionsList,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

export const useRecurrentResetAtomOnUnmount = () => {
  const setTotalsProcess = useSetAtom(atomTotalsProcess);
  const setLoadingProcess = useSetAtom(atomLoadingProcess);
  const setCampaignList = useSetAtom(atomCampaignsRecurrentList);
  const setActionType = useSetAtom(atomActionType);

  const setActionsList = useSetAtom(atomRecurrentActionsList);
  const setDetailCampaign = useSetAtom(atomDetailCampaignRecurrent);
  const setActionMaster = useSetAtom(atomActionMaster);

  const resetAtomActionsList = () => {
    setActionsList(RESET);
    setDetailCampaign(RESET);
    setActionType(RESET);
    setTotalsProcess(RESET);
    setActionMaster(RESET);
  };

  const resetAtomProcess = () => {
    setCampaignList(RESET);
    setTotalsProcess(RESET);
    setLoadingProcess(RESET);
  };

  return { resetAtomProcess, resetAtomActionsList };
};
