import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomActionType, atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useFiltersActions } from '@/modules/CampaignsModule/hooks/useFiltersActions';
import {
  EActionTypes,
  EDateFilterType,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EFilterKeysAction } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useInputDate = () => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersActions();
  const filtersActions = useAtomValue(atomFiltersAction);
  const actionType = useAtomValue(atomActionType);

  const id = '"date-campaigns-action"';

  useEffect(() => {
    if (!filtersActions || (!filtersActions.startDate && !filtersActions.endDate)) {
      setDateRange([null, null]);
    } else {
      setDateRange([filtersActions.startDate ?? null, filtersActions.endDate ?? null]);
    }
  }, [filtersActions]);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersActions?.startDate ?? null,
    filtersActions?.endDate ?? null,
  ]);

  const { dateFilterType, placeholder } =
    actionType === EActionTypes.FINISHED
      ? {
          dateFilterType: EDateFilterType.EXE_DATE,
          placeholder: t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.exeDate'),
        }
      : {
          dateFilterType: EDateFilterType.CREATION_DATE,
          placeholder: t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.creationDate'),
        };

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        event[0] &&
          event[1] &&
          updateFilter(EFilterKeysAction.Date, [event[0], event[1]], dateFilterType);
      }
    },
    [dateFilterType, updateFilter]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    id,
    dateRange,
    placeholder,
    handleOnClickDate,
    resetDateRange,
  };
};
