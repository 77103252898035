import { atom } from 'jotai';

import { Option } from '@/lib/v2/components/Select/Select';

import { IMappingField } from '@/src/ContactsModule/components/ContactsMappingField/ContactsMappingField';
import { FileDataQuantity } from '@/src/presentation/types/interfaces/IImport.interface';

/**
 * @desc Atom of mapping for second step of import contacts
 */
export const atomMappingList = atom<IMappingField>({
  mappingList: [],
});
atomMappingList.debugLabel = 'atomMappingList';

/**
 * @desc Atom of mapping for second step of import contacts
 */
export const atomFileDataQuantity = atom<FileDataQuantity>({
  quantityColumns: 0,
  quantityContacts: 0,
});
atomFileDataQuantity.debugLabel = 'atomFileDataQuantity';

/**
 * @desc Atom of list of custom fields of import contacts
 */
export const atomListCustomField = atom<Option[]>([]);
atomListCustomField.debugLabel = 'atomListCustomField';

/**
 * @desc Atom of codification
 */
export const atomCodifications = atom<Option[]>([]);
atomCodifications.debugLabel = 'atomCodifications';

/**
 * @desc Atom of codification
 */
export const atomCodification = atom<Option>({
  id: '0',
  name: 'utf-8',
  value: '0',
});
atomCodification.debugLabel = 'atomCodification';
