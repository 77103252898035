import { useMemo } from 'react';

import {
  FiltersType,
  SegmentFilterRelationType,
} from '@/src/modules/ContactsModule/types/Segments';

export type RestrictedPair = {
  filterOne: SegmentFilterRelationType;
  filterTwo: SegmentFilterRelationType;
};

export const useFiltersValidator = (filters: FiltersType) => {
  const currentFiltersWithRestriction = useMemo<RestrictedPair[]>(() => {
    const restrictedPairs: RestrictedPair[] = [
      { filterOne: 'groups', filterTwo: 'ecommerceGroups' },
    ];
    const filtersWithRestriction: RestrictedPair[] = [];

    for (const restrictedPair of restrictedPairs) {
      const filterOneIndex = filters.findIndex(
        (filter) => filter.field?.name === restrictedPair.filterOne
      );
      const filterTwoIndex = filters.findIndex(
        (filter) => filter.field?.name === restrictedPair.filterTwo
      );

      if (filterOneIndex !== -1 && filterTwoIndex !== -1) {
        if (filterTwoIndex > filterOneIndex) {
          filtersWithRestriction.push({
            filterOne: restrictedPair.filterOne,
            filterTwo: restrictedPair.filterTwo,
          });
        } else {
          filtersWithRestriction.push({
            filterOne: restrictedPair.filterTwo,
            filterTwo: restrictedPair.filterOne,
          });
        }
      }
    }

    return filtersWithRestriction;
  }, [filters]);

  return { currentFiltersWithRestriction };
};
