import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  atomActionType,
  atomFiltersAction,
  atomStatusFilterCount,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { useFiltersActions } from '@/modules/CampaignsModule/hooks/useFiltersActions';
import { EActionTypes } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EFilterKeysAction } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useFiltersDropdown = (onClose?: () => void) => {
  const { t } = useTranslation();

  const { updateFilter } = useFiltersActions();
  const setFiltersAction = useSetAtom(atomFiltersAction);
  const actionType = useAtomValue(atomActionType);

  const [selectedChannel, setSelectedChannel] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const filtersActions = useAtomValue(atomFiltersAction);
  const [statusFilterCount, setStatusFilterCount] = useAtom(atomStatusFilterCount);

  useEffect(() => {
    if (Object.keys(filtersActions ?? {}).length === 0) {
      setStatusFilterCount(0);
    }
  }, [filtersActions, setStatusFilterCount]);

  const countFilters = Number(statusFilterCount) + Number(filtersActions?.channel?.length ?? 0);
  const placeholderFilters =
    countFilters > 0
      ? `${t('TABLE_FILTER_BAR.filters')} (${countFilters})`
      : t('TABLE_FILTER_BAR.filters');

  const isNotDraftOrFinished =
    actionType !== EActionTypes.DRAFT && actionType !== EActionTypes.FINISHED;

  const onApplyFilters = useCallback(() => {
    updateFilter(EFilterKeysAction.Channel, selectedChannel);
    setFiltersAction((prevFilters) => ({
      ...prevFilters,
      channel: selectedChannel,
      status: selectedStatus,
    }));
    setFiltersApplied(true);
    onClose?.();
  }, [updateFilter, selectedChannel, setFiltersAction, onClose, selectedStatus]);

  return {
    filtersApplied,
    isNotDraftOrFinished,
    onApplyFilters,
    placeholderFilters,
    setSelectedStatus,
    setSelectedChannel,
  };
};
