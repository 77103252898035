import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button, Flex, Text } from '@/lib/v2/components';

import IframeTransport from '@/src/compat/iframeTransport';
import { CAMPAIGNS_PATH } from '@/src/modules/CampaignsModule/constants';
import { EmptyActionIcon } from '@/src/modules/CampaignsModule/images/icons';
import { OutletContextActionsLayoutProps } from '@/src/modules/CampaignsModule/layouts/ActionsLayout';

const ActionContent = () => {
  const { showSpinnerByAction, showErrorCampaign, campaignId } =
    useOutletContext<OutletContextActionsLayoutProps>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToActionList = () => {
    navigate(`${CAMPAIGNS_PATH}/${campaignId}`);
  };

  return (
    <Flex column withGap gapSize="medium">
      {!showErrorCampaign && <IframeTransport loading={showSpinnerByAction} />}

      {showErrorCampaign && (
        <Flex column alignment="center" className="py-12" itemAlignment="center">
          <EmptyActionIcon />
          <Text className="mt-2" fontWeight="medium" variant="title-1">
            {t('CAMPAIGN_ACTIONS_MAIN.emptyCampaign')}
          </Text>
          <Flex alignment="center" gapSize="small">
            <Text variant="text">
              <Trans
                components={{
                  featured: (
                    <Button
                      link
                      className="mx-0 !px-0 font-medium text-emblue-primary"
                      classNameContainer={'inline-flex'}
                      onClick={handleBackToActionList}
                    />
                  ),
                }}
                i18nKey={t('CAMPAIGN_ACTIONS_MAIN.goToActions')}
              />
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ActionContent;
