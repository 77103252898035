export const RulesAPIIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M12.3 26.7539V24.0155L8.60734 20.3228C8.32556 20.0411 8.10878 19.7165 7.957 19.3492C7.805 18.9818 7.729 18.5964 7.729 18.1929V12.0952C7.729 11.2556 8.02467 10.5388 8.616 9.94485C9.20734 9.35085 9.92278 9.05385 10.7623 9.05385H12.024L10.5073 10.5625V5.24518C10.5073 4.81963 10.6537 4.4604 10.9463 4.16752C11.2392 3.87485 11.5984 3.72852 12.024 3.72852C12.4496 3.72852 12.8087 3.87485 13.1013 4.16752C13.3942 4.4604 13.5407 4.81963 13.5407 5.24518V9.06185H18.4593V5.24518C18.4593 4.81963 18.6058 4.4604 18.8987 4.16752C19.1913 3.87485 19.5505 3.72852 19.976 3.72852C20.4016 3.72852 20.7608 3.87485 21.0537 4.16752C21.3463 4.4604 21.4927 4.81963 21.4927 5.24518V10.5625L19.976 9.05385H21.2377C22.0772 9.05385 22.7927 9.35085 23.384 9.94485C23.9753 10.5388 24.271 11.2556 24.271 12.0952V18.1929C24.271 18.5964 24.195 18.9818 24.043 19.3492C23.8912 19.7165 23.6745 20.0411 23.3927 20.3228L19.7 24.0155V26.7539C19.7 27.1794 19.5537 27.5386 19.261 27.8315C18.9681 28.1242 18.6089 28.2705 18.1833 28.2705H13.8167C13.3911 28.2705 13.0319 28.1242 12.739 27.8315C12.4463 27.5386 12.3 27.1794 12.3 26.7539Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
