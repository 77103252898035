import { CustomsFieldsProps } from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';

interface CleanedObject {
  [key: string]: string;
}

export const cleanCustomFields = (customFields: CustomsFieldsProps[]) => {
  const filteredFields = customFields
    .filter((field: CustomsFieldsProps) => {
      if (field.value !== undefined && String(field.value).trim() !== '') {
        return true;
      }
      return false;
    })
    .map((field: CustomsFieldsProps) => {
      return { name: field.name, value: String(field.value).trim() };
    });
  return filteredFields;
};

export const cleanObject = (obj: CleanedObject): CleanedObject => {
  const cleanObj: CleanedObject = {};
  for (const key in obj) {
    if (obj[key] !== '' && obj[key] !== undefined && key !== 'email') {
      cleanObj[key] = obj[key];
    }
  }
  return cleanObj;
};

interface Translations {
  [key: string]: string;
}
/* cspell:disable */
const translationTov2WithKey: Translations = {
  nombre: 'name',
  apellido: 'surName',
  telefono_1: 'phoneNumber',
  telefono_2: 'phoneNumber2',
  direccion: 'address',
  estado: 'state',
  ciudad: 'city',
  codigo_postal: 'zipCode',
  cargo: 'position',
  email_secundario: 'secondEmail',
  cumpleanios: 'birthday',
  pais: 'country',
  empresa: 'busyness',
  web_url: 'webUrl',
  facebook: 'facebook',
  twitter: 'twitter',
  linkedin: 'linkedin',
  campo_personalizado: 'customField',
};

const translationsToV1: Translations = {
  name: 'nombre',
  surName: 'apellido',
  phoneNumber: 'telefono_1',
  phoneNumber2: 'telefono_2',
  address: 'direccion',
  state: 'estado',
  city: 'ciudad',
  zipCode: 'codigo_postal',
  position: 'cargo',
  birthday: 'cumpleanios',
  secondEmail: 'email_secundario',
  country: 'pais',
  busyness: 'empresa',
  webUrl: 'web_url',
  facebook: 'facebook',
  twitter: 'twitter',
  linkedin: 'linkedin',
  customField: 'campo_personalizado',
};
/* cspell:enable */

const translateFields = (field: string): string => {
  return translationsToV1[field] || field;
};

export const translateFieldsKeyToV2 = (
  customsFields: CustomsFieldsProps[]
): CustomsFieldsProps[] | undefined => {
  const translatedFields = customsFields.map((field: CustomsFieldsProps) => {
    const nameTranslated = translationTov2WithKey[field.name] || field.name;
    if (nameTranslated !== field.name) {
      return { ...field, name: nameTranslated };
    }
  });

  if (translatedFields === undefined) {
    return undefined;
  }

  return translatedFields.filter((field) => field !== undefined) as CustomsFieldsProps[];
};

export const formatFields = (objects: Object): string => {
  const entries: [string, unknown][] = Object.entries({ ...objects });
  const lastIdx = entries.length - 1;
  const formattedEntries = entries
    .map(([key, value], idx) => {
      const endSeparator = idx === lastIdx ? '' : '|||';
      const keyTranslated = typeof key === 'string' ? translateFields(key) : '';
      return `${keyTranslated}:|:${value as string}:|:1${endSeparator}`;
    })
    .join('');

  return formattedEntries;
};
