// cspell:disable

export const NewContactWithoutInteractionIcon = () => {
  return (
    <svg height="52" viewBox="0 0 52 52" width="52" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          filterUnits="userSpaceOnUse"
          height="19.722"
          id="Path_145"
          width="29.945"
          x="21.922"
          y="24.409"
        >
          <feOffset dy="2" in="SourceAlpha" />
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodOpacity="0.161" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 8180" id="Group_8180" transform="translate(-1002.75 -346.749)">
        <g data-name="Group 7953" id="Group_7953" transform="translate(1003 347)">
          <rect
            data-name="Rectangle 4375"
            fill="none"
            height="52"
            id="Rectangle_4375"
            transform="translate(-0.25 -0.251)"
            width="52"
          />
          <g data-name="Group 7952" id="Group_7952" transform="translate(3.084 2.07)">
            <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(29.314 31.342)">
              <path
                d="M386.11,331.53a11.571,11.571,0,0,0,.714,1.667h0C386.585,332.721,386.348,332.007,386.11,331.53Z"
                data-name="Trazado 3928"
                fill="#2552a0"
                id="Trazado_3928"
                transform="translate(-386.11 -331.53)"
              />
            </g>
            <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(22.645 23.474)">
              <path
                d="M315.57,248.307a.876.876,0,0,1,.714.238A.873.873,0,0,0,315.57,248.307Z"
                data-name="Trazado 3930"
                fill="#2552a0"
                id="Trazado_3930"
                transform="translate(-315.57 -248.301)"
              />
            </g>
            <g data-name="Grupo 6573" id="Grupo_6573" transform="translate(6.611 0)">
              <circle
                cx="11.454"
                cy="11.454"
                data-name="Elipse 1742"
                fill="#f6a658"
                id="Elipse_1742"
                r="11.454"
              />
            </g>
            <g data-name="Grupo 6574" id="Grupo_6574" transform="translate(0 22.708)">
              <path
                d="M111.989,252.962a22.449,22.449,0,0,0-8.1-12.562,1.782,1.782,0,0,0-1.675,0,14.308,14.308,0,0,1-16.2,0,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h21.516C109.471,259.667,111.989,252.962,111.989,252.962Z"
                data-name="Trazado 3933"
                fill="#788f9a"
                id="Trazado_3933"
                transform="translate(-76.043 -240.19)"
              />
            </g>
            <path
              d="M157.424,0a11.454,11.454,0,0,0,0,22.909Z"
              data-name="Path 4758"
              fill="#f5b673"
              id="Path_4758"
              transform="translate(-139.359 0)"
            />
            <path
              d="M94.108,242.915a14.291,14.291,0,0,1-8.1-2.515,1.78,1.78,0,0,0-1.677,0,21.623,21.623,0,0,0-8.1,12.562s-1.675,6.7,5.03,6.7h12.85Z"
              data-name="Path 4759"
              fill="#8fa3ad"
              id="Path_4759"
              transform="translate(-76.042 -217.482)"
            />
          </g>
        </g>
        <g data-name="Group 8179" id="Group_8179" transform="translate(1030.672 375.158)">
          <g data-name="Group 265" id="Group_265">
            <path
              d="M65.389,206.986H48.36a.461.461,0,0,1-.46-.46V193.86a.461.461,0,0,1,.46-.46H65.389a.461.461,0,0,1,.46.46v12.665A.461.461,0,0,1,65.389,206.986Z"
              data-name="Path 140"
              fill="#e7eefd"
              id="Path_140"
              transform="translate(-47.9 -193.4)"
            />
            <path
              d="M313.769,196.3l0,0a.509.509,0,0,1,.17.383V197.9l-6.885,5.038a4.517,4.517,0,0,1-1.25.744l7.946,6a.461.461,0,0,0,.186-.368V196.649A.443.443,0,0,0,313.769,196.3Z"
              data-name="Path 141"
              fill="#cfdbfc"
              id="Path_141"
              transform="translate(-295.986 -196.188)"
            />
            <g filter="url(#Path_145)" transform="matrix(1, 0, 0, 1, -27.92, -28.41)">
              <path
                d="M59.028,200.212a3.008,3.008,0,0,1-4.31,0l-6.818-5.09V193.9a.482.482,0,0,1,.46-.5H65.385a.482.482,0,0,1,.46.5v1.223Z"
                data-name="Path 145"
                fill="#fff"
                id="Path_145-2"
                transform="translate(-19.98 -164.99)"
              />
            </g>
          </g>
          <g data-name="Group 8178" id="Group_8178" transform="translate(0 -1)">
            <g data-name="Group 271" id="Group_271" transform="translate(10.846 6.793)">
              <ellipse
                cx="5.64"
                cy="5.64"
                data-name="Ellipse 156"
                fill="#fc7e00"
                id="Ellipse_156"
                rx="5.64"
                ry="5.64"
                transform="translate(0 0.001)"
              />
              <path
                d="M1716.887-633.088c2.2,0,4.076.955,4.787,2.29-.493-1.661-2.449-2.9-4.787-2.9s-4.294,1.241-4.787,2.9C1712.811-632.135,1714.687-633.088,1716.887-633.088Z"
                data-name="Path 319"
                fill="rgba(255,255,255,0.4)"
                id="Path_319"
                transform="translate(-1711.248 634.461)"
              />
            </g>
            <path
              d="M12,9v4.083m0,3.267h.006v.009H12Z"
              data-name="Path 325"
              fill="none"
              id="Path_325"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              transform="translate(4.635 -0.246)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
