// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const translateInterface = (
  interfaceInSpanish: any,
  interfaceInEnglish: any,
  translations: { [key: string]: string }
) => {
  // Loop through the interface attributes in Spanish
  for (const attribute in interfaceInSpanish) {
    // If the attribute is an object, call the function recursively
    // to translate nested attributes
    if (typeof interfaceInSpanish[attribute] === 'object') {
      interfaceInEnglish[translations[attribute]] = {};
      translateInterface(
        interfaceInSpanish[attribute],
        interfaceInEnglish[translations[attribute]],
        translations
      );
    } else {
      // If the attribute is found in the translation dictionary,
      // assign its value to the english interface
      if (translations[attribute]) {
        interfaceInEnglish[translations[attribute]] = interfaceInSpanish[attribute];
      }
    }
  }

  // Returns the interface in English
  return interfaceInEnglish;
};
