import { ChangeEvent, useCallback, useState } from 'react';

type IInputSearchProps = {
  placeholder?: string;
  defaultValue?: string;
};

export const useInputFilter = ({ defaultValue, placeholder }: IInputSearchProps) => {
  const [value, setValue] = useState<string>(defaultValue ?? '');

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const onClear = useCallback(() => {
    setValue('');
  }, []);

  return {
    placeholder,
    value,
    onChange,
    onClear,
    setValue,
  };
};
