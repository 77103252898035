export enum DateFormat {
  /**
   * `format`: 'dd/mm/yyyy'
   */
  ShortDate = 'shortDate',
  /**
   * `format`: 'YYYY-MM-DD'
   */
  ShortDateServer = 'shortDateServer',
  /**
   * `format`: 'dd-mm-yyyy, hh:mm:ss'
   */
  LongDate = 'longDate',
  /**
   * `format`: 'dd/mm/yyyy, hh:mm:ss'
   */
  LongDateOutSeconds = 'longDateOutSeconds',
  /**
   * `format`: 'hh:mm:ss'
   */
  TimeOnly = 'timeOnly',
}

const dateFormatStrings: Record<DateFormat, string> = {
  [DateFormat.ShortDate]: 'dd/mm/yyyy',
  [DateFormat.ShortDateServer]: 'yyyy-mm-dd',
  [DateFormat.LongDate]: 'dd/mm/yyyy, HH:MM:ss',
  [DateFormat.LongDateOutSeconds]: 'dd/mm/yyyy, HH:MM',
  [DateFormat.TimeOnly]: 'hh:mm:ss',
};

interface IDateTimeLocal {
  date: string;
  time: string;
}

/**
 * Formats a date in the specified format from Enum DateFormat.
 * @param {string} inputDate - The input date as a string.
 * @param {DateFormat} format - The format in which the date should be formatted. {@link DateFormat}
 * @returns {string} The formatted date.
 */

export const formatDate = (inputDate: string, format: DateFormat): string => {
  const date = new Date(inputDate);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  let formattedFormat = dateFormatStrings[format];
  formattedFormat = formattedFormat.replace('dd', day.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('mm', month.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('yyyy', year.toString());
  formattedFormat = formattedFormat.replace('HH', hours.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('MM', minutes.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('ss', seconds.toString().padStart(2, '0'));

  return formattedFormat;
};

/**
 * Formats a date in the specified format from Enum DateFormat.
 * @param {string} inputTime - The input time as a string.
 * @param {DateFormat} format - The format in which the date should be formatted. {@link DateFormat}
 * @returns {string} The formatted date.
 */

export const formatTime = (inputTime: string, format: DateFormat): string => {
  const date = new Date(inputTime);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  let formattedFormat = dateFormatStrings[format];
  formattedFormat = formattedFormat.replace('hh', hours.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('mm', minutes.toString().padStart(2, '0'));
  formattedFormat = formattedFormat.replace('ss', seconds.toString().padStart(2, '0'));

  return formattedFormat;
};

export const formatDateTimeLocal = (inputDate: Date): IDateTimeLocal => {
  const dateLocal = inputDate.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const timeLocal = inputDate.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return { date: dateLocal, time: timeLocal };
};

/**
 * Formats a Date object to a string representation.
 * If the `includeTime` parameter is true, the time is set to "23:59:59".
 * Otherwise, the time is set to "00:00:00".
 *
 * @param {Date} date - The Date object to format.
 * @param {boolean} [includeTime=false] - Whether to include "23:59:59" in the formatted string.
 * @returns {string} The formatted date string.
 */
export const formatDateToString = (date: Date, includeTime: boolean = false) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const time = includeTime ? '23:59:59' : '00:00:00';
  return `${year}-${month}-${day} ${time}`;
};

export const formatOffset = (offset: string): string => {
  const numericOffset = parseInt(offset, 10);
  const absOffset = Math.abs(numericOffset);
  const sign = numericOffset < 0 ? '-' : '+';
  return `${sign}${absOffset.toString().padStart(2, '0')}`;
};

export const getFormatDateGMT = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    hourCycle: 'h24',
  });

  const parts = formatter.formatToParts(date);
  const getPart = (part: string) => parts.find((p) => p.type === part)?.value;

  const day = getPart('day') || '01';
  const month = getPart('month') || '01';
  const year = getPart('year') || '01';
  const hour = getPart('hour') || '00';
  const minutes = getPart('minute') || '00';

  const amPm = typeof hour === 'number' && hour < 12 ? 'a.m' : 'p.m';

  return `${day}/${month}/${year}, ${hour}:${minutes} ${amPm}`;
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && date.toString() !== 'Invalid Date';
};
