import { useEffect, useState } from 'react';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { ITagsEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useTagList = ({
  page,
  pageSize,
  search,
  orderColumn,
  orderDirection,
  tagsCategoriesFilter,
  tagsDataSourceFilter,
  lastRefresh,
}: ITagsEmblueService): [
  (
    | {
        list: { tag: ITag }[];
      }
    | undefined
  ),
  Error | undefined,
  boolean | undefined
] => {
  const [tagListValue, error, isLoading] = useEmblue(ServiceMethods.getTags, {
    page,
    pageSize,
    search,
    orderColumn,
    orderDirection: orderDirection,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
  });

  return [tagListValue, error, isLoading];
};
