export const GroupTableIcon = () => {
  return (
    <svg
      data-name="Grupo 3409"
      height="32"
      id="Grupo_3409"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectángulo 3174" fill="none" height="32" id="Rectángulo_3174" width="32" />
      <ellipse
        cx="3.17"
        cy="3.17"
        data-name="Elipse 1791"
        fill="#004dbc"
        id="Elipse_1791"
        rx="3.17"
        ry="3.17"
        transform="translate(22.189 10.716)"
      />
      <ellipse
        cx="3.17"
        cy="3.17"
        data-name="Elipse 1792"
        fill="#004dbc"
        id="Elipse_1792"
        rx="3.17"
        ry="3.17"
        transform="translate(3.471 10.716)"
      />
      <path
        d="M-1216.105,149.861a5.943,5.943,0,0,0-2.415-3.623.283.283,0,0,0-.453,0,4.277,4.277,0,0,1-4.227.3,12.975,12.975,0,0,1,3.019,5.283h2.566C-1215.652,151.824-1216.105,149.861-1216.105,149.861Z"
        data-name="Trazado 4241"
        fill="#004dbc"
        id="Trazado_4241"
        transform="translate(1246.598 -129.03)"
      />
      <path
        d="M-1230.59,146.54a3.9,3.9,0,0,1-4.227-.3.283.283,0,0,0-.453,0,6.842,6.842,0,0,0-2.415,3.623s-.453,1.962,1.51,1.962h2.566A12.979,12.979,0,0,1-1230.59,146.54Z"
        data-name="Trazado 4242"
        fill="#004dbc"
        id="Trazado_4242"
        transform="translate(1239.193 -129.03)"
      />
      <g data-name="Grupo 6921" id="Grupo_6921" transform="translate(6.088 4.527)">
        <ellipse
          cx="6.038"
          cy="5.887"
          data-name="Elipse 1789"
          fill="#004dbc"
          id="Elipse_1789"
          rx="6.038"
          ry="5.887"
          transform="translate(3.874)"
        />
        <path
          d="M-1214.791,152.882s-1.057-4.529-4.529-6.944a.962.962,0,0,0-.906,0,7.713,7.713,0,0,1-8.906,0,.962.962,0,0,0-.906,0c-3.472,2.264-4.529,6.944-4.529,6.944s-.906,3.774,2.717,3.774h14.189C-1213.885,156.656-1214.791,152.882-1214.791,152.882Z"
          data-name="Trazado 4233"
          fill="#004dbc"
          id="Trazado_4233"
          transform="translate(1234.667 -133.711)"
        />
      </g>
    </svg>
  );
};
