import { memo } from 'react';

import { Text } from '@/lib/v2/components';

import useClockDisplay from './useClockDisplay';

export type LanguageAccount = 'en' | 'es' | 'pt';

interface ClockDisplayProps {
  gmt: number;
  language: LanguageAccount;
}

const ClockDisplay = ({ gmt, language = 'es' }: ClockDisplayProps) => {
  const time = useClockDisplay({ gmt, language });
  return <Text variant="text-sm">{time}</Text>;
};

export default memo(ClockDisplay);
