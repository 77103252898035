export const ClipboardIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_889_1462)">
        <path
          d="M8.43979 3.61351L14.5386 9.71231C15.7106 10.8843 15.7106 12.7829 14.5386 13.955C13.3666 15.127 11.468 15.127 10.2959 13.955L3.66682 7.32583C2.93496 6.59397 2.93496 5.40603 3.66682 4.67417C4.39867 3.94232 5.58661 3.94232 6.31847 4.67417L11.8869 10.2426C12.1786 10.5343 12.1786 11.0116 11.8869 11.3033C11.5953 11.595 11.118 11.595 10.8263 11.3033L5.78814 6.26517L4.99264 7.06066L10.0308 12.0988C10.7626 12.8307 11.9506 12.8307 12.6824 12.0988C13.4143 11.3669 13.4143 10.179 12.6824 9.44715L7.11396 3.87868C5.94193 2.70665 4.04335 2.70665 2.87132 3.87868C1.69929 5.05071 1.69929 6.94929 2.87132 8.12132L9.50045 14.7504C11.1126 16.3627 13.7219 16.3627 15.3341 14.7504C16.9463 13.1382 16.9463 10.529 15.3341 8.91682L9.23528 2.81802L8.43979 3.61351Z"
          fill="#9DA3A7"
        />
      </g>
      <defs>
        <clipPath id="clip0_889_1462">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
