import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputBar, TableSkeleton } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { useTablePagination } from '@/lib/components/Table/useTablePagination';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { IContactActivityItems } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

import { ContactsProfileItemsTable } from './ContactsProfileItemsTable';

export const ContactsProfileActivityItemsTable = ({ emailId }: { emailId: number }) => {
  const { t } = useTranslation();
  const [search, setSearchValue] = useState<string>('');
  const [totalItemsContacts, setTotalItemsContacts] = useState<number>();
  const {
    pageSize,
    pageIndex,
    totalPages,
    isLoading,
    gotoPageIndex,
    setIsLoading,
    changeTablePage,
    resetPagination,
  } = useTablePagination(totalItemsContacts);
  const [activityItemsListValue] = useEmblue(ServiceMethods.getContactActivityItems, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
    search,
  });
  const [activityItemsTotalCount] = useEmblue(ServiceMethods.getContactActivityItems, {
    emailId,
    pageNumber: pageIndex,
    rowsPerPage: pageSize,
  });
  const { resetFilter } = useResetSearch({ cleanBox: setSearchValue as () => void });
  const [activityItemsList, setActivityItemsList] = useState<{
    list: IContactActivityItems[];
    total: number;
  }>();
  const cleanBoxRef = useRef<CleanBoxRef>(null);

  useEffect(() => {
    if (activityItemsListValue) {
      setActivityItemsList(activityItemsListValue);
    }
  }, [activityItemsListValue]);

  useEffect(() => {
    if (activityItemsList) {
      setTotalItemsContacts(activityItemsListValue?.total);
    }
  }, [activityItemsList, activityItemsListValue?.total, search]);

  useEffect(() => {
    activityItemsList && activityItemsList.list;
    resetPagination();
    if (search && totalItemsContacts) {
      setTotalItemsContacts(totalPages);
    }
  }, [search]);

  useEffect(() => {
    activityItemsList && setIsLoading(false);
  }, [activityItemsList]);

  const resetActivityItems = () => {
    cleanBoxRef?.current?.cleanBox();
    resetFilter();
  };

  return (
    <>
      {activityItemsTotalCount && activityItemsTotalCount.total > 0 && (
        <div className="mb-[-53px] w-[250px] py-2">
          <SearchInputBar
            ref={cleanBoxRef}
            withButton
            cleanBox={resetFilter}
            placeHolder={t('PROFILE_ITEMS_TABLE.searchInputBar')}
            onAction={(ev) => setSearchValue && setSearchValue(ev)}
          />
        </div>
      )}
      <Flex column>
        {!activityItemsList && (
          <span className="h-full w-full py-10">
            <TableSkeleton />
          </span>
        )}
      </Flex>
      {activityItemsList && (
        <ContactsProfileItemsTable
          activityList={activityItemsList.list}
          activityTotalCount={activityItemsTotalCount ? activityItemsTotalCount.total : 0}
          callToAction={[resetActivityItems]}
          changeTablePage={changeTablePage}
          gotoPageIndex={gotoPageIndex}
          isLoading={isLoading}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
