import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { ImmediateDeliveryIcon, ScheduledDeliveryIcon } from '@/lib/v2/icons/general';

import { ImmediateDeliveryForm } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling/components/ImmediateDeliveryForm';
import { SchedulingDeliveryForm } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling/components/SchedulingDeliveryForm';

import { atomDisableEditAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { TRANSLATE_WHATSAPP_SCHEDULING } from '@/modules/CampaignsModule/constants';
import { DELIVERY_OPTIONS } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useDeliveryWhatsApp = () => {
  const { t } = useTranslation('inbox');
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const immediateDelivery = {
    id: DELIVERY_OPTIONS.immediate,
    icon: <IconSvg height="40px" svgComponent={<ImmediateDeliveryIcon />} width="40px" />,
    title: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.immediate`),
    description: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.immediateDescription`),
    children: <ImmediateDeliveryForm />,
  };

  const scheduledDelivery = {
    id: DELIVERY_OPTIONS.scheduled,
    icon: <IconSvg height="40px" svgComponent={<ScheduledDeliveryIcon />} width="40px" />,
    title: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.schedule`),
    description: t(`${TRANSLATE_WHATSAPP_SCHEDULING}.scheduleDescription`),
    children: <SchedulingDeliveryForm disabled={disableEditAction} />,
  };

  return [immediateDelivery, scheduledDelivery];
};
