import { IconProps } from './iconPropTypes';

export const ArrowUpTableIcon = ({ color = '#96a6ce' }: IconProps) => {
  return (
    <svg height="7" id="up" viewBox="0 0 7 5" width="10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5,3,13,8H6L9.5,3Z"
        fill={`${color}`}
        fillRule="evenodd"
        id="path"
        transform="translate(-6 -3)"
      />
    </svg>
  );
};
