const copyToClipboard = async (text: number | string) => {
  if (document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.value = text.toString();
    textarea.style.position = 'fixed';
    textarea.style.top = '-999px';
    document.body.appendChild(textarea);
    textarea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error(err);
    } finally {
      document.body.removeChild(textarea);
    }
  } else if (navigator.clipboard) {
    await navigator.clipboard.writeText(text.toString());
  }
};

export const copy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Error copy value to clipboard: ', err);
    return false;
  }
};

export default copyToClipboard;
