import { useEffect } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Flex, Select, Text } from '@/lib/v2/components';

import { useEmailSender } from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/components/SenderInput/useEmailSender.hooks';
import { TRANSLATE_CREATE_EMAIL_ACTION } from '@/modules/CampaignsModule/constants';
import { IEmailFormInfo, IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface ISenderInputProps {
  loading?: boolean;
  form: IFormProps<IEmailFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}
const SenderInput = ({ form, loading, rules, disabled = false }: ISenderInputProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { control } = form;

  const {
    events: { getSenders, handleChangeSender },
    state: { senderDropdownOptions, loading: senderLoading, isInvalidSender },
  } = useEmailSender({ setValue: form.setValue });

  useEffect(() => void getSenders(), [getSenders]);

  return (
    <>
      <Text
        className="sticky top-0 z-30 border-b border-solid border-emblue-borders bg-emblue-white pb-2"
        color="primary"
        fontWeight="medium"
        variant="sub-headline"
      >
        {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.sender`)}
      </Text>
      <Flex column withGap className="relative z-20 mt-9 pl-[1px]" itemAlignment="start">
        <Select
          control={control}
          disabled={disabled}
          id="create-action-sender-select"
          isLoading={loading || senderLoading}
          name="sender"
          options={senderDropdownOptions}
          rules={rules?.sender}
          onChange={handleChangeSender}
        />
        {isInvalidSender && (
          <Alert
            className="mb-1"
            description={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.senderAlert`)}
            type="warning"
          />
        )}
        <Button
          gray
          outline
          disabled={disabled}
          id="create-sender"
          size="small"
          onClick={() => navigate('/v2/configuration/senders')}
        >
          {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.createSender`)}
        </Button>
      </Flex>
    </>
  );
};

export default SenderInput;
