/* eslint-disable pii/no-email */
import {
  IEmailData,
  IEmailVerified,
  ISender,
  ISenderData,
  ISendersResponse,
} from '@/src/presentation/types/interfaces/ISenderData.interface';
/** cspell:disable */
export const defaultSender: ISenderData = {
  id: 1,
  name: 'Cande',
  isDefault: false,
  business: {
    name: 'emBlue',
    address: 'Siempre viva 742',
    zipCode: '1842',
    city: 'Buenos Aires',
    province: 'Buenos Aires',
    country: 'Argentina',
  },
  emailFrom: {
    id: 5,
    businessId: 5971,
    // eslint-disable-next-line pii/no-email
    email: 'lemobal@youmails.online',
    isDefault: false,
    details: [
      {
        state: 'NonExistent',
        key: 'spf',
      },
      {
        state: 'NonExistent',
        key: 'dkim',
      },
      {
        state: 'NonExistent',
        key: 'dmarc',
      },
      {
        state: 'NotVerified',
        key: 'email',
      },
    ],
  },
  emailReply: {
    id: 2,
    businessId: 5971,
    email: 'gerardo.a@embluemail.com',
    isDefault: false,
    details: [
      {
        state: 'Verified',
        key: 'spf',
      },
      {
        state: 'Verified',
        key: 'dkim',
      },
      {
        state: 'Verified',
        key: 'dmarc',
      },
      {
        state: 'NotVerified',
        key: 'email',
      },
    ],
  },
};

export const createSenderMock: ISender = {
  notification: {
    content: true,
    id_notification: 'SENDER_CREATE',
  },
  sender: defaultSender,
};

export const senderListDataMock: ISendersResponse = {
  result: [
    {
      id: 1,
      name: 'Cande',
      isDefault: false,
      business: {
        name: 'emBlue',
        address: 'Siempre viva 742',
        zipCode: '1842',
        city: 'Buenos Aires',
        province: 'Buenos Aires',
        country: 'Argentina',
      },
      emailFrom: {
        id: 1,
        businessId: 5971,
        email: 'javier.p@embluemail.com',
        isDefault: true,
        details: [
          {
            state: 'Verified',
            key: 'spf',
          },
          {
            state: 'Verified',
            key: 'dkim',
          },
          {
            state: 'Verified',
            key: 'dmarc',
          },
          {
            state: 'Verified',
            key: 'email',
          },
        ],
      },
      emailReply: {
        id: 3,
        businessId: 5971,
        email: 'jose.c@embluemail.com',
        isDefault: false,
        details: [
          {
            state: 'Verified',
            key: 'spf',
          },
          {
            state: 'Verified',
            key: 'dkim',
          },
          {
            state: 'Verified',
            key: 'dmarc',
          },
          {
            state: 'Verified',
            key: 'email',
          },
        ],
      },
    },
  ],
  pager: {
    countPartial: 2,
    countTotal: 2,
    currentPage: 0,
  },
};

export const senderCreateDataMock = {
  email: 'luis.e+4@embluemail.com',
  accountId: 5971,
  isDefault: false,
  details: [
    {
      state: '2',
      key: 'spf',
    },
    {
      state: '2',
      key: 'dmarc',
    },
    {
      state: '2',
      key: 'dkim',
    },
    {
      state: '0',
      key: 'email',
    },
  ],
  id: 72019,
};

export const senderEmailListDataMock: IEmailVerified[] = [
  {
    id: 1,
    businessId: 5971,
    email: 'javier.p@embluemail.com',
    verified: true,
    isDefault: true,
    spf: 2,
    dkim: 2,
    dmarc: 2,
  },
];

export const emailsDataMock: IEmailData[] = [
  {
    detail: [
      {
        state: 'No verificado',
        key: 'email',
      },
      {
        state: 'Inexistente',
        key: 'spf',
      },
      {
        state: 'Inexistente',
        key: 'dkim',
      },
      {
        state: 'Inexistente',
        key: 'dmarc',
      },
    ],
    email: 'defaultsender@mailingmktg.co',
    id: '67749',
    default: false,
  },
  {
    detail: [
      {
        state: 'Verificado',
        key: 'email',
      },
      {
        state: 'Verificado',
        key: 'spf',
      },
      {
        state: 'Verificado',
        key: 'dkim',
      },
      {
        state: 'Verificado',
        key: 'dmarc',
      },
    ],
    email: 'defaultsender@mailingmktg.com',
    id: '67668',
    default: true,
  },
  {
    detail: [
      {
        state: 'Verificado',
        key: 'email',
      },
      {
        state: 'Verificado',
        key: 'spf',
      },
      {
        state: 'Verificado',
        key: 'dkim',
      },
      {
        state: 'Verificado',
        key: 'dmarc',
      },
    ],
    email: 'jesus.tovar@embluemail.com',
    id: '67770',
    default: false,
  },
  {
    detail: [
      {
        state: 'No verificado',
        key: 'email',
      },
      {
        state: 'Verificado',
        key: 'spf',
      },
      {
        state: 'Verificado',
        key: 'dkim',
      },
      {
        state: 'Verificado',
        key: 'dmarc',
      },
    ],
    email: 'sandra.b@embluemail.com',
    id: '67772',
    default: false,
  },
];

export const mockSenderResponse = {
  status: 'error',
  message: ['Invalid domain'],
  stack:
    'ValidationError: Validation error\n    at Proxy.createSenderEmail (/usr/app/core/services/src/modules/sender/sender_service.ts:211:19)\n    at process.processTicksAndRejections (node:internal/process/task_queues:95:5)\n    at Proxy.emailsValidation (/usr/app/core/services/src/modules/sender/sender_service.ts:116:33)\n    at Proxy.createSender (/usr/app/core/services/src/modules/sender/sender_service.ts:52:43)\n    at <anonymous> (/usr/app/rest/ema-node/src/code.ts:665:22)',
};

//**cspell:enable */
