import { useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { atomOpenMessageModal } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { useActionEmailData } from '@/src/modules/CampaignsModule/hooks/useActionEmailData';
import { EActionStatusAvailable } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

interface IEmailPreviewButtons {
  sourceEditor?: string;
}

const PATHS_TO_REDIRECT = ['/summary', '/preview'];
const SOURCE_EDITOR = ['beefree', 'htmlUpload'];
const REMOVE_PATHS = ['/summary', '/scheduling', '/preview'];

export const useEmailPreviewButtons = ({ sourceEditor }: IEmailPreviewButtons) => {
  const { actionId } = useParams();
  const navigate = useNavigate();

  const setOpenModal = useSetAtom(atomOpenMessageModal);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { getEmailInfo, setActionState, deleteEmailTemplate } = useActionEmailData(
    Number(actionId)
  );

  const editorRoute = useMemo(() => {
    if (sourceEditor && SOURCE_EDITOR.includes(sourceEditor)) {
      return sourceEditor === 'beefree' ? 'editor' : 'html-editor';
    }

    return 'html-editor';
  }, [sourceEditor]);

  const redirectLocation = useMemo(() => {
    const path = window.location.pathname;

    if (REMOVE_PATHS.some((ending) => path.endsWith(ending))) {
      return path.replace(/\/(?:summary|scheduling|preview)$/, '');
    } else {
      return path.endsWith('/') ? path.substring(0, path.length - 1) : path;
    }
  }, []);

  //TODO: add methods for different types of messages
  const handleDeleteMessage = useCallback(async () => {
    setLoadingDelete(true);
    await deleteEmailTemplate(Number(actionId));
    await getEmailInfo();
    await setActionState({ actionId: Number(actionId), statusId: EActionStatusAvailable.DRAFT });

    setLoadingDelete(false);
    setOpenModal(false);

    if (PATHS_TO_REDIRECT.some((path) => window.location.pathname.includes(path))) {
      navigate(redirectLocation);
    }
  }, [
    deleteEmailTemplate,
    actionId,
    getEmailInfo,
    setActionState,
    setOpenModal,
    navigate,
    redirectLocation,
  ]);

  return {
    state: {
      editorRoute,
      redirectLocation,
      loadingDelete,
    },
    events: {
      handleDeleteMessage,
    },
  };
};
