// cspell:disable

export const ContactsToWakeUpIcon = () => {
  return (
    <svg
      height="46.251"
      viewBox="0 0 47.905 46.251"
      width="47.905"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 8013" id="Group_8013" transform="translate(-781.016 -348.65)">
        <g data-name="Group 8174" id="Group_8174" transform="translate(781.016 348.65)">
          <g data-name="Grupo 6567" id="Grupo_6567" transform="translate(31.196 31.66)">
            <path
              d="M386.11,331.53a12.554,12.554,0,0,0,.774,1.808h0C386.625,332.822,386.368,332.048,386.11,331.53Z"
              data-name="Trazado 3928"
              fill="#2552a0"
              id="Trazado_3928"
              transform="translate(-386.11 -331.53)"
            />
          </g>
          <g data-name="Grupo 6569" id="Grupo_6569" transform="translate(23.962 23.125)">
            <path
              d="M315.57,248.308a.951.951,0,0,1,.774.258A.947.947,0,0,0,315.57,248.308Z"
              data-name="Trazado 3930"
              fill="#2552a0"
              id="Trazado_3930"
              transform="translate(-315.57 -248.301)"
            />
          </g>
          <g data-name="Group 7946" id="Group_7946" transform="translate(0.018 0)">
            <ellipse
              cx="12.476"
              cy="12.476"
              data-name="Ellipse 1912"
              fill="#f6a658"
              id="Ellipse_1912"
              rx="12.476"
              ry="12.476"
              transform="translate(7.339)"
            />
            <path
              d="M796.555,529.608H788.1a1.036,1.036,0,0,1-.944-.615,1.009,1.009,0,0,1,.205-1.108l6.033-6.033h-5.869a1.026,1.026,0,1,1,0-2.052H795.9a1.036,1.036,0,0,1,.944.615,1.008,1.008,0,0,1-.2,1.108l-6.033,6.033h5.951a1.026,1.026,0,1,1,0,2.052Z"
              data-name="Path 4749"
              fill="#9c59df"
              id="Path_4749"
              transform="translate(-749.694 -494.52)"
            />
            <path
              d="M780.164,559.687h-5.828a1.037,1.037,0,0,1-.944-.615,1.008,1.008,0,0,1,.206-1.108l3.611-3.611h-3.283a1.026,1.026,0,1,1,0-2.052h5.787a1.037,1.037,0,0,1,.944.616,1.008,1.008,0,0,1-.205,1.108l-3.612,3.611h3.365a1.016,1.016,0,0,1,1.026,1.026A1.051,1.051,0,0,1,780.164,559.687Z"
              data-name="Path 4750"
              fill="#9c59df"
              id="Path_4750"
              transform="translate(-741.676 -513.682)"
            />
            <path
              d="M724.924,536.639a5.11,5.11,0,0,1,4.145-8.126H730.1a5.1,5.1,0,0,1,1.477-4.022h0a5.055,5.055,0,0,1-2.011-3.324,18.061,18.061,0,0,0-2.339-1.888,1.678,1.678,0,0,0-1.847,0,15.3,15.3,0,0,1-17.607,0,1.6,1.6,0,0,0-1.847,0c-6.936,4.638-8.906,13.625-8.906,13.625s-1.806,7.387,5.5,7.387h22A5.14,5.14,0,0,1,724.924,536.639Z"
              data-name="Path 4751"
              fill="#788f9a"
              id="Path_4751"
              transform="translate(-696.818 -494.041)"
            />
            <path
              d="M780.51,553.923l-3.612,3.611h3.365a1.026,1.026,0,0,1,0,2.052h-5.828a1.037,1.037,0,0,1-.944-.616,1.009,1.009,0,0,1,.205-1.108l3.612-3.611h-3.283a1.026,1.026,0,0,1,0-2.052h3.653a5.134,5.134,0,0,0,2.462.657h.574A.944.944,0,0,1,780.51,553.923Z"
              data-name="Path 4752"
              fill="#9c59df"
              id="Path_4752"
              transform="translate(-741.735 -513.623)"
            />
            <path
              d="M796.555,529.549h-6.32a5.139,5.139,0,0,0-2.463-.656H787.2a1.008,1.008,0,0,1,.206-1.108l6.033-6.033h-5.91a1.026,1.026,0,1,1,0-2.052H795.9a1.036,1.036,0,0,1,.944.616,1.008,1.008,0,0,1-.2,1.108l-6.033,6.033h5.951a1.016,1.016,0,0,1,1.026,1.026A1.051,1.051,0,0,1,796.555,529.549Z"
              data-name="Path 4753"
              fill="#9c59df"
              id="Path_4753"
              transform="translate(-749.694 -494.461)"
            />
            <g data-name="Group 7944" id="Group_7944" transform="translate(36.806 25.28)">
              <path
                d="M796.555,529.608H788.1a1.036,1.036,0,0,1-.944-.615,1.009,1.009,0,0,1,.205-1.108l6.033-6.033h-5.869a1.026,1.026,0,1,1,0-2.052H795.9a1.036,1.036,0,0,1,.944.615,1.008,1.008,0,0,1-.2,1.108l-6.033,6.033h5.951a1.026,1.026,0,1,1,0,2.052Z"
                data-name="Path 4754"
                fill="#004dbc"
                id="Path_4754"
                transform="translate(-786.5 -519.8)"
              />
            </g>
            <g data-name="Group 7945" id="Group_7945" transform="translate(31.224 38.618)">
              <path
                d="M780.164,559.687h-5.828a1.037,1.037,0,0,1-.944-.615,1.008,1.008,0,0,1,.206-1.108l3.611-3.611h-3.283a1.026,1.026,0,1,1,0-2.052h5.787a1.037,1.037,0,0,1,.944.616,1.008,1.008,0,0,1-.205,1.108l-3.612,3.611h3.365a1.016,1.016,0,0,1,1.026,1.026A1.051,1.051,0,0,1,780.164,559.687Z"
                data-name="Path 4755"
                fill="#004dbc"
                id="Path_4755"
                transform="translate(-772.9 -552.3)"
              />
            </g>
          </g>
          <path
            d="M158.326-.344a12.425,12.425,0,0,0,0,24.851Z"
            data-name="Path 4756"
            fill="#f5b673"
            id="Path_4756"
            transform="translate(-138.78 0.379)"
          />
          <path
            d="M95.57,243.239a15.393,15.393,0,0,1-8.718-2.822,1.931,1.931,0,0,0-1.819,0,23.455,23.455,0,0,0-8.789,13.627s-1.886,7.367,5.387,7.367H95.57Z"
            data-name="Path 4757"
            fill="#8fa3ad"
            id="Path_4757"
            transform="translate(-76.024 -215.179)"
          />
        </g>
      </g>
    </svg>
  );
};
