import { useEffect, useState } from 'react';

import { useUserData } from './useUserData';

export const useIntegrationTabVisibility = (integration: number[]) => {
  const [{ integrationId, integrationDeletedDate }] = useUserData();
  const [visibility, setVisibility] = useState<boolean>(false);
  const integrationNum = integration.find((item) => item === integrationId);

  useEffect(() => {
    if (integrationId === integrationNum && !integrationDeletedDate) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [integrationId, integration, integrationDeletedDate]);

  return { visibility: visibility, integrationId: integrationId };
};
