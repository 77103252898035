/* cSpell:disable */

type CategoryIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const CategoryIcon = ({ tableSize, stroked }: CategoryIconProps) => {
  return (
    <>
      {!stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7468" id="Grupo_7468" transform="translate(-0.318 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0.318 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <g id="check" transform="translate(2.318 1.837)">
              <path
                d="M0,0H16V16H0Z"
                data-name="Trazado 3061"
                fill="rgba(0,0,0,0)"
                id="Trazado_3061"
              />
              <path
                d="M5.086,3A2.086,2.086,0,0,0,3,5.086V7.171A2.086,2.086,0,0,0,5.086,9.257H7.171A2.086,2.086,0,0,0,9.257,7.171V5.086A2.086,2.086,0,0,0,7.171,3Zm0,8.343A2.086,2.086,0,0,0,3,13.429v2.086A2.086,2.086,0,0,0,5.086,17.6H7.171a2.086,2.086,0,0,0,2.086-2.086V13.429a2.086,2.086,0,0,0-2.086-2.086Zm6.257-6.257A2.086,2.086,0,0,1,13.429,3h2.086A2.086,2.086,0,0,1,17.6,5.086V7.171a2.086,2.086,0,0,1-2.086,2.086H13.429a2.086,2.086,0,0,1-2.086-2.086Zm0,8.343a2.086,2.086,0,0,1,2.086-2.086h2.086A2.086,2.086,0,0,1,17.6,13.429v2.086A2.086,2.086,0,0,1,15.514,17.6H13.429a2.086,2.086,0,0,1-2.086-2.086Z"
                data-name="Trazado 3252"
                fill="#004dbc"
                id="Trazado_3252"
                transform="translate(-2.3 -2.3)"
              />
            </g>
          </g>
        </svg>
      )}
      {stroked && (
        <svg
          height={tableSize ? 19 : 32}
          viewBox="0 0 20 20"
          width={tableSize ? 19 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Grupo 7466" id="Grupo_7466" transform="translate(-0.318 0.163)">
            <rect
              data-name="Rectángulo 3174"
              fill="none"
              height={tableSize ? 19 : 32}
              id="Rectángulo_3174"
              transform="translate(0.318 -0.163)"
              width={tableSize ? 19 : 32}
            />
            <g id="check" transform="translate(2.318 1.837)">
              <path d="M0,0H16V16H0Z" data-name="Trazado 3061" fill="none" id="Trazado_3061" />
              <path
                d="M5.086,3A2.086,2.086,0,0,0,3,5.086V7.171A2.086,2.086,0,0,0,5.086,9.257H7.171A2.086,2.086,0,0,0,9.257,7.171V5.086A2.086,2.086,0,0,0,7.171,3Zm0,8.343A2.086,2.086,0,0,0,3,13.429v2.086A2.086,2.086,0,0,0,5.086,17.6H7.171a2.086,2.086,0,0,0,2.086-2.086V13.429a2.086,2.086,0,0,0-2.086-2.086Zm6.257-6.257A2.086,2.086,0,0,1,13.429,3h2.086A2.086,2.086,0,0,1,17.6,5.086V7.171a2.086,2.086,0,0,1-2.086,2.086H13.429a2.086,2.086,0,0,1-2.086-2.086Zm0,8.343a2.086,2.086,0,0,1,2.086-2.086h2.086A2.086,2.086,0,0,1,17.6,13.429v2.086A2.086,2.086,0,0,1,15.514,17.6H13.429a2.086,2.086,0,0,1-2.086-2.086Z"
                data-name="Trazado 3252"
                fill="none"
                id="Trazado_3252"
                stroke="#004dbc"
                strokeWidth="1.5"
                transform="translate(-2.3 -2.3)"
              />
            </g>
          </g>
        </svg>
      )}
    </>
  );
};
