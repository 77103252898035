export const ActionTableIcon = () => {
  return (
    <svg className="mr-2 flex h-4 w-4 self-center" viewBox="0 0 17 17">
      <path
        d="M11,17.222,19,19,11,3,3,19Zm0,0V10.111"
        fill="none"
        stroke="#004dbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-2.5 -2.5)"
      />
    </svg>
  );
};
