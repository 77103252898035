import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import NpsProvider from '@/src/modules/NPSModule/contexts/NpsContext';

const NpsLayout = () => (
  <NpsProvider>
    <Outlet />
  </NpsProvider>
);

export default memo(NpsLayout);
