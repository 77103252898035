import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { atomModalHelp } from '@/src/modules/ContactsModule/atoms/modalHelp';

export const useModalHelp = () => {
  const [showHelp, setShowHelp] = useAtom(atomModalHelp);

  const openModalHelp = useCallback(() => {
    setShowHelp(true);
  }, [setShowHelp]);

  const closeModalHelp = useCallback(() => {
    setShowHelp(false);
  }, [setShowHelp]);

  return {
    showHelp,
    closeModalHelp,
    openModalHelp,
  };
};
