import { datadogRum } from '@datadog/browser-rum';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useUserData } from '@/src/infrastructure/services/useUserData';

import {
  atomHasMoreSearchCampaigns,
  atomListSearchCampaigns,
  atomLoadingSearchCampaigns,
  atomPayloadSearchCampaigns,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { ITEMS_PER_PAGE_SEARCH_CAMPAIGNS } from '@/modules/CampaignsModule/constants';
import { IListSearchCampaigns } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface ISearchCampaignsArgs {
  keyword: string;
  page?: number;
}

export const useSearchCampaignsData = () => {
  const [listSearchCampaigns, setListSearchCampaigns] = useAtom(atomListSearchCampaigns);
  const [payloadSearchCampaigns, setPayloadSearchCampaigns] = useAtom(atomPayloadSearchCampaigns);
  const setLoadingSearchCampaigns = useSetAtom(atomLoadingSearchCampaigns);
  const setHasMoreSearchCampaigns = useSetAtom(atomHasMoreSearchCampaigns);

  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const campaignService = useCampaignsService();
  const [userData] = useUserData();

  const searchCampaignsGlobal = useCallback(
    async ({ keyword, page }: ISearchCampaignsArgs) => {
      const emptyPage = page && page === 1;

      emptyPage ? setLoadingSearchCampaigns(true) : setLoadingMore(true);

      const payload = {
        ...payloadSearchCampaigns,
        search: keyword,
        page: page ?? payloadSearchCampaigns.page,
      };
      const data: IListSearchCampaigns[] = await campaignService.globalSearchCampaigns(payload);
      const dataSet =
        keyword === payloadSearchCampaigns.search ? listSearchCampaigns?.concat(data) : data;

      setListSearchCampaigns(dataSet);
      setHasMoreSearchCampaigns(data?.length === ITEMS_PER_PAGE_SEARCH_CAMPAIGNS);
      setPayloadSearchCampaigns(payload);
      emptyPage ? setLoadingSearchCampaigns(false) : setLoadingMore(false);

      //TODO: move this metrics to custom hook for Datadog Search Campaigns
      datadogRum.addAction('searchCampaign.onSearch', {
        accountId: Number(userData.companyId),
        email: userData.email,
        keyword,
        page: page ?? payloadSearchCampaigns.page,
      });

      if (data.length === 0) {
        datadogRum.addAction('searchCampaign.onNoResults', {
          accountId: Number(userData.companyId),
          email: userData.email,
          keyword,
        });
      }
    },
    [
      payloadSearchCampaigns,
      campaignService,
      listSearchCampaigns,
      setLoadingSearchCampaigns,
      setListSearchCampaigns,
      setPayloadSearchCampaigns,
      setHasMoreSearchCampaigns,
      userData,
    ]
  );

  return {
    searchCampaignsGlobal,
    loadingMore,
  };
};
