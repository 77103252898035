import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, If, Text } from '@/lib/v2/components';
import { RangeConfig } from '@/lib/v2/components/RangeSlider/RangeSlider';

import { AdditionalCosts } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/AdditionalCosts';
import { ModalComparisonOfPlans } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/ModalComparisonOfPlans';
import { RangeWithLabel } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/RangeWithLabel';

import { atomBillingInformation } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { useSelectedPlanData } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';
import { PlansIds, SelectedPlanData } from '@/modules/MyPlanModule/types';

type Ranges = Array<{ min: number; max: number; step: number }>;

const PlanCustomization = () => {
  const { t } = useTranslation();
  const [modalComparisonIsOpen, setModalComparisonIsOpen] = useState(false);
  const {
    selectedPlanData,
    setSelectedPlanData,
    isMoreOption,
    plan,
    planName,
    handleSelectPlan,
    frequency,
    nextPlan,
    getPlanNameById,
    isFreemiumPlan,
  } = useSelectedPlanData();

  const { getCountrySymbol } = useCountriesOptions();
  const billingInformation = useAtomValue(atomBillingInformation);

  const nextPlanName = nextPlan && getPlanNameById(nextPlan);

  const {
    selectedRanges: { rangeEmail, rangeOnsite, rangeSms },
  } = selectedPlanData;

  const handleChangeRanges = useCallback(
    (value: string | number, keyRange: keyof SelectedPlanData['selectedRanges']) => {
      setSelectedPlanData((prevState) => {
        const copyState = JSON.parse(JSON.stringify(prevState)) as SelectedPlanData;
        copyState.selectedRanges[keyRange] = value;
        copyState.pristine = false;
        return copyState;
      });
    },
    [setSelectedPlanData]
  );

  const getRanges = (values: Ranges): RangeConfig[] => {
    return values.map((item) => ({
      min: item.min,
      max: item.max,
      step: item.step,
    }));
  };

  const emailConfigRange = getRanges(plan?.volume.email.volumeSteps as Ranges);
  const onsiteConfigRange = getRanges(plan?.volume.onsite.volumeSteps as Ranges);
  const smsConfigRange = getRanges(plan?.volume.sms.volumeSteps as Ranges);

  const countrySymbol = getCountrySymbol(billingInformation?.country ?? '');

  const additionalPriceEmail = (plan?.volume.email.priceExc ?? 0) * 1000;
  const additionalPriceSms = (plan?.volume.sms.priceExc[countrySymbol] ?? 0) * 1000;
  const additionalPriceOnsite = (plan?.volume.onsite.priceExc ?? 0) * 1000;

  return (
    <div className="flex w-full flex-col">
      <ModalComparisonOfPlans
        frequency={frequency}
        isOpen={modalComparisonIsOpen}
        onClose={setModalComparisonIsOpen}
        onSelectedPlan={(id: PlansIds) => (handleSelectPlan(id), setModalComparisonIsOpen(false))}
      />
      <div className="mb-7 flex items-baseline gap-4">
        <Text variant="title-1">
          {t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.title', { planName })}
        </Text>
        <Text
          link
          color="primary"
          fontWeight="medium"
          id="link-plans-modal"
          textDecoration="underline"
          onClick={() => setModalComparisonIsOpen(true)}
        >
          {t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.viewMoreDetailPlan')}
        </Text>
      </div>
      <If condition={!isFreemiumPlan}>
        <Text>{t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.descriptionNoFree')}</Text>
      </If>
      <If condition={isFreemiumPlan}>
        <Text>{t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.descriptionFree')}</Text>
      </If>
      <If condition={isMoreOption}>
        <div className="mt-5">
          <Alert type="info">
            <Text className="mt-0.5" variant="text">
              <Trans
                components={{
                  li: (
                    <Text
                      link
                      color="primary"
                      fontWeight="medium"
                      variant="text"
                      onClick={() =>
                        nextPlan !== 'plan-enterprise'
                          ? nextPlan && handleSelectPlan(nextPlan)
                          : handleSelectPlan('plan-enterprise')
                      }
                    />
                  ),
                }}
                i18nKey={'MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.nextPlan'}
                values={{
                  planName: nextPlanName,
                  clickText:
                    nextPlan !== 'plan-enterprise'
                      ? t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.clickSeeIt')
                      : t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.clickToContactUs'),
                }}
              />
            </Text>
          </Alert>
        </div>
      </If>
      <div className="mb-10 mt-6">
        <RangeWithLabel
          config={emailConfigRange}
          disabled={isFreemiumPlan}
          id="range-email"
          label={t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.labelRangeOne')}
          value={rangeEmail}
          onChange={(value: string | number) => handleChangeRanges(value, 'rangeEmail')}
        />
      </div>
      <div className="mb-10">
        <RangeWithLabel
          config={smsConfigRange}
          disabled={isFreemiumPlan}
          id="range-sms"
          label={t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.labelRangeTwo')}
          value={rangeSms}
          onChange={(value: string | number) => handleChangeRanges(value, 'rangeSms')}
        />
      </div>
      <div className="mb-10">
        <RangeWithLabel
          config={onsiteConfigRange}
          disabled={isFreemiumPlan}
          id="range-onsite"
          label={t('MANAGE_PLAN.STEP_TWO.PLAN_CUSTOMIZATION.labelRangeThree')}
          value={rangeOnsite}
          onChange={(value: string | number) => handleChangeRanges(value, 'rangeOnsite')}
        />
      </div>
      <AdditionalCosts
        additionalPriceEmail={additionalPriceEmail}
        additionalPriceOnsite={additionalPriceOnsite}
        additionalPriceSms={additionalPriceSms}
      />
    </div>
  );
};

export default PlanCustomization;
