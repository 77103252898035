import { useEffect, useState } from 'react';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { SenderScore } from '@/src/presentation/types/models/SenderScore.class';

import { useVersion } from './../../application/hooks/useVersion';

export const useSenderScore = (senderScore?: number): [number | undefined, Error | undefined] => {
  const { version } = useVersion();
  const [scoreValue, error] = useEmblue(ServiceMethods.getSenderScore);
  const [score, setScore] = useState(senderScore);
  useEffect(() => {
    if (scoreValue && version === 'v2' && scoreValue.length) {
      const senderScoring = SenderScore.mapSenderScoreDTO(scoreValue as any[]);
      setScore(Math.round((senderScoring[0].scoring + Number.EPSILON) * 10) / 10);
    }
  }, [scoreValue, version]);
  return [score, error];
};
