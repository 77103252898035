import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { IconSvg } from '@/lib/v2/components';
import { ImmediateDeliveryIcon, ScheduledDeliveryIcon } from '@/lib/v2/icons/general';

import { ImmediateDeliveryForm } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsScheduling/components/ImmediateDeliveryForm';
import { SchedulingDeliveryForm } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsScheduling/components/SchedulingDeliveryForm';

import { atomDisableEditAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { DELIVERY_OPTIONS } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const TRANSLATE_SCHEDULING = 'CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.SCHEDULING';

export const useDeliverySms = () => {
  const { t } = useTranslation();
  const disableEditAction = useAtomValue(atomDisableEditAction);

  const immediateDelivery = {
    id: DELIVERY_OPTIONS.immediate,
    icon: <IconSvg height="40px" svgComponent={<ImmediateDeliveryIcon />} width="40px" />,
    title: t(`${TRANSLATE_SCHEDULING}.immediate`),
    description: t(`${TRANSLATE_SCHEDULING}.immediateDescription`),
    children: <ImmediateDeliveryForm />,
  };

  const scheduledDelivery = {
    id: DELIVERY_OPTIONS.scheduled,
    icon: <IconSvg height="40px" svgComponent={<ScheduledDeliveryIcon />} width="40px" />,
    title: t(`${TRANSLATE_SCHEDULING}.schedule`),
    description: t(`${TRANSLATE_SCHEDULING}.scheduleDescription`),
    children: <SchedulingDeliveryForm disabled={disableEditAction} />,
  };

  return [immediateDelivery, scheduledDelivery];
};
