import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown, TableSkeleton, TextSkeleton } from '@/lib/components';
import { ItemsListProps } from '@/lib/components/DropDown/DropDown';
import { closeModal, FloatModal, openModal } from '@/lib/components/Modal/Modal';
import { Spinner } from '@/lib/components/Util/Spinner';
import { ActivityCleanFilterIcon, DataStorageIcon, PlusIcon, TagCloudIcon } from '@/lib/icon';
import {
  Button,
  Card,
  CardSection,
  Container,
  InputSearch,
  Text,
  toast,
} from '@/lib/v2/components';
import IconSvg from '@/lib/v2/components/IconSvg/IconSvg';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';

import { EditCategoryModal } from '@/src/ContactsModule/components/ContactsModals';
import { CreateAndEditTagModal } from '@/src/ContactsModule/components/ContactsModals/CreateAndEditTagModal';
import TagCloud from '@/src/ContactsModule/components/TagCloud/TagCloud';
import { TagsActionBar } from '@/src/ContactsModule/components/TagsActionBar/TagsActionBar';
import { TagsTable } from '@/src/ContactsModule/components/TagsTable';
import { CategoryIcon } from '@/src/ContactsModule/Icons/CategoryIcon';
import { TagIcon } from '@/src/ContactsModule/Icons/TagIcon';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useTagCloudList } from '@/src/infrastructure/services/useTagCloudList';
import { useTagList } from '@/src/infrastructure/services/useTagList';

import { useRecalculateTag } from './useRecalculateTag';

import { DistributeModal, SectionHeader } from '@/modules/ContactsModule/components';
import {
  useMutationContactsContext,
  useStateContactsContext,
} from '@/modules/ContactsModule/contexts/ContactsContext';
import { useDistributeContacts } from '@/modules/ContactsModule/hooks/useDistributeContacts';

export const TagsMain = () => {
  const { t } = useTranslation();

  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshTagsValue);
  const [lastRefresh, setLastRefresh] = useState<number>(initialLastRefreshValue ?? 0);
  const [isChecked, setIsChecked] = useState(false);
  const [totalTags, setTotalTags] = useState<number>();

  const {
    searchQuery: search,
    pageIndex,
    pageSize,
    gotoPageIndex,
    totalPages,
  } = useStateContactsContext();
  const { setListTotalPagination, changeTablePage, setSearchQuery } = useMutationContactsContext();

  const [searchQueryHandler, setSearchQueryHandler] = useState(search);

  const [isRecalculateTag, setIsRecalculateTag] = useState<boolean>(false);

  const [tagsCategoriesFilter, setTagsCategoriesFilter] = useState<number[]>([]);
  const [tagsDataSourceFilter, setTagsDataSourceFilter] = useState<number[]>([]);
  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('asc');
  const [tagCloudEnabled, setTagCloudEnabled] = useState<boolean>(false);
  const [tagCloudList, , isLoadingTagsCloud] = useTagCloudList({
    identifier: 'TAG_CLOUD',
    search,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
  });
  const [tagList, , isLoadingTags] = useTagList({
    page: pageIndex,
    pageSize,
    search,
    orderColumn,
    orderDirection,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
  });
  const [tagsCountOfSearch] = useEmblue(ServiceMethods.getTagsCountOfSearch, {
    search,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
    identifier: tagsDataSourceFilter.length > 0 ? 'TAGS_COUNT_BY_DATA_SOURCE' : '',
  });
  const [totalTagsCount] = useEmblue(ServiceMethods.getTagsCountOfSearch, { lastRefresh });
  const [countTotalTags, setCountTotalTags] = useState<number>(0);
  const [tagsCategories] = useEmblue(ServiceMethods.getTagsCategories, {
    identifier: 'TAGS_FILTER_CATEGORY',
  });
  const [selectedTagsRows, setSelectedTagsRows] = useState<number[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);

  const [, setTagsToRecalculate] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [recalculateTag, recalculateTagResponse] = useRecalculateTag();

  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const service = useService();

  useEffect(() => {
    setSearchQueryHandler(search);
  }, [search]);

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    service?.incrementLastRefreshTagsValue();
  }, [service]);

  const getOnSearch = () => {
    setSearchQuery(searchQueryHandler ?? '');
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQueryHandler(event.target.value);

  const handleClear = () => {
    setSearchQuery('');
    setSearchQueryHandler('');
  };

  useEffect(() => {
    setListTotalPagination(totalTags ? totalTags : 0);
  }, [setListTotalPagination, totalTags]);

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
    }
  }, [initialLastRefreshValue]);

  useEffect(() => {
    if (!totalTags && tagsCountOfSearch && tagsCountOfSearch.count) {
      setTotalTags(tagsCountOfSearch.count);
    }
    if (
      !search &&
      !tagsDataSourceFilter.length &&
      tagsCountOfSearch &&
      totalTags &&
      countTotalTags > 0 &&
      countTotalTags !== totalTags
    ) {
      setTotalTags(tagsCountOfSearch.count);
    }
    if (
      totalTags &&
      tagsCountOfSearch &&
      tagsCountOfSearch.count &&
      tagsCountOfSearch.count !== totalTags
    ) {
      setTotalTags(tagsCountOfSearch.count);
    }
    if (totalTagsCount) {
      setCountTotalTags(totalTagsCount.count);
    }
    if (tagsDataSourceFilter.length > 0) {
      setTotalTags(tagCloudList && tagCloudList?.count);
    }
  }, [
    tagsCategoriesFilter,
    tagsCountOfSearch,
    totalTags,
    search,
    tagsDataSourceFilter.length,
    totalTagsCount,
    tagCloudList,
    countTotalTags,
  ]);

  const createTag = useCallback(() => {
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <CreateAndEditTagModal
          lastRefreshIncrement={lastRefreshIncrement}
          tagsCategories={tagsCategories?.list}
        />
      ),
    });
  }, [lastRefreshIncrement, tagsCategories?.list]);

  useEffect(() => {
    const isThereTagToRecalculate = tagList?.list.some((e) => e.tag.updated === 'true') || false;
    setTagsToRecalculate(isThereTagToRecalculate);
  }, [tagList]);

  const initialStateOrderByList = [
    { id: 'Name', isAsc: true },
    { id: 'Contacts', isAsc: undefined },
  ];
  const contactSelection: ITag[] = useMemo(
    () =>
      selectedTagsRows
        ?.filter((index) => tagList?.list?.[index]?.tag)
        ?.map((index) => tagList?.list[index].tag as ITag),
    [selectedTagsRows]
  );

  const [tableOrderByList, setTableOrderByList] = useState(initialStateOrderByList);

  const changeOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);

  const applyDataSourceFilter = (dataSourceList: string[]) => {
    setTagsDataSourceFilter(dataSourceList.map((i) => Number(i)));
  };

  const dataSourceOptions: ItemsListProps[] = useMemo(
    () => [
      {
        id: '4',
        title: t('TAGS_MAIN.actClick'),
        state: false,
      },
      {
        id: '1',
        title: t('TAGS_MAIN.csv'),
        state: false,
      },
      {
        id: '8',
        title: t('TAGS_MAIN.flows'),
        state: false,
      },
      {
        id: '7',
        title: t('TAGS_MAIN.remarketing'),
        state: false,
      },
      {
        id: '2',
        title: t('TAGS_MAIN.UI/1A1'),
        state: false,
      },
      {
        id: '6',
        title: t('TAGS_MAIN.webTracking'),
        state: false,
      },
      {
        id: '0',
        title: t('TAGS_MAIN.withoutDataSource'),
        state: false,
      },
    ],
    [t]
  );

  const resetTags = () => {
    setSearchQuery('');
    setTagsCategoriesFilter([]);
    setTagsDataSourceFilter([]);
    setOrderDirection('asc');
    setOrderColumn(undefined);
    setTableOrderByList(initialStateOrderByList);
  };

  const viewTagCloud = () => {
    setTagCloudEnabled(!tagCloudEnabled);
  };

  const tagListCurrent = useMemo(() => tagList, [tagList]);
  const tagCloudListCurrent = useMemo(() => tagCloudList, [tagCloudList]);

  useEffect(() => {
    setIsChecked(selectedTagsRows.length > 0);
  }, [selectedTagsRows]);

  useEffect(() => {
    if (confirmDelete) {
      deleteTags()
        .then(() => lastRefreshIncrement())
        .catch(console.error);
      setConfirmDelete(false);
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (openEditModal) {
      openEditModalFunction().catch(console.error);
      setOpenEditModal(false);
    }
  }, [openEditModal]);

  useEffect(() => {
    if (openMergeModal) {
      openMergeModalFunction();
      setOpenMergeModal(false);
    }
  }, [openMergeModal]);

  const openMergeModalFunction = useCallback(() => {
    if (!openMergeModal) return;

    const title = t('TAGS_FILTERS.mergeTags');
    const allCategories = tagsCategories ? tagsCategories.list : [];
    const tagsForMerge: ITag[] = Array.isArray(selectedTagsRows)
      ? selectedTagsRows
          .filter((index) => tagList?.list?.[index]?.tag)
          .map((index) => tagList?.list?.[index]?.tag as ITag)
      : [];

    const element = (
      <CreateAndEditTagModal
        lastRefreshIncrement={lastRefreshIncrement}
        mode="merge"
        tagsCategories={allCategories}
        tagsForMerge={tagsForMerge}
        wordings={{ title }}
      />
    );
    //TO DO: change this openModal to new Modal component v2
    openModal({ element });
  }, [openMergeModal, tagsCategories, selectedTagsRows, tagList]);

  const openEditModalFunction = async () => {
    const selectedTagRow = selectedTagsRows.at(0);
    if (tagList === undefined || selectedTagRow === undefined) {
      return;
    }
    const selectedTag = tagList.list.at(selectedTagRow)?.tag;
    if (selectedTag === undefined) {
      return;
    }
    const extraDataTag = await service?.getTagContent({ id: selectedTag.id });
    // eslint-disable-next-line eqeqeq
    if (extraDataTag == undefined) {
      return;
    }
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <CreateAndEditTagModal
          editProps={{
            tagId: selectedTag.id,
            tagName: selectedTag.name,
            tagCategory: selectedTag?.category ?? '',
            matchContent: extraDataTag.tagContentTypeId != null,
            content: extraDataTag.content,
            contentType: extraDataTag.tagContentTypeId,
            encoding: extraDataTag.encoding,
            url: extraDataTag.url,
          }}
          lastRefreshIncrement={lastRefreshIncrement}
          mode="edit"
          tagsCategories={tagsCategories?.list}
        />
      ),
    });
  };

  const deleteTags = async () => {
    if (tagList === undefined || selectedTagsRows.length < 1) return;
    //TO DO: change this openModal to new Modal component v2
    openModal({
      element: (
        <FloatModal>
          <Spinner />
        </FloatModal>
      ),
    });
    const tagsIds: number[] =
      selectedTagsRows.length === pageSize
        ? tagList.list.map((tag) => tag.tag.id) // all ids of page
        : tagList.list.filter((c, i) => selectedTagsRows.includes(i)).map((tag) => tag.tag.id); // ids of some contacts in page
    const result = await service?.deleteSelectedTags({ tagsIds: tagsIds });
    closeModal(); // Spinner
    showNotification(result ? result : false);
  };

  const recalculateTagHandler = (event: MouseEvent<HTMLButtonElement>, tagId: number) => {
    event.stopPropagation();
    if (tagId) {
      setIsRecalculateTag(true);
      recalculateTag(tagId);
    }
  };

  useEffect(() => {
    if (recalculateTagResponse) {
      setIsRecalculateTag(false);
      lastRefreshIncrement();
    }
  }, [recalculateTagResponse]);

  const showNotification = (result: boolean) => {
    toast({
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      variant: result ? 'success' : 'error',
    });
  };

  useShowModalSignal('CreateTags', createTag);

  const title = useMemo(() => {
    return (
      <Flex column itemAlignment="start">
        <Text as="span" fontWeight="medium" variant="title-1">
          {t('TAGS_MAIN.tag')}
          <span className="text-[#004DBC]">
            {countTotalTags > 0 && (
              <span className="font-medium text-[#004DBC]"> ({quantifier(countTotalTags)})</span>
            )}
          </span>
        </Text>
        {!tagList || isLoadingTags ? (
          <TextSkeleton />
        ) : !search && !tagsCategoriesFilter.length && !tagsDataSourceFilter.length && totalTags ? (
          <span>{t('TAGS_MAIN.noFilters')}</span>
        ) : (search || tagsCategoriesFilter.length || tagsDataSourceFilter.length) &&
          totalTags &&
          ((tagsCountOfSearch && tagsCountOfSearch.count) ||
            (tagCloudList && tagCloudList.count)) ? (
          <span>
            {' '}
            {t('TAGS_MAIN.youHave')}{' '}
            <span className="font-medium text-[#004DBC]">
              {' '}
              {quantifier(
                tagCloudList && tagsDataSourceFilter.length > 0 ? tagCloudList.count : totalTags
              )}{' '}
            </span>{' '}
            {totalTags === 1 ? `${t('TAGS_MAIN.contactMatch')}` : `${t('TAGS_MAIN.contactsMatch')}`}
          </span>
        ) : (
          t('noResultsFound')
        )}
      </Flex>
    );
  }, [
    countTotalTags,
    isLoadingTags,
    search,
    t,
    tagCloudList,
    tagList,
    tagsCategoriesFilter.length,
    tagsCountOfSearch,
    tagsDataSourceFilter.length,
    totalTags,
  ]);

  const handleCloseModalCategoryEdit = useCallback(() => {
    setOpenEditCategoryModal(false);
  }, [openEditCategoryModal]);

  const {
    distributeIsDisabled,
    handleDistributeSubmit,
    distributeIsLoading,
    openDistributeModal,
    setOpenDistributeModal,
    quantityOptions,
  } = useDistributeContacts({
    currentList: tagList?.list ?? [],
    selectedRows: selectedTagsRows,
  });

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button iconLeft={<PlusIcon />} id="create-tag-button" onClick={createTag}>
                  {t('CONTACTS_ACTIONS_DROPDOWN.Tag')}
                </Button>
              }
              icon={<TagIcon />}
              justifyContentActionsButtons="end"
              title={title}
            />
            <div className="size-full">
              <Flex column withGap gapSize="medium">
                {!isChecked ? (
                  <Flex
                    alignment="start"
                    className={`-mb-[62px] ${
                      !isChecked && 'xl:max-w-[900px] 2xl:max-w-fit'
                    } self-start`}
                  >
                    <Container background="white">
                      <Flex withGap alignment="start" className="mt-7">
                        {countTotalTags > 0 && (
                          <>
                            <div className="w-[340px]">
                              <InputSearch
                                id={'search-tags'}
                                placeHolder={t('TAGS_FILTERS.searchInputBar')}
                                value={searchQueryHandler}
                                onAction={getOnSearch}
                                onChange={handleOnChange}
                                onClear={handleClear}
                              />
                            </div>
                            <Flex noGrow withGap>
                              <div className="min-w-[150px]">
                                <DropDown
                                  checkBox
                                  overflowWidth
                                  search
                                  btnOnAction={(itemFilterList: string[]) => {
                                    setTagsCategoriesFilter &&
                                      setTagsCategoriesFilter(itemFilterList.map((i) => Number(i)));
                                  }}
                                  btnText={t('TAGS_FILTERS.apply')}
                                  iconHead={<CategoryIcon stroked tableSize />}
                                  itemList={
                                    tagsCategories?.list &&
                                    tagsCategories?.list.map((x) => ({
                                      id: String(x.id),
                                      title:
                                        x.name === 'noCategory'
                                          ? t('TAGS_TABLE.noCategory')
                                          : x.name,
                                      state: false,
                                    }))
                                  }
                                  searchPlaceHolder={t('TAGS_FILTERS.search')}
                                  subtitle={t('TAGS_FILTERS.selectCategory')}
                                  titleHead={t('TAGS_FILTERS.category')}
                                  tooltip={t('TAGS_FILTERS.filterCategory')}
                                  totalSelectedChk={
                                    tagsCategoriesFilter && tagsCategoriesFilter.length
                                  }
                                  valuesSelected={
                                    tagsCategoriesFilter &&
                                    tagsCategoriesFilter.map((i) => String(i))
                                  }
                                />
                              </div>
                              <div className="min-w-[150px]">
                                <DropDown
                                  checkBox
                                  overflowWidth
                                  btnOnAction={applyDataSourceFilter}
                                  btnText={t('ITEMS_FILTERS.apply')}
                                  iconHead={<DataStorageIcon />}
                                  itemList={
                                    dataSourceOptions &&
                                    dataSourceOptions?.map((x) => ({
                                      id: String(x.id),
                                      title: x.title,
                                      state: false,
                                    }))
                                  }
                                  subtitle={t('ITEMS_FILTERS.selectSource')}
                                  titleHead={t('ITEMS_FILTERS.source')}
                                  tooltip={t('ITEMS_FILTERS.source')}
                                  totalSelectedChk={
                                    tagsDataSourceFilter && tagsDataSourceFilter?.length
                                  }
                                  valuesSelected={tagsDataSourceFilter?.map((i) => String(i))}
                                />
                              </div>
                              <div className="w-auto">
                                <Button
                                  fullWidth
                                  standard
                                  iconLeft={
                                    <IconSvg
                                      fillColor="primary"
                                      svgComponent={<ActivityCleanFilterIcon />}
                                    />
                                  }
                                  id="clear-filters-button"
                                  tooltip={t('TAGS_FILTERS.cleanAllFilters')}
                                  onClick={resetTags}
                                ></Button>
                              </div>
                              <div className="w-auto">
                                <Button
                                  fullWidth
                                  standard
                                  iconLeft={
                                    <IconSvg
                                      fillColor={
                                        // eslint-disable-next-line no-nested-ternary
                                        tagCloudEnabled
                                          ? 'primary'
                                          : isHovered
                                          ? 'primary'
                                          : 'disabled'
                                      }
                                      height="20px"
                                      strokeColor={
                                        // eslint-disable-next-line no-nested-ternary
                                        tagCloudEnabled
                                          ? 'primary'
                                          : isHovered
                                          ? 'primary'
                                          : 'disabled'
                                      }
                                      svgComponent={<TagCloudIcon />}
                                      width="20px"
                                    />
                                  }
                                  tooltip={t('CONTACTS_FILTERS.tagCloud')}
                                  onClick={viewTagCloud}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                ></Button>
                              </div>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </Container>
                  </Flex>
                ) : (
                  <Flex alignment="start" className="z-50 mb-[-62px] self-start">
                    <TagsActionBar
                      contactSelection={contactSelection}
                      distributeIsDisabled={distributeIsDisabled}
                      setConfirmDelete={setConfirmDelete}
                      setOpenDistributeModal={setOpenDistributeModal}
                      setOpenEditCategoryModal={setOpenEditCategoryModal}
                      setOpenEditModal={setOpenEditModal}
                      setOpenMergeModal={setOpenMergeModal}
                    />
                  </Flex>
                )}
                {tagCloudEnabled && tagCloudListCurrent && (
                  <TagCloud
                    callToAction={[resetTags, createTag]}
                    isLoading={isLoadingTagsCloud}
                    tagList={tagCloudListCurrent.list}
                    totalTagCount={countTotalTags || 0}
                  />
                )}
                {!tagListCurrent && (
                  <div className="size-full py-10">
                    <TableSkeleton />
                  </div>
                )}
                {tagListCurrent && !tagCloudEnabled && (
                  <TagsTable
                    callToAction={[resetTags, createTag]}
                    changeTableOrder={changeOrderBy}
                    changeTablePage={changeTablePage}
                    gotoPageIndex={gotoPageIndex}
                    isLoading={isLoadingTags}
                    isRecalculateTag={isRecalculateTag}
                    recalculateTag={recalculateTagHandler}
                    setSelectedTags={setSelectedTagsRows}
                    setTableOrderByList={setTableOrderByList}
                    tableOrderByList={tableOrderByList}
                    tagList={tagListCurrent.list}
                    totalPages={totalPages}
                    totalTagCount={countTotalTags}
                  />
                )}

                {openEditCategoryModal && (
                  <EditCategoryModal
                    categories={tagsCategories?.list ?? []}
                    lastRefreshIncrement={lastRefreshIncrement}
                    show={openEditCategoryModal}
                    tags={selectedTagsRows?.map(
                      (index) => tagListCurrent?.list?.[index].tag as ITag
                    )}
                    onClose={handleCloseModalCategoryEdit}
                  />
                )}
              </Flex>
            </div>
          </Flex>
        </CardSection>
      </Card>
      <DistributeModal
        isLoading={distributeIsLoading}
        isOpen={openDistributeModal}
        quantityOptions={quantityOptions}
        onClose={setOpenDistributeModal}
        onSubmit={handleDistributeSubmit}
      />
    </>
  );
};
