export const EmptyWidgetsIcon = () => {
  return (
    <svg
      height="153.178"
      viewBox="0 0 251.114 153.178"
      width="251.114"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-25.501 -17.5)">
        <path
          d="M214.912,132,160.8,139.952a16.794,16.794,0,0,1,8.866,14.9,14.75,14.75,0,0,1-.64,4.479Z"
          data-name="Trazado 5329"
          fill="#ddd"
          opacity="0.6"
          transform="translate(-11.585 -9.797)"
        />
        <path
          d="M275.538,143.226C272.248,73.483,217.587,18,150.769,18S29.291,73.483,26,143.226"
          data-name="Trazado 5330"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(0 0)"
        />
        <g opacity="0.6" transform="translate(33.495 24.398)">
          <path
            d="M211.69,39.168a111.12,111.12,0,0,0-108.59,0l23.857,48.171a59.81,59.81,0,0,1,61.79-2.1Z"
            fill="#004dbc"
            transform="translate(-40.121 -25)"
          />
          <path
            d="M118.019,90.854,94.071,42.5C59.885,62.975,36.211,101.091,34.2,145.332H94.254c-.183-2.1-.366-4.2-.366-6.4A60.024,60.024,0,0,1,118.019,90.854Z"
            data-name="Trazado 5332"
            fill="#004dbc"
            transform="translate(-34.2 -26.504)"
          />
          <path
            d="M283.022,145.332c-2.1-44.241-25.685-82.357-59.871-102.832L200.3,88.569a59.866,59.866,0,0,1,27.6,50.365,49.126,49.126,0,0,1-.366,6.4Z"
            data-name="Trazado 5333"
            fill="#004dbc"
            transform="translate(-48.475 -26.504)"
          />
        </g>
        <circle cx="8.227" cy="8.227" fill="#fff" r="8.227" transform="translate(53.879 96.152)" />
        <circle cx="8.227" cy="8.227" fill="#fff" r="8.227" transform="translate(144.371 33.996)" />
        <circle cx="8.227" cy="8.227" fill="#fff" r="8.227" transform="translate(233.949 97.98)" />
        <g data-name="Grupo 8520" id="Grupo_8520" transform="translate(74.811 59.773)">
          <path
            d="M121.9,93.982l-7.5-15.082C93.385,93.891,79.491,119.393,79.4,148.46H98.047c-.183-2.1-.366-4.2-.366-6.4A60.365,60.365,0,0,1,121.9,93.982Z"
            fill="#ddd"
            transform="translate(-79.4 -65.006)"
          />
          <path
            d="M197.881,73.389A73.922,73.922,0,0,0,121.1,75.583l7.5,15.082a59.81,59.81,0,0,1,61.79-2.1Z"
            fill="#ddd"
            transform="translate(-82.984 -63.7)"
          />
          <path
            d="M246.186,148.245c0-30.621-15.448-57.312-38.391-71.845l-7.5,15.082a59.866,59.866,0,0,1,27.6,50.365,49.126,49.126,0,0,1-.366,6.4Z"
            fill="#ddd"
            transform="translate(-89.79 -64.791)"
          />
        </g>
        <path
          d="M109.725,83.4A85.588,85.588,0,0,0,79.5,149.136"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-4.293 -5.91)"
        />
        <path
          d="M198.621,73.142A73.4,73.4,0,0,0,128,72.372"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-9.538 -3.498)"
        />
        <path
          d="M248.1,148.9a85.277,85.277,0,0,0-31.6-66.8"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-17.286 -5.678)"
        />
        <path
          d="M65.022,114A4.44,4.44,0,0,0,61,116.559h8.044A4.44,4.44,0,0,0,65.022,114Z"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-3.008 -8.25)"
        />
        <path
          d="M68.48,111.477a1.188,1.188,0,0,0,0-2.377,1.294,1.294,0,0,0-1.28,1.188,1.232,1.232,0,0,0,1.28,1.188Z"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-3.541 -7.829)"
        />
        <path
          d="M62.18,111.477a1.188,1.188,0,0,0,0-2.377,1.294,1.294,0,0,0-1.28,1.188,1.232,1.232,0,0,0,1.28,1.188Z"
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-2.999 -7.829)"
        />
        <path
          d="M265.38,113.277a1.294,1.294,0,0,0,1.28-1.188,1.283,1.283,0,1,0-1.28,1.188Z"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-20.462 -7.984)"
        />
        <path
          d="M259.08,113.277a1.294,1.294,0,0,0,1.28-1.188,1.232,1.232,0,0,0-1.28-1.188,1.294,1.294,0,0,0-1.28,1.188,1.232,1.232,0,0,0,1.28,1.188Z"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-19.921 -7.984)"
        />
        <path
          d="M261.9,119.025a4.421,4.421,0,0,0,4.2-2.925H257.7A4.509,4.509,0,0,0,261.9,119.025Z"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-19.912 -8.431)"
        />
        <path
          d="M167.387,44.268a1.307,1.307,0,0,0,1.28-1.28,1.232,1.232,0,0,0-1.28-1.188,1.21,1.21,0,0,0-1.188,1.188,1.13,1.13,0,0,0,1.188,1.28Z"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-12.048 -2.045)"
        />
        <path
          d="M161.087,44.268a1.307,1.307,0,0,0,1.28-1.28,1.232,1.232,0,0,0-1.28-1.188,1.21,1.21,0,0,0-1.188,1.188,1.13,1.13,0,0,0,1.188,1.28Z"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-11.506 -2.045)"
        />
        <path
          d="M168.109,48.2H159.7"
          fill="#fff"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(-11.49 -2.595)"
        />
        <path
          d="M210.658,132l-40.452,18.135a18.532,18.532,0,0,1,.163,2.2,13.112,13.112,0,0,1-.57,3.985Z"
          fill="#fff"
          transform="translate(-10.53 -8.748)"
        />
        <g data-name="Grupo 8521" id="Grupo_8521" transform="translate(221.711 115.774)">
          <circle
            cx="23.819"
            cy="23.819"
            fill="#fff"
            r="23.819"
            transform="translate(3.166 3.494)"
          />
          <path
            d="M30.827,3.375A27.452,27.452,0,1,0,58.279,30.827,27.448,27.448,0,0,0,30.827,3.375ZM42.771,32.939H32.939v9.833a2.112,2.112,0,0,1-4.223,0V32.939H18.883a2.112,2.112,0,0,1,0-4.223h9.833V18.883a2.112,2.112,0,0,1,4.223,0v9.833h9.833a2.112,2.112,0,0,1,0,4.223Z"
            fill="#fc7e00"
            transform="translate(-3.375 -3.375)"
          />
        </g>
        <g
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(53.879 96.152)"
        >
          <circle cx="8.227" cy="8.227" r="8.227" stroke="none" />
          <circle cx="8.227" cy="8.227" fill="none" r="7.727" />
        </g>
        <g
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(144.371 33.996)"
        >
          <circle cx="8.227" cy="8.227" r="8.227" stroke="none" />
          <circle cx="8.227" cy="8.227" fill="none" r="7.727" />
        </g>
        <g
          fill="none"
          opacity="0.6"
          stroke="#231f20"
          strokeWidth="1"
          transform="translate(233.949 97.98)"
        >
          <circle cx="8.227" cy="8.227" r="8.227" stroke="none" />
          <circle cx="8.227" cy="8.227" fill="none" r="7.727" />
        </g>
      </g>
    </svg>
  );
};
