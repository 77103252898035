import classNames from 'classnames';
import { isValidElement, memo, ReactElement } from 'react';

import {
  Button,
  ButtonProps,
  Card,
  CardSection,
  IconSvg,
  IconSvgProps,
  Text,
  Tooltip,
} from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { QuestionIcon } from '@/lib/v2/icons/solid';

export interface InsightCardProps {
  /** Unique identifier and data-testid of the component */
  id?: string;
  /** Receives an IconSvg component v2 */
  icon: ReactElement<IconSvgProps>;
  /** Card title  */
  title?: string | number;
  /** Card description */
  description?: string;
  /** Receives a Button component v2 */
  button?: ReactElement<ButtonProps>;
  /** Show a loading skeleton in the title */
  isLoading?: boolean;
  /** callback function when clicking the card */
  onClick?: () => void;
  /** change title color */
  titleColor?: 'black' | 'primary';
  /** show a tooltip */
  tooltip?: string;
  /** delete box shadow */
  noShadow?: boolean;
  /** show a tooltip icon Interrogation */
  tooltipInterrogation?: string;
  /** delete spacing */
  hasSpacing?: boolean;
  /** show all text without truncate*/
  fullText?: boolean;
}

const InsightCard = ({
  id,
  icon,
  button,
  title,
  description,
  onClick,
  isLoading,
  titleColor = 'black',
  tooltip,
  noShadow = false,
  tooltipInterrogation,
  hasSpacing = false,
  fullText = false,
}: InsightCardProps) => {
  if (!isValidElement(icon) || icon.type !== IconSvg) {
    throw new Error('The "icon" prop must be an IconSvg component v2');
  }

  if (button && (!isValidElement(button) || button.type !== Button)) {
    throw new Error('The "button" prop must be a Button component v2');
  }

  const classes = classNames({
    'focus:ring-2 focus:ring-[#004DBC] focus:ring-offset-2': !button && onClick,
  });

  const classesIcon = classNames({
    'basis-1/3 justify-center': button,
    'flex-none basis-10 xl:basis-12 2xl:basis-14': !button,
  });

  const classesContainerTextAndButton = classNames({
    'flex basis-2/3 flex-col gap-1 justify-between': button,
    'flex flex-col justify-center truncate': !button && !fullText,
    'flex flex-col justify-center': !button && fullText,
  });

  const classesText = classNames({
    '!text-12 xl:!text-14 2xl:!text-18 truncate': !button && !fullText,
    '!text-12 xl:!text-14 2xl:!text-18 ': !button && fullText,
  });

  const cardContent = (
    <Card className={classes} id={id} noShadow={noShadow} onClick={onClick}>
      <CardSection>
        <div className="flex gap-4">
          <div className={classesIcon}>{icon}</div>
          <div className={classesContainerTextAndButton}>
            {isLoading && (
              <div className="mb-2 flex w-16 animate-pulse flex-col pt-1">
                <div className="h-3 w-full rounded-full bg-gray-200"></div>
              </div>
            )}
            {!isLoading && (
              <Flex
                withGap
                alignment="start"
                className="!h-auto"
                itemAlignment="center"
                noGrow={hasSpacing}
              >
                <Text color={titleColor} fontWeight="bold" variant="sub-headline">
                  {title}
                </Text>
                {tooltipInterrogation && (
                  <div title={tooltipInterrogation}>
                    <IconSvg svgComponent={<QuestionIcon />} />
                  </div>
                )}
              </Flex>
            )}
            <Text className={classesText} variant="text">
              {description}
            </Text>
            {button}
          </div>
        </div>
      </CardSection>
    </Card>
  );

  return tooltip ? (
    <Tooltip isDark isFullWidth content={tooltip}>
      {cardContent}
    </Tooltip>
  ) : (
    cardContent
  );
};

export default memo(InsightCard);
