export const numberToCurrency = (
  num: number | string,
  noCurrencySymbol: boolean = false,
  decimals: number = 2
) => {
  const number = Number(String(num).replace(',', '.'));
  if (noCurrencySymbol) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
    }).format(number);
  }
  return `$ ${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(number)}`;
};
