import { t } from 'i18next';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ReactI18NextChild } from 'react-i18next';

import { ItemsListProps } from '@/lib/components/DropDown/DropDown';
import { SearchInputBar } from '@/lib/components/SearchInputBar';
import { BigColumnSkeleton } from '@/lib/components/Skeletons';
import { CrossSimpleIcon } from '@/lib/icon';
import { DownArrowIcon } from '@/lib/icon/DownArrowIcon';
import { IconSvg } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';
import { useResetSearch } from '@/src/infrastructure/services/useResetSearch';

export type ItemSelectProps = {
  title: string;
  id: string;
};

export type SelectProps = {
  itemList?: ItemSelectProps[];
  searchPlaceHolder?: string;
  search?: boolean;
  plain?: boolean;
  title?: string;
  overflowWidth?: boolean;
  iconHead?: JSX.Element;
  onClick?: (itemFilterList: string[]) => void;
  onSelect?: (element: ItemSelectProps, current: string) => void;
  defaultValue?: string;
  clearValue?: boolean;
};

export type CleanGroupBoxRef = { cleanGroupBox: () => void };

export const Select = forwardRef<CleanGroupBoxRef, SelectProps>(
  (
    {
      searchPlaceHolder = '',
      itemList,
      plain,
      onSelect,
      search,
      title,
      iconHead,
      overflowWidth,
      onClick,
      defaultValue,
      clearValue,
    }: SelectProps,
    ref
  ) => {
    const [toggle, setToggle] = useState(false);
    const defaultValueAux = itemList?.find((item) => item.id === defaultValue);
    const [current, setCurrent] = useState<string>(
      (defaultValueAux && defaultValueAux?.title) ?? ''
    );
    const [searchItem, setSearchItem] = useState('');

    useEffect(() => {
      setCurrent(defaultValueAux?.title ?? '');
    }, [defaultValueAux]);

    const { resetFilter } = useResetSearch({ cleanBox: setSearchItem as () => void });

    const titleDefault = title ? title : itemList && itemList[0].title;

    const setItem = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchItem(event.target.value);
    };

    const foundItemCoincidences = (item: ItemsListProps) => {
      if (searchItem === '') {
        return true;
      } else {
        const regex = new RegExp(searchItem, 'i');
        return regex.test(item.title);
      }
    };

    const toggleOptions = useCallback(() => {
      setToggle(!toggle);
      resetFilter();
    }, [toggle]);

    const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    useOnClickOutside(toggleRef, () => setToggle(false));

    const onAction = (element: ItemSelectProps) => {
      setCurrent(element.title);
      toggleOptions();
      onSelect && onSelect(element, current);
      onClick && onClick([element.id]);
    };

    const onReset = useCallback(() => {
      setCurrent('');
      onClick && onClick([]);
    }, [onClick]);

    useImperativeHandle(
      ref,
      () => {
        return { cleanGroupBox: onReset };
      },
      [onReset]
    );

    itemList && itemList.sort((e, b) => e.title.localeCompare(b.title, 'en', { numeric: true }));

    const filteredItems =
      itemList && itemList.filter((item: ItemSelectProps) => foundItemCoincidences(item));

    return (
      <div ref={toggleRef} className="max-h-xl relative inline-block h-[38px] w-full text-left">
        <button
          className={`h-[38px] w-full bg-white px-4  py-1
                    ${title ? 'text-14' : 'text-12'}
                    font-medium text-gray-700 hover:bg-gray-50
                    focus:outline-none focus:ring-2 focus:ring-[#004DBC] focus:ring-offset-2 focus:ring-offset-gray-100
                    ${!plain ? 'rounded-md border border-gray-300 shadow-sm' : ''}
            `}
          type="button"
          onClick={() => (clearValue && current ? '' : toggleOptions())}
        >
          <Flex>
            <Flex withGap>
              {iconHead}
              {current || titleDefault}
            </Flex>
            {current && titleDefault && clearValue ? (
              <button className="pl-1" data-testid="reset-item-selected" onClick={onReset}>
                <IconSvg svgComponent={<CrossSimpleIcon />} />
              </button>
            ) : (
              <div>
                <DownArrowIcon color="black" />
              </div>
            )}
          </Flex>
        </button>
        {toggle && (
          <>
            <div
              className={`${
                overflowWidth ? 'w-fit max-w-[30rem]' : 'w-full'
              } mt-min-w-200 absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              style={{ right: 'auto' }}
            >
              {itemList ? (
                <>
                  {search && (
                    <div className="w-full px-3 pt-2">
                      <section className="mb-2 w-full">
                        <SearchInputBar
                          cleanBox={resetFilter}
                          placeHolder={searchPlaceHolder}
                          value={searchItem}
                          onChange={setItem}
                        />
                      </section>
                    </div>
                  )}
                  <section
                    className={`visible-scrollbar h-full max-h-96 overflow-y-auto overflow-x-hidden pb-1  ${
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      search && 'min-w-full'
                    }`}
                  >
                    <Flex column itemAlignment="stretch">
                      {filteredItems && filteredItems.length > 0 ? (
                        filteredItems.map((element: ItemsListProps, i) => {
                          return (
                            <div key={i}>
                              {!title ? (
                                <a
                                  className="text-sm block px-4 py-2 text-gray-700 hover:bg-[#CCDEF8]"
                                  onClick={() => onAction(element)}
                                >
                                  {element.title}
                                </a>
                              ) : (
                                <a
                                  className="block cursor-pointer px-4 py-2 text-14 text-gray-700 hover:bg-[#CCDEF8]"
                                  onClick={() => onAction(element)}
                                >
                                  <Flex alignment="start">
                                    {element.icon && element.icon}
                                    <div className="ml-2 w-fit text-14">
                                      <Flex withGap textAlignment="left">
                                        {element.title}
                                      </Flex>
                                    </div>
                                  </Flex>
                                </a>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div className="p-4 text-14">
                          {t('CONTACTS_FILTERS.noResultsFound') as ReactI18NextChild}
                        </div>
                      )}
                    </Flex>
                  </section>
                </>
              ) : (
                <BigColumnSkeleton />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
