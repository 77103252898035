type GMTOffset = string | number;

interface DateConversionOptions {
  format?: string;
  locale?: string;
  timezone?: string;
}

export default class DateConverter {
  /**
   * Converts a date string to a Date object, considering a specific GMT offset
   * @param dateString - Date string to convert
   * @param gmtOffset - Desired GMT (e.g., 'GMT-5', '+3', -4)
   * @param options - Additional conversion options
   */
  static toGMTDate(
    dateString: string,
    gmtOffset: GMTOffset,
    options: DateConversionOptions = {}
  ): Date {
    // Normalize the GMT offset
    const offset = this.normalizeGMTOffset(gmtOffset);

    // Create base date
    const baseDate = new Date(dateString);

    // Adjust to the specified GMT
    const utcTimestamp = baseDate.getTime() + baseDate.getTimezoneOffset() * 60000;
    const targetTimestamp = utcTimestamp + offset * 3600000;

    return new Date(targetTimestamp);
  }

  /**
   * Formats a date to a specific GMT
   * @param date - Date to format
   * @param gmtOffset - Desired GMT offset
   * @param format - Desired format (optional)
   */
  static formatToGMT(
    date: Date,
    gmtOffset: GMTOffset,
    options: DateConversionOptions = {}
  ): string {
    const { format = 'full', locale = 'en-US' } = options;

    const gmtDate = this.toGMTDate(date.toISOString(), gmtOffset);

    const formatOptions: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      hour12: false,
    };

    switch (format) {
      case 'full':
        return gmtDate.toISOString();
      case 'date':
        return gmtDate.toLocaleDateString(locale, formatOptions);
      case 'time':
        return gmtDate.toLocaleTimeString(locale, formatOptions);
      case 'dateTime':
        return gmtDate.toLocaleString(locale, formatOptions);
      default:
        return gmtDate.toISOString();
    }
  }

  private static normalizeGMTOffset(gmtOffset: GMTOffset): number {
    if (typeof gmtOffset === 'number') {
      return gmtOffset;
    }

    const cleanOffset = gmtOffset.toString().replace('GMT', '').trim();
    const parsedOffset = parseInt(cleanOffset);

    if (isNaN(parsedOffset)) {
      throw new Error('Invalid GMT offset format');
    }

    return parsedOffset;
  }
}
