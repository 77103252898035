export const GroupIcon = () => {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24">
      <g data-name="Group 3330" id="Group_3330" transform="translate(-1633 -1618)">
        <g id="sms" transform="translate(1633 1618)">
          <path d="M0,0H24V24H0Z" data-name="Path 3150" fill="none" id="Path_3150" />
          <path
            d="M20,2H4A2.006,2.006,0,0,0,2,4V22l4-4H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,14H5.17L4,17.17V4H20ZM7,9H9v2H7Zm8,0h2v2H15ZM11,9h2v2H11Z"
            data-name="Path 3151"
            fill="#fc7e00"
            id="Path_3151"
          />
        </g>
      </g>
    </svg>
  );
};
