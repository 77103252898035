import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';

import { FeaturedTextTranslation } from '@/src/modules/CampaignsModule/components/FeaturedTextTranslation';
import { ITableCampaignActions } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ITableCampaigns,
  ITableCampaignsRecurrent,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { formattedNumber } from '@/src/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/src/modules/CampaignsModule/utils/formattedNumber';

interface SectionHeaderSubtitleProps {
  dataList: ITableCampaigns[] | ITableCampaignActions[] | ITableCampaignsRecurrent[] | undefined;
  loading: boolean;
  countRecordsPartial: number;
  countFilters: number;
  i18nKey: string;
}

const SectionHeaderSubtitle = ({
  dataList,
  loading,
  countRecordsPartial,
  countFilters,
  i18nKey,
}: SectionHeaderSubtitleProps) => {
  const { t, i18n } = useTranslation();

  const noFiltersMessage = t('CAMPAIGNS_MAIN.noFilters');
  const noResultsMessage = t('noResultsFound');

  return useMemo(() => {
    if (!dataList || loading) return <TextSkeleton />;

    if (countRecordsPartial > 0) {
      if (countFilters > 0) {
        return (
          <Trans
            components={{
              featured: (
                <FeaturedTextTranslation
                  text={
                    formattedNumber(
                      countRecordsPartial,
                      i18n.language as keyof typeof ELanguageMap
                    ) as string
                  }
                />
              ),
            }}
            i18nKey={i18nKey}
          />
        );
      } else {
        return <>{noFiltersMessage}</>;
      }
    } else {
      return <>{noResultsMessage}</>;
    }
  }, [
    dataList,
    loading,
    countRecordsPartial,
    countFilters,
    i18n.language,
    i18nKey,
    noFiltersMessage,
    noResultsMessage,
  ]);
};

export default SectionHeaderSubtitle;
