export const PatternIcon = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#CFF6FE]">
      <svg height="55%" viewBox="0 0 65.835 65.835" width="55%" xmlns="http://www.w3.org/2000/svg">
        <g id="pattern-security-svgrepo-com" transform="translate(0.955 0.955)">
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1750"
            fill="#11cffa"
            id="Ellipse_1750"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1751"
            fill="#11cffa"
            id="Ellipse_1751"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(26.112)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1752"
            fill="#11cffa"
            id="Ellipse_1752"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(52.225)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1753"
            fill="#11cffa"
            id="Ellipse_1753"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(0 26.112)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1754"
            fill="#11cffa"
            id="Ellipse_1754"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(26.112 26.112)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1755"
            fill="#11cffa"
            id="Ellipse_1755"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(52.225 26.112)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1756"
            fill="#11cffa"
            id="Ellipse_1756"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(0 52.225)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1757"
            fill="#11cffa"
            id="Ellipse_1757"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(26.112 52.225)"
          />
          <circle
            cx="5.85"
            cy="5.85"
            data-name="Ellipse 1758"
            fill="#11cffa"
            id="Ellipse_1758"
            r="5.85"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(52.225 52.225)"
          />
          <line
            data-name="Line 492"
            fill="none"
            id="Line_492"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(9.9 9.9)"
            x1="18.041"
            y1="18.041"
          />
          <line
            data-name="Line 493"
            fill="none"
            id="Line_493"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(31.963 37.708)"
            y1="14.61"
          />
          <line
            data-name="Line 494"
            fill="none"
            id="Line_494"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(37.708 5.85)"
            x1="14.61"
          />
          <line
            data-name="Line 495"
            fill="none"
            id="Line_495"
            stroke="#008bd0"
            strokeWidth="1.91"
            transform="translate(11.607 5.85)"
            x1="14.61"
          />
        </g>
      </svg>
    </div>
  );
};
