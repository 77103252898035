import { useAtom } from 'jotai';

import { Card, CardSection } from '@/lib/v2/components';

import { ContactsDetailTable } from '@/src/ContactsModule/components/ContactsViewInfo/ContactsDetailTable';
import { ImportSteps } from '@/src/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';

import { atomModalMappedFields } from '@/modules/ContactsModule/atoms/Step4';
import { atomDetailList, atomFiles } from '@/modules/ContactsModule/atoms/Steps';

const DetailTable = () => {
  const [files] = useAtom(atomFiles);
  const [detailList] = useAtom(atomDetailList);
  const [, setIsOpenModalMappedFields] = useAtom(atomModalMappedFields);

  if (
    !(files?.step === ImportSteps.dataCleaning || files?.step === ImportSteps.tagsAndGroup) ||
    !detailList
  )
    return null;

  return (
    <Card className="mt-6 rounded-t-none px-4 pt-4">
      <CardSection noPadding>
        <ContactsDetailTable
          detailList={detailList}
          setIsOpenModalMappedFields={setIsOpenModalMappedFields}
        />
      </CardSection>
    </Card>
  );
};

export default DetailTable;
