export const DashboardIcon = () => {
  //width="19.825" height="19.825"
  return (
    <svg viewBox="0 0 19.825 19.825">
      <path
        d="M20.623,5.2v2.2H16.217V5.2h4.406M9.608,5.2v6.608H5.2V5.2H9.608m11.014,8.811v6.608H16.217V14.014h4.406M9.608,18.42v2.2H5.2v-2.2H9.608M22.825,3H14.014V9.608h8.811ZM11.811,3H3V14.014h8.811Zm11.014,8.811H14.014V22.825h8.811ZM11.811,16.217H3v6.608h8.811Z"
        data-name="Path 3056"
        fill="#fff"
        id="Path_3056"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
