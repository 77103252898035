export const ReactivateIcon = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 7590" id="Group_7590" transform="translate(-0.318 0.163)">
        <rect
          data-name="Rectangle 3174"
          fill="none"
          height="20"
          id="Rectangle_3174"
          transform="translate(0.318 -0.163)"
          width="20"
        />
        <path
          d="M2.25,9.235a6.985,6.985,0,1,1,6.985,6.985A6.985,6.985,0,0,1,2.25,9.235Zm10.047-.7a.806.806,0,0,1,0,1.409L8.283,12.17a.806.806,0,0,1-1.2-.7V7.005a.806.806,0,0,1,1.2-.7Z"
          data-name="Traced 4479"
          fill="#004dbc"
          fillRule="evenodd"
          id="Traced_4479"
          transform="translate(1.068 0.615)"
        />
      </g>
    </svg>
  );
};
