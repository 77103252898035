/* cSpell:disable */

type BulletListIconProps = {
  tableSize?: boolean;
  stroked?: boolean;
};

export const BulletListIcon = ({ tableSize, stroked = true }: BulletListIconProps) => {
  return (
    <>
      {stroked && (
        <svg
          data-name="Grupo 6146"
          height={tableSize ? 20 : 32}
          id="Grupo_6146"
          viewBox="0 0 20 20"
          width={tableSize ? 20 : 32}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            data-name="Rectángulo 3306"
            fill="none"
            height={tableSize ? 20 : 32}
            id="Rectángulo_3306"
            width={tableSize ? 20 : 32}
          />
          <path
            d="M7.975,6.729H19.3M7.975,11.682H19.3M7.975,16.636H19.3M3.729,6.729h.007v.008H3.729Zm.354,0a.354.354,0,1,1-.354-.354A.354.354,0,0,1,4.083,6.729Zm-.354,4.953h.007v.008H3.729Zm.354,0a.354.354,0,1,1-.354-.354A.354.354,0,0,1,4.083,11.682Zm-.354,4.953h.007v.008H3.729Zm.354,0a.354.354,0,1,1-.354-.354A.354.354,0,0,1,4.083,16.636Z"
            data-name="Trazado 3511"
            fill="none"
            id="Trazado_3511"
            stroke="#004dbc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-0.875 -1.875)"
          />
        </svg>
      )}
    </>
  );
};
