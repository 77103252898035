import { KeyValuePairNumber, KeyValuePairString } from '@/src/ContactsModule/interfaces/Interfaces';

// cspell:disable
interface ICustomField {
  id: string;
  nombre: string;
  valor: string | null;
  tipoCampo: number;
  tipoDato: number;
  tipoOpcion: number;
  valores: string | null;
  esFijoEmblue: boolean;
  numero: string;
  cantidadContactos: number;
  solo_lectura: boolean;
  integracion_id: string | null;
}

interface ISegmentConditions {
  id: number;
  especie: string;
  instancia: InstanceSegment[];
}

interface InstanceSegment {
  operadorLogico: number;
  tipoValidacion: KeyValuePairNumber[];
  valores: KeyValuePairString[];
  valoresSecundarios: Record<string, string[]>;
  tipoValor: Record<number, number>;
  tipoValorSecundario: Record<string, number>;
}

export function wrapSegmentConditions(segmentsConditions: {
  id: number;
  nombre: string;
  valores: ISegmentConditions[];
}) {
  const { id, nombre, valores } = segmentsConditions;

  return {
    data: {
      id,
      name: nombre,
      values: valores.map((valor) => ({
        id: valor.id,
        specie: valor.especie,
        instance: valor.instancia.map((instance) => ({
          logicOperator: instance.operadorLogico,
          validationType: instance.tipoValidacion.map(({ Key, Value }) => ({ Key, Value })),
          values: instance.valores.map(({ Key, Value }) => ({ Key, Value })),
          secondaryValues: instance.valoresSecundarios,
          typeValue: instance.tipoValor,
          typeSecondaryValue: instance.tipoValorSecundario,
        })),
      })),
    },
  };
}

export const wrapCustomFieldsList = (customFieldList: ICustomField[]) => {
  const response = customFieldList;
  let i,
    // eslint-disable-next-line prefer-const
    rows = [],
    row = {},
    typeData = '',
    typeField = '',
    typeOption = '';

  const dataTypes: string[] = [];
  dataTypes['1'] = 'date';
  dataTypes['2'] = 'numeric';
  dataTypes['3'] = 'alphanumeric';
  dataTypes['4'] = 'email';
  dataTypes['5'] = 'sex';
  dataTypes['6'] = 'country';

  const fieldsType: string[] = [];
  fieldsType['1'] = 'normal';
  fieldsType['2'] = 'extended';

  const fieldsOption: string[] = [];
  fieldsOption['1'] = 'normal';
  fieldsOption['2'] = 'unique';
  fieldsOption['3'] = 'multiple';

  for (i = 0; i < response.length; i++) {
    typeData = dataTypes[response[i].tipoDato] ? dataTypes[response[i].tipoDato] : 'alphanumeric';

    typeField = fieldsType[response[i].tipoCampo] ? fieldsType[response[i].tipoCampo] : 'normal';

    typeOption = fieldsOption[response[i].tipoOpcion]
      ? fieldsOption[response[i].tipoOpcion]
      : 'normal';
    let nombre = response[i].nombre;
    let iconImage = response[i].esFijoEmblue
      ? '/emblue/images/icons/custom_fields/' + response[i].nombre + '.png'
      : '/emblue/images/icons/custom_fields/flag.png';
    if (response[i].integracion_id !== null) {
      iconImage = '/emblue/images/icons/custom_fields/actividad.png';
      nombre = nombre.replace('_', ' ');
    }

    row = {
      id: response[i].id,
      name: nombre,
      typeField: typeField,
      typeData: typeData,
      typeOption: typeOption,
      reserved: response[i].esFijoEmblue,
      values: response[i].valores,
      icon: iconImage,
      reservedField: response[i].esFijoEmblue,
      number: response[i].numero,
      integracion_id: response[i].integracion_id,
      solo_lectura: response[i].solo_lectura,
    };
    rows.push(row);
  }
  return {
    data: rows,
  };
};
